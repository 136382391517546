export const config = {
  prod: {
    env: 'prod',
    cognito: {
      UserPoolId: 'eu-central-1_LjxHx1STW',
      ClientId: 'tqjnbbvmf31ph070c8puraa7p',
    },
    gql: {
      url: 'https://api2.star.sami.se/prod/gql',
    },
    pnr: {
      url: 'https://api2.star.sami.se/prod/pnr',
    },
    distribution: {
      url: 'https://api2.star.sami.se/prod/dist',
    },
    matching: {
      url: 'https://api2.star.sami.se/prod/matching',
    },
    payment: {
      url: 'https://api2.star.sami.se/prod/payment',
    },
    notes: {
      url: 'https://api2.star.sami.se/prod/notes',
    },
    worklist: {
      url: 'https://api2.star.sami.se/prod/worklists',
    },
    process: {
      url: 'https://api2.star.sami.se/prod/process',
    },
    buckets: {
      data: 'star-data-prod',
      internal: 'star-docrepo-internal-prod',
    },
    cronjob: {
      replace: 'https://prod-services.star.sami.se',
      with: 'https://api2.star.sami.se/prod',
    },
  },
  test: {
    env: 'test',
    cognito: {
      UserPoolId: 'eu-central-1_LjxHx1STW',
      ClientId: 'tqjnbbvmf31ph070c8puraa7p',
    },
    gql: {
      url: 'https://api2.star.sami.se/test/gql',
    },
    pnr: {
      url: 'https://api2.star.sami.se/test/pnr',
    },
    distribution: {
      url: 'https://api2.star.sami.se/test/dist',
    },
    matching: {
      url: 'https://api2.star.sami.se/test/matching',
    },
    payment: {
      url: 'https://api2.star.sami.se/test/payment',
    },
    notes: {
      url: 'https://api2.star.sami.se/test/notes',
    },
    worklist: {
      url: 'https://api2.star.sami.se/test/worklists',
    },
    process: {
      url: 'https://api2.star.sami.se/test/process',
    },
    buckets: {
      data: 'star-data-test',
      internal: 'star-docrepo-internal-test',
    },
    cronjob: {
      replace: 'https://test-services.star.sami.se',
      with: 'https://api2.star.sami.se/test',
    },
  },
  dev: {
    env: 'dev',
    cognito: {
      UserPoolId: 'eu-central-1_LjxHx1STW',
      ClientId: 'tqjnbbvmf31ph070c8puraa7p',
    },
    gql: {
      url: 'https://api2.star.sami.se/dev/gql',
    },
    pnr: {
      url: 'https://api2.star.sami.se/dev/pnr',
    },
    distribution: {
      url: 'https://api2.star.sami.se/dev/dist',
    },
    matching: {
      url: 'https://api2.star.sami.se/dev/matching',
    },
    payment: {
      url: 'https://api2.star.sami.se/dev/payment',
    },
    notes: {
      url: 'https://api2.star.sami.se/dev/notes',
    },
    worklist: {
      url: 'https://api2.star.sami.se/dev/worklists',
    },
    process: {
      url: 'https://api2.star.sami.se/dev/process',
    },
    buckets: {
      data: 'star-data-dev',
      internal: 'star-docrepo-internal-dev',
    },
    cronjob: {
      replace: 'https://dev-services.star.sami.se',
      with: 'https://api2.star.sami.se/dev',
    },
  },
  local: {
    env: 'local',
    cognito: {
      UserPoolId: 'eu-central-1_LjxHx1STW',
      ClientId: 'tqjnbbvmf31ph070c8puraa7p',
    },
    gql: {
      url: 'http://localhost:3000/gql',
    },
    pnr: {
      url: 'http://localhost:8087/pnr',
    },
    distribution: {
      url: 'http://localhost:8082/dist',
    },
    matching: {
      url: 'http://localhost:8084/matching',
    },
    payment: {
      url: 'http://localhost:8083/payment',
    },
    notes: {
      url: 'https://api2.star.sami.se/test/notes',
    },
    worklist: {
      url: 'https://api2.star.sami.se/test/worklists',
    },
    process: {
      url: 'https://api2.star.sami.se/test/process',
    },
    buckets: {
      data: 'star-data-local',
      internal: 'star-docrepo-internal-local',
    },
    cronjob: {
      replace: 'none',
      with: 'none',
    },
  },
};
