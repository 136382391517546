import moment from 'moment';
import RemoteFileHelper from '../common/remoteFileHelper';
import Delay from '../common/delay';

export default {
  data() {
    return {
      loading: false,
      filterQuery: '',
      reportHeadersResult: { items: [], total_count: 0 },
      optionsVisibleId: null,
      timeoutHandle: null,
      error: false,
      isUploadingFile: false,
      societyIpdCodeFilter: undefined,
      finished: undefined,
      pagination: {
        currentPage: 1,
        hitsPerPage: 25,
      },
      sortTerms: {
        param: undefined,
        order: undefined,
      },
    };
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  beforeRouteLeave(_to, _from, next) {
    clearTimeout(this.timeoutHandle);
    this.timeoutHandle = null;
    next();
  },
  methods: {
    numberOfPages() {
      return Math.ceil(this.reportHeadersResult.total_count / this.pagination.hitsPerPage);
    },
    getOffset() {
      return (this.pagination.currentPage - 1) * this.pagination.hitsPerPage;
    },
    getMatchStateClass(matchState) {
      switch (matchState) {
        case 'I':
        case 'M':
        case 'T':
          return 'report__headers-chip--wait';
        case 'MF':
          return 'report__headers-chip--ok';
        case 'E':
          return 'report__headers-chip--fail';
        case 'F':
        case 'D':
        default:
          return 'report__headers-chip--remove';
      }
    },
    isWaitState(matchState) {
      switch (matchState) {
        case 'I':
        case 'M':
        case 'T':
          return true;
        default:
          return false;
      }
    },
    isWaitingOverdue(date) {
      const ms = moment().diff(moment.utc(date, 'YYYY-MM-DD HH:mm:ss'));
      return moment.duration(ms)._milliseconds > 1000 * 60 * 30;
    },
    search(terms) {
      this.pagination.currentPage = 1;
      this.filterQuery = terms;
      this.updateQueryParams();
    },
    clearSearch() {
      this.pagination.currentPage = 1;
      this.filterQuery = '';
      this.updateQueryParams();
    },
    onSort(sortOptions) {
      this.sortTerms.param = sortOptions.sortParam;
      this.sortTerms.order = sortOptions.order;
      this.updateQueryParams();
    },
    selectPage(page) {
      this.pagination.currentPage = page;
      this.updateQueryParams();
    },
    updateHitsPerPage(limit) {
      this.pagination.hitsPerPage = +limit;
      this.pagination.currentPage = 1;
      this.updateQueryParams();
    },
    updateQueryParams() {
      const queryParams = {};
      queryParams.sortColumn = this.sortTerms.param;
      queryParams.sortOrder = this.sortTerms.order;
      if (this.filterQuery) {
        queryParams.filter = this.filterQuery;
      }
      if (this.pagination.currentPage > 1) {
        queryParams.page = this.pagination.currentPage;
      }
      if (this.pagination.hitsPerPage !== 25) {
        queryParams.pageSize = this.pagination.hitsPerPage;
      }
      if (this.societyIpdCodeFilter) {
        queryParams.society = this.societyIpdCodeFilter;
      }
      if (this.finished) {
        queryParams.finished = this.finished;
      }
      this.$router.replace({
        name: this.$router.currentRoute.name,
        query: queryParams,
      });
    },
    readQueryParams() {
      if (this.$route.query.filter) {
        this.filterQuery = this.$route.query.filter;
      }
      if (this.$route.query.sortColumn) {
        this.sortTerms.param = this.$route.query.sortColumn;
      }
      if (this.$route.query.sortOrder) {
        this.sortTerms.order = this.$route.query.sortOrder;
      }
      if (this.$route.query.page) {
        this.pagination.currentPage = Number(this.$route.query.page);
      }
      if (this.$route.query.pageSize) {
        this.pagination.hitsPerPage = Number(this.$route.query.pageSize);
      }
      if (this.$route.query.society) {
        this.societyIpdCodeFilter = this.$route.query.society;
      }
      if (this.$route.query.finished) {
        this.finished = this.$route.query.finished === 'true';
      }
    },
    handleError(title, error) {
      error.title = title;
      this.$addStarError(error);
    },
    async fetchData() {
      // Override;
    },
    async upload() {
      this.isUploadingFile = true;
      this.error = false;
      try {
        this.uploadFile.uploadProgress = 0;
        await RemoteFileHelper.uploadPlaytimeReportFile(
          this.uploadFile,
          (progressInPercent) => (this.uploadFile.uploadProgress = progressInPercent),
        );
        this.resetFileUpload();
        await Delay(2000);
      } catch (error) {
        this.isUploadingFile = false;
        this.handleError('Upload failed', error);
      }
    },
  },
};
