var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s10"},[_c('h2',{staticClass:"float-left"},[_vm._v(" "+_vm._s(_vm.relationType === 'childAssociations' ? 'Collects/Inherits from' : 'Transfers to Associates')+" "),(_vm.numAssociations > 8)?_c('span',[_vm._v("("+_vm._s(_vm.numAssociations)+")")]):_vm._e()])])]),_c('div',{staticClass:"row card push-half--bottom"},[_c('div',{staticClass:"col s12"},[_c('table',{staticClass:"lineup__performers"},[_c('sortable-head',{attrs:{"more-options":_vm.enableRowOptions,"columns":_vm.columns,"bulk-enabled":false,"default-sort-column-index":0}}),_c('tbody',[(_vm.loading)?[_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('component-spinner')],1)])]:_vm._l((_vm.sortedItems),function(item,index){return [(
                _vm.inEdit === `${item.id}${item.row_id}` || (_vm.inEdit === -1 && item.row_id === -1)
              )?_c('tr',{key:`edit-${_vm.getIndex(item)}${item.row_id}`,staticClass:"prevent-hover-bg"},[_c('td',{attrs:{"colspan":"1000"}},[_c('form',{attrs:{"data-vv-scope":_vm.scope}},[_c('edit-associate',{attrs:{"associate":item,"index":_vm.getIndex(item),"relation-type":_vm.relationType,"namespace":_vm.namespace,"edit-mode":true},on:{"cancel":function($event){return _vm.onCancel(item)},"save":_vm.save}})],1)])]):_c('tr',{key:index},[_c('td',[_c('router-link',{attrs:{"to":_vm.calculateRoute(item)}},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(_vm.$filters.associateTypeName(item.type === 'PERFORMER' ? 'Performer' : item.type))+" ")]),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(item.start_date)+" ")]),_c('td',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(item.end_date)+" ")]),_c('td',[(item.access_policy !== 'NONE')?_c('i',{staticClass:"push-half--right far fa-eye"}):_vm._e()]),_c('td',[(item.access_policy === 'WRITE')?_c('i',{staticClass:"push-half--right fas fa-pencil-alt"}):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.share)+" ")]),(_vm.enableRowOptions)?_c('td',[_c('context-menu',{attrs:{"disabled":_vm.inEdit !== '',"options":_vm.contextMenuOptions()},on:{"edit":function($event){return _vm.onEdit(item)},"remove":function($event){_vm.remove({
                      id: item.id,
                      row_id: item.row_id,
                      type: _vm.namespace,
                      index: _vm.getIndex(item),
                    })}}})],1):_vm._e()])]})],2)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12 edit-link"},[_c('a',{class:{ disabled: _vm.inEdit !== '' },on:{"click":_vm.onAdd}},[_c('i',{staticClass:"far fa-plus-square"}),_vm._v(" Add associated ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }