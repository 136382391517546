/**
 * The Album Service contains album related methods, such as CRUD.
 */
import Vue from 'vue';
import * as uuid from 'uuid';
import { flattenLocalCodes } from '../common/aggregateUtils';
import merge from '../common/objectMerger';
import {
  createAlbumAggregate,
  createAlbumCommand,
  createUpdateBasicInfoCommand,
  createUpdateTracksCommand,
} from '../domain/albumDomain';
import gql from '../domain/albumGql';
import { getConfigValue, PROPS } from './configService';
import prepareForWire from './wirePrepper';
import { query } from './apolloRequest';

const ALBUM_URL = `${getConfigValue(PROPS.PNR_URL)}/albums`;

function prepareAlbumDataForWire(command) {
  prepareForWire(command);
  return flattenLocalCodes(command);
}

function prepareTracksFoWire(trackAggregate) {
  return trackAggregate
    .filter((t) => t.recording.id)
    .map((t) => ({
      track_title: t.track_title,
      duration_sec: t.recordingAggregate.basic_info.duration_sec,
      recording: t.recording,
    }));
}

function addCommandIds(command, aggregate) {
  command.version = aggregate.version || 0;
  command.stream_id = aggregate.id;
  command.process_id = uuid.v4();
  command.timestamp = new Date();
}

function createUpdateAlbumBasicInfoCommand(aggregate) {
  const updateCommand = createUpdateBasicInfoCommand();
  addCommandIds(updateCommand, aggregate);
  updateCommand.payload.basic_info = merge(updateCommand.payload.basic_info, aggregate.basic_info);
  prepareAlbumDataForWire(updateCommand);
  return updateCommand;
}

function createUpdateAlbumTracksCommand(aggregate, upsert = false) {
  const updateCommand = createUpdateTracksCommand(upsert);
  addCommandIds(updateCommand, aggregate);

  aggregate.tracks.forEach((track) => {
    if (track.recording.version_title) {
      delete track.recording.version_title;
    }
  });

  updateCommand.payload.tracks = prepareTracksFoWire(aggregate.tracks);
  prepareForWire(updateCommand.payload);
  return updateCommand;
}

function createAlbumAggregateCommand(aggregate) {
  const createCommand = createAlbumCommand();
  createCommand.process_id = uuid.v4();
  createCommand.stream_id = uuid.v4();
  createCommand.version = 0;
  createCommand.timestamp = new Date();
  createCommand.payload.basic_info = aggregate.basic_info;
  createCommand.payload.tracks = prepareTracksFoWire(aggregate.tracks);
  return prepareAlbumDataForWire(createCommand);
}

function processAggregate(aggregate) {
  // Merge fetched performer with the known domain model, retain known domain model fields and omit unknown on fetched performer.
  return merge(createAlbumAggregate(), aggregate);
}

async function getAlbum(idTerm, versionTerm) {
  const res = await query(
    { query: gql.getFullAlbum, variables: { idTerm, versionTerm } },
    { pickProp: 'albumAggregate' },
  );
  return processAggregate(res);
}

async function getDiscographyAlbums(type, id, options) {
  const validTypes = ['performer', 'mainartist'];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}'. Only ${validTypes.join(', ')} are supported`);
  }
  const { filterQuery, mainArtist, sortField, sortOrder, pageFrom, pageSize } = options;
  const pagination = { sortField, sortOrder, pageFrom, pageSize };
  return query(
    {
      query: gql.getDiscographyAlbums,
      variables: { id, type, filterQuery, mainArtist, pagination },
    },
    { pickProp: 'discographyAlbums' },
  );
}

async function getAlbumsByMainArtist(id, options) {
  return getDiscographyAlbums('mainArtist', id, options);
}

async function getAlbumsByPerformer(id, options) {
  return getDiscographyAlbums('performer', id, options);
}

async function getTracksByAlbum(id) {
  const aggregate = await query(
    { query: gql.getTracksByAlbum, variables: { idTerm: id } },
    { pickProp: 'albumAggregate' },
  );
  return aggregate.tracks;
}

export default {
  getAlbum,
  getAlbumsByMainArtist,
  getAlbumsByPerformer,
  getTracksByAlbum,
  getDiscographyAlbums,
  createAlbum: (aggregate) =>
    Vue.$http
      .post(`${ALBUM_URL}/creation`, createAlbumAggregateCommand(aggregate))
      .then((response) => Promise.resolve(response.data.id)),
  updateAlbumBasicInfo: (aggregate) =>
    Vue.$http
      .post(`${ALBUM_URL}/basic-info`, createUpdateAlbumBasicInfoCommand(aggregate))
      .then((response) => Promise.resolve(response.data.id)),
  updateAlbumTracks(aggregate, upsert = false) {
    return Vue.$http
      .post(`${ALBUM_URL}/tracks`, createUpdateAlbumTracksCommand(aggregate, upsert))
      .then((response) => Promise.resolve(response.data.id));
  },
};
