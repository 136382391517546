import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getConfigValue, PROPS } from '@/services/configService';
import { addStarError } from '@/services/notificationService';
import AuthenticationService from '@/services/authenticationService';

const cache = new InMemoryCache({
  addTypename: false,
});

cache.restore(window.__APOLLO_STATE__ || {});

const httpLink = new HttpLink({ uri: getConfigValue(PROPS.GQL_URL) });

const middlewareLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: AuthenticationService.getAuthToken(),
    },
  });
  return forward(operation);
}).concat(httpLink);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
    });
  }
  if (networkError) {
    addStarError(`[Network error]: ${networkError}`);
  }
  if (!(graphQLErrors || networkError)) {
    throw new Error('Uncaught Apollo error');
  }
}).concat(middlewareLink);

const link = errorLink;

export default function configure() {
  return {
    link,
    cache,
  };
}
