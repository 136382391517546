<template>
  <div>
    <div class="row wrapper">
      <div class="col s11">
        <h2>Merge IPN's</h2>
      </div>
    </div>
    <div class="row card">
      <div class="row">
        <div class="col s5">
          <file-upload-simple-field
            ref="mergeField"
            label="Merge report"
            name="mergeRefId"
            accept-file-type=".csv"
            @addFile="mergeFile"
            @removeFile="removeFile"
          />
        </div>
        <div v-if="state === 'UPDATE_COMPLETE'" class="col s6">
          <div class="chip chip--green">Update complete!</div>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <table class="errors__recordings">
            <thead>
              <th>Old IPN</th>
              <th>New IPN</th>
              <th>Name</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr class="text--center spinner-row" />
              <template v-for="(row, index) in fileRows">
                <tr v-if="index <= 9 || showAll" :key="index">
                  <td class="fs-14">
                    {{ row.currentIpn }}
                  </td>
                  <td>
                    {{ row.newIpn }}
                  </td>
                  <td>
                    {{ row.performerName }}
                  </td>
                  <td>
                    <span v-if="row.exists === -1">
                      <i class="fa fa-exclamation-circle text--orange" />
                      Ignored, old ipn not found
                    </span>
                    <span v-if="row.exists === 0">
                      <i class="fa fa-question-circle text--green" />
                      Checking, wait...
                    </span>
                    <span v-if="row.exists === 1">
                      <i class="fa fa-check-circle text--green" />
                      <span v-if="state === 'UPDATE_COMPLETE'">Updated</span>
                      <span v-else>Ready to update</span>
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
            <div v-if="fileRows.length > 9" class="no-result">
              <a class="col s12" @click="showAll = !showAll">
                {{ showAll ? 'Show less' : `Show all (${fileRows.length})` }}
              </a>
            </div>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <button :disabled="state !== 'READY_TO_UPDATE'" class="btn primary m2" @click="update()">
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import PerformerService from '../../services/performerService';
import FileUploadSimpleField from '../ui/file/file-upload-simple-field';

const ADD_FILES = 'ADD_FILES';
const NO_DATA = 'NO_DATA';
const READY_TO_UPDATE = 'READY_TO_UPDATE';
const UPDATE_IN_PROGRESS = 'UPDATE_IN_PROGRESS';
const UPDATE_COMPLETE = 'UPDATE_COMPLETE';

export default {
  name: 'IpdMergeIpn',
  components: {
    FileUploadSimpleField,
  },
  data() {
    return {
      state: ADD_FILES,
      file: undefined,
      fileRows: [],
      performersToUpdate: [],
      showAll: false,
    };
  },
  methods: {
    mergeFile(file) {
      this.file = file;
      const fileReader = new FileReader();
      fileReader.addEventListener('load', (event) => {
        const textFile = event.target;
        this.fileRows = textFile.result
          .split('\n')
          .filter((line) => line && line.length > 0)
          .map((line) => {
            const rows = line.split(',');
            const mergedIpn = rows[7] ? rows[7].trim() : undefined;
            const realIpn = rows[1] ? rows[1].trim() : undefined;
            return {
              currentIpn: rows[0].trim(),
              newIpn: realIpn !== '' ? realIpn : mergedIpn,
              performerName: `${rows[2]} ${rows[3]}`,
              exists: 0,
            };
          })
          .slice(1)
          .slice(0, -1);
      });
      fileReader.addEventListener('loadend', () => {
        if (this.fileRows.length === 0) {
          this.state = NO_DATA;
        } else {
          this.checkStatus();
        }
      });
      fileReader.readAsText(this.file);
    },
    async checkStatus() {
      try {
        const fileRowsChunks = _.chunk(this.fileRows, 100);
        const searchPromises = fileRowsChunks.map((chunk) =>
          PerformerService.idByIPN(chunk.map((row) => row.currentIpn)),
        );
        const searchResult = await Promise.all(searchPromises);
        this.performersToUpdate = _.flatMap(searchResult).map((p) => {
          const mergeRow = this.fileRows.find((mr) => mr.currentIpn === p.ipn);
          mergeRow.exists = 1;
          return {
            id: p.id,
            ipn: mergeRow.newIpn,
          };
        });
        this.fileRows.forEach((r) => {
          if (r.exists === 0) {
            r.exists = -1;
          }
        });

        if (this.performersToUpdate.length === 0) {
          this.state = NO_DATA;
        } else {
          this.state = READY_TO_UPDATE;
        }
      } catch (e) {
        e.title = 'Could not check status';
        this.$addStarError(e);
        this.removeFile();
      }
    },
    async update() {
      this.state = UPDATE_IN_PROGRESS;
      try {
        const promises = this.performersToUpdate.map((p) =>
          PerformerService.patchPerformerIpn(p.id, p.ipn),
        );
        await Promise.all(promises);
        this.state = UPDATE_COMPLETE;
      } catch (e) {
        e.title = 'Failed to update ipn';
        this.$addStarError(e);
        this.state = READY_TO_UPDATE;
      }
    },
    removeFile() {
      this.fileRows = [];
      this.performersToUpdate = [];
      this.showAll = false;
      this.file = undefined;
      this.state = ADD_FILES;
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.strike {
  text-decoration: line-through;
}

.chip {
  margin: var(--spacing-half);

  &--green {
    background-color: var(--green);
    color: var(--white);
  }
}
</style>
