<template>
  <form class="pos-relative" data-vv-scope="edit-tags" @keyup.esc="cancel">
    <div class="row">
      <div class="col s3">
        <h2>Tags</h2>
      </div>
      <div class="text--right col s9">
        <action-buttons @save="save" @cancel="cancel" />
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <searchable-tag-input
          name="metadata_info.tags"
          label="Tags"
          placeholder="E.g. Top 100"
          :value-list="localTags"
          :searcher="searchTag"
          :preview-list="previewTags"
          use-template="tag"
          scope="edit-tags"
          @add="addTag"
          @remove="removeTagAt"
        />
      </div>
    </div>
  </form>
</template>

<script>
import ActionButtons from '../../../ui/button/action-buttons';
import EditHelper from './editHelper';
import SearchableTagInput from '../../../ui/tag/searchable-tag-input';
import SearchHelper from '../../../search/searchHelper';

import PnrService from '@/services/pnrService';
import { createMetadataInfoCommand } from '@/domain/performerDomain';

export default {
  name: 'EditTags',
  components: {
    ActionButtons,
    SearchableTagInput,
  },
  data() {
    return {
      performer: EditHelper.getCurrentPerformer(),
      localTags: this.copyTags(EditHelper.getCurrentPerformer().metadata_info.tags.slice()),
      allTags: [],
    };
  },
  computed: {
    previewTags() {
      return this.allTags.filter((tag) =>
        this.localTags.length > 0
          ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
          : true,
      );
    },
  },
  async created() {
    this.allTags = await SearchHelper.getAllTags();
  },
  methods: {
    copyTags(tags) {
      return tags.map((tag) => ({ name: tag, type: 'tag' }));
    },
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.localTags = event.data;
      this.performer.metadata_info.tags.push(event.item.name);
    },
    removeTagAt(event) {
      this.performer.metadata_info.tags.splice(event.idx, 1);
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    async save() {
      const command = PnrService.prepareCommand(
        this.performer,
        createMetadataInfoCommand(),
        this.performer.metadata_info,
      );

      await PnrService.postCommands({ errorTitle: 'Could not update tags' }, [command]);
      this.$emit('saved');
    },
  },
};
</script>
