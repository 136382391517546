<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Performers</h2>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <StarDataTable
          name="performers"
          :columns="table.columns"
          :items="localPerformers"
          :sort.sync="table.sort"
          local-sort
        >
          <template #item:name="{ item }">
            <router-link :to="{ name: 'performerView', params: { id: item.id } }">
              {{ item.name }}
              {{ item.protected_identity ? ' 🔒' : '' }}
            </router-link>
          </template>

          <template #item:pseudo="{ item }">
            <span v-if="item.pseudo_names.length">
              {{ item.pseudo_names.join(', ') }}
            </span>
            <span v-else class="none">None</span>
          </template>

          <template #item:instruments="{ item }">
            <span v-if="item.instruments.length">
              {{ $filters.formatInstrumentList(item.instruments) }}
            </span>
            <span v-else class="none">None</span>
          </template>

          <template #item:roles="{ item }">
            <span v-if="item.roles.length">
              {{ $filters.formatRoleList(item.roles) }}
            </span>
            <span v-else class="none">None</span>
          </template>

          <template #item:appears-on="{ item }">
            <span>{{ item.tracks.length }} track{{ item.tracks.length > 1 ? 's' : '' }}</span>
          </template>

          <template #item:nationality="{ item }">
            <span v-if="item.nationality">
              {{ $filters.formatCountry(item.nationality) }}
            </span>
            <span v-else class="none">None</span>
          </template>

          <template #item:ipn="{ item }">
            <span v-if="item.ipn">
              {{ item.ipn }}
            </span>
            <span v-else class="none">None</span>
          </template>

          <template #item:context="{ item }">
            <context-menu
              :options="['Add/Edit appearances']"
              @addEditAppearances="openEditPerformer(item)"
            />
          </template>
        </StarDataTable>
      </div>
    </div>

    <div class="row">
      <h2>Add Existing Performers</h2>
    </div>
    <div class="edit-link">
      <a @click="openEditPerformer()">
        <i class="far fa-plus-square" />
        Add performer
      </a>
    </div>

    <EditAddNewPerformer
      v-if="showEditPerformer"
      :performer="selectedPerformer"
      :enable-search="!selectedPerformer"
      @cancel="closeEditPerformer()"
      @patch="$emit('patchLineup', $event)"
    ></EditAddNewPerformer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ContextMenu from '../../ui/context-menu';
import EditAddNewPerformer from '../edit/edit-add-new-performer.vue';

export default {
  components: {
    ContextMenu,
    EditAddNewPerformer,
  },
  props: {
    showEditPerformer: Boolean,
    selectedPerformerId: String,
  },
  data() {
    return {
      localPerformers: [],
      table: {
        sort: {
          order_by: 'name',
          direction: 'desc',
        },
        columns: [
          {
            key: 'name',
            text: 'Name',
            sortable: true,
          },
          {
            key: 'pseudo',
            text: 'Pseudo Names',
            sortable: true,
            sort: (a, b) => {
              const aPseudo = a.pseudo_names[0] || '';
              const bPseudo = b.pseudo_names[0] || '';

              return aPseudo.localeCompare(bPseudo);
            },
          },
          {
            key: 'instruments',
            text: 'Instruments',
            sortable: true,
            sort: (a, b) => {
              const aInstrument = this.$filters.formatInstrumentList(a.instruments)[0] || '';
              const bInstrument = this.$filters.formatInstrumentList(b.instruments)[0] || '';

              return aInstrument.localeCompare(bInstrument);
            },
          },
          {
            key: 'roles',
            text: 'Roles',
            sortable: true,
            sort: (a, b) => {
              const aRole = this.$filters.formatRoleList(a.roles)[0] || '';
              const bRole = this.$filters.formatRoleList(b.roles)[0] || '';

              return aRole.localeCompare(bRole);
            },
          },
          {
            key: 'appears-on',
            text: 'Appears on',
            sortable: true,
            sort: (a, b) => a.tracks.length - b.tracks.length,
          },
          {
            key: 'nationality',
            text: 'Nationality',
            sortable: true,
            sort: (a, b) => {
              const aCountry = this.$filters.formatCountry(a.nationality) || '';
              const bCountry = this.$filters.formatCountry(b.nationality) || '';

              return aCountry.localeCompare(bCountry);
            },
          },
          {
            key: 'ipn',
            text: 'IPN',
            sortable: true,
          },
          {
            key: 'context',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters('album', ['tracks']),
    selectedPerformer() {
      if (!this.selectedPerformerId) {
        return null;
      }

      return this.localPerformers.find((p) => p.id === this.selectedPerformerId);
    },
  },
  watch: {
    tracks() {
      this.localPerformers = this.mapPerformers(this.tracks);
    },
  },
  created() {
    this.localPerformers = this.mapPerformers(this.tracks);
  },
  methods: {
    openEditPerformer(performer) {
      this.$emit('openEditPerformer', performer);
    },
    closeEditPerformer() {
      this.$emit('closeEditPerformer');
    },
    mapPerformers(tracks) {
      return tracks
        .flatMap((track) =>
          (track.recordingAggregate.lineup ? track.recordingAggregate.lineup : []).map((line) => ({
            ...line.relation,
            roles: line.role ? [line.role] : [],
            instruments: [...line.instruments],
            pseudo_names: line.pseudo_name ? [line.pseudo_name] : [],
            tracks: [
              {
                id: track.recording.id,
                role: line.role,
                instruments: line.instruments,
                pseudo_name: line.pseudo_name,
              },
            ],
          })),
        )
        .reduce((lineup, performer) => {
          const lineupIndex = lineup.findIndex((p) => p.id === performer.id);

          if (lineupIndex >= 0) {
            lineup[lineupIndex].tracks.push(...performer.tracks);

            for (const track of performer.tracks) {
              if (track.role && !lineup[lineupIndex].roles.includes(track.role)) {
                lineup[lineupIndex].roles.push(track.role);
              }

              for (const instrument of track.instruments) {
                if (instrument && !lineup[lineupIndex].instruments.includes(instrument)) {
                  lineup[lineupIndex].instruments.push(instrument);
                }
              }

              if (
                track.pseudo_name &&
                !lineup[lineupIndex].pseudo_names.includes(track.pseudo_name)
              ) {
                lineup[lineupIndex].pseudo_names.push(track.pseudo_name);
              }
            }
          } else {
            lineup.push(performer);
          }
          return lineup;
        }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  border-bottom: 1pt solid var(--grey--light);
}
</style>
