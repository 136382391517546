import RemoteFileService from './remoteFileService';
import CreateUploadFileDescriptor from '../domain/uploadFileDescriptor';

export default async function uploadDocuments(aggregateType, documents) {
  const documentsToUpload = documents.filter((document) => document && document.fileHandle);
  const promises = documentsToUpload.map(async (document) => {
    const entityType = `${aggregateType.toUpperCase()}-${document.type}`;
    const fileDescriptor = CreateUploadFileDescriptor(
      entityType,
      document.id,
      [document.fileName || document.fileHandle.name],
      document.fileHandle,
    );
    const fileName = await RemoteFileService.uploadToDocumentRepository(fileDescriptor, {
      repo: 'star-docrepo-internal',
    });
    document.url = fileName;
    delete document.fileHandle;
    delete document.fileName;
  });
  await Promise.all(promises);
}
