function createEl(fileData, fileName) {
  const ahref = document.createElement('a');
  ahref.href = window.URL.createObjectURL(fileData);
  ahref.download = fileName;
  return ahref;
}

/**
 * Starts the download directly.
 * @param fileData the file data (e.g. blob)
 * @param fileName thee file name
 */
function direct(fileData, fileName) {
  return createEl(fileData, fileName).click();
}

export default {
  direct,
};
