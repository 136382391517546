<template>
  <div class="right-column row">
    <div class="col s12">
      <div class="tabs">
        <div class="tabs__wrapper">
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'general' }"
            @click="selectedTab = 'general'"
          >
            General
          </div>
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'activity' }"
            @click="selectedTab = 'activity'"
          >
            Activity
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'general'">
        <create-tags
          v-if="isEditMode('Tags')"
          :edit-mode="true"
          scope="tags-society"
          entity-type="society"
          path="meta_info"
          add-method="addToMetaInfoTags"
          remove-method="removeFromMetaInfoTags"
          @update="onUpdateMetaInfo"
          @cancel="onCancel"
        />
        <tags v-else :tags="metaInfoTags" @edit="onEdit" />

        <notes :id="id" />

        <manual-payment-add-btn :entity-id="id" />
      </div>
      <div v-else>
        <activity :version="version" :stream-id="id" view-name="societyInformationVersion" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Activity from '../ui/activity';
import CreateTags from '../tags/create-tags';
import ManualPaymentAddBtn from '../ui/payment/manual-payment-add-btn';
import Notes from '../ui/input/notes';
import Tags from '../tags/tags';

export default {
  name: 'RightColumn',
  components: {
    Activity,
    CreateTags,
    ManualPaymentAddBtn,
    Notes,
    Tags,
  },
  props: {
    editableComponent: {
      type: String,
      default: '',
    },
    preSelectedTab: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      selectedTab: this.preSelectedTab,
    };
  },
  computed: {
    ...mapGetters('society', ['id', 'version', 'metaInfoTags']),
  },
  watch: {
    preSelectedTab(tab) {
      this.selectedTab = tab;
    },
  },
  methods: {
    ...mapMutations('society', ['updateMetaInfoTags']),
    onEdit(view) {
      this.$emit('onEdit', view);
    },
    onCancel() {
      this.$emit('onCancel');
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onUpdateMetaInfo() {
      this.$emit('onUpdateMetaInfo');
    },
  },
};
</script>
