<template>
  <div class="quick-search">
    <div class="row">
      <div class="col s11">
        <div class="quick-search__input-wrapper">
          <clearable-input
            v-model="query"
            type="search"
            class="quick-search__input"
            :placeholder="placeholder"
            @input="debouncedSearch($event)"
            @keydown.prevent.enter="handleEnter()"
            @click="openSearchResults()"
            @keydown.up="selectPreviousResult()"
            @keydown.down="selectNextResult()"
            @keydown.prevent.down="openSearchResults()"
            @keydown.prevent.esc="closeSearchResults()"
            @blur="closeSearchResults()"
          />

          <div
            v-show="showDropdownResults"
            class="quick-search__autocomplete"
            :style="quickSearchStyle()"
            @blur="closeSearchResults()"
          >
            <template v-if="hasResult && !query">
              <quick-search-result
                v-if="searchResult.latestVisited.length > 0"
                label="Latest Visited"
                :show-header="false"
                query=""
                :results="searchResult.latestVisited"
                :selected-result="selectedResult"
                @selected="onSelectedResult"
                @clicked="handleEnter()"
              />
            </template>
            <template v-else-if="hasResult && query.length >= 3">
              <quick-search-result
                label="Performers"
                :query="query"
                :results="searchResult.performers"
                :selected-result="selectedResult"
                @selected="onSelectedResult"
                @clicked="handleEnter()"
              />

              <quick-search-result
                label="Recordings"
                :query="query"
                :results="searchResult.recordings"
                :selected-result="selectedResult"
                @selected="onSelectedResult"
                @clicked="handleEnter()"
              />

              <quick-search-result
                label="Main Artists"
                :query="query"
                :results="searchResult.mainartists"
                :selected-result="selectedResult"
                @selected="onSelectedResult"
                @clicked="handleEnter()"
              />

              <quick-search-result
                label="Albums"
                :query="query"
                :results="searchResult.albums"
                :selected-result="selectedResult"
                @selected="onSelectedResult"
                @clicked="handleEnter()"
              />

              <quick-search-result
                label="Associates"
                :query="query"
                :results="searchResult.associates"
                :selected-result="selectedResult"
                @selected="onSelectedResult"
                @clicked="handleEnter()"
              />

              <quick-search-result
                label="Societies"
                :query="query"
                :results="searchResult.societies"
                :selected-result="selectedResult"
                @selected="onSelectedResult"
                @clicked="handleEnter()"
              />
            </template>
            <template v-else>
              <div class="quick-search__autocomplete-result">
                <template v-if="query.length <= 2">
                  <div>
                    <i class="material-icons">thumb_up</i>
                    Almost there, give me {{ 3 - query.length }} more
                    <span v-if="query.length === 2">letter</span>
                    <span v-else>letters</span>
                  </div>
                </template>
                <template v-else>
                  <template v-if="isSearching">
                    <div>
                      <i class="far fa-smile" />
                    </div>
                    <div>I'm looking...hang on!</div>
                  </template>
                  <SearchQueryTooLong
                    v-else-if="isTooLongQuery"
                    :length-limit="searchLengthLimit"
                    :query-too-long="isTooLongQuery"
                  />
                  <template v-else>
                    <div>
                      <i class="far fa-frown" />
                      No result
                    </div>
                  </template>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col s1">
        <button class="btn quick-search__btn" @click="toAdvancedSearchResultPage">
          <i class="fas fa-search" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SearchHelper from './searchHelper';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import QuickSearchResult from './quick-search-result';
import ClearableInput from '../ui/input/clearable-input.vue';
import SearchQueryTooLong from './search-query-too-long';

const routeMappings = {
  performer: 'performerView',
  recording: 'recordingView',
  mainartist: 'mainArtistView',
  album: 'albumView',
  associate: 'associateView',
  society: 'societyView',
};

export default {
  name: 'Search',
  components: {
    QuickSearchResult,
    ClearableInput,
    SearchQueryTooLong,
  },
  mixins: [DebouncedSearchMixin(350)],
  props: {
    placeholder: { type: String },
    minChars: { type: String },
  },
  data() {
    return {
      searchResult: null,
      query: '',
      showDropdownResults: false,
      isSearching: false,
      isCanceledSearch: false,
      isTooLongQuery: false,
      searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
      selectedResult: null,
    };
  },
  computed: {
    hasResult() {
      const s = this.searchResult;
      return (
        s &&
        (s.latestVisited.length > 0 ||
          s.albums.length > 0 ||
          s.recordings.length > 0 ||
          s.mainartists.length > 0 ||
          s.performers.length > 0 ||
          s.associates.length > 0 ||
          s.societies.length > 0)
      );
    },
  },
  watch: {
    showDropdownResults(val) {
      if (val) {
        document.body.classList.add('overlay-active');
      } else {
        document.body.classList.remove('overlay-active');
      }
    },
  },
  beforeCreate() {
    SearchHelper.handleUpgradeLatestVisited();
  },
  methods: {
    quickSearchStyle() {
      return {
        'max-height': `${document.documentElement.clientHeight - 70}px`,
      };
    },
    search(terms) {
      this.query = terms;
      if (SearchHelper.isQueryTooLong(this.query)) {
        this.isTooLongQuery = true;
        this.searchResult = null;
        return;
      }
      this.isTooLongQuery = false;
      this.isSearching = true;
      SearchHelper.quickSearch(terms)
        .then((result) => {
          this.isSearching = false;
          this.selectedResult = null;
          this.searchResult = result;
          if (!this.isCanceledSearch) {
            this.openSearchResults();
          } else {
            this.isCanceledSearch = false;
          }
        })
        .catch((error) => {
          if (error.type === 'MIN_CHAR') {
            this.searchResult = SearchHelper.fetchLatestVisited();
          } else {
            console.log(error);
          }
        });
    },
    handleEnter() {
      if (!this.selectedResult) {
        this.toAdvancedSearchResultPage();
      } else {
        SearchHelper.addToLatestVisited(this.selectedResult);
        this.$router.push({
          name: routeMappings[this.selectedResult.type],
          params: { id: this.selectedResult.id },
        });

        this.closeSearchResults();
      }
    },
    toAdvancedSearchResultPage() {
      this.closeSearchResults();
      this.isCanceledSearch = true;
      if (this.query !== '') {
        this.$router.push({
          name: 'advancedSearchEverythingQueryView',
          params: { query: this.query },
          query: { i: new Date().getTime() },
        });
        localStorage.setItem('activeRoute', '/search/everything');
      } else {
        this.$router.push({
          name: 'advancedSearchEverythingView',
        });
        localStorage.setItem('activeRoute', '/search/everything');
      }
    },
    openSearchResults() {
      document.body.classList.remove('overlay-active');
      if (!this.showDropdownResults && this.query.length < 3) {
        this.searchResult = SearchHelper.fetchLatestVisited();
      }
      if (this.searchResult) {
        this.showDropdownResults = true;
      }
    },
    closeSearchResults() {
      document.body.classList.remove('overlay-active');
      this.showDropdownResults = false;
      this.selectedResult = null;
    },
    selectPreviousResult() {
      const pos = this.findSelectedResultPosition();
      if (pos > 0) {
        this.selectedResult = this.searchResult.enumerated[pos - 1];
      } else {
        this.closeSearchResults();
      }
    },
    selectNextResult() {
      const pos = this.findSelectedResultPosition();
      if (this.searchResult.enumerated.length - 1 > pos) {
        this.selectedResult = this.searchResult.enumerated[pos + 1];
      }
    },
    findSelectedResultPosition() {
      const needle = this.selectedResult ? this.selectedResult.id : 0;
      const haystack = this.searchResult ? this.searchResult.enumerated : [];
      return haystack.findIndex((x) => x.id === needle);
    },
    resultDisplayName(result) {
      if (result.type === 'performer') {
        return `${result.first_name || ''} ${result.last_name || ''}`;
      } else if (['recording', 'mainartist', 'album'].includes(result.type)) {
        return `${result.name || ''}`;
      }
      return '';
    },
    onSelectedResult(result) {
      this.selectedResult = result;
    },
  },
};
</script>
