<template>
  <div class="left-content-column">
    <entity-tag />
    <div>
      <ul>
        <li v-for="(view, idx) in views" :key="idx" class="list-item">
          <router-link
            :exact="view.exactActiveRoute || false"
            :to="{ name: view.name || view, params: resolveParams(view) }"
          >
            <span>
              {{ view.title || getRouteTitle(view) }}
            </span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EntityTag from './ui/entity-tag';
import RoutesMixin from '../common/routesMixin';

export default {
  name: 'EntityNavigation',
  components: {
    EntityTag,
  },
  mixins: [RoutesMixin],
  props: {
    views: {
      type: Array, // Array of view names or objects
      default: () => [],
    },
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
    };
  },
  watch: {
    $route() {
      this.id = this.$router.currentRoute.params.id;
    },
  },
  methods: {
    resolveParams(view) {
      return Object.assign({}, { id: this.id }, view.params);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  a {
    display: block;
    min-height: 34px;
    color: #3b3b3b;
    padding: 8px 0 8px 24px;
    margin-left: -24px;

    &:hover {
      background: rgba(232, 232, 232, 0.5);
    }

    &.router-link-active {
      background-color: #e8e8e8;
      font-weight: bold;
    }
  }
}
</style>
