<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Member Information</h2>
      </div>
      <div class="col s5 text--right edit-link">
        <RefreshButton
          :disabled="ipdState.state === 'Pending'"
          :is-loading="ipdState.state === 'Pending'"
          label="UPLOAD TO IPD"
          @click="ipdUpload"
        />
      </div>
      <div class="col s1 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit"></i>
          Edit
        </a>
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <label class="col s3">Main artists</label>
        <div class="col s9">
          <span v-for="(mainArtist, index) in mainArtists" :key="mainArtist.id">
            <router-link :to="'/mainartists/' + mainArtist.id">
              {{ mainArtist.name }}
              <span v-if="index + 1 !== mainArtists.length">,</span>
            </router-link>
          </span>
          <span v-if="mainArtists.length === 0" class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Main instrument</label>
        <div class="col s9">
          <span>{{ $filters.formatInstrument(mainInstrument) }}</span>
          <span v-if="!mainInstrument" class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">Secondary instruments</label>
        <div class="col s9">
          <span v-for="(secondaryInstrument, index) in secondaryInstruments" :key="index">
            {{ $filters.formatInstrument(secondaryInstrument) }}
            <span v-if="index + 1 !== secondaryInstruments.length">,</span>
          </span>
          <span v-if="secondaryInstruments.length === 0" class="none">None</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">IPN</label>
        <div class="col s9">
          <span>{{ ipn }}</span>
          <span v-if="!ipn" class="none">None</span>
        </div>
      </div>
      <div class="row flush--bottom">
        <label class="col s3">Voting right</label>
        <div class="col s9">
          <span>{{ $filters.yesno(votingRight) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { commit } from '../../../../store/modules/performer/utils';
import RefreshButton from '../../../ui/button/refresh-button';
import PerformerService from '../../../../services/performerService';

export default {
  name: 'MemberInformation',
  components: {
    RefreshButton,
  },
  computed: mapGetters('performer', [
    'id',
    'mainArtists',
    'mainInstrument',
    'secondaryInstruments',
    'ipn',
    'ipdState',
    'votingRight',
  ]),
  methods: {
    async ipdUpload() {
      try {
        const result = await PerformerService.ipdUpload(this.id);

        if (!result.state) {
          this.$addStarError({
            title: 'Cant upload performer to IPD',
            message:
              'This might be due to incomplete information or performer is not a SAMI memeber.',
          });
        }

        commit('updateIpdState', result);
      } catch (error) {
        this.$addStarError({
          title: 'Ooops!',
          type: `${error.statusCode} - ${error.code}`,
          message: error.message,
        });
      }
    },
  },
};
</script>
