<template>
  <div>
    <h3>{{ list.title }}</h3>
    <p />
    <div class="contact-info">
      <div>
        <b>Sender:</b>
        <span>{{ list.contact.name }}</span>
      </div>
      <div>
        <b>Email:</b>
        <a :href="`mailto:${list.contact.email}`">{{ list.contact.email }}</a>
      </div>
      <div>
        <b>Phone:</b>
        <a :href="`tel:${list.contact.phone}`">{{ list.contact.phone }}</a>
      </div>
    </div>

    <p v-if="list.comment">
      <b>Note:</b>
      <span>{{ list.comment }}</span>
    </p>

    <p>
      <b>Main artist:</b>
      <span>{{ list.groupOrArtistName }}</span>
    </p>

    <div v-for="(rec, idx) in list.recordings" :key="idx">
      <div>
        <strong>1:{{ rec.number }} {{ getTitle(rec) }}</strong>
      </div>
      <div v-for="(contr, idx2) of rec.lineup" :key="idx2">
        <router-link v-if="contr.performer" :to="`/performers/${contr.performer.id}/information`">
          <div>{{ getContributorName(contr) }} ({{ contr.role }})</div>
        </router-link>
        <div v-else>{{ getContributorName(contr) }} ({{ contr.role }})</div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { query } from '../../services/apolloRequest';
export default {
  props: {
    list: Object,
    version: Number,
  },
  data() {
    return {
      performerIdx: {},
    };
  },
  created() {
    this.fetchPerformerInfo();
  },
  methods: {
    getTitle(recording) {
      if (recording.versionTitle) {
        return `${recording.title} (${recording.versionTitle})`;
      }
      return recording.title;
    },
    async fetchPerformerInfo() {
      const missingNameIds = new Set();
      this.list.recordings
        .flatMap((rec) => rec.lineup)
        .forEach((contributor) => {
          if (contributor.performer?.id && !contributor.performer?.pseudoName) {
            missingNameIds.add(contributor.performer.id);
          }
        });
      if (missingNameIds.size > 0) {
        const { elasticPerformers } = await query({
          query: gql`
            query ElasticPerformers($ids: [String]!) {
              elasticPerformers(ids: $ids) {
                id
                first_name
                last_name
              }
            }
          `,
          variables: {
            ids: [...missingNameIds],
          },
        });
        this.performerIdx = Object.fromEntries(elasticPerformers.map((p) => [p.id, p]));
      }
    },
    getContributorName(contributor) {
      if (contributor.newPerformer) {
        const { pseudoName, firstName, lastName } = contributor.newPerformer;
        const name = pseudoName || `${firstName} ${lastName}`;
        return `${name} (new)`;
      }
      const { id, pseudoName } = contributor.performer;
      if (pseudoName) {
        return pseudoName;
      }
      const p = this.performerIdx[id];
      return p ? `${p.first_name} ${p.last_name}` : 'Unknown';
    },
  },
};
</script>

<style scoped>
.contact-info {
  margin-bottom: 1rem;
}
b {
  margin-right: 0.3rem;
}
</style>
