import gql from 'graphql-tag';

const getMainArtistPerformers = gql`
  query mainartist($idTerm: String!, $versionTerm: Int) {
    mainArtistAggregate(id: $idTerm, version: $versionTerm) {
      performers {
        id
        name
        protected_identity
        mandates {
          society_code
          type
        }
        instrument
        role
        nationality
        ipn
        pseudo_name
        pseudo_names
        active_period {
          from
          to
        }
      }
    }
  }
`;

const getFullMainArtist = gql`
  query mainartist($idTerm: String!, $versionTerm: Int) {
    mainArtistAggregate(id: $idTerm, version: $versionTerm) {
      id
      version
      basic_info {
        name
        genre
        alternate_names
        active_period {
          to
          from
        }
        origin_country
        origin_city
        profile_description
        other_external_sources {
          source
          url
        }
        local_codes {
          code
          value
        }
        tags
      }
      performers {
        id
      }
      readonly
      merged_with
    }
  }
`;

const mainArtistMatchSearch = gql`
  query mainArtistMatch($name: String!) {
    mainArtistMatch(name: $name) {
      name
      id
    }
  }
`;

export default {
  getFullMainArtist,
  getMainArtistPerformers,
  mainArtistMatchSearch,
};
