import gql from 'graphql-tag';

const handleCommands = gql`
  mutation handleCommands($target: CommandTarget!, $commands: [CommandInput]!) {
    handleCommands(target: $target, commands: $commands) {
      streamId
      error
      command {
        stream_id
        process_id
        timestamp
        type
        version
        payload
        user
      }
    }
  }
`;

const handleCommand = gql`
  mutation handleCommand($target: CommandTarget!, $command: CommandInput!) {
    handleCommand(target: $target, command: $command) {
      processId
    }
  }
`;

export default {
  handleCommands,
  handleCommand,
};
