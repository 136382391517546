<template>
  <div>
    <div class="row small-inner">
      <div class="col s12">
        <h2>Upload new {{ config.name }} File</h2>
      </div>
    </div>

    <div class="card">
      <div class="row">
        <div v-if="config.sources.length > 1" class="col s2">
          <select-input
            v-model="source"
            :empty-select-option="false"
            label="Source"
            name="sources"
            :items="config.sources"
            item-key="name"
            item-value="name"
            translation-prefix="music_report"
          />
        </div>

        <div v-if="config.reportDate" class="col s2">
          <select-date v-model="reportDate" name="reportDate" label="Usage year" />
        </div>

        <div v-if="config.selectChannel" class="col s2">
          <select-input
            v-model="channel"
            name="channel"
            label="Channel"
            :items="channels"
            item-key="code"
            item-value="name"
            :return-object="true"
          />
        </div>

        <div v-if="config.localCode" class="col s2">
          <select-input
            v-model="localCode"
            name="localCodeType"
            label="Local code"
            :items="localCodeTypes"
            item-key="code"
            item-value="name"
            :disabled="localCodeTypes.length === 1"
          />
        </div>

        <div class="col s3">
          <span>
            File to upload
            <span v-if="fileType">
              ({{ fileType }}{{ fileEncoding ? ' ' + fileEncoding : '' }})
            </span>
          </span>
          <div :class="{ disabled: !source }" class="file-input nowrap">
            <input
              id="file"
              type="file"
              :accept="`.${fileType}`"
              :disabled="!source"
              @change="addFile"
            />

            <span v-if="!source">
              <i class="fas fa-ban" />
              No source selectd
            </span>
            <span v-else>
              <i class="far fa-file-alt" />
              {{ file ? file.name : 'Drag your files here or click in this area.' }}
            </span>
          </div>
          <div v-if="config.templateFile">
            <a :href="config.templateFile.url" target="_blank">
              {{ config.templateFile.text }}
            </a>
          </div>
        </div>
      </div>

      <div class="row flush">
        <div class="col offset-s8 s3 margin--top">
          <ProgressBar
            v-if="loading"
            label="Uploading"
            :total="100"
            :processed="progress"
            :show-percent="true"
          />
        </div>

        <div class="col s1 text--right">
          <button
            class="btn secondary abs-top"
            :disabled="!valid || loading"
            @click.prevent="upload"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { query } from '../../services/apolloRequest';
import AuthenticationService from '../../services/authenticationService';
import gql from 'graphql-tag';
import SelectInput from '../ui/select/select-input';
import SelectDate from '../ui/select/select-date';
import ProgressBar from '../ui/progress-bar';
import config from './config';
import DistributionService from '@/services/distributionService';
import remoteFileHelper from '../../common/remoteFileHelper';

export default {
  name: 'UploadReport',
  components: {
    SelectInput,
    SelectDate,
    ProgressBar,
  },
  data() {
    return {
      channel: null,
      channels: [],
      source: null,
      reportDate: null,
      localCode: null,
      fileType: null,
      fileEncoding: null,
      contentType: null,
      file: null,
      loading: false,
      progress: 0,
      localCodeTypes: this.$store.state.appdata.localCodeTypes,
    };
  },
  computed: {
    config() {
      return config[this.$route.params.type];
    },
    valid() {
      return (
        this.source &&
        (!this.config.selectChannel || this.channel) &&
        (!this.config.reportDate || this.reportDate) &&
        this.localCode &&
        this.file
      );
    },
  },
  watch: {
    $route(to, from) {
      if (from.path !== to.path) {
        this.init();
      }
    },
    source() {
      const { localCode, fileType, fileEncoding, contentType } =
        this.config.sources.find((s) => s.name === this.source) || {};

      this.file = null;
      this.fileType = fileType;
      this.fileEncoding = fileEncoding;
      this.contentType = contentType;

      if (localCode) {
        this.localCodeTypes = [{ code: localCode, name: localCode }];
        this.localCode = localCode;
      } else {
        this.localCodeTypes = this.$store.state.appdata.localCodeTypes;
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.config.sources.length === 1) {
        this.source = this.config.sources[0].name;
      } else {
        this.source = null;
      }
      if (this.config.selectChannel) {
        this.fetchChannels();
      }
    },
    async fetchChannels() {
      const result = await DistributionService.getDistributionChannelsCached();
      this.channels = result.channels;
    },
    addFile(event) {
      this.file = event.target.files[0];
    },
    async upload() {
      this.progress = 0;
      this.loading = true;
      const processId = uuidv4();
      const metadata = {
        processId,
        source: this.source,
        localCode: this.localCode,
        reportDate: this.reportDate,
        uploadedBy: AuthenticationService.getUserName(),
        uploadedDate: new Date().toISOString(),
        channel: this.channel.code,
      };
      const fileNameCleaned = remoteFileHelper.cleanFileName(this.file.name);
      try {
        this.$store.dispatch('process/addProcess', {
          name: `${this.config.name} - ${this.file.name}`,
          id: processId,
        });
        const uploadUrl = await query(
          {
            query: gql`
              query ($source: MusicReportSource!, $filename: String!, $metadata: JSON) {
                getMusicReportUploadUrl(source: $source, filename: $filename, metadata: $metadata)
              }
            `,
            variables: {
              source: this.source,
              filename: fileNameCleaned,
              metadata,
            },
          },
          { pickProp: 'getMusicReportUploadUrl' },
        );

        await Vue.axios.create().put(uploadUrl, this.file, {
          headers: {
            'content-type': this.contentType,
            ...metadata,
          },
          onUploadProgress: (p) => (this.progress = Math.round((p.loaded * 100) / p.total)),
        });

        this.channel = null;
        this.reportDate = null;
        this.localCode = null;
        this.file = null;
        this.init();
      } catch (err) {
        this.$store.dispatch('process/removeProcess', processId);
        this.$addStarError({ title: 'Upload failed', ...err });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
