<template>
  <div class="cmp e2e-create-revenue">
    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>
    <div v-else>
      <div class="cmp">
        <div class="row">
          <div class="col s1 hide-on-med-and-down">
            <entity-tag />
          </div>
          <div class="col s12 l11 view-layout">
            <div class="row wrapper">
              <div class="col s12">
                <h1>Create Revenue</h1>
              </div>
            </div>

            <div class="row inner">
              <div class="col s6">
                <h2>Revenue</h2>
              </div>
            </div>

            <form :data-vv-scope="scope" class="pos-relative card" @keyup.esc="cancel">
              <create-menu
                entity-type="revenue"
                :cancel-route-name="viewRouteName"
                @create="createRevenue"
              />

              <create-basic-information :incoming-category="category" :scope="scope" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { dispatch, getAggregate } from '../../../store/modules/revenue/utils';
import EntityTag from '../../ui/entity-tag';
import CreateMenu from '../../ui/create-menu';
import CreateBasicInformation from './create-basic-information';
import DistributionService from '../../../services/distributionService';
import Spinner from './../../spinner';
import RemoteFileHelper from '../../../common/remoteFileHelper';
import { DistributionCategory } from '../../../domain/distributionDomain';

export default {
  name: 'CreateRevenue',
  components: {
    EntityTag,
    CreateBasicInformation,
    CreateMenu,
    Spinner,
  },
  $_veeValidate: {
    validator: 'new',
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name) {
        vm.viewRouteName = from.name;
        vm.category =
          from.name === 'revenuesViewDomestic'
            ? DistributionCategory.DOMESTIC
            : DistributionCategory.INTERNATIONAL;
      }
    });
  },
  data() {
    return {
      scope: 'create-revenue',
      loading: false,
      error: false,
      savedInitialAggregate: null,
      viewRouteName: 'revenuesViewDomestic',
      category: DistributionCategory.DOMESTIC,
    };
  },
  computed: {
    ...mapGetters('revenue', ['specification']),
  },
  created() {
    dispatch('createAggregate');
  },
  methods: {
    async createRevenue() {
      this.loading = true;
      this.error = false;
      const aggregate = _.cloneDeep(getAggregate());
      try {
        if (aggregate.statementFileHandle && aggregate.statementFileHandle.name) {
          await RemoteFileHelper.uploadStatement(aggregate);
        }
        if (aggregate.attachmentFileHandles && aggregate.attachmentFileHandles.length > 0) {
          await RemoteFileHelper.uploadAttachments(aggregate);
        }
        await DistributionService.createOrUpdateRevenue(aggregate);
        setTimeout(() => {
          this.loading = false;
          this.$router.push({
            name: this.viewRouteName,
          });
        }, 1000);
      } catch (error) {
        this.loading = false;
        error.title = 'Oops!';
        this.$addStarError(error);
      }
    },
  },
};
</script>
