<template>
  <div class="compare__action-btns">
    <performer-candidate-selection
      :candidates="row.matchingDetails.hits"
      @newCandidate="onNewCandidate"
      @candidateChanged="onCandidateChanged"
    />

    <div class="compare__buttons">
      <div>
        <button class="btn green" @click="onDisburse">Disburse</button>
        <button class="btn secondary" @click="onRefund">Refund</button>
      </div>
    </div>
  </div>
</template>

<script>
import PerformerCandidateSelection from './performer-candidate-selection';

export default {
  name: 'CompareButtonsMatching',
  components: {
    PerformerCandidateSelection,
  },
  props: {
    row: {
      type: Object,
    },
  },
  methods: {
    onCandidateChanged(candidate) {
      this.$emit('candidateChanged', candidate);
    },
    onNewCandidate(candidate) {
      this.$emit('newCandidate', candidate);
    },
    onDisburse() {
      this.$emit('disburse', this.row);
    },
    onRefund() {
      this.$emit('refund', this.row);
    },
  },
};
</script>
