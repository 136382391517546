<template>
  <form class="cmp" @keyup.esc="cancel">
    <div v-if="editMode" class="row">
      <div class="col s12">
        <action-buttons class="float-right" @save="update" @cancel="cancel" />
      </div>
    </div>
    <div class="row card">
      <div class="row">
        <div v-if="editMode" class="col s2">Name of album</div>
        <div class="col" :class="editMode ? 's7' : 's8'">
          <validated-text-input
            ref="name"
            :placeholder="placeHolderName"
            :value="name"
            :autofocus="true"
            :label="editMode ? '' : 'Name of album'"
            :mandatory="editMode ? false : true"
            name="basic_info.name"
            rule="required"
            @input="_updateBasicInfoName"
          />
        </div>
      </div>
      <div class="row">
        <div v-if="editMode" class="col s2">Alternate names</div>
        <div class="col" :class="editMode ? 's7' : 's8'">
          <tag-input
            ref="alternate_titles"
            name="basic_info.alternate_titles"
            :label="editMode ? '' : 'Alternate names'"
            :placeholder="placeHolderAlternateTitle"
            :value-list="alternate_titles"
            @add="addToBasicInfoAlternateTitles"
            @remove="removeFromBasicInfoAlternateTitles"
          />
        </div>
      </div>
      <div class="row">
        <div v-if="editMode" class="col s2">Main artist name</div>
        <div class="col" :class="editMode ? 's7' : 's8'">
          <main-artist-input
            ref="mainArtist"
            :placeholder="placeHolderMainArtist"
            :value="main_artist"
            :mandatory="true"
            :label="editMode ? '' : 'Main artist name'"
            name="basic_info.main_artist"
            :show-label="editMode ? false : true"
            @input="_updateBasicInfoMainArtist"
          />
        </div>
      </div>
      <div class="row">
        <div v-if="editMode" class="col s2">Release date</div>
        <div class="col" :class="editMode ? 's7' : 's8'">
          <select-date
            name="basic_info.release_date"
            :label="editMode ? '' : 'Release date'"
            :value="release_date"
            @input="updateBasicInfoReleaseDate"
          />
        </div>
      </div>
      <div class="row">
        <div v-if="editMode" class="col s2">Label</div>
        <div class="col" :class="editMode ? 's7' : 's8'">
          <validated-text-input
            :placeholder="placeHolderLabel"
            :value="label"
            :label="editMode ? '' : 'Label'"
            name="basic_info.label"
            @input="updateBasicInfoLabel"
          />
        </div>
      </div>
      <div class="row">
        <div v-if="editMode" class="col s2">Catalogue number</div>
        <div class="col" :class="editMode ? 's7' : 's8'">
          <validated-text-input
            :placeholder="placeHolderCatalogNumber"
            :value="catalog_number"
            :label="editMode ? '' : 'Catalogue number'"
            name="basic_info.catalog_number"
            @input="updateBasicInfoCatalogNumber"
          />
        </div>
      </div>
      <div v-if="editMode" class="row flush--bottom">
        <div class="col s2">Local code</div>
        <div class="col" :class="editMode ? 's7' : 's8'">
          <local-codes
            ref="localCodes"
            :entries="localLocalCodes"
            :local-codes="localCodeItems"
            entry-key-name="code"
            entry-value-name="value"
            name="basic_info.local_codes"
            selectable-item-key-name="code"
            selectable-item-value-name="name"
            :show-label="editMode ? false : true"
            @add="addBasicInfoLocalCode"
            @input="_replaceInBasicInfoLocalCodes"
            @remove="_removeFromBasicInfoLocalCodes"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { createLocalCode } from '../../../domain/common';
import ActionButtons from '../../ui/button/action-buttons';
import MainArtistInput from '../../ui/input/main-artist-input';
import LocalCodes from '../../ui/input/local-codes';
import SelectDate from '../../ui/select/select-date';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import TagInput from '../../ui/tag/tag-input';

export default {
  name: 'CreateBasicInfo',
  components: {
    MainArtistInput,
    LocalCodes,
    SelectDate,
    ValidatedTextInput,
    ActionButtons,
    TagInput,
  },
  props: {
    editMode: { type: Boolean, default: false },
  },
  data() {
    return {
      localLocalCodes: [],
      placeHolderCatalogNumber: 'E.g. 123',
      placeHolderComposer: 'E.g. Joakim Berg',
      placeHolderLabel: 'E.g. BMG',
      placeHolderMainArtist: 'E.g. Kent',
      placeHolderName: 'E.g. Du & jag döden',
      placeHolderAlternateTitle: 'E.g. Du och jag döden',
    };
  },
  computed: {
    ...mapState('album', {
      name: (state) => state.basic_info.name,
      alternate_titles: (state) => state.basic_info.alternate_titles,
      main_artist: (state) => state.basic_info.main_artist,
      catalog_number: (state) => state.basic_info.catalog_number,
      release_date: (state) => state.basic_info.release_date,
      composer: (state) => state.basic_info.composer,
      label: (state) => state.basic_info.label,
      local_codes: (state) => state.basic_info.local_codes,
    }),
    localCodeItems() {
      return this.$store.state.appdata.localCodeTypes;
    },
  },
  created() {
    if (!this.main_artist) {
      this.updateBasicInfoMainArtist({});
    }
    this.localLocalCodes = _.clone(this.local_codes);
    if (this.localLocalCodes.length === 0) {
      this.localLocalCodes.push(createLocalCode());
      this.addToBasicInfoLocalCodes(createLocalCode());
    }
  },
  methods: {
    ...mapMutations('album', [
      'updateBasicInfoName',
      'updateBasicInfoMainArtist',
      'updateBasicInfoCatalogNumber',
      'updateBasicInfoReleaseDate',
      'updateBasicInfoComposer',
      'updateBasicInfoLabel',
      'addToBasicInfoLocalCodes',
      'replaceInBasicInfoLocalCodes',
      'removeFromBasicInfoLocalCodes',
      'addToBasicInfoAlternateTitles',
      'removeFromBasicInfoAlternateTitles',
    ]),
    addBasicInfoLocalCode() {
      this.localLocalCodes.push(createLocalCode());
      this.addToBasicInfoLocalCodes(createLocalCode());
    },
    _replaceInBasicInfoLocalCodes(entryUpdate) {
      this.localLocalCodes.splice(entryUpdate.index, 1, entryUpdate.entry);
      this.replaceInBasicInfoLocalCodes({ index: entryUpdate.index, value: entryUpdate.entry });
    },
    _removeFromBasicInfoLocalCodes(index) {
      this.localLocalCodes.splice(index, 1);
      this.removeFromBasicInfoLocalCodes(index);
      if (this.localLocalCodes.length === 0) {
        this.localLocalCodes.push(createLocalCode());
        this.addToBasicInfoLocalCodes(createLocalCode());
      }
    },
    _updateBasicInfoName(value) {
      this.updateBasicInfoName(value);
    },
    _updateBasicInfoMainArtist(value) {
      this.updateBasicInfoMainArtist(value);
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    update() {
      this.$emit('update', this.$options.name);
    },
  },
};
</script>
