import gql from 'graphql-tag';

const getUploadUrl = gql`
  query getUploadUrl(
    $fileName: String
    $repo: String
    $directory: String
    $metadata: [S3Metadata]
  ) {
    getUploadUrl(fileName: $fileName, repo: $repo, directory: $directory, metadata: $metadata)
  }
`;

const getFetchUrl = gql`
  query getFetchUrl($fileName: String, $bucket: String) {
    getFetchUrl(fileName: $fileName, bucket: $bucket)
  }
`;

const getDownloadManyUrl = gql`
  query getDownloadManyUrl($filesEntry: MultiFileEntry) {
    downloadMany(filesEntry: $filesEntry)
  }
`;

const getInvoiceFetchUrl = gql`
  query getInvoiceFetchUrl($fileName: String) {
    getInvoiceFetchUrl(fileName: $fileName)
  }
`;

const getEndOfYearStatementFileFetchUrl = gql`
  query getEndOfYearStatementFileFetchUrl($fileName: String) {
    getEndOfYearStatementFileFetchUrl(fileName: $fileName)
  }
`;

const getStimFileFetchUrl = gql`
  query getStimFileFetchUrl($fileName: String) {
    getStimFileFetchUrl(fileName: $fileName)
  }
`;

const getStatementFetchUrl = gql`
  query getStatementFetchUrl($fileName: String) {
    getStatementFetchUrl(fileName: $fileName)
  }
`;

const getMissingReportersFetchUrl = gql`
  query getMissingReportersFetchUrl($fileName: String) {
    getMissingReportersFetchUrl(fileName: $fileName)
  }
`;

const getFeedbackFetchUrl = gql`
  query getFeedbackFetchUrl($fileName: String) {
    getFeedbackFetchUrl(fileName: $fileName)
  }
`;

const listFiles = gql`
  query listFiles($prefix: String, $bucket: String) {
    listFiles(prefix: $prefix, bucket: $bucket) {
      path
      filename
      fileType
      lastModified
      size
      entityType
      streamId
      uploadedBy
      metadata
    }
  }
`;

const deleteFile = gql`
  mutation deleteFile($filename: String) {
    deleteFile(filename: $filename)
  }
`;

const updateMetadata = gql`
  mutation updateMetadata($command: UpdateMetadataCommand) {
    updateMetadata(command: $command)
  }
`;

const createVotingRightsFile = gql`
  mutation createVotingRightsFile($processId: String!) {
    createVotingRightsFile(processId: $processId)
  }
`;

export default {
  getUploadUrl,
  getFetchUrl,
  getDownloadManyUrl,
  getInvoiceFetchUrl,
  getEndOfYearStatementFileFetchUrl,
  listFiles,
  deleteFile,
  getStatementFetchUrl,
  getMissingReportersFetchUrl,
  getFeedbackFetchUrl,
  getStimFileFetchUrl,
  updateMetadata,
  createVotingRightsFile,
};
