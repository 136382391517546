<template>
  <div class="right-column row">
    <div class="col s12">
      <div class="tabs">
        <div class="tabs__wrapper">
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'general' }"
            @click="selectedTab = 'general'"
          >
            General
          </div>
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'activity' }"
            @click="selectedTab = 'activity'"
          >
            Activity
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'general'">
        <status-information
          :edit-mode="isEditMode('status-information')"
          :edit-disabled="lineupLocked"
          :status="distributionStateStatus"
          :vrdb2-sync-state="vrdb2State.sync_state"
          @edit="onEdit('status-information')"
          @cancel="onCancel"
          @statuschange="onStatusChange"
        />

        <vrdb2-info v-bind="vrdb2State" @enqueue="onEnqueue" />

        <div class="row">
          <div class="col s12">
            <h2>Redistribution</h2>
            <p>
              <button class="btn" @click="addToRedist">
                <span>Redistribute now</span>
                <simple-spinner v-if="redistLoading" />
              </button>
            </p>
          </div>
        </div>

        <tags v-if="!isEditMode('Tags')" :tags="basicInfoTags" @edit="onEdit" />
        <edit-tags v-else @cancel="onCancel" @error="onError" />

        <div class="row">
          <div class="col s12">
            <h2>SAMI Lists</h2>
            <p>
              <SamiListList :entity-id="id" entity-type="recording" />
            </p>
          </div>
        </div>

        <notes :id="id" />

        <div v-if="!recordingDeleted" class="row">
          <div class="col s12">
            <h2>Delete Recording</h2>
            <p>
              <button class="btn red" @click="deleteRecording">
                <i class="fas fa-times" />
                <span>Delete</span>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <activity :stream-id="id" :version="version" view-name="recordingInformationVersion" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Activity from '../ui/activity';
import EditTags from './edit/edit-tags';
import Notes from '../ui/input/notes';
import StatusInformation from './status-information';
import Vrdb2Info from './vrdb2-info';
import Tags from '../tags/tags';
import SimpleSpinner from '../ui/simple-spinner.vue';
import SamiListList from '../sami-list/sami-list-list.vue';

export default {
  name: 'RightColumn',
  components: {
    Activity,
    EditTags,
    Notes,
    StatusInformation,
    Tags,
    Vrdb2Info,
    SimpleSpinner,
    SamiListList,
  },
  props: {
    editableComponent: {
      type: String,
      default: '',
    },
    distributionStateStatus: {
      type: String,
      default: undefined,
    },
    preSelectedTab: {
      type: String,
      default: 'general',
    },
    redistLoading: {
      type: Boolean,
      default: false,
    },
    recordingDeleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTab: this.preSelectedTab,
    };
  },
  computed: {
    ...mapGetters('recording', ['id', 'version', 'basicInfoTags', 'vrdb2State', 'lineupLocked']),
  },
  watch: {
    preSelectedTab(tab) {
      this.selectedTab = tab;
    },
  },
  methods: {
    onEdit(view) {
      this.$emit('onEdit', view);
    },
    onCancel() {
      this.$emit('onCancel');
    },
    onError(error) {
      this.$emit('onError', error);
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onStatusChange(status) {
      this.$emit('onStatusChange', status);
    },
    onEnqueue() {
      this.$emit('onVrdb2Enqueue');
    },
    addToRedist() {
      this.$emit('addToRedist');
    },
    deleteRecording() {
      this.$emit('deleteRecording');
    },
  },
};
</script>
