<template>
  <div>
    <label v-if="showLabel" class="label" :class="{ 'label--error': showErrorMessage }">
      {{ label }}
      <span v-if="mandatory" class="mandatory">(mandatory)</span>
    </label>
    <input
      ref="inputField"
      v-validate="computedRule"
      autocomplete="off"
      type="text"
      :data-vv-as="label.toLowerCase()"
      :name="name"
      :value="value"
      :placeholder="orgno ? 'XXXXXX-XXXX' : 'YYYYMMDD-XXXX'"
      :disabled="disabled"
      @input="updateValue($event.target.value)"
      @keydown.enter.prevent
    />
    <span v-show="showErrorMessage" class="help is-danger">
      <i class="fas fa-times-circle" />
      {{ validationErrors.first(name, scope) }}
    </span>
  </div>
</template>

<script>
import ValidationPatterns from '../../../common/validationPatterns';

export default {
  name: 'SsnInput',
  inject: ['$validator'],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    orgno: { type: Boolean, default: false },
    validationConfig: {
      type: Object,
      default: () => ({
        ssnUnique: false,
      }),
    },
    mandatory: { type: Boolean, default: false },
    name: String,
    scope: { type: String, default: undefined },
    rule: {
      type: Object,
      default: () => {},
    },
    type: String,
    value: [Number, String],
  },
  data() {
    return {
      currentValue: null,
    };
  },
  computed: {
    showErrorMessage() {
      if (this.validationErrors.has(this.name, this.scope)) {
        return true;
      }
      const field = this.validationFields[this.name];
      return field && field.touched && field.invalid;
    },
    computedRule() {
      return Object.assign(
        {},
        {
          ssn: true,
          ssnUnique: this.validationConfig.ssnUnique,
          luhn: true,
        },
        this.rule,
      );
    },
  },
  created() {
    if (this.value) {
      if (Number.isInteger(this.value)) {
        this.currentValue = Number(this.value);
      } else {
        this.currentValue = this.value.slice(0);
      }
    }
  },
  methods: {
    updateValue(_value) {
      let value = _value;
      if (value.length >= 10) {
        value = value.replace(/\D+/g, '');
        value = `${value.slice(0, 8)}-${value.slice(8)}`;
        if (!value.match(ValidationPatterns.SSN)) {
          // Don't format to something invalid!
          value = _value;
        }
        this.$nextTick(() => {
          this.$validator.validate(this.scope ? `${this.scope}.${this.name}` : this.name, value);
        });
      }
      this.currentValue = value;
      this.$emit('input', this.currentValue);
    },
  },
};
</script>
