<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>SDEG Collecting and Claiming</h1>
      </div>
    </div>

    <div class="tabs row">
      <div class="tabs__wrapper">
        <router-link :to="'/integrations/sdeg/collecting'">SAMI Collecting</router-link>
        <router-link :to="'/integrations/sdeg/played-recordings'">SAMI Claiming</router-link>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ViewSdegOverview',
  data() {
    return {
      viewType: this.getViewType(),
    };
  },
  watch: {
    $route() {
      this.viewType = this.getViewType();
    },
  },
  methods: {
    getViewType() {
      return this.$router.currentRoute.name === 'sdegPlayedRecordingsOverview'
        ? 'claiming'
        : 'collecting';
    },
  },
};
</script>
