<template>
  <div class="view-member-overview">
    <div class="row">
      <div class="col s12">
        <h1>Member Files</h1>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col s12">
          <h2>Member Update</h2>
        </div>
      </div>

      <div v-if="completedUploadFilename" class="row card">
        <span class="text--bold">Upload was successful. Star will now update the catalogue.</span>
        <br />
        [ File:
        <i>{{ completedUploadFilename }}</i>
        ]
      </div>

      <div v-show="isUploadingFile && !error" class="row card">
        Uploading... {{ uploadFile.uploadProgress }} %
        <div class="progress">
          <div ref="uploadProgressBar" class="determinate" style="width: 0%" />
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <select-input
            name="uploadSociety"
            label="Select society"
            :value="uploadFileSocietyCode"
            :items="selectableSocieties"
            item-value="name"
            item-key="code"
            @input="(val) => (uploadFileSocietyCode = val)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <file-upload-simple-field
            ref="fileUploadField"
            :disabled="!uploadFileSocietyCode"
            label="Upload file"
            name="documentRefId"
            accept-file-type=".csv"
            @addFile="updateFile"
            @removeFile="onRemoveFile"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <button
            class="btn secondary abs-top"
            type="submit"
            :disabled="isUploadingFile || !uploadFile.handle"
            @click.prevent="upload"
          >
            Upload
          </button>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="row">
        <div class="col s12">
          <h2>Generate Member File</h2>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <select-input
            name="generateSociety"
            label="Select society"
            :value="generateFileSocietyId"
            :items="selectableSocieties"
            item-value="name"
            item-key="id"
            @input="(val) => (generateFileSocietyId = val)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <button
            :disabled="!generateFileSocietyId"
            class="btn"
            type="submit"
            @click.prevent="generateMemberFile"
          >
            Create
          </button>
        </div>
      </div>
      <div v-if="generatingMemberFiles.length" class="row">
        <div v-for="society in generatingMemberFiles" :key="society.id">
          - Generating member file for society {{ society.name }}. When completed it can be found
          under
          <router-link :to="'/societies/' + society.id + '/member'">
            society member files.
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import FileUploadSimpleField from '../ui/file/file-upload-simple-field';
import SelectInput from '../ui/select/select-input';
import FileHandleMixin from '../../common/fileHandleMixin';
import AuthenticationService from '../../services/authenticationService';
import SocietyService from '../../services/societyService';
import RemoteFileService from '../../services/remoteFileService';
import CreateUploadFileDescriptor from '../../domain/uploadFileDescriptor';
import { getConfigValue, PROPS } from '../../services/configService';
import MandateService from '../../services/mandateService';

async function uploadFile(fileHandle, progressUpdatedFunc, selectedSociety, processId) {
  const progressFunc = (progressEvent) =>
    progressUpdatedFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  const metaIdentifiers = [fileHandle.name.slice(0, -4)];
  const fileDescriptor = CreateUploadFileDescriptor(
    'MEMBER_UPLOAD',
    'STAR',
    metaIdentifiers,
    fileHandle,
  );
  const bucket = getConfigValue(PROPS.DATA_BUCKET);
  const options = {
    repo: bucket,
    directory: `mandates/inbox/${selectedSociety}`,
    metadata: [
      {
        key: 'user',
        value: String(AuthenticationService.getUserName()),
      },
      {
        key: 'Content-Type',
        value: 'text/plain; charset=iso-8859-1',
      },
      {
        key: 'process_id',
        value: processId,
      },
    ],
    progressFunc,
  };
  await RemoteFileService.uploadToDocumentRepository(fileDescriptor, options);
}

export default {
  name: 'ViewOtherMemberOverview',
  components: {
    FileUploadSimpleField,
    SelectInput,
  },
  mixins: [FileHandleMixin],
  data() {
    return {
      viewRouteName: 'otherMemberOverview',
      documentRefId: `${this.name}_document`,
      uploadFile: { handle: undefined, uploadProgress: 0, selectedSociety: undefined },
      isUploadingFile: false,
      completedUploadFilename: undefined,
      generateFileSocietyId: undefined,
      uploadFileSocietyCode: undefined,
      selectableSocieties: [],
      generatingMemberFiles: [],
    };
  },
  watch: {
    uploadFile: {
      handler(uploader) {
        this.$refs.uploadProgressBar.style.width = `${uploader.uploadProgress}%`;
      },
      deep: true,
    },
  },
  created() {
    SocietyService.getSocieties().then((soc) => {
      this.selectableSocieties = soc
        .filter((s) => s.meta_info.code !== 'SAMI' && s.meta_info.create_mandate === true)
        .map(({ meta_info, basic_info, id }) => ({
          code: meta_info.code,
          name: basic_info.name,
          id,
        }));
    });
  },
  methods: {
    async upload() {
      this.isUploadingFile = true;
      this.error = false;
      const processId = uuid.v4();
      try {
        this.uploadFile.uploadProgress = 0;
        const soc = this.selectableSocieties.find(
          (societies) => societies.code === this.uploadFileSocietyCode,
        );
        this.$store.dispatch('process/addProcess', {
          name: `Mandate Import - ${soc.name}`,
          id: processId,
        });
        await uploadFile(
          this.uploadFile.handle,
          (progressInPercent) => (this.uploadFile.uploadProgress = progressInPercent),
          soc.code,
          processId,
        );
        this.completedUploadFilename = String(this.uploadFile.handle.name);
        this.resetFileUpload();
      } catch (error) {
        this.handleError('Upload failed', error);
        this.$store.dispatch('process/removeProcess', processId);
      } finally {
        this.isUploadingFile = false;
      }
    },
    handleError(title, error) {
      this.$addStarError(error);
    },
    async generateMemberFile() {
      const processId = uuid.v4();
      const soc = this.selectableSocieties.find((s) => s.id === this.generateFileSocietyId);
      await MandateService.createMandateFile(soc.code, processId);
      this.generatingMemberFiles.push(soc);
      this.$store.dispatch('process/addProcess', {
        name: `Mandate Export - ${soc.name}`,
        id: processId,
        route: `/societies/${soc.id}/member`,
      });
      this.generateFileSocietyId = undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
.view-member-overview {
  .custom-select {
    width: 200px;
  }
}
</style>
