<template>
  <div class="cmp">
    <div class="row">
      <div class="col s6">
        <h2>Tracks</h2>
      </div>
      <template v-if="editMode">
        <div class="col s6 text--right">
          <action-buttons @save="onSave" @cancel="onCancel" />
        </div>
      </template>
    </div>

    <div class="card">
      <div class="row number-of-tracks" :class="{ 'flush--bottom': tracks.length === 0 }">
        <div class="col s12">
          <label>Number of tracks:</label>
          <select v-model="nbrToAdd">
            <option v-for="i in 10" :key="i" :value="i">
              {{ i }}
            </option>
          </select>
          <button type="button" class="btn" @click="addXTracks()">Create</button>
        </div>
      </div>

      <form>
        <track-row
          v-for="(track, index) in tracks"
          :key="track._localId"
          :ref="track._localId"
          :name="track._localId"
          :track="tracks[index]"
          @delete="onDelete(index)"
          @input="onInput($event, index)"
        />
      </form>

      <div v-show="showAddTrackBtn" class="row flush--bottom">
        <div class="col s4 edit-link">
          <a @click="addOneTrack">
            <i class="far fa-plus-square" />
            Add track
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import * as uuid from 'uuid';
import { mapMutations, mapGetters } from 'vuex';
import { createTrack } from './../../../domain/albumDomain';
import TrackRow from './track-row';
import ActionButtons from '../../ui/button/action-buttons';

export default {
  name: 'CreateTracks',
  components: {
    TrackRow,
    ActionButtons,
  },
  inject: ['$validator'],
  props: {
    editMode: { type: Boolean, default: false },
  },
  data() {
    return {
      nbrToAdd: 1,
      showAddTrackBtn: false,
    };
  },
  computed: {
    ...mapGetters('album', ['tracks']),
  },
  watch: {
    tracks() {
      this.setLocalIds();
      if (this.tracks.length === 0) {
        this.showAddTrackBtn = false;
      }
    },
  },
  mounted() {
    this.setLocalIds();
  },
  methods: {
    ...mapMutations('album', ['addToTracks', 'removeFromTracks', 'replaceInTracks']),
    setLocalIds() {
      this.tracks.forEach((track) => {
        if (!track._localId) {
          track._localId = uuid.v4();
        }
      });
    },
    onInput(track, index) {
      this.replaceInTracks({ index, value: _.cloneDeep(track) });
    },
    onDelete(index) {
      this.removeFromTracks(index);
    },
    addOneTrack() {
      this.addXTracks(1);
    },
    addXTracks(x) {
      this.showAddTrackBtn = true;
      // eslint-disable-next-line
        Array.apply(null, Array(x || this.nbrToAdd))
        .map(createTrack)
        .forEach((t) => this.addToTracks(t));
      this.nbrToAdd = 1;
    },
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    onSave() {
      this.$emit('update', this.$options.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.number-of-tracks {
  line-height: 30px;

  label {
    float: left;
    margin-right: 5px;
  }
  select {
    float: left;
    width: var(--spacing-five-times);
    margin-right: 5px;
  }
  button {
    float: left;
  }
}
</style>
