import _ from 'lodash';
import Fraction from 'fraction.js';
import { isValidIBAN, isValidBIC } from 'ibantools';
import luhn from 'luhn';
import moment from 'moment';
import ValidationPatterns from '@/common/validationPatterns';
import { isValidAccount, isValidClearingNumber } from '@/common/bankAccountUtils';

export const VeeValidateConfig = {
  errorBagName: 'validationErrors',
  fieldsBagName: 'validationFields',
  delay: 0,
  locale: 'en',
  dictionary: null,
  strict: false,
  classes: true,
  classNames: {
    touched: 'touched', // the control has been blurred
    untouched: 'untouched', // the control hasn't been blurred
    valid: 'valid', // model is valid
    invalid: 'invalid', // model is invalid
    pristine: 'pristine', // control has not been interacted with
    dirty: 'dirty', // control has been interacted with
  },
  events: 'input|blur',
  inject: false,
  validity: false,
  aria: true,
};

export function applyCustomRules(instance) {
  instance.Validator.extend('luhn', {
    getMessage(label) {
      return `The ${label} field has to be valid.`;
    },
    validate(value) {
      return luhn.validate(value.substring(2).replace(/\D+/g, ''));
    },
  });

  instance.Validator.extend('requiredValue', {
    getMessage(label) {
      return `The ${label} field is required.`;
    },
    validate(value, args) {
      return !_.isEmpty(args[0]);
    },
  });

  instance.Validator.extend('requiredTag', {
    getMessage(label) {
      return `The ${label} field is required.`;
    },
    validate(value, valueList) {
      return valueList.length > 0;
    },
  });

  instance.Validator.extend('reserved', {
    getMessage() {
      return 'The value is reserved by the system and cannot be added.';
    },
    validate(value, reserved) {
      return reserved.indexOf(value) === -1;
    },
  });

  instance.Validator.extend('uniqueRoleCode', {
    getMessage() {
      return 'The role code mapping must be unique';
    },
    validate(value, validationData) {
      const existingMappings = validationData.existingMappings;
      const newMapping = validationData.newMapping;

      // if empty code, set code to type (FA/NF)
      const code = newMapping.code ? newMapping.code : newMapping.type;

      const existingMappingsByNewMappingCode = _.mapKeys(existingMappings, (v, k) =>
        k.toLowerCase(),
      )[code.toLowerCase()];

      if (existingMappingsByNewMappingCode && existingMappingsByNewMappingCode.length > 0) {
        // if mapping with given code exist, type cannot be ALL
        if (newMapping.type === 'ALL') {
          return false;
        }
        // mapping with given code and type NONE already exists
        if (
          existingMappingsByNewMappingCode.filter((roleCode) => roleCode.type === 'ALL').length > 0
        ) {
          return false;
        }
        return (
          existingMappingsByNewMappingCode.find((roleCode) => roleCode.type === newMapping.type) ===
          undefined
        );
      }
      return true;
    },
  });

  instance.Validator.extend('paymentReceiverAllowedOnChild', {
    getMessage() {
      return 'Recipient of money is not allowed in combination with payment info.';
    },
    validate(value, payment) {
      if (payment && payment.length > 0) {
        const noPaymentInfoExists =
          (payment[0].private.bank === null || payment[0].private.bank === '') &&
          (payment[0].foreign.account_holder === null ||
            payment[0].foreign.account_holder === '') &&
          (payment[0].foreign.account_number === null ||
            payment[0].foreign.account_number === '') &&
          (payment[0].foreign.iban_number === null || payment[0].foreign.iban_number === '') &&
          (payment[0].foreign.bic_swift_number === null ||
            payment[0].foreign.bic_swift_number === '');
        return noPaymentInfoExists;
      }
      return true;
    },
  });

  instance.Validator.extend('paymentReceiverAllowedOnParent', {
    getMessage() {
      return "Can't add an associate with payment info, or no available share";
    },
    validate(value, data) {
      if (value) {
        return !!data.has_payment_info;
      }
      return true;
    },
  });

  instance.Validator.extend('amount', {
    getMessage(label) {
      return `The ${label} field must be a valid amount.`;
    },
    validate(value) {
      return ValidationPatterns.AMOUNT.test(value);
    },
  });

  instance.Validator.extend('negative_or_positive_amount', {
    getMessage(label) {
      return `The ${label} field must be a valid amount.`;
    },
    validate(value) {
      return ValidationPatterns.NEGATIVE_OR_POSITIVE_AMOUNT.test(value);
    },
  });

  instance.Validator.extend('negative_amount', {
    getMessage(label) {
      return `The ${label} field must be a negative amount.`;
    },
    validate(value) {
      return ValidationPatterns.NEGATIVE_AMOUNT.test(value);
    },
  });

  instance.Validator.extend('positive_amount', {
    getMessage(label) {
      return `The ${label} field must be a positive amount.`;
    },
    validate(value) {
      return ValidationPatterns.AMOUNT.test(value);
    },
  });

  instance.Validator.extend('year', {
    getMessage(label) {
      return `The ${label} field must be a valid year.`;
    },
    validate(value) {
      return ValidationPatterns.YEAR.test(value);
    },
  });

  instance.Validator.extend('isrc', {
    getMessage(label) {
      return `The ${label} field must be a valid ISRC number.`;
    },
    validate(value) {
      return ValidationPatterns.ISRC.test(value);
    },
  });

  instance.Validator.extend('phonenumber', {
    getMessage(label) {
      return `The ${label} field must be a valid phone number.`;
    },
    validate(value) {
      return ValidationPatterns.PHONE_NUMBER.test(value);
    },
  });

  instance.Validator.extend('ssn', {
    getMessage(label) {
      return `The ${label} field must be a valid Social Security/Organisation number.`;
    },
    validate(value) {
      return ValidationPatterns.SSN.test(value);
    },
  });

  instance.Validator.extend('ssnUnique', {
    getMessage(label, config, data) {
      return `The ${label} field is already taken by ${data.performer_name} (${data.performer_id})`;
    },
    validate(value, config) {
      if (config && config.originalValue && config.originalValue === value) {
        return true;
      }
      return config.validator(value);
    },
  });

  instance.Validator.extend('gironumber', {
    getMessage(label) {
      return `The ${label} field must be a valid giro number.`;
    },
    validate(value) {
      const cleanedValue = value.replace(/ /g, '').replace(/-/g, '');
      return ValidationPatterns.GIRO_NUMBER.test(cleanedValue);
    },
  });

  instance.Validator.extend('clearingnumber', {
    getMessage(label) {
      return `The ${label} field must be a valid clearing number.`;
    },
    validate(value) {
      const cleanedValue = value.replace(/ /g, '').replace(/-/g, '');
      return isValidClearingNumber(cleanedValue);
    },
  });

  instance.Validator.extend('accountbyclearing', {
    getMessage(label) {
      return `The ${label} field must be a valid bank account number and match the clearing number.`;
    },
    validate(value, [clearingNbr]) {
      const cleanedValue = value.replace(/ /g, '').replace(/-/g, '');
      return isValidAccount(clearingNbr, cleanedValue);
    },
  });

  instance.Validator.extend('iban', {
    getMessage(label) {
      return `The ${label} field must be a valid IBAN number.`;
    },
    validate(value) {
      const cleanedValue = value.replace(/ /g, '').replace(/-/g, '');
      return isValidIBAN(cleanedValue);
    },
  });

  instance.Validator.extend('ipn', {
    getMessage(label) {
      return `The ${label} field must be a valid IPN number.`;
    },
    validate(value) {
      return ValidationPatterns.IPN.test(value);
    },
  });

  instance.Validator.extend('bicswiftnumber', {
    getMessage(label) {
      return `The ${label} field must be a valid BIC/SWIFT number.`;
    },
    validate(value) {
      return isValidBIC(value);
    },
  });

  instance.Validator.extend('isZero', {
    getMessage(label) {
      return `The ${label} field must be exactly 0.00.`;
    },
    validate(value) {
      return parseFloat(value) === 0.0;
    },
  });

  instance.Validator.extend('accountNumberRestriction', {
    getMessage() {
      return 'Account number must not occur at the same time as IBAN.';
    },
    validate(value) {
      return value === '';
    },
  });

  instance.Validator.extend('ibanRestriction', {
    getMessage() {
      return 'IBAN must not occur at the same time as account number or routing number.';
    },
    validate(value) {
      return value === '';
    },
  });

  instance.Validator.extend('routingNumberRestriction', {
    getMessage() {
      return 'Routing number must not occur at the same time as IBAN.';
    },
    validate(value) {
      return value === '';
    },
  });

  instance.Validator.extend('routingnumber_long', {
    getMessage(label) {
      return `The ${label} field must contain exactly 9 digits.`;
    },
    validate(value) {
      return ValidationPatterns.ROUTING_LONG.test(value);
    },
  });

  instance.Validator.extend('routingnumber_short', {
    getMessage(label) {
      return `The ${label} field must contain exactly 6 digits.`;
    },
    validate(value) {
      return ValidationPatterns.ROUTING_SHORT.test(value);
    },
  });

  instance.Validator.extend('ddex_party_id', {
    getMessage() {
      return `DDEX Partt ID must follow the pattren: 'PADPIDAxxxxxxxxxxx'`;
    },
    validate(value) {
      return ValidationPatterns.DDEX_PARTY_ID.test(value);
    },
  });

  instance.Validator.extend('vatnumber', {
    getMessage(label, args) {
      const countryCode = args[0];

      switch (countryCode) {
        case 'SE':
          return "Swedish VAT numbers need to follow the pattern: 'SEnnnnnnnnnn01'";
        default:
          return `The ${label} field must be a valid VAT starting with two characters.`;
      }
    },
    validate(value, args) {
      const key = `VAT_${args[0]}`;
      const pattern = ValidationPatterns[key] || ValidationPatterns.VAT;

      return pattern.test(value);
    },
  });

  instance.Validator.extend('tin', {
    getMessage(label) {
      return `The ${label} field must be a valid TIN of 10 digits.`;
    },
    validate(value) {
      return ValidationPatterns.TIN.test(value);
    },
  });

  instance.Validator.extend('org_no', {
    getMessage(label) {
      return `The ${label} field must be a valid organisation number of 10 digits.`;
    },
    validate(value) {
      return ValidationPatterns.ORG_NO.test(value);
    },
  });

  instance.Validator.extend('unique_org_no', {
    getMessage() {
      return 'There is already an associate with this Social Security/Organisation number.';
    },
    validate(value, config) {
      return config.validator(value, config.type, config.streamId);
    },
  });

  instance.Validator.extend('share', {
    getMessage(label) {
      return `The ${label} a valid share (a fraction or number greater than 0 and lower than 1).`;
    },
    validate(value) {
      let fractionValue;
      try {
        fractionValue = Fraction(value);
      } catch (e) {
        return false;
      }
      if (fractionValue > 1 || fractionValue <= 0) {
        return false;
      }
      return true;
    },
  });

  instance.Validator.extend('performer_name', {
    getMessage(label) {
      return `${label} contains an invalid character`;
    },
    validate(value) {
      return ValidationPatterns.PERFORMER_NAME.test(value);
    },
  });

  instance.Validator.extend('same_or_after', {
    getMessage(label, config) {
      return `The ${label} must be same or after ${config[0]}`;
    },
    validate(value, config) {
      return moment(value).isSameOrAfter(config[0]);
    },
  });

  instance.Validator.extend('unique_ipd_code', {
    getMessage() {
      return 'This Ipd code is already used by another society';
    },
    validate(value, config) {
      return !config.map((society) => society.ipd_code).includes(value);
    },
  });

  instance.Validator.extend('one_of_optional', {
    getMessage(label, args, { value }) {
      return `${value} is not valid ${label}`;
    },
    validate(value, args) {
      const key = args[0];
      const values = args[1];

      return {
        valid: !value || values.findIndex((v) => v[key] === value) !== -1,
        data: {
          value,
        },
      };
    },
  });

  return instance;
}
