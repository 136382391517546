export const distributionClosureState = {
  Created: 0,
  Approved: 1,
  Confirmed: 2,
  Removed: 3,
  Error: 4,
  Ready: 5,
};

export const distributionClosureRowState = {};
