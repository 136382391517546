/**
 * Mixin for aggregates
 */
export default {
  methods: {
    routeAggregateId() {
      return this.$router.currentRoute.params.id;
    },
    routeVersion() {
      return this.$router.currentRoute.params.version
        ? Number(this.$router.currentRoute.params.version)
        : null;
    },
    async loadAggregate(fetchFunc, id = this.routeAggregateId(), version = this.routeVersion()) {
      this.loading = true;
      this.error = false;
      return fetchFunc(id, version);
    },
  },
};
