<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2 class="float-left">Age and Living</h2>
      </div>
    </div>

    <div class="card">
      <div class="row small-inner">
        <div class="col s4">
          <select-date
            name="general_info.date_of_birth"
            :scope="scope"
            label="Date of birth"
            :value="date_of_birth"
            @input="updateDateOfBirth"
          />
        </div>
        <div v-show="date_of_death || dateOfDeathActive" class="col s4">
          <select-date
            name="general_info.date_of_death"
            :scope="scope"
            label="Date of death"
            :rule="!!date_of_birth ? { after: date_of_birth } : {}"
            :value="date_of_death"
            @input="updateDateOfDeath"
          />
        </div>
      </div>

      <div v-show="!dateOfDeathActive" class="row">
        <div class="col s12 edit-link">
          <a @click="dateOfDeathActive = 'true'">
            <i class="far fa-plus-square" />
            Add date of death
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <input
            id="general_info.collective"
            name="general_info.collective"
            type="checkbox"
            class="filled-in"
            :checked="collective"
            @change="updateCollective($event.target.checked)"
          />
          <label for="general_info.collective">Collective</label>
        </div>
      </div>

      <div v-if="!collective" class="row">
        <div class="col s8 create__age-sex">
          <label class="label" :class="{ 'label--error': validationErrors.has('sex', scope) }">
            Gender
            <span class="mandatory">(mandatory)</span>
          </label>
          <div>
            <input
              id="female"
              v-validate="'required'"
              type="radio"
              value="F"
              name="sex"
              :checked="sex === 'F'"
              @change="updateSex($event.target.value)"
            />
            <label for="female" class="margin--right">Female</label>

            <input
              id="male"
              v-validate="'required'"
              type="radio"
              value="M"
              name="sex"
              :checked="sex === 'M'"
              @change="updateSex($event.target.value)"
            />
            <label for="male" class="margin--right">Male</label>

            <input
              id="diverse"
              v-validate="'required'"
              type="radio"
              value="X"
              name="sex"
              :checked="sex === 'X'"
              @change="updateSex($event.target.value)"
            />
            <label for="diverse">Diverse</label>
          </div>
          <span v-show="validationErrors.has('sex', scope)" class="help is-danger float-left">
            <i class="fas fa-times-circle" />
            {{ validationErrors.first('sex', scope) }}
          </span>
        </div>
      </div>

      <div v-else class="row">
        <div class="col s8">
          <validated-text-input
            name="general_info.collective_size"
            label="Collective size"
            placeholder="15"
            :scope="scope"
            :value="collective_size"
            :mandatory="true"
            :rule="{ required: true, numeric: true, min_value: 15 }"
            @input="updateCollectiveSize"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <select-country
            class="e2e-nationality"
            name="general_info.nationality"
            label="Nationality"
            :value="nationality"
            :scope="scope"
            :mandatory="true"
            rule="required"
            @input="updateNationality"
          />
        </div>
      </div>

      <div class="row flush--bottom">
        <div class="col s8">
          <select-country
            class="e2e-country-of-residence"
            name="general_info.country_of_residence"
            label="Country of residence"
            :value="country_of_residence"
            :scope="scope"
            :mandatory="true"
            rule="required"
            @input="updateCountryOfResidence"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import ValidationPatterns from '../../../../common/validationPatterns';
import ValidatedTextInput from '../../../ui/input/validated-text-input';
import SelectDate from '../../../ui/select/select-date';
import SelectCountry from '../../../ui/select/select-country';

export default {
  name: 'CreateAgeLivingInformation',
  components: {
    SelectCountry,
    SelectDate,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      vuexWatchHandles: [],
      dateOfDeathActive: false,
    };
  },
  computed: {
    ...mapState('performer', {
      date_of_birth: (state) => state.general_info.date_of_birth,
      date_of_death: (state) => state.general_info.date_of_death,
      sex: (state) => state.general_info.sex,
      nationality: (state) => state.general_info.nationality,
      country_of_residence: (state) => state.general_info.country_of_residence,
      collective: (state) => state.general_info.collective,
      collective_size: (state) => state.general_info.collective_size,
    }),
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  mounted() {
    const ssnWatch = this.$store.watch(
      (state) => state.performer.general_info.social_security_number,
      (dateOfBirth) => {
        if (ValidationPatterns.SSN_RELAXED.test(dateOfBirth)) {
          this.updateDateOfBirth(moment(dateOfBirth, 'YYYYMMDD').format('YYYY-MM-DD'));
        }
      },
    );
    this.vuexWatchHandles.push(ssnWatch);

    const countryWatch = this.$store.watch(
      (state) => state.performer.contact_info.address.country,
      (newCountry) => {
        this.updateNationality(newCountry);
        this.updateCountryOfResidence(newCountry);
      },
    );
    this.vuexWatchHandles.push(countryWatch);
  },
  destroyed() {
    this.vuexWatchHandles.forEach((handle) => handle());
  },
  methods: {
    ...mapMutations('performer', [
      'updateDateOfBirth',
      'updateDateOfDeath',
      'updateSex',
      'updateNationality',
      'updateCountryOfResidence',
      'updateCollective',
      'updateCollectiveSize',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.label {
  margin-right: 20px;
}
</style>
