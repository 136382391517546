<template>
  <div class="compare__action-btns">
    <template v-if="line.incoming.match_state === 'M'">
      <div class="compare__buttons hc">
        <button class="btn red m2" @click="handleUnidentifyBtn(line.incoming, undefined, 'R')">
          Unidentify
        </button>
        <button
          class="btn primary m2"
          :disabled="!(currentCandidate && currentCandidate.id)"
          @click="handleSaveBtn(line.incoming.group_id, currentCandidate.id, line.dirty)"
        >
          {{ line.dirty ? 'Save & Confirm' : 'Confirm' }}
        </button>
      </div>
    </template>
    <template v-else>
      <candidate-selection :is-album="isAlbum" @candidateChanged="candidateChanged" />
      <div class="compare__buttons hc">
        <button
          class="btn primary m2"
          :disabled="!(currentCandidate && currentCandidate.id)"
          @click="handleSaveBtn(line.incoming.group_id, currentCandidate.id, line.dirty)"
        >
          {{ line.dirty ? 'Save & Confirm' : 'Confirm' }}
        </button>
        <button class="btn secondary m2" @click="handleNewBtn(line)">
          {{ isAlbum ? 'New Album' : 'New recording' }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CandidateSelection from '../matching/candidate-selection';

export default {
  name: 'CompareButtonsMediarc',
  components: {
    CandidateSelection,
  },
  props: {
    candidateChanged: {
      type: Function,
    },
    handleUnidentifyBtn: {
      type: Function,
    },
    handleNewBtn: {
      type: Function,
    },
    handleSaveBtn: {
      type: Function,
    },
    line: {
      type: Object,
    },
    isAlbum: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('matching', ['currentCandidate']),
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.hc {
  display: flex;
  justify-content: center;
}
.m2 {
  margin: 2px;
}
</style>
