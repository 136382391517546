import Vue from 'vue';
import { getConfigValue, PROPS } from './configService';

const LOCAL_STORAGE_KEY = 'processes';

export const LOG_LEVEL = {
  ERROR: 50,
  WARNING: 40,
  INFO: 30,
  DEBUG: 20,
};

export const getQuery = (after, level) => {
  const env = getConfigValue(PROPS.ENVIRONMENT);
  let qs = `?env=${env}&limit=300&order=desc`;
  if (typeof after === 'string' && after.length > 0) {
    qs = `${qs}&after=${encodeURIComponent(after)}`;
  }
  if (typeof level === 'number') {
    qs = `${qs}&level=${level}`;
  }
  return qs;
};

export const getProcessesFromLocalStorage = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || [];

export const writeProcessesToLocalStorage = (processes) =>
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(processes));

export const getProcessLogRows = async (processId, level, after) => {
  const qs = getQuery(after, level);
  const res = await Vue.$http.get(`${getConfigValue(PROPS.PROCESS_URL)}/${processId}${qs}`);
  return res.data.items.map((item) => ({
    ...item,
    error: typeof item.error === 'string' ? JSON.parse(item.error) : item.error,
  }));
};

export const getLogRowsByLevel = async (level, after) => {
  const qs = getQuery(after, level);
  const res = await Vue.$http.get(`${getConfigValue(PROPS.PROCESS_URL)}${qs}`);
  return res.data.items;
};

export const aggregateLogRows = (rows) =>
  rows.reduce((map, row) => {
    const existing = map.get(row.process_id) || [];
    map.set(row.process_id, [...existing, row]);
    return map;
  }, new Map());

export const isFinished = (rows) => rows.some((row) => row.finished === true);
