import _ from 'lodash';
import moment from 'moment';

/**
 * Mixin for field comparing logic
 */
export default {
  methods: {
    bothHaveValues(compared, master) {
      return this.hasValue(compared) && this.hasValue(master);
    },

    valuesMatch(compared, master) {
      return _.isEqual(compared, master);
    },

    valuesMatchReplaceSpecialCharacters(first, second) {
      return this.valuesMatch(first.replace('´', "'"), second.replace('´', "'"));
    },

    valuesMatchCompareTokens(first, second) {
      let firstSplitted;
      let secondSplitted;

      if (first.includes(',')) {
        firstSplitted = first.split(',').map((item) => item.trim());
      } else {
        firstSplitted = first.split(' ').map((item) => item.trim());
      }

      if (second.includes(',')) {
        secondSplitted = second.split(',').map((item) => item.trim());
      } else {
        secondSplitted = second.split(' ').map((item) => item.trim());
      }

      return _.isEqual(firstSplitted.sort(), secondSplitted.sort());
    },

    valuesMatchRelaxed(first, second) {
      const firstAsComparable = first ? first.toString().trim().toLowerCase() : '';
      const secondAsComparable = second ? second.toString().trim().toLowerCase() : '';
      if (this.valuesMatch(firstAsComparable, secondAsComparable)) {
        return true;
      } else if (this.valuesMatchReplaceSpecialCharacters(firstAsComparable, secondAsComparable)) {
        return true;
      } else if (this.valuesMatchCompareTokens(firstAsComparable, secondAsComparable)) {
        return true;
      }
      return false;
    },

    yearsMatch(first, second) {
      const firstYear = moment(first, 'YYYY-MM-DD').year();
      const secondYear = moment(second, 'YYYY-MM-DD').year();

      return this.valuesMatch(firstYear, secondYear);
    },

    arrayIncludesValueRelaxed(arr, val) {
      return !!arr.find((el) => this.valuesMatchRelaxed(el, val));
    },
    arraysSame(arr, arr2) {
      return _.isEqual(arr.slice().sort(), arr2.slice().sort());
    },
    arrayIsSubset(first, second) {
      return _.intersection(first, second).length === first.length;
    },
    hasValue(v) {
      return (
        !_.isNull(v) &&
        !_.isUndefined(v) &&
        !(_.isString(v) && v.length === 0) &&
        !(_.isArray(v) && v.length === 0)
      );
    },
  },
};
