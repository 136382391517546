import gql from 'graphql-tag';

const referenceData = gql`
  query referenceData {
    referenceData {
      countries {
        name
        iso
        routing_code
        broadcast
        web
      }

      mandateRights {
        name
        code
      }

      instruments {
        family
        code
        family_name
        name
        disabled
      }

      genres {
        name
        code
        vrdb_code
        sdeg_code
      }

      rolesRecording {
        code
        name
        sdeg_mapping
      }

      localCodes {
        code
        name
        country
        create_mandate
        ipd_name
        ipd_code
        vrdb_code
      }

      documentTypes {
        name
        code
        filter
      }

      banks {
        name
        code
        account_formats {
          clearing_nos
          account_no_max_digits
          ssn_account
          type
        }
      }

      tagsInternal

      ipdSocieties {
        name
        code
      }
    }
  }
`;

export default {
  referenceData,
};
