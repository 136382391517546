<template>
  <validated-text-input
    :name="name"
    :show-label="showLabel"
    :placeholder="placeholder"
    :label="label"
    :scope="scope"
    :rule="computedRule"
    :value="durationStr"
    @input="emitChange"
  />
</template>

<script>
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'DurationInput',
  components: {
    ValidatedTextInput,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: 'duration-input',
    },
    label: {
      type: String,
      default: 'Duration',
    },
    placeholder: {
      type: String,
      default: 'E.g. 01:32:14',
    },
    scope: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    rule: {
      type: Object, // Allow only Object due to computed rule
      default: () => {},
    },
  },

  data() {
    return {
      delimiterChar: ':',
      validationPattern: /^\d{2,3}:\d\d(:\d\d)?$/,
      durationStr: '',
    };
  },
  computed: {
    computedRule() {
      return Object.assign(
        {},
        {
          regex: this.validationPattern,
        },
        this.rule,
      );
    },
  },
  watch: {
    value(val) {
      this.durationStr = this.secondsToString(val);
    },
  },
  created() {
    this.durationStr = this.secondsToString(this.value);
  },
  methods: {
    countDelimiters(str) {
      const matches = str.match(new RegExp(this.delimiterChar, 'g'));
      return (matches && matches.length) || 0;
    },
    secondsToString(seconds) {
      if (!seconds) {
        return null;
      }
      const h = Math.floor(seconds / 3600);
      const m = Math.floor((seconds - h * 3600) / 60);
      const s = Math.round(seconds - h * 3600 - m * 60);
      const out = h > 0 ? [String(h).padStart(2, '0')] : [];
      out.push(String(m).padStart(2, '0'), String(s).padStart(2, '0'));
      return out.join(':');
    },
    stringToSeconds(str) {
      const seconds = str
        // split by colon and reverse so that seconds are processed first
        .split(':')
        .reverse()
        .reduce((sum, val, i) => sum + val * 60 ** i, 0);
      return Number.isNaN(seconds) ? 0 : seconds;
    },
    async validate(val) {
      return !val || this.validationPattern.test(val);
    },
    async emitChange(value) {
      this.durationStr = value;
      const isValid = await this.validate(value);
      if (isValid) {
        const seconds = this.stringToSeconds(value);
        this.$emit('input', seconds > 0 ? seconds : null);
      }
    },
  },
};
</script>
