import * as uuid from 'uuid';

import { createContactInfo } from './common';

import { createPaymentInfo } from './commonPayment';

export const AgreementType = {
  A: 'A',
  B: 'B',
};

export function createBasicInfo(name = null, version = null) {
  return {
    name,
    version,
  };
}

export function createMetaInfo(
  code = null,
  ipdSocietyCode = null,
  ipdSocietyName = null,
  vrdbCode,
  order = null,
  createMandate = null,
  country = null,
  tags = [],
  statement_file_type = null,
  ddex_party_id = null,
) {
  return {
    code,
    IPD_society_code: ipdSocietyCode,
    IPD_society_name: ipdSocietyName,
    vrdb_code: vrdbCode,
    order,
    create_mandate: createMandate,
    country,
    tags,
    statement_file_type,
    ddex_party_id,
  };
}

export function createAgreementContract() {
  return {
    url: '',
  };
}

export function createAgreement(
  id = uuid.v4(),
  name = null,
  fromDate = null,
  toDate = null,
  signDate = null,
  contractUrl = '',
  type = AgreementType.NONE,
  rights = [],
) {
  return {
    id,
    name,
    from_date: fromDate,
    to_date: toDate,
    sign_date: signDate,
    contract: {
      url: contractUrl,
    },
    type,
    rights,
  };
}

export function createRoleCodes(incoming = null, outgoing = null) {
  return {
    incoming,
    outgoing,
  };
}

export function createSocietyAggregate() {
  return {
    id: null,
    version: null,
    process_id: null,
    stream_id: uuid.v4(),
    timestamp: null,
    basic_info: createBasicInfo(),
    contact_info: createContactInfo(),
    payment_info: createPaymentInfo(),
    agreements: [],
    role_codes: createRoleCodes(),
    meta_info: createMetaInfo(),
    documents: [],
  };
}

export function createUpdateBasicInfoCommand(id = uuid.v4()) {
  return {
    type: 'society:basicInfo:update',
    version: null,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      name: null,
    },
  };
}

export function createUpdateMetaInfoCommand(id = uuid.v4()) {
  return {
    type: 'society:metaInfo:update',
    version: null,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      IPD_society_code: null,
      IPD_society_name: null,
      vrdb_code: null,
      code: null,
      create_mandate: null,
      order: null,
      country: null,
      tags: [],
      statement_file_type: null,
      ddex_party_id: null,
    },
  };
}

export function createUpdateRoleCodesCommand(id, version) {
  return {
    type: 'society:roleCodes:update',
    version,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: createRoleCodes(),
  };
}

export function createSocietyAggregateCommand(id = uuid.v4()) {
  return {
    type: 'society:create',
    version: 1,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      basic_info: null,
      contact_info: null,
      payment_info: null,
      agreements: [],
      role_codes: createRoleCodes(),
      meta_info: null,
    },
  };
}

export function createAddAgreementCommand(id = uuid.v4()) {
  return {
    type: 'society:agreements:add',
    version: 1,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      agreement: null,
    },
  };
}

export function createRemoveAgreementCommand(id = uuid.v4()) {
  return {
    type: 'society:agreements:remove',
    version: 1,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      agreement_id: null,
    },
  };
}

export function createUpdateAgreementCommand(id = uuid.v4()) {
  return {
    type: 'society:agreements:update',
    version: 1,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      agreement: null,
    },
  };
}
