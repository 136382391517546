import gql from 'graphql-tag';

const jobs = gql`
  query {
    jobs {
      id
      type
      state
      payload
      date_created
      date_changed
      user
      service
    }
  }
`;

export default {
  jobs,
};
