export default {
  COUNTRY_CODE: /^[A-Z]{2}$/,
  AMOUNT: /^\d+(.\d{2})?$/,
  NEGATIVE_OR_POSITIVE_AMOUNT: /^-?\d+(.\d{2})?$/,
  NEGATIVE_AMOUNT: /^-\d+(.\d{2})?$/,
  DATE: /^(18|19|20)?(\d{2})-(0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-9]|3[0-1])$/,
  UNKNOWN_PROTECTED_DATE: /^4000-01-01$/,
  GIRO_NUMBER: /^\d{7,8}$/,
  INSTRUMENT_CODE: /^[a-z]{3}$/,
  ISRC: /^[a-z]{2}(\w){3}(\d){2}(\d){5}$/i,
  PHONE_NUMBER: /^\+?[\d\s]{5,}$/,
  ROUTING_LONG: /^\d{9}$/,
  ROUTING_SHORT: /^\d{6}$/,
  SSN: /^(\d{8}-\d{4})$/,
  SSN_RELAXED: /^(18|19|20)?(\d{6})-?(\d{4})$/,
  UUID: /[a-f0-9]{8}-?[a-f0-9]{4}-?[1-5][a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/,
  YEAR: /^(18|19|20)(\d{2})$/,
  ZULU: /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/,
  IPN: /^1[0-9]{7}$/,
  VAT: /^([a-zA-Z]){2}(.+)$/,
  VAT_SE: /^SE\d{10}01$/,
  ORG_NO: /^\d{6}-\d{4}$/,
  TIN: /.*/,
  PERFORMER_NAME: /^[^0-9§°!"#€%&/()=?+¢\\{}$@*_:.,;<>]*$/,
  PUBLIC_ID: /^\d{2,}$/,
  DDEX_PARTY_ID: /^PADPIDA\d{10}[A-Z0-9]$/,
};
