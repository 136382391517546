<template>
  <div class="filter__wrapper">
    <div
      v-for="(tag, index) in tags"
      :key="index"
      :class="{ chip: true, selected: tagSelected(tag) }"
      @click="onClick(tag)"
    >
      {{ tag }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterTags',
  props: {
    tags: { type: Array, default: () => [] },
    allTag: { type: String, default: 'All' },
    activeTab: { type: String, default: '' },
  },
  data() {
    return {
      selectedTags: [],
    };
  },
  computed: {},
  watch: {
    activeTab() {
      this.selectedTags = [];
      this.selectedTags.push(this.allTag);
    },
  },
  created() {
    this.selectedTags.push(this.allTag);
  },
  methods: {
    tagSelected(tag) {
      return this.selectedTags.indexOf(tag) !== -1;
    },
    onClick(tag) {
      if (tag === this.allTag) {
        this.selectedTags = [this.allTag];
        this.$emit('filterAll');
      } else {
        const idx = this.selectedTags.indexOf(tag);
        if (idx !== -1) {
          this.selectedTags.splice(idx, 1);
        } else {
          this.selectedTags.push(tag);
        }
        if (this.selectedTags.length === 0) {
          this.selectedTags.push(this.allTag);
          this.$emit('filterAll');
        } else if (this.selectedTags.indexOf(this.allTag) !== -1) {
          this.selectedTags.splice(this.selectedTags.indexOf(this.allTag), 1);
          this.$emit('filter', this.selectedTags);
        } else {
          this.$emit('filter', this.selectedTags);
        }
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.filter__wrapper {
  display: flex;
  flex-wrap: wrap;
}
</style>
