<template>
  <div>
    <div class="row text--right edit-link">
      <div class="col s12">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>
    <div v-show="organisationInfoOrgNo" class="row card">
      <div class="row">
        <label class="col s3">Organisation number</label>
        <div class="col s9">
          <span>{{ organisationInfoOrgNo }}</span>
        </div>
      </div>

      <view-local-codes :local-codes="localCodes" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewLocalCodes from '../../ui/view-local-codes';

export default {
  name: 'OrganisationInformation',
  components: {
    ViewLocalCodes,
  },
  computed: mapGetters('associate', ['organisationInfoOrgNo', 'localCodes']),
};
</script>
