<template>
  <div class="cronjob">
    <div class="cronjob-header">
      <h3 class="cronjob-name">
        {{ name }}
      </h3>
      <div class="cronjob-action">
        <toggle-switch
          :id="name"
          class="cronjob-switch"
          :name="name"
          :on="enabled"
          @change="onChange"
        />
        <button v-if="trigger" class="btn red" @click="showTriggerModal = true">TRIGGER</button>
      </div>
    </div>
    <p class="cronjob-description">
      {{ description }}
    </p>
    <div class="cronjob-schedule">
      <i class="fa fa-clock" />
      {{ schedule }}
    </div>

    <modal
      v-if="showTriggerModal"
      submit-label="Yes"
      @save="onTrigger()"
      @close="showTriggerModal = false"
      @cancel="showTriggerModal = false"
    >
      <h1 slot="header">Manual trigger</h1>
      <div slot="body">Do you manually want to trigger job {{ name }}?</div>
    </modal>
  </div>
</template>

<script>
import Modal from '../ui/dialog/modal';
import ToggleSwitch from '../ui/toggle-switch';

export default {
  name: 'CronjobListItem',
  components: {
    ToggleSwitch,
    Modal,
  },
  props: {
    name: String,
    enabled: Boolean,
    schedule: String,
    description: String,
    trigger: Object,
  },
  data() {
    return {
      showTriggerModal: false,
    };
  },
  methods: {
    onChange(state) {
      this.$emit('stateChange', state);
    },
    onTrigger() {
      this.showTriggerModal = false;
      this.$emit('trigger');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.cronjob-header {
  display: flex;
  flex: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.3rem;
}
.cronjob-switch {
  margin-right: 1rem;
}
.cronjob {
  margin-bottom: 2rem;
  max-width: 44rem;
}
.cronjob-action {
  display: flex;
  align-items: center;
  width: 12rem;
}
.cronjob-schedule {
  color: var(--grey--light);
  .fa {
    margin-right: 0.5rem;
  }
}
</style>
