import { mutate, query } from './apolloRequest';
import gql from '../domain/ipdGql';

export default {
  getDownloadFiles: async (request) =>
    query(
      { query: gql.getDownloadFiles, variables: { request } },
      { pickProp: 'getDownloadFiles' },
    ),
  getDownloadRightHolders: async (request) =>
    query(
      { query: gql.getDownloadRightHolders, variables: { request } },
      { pickProp: 'getDownloadRightHolders' },
    ),
  createDownloadFiles: async (command) =>
    mutate(
      { mutation: gql.createDownloadFiles, variables: { command } },
      { pickProp: 'createDownloadFiles' },
    ),
};
