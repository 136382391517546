<template>
  <form class="card">
    <div class="row">
      <div class="col s12">
        <calculate-selector :loading="loading" @calculate="onCalculate" />
      </div>
    </div>
    <template v-if="waitingForDistClosureToExist">
      <spinner label="Waiting for distribution closure to be created..." />
    </template>
    <template v-else-if="loading">
      <spinner />
    </template>
  </form>
</template>

<script>
import CalculateSelector from './calculate-selector';
import Spinner from '../../spinner';
import CommandService from '@/services/commandService';
import PaymentService from '@/services/paymentService';
import * as uuid from 'uuid';

export default {
  name: 'ProgressDistributionClosureCreate',
  components: {
    CalculateSelector,
    Spinner,
  },
  inject: ['$validator'],
  data() {
    return {
      processId: null,
      streamId: null,
      loading: true,
      waitingForDistClosureToExist: false,
    };
  },
  created() {
    this.checkIfActiveExists();
  },
  methods: {
    async onCalculate(year) {
      try {
        this.loading = true;
        this.streamId = uuid.v4();
        await CommandService.handleCommand('payment', {
          type: 'closure/create',
          stream_id: this.streamId,
          payload: { year },
        });
        this.waitingForDistClosureToExist = true;
        await this.waitForDistClosureToExist(this.streamId);
        this.waitingForDistClosureToExist = false;
        await this.$router.push({
          name: 'distributionClosures',
          query: {
            id: this.streamId,
          },
        });
      } catch (e) {
        this.$addStarError(e);
      }
    },
    async waitForDistClosureToExist(id) {
      let closure = null;

      while (!closure?.header) {
        closure = await PaymentService.getDistributionClosure(id);

        if (!closure?.header) {
          await new Promise((resolve) => setTimeout(resolve, 2500));
        }
      }
    },
    async checkIfActiveExists() {
      const { distributionClosureId } = await PaymentService.getActiveDistributionClosure();
      if (distributionClosureId !== null) {
        this.$router.push({
          name: 'distributionClosures',
          query: {
            id: distributionClosureId,
          },
        });
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
