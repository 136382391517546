import _ from 'lodash';
import DistributionService from '../../services/distributionService';

async function getChannelsByGroup() {
  const response = await DistributionService.getDistributionChannels();
  const channels = response.channels;

  return {
    flat: response.channels,
    groupless: channels.filter((channel) => !channel.channel_group),
    groups: _.groupBy(
      channels.filter((channel) => channel.channel_group),
      'channel_group',
    ),
  };
}

async function getIncompleteRecordings(limit, page, sortTerms, filterTerms) {
  const request = {
    limit,
    page,
  };
  if (filterTerms.year) {
    request.year = Number(filterTerms.year);
  }
  if (filterTerms.country) {
    request.country = filterTerms.country;
  }
  if (filterTerms.issue_type) {
    request.issue_type = filterTerms.issue_type;
  }
  if (filterTerms.worklist_id) {
    request.worklist_id = filterTerms.worklist_id;
  }
  if (filterTerms.vrdb_id) {
    request.vrdb_id = filterTerms.vrdb_id;
  }
  if (filterTerms.web) {
    request.web = filterTerms.web;
  }
  if (filterTerms.range_start) {
    request.range_start = filterTerms.range_start;
  }
  if (filterTerms.range_end) {
    request.range_end = filterTerms.range_end;
  }
  if (filterTerms.channel) {
    request.channel = filterTerms.channel;
  }
  if (sortTerms.param) {
    request.order_by = sortTerms.param;
  }
  if (sortTerms.order) {
    request.sort_order = sortTerms.order;
  }
  return DistributionService.getIncompleteRecordings(request);
}

export default {
  getChannelsByGroup,
  getIncompleteRecordings,
};
