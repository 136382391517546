<template>
  <div class="progress">
    <div
      v-for="(step, index) in progressionSteps"
      :key="index"
      class="progress__bullet-section"
      :class="{ 'current-or-less': index <= currentProgressIndex }"
      :style="{
        left: `${index * (100 / (progressionSteps.length - 1))}%`,
        width: `${100 / (progressionSteps.length - 1)}%`,
      }"
    >
      <div class="progress__bullet" />
      <div class="progress__bullet-label">{{ index + 1 }}. {{ step.label }}</div>
      <div v-if="index > 0" class="progress__midline" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BulletProgress',
  components: {},
  props: {
    currentProgress: {
      type: Object,
      required: true,
    },
    progressionSteps: {
      type: Array,
      required: true,
    },
  },
  computed: {
    currentProgressIndex() {
      return this.progressionSteps.findIndex((el) => el.name === this.currentProgress.name);
    },
  },
};
</script>

<!--<style lang="scss" scoped>-->
<!--.progress__bullet-section {-->
<!--  overflow-x: hidden;-->
<!--}-->
<!--</style>-->
