/**
 * Mixin for route logic
 */
import { AssociateType } from '../domain/associateDomain';

export default {
  data() {
    return {
      routes: this.$router.options.routes
        .map((route) => (route.children ? [route, ...route.children] : [route]))
        .reduce((acc, cur) => acc.concat(cur), []),
    };
  },
  methods: {
    getRoute(viewName) {
      return this.routes.find((view) => view.name === viewName);
    },
    getRouteTitle(viewName) {
      return this.getRoute(viewName).meta.name;
    },
    getRouteBaseForEntity(type) {
      let rootRoutePart;
      if (Object.values(AssociateType).includes(type.toUpperCase())) {
        rootRoutePart = 'associates';
      } else if (type.toUpperCase() === 'SOCIETY') {
        rootRoutePart = 'societies';
      } else {
        rootRoutePart = `${type.toLowerCase()}s`;
      }
      return rootRoutePart;
    },
  },
};
