<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>

    <div class="row">
      <div class="col s12">
        <TableOptions
          :countries="!isSdeg ? recordedInCountries : []"
          :bulk-action-enabled="anySelected()"
          :bulk-actions="bulkEditActions"
          :recorded-in-country-code="recordedInCountryCode"
          :custom-select="isSdeg ? samiMemberSelect : null"
          :range="range"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
          @countryChanged="onCountryChanged"
          @bulkAction="onBulkAction"
          @customSelectChanged="onCustomSelectChange"
        />
      </div>
    </div>
    <div v-show="!loading" class="row card">
      <div class="col s12">
        <modal
          v-if="showIdentifyAllModal"
          :hide-overflow="false"
          :disable-submit="!currentCandidate || !currentCandidate.id"
          @close="showIdentifyAllModal = false"
          @cancel="showIdentifyAllModal = false"
          @save="onBulkIdentify"
        >
          <h1 slot="header">Identify all</h1>
          <candidate-selection
            slot="body"
            :is-album="isAlbum"
            @candidateChanged="candidateChanged"
          />
        </modal>

        <table>
          <CompareSort
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareListMatching
            ref="comparelist"
            :sdeg="isSdeg"
            :album="isAlbum"
            :show-sami-member="true"
            :candidate-changed="candidateChanged"
            :get-candidate-value="getCandidateValue"
            :handle-identify-btn="handleIdentifyBtn"
            :handle-ignore-btn="handleIgnoreBtn"
            :handle-unmatch-btn="handleUnmatchBtn"
            :handle-new-recording="newRecordingFromButton"
            :handle-save-btn="handleSaveBtn"
            :is-expanded="isExpanded"
            :revert-match="revertMatch"
            :toggle-expanded="toggleExpanded"
            @change="updateDirtyState"
          />
        </table>

        <div class="result__pagination">
          <pagination
            v-if="numberOfPages() > 0"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="totalCount"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import MusicReportHelper from './musicReportHelper';
import Pagination from '../pagination';
import PaginationAndSortMixin from '../../common/paginationAndSortMixin';
import TableOptionsMixin from '../../common/tableOptionsMixin';
import TableOptions from '../ui/table/table-options';
import ViewMusicReportMixin from './view-music-report-mixin';
import CompareSort from '../compare/compare-sort';
import CompareListMatching from '../compare/compare-list-matching';
import Modal from '../ui/dialog/modal';
import CandidateSelection from './candidate-selection';

export default {
  name: 'ViewMusicReportCandidateLines',
  components: {
    CompareListMatching,
    CompareSort,
    Pagination,
    TableOptions,
    Modal,
    CandidateSelection,
  },
  mixins: [
    DebouncedSearchMixin(50),
    PaginationAndSortMixin,
    TableOptionsMixin,
    ViewMusicReportMixin,
  ],
  data() {
    return {
      worklistId: null,
      lineupStateMask: 'C',
      bulkEditActions: [{ key: 'identify', value: 'Identify' }],
      showIdentifyAllModal: false,
      samiMemberSelect: {
        name: 'SAMI member',
        selected: null,
        items: [{ key: true, name: 'Yes' }],
        itemKey: 'key',
        itemValue: 'name',
      },
    };
  },
  created() {
    this.clearFilter('ViewMusicReportCandidateLines');
  },
  async activated() {
    const expandedId = this.viewMusicReportCandidateLines.expandedId;
    await this.loadPaginated();
    this.recordedInCountries = MusicReportHelper.getCountries();
    if (this.$route.query.worklist_id) {
      this.fetchData();
    }
    if (expandedId) {
      this.toggleExpanded(expandedId);
    }
  },
  deactivated() {
    this.updateViewMusicReportCandidateLinesExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
  methods: {
    async onBulkAction(value) {
      switch (value) {
        case 'identify': {
          this.setExpanded(undefined);
          this.clearCurrentCandidate();
          const hits = [
            ...new Set(
              this.reportLines.filter((rl) => rl.checked).flatMap((rl) => rl.incoming.hits),
            ),
          ];
          await this.fetchCandidates(hits);
          this.showIdentifyAllModal = true;
          break;
        }
        default:
          console.log('Unknown bulk action');
          break;
      }
    },
    async newRecordingFromButton(line) {
      await this.createNewRecordings(this.reportId, [line.incoming]);
    },
    async onBulkIdentify() {
      this.showIdentifyAllModal = false;
      await this.identifyOnCheckedItems();
    },
  },
};
</script>
