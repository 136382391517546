<template>
  <div>
    <select-input
      :name="name"
      :rule="{ required: required }"
      :label="label"
      :big-label="bigLabel"
      :value="year"
      :items="selectableYears"
      :disabled="disabled"
      :empty-select-option="emptySelectOption"
      item-key="key"
      item-value="value"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from './select-input';

const currentYear = new Date().getFullYear();

export default {
  name: 'SelectYear',
  components: { SelectInput },
  props: {
    value: { type: String, default: currentYear.toString() },
    years: { type: Array, default: () => [] },
    name: { type: String, default: 'selectYear' },
    required: { type: Boolean, default: true },
    label: { type: String, default: 'Year' },
    bigLabel: { type: Boolean, default: false },
    emptySelectOption: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      selectableYears: [],
      year: this.value,
    };
  },
  created() {
    this.selectableYears = this.years
      ? this.years.map((val) => ({ key: val.toString(), value: val.toString() }))
      : [];
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
