<template>
  <div>
    <entity-badge entity="society" />
    <div class="search-result__everything__data">
      <div>
        <span class="search-result__everything__data__name">
          <router-link :to="'/societies/' + typedResult.value.id">
            <span>{{ typedResult.value.name }}</span>
          </router-link>
        </span>
      </div>
      <div
        v-if="typedResult.value.contact_info && typedResult.value.contact_info.address"
        class="search-result__everything__data__contact_info"
      >
        <div v-if="typedResult.value.contact_info.address.city">
          <span class="label">City:</span>
          <span>{{ typedResult.value.contact_info.address.city }}.</span>
        </div>
        <div v-if="typedResult.value.contact_info.address.country">
          <span class="label">Country:</span>
          <span>{{ $filters.formatCountry(typedResult.value.contact_info.address.country) }}.</span>
        </div>
      </div>
      <div v-if="typedResult.value.tags" class="search-result__tags chips__wrapper">
        <template v-for="(tag, index) in typedResult.value.tags">
          <tag :key="index" :tag="tag" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../tags/tag';
import EntityBadge from '../ui/entity-badge';

export default {
  name: 'EverythingResultSociety',
  components: { Tag, EntityBadge },
  props: {
    typedResult: Object,
  },
  data() {
    return {
      showFullProfile: false,
    };
  },
};
</script>
