<template>
  <form :data-vv-scope="scope">
    <div class="row">
      <div class="col s6">
        <slot name="title" />
      </div>
      <div v-if="displayActionButtons && rows.length > 0" class="col s6 text--right">
        <action-buttons :disable-submit="disableSubmit" @save="save" @cancel="cancel" />
      </div>
    </div>
    <div v-if="rows.length > 0" class="row card">
      <div v-for="(row, index) in rows" :key="index">
        <slot name="default" :index="index" :row="row" />
        <delete-button
          v-if="index > 0"
          :is-component="true"
          :has-label="true"
          @click="removeRow(index)"
        />
      </div>
      <div class="edit-link">
        <a :class="{ disabled: !addEnabled }" @click="addRow" @keyup.enter="addRow">
          <i class="far fa-plus-square" />
          Add
        </a>
      </div>
    </div>
  </form>
</template>

<script>
import ActionButtons from '../../ui/button/action-buttons';
import DeleteButton from '../../ui/button/delete-button';

export default {
  name: 'MultiLineForm',
  components: {
    ActionButtons,
    DeleteButton,
  },
  props: {
    displayActionButtons: {
      type: Boolean,
      default: true,
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    addEnabled: {
      type: Boolean,
      default: false,
    },
    scope: {
      type: String,
      default: 'multi-line-form',
    },
    createRow: {
      type: Function,
      default: () => ({}),
    },
  },
  data() {
    return {
      rows: [{}],
    };
  },
  computed: {
    lastHasEntry() {
      console.log(this.rows);
      return false;
      // return this.rows.every(s => s.source || s.url);
    },
  },
  methods: {
    save() {
      this.$emit('save', this.rows);
    },
    cancel() {
      this.$emit('cancel');
    },
    addRow() {
      this.rows.push(this.createRow());
    },
    removeRow(idx) {
      this.rows = [...this.rows.slice(0, idx), ...this.rows.slice(idx + 1)];
    },
  },
};
</script>
