<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2>Document</h2>
      </div>
    </div>

    <div class="card">
      <div v-for="(document, index) in documents" :key="index" class="row small-inner">
        <div class="col s6">
          <select-input
            label="Type of document"
            :name="`document_${index}`"
            :value="document.type"
            :items="documentTypes"
            item-key="code"
            item-value="name"
            @input="onUpdateDocumentType($event, index)"
          />
        </div>
        <div class="col s6">
          <file-upload-simple-field
            :name="`documentRef_${index}`"
            @addFile="onAddDocumentFile($event, index)"
            @removeFile="onRemoveDocumentFile($event, index)"
          />
        </div>
      </div>

      <div class="row flush--bottom">
        <div class="col s12 edit-link">
          <a :class="{ disabled: !lastHasEntry }" @click="onAddDocument">
            <i class="far fa-plus-square" />
            Add document
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import FileUploadSimpleField from '../../../ui/file/file-upload-simple-field';
import { createDocument } from '../../../../domain/common';
import SelectInput from '../../../ui/select/select-input';

export default {
  name: 'CreateDocumentsInformation',
  components: {
    SelectInput,
    FileUploadSimpleField,
  },
  inject: ['$validator'],
  data() {
    return {
      selectedDocumentType: null,
    };
  },
  computed: {
    ...mapGetters('performer', ['documents']),
    lastHasEntry() {
      return this.documents.every((doc) => doc.fileHandle && doc.type != null && doc.type !== '');
    },
    documentTypes() {
      return this.$store.state.appdata.referenceData.documentTypes;
    },
  },
  created() {
    if (this.documents.length === 0) {
      this.onAddDocument();
    }
  },
  methods: {
    ...mapMutations('performer', ['updateDocuments']),
    onUpdateDocumentType(type, index) {
      this.documents[index].type = type;
      this.updateDocuments(this.documents);
    },
    onAddDocument() {
      const docs = this.documents;
      docs.push(createDocument());
      this.updateDocuments(docs);
    },
    onAddDocumentFile(file, index) {
      const doc = this.documents[index];
      this.$set(doc, 'fileHandle', file);
      this.updateDocuments(this.documents);
    },
    onRemoveDocumentFile(file, index) {
      delete this.documents[index].fileHandle;
      this.documents[index].type = '';
      this.updateDocuments(this.documents);
    },
  },
};
</script>
