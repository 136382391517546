¨
<template>
  <div>
    <div v-if="statementHeader.error" class="error">
      <h2>Error while parsing statement file</h2>
      <p class="text--error">
        {{ statementHeader.error }}
      </p>
    </div>
    <div v-else class="result">
      <div class="row wrapper">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link class="nowrap" :to="'/distribution/revenues/' + id + '/to-check'">
              To Check
            </router-link>
            <router-link class="nowrap" :to="'/distribution/revenues/' + id + '/disburse'">
              Disburse
            </router-link>
            <router-link class="nowrap" :to="'/distribution/revenues/' + id + '/refund'">
              Refund
            </router-link>
            <router-link class="nowrap" :to="'/distribution/revenues/' + id + '/done'">
              Done
            </router-link>
          </div>
        </div>
      </div>
      <keep-alive>
        <router-view :statement-header="statementHeader" @fetchRevenue="$emit('fetchRevenue')" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StatementMatching',
  components: {},
  props: {
    statementHeader: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('revenue', ['id']),
    sourceFile() {
      return this.statementHeader.source_file.split('/').pop();
    },
  },
  methods: {},
};
</script>
