import * as uuid from 'uuid';
import { createContactInfo, createTaxInformation } from './common';
import { createPaymentInfo } from './commonPayment';

export const AssociateType = {
  HEIR: 'HEIR',
  COMPANY: 'COMPANY',
  INTERMEDIARY: 'INTERMEDIARY',
  MANAGER: 'MANAGER',
  ACCOUNTING_FIRM: 'ACCOUNTING_FIRM',
  LEGAL_GUARDIAN: 'LEGAL_GUARDIAN',
  OTHER: 'OTHER',
};

export const CHILD_ASSOCIATION_TYPES = {
  PERFORMER: 'PERFORMER',
  ASSOCIATE: 'ASSOCIATE',
  ASSOCIATE_HEIR: 'associate.HEIR',
  ASSOCIATE_COMPANY: 'associate.COMPANY',
  ASSOCIATE_INTERMEDIARY: 'associate.INTERMEDIARY',
  ASSOCIATE_MANAGER: 'associate.MANAGER',
  ASSOCIATE_ACCOUNTING_FIRM: 'associate.ACCOUNTING_FIRM',
  ASSOCIATE_LEGAL_GUARDIAN: 'associate.LEGAL_GUARDIAN',
  ASSOCIATE_OTHER: 'associate.OTHER',
};

export const ACCESS_POLICIES = {
  NONE: 'NONE',
  READ: 'READ',
  WRITE: 'WRITE',
};

export function createPersonInfo(
  socialSecurityNumber = null,
  firstName = null,
  lastName = null,
  middleName = null,
  dateOfBirth = null,
  dateOfDeath = null,
  protectedIdentity = null,
) {
  return {
    social_security_number: socialSecurityNumber,
    date_of_birth: dateOfBirth,
    date_of_death: dateOfDeath,
    first_name: firstName,
    last_name: lastName,
    middle_name: middleName,
    protected_identity: protectedIdentity,
  };
}

export function createOrganisationInfo(orgNo = null, name = null) {
  return {
    org_no: orgNo,
    name,
  };
}

export function createMetaInfo(tags = []) {
  return {
    tags,
  };
}

export function createSystemState(paymentsBlocked = false, paymentsBlockedReason = '') {
  return {
    payments_blocked: paymentsBlocked,
    payments_blocked_reason: paymentsBlockedReason,
  };
}

export function createAssociateAggregateCommand(id) {
  return {
    type: 'associate:create',
    version: 1,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      associate: {
        type: null,
        person_info: null,
        organisation_info: null,
        contact_info: null,
        payment_info: null,
        tax_info: null,
        associates: [],
        meta_info: {
          tags: [],
        },
      },
      child_associations: [],
    },
  };
}

export function createUpdateBasicInfoCommand(id) {
  return {
    type: 'associate:updateBasicInfo',
    version: null,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      person_info: null,
      organisation_info: null,
      local_codes: null,
    },
  };
}

export function createUpdateChildAssociationsCommand() {
  return {
    type: 'associate:children:update',
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      child_associations: [],
    },
  };
}

export function createRemoveChildAssociationsCommand() {
  return {
    type: 'associate:children:remove',
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      id: null,
      row_id: null,
      type: null,
    },
  };
}

export function createUpdateMetaInfoCommand(id) {
  return {
    type: 'associate:metaInfo:update',
    version: null,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      tags: [],
    },
  };
}

export function createAssociateAggregate(type = null) {
  return {
    id: null,
    version: null,
    process_id: null,
    stream_id: null,
    timestamp: null,
    type,
    person_info: createPersonInfo(),
    organisation_info: createOrganisationInfo(),
    contact_info: createContactInfo(),
    payment_info: createPaymentInfo(),
    tax_info: createTaxInformation(),
    associates: [],
    child_associations: [],
    num_child_associations: null,
    meta_info: createMetaInfo(),
    system_state: {
      payments_blocked: false,
      payments_blocked_reason: '',
    },
    documents: [],
    local_codes: [],
  };
}

export function createUpdatePaymentsBlockedCommand() {
  return {
    type: 'associate:systemState:paymentsBlocked:update',
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      payments_blocked: false,
      payments_blocked_reason: '',
    },
  };
}

export function createConfirmMembershipCommand() {
  return {
    type: 'associate:membership:confirm',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {},
  };
}
