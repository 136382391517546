var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('ComponentSpinner'):_c('div',[_c('table',[_c('thead',[_c('th',[_vm._v("Date Created")]),_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Payment Date")]),_c('th',[_vm._v("Society")]),_c('th',[_vm._v("Created By")]),_c('th',[_vm._v("Status")]),_c('th',{staticClass:"text--right"},[_vm._v("Amount")])]),_c('tbody',_vm._l((_vm.payments),function(p){return _c('tr',{key:p.id,class:{ 'payment-removed': _vm.isRemoved(p) }},[_c('td',[_vm._v(" "+_vm._s(_vm.$filters.dateFormat(_vm.$filters.dateParse(p.createdAt), 'YYYY-MM-DD'))+" ")]),_c('td',[(_vm.paymentIsOpen(p) || _vm.paymentIsConfirmed(p))?_c('router-link',{attrs:{"to":{
                path: `/payments/${p.id}`,
              }}},[_vm._v(" "+_vm._s(p.name)+" ")]):_c('span',[_vm._v(_vm._s(p.name))])],1),(p.occupied && _vm.getPaymentProcess(p))?[_c('td',{attrs:{"colspan":"3"}},[_c('process-progress-bar',{attrs:{"id":p.processId},on:{"finished":_vm.processFinished}})],1)]:[_c('td',[_vm._v(_vm._s(_vm.$filters.dateFormat(_vm.$filters.dateParse(p.paymentDate), 'YYYY-MM-DD')))]),_c('td',[_vm._v(_vm._s(_vm.$filters.formatSocietyV2(p.societyId, _vm.societies)))]),_c('td',[_vm._v(_vm._s(p.createdBy || 'you'))])],_c('td',{class:{
              'text--green': _vm.paymentIsOpen(p),
              'text--yellow': p.occupied,
              'text--red': _vm.paymentHasError(p),
            }},[_vm._v(" "+_vm._s(_vm.getPaymentReadableState(p))+" ")]),_c('td',{staticClass:"amount-row"},[_vm._v(" "+_vm._s(_vm.$filters.formatAmountCent(p.payableAmount + p.payableVat || 0, 'SEK'))+" ")]),_c('td',[(!(_vm.paymentIsConfirmed(p) || _vm.paymentIsRemoved(p) || _vm.paymentHasError(p)))?_c('options',{attrs:{"options":['Delete']},on:{"delete":function($event){return _vm.handleDelete(p.id)}}}):_vm._e()],1)],2)}),0)]),(_vm.numberOfPayments > 0)?_c('pagination',{attrs:{"number-of-pages":_vm.numberOfPages,"number-of-hits":_vm.numberOfPayments,"selected-page":Math.floor(_vm.offset / _vm.limit) + 1,"hits-per-page":_vm.limit},on:{"selectPage":_vm.selectPage,"updateHitsPerPage":_vm.updateHitsPerPage}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }