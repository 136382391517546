<template>
  <div>
    <label v-if="label && !blueLink" class="label">{{ label }} {{ encoding }}</label>
    <span v-if="mandatory" class="mandatory">(mandatory)</span>
    <div v-if="!selectedFiles || selectedFiles.length === 0" class="mt">
      <label v-if="blueLink" :for="name">
        <a>
          <i class="fas fa-paperclip" />
          {{ label }} {{ encoding }}
        </a>
      </label>
      <label v-else :for="name" class="btn secondary">
        <i class="fas fa-upload" />
        Choose files...
      </label>
      <input
        :id="name"
        :ref="name"
        v-validate="rule"
        type="file"
        class="blank-style"
        placeholder="fiwoj"
        style="display: none"
        :name="name"
        :data-vv-as="name.toLowerCase()"
        :disabled="disabled"
        :accept="acceptFileType"
        multiple
        @change="onAddFiles"
      />
    </div>
    <div class="row">
      <div v-for="(file, index) in selectedFiles" :key="index">
        <i :class="getIcon(getFileType(file))" />
        {{ file.name }}
        <i class="fas fa-times close cursor-pointer" @click="removeFile" />
      </div>
      <div class="mt">
        <span>
          <strong>Total size: {{ (totalFileSize / 1000000).toFixed(2) }} MB</strong>
        </span>
      </div>

      <div v-if="validationErrors.has(name, scope)">
        <span class="help is-danger">
          <i class="fas fa-times-circle" />
          {{ validationErrors.first(name, scope) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: 'Upload document',
    },
    scope: {
      type: String,
      default: undefined,
    },
    rule: {
      type: [String, Object],
      default: '',
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blueLink: {
      type: Boolean,
      default: false,
    },
    fileHandle: {
      type: File,
      default: () => undefined,
    },
    acceptFileType: {
      type: String,
      default: '*',
    },
  },
  data() {
    return {
      selectedFiles: [],
    };
  },
  computed: {
    totalFileSize() {
      return this.selectedFiles.reduce((acc, f) => acc + f.size, 0);
    },
    encoding() {
      if (this.acceptFileType === '*') {
        return '';
      }
      let encoding = '';
      if (/csv|xml/.test(this.acceptFileType)) {
        encoding = ' UTF-8';
      } else if (/txt/.test(this.acceptFileType)) {
        encoding = ' ISO-8859-1';
      }
      return `(${this.acceptFileType.replace(/\./g, '')}${encoding})`;
    },
  },
  watch: {
    fileHandle(handle) {
      if (handle) {
        this.selectedFiles = handle;
      }
    },
  },
  methods: {
    onAddFiles(upload) {
      for (let i = 0; i < upload.target.files.length; i++) {
        this.$set(upload.target.files[i], 'p', 0);
        this.$set(upload.target.files[i], 'fid', i);
      }
      this.selectedFiles = Array.from(upload.target.files);
      this.$emit('updateFiles', this.selectedFiles);
    },
    getFileType(file) {
      return file.type.split('/').pop(-1);
    },
    getIcon(fileType) {
      switch (fileType) {
        case 'pdf':
          return 'far fa-file-pdf';
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'svg':
          return 'far fa-file-image';
        case 'ppt':
          return 'far fa-file-powerpoint';
        case 'txt':
        case 'doc':
        case 'docx':
          return 'far fa-file-alt';
        case 'xls':
        case 'xlsx':
          return 'far fa-file-excel';
        default:
          return 'far fa-file';
      }
    },
    getEncoding(fileType) {
      switch (fileType) {
        case 'txt':
          return '(txt ISO-8859-1)';
        case 'csv':
        case 'xml':
          return `(${fileType} UTF-8)`;
        default:
          return `(${fileType})`;
      }
    },
    removeFile(idx) {
      this.selectedFiles.splice(idx, 1);
      this.$emit('updateFiles', this.selectedFiles);
    },
    reset() {
      this.selectedFiles.splice(0, this.selectedFiles.length);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.blank-style {
  width: 300px;
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  margin-bottom: 0;
}
.mt {
  margin-top: 6px;
}
</style>
