<template>
  <form class="card">
    <div class="row flush--bottom">
      <div class="col s12">
        <p>
          Reserve release is a process where amounts that were reserved in various distributions up
          until the release year are reallocated to performers that has received money for the same
          year, source and area.
        </p>
        <p>Here’s a brief description of its effects on the system:</p>
        <ul class="release-bullet-list">
          <li>
            It will reallocate reserved amounts as remunerations to performers in the same ratio as
            they have distributed/redistributed over the years. E.g. the performer that has received
            the most amount will also receive the largest fraction of the reserve.
          </li>
          <li>
            Close all related point values, so that the redistribution process will ignore these in
            its future calculations.
          </li>
          <li>Mark any remaining reserves as closed.</li>
          <li>Create an accounting report with the results.</li>
        </ul>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col s3">
        <select-year
          v-model="releaseYear"
          name="releaseYear"
          label="Release Year"
          :years="years"
          :empty-select-option="false"
          :rule="{ required: true }"
        />
      </div>
    </div>

    <div class="row flush--bottom">
      <div class="col s12">
        <div class="float-right">
          <action-buttons
            submit-label="Calculate release of reserves"
            :show-abort="false"
            :disable-submit="isSubmitted"
            @save="onSubmit()"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import ActionButtons from '../ui/button/action-buttons';
import SelectYear from '../ui/select/select-year';

export default {
  name: 'ProgressReleaseOfReserves',
  components: {
    SelectYear,
    ActionButtons,
  },
  inject: ['$validator'],
  data() {
    return {
      releaseYear: undefined,
      years: [],
      isSubmitted: false,
    };
  },
  computed: {},
  created() {
    const years = [];
    const endYear = moment().subtract(10, 'year').year();
    let startYear = moment().subtract(19, 'year').year();
    while (startYear <= endYear) {
      years.push(startYear++);
    }
    this.years = years.reverse().map((y) => y.toString());
    this.releaseYear = this.years[0];
  },
  methods: {
    async onSubmit() {
      this.$emit('onSubmit', this.releaseYear);
    },
  },
};
</script>

<style scoped>
.release-bullet-list {
  padding-left: 1rem;
}
.release-bullet-list li {
  line-height: 2;
  list-style-type: disc;
}
</style>
