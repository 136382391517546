<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2>Create and Add Album</h2>
      </div>
    </div>
    <div v-if="formVisible">
      <div>
        <div v-for="(album, index) in localAlbums" :key="album._localId">
          <create-new-album
            :name="'create-new-album.' + index"
            :scope="'create-new-album.' + index"
            :album="album"
            :countries="countries"
            @input="updateNewAlbum(index, $event)"
            @remove="removeAlbum(index)"
          />
        </div>
        <div class="row edit-link">
          <div class="col s12">
            <a
              :class="{ disabled: disableAddButton }"
              @click="addNewAlbum"
              @keyup.enter="addNewAlbum"
            >
              <i class="far fa-plus-square" />
              Create and add a new album
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row edit-link">
      <div class="col s12">
        <a @click="formVisible = true">
          <i class="far fa-plus-square" />
          Create and add a new album
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import * as uuid from 'uuid';
import clone from './../../../common/clone';
import { createAlbum } from './../../../domain/recordingDomain';
import CreateNewAlbum from './create-new-album';

export default {
  name: 'CreateNewAlbums',
  components: { CreateNewAlbum },
  inject: ['$validator'],
  data() {
    return {
      localAlbums: [],
      formVisible: false,
    };
  },
  computed: {
    ...mapGetters('recording', ['newAlbums', 'albums']),
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
    disableAddButton() {
      return !this.lastHasEntry || this.hasErrors;
    },
    hasErrors() {
      for (let idx = 0; idx < this.localAlbums.length; idx++) {
        if (this.validationErrors.has(`${this.name}.${idx}`, 'create-new-album')) {
          return true;
        }
      }
      return false;
    },
    lastHasEntry() {
      const lastAlbum = this.localAlbums[this.localAlbums.length - 1];
      return this.localAlbums.length === 0 || (lastAlbum.album_name && lastAlbum.main_artist);
    },
  },
  created() {
    this.addNewAlbum();
  },
  methods: {
    ...mapMutations('recording', [
      'addToNewAlbums',
      'removeInNewAlbums',
      'replaceInNewAlbums',
      'addToAlbums',
      'replaceInAlbums',
      'newAlbumUpdate',
    ]),
    updateNewAlbum(index, valueUpdate) {
      if (valueUpdate.id == null) {
        const newAlbum = createAlbum();
        this.addToNewAlbums(newAlbum);
        valueUpdate.id = newAlbum.stream_id;
      }

      const newAlbumIndex = this.newAlbums.findIndex((album) => album.stream_id === valueUpdate.id);
      if (newAlbumIndex !== -1) {
        const newAlbumUpdate = clone(this.newAlbums[newAlbumIndex]);
        newAlbumUpdate.album_name = valueUpdate.album_name;
        newAlbumUpdate.main_artist = valueUpdate.main_artist;
        newAlbumUpdate.produced_in_country = valueUpdate.produced_in_country;
        newAlbumUpdate.catalog_number = valueUpdate.catalog_number;
        newAlbumUpdate.release_date = valueUpdate.release_date;

        newAlbumUpdate.side_no = valueUpdate.side_no;
        newAlbumUpdate.track_no = valueUpdate.track_no;

        this.replaceInNewAlbums({ index: newAlbumIndex, value: newAlbumUpdate });
      }
      const album = this.localAlbums[index];
      album.id = valueUpdate.id;
      album.album_name = valueUpdate.album_name;
      album.main_artist = valueUpdate.main_artist;
      album.produced_in_country = valueUpdate.produced_in_country;
      album.catalog_number = valueUpdate.catalog_number;
      album.release_date = valueUpdate.release_date;
      album.side_no = valueUpdate.side_no;
      album.track_no = valueUpdate.track_no;
      this.localAlbums.splice(index, 1, album);
    },
    removeAlbum(index) {
      this.localAlbums.splice(index, 1);
    },
    addNewAlbum() {
      this.localAlbums.push(
        Object.assign(
          { _localId: uuid.v4() },
          {
            id: null,
            album_name: null,
            main_artist: null,
            produced_in_country: null,
            catalog_number: null,
            release_date: null,
            side_no: 1,
            track_no: 1,
          },
        ),
      );
    },
  },
};
</script>
