import Vue from 'vue';
import { split } from 'lodash';
import * as uuid from 'uuid';
import RemoteFileService from '../services/remoteFileService';
import { AGGREGATE_TYPES, SUB_AGGREGATE_TYPES } from '../domain/common';
import CreateUploadFileDescriptor from '../domain/uploadFileDescriptor';
import MatchingService from '../services/matchingService';
import { getConfigValue, PROPS } from '../services/configService';

async function uploadStatement(revenue) {
  const entityType = `${AGGREGATE_TYPES.DISTRIBUTION}-${SUB_AGGREGATE_TYPES.REVENUE}`;
  const metaIdentifiers = [revenue.statementFileHandle.name];
  const fileDescriptor = CreateUploadFileDescriptor(
    entityType,
    revenue.id,
    metaIdentifiers,
    revenue.statementFileHandle,
  );
  const bucket = getConfigValue(PROPS.DATA_BUCKET);
  const options = {
    repo: bucket,
    directory: 'sdeg/statements/inbox',
    metadata: [
      {
        key: 'revenue-id',
        value: String(revenue.id),
      },
      {
        key: 'society',
        value: revenue.society_code || 'SAMI',
      },
    ],
  };
  const fileName = await RemoteFileService.uploadToDocumentRepository(fileDescriptor, options);
  revenue.statement_url = fileName;
  return true;
}

async function uploadAttachments(revenue) {
  const attachmentsToUpload = revenue.attachmentFileHandles.filter((fileHandle) => fileHandle);
  const promises = attachmentsToUpload.map(async (fileHandle) => {
    const entityType = `${AGGREGATE_TYPES.DISTRIBUTION}-${SUB_AGGREGATE_TYPES.REVENUE}`;
    const metaIdentifiers = [fileHandle.name];
    const fileDescriptor = CreateUploadFileDescriptor(
      entityType,
      uuid.v4(),
      metaIdentifiers,
      fileHandle,
    );
    const fileName = await RemoteFileService.uploadToDocumentRepository(fileDescriptor);
    if (revenue.attachment_urls) {
      revenue.attachment_urls.push(fileName);
    } else {
      revenue.attachment_urls = [fileName];
    }
    return true;
  });
  await Promise.all(promises);
}

function createSdegUploadFilename(reportFileObject) {
  const fileNameParts = split(reportFileObject.file.name, '.');
  const fileSuffix = fileNameParts.pop();
  return `${fileNameParts.join('.')}.${reportFileObject.source.toUpperCase()}_${
    reportFileObject.society
  }_${reportFileObject.from}_${reportFileObject.to}_${
    reportFileObject.name
  }.${fileSuffix.toLowerCase()}`.replace(/\s/g, '_');
}

async function uploadPlaytimeReportFile(reportFileObject, progressUpdatedFunc) {
  // Report progress in whole percents.
  const progressFunc = (progressEvent) =>
    progressUpdatedFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total));

  // ensure the file name extension is lowercase (i.e. turn "My.File.TXT" into "My.File.txt")
  // and remove unwanted characters not in [^a-zA-Z0-9_-].
  const fileNameCleaned = cleanFileName(reportFileObject.file.name);

  const metadata = [
    { key: 'source', value: reportFileObject.source },
    { key: 'localCode', value: reportFileObject.localCodeType },
    { key: 'playDate', value: reportFileObject.playDate || '' },
    { key: 'reportDate', value: reportFileObject.reportDate },
  ];
  // there is a requirement to make sure that files are not uploaded twice.
  const dataUrlsComplete = await MatchingService.getDataUrls(
    reportFileObject.path,
    reportFileObject.source,
    fileNameCleaned,
    metadata,
  );
  await RemoteFileService.fileExist(dataUrlsComplete.headImported);
  await RemoteFileService.fileExist(dataUrlsComplete.headInbox);
  await RemoteFileService.upload(dataUrlsComplete.upload, reportFileObject.file, {
    progressFunc,
    hideUser: true,
    metadata,
  });
}

async function uploadMediarcReportFile(reportFileObject, options = {}) {
  const fileNameCleaned = cleanFileName(reportFileObject.file.name);
  const dataUrls = await MatchingService.getDataUrls(
    reportFileObject.path,
    undefined,
    fileNameCleaned,
    options.metadata,
  );
  await RemoteFileService.fileExist(dataUrls.headImported);
  await RemoteFileService.fileExist(dataUrls.headInbox);
  await RemoteFileService.upload(dataUrls.upload, reportFileObject.file, {
    ...options,
    hideUser: true,
  });
}

/**
 * Download a report file
 * @param process "playlists|ipd|sdeg"
 * @param subtype "sr|com|nothing"
 * @param reportName the report name (file name)
 * @returns {PromiseLike<T | never>}
 */
async function downloadReportFile(process, subtype, reportName) {
  const dataUrls = await MatchingService.getDataUrls(process, subtype, reportName, undefined);
  const head = await Vue.axios.create().head(dataUrls.headImported);
  const fileData = await RemoteFileService.downloadBinary(dataUrls.fetch);
  return {
    originalFileName: head.headers['x-amz-meta-original-file-name'],
    fileData,
  };
}

/**
 * Change the extension of a file name to be lowercase. Example: My.File.TXT -> My.File.txt.
 * @param fileName "string"
 */
function lowerCaseExtension(fileName) {
  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex === -1) {
    return fileName;
  } else {
    return fileName.substring(0, dotIndex) + fileName.substring(dotIndex).toLowerCase();
  }
}

/**
 * Remove unwanted characters from filename.
 * @param fileName "string"
 */
function removeUnwantedChars(fileName) {
  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex === -1) {
    return fileName;
  } else {
    return (
      fileName.substring(0, dotIndex).replace(/[^a-zA-Z0-9_-]/g, '') + fileName.substring(dotIndex)
    );
  }
}

function cleanFileName(fileName) {
  fileName = lowerCaseExtension(fileName);
  fileName = removeUnwantedChars(fileName);
  return fileName;
}

export default {
  uploadPlaytimeReportFile,
  downloadReportFile,
  uploadStatement,
  uploadAttachments,
  createSdegUploadFilename,
  uploadMediarcReportFile,
  cleanFileName,
};
