<template>
  <form class="pos-relative" data-vv-scope="contact-information">
    <div class="row">
      <div class="col s6">
        <h2>Contact Information</h2>
      </div>
      <template v-if="editMode">
        <div class="text--right col s6">
          <action-buttons
            scope="contact-information"
            @save="saveFunction"
            @cancel="cancelFunction"
          />
        </div>
      </template>
    </div>

    <div class="card e2e-edit-contact-information">
      <div class="row">
        <div v-if="editMode" class="col s2">C/O</div>
        <div class="col s7">
          <validated-text-input
            v-model="contactInfoData.address.care_of"
            name="contact_info.address.care_of"
            placeholder=""
            :label="editMode ? '' : 'C/O'"
            @input="emit"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">Street</div>
        <div class="col s7">
          <validated-text-input
            v-model="contactInfoData.address.street"
            name="contact_info.address.street"
            placeholder="E.g. Söder Mälarstrand 75"
            :label="editMode ? '' : 'Street'"
            @input="emit"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">Postal code</div>
        <div class="col s7">
          <validated-text-input
            v-model="contactInfoData.address.postal_code"
            name="contact_info.address.postal_code"
            placeholder="E.g. 118 25"
            :label="editMode ? '' : 'Postal code'"
            scope="contact-information"
            @input="emit"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">City</div>
        <div class="col s7">
          <validated-text-input
            v-model="contactInfoData.address.city"
            name="contact_info.address.city"
            placeholder="E.g. Stockholm"
            :label="editMode ? '' : 'City'"
            @input="emit"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">Province</div>
        <div class="col s7">
          <validated-text-input
            v-model="contactInfoData.address.province"
            name="contact_info.address.province"
            placeholder="E.g. Stockholms län"
            :label="editMode ? '' : 'Province'"
            @input="emit"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">Country</div>
        <div class="col s7">
          <select-country
            v-model="contactInfoData.address.country"
            :show-label="editMode ? false : true"
            :mandatory="countryRequired"
            scope="contact-information"
            :label="editMode ? '' : 'Country'"
            :rule="countryRequired ? 'required' : ''"
            @input="emit"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">Website</div>
        <div class="col s7">
          <validated-text-input
            v-model="contactInfoData.website"
            name="contact_info.website"
            :label="editMode ? '' : 'Website'"
            placeholder="E.g. http://www.sami.se"
            rule="url"
            scope="contact-information"
            @input="emit"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">Email</div>
        <div class="col s7">
          <addable-text-input
            name="contact_info.emails"
            scope="contact-information"
            placeholder="E.g. jocke.berg@gmail.com"
            :label="editMode ? '' : 'Email'"
            :rule="emailRule"
            :show-label="!editMode"
            :values="contactInfoData.emails"
            @input="onUpdateEmail"
            @add="onAddEmail"
            @remove="onRemoveEmail"
          />
        </div>
      </div>

      <div class="row flush--bottom">
        <div v-if="editMode" class="col s2">Phone numbers</div>
        <div class="col s7">
          <phone-number-input
            name="contact_info.phone_numbers"
            scope="contact-information"
            :label="editMode ? '' : 'Phone numbers'"
            placeholder="E.g. 070 111 22 33"
            :required="phoneNumberRequired"
            :phone-numbers="contactInfoData.phone_numbers"
            @input="emit"
            @add="onAddPhoneNumber"
            @remove="onRemovePhoneNumber"
          />
        </div>
      </div>
    </div>

    <template v-if="enableContactPerson">
      <div class="row">
        <div class="col s12">
          <h2>Contact Person</h2>
        </div>
      </div>

      <div>
        <div
          v-for="(contactPerson, index) in contactInfoData.contact_persons"
          :key="contactPerson._localId"
        >
          <contact-person
            :contact-person="contactPerson"
            :deletable="contactInfoData.contact_persons.length > 1"
            @input="onContactPersonUpdated(index, $event)"
            @remove="onRemoveContactPerson(index)"
          />
        </div>

        <div class="row wrapper">
          <div class="col s12 edit-link">
            <a
              :class="{ disabled: disableAddButton }"
              @click="addContactPerson(contactInfoData.contact_persons.length - 1)"
            >
              <i class="far fa-plus-square" />
              Add contact person
            </a>
          </div>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import _ from 'lodash';

import * as uuid from 'uuid';
import clone from '../../../common/clone';
import { AssociateType } from '../../../domain/associateDomain';
import { createContactPerson, createPhoneNumber } from '../../../domain/common';
import ActionButtons from '../../ui/button/action-buttons';
import AddableTextInput from '../../ui/input/addable-text-input';
import ContactPerson from './contact-person';
import PhoneNumberInput from '../../ui/input/phone-number-input';
import SelectCountry from '../../ui/select/select-country';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'CreateEditContactInfo',
  components: {
    ActionButtons,
    AddableTextInput,
    ContactPerson,
    PhoneNumberInput,
    SelectCountry,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  props: {
    contactInfo: {
      type: Object,
    },
    editContactPerson: {
      type: Boolean,
      default: false,
    },
    enableContactPerson: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    countryRequired: {
      type: Boolean,
      default: false,
    },
    associateType: {
      type: String,
      default: undefined,
    },
    cancelFunction: {
      type: Function,
    },
    saveFunction: {
      type: Function,
    },
  },
  data() {
    return {
      contactInfoData: clone(this.contactInfo),
    };
  },
  computed: {
    disableAddButton() {
      return !this.lastHasEntry || this.validationErrors.any('contact-person');
    },
    lastHasEntry() {
      return (
        this.contactInfoData.contact_persons.length === 0 ||
        !!this.contactInfoData.contact_persons.slice(-1)[0].name
      );
    },
    contact_persons() {
      return this.contactInfoData.contact_persons;
    },
    emailRule() {
      const rule = {};
      if ([AssociateType.INTERMEDIARY, AssociateType.MANAGER].includes(this.associateType)) {
        rule.required = this.contactInfoData.phone_numbers.every((field, index) => {
          const f = this.validationFields[`contact_info.phone_numbers.${index}`];
          return _.isEmpty(field.number) || (f && f.invalid);
        });
      }
      rule.email = true;
      return rule;
    },
    phoneNumberRequired() {
      if ([AssociateType.INTERMEDIARY, AssociateType.MANAGER].includes(this.associateType)) {
        return this.contactInfoData.emails.every((field, index) => {
          const f = this.validationFields[`contact_info.emails.${index}`];
          return _.isEmpty(field) || (f && f.invalid);
        });
      }
      return false;
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    contact_persons() {
      this.setLocalIds();
    },
  },
  mounted() {
    this.setLocalIds();
  },
  created() {
    this.initiate();
  },
  methods: {
    setLocalIds() {
      this.contactInfoData.contact_persons.forEach((person) => {
        if (!person._localId) {
          person._localId = uuid.v4();
        }
      });
    },
    initiate() {
      if (!this.contactInfoData.emails || this.contactInfoData.emails.length === 0) {
        this.onAddEmail();
      }
      if (!this.contactInfoData.phone_numbers || this.contactInfoData.phone_numbers.length === 0) {
        this.onAddPhoneNumber();
      }
      if (
        !this.contactInfoData.contact_persons ||
        this.contactInfoData.contact_persons.length === 0
      ) {
        this.addContactPerson();
      }
    },
    onAddEmail() {
      this.contactInfoData.emails.push('');
      this.emit();
    },
    onAddPhoneNumber() {
      this.contactInfoData.phone_numbers.push(createPhoneNumber());
      this.emit();
    },
    onRemovePhoneNumber(index) {
      this.contactInfoData.phone_numbers.splice(index, 1);
      if (this.contactInfoData.phone_numbers.length === 0) {
        this.contactInfoData.phone_numbers.push(createPhoneNumber());
      }
      this.emit();
    },
    onRemoveContactPerson(index) {
      this.contactInfoData.contact_persons.splice(index, 1);
      if (this.contactInfoData.contact_persons.length === 0) {
        this.contactInfoData.contact_persons.push(createContactPerson());
      }
      this.emit();
    },
    addContactPerson() {
      if (this.lastHasEntry) {
        this.contactInfoData.contact_persons.push(createContactPerson());
        this.emit();
      }
    },
    onUpdateEmail(valueUpdate) {
      this.$set(this.contactInfoData.emails, valueUpdate.index, valueUpdate.value);
      this.emit();
    },
    onRemoveEmail(index) {
      this.contactInfoData.emails.splice(index, 1);
      if (this.contactInfoData.emails.length === 0) {
        this.contactInfoData.emails.push('');
      }
      this.emit();
    },
    onContactPersonUpdated(index, value) {
      this.$set(this.contactInfoData.contact_persons, index, value);
      this.emit();
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    emit() {
      this.$validator.validateAll('contact-information');
      this.$emit('input', this.contactInfoData);
    },
  },
};
</script>
