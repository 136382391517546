<template>
  <form>
    <div class="row small-inner">
      <div class="col s6">
        <h2>External information</h2>
      </div>
      <div class="text--right col s6">
        <action-buttons @save="onSave" @cancel="onCancel" />
      </div>
    </div>

    <div class="row card">
      <external-sources-input
        label="External sources"
        :external-sources="externalSources"
        :right-column="true"
        @add="onAddExternalSource"
        @remove="onRemoveExternalSource"
      />
    </div>
  </form>
</template>

<script>
import { createOtherExternalSources } from '../../../domain/common';
import ActionButtons from '../../ui/button/action-buttons';
import ExternalSourcesInput from '../../ui/input/external-sources-input';

export default {
  name: 'EditExternalSources',
  components: {
    ActionButtons,
    ExternalSourcesInput,
  },
  inject: ['$validator'],
  props: {
    mainArtist: { type: Object },
  },
  data() {
    return {
      externalSources: this.mainArtist.basic_info.other_external_sources,
    };
  },
  created() {
    if (this.externalSources.length === 0) {
      this.onAddExternalSource();
    }
  },
  methods: {
    onAddExternalSource() {
      this.externalSources.push(createOtherExternalSources());
    },
    onRemoveExternalSource(index) {
      this.externalSources.splice(index, 1);
      if (this.externalSources.length === 0) {
        this.onAddExternalSource();
      }
    },
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    onSave() {
      this.mainArtist.basic_info.other_external_sources = this.externalSources;
      this.$emit('save', this.mainArtist);
    },
  },
};
</script>
