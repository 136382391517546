<template>
  <div>
    <div v-if="loading">
      <Spinner />
    </div>

    <div class="row">
      <div class="col s7">
        <TableOptions
          :bulk-actions="[{ key: 'join', value: 'Join duplicates' }]"
          :bulk-action-enabled="selectedItems.length >= 2"
          :range="{ start: rangeStart, end: rangeEnd }"
          @rangeStartChanged="setRangeStart"
          @rangeEndChanged="setRangeEnd"
          @bulkAction="onBulkAction"
        />
      </div>
      <div class="col s2"></div>
      <div class="col s3 flex">
        <div class="tags__text margin--right">Tags</div>
        <div class="tags__search">
          <SearchableTagInput
            name="tags"
            placeholder="E.g. Duplicate"
            use-template="tag"
            :preview-list="previewTags"
            :value-list="localTags"
            :searcher="searchTag"
            :show-label="false"
            @add="addTag"
            @remove="fetchData"
          />
        </div>
      </div>
    </div>

    <div class="row card">
      <table>
        <SortableHead
          :expanded-content="true"
          :more-options="false"
          :columns="columns"
          :bulk-enabled="false"
          :default-sort-should-override="true"
          @sort="sort"
        />

        <DuplicateItem
          v-for="(item, index) in items"
          :key="item.stream_id"
          :merge-type="mergeType"
          :item="item"
          :expanded="index === expandedIndex"
          @toggleExpanded="toggleExpanded(index)"
        />
      </table>

      <div class="result__pagination">
        <Pagination
          v-if="numberOfPages() > 0"
          :number-of-pages="numberOfPages()"
          :selected-page="page"
          :number-of-hits="itemCount"
          :hits-per-page="pageSize"
          @selectPage="selectPage"
          @updateHitsPerPage="selectPageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaginationQueryParamMixin from '../../common/paginationQueryParamMixin';
import Pagination from '../pagination';
import SortableHead from '../ui/table/sortable-head';
import Spinner from '../spinner';
import MergeService from '../../services/mergeService';
import TableOptions from '../ui/table/table-options';
import DuplicateItem from './duplicate-item';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import SearchHelper from '../search/searchHelper';

export default {
  name: 'ViewDuplicatesList',
  components: {
    Pagination,
    SortableHead,
    Spinner,
    TableOptions,
    DuplicateItem,
    SearchableTagInput,
  },
  mixins: [PaginationQueryParamMixin],
  data() {
    return {
      allTags: [],
      localTags: [],
      items: [],
      mergeType: undefined,
      expandedIndex: undefined,
    };
  },
  computed: {
    previewTags() {
      return this.allTags.filter((tag) =>
        this.localTags.length > 0
          ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
          : true,
      );
    },
    selectedItems() {
      return this.items.filter((item) => item.checked);
    },
  },
  watch: {
    $route(from, to) {
      if (from.name !== to.name) {
        this.routeChange();
      }
      this.expandedIndex = undefined;
      this.fetchData();
    },
  },
  async created() {
    this.routeChange();
    this.updateQueryParams(this.$route.query);
    this.fetchData();
    this.allTags = await SearchHelper.getAllTags();
  },
  activated() {
    window.addEventListener('keydown', this.onKeyDown);
  },
  deactivated() {
    window.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    onKeyDown(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.togglePrevOrNext('next');
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.togglePrevOrNext('prev');
      }
    },
    async togglePrevOrNext(direction) {
      if (this.expandedIndex === undefined) {
        return;
      }

      const nextIndex = direction === 'prev' ? this.expandedIndex - 1 : this.expandedIndex + 1;

      if (nextIndex < 0 || nextIndex >= this.items.length) {
        this.expandedIndex = undefined;
        const page = direction === 'prev' ? this.page - 1 : this.page + 1;

        if (page < 1 || page > this.numberOfPages()) {
          return;
        }

        this.updateQueryParams({ page }, true);
        await this.fetchData();
        this.expandedIndex = direction === 'prev' ? this.items.length - 1 : 0;
      } else {
        this.expandedIndex = nextIndex;
      }
    },
    toggleExpanded(index) {
      this.expandedIndex = this.expandedIndex !== index ? index : undefined;
    },
    addTag(event) {
      this.localTags = event.data;
      this.fetchData();
    },
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    routeChange() {
      this.mergeType = this.getMergeType();
      this.columns = this.getColumns();
    },
    onBulkAction(value) {
      switch (value) {
        case 'join':
          this.joinDuplicates();
          break;
        default:
          console.log('Unknown bulk action');
      }
    },
    async joinDuplicates() {
      const streamIds = this.selectedItems.map((item) => item.stream_id);
      const response = await MergeService.joinDuplicates(streamIds);

      this.$router.push({
        name: 'mergePreview',
        params: { id: response.stream_id, mergeType: this.mergeType },
      });
    },
    async fetchData() {
      this.loading = true;
      try {
        const { items, total } = await MergeService.getDuplicates({
          type: this.mergeType,
          limit: this.pageSize,
          offset: this.getOffset(),
          orderBy: this.sortParam,
          ascending: this.sortOrder !== 'desc',
          rangeStart: this.rangeStart,
          rangeEnd: this.rangeEnd,
          tags: this.localTags.map((tag) => tag.name),
        });

        this.itemCount = total;
        this.items = items;
      } catch (err) {
        this.$addStarError(err);
      }
      this.loading = false;
    },
    getMergeType() {
      switch (this.$router.currentRoute.name) {
        case 'mergeDuplicateAlbums':
          return 'album';
        case 'mergeDuplicateMainArtists':
          return 'mainartist';
        default:
          throw new Error(`Unknown route name: ${this.$router.currentRoute.name}`);
      }
    },
    getColumns() {
      switch (this.mergeType) {
        case 'album':
          return [
            {
              thinColumn: true,
            },
            {
              name: 'Name',
              ascending: true,
              sortParam: 'name',
              active: true,
              default: true,
            },
            {
              name: 'Main artist',
              ascending: true,
              sortParam: 'main_artist',
              active: false,
              default: false,
            },
            {
              name: 'Detected',
              ascending: true,
              sortParam: 'detected',
              active: false,
              default: false,
            },
          ];
        case 'mainartist':
          return [
            {
              thinColumn: true,
            },
            {
              name: 'Name',
              ascending: true,
              sortParam: 'name',
              active: true,
              default: true,
            },
            {
              name: 'Detected',
              ascending: true,
              sortParam: 'detected',
              active: false,
              default: false,
            },
          ];
        default:
          throw new Error(`Unknown type: ${this.mergeType}`);
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.btn-positioning {
  position: absolute;
  top: 19px;
}
.tags {
  &__text {
    display: flex;
    align-items: center;
  }

  &__search {
    flex: 1;
  }
}
</style>
