import Vue from 'vue';
import _ from 'lodash';
import AuthenticationService from './authenticationService';
import { mutate, query } from './apolloRequest';
import docGql from '../domain/docRepoGql';
import gql from 'graphql-tag';
import { createError, ErrorType } from '../domain/starNotification';

const FILE_TYPE_MIME = {
  PDF: 'application/pdf',
  TXT: 'text/plain',
  CSV: 'text/csv',
  XML: 'text/xml',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

function isValidFileExtension(ext) {
  return !!ext && ext.toUpperCase() in FILE_TYPE_MIME;
}

const uploadConfigDefault = {
  headers: {},
  onUploadProgress: (progressEvent) => progressEvent,
};

const downloadDefaultConfig = {
  responseType: 'blob',
};

const spaceReplace = (str) => str.replace(/\s+/g, '_');

function createFilenameForDocumentRepository(uploadFileDescriptor) {
  if (uploadFileDescriptor.filename) {
    return spaceReplace(uploadFileDescriptor.filename);
  }
  const metaIdentifiersString = uploadFileDescriptor.metaIdentifiers
    .map((id) => id.toUpperCase())
    .join('_');
  return spaceReplace(
    `${uploadFileDescriptor.entityType.toUpperCase()}_${uploadFileDescriptor.entityId}_${
      uploadFileDescriptor.createdEpoch
    }__${metaIdentifiersString}.${uploadFileDescriptor.fileSuffix.toLowerCase()}`,
  );
}

function getUploadUrl(fileName, repo, directory, metadata) {
  return query(
    { query: docGql.getUploadUrl, variables: { fileName, repo, directory, metadata } },
    { pickProp: 'getUploadUrl' },
  );
}

function getFetchUrl(fileName, bucket) {
  return query(
    { query: docGql.getFetchUrl, variables: { fileName, bucket } },
    { pickProp: 'getFetchUrl' },
  );
}

function getStatementFetchUrl(fileName) {
  return query(
    { query: docGql.getStatementFetchUrl, variables: { fileName } },
    { pickProp: 'getStatementFetchUrl' },
  );
}

function getMissingReportersFetchUrl(fileName) {
  return query(
    { query: docGql.getMissingReportersFetchUrl, variables: { fileName } },
    { pickProp: 'getMissingReportersFetchUrl' },
  );
}

function getFeedbackFetchUrl(fileName) {
  return query(
    { query: docGql.getFeedbackFetchUrl, variables: { fileName } },
    { pickProp: 'getFeedbackFetchUrl' },
  );
}

function getDownloadManyUrl(filesEntry) {
  return query(
    { query: docGql.getDownloadManyUrl, variables: { filesEntry } },
    { pickProp: 'downloadMany' },
  );
}

function getInvoiceFetchUrl(fileName) {
  return query(
    { query: docGql.getInvoiceFetchUrl, variables: { fileName } },
    { pickProp: 'getInvoiceFetchUrl' },
  );
}

function getEndOfYearStatementFileFetchUrl(fileName) {
  return query(
    { query: docGql.getEndOfYearStatementFileFetchUrl, variables: { fileName } },
    { pickProp: 'getEndOfYearStatementFileFetchUrl' },
  );
}

function getStimFileFetchUrl(fileName) {
  return query(
    { query: docGql.getStimFileFetchUrl, variables: { fileName } },
    { pickProp: 'getStimFileFetchUrl' },
  );
}

function updateMetadata(fileName, metadata, bucket) {
  return mutate({
    mutation: docGql.updateMetadata,
    variables: { command: { fileName, bucket, metadata } },
  });
}

function createVotingRightsFile(processId) {
  return mutate({ mutation: docGql.createVotingRightsFile, variables: { processId } });
}

/**
 * Determine if the file exists at the specified path.
 * @param path the file path.
 * @returns true or false
 */
async function fileExist(path) {
  try {
    await Vue.axios.create().head(path);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return;
    }
    throw createError(ErrorType.NETWORK, `Request failed: ${error.response}`);
  }
  throw createError(ErrorType.FILE_EXIST, 'File already exists.');
}

/**
 * Download the binary file at the specified path.
 * @param path the file path
 * @param config the download config, defaults to { responseType: 'blob' }
 * @returns {Promise.<T>}
 */
async function downloadBinary(path, config = downloadDefaultConfig) {
  try {
    const result = await Vue.axios.create().get(path, config);
    return result.data;
  } catch (error) {
    throw createError(
      ErrorType.NETWORK,
      `Could not download ${path}`,
      JSON.stringify(error.response),
    );
  }
}

/**
 * Downloads the file data from the internal doc repo.
 * @param fileName the file name.
 * @param bucket. Default is internal doc repo
 * @returns {AxiosPromise}
 */
async function downloadFromDocumentRepository(fileName, bucket) {
  const downloadUrl = await getFetchUrl(fileName, bucket);
  return downloadBinary(downloadUrl);
}

async function downloadFromDocumentRepositoryAndUpdateMetadata(fileName, metadata, bucket) {
  await updateMetadata(fileName, metadata, bucket);
  return downloadFromDocumentRepository(fileName, bucket);
}

/**
 * Downloads the file data from the internal doc repo as a zip.
 * @param files the files.
 * @param downloadPrefix a name to prefix the downloaded file name with
 * @returns {AxiosPromise}
 */
async function downloadManyFromDocumentRepository(filesEntry, downloadPrefix) {
  const downloadUrl = await getDownloadManyUrl(filesEntry, downloadPrefix);
  return downloadBinary(downloadUrl);
}

async function downloadInvoice(fileName) {
  const downloadUrl = await getInvoiceFetchUrl(fileName);
  return downloadBinary(downloadUrl);
}

async function downloadEndOfYearStatementFile(fileName) {
  const downloadUrl = await getEndOfYearStatementFileFetchUrl(fileName);
  return downloadBinary(downloadUrl);
}

async function downloadStatementFile(fileName) {
  const downloadUrl = await getStatementFetchUrl(fileName);
  return downloadBinary(downloadUrl);
}

async function downloadMissingPerformersFile(fileName) {
  const downloadUrl = await getMissingReportersFetchUrl(fileName);
  return downloadBinary(downloadUrl);
}

async function onDownloadFeedbackFile(fileName) {
  const downloadUrl = await getFeedbackFetchUrl(fileName);
  return downloadBinary(downloadUrl);
}

async function downloadStimFile(fileName) {
  const downloadUrl = await getStimFileFetchUrl(fileName);
  return downloadBinary(downloadUrl);
}

/**
 * Uploads the file data to the specified path.
 * @param path the upload file path.
 * @param fileData the file data.
 * @param options { progressFunc: () => progressEvent }.
 * @returns {AxiosPromise}
 */
async function upload(path, fileData, options = { hideUser: false }) {
  const config = _.cloneDeep(uploadConfigDefault);
  // add optional metadata
  if (Array.isArray(options.metadata)) {
    options.metadata.forEach(({ key, value }) => {
      config.headers[`x-amz-meta-${key}`] = `${value}`;
    });
  } else if (typeof options.metadata === 'object') {
    Object.entries(options.metadata).forEach(([key, value]) => {
      config.headers[`x-amz-meta-${key}`] = `${value}`;
    });
  }
  if (!options.hideUser) {
    config.headers['x-amz-meta-uploaded-by'] = AuthenticationService.getUserName();
  }
  if (options.progressFunc) {
    config.onUploadProgress = options.progressFunc;
  }
  if (options.fileType) {
    config.headers['content-type'] = FILE_TYPE_MIME[options.fileType];
  }
  try {
    await Vue.axios.create().put(path, fileData, config);
  } catch (error) {
    throw createError(ErrorType.NETWORK, 'Upload failed', error.response);
  }
}

/**
 * Uploads the file data to the doc repo.
 * @param uploadFileDescriptor the file descriptor.
 * @param options { progressFunc: () => progressEvent }.
 * @returns {AxiosPromise}
 */
async function uploadToDocumentRepository(uploadFileDescriptor, options = {}) {
  const fileName = createFilenameForDocumentRepository(uploadFileDescriptor);
  const uploadUrl = await getUploadUrl(fileName, options.repo, options.directory, options.metadata);
  await upload(
    uploadUrl,
    uploadFileDescriptor.localFile,
    Object.assign(options, { fileType: uploadFileDescriptor.fileSuffix.toUpperCase() }),
  );
  return fileName;
}

/**
 * List files in doc repo.
 * @param prefix the file prefix to search for.
 * @param bucket. Default is internal doc repo
 * @returns {AxiosPromise}
 */
async function listDocumentRepository(prefix, bucket) {
  return query(
    { query: docGql.listFiles, variables: { prefix, bucket } },
    { pickProp: 'listFiles' },
  );
}

/**
 * Deletes a file from the internal doc repo.
 * @param fileName the file to delete.
 * @returns {AxiosPromise}
 */
async function deleteFromDocumentRepository(filename) {
  return mutate({ mutation: docGql.deleteFile, variables: { filename } });
}

async function getFileInfo(key) {
  return query(
    {
      query: gql`
      query {
        fileInfo(key: "${key}") {
          lastModified
          size
          metadata
        }
      }
    `,
    },
    { pickProp: 'fileInfo' },
  );
}

export default {
  ErrorType,
  fileExist,
  upload,
  uploadToDocumentRepository,
  updateMetadata,
  downloadFromDocumentRepository,
  downloadManyFromDocumentRepository,
  downloadInvoice,
  downloadEndOfYearStatementFile,
  getEndOfYearStatementFileFetchUrl,
  downloadBinary,
  downloadStatementFile,
  downloadMissingPerformersFile,
  onDownloadFeedbackFile,
  listDocumentRepository,
  deleteFromDocumentRepository,
  downloadStimFile,
  getStimFileFetchUrl,
  downloadFromDocumentRepositoryAndUpdateMetadata,
  createVotingRightsFile,
  getFileInfo,
  isValidFileExtension,
};
