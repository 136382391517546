<template>
  <div class="cmp">
    <div class="row">
      <div class="col s12">
        <div v-if="showTabs" class="tabs">
          <div class="tabs__wrapper">
            <a
              :class="{ active: selectedCategory === 'person' }"
              @click="changeSelectedCategory('person')"
            >
              Person
            </a>
            <a
              :class="{ active: selectedCategory === 'organisation' }"
              @click="changeSelectedCategory('organisation')"
            >
              Organisation
            </a>
          </div>
        </div>
      </div>
    </div>

    <create-person-info v-if="selectedCategory === 'person'" :scope="scope" />

    <create-organisation-info v-if="selectedCategory === 'organisation'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CreatePersonInfo from './create-person-info';
import CreateOrganisationInfo from './create-organisation-info';
import { AssociateType } from '../../../domain/associateDomain';

export default {
  name: 'CreateGeneralInfo',
  components: {
    CreatePersonInfo,
    CreateOrganisationInfo,
  },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedCategory: '',
    };
  },
  computed: {
    ...mapGetters('associate', ['type']),
    showTabs() {
      return (
        this.type !== AssociateType.COMPANY &&
        this.type !== AssociateType.ACCOUNTING_FIRM &&
        this.type !== AssociateType.LEGAL_GUARDIAN
      );
    },
  },
  watch: {
    type() {
      this.setSelectedCategory();
    },
  },
  created() {
    this.setSelectedCategory();
  },
  methods: {
    changeSelectedCategory(category) {
      this.selectedCategory = category;
      this.$emit('updateSelectedCategory', category);
    },
    setSelectedCategory() {
      const category =
        this.type === AssociateType.COMPANY || this.type === AssociateType.ACCOUNTING_FIRM
          ? 'organisation'
          : 'person';
      this.selectedCategory = category;
      this.$emit('updateSelectedCategory', category);
    },
  },
};
</script>
