<template>
  <li class="compare__list-item">
    <div class="compare__incoming">
      <strong>{{ label }}</strong>
      <span v-if="incoming" class="compare__incoming-value">
        {{ incoming }}
      </span>
      <span v-else class="compare__incoming-value none">None</span>
    </div>
    <div class="compare__candidate">
      <strong>{{ label }}</strong>
      <div v-if="!candidate" class="compare__candidate-value none">None</div>
      <div v-else class="compare__candidate-value">
        <span v-if="candidate">
          <a v-if="link !== ''" :href="`${link}`">
            {{ candidate }}
          </a>
          <span v-else>
            {{ candidate }}
          </span>
        </span>
        <span v-else class="none">None</span>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'CompareStatementGridRow',
  props: {
    label: {
      type: String,
      required: true,
    },
    incoming: {
      type: String,
      default: '',
    },
    candidate: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
