<template>
  <div v-if="status">
    <div :class="colorClass">
      <i :class="iconClass" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatusIcon',
  props: {
    status: {
      type: String,
    },
  },
  data() {
    return {
      colorClassMap: {
        Green: 'success',
        Yellow: 'warning',
        Red: 'error',
      },
      iconClassMap: {
        Green: 'fa-check',
        Yellow: 'fa-exclamation',
        Red: 'fa-times',
      },
    };
  },
  computed: {
    colorClass() {
      return `status__icon ${this.colorClassMap[this.status]}`;
    },
    iconClass() {
      return `fas ${this.iconClassMap[this.status]}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  &__banner {
    position: relative;
    padding: 5px var(--spacing-quadruple);
    width: 100%;
    border-radius: 2px;
    color: var(--white);
    font-weight: bold;

    &.success {
      background-color: var(--alert--success) !important;
    }

    &.warning {
      background-color: var(--alert--warning) !important;
    }

    &.error {
      background-color: var(--alert--error) !important;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    color: var(--white);
    text-align: center;
    display: block;

    > i {
      display: block;
      line-height: 16px;
      font-size: 10px;
      font-weight: bold;
      width: 16px;
    }

    &.success {
      background-color: var(--alert--success) !important;
    }

    &.warning {
      background-color: var(--alert--warning) !important;
    }

    &.error {
      background-color: var(--alert--error) !important;
    }
  }
}
</style>
