<template>
  <div>
    <label v-if="showLabel" class="label" :class="{ 'label--error': showErrorMessage }">
      {{ label }}
      <span v-if="mandatory" class="mandatory">(mandatory)</span>
    </label>
    <input
      :id="`${name}-validated-input`"
      ref="inputField"
      v-validate="rule"
      :autocomplete="autocomplete"
      :data-vv-as="label.toLowerCase()"
      :name="name"
      :type="type ? type : 'text'"
      :style="cssProps"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      @input="updateValue"
      @blur="$emit('blur')"
      @keydown.enter.prevent
    />
    <slot name="input:after"></slot>
    <span v-show="showErrorMessage" class="help is-danger">
      <i class="fas fa-times-circle"></i>
      {{ validationErrors.first(name, scope) }}
    </span>
    <slot name="after"></slot>
  </div>
</template>

<script>
export default {
  name: 'ValidatedTextInput',
  inject: ['$validator'],
  props: {
    autocomplete: {
      type: String,
      default: 'off',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Number, String],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    scope: {
      type: String,
      default: undefined,
    },
    rule: {
      type: [String, Object],
      default: '',
    },
    maxlength: {
      type: Number,
      default: undefined,
    },
    type: {
      type: String,
    },
    cssProps: {
      type: String,
      default: '',
    },
    inputFilter: {
      type: Function,
      default: undefined,
    },
  },
  computed: {
    showErrorMessage() {
      if (this.validationErrors.has(this.name, this.scope)) {
        return true;
      }
      const field = this.validationFields[this.name];
      return field && field.touched && field.invalid;
    },
  },
  mounted() {
    if (this.autofocus === 'true') {
      this.$refs.inputField.focus();
    }
  },
  methods: {
    updateValue(event) {
      var value = event.target.value;

      value = this.inputFilter ? this.inputFilter(value) : value;

      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  display: flex;
  flex-wrap: wrap;
}

label {
  flex-basis: 100%;
}

input {
  flex-basis: 100%;
}
</style>
