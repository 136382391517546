import Axios from 'axios';
import VueAxios from 'vue-axios';
// Apollo & axios conf
import axiosConfig from './axiosConfiguration';

export default {
  install(Vue) {
    Vue.use(VueAxios, Axios);
    Vue.$http = axiosConfig(Axios);
  },
};
