import luhn from 'luhn';
import store from '../store/index';

function getBanks() {
  return store.getters['appdata/banks'] || [];
}

export function isInInterval(_nbr, interval) {
  const nbr = parseInt(_nbr, 10);
  let [from, to] = interval.split('-');
  from = parseInt(from, 10);
  to = parseInt(to, 10);
  if (!to) {
    return nbr === from;
  }
  return nbr >= from && nbr <= to;
}

export function getMatchingAccountFormat(clearingNbr, bank) {
  const format = bank.account_formats.find((af) => clearingNbr === af.clearing_nos);
  if (format) {
    return format;
  }
  return bank.account_formats.find((af) => isInInterval(clearingNbr, af.clearing_nos));
}

export function isSSN(value) {
  return value.replace(/\D/g, '').length === 10 && luhn.validate(value.replace(/\D+/g, ''));
}

export function getBankByClearingNbr(clearingNbr) {
  if (clearingNbr) {
    return getBanks().find(
      (bank) =>
        getMatchingAccountFormat(clearingNbr.replace(/ /g, '').replace(/-/g, ''), bank) !==
        undefined,
    );
  }
  return null;
}

export function isSSNAccount(clearingNbr) {
  const bank = getBankByClearingNbr(clearingNbr);
  if (bank) {
    const format = getMatchingAccountFormat(clearingNbr, bank);
    return format && format.ssn_account;
  }
  return false;
}

export function isValidClearingNumber(clearingNbr) {
  return !!getBankByClearingNbr(clearingNbr);
}

export function isValidAccount(clearingNbr, accountNbr) {
  const cleanedClearingNbr = clearingNbr.replace(/ /g, '').replace(/-/g, '');
  const bank = getBankByClearingNbr(cleanedClearingNbr);

  if (!bank) {
    return false;
  }

  const accountFormat = getMatchingAccountFormat(cleanedClearingNbr, bank);

  if (accountFormat.account_no_max_digits) {
    if (accountFormat.pad_left) {
      return accountNbr.length >= 7;
    }
    return accountNbr.length <= accountFormat.account_no_max_digits;
  }

  if (accountFormat.ssn_account) {
    return isSSN(accountNbr);
  }

  return false;
}
