import { render, staticRenderFns } from "./progress-release-of-reserves-confirmed.vue?vue&type=template&id=1890e9bf&scoped=true"
import script from "./progress-release-of-reserves-confirmed.vue?vue&type=script&lang=js"
export * from "./progress-release-of-reserves-confirmed.vue?vue&type=script&lang=js"
import style0 from "./progress-release-of-reserves-confirmed.vue?vue&type=style&index=0&id=1890e9bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1890e9bf",
  null
  
)

export default component.exports