<template>
  <div class="right-column row">
    <div class="col s12">
      <div class="tabs">
        <div class="tabs__wrapper">
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'general' }"
            @click="selectedTab = 'general'"
          >
            General
          </div>
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'activity' }"
            @click="selectedTab = 'activity'"
          >
            Activity
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'general'">
        <tags
          v-if="!isEditMode('Tags')"
          :tags="mainArtist.basic_info.tags"
          :readonly="mainArtist.readonly"
          @edit="onEdit"
        />

        <div v-else>
          <form class="pos-relative">
            <div class="row">
              <div class="col s3">
                <h2>Tags</h2>
              </div>
              <div class="text--right col s9">
                <action-buttons @save="onSaveBasicInfoTags" @cancel="onEditCancel" />
              </div>
            </div>

            <div class="row card">
              <div class="col s12">
                <searchable-tag-input
                  name="basic_info.tags"
                  label="Tags"
                  placeholder="E.g. Top 100"
                  :preview-list="previewTags"
                  :searcher="searchTag"
                  :value-list="localTags"
                  use-template="tag"
                  scope="edit-mainartist"
                  @add="addTag"
                  @remove="removeTagAt"
                />
              </div>
            </div>
          </form>
        </div>

        <notes :id="aggregateId" :readonly="mainArtist.readonly" />

        <view-external-sources
          v-if="!isEditMode('ExternalSources')"
          :main-artist="mainArtist"
          :readonly="mainArtist.readonly"
          @edit="onEdit"
        />

        <edit-external-sources
          v-else
          :main-artist="mainArtistCopy"
          @save="onSaveBasicInfo"
          @cancel="onEditCancel"
        />
      </div>
      <div v-else>
        <activity
          :stream-id="aggregateId"
          :version="aggregateVersion"
          view-name="mainArtistInformationVersion"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Activity from '../ui/activity';
import EditExternalSources from './edit/edit-external-sources';
import Notes from '../ui/input/notes';
import Tags from '../tags/tags';
import ViewExternalSources from './view/external-sources';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import SearchHelper from '../search/searchHelper';
import ActionButtons from '../ui/button/action-buttons';

export default {
  name: 'RightColumn',
  components: {
    Activity,
    EditExternalSources,
    Notes,
    ViewExternalSources,
    Tags,
    SearchableTagInput,
    ActionButtons,
  },
  props: {
    aggregateId: {
      type: String,
      required: true,
    },
    aggregateVersion: {
      type: Number,
    },
    editableComponent: {
      type: String,
      default: '',
    },
    mainArtist: {
      type: Object,
      default: () => null,
    },
    mainArtistCopy: {
      type: Object,
      default: () => null,
    },
    preSelectedTab: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      selectedTab: this.preSelectedTab,
      localTags: [],
      allTags: [],
    };
  },
  computed: {
    previewTags() {
      return this.allTags.filter((tag) =>
        this.localTags.length > 0
          ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
          : true,
      );
    },
  },
  async created() {
    this.localTags = this.copyTags(this.mainArtist.basic_info.tags);
    this.allTags = await SearchHelper.getAllTags();
  },
  methods: {
    onEdit(view) {
      this.$emit('onEdit', view);
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onSaveBasicInfo(mainArtist) {
      this.$emit('onSaveBasicInfo', mainArtist);
    },
    onSaveBasicInfoTags() {
      this.$emit('onSaveBasicInfo', this.mainArtist);
    },
    onEditCancel() {
      this.$emit('onEditCancel');
    },
    copyTags(tags) {
      return tags.map((tag) => ({ name: tag, type: 'tag' }));
    },
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.localTags = event.data;
      this.mainArtist.basic_info.tags.push(event.item.name);
    },
    removeTagAt(event) {
      this.mainArtist.basic_info.tags.splice(event.idx, 1);
    },
  },
};
</script>
