<template>
  <div>
    <strong>Deadline</strong>
    <a v-if="!editDeadline" class="margin--left" @click="edit">
      <i class="fa fa-edit" />
    </a>
    <div v-if="editDeadline" class="row">
      <select-date
        v-model="newDeadline"
        class="col s6"
        name="deadline"
        :rule="{ required: true }"
      />
      <button class="btn tertiary" @click="cancelEdit">Cancel</button>
      <button class="btn" @click="update">OK</button>
    </div>
    <div v-else :class="deadlinePassed() ? 'is-danger text--bold' : ''">
      <span v-if="deadline">{{ deadline }}</span>
      <span v-else class="none">None</span>
    </div>
  </div>
</template>

<script>
import SelectDate from '@/components/ui/select/select-date.vue';
import * as moment from 'moment/moment';

export default {
  components: { SelectDate },
  props: {
    deadline: {
      type: String,
    },
    finished: {
      type: Boolean,
    },
  },
  data() {
    return {
      editDeadline: false,
      newDeadline: '',
    };
  },
  methods: {
    deadlinePassed() {
      if (this.finished) {
        return false;
      }
      return moment(this.deadline).isBefore(moment());
    },
    edit() {
      this.newDeadline = this.deadline;
      this.editDeadline = true;
    },
    cancelEdit() {
      this.editDeadline = false;
      this.newDeadline = this.deadline;
    },
    update() {
      this.editDeadline = false;
      this.$emit('update', this.newDeadline);
    },
  },
};
</script>
