import gql from 'graphql-tag';

const listUsers = gql`
  query allUsers {
    allUsers {
      users {
        name
        type
      }
    }
  }
`;

export default {
  listUsers,
};
