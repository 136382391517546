<template>
  <div>
    <div class="row flush--bottom">
      <div class="col s8">
        <div class="row">
          <div
            v-if="relationType === 'childAssociations' && associate._new"
            class="col s12 push--top"
          >
            <input
              :id="`inheritsFromPerformer.${relationType}.${index}`"
              type="radio"
              :name="`inheritsFromPerformer.${relationType}.${index}`"
              :checked="inheritsFromAssociate === false"
              @change="onChangeInheritsType(false)"
            />
            <label :for="`inheritsFromPerformer.${relationType}.${index}`">Performer</label>
            <template v-if="type !== AssociateType.HEIR">
              <input
                :id="`inheritsFromAssociate.${relationType}.${index}`"
                type="radio"
                :name="`inheritsFromAssociate.${relationType}.${index}`"
                :checked="inheritsFromAssociate === true"
                @change="onChangeInheritsType(true)"
              />
              <label :for="`inheritsFromAssociate.${relationType}.${index}`">Associate</label>
            </template>
          </div>
        </div>
        <div class="row">
          <template v-if="associate._new">
            <div v-if="relationType === 'associates' || inheritsFromAssociate" class="col s4">
              <select-associate-type
                :value="associate.type"
                :rule="{ required: formHasValue }"
                :associate-types="filteredAssociates"
                @input="onTypeChange({ type: $event })"
              />
            </div>
            <div class="col s8">
              <searchable-input
                ref="name"
                placeholder="Name, social security number or IPN"
                class="e2e-associate-child-search"
                :name="`name.${relationType}.${index}`"
                :label="
                  relationType === 'associates' || inheritsFromAssociate
                    ? 'Search name'
                    : 'Add a performer'
                "
                :searcher="
                  relationType === 'associates' || inheritsFromAssociate
                    ? SearchHelper.searchAssociatesByType
                    : SearchHelper.nameSearchPerformer
                "
                :searcher-extra-args="[associate.type]"
                :use-template="
                  relationType === 'associates' || inheritsFromAssociate
                    ? 'associate'
                    : 'performerNameSearch'
                "
                :value="associate.name"
                scope="associates"
                :rule="{ required: required || formHasValue }"
                @input="onSearchInput"
              />
            </div>
          </template>
          <template v-else>
            <div class="col s12">
              <h3 class="push-half--top">{{ associate.name }} ({{ associate.type }})</h3>
            </div>
          </template>
        </div>
      </div>
      <div v-if="editMode" class="col s4" :class="{ 'float-right': true }">
        <action-buttons
          v-if="editMode"
          class="float-right"
          :disable-submit="!formHasValue || hasPaymentInfo"
          @save="$emit('save', associate.id)"
          @cancel="onCancel"
        />
      </div>
      <div v-if="hasPaymentInfo" class="col s3" :class="{ 'float-right': true }">
        <span class="help is-danger float-right">
          <i class="fas fa-times-circle" />
          Can't add an associate with payment information
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col s3">
        <select-date
          v-if="associate._new"
          label="Start date"
          :name="`startDate.${relationType}.${index}`"
          :scope="scope"
          :value="associate.start_date"
          :rule="{ required: formHasValue }"
          @input="onDateInput({ start_date: $event })"
        />
        <template v-else>
          <label for="start-date-locked">Start date</label>
          <div id="start-date-locked" :class="{ 'push-half--top': true }">
            {{ associate.start_date }}
          </div>
        </template>
      </div>
      <div class="col s3">
        <select-date
          label="End date"
          :name="`endDate.${relationType}.${index}`"
          :scope="scope"
          :rule="{
            required: endDateRequired(associate),
            after: associate.start_date ? associate.start_date : false,
          }"
          :value="associate.end_date"
          @input="onDateInput({ end_date: $event })"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s3" style="padding-top: var(--spacing-triple)">
        <input
          :id="`isRecipient.${relationType}.${index}`"
          v-validate="paymentInfoRules(associate)"
          type="checkbox"
          class="filled-in"
          :name="`isRecipient.${relationType}.${index}`"
          data-vv-as="recipient of money"
          :checked="associate.is_recipient_of_money"
          :disabled="
            !associate._new ||
            associate.type === AssociateType.COMPANY ||
            recipientOfMoneyRequiredOnChild()
          "
          @input="onMoneyCheckboxInput"
        />
        <label :for="`isRecipient.${relationType}.${index}`" class="e2e-recipient-of-money">
          <i class="push-half--right fas fa-dollar-sign" />
          Recipient of money
        </label>
        <span
          v-show="validationErrors.has(`isRecipient.${relationType}.${index}`, 'associates')"
          class="help is-danger"
        >
          <i class="fas fa-times-circle" />
          {{ validationErrors.first(`isRecipient.${relationType}.${index}`, 'associates') }}
        </span>
      </div>
      <div class="col s2">
        <validated-text-input
          v-if="associate._new && isShareForHeir(associate)"
          class="e2e-associate-child-share"
          placeholder="E.g. 1/3"
          label="Share"
          :scope="scope"
          :name="`share.${relationType}.${index}`"
          :disabled="associate.is_recipient_of_money === false"
          :rule="{ required: true, share: true }"
          :value="associate.share"
          @input="patchState({ share: $event })"
          @blur="patchState({ share: shareAsFraction(associate.share) })"
        />
        <template v-else>
          <label for="share-locked">Share</label>
          <div
            id="share-locked"
            :class="{ 'push-half--top': true, disabled: associate.is_recipient_of_money === false }"
          >
            {{ associate.share }}
          </div>
        </template>
      </div>
    </div>
    <div class="row flush--bottom">
      <div class="col s8">
        <input
          :id="`viewAccess.${relationType}.${index}`"
          type="checkbox"
          class="filled-in"
          :name="`viewAccess.${relationType}.${index}`"
          :checked="associate.access_policy && associate.access_policy !== ACCESS_POLICIES.NONE"
          :disabled="!isActive(associate) || associate.access_policy === ACCESS_POLICIES.WRITE"
          @click="setAccess($event, index, ACCESS_POLICIES.READ)"
        />
        <label :for="`viewAccess.${relationType}.${index}`">
          <i class="push-half--right far fa-eye" />
          Give read access on performer account
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col s8">
        <input
          :id="`editAccess.${relationType}.${index}`"
          type="checkbox"
          class="filled-in"
          :disabled="!isActive(associate)"
          :name="`editAccess.${relationType}.${index}`"
          :checked="associate.access_policy === ACCESS_POLICIES.WRITE"
          @click="setAccess($event, index, ACCESS_POLICIES.WRITE)"
        />
        <label :for="`editAccess.${relationType}.${index}`">
          <i class="push-half--right fas fa-pencil-alt" />
          Give write access on performer account
        </label>
      </div>
    </div>

    <delete-button v-if="!editMode" :is-component="true" @click="onRemove" />
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import Fraction from 'fraction.js';
import moment from 'moment';
import { ACCESS_POLICIES, AssociateType } from '../../../domain/associateDomain';
import ActionButtons from '../button/action-buttons';
import AssociatesMixin from './associates-mixin';
import SearchableInput from '../input/searchable-input';
import SearchHelper from '../../search/searchHelper';
import SelectAssociateType from '../select/select-associate-type';
import SelectDate from '../select/select-date';
import ValidatedTextInput from '../input/validated-text-input';
import DeleteButton from '../../ui/button/delete-button';

export default {
  name: 'EditAssociate',
  components: {
    ActionButtons,
    SearchableInput,
    SelectAssociateType,
    SelectDate,
    ValidatedTextInput,
    DeleteButton,
  },
  mixins: [AssociatesMixin],
  inject: ['$validator'],
  props: {
    associate: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inheritsFromAssociate: false,
      ACCESS_POLICIES,
      AssociateType,
      SearchHelper,
      associateTypes: Object.keys(AssociateType).map((oKey) => ({
        key: oKey,
        value: Vue.$i18n.t(`common.${AssociateType[oKey].toLowerCase()}`),
      })),
      filteredAssociates: [],
    };
  },
  computed: {
    ...mapGetters('performer', ['dateOfDeath', 'privateAccount', 'foreignAccount']),
    ...mapGetters('associate', ['type']),
    formHasValue() {
      const a = this.associate;
      return !!(
        a.name ||
        a.start_date ||
        a.end_date ||
        a.id ||
        (this.namespace !== 'associate' && a.type)
      );
    },
    hasPaymentInfo() {
      // workaround to make it impossible to save an associate where performer party has payment info
      if (
        ((this.namespace === 'associate' || this.associate.type === 'PERFORMER') &&
          this.aggregate.type === 'COMPANY') ||
        (this.associate.type === 'COMPANY' && this.namespace === 'performer')
      ) {
        if (this.associate.type === 'PERFORMER') {
          return this.associate.has_payment_info;
        }
        if (this.associate.type === 'COMPANY') {
          const paymentInfo = this.aggregate?.payment_info;
          return !!(
            paymentInfo.private_account?.account_number ||
            paymentInfo.foreign_account?.account_number ||
            paymentInfo.company_account?.account_number
          );
        }
      }
      return false;
    },
  },
  watch: {
    async type() {
      this.filterAssociateTypes();
    },
    dateOfDeath() {
      this.filterAssociateTypes();
    },
  },
  created() {
    this._initialState = _.cloneDeep(this.associate);
    this.filterAssociateTypes();
    if (this.recipientOfMoneyRequiredOnChild()) {
      this.associate.is_recipient_of_money = true;
      this.associate.share = '1';
    }
  },
  methods: {
    ...mapMutations({
      replaceInChildAssociations(commit, payload) {
        return commit('associate/replaceInChildAssociations', payload);
      },
    }),
    filterAssociateTypes() {
      if (this.relationType === 'childAssociations') {
        this.filterChildAssociateTypes();
      } else {
        this.filterParentAssociateTypes();
      }
    },
    filterParentAssociateTypes() {
      if (this.namespace === 'associate') {
        this.filterAssociateParentAssociates();
      } else {
        this.filterPerformerParentAssociates();
      }
    },
    filterChildAssociateTypes() {
      if (
        this.type === AssociateType.OTHER ||
        this.type === AssociateType.LEGAL_GUARDIAN ||
        this.type === AssociateType.COMPANY
      ) {
        this.filteredAssociates = this.associateTypes.filter(
          (assType) => assType.key === AssociateType.HEIR,
        );
      } else {
        this.filteredAssociates = this.associateTypes.filter(
          (assType) => assType.key === AssociateType.HEIR || assType.key === AssociateType.COMPANY,
        );
      }
    },
    filterAssociateParentAssociates() {
      if (this.type === AssociateType.HEIR) {
        this.filteredAssociates = this.associateTypes.filter(
          (assType) => assType.key !== AssociateType.HEIR,
        );
      } else {
        this.filteredAssociates = this.associateTypes.filter(
          (assType) =>
            assType.key !== AssociateType.COMPANY &&
            assType.key !== AssociateType.HEIR &&
            assType.key !== AssociateType.LEGAL_GUARDIAN &&
            assType.key !== AssociateType.OTHER,
        );
      }
    },
    filterPerformerParentAssociates() {
      if (
        this.dateOfDeath ||
        (this.aggregate.general_info && this.aggregate.general_info.date_of_death)
      ) {
        this.filteredAssociates = this.associateTypes;
      } else {
        this.filteredAssociates = this.associateTypes.filter(
          (assType) => assType.key !== AssociateType.HEIR,
        );
      }
    },
    recipientOfMoneyRequiredOnChild() {
      if (this.relationType === 'childAssociations') {
        if (
          this.aggregate.type === AssociateType.COMPANY ||
          this.aggregate.type === AssociateType.HEIR
        ) {
          return true;
        }
      }
      return false;
    },
    paymentInfoRules() {
      let rule = { required: false };
      if (this.recipientOfMoneyRequiredOnChild()) {
        rule = { required: true };
      } else if (this.relationType === 'childAssociations') {
        rule = { required: this.isHeirOrCompany(this.aggregate) };
      }
      if (this.relationType === 'associates') {
        return Object.assign(
          {},
          {
            paymentReceiverAllowedOnChild: this.associate.is_recipient_of_money
              ? { private: this.privateAccount, foreign: this.foreignAccount }
              : true,
          },
          rule,
        );
      }
      return Object.assign(
        {},
        {
          paymentReceiverAllowedOnParent:
            this.associate && this.associate.has_payment_info
              ? this.associate.has_payment_info
              : false,
        },
        rule,
      );
    },
    onChangeInheritsType(fromAssociate) {
      this.inheritsFromAssociate = fromAssociate;
      const emptyAssociate = {
        id: null,
        has_payment_info: false,
        is_recipient_of_money: this.recipientOfMoneyRequiredOnChild(),
        name: null,
        type: null,
      };
      this.patchState(emptyAssociate);
    },
    patchState(patch) {
      if (this.relationType === 'associates') {
        this.replaceInAssociates({
          value: Object.assign({}, this.associate, patch),
          index: this.index,
        });
      } else {
        this.replaceInChildAssociations({
          value: Object.assign({}, this.associate, patch),
          index: this.index,
        });
      }
    },
    shareAsFraction(share = this.associate.share) {
      try {
        return Fraction(share).toFraction();
      } catch (e) {
        return '';
      }
    },
    onSearchInput(e) {
      const patch = {};
      if (e) {
        patch.id = e.id;
        patch.name = e.name;
        patch.type = e.associate_type ? e.associate_type.toUpperCase() : e.type.toUpperCase();
        patch.has_payment_info = e.has_payment_info;
      } else {
        patch.id = null;
        patch.name = null;
      }
      if (this.isHeirOrCompany(patch) || this.recipientOfMoneyRequiredOnChild()) {
        patch.is_recipient_of_money = true;
      }
      this.patchState(patch);
    },
    onMoneyCheckboxInput(e) {
      this.patchState({
        is_recipient_of_money: e.target.checked,
        share: '1',
      });
    },
    onDateInput(patch) {
      if (!this.isActive(Object.assign({}, this.associate, patch))) {
        patch.access_policy = ACCESS_POLICIES.NONE;
      }
      this.patchState(patch);
    },
    onTypeChange(patch) {
      patch.name = null;
      patch.id = null;
      if (
        (this.isActive(this.associate) && this.isHeirOrCompany(patch)) ||
        this.recipientOfMoneyRequiredOnChild()
      ) {
        patch.is_recipient_of_money = true;
      } else if (patch.type === AssociateType.COMPANY) {
        patch.is_recipient_of_money = true;
      } else {
        patch.is_recipient_of_money = false;
      }
      if (patch.type !== AssociateType.HEIR) {
        patch.share = '1';
      }
      this.patchState(patch);
    },
    onCancel(associate) {
      this.patchState(this._initialState);
      this.$emit('cancel', associate);
    },
    isActive(patch, includeFuture = true) {
      const startDate = Object.prototype.hasOwnProperty.call(patch, 'start_date')
        ? patch.start_date
        : this.associate.start_date;
      if (includeFuture) {
        // consider active if in future
        if (moment().isBefore(startDate)) {
          return true;
        }
      }

      const endDate = Object.prototype.hasOwnProperty.call(patch, 'end_date')
        ? patch.end_date
        : this.associate.end_date;
      return moment().isBetween(startDate, !endDate ? moment().endOf('day') : endDate, 'day', '[]');
    },
    isShareForHeir(associate) {
      const selfType = this.aggregate.type;
      const editingType = associate.type;
      const selfIsParent = this.relationType === 'childAssociations';
      if (selfIsParent) {
        return selfType === AssociateType.HEIR;
      }
      return editingType === AssociateType.HEIR;
    },
    endDateRequired(associate) {
      return !!associate.id && !!associate.is_recipient_of_money && !associate.share;
    },
    isHeirOrCompany(associate) {
      if (!associate) {
        return false;
      }
      return associate.type === AssociateType.HEIR || associate.type === AssociateType.COMPANY;
    },
    setAccess(e, i, policy) {
      if (e.target.checked) {
        this.patchState({ access_policy: policy });
      } else {
        this.patchState({ access_policy: ACCESS_POLICIES.NONE });
      }
    },
    onRemove(index) {
      this.$emit('remove', index);
    },
  },
};
</script>
