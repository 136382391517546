<template>
  <div>
    <select-input
      name="selectMonth"
      :disabled="disabled"
      :label="label"
      :value="month"
      :items="months"
      item-key="id"
      item-value="name"
      rule="required"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from './select-input';

const monthsByIdAndName = [
  { id: 0, name: 'January' },
  { id: 1, name: 'February' },
  { id: 2, name: 'March' },
  { id: 3, name: 'April' },
  { id: 4, name: 'May' },
  { id: 5, name: 'June' },
  { id: 6, name: 'July' },
  { id: 7, name: 'August' },
  { id: 8, name: 'September' },
  { id: 9, name: 'October' },
  { id: 10, name: 'November' },
  { id: 11, name: 'December' },
];
const currentMonthIndex = new Date().getMonth();

export default {
  name: 'SelectMonth',
  components: { SelectInput },
  props: {
    value: { type: Number, default: currentMonthIndex },
    startMonth: { type: Number, default: 0 },
    endMonth: { type: Number, default: 11 },
    label: { type: String, default: 'Month' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      month: this.maxMonth(this.value),
      months: [],
    };
  },
  watch: {
    startMonth() {
      this.prepareMonths();
    },
    endMonth() {
      this.prepareMonths();
    },
    value(month) {
      this.$nextTick(() => (this.month = this.maxMonth(month)));
    },
  },
  mounted() {
    this.months = this.prepareMonths();
  },
  methods: {
    maxMonth(val) {
      return val < 11 ? val : 11;
    },
    prepareMonths() {
      this.$nextTick(
        () =>
          (this.months = monthsByIdAndName.slice(
            this.startMonth,
            this.maxMonth(this.endMonth) + 1,
          )),
      );
    },
    onInput(value) {
      if (value !== '') {
        this.$emit('input', Number(value));
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
