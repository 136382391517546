<template>
  <div class="e2e-create-associate">
    <div class="cmp">
      <div class="row">
        <div class="col s2 hide-on-med-and-down">
          <entity-tag />
        </div>
        <div class="col s12 l7">
          <div class="row">
            <div class="col s12">
              <h1>Create Associate</h1>
            </div>
          </div>

          <form :data-vv-scope="scope">
            <div class="row card">
              <div class="col s8">
                <select-associate-type
                  :mandatory="true"
                  :rule="{ required: true }"
                  :value="type"
                  :associate-types="associateTypes"
                  @input="updateType"
                />
              </div>
            </div>
            <create-menu entity-type="associate" @create="createAndNavigateToView" />
            <div v-show="type">
              <create-general-info
                :scope="scope"
                @updateSelectedCategory="onUpdateSelectedCategory"
              />
              <create-contact-info :selected-category="selectedCategory" />
              <create-payment-information
                :private-account="privateAccount"
                :company-account="companyAccount"
                :foreign-account="foreignAccount"
                :company-account-visible="selectedCategory === 'organisation'"
                @resetPaymentInfo="resetPaymentInfo"
                @addPrivateAccount="commitChange('addPrivateAccount', $event)"
                @addCompanyAccount="commitChange('addCompanyAccount', $event)"
                @addForeignAccount="commitChange('addForeignAccount', $event)"
              />
              <create-associates
                v-if="type === 'COMPANY' || type === 'HEIR'"
                relation-type="associates"
                namespace="associate"
              />
              <create-child-associations
                v-if="type"
                relation-type="childAssociations"
                namespace="associate"
                :required="true"
              />
              <create-tax-info :scope="scope" />
              <create-meta-information />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { commit, dispatch, getAggregate } from '../../../store/modules/associate/utils';
import { AGGREGATE_TYPES } from '../../../domain/common';
import { AssociateType } from '../../../domain/associateDomain';
import AssociateService from '../../../services/associateService';
import CreateAssociates from '../../ui/associates/create-associates';
import CreateChildAssociations from '../../ui/associates/create-child-associations';
import CreateContactInfo from './create-contact-info';
import CreateGeneralInfo from './create-general-info';
import CreateMenu from '../../ui/create-menu';
import CreateMetaInformation from './create-meta-info';
import CreatePaymentInformation from '../../ui/payment/create-payment-information';
import CreateTaxInfo from './create-tax-info';
import delay from '../../../common/delay';
import EntityTag from '../../ui/entity-tag';
import SelectAssociateType from '../../ui/select/select-associate-type';
import UploadDocuments from '../../../services/uploadDocuments';

export default {
  name: 'CreateAssociate',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    CreateChildAssociations,
    CreateAssociates,
    CreateContactInfo,
    CreateGeneralInfo,
    CreateMenu,
    CreateMetaInformation,
    CreatePaymentInformation,
    CreateTaxInfo,
    EntityTag,
    SelectAssociateType,
  },
  data() {
    return {
      AssociateType,
      scope: 'create-associate',
      selectedCategory: '',
      performers: [],
      error: false,
      associateTypes: Object.keys(AssociateType).map((oKey) => ({
        key: oKey,
        value: Vue.$i18n.t(`common.${AssociateType[oKey].toLowerCase()}`),
      })),
    };
  },
  computed: {
    ...mapGetters('associate', ['companyAccount', 'foreignAccount', 'privateAccount', 'type']),
  },
  created() {
    dispatch('createAggregate', {});
  },
  methods: {
    ...mapMutations('associate', ['updateType']),
    async createAndNavigateToView() {
      const associateAggregate = _.cloneDeep(getAggregate());
      if (this.selectedCategory === 'person') {
        associateAggregate.organisation_info = {};
      } else {
        associateAggregate.person_info = {};
      }
      try {
        const documents = associateAggregate.associates
          .map((child) => child.documents)
          .reduce((a, b) => a.concat(b), []);
        await UploadDocuments(AGGREGATE_TYPES.ASSOCIATE, documents);
        const id = await AssociateService.createAssociate(associateAggregate);
        await delay(1000);
        this.$router.push({
          name: 'associateView',
          params: { id },
        });
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    resetPaymentInfo() {
      commit('resetPaymentInfo');
    },
    commitChange(type, event) {
      this.resetPaymentInfo();
      commit(type, event);
    },
    onUpdateSelectedCategory(value) {
      this.selectedCategory = value;
    },
  },
};
</script>
