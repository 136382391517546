const companyAccount = `
      company_account {
      account_type
      account_number
    }`;

const foreignAccount = `
  foreign_account {
    account_holder
    iban_number
    account_number
    bic_swift_number
    routing_number
    routing_code
    meta_data
  }`;

const privateAccount = `
  private_account {
    bank
    clearing_number
    account_number
  }`;

const paymentInfo = `
  payment_info {
    ${companyAccount}
    ${privateAccount}
    ${foreignAccount}
  }`;

export default {
  paymentInfo,
};
