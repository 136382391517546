<template>
  <edit-add-from-mainartist
    scope="edit-add-from-mainartist"
    @save="addMainArtistsToLineup($event)"
  />
</template>

<script>
import * as uuid from 'uuid';
import { mapMutations, mapGetters } from 'vuex';
import EditAddFromMainartist from '../edit/edit-add-from-mainartist';
import { createPerformerReference } from './../../../domain/recordingDomain';
import { createNamedRelation } from './../../../domain/common';

export default {
  name: 'AddMainartistPerformers',
  components: { EditAddFromMainartist },
  inject: ['$validator'],
  computed: {
    ...mapGetters('recording', ['lineup']),
  },
  methods: {
    ...mapMutations('recording', ['addToLineup', 'removeFromLineup']),
    addMainArtistsToLineup(performerReferences) {
      if (performerReferences.length > 0) {
        const referencesToAdd = performerReferences.map((pr) =>
          createPerformerReference(
            createNamedRelation(pr.relation.id, pr.relation.name, pr.relation.type),
            pr.instruments,
            pr.role,
          ),
        );
        referencesToAdd.forEach((r) => this.addToLineup(Object.assign({ _localId: uuid.v4() }, r)));
        const idx = this.lineup.findIndex((p) => !p.relation.id);
        if (idx > -1) {
          this.removeFromLineup(idx);
        }
      }
    },
  },
};
</script>
