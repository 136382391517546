<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else class="row">
      <div class="col s12 xl9 mainartist-wrapper">
        <h1>{{ name }}</h1>
        <version
          :version="routeVersion()"
          :readonly="mainArtist.readonly"
          entity-type="main artist"
          view-name="mainArtistInformation"
        />
        <div>
          <basic-information
            v-if="!isEditMode('BasicInformation')"
            :data="mainArtist.basic_info"
            @edit="onEdit"
          />
          <edit-basic-information
            v-else
            :main-artist="mainArtistCopy"
            @save="onSaveBasicInfo"
            @cancel="onEditCancel"
          />
        </div>

        <bulk-table
          ref="memberTable"
          name="Members"
          :columns="columns"
          :bulk-options="!routeVersion() && !mainArtist.readonly ? bulkOptions : []"
          :enable-row-options="!routeVersion() && !mainArtist.readonly"
          :item-ids="performerIds"
          :default-sort-column-index="0"
          :displayed-bulk-action="bulkActionDisplayed"
          @selectedItems="onSelectedItems"
          @bulkAction="onBulkAction"
          @editItem="onEditItem"
          @editItemCancel="onEditItemCancel"
          @editItemSave="onEditItemSave"
          @deleteItem="onDeleteItem"
          @sort="onSort"
        >
          <template slot="edit">
            <div class="row">
              <div class="col s12">
                <div>
                  <div class="row">
                    <div class="col s4">
                      <ul>
                        <li v-for="performer in selectedPerformers" :key="performer.id">
                          {{ performer.name }}
                        </li>
                      </ul>
                    </div>
                    <div class="col s4">
                      <select-input
                        v-model="bulkEditInstrument"
                        label="Instrument"
                        name="bulkEditInstrument"
                        :items="instruments"
                        :sorted="true"
                        item-key="code"
                        item-value="name"
                      />
                    </div>
                    <div class="col s4">
                      <select-input
                        v-model="bulkEditRole"
                        label="Role"
                        name="bulkEditRole"
                        :items="roles"
                        item-key="code"
                        item-value="name"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col s12">
                      <action-buttons @save="onBulkEditSave" @cancel="onEditCancel" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-for="performer in mainArtist.performers" :slot="performer.id">
            <td :key="`${performer.id}-name`">
              <router-link :to="'/performers/' + performer.id">
                {{ performer.name }}
                {{ performer.protected_identity ? ' 🔒' : '' }}
              </router-link>
            </td>
            <td :key="`${performer.id}-pseudo`">
              <template v-if="isEditRowItem(performer.id)">
                <select-input
                  v-model="performer.pseudo_name"
                  :items="getPseudoNameOptions(performer.pseudo_names)"
                  :scope="scope"
                  :show-label="false"
                />
              </template>
              <template v-else>
                <span v-if="!performer.pseudo_name" class="none">None</span>
                <span>{{ performer.pseudo_name }}</span>
              </template>
            </td>
            <td :key="`${performer.id}-instrument`" class="instrument">
              <template v-if="isEditRowItem(performer.id)">
                <select-instrument
                  v-model="performer.instrument"
                  :scope="scope"
                  :show-label="false"
                />
              </template>
              <template v-else>
                {{ $filters.formatInstrument(performer.instrument) }}
                <span v-if="!performer.instrument" class="none">None</span>
              </template>
            </td>
            <td :key="`${performer.id}-nationality`">
              {{ $filters.formatCountry(performer.nationality) }}
              <span v-if="!performer.nationality" class="none">None</span>
            </td>

            <td
              :key="`${performer.id}-active_period`"
              :class="{ 'active-period': isEditRowItem(performer.id) }"
            >
              <template v-if="isEditRowItem(performer.id)">
                <form :data-vv-scope="scope" @click.prevent>
                  <create-active-period
                    name="active_period"
                    :scope="scope"
                    :active-periods="performer.active_period"
                    :is-inline-edit="true"
                    @fromDateChanged="onActivePeriodFromDateChanged"
                    @toDateChanged="onActivePeriodToDateChanged"
                    @add="onAddActivePeriod"
                    @remove="onRemoveActivePeriod"
                  />
                </form>
              </template>
              <template v-else>
                <span v-if="performer.active_period && performer.active_period.length > 0">
                  <span v-for="(period, index) in performer.active_period" :key="index">
                    <div class="row active-periods">
                      {{ period.from }}-{{ period.to || 'present' }}
                    </div>
                  </span>
                </span>
                <span v-else class="none">None</span>
              </template>
            </td>
            <td :key="`${performer.id}-ipn`">
              {{ performer.ipn }}
              <span v-if="!performer.ipn" class="none">None</span>
            </td>
            <td :key="`${performer.id}-societies`">
              <span v-if="performer.mandates && performer.mandates.length > 0">
                <span v-for="(mandate, index) in performer.mandates" :key="index">
                  {{ $filters.formatSociety(mandate.society_code) }}
                  <i
                    v-if="mandate.type"
                    style="float: none"
                    class="fas"
                    :class="$filters.iconByMandateTypeRegionalOrWorldWide(mandate.type)"
                  />
                  <i
                    v-if="mandate.type === 'R+' || mandate.type === 'WW-'"
                    style="float: none"
                    class="fas"
                    :class="$filters.iconByMandateTypePlusOrMinus(mandate.type)"
                  />
                  <span v-if="index < performer.mandates.length - 1">,</span>
                </span>
              </span>
              <span v-else class="none">None</span>
            </td>
          </template>
        </bulk-table>

        <div class="row">
          <div class="col s12">
            <add-performer-form @save="onAddExistingPerformers" />
          </div>
        </div>
      </div>
      <div class="col s12 xl3">
        <right-column
          :aggregate-id="mainArtist.id"
          :aggregate-version="mainArtist.version"
          :main-artist="mainArtist"
          :main-artist-copy="mainArtistCopy"
          :pre-selected-tab="routeVersion() ? 'activity' : 'general'"
          :editable-component="editableComponent"
          @onEdit="onEdit"
          @onSaveBasicInfo="onSaveBasicInfo"
          @onEditCancel="onEditCancel"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { createActivePeriod } from '../../../domain/mainArtistDomain';
import { formatCountry, formatInstrument } from '../../../filters';
import clone from '../../../common/clone';
import AddPerformerForm from '../member/add-performer-form';
import AggregateMixin from '../../../common/aggregateMixin';
import BasicInformation from './basic-information';
import RightColumn from '../right-column';
import BulkTable from '../../ui/table/bulk-table';
import CreateActivePeriod from '../create-active-period';
import EditBasicInformation from '../edit/edit-basic-information';
import EditHelper from '../editHelper';
import MainArtistService from '../../../services/mainArtistService';
import SelectInput from '../../ui/select/select-input';
import Spinner from './../../spinner';
import Version from '../../ui/version';
import SelectInstrument from '../../ui/select/select-instrument';
export default {
  name: 'MainartistInformation',
  components: {
    SelectInstrument,
    AddPerformerForm,
    BasicInformation,
    BulkTable,
    CreateActivePeriod,
    RightColumn,
    EditBasicInformation,
    SelectInput,
    Spinner,
    Version,
  },
  mixins: [AggregateMixin],
  data() {
    return {
      scope: 'view-mainartist-information',
      loading: true,
      editableComponent: '',
      showAddExistingPerformer: false,
      mainArtist: null,
      mainArtistCopy: null,
      editItemId: null,
      editPerformer: null,
      selectedItemIds: [],
      bulkEditInstrument: null,
      bulkEditRole: null,
      bulkOptions: ['remove'],
      bulkOptionSelected: '',
      bulkActionDisplayed: '',
      addPerformers: [],
      columns: [
        {
          name: 'Name',
          ascending: false,
          sortParam: 'name',
          active: true,
        },
        {
          name: 'Pseudo',
        },
        {
          name: 'Instrument',
          ascending: true,
          sortParam: 'instrument',
          active: false,
        },
        {
          name: 'Nationality',
          ascending: true,
          sortParam: 'nationality',
          active: false,
        },
        {
          name: 'Active',
        },
        {
          name: 'IPN',
          ascending: true,
          sortParam: 'ipn',
          active: false,
        },
        {
          name: 'Society',
        },
      ],
    };
  },
  computed: {
    name() {
      return this.mainArtist.basic_info.name;
    },
    performerIds() {
      return this.mainArtist.performers.map((performer) => performer.id);
    },
    selectedPerformers() {
      return this.mainArtist.performers.filter((performer) =>
        this.selectedItemIds.includes(performer.id),
      );
    },
    notSelectedPerformers() {
      return this.mainArtist.performers.filter(
        (performer) => !this.selectedItemIds.includes(performer.id),
      );
    },
    roles() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
    instruments() {
      return this.$store.state.appdata.referenceData.instruments;
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const [aggregate, response] = await Promise.all([
          this.loadAggregate(MainArtistService.getMainArtist),
          MainArtistService.getMainArtistPerformers(this.routeAggregateId()),
        ]);
        aggregate.performers = response.performers;
        this.mainArtist = aggregate;
      } catch (error) {
        error.title = 'Could not load main artist';
        this.$addStarError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.routeAggregateId() },
        });
      } finally {
        this.loading = false;
        this.mainArtistCopy = clone(this.mainArtist);
      }
    },
    isEditRowItem(id) {
      return id === this.editItemId;
    },
    onBulkAction(type) {
      switch (type) {
        case 'remove':
          this.deleteItems(this.selectedItemIds);
          break;
        case 'edit':
          this.bulkActionDisplayed = 'edit';
          break;
        default:
          console.log(`Unknown event type: ${type}`);
      }
    },
    onSort(column) {
      const sortOrder = column.ascending ? 'asc' : 'desc';
      const sortParam = column.sortParam;
      const sorted = _.orderBy(
        this.mainArtist.performers,
        [
          function sortValue(performer) {
            const value = performer[sortParam];
            switch (sortParam) {
              case 'nationality':
                return formatCountry(value);
              case 'instrument':
                return formatInstrument(value);
              default:
                return value;
            }
          },
        ],
        [sortOrder],
      );
      this.mainArtist.performers = sorted;
    },
    onSelectedItems(ids) {
      this.selectedItemIds = ids;
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onEdit(view) {
      this.editableComponent = view;
    },
    onEditCancel() {
      this.$refs.memberTable.clearBulkAction();
      this.editableComponent = '';
      this.fetchData();
    },
    async onBulkEditSave() {
      this.onEditCancel();
      this.selectedPerformers.forEach((performer) => {
        if (this.bulkEditInstrument) {
          performer.instrument = this.bulkEditInstrument;
        }
        if (this.bulkEditRole) {
          performer.role = this.bulkEditRole;
        }
      });
      try {
        await EditHelper.updatePerformers(this.mainArtist);
        await this.fetchData();
      } catch (error) {
        error.title = 'Could not update performer';
        throw error;
      }
    },
    onEditItem(id) {
      this.editItemId = id;
      this.editPerformer = this.mainArtist.performers.find(
        (performer) => this.editItemId === performer.id,
      );
      if (this.editPerformer && this.editPerformer.active_period == null) {
        this.editPerformer.active_period = [];
        this.onAddActivePeriod();
      }
    },
    onEditItemCancel() {
      this.editItemId = null;
      this.editPerformer = null;
      this.mainArtist = clone(this.mainArtistCopy);
    },
    async onEditItemSave() {
      try {
        await EditHelper.updatePerformers(this.mainArtist);
        this.onEditItemCancel();
        await this.fetchData();
      } catch (error) {
        error.title = 'Could not save members';
        throw error;
      }
    },
    onDeleteItem(id) {
      this.deleteItems([id]);
    },
    async onSaveBasicInfo(mainArtist) {
      try {
        await EditHelper.updateBasicInfo(mainArtist);
        this.onEditCancel();
        await this.fetchData();
      } catch (error) {
        error.title = 'Could not save information';
        throw error;
      }
    },
    async onAddExistingPerformers(performers) {
      const copy = this.mainArtistCopy;
      copy.performers = copy.performers.concat(performers);

      try {
        await EditHelper.updatePerformers(copy);
        await this.fetchData();
      } catch (error) {
        error.title = 'Could not update performers';
        throw error;
      }
    },
    async deleteItems(ids) {
      this.mainArtist.performers = this.mainArtist.performers.filter(
        (performer) => !ids.includes(performer.id),
      );

      try {
        await EditHelper.updatePerformers(this.mainArtist);
        this.onEditCancel();
        await this.fetchData();
      } catch (error) {
        error.title = 'Could not update members';
        throw error;
      }
    },
    onActivePeriodFromDateChanged(obj) {
      const toDate = this.editPerformer.active_period[obj.index].to;
      this.editPerformer.active_period.splice(obj.index, 1, { to: toDate, from: obj.entry });
    },
    onActivePeriodToDateChanged(obj) {
      const fromDate = this.editPerformer.active_period[obj.index].from;
      this.editPerformer.active_period.splice(obj.index, 1, { to: obj.entry, from: fromDate });
    },
    onAddActivePeriod() {
      this.editPerformer.active_period.push(createActivePeriod());
    },
    onRemoveActivePeriod(index) {
      this.editPerformer.active_period.splice(index, 1);
      if (this.editPerformer.active_period.length === 0) {
        this.editPerformer.active_period.push(createActivePeriod());
      }
    },
    getPseudoNameOptions(names) {
      return (names || []).map((name) => ({
        code: name,
        name,
      }));
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.mainartist-wrapper {
  padding-right: 24px;
  .active-periods {
    margin: 0;
  }
}
.active-period {
  vertical-align: bottom;
  padding-top: 10px;
}
.instrument {
  padding-top: 10px;
}
</style>
