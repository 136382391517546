export const DistributionMethod = {
  POINTS: 'POINTS',
  AIRTIME: 'AIRTIME',
  BY_SOURCE: 'BY_SOURCE',
  MANUAL: 'MANUAL',
};

export const RevenueState = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED',
  TO_BE_DISTRIBUTED: 'TO_BE_DISTRIBUTED',
  ON_HOLD: 'ON_HOLD',
};

export const DistributionState = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const DistributionCategory = {
  DOMESTIC: 'domestic',
  INTERNATIONAL: 'international',
};

export const DistributionType = {
  BY_CHANNEL: 'BY_CHANNEL',
  BY_SOURCE: 'BY_SOURCE',
};

export const DistributionBasis = {
  REPORTED: 'REPORTED',
  ANALOGY: 'ANALOGY',
};

export function createDistributionRule(id = null, name = null) {
  return {
    id,
    name,
  };
}

export function createDistributionArea(
  id = null,
  name = null,
  categoryOfRights = null,
  distributionRule = createDistributionRule(),
  distributionBasis = DistributionBasis.REPORTED,
) {
  return {
    id,
    name,
    category_of_rights: categoryOfRights,
    distribution_rule: distributionRule,
    distribution_basis: distributionBasis,
  };
}

export function createDistributionAreaAggregate(area = createDistributionArea(), channels = []) {
  return {
    area,
    channels,
  };
}

export function createDistributionChannel(
  id = null,
  name = null,
  code = null,
  channelGroup = null,
  vrdbPlaylist = null,
  user = null,
  dateChanged = null,
) {
  return {
    id,
    name,
    code,
    channel_group: channelGroup,
    vrdb_playlist: vrdbPlaylist,
    user,
    date_changed: dateChanged,
  };
}
export function createDistributionInput(channelId = null, amount = null) {
  return {
    channel_id: channelId,
    amount,
  };
}

export function createSourceDistributionInput(sourceId = null, channelIds = []) {
  return {
    source_id: sourceId,
    channel_ids: channelIds,
  };
}

export function createClientAttributes(sourceDistributions = []) {
  return {
    source_distributions: sourceDistributions,
  };
}

export function createDistributeCommand(
  distributionId = null,
  distributionYear = null,
  toMonth = null,
  distributionMethod = null,
  revenueIds = [],
  channelDistributions = [],
  sourceDistributions = [],
  clientAttributes = null,
  user = null,
  timestamp = new Date(),
) {
  const command = {
    distribution_year_id: distributionYear,
    to_month: toMonth,
    distribution_method: distributionMethod,
    revenue_ids: revenueIds,
    channel_distributions: channelDistributions,
    source_distributions: sourceDistributions,
    client_attributes: clientAttributes,
    user,
    timestamp,
  };
  if (distributionId) {
    command.distribution_id = distributionId;
  }
  return command;
}

export function createDistributionChannelCommand(channel = null) {
  return {
    channels: [channel],
  };
}

export function createDistributionYear(
  id = null,
  name = null,
  areaId = null,
  area = createDistributionArea(),
  reportYear = null,
  state = null,
  openMonth = null,
  endMonth = null,
  errorPct = null,
) {
  return {
    id,
    name,
    area_id: areaId,
    area,
    report_year: reportYear,
    state,
    open_month: openMonth,
    end_month: endMonth,
    error_pct: errorPct,
    errors: null,
  };
}

export function createDistribution(
  id = null,
  distributionYearId = null,
  distributionMethod = null,
  revenueIds = [],
  state = null,
  points = null,
  fromMonth = null,
  toMonth = null,
  channelDistributions = [],
  clientAttributes = null,
  user = null,
  dateChanged = null,
  distributionGroups = [],
  result = { date: null },
) {
  return {
    id,
    distribution_year_id: distributionYearId,
    distribution_method: distributionMethod,
    revenue_ids: revenueIds,
    state,
    points,
    from_month: fromMonth,
    to_month: toMonth,
    channel_distributions: channelDistributions,
    client_attributes: clientAttributes,
    distribution_groups: distributionGroups,
    user,
    date_changed: dateChanged,
    result,
  };
}

export function createChannelDistribution(
  channelId = null,
  airtime = null,
  recordingCount = null,
  points = null,
  reservedPoints = null,
  amount = null,
  pointValue = null,
  sourceId = null,
) {
  return {
    channel_id: channelId,
    airtime,
    recording_count: recordingCount,
    points,
    reserved_points: reservedPoints,
    amount,
    point_value: pointValue,
    source_id: sourceId,
  };
}

export function createDuration(hours = null, minutes = null, seconds = null) {
  return {
    hours,
    minutes,
    seconds,
  };
}

export function createDistributionYearAggregate(
  header = createDistributionYear(),
  distributions = [],
) {
  return {
    header,
    distributions,
  };
}

export function createRevenue() {
  return {
    id: null,
    name: null,
    year: null,
    payment_date: null,
    note: null,
    source: null,
    source_id: null,
    state: null,
    original_amount: null,
    currency: null,
    amount: null,
    user: null,
    distributed_amount: null,
    distributable_amount: null,
    refunded_amount: null,
    conversion_rate: null,
    financing_rate: null,
    financing_fee: null,
    statement_url: null,
    statement_total_amount: null,
    statement_conversion_rate: null,
    missing_performers_url: null,
    statement_feedback_file_url: null,
    attachment_urls: [],
    statementFileHandle: null,
    attachmentFileHandles: [],
    date_changed: null,
    distribution_id: null,
    specification: [],
    society_code: null,
  };
}

export function createRevenueCommand(revenue = createRevenue()) {
  // Flip sign of financing fee, i.e. negative fees becomes positive and vice-versa, before calling distribution.
  // This because of how distribution handles calculation of fee rate.
  if (revenue.financing_fee < 0) {
    revenue.financing_fee = Math.abs(revenue.financing_fee).toString();
  } else {
    revenue.financing_fee = (-Math.abs(revenue.financing_fee)).toString();
  }

  if (revenue.specification) {
    revenue.specification = revenue.specification.filter((row) => row.amount);
    if (revenue.specification.length === 0) {
      delete revenue.specification;
    }
  }
  return {
    revenues: [revenue],
  };
}

export function createDistributionSpecification(sourceId = null, name = null, amount = null) {
  return {
    source_id: sourceId,
    name,
    amount,
  };
}

export function createRevenueSubSource(
  id = null,
  name = null,
  source_society_name = null,
  projectCodeIn = null,
  projectCodeOut = null,
  projectCodeReserves = null,
) {
  return {
    id,
    name,
    source_society_name,
    project_code_in: projectCodeIn,
    project_code_out: projectCodeOut,
    project_code_reserves: projectCodeReserves,
  };
}

export function createRevenueSource(
  id = null,
  name = null,
  source_society_name = null,
  projectCodeIn = null,
  projectCodeOut = null,
  category = null,
  financingRate = null,
  subSources = [],
  user = null,
  projectCodeReserves = null,
) {
  return {
    id,
    name,
    source_society_name,
    project_code_in: projectCodeIn,
    project_code_out: projectCodeOut,
    project_code_reserves: projectCodeReserves,
    category,
    financing_rate: financingRate,
    sub_sources: subSources,
    user,
  };
}

export function createRevenueFinancingRateCommand(year = null, financingRate = null, user = null) {
  return {
    year,
    financing_rate: financingRate,
    user,
  };
}
