<template>
  <div>
    <div class="row flush--bottom">
      <div class="col s12">
        <searchable-tag-input
          name="tags"
          label="Tags"
          placeholder="E.g. Top 100"
          :preview-list="previewTags"
          :value-list="localTags"
          :searcher="searchTag"
          :scope="scope"
          use-template="tag"
          @add="addTag"
          @remove="removeTagAt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SearchableTagInput from '../../ui/tag/searchable-tag-input';
import SearchHelper from '../../search/searchHelper';

export default {
  name: 'CreateTags',
  components: { SearchableTagInput },
  props: {
    scope: { type: String, default: '' },
  },
  data() {
    return {
      localTags: [],
      allTags: [],
    };
  },
  computed: {
    ...mapState('recording', {
      tags: (state) => state.basic_info.tags,
    }),
    previewTags() {
      return this.allTags.filter((tag) =>
        this.localTags.length > 0
          ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
          : true,
      );
    },
  },
  async created() {
    this.allTags = await SearchHelper.getAllTags();
  },
  methods: {
    ...mapMutations('recording', ['addToBasicInfoTags', 'removeFromBasicInfoTags']),
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.localTags = event.data;
      this.tags.push(event.item.name);
    },
    removeTagAt(event) {
      this.tags.splice(event.idx, 1);
    },
  },
};
</script>
