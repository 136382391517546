<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>

    <div class="row">
      <div class="col s12">
        <TableOptions
          :countries="!isSdeg ? recordedInCountries : []"
          :bulk-action-enabled="false"
          :recorded-in-country-code="recordedInCountryCode"
          :custom-select="!isSdeg ? errorTypes : undefined"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
          @countryChanged="onCountryChanged"
          @customSelectChanged="onErrorTypeChanged"
        />
      </div>
    </div>

    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table>
          <CompareSort
            :bulk-enabled="false"
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareListError
            ref="comparelist"
            :is-expanded="isExpanded"
            :toggle-expanded="toggleExpanded"
          />
        </table>

        <div class="result__pagination">
          <pagination
            v-if="numberOfPages() > 0"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="totalCount"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import MusicReportHelper from './musicReportHelper';
import Pagination from '../pagination';
import PaginationAndSortMixin from '../../common/paginationAndSortMixin';
import TableOptionsMixin from '../../common/tableOptionsMixin';
import TableOptions from '../ui/table/table-options';
import ViewMusicReportMixin from './view-music-report-mixin';
import CompareSort from '../compare/compare-sort';
import CompareListError from '../compare/compare-list-error';

export default {
  name: 'ViewMusicReportErrorLines',
  components: {
    CompareListError,
    CompareSort,
    Pagination,
    TableOptions,
  },
  mixins: [
    DebouncedSearchMixin(50),
    PaginationAndSortMixin,
    TableOptionsMixin,
    ViewMusicReportMixin,
  ],
  data() {
    return {
      worklistId: null,
      lineupStateMask: 'E',
      errorTypes: {
        name: 'Error types',
        selected: null,
        items: [
          { key: 'SCHEMA', name: 'Bad format' },
          { key: 'SR_PROTECTED', name: 'SR protected' },
          { key: 'SR_NOT_PAYED', name: 'SR not payed' },
        ],
        itemKey: 'key',
        itemValue: 'name',
      },
    };
  },
  created() {
    this.clearFilter('ViewMusicReportErrorLines');
  },
  async activated() {
    const expandedId = this.viewMusicReportCandidateLines.expandedId;
    await this.loadPaginated();
    this.recordedInCountries = MusicReportHelper.getCountries();
    if (expandedId) {
      this.toggleExpanded(expandedId);
    }
  },
  deactivated() {
    this.updateViewMusicReportErrorLinesExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
  methods: {
    onErrorTypeChanged(type) {
      this.queryErrorTypes = type ? [type] : [];
      this.fetchData();
    },
  },
};
</script>
