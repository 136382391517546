<template>
  <div class="right-column row">
    <div class="col s12">
      <div class="tabs">
        <div class="tabs__wrapper">
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'general' }"
            @click="selectedTab = 'general'"
          >
            General
          </div>
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'activity' }"
            @click="selectedTab = 'activity'"
          >
            Activity
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'general'">
        <membership-agreement @saved="onSaved" />

        <system-state namespace="associate" @saved="onUpdateBlockedPayments" @cancel="onCancel" />

        <create-tags
          v-if="isEditMode('Tags')"
          :edit-mode="true"
          scope="tags-associate"
          entity-type="associate"
          path="meta_info"
          add-method="addToMetaInfoTags"
          remove-method="removeFromMetaInfoTags"
          @update="onUpdateMetaInfo"
          @cancel="onCancel"
        />
        <tags v-else :tags="metaInfoTags" @edit="onEdit" />
        <notes :id="id" />

        <div class="row small-inner">
          <div class="col s12">
            <div class="row small-inner">
              <div class="col s12">
                <h2>Active Payments</h2>
              </div>
            </div>
            <div class="row card">
              <div class="col s12">
                <active-payments :node-id="id" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <activity :stream-id="id" :version="version" view-name="associateInformationVersion" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Activity from '../ui/activity';
import CreateTags from '../tags/create-tags';
import ActivePayments from '../payment/active-payments';
import MembershipAgreement from './membership-agreement';
import Notes from '../ui/input/notes';
import Tags from '../tags/tags';
import SystemState from '../ui/input/system-state';

export default {
  name: 'RightColumn',
  components: {
    Activity,
    CreateTags,
    ActivePayments,
    MembershipAgreement,
    Notes,
    Tags,
    SystemState,
  },
  props: {
    editableComponent: {
      type: String,
      default: '',
    },
    preSelectedTab: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      selectedTab: this.preSelectedTab,
    };
  },
  computed: {
    ...mapGetters('associate', ['id', 'version', 'metaInfoTags']),
  },
  methods: {
    ...mapMutations('associate', ['updateMetaInfoTags', 'updateSystemState']),
    onEdit(view) {
      this.$emit('onEdit', view);
    },
    onSaved(component, waitForServer = false) {
      this.$emit('onSaved', component, waitForServer);
    },
    onUpdateBlockedPayments() {
      this.$emit('onUpdateBlockedPayments');
    },
    onCancel() {
      this.$emit('onCancel');
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onUpdateMetaInfo() {
      this.$emit('onUpdateMetaInfo');
    },
  },
};
</script>
