<template>
  <div>
    <form v-if="mode === 'edit'">
      <div class="row small-inner">
        <div class="col s3">
          <h2>{{ title }}</h2>
        </div>
        <div class="text--right col s9">
          <action-buttons @save="savePaymentsBlocked" @cancel="cancelPaymentsBlocked" />
        </div>
      </div>
      <div class="row card">
        <div class="row flush--bottom payments-blocked">
          <div class="col s">Block payments</div>
          <div class="col s6">
            <input
              id="payments_blocked"
              v-model="localState.payments_blocked"
              type="checkbox"
              name="payments_blocked"
              class="filled-in"
            />
            <label for="payments_blocked" />
          </div>
        </div>
        <div v-if="localState.payments_blocked" class="row">
          <div class="col s12">
            <label for="paymentsBlockedReason">Reason</label>
            <textarea
              id="paymentsBlockedReason"
              v-model="localState.payments_blocked_reason"
              name="reason"
              placeholder="E.g. Rights issue"
            />
          </div>
        </div>
      </div>
    </form>

    <div v-else>
      <div class="row small-inner">
        <div class="col s6">
          <h2>{{ title }}</h2>
        </div>
        <div class="text--right edit-link col s6">
          <a :class="{ disabled: routeVersion() !== null }" @click="setMode('edit')">
            <i class="fas fa-pencil-alt" />
            Edit
          </a>
        </div>
      </div>
      <div v-if="empty" class="row">
        <span class="none">None</span>
      </div>
      <div v-if="systemState.payments_blocked" class="row flush--bottom">
        <strong class="col s12 text--red">{{ $filters.uppercase(namespace) }} is blocked</strong>
      </div>
      <div v-if="systemState.payments_blocked" class="row">
        <div class="col s12">
          <span v-if="systemState.payments_blocked_reason">
            {{ systemState.payments_blocked_reason }}
          </span>
          <i v-else class="no-pb-reason">None</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import clone from '../../../common/clone';
import { AGGREGATE_TYPES } from '../../../domain/common';
import PerformerService from '../../../services/performerService';
import AssociateService from '../../../services/associateService';
import ActionButtons from '../button/action-buttons';
import AggregateMixin from '../../../common/aggregateMixin';

export default {
  name: 'SystemState',
  components: {
    ActionButtons,
  },
  mixins: [AggregateMixin],
  props: {
    namespace: { type: String, default: '' },
  },
  data() {
    return {
      mode: 'view',
      title: 'Status',
      localState: null,
    };
  },
  computed: {
    ...mapState({
      systemState(state, getters) {
        return getters[`${this.namespace}/systemState`];
      },
      id(state, getters) {
        return getters[`${this.namespace}/id`];
      },
      empty() {
        return !this.systemState.payments_blocked;
      },
    }),
  },
  watch: {
    mode() {
      this.localState = clone(this.systemState);
    },
  },
  created() {
    this.localState = clone(this.systemState);
  },
  methods: {
    setMode(mode) {
      this.mode = mode;
    },
    cancelPaymentsBlocked() {
      this.setMode('view');
      this.$emit('cancel', this.$options.name);
    },
    async savePaymentsBlocked() {
      if (this.namespace === AGGREGATE_TYPES.PERFORMER) {
        await PerformerService.updatePerformerPaymentsBlocked(this.localState, this.id);
      } else if (this.namespace === AGGREGATE_TYPES.ASSOCIATE) {
        await AssociateService.updateAssociatePaymentsBlocked(this.localState, this.id);
      }
      this.setMode('view');
      this.$emit('saved', this.$options.name);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.no-pb-reason {
  color: var(--grey--light);
}
.payments-blocked {
  line-height: 25px;
}
</style>
