<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1 class>Processes</h1>
      </div>
    </div>
    <div class="tabs">
      <div class="tabs__wrapper">
        <router-link
          v-for="(tab, idx) of tabs"
          :key="idx"
          active-class="active"
          :to="getTabRoute(tab)"
          :replace="true"
          exact
        >
          {{ tab.label }}
        </router-link>
      </div>
    </div>
    <div class="row card">
      <spinner v-if="loading" />
      <div v-if="!rows || rows.length === 0">No log rows to display.</div>
      <div v-for="[date, row] of rowsByDate.entries()" v-else :key="date">
        <h3>{{ date }}</h3>
        <ul>
          <li v-for="[id, prcs] of groupByProcess(row).entries()" :key="id">
            <process-item
              :id="id"
              :name="prcs[0].task"
              :log-rows="prcs"
              :removeable="false"
              :route="`/processes/${id}`"
              :limit-rows="10"
              route-availability="*"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import {
  getLogRowsByLevel,
  // aggregateLogRows,
  LOG_LEVEL,
} from '../../services/processService';
import ProcessItem from './process-item';
import Spinner from '../spinner';

export default {
  name: 'ProcessOverview',
  components: {
    ProcessItem,
    Spinner,
  },
  filters: {
    time(timestamp) {
      return moment(timestamp).format('HH:mm:ss');
    },
  },
  data() {
    return {
      processes: new Map(),
      rows: [],
      tabs: [
        {
          label: 'Errors',
          level: LOG_LEVEL.ERROR,
        },
        {
          label: 'Warnings',
          level: LOG_LEVEL.WARNING,
        },
        {
          label: 'Info',
          level: LOG_LEVEL.INFO,
        },
        {
          label: 'Debug',
          level: LOG_LEVEL.DEBUG,
        },
      ],
      defaultLevel: LOG_LEVEL.ERROR,
      loading: false,
    };
  },
  computed: {
    rowsByDate() {
      return this.rows.reduce((map, row) => {
        const date = this.getRowDate(row);
        const rows = map.get(date) || [];
        map.set(date, [...rows, row]);
        return map;
      }, new Map());
    },
  },
  watch: {
    async $route() {
      await this.fetchLogRows();
    },
  },
  async created() {
    if (!this.$route.query.level) {
      this.$router.replace({ query: { level: this.defaultLevel } });
    } else {
      await this.fetchLogRows();
    }
  },
  methods: {
    getRowDate(row) {
      return moment(row.timestamp_unix).format('YYYY-MM-DD');
    },
    groupByProcess(rows) {
      return rows.reduce((map, row) => {
        const proc = row.process_id;
        const group = map.get(proc) || [];
        map.set(proc, [row, ...group]);
        return map;
      }, new Map());
    },
    getTabRoute(tab) {
      return `/processes?level=${tab.level}`;
    },
    async fetchLogRows() {
      const level = Number(this.$route.query.level || this.defaultLevel);
      this.loading = true;
      this.rows = await getLogRowsByLevel(level);
      this.loading = false;
    },
  },
};
</script>

<style>
.log-row {
  margin-bottom: 0.2rem;
}
.time {
  color: rgba(0, 0, 0, 0.5);
}
</style>
