<!--

Default usage positions button outside a grid-row's or grid-column's upper right corner:

For row...

div.row
  div.col
    input
  delete-button

...or for column:

div.row
  div.col
    input
    delete-button


To instead position it in the top right corner of a padding-less component, add :is-component="true":

div.my-component
  row
  delete-button[:is-component="true"]

-->

<template>
  <span
    class="delete-button"
    :class="{
      'delete-button--component': isComponent,
      'delete-button--label': hasLabel,
    }"
    :title="title"
  >
    <button
      role="button"
      type="button"
      tabindex="0"
      :class="[disabled]"
      @click.stop.prevent="onClick"
      @keyup.enter="onClick"
    >
      <i class="far fa-trash-alt" />
    </button>
    <br />
  </span>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isComponent: {
      type: Boolean,
      default: false,
    },
    hasLabel: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Clear',
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.delete-button {
  position: absolute;
  right: -30px;
  top: 20px;
  z-index: 1;

  .col & {
    right: calc((var(--spacing) - 2.5rem));
    top: var(--spacing);
  }

  &.delete-button--component {
    right: var(--spacing);
  }

  &.delete-button--label {
    top: calc((var(--spacing) + 1.2rem));
  }
}
</style>
