import { render, staticRenderFns } from "./edit-distribution-channel.vue?vue&type=template&id=ea1a87d0&scoped=true"
import script from "./edit-distribution-channel.vue?vue&type=script&lang=js"
export * from "./edit-distribution-channel.vue?vue&type=script&lang=js"
import style0 from "./edit-distribution-channel.vue?vue&type=style&index=0&id=ea1a87d0&prod&lang=scss&rel=stylesheet%2Fscss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ea1a87d0",
  null
  
)

export default component.exports