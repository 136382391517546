<template>
  <div>
    <div class="row">
      <div class="col s12 l1 hide-on-med-and-down">
        <entity-tag />
      </div>
      <div v-if="currentProgress" class="col s12 l11 view-layout">
        <div class="row">
          <div class="col s8 l10 offset-s2 offset-l1">
            <bullet-progress
              :current-progress="currentProgress"
              :progression-steps="progressionSteps"
            />
          </div>
        </div>
        <div v-if="payment && isWorking">
          <h2>{{ payment.name }}</h2>
          <p>Payment is being processed</p>
          <process-progress-bar
            :id="payment.processId"
            :display-msg="true"
            @finished="fetchNextVersion"
          />
        </div>
        <template v-else>
          <div class="row">
            <div class="col s12">
              <h1>
                {{ currentProgress.label }}
                <span v-if="payment && payment.name">for {{ payment.name }}</span>
                <span v-if="currentProgress.name === 'confirmed'" class="text--green">✔︎</span>
              </h1>
            </div>
          </div>
          <progress-create-payment v-if="currentProgress.name === 'create'" />
          <progress-verify-information
            v-if="currentProgress.name === 'verify' && payment"
            :payment="payment"
            @approved="fetchNextVersion"
          />
          <progress-bank-file
            v-if="currentProgress.name === 'bankfile' && payment"
            :payment="payment"
            @confirmed="fetchNextVersion"
          />
          <progress-payment-confirmed
            v-if="currentProgress.name === 'confirmed' && payment"
            :payment="payment"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { paymentState } from '../../domain/paymentDomain';
import EntityTag from '../ui/entity-tag';
import BulletProgress from '../ui/bullet-progress';
import ProgressBankFile from './progress-bank-file';
import ProgressPaymentConfirmed from './progress-payment-confirmed';
import ProgressCreatePayment from './progress-create-payment';
import ProgressVerifyInformation from './progress-verify-information';
import ProcessProgressBar from '../process/process-progress-bar.vue';
import { query } from '../../services/apolloRequest';
import gql from 'graphql-tag';

export default {
  name: 'PaymentWizardMain',
  components: {
    EntityTag,
    BulletProgress,
    ProgressBankFile,
    ProgressPaymentConfirmed,
    ProgressCreatePayment,
    ProgressVerifyInformation,
    ProcessProgressBar,
  },
  data() {
    return {
      progressionSteps: [
        {
          name: 'create',
          label: 'Create Payment',
        },
        {
          name: 'verify',
          label: 'Verify Information',
        },
        {
          name: 'bankfile',
          label: 'Bank File',
        },
        {
          name: 'confirmed',
          label: 'Payment Confirmed',
        },
      ],
      payment: null,
      loading: false,
      fetchInterval: 0,
    };
  },
  computed: {
    polling() {
      return this.$store.state.payment.polling;
    },
    paymentId() {
      return this.$route.params.paymentId;
    },
    isWorking() {
      return this.payment && this.payment.occupied;
    },
    currentProgress() {
      if (!this.paymentId) {
        return {
          name: 'create',
          label: 'Create Payment',
        };
      }
      if (!this.payment) {
        return null;
      }
      switch (this.payment.state) {
        case paymentState.Created:
        case paymentState.Ready:
          return {
            name: 'verify',
            label: 'Verify information',
          };
        case paymentState.UserApproved:
        case paymentState.SystemApproved:
        case paymentState.ConfirmReady:
          return {
            name: 'bankfile',
            label: 'Bank file',
          };
        case paymentState.Confirmed:
          return {
            name: 'confirmed',
            label: 'Payment confirmed',
          };
        default:
          return {
            name: 'loading',
            label: 'Payment Loading',
          };
      }
    },
  },
  async created() {
    if (this.paymentId) {
      const payment = await this.fetchPayment();
      this.setPayment(payment);
    }
  },
  methods: {
    getProcess(id) {
      return this.$store.getters['process/process'](id);
    },
    async setPayment(payment) {
      this.payment = payment;
      // add a process subscription if not present
      if (this.payment.occupied && this.payment.processId) {
        const process = this.getProcess(this.payment.processId);
        if (!process) {
          this.$store.dispatch('process/addProcess', {
            name: `Payment ${this.payment.name}`,
            id: this.payment.processId,
          });
        }
      }
      // keep fetching next version for the following states
      if ([paymentState.UserApproved, paymentState.SystemApproved].includes(this.payment.state)) {
        this.fetchNextVersion();
      }
    },
    async fetchNextVersion() {
      let payment = await this.fetchPayment();
      let interval = 0;
      while (payment.version <= this.payment.version) {
        await new Promise((resolve) => setTimeout(resolve, interval));
        interval += 1000;
        payment = await this.fetchPayment();
      }
      this.setPayment(payment);
    },
    async fetchPayment() {
      return query(
        {
          query: gql`
          query {
            payment(id: "${this.paymentId}") {
              id
              name
              state
              createdAt
              paymentDate
              societyId
              payableAmount
              payableVat
              totalAmount
              totalVat
              numPerformers
              numPayees
              createdBy
              confirmedBy
              confirmedAt
              occupied
              processId
              version
              files {
                type
                name
                key
                link
              }
              stats {
                state
                totalCount
                payeeCount
                totalAmount
                payeeNet
                payeeVat
              }
              type
            }
          }
        `,
        },
        { pickProp: 'payment' },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h1 span {
  font-size: 14px;
}
</style>
