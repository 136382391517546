<template>
  <div class="searchable-input-wrapper">
    <searchable-input
      ref="mainArtist"
      :label="label"
      :scope="scope"
      :show-label="showLabel"
      :mandatory="mandatory"
      :name="name"
      :placeholder="placeholder"
      :remove="remove"
      :rule="{ requiredValue: namedRelation.id }"
      :searcher="searchMainArtist"
      use-template="mainartist"
      :value="initialQueryValue"
      class="searchable-input"
      @change="changeQuery"
      @clear="clearSelectedMainArtist"
      @input="updateSelectedMainArtist"
    />
    <div v-if="!namedRelation.id && namedRelation.name" class="create-button-wrapper">
      <a :class="{ disabled: loading }" @click="onCreate">
        <i class="far fa-plus-square" />
        Create Main artist
      </a>
    </div>
  </div>
</template>

<script>
import { createNamedRelation } from '../../../domain/common';
import SearchableInput from './searchable-input';
import SearchHelper from '../../search/searchHelper';
import MainArtistService from '../../../services/mainArtistService';

export default {
  name: 'MainArtistInput',
  components: { SearchableInput },
  inject: ['$validator'],
  props: {
    value: {
      type: Object,
      default() {
        return createNamedRelation();
      },
    },
    remove: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    scope: {
      type: String,
      default: undefined,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      initialQueryValue: this.value.name,
      namedRelation: createNamedRelation(this.value.id, this.value.name, this.value.type),
      loading: false,
    };
  },
  watch: {
    value() {
      this.initialQueryValue = this.value.name;
    },
  },
  methods: {
    async searchMainArtist(terms) {
      const res = await SearchHelper.advancedSearchMainArtists(terms);
      return res.results;
    },
    changeQuery(terms) {
      if (terms) {
        this.namedRelation.id = null;
        this.namedRelation.name = terms;
        this.namedRelation.type = null;
        this.emitUpdate();
      } else {
        this.clearSelectedMainArtist();
      }
    },
    updateSelectedMainArtist(mainArtist) {
      if (mainArtist && mainArtist.id) {
        this.namedRelation.id = mainArtist.id;
        this.namedRelation.name = mainArtist.name;
        this.namedRelation.type = mainArtist.relation_type;
        this.emitUpdate();
      } else {
        this.clearSelectedMainArtist();
      }
    },
    clearSelectedMainArtist() {
      this.namedRelation.id = null;
      this.namedRelation.name = null;
      this.namedRelation.type = null;
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('input', this.namedRelation);
    },
    async onCreate() {
      this.loading = true;
      try {
        this.namedRelation.id = await MainArtistService.createMainArtist(
          { name: this.namedRelation.name },
          [],
          [],
        );
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.searchable-input-wrapper {
  display: flex;
  gap: 10px;
}

.searchable-input {
  flex: 1;
}

.create-button-wrapper {
  padding-top: 9px;
}
</style>
