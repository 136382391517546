<template>
  <div>
    <div class="row">
      <label class="col s12">Local codes</label>
    </div>
    <div class="row wrapper">
      <span
        v-for="candidate of computedCandidates"
        :key="candidate.id"
        :class="{ 'not-allowed': candidate.master }"
      >
        <span
          class="chip"
          :class="{ selected: isSelected(candidate.value), disabled: candidate.master }"
          :style="`background: ${candidate.color}`"
          @click="toggleLocalCode(candidate.value)"
        >
          {{ candidate.descriptor }} {{ getLocalCodeName(candidate.value.code) }} -
          {{ candidate.value.value }}
        </span>
      </span>
    </div>
    <div class="row">
      <a @click="showAll = !showAll">
        <i :class="`far fa-${showAll ? 'minus' : 'plus'}-square`"></i>
        {{ showAll ? 'Hide' : 'Show' }} existing
      </a>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';

export default {
  name: 'MergeLocalCodes',
  props: {
    path: {
      type: String,
      required: true,
    },
    changes: {
      type: Object,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    localCodeTypes() {
      return this.$store.state.appdata.localCodeTypes;
    },
    localCodes() {
      return get(this.changes, `${this.path}.value`, get(this.candidates[0], this.path)) || [];
    },
    computedCandidates() {
      return this.candidates
        .filter((candidate) => this.showAll || !candidate.master)
        .sort((candidate) => (candidate.master ? 1 : -1))
        .reduce((acc, candidate) => {
          const localCodes = get(candidate, this.path, []) || [];
          acc.push(
            ...localCodes.map((lc) => {
              if (!candidate.master && lc.code === 'public_id') {
                lc.code = 'merged_public_id';
              }
              return {
                value: lc,
                color: candidate.color,
                master: candidate.master,
                descriptor: candidate.descriptor,
              };
            }),
          );
          return acc;
        }, []);
    },
  },
  created() {
    const localCodes = this.computedCandidates.map((c) => c.value);

    // All local codes should be selected by default
    if (localCodes.length) {
      this.$emit('setValue', { value: [...this.localCodes, ...localCodes], path: this.path });
    }
  },
  methods: {
    isSelected(localCode) {
      return !!this.localCodes.find(
        (lc) => lc.code === localCode.code && lc.value === localCode.value,
      );
    },
    getLocalCodeName(code) {
      const localCode = this.localCodeTypes.find((lct) => lct.code === code);
      if (!localCode) {
        return code;
      }
      return localCode.name;
    },
    toggleLocalCode(localCode) {
      let localCodes = [];
      if (this.isSelected(localCode)) {
        localCodes = this.localCodes.filter(
          (lc) => !(lc.code === localCode.code && lc.value === localCode.value),
        );
      } else {
        localCodes = [...this.localCodes, localCode];
      }

      this.$emit('setValue', { value: localCodes, path: this.path });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.not-allowed {
  cursor: not-allowed;
}
.chip {
  white-space: nowrap;
  height: 26px;

  &.selected {
    text-decoration: none;
    border: 1px solid black;
  }

  &:not(.selected) {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
