import { dispatch } from '../../../store/modules/recording/utils';
import RecordingService from '../../../services/recordingService';
import Spinner from './../../spinner';
import AggregateMixin from '../../../common/aggregateMixin';

export default {
  components: {
    Spinner,
  },
  mixins: [AggregateMixin],
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const aggregate = await this.loadAggregate(RecordingService.getRecording);
        dispatch('setRecordingAggregate', aggregate);
      } catch (error) {
        error.title = 'Could not load recording';
        dispatch('createAggregate');
        this.$addStarError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.routeAggregateId() },
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
