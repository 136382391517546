<template>
  <div class="selectable-entry">
    <div class="row flush--bottom">
      <div class="col s6 no-padding">
        <select-input
          :name="`${name}.key`"
          :label="label"
          :show-label="showLabel"
          :disabled="disabled"
          :data-vv-as="label.toLowerCase()"
          :value="getEntryKey"
          :items="items"
          :disabled-items="disabledItems"
          :item-key="selectableItemKeyName"
          :item-value="selectableItemValueName"
          @input="updateEntryKey"
        />
      </div>

      <div class="col s6 no-padding">
        <label
          v-show="showLabel"
          class="label"
          :class="{ 'label--error': validationErrors.has(`${name}.value`, scope) }"
        >
          {{ secondLabel }}
          <span v-if="entryValueRequired" class="mandatory">(mandatory)</span>
        </label>
        <input
          v-validate="getEntryKey && entryValueRequired ? 'required' : ''"
          type="text"
          autocomplete="off"
          :name="`${name}.value`"
          :data-vv-as="secondLabel.toLowerCase()"
          :placeholder="valuePlaceholder"
          :value="getEntryValue"
          :disabled="disabled || !getEntryKey"
          @input="updateEntryValue($event.target.value)"
        />
        <span v-show="validationErrors.has(`${name}.value`, scope)" class="help is-danger">
          <i class="fas fa-times-circle" />
          {{ validationErrors.first(`${name}.value`, scope) }}
        </span>
      </div>

      <delete-button
        v-if="deletable"
        :has-label="showLabel"
        @click="onRemoveEntry"
        @keyup.enter="onRemoveEntry"
      />
    </div>
  </div>
</template>

<script>
/**
 * The selectable entry component provides editable key-value entry input.
 * An entry can have one of the following formats:
 *
 * 1. { a-key-name: 'the key value', a-value-name: 'the value' }
 * 2  { a-key-name: a-value-name }
 *
 * Case 1 requires that the entryKeyName and entryValueName attributes are set.
 * Case 2 is assumed if the above attributes are omitted or ''.
 */
import SelectInput from './select-input';
import DeleteButton from '../button/delete-button';

export default {
  name: 'SelectableEntry',
  components: {
    DeleteButton,
    SelectInput,
  },
  inject: ['$validator'],
  props: {
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    showLabel: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    disabledItems: { type: Array, default: () => [] },
    secondLabel: { type: String, default: '' },
    scope: { type: String, default: undefined },
    entry: Object,
    entryKeyName: { type: String, default: '' },
    entryValueName: { type: String, default: '' },
    entryValueRequired: { type: Boolean, default: true },
    items: Array,
    selectableItemKeyName: { type: String, default: '' },
    selectableItemValueName: { type: String, default: '' },
    valuePlaceholder: { type: String, default: 'E.g. Write a comment' },
    deletable: { type: Boolean, default: false },
  },
  data() {
    return {
      currentEntry: null,
    };
  },
  computed: {
    getEntryKey() {
      return this.entryKeyName !== ''
        ? this.currentEntry[this.entryKeyName]
        : Object.keys(this.currentEntry)[0];
    },
    getEntryValue() {
      return this.entryValueName !== ''
        ? this.currentEntry[this.entryValueName]
        : this.currentEntry[Object.keys(this.currentEntry)[0]];
    },
  },
  created() {
    this.currentEntry = Object.assign({}, this.entry);
  },
  methods: {
    updateEntryKey(value) {
      if (!value) {
        this.currentEntry[this.entryValueName || this.getEntryKey] = '';
      }
      if (this.entryKeyName !== '') {
        this.currentEntry[this.entryKeyName] = value;
      } else {
        const newEntry = {};
        newEntry[value] = this.getEntryValue;
        this.currentEntry = newEntry;
      }
      this.$emit('input', this.currentEntry);
    },
    updateEntryValue(value) {
      if (this.entryKeyName !== '') {
        this.currentEntry[this.entryValueName] = value;
      } else {
        this.currentEntry[this.getEntryKey] = value;
      }
      this.$emit('input', this.currentEntry);
    },
    onRemoveEntry() {
      this.$emit('remove');
    },
  },
};
</script>
