import Vue from 'vue';
import { getConfigValue, PROPS } from './configService';

const headers = { 'content-type': 'application/json' };

function mapWorklist(worklist) {
  return {
    worklistId: worklist.worklist_id,
    created: worklist.created,
    assignee: worklist.assignee,
    url: decodeURI(worklist.payload.url),
    name: worklist.payload.name,
    category: worklist.payload.category,
  };
}

export async function getWorklistsByAssignee(userName) {
  const result = await Vue.$http.get(`${getConfigValue(PROPS.WORKLIST_URL)}/${userName}`);
  const worklists = [];
  result.data.worklists.forEach((worklist) => worklists.push(mapWorklist(worklist)));
  return worklists;
}

export async function getWorklist(userName) {
  const result = await Vue.$http.get(
    `${getConfigValue(PROPS.WORKLIST_URL)}/${userName}/${userName}`,
  );
  return result.data.worklist.payload;
}

export async function getAllWorklists(lastKey) {
  let result = null;
  if (lastKey) {
    const queryString = Object.keys(lastKey)
      .map((key) => `${key}=${Object.values(lastKey[key])}`)
      .join('&');
    result = await Vue.$http.get(`${getConfigValue(PROPS.WORKLIST_URL)}?${queryString}`);
  } else {
    result = await Vue.$http.get(`${getConfigValue(PROPS.WORKLIST_URL)}`);
  }
  const worklists = [];
  result.data.worklists.forEach((worklist) => worklists.push(mapWorklist(worklist)));
  const worklistsResult = {
    lastEvaluatedKey: result.data.lastEvaluatedKey,
    worklists,
  };
  return worklistsResult;
}

export async function saveWorklist(worklist) {
  const data = JSON.stringify({
    worklist_id: worklist.worklist_id,
    created: new Date(),
    assignee: worklist.assignee,
    worklist_type: 'public',
    payload: worklist.payload,
  });
  const result = await Vue.$http.post(getConfigValue(PROPS.WORKLIST_URL), data, { headers });
  return result.data;
}

export async function addToWorklist(data, username) {
  const result = await Vue.$http.post(`${getConfigValue(PROPS.WORKLIST_URL)}/${username}`, data, {
    headers,
  });
  return result.data;
}

export async function deleteWorklist(worklist) {
  const data = JSON.stringify({
    worklist_id: worklist.worklistId,
    assignee: worklist.assignee,
  });
  const result = await Vue.$http.delete(getConfigValue(PROPS.WORKLIST_URL), { data, headers });
  return result.data;
}
