import Vue from 'vue';
import { mutate } from './apolloRequest';

import gql from '../domain/mandaterGql';
import { getConfigValue, PROPS } from './configService';

async function getProcessById(processId, after = undefined) {
  const url = `${getConfigValue(PROPS.PROCESS_URL)}/${processId}${
    after ? `?after=${encodeURIComponent(after)}` : ''
  }`;
  const result = await Vue.$http.get(url);
  return result.data.items;
}

export default {
  createMandateFile: async (societyCode, processId) =>
    mutate(
      {
        mutation: gql.createMandateFile,
        variables: { societyCode, processId },
        fetchPolicy: 'network-only',
      },
      {},
    ),
  getProcessById,
};
