<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Regional Membership Applications</h1>
      </div>
    </div>

    <div class="row card">
      <div class="col s4">
        <select-input
          v-model="filter.intermediary"
          item-key="id"
          item-value="name"
          :sorted="true"
          name="intermediary"
          label="Intermediary"
          :items="intermediaries"
          query-param-persist
          :disabled="loading"
        />
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <DataTable
          :columns="table.columns"
          :items="filteredApplications"
          :sort.sync="table.sort"
          :loading="loading"
          item-key="id"
        >
          <template #item:performer="{ item }">
            <router-link :to="{ name: 'performerView', params: { id: item.performer.id } }">
              {{ item.performer.general_info.first_name }}
              {{ item.performer.general_info.last_name }}
            </router-link>
          </template>

          <template #item:intermediary="{ item }">
            <ul class="intermediaries">
              <template
                v-for="intermediary in item.performer.associates.filter(
                  (a) => a.type === 'INTERMEDIARY',
                )"
              >
                <li :key="intermediary.id" class="intermediary">
                  <router-link :to="{ name: 'associateView', params: { id: intermediary.id } }">
                    {{ intermediary.name }}
                  </router-link>
                </li>
              </template>
            </ul>
          </template>

          <template #item:created="{ item }">
            {{ $filters.formatDateTime(item.created) }}
          </template>

          <template #item:status="{ item }">
            {{ item.on_hold ? 'On Hold' : item.status }}
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '../../services/apolloRequest';
import gql from '../../domain/performerMembershipApplicationGql';
import SelectInput from '../ui/select/select-input';
import DataTable from '@/components/ui/table/DataTable.vue';

export default {
  components: {
    SelectInput,
    DataTable,
  },

  data() {
    return {
      applications: [],
      loading: false,

      table: {
        columns: [
          {
            key: 'performer',
            text: 'Performer',
          },
          {
            key: 'intermediary',
            text: 'Intermediary',
          },
          {
            key: 'created',
            text: 'Created',
            sortable: true,
          },
          {
            key: 'status',
            text: 'Status',
            sortable: true,
          },
        ],
        sort: {
          order_by: 'created',
          direction: 'asc',
        },
      },

      filter: {
        intermediary: null,
      },
    };
  },
  computed: {
    intermediaries() {
      const intermediaries = [];
      const seenIntermediaries = {};
      this.applications.forEach((application) => {
        application.performer.associates.forEach((associate) => {
          if (!seenIntermediaries[associate.id]) {
            intermediaries.push(associate);
            seenIntermediaries[associate.id] = associate;
          }
        });
      });
      return intermediaries;
    },
    filteredApplications() {
      return this.applications.filter((application) => {
        if (
          this.filter.intermediary &&
          application.performer.associates.findIndex((a) => a.id === this.filter.intermediary) ===
            -1
        ) {
          return false;
        }
        return true;
      });
    },
  },
  watch: {
    'table.sort': {
      deep: true,
      handler() {
        if (!this.loading) {
          this.reloadBaseData(true);
        }
      },
    },
  },

  created() {
    this.enableSort = true;
  },

  mounted() {
    this.reloadBaseData(true);
  },

  methods: {
    async reloadBaseData(reload = false) {
      if (this.applications.length > 0 && !reload) {
        // query params might have changed, no need to reload data
        return;
      }
      this.loading = true;
      try {
        this.applications = await query(
          {
            query: gql.activePerformerMembershipApplications,
            variables: {
              request: this.table.sort,
            },
          },
          { pickProp: 'activePerformerMembershipApplications' },
        );
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.intermediaries {
  margin-bottom: 0;
}
.intermediary {
  &:not(:first-child) {
    margin-top: 0.75rem;
  }
}
</style>

<style lang="scss" scoped>
.intermediaries {
  margin-bottom: 0;
}

.intermediary {
  &:not(:first-child) {
    margin-top: 0.75rem;
  }
}
</style>
