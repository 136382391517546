<template>
  <form>
    <create-edit-contact-info
      :contact-info="performer.contact_info"
      :save-function="updateContactInfo"
      :cancel-function="cancel"
      :edit-mode="true"
      @input="onContactInfoChanged"
    />
  </form>
</template>

<script>
import EditHelper from './editHelper';
import { createPhoneNumber, createAddress } from '../../../../domain/common';
import CreateEditContactInfo from '../../../ui/contact/create-edit-contact-info';

export default {
  name: 'EditContactInformation',
  components: {
    CreateEditContactInfo,
  },
  data() {
    return {
      performer: EditHelper.getCurrentPerformer(),
    };
  },
  computed: {
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  created() {
    if (this.performer.contact_info.phone_numbers.length === 0) {
      this.performer.contact_info.phone_numbers.push(createPhoneNumber(null, null));
    }
    if (this.performer.contact_info.emails.length === 0) {
      this.performer.contact_info.emails.push('');
    }
    if (this.performer.contact_info.address == null) {
      this.performer.contact_info.address = createAddress();
    }
  },
  methods: {
    onContactInfoChanged(value) {
      this.performer.contact_info = value;
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    async updateContactInfo() {
      try {
        await EditHelper.updateContactInfo(this.performer);
        this.$emit('saved', this.$options.name);
      } catch (error) {
        error.title = 'Could not update contact information';
        throw error;
      }
    },
  },
};
</script>
