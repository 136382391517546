<template>
  <div class="row">
    <label class="col s2">
      {{ label }}
    </label>
    <div class="col s10 wrapper">
      <template v-if="computedCandidates.length">
        <span
          v-for="candidate of computedCandidates"
          :key="candidate.id"
          :class="{ 'not-allowed': candidate.master }"
        >
          <span
            class="chip"
            :class="{ selected: isSelected(candidate.value), disabled: candidate.master }"
            :style="`background: ${candidate.color}`"
            @click="toggleValue(candidate.value)"
          >
            {{ candidate.descriptor }}
            {{ template ? replaceTokens(candidate.value) : candidate.value }}
          </span>
        </span>
      </template>
      <span v-else class="none">None</span>
    </div>
  </div>
</template>

<script>
import { get, uniqWith, isEqual } from 'lodash';

export default {
  name: 'MergeArray',
  props: {
    label: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    changes: {
      type: Object,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
    template: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    computedCandidates() {
      return uniqWith(
        this.candidates.reduce((acc, candidate) => {
          const values = get(candidate, this.path, []) || [];
          acc.push(
            ...values.map((value) => ({
              value,
              color: candidate.color,
              master: candidate.master,
              descriptor: candidate.descriptor,
            })),
          );
          return acc;
        }, []),
        (a, b) => isEqual(a.value, b.value),
      );
    },
    values() {
      return get(this.changes, `${this.path}.value`, get(this.candidates[0], this.path)) || [];
    },
  },
  methods: {
    isSelected(value) {
      return this.values.includes(value);
    },
    replaceTokens(value) {
      return this.template.replace(/\{([a-zA-Z0-9-_.:]*?)\}/g, (_, token) => {
        const [path, defaultValue = ''] = token.split(':');
        return get(value, path, defaultValue) || defaultValue;
      });
    },
    toggleValue(value) {
      let values = [];
      if (this.values.includes(value)) {
        values = this.values.filter((v) => !isEqual(v, value));
      } else {
        values = [...this.values, value];
      }

      this.$emit('setValue', { value: values, path: this.path });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.not-allowed {
  cursor: not-allowed;
}
.chip {
  white-space: nowrap;
  height: 26px;

  &.selected {
    text-decoration: none;
    border: 1px solid black;
  }

  &:not(.selected) {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
