<template>
  <div class="table-wrapper">
    <table :class="{ loading, sticky: stickyHeader }">
      <thead>
        <tr>
          <th v-if="expandKey" class="thin-column"></th>
          <template v-for="column in columns">
            <th
              :key="column.key"
              :class="{ sortable: column.sortable, 'text--right': column.count }"
              @click="onColumnClick(column)"
            >
              {{ column.text }}

              <template v-if="column.sortable">
                <i
                  class="fas"
                  :class="{
                    'fa-caret-up':
                      (column.key === sort.order_by && sort.direction === 'asc') ||
                      column.ascending,
                    'fa-caret-down':
                      (column.key === sort.order_by && sort.direction === 'desc') ||
                      !column.ascending,
                    'text--grey': column.key !== sort.order_by,
                  }"
                />
              </template>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <template v-if="loading"><Spinner class="table-spinner" /></template>

        <template v-for="(item, index) in localSort ? sortedItems : items" v-else>
          <slot name="item" :item="item">
            <tr :key="item[itemKey]">
              <td
                v-if="expandKey"
                class="thin-column dropdown-button__arrow"
                @click="toggleExpanded(item[itemKey])"
              >
                <i :class="`fas fa-caret-${expanded === item[itemKey] ? 'up' : 'down'}`"></i>
              </td>
              <template v-for="column in columns">
                <td :key="column.key" :class="{ 'text--right': column.count }">
                  <slot :name="`item:${column.key}`" :item="item" :index="index">
                    {{ item[column.key] }}
                  </slot>
                </td>
              </template>
            </tr>
            <tr v-if="expanded === item[itemKey]" :key="`${item[itemKey]}-exp`">
              <td colspan="100">
                <slot :name="`item:${expandKey}`" :item="item" :index="index">
                  {{ item[expandKey] }}
                </slot>
              </td>
            </tr>
          </slot>
        </template>
      </tbody>
    </table>

    <div v-if="totalItems > 0" class="result__pagination">
      <Pagination
        :topic="topic"
        :number-of-pages="pageCount"
        :selected-page="selectedPage"
        :number-of-hits="totalItems"
        :hits-per-page="pageSize"
        @selectPage="selectPage"
        @updateHitsPerPage="selectPageSize"
      />
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/spinner';
import Pagination from '@/components/pagination';

export default {
  components: {
    Spinner,
    Pagination,
  },

  props: {
    name: {
      type: String,
      required: false,
      default: '',
    },

    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    itemKey: {
      type: String,
      required: false,
    },

    sort: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    stickyHeader: {
      type: Boolean,
      required: false,
      default: false,
    },

    localSort: {
      type: Boolean,
      required: false,
      default: false,
    },

    totalItems: {
      type: Number,
      required: false,
      default: 0,
    },

    topic: {
      type: String,
      required: false,
    },

    expandKey: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    sortedItems: [],
    pageSize: 25,
    selectedPage: 1,
    expanded: null,
  }),

  computed: {
    pageCount() {
      return Math.ceil(this.totalItems / this.pageSize);
    },
  },

  watch: {
    sort: {
      deep: true,
      handler(sort) {
        if (this.localSort) {
          this.sortedItems = this.sortItems([...this.items], sort);
        } else {
          this.updateRoute(sort);
        }
      },
    },
  },

  mounted() {
    this.sortedItems = this.items;

    const routeOrderBy = this.$route.query[this.getQueryParamKey('order_by')];
    const routeDirection = this.$route.query[this.getQueryParamKey('direction')];

    if (
      !this.sort ||
      (routeOrderBy != null && this.sort.order_by !== routeOrderBy) ||
      (routeDirection != null && this.sort.direction !== routeDirection)
    ) {
      this.$emit('update:sort', {
        order_by: routeOrderBy,
        direction: routeDirection,
      });
    }
  },

  methods: {
    getQueryParamKey(key) {
      return this.name ? `${this.name}:${key}` : key;
    },

    onColumnClick(column) {
      if (!column.sortable) {
        return;
      }

      let direction = column.ascending ? 'asc' : 'desc';

      if (this.sort.order_by === column.key) {
        direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      }

      column.ascending = direction === 'asc';
      const sort = {
        order_by: column.key,
        direction: direction,
      };

      this.$emit('update:sort', sort);
    },

    toggleExpanded(key) {
      if (this.expanded === key) {
        this.expanded = null;
      } else {
        this.expanded = key;
      }
    },

    compareItems(a, b, sort, compare) {
      if (compare) {
        return compare(a, b);
      }

      const aValue = a[sort.order_by];
      const bValue = b[sort.order_by];

      if (aValue instanceof String) {
        return aValue.localeCompare(bValue);
      }

      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    },

    sortItems(items, sort) {
      const column = this.columns.find((column) => column.key === sort.order_by);
      const compare = column.sort;

      return items.sort((a, b) => {
        const multiplier = sort.direction === 'asc' ? 1 : -1;

        return this.compareItems(a, b, sort, compare) * multiplier;
      });
    },

    updateRoute(sort) {
      const pathQuery = { ...this.$route.query };
      let anyChanges = false;

      const setQueryParam = (key, value) => {
        key = this.getQueryParamKey(key);

        if (value == null) {
          delete pathQuery[key];
        } else {
          pathQuery[key] = value;
        }
        if (!anyChanges) {
          anyChanges = this.$route.query[key] !== value;
        }
      };

      setQueryParam('order_by', sort.order_by);
      setQueryParam('direction', sort.direction);

      if (anyChanges) {
        this.$router.replace({ path: this.$route.path, query: pathQuery });
      }
    },

    selectPage(page) {
      this.selectedPage = page;
      this.updatePagination();
    },

    selectPageSize(size) {
      this.pageSize = +size;
      this.selectedPage = 1;
      this.updatePagination();
    },

    updatePagination() {
      this.$emit('updatePagination', {
        offset: (this.selectedPage - 1) * this.pageSize,
        limit: this.pageSize,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  width: 100%;
  height: 100%;
  // need to rework context-menu
  // divs also have position: relative set for some reason..
  // and so does td elements
  // much weird stuff when it comes to the postion attribute
  // overflow: auto;
}

table.loading {
  position: relative;
  min-height: 30rem;
}

.table-spinner {
  position: absolute;
}

th:not(.sortable) {
  cursor: default;
}

table.sticky {
  margin-top: -10px;

  thead {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1;

    th {
      padding-top: 10px;
    }
  }
}
</style>
