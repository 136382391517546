import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

import { routerBeforeEach } from './auth';

Vue.use(VueRouter);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

const CURRENT_HEADER = 'currentHeader';

router.afterEach((to) => {
  router.app.$nextTick(() => {
    if (to.meta && to.meta.title) {
      let title = to.meta.title;
      if (to.params.query) {
        title = title.replace('{query}', to.params.query);
      }
      if (title !== 'Star') {
        title = `${title} - Star`;
      }
      document.title = title;
    }
    setTimeout(() => {
      localStorage.setItem(
        CURRENT_HEADER,
        document.getElementsByTagName('h1')[0]
          ? document.getElementsByTagName('h1')[0].innerText
          : document.title,
      );
    }, 500);
  });
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  await routerBeforeEach(to, from, next);
});

export default router;
