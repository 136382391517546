<template>
  <form>
    <input id="lineup-complete" v-model="lineupComplete" type="checkbox" class="filled-in" />
    <label for="lineup-complete">Complete lineup</label>

    <div class="push--top float-right">
      <action-buttons
        :disable-submit="hasTrackWithLockedLineup"
        @save="showModal = true"
        @cancel="$emit('cancel')"
      />
    </div>

    <ChangeTracksModal
      prop="status"
      :show="showModal"
      :value="lineupComplete ? 'Complete lineup' : 'Incomplete lineup'"
      :count="count"
      @cancel="showModal = false"
      @save="onSave"
    />
  </form>
</template>

<script>
import ActionButtons from '../../../ui/button/action-buttons';
import ChangeTracksModal from './change-tracks-modal';

export default {
  name: 'ChangeTracksLineupComplete',
  components: {
    ChangeTracksModal,
    ActionButtons,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    tracks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lineupComplete: true,
      showModal: false,
    };
  },
  computed: {
    hasTrackWithLockedLineup() {
      return this.tracks.some((track) => track.recordingAggregate.lineup_locked);
    },
  },
  methods: {
    onSave() {
      this.showModal = false;
      this.$emit(
        'save',
        'recording:updateDistributionState',
        {
          distribution_state: { status: this.lineupComplete ? 'Green' : 'Yellow' },
        },
        { preserveLegacyUnknownPerformers: true },
      );
    },
  },
};
</script>
