<template>
  <div>
    <div class="filter" :class="{ 'inline-label': inlineLabel }">
      <label class="label">{{ label }}</label>
      <input
        v-model="value"
        type="text"
        autocomplete="off"
        name="filter"
        :placeholder="placeholder"
        @keydown.prevent.esc="clearFilter"
        @keydown.prevent.enter="filterSearch($event.target.value, true)"
        @input="filterSearch($event.target.value, instant)"
      />
      <i class="fas fa-times cursor-pointer move-right" @click="clearFilter" />
    </div>
    <div v-show="showTip">Hit Enter to filter</div>
  </div>
</template>

<script>
export default {
  name: 'FilterInput',
  props: {
    label: {
      type: String,
      default: 'Filter',
    },
    filterQuery: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'E.g. Gravitation',
    },
    instant: {
      type: Boolean,
      default: true,
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
    delayMs: {
      type: Number,
    },
  },
  data() {
    return {
      showTip: false,
      value: '',
      timeout: null,
    };
  },
  watch: {
    filterQuery(val) {
      this.value = val;
    },
  },
  created() {
    this.value = this.filterQuery;
  },
  methods: {
    filterSearch(value, instant) {
      if (this.delayMs) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$emit('input', value);
        }, this.delayMs);
      } else if (instant) {
        this.showTip = false;
        this.$emit('input', value);
      } else {
        this.showTip = value.length > 0;
      }
    },
    clearFilter() {
      this.$emit('input', '');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.filter {
  position: relative;

  i {
    display: inline-block;
    margin-left: -20px;
    width: 10px;
    height: 10px;
  }
}

.inline-label {
  display: flex;
  flex-flow: row;
}
</style>
