<template>
  <tbody>
    <template v-for="(line, index) in reportLines">
      <tr ref="line" :key="`${index}-tr1`">
        <td
          class="thin-column dropdown-button__arrow"
          @click="toggleExpanded(line.incoming.group_id)"
        >
          <i v-if="isExpanded(line.incoming.group_id)" class="fas fa-caret-up" />
          <i v-else class="fas fa-caret-down" />
        </td>
        <td>
          <span v-if="line.incoming.title" class="fs-14">
            {{ $filters.toTitleCase(line.incoming.title) }}
          </span>
          <span v-else>-</span>
        </td>
        <td>
          <span v-if="line.incoming.version_title" class="fs-14">
            {{ $filters.toTitleCase(line.incoming.version_title) }}
          </span>
          <span v-else>-</span>
        </td>
        <td>
          <span v-if="line.incoming.main_artist && line.incoming.main_artist !== 'null'">
            {{ $filters.toTitleCase(line.incoming.main_artist) }}
          </span>
          <span v-else>-</span>
        </td>
        <td>
          <span v-if="line.incoming.title" class="fs-14">{{ line.incoming.recording_year }}</span>
          <span v-else>-</span>
        </td>
        <td>
          <span v-if="line.incoming.title" class="fs-14">
            {{ $filters.formatCountry(line.incoming.recording_country) }}
          </span>
          <span v-else>-</span>
        </td>
        <td>
          {{ line.incoming.errors.length }} error
          <template v-if="line.incoming.errors.length > 1">s</template>
          found
        </td>
      </tr>
      <tr v-if="isExpanded(line.incoming.group_id)" :key="`${index}-tr2`" class="expand-info">
        <td class="thin-column" />
        <td colspan="6">
          <ul>
            <li v-for="(error, index2) in line.incoming.errors" :key="`${index2}-li`">
              <div
                v-for="(info, index3) in error.error"
                :key="`${index3}-div`"
                class="margin--ends"
              >
                <span class="text--bold">Line: {{ error.payload.line }}</span>
                <span class="text--red">{{ info.type }}</span>
                <div>
                  <span>{{ info.message }}</span>
                  <div v-if="info.details">
                    Details:
                    <span v-if="info.details.country">
                      {{ $filters.formatCountry(info.details.country) }}
                    </span>
                    <span v-if="info.details.year">
                      , Year: {{ info.details.year || 'Unknown year' }}
                    </span>
                  </div>
                  <div>
                    <pre>{{ error.payload }}</pre>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </td>
      </tr>
    </template>
  </tbody>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CompareListError',
  props: {
    isExpanded: {
      type: Function,
    },
    toggleExpanded: {
      type: Function,
    },
  },
  computed: {
    ...mapGetters('matching', ['reportLines']),
  },
};
</script>
