<template>
  <div class="cmp e2e-create-mainartist">
    <div class="row">
      <div class="col s2 hide-on-med-and-down">
        <entity-tag />
      </div>
      <div class="col s12 l7">
        <div class="row">
          <div class="col s12">
            <h1>Create Main Artist</h1>
          </div>
        </div>

        <form :data-vv-scope="scope" @click.prevent>
          <create-menu entity-type="main artist" @create="createMainArtistAndNavigateToView" />

          <div>
            <div class="card">
              <div class="row">
                <div class="col s8">
                  <validated-text-input
                    v-model="basicInfo.name"
                    name="basic_info.name"
                    label="Name"
                    :scope="scope"
                    placeholder="E.g. Kent"
                    :mandatory="true"
                    rule="required"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col s8">
                  <tag-input
                    name="alternateTitle"
                    label="Alternate names"
                    placeholder="E.g. Kent"
                    :value-list="basicInfo.alternate_names"
                    @add="addToAlternateNames"
                    @remove="removeFromAlternateNames"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col s8 no-padding">
                  <create-active-period
                    :active-periods="basicInfo.active_period"
                    name="basicInfo.active_period"
                    :scope="scope"
                    label="Years active"
                    @fromDateChanged="onActivePeriodFromDateChanged"
                    @toDateChanged="onActivePeriodToDateChanged"
                    @add="onAddActivePeriod"
                    @remove="onRemoveActivePeriod"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col s8">
                  <select-input
                    name="basic_info.genre"
                    label="Genre"
                    :value="basicInfo.genre"
                    :items="genres"
                    item-key="code"
                    item-value="name"
                    @input="updateBasicInfoGenre"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col s4">
                  <select-country
                    v-model="basicInfo.origin_country"
                    name="basic_info.origin_country"
                    label="Country"
                  />
                </div>
                <div class="col s4">
                  <validated-text-input
                    v-model="basicInfo.origin_city"
                    name="basic_info.origin_city"
                    label="Origin city"
                    rule=""
                    placeholder="E.g. Eskilstuna"
                  />
                </div>
              </div>
            </div>

            <add-performer-form
              :scope="scope"
              :display-action-buttons="false"
              :always-open="true"
              @input="updateExistingPerformers"
            />

            <create-new-performers :notify-validation="true" @input="updateNewPerformers" />

            <div class="row">
              <div class="col s12">
                <h2>Extra Information and Meta Data</h2>
              </div>
            </div>

            <div class="card">
              <div class="row">
                <div class="col s8">
                  <label class="label">Profile</label>
                  <textarea
                    v-model="basicInfo.profile_description"
                    class="profile"
                    name="basic_info.profile_description"
                    placeholder="E.g. Kent was a Swedish rock band, formed in Eskilstuna in 1990."
                    @keyup="autoHeight"
                  />
                </div>
              </div>

              <div class="row flush--bottom">
                <external-sources-input
                  label="External sources"
                  :external-sources="basicInfo.other_external_sources"
                  @add="onAddExternalSource"
                  @remove="onRemoveExternalSource"
                />
              </div>
            </div>
            <div class="card">
              <div class="row flush--bottom">
                <div class="col s8">
                  <searchable-tag-input
                    name="basic_info.tags"
                    label="Tags"
                    placeholder="E.g. Top 100"
                    :preview-list="previewTags"
                    :value-list="localTags"
                    :searcher="searchTag"
                    use-template="tag"
                    :scope="scope"
                    @add="addTag"
                    @remove="removeTagAt"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createBasicInfo,
  createPerformerRelation,
  createActivePeriod,
} from '../../../domain/mainArtistDomain';
import {
  createLocalCode,
  createOtherExternalSources,
  textareaAutoheight,
} from '../../../domain/common';
import { createPerformerAggregateCommand } from './../../../domain/performerDomain';
import AddPerformerForm from '../member/add-performer-form';
import EntityTag from '../../ui/entity-tag';
import CreateHelper from './createHelper';
import CreateActivePeriod from '../create-active-period';
import CreateNewPerformers from './create-new-performers';
import CreateMenu from '../../ui/create-menu';
import SelectInput from '../../ui/select/select-input';
import TagInput from '../../ui/tag/tag-input';
import ExternalSourcesInput from '../../ui/input/external-sources-input.vue';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import SearchableTagInput from '../../ui/tag/searchable-tag-input';
import SearchHelper from '../../search/searchHelper';
import SelectCountry from '../../ui/select/select-country';

export default {
  name: 'CreateMainartist',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    SelectCountry,
    AddPerformerForm,
    EntityTag,
    CreateMenu,
    CreateNewPerformers,
    CreateActivePeriod,
    SelectInput,
    TagInput,
    ValidatedTextInput,
    SearchableTagInput,
    ExternalSourcesInput,
  },
  data() {
    return {
      scope: 'create-mainartist',
      error: false,
      basicInfo: createBasicInfo(),
      existingPerformers: [],
      newPerformers: [],
      newPerformerCommands: [],
      localTags: [],
      allTags: [],
    };
  },
  computed: {
    previewTags() {
      return this.allTags.filter((tag) =>
        this.localTags.length > 0
          ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
          : true,
      );
    },
    genres() {
      return this.$store.state.appdata.referenceData.genres;
    },
  },
  async created() {
    if (this.basicInfo.local_codes.length === 0) {
      this.addBasicInfoLocalCode();
    }
    if (this.basicInfo.active_period.length === 0) {
      this.onAddActivePeriod();
    }
    if (this.basicInfo.other_external_sources.length === 0) {
      this.onAddExternalSource();
    }
    this.allTags = await SearchHelper.getAllTags();
  },
  methods: {
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.localTags = event.data;
      this.basicInfo.tags.push(event.item.name);
    },
    removeTagAt(event) {
      this.basicInfo.tags.splice(event.idx, 1);
    },
    addBasicInfoLocalCode() {
      this.addToBasicInfoLocalCodes(createLocalCode());
    },
    onAddExternalSource() {
      this.basicInfo.other_external_sources.push(createOtherExternalSources());
    },
    onRemoveExternalSource(index) {
      this.basicInfo.other_external_sources.splice(index, 1);
      if (this.basicInfo.other_external_sources.length === 0) {
        this.onAddExternalSource();
      }
    },
    updateBasicInfoSourceInfo(entry) {
      this.basicInfo.source_info = entry;
    },
    updateBasicInfoGenre(value) {
      this.basicInfo.genre = value;
    },
    updateBasicInfoLocalCode(obj) {
      this.basicInfo.local_codes.splice(obj.index, 1, obj.entry);
    },
    addToBasicInfoLocalCodes(value) {
      this.basicInfo.local_codes.push(value);
    },
    removeFromBasicInfoLocalCodes(index) {
      this.basicInfo.local_codes.splice(index, 1);
      if (this.basicInfo.local_codes.length === 0) {
        this.addBasicInfoLocalCode();
      }
    },
    addToAlternateNames(value) {
      this.basicInfo.alternate_names.push(value);
    },
    removeFromAlternateNames(idx) {
      this.basicInfo.alternate_names.splice(idx, 1);
    },
    updateExistingPerformers(updatedPerformers) {
      this.existingPerformers = updatedPerformers;
    },
    updateNewPerformers(updatedPerformers) {
      this.newPerformers = [];
      this.newPerformerCommands = updatedPerformers.map((performer) => {
        const newPerformerCommand = createPerformerAggregateCommand();
        newPerformerCommand.general_info = {
          first_name: performer.first_name,
          last_name: performer.last_name,
          pseudo_names: performer.pseudo_names ? performer.pseudo_names : [],
          country_of_residence: performer.country_of_residence,
        };
        this.newPerformers.push(
          createPerformerRelation(
            newPerformerCommand.stream_id,
            performer.instrument,
            performer.role,
            performer.active_period,
          ),
        );
        return newPerformerCommand;
      });
    },
    onActivePeriodFromDateChanged(obj) {
      const toDate = this.basicInfo.active_period[obj.index].to;
      this.basicInfo.active_period.splice(obj.index, 1, { to: toDate, from: obj.entry });
    },
    onActivePeriodToDateChanged(obj) {
      const fromDate = this.basicInfo.active_period[obj.index].from;
      this.basicInfo.active_period.splice(obj.index, 1, { to: obj.entry, from: fromDate });
    },
    onAddActivePeriod() {
      this.basicInfo.active_period.push(createActivePeriod());
    },
    onRemoveActivePeriod(index) {
      this.basicInfo.active_period.splice(index, 1);
      if (this.basicInfo.active_period.length === 0) {
        this.basicInfo.active_period.push(createActivePeriod());
      }
    },
    async createMainArtistAndNavigateToView() {
      this.error = false;
      try {
        const id = await CreateHelper.createMainArtistAndNavigateToView(
          this.basicInfo,
          this.existingPerformers.concat(this.newPerformers),
          this.newPerformerCommands,
        );
        this.$router.push({
          name: 'mainArtistView',
          params: { id },
        });
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    autoHeight(e) {
      textareaAutoheight(e);
    },
  },
};
</script>
