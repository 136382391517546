<template>
  <div :class="`status__banner text--center ${status.class}`">
    <i
      class="fas"
      :class="{
        'fa-check': status.class === 'success',
        'fa-exclamation': status.class === 'warning',
        'fa-times': status.class === 'error',
      }"
    />
    {{ status.label }}
  </div>
</template>

<script>
export default {
  name: 'StatusBanner',
  props: {
    status: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.status__banner {
  display: inline-block;
  padding: 0.8rem 1rem;
  border-radius: 3px;
  background: var(--grey);
  color: white;
}
.success {
  background: var(--green);
}
.warning {
  background: var(--orange);
}
.error {
  background: var(--red);
}
.fas {
  margin-right: 0.5rem;
}
</style>
