export function createCompanyAccount(accountType = null, accountNumber = null) {
  return {
    account_type: accountType,
    account_number: accountNumber,
  };
}

export function createForeignAccount(
  accountHolder = null,
  ibanNumber = null,
  accountNumber = null,
  bicSwiftNumber = null,
  routingNumber = null,
  routingCode = null,
  metaData = null,
) {
  return {
    account_holder: accountHolder,
    iban_number: ibanNumber,
    account_number: accountNumber,
    bic_swift_number: bicSwiftNumber,
    routing_number: routingNumber,
    routing_code: routingCode,
    meta_data: metaData,
  };
}

export function createPrivateAccount(bank = null, clearingNumber = null, accountNumber = null) {
  return {
    bank,
    clearing_number: clearingNumber,
    account_number: accountNumber,
  };
}

export function createPaymentInfo(
  privateAccount = createPrivateAccount(),
  companyAccount = createCompanyAccount(),
  foreignAccount = createForeignAccount(),
) {
  return {
    private_account: privateAccount,
    company_account: companyAccount,
    foreign_account: foreignAccount,
  };
}

export function createUpdatePaymentInfoCommand(aggregateType) {
  return {
    type: `${aggregateType}:paymentInfo:update`,
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      company_account: null,
      foreign_account: null,
      private_account: null,
    },
  };
}
