import gql from 'graphql-tag';
import CommonGql from './commonGql';
import CommonPaymentGql from './commonPaymentGql';

const getSociety = gql`
  query society($idTerm: String!, $versionTerm: Int) {
    societyAggregate(id: $idTerm, version: $versionTerm) {
      id
      version
      basic_info {
        name
        version
      }
      meta_info {
        code
        IPD_society_name
        IPD_society_code
        vrdb_code
        create_mandate
        order
        country
        tags
        ddex_party_id
        statement_file_type
      }
      ${CommonGql.contactInfoWithContactPersons}
      ${CommonPaymentGql.paymentInfo}
      agreements {
        id
        name
        from_date
        to_date
        sign_date
        contract {
          url
        }
        type
        rights
      }
      role_codes {
        incoming
        outgoing
      }
      documents {
        id
        type
        url
        created
      }
    }
  }
`;

const societies = gql`
  query societies {
    societies {
      id
      basic_info {
        name
      }
      meta_info {
        code
        IPD_society_name
        IPD_society_code
        vrdb_code
        create_mandate
        ddex_party_id
        statement_file_type
      }
    }
  }
`;

export default {
  getSociety,
  societies,
};
