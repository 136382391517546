<template>
  <ul>
    <button class="performer-foldout-arrow" @click="performerExpanded = !performerExpanded">
      <i :class="`fas fa-caret-${performerExpanded ? 'up' : 'down'}`"></i>
    </button>
    <CompareGridRow
      :class="{
        'compare__list-item--rejected': isRejected,
        'compare__list-item--deleted': isDeleted,
      }"
      :candidate="{
        key: 'name',
        value: getFullName(localPerformer),
        link: localPerformer.internal_id ? `#/performers/${localPerformer.internal_id}` : null,
        isEmpty,
      }"
      :incoming="{
        key: 'name',
        value: getFullName(incomingPerformer),
      }"
      :allow-action="false"
      :show-left-side="showLeftSide"
      @valueUpdated="toggleUpdatedAttribute"
    />
    <context-menu
      v-if="allowAction && !isEmpty && isEditAllowed"
      class="compare__list-item-menu"
      :options="['Merge', showLeftSide ? 'Reject & Remove' : 'Remove']"
      :disabled-options="mergable ? [] : ['Merge']"
      @merge="onMergeWithPerformer"
      @remove="showRemoveModal = true"
      @rejectRemove="toggleRemoveModal()"
    />
    <CompareGridRow
      :visible="
        !!(
          localPerformer.pseudo_names &&
          localPerformer.pseudo_names.length &&
          getFullName(incomingPerformer)
        )
      "
      :candidate="{
        key: 'pseudo_names',
        value: localPerformer.pseudo_names,
        displayFilter: 'prettyPrintArray',
      }"
      forced-status="idle"
      :show-left-side="false"
      :allow-action="false"
    />
    <CompareGridRowRoleCode
      :candidate="{
        key: 'role_code',
        value: localPerformer.role_code,
        displayFilter: 'formatRole',
        isEmpty,
        valueWhenEmpty: isRejected ? 'None' : 'The new performer will be added on “Accept all”',
      }"
      :incoming="{
        key: 'role_code',
        value: incomingPerformer.role_code,
        displayFilter: 'formatRole',
        valueWhenEmpty: 'The new performer is now known',
      }"
      :show-left-side="showLeftSide"
      :allow-action="allowAction && isConflict"
      :updated-attribute="isUpdatedAttribute('role_code')"
      @valueUpdated="toggleUpdatedAttribute"
      @roleCodeChange="onIncomingRoleCodeChange"
    />
    <CompareGridRow
      :visible="ruleForShowingRow"
      :candidate="{
        key: 'ipn',
        value: localPerformer.ipn,
        isEmpty,
      }"
      :incoming="{
        key: 'ipn',
        value: incomingPerformer.ipn,
      }"
      :show-left-side="showLeftSide"
      :allow-action="allowAction && isEditAllowed"
      :updated-attribute="isUpdatedAttribute('ipn')"
      @valueUpdated="toggleUpdatedAttribute"
    />
    <CompareGridRow
      :visible="ruleForShowingRow"
      :candidate="{
        key: 'local_code',
        value: localPerformer.local_performer_id
          ? localPerformer.local_performer_id.split(':')[1]
          : undefined,
        isEmpty,
      }"
      :incoming="{
        key: 'local_code',
        value: incomingPerformer.local_performer_id
          ? incomingPerformer.local_performer_id.split(':')[1]
          : undefined,
      }"
      :show-left-side="showLeftSide"
      :allow-action="false"
      @valueUpdated="toggleUpdatedAttribute"
    />
    <CompareGridRow
      :visible="ruleForShowingRow"
      :candidate="{
        key: 'date_of_birth',
        value: localPerformer.date_of_birth,
        isEmpty,
      }"
      :incoming="{
        key: 'date_of_birth',
        value: incomingPerformer.date_of_birth,
      }"
      :show-left-side="showLeftSide"
      :allow-action="allowAction && isEditAllowed"
      :updated-attribute="isUpdatedAttribute('date_of_birth')"
      @valueUpdated="toggleUpdatedAttribute"
    />
    <CompareGridRow
      :candidate="{
        key: 'instruments',
        value: localPerformer.instruments,
        isEmpty,
        displayFilter: 'formatInstrumentList',
        valueConverter: formatInstrumentListAsFamily,
      }"
      :incoming="{
        key: 'instruments',
        value: incomingPerformer.instruments,
        displayFilter: 'formatInstrumentListAsFamily',
        valueConverter: formatInstrumentListAsFamily,
      }"
      :show-left-side="showLeftSide"
      :allow-action="allowAction && isEditAllowed"
      :updated-attribute="isUpdatedAttribute('instruments')"
      @valueUpdated="toggleUpdatedAttribute"
    />
    <CompareGridRow
      :visible="!(calculateAge(incomingPerformer.date_of_birth) >= 15)"
      :candidate="{
        key: 'age_when_recorded',
        value: calculateAge(localPerformer.date_of_birth),
        isEmpty,
      }"
      :incoming="{
        key: 'age_when_recorded',
        value: calculateAge(incomingPerformer.date_of_birth),
      }"
      forced-status="error"
      :show-left-side="showLeftSide"
      :allow-action="false"
    />
    <CompareGridRow
      :visible="ruleForShowingRow"
      :candidate="{
        key: 'sex',
        value: localPerformer.sex,
        displayFilter: 'formatSex',
        isEmpty,
      }"
      :incoming="{
        key: 'sex',
        value: incomingPerformer.sex,
        displayFilter: 'formatSex',
      }"
      :show-left-side="showLeftSide"
      :allow-action="allowAction && isEditAllowed"
      :updated-attribute="isUpdatedAttribute('sex')"
      @valueUpdated="toggleUpdatedAttribute"
    />
    <CompareGridRow
      :visible="ruleForShowingRow"
      :candidate="{
        key: 'collective',
        value: localPerformer.collective,
        displayFilter: 'yesno',
        isEmpty,
      }"
      :incoming="{
        key: 'collective',
        value: incomingPerformer.collective,
        displayFilter: 'yesno',
      }"
      :show-left-side="showLeftSide"
      :allow-action="allowAction && isEditAllowed"
      :updated-attribute="isUpdatedAttribute('collective')"
      @valueUpdated="toggleUpdatedAttribute"
    />
    <li v-if="incomingPerformer.source_society" class="compare__list-item">
      <div class="compare__incoming compare__clean-row">
        <strong>Source Society:</strong>
        <span class="compare__incoming-value">
          {{ incomingPerformer.source_society }}
        </span>
      </div>
    </li>

    <template v-if="showLeftSide">
      <li v-if="allowAction && showRejectionDialog" class="compare__list-item">
        <rejection-dialog
          class="push--top push--left"
          @cancel="showRejectionDialog = false"
          @reject="reject"
        />
      </li>
      <template v-if="isRejected || isDeleted">
        <li class="compare__list-item">
          <div class="compare__incoming compare__clean-row">
            <strong>Rejection reason:</strong>
            <span v-if="performerLine.rejected_reason" class="compare__incoming-value">
              {{ $t(`matching.rejection_reason.${performerLine.rejected_reason.toLowerCase()}`) }}
            </span>
          </div>
        </li>
        <li class="compare__list-item">
          <div class="compare__incoming compare__clean-row">
            <strong>Rejection note:</strong>
            <span class="compare__incoming-value">
              {{ performerLine.rejected_note || 'None' }}
            </span>
          </div>
        </li>
      </template>
      <li v-if="allowAction && isRejected && !isDeleted" class="compare__list-item">
        <div class="compare__incoming compare__clean-row push-half--top">
          <a @click="undoReject">
            <i class="fas fa-undo-alt"></i>
            Undo rejection
          </a>
        </div>
      </li>
      <li v-if="allowAction && !showRejectionDialog && !isRejected" class="compare__list-item">
        <div class="compare__incoming compare__clean-row push-half--top">
          <a class="is-danger" @click="showRejectionDialog = true">
            <i class="fas fa-times-circle"></i>
            Reject
          </a>
        </div>
        <div class="compare__candidate compare__clean-row push-half--top">
          <div v-if="performerLine.merged">
            <a @click="undoMerge">
              <i class="fas fa-undo-alt"></i>
              Undo merge
            </a>
          </div>
        </div>
      </li>
    </template>
    <li v-if="showMergeDialog" class="compare__list-item">
      <div class="compare__candidate">
        <div class="push--bottom" style="grid-column: 1 / span 2">
          <select-input
            :disabled="disableMergeSelect"
            class="push--bottom"
            item-key="key"
            item-value="name"
            :items="unmatchedIncomingLineup"
            @input="onSelectIncomingToMergeId"
          />
          <action-buttons
            submit-label="Merge"
            class="text--right"
            :disable-submit="!incomingToMergeId"
            @save="onCommitMerge"
            @cancel="onCancelMerge"
          />
        </div>
      </div>
    </li>

    <modal
      v-if="showRemoveModal"
      :submit-label="`${showLeftSide ? 'Reject & ' : ''}Remove from recording`"
      :hide-overflow="false"
      :disable-submit="showLeftSide && !rejection.reason"
      @save="onRemove()"
      @close="showRemoveModal = false"
      @cancel="showRemoveModal = false"
    >
      <template v-if="showLeftSide">
        <h2 slot="header">Reject & Remove from recording?</h2>
        <div slot="body">
          Are you sure you want to reject incoming performer and remove this performer from this
          recording?
          <rejection-dialog
            class="push--top"
            :rejection="rejection"
            :show-buttons="false"
          ></rejection-dialog>
        </div>
      </template>
      <template v-else>
        <h2 slot="header">Remove from recording?</h2>
        <div slot="body">Are you sure you want to remove this performer from this recording?</div>
      </template>
    </modal>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CompareGridRow from './compare-grid-row';
import CompareGridRowRoleCode from './compare-grid-row-role-code';
import { createPlaylistLocalPerformer, playlistLineupStates } from '../../domain/matchingDomain';
import { formatInstrumentListAsFamily } from '../../filters';
import RejectionDialog from '../ui/dialog/rejection-dialog';
import SelectInput from '../ui/select/select-input';
import ActionButtons from '../ui/button/action-buttons';
import ContextMenu from '../ui/context-menu';
import Modal from '../ui/dialog/modal';
import { UNKNOWN_PROTECTED_DATE } from '../../domain/recordingDomain';

export default {
  name: 'ComparePerformer',
  components: {
    ActionButtons,
    CompareGridRow,
    CompareGridRowRoleCode,
    ContextMenu,
    RejectionDialog,
    SelectInput,
    Modal,
  },
  props: {
    performerLine: {
      type: Object,
      required: true,
    },
    allowAction: {
      type: Boolean,
      default: true,
    },
    processType: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      formatInstrumentListAsFamily,
      showRejectionDialog: false,
      showMergeDialog: false,
      showRemoveModal: false,
      disableMergeSelect: false,
      incomingToMergeId: null,
      playlistLineupStates,
      performerExpanded: false,
      isMerged: false,
      rejection: {
        reason: null,
        note: null,
      },
    };
  },
  computed: {
    ...mapGetters('matching', ['currentIncoming', 'currentCandidate']),
    localPerformer() {
      if (
        this.performerLine.artist &&
        this.performerLine.updated_attributes &&
        this.performerLine.updated_attributes.includes('new_performer')
      ) {
        return {
          ...this.performerLine.artist,
          newPerformer: true,
        };
      }

      return this.performerLine.artist || createPlaylistLocalPerformer();
    },
    incomingPerformer() {
      return this.performerLine.incoming_artist || {};
    },
    isEmpty() {
      return (
        this.localPerformer &&
        !(
          this.localPerformer.internal_id ||
          this.localPerformer.ipn ||
          this.localPerformer.newPerformer
        )
      );
    },
    showLeftSide() {
      return !!this.performerLine.incoming_artist;
    },
    unmatchedIncomingLineup() {
      return this.currentIncoming.lineup
        .filter((l) => !!l.incoming_artist && !l.artist)
        .map((l) => ({
          key: l.id,
          name: `${l.incoming_artist.first_name || ''} ${l.incoming_artist.last_name}`,
        }));
    },
    mergable() {
      return (
        !this.performerLine.incoming_artist &&
        this.currentIncoming.lineup.some((l) => !!l.incoming_artist && !l.artist) &&
        this.currentIncoming.lineup.some(
          (l) => l.match_state !== 'M' && !l.incoming_artist && !!l.artist,
        )
      );
    },
    isRejected() {
      return (
        this.performerLine.match_state === this.playlistLineupStates.REJECTED ||
        this.performerLine.match_state === this.playlistLineupStates.REJECTED_SENT ||
        this.performerLine.match_state === this.playlistLineupStates.REJECTED_DELETED
      );
    },
    isDeleted() {
      return (
        this.performerLine.match_state === this.playlistLineupStates.DELETED ||
        this.performerLine.match_state === this.playlistLineupStates.REJECTED_DELETED
      );
    },
    isConflict() {
      return this.performerLine.match_state === this.playlistLineupStates.CONFLICT;
    },
    isEditAllowed() {
      return (
        this.performerLine.match_state === this.playlistLineupStates.CONFLICT ||
        this.performerLine.match_state === this.playlistLineupStates.ACCEPTED
      );
    },
    ruleForShowingRow() {
      if (!this.performerExpanded) {
        return { byStatus: ['error', 'warning'] };
      }
      return true;
    },
  },
  watch: {
    performerLine() {
      this.showRejectionDialog = false;
    },
  },
  methods: {
    isUpdatedAttribute(attribute) {
      return (this.performerLine.updated_attributes || []).includes(attribute);
    },
    calculateAge(performerBorn) {
      let recordingYear = this.currentCandidate.basic_info.recording_date;

      if (!recordingYear || recordingYear === UNKNOWN_PROTECTED_DATE) {
        recordingYear = this.currentIncoming.recording_year;
      }

      if (!recordingYear || !performerBorn) {
        return 15;
      }
      const diff = moment(recordingYear).diff(
        moment(performerBorn).set({ month: 0, date: 1 }),
        'years',
      );
      return diff < 0 ? 'Not born' : `${diff}`;
    },
    getFullName(p) {
      if (p && (p.first_name || p.last_name)) {
        return `${p.first_name || ''} ${p.last_name || ''}`;
      }
      return null;
    },
    toggleUpdatedAttribute(e) {
      this.$emit('toggleUpdatedAttribute', {
        id: this.performerLine.id,
        recording_id: this.performerLine.recording_id,
        key: e.data.key,
      });
    },
    onIncomingRoleCodeChange(e) {
      this.$emit('toggleUpdatedAttribute', {
        id: this.performerLine.id,
        recording_id: this.performerLine.recording_id,
        key: 'role_code',
        value: e,
      });
    },
    reject(e) {
      const obj = {
        id: this.performerLine.id,
        recording_id: this.performerLine.recording_id,
        reason: e.reason,
        note: e.note,
      };
      this.$emit('reject', obj);
    },
    undoReject() {
      const obj = {
        id: this.performerLine.id,
        recording_id: this.performerLine.recording_id,
      };
      this.$emit('undoReject', obj);
    },
    undoMerge() {
      const obj = {
        id: this.performerLine.id,
        recording_id: this.performerLine.recording_id,
      };
      this.$emit('undoMerge', obj);
      this.isMerged = false;
    },
    toggleRemoveModal() {
      this.rejection = {
        reason: null,
        note: null,
      };
      this.showRemoveModal = true;
    },
    onSelectIncomingToMergeId(e) {
      this.incomingToMergeId = e;
      this.disableMergeSelect = true;
      this.$emit('previewMerge', {
        incoming_id: Number.parseInt(this.incomingToMergeId, 10),
        candidate: this.performerLine.artist,
      });
    },
    onCommitMerge() {
      this.$emit('merge', {
        recording_id: Number.parseInt(this.performerLine.recording_id, 10),
        source_id: Number.parseInt(this.performerLine.id, 10),
        incoming_id: Number.parseInt(this.incomingToMergeId, 10),
      });
      this.disableMergeSelect = false;
      this.showMergeDialog = false;
      this.isMerged = true;
    },
    onCancelMerge() {
      this.$emit('cancelMerge', {
        incoming_id: Number.parseInt(this.incomingToMergeId, 10),
      });
      this.incomingToMergeId = null;
      this.showMergeDialog = false;
      this.disableMergeSelect = false;
    },
    onRemove() {
      this.showRemoveModal = false;

      if (this.showLeftSide) {
        this.$emit('remove', this.rejection);
      } else {
        this.$emit('remove');
      }
    },
    onMergeWithPerformer() {
      this.showMergeDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.performer-foldout-arrow {
  position: absolute;
  top: 8px;
  left: -24px;
}
h2 {
  font-size: 24px;
}
</style>
