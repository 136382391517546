<template>
  <div class="progress-bar">
    <div v-if="label" class="label">{{ label }}:</div>
    <div class="progress-bar-wrapper">
      <div class="bar" :style="{ width: `${percent}%` }" />
    </div>
    <div v-if="showPercent" class="percent">{{ percent }}%</div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    total: {
      type: Number,
      required: true,
    },
    processed: {
      type: Number,
      default: 0,
    },
    showPercent: {
      type: Boolean,
      default: false,
    },
    percentDecimals: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    percent() {
      const pct = this.total === 0 ? 0 : this.processed / this.total;
      return (100 * pct).toFixed(this.percentDecimals);
    },
  },
};
</script>

<style scoped>
.progress-bar {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;
}
.progress-bar-wrapper {
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.bar {
  background: black;
  height: 100%;
}
.percent {
  font-size: 0.7rem;
  margin-left: 1rem;
}
.label {
  font-size: 0.7rem;
  margin-right: 1rem;
}
</style>
