<template>
  <div class="spinner-container">
    <div class="spinner"></div>
    <h1 v-if="label" class="spinner-label">{{ label }}</h1>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-container {
  position: fixed;
  top: 56px; // top bar is 56px high
  left: 56px; // left bar is 56px wide
  right: 0;
  bottom: 0;
  z-index: 9000;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 50px;

  .spinner-row td & {
    height: 100px;
  }
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.spinner {
  text-indent: -9999em;
  border-top: 10px solid rgba(76, 175, 80, 0.3);
  border-right: 10px solid rgba(76, 175, 80, 0.3);
  border-bottom: 10px solid rgba(76, 175, 80, 0.3);
  border-left: 10px solid var(--green);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.spinner-label {
  font-size: 24px;
  text-align: center;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
