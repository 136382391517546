export const playlistLineupStates = {
  ACCEPTED: 'A',
  APPLIED: 'Y',
  CONFLICT: 'C',
  DELETED: 'D',
  REJECTED_DELETED: 'Z',
  MERGED: 'M',
  REJECTED: 'R',
  REJECTED_SENT: 'X',
  NOT_MATCHED: 'N',
};

export const processType = {
  VRDB2: 'VRDB2',
  PLAYTIME: 'PLAYTIME',
  SDEG: 'SDEG',
};

export const ReportType = {
  PLAYED_RECORDING_REPORT: 'PRR',
  PLAYLIST_REPORT: 'PLR',
};

export const performerMatchState = {
  DISBURSE: 'D',
  CHECK: 'C',
  REFUND: 'R',
  DONE: 'T',
};

export function createUploadFile(
  file = null,
  path = null,
  source = null,
  reportDate = null,
  uploadProgress = null,
  localCodeType = null,
) {
  return {
    file,
    path,
    source,
    reportDate,
    uploadProgress,
    localCodeType,
  };
}

export function createSdegFile(
  file = null,
  path = null,
  source = null,
  society = null,
  name = null,
  from = null,
  to = null,
  uploadProgress = null,
) {
  return {
    file,
    path,
    source,
    society,
    name,
    from,
    to,
    uploadProgress,
  };
}

export function createPlaylistLocalPerformer() {
  return {
    ipn: null,
    first_name: null,
    last_name: null,
    role_code: null,
    main_instrument: [],
    date_of_birth: null,
    artist_type: null,
    internal_id: null,
    pseudo_names: [],
  };
}
