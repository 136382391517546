<template>
  <form :data-vv-scope="scope">
    <div class="row">
      <div class="col s8">
        <h2 class="float-left">Collects/Inherits from</h2>
      </div>
    </div>
    <div class="card">
      <template v-for="(associate, index) in childAssociations">
        <hr v-if="index > 0" :key="`${index}-hr`" />
        <div :key="index">
          <edit-associate
            :associate="associate"
            :index="index"
            :relation-type="relationType"
            :namespace="namespace"
            :required="required"
          />
        </div>
      </template>
    </div>
    <div class="row">
      <div class="col s12 edit-link">
        <a :class="{ disabled: !lastHasEntry }" @click="addNewChildAssociate">
          <i class="far fa-plus-square" />
          Add associated
        </a>
      </div>
    </div>
  </form>
</template>

<script>
import { mapMutations } from 'vuex';
import { createAssociation } from '../../../domain/common';
import AssociatesMixin from './associates-mixin';
import EditAssociate from './edit-associate';

export default {
  name: 'CreateChildAssociations',
  components: {
    EditAssociate,
  },
  mixins: [AssociatesMixin],
  inject: ['$validator'],
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    lastHasEntry() {
      return this.childAssociations.every((a) => a.name && a.start_date);
    },
  },
  mounted() {
    this.addNewChildAssociate();
  },
  methods: {
    ...mapMutations({
      update() {
        this.$emit('update');
      },
      updateChildAssociations(commit, payload) {
        return commit('associate/updateChildAssociations', payload);
      },
      removeFromChildAssociations(commit, payload) {
        return commit('associate/removeFromChildAssociations', payload);
      },
      addToChildAssociations(commit, payload) {
        return commit('associate/addToChildAssociations', payload);
      },
      replaceInChildAssociations(commit, payload) {
        return commit('associate/replaceInChildAssociations', payload);
      },
    }),
    addNewChildAssociate() {
      const obj = createAssociation(null, -1, 'performer');
      obj._new = true;
      obj.is_recipient_of_money = false;
      this.addToChildAssociations(obj);
    },
  },
};
</script>
