<template>
  <div>
    <div class="row">
      <div class="col s7">
        <TableOptions
          v-if="bulkEnabled"
          :bulk-actions="bulkEditActions"
          :bulk-action-enabled="anySelected()"
          :range-enabled="false"
          :custom-select="tagSelect"
          @bulkAction="onBulkAction"
          @customSelectChanged="onTagSelect($event)"
        />
      </div>
      <div
        v-if="
          ((currentState === matchState.DISBURSE || currentState === matchState.REFUND) &&
            statementRows.items &&
            statementRows.items.length > 0) ||
          (currentState === matchState.REFUND && statementRows.feedback)
        "
        class="col s5 text--right"
      >
        <button
          class="btn"
          :disabled="state !== RevenueState.TO_BE_DISTRIBUTED"
          @click="onActionButtonClick"
        >
          <span v-if="currentState === matchState.DISBURSE">Confirm & send to ledger</span>
          <span v-else>Create feedback file</span>
        </button>
      </div>
    </div>
    <div class="row card">
      <div class="col s12">
        <table>
          <CompareSort
            :bulk-enabled="bulkEnabled"
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            @markAll="toggleSelectAll"
            @sort="sort"
          />
          <ComparePerformersStatement
            :local-rows="localRows"
            :state="currentState"
            :marked-for-bulk="allAreSelected()"
            :statement-header="statementHeader"
            :columns="columns"
            @fetchData="fetchData(true)"
            @candidateChanged="onCandidateChanged"
            @newCandidate="onNewCandidate"
          />
        </table>

        <pagination
          v-if="numberOfPages > 0"
          class="result__pagination"
          :number-of-pages="numberOfPages"
          :selected-page="pagination.currentPage"
          :number-of-hits="totalCount"
          :hits-per-page="pagination.hitsPerPage"
          @selectPage="selectPage"
          @updateHitsPerPage="updateHitsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import clone from '../../../common/clone';
import TableOptions from '../../ui/table/table-options';
import { RevenueState } from '../../../domain/distributionDomain';
import ComparePerformersStatement from './compare-performers-statement';
import CompareSort from '../../compare/compare-sort';
import Pagination from '../../pagination';
import PaginationAndSortMixin from '../../../common/paginationAndSortMixin';
import StatementService from '../../../services/statementService';
import SearchHelper from '../../search/searchHelper';
import { performerMatchState as matchState } from '../../../domain/matchingDomain';

export default {
  name: 'StatementMatchingTable',
  components: {
    ComparePerformersStatement,
    CompareSort,
    Pagination,
    TableOptions,
  },
  mixins: [PaginationAndSortMixin],
  props: {
    statementHeader: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      bulkEditActions: [],
      statementRows: [],
      localRows: [],
      RevenueState,
      aggregateId: this.$router.currentRoute.params.id,
      currentState: '',
      matchState,
      totalCount: 0,
      tagSelect: {
        name: 'Tag',
        selected: undefined,
        items: [
          { key: 'International NOK', name: 'International NOK' },
          { key: 'International OK', name: 'International OK' },
          { key: 'Duplicate', name: 'Duplicate' },
          { key: 'Former member', name: 'Former member' },
        ],
        itemKey: 'key',
        itemValue: 'name',
      },
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalCount / this.pagination.hitsPerPage);
    },
    ...mapGetters('revenue', ['state']),
    bulkEnabled() {
      return [this.matchState.DISBURSE, this.matchState.REFUND].includes(this.currentState);
    },
    columns() {
      const defaultCols = [
        { name: 'Name', sortParam: 'name', active: false },
        { name: 'Main artist' },
        { name: 'Ipn' },
        { name: 'Tags' },
        { name: 'Net amount', styleClass: 'text--right' },
        {
          name: 'Converted net amount',
          styleClass: 'text--right',
          sortParam: 'amount',
          active: true,
          default: true,
          ascending: false,
        },
      ];
      if (this.currentState === this.matchState.REFUND) {
        return [
          ...defaultCols.slice(0, 3),
          {
            name: 'Reason',
            resolve: (row) => row.error || '',
          },
          ...defaultCols.slice(3),
        ];
      }
      return defaultCols;
    },
  },
  created() {
    switch (this.$router.currentRoute.name) {
      case 'statementToCheck':
        this.currentState = this.matchState.CHECK;
        break;
      case 'statementDisburse':
        this.currentState = this.matchState.DISBURSE;
        this.bulkEditActions = [{ key: this.matchState.CHECK, value: "Move back to 'to check'" }];
        break;
      case 'statementRefund':
        this.currentState = this.matchState.REFUND;
        this.bulkEditActions = [{ key: this.matchState.CHECK, value: "Move back to 'to check'" }];
        break;
      default:
        this.currentState = this.matchState.DONE;
        break;
    }
  },
  methods: {
    createChangePerformerMatchStateCommand(statementHeaderId, rows, state) {
      const command = {
        statement_header_id: statementHeaderId,
        items: rows.map((row) => ({
          performerId: row.topCandidate ? row.topCandidate.id : row.performer_id,
          groupId: row.group_id,
          state,
        })),
      };
      return command;
    },
    async changePerformerMatchState(statementHeaderId, rows, state) {
      await StatementService.changePerformerMatchState(
        this.createChangePerformerMatchStateCommand(statementHeaderId, rows, state),
      );
    },
    async onCandidateChanged(row, _candidate) {
      if (_candidate) {
        const candidateList = await SearchHelper.nameSearchPerformer(_candidate.id);
        this.localRows.forEach((localItem) => {
          if (localItem.id === row.id) {
            localItem.topCandidate = candidateList[0];
          }
        });
        this.localRows = this.localRows.slice();
      }
    },
    onNewCandidate(row, candidate) {
      this.localRows.forEach((localItem) => {
        if (localItem.id === row.id) {
          localItem.matchingDetails.hits.unshift(candidate);
          localItem.topCandidate = candidate;
        }
      });
      this.localRows = this.localRows.slice();
    },
    async onActionButtonClick() {
      this.$starContentLoading(true);
      if (this.currentState === this.matchState.DISBURSE) {
        await StatementService.confirmDisburse({
          statementId: this.statementHeader.id,
          revenueId: this.aggregateId,
        });
      } else {
        await StatementService.createFeedbackFile({
          revenueId: this.aggregateId,
          statementId: this.statementHeader.id,
        });
      }
      setTimeout(() => {
        this.fetchData(true);
      }, 5000);
    },
    onTagSelect(tag) {
      this.tagSelect.selected = tag;
      this.fetchData();
    },
    async fetchData(fetchRevenue = false) {
      this.$starContentLoading(true);
      this.error = false;
      try {
        const result = await StatementService.getStatementPerformers(this.statementHeader.id, {
          state: this.currentState,
          limit: this.pagination.hitsPerPage,
          offset: this.getOffset(),
          dir: this.sortTerms.order.toLowerCase(),
          orderBy: this.sortTerms.param,
          tag: this.tagSelect.selected,
        });

        this.totalCount = result.total_count;
        this.statementRows = result;
        this.localRows = clone(this.statementRows.items);

        if (this.totalCount === 0 && fetchRevenue) {
          this.$emit('fetchRevenue');
        }
      } catch (error) {
        error.title = 'Could not fetch statement rows';
        this.$addStarError(error);
        this.statementRows = {};
      }

      this.$starContentLoading(false);
    },
    async onBulkAction(state) {
      const checkedRows = this.localRows.filter((row) => row.checked);
      await this.changePerformerMatchState(
        this.statementHeader.id,
        checkedRows,
        state.toUpperCase(),
      );
      this.fetchData(true);
    },
    allAreSelected(predicate = () => true) {
      if (this.localRows) {
        return this.localRows.length > 0
          ? this.localRows.filter(predicate).every((row) => row.checked)
          : false;
      }
      return false;
    },
    toggleSelectAll(incomingSelect, predicate = () => true) {
      const select = !incomingSelect || this.allAreSelected(predicate);
      this.localRows.filter(predicate).forEach((row) => {
        if (!row.checked) {
          row.checked = false;
        }
        row.checked = !select;
      });
      this.localRows = this.localRows.slice();
    },
    anySelected() {
      if (this.localRows) {
        return this.localRows.some((row) => row.checked);
      }
      return false;
    },
  },
};
</script>
