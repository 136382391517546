<template>
  <div class="select-date">
    <label class="label" :class="{ 'label--error': validationErrors.has(name, scope) }">
      {{ label }}
      <span v-if="mandatory" class="mandatory">(mandatory)</span>
    </label>
    <input
      v-model="inputDate"
      v-validate="computedRule"
      data-vv-as="date"
      type="text"
      maxlength="10"
      autocomplete="off"
      data-vv-validate-on="input"
      :name="name"
      :class="{ 'is-danger': validationErrors.has(name, scope) }"
      placeholder="YYYY-MM-DD"
      :disabled="disabled"
      @input="onInput"
      @keyup.down="showDatePicker"
      @keyup.up="hideDatePicker"
    />
    <span v-if="validationErrors.has(name, scope)" class="help is-danger">
      <i class="fas fa-times-circle" />
      {{ validationErrors.first(name, scope) }}
    </span>
    <div v-show="datePickerVisible" class="select-date__picker">
      <datepicker
        :name="`${name}-datepicker`"
        :initial-view="initialView"
        :inline="true"
        :monday-first="true"
        :disabled="disabled"
        :disabled-dates="disabledDates"
        format="YYYY-MM-DD"
        :value="enforcedDate"
        @selected="onSelected"
        @changedYear="onChangedYear"
        @changedMonth="onChangedMonth"
      />
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
import validationPatterns from '../../../common/validationPatterns';

export default {
  name: 'SelectDate',
  components: {
    Datepicker,
  },
  inject: ['$validator'],
  props: {
    value: { type: String },
    name: {
      type: String,
      required: true,
    },
    initialView: {
      type: String,
      default: 'year',
    },
    label: { type: String, default: '' },
    scope: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
    extraDatePatterns: { type: Array, default: () => [] },
    rule: {
      type: Object, // Allow only Object due to computed rule
      default: () => {},
    },
  },
  data() {
    return {
      enforcedDate: undefined,
      inputDate: this.value || '',
      datePickerPickedDate: {},
      datePickerVisible: false,
    };
  },
  computed: {
    computedRule() {
      return Object.assign(
        {},
        {
          date_format: 'yyyy-MM-dd',
          after: '1800-01-01',
        },
        this.rule,
      );
    },
    disabledDates() {
      return {
        to: this.rule && this.rule.after ? new Date(this.rule.after) : undefined, // Disable all dates up to specific date
        from: this.rule && this.rule.before ? new Date(this.rule.before) : undefined, // Disable all dates after specific date
      };
    },
  },
  watch: {
    value() {
      this.inputDate = this.value;
    },
  },
  created() {
    this.addEventListeners();
  },
  destroyed() {
    this.removeEventListeners();
  },
  methods: {
    hideDatePicker() {
      this.datePickerVisible = false;
    },
    showDatePicker() {
      this.datePickerVisible = true;
    },
    addEventListeners() {
      window.addEventListener('keyup', this.keyupListener);
      window.addEventListener('mouseup', this.onMouseup);
    },
    removeEventListeners() {
      window.removeEventListener('keyup', this.keyupListener);
      window.removeEventListener('mouseup', this.onMouseup);
    },
    keyupListener(e) {
      if (e.keyCode === 27) {
        this.onKeyupEscape(e);
      }
    },
    onKeyupEscape(e) {
      if (this.datePickerVisible) {
        this.hideDatePicker();
      } else if (e.target.name === this.name) {
        this.clearAll();
      }
    },
    onInput(e) {
      if (['deleteContentBackward', 'deleteSoftLineBackward'].includes(e.inputType)) {
        if (!e.target.value) {
          this.$emit('input');
        }
        return;
      }
      if (this.isValidDate(e.target.value)) {
        this.enforceInputDate(e.target.value);
        this.hideDatePicker();
      } else {
        this.enforcedDate = undefined;
      }
    },
    onMouseup(e) {
      const path = e.path || (e.composedPath && e.composedPath());
      if (e.target.name === this.name) {
        if (e.target === document.activeElement) {
          if (this.datePickerVisible === true) {
            this.hideDatePicker();
          } else {
            this.showDatePicker();
          }
        }
      } else if (!path.includes(this.$el)) {
        this.hideDatePicker();
      }
    },
    onChangedYear(e) {
      if (e && e.year) {
        this.datePickerPickedDate.year = moment().year(e.year).format('YYYY');
        this.populateInputField();
      }
    },
    onChangedMonth(e) {
      if (e && e.month) {
        this.datePickerPickedDate.month = moment().month(e.month).format('MM');
        this.populateInputField();
      }
    },
    onSelected(e) {
      this.enforceDatePickerDate(e);
      this.hideDatePicker();
    },
    isUntouched() {
      try {
        if (this.scope) {
          return this.validationFields[`$${this.scope}`][this.name].untouched;
        }
        return this.validationFields[this.name].untouched;
      } catch (e) {
        return false;
      }
    },
    clearAll() {
      this.datePickerPickedDate = {};
      this.enforcedDate = undefined;
      this.$emit('input', this.enforcedDate);
    },
    populateInputField() {
      let str = this.datePickerPickedDate.year;
      if (this.datePickerPickedDate.month) {
        str += `-${this.datePickerPickedDate.month}`;
        if (this.datePickerPickedDate.day) {
          str += `-${this.datePickerPickedDate.day}`;
        }
      }
      this.inputDate = str;
    },
    trimDashes(str) {
      return str.trim().replace(/-/g, '');
    },
    isValidDate(str) {
      const inputReady = this.inputIsReady(str);
      return inputReady && moment(str, ['YYYY-MM-DD']).isValid();
    },
    inputIsReady(input) {
      if (input.indexOf('-') >= 0) {
        if (this.extraDatePatterns.some((pattern) => validationPatterns[pattern].test(input))) {
          return true;
        }
        return validationPatterns.DATE.test(input);
      }
      return input.length === 8;
    },
    enforceInputDate(str) {
      this.enforcedDate = moment(this.trimDashes(str), ['YYYY-MM-DD']).format('YYYY-MM-DD');
      this.$emit('input', this.enforcedDate);
    },
    enforceDatePickerDate(date) {
      this.enforcedDate = moment(date).format('YYYY-MM-DD');
      this.$emit('input', this.enforcedDate);
    },
  },
};
</script>
