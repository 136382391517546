import * as uuid from 'uuid';
import { query, mutate } from './apolloRequest';
import gql from '../domain/paymentGql';
import worklistGql from '../domain/worklistGql';
import AuthenticationService from './authenticationService';

function mapCreatePaymentCommand(payment) {
  return {
    stream_id: uuid.v4(),
    name: payment.name,
    society_id: payment.societyId,
    payment_date: payment.paymentDate,
    min_limit: payment.minimumAmount,
    node_ids: payment.nodeIds,
  };
}

function mapCreateWorklistsCommand(id, partitionInfo) {
  return {
    id,
    partitionInfo: {
      worklist_ids: partitionInfo.worklistIds,
      size: Number.parseInt(partitionInfo.size, 10),
    },
  };
}

function mapMovePaymentRowsCommand(paymentId, ids, operation) {
  return {
    id: paymentId,
    operation,
    row_ids: ids,
  };
}

function preparePayment(_payment) {
  const payment = Object.assign({}, _payment);
  if (payment.rows === null) {
    payment.rows = { rows: [] };
  }
  return payment;
}

export default {
  getRemuneration: async (id) =>
    query({ query: gql.getRemuneration, variables: { idTerm: id } }, { pickProp: 'remuneration' }),
  getLedgerDetails: async (ledgerDetailRequest) =>
    query(
      { query: gql.getLedgerDetails, variables: { ledgerDetailRequest } },
      { pickProp: 'ledgerDetails' },
    ),
  getCurrentLedgerVersion: async (ledgerId) => {
    const result = await query(
      {
        query: gql.getCurrentLedgerVersion,
        variables: { ledgerDetailRequest: { limit: 1, ledgerId } },
      },
      { pickProp: 'ledgerDetails' },
    );
    return Number(result.version) || 1;
  },
  getSummaryArea: async (id, year) =>
    query(
      { query: gql.getSummaryArea, variables: { idTerm: id, yearTerm: year } },
      { pickProp: 'summaryArea' },
    ),
  getRemunerationBalance: async (id) =>
    query(
      { query: gql.getRemunerationBalance, variables: { idTerm: id } },
      { pickProp: 'remunerationBalance' },
    ),
  getAllPayments: async (request) =>
    query({ query: gql.getAllPayments, variables: { request } }, { pickProp: 'getAllPayments' }),
  getPaymentHistory: async (paymentHistoryRequest) =>
    query(
      { query: gql.getPaymentHistory, variables: { paymentHistoryRequest } },
      { pickProp: 'paymentHistory' },
    ),
  getPaymentDetails: async (nodeId, rowId) =>
    query(
      { query: gql.getPaymentDetails, variables: { nodeId, rowId } },
      { pickProp: 'paymentDetails' },
    ),
  getPaymentNode: async (nodeId, paymentId) =>
    query(
      { query: gql.getPaymentNode, variables: { nodeId, paymentId } },
      { pickProp: 'paymentNode' },
    ),
  getPayment: async (request) => {
    const payment = await query(
      { query: gql.getPayment, variables: { request } },
      { pickProp: 'getPayment' },
    );
    return preparePayment(payment);
  },
  getLedgerBalance: async (id) =>
    query({ query: gql.getLedgerBalance, variables: { idTerm: id }, pickProp: 'ledgerBalance' }),
  getTrackBreakdown: async (request) =>
    query({ query: gql.getTrackBreakdown, variables: { request }, pickProp: 'trackBreakdown' }),
  getUniqueErrorTypes: async (paymentId) =>
    query(
      { query: gql.getUniqueErrorTypes, variables: { paymentId } },
      { pickProp: 'getUniqueErrorTypes' },
    ),
  createPayment: async (payment) =>
    mutate(
      {
        mutation: gql.createPayment,
        variables: { command: mapCreatePaymentCommand(payment) },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'createPayment' },
    ),
  deletePayment: async (id) =>
    mutate(
      { mutation: gql.deletePayment, variables: { idTerm: id }, fetchPolicy: 'network-only' },
      {},
    ),
  createOutputs: async (paymentId, processId = uuid.v4()) =>
    mutate(
      {
        mutation: gql.createOutputs,
        variables: { command: { paymentId, processId } },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'createOutputs' },
    ),
  confirmPayment: async (id) =>
    mutate(
      { mutation: gql.confirmPayment, variables: { idTerm: id }, fetchPolicy: 'network-only' },
      { pickProp: 'confirmPayment' },
    ),
  movePaymentRows: async (paymentId, ids, operation) =>
    mutate(
      {
        mutation: gql.movePaymentRows,
        variables: { command: mapMovePaymentRowsCommand(paymentId, ids, operation) },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'movePaymentRows' },
    ),
  skipTransferRow: async (paymentId, transferId) =>
    mutate({ mutation: gql.skipTransferRow, variables: { paymentId, transferId } }),
  unskipTransferRow: async (paymentId, transferId) =>
    mutate({ mutation: gql.unskipTransferRow, variables: { paymentId, transferId } }),
  setWorklistIds: async (id, partitionInfo) =>
    mutate(
      {
        mutation: worklistGql.createPaymentWorklists,
        variables: { command: mapCreateWorklistsCommand(id, partitionInfo) },
        fetchPolicy: 'network-only',
      },
      {},
    ),
  refund: async (command) =>
    mutate({ mutation: gql.refund, variables: { command }, fetchPolicy: 'network-only' }, {}),
  getDistributionClosurePredictionHeaderRowCounts: async (year) =>
    query(
      { query: gql.getDistributionClosurePredictionHeaderRowCounts, variables: { year } },
      { pickProp: 'getDistributionClosurePredictionHeaderRowCounts' },
    ),
  getDistributionClosurePredictionHeaderAmounts: async (year) =>
    query(
      { query: gql.getDistributionClosurePredictionHeaderAmounts, variables: { year } },
      { pickProp: 'getDistributionClosurePredictionHeaderAmounts' },
    ),
  getDistributionClosurePrediction: async (command) =>
    query(
      { query: gql.getDistributionClosurePrediction, variables: { command } },
      { pickProp: 'getDistributionClosurePrediction' },
    ),
  getDistributionClosureHeaders: async () =>
    query(
      { query: gql.getDistributionClosureHeaders },
      { pickProp: 'getDistributionClosureHeaders' },
    ),
  createDistributionClosure: async (year) =>
    mutate(
      {
        mutation: gql.createDistributionClosure,
        variables: { command: { year, user: AuthenticationService.getUserName() } },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'createDistributionClosure' },
    ),
  calculateDistributionClosure: async (id) =>
    mutate(
      {
        mutation: gql.calculateDistributionClosure,
        variables: { command: { id, user: AuthenticationService.getUserName() } },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'calculateDistributionClosure' },
    ),
  getActiveDistributionClosure: async () =>
    query(
      { query: gql.getActiveDistributionClosure },
      { pickProp: 'getActiveDistributionClosure' },
    ),
  getDistributionClosure: async (id) =>
    query(
      { query: gql.getDistributionClosure, variables: { id } },
      { pickProp: 'getDistributionClosure' },
    ),
  getDistributionClosureDetails: async (request) =>
    query(
      { query: gql.getDistributionClosureDetails, variables: { request } },
      { pickProp: 'getDistributionClosureDetails' },
    ),
  getDistributionClosureLastEvent: async (id) =>
    query(
      { query: gql.getDistributionClosureLastEvent, variables: { id } },
      { pickProp: 'getDistributionClosureLastEvent' },
    ),
  deleteDistributionClosure: async (id) =>
    mutate(
      {
        mutation: gql.deleteDistributionClosure,
        variables: { id: id.toString() },
        fetchPolicy: 'network-only',
      },
      {},
    ),
  approveDistributionClosure: async (id, processId) =>
    mutate(
      {
        mutation: gql.approveDistributionClosure,
        variables: {
          command: { id: id.toString(), processId, user: AuthenticationService.getUserName() },
        },
      },
      { pickProp: 'approveDistributionClosure' },
    ),
  confirmDistributionClosure: async (id, processId) =>
    mutate(
      {
        mutation: gql.confirmDistributionClosure,
        variables: {
          command: { id: id.toString(), processId, user: AuthenticationService.getUserName() },
        },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'confirmDistributionClosure' },
    ),
  approvePayment: async (id) => mutate({ mutation: gql.approvePayment, variables: { idTerm: id } }),
  processPayment: async (paymentId, nodeIds) =>
    mutate(
      { mutation: gql.processPayment, variables: { paymentId, pid: uuid.v4(), nodeIds } },
      { pickProp: 'processPayment' },
    ),
  getActiveMandates: async (performerId) =>
    query(
      { query: gql.getActiveMandates, variables: { performerId } },
      { pickProp: 'getActiveMandates' },
    ),
};

export function getState(state) {
  return [
    'Created',
    'ApprovalReady',
    'ApprovalRequested',
    'Approved',
    'ConfirmReady',
    'ConfirmRequested',
    'Confirmed',
  ][state];
}
