<template>
  <div class="activity">
    <div
      v-for="(groupedActivity, index) in groupedActivities"
      :key="`groupedActivity-${index}`"
      :class="{ frame: eventExpanded(groupedActivity[0].id) && groupedActivity.length > 1 }"
    >
      <div
        v-for="(activity, index2) in groupedActivity"
        :key="`activity-${index2}`"
        class="card-wrapper"
        @click="toggleDetails(groupedActivity[0].id)"
      >
        <div
          v-if="index2 === 0 || eventExpanded(groupedActivity[0].id)"
          class="row col s12 card card--half"
          :class="{
            'card--active': activity.version === version || (activity.id === 0 && !version),
          }"
        >
          <div class="text--bold">{{ $filters.formatDateTime(activity.timestamp) }}</div>

          <div class="row" :class="{ 'no-margin': groupedActivity.length > 1 }">
            <span>
              {{ activity.username || 'Star' }}
            </span>
            <span v-if="activity.section === 'created'">
              created "{{ $filters.translate(`activity.${activity.type}`) }}"
            </span>
            <span v-else-if="activity.section === 'sdeg_created_recording'">
              created "{{ $filters.translate(`activity.${activity.type}`) }}" via SDEG
            </span>
            <span v-else-if="activity.section === 'ipd_create_performer'">
              requested new
              <span>"{{ $filters.translate(`activity.ipd_create_performer`) }}"</span>
            </span>
            <span
              v-else-if="
                activity.section === 'membership_agreement_sent' ||
                activity.section === 'membership_agreement_received'
              "
            >
              executed
              <span>"{{ $filters.translate(`activity.${activity.section}`) }}"</span>
            </span>
            <span v-else-if="activity.section === 'deleted'">
              deleted "{{ $filters.translate(`activity.${activity.type}`) }}"
            </span>
            <span v-else-if="activity.section === 'merged'">
              merged "{{ $filters.translate(`activity.${activity.type}`) }}"
            </span>
            <span v-else>
              edited
              <span>"{{ $filters.translate(`activity.${activity.section}`) }}"</span>
            </span>
          </div>
          <div v-if="groupedActivity.length > 1 && index2 === 0" class="">
            <span class="thin-column dropdown-button__arrow">
              <i
                :class="`fas fa-caret-${eventExpanded(groupedActivity[0].id) ? 'up' : 'down'}`"
              ></i>
              <span>({{ groupedActivity.length }})</span>
            </span>
          </div>

          <div v-if="eventExpanded(groupedActivity[0].id)" class="row flush--bottom">
            <template v-if="activity.section === 'merged'">
              <div class="margin--top">Merged with:</div>
              <div v-for="mergedId of activity.payload.merged_with" :key="mergedId">
                <router-link :to="`/${activity.type}s/${mergedId}`">
                  {{ mergedId }}
                </router-link>
              </div>
            </template>
            <template v-if="activity.section === 'deleted' && activity.payload.merged_with">
              <div class="margin--top">Merged with:</div>
              <div>
                <router-link :to="`/${activity.type}s/${activity.payload.merged_with}`">
                  {{ activity.payload.merged_with }}
                </router-link>
              </div>
            </template>
            <template v-if="activity.section === 'created' && activity.payload.cloned_from">
              <div class="margin--top">Cloned from:</div>
              <div>
                <router-link :to="`/${activity.type}s/${activity.payload.cloned_from}`">
                  {{ activity.payload.cloned_from }}
                </router-link>
              </div>
            </template>

            <div
              v-if="activity.version === version || (activity.id === 0 && !version)"
              class="margin--top selected-version"
            >
              You are watching version: {{ activity.version }} {{ index === 0 ? '(Latest)' : '' }}
            </div>
            <div v-else class="margin--top blue-text">
              <router-link
                :to="{ name: viewName, params: { id: streamId, version: activity.version } }"
              >
                <span class="navigation__item-text text--bold">
                  View version: {{ activity.version }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import HistoryService from '../../services/historyService';

export default {
  props: {
    streamId: String,
    version: Number,
    viewName: String,
  },
  data() {
    return {
      activities: [],
      groupedActivities: [],
      expandedEventIds: [],
      Action: HistoryService.Action,
    };
  },
  async created() {
    this.activities = await this.fetchActivities(this.streamId);
    this.groupedActivities = Object.values(
      this.activities.reduce((acc, entry) => {
        if (acc.length) {
          const currentArray = acc[acc.length - 1];
          const existing = currentArray[currentArray.length - 1];

          if (this.isReduceable(existing, entry)) {
            currentArray.push(entry);
          } else {
            acc.push([entry]);
          }
        } else {
          acc.push([entry]);
        }
        return acc;
      }, []),
    );
  },
  methods: {
    mapActivity(activity) {
      activity.section = _.snakeCase(activity.section).replace(/_updated|_patched/, '');
      activity.show = activity.version === this.version || (activity.id === 0 && !this.version);
      return activity;
    },
    eventExpanded(id) {
      return _.includes(this.expandedEventIds, id);
    },
    isReduceable(existing, entry) {
      const sameSection = ['mandate', 'mandate_cancelled'];
      return (
        existing &&
        (existing.section === entry.section ||
          (sameSection.includes(existing.section) && sameSection.includes(entry.section))) &&
        existing.username === entry.username &&
        moment(existing.timestamp).isSame(moment(entry.timestamp), 'day')
      );
    },
    async fetchActivities(streamId) {
      const activities = await HistoryService.getActivities(streamId);
      return activities.map(this.mapActivity);
    },
    toggleDetails(id) {
      const index = _.indexOf(this.expandedEventIds, id);
      if (index === -1) {
        this.expandedEventIds.push(id);
      } else {
        this.expandedEventIds.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.activity {
  display: flex;
  flex-direction: column;

  .card {
    cursor: pointer;
    padding-bottom: 10px;

    &--active {
      border: 1px solid var(--orange);
    }

    .row {
      margin-bottom: 0;
    }
  }
  .frame {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid;

    .card-wrapper:nth-child(n + 2) {
      align-self: center;
      font-size: 0.8em;
      width: 66%;
    }
  }
}
</style>
