<template>
  <div>
    <div class="row">
      <p v-if="processId" class="processing">
        <ReusableSpinner :size="16" />
        <span>Processing...</span>
      </p>
      <p v-else>Ready to begin processing.</p>
      <p v-if="progress > 0 && progress < 0.02" class="tip">
        <strong>Hey you!</strong>
        It's normal for this progress bar to get stuck at 1% for a minute or so. Please hold tight!
      </p>
      <div class="button-and-progress">
        <button class="btn" :disabled="!!processId || loading" @click="startJob">
          Process now
        </button>
        <process-progress-bar
          v-if="processId"
          :id="processId"
          class="progress-bar"
          @finished="onProcessFinished"
          @progressChanged="onProgressChanged($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <div class="float-right">
          <action-buttons
            submit-label="Back to distribution closures"
            :show-abort="false"
            @save="backToHistory()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { STATE } from '@/store/modules/process/store';
import AuthenticationMixin from '../../../common/authenictaionMixin';
import ActionButtons from '@/components/ui/button/action-buttons';
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import CommandService from '@/services/commandService';
import ProcessProgressBar from '../../process/process-progress-bar.vue';
import ReusableSpinner from '../../reusable-spinner.vue';

export default {
  name: 'ProgressDistributionClosureVerify',
  components: {
    ActionButtons,
    ProcessProgressBar,
    ReusableSpinner,
  },
  mixins: [AuthenticationMixin],
  props: {
    distributionClosure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      working: false,
      approved: this.distributionClosure.header?.state === distributionClosureState.Approved,
      verifyWithPasswordMessage:
        ' I hereby confirm that I want to perform\n' + '          a distribution closure.\n',
      processId: null,
      progress: 0,
      loading: false,
      onProcessFinishedCalled: false,
    };
  },
  mounted() {
    if (this.distributionClosure.header.workerProcessId) {
      this.processId = this.distributionClosure.header.workerProcessId;
    }
  },
  methods: {
    async startJob() {
      try {
        this.loading = true;

        const result = await CommandService.handleCommand('payment', {
          type: 'closure/run-job',
          stream_id: this.distributionClosure.header.distributionClosureId,
          payload: {},
        });

        this.processId = result.processId;

        this.$store.dispatch('process/addProcess', {
          name: 'Distribution closure',
          id: result.processId,
          route: `/distribution-closures/${this.distributionClosure.header.distributionClosureId}`,
          routeAvailability: [STATE.RUNNING, STATE.FINISHED],
        });
      } finally {
        this.loading = false;
      }
    },
    onProcessFinished() {
      if (!this.onProcessFinishedCalled) {
        this.$emit('refresh', { targetState: distributionClosureState.Ready });
      }
      this.onProcessFinishedCalled = true;
    },
    onProgressChanged(progress) {
      this.progress = progress;
      if (this.progress >= 1) {
        this.onProcessFinished();
      }
    },
    backToHistory() {
      this.$router.push({
        name: 'distributionClosureHistory',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-and-progress {
  display: flex;
  align-items: center;
  gap: 15px;
}

.progress-bar {
  flex: 1;
}

.tip {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  border: var(--green) solid 1px;
  margin: 10px 0;
}

.processing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>
