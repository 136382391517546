<template>
  <div>
    <view-contact-info :contact-info="contact_info" @edit="$emit('edit', $options.name)" />

    <contact-persons-edit-table
      v-if="selectedCategory === 'organisation'"
      :contact-persons="contactInfoContactPersons"
      :disabled="disabled"
      :edit-mode="true"
      @input="onContactPersonsChanged"
    />

    <add-new-contact-persons @save="onSaveNewContactPersons" @cancel="onCancel" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { commit } from '../../../store/modules/associate/utils';
import ContactPersonsEditTable from '../../ui/contact/contact-persons-edit-table';
import ViewContactInfo from '../../ui/contact/view-contact-info';
import AddNewContactPersons from '../../ui/contact/add-new-contact-persons';

export default {
  name: 'ContactInformation',
  components: { ContactPersonsEditTable, AddNewContactPersons, ViewContactInfo },
  props: {
    selectedCategory: { type: String, default: 'person' },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    contactPersonIds() {
      return [...Array(this.contactInfoContactPersons.length).keys()];
    },
    ...mapGetters('associate', ['contact_info', 'contactInfoContactPersons']),
  },
  methods: {
    ...mapMutations('associate', [
      'updateContactInfoContactPersons',
      'addToContactInfoContactPersons',
    ]),
    onContactPersonsChanged(contactPersons) {
      commit('updateContactInfoContactPersons', contactPersons);
      this.$emit('update');
    },
    onSaveNewContactPersons(contactPersons) {
      contactPersons.forEach((cp) => commit('addToContactInfoContactPersons', cp));
      this.$emit('update');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
