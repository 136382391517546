<template>
  <div>
    <div class="row">
      <div class="col s12 l1">
        <entity-navigation :views="navigationViews" />
      </div>
      <div class="col s12 l11 view-layout">
        <router-view type="associate" />
      </div>
    </div>
  </div>
</template>

<script>
import EntityNavigation from '../../entity-navigation';

export default {
  name: 'ViewAssociate',
  components: {
    EntityNavigation,
  },
  data() {
    return {
      navigationViews: [
        'associateInformation',
        'viewAssociateLedger',
        'viewAssociateEndOfYearStatement',
      ],
    };
  },
};
</script>
