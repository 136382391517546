export const RefundReasonTypes = {
  TRANSACTION_FAILED: 'Transaction failed',
  OTHER: 'Other',
};

export const paymentType = {
  MigratedHeader: 0,
  MigratedPayment: 1,
  Default: 2,
  MigratedPaidPayment: 3,
  0: 'MigratedHeader',
  1: 'MigratedPayment',
  2: 'Default',
  3: 'MigratedPaidPayment',
};

export const paymentState = {
  Created: 0,
  Ready: 1,
  UserApproved: 2,
  SystemApproved: 3,
  ConfirmReady: 4,
  Confirmed: 5,
  Removed: 6,
  Failed: 7,
  0: 'Created',
  1: 'Ready',
  2: 'UserApproved',
  3: 'SystemApproved',
  4: 'ConfirmReady',
  5: 'Confirmed',
  6: 'Removed',
  7: 'Failed',
};

export const fileType = ['Bank', 'Statement', 'Mandate Conflicts'];

export const transactionType = [
  'Distribution',
  'Redistribution',
  'ReserveRelease',
  'Statement',
  'Payment',
  'Refund',
];

export const paymentNodeState = {
  Pending: 0,
  Approved: 1,
  InvalidPaymentInfo: 2,
  InvalidTaxInfo: 3,
  NoValidPayee: 4,
  InvalidShare: 5,
  InsufficientFunds: 6,
  InvalidMandate: 7,
  Blocked: 8,
  0: 'Pending',
  1: 'Approved',
  2: 'InvalidPaymentInfo',
  3: 'InvalidTaxInfo',
  4: 'NoValidPayee',
  5: 'InvalidShare',
  6: 'InsufficientFunds',
  7: 'InvalidMandate',
  8: 'Blocked',
};

export const txSourceType = {
  Payment: 0,
  Deposit: 1,
  Refund: 2,
  DistClosure: 3,
  MigratedPayment: 4,
  MigratedRefund: 5,
  MigratedDistClosure: 6,
  MigratedManualTransfer: 7,
  MigratedDeposit: 8,
  Transfer: 9,
  0: 'Payment',
  1: 'Deposit',
  2: 'Refund',
  3: 'DistClosure',
  4: 'MigratedPayment',
  5: 'MigratedRefund',
  6: 'MigratedDistClosure',
  7: 'MigratedManualTransfer',
  8: 'MigratedDeposit',
  9: 'Transfer',
};

export const EntryType = {
  0: 'Credit',
  1: 'Debit',
  Credit: 0,
  Debit: 1,
};

export const vatSpecKey = '2539cb43-cb29-4cbe-8d83-76fa5db83632';

export const unspecifiedSpecKey = '2aa2bf33-50af-44ce-a1ef-fd887df4334e';

export const nodePositions = {
  Source: 0,
  Intermediary: 1,
  Payee: 2,
  0: 'Source',
  1: 'Intermediary',
  2: 'Payee',
};

export const LabelType = {
  PaymentSent: 0,
  PaymentReceived: 1,
  VatReceived: 2,
  VatSent: 3,
  RefundReceived: 4,
  RefundSent: 5,
  ManualTransferFrom: 6,
  ManualTransferTo: 7,
  0: 'PaymentSent',
  1: 'PaymentReceived',
  2: 'VatReceived',
  3: 'VatSent',
  4: 'RefundReceived',
  5: 'RefundSent',
  6: 'ManualTransferFrom',
  7: 'ManualTransferTo',
};

export function getRecordState(idx) {
  return Object.keys(paymentNodeState)[idx];
}

export const errorTypes = {
  mandate_conflict: 'Mandate conflict',
  incomplete_payment_share: 'Incomplete payment share',
  blocked: 'Blocked',
  missing_payment_info: 'Missing payment info',
  missing_tin: 'Missing tin',
  missing_org_no: 'Missing org number',
  missing_tin_and_dob: 'Missing TIN/Date of birth',
  invalid_ssn: 'Invalid ssn',
  missing_tax_domicile: 'Missing tax domicile',
  private_account: 'Invalid bank account',
  foreign_account: 'Invalid foreign account',
  company_account: 'Invalid company account',
  address: 'Invalid address',
  tax_info: 'Invalid tax info',
  missing_dob: 'Missing Date of birth',
  country_of_residence: 'Missing country of residence',
  performer_info: 'Missing performer info',
};

export const nodeTypes = [
  'heir',
  'legal_guardian',
  'company',
  'intermediary',
  'accounting_firm',
  'performer',
  'society',
  'other',
  'manager',
];
