import Vue from 'vue';
import { transformApolloError } from '../common/errorTransformers';
import gql from '../domain/referenceDataGql';

export default {
  getReferenceData: () =>
    Vue.$apollo.client
      .query({ query: gql.referenceData, variables: {} })
      .then((response) => response.data.referenceData)
      .catch((error) => Promise.reject(transformApolloError(error))),
};
