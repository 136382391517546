<template>
  <tr>
    <td v-if="!disabled" class="thin-column">
      <input
        :id="index"
        ref="mark"
        :value="marked"
        class="filled-in"
        type="checkbox"
        name="mark"
        @change="markForBulk(performer, index)"
      />
      <label :for="index" />
    </td>
    <td>
      <router-link :to="'/performers/' + performer.id">
        {{ performer.name || 'Mr. Unkown' }}
        {{ performer.protected_identity ? ' 🔒' : '' }}
      </router-link>
    </td>
    <td>
      <span v-if="!performer.pseudo || performer.pseudo.length === 0" class="none">None</span>
      <span v-else>
        {{ performer.pseudo }}
      </span>
    </td>
    <td>
      <span v-if="!performer.instruments || performer.instruments.length === 0" class="none">
        None
      </span>
      <span v-else>
        {{ $filters.formatInstrumentList(performer.instruments) }}
      </span>
    </td>
    <td>
      <span v-if="!performer.role" class="none">None</span>
      <span v-else>
        {{ $filters.formatRole(performer.role) }}
      </span>
    </td>
    <td>
      <span v-if="!performer.nationality" class="none">None</span>
      <span v-else>
        {{ $filters.formatCountry(performer.nationality) }}
      </span>
    </td>
    <td>
      <span v-if="!performer.societies || performer.societies.length === 0" class="none">None</span>
      <span v-else>
        <mandate-list :mandates="performer.societies" />
      </span>
    </td>
    <td>
      <span v-if="!performer.ipn" class="none">None</span>
      <span v-else>
        {{ performer.ipn }}
      </span>
    </td>
    <td>
      <context-menu
        v-if="!disabled"
        :options="['Edit', 'Remove']"
        @edit="editPerformer(performer.id)"
        @remove="showRemoveModal = true"
      />
    </td>

    <modal
      v-if="showRemoveModal"
      submit-label="Remove from recording"
      @save="deletePerformer(index, performer.id)"
      @close="showRemoveModal = false"
      @cancel="showRemoveModal = false"
    >
      <h1 slot="header">Remove from recording?</h1>
      <div slot="body">
        Are you sure you want to remove {{ performer.name }} from this recording?
      </div>
    </modal>
  </tr>
</template>

<script>
import ContextMenu from '../../ui/context-menu';
import MandateList from '../../ui/performer/mandate-list';
import Modal from '../../ui/dialog/modal';

export default {
  name: 'LineupRow',
  components: {
    ContextMenu,
    MandateList,
    Modal,
  },
  props: {
    performer: Object,
    index: Number,
    markedForBulk: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      optionsVisiblePerformerId: '',
      localMarkedForBulk: false,
      showRemoveModal: false,
    };
  },
  computed: {
    marked: function marked() {
      return this.markedForBulk;
    },
    mandates() {
      return this.performer.societies.reduce((flat, m) => flat.concat(m.mandates), []);
    },
  },
  created() {
    this.localMarkedForBulk = this.markedForBulk;
  },
  updated() {
    this.localMarkedForBulk = this.markedForBulk;
    if (!this.disabled) {
      this.$refs.mark.checked = this.localMarkedForBulk;
    }
  },
  methods: {
    markForBulk(performer, index) {
      this.localMarkedForBulk = !this.localMarkedForBulk;
      this.$emit('markForBulk', this.performer, this.localMarkedForBulk, index);
    },
    editPerformer(id) {
      this.$emit('editPerformer', id);
    },
    deletePerformer(index, id) {
      this.showRemoveModal = false;
      this.$emit('deletePerformer', { index, id });
    },
  },
};
</script>
