<template>
  <div>
    <select-input
      :name="name"
      :label="label"
      :value="value"
      :items="roles"
      :disabled="disabled"
      :mandatory="mandatory"
      :rule="rule"
      :scope="scope"
      :show-label="showLabel"
      :letter-sequence-filter="true"
      item-key="code"
      item-value="name_fa"
      @input="onInput"
    />
  </div>
</template>

<script>
import { addRoleFaFormat } from '../../../filters';
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectRoleCode',
  components: {
    SelectInput,
  },
  props: {
    name: { type: String, default: 'role' },
    value: { type: String, default: '' },
    label: { type: String, default: 'Role' },
    mandatory: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    rule: { type: String, default: '' },
    scope: { type: String, default: '' },
    collectiveRoles: { type: String, default: 'none' },
  },
  data() {
    return {
      code: this.value,
    };
  },
  computed: {
    roles() {
      const roles = this.$store.state.appdata.referenceData.rolesRecording.map(addRoleFaFormat);

      switch (this.collectiveRoles) {
        case 'all':
          return roles.filter((r) => ['SC', 'FC'].includes(r.code));
        case 'sami':
          return roles.filter((r) => r.code === 'SC');
        case 'foreign':
          return roles.filter((r) => r.code === 'FC');
        case 'none':
        default:
          return roles.filter((r) => !['SC', 'FC'].includes(r.code));
      }
    },
  },
  watch: {
    value() {
      this.code = this.value;
    },
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
