<template>
  <div>
    <entity-badge entity="recording" />
    <div class="search-result__everything__data">
      <div>
        <span class="search-result__everything__data__name">
          <router-link :to="'/recordings/' + typedResult.value.id">
            {{ typedResult.value.name }}{{ typedResult.value.lineup_locked ? ' 🔒' : '' }}
          </router-link>
        </span>
        <span v-if="typedResult.value.version_title">({{ typedResult.value.version_title }})</span>
        {{
          typedResult.value.main_artist
            ? 'by ' + typedResult.value.main_artist.name
            : '(main artist missing)'
        }}
      </div>
      <div
        v-if="typedResult.value.alternate_titles && typedResult.value.alternate_titles.length > 0"
        class="search-result__everything__data__alternate-names"
      >
        Also known as {{ $filters.join(typedResult.value.alternate_titles) }}
      </div>
      <div v-if="typedResult.value.isrc">
        <span class="label">ISRC:</span>
        <span>{{ typedResult.value.isrc }}.</span>
      </div>
      <div v-if="typedResult.value.release_date">
        <span class="label">Released:</span>
        <span>{{ $filters.formatDate(typedResult.value.release_date) }}.</span>
      </div>
      <div v-if="typedResult.value.tags" class="search-result__tags chips__wrapper">
        <template v-for="(tag, index) in typedResult.value.tags">
          <tag :key="index" :tag="tag" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../tags/tag';
import EntityBadge from '../ui/entity-badge';

export default {
  name: 'EverythingResultRecording',
  components: { Tag, EntityBadge },
  props: {
    typedResult: Object,
  },
};
</script>
