<template>
  <div>
    <div>
      <addable-text-input
        name="lineup"
        label="Lineup"
        scope="lineup"
        placeholder="E.g. Joakim Berg"
        :values="localLineup"
        @input="updatePerformer"
        @remove="removePerformer"
        @add="addEmptyPerformer"
      />
    </div>
  </div>
</template>

<script>
import AddableTextInput from '../ui/input/addable-text-input';

export default {
  name: 'AddExistingPerformers',
  components: { AddableTextInput },
  inject: ['$validator'],
  props: {
    performers: { type: Array },
  },
  data() {
    return {
      localLineup: [],
    };
  },
  watch: {
    performers() {
      this.localLineup = this.performers.slice();
      if (this.localLineup.length === 0) {
        this.addEmptyPerformer();
      }
    },
  },
  created() {
    this.localLineup = this.performers.slice();
    if (this.localLineup.length === 0) {
      this.addEmptyPerformer();
    }
  },
  methods: {
    updatePerformer(event) {
      const { index, value } = event;
      this.$emit('replaceInLineup', { index, performerName: value });
    },
    addEmptyPerformer() {
      this.localLineup.push('');
    },
    removePerformer(index) {
      this.$emit('removeInLineup', index);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.performer-header {
  float: left;
  margin: var(--spacing-triple) 0 0 5px;
  cursor: pointer;
}
</style>
