<template>
  <form class="e2e-search-result__performers">
    <div class="search-result__filters card">
      <div class="row">
        <div class="col s3">
          <label class="label">Search</label>
          <clearable-input
            v-model="searchTerms.query"
            type="search"
            name="filter"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s3">
          <select-country
            v-model="searchTerms.residenceCountryCode"
            label="Country of Residence"
            :input-countries="localCountries"
          />
        </div>
        <div class="col s2">
          <label class="label">City</label>
          <clearable-input
            v-model="searchTerms.city"
            type="text"
            autocomplete="off"
            placeholder="E.g. Stockholm"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s2">
          <select-country
            v-model="searchTerms.nationalityCountryCode"
            label="Nationality"
            :input-countries="localCountries"
          />
        </div>
        <div class="col s2">
          <select-date
            v-model="searchTerms.dateOfBirth"
            name="date-of-birth"
            label="Date of Birth"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s2">
          <select-instrument v-model="searchTerms.instrument" />
        </div>
        <div class="col s2">
          <select-input
            ref="society"
            v-model="searchTerms.society"
            name="society-filter"
            :value="searchTerms.society"
            :items="$store.state.appdata.societies"
            label="Society"
            :show-label="true"
            scope="create-mandate"
            item-key="ipd_code"
            item-value="name"
          />
        </div>
        <div class="col s4">
          <searchable-tag-input
            name="tags"
            label="Tags"
            placeholder="E.g. Top 100"
            :preview-list="previewTags"
            :value-list="localTags"
            :searcher="searchTag"
            scope="advanced-search-performers"
            use-template="tag"
            @add="addTag"
            @remove="removeTagAt"
          />
        </div>
        <div class="col s2">
          <div class="cb-positioning">
            <input
              id="collective"
              name="collective"
              class="filled-in"
              type="checkbox"
              :checked="searchTerms.collective"
              @change="onlyCollective($event.target.checked)"
            />
            <label for="collective">Collective</label>
          </div>
        </div>
      </div>
      <div class="row flush--bottom">
        <div class="col s12 text--right">
          <action-buttons
            submit-label="Search"
            abort-label="Clear"
            @save="onSearchClick"
            @cancel="onClearClick"
          />
        </div>
      </div>
    </div>

    <div v-show="searchContext.isExecuted" class="search-result__summary">
      Search for
      <span v-if="searchContext.query != ''" class="text--bold">{{ searchContext.query }}</span>
      <span v-else>*</span>
      in Performers gave {{ $filters.formatNumber(searchContext.result.totalHits) }} results
    </div>

    <SearchQueryTooLong
      :length-limit="searchContext.searchLengthLimit"
      :query-too-long="searchContext.isTooLong"
    />

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="search-result__result card row">
        <div class="row">
          <div class="col s12 recording-result">
            <table>
              <thead>
                <th
                  v-for="(column, index) in columns"
                  :key="index"
                  :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  @click="column.sortName ? sort(column) : ''"
                >
                  <span>{{ column.name }}</span>
                  <span v-if="column.sortName && !column.thinColumn" class="dropdown-button__arrow">
                    <i
                      v-if="column.order === 'desc'"
                      class="fas fa-caret-up"
                      :class="{ 'text--grey': !column.active }"
                    />
                    <i v-else class="fas fa-caret-down" :class="{ 'text--grey': !column.active }" />
                  </span>
                </th>
              </thead>
              <tbody>
                <template v-for="(result, resultIndex) in searchContext.result.results">
                  <tr :key="resultIndex">
                    <td class="thin-column dropdown-button__arrow" />
                    <td>
                      <span>
                        <router-link
                          :to="`/performers/${result.id}`"
                          v-html="
                            $filters.highlightToHtml(
                              `${result.first_name} ${result.last_name}${
                                result.protected_identity ? ' 🔒' : ''
                              }`,
                              searchContext.query,
                            )
                          "
                        />
                      </span>
                    </td>
                    <td>
                      <template v-if="result.pseudo_names && result.pseudo_names.length > 0">
                        <span
                          v-for="(pseudoName, index) in result.pseudo_names"
                          :key="index"
                          v-html="
                            $filters.highlightToHtml(pseudoName, searchContext.query) +
                            (index + 1 !== result.pseudo_names.length ? ', ' : '')
                          "
                        />
                      </template>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.date_of_birth">
                        {{ $filters.formatDate(result.date_of_birth) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span>{{ $filters.formatCountry(result.nationality) }}</span>
                      <span v-if="!result.nationality" class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.main_instrument">
                        {{ $filters.formatInstrument(result.main_instrument) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.main_artists.length > 0">
                        <span v-for="(mainArtist, index) in result.main_artists" :key="index">
                          <router-link :to="'/mainartists/' + mainArtist.id" class="fs-13">
                            {{ mainArtist.name }}
                            <span v-if="index + 1 !== result.main_artists.length">,</span>
                          </router-link>
                        </span>
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <mandate-list :filter-duplicates="true" :mandates="result.mandates" />
                    </td>
                    <td>
                      <span v-if="result.ipn">{{ result.ipn }}</span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.collective">Yes</span>
                      <span v-else class="none">No</span>
                    </td>
                  </tr>
                  <tr
                    v-if="performerExpanded(result.id)"
                    :key="`${resultIndex}-expanded`"
                    class="expand-info"
                  >
                    <td class="thin-column" />
                    <td />
                    <td />
                    <td />
                    <td colspan="2" class="expand-info__meta">
                      <span class="expand-info__header">More meta data</span>
                      <div class="expand-info__meta__tags">
                        <div class="expand-info__meta__tags-title expand-info__meta__title">
                          Tags:
                        </div>
                        <div class="chips__wrapper">
                          <template v-if="result.tags && result.tags.length > 0">
                            <tag v-for="(tag, index) in result.tags" :key="index" :tag="tag" />
                          </template>
                          <div v-else class="none">None</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="searchContext.isExecuted && searchContext.result.totalHits === 0">
                  <tr>
                    <td colspan="1000" class="search-result__no-result">No items found.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="searchContext.isExecuted">
          <div class="col s12">
            <pagination
              v-show="numberOfPages > 0"
              topic="search"
              :number-of-pages="numberOfPages"
              :selected-page="pagination.page"
              :number-of-hits="searchContext.result.totalHits"
              :hits-per-page="pagination.hitsPerPage"
              @selectPage="selectPage"
              @updateHitsPerPage="updateHitsPerPage"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import ActionButtons from '../ui/button/action-buttons';
import Pagination from './../pagination';
import SearchHelper from './searchHelper';
import SelectCountry from '../ui/select/select-country';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import MandateList from '../ui/performer/mandate-list';
import SelectInput from '../ui/select/select-input';
import Spinner from '../spinner';
import Tag from '../tags/tag';
import SelectInstrument from '../ui/select/select-instrument';
import SelectDate from '../ui/select/select-date';
import ClearableInput from '../ui/input/clearable-input.vue';
import SearchQueryTooLong from './search-query-too-long';

export default {
  name: 'AdvancedSearchPerformers',
  components: {
    SelectInstrument,
    ActionButtons,
    Pagination,
    SelectCountry,
    Spinner,
    SearchableTagInput,
    Tag,
    MandateList,
    SelectDate,
    SelectInput,
    ClearableInput,
    SearchQueryTooLong,
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Name',
          sortName: 'first_name.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'Pseudo Names',
          sortName: 'pseudo_names.keyword',
          order: 'desc',
          mode: 'min',
          active: false,
        },
        {
          name: 'Date of Birth',
          sortName: 'date_of_birth',
          order: 'desc',
          active: false,
        },
        {
          name: 'Nationality',
          sortName: 'nationality',
          order: 'desc',
          active: false,
        },
        {
          name: 'Main Instrument',
          sortName: 'main_instrument',
          order: 'desc',
          active: false,
        },
        {
          name: 'Main Artists',
          active: false,
        },
        {
          name: 'Society',
          active: false,
        },
        {
          name: 'Ipn',
          active: false,
        },
        {
          name: 'Collective',
          active: false,
        },
      ],
      allTags: [],
      localTags: [],
      searchTerms: {
        query: '',
        residenceCountryCode: '',
        nationalityCountryCode: '',
        city: '',
        instrument: '',
        tags: [],
        dateOfBirth: '',
        society: '',
        collective: false,
      },
      sortTerms: {
        param: '',
        order: '',
      },
      defaultHitsPerPage: 25,
      searchContext: {
        query: '',
        isExecuted: false,
        isTooLong: false,
        searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
        result: {
          totalHits: 0,
          results: [],
        },
      },
      pagination: {
        hitsPerPage: this.defaultHitsPerPage,
        page: 1,
      },
      expandedPerformerIds: [],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.searchContext.result.totalHits / this.pagination.hitsPerPage);
    },
    previewTags() {
      return this.allTags
        .filter((tag) =>
          this.localTags.length > 0
            ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
            : true,
        )
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
    },
    firstHitIndexOnCurrentPage() {
      if (this.searchContext.result.totalHits === 0) {
        return 0;
      }
      return Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage + 1;
    },
    lastHitIndexOnCurrentPage() {
      const totalHits = this.searchContext.result.totalHits;
      const lastHitIndex =
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage +
        this.pagination.hitsPerPage;
      return lastHitIndex < totalHits ? lastHitIndex : totalHits;
    },
    localCountries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    $route() {
      this.updateRouterSearchParams();
      if (this.$router.currentRoute.query.i) {
        this.search();
      } else {
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        this.$emit('updateIsExecuted', false);
      }
    },
  },
  async created() {
    this.allTags = await SearchHelper.getAllTags();
    if (this.$router.currentRoute.query.i) {
      this.updateRouterSearchParams();
      this.search();
    }
  },
  methods: {
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.searchTerms.tags = event.data.map((tag) => tag.name);
      this.localTags = event.data;
    },
    removeTagAt(event) {
      this.searchTerms.tags.splice(event.idx, 1);
    },
    onlyCollective(checked) {
      this.searchTerms.collective = checked;
    },
    hasDetails(performer) {
      return performer.tags && performer.tags.length > 0;
    },
    toggleDetails(id, e) {
      const index = _.indexOf(this.expandedPerformerIds, id);
      const row = e.target.closest('tr');
      if (index === -1) {
        this.expandedPerformerIds.push(id);
        row.style.whiteSpace = 'normal';
      } else {
        this.expandedPerformerIds.splice(index, 1);
        row.style.whiteSpace = 'nowrap';
      }
    },
    performerExpanded(id) {
      return _.includes(this.expandedPerformerIds, id);
    },
    selectPage(selectedPage) {
      this.pagination.page = selectedPage;
      this.pushSearch();
    },
    updateHitsPerPage(value) {
      this.pagination.hitsPerPage = Number(value);
      this.resetPages();
      this.pushSearch();
    },
    updateRouterSearchParams() {
      this.searchTerms.query = this.$router.currentRoute.params.query
        ? this.$router.currentRoute.params.query
        : '';
      this.searchTerms.residenceCountryCode = this.$router.currentRoute.query.countryOfResidence
        ? this.$router.currentRoute.query.countryOfResidence
        : '';
      this.searchTerms.city = this.$router.currentRoute.query.city
        ? this.$router.currentRoute.query.city
        : '';
      this.searchTerms.nationalityCountryCode = this.$router.currentRoute.query.nationality
        ? this.$router.currentRoute.query.nationality
        : '';
      this.searchTerms.instrument = this.$router.currentRoute.query.instrument
        ? this.$router.currentRoute.query.instrument
        : '';
      this.searchTerms.dateOfBirth = this.$router.currentRoute.query.dateOfBirth
        ? this.$router.currentRoute.query.dateOfBirth
        : '';
      this.searchTerms.collective = [true, 'true'].includes(
        this.$router.currentRoute.query.collective,
      );
      this.searchTerms.tags = this.$router.currentRoute.query.tags
        ? this.$router.currentRoute.query.tags
        : [];
      this.searchTerms.society = this.$router.currentRoute.query.society
        ? this.$router.currentRoute.query.society
        : '';
      if (this.$router.currentRoute.query.tags) {
        if (_.isArray(this.$router.currentRoute.query.tags)) {
          this.localTags = this.$router.currentRoute.query.tags.map((tag) => ({
            name: tag,
            type: 'tag',
          }));
        } else {
          this.localTags = [{ name: this.$router.currentRoute.query.tags, type: 'tag' }];
        }
      } else {
        this.localTags = [];
      }
      this.sortTerms.param = this.$router.currentRoute.query.sortParam
        ? this.$router.currentRoute.query.sortParam
        : '';
      this.sortTerms.order = this.$router.currentRoute.query.sortOrder
        ? this.$router.currentRoute.query.sortOrder
        : '';
      this.pagination.page = this.$router.currentRoute.query.page
        ? Number(this.$router.currentRoute.query.page)
        : 1;
      this.pagination.hitsPerPage = this.$router.currentRoute.query.hits
        ? Number(this.$router.currentRoute.query.hits)
        : this.defaultHitsPerPage;
    },
    onSearchClick() {
      this.resetPages();
      this.pushSearch();
    },
    onClearClick() {
      this.$router.push({
        name: 'advancedSearchPerformersView',
        query: '',
      });
    },
    pushSearch() {
      const queryParams = {
        i: new Date().getTime(),
      };

      if (this.pagination.hitsPerPage) {
        queryParams.hits = this.pagination.hitsPerPage;
      }

      if (this.pagination.page) {
        queryParams.page = this.pagination.page;
      }
      if (this.searchTerms.residenceCountryCode) {
        queryParams.countryOfResidence = this.searchTerms.residenceCountryCode;
      }
      if (this.searchTerms.city) {
        queryParams.city = this.searchTerms.city;
      }
      if (this.searchTerms.nationalityCountryCode) {
        queryParams.nationality = this.searchTerms.nationalityCountryCode;
      }
      if (this.searchTerms.instrument) {
        queryParams.instrument = this.searchTerms.instrument.toLowerCase();
      }
      if (this.searchTerms.society) {
        queryParams.society = this.searchTerms.society;
      }
      if (this.searchTerms.tags) {
        queryParams.tags = this.searchTerms.tags;
      }
      if (this.searchTerms.collective) {
        queryParams.collective = this.searchTerms.collective;
      }
      if (this.searchTerms.dateOfBirth) {
        queryParams.dateOfBirth = this.searchTerms.dateOfBirth;
      }
      if (this.sortTerms.param) {
        queryParams.sortParam = this.sortTerms.param;
      }
      if (this.sortTerms.order) {
        queryParams.sortOrder = this.sortTerms.order;
      }
      if (this.searchTerms.query !== '') {
        this.$router.push({
          name: 'advancedSearchPerformersQueryView',
          params: { query: this.searchTerms.query },
          query: queryParams,
        });
      } else {
        this.$router.push({
          name: 'advancedSearchPerformersView',
          query: queryParams,
        });
      }
    },
    async search() {
      this.searchContext.query = this.searchTerms.query;
      if (SearchHelper.isQueryTooLong(this.searchTerms.query)) {
        this.searchContext.isTooLong = true;
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        return;
      }
      this.searchContext.isTooLong = false;
      this.loading = true;
      const result = await SearchHelper.advancedSearchPerformers(
        this.searchTerms,
        this.sortTerms.order,
        this.sortTerms.param,
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage,
        this.pagination.hitsPerPage,
      );
      this.searchContext.isExecuted = true;
      this.$emit('updateIsExecuted', true);
      this.searchContext.result = result;
      this.loading = false;
    },
    resetPages() {
      this.sortTerms = { param: '', order: '' };
      this.pagination.page = 1;
    },
    sort: function sort(column) {
      if (column.sortName !== '') {
        this.sortTerms.param = column.sortName;
        column.order = column.order === 'desc' ? 'asc' : 'desc';
        this.sortTerms.order = column.order;
        this.columns.forEach((c) => {
          c.active = false;
        });
        column.active = true;
        this.pushSearch();
        this.search();
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.cb-positioning {
  position: absolute;
  top: 22px;
}
</style>
