import traverseApply from './traverseApplicator';

/**
 * Bumps the version on the aggregate.
 * @param int the next version
 * @returns {Object} the aggregate
 */
export function bumpVersion(aggregate, i = 1) {
  aggregate.version += i;
  return aggregate;
}

/**
 * Adds command ids (stream_id) to the command from the aggregate.
 * @param command
 * @param aggregate
 * @param noVersion if version should be omitted from the command
 */
export function addCommandIds(command, aggregate, noVersion = false) {
  command.version = aggregate.version;
  command.stream_id = aggregate.id;
  command.process_id = aggregate.id;
  command.timestamp = new Date();
  if (noVersion) {
    delete command.version;
  }
}

export function flattenLocalCodes(payload) {
  return traverseApply(
    payload,
    (key) => ['local_codes'].includes(key),
    (key, fieldValue) =>
      fieldValue.reduce((acc, entry) => {
        if (!acc[entry.code]) {
          acc[entry.code] = [entry.value];
        } else {
          acc[entry.code].push(entry.value);
        }
        return acc;
      }, {}),
  );
}
