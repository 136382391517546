import { generateMutations, generateGetters } from '../../utils';
import * as domain from '../../../domain/recordingDomain';

// The root, initial state object
function createRootState() {
  return {
    id: null,
    version: null,
    stream_id: null,
    process_id: null,
    basic_info: domain.createBasicInfo(),
    distribution_state: domain.createDistributionState(),
    lineup: [],
    lineup_locked: false,
    albums: [],
    new_performers: [],
    new_albums: [],
    vrdb2_state: domain.createVrdb2State(),
  };
}

const rootState = createRootState();

const actions = {
  createAggregate(context) {
    context.commit('CREATE_AGGREGATE');
  },
  setRecordingAggregate(context, recordingAggregate) {
    context.commit('SET_RECORDING_AGGREGATE', recordingAggregate);
  },
};

const mutations = {
  ...generateMutations(rootState),
  CREATE_AGGREGATE(state) {
    state.id = null;
    state.version = null;
    state.stream_id = null;
    state.process_id = null;
    state.basic_info = domain.createBasicInfo();
    state.performers = [];
    state.albums = [];
    state.lineup = [];
    state.lineup_locked = false;
    state.new_performers = [];
    state.new_albums = [];
  },
  SET_RECORDING_AGGREGATE(state, recordingAggregate) {
    state.id = recordingAggregate.id;
    state.version = recordingAggregate.version;
    state.deleted = recordingAggregate.deleted;
    state.deletedBy = recordingAggregate.deletedBy;
    state.stream_id = recordingAggregate.stream_id;
    state.process_id = recordingAggregate.process_id;
    state.basic_info = recordingAggregate.basic_info;
    state.distribution_state = recordingAggregate.distribution_state;
    state.lineup = recordingAggregate.lineup;
    state.lineup_locked = recordingAggregate.lineup_locked;
    state.albums = recordingAggregate.albums;
    state.new_performers = recordingAggregate.new_performers;
    state.new_albums = recordingAggregate.new_albums;
    state.vrdb2_state = recordingAggregate.vrdb2_state;
  },
  SET_DELETE_AGGREGATE(state, deletedBy) {
    state.deleted = true;
    state.deletedBy = deletedBy;
    state.vrdb2_state = domain.createVrdb2State();
  },
};

const getters = {
  ...generateGetters(rootState),
  basic_info: (state) => state.basic_info,
  aggregate: (state) => state,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
