export default {
  props: {
    payload: {
      type: [Object, Array],
      required: true,
    },
    extras: {
      type: [Object, Array],
      default: undefined,
    },
    icon: {
      type: Object,
      default: undefined,
    },
  },
};
