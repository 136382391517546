import gql from 'graphql-tag';

const createPaymentWorklists = gql`
  mutation createPaymentWorklists($command: CreatePaymentWorklistsCommand!) {
    createPaymentWorklists(command: $command)
  }
`;

const createMatchWorklists = gql`
  mutation createMatchWorklists($command: CreateMatchWorklistsCommand!) {
    createMatchWorklists(command: $command)
  }
`;

const assignIssues = gql`
  mutation assignIssues($command: AssignIssuesCommand!) {
    assignIssues(command: $command)
  }
`;

const unassignIssues = gql`
  mutation unassignIssues($command: UnassignIssuesCommand!) {
    unassignIssues(command: $command)
  }
`;

const deleteWorklist = gql`
  mutation deleteWorklist($worklistId: String!) {
    deleteWorklist(worklist_id: $worklistId)
  }
`;

export default {
  createPaymentWorklists,
  createMatchWorklists,
  assignIssues,
  unassignIssues,
  deleteWorklist,
};
