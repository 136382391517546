<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Membership Agreements</h1>
      </div>
    </div>

    <div class="row wrapper">
      <div class="col s11">
        <h2>Membership Agreements to Send</h2>
      </div>
    </div>

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="row card">
        <refresh-button class="float-right" :is-loading="loading" @click="reloadBaseData" />
        <div class="search-result__result">
          <div class="row">
            <div class="col s12">
              <table>
                <thead>
                  <th class="thin-column compare__checkbox">
                    <input
                      id="markAll"
                      ref="mark"
                      name="mark"
                      type="checkbox"
                      class="filled-in"
                      :checked="allChecked"
                      :disabled="fileTypes.every((ft) => ft === 'zip')"
                      @change="markAll()"
                    />
                    <label for="markAll" />
                  </th>
                  <th
                    v-for="(column, index) in columns"
                    :key="`column-${index}`"
                    :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  >
                    <span>{{ column.name }}</span>
                  </th>
                </thead>
                <tbody>
                  <tr v-for="file in files" :key="file.id">
                    <td class="compare__checkbox">
                      <input
                        v-if="file.fileType !== 'zip'"
                        :id="file.id"
                        ref="mark"
                        v-model="file.checked"
                        class="filled-in"
                        type="checkbox"
                        name="mark"
                        @click.stop
                      />
                      <label :for="file.id" @click.stop />
                    </td>
                    <td>
                      <span>
                        {{ moment(file.lastModified).format('YYYY-MM-DD') }}
                      </span>
                    </td>
                    <td>
                      <span v-if="file.fileType === 'zip'">Performer Agreement Bundle</span>
                      <span v-else>
                        {{ file.filename.split('__')[1] }}
                      </span>
                    </td>
                    <td>
                      <span>
                        <a @click="onDownloadFile(file)">
                          <i class="fas fa-download" />
                          ({{ (file.size / 1000000).toFixed(2) }} MB)
                        </a>
                      </span>
                    </td>
                    <td class="w-50">
                      <div class="float-right">
                        <a @click="onDeleteFile(file)">
                          <i class="fas fa-trash" />
                          &nbsp; Delete
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <button
          class="btn secondary abs-top float-right"
          :disabled="downloading || fileTypes.every((ft) => ft === 'zip') || !anyChecked"
          type="submit"
          @click.prevent="onDownloadMany"
        >
          <i v-if="downloading" class="fas fa-sync-alt" :class="{ spin: downloading }" />
          Package and download selected
        </button>
      </div>
    </div>

    <upload-signed-agreements />
  </div>
</template>

<script>
import moment from 'moment';
import { AGGREGATE_TYPES, SUB_AGGREGATE_TYPES } from '../../domain/common';
import DownloadLink from '../ui/file/downloadLink';
import RefreshButton from '../ui/button/refresh-button';
import RemoteFileService from '../../services/remoteFileService';
import Spinner from '../spinner';
import UploadSignedAgreements from './upload-signed-agreements';
import ValidationPatterns from '../../common/validationPatterns';

export default {
  name: 'ViewSendMembershipAgreement',
  components: {
    Spinner,
    UploadSignedAgreements,
    RefreshButton,
  },
  data() {
    return {
      moment,
      error: false,
      loading: false,
      downloading: false,
      files: [],
      columns: [
        {
          name: 'Date Created',
        },
        {
          name: 'Name',
        },
        {
          name: 'Download (Size)',
        },
      ],
    };
  },
  computed: {
    fileEntityTypes() {
      return Array.from(new Set(this.files.map((f) => f.entityType)));
    },
    fileTypes() {
      return Array.from(new Set(this.files.map((f) => f.fileType)));
    },
    anyChecked() {
      return this.files.some((f) => f.checked);
    },
    allChecked() {
      return this.files.filter((f) => f.fileType === 'pdf').every((f) => f.checked);
    },
  },
  async created() {
    await this.reloadBaseData();
  },
  methods: {
    markAll() {
      this.files.forEach((f) => {
        f.checked = !f.checked;
      });
    },
    enhanceFiles(files) {
      let id = 0;
      return files
        .filter((file) => !ValidationPatterns.UUID.test(file.filename.split('__')[1]))
        .map((file) => ({ ...file, id: ++id, checked: false }))
        .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));
    },
    async onDownloadFile(file) {
      try {
        const fileData = await RemoteFileService.downloadFromDocumentRepository(
          `${file.path}/${file.filename}`,
        );
        DownloadLink.direct(fileData, file.filename);
      } catch (error) {
        this.$addStarError(error);
      }
    },
    async onDeleteFile(file) {
      try {
        await RemoteFileService.deleteFromDocumentRepository(`${file.path}/${file.filename}`);
      } catch (error) {
        this.$addStarError(error);
      } finally {
        await this.reloadBaseData();
      }
    },
    async onDownloadMany() {
      this.downloading = true;
      try {
        const path = `${this.files[0].path}`;
        const filenames = this.files.filter((f) => f.checked).map((f) => f.filename);
        const fileData = await RemoteFileService.downloadManyFromDocumentRepository({
          path,
          filenames,
          entityType: `${AGGREGATE_TYPES.PERFORMER}-${SUB_AGGREGATE_TYPES.AGREEMENT}`,
        });
        DownloadLink.direct(
          fileData,
          `membership-agreements-${moment().format('YYYY-MM-DD-HH-mm-ss')}.zip`,
        );
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.downloading = false;
        await this.reloadBaseData();
      }
    },
    async reloadBaseData() {
      try {
        this.loading = true;
        const files = await RemoteFileService.listDocumentRepository(
          'membership-agreement/created',
        );
        this.files = this.enhanceFiles(files);
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
