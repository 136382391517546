<template>
  <div class="card">
    <div class="row">
      <div class="col s2">
        <select-year
          name="year-filter"
          label="Year"
          :disabled="loading"
          :years="years"
          :empty-select-option="false"
          :value="selectedYear"
          @input="
            (value) => {
              selectedYear = value;
            }
          "
        />
      </div>
    </div>
    <div class="row">
      <div class="col s2">
        <button class="btn" :disabled="loading" type="submit" @click.prevent="calculate">
          Create
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SelectYear from './../../ui/select/select-year';

export default {
  name: 'CalculateSelector',
  components: {
    SelectYear,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedYear: undefined,
      years: [],
    };
  },

  created() {
    const currentYear = new Date().getFullYear();
    this.selectedYear = (currentYear - 10).toString();
    this.years = [currentYear - 10, currentYear - 11];
  },
  methods: {
    calculate() {
      this.$emit('calculate', this.selectedYear);
    },
  },
};
</script>

<style lang="scss" scoped></style>
