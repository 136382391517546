<template>
  <div class="lineup">
    <div class="row margin--bottom">
      <div class="col s6">
        <h2>Performers</h2>
      </div>
      <div v-show="selectedBulkAction !== 'edit'" class="float-right">
        <action-buttons
          :disable-submit="!lineupComplete"
          :show-abort="false"
          :submit-label="lineupLocked ? 'Unlock Lineup 🔓' : 'Lock Lineup 🔒'"
          @save="toggleLock"
        ></action-buttons>
      </div>
    </div>
    <div v-if="!disabled" class="row">
      <action-buttons
        v-if="selectedBulkAction === 'edit'"
        class="push--top float-right"
        @save="saveBulkEdit"
        @cancel="cancelBulk"
      />
      <div class="col s3">
        <span>Bulk</span>
        <select-input
          name="bulk"
          :value="selectedBulkAction"
          :items="bulkActions"
          :disabled="numberOfPerformersMarkedForBulk() === 0"
          item-key="key"
          item-value="value"
          @input="onBulkAction"
        />
      </div>
    </div>
    <div v-show="selectedBulkAction === 'edit'" class="row card">
      <div class="col s12">
        <div class="lineup__bulk">
          <div class="row">
            <div class="col s4">
              <ul class="lineup__bulk-performers">
                <li v-for="(performer, index) in flattenedLineup" :key="index">
                  <span v-if="performer.markedForBulk">
                    {{ performer.name }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="col s4">
              <select-instrument
                name="membership_info.secondary_instruments"
                :addable="true"
                :value="bulkEditInstruments"
                @input="bulkEditInstruments = $event"
              />
            </div>
            <div class="col s4">
              <select-role-code v-model="bulkEditRole" name="bulkEditRole" :show-label="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row card">
      <div class="col s12">
        <table class="lineup__performers">
          <sortable-head
            :bulk-enabled="!disabled"
            :columns="columns"
            :marked-for-bulk="allMarked"
            :default-sort-column-index="0"
            @markAll="onMarkAll"
            @sort="onSort"
          />
          <tbody>
            <template v-for="(flattenedPerformer, index) in flattenedLineup">
              <edit-lineup-row
                v-if="performerBeingEdited === flattenedPerformer.id"
                :key="flattenedPerformer.id"
                :performer="flattenedPerformer"
                :index="index"
                @save="updatePerformerRow($event, index)"
                @cancel="performerBeingEdited = null"
              />
              <lineup-row
                v-else
                :key="flattenedPerformer.id"
                :performer="flattenedPerformer"
                :index="index"
                :marked-for-bulk="flattenedPerformer.markedForBulk"
                :disabled="disabled"
                @editPerformer="editPerformer"
                @markForBulk="markForBulk"
                @deletePerformer="deletePerformer"
              />
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <add-performer-form v-if="!disabled" :required="true" @save="updateExistingPerformerLineup" />
    </div>
    <div class="row">
      <edit-add-new-performers
        v-if="!disabled"
        @save="updateNewPerformerLineup"
        @cancel="cancelAddNewPerformer"
      />
    </div>
    <div class="row">
      <edit-add-from-mainartist v-if="!disabled" @save="updateExistingPerformerLineup" />
    </div>
    <modal
      v-if="showRemoveModal"
      submit-label="Remove from recording"
      :disable-submit="!removeAllAllowed()"
      @save="bulkRemove"
      @close="showRemoveModal = false"
      @cancel="showRemoveModal = false"
    >
      <template v-if="removeAllAllowed()">
        <h1 slot="header">Remove from recording?</h1>
        <div slot="body">
          Are you sure you want to remove {{ numberOfPerformersMarkedForBulk() }} performer(s) from
          this recording?
        </div>
      </template>
      <template v-else>
        <h1 slot="header">Removal not allowed</h1>
        <div slot="body">
          Removing all performers from a lineup is not allowed when the recording distribution state
          is Green.
        </div>
      </template>
    </modal>

    <CopyLineupModal
      v-if="showCopyModal"
      :enable-search="true"
      :source="recording.basic_info"
      @cancel="showCopyModal = false"
      @copy="copyLineup"
    ></CopyLineupModal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { createPerformerAggregateCommand } from './../../../domain/performerDomain';
import { createPerformerReference } from './../../../domain/recordingDomain';
import { createNamedRelation } from './../../../domain/common';
import CommandService from '../../../services/commandService';
import ActionButtons from '../../ui/button/action-buttons';
import SelectInstrument from '../../ui/select/select-instrument';
import AddPerformerForm from './add-performer-form';
import EditAddNewPerformers from '../edit/edit-add-new-performers';
import EditHelper from './../edit/editHelper';
import EditLineupRow from './edit-lineup-row';
import LineupRow from './lineup-row';
import SelectRoleCode from '../../ui/select/select-role-code';
import SortableHead from '../../ui/table/sortable-head';
import EditAddFromMainartist from '../edit/edit-add-from-mainartist';
import Modal from '../../ui/dialog/modal';
import SelectInput from '../../ui/select/select-input';
import CopyLineupModal from '../../ui/recording/copy-lineup-modal';

export default {
  name: 'Lineup',
  components: {
    ActionButtons,
    SelectInstrument,
    AddPerformerForm,
    EditAddNewPerformers,
    EditLineupRow,
    LineupRow,
    SelectRoleCode,
    SortableHead,
    EditAddFromMainartist,
    Modal,
    SelectInput,
    CopyLineupModal,
  },
  props: {
    disabled: Boolean,
    lineupComplete: Boolean,
    lineupLocked: Boolean,
  },
  data() {
    return {
      flattenedLineup: [],
      optionsVisiblePerformerId: '',
      performerBeingEdited: null,
      recording: EditHelper.getCurrentRecording(),
      allMarked: false,
      selectedBulkAction: '',
      bulkEditInstruments: [],
      bulkEditRole: '',
      showAddNewPerformer: false,
      showAddExistingPerformer: false,
      showRemoveModal: false,
      showCopyModal: false,
      copyDestinations: [],
      bulkActions: [
        { key: 'edit', value: 'Edit' },
        { key: 'remove', value: 'Remove' },
        { key: 'copy', value: 'Copy' },
      ],
      columns: [
        {
          name: 'Name',
          ascending: false,
          sortParam: 'name',
          active: true,
        },
        {
          name: 'Pseudo',
          ascending: true,
          sortParam: 'pseudo',
          active: false,
        },
        {
          name: 'Instrument',
          ascending: true,
          sortParam: 'instrument',
          active: false,
        },
        {
          name: 'Role',
          ascending: true,
          sortParam: 'role',
          active: false,
        },
        {
          name: 'Nationality',
          ascending: true,
          sortParam: 'nationality',
          active: false,
        },
        {
          name: 'Society',
        },
        {
          name: 'IPN',
          ascending: true,
          sortParam: 'ipn',
          active: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('recording', ['lineup']),
    performerIds() {
      return this.flattenedLineup.map((performer) => performer.id);
    },
    roles() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
  },
  mounted() {
    this.$nextTick(() => this.flattenLineup());
    this.$nextTick(() => this.onSort(this.columns[0]));
  },
  methods: {
    toggleLock() {
      this.$emit('toggleLock');
    },
    removeAllAllowed() {
      return !this.allMarked || this.recording.distribution_state.status !== 'Green';
    },
    flattenLineup() {
      this.flattenedLineup = [];
      this.lineup.forEach((performer) => {
        const filteredSocieties = Array.from(
          new Set(
            performer.relation.societies.map((soc) => `${soc.society_code}:${soc.mandate_type}`),
          ),
        ).map((soc) => {
          const splittedId = soc.split(':');
          return {
            society_code: splittedId[0],
            mandate_type: splittedId[1],
          };
        });
        const flattenedPerformer = {
          id: performer.relation.id,
          name: performer.relation.name,
          protected_identity: performer.relation.protected_identity,
          pseudo: performer.pseudo_name,
          pseudoNames: performer.relation.pseudo_names,
          instruments: performer.instruments,
          role: performer.role,
          nationality: performer.relation.nationality,
          societies: filteredSocieties,
          ipn: performer.relation.ipn,
          markedForBulk: false,
          collective: performer.relation.collective,
        };
        this.flattenedLineup.push(flattenedPerformer);
      });
    },
    markForBulk(performer, markedForBulk) {
      if (markedForBulk) {
        if (!this.isIdMarkedForBulk(performer.id)) {
          this.flattenedLineup.forEach((flattenedPerformer) => {
            if (flattenedPerformer.id === performer.id) {
              flattenedPerformer.markedForBulk = true;
            }
          });
        }
        if (this.numberOfPerformersMarkedForBulk() === this.flattenedLineup.length) {
          this.allMarked = true;
        }
      } else {
        this.allMarked = false;
        this.flattenedLineup.forEach((flattenedPerformer) => {
          if (flattenedPerformer.id === performer.id) {
            flattenedPerformer.markedForBulk = false;
          }
        });
        if (this.numberOfPerformersMarkedForBulk() === 0) {
          this.selectedBulkAction = '';
        }
      }
    },
    onMarkAll(allMarked) {
      if (!allMarked) {
        this.selectedBulkAction = '';
      }
      this.flattenedLineup.forEach((performer) => {
        this.markForBulk(performer, allMarked);
      });
    },
    numberOfPerformersMarkedForBulk() {
      let amount = 0;
      this.flattenedLineup.forEach((performer) => {
        if (performer.markedForBulk) {
          amount += 1;
        }
      });
      return amount;
    },
    isIdMarkedForBulk(id) {
      let markedForBulk = false;
      this.flattenedLineup.forEach((performer) => {
        if (performer.markedForBulk && performer.id === id) {
          markedForBulk = true;
        }
      });
      return markedForBulk;
    },
    onBulkAction(value) {
      this.selectedBulkAction = value;

      switch (value) {
        case 'remove':
          this.showRemoveModal = true;
          break;
        case 'copy':
          this.showCopyModal = true;
          break;
        default:
          break;
      }
    },
    async bulkRemove() {
      this.showRemoveModal = false;
      for (let j = this.recording.lineup.length - 1; j >= 0; j -= 1) {
        if (this.isIdMarkedForBulk(this.recording.lineup[j].relation.id)) {
          this.recording.lineup.splice(j, 1);
        }
      }

      for (let k = this.flattenedLineup.length - 1; k >= 0; k -= 1) {
        if (this.isIdMarkedForBulk(this.flattenedLineup[k].id)) {
          this.flattenedLineup.splice(k, 1);
        }
      }
      try {
        this.$starContentLoading(true);
        const aggregate = await EditHelper.updateLineup(this.recording);
        this.recording = aggregate;
        this.cancelBulk();
        this.$emit('update');
      } catch (error) {
        this.$emit('error', error);
      }
      this.$starContentLoading(false);
    },
    async copyLineup(destinationIds) {
      this.showCopyModal = false;
      this.$starContentLoading(true);

      const filter = this.flattenedLineup
        .filter((line) => line.markedForBulk)
        .map((line) => line.id);

      const commands = destinationIds.map((destId) => ({
        type: 'recording:copyLineup',
        stream_id: destId,
        payload: {
          source_id: this.recording.id,
          filter,
        },
      }));

      try {
        await CommandService.handleCommands(commands);
      } finally {
        this.cancelBulk();
        this.$starContentLoading(false);
      }
    },
    async saveBulkEdit() {
      const instruments = this.bulkEditInstruments.filter((inst) => inst);
      this.recording.lineup.forEach((performer) => {
        if (this.isIdMarkedForBulk(performer.relation.id)) {
          if (instruments.length > 0) {
            performer.instruments = instruments;
          }
          if (this.bulkEditRole !== '') {
            performer.role = this.bulkEditRole;
          }
        }
      });

      this.flattenedLineup.forEach((performer) => {
        if (this.isIdMarkedForBulk(performer.id)) {
          if (instruments.length > 0) {
            performer.instruments = instruments;
          }
          if (this.bulkEditRole !== '') {
            performer.role = this.bulkEditRole;
          }
        }
      });
      try {
        this.$starContentLoading(true);
        const aggregate = await EditHelper.updateLineup(this.recording);
        this.recording = aggregate;
        this.cancelBulk();
        this.$emit('update');
      } catch (error) {
        this.$emit('error', error);
      }
      this.$starContentLoading(false);
    },
    cancelBulk() {
      this.allMarked = false;
      this.selectedBulkAction = '';
      this.bulkEditInstruments = [null];
      this.bulkEditRole = '';
      this.flattenedLineup.forEach((performer) => {
        performer.markedForBulk = false;
      });
    },
    cancelAddNewPerformer() {
      this.clearNewPerformers();
    },
    editPerformer(id) {
      this.performerBeingEdited = id;
    },
    async deletePerformer(replacer) {
      this.flattenedLineup.splice(replacer.index, 1);
      this.recording.lineup = this.recording.lineup.filter((p) => p.relation.id !== replacer.id);
      try {
        this.$starContentLoading(true);
        const aggregate = await EditHelper.updateLineup(this.recording);
        this.recording = aggregate;
        this.$emit('update');
      } catch (error) {
        this.$emit('error', error);
      }
      this.$starContentLoading(false);
    },
    async updatePerformerRow(updatedPerformer, index) {
      this.flattenedLineup[index] = updatedPerformer;
      const performerRelation = createNamedRelation(
        updatedPerformer.id,
        updatedPerformer.name,
        'performer',
      );
      const updatedPerformerReference = createPerformerReference(
        performerRelation,
        updatedPerformer.instruments,
        updatedPerformer.role,
        updatedPerformer.pseudo,
      );
      const lineupIndex = this.lineup.findIndex(
        (performer) => performer.relation.id === updatedPerformer.id,
      );
      this.recording.lineup[lineupIndex] = updatedPerformerReference;
      try {
        this.$starContentLoading(true);
        const aggregate = await EditHelper.updateLineup(this.recording);
        this.recording = aggregate;
        this.performerBeingEdited = null;
        this.$emit('update');
      } catch (error) {
        this.$emit('error', error);
      }
      this.$starContentLoading(false);
    },
    clearNewPerformers() {
      const existingNewPerformerIds = this.recording.new_performers.map(
        (newPerformer) => newPerformer.stream_id,
      );
      this.recording.lineup = this.recording.lineup.filter(
        (performer) => existingNewPerformerIds.indexOf(performer.relation.id) === -1,
      );
      this.recording.new_performers = [];
    },
    async updateNewPerformerLineup(newPerformers) {
      this.recording = this.$store.state.recording; // ugly fix for: sc-11637
      // Start by resetting the existing new performers list to get a clean state.
      this.clearNewPerformers();
      newPerformers.forEach((newPerformer) => {
        // Only add if valid (should be handled by component validation instead)
        if (
          (newPerformer.first_name && newPerformer.last_name) ||
          (newPerformer.pseudo_names && newPerformer.pseudo_names.length > 0)
        ) {
          const newPerformerCommand = createPerformerAggregateCommand();
          newPerformerCommand.general_info = {
            first_name: newPerformer.first_name,
            last_name: newPerformer.last_name,
            pseudo_names: newPerformer.pseudo_names,
            country_of_residence: newPerformer.country_of_residence,
          };
          this.recording.new_performers.push(newPerformerCommand);
          let name = newPerformer.first_name ? `${newPerformer.first_name}` : '';
          name += newPerformer.last_name ? ` ${newPerformer.last_name}` : '';
          name = name || newPerformer.pseudo_names[0];
          const pseudo = newPerformer.pseudo_names && newPerformer.pseudo_names[0];
          const performerRelation = createNamedRelation(
            newPerformerCommand.stream_id,
            name,
            'performer',
          );
          this.recording.lineup.push(
            createPerformerReference(
              performerRelation,
              newPerformer.instruments,
              newPerformer.role,
              pseudo,
            ),
          );
        }
      });
      try {
        this.$starContentLoading(true);
        const aggregate = await EditHelper.updateLineup(this.recording);
        this.recording = aggregate;
        this.flattenLineup();
        this.cancelAddNewPerformer();
        this.$emit('update');
      } catch (error) {
        this.$emit('error', error);
      }
      this.$starContentLoading(false);
    },
    async updateExistingPerformerLineup(newPerformers) {
      this.recording = this.$store.state.recording; // ugly fix for: sc-11637
      const savedLineup = _.cloneDeep(this.recording.lineup);
      newPerformers.forEach((newPerformer) => {
        this.recording.lineup.push(newPerformer);
      });
      try {
        this.$starContentLoading(true);
        const aggregate = await EditHelper.updateLineup(this.recording);
        this.recording = aggregate;
        this.flattenLineup();
        this.$emit('update');
      } catch (error) {
        this.recording.lineup = savedLineup;
        this.$emit('error', error);
      }
      this.$starContentLoading(false);
    },
    onSort(column) {
      const sortOrder = column.ascending ? 'asc' : 'desc';
      const sortParam = column.sortParam;
      const sorted = _.orderBy(
        this.flattenedLineup,
        [
          function sortValue(recording) {
            return recording[sortParam];
          },
        ],
        [sortOrder],
      );
      this.flattenedLineup = sorted;
    },
  },
};
</script>
