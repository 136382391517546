<template>
  <div>
    <div class="title">SDEG Claiming Deadlines</div>
    <section class="widget-table">
      <header>
        <div class="widget-table--col">Report</div>
        <div class="widget-table--col">Deadline</div>
      </header>
      <div v-for="report in payload.items" :key="report.report_id" class="widget-table--row">
        <div class="widget-table--col">
          <router-link
            :to="`/societies/${getSocietyId(report.society_code)}/sdeg/played-recordings/${
              report.report_id
            }`"
          >
            {{ report.report_name }}
          </router-link>
        </div>
        <div class="widget-table--col">
          {{ report.deadline }}
          <i v-if="deadlinePassed(report.deadline)" class="fas fa-exclamation-triangle"></i>
        </div>
      </div>
    </section>

    <section class="widget-link">
      <router-link to="/integrations/sdeg/played-recordings?sortColumn=deadline&sortOrder=desc">
        Show SDEG Played recordings
        {{ payload.total_count > 5 ? '(' + (payload.total_count - 5) + ' more)' : '' }}
      </router-link>
    </section>
  </div>
</template>

<script>
import * as moment from 'moment';
import WidgetMixin from './widget-mixin';

export default {
  name: 'SdegClaimingDeadlinesWidget',
  mixins: [WidgetMixin],
  computed: {
    societies() {
      return this.$store.state.appdata.societies;
    },
  },
  methods: {
    deadlinePassed(date) {
      return moment(date).isBefore(moment());
    },
    getSocietyId(code) {
      return this.societies.find((s) => s.code === code)?.id;
    },
  },
};
</script>
