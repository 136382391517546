<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Extra Information and Meta Data</h2>
      </div>
      <template v-if="editMode">
        <div class="col s6 text--right">
          <action-buttons @save="update" @cancel="cancel" />
        </div>
      </template>
    </div>
    <form>
      <div class="card">
        <div class="row">
          <template v-if="editMode">
            <div class="row">
              <div class="col s2">Star code</div>
              <div class="col s7">
                <validated-text-input
                  placeholder="E.g. RPM"
                  name="meta_info.code"
                  :value="metaInfoCode"
                  :disabled="!isDeveloper"
                  @input="updateMetaInfoCode"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s2">IPD Society code</div>
              <div class="col s7">
                <validated-text-input
                  placeholder="E.g. 01"
                  name="meta_info.ipd_society_code"
                  :rule="{ required: true }"
                  :value="metaInfoIpdSocietyCode"
                  :disabled="!isDeveloper"
                  @input="updateMetaInfoIpdSocietyCode"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s2">IPD Society name</div>
              <div class="col s7">
                <validated-text-input
                  placeholder="E.g. 01"
                  name="meta_info.ipd_society_name"
                  :value="metaInfoIpdSocietyName"
                  :disabled="!isDeveloper"
                  @input="updateMetaInfoIpdSocietyName"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s2">VRDB code</div>
              <div class="col s7">
                <validated-text-input
                  placeholder="E.g. 01"
                  name="meta_info.vrdb_code"
                  :value="metaInfoVrdbCode"
                  :disabled="!isDeveloper"
                  @input="updateMetaInfoVrdbCode"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s2">Country</div>
              <div class="col s7">
                <validated-text-input
                  placeholder="E.g. SE"
                  name="meta_info.country"
                  :value="metaInfoCountry"
                  :disabled="!isDeveloper"
                  @input="updateMetaInfoCountry"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s2">Create mandate</div>
              <div class="col s7">
                <input
                  id="meta_info.create_mandate"
                  type="checkbox"
                  name="meta_info.create_mandate"
                  class="filled-in"
                  :value="true"
                  :checked="!!metaInfoCreateMandate"
                  :disabled="!isDeveloper"
                  @change="onUpdateMetaInfoCreateMandate($event.target.checked)"
                />
                <label for="meta_info.create_mandate">Yes</label>
              </div>
            </div>
            <div class="row">
              <div class="col s2">Order</div>
              <div class="col s7">
                <validated-text-input
                  placeholder="E.g. true/false"
                  name="meta_info.order"
                  :value="metaInfoOrder"
                  :disabled="!isDeveloper"
                  @input="updateMetaInfoOrder"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s2">DDEX Party ID</div>
              <div class="col s7">
                <validated-text-input
                  placeholder="E.g. PADPIDA1234567890Z"
                  name="meta_info.ddex_party_id"
                  :value="metaInfoDdexPartyId"
                  :rule="{ ddex_party_id: true }"
                  :disabled="!isDeveloper"
                  @input="updateMetaInfoDdexPartyId"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s2">Statement file type</div>
              <div class="col s7">
                <select-statement-file-type
                  name="meta_info.statement_file_type"
                  :value="metaInfoStatementFileType"
                  @input="updateMetaInfoStatementFileType"
                />
              </div>
            </div>
          </template>

          <template v-else>
            <div class="row">
              <div class="col s8">
                <validated-text-input
                  placeholder="E.g. 01"
                  name="meta_info.ipd_society_code"
                  label="IPD Society code"
                  :rule="{ required: true, unique_ipd_code: societies }"
                  :mandatory="true"
                  :value="metaInfoIpdSocietyCode"
                  @input="updateMetaInfoIpdSocietyCode"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s8">
                <validated-text-input
                  placeholder="E.g. PADPIDA1234567890Z"
                  name="meta_info.ddex_party_id"
                  label="DDEX Party ID"
                  :rule="{ ddex_party_id: true }"
                  :value="metaInfoDdexPartyId"
                  @input="updateMetaInfoDdexPartyId"
                />
              </div>
            </div>
            <div class="row">
              <div class="col s8">
                <select-statement-file-type
                  name="meta_info.statement_file_type"
                  :show-label="true"
                  :value="metaInfoStatementFileType"
                  @input="updateMetaInfoStatementFileType"
                />
              </div>
            </div>
          </template>
        </div>
      </div>

      <div>
        <create-tags
          v-if="!editMode"
          scope="tags-society"
          entity-type="society"
          path="meta_info"
          add-method="addToMetaInfoTags"
          remove-method="removeFromMetaInfoTags"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import CreateTags from '../../tags/create-tags';
import SelectStatementFileType from '../../ui/select/select-statement-file-type.vue';

export default {
  name: 'CreateMetaInfo',
  components: {
    ValidatedTextInput,
    ActionButtons,
    CreateTags,
    SelectStatementFileType,
  },
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('society', [
      'code',
      'metaInfoIpdSocietyCode',
      'metaInfoIpdSocietyName',
      'metaInfoVrdbCode',
      'metaInfoCreateMandate',
      'metaInfoOrder',
      'metaInfoCode',
      'metaInfoCountry',
      'metaInfoStatementFileType',
      'metaInfoDdexPartyId',
    ]),
    ...mapGetters('user', ['isDeveloper']),
    societies() {
      return this.$store.state.appdata.societies;
    },
  },
  methods: {
    ...mapMutations('society', [
      'updateCode',
      'updateMetaInfoIpdSocietyCode',
      'updateMetaInfoIpdSocietyName',
      'updateMetaInfoVrdbCode',
      'updateMetaInfoCreateMandate',
      'updateMetaInfoOrder',
      'updateMetaInfoCode',
      'updateMetaInfoCountry',
      'updateMetaInfoStatementFileType',
      'updateMetaInfoDdexPartyId',
    ]),
    onUpdateMetaInfoCreateMandate(x) {
      this.updateMetaInfoCreateMandate(x);
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    update() {
      this.$emit('update', this.$options.name);
    },
  },
};
</script>
