<template>
  <div>
    <div style="display: inline" class="col s3">
      <span>Bulk</span>
      <select
        v-model="bulkAction"
        name="bulkAction"
        :disabled="!enabled"
        @change="$emit('input', toActionFriendlyName(bulkAction))"
      >
        <option value="" selected>Choose one...</option>
        <option v-for="(option, index) in options" :key="index" :value="option">
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'BulkAction',
  props: {
    enabled: { type: Boolean },
    options: { type: Array },
    bulkOptionSelected: { type: String, default: '' },
  },
  data() {
    return {
      bulkAction: this.bulkOptionSelected,
    };
  },
  watch: {
    bulkOptionSelected() {
      this.bulkAction = this.bulkOptionSelected;
    },
  },
  methods: {
    toActionFriendlyName(bulkAction) {
      return _.camelCase(bulkAction);
    },
  },
};
</script>
