<template>
  <div class="table-items">
    <div class="row">
      <div class="col s12">
        <h2>{{ name }}</h2>
      </div>
    </div>
    <div v-if="isBulkEnabled || refreshDataEnabled" class="row bulk-action">
      <bulk-action
        v-if="isBulkEnabled"
        :enabled="anyItemSelected"
        :options="bulkOptions"
        :bulk-option-selected="selectedBulkAction"
        @input="onBulkAction"
      />

      <div v-if="refreshDataEnabled" style="display: inline" class="col s9 float-right">
        <br />
        <refresh-button class="float-right" :is-loading="isLoadingData" @click="onRefreshData" />
      </div>
    </div>
    <!-- Insertion point for bulk option templates -->

    <template v-for="option in bulkOptions">
      <slot v-if="selectedBulkAction === option" :name="option" />
    </template>

    <div v-if="inProgress" class="row">
      <div class="col s12">In progress...</div>
    </div>

    <div class="row card">
      <div class="col s12">
        <table class="lineup__items">
          <sortable-head
            :columns="columns"
            :bulk-enabled="isBulkEnabled"
            :bulk-suspend="selectedItemId != null"
            :marked-for-bulk="allItemsMarked"
            :default-sort-column-index="defaultSortColumnIndex"
            :more-options="enableRowOptions"
            @markAll="onMarkAll"
            @sort="$emit('sort', $event)"
          />
          <tbody>
            <template v-for="id in itemIds">
              <row
                :key="`${id}-row`"
                :item-id="id"
                :bulk-enabled="isBulkEnabled"
                :bulk-suspend="selectedItemId != null"
                :checked="isItemChecked(id)"
                :options-menu-enabled="enableRowOptions && !editRow"
                @edit="onEditRow"
                @editSave="onEditRowSave"
                @change="onCheckedChanged"
                @delete="onDeleteRow"
              >
                <slot :name="id" />
              </row>
              <tr v-if="editButtonsEnabled && editRow && selectedItemId === id" :key="`${id}-tr`">
                <td :colspan="columns.length + 2" class="text--right">
                  <action-buttons @save="onEditRowSave" @cancel="onEditRowCancel" />
                  <br />
                </td>
              </tr>

              <slot :name="`expand_${id}`" />
            </template>
          </tbody>
          <tfoot>
            <slot name="tfoot" />
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButtons from '../button/action-buttons';
import RefreshButton from '../button/refresh-button';
import BulkAction from './bulk-action';
import Row from './row';
import SortableHead from './sortable-head';

export default {
  name: 'BulkTable',
  components: {
    ActionButtons,
    BulkAction,
    RefreshButton,
    Row,
    SortableHead,
  },
  props: {
    name: { type: String },
    columns: { type: Array },
    itemIds: { type: Array, default: () => [] },
    bulkOptions: { type: Array, default: () => [] },
    editRowEnabled: { type: Boolean, default: true },
    editButtonsEnabled: { type: Boolean, default: true },
    editMode: { type: Boolean, default: false },
    enableRowOptions: { type: Boolean, default: true },
    defaultSortColumnIndex: { type: Number, default: 1 },
    displayedBulkAction: { type: String, default: '' },
    inProgress: { type: Boolean, default: false },
    isLoadingData: { type: Boolean, default: false },
    refreshDataEnabled: { type: Boolean, default: false },
  },
  data() {
    return {
      optionsVisiblePerformerId: '',
      selectedBulkAction: '',
      selectedItemIds: [],
      selectedItemId: null,
      editRow: false,
    };
  },
  computed: {
    allItemsMarked() {
      return this.selectedItemIds.length === this.itemIds.length && this.itemIds.length > 0;
    },
    anyItemSelected() {
      return this.selectedItemIds.length > 0;
    },
    isBulkEnabled() {
      return this.bulkOptions && this.bulkOptions.length > 0;
    },
  },
  watch: {
    displayedBulkAction(action = '') {
      this.selectedBulkAction = action;
    },
  },
  methods: {
    onBulkAction(type) {
      if (type !== '') {
        this.selectedBulkAction = type;
        this.$emit('bulkAction', type);
      }
    },
    onCheckedChanged(event) {
      if (event.checked) {
        this.selectedItemIds.push(event.id);
      } else {
        this.selectedItemIds = this.selectedItemIds.filter((id) => id !== event.id);
      }
      this.emitSelectedItems();
    },
    onMarkAll(allMarked) {
      if (!allMarked) {
        this.selectedBulkAction = '';
        this.selectedItemIds = [];
      } else {
        this.selectedItemIds = this.itemIds;
      }
      this.emitSelectedItems();
    },
    onEditRow(id) {
      this.editRow = true;
      this.selectedItemId = id;
      this.$emit('editItem', this.selectedItemId);
    },
    onEditRowCancel() {
      this.editRow = false;
      this.selectedItemId = null;
      this.$emit('editItemCancel', this.selectedItemId);
    },
    onEditRowSave() {
      this.$emit('editItemSave', this.selectedItemId);
    },
    onDeleteRow(id) {
      this.selectedItemId = id;
      this.$emit('deleteItem', this.selectedItemId);
    },
    onRefreshData() {
      this.$emit('refreshData');
    },
    isItemChecked(id) {
      return this.selectedItemIds.find((selectedId) => selectedId === id) !== undefined;
    },
    cancelBulk() {
      this.allMarked = false;
      this.selectedBulkAction = '';
    },
    cancelAddNewPerformer() {
      this.showAddNewPerformer = false;
      this.clearNewPerformers();
    },
    cancelAddExistingPerformer() {
      this.showAddExistingPerformer = false;
    },
    emitSelectedItems() {
      this.$emit('selectedItems', this.selectedItemIds);
    },
    clearSelected() {
      this.selectedItemIds = [];
    },
    clearBulkAction() {
      this.selectedBulkAction = '';
    },
    cancelEdit() {
      this.onEditRowCancel();
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-action {
  margin-top: 16px;
}
</style>
