<template>
  <div>
    <select-input
      label="Type"
      item-key="key"
      item-value="value"
      :name="name"
      :value="value"
      :items="types"
      :rule="rule"
      :scope="scope"
      :disabled="disabled"
      @input="onInput"
    />
  </div>
</template>

<script>
import { AgreementType } from '../../../domain/societyDomain';
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectAgreementType',
  components: { SelectInput },
  props: {
    value: String,
    name: String,
    disabled: { type: Boolean, default: false },
    scope: {
      type: String,
      default: undefined,
    },
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      types: Object.keys(AgreementType).map((oKey) => ({ key: oKey, value: AgreementType[oKey] })),
    };
  },
  methods: {
    onInput(key) {
      this.$emit('input', AgreementType[key]);
    },
  },
};
</script>
