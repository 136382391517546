import { generateMutations, generateGetters } from '../../utils';
import * as domain from '../../../domain/albumDomain';

// The root, initial state object
const rootState = domain.createAlbumAggregate();

const actions = {
  createAggregate(context) {
    context.commit('CREATE_AGGREGATE');
  },
  setAlbumAggregate(context, albumAggregate) {
    context.commit('SET_ALBUM_AGGREGATE', albumAggregate);
  },
};

const mutations = {
  ...generateMutations(rootState),
  CREATE_AGGREGATE(state) {
    state.id = null;
    state.version = null;
    state.stream_id = null;
    state.process_id = null;
    state.basic_info = domain.createBasicInfo();
    state.tracks = [];
    state.readonly = false;
    state.merged_with = [];
  },
  SET_ALBUM_AGGREGATE(state, albumAggregate) {
    state.id = albumAggregate.id;
    state.version = albumAggregate.version;
    state.stream_id = albumAggregate.stream_id;
    state.process_id = albumAggregate.process_id;
    state.basic_info = albumAggregate.basic_info;
    state.tracks = albumAggregate.tracks;
    state.readonly = albumAggregate.readonly;
    state.merged_with = albumAggregate.merged_with;
  },
};

const getters = {
  ...generateGetters(rootState),
  basic_info: (state) => state.basic_info,
  tracks: (state) => state.tracks,
  aggregate: (state) => state,
  readonly: (state) => state.readonly,
  merged_with: (state) => state.merged_with,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
