<template>
  <transition name="modal">
    <div :class="{ overflow: hideOverflow }" class="modal-mask">
      <div class="modal-wrapper">
        <div :class="{ 'modal-container': true, 'modal-container-small': small, wide: wide }">
          <div class="modal-header">
            <div class="float-right">
              <i class="fas fa-times cursor-pointer" @click="onClose()"></i>
            </div>
            <slot name="header"></slot>
          </div>

          <div :class="{ 'modal-body': true, 'modal-body-small': small, overflow: hideOverflow }">
            <slot name="body"></slot>
          </div>

          <div v-if="showConfirmCheckBox && confirmMessage" class="modal-body">
            <input id="modal-confirm" v-model="confirmed" type="checkbox" name="Confirm" />
            <label for="modal-confirm">{{ confirmMessage }}</label>
          </div>

          <div v-if="!disableFooter" class="modal-footer">
            <br />
            <slot name="footer" />
            <div class="row">
              <router-link
                v-for="link in links"
                :key="link.name"
                :to="{ name: link.view, params: link.params }"
              >
                {{ link.name }}
              </router-link>
              <div class="float-right">
                <action-buttons
                  :disable-submit="disableSubmit || (showConfirmCheckBox && !confirmed)"
                  :submit-label="submitLabel"
                  :abort-label="abortLabel"
                  :show-abort="showAbort"
                  :button-class="actionButtonClass"
                  @save="onSave"
                  @cancel="onCancel"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ActionButtons from '../button/action-buttons';

export default {
  name: 'Modal',
  components: {
    ActionButtons,
  },
  props: {
    confirmMessage: {
      type: String,
      default: undefined,
    },
    showConfirmCheckBox: {
      type: Boolean,
      default: false,
    },
    actionButtonClass: {
      type: String,
    },
    name: {
      type: String,
    },
    submitLabel: {
      type: String,
    },
    abortLabel: {
      type: String,
      default: 'Cancel',
    },
    showAbort: {
      type: Boolean,
      default: true,
    },
    links: {
      type: Array,
      default: () => [],
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    hideOverflow: {
      type: Boolean,
      default: true,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmed: false,
    };
  },
  created() {
    document.body.classList.add('overlay-active');
  },
  destroyed() {
    document.body.classList.remove('overlay-active');
  },
  methods: {
    disableSubmitButton() {
      if (this.showConfirmCheckBox && !this.confirmed) {
        this.disableSubmit = true;
      }
    },
    onClose() {
      this.$emit('close');
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSave() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
/**
  * Modal definition, not derived from MaterializeCss.
  */

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  &.overflow {
    overflow-y: auto;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 40%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

  &.wide {
    width: 80%;
  }
}

.modal-body {
  margin: 20px 0;

  &.overflow {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.modal-default-button {
  float: right;
}

.modal-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
  height: 56px;
  width: 100%;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

.modal-container-small {
  width: 20% !important;
}

.modal-body-small {
  min-height: 100px !important;
  max-height: 100px !important;
}
</style>
