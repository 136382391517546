<template>
  <div class="edit-link">
    <div v-if="isOnManualPayroll">
      Next payment will be paid manually
      <a :class="{ disabled: routeVersion() !== null }" @click="unmanual">
        <i class="fas fa-times" />
        Remove
      </a>
    </div>
    <a v-else :class="{ disabled: routeVersion() !== null }" @click="manual">
      <i class="far fa-plus-square" />
      Add to manual payments
    </a>
  </div>
</template>

<script>
import AggregateMixin from '../../../common/aggregateMixin';

export default {
  name: 'ManualPaymentAddBtn',
  mixins: [AggregateMixin],
  props: {
    entityId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isOnManualPayroll() {
      return !!this.$store.state.payment.manualPayments.find((p) => p.nodeId === this.entityId);
    },
  },
  methods: {
    async manual() {
      this.$emit('add', this.entityId);
    },
    async unmanual() {
      this.$emit('remove', this.entityId);
    },
  },
};
</script>
