/**
 * The Performer Service contains performer related methods, such as CRUD.
 */
import moment from 'moment';
import { addCommandIds, flattenLocalCodes } from '../common/aggregateUtils';
import { mutate, query } from './apolloRequest';
import {
  AGGREGATE_TYPES,
  createDocumentsCommand,
  createUpdateTaxInfoCommand,
  createUpdateAssociatesCommand,
  createRemoveAssociatesCommand,
  createAssociation,
} from '../domain/common';
import clone from '../common/clone';
import { getConfigValue, PROPS } from './configService';
import { createUpdatePaymentInfoCommand } from '../domain/commonPayment';
import merge from '../common/objectMerger';
import post from './postRequest';
import {
  createPerformerAggregate,
  createPerformerAggregateCommand,
  createUpdateContactInfoCommand,
  createMetadataInfoCommand,
  createUpdateMandateInfoCommand,
  createUpdatePaymentsBlockedCommand,
  createInitiateMembershipCommand,
  createConfirmMembershipCommand,
  createApproveMembershipApplicationCommand,
  createPatchIpnCommand,
  createRegionalMembershipAgreementCommand,
} from '../domain/performerDomain';
import gql from '../domain/performerGql';
import traverseApply from '../common/traverseApplicator';
import prepareForWire from './wirePrepper';
import { preparePhoneNumbers } from './wirePrepperUtils';
import AuthenticationService from '@/services/authenticationService';

const PERFORMERS_URL = `${getConfigValue(PROPS.PNR_URL)}/performers`;

function preparePerformerDataForWire(performerData) {
  // Remove derived gql field not allowed when creating
  if (performerData.membership_info && performerData.membership_info.main_artists) {
    delete performerData.membership_info.main_artists;
  }
  traverseApply(
    performerData,
    (key) => ['member_from', 'date_of_birth', 'date_of_death'].includes(key),
    (key, fieldValue) => (fieldValue ? moment.utc(fieldValue).toISOString() : fieldValue),
  );
  if (performerData.mandates) {
    performerData.mandates.forEach((mandate) => {
      mandate.start_date = moment.utc(mandate.start_date).format('YYYY-MM-DD');
      if (mandate.end_date) {
        mandate.end_date = moment.utc(mandate.end_date).format('YYYY-MM-DD');
      }
    });
  }
  preparePhoneNumbers(performerData);
  return prepareForWire(performerData);
}

function processPerformerAggregate(performerAggregate) {
  // Merge fetched performer with the known domain model, retain known domain model fields and omit unknown on fetched performer.
  const mergedPerformerAggregate = merge(createPerformerAggregate(), performerAggregate);
  // Convert zulu utc to readable
  return traverseApply(
    mergedPerformerAggregate,
    (key) => ['member_from', 'date_of_birth', 'date_of_death', 'start_date'].includes(key),
    (key, fieldValue) =>
      fieldValue ? moment.utc(fieldValue).local().format('YYYY-MM-DD') : fieldValue,
  );
}

function processSimplePerformerAggregate(performerAggregate) {
  const mergedPerformerAggregate = {};
  mergedPerformerAggregate.id = performerAggregate.id;
  mergedPerformerAggregate.name = `${performerAggregate.general_info.first_name} ${performerAggregate.general_info.last_name}`;
  return mergedPerformerAggregate;
}

function prepareAssociates(_associates, getByID = false) {
  if (_associates.filter((a) => a._new).length > 1) {
    // even though the backend can handle it...
    throw new Error('There should only be one new associate in aggregate.');
  }
  const associates = [];
  _associates.forEach((a) => {
    if (!a.id) {
      return;
    }
    const obj = {};

    obj.associate = createAssociation(
      a.id,
      a.row_id,
      a.type,
      a.access_policy,
      a.start_date,
      a.end_date,
    );

    if (getByID && a.id !== getByID) {
      return;
    }

    if (a._new ? a.is_recipient_of_money : a.share) {
      obj.payment_receiver = {
        share: a.share,
      };
    }

    associates.push(obj);
  });

  return associates;
}

function createPerformerCommand(performer) {
  const createCommand = createPerformerAggregateCommand();
  createCommand.general_info = performer.general_info;
  createCommand.contact_info = performer.contact_info;
  createCommand.tax_info = performer.tax_info;
  createCommand.metadata_info = performer.metadata_info;
  createCommand.membership_info = performer.membership_info;
  createCommand.mandates = performer.mandates;
  createCommand.payment_info = performer.payment_info;
  createCommand.documents = performer.documents;

  createCommand.associates = prepareAssociates(performer.associates);
  delete createCommand.mandate_groups;

  preparePerformerDataForWire(createCommand);
  flattenLocalCodes(createCommand.general_info);
  return createCommand;
}

function prepareLegacyPayload(aggregate, payloadPropertyName, commandTemplate) {
  addCommandIds(commandTemplate, aggregate);
  commandTemplate[payloadPropertyName] = merge(
    commandTemplate[payloadPropertyName],
    aggregate[payloadPropertyName],
  );
  preparePerformerDataForWire(commandTemplate[payloadPropertyName]);
  flattenLocalCodes(commandTemplate[payloadPropertyName]);
  return commandTemplate;
}

function preparePayload(aggregate, payloadPropertyName, commandTemplate) {
  addCommandIds(commandTemplate, aggregate);
  commandTemplate.payload = merge(commandTemplate.payload, aggregate[payloadPropertyName]);
  preparePerformerDataForWire(commandTemplate.payload);
  return commandTemplate;
}

function prepareUpdatePayload(payload, aggregateId, commandTemplate, aggregate) {
  commandTemplate.stream_id = aggregateId;
  commandTemplate.process_id = aggregateId;
  commandTemplate.timestamp = new Date();
  commandTemplate.payload = payload;
  if (aggregate == null) {
    delete commandTemplate.version;
  } else {
    commandTemplate.version = aggregate.version;
  }
  return commandTemplate;
}

export default {
  preparePayload,
  preparePerformerDataForWire,

  createPerformer: (performer) =>
    post(`${PERFORMERS_URL}/creation`, createPerformerCommand(performer), { pickProp: 'id' }),
  canDeletePerformer: (idTerm) =>
    query(
      {
        query: gql.canDeletePerformer,
        variables: { idTerm },
      },
      { pickProp: 'canDeletePerformer' },
    ),
  deletePerformer: (idTerm) =>
    mutate(
      {
        mutation: gql.deletePerformer,
        variables: { idTerm, user: AuthenticationService.getUserName() },
      },
      { pickProp: 'handleCommands' },
    ),
  getPerformer: async (idTerm, allMandates = false, versionTerm = null) => {
    const aggregate = await query(
      { query: gql.getFullPerformer, variables: { idTerm, allMandates, versionTerm } },
      { pickProp: 'performerAggregate' },
    );
    return processPerformerAggregate(clone(aggregate));
  },
  getIpdRightHolder: async (ipn) =>
    query({ query: gql.ipdRightHolder, variables: { ipn } }, { pickProp: 'ipdRightHolder' }),
  getMandates: async (idTerm, allMandates, versionTerm = null) => {
    const aggregate = await query(
      { query: gql.getMandates, variables: { idTerm, allMandates, versionTerm } },
      { pickProp: 'performerAggregate' },
    );
    return aggregate.mandates;
  },
  getSimplePerformer: async (idTerm) => {
    const aggregate = await query(
      { query: gql.getSimplePerformer, variables: { idTerm } },
      { pickProp: 'performersByAlbum' },
    );
    return processSimplePerformerAggregate(aggregate);
  },
  getPerformerList: async (idList) => {
    const res = await query(
      { query: gql.getPerformerList, variables: { idList } },
      { pickProp: 'performerAggregateList' },
    );
    return res;
  },
  updatePerformerContactInfo: (performerAggregate) =>
    post(
      `${PERFORMERS_URL}/contact-info`,
      preparePayload(performerAggregate, 'contact_info', createUpdateContactInfoCommand()),
      { pickProp: 'id' },
    ),
  patchPerformerIpn: (aggregateId, ipn) =>
    post(
      `${PERFORMERS_URL}/patch-membership-info`,
      createPatchIpnCommand(aggregateId, ipn, 'star-ipd-merge-updater'),
      { pickProp: 'id' },
    ),
  updatePerformerMandateInfo: (mandates, aggregateId) =>
    post(
      `${PERFORMERS_URL}/mandates`,
      prepareUpdatePayload(mandates, aggregateId, createUpdateMandateInfoCommand()),
      { pickProp: 'id' },
    ),
  updatePerformerTaxInfo: (performerAggregate) =>
    post(
      `${PERFORMERS_URL}/tax-info`,
      preparePayload(
        performerAggregate,
        'tax_info',
        createUpdateTaxInfoCommand(AGGREGATE_TYPES.PERFORMER),
      ),
      { pickProp: 'id' },
    ),
  updatePerformerMetadataInfo: (performerAggregate) =>
    post(
      `${PERFORMERS_URL}/metadata-info`,
      prepareLegacyPayload(performerAggregate, 'metadata_info', createMetadataInfoCommand()),
      { pickProp: 'id' },
    ),
  updatePerformerPaymentInfo: (paymentInfo, aggregateId) =>
    post(
      `${PERFORMERS_URL}/payment-info`,
      prepareUpdatePayload(
        paymentInfo,
        aggregateId,
        createUpdatePaymentInfoCommand(AGGREGATE_TYPES.PERFORMER),
      ),
      { pickProp: 'id' },
    ),
  updatePerformerAssociates: (associates, aggregateId, associateId, aggregate) =>
    post(
      `${PERFORMERS_URL}/associates`,
      prepareUpdatePayload(
        { associates: prepareAssociates(associates, associateId) },
        aggregateId,
        createUpdateAssociatesCommand('performer'),
        aggregate,
      ),
      { pickProp: 'id' },
    ),
  removePerformerAssociate: (associateId, rowId, aggregateId) =>
    post(
      `${PERFORMERS_URL}/associates`,
      prepareUpdatePayload(
        { id: associateId, row_id: rowId },
        aggregateId,
        createRemoveAssociatesCommand('performer'),
      ),
      { pickProp: 'id' },
    ),
  updatePerformerDocuments: (documents, aggregateId) =>
    post(
      `${PERFORMERS_URL}/documents`,
      prepareUpdatePayload(
        documents,
        aggregateId,
        createDocumentsCommand(AGGREGATE_TYPES.PERFORMER),
      ),
      { pickProp: 'id' },
    ),
  updatePerformerPaymentsBlocked: (data, aggregateId) =>
    post(
      `${PERFORMERS_URL}/system-state/payments-blocked`,
      prepareUpdatePayload(data, aggregateId, createUpdatePaymentsBlockedCommand()),
    ),
  initiateMembership: (data, aggregateId) =>
    post(
      `${PERFORMERS_URL}/membership`,
      prepareUpdatePayload(data, aggregateId, createInitiateMembershipCommand()),
    ),
  confirmMembership: (data, aggregateId) =>
    post(
      `${PERFORMERS_URL}/membership/confirm`,
      prepareUpdatePayload(data, aggregateId, createConfirmMembershipCommand()),
    ),
  approveApplication: (data, aggregateId) =>
    post(
      `${PERFORMERS_URL}/membership/applicationApproved`,
      prepareUpdatePayload(data, aggregateId, createApproveMembershipApplicationCommand()),
    ),
  createMembershipAgreement: (data, aggregateId) =>
    post(
      `${PERFORMERS_URL}/membership/createRegionalMembershipAgreement`,
      prepareUpdatePayload(data, aggregateId, createRegionalMembershipAgreementCommand()),
    ),
  ipdSync: async (id) =>
    mutate({ mutation: gql.ipdSync, variables: { idTerm: id }, fetchPolicy: 'network-only' }, {}),
  ipdUpload: async (id) =>
    mutate(
      { mutation: gql.ipdUpload, variables: { idTerm: id }, fetchPolicy: 'network-only' },
      { pickProp: 'ipdUpload' },
    ),
  isUniqueSSN: async (ssn) =>
    query({ query: gql.uniqueSSN, variables: { ssn } }, { pickProp: 'uniqueSSN' }),
  idByIPN: async (ipnList) =>
    query({ query: gql.idByIPN, variables: { ipnList } }, { pickProp: 'idByIPN' }),
};
