<template>
  <div>
    <select-input
      name="genre"
      :label="label"
      :value="code"
      :items="genreItems"
      item-key="code"
      item-value="name"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectGenre',
  components: { SelectInput },
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: 'Genre' },
  },
  data() {
    return {
      code: this.value,
    };
  },
  computed: {
    genreItems() {
      return this.$store.state.appdata.referenceData.genres;
    },
  },
  watch: {
    value() {
      this.code = this.value;
    },
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
