<template>
  <div
    v-if="!widget.hideEmpty || (!widget.loading && widget.payload && widget.payload.total_count)"
  >
    <div class="widget">
      <div v-if="widget.title" class="title">
        {{ widget.title }}
      </div>

      <div
        class="widget-content"
        :class="widget.color"
        :style="'min-height:' + widget.height + 'px'"
      >
        <div
          v-if="widget.icon"
          class="icon"
          :style="'background: var(--' + widget.icon.color + ')'"
        >
          <entity-icon :entity="widget.icon.entity" />
        </div>
        <template v-if="!widget.loading && !widget.error">
          <component
            :is="widget.component"
            v-if="widget.payload"
            :payload="widget.payload"
            :extras="widget.extras"
            :icon="widget.icon"
            class="widget-component"
          />
        </template>
        <simple-spinner v-else-if="widget.loading"></simple-spinner>
        <span v-else-if="widget.error">Failed to load widget.</span>
      </div>
    </div>
  </div>
</template>

<script>
import AirtimeAllTimeWidget from './airtime-all-time-widget';
import MembersWidget from './members-widget';
import SdegClaimingDeadlinesWidget from './sdeg-claiming-deadlines-widget';
import NewRevenuesWidget from './new-revenues-widget';
import IconWidget from './icon-widget';
import NewDistributionsWidget from './new-distributions-widget';
import BlockedRedistributionsWidget from './blocked-redistributions-widget';
import NewChannelsWidget from './new-channels-widget';
import VrdbToCheckWidget from './vrdb-to-check-widget';
import SimpleSpinner from '../../../ui/simple-spinner';
import EntityIcon from '../../../ui/entity-icon';

export default {
  name: 'Widget',
  components: {
    AirtimeAllTimeWidget,
    SdegClaimingDeadlinesWidget,
    MembersWidget,
    NewRevenuesWidget,
    IconWidget,
    NewDistributionsWidget,
    BlockedRedistributionsWidget,
    NewChannelsWidget,
    SimpleSpinner,
    EntityIcon,
    VrdbToCheckWidget,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.simple-spinner {
  font-size: 18px;
  margin: auto 50%;
}
</style>
