<template>
  <tr class="prevent-hover-bg">
    <td></td>
    <td colspan="100%" class="expanded">
      <div class="flex">
        <div>
          <div class="expand-info__meta__title">Local codes:</div>
          <ViewLocalCodes :local-codes="local_codes" :show-label="false" />
        </div>
        <div>
          <div class="expand-info__meta__title">Recordings:</div>
          <table>
            <tr v-for="track of sortedTrack" :key="track.recording.id" class="track">
              <td>
                {{ track.recording.side_no ? track.recording.side_no : '?' }}.{{
                  track.recording.track_no ? track.recording.track_no : '?'
                }}
              </td>
              <td>
                <router-link :to="`/recordings/${track.recording.id}`">
                  {{ track.name }}
                </router-link>
              </td>
              <td>
                <span v-if="track.version_title">{{ track.version_title }}</span>
              </td>
              <td>
                <span v-if="track.duration_sec">
                  {{ $filters.toMinutes(track.duration_sec) }}
                </span>
              </td>
              <td>
                <span v-if="track.recording_date">
                  {{ $filters.formatRecordingDate(track.recording_date) }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { orderBy } from 'lodash';
import ViewLocalCodes from '../ui/view-local-codes';
import AlbumService from '../../services/albumService';

export default {
  name: 'DuplicateMatchAlbum',
  components: {
    ViewLocalCodes,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      local_codes: [],
      tracks: [],
    };
  },
  computed: {
    sortedTrack() {
      return orderBy(this.tracks, ['recording.side_no', 'recording.track_no']);
    },
  },
  async created() {
    const album = await AlbumService.getAlbum(this.id);
    this.tracks = album.tracks;
    this.local_codes = album.basic_info.local_codes;
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.expanded {
  border: 1px solid #eee;
}
.flex > div {
  min-width: 300px;
}
tr.track > td {
  padding: 0 var(--spacing) 0 0;
}
</style>
