<template>
  <div class="col s7">
    <div class="row wrapper">
      <div class="col s12">
        <h1>Manual Correction</h1>
      </div>
    </div>
    <div v-if="isDeveloper" class="row">
      <div class="col s12">
        <button class="btn" type="button" @click="onGenerateDummyDebit()">
          Generate dummy debit (secret developer button)
        </button>
      </div>
    </div>
    <div v-if="isDeveloper" class="row">
      <div class="col s12">
        <button class="btn" type="button" @click="onGenerateDummyCredit()">
          Generate dummy credit (secret developer button)
        </button>
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <div class="col s6">
          <select-generic
            v-model="refund.reason"
            name="refund-reason"
            label="Reason"
            :rule="{ required: true }"
            :data="RefundReasonTypes"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-date
            v-model="refund.date"
            name="refund-date"
            label="Transaction date"
            :rule="{ required: true }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <validated-text-input
            v-model="refund.detail.year"
            name="refund-detail-year"
            label="Year"
            placeholder="YYYY"
            :maxlength="4"
            :rule="{ required: true, year: true }"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-distribution-area-right
            v-model="refund.detail.right"
            name="refund-detail-right"
            label="Right"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-distribution-area
            name="refund-detail-area"
            :rule="{ required: false }"
            :value="refund.detail.area_id"
            @input="refund.detail.area_id = $event ? $event.id : 0"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-source
            name="refund-detail-source"
            :rule="{ required: true }"
            :sources="sources"
            :value="refund.detail.source_id"
            @input="refund.detail.source_id = $event.source.id"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-country
            v-model="refund.detail.territory"
            name="refund-detail-territory"
            label="Territory"
            rule="required"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-input
            disabled
            label="Performance activity"
            name="refund-detail-performance-activity"
            value="na"
            :items="performanceActivities"
            :empty-select-option="false"
            item-key="code"
            item-value="name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-input
            disabled
            label="Conditional aspect"
            name="refund-detail-conditional-aspect"
            value="na"
            :items="conditionalAspects"
            :empty-select-option="false"
            item-key="code"
            item-value="name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <label class="label">Note</label>
          <input v-model="refund.note" name="refund-note" type="text" autocomplete="off" />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <validated-text-input
            v-model="refund.detail.original_amount"
            name="refund-detail-original-amount"
            label="Original amount"
            type="number"
            :rule="{ required: true, decimal: 2 }"
            @input="calcNetAmount"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <validated-text-input
            v-model="refund.detail.vat"
            name="refund-details-vat"
            label="VAT"
            type="number"
            :rule="{
              required: true,
              decimal: 2,
              negative_amount: refund.detail.vat !== '0' && refund.detail.original_amount < 0,
              positive_amount: refund.detail.vat !== '0' && refund.detail.original_amount > 0,
            }"
            @input="calcNetAmount"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <validated-text-input
            v-model="refund.detail.tax"
            name="refund-detail-tax"
            label="Tax"
            type="number"
            :rule="{
              required: true,
              decimal: 2,
              negative_amount: refund.detail.tax !== '0' && refund.detail.original_amount > 0,
              positive_amount: refund.detail.tax !== '0' && refund.detail.original_amount < 0,
            }"
            @input="calcNetAmount"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <validated-text-input
            v-model="refund.detail.fee"
            name="refund-detail-fee"
            label="Administrative cost"
            type="number"
            :rule="{
              required: true,
              negative_amount: refund.detail.fee !== '0' && refund.detail.original_amount > 0,
              positive_amount: refund.detail.fee !== '0' && refund.detail.original_amount < 0,
            }"
            @input="calcNetAmount"
          />
        </div>
      </div>
      <div class="row small-inner">
        <div class="col s12">
          <b>Net amount: {{ refund.detail.net_amount }}</b>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <action-buttons submit-label="Refund" :show-abort="false" @save="onRefund()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { RefundReasonTypes } from '../../../domain/paymentDomain';
import ActionButtons from '../../ui/button/action-buttons';
import PaymentService from '../../../services/paymentService';
import SelectCountry from '../../ui/select/select-country';
import SelectDate from '../../ui/select/select-date';
import SelectDistributionAreaRight from '../../ui/select/select-distribution-area-right';
import SelectGeneric from '../../ui/select/select-generic';
import SelectSource from '../../ui/select/select-source';
import SelectDistributionArea from '../../ui/select/select-distribution-area';
import SelectInput from '../../ui/select/select-input';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import RevenueMixin from '../../../common/revenueMixin';

export default {
  name: 'ManualRefund',
  components: {
    ActionButtons,
    SelectCountry,
    SelectDate,
    SelectDistributionAreaRight,
    SelectGeneric,
    SelectSource,
    SelectDistributionArea,
    ValidatedTextInput,
    SelectInput,
  },
  mixins: [RevenueMixin],
  data() {
    return {
      sources: [],
      ledgerId: this.$route.params.id,
      RefundReasonTypes,
      currentLedgerVersion: null,
      performanceActivities: [{ name: 'N/A', code: 'na' }],
      conditionalAspects: [{ name: 'N/A', code: 'na' }],
      refund: {
        reason: null,
        date: null,
        note: null,
        detail: {
          year: null,
          right: null,
          area_id: null,
          source_id: null,
          territory: null,
          performance_activity: undefined,
          conditional_aspect: undefined,
          original_amount: 0,
          net_amount: 0,
          vat: 0,
          tax: 0,
          fee: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters('user', ['isDeveloper']),
  },
  async created() {
    this.currentLedgerVersion = await PaymentService.getCurrentLedgerVersion(this.ledgerId);
    await this.getRevenueSources();
  },
  methods: {
    calcNetAmount() {
      const d = this.refund.detail;
      this.refund.detail.net_amount = (
        Number(d.original_amount) +
        Number(d.vat) +
        Number(d.fee) +
        Number(d.tax)
      ).toFixed(2);
    },
    onGenerateDummyDebit() {
      this.refund = {
        reason: 'TRANSACTION_FAILED',
        date: '2020-01-01',
        note: 'DEBIT',
        detail: {
          year: '2020',
          right: 'LE',
          area_id: 1,
          source_id: '422',
          territory: 'MY',
          performance_activity: undefined,
          conditional_aspect: undefined,
          original_amount: '-100',
          vat: '-10',
          tax: '10',
          fee: '10',
          net_amount: -90,
        },
      };
    },
    onGenerateDummyCredit() {
      this.refund = {
        reason: 'TRANSACTION_FAILED',
        date: '2020-01-01',
        note: 'CREDIT',
        detail: {
          year: '2020',
          right: 'LE',
          area_id: 1,
          source_id: '422',
          territory: 'MY',
          performance_activity: undefined,
          conditional_aspect: undefined,
          original_amount: '100',
          vat: '10',
          tax: '-10',
          fee: '-10',
          net_amount: 90,
        },
      };
    },
    async onRefund() {
      try {
        await PaymentService.refund(this.currentLedgerVersion, this.ledgerId, this.refund);

        this.$router.push({
          name: 'viewPerformerAccountStatement',
          query: { id: this.id },
        });
      } catch (error) {
        error.title = 'Error when refunding';
        this.$addStarError(error);
      }
    },
  },
};
</script>
