import gql from 'graphql-tag';

const getDistributionAreaAggregate = gql`
  query distributionAreaAggregate($idTerm: Int!) {
    distributionAreaAggregate(id: $idTerm) {
      area {
        id
        name
        distribution_rule {
          id
          name
        }
        category_of_rights
        distribution_basis
      }
      channelRelations {
        channel_id
        factor
      }
    }
  }
`;

const getDistributionChannels = gql`
  query distributionChannels {
    distributionChannels {
      channels {
        id
        name
        code
        vrdb_playlist
        channel_group
        user
        date_changed
      }
    }
  }
`;

const getDistributionGroups = gql`
  query distributionGroups {
    distributionGroups {
      groups
    }
  }
`;

const getDistributionAreas = gql`
  query distributionAreas {
    distributionAreas {
      areas {
        id
        name
        distribution_rule {
          id
          name
        }
        category_of_rights
        distribution_basis
      }
    }
  }
`;

const getDistributionYearAll = gql`
  query distributionYearAll($yearTerm: Int!) {
    distributionYearAll(year: $yearTerm) {
      id
      name
      area_id
      report_year
      state
      open_month
      end_month
      errors
      correct
      error_pct
    }
  }
`;

const getDistributionYear = gql`
  query distributionYear($idTerm: Int!) {
    distributionYear(id: $idTerm) {
      header {
        id
        name
        area_id
        report_year
        state
        open_month
        end_month
        errors
        correct
        error_pct
        area {
          id
          name
          category_of_rights
          distribution_basis
          distribution_rule {
            id
            name
          }
        }
      }
      distributions {
        id
        name
        distribution_year_id
        distribution_method
        revenue_ids
        state
        points
        from_month
        to_month
        channel_distributions {
          channel_id
          airtime {
            hours
            minutes
            seconds
          }
          recording_count
          points
          reserved_points
          amount
          point_value
          source {
            id
            name
          }
        }
        client_attributes {
          source_distributions {
            source_id
            channel_ids
          }
        }
        distribution_groups {
          airtime {
            hours
            minutes
            seconds
          }
          recording_count
          points
          reserved_points
          amount
          point_value
          source {
            id
            name
          }
          sub_groups {
            channel_ids
            airtime {
              hours
              minutes
              seconds
            }
            recording_count
            points
            reserved_points
            amount
            point_value
            source {
              id
              name
            }
          }
        }
        result {
          date
          distributed_amount
          reserved_amount
          financing_fee
          remainder
          performer_count
        }
        user
        date_changed
      }
    }
  }
`;
const getDistributionYearIssues = gql`
  query distributionYearIssues($yearIdTerm: Int!, $limitTerm: Int, $pageTerm: Int) {
    distributionYearIssues(yearId: $yearIdTerm, limit: $limitTerm, page: $pageTerm) {
      issues {
        title
        main_artist
        recording_id
        issues
        airtime {
          hours
          minutes
          seconds
        }
      }
      total_count
    }
  }
`;

const getIncompleteRecordings = gql`
  query incompleteRecordings($incompleteRecordingsRequest: IncompleteRecordingsRequest) {
    incompleteRecordings(incompleteRecordingsRequest: $incompleteRecordingsRequest) {
      issues {
        title
        version_title
        main_artist
        recording_id
        recorded_in_country
        recording_year
        issues
        vrdb2_id
        performers {
          name
          id
        }
        airtime {
          hours
          minutes
          seconds
        }
        tags
      }
      total_count
      years
      countries
      issue_types
      channels
    }
  }
`;

const getDistributionRules = gql`
  query distributionRules {
    distributionRules {
      id
      name
    }
  }
`;

const revenue = `
  id
  payment_date
  currency
  name
  year
  source_id
  source {
    category
    name
  }
  society_code
  state
  original_amount
  amount
  distributable_amount
  distributed_amount
  refunded_amount
  conversion_rate
  financing_rate
  financing_fee
  statement_url
  attachment_urls
  statement_total_amount
  statement_conversion_rate
  missing_performers_url
  note
  user
  date_changed
  date_created
  distribution_id
  specification {
    source_id
    name
    amount
  }
  statements {
    id
    currency
    state
    source_file
    refund_files {
      url
      filename
    }
    progress
    error
  }
`;

const getDistributionRevenues = gql`
  query distributionRevenues($distributionId: Int!) {
    distributionRevenues(distributionId: $distributionId) {
      ${revenue}
    }
  }
`;

const getRevenues = gql`
  query revenues($options: RevenueOptions) {
    revenues(options: $options) {
      total_count
      items {
        id
        date_created
        name
        year
        source {
          category
          name
        }
        amount
        distributable_amount
        distributed_amount
        state
        payment_date
        original_amount
        conversion_rate
        financing_rate
        financing_fee
        note
        statement_url
      }
    }
  }
`;

const getRevenue = gql`
   query revenue($idTerm: Int!) {
    revenue(id: $idTerm) {
      ${revenue}
    }
  }
`;

const getRevenueSource = gql`
  query revenueSource($idTerm: String!) {
    revenueSource(id: $idTerm) {
      id
      name
    }
  }
`;

const getRevenueYears = gql`
  query revenueYears {
    revenueYears {
      years
    }
  }
`;

const getRevenueSources = gql`
  query revenueSources {
    revenueSources {
      years {
        year
        financing_rate
        closed
        user
        account_no
      }
      sources {
        id
        name
        source_society_name
        project_code_in
        project_code_out
        project_code_reserves
        financing_rate
        category
        sub_sources {
          id
          name
          project_code_in
          project_code_out
          project_code_reserves
        }
        user
      }
    }
  }
`;

const getClosedDistributions = gql`
  query closedDistributions($year: Int!) {
    closedDistributions(year: $year) {
      id
      name
      area_id
      area_name
    }
  }
`;

const getRedistSummary = gql`
  query {
    redistSummary {
      recording_id
      title
      main_artist
      abs_amount
      amount
      performers
      blocked_by
      block_date
    }
  }
`;

const getRedistDetails = gql`
  query redistDetails($recordingId: String!) {
    redistDetails(recordingId: $recordingId) {
      performer_id
      name
      role_code
      dist_amount
      redist_amount
      tot_amount
    }
  }
`;

const getProtectionChanges = gql`
  query protectionChanges($page: Int, $limit: Int) {
    protectionChanges(page: $page, limit: $limit) {
      items {
        id
        recording_id
        title
        version_title
        main_artist
        vrdb2_id
        prev_recording_country
        recording_country
        prev_produced_in_country
        produced_in_country
        date_updated
        playing_time_sec
      }
      total_count
    }
  }
`;

const recordingAirtimeSummery = gql`
  query recordingAirtimeSummery($starId: String!) {
    recordingAirtimeSummery(starId: $starId) {
      performance_year
      channel_id
      channel_name
      channel_group
      vrdb_playlist
      playing_time_sec
      playing_count
    }
  }
`;

const recordingDistributionHistory = gql`
  query recordingDistributionHistory($starId: String!, $limit: Int, $offset: Int) {
    recordingDistributionHistory(starId: $starId, limit: $limit, offset: $offset) {
      total_count
      items {
        dist_date
        type
        amount
      }
    }
  }
`;

const recordingDistributionHistoryDetails = gql`
  query recordingDistributionHistoryDetails(
    $starId: String!
    $distDate: String!
    $type: DistributionType!
  ) {
    recordingDistributionHistoryDetails(starId: $starId, distDate: $distDate, type: $type) {
      performer_id
      performer_name
      role_code
      right
      amount
    }
  }
`;

const recordingPerformerRemuneration = gql`
  query recordingPerformerRemuneration($starId: String!) {
    recordingPerformerRemuneration(starId: $starId) {
      performer_id
      performer_name
      amount
    }
  }
`;

const recordingPerformerRemunerationDetails = gql`
  query recordingPerformerRemunerationDetails($starId: String!, $performerId: String!) {
    recordingPerformerRemunerationDetails(starId: $starId, performerId: $performerId) {
      type
      role_code
      right
      amount
    }
  }
`;

const distribute = gql`
  mutation distribute($command: DistributeCommand!) {
    distribute(command: $command)
  }
`;

const payToLedger = gql`
  mutation payToLedger($distributionId: Int!) {
    payToLedger(distributionId: $distributionId)
  }
`;

const removeRevenue = gql`
  mutation removeRevenue($id: Int!) {
    removeRevenue(id: $id)
  }
`;

const updateRevenueSource = gql`
  mutation updateRevenueSource($command: RevenueSourceCommand!) {
    updateRevenueSource(command: $command)
  }
`;

const copyDistribution = gql`
  mutation copyDistribution($command: CopyDistributionCommand!) {
    copyDistribution(command: $command)
  }
`;

const updateRevenueFinancingRate = gql`
  mutation updateRevenueFinancingRate($command: RevenueFinancingRateCommand!) {
    updateRevenueFinancingRate(command: $command)
  }
`;

const vrdb2RecordingsSummaries = `
  summaries {
    type
    weight_3
    weight_2
    weight_1
  }
`;

const getVrdb2Recordings = (summaries = true) => gql`
  query getVrdb2Recordings($request: Vrdb2RecordingRequest!) {
    getVrdb2Recordings(request: $request) {
      total_count
      items {
        star_id
        title
        version_title
        vrdb2_id
        vrdb2_type
        main_artist
        performance_year
        weight
      }
      ${summaries ? vrdb2RecordingsSummaries : ''}
    }
  }
`;

const vrdb2Header = `
    id
    state
    files
    played_recordings
    playlist
    error
    created_at
    type
`;

const getVrdb2Headers = gql`
  query {
    getVrdb2Headers {
      ${vrdb2Header}
    }
  }
`;

const uploadVrdb2Files = gql`
  mutation uploadVrdb2Files($request: Vrdb2UploadRequest!) {
    uploadVrdb2Files(request: $request) {
      ${vrdb2Header}
    }
  }
`;

const blockRedistRecording = gql`
  mutation blockRedistRecording($recordingId: String!) {
    blockForRedistribution(recordingId: $recordingId) {
      blocked_by
      block_date
    }
  }
`;

const unblockRedistRecording = gql`
  mutation unblockRedistRecording($recordingId: String!) {
    unblockForRedistribution(recordingId: $recordingId)
  }
`;

const deleteProtectionChange = gql`
  mutation deleteProtectionChange($recordingId: String!) {
    deleteProtectionChange(recordingId: $recordingId)
  }
`;

const confirmApprovedRedist = gql`
  mutation confirmApprovedRedist($processId: String!) {
    confirmApprovedRedist(processId: $processId)
  }
`;

export default {
  getDistributionAreaAggregate,
  getDistributionChannels,
  getDistributionGroups,
  getDistributionAreas,
  getDistributionYearAll,
  getDistributionYear,
  getDistributionYearIssues,
  getDistributionRules,
  getIncompleteRecordings,
  getRevenues,
  getRevenue,
  getRevenueSource,
  getRevenueYears,
  getRevenueSources,
  distribute,
  payToLedger,
  removeRevenue,
  updateRevenueSource,
  copyDistribution,
  updateRevenueFinancingRate,
  getVrdb2Recordings,
  getVrdb2Headers,
  uploadVrdb2Files,
  getClosedDistributions,
  getDistributionRevenues,
  getRedistSummary,
  getRedistDetails,
  blockRedistRecording,
  unblockRedistRecording,
  getProtectionChanges,
  deleteProtectionChange,
  confirmApprovedRedist,
  recordingAirtimeSummery,
  recordingDistributionHistory,
  recordingDistributionHistoryDetails,
  recordingPerformerRemuneration,
  recordingPerformerRemunerationDetails,
};
