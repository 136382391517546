<template>
  <div class="authentication">
    <div class="authentication__background" />

    <div class="row">
      <div class="col s6 offset-s3 l4 offset-l4">
        <div class="authentication__container">
          <div v-show="loading" class="authentication__container-loader">
            <spinner />
          </div>

          <div class="authentication__logo">
            <h1>Welcome to Star</h1>
          </div>

          <form
            v-show="authenticationState === 'login'"
            data-vv-scope="login-form"
            @submit.prevent="login"
          >
            <div class="row">
              <div class="col s12">
                <p>Login with username and password.</p>
                <p>
                  If it's the first time you login, please use the one-time password received in the
                  registration email.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <label class="label">Username</label>
                <clearable-input
                  v-model="username"
                  v-validate="'required|min:2'"
                  name="username"
                  :class="{
                    input: true,
                    'is-danger': checkValidationFlags('$login-form', 'username', [
                      'dirty',
                      'invalid',
                    ]),
                  }"
                  type="text"
                  placeholder="Username"
                />
                <span
                  v-show="checkValidationFlags('$login-form', 'username', ['dirty', 'invalid'])"
                  class="help is-danger"
                >
                  <i class="fas fa-times-circle" />
                  {{ validationErrors.first('login-form.username') }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <label class="label">Password</label>
                <clearable-input
                  v-model="password"
                  v-validate="'required|min:10'"
                  name="password"
                  :class="{
                    input: true,
                    'is-danger': checkValidationFlags('$login-form', 'password', [
                      'dirty',
                      'invalid',
                    ]),
                  }"
                  type="password"
                  placeholder="Password"
                />
                <span
                  v-show="checkValidationFlags('$login-form', 'password', ['dirty', 'invalid'])"
                  class="help is-danger"
                >
                  <i class="fas fa-times-circle" />
                  {{ validationErrors.first('login-form.password') }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  ref="loginButton"
                  class="btn"
                  type="submit"
                  :disabled="validationErrors.any('login-form')"
                  @click.prevent="login"
                >
                  Login
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <a
                  :class="{
                    'authentication__reset-password': true,
                    'float-right': true,
                    disabled: validationErrors.first('login-form.username'),
                  }"
                  href="#"
                  @click="resetPassword"
                >
                  Reset password
                </a>
              </div>
            </div>
          </form>

          <form
            v-show="authenticationState === 'verificationCodeRequired'"
            data-vv-scope="verification-form"
            @submit.prevent="verifyAndSetNewPassword"
          >
            <div class="row">
              <div class="col s12">
                <p>Verification code required.</p>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <label class="label">Verification code</label>
                <clearable-input
                  v-model="verificationCode"
                  v-validate="'required'"
                  name="verificationCode"
                  :class="{
                    input: true,
                    'is-danger': checkValidationFlags('$verification-form', 'verificationCode', [
                      'dirty',
                      'invalid',
                    ]),
                  }"
                  type="text"
                  placeholder="Verification code"
                  autocomplete="off"
                />
                <span
                  v-show="
                    checkValidationFlags('$verification-form', 'verificationCode', [
                      'dirty',
                      'invalid',
                    ])
                  "
                  class="help is-danger"
                >
                  <i class="fas fa-times-circle" />
                  {{ validationErrors.first('verification-form.verificationCode') }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <label class="label">New password</label>
                <input
                  v-model="verificationNewPassword"
                  v-validate="'required|min:10'"
                  name="verificationNewPassword"
                  :class="{
                    input: true,
                    'is-danger': checkValidationFlags(
                      '$verification-form',
                      'verificationNewPassword',
                      ['dirty', 'invalid'],
                    ),
                  }"
                  type="password"
                  placeholder="New password"
                />
                <span
                  v-show="
                    checkValidationFlags('$verification-form', 'verificationNewPassword', [
                      'dirty',
                      'invalid',
                    ])
                  "
                  class="help is-danger"
                >
                  <i class="fas fa-times-circle" />
                  {{ validationErrors.first('verification-form.verificationNewPassword') }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  class="btn"
                  type="submit"
                  :disabled="validationErrors.any('verification-form')"
                  @click.prevent="verifyAndSetNewPassword"
                >
                  Set new password
                </button>
              </div>
            </div>
          </form>

          <form
            v-show="authenticationState === 'newPasswordRequired'"
            data-vv-scope="newPassword-form"
            @submit.prevent="setNewPassword"
          >
            <div class="row">
              <div class="col s12">
                <p>Set new password.</p>
                <p>This will be the password you sign in with from now on.</p>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <label class="label">New password</label>
                <input
                  v-model="newPassword"
                  v-validate="'required|min:10'"
                  name="newPassword"
                  :class="{
                    input: true,
                    'is-danger': checkValidationFlags('$newPassword-form', 'newPassword', [
                      'dirty',
                      'invalid',
                    ]),
                  }"
                  type="password"
                  placeholder="New password"
                />
                <span
                  v-show="
                    checkValidationFlags('$newPassword-form', 'newPassword', ['dirty', 'invalid'])
                  "
                  class="help is-danger"
                >
                  <i class="fas fa-times-circle" />
                  {{ validationErrors.first('newPassword-form.newPassword') }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  class="btn"
                  type="submit"
                  :disabled="validationErrors.any('newPassword-form')"
                  @click.prevent="setNewPassword"
                >
                  Set new password
                </button>
              </div>
            </div>
          </form>

          <form
            v-show="authenticationState === 'mfaCodeRequired'"
            data-vv-scope="mfaCode-form"
            @submit.prevent="verifyMfaCode"
          >
            <div class="row">
              <div class="col s12">
                <p>Verify your account.</p>
                <p>Please verify your account with the verification code sent to you by SMS.</p>
              </div>
            </div>
            <div class="row">
              <div class="col s12">
                <label class="label">MFA Code</label>
                <clearable-input
                  v-model="mfaCode"
                  v-validate="'required'"
                  name="mfaCode"
                  :class="{
                    input: true,
                    'is-danger': checkValidationFlags('$mfaCode-form', 'mfaCode', [
                      'dirty',
                      'invalid',
                    ]),
                  }"
                  type="text"
                  placeholder="MFA Code"
                  autocomplete="off"
                />
                <span
                  v-show="checkValidationFlags('$mfaCode-form', 'mfaCode', ['dirty', 'invalid'])"
                  class="help is-danger"
                >
                  <i class="fas fa-times-circle" />
                  {{ validationErrors.first('mfaCode-form.mfaCode') }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button
                  class="btn"
                  type="submit"
                  :disabled="validationErrors.any('mfaCode-form')"
                  @click.prevent="verifyMfaCode"
                >
                  Verify
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col s5 m6" />
  </div>
</template>

<script>
import AuthenticationService from '../../services/authenticationService';
import Spinner from '../spinner';
import ClearableInput from '../ui/input/clearable-input.vue';

export default {
  name: 'Authentication',
  components: {
    Spinner,
    ClearableInput,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      username: '',
      password: '',
      verificationCode: '',
      verificationNewPassword: '',
      newPassword: '',
      mfaCode: '',
      authenticationState: 'login',
      userAttributes: {},
      validLoginComponents: {
        username: { valid: false },
        password: { valid: false },
      },
      validVerificationComponents: {
        verificationCode: { valid: false },
        verificationNewPassword: { valid: false },
      },
      validNewPassword: false,
      validMfaCode: false,
      loading: false,
    };
  },
  mounted: function mounted() {
    this.$validator.validateAll('login-form').catch(() => {});
  },
  methods: {
    checkValidationFlags(scope, field, flags) {
      if (
        !(
          this.validationFields &&
          this.validationFields[scope] &&
          this.validationFields[scope][field]
        )
      ) {
        return false;
      }
      if (!flags.every((flag) => this.validationFields[scope][field][flag] === true)) {
        return false;
      }
      return true;
    },
    successDestination(route) {
      if (route.query.redirect) {
        return {
          path: route.query.redirect,
          params: { local: true },
        };
      }
      return {
        name: 'homeView',
        params: { local: true },
      };
    },
    login() {
      this.loading = true;
      AuthenticationService.login(this.username, this.password)
        .then((result) => {
          this.loading = false;
          if (result.status === 'success') {
            this.$router.push(this.successDestination(this.$route));
          } else if (result.status === 'newPasswordRequired') {
            this.userAttributes = result.result;
            this.authenticationState = 'newPasswordRequired';
            this.$validator.validateAll('newPassword-form').catch(() => {});
          } else if (result.status === 'mfaCodeRequired') {
            this.authenticationState = 'mfaCodeRequired';
            this.$validator.validateAll('mfaCode-form').catch(() => {});
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$addStarError({
            title: 'Ooops!',
            type: `${error.statusCode} - ${error.code}`,
            message: error.message,
          });
        });
    },
    resetPassword() {
      this.loading = true;
      AuthenticationService.resetPasswordSendVerficiationCode(this.username)
        .then((result) => {
          this.loading = false;
          if (result.status === 'success') {
            this.authenticationState = 'verificationCodeRequired';
            this.$validator.validateAll('verification-form').catch(() => {});
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$addStarError({
            title: 'Ooops!',
            type: `${error.statusCode} - ${error.code}`,
            message: error.message,
          });
        });
    },
    verifyAndSetNewPassword() {
      this.loading = true;
      AuthenticationService.verifyAndSetNewPassword(
        this.verificationCode,
        this.verificationNewPassword,
      )
        .then((result) => {
          this.loading = false;
          if (result.status === 'success') {
            this.authenticationState = 'login';
            this.$validator.validateAll('login-form').catch(() => {});
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$addStarError({
            title: 'Ooops!',
            type: `${error.statusCode} - ${error.code}`,
            message: error.message,
          });
        });
    },
    setNewPassword() {
      this.loading = true;
      AuthenticationService.setNewPassword(this.userAttributes, this.newPassword)
        .then((result) => {
          this.loading = false;
          if (result.status === 'success') {
            this.$router.push(this.successDestination(this.$route));
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$addStarError({
            title: 'Ooops!',
            type: `${error.statusCode} - ${error.code}`,
            message: error.message,
          });
        });
    },
    verifyMfaCode() {
      this.loading = true;
      AuthenticationService.verifyMfaCode(this.mfaCode)
        .then((result) => {
          this.loading = false;
          if (result.status === 'success') {
            this.$router.push(this.successDestination(this.$route));
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$addStarError({
            title: 'Ooops!',
            type: `${error.statusCode} - ${error.code}`,
            message: error.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.authentication {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: var(--grey--dark);
    opacity: 0.8;
  }

  &__container {
    position: relative;
    margin-top: 100px;
    background: var(--white);
    padding: 10px;
    border: 10px solid var(--orange);

    &-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  &__logo {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__reset {
    &-password {
      font-size: 11px;
    }
  }

  .help {
    width: 100%;
    float: left;
  }

  button {
    width: 100%;
    height: 40px;
  }
}
</style>
