<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="row wrapper">
        <div class="col s12">
          <h1>Performance Year {{ year }}</h1>
        </div>
      </div>

      <div class="row inner">
        <div class="col s3 float-right">
          <filter-input
            v-model="filterQuery"
            label="Filter distribution area"
            :filter-query="filterQuery"
          />
        </div>
        <div class="col s2 float-right">
          <select-input
            name="source"
            label="Source"
            :items="sources"
            :value="filterTerms.source"
            @input="onSourceFilter"
          />
        </div>
        <div class="col s2 float-right">
          <select-distribution-area-right
            name="right-filter"
            label="Category of right"
            rule=""
            :value="filterTerms.right"
            @input="onRightFilter"
          />
        </div>
      </div>

      <div class="row card">
        <table class="table__border">
          <sortable-head
            :columns="columns"
            :bulk-enabled="false"
            :more-options="false"
            :default-sort-should-override="true"
            :disabled="filteredSortedRows.length === 0"
            @sort="clickSort"
          />
          <tbody>
            <template v-for="(row, idx) of filteredSortedRows">
              <tr :key="idx">
                <td>{{ row.sourceName }}</td>
                <td>{{ getRight(row.right) }}</td>
                <td>{{ row.areaName || 'N/A' }}</td>
                <td class="text--right">
                  {{ $filters.formatAmountCent(getOrgAmount(row), 'SEK') }}
                </td>
                <td class="text--right">{{ $filters.formatAmountCent(row.fee, 'SEK') }}</td>
                <td class="text--right">
                  {{ $filters.formatAmountCent(getGrossAmount(row), 'SEK') }}
                </td>
                <td class="text--right">{{ $filters.formatAmountCent(row.tax, 'SEK') }}</td>
                <td class="text--right">{{ $filters.formatAmountCent(row.net, 'SEK') }}</td>
                <td
                  class="text--right"
                  :class="{ negative: row.balance < 0, positive: row.balance > 0 }"
                >
                  {{ $filters.formatAmountCent(row.balance, 'SEK') }}
                </td>
              </tr>
            </template>
            <tr class="summary">
              <td class="text--bold" colspan="3">Total sum:</td>
              <td class="amount-row">
                {{ $filters.formatAmountCent(totals.totalOriginalAmount, 'SEK') }}
              </td>
              <td class="amount-row">
                {{ $filters.formatAmountCent(totals.totalFee, 'SEK') }}
              </td>
              <td class="amount-row">
                {{ $filters.formatAmountCent(totals.totalGrossAmount, 'SEK') }}
              </td>
              <td class="amount-row">
                {{ $filters.formatAmountCent(totals.totalTax, 'SEK') }}
              </td>
              <td class="amount-row">
                {{ $filters.formatAmountCent(totals.totalNetAmount, 'SEK') }}
              </td>
              <td
                class="amount-row"
                :class="{
                  negative: Math.sign(Number(totals.totalBalance)) === -1,
                  positive: Math.sign(Number(totals.totalBalance)) === +1,
                }"
              >
                {{ $filters.formatAmountCent(totals.totalBalance, 'SEK') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DebouncedSearchMixin from '@/common/debouncedSearchMixin';
import FilterInput from '@/components/ui/input/filter-input';
import SelectInput from '@/components/ui/select/select-input';
import SelectDistributionAreaRight from '@/components/ui/select/select-distribution-area-right';
import Spinner from '@/components/spinner';
import SortableHead from '@/components/ui/table/sortable-head';
import { query } from '@/services/apolloRequest';
import { calcGross, calcOrig } from '@/common/amount';
import gql from 'graphql-tag';

export default {
  name: 'ViewDistributionArea',
  components: {
    FilterInput,
    SelectDistributionAreaRight,
    SelectInput,
    Spinner,
    SortableHead,
  },
  mixins: [DebouncedSearchMixin(50)],
  props: {
    type: {
      type: String,
      required: true,
    },
    id: String,
    year: [String, Number],
  },
  data() {
    return {
      loading: false,
      columns: [
        { name: 'Source', active: true, sortParam: 'sourceName', ascending: true },
        { name: 'Category of Right', active: true, sortParam: 'right', ascending: false },
        { name: 'Distribution area', active: true, sortParam: 'areaId', ascending: false },
        { name: 'Original amount', active: true, sortParam: this.getOrgAmount, ascending: false },
        { name: 'Administrative fee', active: true, sortParam: 'fee', ascending: false },
        { name: 'Gross amount', active: true, sortParam: this.getGrossAmount, ascending: false },
        { name: 'Tax', active: true, sortParam: 'tax', ascending: false },
        { name: 'Net amount', active: true, sortParam: 'net', ascending: false },
        { name: 'Balance', active: true, sortParam: 'balance', ascending: false },
      ],
      rows: [],
      sources: [],
      sorting: {
        orderBy: 'sourceName',
        dir: 'asc',
      },
      filterQuery: '',
      filterTerms: {
        right: this.$route.query.right || null,
        source: this.$route.query.source || null,
      },
    };
  },
  computed: {
    filteredSortedRows() {
      return this.rows
        .filter((r) => {
          const ft = this.filterTerms;
          return (
            (!ft.right || r.right === ft.right) &&
            (!ft.source || r.sourceName === ft.source) &&
            (!this.filterQuery ||
              (r.areaName && r.areaName.toLowerCase().includes(this.filterQuery.toLowerCase())))
          );
        })
        .sort((a, b) => {
          const order = this.sorting.dir === 'asc' ? 1 : -1;
          let va;
          let vb;
          if (typeof this.sorting.orderBy === 'function') {
            va = this.sorting.orderBy(a);
            vb = this.sorting.orderBy(b);
          } else {
            va = a[this.sorting.orderBy];
            vb = b[this.sorting.orderBy];
          }
          if (va < vb) {
            return -1 * order;
          } else {
            return 1 * order;
          }
        });
    },
    mandateRights() {
      return this.$store.state.appdata.referenceData.mandateRights;
    },
    totals() {
      const tot = {
        totalOriginalAmount: 0,
        totalFee: 0,
        totalGrossAmount: 0,
        totalTax: 0,
        totalNetAmount: 0,
        totalBalance: 0,
      };
      this.filteredSortedRows.forEach((row) => {
        tot.totalOriginalAmount += this.getOrgAmount(row);
        tot.totalFee += row.fee;
        tot.totalGrossAmount += this.getGrossAmount(row);
        tot.totalTax += row.tax;
        tot.totalNetAmount += row.net;
        tot.totalBalance += row.balance;
      });
      return tot;
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const result = await query(
          {
            query: gql`
              query {
                yearSummary(accountId: "${this.id}", year: ${this.year}) {
                  year
                  areaId
                  areaName
                  sourceId
                  sourceName
                  right
                  net
                  fee
                  tax
                  balance
                }
              }
            `,
          },
          { pickProp: 'yearSummary' },
        );
        this.loading = false;
        this.rows = result;
        this.sources = Array.from(new Set(this.rows.map((row) => row.sourceName))).map(
          (source) => ({ code: source, name: source }),
        );
      } catch (error) {
        console.log('Error fetching discography records', error);
        this.loading = false;
      }
    },
    clickSort(column) {
      this.sorting.orderBy = column.sortParam;
      this.sorting.dir = column.order;
    },
    onSourceFilter(src) {
      this.filterTerms.source = src;
    },
    onRightFilter(val) {
      this.filterTerms.right = val;
    },
    getOrgAmount(row) {
      return calcOrig(row);
    },
    getGrossAmount(row) {
      return calcGross(row);
    },
    getRight(code) {
      return this.mandateRights.find((mr) => mr.code === code)?.name;
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.sources,
.net-amount {
  border-right: 1px solid #eee;
}
.amount-row {
  font-weight: bold;
}
</style>
