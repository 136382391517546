<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>

    <div class="row">
      <div class="col s12">
        <TableOptions
          :countries="[]"
          :bulk-actions="[]"
          :bulk-action-enabled="false"
          :recorded-in-country-code="recordedInCountryCode"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
          @countryChanged="onCountryChanged"
          @bulkAction="onBulkAction"
        />
      </div>
    </div>
    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table>
          <CompareSort
            :bulk-enabled="false"
            :marked-for-bulk="false"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareListMatching
            ref="comparelist"
            :sdeg="isSdeg"
            :album="isAlbum"
            :candidate-changed="candidateChanged"
            :get-candidate-value="getCandidateValue"
            :handle-identify-btn="handleIdentifyBtn"
            :handle-ignore-btn="handleIgnoreBtn"
            :handle-new-recording="handleNewRecording"
            :handle-save-btn="handleSaveBtn"
            :is-expanded="isExpanded"
            :revert-match="revertMatch"
            :toggle-expanded="toggleExpanded"
            :allow-action="false"
            :selectable="false"
            @change="updateDirtyState"
          />
        </table>

        <div class="result__pagination">
          <Pagination
            v-if="numberOfPages() > 0"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="totalCount"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import CompareFieldsMixin from '../../common/compareFieldsMixin';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import MusicReportHelper from './musicReportHelper';
import Pagination from '../pagination';
import PaginationAndSortMixin from '../../common/paginationAndSortMixin';
import TableOptionsMixin from '../../common/tableOptionsMixin';
import TableOptions from '../ui/table/table-options';
import ViewMusicReportMixin from './view-music-report-mixin';
import CompareSort from '../compare/compare-sort';
import CompareListMatching from '../compare/compare-list-matching';

export default {
  name: 'ViewMusicReportClaimedLines',
  components: {
    CompareListMatching,
    CompareSort,
    Pagination,
    TableOptions,
  },
  mixins: [
    CompareFieldsMixin,
    DebouncedSearchMixin(50),
    PaginationAndSortMixin,
    TableOptionsMixin,
    ViewMusicReportMixin,
  ],
  data() {
    return {
      bulkEditActions: [],
      lineupStateMask: 'T',
    };
  },
  created() {
    this.clearFilter('ViewMusicReportClaimedLines');
  },
  async activated() {
    const expandedId = this.viewMusicReportMatchedLines.expandedId;
    await this.loadPaginated();
    this.recordedInCountries = MusicReportHelper.getCountries();
    if (expandedId) {
      this.toggleExpanded(expandedId);
    }
  },
  deactivated() {
    this.updateViewMusicReportMatchedLinesExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
  methods: {
    getMatchedBasicInfoProp(line, path) {
      return _.get(line, `matched.basic_info.${path}`, false);
    },
    onBulkAction() {
      return undefined;
    },
    markAll: function markAll() {
      this.localMarkedForBulk = !this.localMarkedForBulk;
      this.$emit('markAll', this.localMarkedForBulk);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.matching__expand {
  cursor: pointer;
  &:hover {
    background-color: var(--list-hover-blue);
  }
}
.matched__value-diff {
  color: var(--red);
  font-weight: bold;
}
</style>
