<template>
  <div>
    <div class="row wrapper">
      <div class="col s11">
        <h1>Financial Reports</h1>
      </div>
    </div>

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col s3">
          <select-year
            label="Year"
            :empty-select-option="false"
            :years="years"
            :value="selectedYear"
            @input="onYearChanged"
          />
        </div>
      </div>
      <div class="row card">
        <div class="search-result__result">
          <div class="row">
            <div class="col s12">
              <table>
                <thead>
                  <th
                    v-for="(column, index) in columns"
                    :key="`column-${index}`"
                    :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  >
                    <span>{{ column.name }}</span>
                  </th>
                </thead>
                <tbody>
                  <tr v-for="month in reportMonths" :key="month.monthId">
                    <td>
                      <span>
                        {{ $filters.formatMonthFromNumber1(month.monthId) }}
                      </span>
                    </td>
                    <td>
                      <span>
                        <router-link
                          :to="{
                            name: 'financeReportMonthView',
                            params: { year: selectedYear, monthId: month.monthId },
                          }"
                        >
                          Accounting files
                          {{ $filters.formatMonthFromNumber1(month.monthId) }}
                          {{ selectedYear }}
                        </router-link>
                      </span>
                    </td>
                    <td v-if="month.balanceFiles">
                      <div
                        v-for="file of month.balanceFiles"
                        :key="file.report.filename"
                        class="push-half--bottom capitalize"
                      >
                        <a @click="onDownloadReport(file.report)">
                          <i class="fas fa-download"></i>
                          {{ file.type }} balance
                          {{ $filters.formatMonthFromNumber1(month.monthId) }}
                          {{ selectedYear }}
                        </a>
                      </div>
                    </td>
                    <td v-else>
                      <span class="none">No report available for this month</span>
                    </td>
                    <td class="w-15"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import RemoteFileService from '../../services/remoteFileService';
import DownloadLink from '../ui/file/downloadLink';
import SelectYear from './../ui/select/select-year';
import Spinner from '../spinner';

export default {
  name: 'ViewFinanceReportYear',
  components: {
    SelectYear,
    Spinner,
  },
  data() {
    const currentYear = new Date().getFullYear();
    return {
      viewRouteName: 'financeReportYearView',
      years: _.range(currentYear - 10, currentYear + 1, 1).sort((a, b) => b - a),
      selectedYear: this.$router.currentRoute.params.year
        ? this.$router.currentRoute.params.year
        : String(currentYear),
      error: false,
      loading: false,
      reportMonths: [],
      columns: [
        {
          name: 'Month',
        },
        {
          name: 'Name',
        },
        {
          name: 'Balance Report',
        },
      ],
    };
  },
  watch: {
    async $route() {
      await this.reloadBaseData();
    },
  },
  async created() {
    await this.reloadBaseData();
  },
  methods: {
    routeParams(base) {
      return Object.assign(base, { year: this.selectedYear });
    },
    updateRouterCustomSearchParams() {
      if (this.$router.currentRoute.params.year) {
        this.selectedYear = this.$router.currentRoute.params.year.toString();
      }
    },
    onYearChanged(year) {
      this.selectedYear = year;
      const route = {
        name: 'financeReportYearView',
        params: this.routeParams({}),
      };
      this.$router.push(route);
    },
    async onDownloadReport(file) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(
        `${file.path}/${file.filename}`,
      );
      DownloadLink.direct(fileData, file.filename);
    },
    async reloadBaseData() {
      try {
        this.loading = true;
        const directories1 = await RemoteFileService.listDocumentRepository(
          `accounting/${this.selectedYear}`,
        );
        const directories2 = await RemoteFileService.listDocumentRepository(
          `residual-balance/${this.selectedYear}`,
        );

        const reportsByMonth = directories2
          .filter((dir) => dir.filename)
          .reduce((acc, curr) => {
            const month = curr.path.slice(-2);
            let type = 'residual';

            if (/_payable/.test(curr.filename)) {
              type = 'payable';
            } else if (/_reservation/.test(curr.filename)) {
              type = 'reservation';
            }

            if (acc[month]) {
              acc[month].push({ type, report: curr });
            } else {
              acc[month] = [{ type, report: curr }];
            }
            return acc;
          }, {});

        const uniqueMonths = new Set(
          [
            ...directories1.map((dir) => dir.path.slice(-2)),
            ...directories2.map((dir) => dir.path.slice(-2)),
          ].sort(),
        );
        const uniqueMonthArray = Array.from(uniqueMonths);
        this.reportMonths = uniqueMonthArray.map((month) => ({
          monthId: month,
          balanceFiles: reportsByMonth[month],
        }));
      } catch (error) {
        this.baseData = [];
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
