<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Distributions</h1>
      </div>
    </div>

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col s3">
          <select-year
            label="Search by report year"
            :empty-select-option="false"
            :years="years"
            :value="selectedYear"
            @input="onYearChanged"
          />
        </div>
        <div class="col s3">
          <filter-input :filter-query="filterQuery" placeholder="E.g. SR" @input="filterSearch" />
        </div>
        <div class="col s2 text--right">
          <label class="label display--block invisible">Copy distribution</label>
          <button class="btn" type="submit" @click.prevent="showDistributionModal = true">
            Copy distribution
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col s12 l8 card">
          <table>
            <thead>
              <th
                v-for="(column, index) in columns"
                :key="`column-${index}`"
                :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                @click="column.sortName ? sort(column) : ''"
              >
                <span>{{ column.name }}</span>
                <span v-if="column.sortName && !column.thinColumn" class="dropdown-button__arrow">
                  <span v-if="column.sortParam">
                    <i
                      v-if="column.order === 'desc'"
                      class="fas fa-caret-down"
                      :class="{ 'text--grey': !column.active }"
                    />
                    <i v-else class="fas fa-caret-up" :class="{ 'text--grey': !column.active }" />
                  </span>
                </span>
              </th>
            </thead>
            <tbody>
              <tr
                v-for="distributionYear in searchContext.result.results"
                :key="distributionYear.id"
              >
                <td>
                  <span>
                    <router-link
                      :to="{
                        name: 'distributionCalculateView',
                        params: { year: distributionYear.report_year, yearId: distributionYear.id },
                      }"
                    >
                      {{ $filters.toTitleCase(distributionYear.name) }}
                    </router-link>
                  </span>
                </td>
                <td>
                  <span v-if="distributionYear.open_month">
                    <span v-if="distributionYear.open_month === 12" class="none">Done</span>
                    <span>
                      {{ $filters.formatMonthFromNumber(distributionYear.open_month) }} - December
                    </span>
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td class="text--right">
                  <error-percent :value="distributionYear.error_pct" />
                  <span>({{ distributionYear.errors }})</span>
                </td>
                <td>
                  <span v-if="distributionYear.points">{{ distributionYear.points }}</span>
                  <span v-else class="none">None</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <copy-distribution
        :year="selectedYear"
        :show="showDistributionModal"
        @cancel="onCancelCopyDistribution"
        @copy="onCopyDistribution"
      />

      <div class="row">
        <div v-if="searchContext.isExecuted" class="col s12 l8">
          <pagination
            v-show="numberOfPages > 0"
            :number-of-pages="numberOfPages"
            :selected-page="pagination.page"
            :number-of-hits="searchContext.result.totalHits"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import DistributionService from '../../../services/distributionService';
import ErrorPercent from '../error-percent';
import DataTableMixin from '../data-table-mixin';
import FilterInput from './../../ui/input/filter-input';
import SelectYear from './../../ui/select/select-year';
import Spinner from '../../spinner';
import Pagination from './../../pagination';
import CopyDistribution from './copy-distribution';

export default {
  name: 'ViewDistributionYear',
  components: {
    ErrorPercent,
    Pagination,
    SelectYear,
    Spinner,
    FilterInput,
    CopyDistribution,
  },
  mixins: [DataTableMixin],
  data() {
    return {
      viewRouteName: 'distributionYearQueryView',
      selectedYear: new Date().getFullYear().toString(),
      error: false,
      showDistributionModal: false,
      columns: [
        {
          name: 'Distribution Areas',
          sortName: 'name',
          order: 'asc',
          sortParam: true,
          filterable: true,
        },
        {
          name: 'Period Left',
        },
        {
          name: 'Errors',
        },
        {
          name: 'Points',
        },
      ],
      sortTerms: {
        param: 'name',
        order: 'asc',
      },
      years: [],
    };
  },
  created() {
    const currentYear = new Date().getFullYear();
    this.years = _.range(currentYear - 10, currentYear + 1, 1).sort((a, b) => b - a);
  },
  methods: {
    routeParams(base) {
      return Object.assign(base, { year: this.selectedYear });
    },
    updateRouterCustomSearchParams() {
      if (this.$router.currentRoute.params.year) {
        this.selectedYear = this.$router.currentRoute.params.year.toString();
      }
    },
    async onYearChanged(year) {
      this.selectedYear = year;
      await this.reloadBaseData();
      this.filterQuery = null;
      this.pushSearch();
    },
    async reloadBaseData() {
      try {
        const result = await DistributionService.getDistributionYearAll(Number(this.selectedYear));
        this.baseData = result;
      } catch (error) {
        this.baseData = [];
        this.$addStarError(error);
      }
    },
    async onCopyDistribution(copy) {
      await DistributionService.copyDistribution(copy);
      this.showDistributionModal = false;
      await this.reloadBaseData();
      this.filterQuery = null;
      this.pushSearch();
    },
    onCancelCopyDistribution() {
      this.showDistributionModal = false;
    },
  },
};
</script>
