import { debounce } from 'lodash';

/**
 * Mixin for debounced searches.
 * The underlying search function must accept current search terms and previous search terms.
 * By default, this.search will be called.
 *
 * @param millis the debounce period, 350 is a good starting point
 * @param searchFunctionName provide a custom search function name that maps to a function on the component, ie. mySearchFunction
 * @returns {*}
 */
const debouncedSearchMixin = function debouncedSearchMixin(millis, searchFunctionName = 'search') {
  return {
    data() {
      return {
        debouncedSearch: debounce(
          (terms, previousTerms) => this[searchFunctionName](terms, previousTerms),
          millis,
          { leading: false },
        ),
      };
    },
  };
};

export default debouncedSearchMixin;
