<template>
  <div class="track-row">
    <div class="track-row__wrapper">
      <div class="track-row__item track-row__item--recording">
        <searchable-input
          ref="recording"
          :name="name + '.recording'"
          :searcher="searchRecording"
          :value="track.recordingAggregate ? track.recordingAggregate.basic_info.name : null"
          label="Add a recording"
          :mandatory="true"
          use-template="recording"
          rule="required"
          @clear="clearSelectedRecording"
          @input="updateSelectedRecording"
        />
      </div>
      <div class="track-row__item track-row__item--title">
        <validated-text-input
          ref="track_title"
          :name="name + '.track_title'"
          :value="track.track_title"
          label="Track title"
          @input="updateTrackTitle"
        />
      </div>
      <div class="track-row__item track-row__item--duration">
        <duration-input
          ref="duration_sec"
          :name="name + '.duration_sec'"
          :value="
            track.recordingAggregate ? track.recordingAggregate.basic_info.duration_sec : null
          "
          label="Duration"
          @input="updateDuration"
        />
      </div>
      <div class="track-row__item track-row__item--sidenumber">
        <validated-text-input
          ref="side_no"
          :name="name + '.side_no'"
          :value="track.recording.side_no"
          label="Side no."
          :mandatory="true"
          :rule="{ required: true, numeric: true, min_value: 1 }"
          @input="updateSideNo"
        />
      </div>
      <div class="track-row__item track-row__item--tracknumber">
        <validated-text-input
          ref="track_no"
          :name="name + '.track_no'"
          :value="track.recording.track_no"
          label="Track no."
          :mandatory="true"
          :rule="{ required: true, numeric: true, min_value: 1 }"
          @input="updateTrackNo"
        />
        <delete-button :has-label="true" @click="emitDelete" />
      </div>
    </div>
  </div>
</template>

<script>
import DeleteButton from '../../ui/button/delete-button';
import SearchableInput from '../../ui/input/searchable-input';
import SearchHelper from '../../search/searchHelper';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import DurationInput from '../../ui/input/duration-input';

export default {
  name: 'TrackRow',
  components: {
    DeleteButton,
    SearchableInput,
    ValidatedTextInput,
    DurationInput,
  },
  inject: ['$validator'],
  props: {
    track: { type: Object },
    name: { type: String },
  },
  data() {
    return {
      placeHolderRecording: 'E.g. Mannen i den vita hatten',
      placeHolderTitle: 'Track title',
      placeHolderDuration: 'Enter duration in seconds',
      placeHolderSideNo: 'Enter a number...',
      placeHolderTrackNo: 'Enter a number...',
    };
  },
  computed: {
    isDirty() {
      return [
        this.track.name,
        this.track.track_title,
        this.track.duration_sec,
        this.track.recording.id,
        this.track.recording.side_no,
        this.track.recording.track_no,
      ].some((el) => !!el);
    },
    isComplete() {
      return [
        this.track.name,
        this.track.track_title,
        this.track.duration_sec,
        this.track.recording.id,
        this.track.recording.side_no,
        this.track.recording.track_no,
      ].every((el) => !!el);
    },
  },
  methods: {
    searchRecording(terms) {
      return SearchHelper.quickSearchRecording(terms);
    },
    updateSelectedRecording(recording) {
      if (recording) {
        if (recording.id) {
          this.track.recordingAggregate.basic_info.name = recording.name;
          this.track.recording.id = recording.id;
          this.track.recordingAggregate.basic_info.duration_sec = recording.duration_sec;
          this.emitUpdate();
        }
      } else {
        this.clearSelectedRecording();
      }
    },
    clearSelectedRecording() {
      this.track.recording.id = undefined;
      this.track.recordingAggregate.basic_info.name = '';
      this.track.track_title = '';
      this.track.recordingAggregate.basic_info.duration_sec = undefined;
      this.emitUpdate();
    },
    updateTrackTitle(val) {
      this.track.track_title = val;
      this.emitUpdate();
    },
    updateDuration(val) {
      this.track.recordingAggregate.basic_info.duration_sec = val;
      this.emitUpdate();
    },
    updateSideNo(val) {
      this.track.recording.side_no = val;
      this.emitUpdate();
    },
    updateTrackNo(val) {
      this.track.recording.track_no = val;
      this.emitUpdate();
    },
    emitDelete() {
      this.$emit('delete');
    },
    emitUpdate() {
      this.$emit('input', this.track);
    },
  },
};
</script>

<style lang="scss" scoped>
.track-row {
  display: grid;
  grid-template-columns: 1fr 30px;

  & + & {
    margin-top: 16px;
  }

  &__wrapper {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: 2fr repeat(2, 1fr) repeat(2, 150px);
    grid-gap: 0px 8px;
  }
}
</style>
