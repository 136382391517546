<template>
  <div class="row flush--bottom">
    <div
      class="chips col s12"
      :class="{ 'is-danger-chip': validationErrors.has(name, scope) }"
      @click="setFocus"
    >
      <template v-for="(value, index) in valueList">
        <div :key="index" class="chip">
          {{ value }}
          <i class="fas fa-times close" @click="removeTag(index)" />
        </div>
      </template>

      <input
        ref="newTag"
        v-model="currentTag"
        v-validate="computedRules"
        class="input"
        :class="{ invalid: validationErrors.has(name, scope) }"
        :name="name"
        :placeholder="valueList.length === 0 ? placeholder : null"
        :data-vv-as="label.toLowerCase()"
        @blur="addTag"
        @keydown.9="addTag"
        @keydown.enter.prevent="addTag"
        @keydown.delete="currentTag === '' ? removeTag(valueList.length - 1) : null"
      />
    </div>
    <div class="col s6">
      <label
        v-if="showLabel"
        class="label"
        :class="{ 'label--error': validationErrors.has(name, scope) }"
      >
        {{ label }}
      </label>
      <span v-if="mandatory" class="mandatory">(mandatory)</span>
    </div>
    <div class="col s6">
      <div class="add-more-chips text--right">To add more, use enter</div>
    </div>
    <span v-show="validationErrors.has(name, scope)" class="help is-danger float-left">
      <i class="fas fa-times-circle" />
      {{ validationErrors.first(name, scope) }}
    </span>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'TagInput',
  inject: ['$validator'],
  props: {
    name: String,
    label: { type: String, default: '' },
    mandatory: { type: Boolean, default: false },
    placeholder: String,
    valueList: { type: Array, default: () => [] },
    create: { type: Boolean, default: false },
    addTextlabel: { type: String, default: '' },
    autofocus: Boolean,
    scope: { type: String, default: undefined },
    rule: {
      type: [String, Object],
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentTag: '',
    };
  },
  computed: {
    addTextLabelOrDefault() {
      return this.addTextLabel ? this.addTextLabel : `Add ${this.label}`;
    },
    computedRules() {
      const ruleCopy = _.cloneDeep(this.rule);
      if (_.isObject(ruleCopy) && this.valueList.length > 0) {
        ruleCopy.required = false;
      }
      return Object.assign(
        {},
        {
          requiredTag: this.rule && this.rule.required ? this.valueList : false,
        },
        ruleCopy,
      );
    },
  },
  mounted() {
    if (this.autofocus) {
      this.setFocus();
    }
  },
  methods: {
    addTag() {
      if (this.currentTag !== '') {
        this.$emit('add', this.currentTag);
        this.currentTag = '';
      }
    },
    removeTag(idx) {
      this.$emit('remove', idx);
      this.$refs.newTag.focus();
    },
    setFocus() {
      this.$refs.newTag.focus();
    },
  },
};
</script>
