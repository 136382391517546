import gql from 'graphql-tag';

const quickSearch = gql`
  query search($query: String!) {
    quickSearch(query: $query) {
      hits
      performers {
        id
        type
        first_name
        last_name
        pseudo_names
        nationality
        date_of_birth
        highlight_fields
        ipn
      }
      recordings {
        id
        lineup_locked
        vrdb2_id
        vrdb_type
        type
        name
        isrc
        version_title
        main_artist {
          id
          name
          type
        }
        genre
        duration_sec
        release_date
        recording_date
        recorded_in_country
        highlight_fields
        distribution_state {
          status
        }
      }
      mainartists {
        id
        type
        name
        alternate_names
        origin_country
        highlight_fields
      }
      albums {
        id
        type
        name
        catalog_number
        main_artist {
          id
          name
          type
        }
        release_date
        label
        highlight_fields
      }
      associates {
        id
        type
        name
        associate_type
        contact_info {
          address {
            country
          }
        }
        highlight_fields
      }
      societies {
        id
        type
        name
        contact_info {
          address {
            country
            city
          }
        }
        highlight_fields
      }
    }
  }
`;

const advancedSearchAll = gql`
  query search($searchRequest: AllSearchRequest) {
    advancedSearchAll(searchRequest: $searchRequest) {
      hits
      sortList
      performers {
        id
        first_name
        last_name
        pseudo_names
        profile
        main_instrument
        secondary_instruments
        date_of_birth
        date_of_death
        country_of_residence
        nationality
        city
        tags
        collective
      }
      recordings {
        id
        lineup_locked
        type
        name
        version_title
        main_artist {
          id
          name
          type
        }
        genre
        release_date
        alternate_titles
        isrc
        tags
      }
      mainartists {
        id
        type
        name
        alternate_names
        origin_country
        genre
        profile_description
        active_period {
          to
          from
        }
        tags
      }
      albums {
        id
        type
        name
        produced_in_country
        release_date
        label
        main_artist {
          id
          name
          type
        }
        tags
      }
      associates {
        id
        type
        associate_type
        name
        org_ssn_no
        contact_info {
          address {
            country
            city
          }
        }
        tags
      }
      societies {
        id
        type
        name
        contact_info {
          address {
            country
            city
          }
        }
        tags
      }
    }
  }
`;

const advancedSearchMainArtists = gql`
  query search($searchRequest: MainArtistSearchRequest) {
    advancedSearchMainArtists(searchRequest: $searchRequest) {
      hits
      sortList
      mainartists {
        id
        name
        alternate_names
        origin_country
        genre
        profile_description
        active_period {
          to
          from
        }
        performers {
          id
        }
        sami_members
        tags
      }
    }
  }
`;

const advancedSearchRecordings = gql`
  query search($searchRequest: RecordingSearchRequest) {
    advancedSearchRecordings(searchRequest: $searchRequest) {
      hits
      sortList
      recordings {
        id
        name
        version_title
        alternate_titles
        main_artist {
          name
          id
          type
        }
        recording_date
        release_date
        recorded_in_country
        produced_in_country
        duration_sec
        vrdb2_id
        vrdb_type
        composer
        writer
        arranger
        isrc
        label
        lineup {
          id
        }
        lineup_locked
        sami_members
        distribution_state {
          status
          legacy_unknown_performers
        }
        tags
        local_codes {
          code
          value
        }
      }
    }
  }
`;

const advancedSearchPerformers = gql`
  query search($searchRequest: PerformerSearchRequest) {
    advancedSearchPerformers(searchRequest: $searchRequest) {
      hits
      sortList
      performers {
        id
        first_name
        last_name
        protected_identity
        pseudo_names
        profile
        main_instrument
        secondary_instruments
        date_of_birth
        country_of_residence
        nationality
        ipn
        main_artists {
          id
          name
        }
        mandates {
          society_code
          mandate_type
          start_date
          end_date
        }
        tags
        collective
      }
    }
  }
`;

const advancedSearchAlbums = gql`
  query search($searchRequest: AlbumSearchRequest) {
    advancedSearchAlbums(searchRequest: $searchRequest) {
      hits
      sortList
      albums {
        id
        last_modified
        name
        catalog_number
        label
        release_date
        main_artist {
          name
          id
          type
        }
        tracks {
          id
        }
        tags
      }
    }
  }
`;

const advancedSearchAssociates = gql`
  query search($searchRequest: AssociateSearchRequest) {
    advancedSearchAssociates(searchRequest: $searchRequest) {
      hits
      sortList
      associates {
        id
        associate_type
        name
        org_ssn_no
        contact_info {
          address {
            country
          }
        }
        tags
      }
    }
  }
`;

const advancedSearchSocieties = gql`
  query search($searchRequest: SocietySearchRequest) {
    advancedSearchSocieties(searchRequest: $searchRequest) {
      hits
      societies {
        id
        name
        contact_info {
          emails
          website
          address {
            country
            city
          }
        }
        has_active_agreements
        tags
      }
    }
  }
`;

const nameSearch = gql`
  query search($query: String!, $types: [String]) {
    nameSearch(query: $query, types: $types) {
      id
      name
      display_name
      alternate_names
      name_type
      relation_type
      nationality
      date_of_birth
      has_payment_info
      membership_info {
        ipn
        main_instrument
        main_artists
        mandates {
          society_code
          mandate_type
          start_date
          end_date
        }
      }
    }
  }
`;

const getAllTags = gql`
  query getAllTags {
    getAllTags {
      name
      count
    }
  }
`;

const fieldSearch = gql`
  query search($field: String!, $value: String!, $index: String) {
    fieldSearch(field: $field, value: $value, index: $index) {
      type
      id
      name
      first_name
      last_name
      pseudo_names
      display_name
      ipn
      main_artist {
        id
        name
        type
      }
      nationality
      date_of_birth
      origin_country
    }
  }
`;

export default {
  quickSearch,
  advancedSearchAll,
  advancedSearchRecordings,
  advancedSearchPerformers,
  advancedSearchMainArtists,
  advancedSearchAlbums,
  advancedSearchAssociates,
  advancedSearchSocieties,
  nameSearch,
  getAllTags,
  fieldSearch,
};
