export const ErrorType = {
  FILE_EXIST: 'fileExistError',
  NETWORK: 'networkError',
  SERVER: 'serverError',
  GRAPHQL: 'graphQLError',
  ILLEGAL_ARGUMENT: 'illegalArgumentError',
  MINIMUM_CHARACTER: 'MIN_CHAR',
  PROCESS_FINISHED: 'processFinished',
};

export function createStarNotificationRootState() {
  return {
    errors: [],
    modal: {
      show: false,
      message: '',
      submitLabel: '',
      confirmPromise: null,
    },
    loading: {
      content: false,
    },
  };
}

export function createError(type, message, cause, errorData, timestamp = new Date().getTime()) {
  return {
    ...errorData,
    type,
    message,
    cause,
    timestamp,
  };
}
