import RefundOverview from './refund-overview.vue';
import Refund from './refund.vue';

export default [
  {
    path: '/refunds',
    name: 'refunds',
    component: RefundOverview,
    meta: {
      title: 'Refunds',
    },
  },
  {
    path: '/refunds/:refundId',
    name: 'refund',
    component: Refund,
    props: true,
    meta: {
      title: 'Refund',
    },
  },
];
