<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>
          End Of Year Statement for {{ associateName }} ({{
            $filters.translate(`common.${associateType.toLowerCase()}`)
          }})
        </h1>
      </div>
    </div>

    <end-of-year-statement-table :id="id" />
  </div>
</template>

<script>
import AssociateService from '../../services/associateService';
import EndOfYearStatementTable from './end-of-year-statement-table';

export default {
  name: 'ViewAssociateEndOfYearStatement',
  components: {
    EndOfYearStatementTable,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id.toUpperCase(),
      associateName: '',
      associateType: 'unknown',
    };
  },
  async created() {
    const associate = await AssociateService.getAssociate(this.id);
    if (associate.person_info && associate.person_info.first_name) {
      this.associateName = `${associate.person_info.first_name} ${associate.person_info.last_name}`;
    } else {
      this.associateName = associate.organisation_info.name;
    }
    this.associateType = associate.type;
  },
};
</script>
