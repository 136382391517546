const Testing = () => import(/* webpackChunkName: "testing" */ './Index.vue');
const Payment = () => import(/* webpackChunkName: "testing" */ './Payment/Index.vue');

export default [
  {
    path: '/testing',
    name: 'testing',
    component: Testing,
    meta: {
      title: 'Testing',
    },
    children: [
      {
        path: 'payment',
        name: 'testing-payment',
        component: Payment,
        meta: {
          title: 'Testing - Payment',
          environments: ['dev', 'test'],
        },
      },
    ],
  },
];
