import * as _ from 'lodash';
import Vue from 'vue';
import merge from '@/common/objectMerger';
import clone from '@/common/clone';

import { getConfigValue, PROPS } from './configService';
import commandService from './commandService';
import wirePrepper from './wirePrepper';

export default {
  prepareCommand(aggregate, command, payload, payloadMutator = null) {
    let payloadClone = clone(payload);

    command.stream_id = aggregate.id;
    command.process_id = aggregate.id;
    command.version = aggregate.version;
    command.timestamp = new Date();

    command.payload = merge(command.payload, payloadClone);

    wirePrepper(command.payload);

    if (payloadMutator) {
      payloadMutator(command.payload);
    }

    return command;
  },

  postCommands: async (transaction, commands, prepareCommands, transformErrors) => {
    try {
      const groupsMap = _.groupBy(commands, 'stream_id');
      const groupKeys = Object.keys(groupsMap);

      for (const groupKey of groupKeys) {
        const group = groupsMap[groupKey];
        for (let i = 1; i < group.length; i++) {
          const command = group[i];

          delete command.version;
        }
      }

      return await commandService.handleCommands(commands, prepareCommands, transformErrors);
    } catch (error) {
      error.title = transaction.errorTitle;
      throw error;
    }
  },

  postJob: async (payload) => {
    const url = `${getConfigValue(PROPS.PNR_URL)}/job`;

    await Vue.$http.post(url, payload || {});
  },
};
