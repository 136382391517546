export default {
  methods: {
    updateFile(file) {
      this.completedUploadFilename = undefined;
      this.uploadFile.handle = file;
    },
    onRemoveFile() {
      this.fileHandle = null;
    },
    resetFileUpload() {
      this.uploadFile = { handle: undefined, uploadProgress: 0 };
      this.error = false;
      if (this.$refs.fileUploadField) {
        this.$refs.fileUploadField.removeFile();
      }
    },
  },
};
