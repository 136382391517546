var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('spinner'):_vm._e(),_c('div',{staticClass:"row wrapper"},[_c('div',{staticClass:"col s12 text--right edit-link"},[_c('a',{class:{ disabled: _vm.createDisabled },on:{"click":function($event){return _vm.handleCreateNew()}}},[_c('i',{staticClass:"far fa-plus-square"}),_vm._v(" New distribution closure ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s2 float-right report__headers-refresh"},[_c('refresh-button',{attrs:{"is-loading":_vm.isFetchingHeaders},on:{"click":_vm.fetchData}})],1)]),_c('div',{staticClass:"row card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('table',[_c('thead',_vm._l((_vm.columns),function(column){return _c('th',{key:column.id,class:[column.styleClass]},[_vm._v(" "+_vm._s(column.name)+" ")])}),0),_c('tbody',_vm._l((_vm.distributionClosureHeaders),function(d){return _c('tr',{key:d.distributionClosureId},[_c('td',[(d.state !== _vm.hasError(d) || d.state !== _vm.isWorking(d))?_c('router-link',{attrs:{"to":{
                    path: '/distribution/distribution-closures/wizard',
                    query: { id: d.distributionClosureId },
                  }}},[_vm._v(" "+_vm._s(d.year)+" ")]):_c('span',[_vm._v(_vm._s(d.closureYear))])],1),_c('td',[_vm._v(_vm._s(_vm.$filters.formatDate(d.createdDate)))]),_c('td',[_vm._v(_vm._s(d.createdBy))]),_c('td',[(d.confirmedDate)?[_vm._v(" "+_vm._s(_vm.$filters.formatDate(d.confirmedDate))+" ")]:_c('span',{staticClass:"none"},[_vm._v("None")])],2),_c('td',[(d.confirmedBy)?[_vm._v(" "+_vm._s(d.confirmedBy)+" ")]:_c('span',{staticClass:"none"},[_vm._v("None")])],2),_c('td',{class:{
                  'text--green': _vm.isOpen(d),
                  'text--yellow': _vm.isWorking(d),
                  'text--red': _vm.hasError(d),
                }},[_vm._v(" "+_vm._s(_vm.$filters.formatDistributionClosureState(d.state))+" ")]),_c('td',{staticClass:"amount-row"},[_vm._v(" "+_vm._s(_vm.$filters.formatAmountCent(d.totalAmount, 'SEK'))+" ")]),_c('td',[(_vm.isOpen(d) || _vm.hasError(d))?_c('options',{attrs:{"options":['Delete']},on:{"delete":function($event){return _vm.handleDelete(d.distributionClosureId)}}}):_vm._e()],1)])}),0)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }