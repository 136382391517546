import { processType as ProcessType } from '@/domain/matchingDomain';
import AdministrateEndOfYearStatement from '@/components/end-of-year-statement/administrate-end-of-year-statement';
import AdministrateVotingRights from '@/components/voting-rights/administrate-voting-rights';
import AdvancedSearch from '@/components/search/advanced-search';
import AdvancedSearchAlbums from '@/components/search/advanced-search-albums';
import AdvancedSearchAssociates from '@/components/search/advanced-search-associates';
import AdvancedSearchEverything from '@/components/search/advanced-search-everything';
import AdvancedSearchMainArtists from '@/components/search/advanced-search-mainartists';
import AdvancedSearchPerformers from '@/components/search/advanced-search-performers';
import AdvancedSearchRecordings from '@/components/search/advanced-search-recordings';
import AdvancedSearchSocieties from '@/components/search/advanced-search-societies';

import CreateAlbum from '@/components/album/create/create-album';
import CreateAssociate from '@/components/associate/create/create-associate';
import CreateDistributionArea from '@/components/distribution/distribution-area/create-distribution-area';
import CreateDistributionChannel from '@/components/distribution/channel/create-distribution-channel';
import CreateMainArtist from '@/components/mainartist/create/create-mainartist';
import ManualRefund from '@/components/ui/ledger/manual-refund';
import PaymentWizardMain from '@/components/payment/payment-wizard-main';
import ReleaseOfReservesWizardMain from '@/components/reserves/release-of-reserves-wizard-main';
import CreatePerformer from '@/components/performer/information/create/create-performer';
import CreateRecording from '@/components/recording/create/create-recording';
import CreateRevenue from '@/components/distribution/revenue/create-revenue';
import CreateSociety from '@/components/society/create/create-society';

import PageNotFound from '@/components/errors/page-not-found';
import ServerError from '@/components/errors/server-error';

import ViewAlbum from '@/components/album/view/view-album';
import ViewAlbumInformation from '@/components/album/view/view-album-information';
import ViewAssociate from '@/components/associate/view/view-associate';
import ViewAssociateInformation from '@/components/associate/view/view-associate-information';
import ViewDiscographyAlbums from '@/components/discography/view-discography-albums';
import ViewDiscographyMainArtist from '@/components/mainartist/discography/view-discography';
import ViewDiscographyPerformer from '@/components/performer/discography/view-discography';
import ViewDiscographyRecordings from '@/components/discography/view-discography-recordings';
import ViewAssociateLedger from '@/components/associate/view/view-ledger';
import ViewPerformerLedger from '@/components/performer/information/view/view-ledger';
import ViewSocietyPayments from '@/components/society/view/view-payments';
import ViewSocietyVRDBPlaylistLines from '@/components/society/view/playlist/vrdb/view-playlist-lines';
import ViewSocietySDEGPlaylistLines from '@/components/society/view/playlist/sdeg/view-playlist-lines';
import ViewSocietyPlaylistLinesAccepted from '@/components/society/view/playlist/view-playlist-accepted';
import ViewSocietyPlaylistLinesApplied from '@/components/society/view/playlist/view-playlist-applied';
import ViewSocietyPlaylistLinesRejected from '@/components/society/view/playlist/view-playlist-rejected';
import ViewSocietyPlaylistLinesSentRejections from '@/components/society/view/playlist/view-playlist-sent';
import ViewSocietyPlaylistLinesUnknown from '@/components/society/view/playlist/view-playlist-unknown';
import ViewSocietyPlaylistLinesToCheck from '@/components/society/view/playlist/view-playlist-to-check';
import ViewSdegPlayedRecordingsReport from '@/components/matching/sdeg/view-played-recordings-report';
import ViewLedgerPaymentHistory from '@/components/ui/ledger/view-payment-history';
import ViewPaymentDetails from '@/components/ui/ledger/view-payment-details';
import ViewAccountStatement from '@/components/ui/ledger/view-account-statement';
import ViewDistributionArea from '@/components/ui/ledger/view-distribution-area';
import ViewDistributionAreas from '@/components/distribution/distribution-area/view-distribution-areas';
import ViewDistributionChannels from '@/components/distribution/channel/view-distribution-channels';
import ViewDistributionCalculate from '@/components/distribution/distribute/distribute-year-calculate';
import ViewDistributionIssues from '@/components/distribution/distribute/distribute-year-issues';
import ViewDistributionSources from '@/components/distribution/source/view-distribution-sources';
import ViewDistributionYear from '@/components/distribution/distribute/view-distribution-year';
import ViewProtectionChanges from '@/components/distribution/protection-changes/view-protection-changes';
import ViewIncompleteRecordings from '@/components/distribution/view-incomplete-recordings';
import DistributeYear from '@/components/distribution/distribute/distribute-year';
import ViewRevenues from '@/components/distribution/revenue/view-revenues';
import ViewRevenue from '@/components/distribution/revenue/view-revenue';
import StatementMatchingTable from '@/components/distribution/revenue/statement-matching-table';
import ViewMainArtist from '@/components/mainartist/view/view-mainartist';
import ViewMainArtistInformation from '@/components/mainartist/view/view-mainartist-information';
import ViewMusicReportCandidateLines from '@/components/matching/view-music-report-candidate-lines';
import ViewMusicReportErrorLines from '@/components/matching/view-music-report-error-lines';
import ViewMusicReportLines from '@/components/matching/view-music-report-lines';
import ViewMusicReportMatchedLines from '@/components/matching/view-music-report-matched-lines';
import ViewMusicReportClaimedLines from '@/components/matching/view-music-report-claimed-lines';
import ViewMusicReportRestedLines from '@/components/matching/view-music-report-rested-lines';
import ViewMusicReportIgnoredLines from '@/components/matching/view-music-report-ignored-lines';
import ViewMusicReportsDeprecated from '@/components/matching/view-music-reports';
import ViewMediarc from '@/components/matching/mediarc/view-mediarc';
import ViewMediarcRequest from '@/components/matching/mediarc/view-mediarc-request';
import ViewMediarcMatching from '@/components/matching/mediarc/view-mediarc-matching';
import ViewMediarcReportLines from '@/components/matching/mediarc/view-mediarc-report-lines';
import ViewMediarcReportMatchedLines from '@/components/matching/mediarc/view-mediarc-report-matched-lines';
import ViewMediarcReportRestedLines from '@/components/matching/mediarc/view-mediarc-report-rested-lines';
import ViewMediarcReportCandidateLines from '@/components/matching/mediarc/view-mediarc-report-candidate-lines';
import ViewMediarcReportDoneLines from '@/components/matching/mediarc/view-mediarc-report-done-lines';
import ViewMediarcReportVersionTitles from '@/components/matching/mediarc/view-mediarc-report-version-titles';
import ViewPlayedRecordings from '@/components/sdeg-played-recordings/view-played-recordings';
import CreatePlayedRecordings from '@/components/sdeg-played-recordings/create-played-recordings';
import ViewMusicReportTransferredLines from '@/components/matching/view-music-report-transferred-lines';
import ViewPaymentHistory from '@/components/payment/view-payment-history';
import ViewReleaseOfReservesHistory from '@/components/reserves/view-release-of-reserves-history';
import ViewDistributionClosure from '@/components/distribution/distribution-closure/view-distribution-closure';
import ViewDistributionClosureHistory from '@/components/distribution/distribution-closure/view-distribution-closure-history';
import DistributionClosureWizardMain from '@/components/distribution/distribution-closure/distribution-closure-wizard-main';
import ViewPerformer from '@/components/performer/information/view/view-performer';
import ViewPerformerInformation from '@/components/performer/information/view/view-performer-information';
import ViewMembershipAgreement from '@/components/membership/view-send-agreements';
import ViewRecording from '@/components/recording/view/view-recording';
import ViewRecordingInformation from '@/components/recording/view/view-recording-information';
import ViewRecordingUsage from '@/components/recording/view/view-recording-usage';
import ViewDistributionHistory from '@/components/recording/view/view-distribution-history';
import ViewRecordingRemuneration from '@/components/recording/view/view-recording-remuneration';
import ViewSociety from '@/components/society/view/view-society';
import ViewSocietyInformation from '@/components/society/view/view-society-information';
import ViewSocietyRoleCodes from '@/components/society/view/role-codes';
import ViewSocietyMember from '@/components/society/view/member';
import ViewVrdbSocietiesOverview from '@/components/vrdb/view-societies-overview';
import ViewIpdMemberOverview from '@/components/international-exchange-membership/view-ipd-member-overview';
import ViewOtherMemberOverview from '@/components/international-exchange-membership/view-other-member-overview';
import ViewVRDBDashboard from '@/components/vrdb/view-vrdb-dashboard';
import ViewSocietySdegOverview from '@/components/sdeg/view-society-sdeg-overview';
import ViewSdegOverview from '@/components/sdeg/view-sdeg-overview';
import ViewReportHeaders from '@/components/sdeg/view-report-headers';
import ProcessOverview from '@/components/process/process-overview';
import CronjobOverview from '@/components/cronjob/cronjob-overview';
import Process from '@/components/process/process';
import ViewPerformerEndOfYearStatement from '@/components/end-of-year-statement/view-performer-end-of-year-statement';
import ViewAssociateEndOfYearStatement from '@/components/end-of-year-statement/view-associate-end-of-year-statement';
import RedistributionOverview from '@/components/distribution/redistribution/redistribution-overview';
import ViewDuplicates from '@/components/merge/view-duplicates';
import ViewDuplicatesList from '@/components/merge/view-duplicates-list';
import ViewMergePreview from '@/components/merge/view-merge-preview';
import PreviewJson from '@/components/preview/preview-json';
import ViewPerformerMembershipApplications from '@/components/performer-membership-application/view-applications';
import ViewDeceasedPerformerReports from '@/components/deceased-performer-report/view-deceased-performer-reports';
import ViewIpdDownloadFile from '@/components/international-exchange-membership/view-ipd-download-file';
import ViewIpdDownloadRightHolders from '@/components/international-exchange-membership/view-ipd-download-right-holders';
import ViewHome from '@/components/home/view-home';
import ViewWorklists from '@/components/home/worklist/view-worklists';
import ViewDashboard from '@/components/home/dashboard/view-dashboard';
import ViewCountries from '@/components/countries/view-countries';
import ViewUsTaxForms from '@/components/membership/view-us-tax-forms';
import ViewProjectorsProgress from '@/components/projectors/view-projectors-progress';
import ViewRemuneration from '@/components/ui/ledger/view-remuneration';
import ViewTrackBreakdown from '@/components/performer/information/view/view-track-breakdown';
import ViewJobs from '@/components/job/view-jobs';
import PaymentDetailsOverview from '@/components/ui/ledger/payment-details-overview';
import PaymentTrackBreakdown from '@/components/ui/ledger/track-breakdown';

import { ReportType } from '@/domain/matchingDomain';

import { routes as authRoutes } from './auth';
import testingRoutes from '@/testing/routes';
import financeRoutes from '@/components/finance/routes';
import refundRoutes from '@/components/refund/routes';
import transactionRoutes from '@/components/transaction/routes';

import ViewMusicReports from '@/components/music-reports/view-music-reports';
import MusicReports from '@/components/music-reports/music-reports';
import MusicReport from '@/components/music-reports/music-report';
import ReportLines from '@/components/music-reports/report-lines';

const routes = [
  {
    path: '/',
    name: 'homeView',
    component: ViewHome,
    redirect: { name: 'homeViewDashboard' },
    meta: {
      title: 'Star',
      name: 'Home',
    },
    children: [
      {
        path: '/dashboard',
        name: 'homeViewDashboard',
        component: ViewDashboard,
        meta: {
          title: 'Home - Dashboard',
          name: 'Dashboard',
        },
      },
      {
        path: '/statistics',
        name: 'homeViewStatistics',
        component: ViewDashboard,
        meta: {
          title: 'Home - Statistics',
          name: 'Statistics',
        },
      },
      {
        path: '/assigned-worklists',
        name: 'homeViewAssignedWorklists',
        component: ViewWorklists,
        meta: {
          title: 'Home - Assigned Worklists',
          name: 'Assigned Worklists',
        },
      },
      {
        path: '/personal-worklist',
        name: 'homeViewPersonalWorklist',
        component: ViewWorklists,
        meta: {
          title: 'Home - Personal Worklist',
          name: 'Personal Worklist',
        },
      },
      {
        path: '/all-worklists',
        name: 'homeViewAllWorklists',
        component: ViewWorklists,
        meta: {
          title: 'Home - All Worklists',
          name: 'All Worklists',
        },
      },
    ],
  },

  // Search
  {
    path: '/search/:query',
    component: AdvancedSearch,
    children: [
      {
        path: '/search/everything/:query',
        name: 'advancedSearchEverythingQueryView',
        component: AdvancedSearchEverything,
        props: true,
        meta: {
          title: 'Advanced Search - "{query}"',
          name: 'Advanced Search',
        },
      },
      {
        path: '/search/performers/:query',
        name: 'advancedSearchPerformersQueryView',
        component: AdvancedSearchPerformers,
        props: true,
        meta: {
          title: 'Advanced Search Performers - "{query}"',
          name: 'Advanced Search Performers',
        },
      },
      {
        path: '/search/recordings/:query',
        name: 'advancedSearchRecordingsQueryView',
        component: AdvancedSearchRecordings,
        props: true,
        meta: {
          title: 'Advanced Search Recordings - "{query}"',
          name: 'Advanced Search Recordings',
        },
      },
      {
        path: '/search/mainartists/:query',
        name: 'advancedSearchMainArtistsQueryView',
        component: AdvancedSearchMainArtists,
        props: true,
        meta: {
          title: 'Advanced Search Main Artists - "{query}"',
          name: 'Advanced Search Main Artists',
        },
      },
      {
        path: '/search/albums/:query',
        name: 'advancedSearchAlbumsQueryView',
        component: AdvancedSearchAlbums,
        props: true,
        meta: {
          title: 'Advanced Search Albums - "{query}"',
          name: 'Advanced Search Albums',
        },
      },
      {
        path: '/search/associates/:query',
        name: 'advancedSearchAssociatesQueryView',
        component: AdvancedSearchAssociates,
        props: true,
        meta: {
          title: 'Advanced Search Associates - "{query}"',
          name: 'Advanced Search Associates',
        },
      },
      {
        path: '/search/societies/:query',
        name: 'advancedSearchSocietiesQueryView',
        component: AdvancedSearchSocieties,
        props: true,
        meta: {
          title: 'Advanced Search Societies - "{query}"',
          name: 'Advanced Search Societies',
        },
      },
      {
        path: '/search/everything',
        name: 'advancedSearchEverythingView',
        component: AdvancedSearchEverything,
        meta: {
          title: 'Advanced Search',
          name: 'Advanced Search',
        },
      },
      {
        path: '/search/performers',
        name: 'advancedSearchPerformersView',
        component: AdvancedSearchPerformers,
        props: true,
        meta: {
          title: 'Advanced Search Performers',
          name: 'Advanced Search Performers',
        },
      },
      {
        path: '/search/recordings',
        name: 'advancedSearchRecordingsView',
        component: AdvancedSearchRecordings,
        props: true,
        meta: {
          title: 'Advanced Search Recordings',
          name: 'Advanced Search Recordings',
        },
      },
      {
        path: '/search/mainartists',
        name: 'advancedSearchMainArtistsView',
        component: AdvancedSearchMainArtists,
        props: true,
        meta: {
          title: 'Advanced Search Main Artists',
          name: 'Advanced Search Main Artists',
        },
      },
      {
        path: '/search/albums',
        name: 'advancedSearchAlbumsView',
        component: AdvancedSearchAlbums,
        props: true,
        meta: {
          title: 'Advanced Search Albums',
          name: 'Advanced Search albums',
        },
      },
      {
        path: '/search/associates',
        name: 'advancedSearchAssociatesView',
        component: AdvancedSearchAssociates,
        props: true,
        meta: {
          title: 'Advanced Search Associates',
          name: 'Advanced Search Associates',
        },
      },
      {
        path: '/search/societies',
        name: 'advancedSearchSocietiesView',
        component: AdvancedSearchSocieties,
        props: true,
        meta: {
          title: 'Advanced Search Societies',
          name: 'Advanced Search Societies',
        },
      },
    ],
  },

  // Create / view entities
  {
    path: '/performers/create',
    name: 'performerCreate',
    component: CreatePerformer,
    meta: {
      title: 'Create Performer',
    },
  },
  {
    path: '/performers/:id',
    component: ViewPerformer,
    name: 'performerView',
    meta: {
      title: 'Performer',
      name: 'Performer',
    },
    redirect: { name: 'performerInformation' },
    children: [
      {
        path: '/performers/:id/information',
        name: 'performerInformation',
        component: ViewPerformerInformation,
        props: true,
        meta: {
          title: 'Performer - Information',
          name: 'Information',
        },
      },
      {
        path: '/performers/:id/information/:version',
        name: 'performerInformationVersion',
        component: ViewPerformerInformation,
        props: true,
        meta: {
          title: 'Performer - Information',
          name: 'Information',
        },
      },
      {
        path: '/performers/:id/discography',
        component: ViewDiscographyPerformer,
        name: 'discographyPerformerRecordingsLanding',
        meta: {
          title: 'Performer - Discography',
          name: 'Discography',
        },
        redirect: { name: 'discographyPerformerRecordings' },
        children: [
          {
            path: '/performers/:id/discography/recordings',
            name: 'discographyPerformerRecordings',
            component: ViewDiscographyRecordings,
            props: true,
            meta: {
              title: 'Performer - Discography',
              name: 'Discography',
            },
          },
          {
            path: '/performers/:id/discography/albums',
            name: 'discographyPerformerAlbums',
            component: ViewDiscographyAlbums,
            props: true,
            meta: {
              title: 'Performer - Discography',
              name: 'Discography',
            },
          },
        ],
      },
      {
        path: '/performers/:id/ledger',
        component: ViewPerformerLedger,
        props: true,
        name: 'viewPerformerLedger',
        meta: {
          title: 'Performer - Remuneration',
          name: 'Ledger',
        },
        redirect: { name: 'viewPerformerRemunerationHistory' },
        children: [
          {
            path: '/performers/:id/ledger/remuneration-balance',
            name: 'viewPerformerRemunerationBalance',
            component: ViewRemuneration,
            props: true,
            meta: {
              title: 'Performer - Remuneration Balance',
              name: 'Remuneration Balance',
            },
          },
          {
            path: '/performers/:id/ledger/payment-history',
            name: 'viewPerformerPaymentHistory',
            component: ViewLedgerPaymentHistory,
            props: true,
            meta: {
              title: 'Performer - Payment History',
              name: 'Payment History',
            },
          },
          {
            path: '/performers/:id/ledger/transaction-history',
            name: 'viewPerformerTransactionHistory',
            component: ViewAccountStatement,
            props: true,
            meta: {
              title: 'Performer - Transaction History',
              name: 'Transaction History',
            },
          },
          {
            path: '/performers/:id/ledger/remuneration-history',
            name: 'viewPerformerRemunerationHistory',
            component: ViewTrackBreakdown,
            props: true,
            meta: {
              title: 'Performer - Remuneration History',
              name: 'Remuneration History',
            },
          },
        ],
      },
      {
        path: '/performers/:id/ledger/distribution-area/:year',
        name: 'viewPerformerDistributionArea',
        component: ViewDistributionArea,
        props: true,
        meta: {
          title: 'Performer - Distribution Area',
          name: 'Distribution Area',
        },
      },
      {
        path: '/performers/:id/ledger/payment-details/:paymentId',
        name: 'viewPerformerPaymentDetails',
        redirect: { name: 'performerOverview' },
        component: ViewPaymentDetails,
        props: true,
        meta: {
          title: 'Performer - Payment Details',
          name: 'Payment Details',
        },
        children: [
          {
            path: '',
            name: 'performerOverview',
            component: PaymentDetailsOverview,
            props: true,
            meta: {
              nodeType: 'performer',
            },
          },
          {
            path: 'track-breakdown',
            name: 'performerTrackBreakdown',
            component: PaymentTrackBreakdown,
            props: true,
            meta: {
              nodeType: 'performer',
            },
          },
        ],
      },
      {
        path: '/performers/:id/ledger/manual-refund',
        name: 'manualRefund',
        component: ManualRefund,
        props: true,
        meta: {
          title: 'Performer - Manual Refund',
          name: 'Manual Refund',
        },
      },
      {
        path: '/performers/:id/end-of-year-statement',
        name: 'viewPerformerEndOfYearStatement',
        component: ViewPerformerEndOfYearStatement,
        props: true,
        meta: {
          title: 'Performer - End of Year Statement',
          name: 'End of Year Statement',
        },
      },
    ],
  },
  {
    path: '/recordings/create',
    name: 'recordingCreate',
    component: CreateRecording,
    meta: {
      title: 'Create Recording',
    },
  },
  {
    path: '/recordings/:id',
    component: ViewRecording,
    name: 'recordingView',
    meta: {
      title: 'Recording',
      name: 'Recording',
    },
    redirect: { name: 'recordingInformation' },
    children: [
      {
        path: '/recording/:id/information',
        name: 'recordingInformation',
        component: ViewRecordingInformation,
        props: true,
        meta: {
          title: 'Recording - Information',
          name: 'Information',
        },
      },
      {
        path: '/recording/:id/information/:version',
        name: 'recordingInformationVersion',
        component: ViewRecordingInformation,
        props: true,
        meta: {
          title: 'Recording - Information',
          name: 'Information',
        },
      },
      {
        path: '/recordings/:id/usage',
        name: 'recordingUsage',
        component: ViewRecordingUsage,
        props: true,
        meta: {
          title: 'Recording - Usage',
          name: 'Usage',
        },
      },
      {
        path: '/recordings/:id/remuneration',
        name: 'recordingRemuneration',
        component: ViewRecordingRemuneration,
        props: true,
        meta: {
          title: 'Recording - Remuneration',
          name: 'Remuneration',
        },
      },
      {
        path: '/recordings/:id/distribution-history',
        name: 'distributionHistory',
        component: ViewDistributionHistory,
        props: true,
        meta: {
          title: 'Recording - Distribution History',
          name: 'Distribution',
        },
      },
    ],
  },
  {
    path: '/mainartists/create',
    name: 'mainArtistCreate',
    component: CreateMainArtist,
    meta: {
      title: 'Create Main Artist',
    },
  },
  {
    path: '/mainartists/:id',
    component: ViewMainArtist,
    name: 'mainArtistView',
    meta: {
      title: 'Main Artist',
      name: 'Main artist',
    },
    redirect: { name: 'mainArtistInformation' },
    children: [
      {
        path: '/mainartists/:id/information',
        name: 'mainArtistInformation',
        component: ViewMainArtistInformation,
        props: true,
        meta: {
          title: 'Main Artist - Information',
          name: 'Information',
        },
      },
      {
        path: '/mainartists/:id/information/:version',
        name: 'mainArtistInformationVersion',
        component: ViewMainArtistInformation,
        props: true,
        meta: {
          title: 'Main Artist - Information',
          name: 'Information',
        },
      },
      {
        path: '/mainartists/:id/discography',
        component: ViewDiscographyMainArtist,
        name: 'discographyMainArtistRecordingsLanding',
        redirect: { name: 'discographyMainArtistRecordings' },
        meta: {
          title: 'Main Artist - Discography',
          name: 'Discography',
        },
        children: [
          {
            path: '/mainartists/:id/discography/recordings',
            name: 'discographyMainArtistRecordings',
            component: ViewDiscographyRecordings,
            props: true,
            meta: {
              title: 'Main Artist - Discography',
              name: 'Discography',
            },
          },
          {
            path: '/mainartists/:id/discography/albums',
            name: 'discographyMainArtistAlbums',
            component: ViewDiscographyAlbums,
            props: true,
            meta: {
              title: 'Main Artist - Discography',
              name: 'Discography',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/albums/create',
    name: 'albumCreate',
    component: CreateAlbum,
    meta: {
      title: 'Create Album',
    },
  },
  {
    path: '/albums/:id',
    component: ViewAlbum,
    name: 'albumView',
    meta: {
      title: 'Album',
      name: 'Album',
    },
    redirect: { name: 'albumInformation' },
    children: [
      {
        path: '/albums/:id/information',
        name: 'albumInformation',
        component: ViewAlbumInformation,
        props: true,
        meta: {
          title: 'Album - Information',
          name: 'Information',
        },
      },
      {
        path: '/albums/:id/information/:version',
        name: 'albumInformationVersion',
        component: ViewAlbumInformation,
        props: true,
        meta: {
          title: 'Album - Information',
          name: 'Information',
        },
      },
    ],
  },
  {
    path: '/associates/create',
    name: 'associateCreate',
    component: CreateAssociate,
    meta: {
      title: 'Create Associate',
    },
  },
  {
    path: '/associates/:id',
    name: 'associateView',
    component: ViewAssociate,
    meta: {
      title: 'Associate',
      name: 'Associate',
    },
    redirect: { name: 'associateInformation' },
    children: [
      {
        path: '/associates/:id/information',
        name: 'associateInformation',
        component: ViewAssociateInformation,
        props: true,
        meta: {
          title: 'Associate - Information',
          name: 'Information',
        },
      },
      {
        path: '/associates/:id/information/:version',
        name: 'associateInformationVersion',
        component: ViewAssociateInformation,
        props: true,
        meta: {
          title: 'Associate - Information',
          name: 'Information',
        },
      },
      {
        path: '/associates/:id/ledger',
        component: ViewAssociateLedger,
        name: 'viewAssociateLedger',
        meta: {
          title: 'Associate - Payment History',
          name: 'Ledger',
        },
        redirect: { name: 'viewAssociatePaymentHistory' },
        children: [
          {
            path: '/associates/:id/ledger/payment-history',
            name: 'viewAssociatePaymentHistory',
            component: ViewLedgerPaymentHistory,
            props: true,
            meta: {
              title: 'Associate - Payment History',
              name: 'Payment History',
            },
          },
          {
            path: '/associates/:id/ledger/transaction-history',
            name: 'viewAssociateTransactionHistory',
            component: ViewAccountStatement,
            props: true,
            meta: {
              title: 'Associate - Transaction History',
              name: 'Transaction History',
            },
          },
        ],
      },
      {
        path: '/associates/:id/ledger/distribution-area/:year',
        name: 'viewAssociateDistributionArea',
        component: ViewDistributionArea,
        props: true,
        meta: {
          title: 'Associate - Distribution Area',
          name: 'Distribution Area',
        },
      },
      {
        path: '/associates/:id/ledger/payment-details/:paymentId',
        name: 'viewAssociatePaymentDetails',
        redirect: { name: 'associateOverview' },
        component: ViewPaymentDetails,
        props: true,
        meta: {
          title: 'Associate - Payment Details',
          name: 'Payment Details',
          nodeType: 'associate',
        },
        children: [
          {
            path: '',
            name: 'associateOverview',
            component: PaymentDetailsOverview,
            props: true,
            meta: {
              nodeType: 'associate',
            },
          },
          {
            path: 'track-breakdown',
            name: 'associateTrackBreakdown',
            component: PaymentTrackBreakdown,
            props: true,
            meta: {
              nodeType: 'associate',
            },
          },
        ],
      },
      {
        path: '/associates/:id/end-of-year-statement',
        name: 'viewAssociateEndOfYearStatement',
        component: ViewAssociateEndOfYearStatement,
        props: true,
        meta: {
          title: 'Associates - End of Year Statement',
          name: 'End of Year Statement',
        },
      },
    ],
  },
  {
    path: '/societies/create',
    name: 'societyCreate',
    component: CreateSociety,
    meta: {
      title: 'Create Society',
    },
  },
  {
    path: '/societies/:id',
    name: 'societyView',
    component: ViewSociety,
    meta: {
      title: 'Society',
      name: 'Society',
    },
    redirect: { name: 'societyInformation' },
    children: [
      {
        path: '/societies/:id/information',
        name: 'societyInformation',
        component: ViewSocietyInformation,
        props: true,
        meta: {
          title: 'Society - Information',
          name: 'Information',
        },
      },
      {
        path: '/societies/:id/information/:version',
        name: 'societyInformationVersion',
        component: ViewSocietyInformation,
        props: true,
        meta: {
          title: 'Society - Information',
          name: 'Information',
        },
      },
      {
        path: '/societies/:id/role-codes',
        name: 'societyRoleCodesView',
        component: ViewSocietyRoleCodes,
        props: true,
        meta: {
          title: 'Society - Role Codes',
          name: 'Role Codes',
        },
      },
      {
        path: '/societies/:id/payments',
        component: ViewSocietyPayments,
        name: 'viewSocietyPayments',
        props: true,
        meta: {
          title: 'Society - Payment History',
          name: 'Payments',
        },
      },
      {
        path: '/societies/:id/ledger/distribution-area/:year',
        name: 'viewSocietyDistributionArea',
        component: ViewDistributionArea,
        props: true,
        meta: {
          title: 'Society - Distribution Area',
          name: 'Distribution Area',
        },
      },
      {
        path: '/societies/:id/ledger/payment-details/:rowId',
        name: 'viewSocietyPaymentDetails',
        component: ViewPaymentDetails,
        props: true,
        meta: {
          title: 'Society - Payment Details',
          name: 'Payment Details',
        },
      },
      {
        path: '/societies/:id/sdeg',
        name: 'viewSocietySdeg',
        component: ViewSocietySdegOverview,
        props: { processType: ProcessType.SDEG },
        meta: {
          title: 'Society - SDEG',
          name: 'SDEG',
        },
        redirect: { name: 'sdegSocietyClaimsOverview' },
        children: [
          {
            path: '/societies/:id/sdeg/claims',
            name: 'sdegSocietyClaimsOverview',
            component: ViewReportHeaders,
            props: {
              reportType: ReportType.PLAYLIST_REPORT,
              processType: ProcessType.SDEG,
              enablePrioritization: true,
            },
            meta: {
              title: 'SDEG - Claims',
              name: 'SDEG',
            },
          },
          {
            path: '/societies/:id/sdeg/played-recordings',
            name: 'sdegSocietyPlayedRecordingsOverview',
            component: ViewReportHeaders,
            props: {
              reportType: ReportType.PLAYED_RECORDING_REPORT,
              processType: ProcessType.SDEG,
            },
            meta: {
              title: 'SDEG - Played Recordings',
              name: 'SDEG',
            },
          },
        ],
      },
      {
        path: '/societies/:id/sdeg/played-recordings/:reportId',
        name: 'viewSdegPlayedRecordingsReport',
        component: ViewSdegPlayedRecordingsReport,
        props: (route) => ({
          processType: ProcessType.SDEG,
          reportId: route.params.reportId,
        }),
        meta: {
          title: 'SDEG - Played Recordings Report',
          name: 'SDEG',
        },
        redirect: {
          name: 'viewSdegPlayedRecordingsReportMatched',
          namespace: 'sdeg',
        },
        children: [
          {
            path: '/societies/:id/sdeg/played-recordings/:reportId/matched',
            name: 'viewSdegPlayedRecordingsReportMatched',
            component: ViewMusicReportMatchedLines,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
            }),
            meta: {
              title: 'SDEG Played Recordings - Matched',
              namespace: 'sdeg',
            },
          },
          {
            path: '/societies/:id/sdeg/played-recordings/:reportId/matched-not-sami',
            name: 'viewSdegPlayedRecordingsReportMatchedNotSami',
            component: ViewMusicReportIgnoredLines,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
            }),
            meta: {
              title: 'SDEG Played Recordings - Matched not SAMI',
              namespace: 'sdeg',
            },
          },
          {
            path: '/societies/:id/sdeg/played-recordings/:reportId/candidates',
            component: ViewMusicReportCandidateLines,
            name: 'viewSdegPlayedRecordingsReportCandidates',
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
            }),
            meta: {
              title: 'SDEG Played Recordings - Candidates',
              namespace: 'sdeg',
            },
          },
          {
            path: '/societies/:id/sdeg/played-recordings/:reportId/new',
            name: 'viewSdegPlayedRecordingsReportNew',
            component: ViewMusicReportRestedLines,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
            }),
            meta: {
              title: 'SDEG Played Recordings - New',
              namespace: 'sdeg',
            },
          },
          {
            path: '/societies/:id/sdeg/played-recordings/:reportId/sent-claims',
            name: 'viewSdegPlayedRecordingsReportSentClaims',
            component: ViewMusicReportClaimedLines,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
            }),
            meta: {
              title: 'SDEG Played Recordings - Sent Claims',
              namespace: 'sdeg',
            },
          },
          {
            path: '/societies/:id/sdeg/played-recordings/:reportId/errors',
            name: 'viewSdegPlayedRecordingsReportErrors',
            component: ViewMusicReportErrorLines,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
            }),
            meta: {
              title: 'SDEG Played Recordings - Errors',
              namespace: 'sdeg',
            },
          },
        ],
      },
      {
        path: '/societies/:id/sdeg/claims/:reportId',
        name: 'viewSocietySDEGReport',
        component: ViewSocietySDEGPlaylistLines,
        props: (route) => ({
          processType: ProcessType.SDEG,
          reportId: route.params.reportId,
        }),
        meta: {
          title: 'SDEG - Claims Report',
          name: 'SDEG-CLAIMS',
        },
        redirect: {
          name: 'viewSocietySDEGPlaylistLinesToCheck',
        },
        children: [
          {
            path: '/societies/:id/sdeg/claims/:reportId/to_check',
            name: 'viewSocietySDEGPlaylistLinesToCheck',
            component: ViewSocietyPlaylistLinesToCheck,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
              worklistId: route.query.worklist_id,
            }),
            meta: {
              title: 'SDEG Claims to Check',
            },
          },
          {
            path: '/societies/:id/sdeg/claims/:reportId/accepted',
            name: 'viewSocietySDEGPlaylistLinesAccepted',
            component: ViewSocietyPlaylistLinesAccepted,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
              worklistId: route.query.worklist_id,
            }),
            meta: {
              title: 'Accepted SDEG Claims',
            },
          },
          {
            path: '/societies/:id/sdeg/claims/:reportId/rejected',
            name: 'viewSocietySDEGPlaylistLinesRejected',
            component: ViewSocietyPlaylistLinesRejected,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
              worklistId: route.query.worklist_id,
            }),
            meta: {
              title: 'Rejected SDEG Claims',
            },
          },
          {
            path: '/societies/:id/sdeg/claims/:reportId/applied',
            name: 'viewSocietySDEGPlaylistLinesApplied',
            component: ViewSocietyPlaylistLinesApplied,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
              worklistId: route.query.worklist_id,
            }),
            meta: {
              title: 'Applied SDEG Claims',
            },
          },
          {
            path: '/societies/:id/sdeg/claims/:reportId/sent_rejections',
            name: 'viewSocietySDEGPlaylistLinesSentRejections',
            component: ViewSocietyPlaylistLinesSentRejections,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
              worklistId: route.query.worklist_id,
            }),
            meta: {
              title: 'SDEG Sent Rejections',
            },
          },
          {
            path: '/societies/:id/sdeg/claims/:reportId/unknown',
            name: 'viewSocietySDEGPlaylistLinesUnknown',
            component: ViewSocietyPlaylistLinesUnknown,
            props: (route) => ({
              processType: ProcessType.SDEG,
              reportId: route.params.reportId,
            }),
            meta: {
              title: 'SDEG Errors',
            },
          },
        ],
      },
      {
        path: '/societies/:id/vrdb',
        name: 'viewSocietyVRDB',
        component: ViewSocietyVRDBPlaylistLines,
        props: { processType: ProcessType.VRDB2 },
        meta: {
          title: 'Society - VRDB',
          name: 'VRDB',
        },
        redirect: {
          name: 'viewSocietyVRDBPlaylistLinesToCheck',
        },
        children: [
          {
            path: '/societies/:id/vrdb/to_check',
            name: 'viewSocietyVRDBPlaylistLinesToCheck',
            component: ViewSocietyPlaylistLinesToCheck,
            props: { processType: ProcessType.VRDB2 },
            meta: {
              title: 'VRDB Claims to Check',
            },
          },
          {
            path: '/societies/:id/vrdb/accepted',
            name: 'viewSocietyVRDBPlaylistLinesAccepted',
            component: ViewSocietyPlaylistLinesAccepted,
            props: { processType: ProcessType.VRDB2 },
            meta: {
              title: 'Accepted VRDB Claims',
            },
          },
          {
            path: '/societies/:id/vrdb/rejected',
            name: 'viewSocietyVRDBPlaylistLinesRejected',
            component: ViewSocietyPlaylistLinesRejected,
            props: { processType: ProcessType.VRDB2 },
            meta: {
              title: 'Rejected VRDB Claims',
            },
          },
          {
            path: '/societies/:id/vrdb/applied',
            name: 'viewSocietyVRDBPlaylistLinesApplied',
            component: ViewSocietyPlaylistLinesApplied,
            props: { processType: ProcessType.VRDB2 },
            meta: {
              title: 'Applied VRDB Claims',
            },
          },
          {
            path: '/societies/:id/vrdb/sent_rejections',
            name: 'viewSocietyVRDBPlaylistLinesSentRejections',
            component: ViewSocietyPlaylistLinesSentRejections,
            props: { processType: ProcessType.VRDB2 },
            meta: {
              title: 'VRDB Sent Rejections',
            },
          },
          {
            path: '/societies/:id/vrdb/unknown',
            name: 'viewSocietyVRDBPlaylistLinesUnknown',
            component: ViewSocietyPlaylistLinesUnknown,
            props: { processType: ProcessType.VRDB2 },
            meta: {
              title: 'VRDB Errors',
            },
          },
        ],
      },
      {
        path: '/societies/:id/member',
        name: 'viewSocietyMember',
        component: ViewSocietyMember,
        props: true,
        meta: {
          title: 'Society - Member Files',
          name: 'Member Files',
        },
      },
    ],
  },
  // Music reports
  {
    path: '/music-reports',
    name: 'viewMusicReports',
    component: ViewMusicReports,
    props: true,
    meta: {
      title: 'Music Reports',
    },
    redirect: { name: 'musicReports', params: { type: 'playtime' } },
    children: [
      {
        path: ':type',
        name: 'musicReports',
        component: MusicReports,
        props: true,
        meta: {
          title: 'Music Reports',
        },
      },
      {
        path: ':type/:id',
        name: 'musicReport',
        component: MusicReport,
        props: true,
        meta: {
          title: 'Music Report',
        },
        redirect: (to) => ({ name: 'reportLines', params: { ...to.params, state: 'matched' } }),
        children: [
          {
            path: ':state',
            name: 'reportLines',
            component: ReportLines,
            props: true,
            meta: {
              title: 'Music Report',
            },
          },
        ],
      },
    ],
  },
  // Music reports DEPRECATED
  {
    path: '/music-reports-deprecated',
    name: 'musicReportsViewDeprecated',
    component: ViewMusicReportsDeprecated,
    props: true,
    meta: {
      title: 'Music Reports',
    },
  },
  {
    path: '/music-reports-deprecated/:reportId',
    component: ViewMusicReportLines,
    props: true,
    children: [
      {
        path: '/music-reports-deprecated/:reportId/matched',
        name: 'musicReportMatchedView',
        component: ViewMusicReportMatchedLines,
        props: true,
        meta: {
          title: 'Music Reports - Matched',
          namespace: 'matching',
        },
      },
      {
        path: '/music-reports-deprecated/:reportId/candidates',
        name: 'musicReportCandidatesView',
        component: ViewMusicReportCandidateLines,
        props: true,
        meta: {
          title: 'Music Reports - Candidates',
          namespace: 'matching',
        },
      },
      {
        path: '/music-reports-deprecated/:reportId/transferred',
        name: 'musicReportTransferredView',
        component: ViewMusicReportTransferredLines,
        props: true,
        meta: {
          title: 'Music Reports - Transferred',
          namespace: 'matching',
        },
      },
      {
        path: '/music-reports-deprecated/:reportId/rested',
        name: 'musicReportRestView',
        component: ViewMusicReportRestedLines,
        props: true,
        meta: {
          title: 'Music Reports - Rested',
          namespace: 'matching',
        },
      },
      {
        path: '/music-reports-deprecated/:reportId/errors',
        name: 'musicReportErrorView',
        component: ViewMusicReportErrorLines,
        props: true,
        meta: {
          title: 'Music Reports - Errors',
          namespace: 'matching',
        },
      },
    ],
  },

  // Mediarc
  {
    path: '/mediarc',
    name: 'mediarcView',
    component: ViewMediarc,
    redirect: { name: 'mediarcRequestView' },
    meta: {
      title: 'Mediarc',
    },
    children: [
      {
        path: '/mediarc/request',
        name: 'mediarcRequestView',
        component: ViewMediarcRequest,
        meta: {
          title: 'Mediarc - Request',
          namespace: 'mediarc',
        },
      },
      {
        path: '/mediarc/matching',
        name: 'mediarcMatchingView',
        component: ViewMediarcMatching,
        meta: {
          title: 'Mediarc - Import',
          namespace: 'mediarc',
        },
      },
    ],
  },
  {
    path: '/mediarc/:reportId',
    name: 'mediarcReportView',
    component: ViewMediarcReportLines,
    redirect: { name: 'mediarcReportMatchedView' },
    props: true,
    meta: {
      title: 'Mediarc Report',
    },
    children: [
      {
        path: '/mediarc/:reportId/matched',
        name: 'mediarcReportMatchedView',
        component: ViewMediarcReportMatchedLines,
        props: true,
        meta: {
          title: 'Mediarc Report - Matched',
          namespace: 'mediarc',
        },
      },
      {
        path: '/mediarc/:reportId/candidates',
        name: 'mediarcReportCandidateView',
        component: ViewMediarcReportCandidateLines,
        props: true,
        meta: {
          title: 'Mediarc Report - Candidates',
          namespace: 'mediarc',
        },
      },
      {
        path: '/mediarc/:reportId/rested',
        name: 'mediarcReportRestedView',
        component: ViewMediarcReportRestedLines,
        props: true,
        meta: {
          title: 'Mediarc Report - Rested',
          namespace: 'mediarc',
        },
      },
      {
        path: '/mediarc/:reportId/done',
        name: 'mediarcReportDoneView',
        component: ViewMediarcReportDoneLines,
        props: true,
        meta: {
          title: 'Mediarc Report - Done',
          namespace: 'mediarc',
        },
      },
      {
        path: '/mediarc/:reportId/version-titles',
        name: 'mediarcReportVersionTitlesView',
        component: ViewMediarcReportVersionTitles,
        props: true,
        meta: {
          title: 'Mediarc Report - Version Titles',
          namespace: 'mediarc',
        },
      },
    ],
  },

  // Distribution
  {
    path: '/distribution/distribution-area/create',
    name: 'distributionAreaCreate',
    component: CreateDistributionArea,
    props: true,
    meta: {
      title: 'Create Distribution Area',
    },
  },
  {
    path: '/distribution/distribution-area',
    name: 'distributionAreasView',
    component: ViewDistributionAreas,
    props: true,
    meta: {
      title: 'Distribution Areas',
    },
  },
  {
    path: '/distribution/distribution-area/:id',
    name: 'distributionAreaEdit',
    component: CreateDistributionArea,
    props: true,
    meta: {
      title: 'Create Distribution Area',
    },
  },
  {
    path: '/distribution/distribution-channel/create',
    name: 'distributionChannelCreate',
    component: CreateDistributionChannel,
    props: true,
    meta: {
      title: 'Create Channel',
    },
  },
  {
    path: '/distribution/distribution-channel',
    name: 'distributionChannelsView',
    component: ViewDistributionChannels,
    props: true,
    meta: {
      title: 'Channels',
    },
  },
  {
    path: '/distribution/distribute',
    name: 'distributionYearView',
    component: ViewDistributionYear,
    props: true,
    meta: {
      title: 'Distribution Areas',
    },
  },
  {
    path: '/distribution/distribute/:year',
    name: 'distributionYearQueryView',
    component: ViewDistributionYear,
    props: true,
    meta: {
      title: 'Distribution Year',
    },
  },
  {
    path: '/distribution/distribute/:year/:yearId',
    name: 'distributeYear',
    component: DistributeYear,
    props: true,
    meta: {
      title: 'Distribution Year',
    },
    children: [
      {
        path: '/distribution/distribute/:year/:yearId/calculate',
        name: 'distributionCalculateView',
        component: ViewDistributionCalculate,
        props: true,
        meta: {
          title: 'Calculate Distribution',
        },
      },
      {
        path: '/distribution/distribute/:year/:yearId/calculate/:distributionId',
        name: 'distributionCalculateIdView',
        component: ViewDistributionCalculate,
        props: true,
        meta: {
          title: 'Distribution Calculation',
        },
      },
      {
        path: '/distribution/distribute/:year/:yearId/issues',
        name: 'distributionIssuesView',
        component: ViewDistributionIssues,
        props: true,
        meta: {
          title: 'Distribution - Errors',
        },
      },
    ],
  },
  {
    path: '/distribution/sources',
    name: 'distributionSourcesView',
    component: ViewDistributionSources,
    meta: {
      title: 'Sources',
    },
  },
  {
    path: '/distribution/incomplete-recordings',
    name: 'incompleteRecordingsView',
    component: ViewIncompleteRecordings,
    meta: {
      title: 'Incomplete Recordings',
    },
  },
  {
    path: '/distribution/protection-changes',
    name: 'protectionChanges',
    component: ViewProtectionChanges,
    meta: {
      title: 'Protection Changes',
    },
  },
  {
    path: '/distribution/distribution-closures',
    name: 'distributionClosureLandingPage',
    component: ViewDistributionClosure,
    props: true,
    redirect: { name: 'distributionClosureHistory' },
    meta: {
      title: 'Distribution Closure',
      name: 'distributionClosure',
    },
    children: [
      {
        path: '/distribution/distribution-closures/history',
        name: 'distributionClosureHistory',
        component: ViewDistributionClosureHistory,
        props: true,
        meta: {
          title: 'Distribution Closure - History',
          name: 'distributionClosureHistory',
        },
      },
      {
        path: '/distribution/distribution-closures/wizard',
        name: 'distributionClosures',
        component: DistributionClosureWizardMain,
        props: true,
        meta: {
          title: 'Distribution Closures',
        },
      },
    ],
  },
  {
    path: '/distribution/revenues/create',
    name: 'createRevenue',
    component: CreateRevenue,
    meta: {
      title: 'Create Revenue',
    },
  },
  {
    path: '/distribution/revenues/domestic',
    name: 'revenuesViewDomestic',
    component: ViewRevenues,
    props: true,
    meta: {
      title: 'Revenues - Domestic',
    },
  },
  {
    path: '/distribution/revenues/international',
    name: 'revenuesViewInternational',
    component: ViewRevenues,
    props: true,
    meta: {
      title: 'Revenues - International',
    },
  },
  {
    path: '/distribution/revenues/:id',
    component: ViewRevenue,
    name: 'revenueView',
    meta: {
      title: 'Revenue',
      name: 'Revenue',
    },
    redirect: { name: 'statementToCheck' },
    children: [
      {
        path: '/distribution/revenues/:id/to-check',
        name: 'statementToCheck',
        component: StatementMatchingTable,
        props: true,
        meta: {
          title: 'Revenue',
          name: 'Revenue',
        },
      },
      {
        path: '/distribution/revenues/:id/disburse',
        name: 'statementDisburse',
        component: StatementMatchingTable,
        props: true,
        meta: {
          title: 'Revenue',
          name: 'Revenue',
        },
      },
      {
        path: '/distribution/revenues/:id/refund',
        name: 'statementRefund',
        component: StatementMatchingTable,
        props: true,
        meta: {
          title: 'Revenue',
          name: 'Revenue',
        },
      },
      {
        path: '/distribution/revenues/:id/done',
        name: 'statementDone',
        component: StatementMatchingTable,
        props: true,
        meta: {
          title: 'Revenue',
          name: 'Revenue',
        },
      },
    ],
  },
  {
    path: '/distribution/redistribution',
    name: 'redistribution',
    component: RedistributionOverview,
    props: true,
    meta: {
      title: 'Redistribution',
    },
  },
  // Payment
  {
    path: '/payments',
    name: 'paymentHistory',
    component: ViewPaymentHistory,
    props: true,
    meta: {
      title: 'Payment History',
    },
  },
  {
    path: '/payments/create',
    name: 'createPayment',
    component: PaymentWizardMain,
    props: true,
    meta: {
      title: 'Create Payment',
    },
  },
  {
    path: '/payments/:paymentId',
    name: 'payment',
    component: PaymentWizardMain,
    props: true,
    meta: {
      title: 'Payment',
    },
  },
  // Release of reserves
  {
    path: '/reserves/history',
    name: 'releaseOfReservesHistory',
    component: ViewReleaseOfReservesHistory,
    props: true,
    meta: {
      title: 'Reserve Release History',
    },
  },
  {
    path: '/reserves',
    name: 'newReleaseOfReserves',
    component: ReleaseOfReservesWizardMain,
    props: true,
    meta: {
      title: 'New Release of Reserves',
    },
  },
  {
    path: '/countries',
    name: 'countries',
    component: ViewCountries,
    meta: {
      title: 'Countries',
    },
  },
  // Integrations
  {
    path: '/integrations/vrdb/repertoire',
    name: 'vrdbSocietiesOverview',
    component: ViewVrdbSocietiesOverview,
    props: true,
    meta: {
      title: 'VRDB',
    },
  },
  {
    path: '/integrations/vrdb/dashboard',
    name: 'vrdbDashboard',
    component: ViewVRDBDashboard,
    props: true,
    meta: {
      title: 'VRDB Dashboard',
    },
  },
  {
    path: '/integrations/ipd',
    name: 'ipdMemberOverview',
    component: ViewIpdMemberOverview,
    props: true,
    meta: {
      title: 'IPD',
    },
  },
  {
    path: '/integrations/ipd/download',
    name: 'viewIpdDownloadFile',
    component: ViewIpdDownloadFile,
    props: true,
    meta: {
      title: 'IPD',
    },
  },
  {
    path: '/integrations/ipd/download/:downloadId',
    name: 'viewIpdDownloadRightHolders',
    component: ViewIpdDownloadRightHolders,
    props: true,
    meta: {
      title: 'IPD',
    },
  },
  {
    path: '/integrations/member',
    name: 'otherMemberOverview',
    component: ViewOtherMemberOverview,
    props: true,
    meta: {
      title: 'Member',
    },
  },
  {
    path: '/integrations/sdeg',
    name: 'sdegOverview',
    component: ViewSdegOverview,
    props: true,
    redirect: { name: 'sdegClaimsOverview' },
    meta: {
      title: 'SDEG - Played Recordings',
      name: 'SDEG',
    },
    children: [
      {
        path: '/integrations/sdeg/collecting',
        name: 'sdegClaimsOverview',
        component: ViewReportHeaders,
        props: {
          reportType: ReportType.PLAYLIST_REPORT,
          enablePrioritization: true,
        },
        meta: {
          title: 'SDEG - Claims',
          name: 'SDEG',
        },
      },
      {
        path: '/integrations/sdeg/played-recordings',
        name: 'sdegPlayedRecordingsOverview',
        component: ViewReportHeaders,
        props: {
          reportType: ReportType.PLAYED_RECORDING_REPORT,
        },
        meta: {
          title: 'SDEG - Played Recordings',
          name: 'SDEG',
        },
      },
    ],
  },
  {
    path: '/integrations/sdeg-played-recordings',
    name: 'sdegPlayedRecordings',
    component: ViewPlayedRecordings,
  },
  {
    path: '/integrations/create-played-recordings',
    name: 'createPlayedRecordings',
    component: CreatePlayedRecordings,
  },
  {
    path: '/membership/agreement',
    name: 'membershipAgreement',
    component: ViewMembershipAgreement,
    meta: {
      title: 'Handle Membership Agreements',
    },
  },
  {
    path: '/membership/deceased-performers',
    name: 'deceased-performers',
    component: ViewDeceasedPerformerReports,
    meta: {
      title: 'Deceased Performer Reports',
    },
  },
  {
    path: '/membership/applications',
    name: 'performerMembershipApplications',
    component: ViewPerformerMembershipApplications,
    meta: {
      title: 'Regional Membership Applications',
    },
  },
  {
    path: '/membership/us-tax-forms',
    name: 'membershipUsTaxForms',
    component: ViewUsTaxForms,
    meta: {
      title: 'US Tax Forms',
    },
  },
  {
    path: '/processes',
    name: 'processes',
    component: ProcessOverview,
    meta: {
      title: 'Processes',
    },
  },
  {
    path: '/processes/:id',
    name: 'Process',
    component: Process,
  },
  {
    path: '/projectors',
    name: 'projectors',
    component: ViewProjectorsProgress,
    meta: {
      title: 'Projectors progress',
    },
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: ViewJobs,
    meta: {
      title: 'Jobs',
    },
  },
  {
    path: '/cronjobs',
    name: 'cronjobs',
    component: CronjobOverview,
    meta: {
      title: 'Cronjobs',
    },
  },
  {
    path: '/preview/json',
    name: 'previewJson',
    component: PreviewJson,
    meta: {
      title: 'Preview',
    },
  },
  {
    path: '/end-of-year-statements',
    name: 'endOfYearStatements',
    component: AdministrateEndOfYearStatement,
    props: true,
    meta: {
      title: 'End of Year Statements',
    },
  },
  {
    path: '/voting-rights',
    name: 'votingRights',
    component: AdministrateVotingRights,
    props: true,
    meta: {
      title: 'Voting Rights',
    },
  },
  // Merge
  {
    path: '/merge/duplicates',
    name: 'mergeDuplicates',
    component: ViewDuplicates,
    meta: {
      title: 'Duplicates',
    },
    redirect: { name: 'mergeDuplicateMainArtists' },
    children: [
      {
        path: '/merge/duplicates/albums',
        name: 'mergeDuplicateAlbums',
        component: ViewDuplicatesList,
        meta: {
          title: 'Duplicates - Albums',
        },
      },
      {
        path: '/merge/duplicates/mainartists',
        name: 'mergeDuplicateMainArtists',
        component: ViewDuplicatesList,
        meta: {
          title: 'Duplicates - Main Artists',
        },
      },
    ],
  },
  {
    path: '/merge/preview/:mergeType/:id',
    name: 'mergePreview',
    component: ViewMergePreview,
    meta: {
      title: 'Merge Preview',
    },
  },
];

const errorRoutes = [
  {
    path: '/server-error',
    name: '500',
    component: ServerError,
    props: true,
    meta: {
      title: 'Server Error',
    },
  },
  {
    path: '/404',
    name: '404',
    component: PageNotFound,
    props: true,
    meta: {
      title: '404 Page not Found',
    },
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    props: true,
    meta: {
      title: '404 Page not Found',
    },
  },
];

export default [
  ...authRoutes,
  ...routes,
  ...testingRoutes,
  ...errorRoutes,
  ...financeRoutes,
  ...refundRoutes,
  ...transactionRoutes,
];
