var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12 l1 hide-on-med-and-down"},[_c('entity-tag')],1),_c('div',{staticClass:"col s12 l11 view-layout"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s8 l10 offset-s2 offset-l1"},[_c('bullet-progress',{attrs:{"progression-steps":_vm.progressionSteps,"current-progress":_vm.currentProgress}})],1)]),(_vm.loading)?[_c('spinner')]:_vm._e(),(!_vm.reserveRelease)?[_vm._m(0),_c('progress-release-of-reserves-create',{on:{"onSubmit":_vm.createNewRelease}})]:(_vm.isWorking)?[_vm._m(1),_vm._m(2)]:(_vm.isPendingConfirmation)?[_vm._m(3),_c('progress-verify-information',{attrs:{"reserve-release":_vm.reserveRelease},on:{"onSubmit":_vm.confirmRelease}})]:_vm._e(),(_vm.isFinished)?[_vm._m(4),_c('progress-release-of-reserves-confirmed',{attrs:{"reserve-release":_vm.reserveRelease}})]:(_vm.isFailed)?[_vm._m(5),_c('progress-release-of-reserves-confirmed',{attrs:{"reserve-release":_vm.reserveRelease}})]:_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v("New Release")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v("Processing Release")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row card"},[_c('div',{staticClass:"class col s12"},[_vm._v("The system is currently computing the release.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v("Verify Information")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v(" Release Completed "),_c('span',{staticClass:"text--green"},[_vm._v("✔︎")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('h1',[_vm._v(" Release Failed "),_c('span',{staticClass:"fas fa-check text--green"})])])])
}]

export { render, staticRenderFns }