/**
 * The Merge Service contains  merge related methods, such as CRUD.
 */

import Vue from 'vue';
import gql from '../domain/mergeGql';
import { query } from './apolloRequest';
import { getConfigValue, PROPS } from './configService';
import { flattenLocalCodes } from '../common/aggregateUtils';

const MERGE_URL = `${getConfigValue(PROPS.PNR_URL)}/merge`;

function mapTrack(track) {
  return {
    duration_sec: track.duration_sec,
    track_title: track.track_title,
    recording: track.recording,
  };
}

function mapPerformers(performer) {
  return {
    id: performer.id,
    instrument: performer.instrument,
    role: performer.role,
    active_period: performer.active_period,
    pseudo_name: performer.pseudo_name,
  };
}

function prepareAlbumPayload(aggregate) {
  return {
    type: 'album',
    album: {
      basic_info: aggregate.basic_info && flattenLocalCodes(aggregate.basic_info),
      tracks: aggregate.tracks && aggregate.tracks.map(mapTrack),
    },
  };
}

function prepareMainartistPayload(aggregate) {
  return {
    type: 'mainartist',
    mainartist: {
      basic_info: aggregate.basic_info && flattenLocalCodes(aggregate.basic_info),
      performers: aggregate.performers && aggregate.performers.map(mapPerformers),
    },
  };
}

const mergeGql = {
  mainartist: {
    matches: 'getMainartistMatches',
    mergePreview: 'getMainartistMergePreview',
  },
  album: {
    matches: 'getAlbumMatches',
    mergePreview: 'getAlbumMergePreview',
  },
};

const mergePayload = {
  mainartist: prepareMainartistPayload,
  album: prepareAlbumPayload,
};

export default {
  getDuplicates: async (request) =>
    query({ query: gql.getDuplicates, variables: { request } }, { pickProp: 'getDuplicates' }),
  getMatches: async (id, type) =>
    query(
      { query: gql[mergeGql[type].matches], variables: { id } },
      { pickProp: mergeGql[type].matches },
    ),
  getMergePreview: async (id, type) =>
    query(
      { query: gql[mergeGql[type].mergePreview], variables: { id } },
      { pickProp: mergeGql[type].mergePreview },
    ),
  updateDuplicateStreamId: async (fromStreamId, toStreamId) =>
    Vue.$http.patch(`${MERGE_URL}/duplicate/${fromStreamId}`, { id: toStreamId }),
  setDuplicateMatchedIds: async (streamId, matchedIds) =>
    Vue.$http.post(`${MERGE_URL}/duplicate/${streamId}/matched`, { matched_ids: matchedIds }),
  addDuplicateBlockedId: async (streamId, blockedId) =>
    Vue.$http.patch(`${MERGE_URL}/duplicate/${streamId}/blocked`, { blocked_id: blockedId }),
  joinDuplicates: async (streamIds) =>
    Vue.$http
      .post(`${MERGE_URL}/duplicate/join`, { stream_ids: streamIds })
      .then((res) => res.data),
  merge: async (aggregate, type) =>
    Vue.$http.post(`${MERGE_URL}/${aggregate.id}`, mergePayload[type](aggregate)),
};
