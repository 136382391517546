import gql from 'graphql-tag';

const cronjob = `
  name
  enabled
  schedule
  description
  trigger {
    url
    method
    payload
  }
`;

const getCronjobs = gql`
  query {
    cronjobs {
      ${cronjob}
    }
  }
`;

const getCronjob = gql`
  query cronjob($name: String!) {
    cronjob(name: $name) {
      ${cronjob}
    }
  }
`;

const enableCronjob = gql`
  mutation enableCronJob($name: String!) {
    enableCronjob(name: $name) {
      ${cronjob}
    }
  }
`;

const disableCronjob = gql`
  mutation disableCronjob($name: String!) {
    disableCronjob(name: $name) {
      ${cronjob}
    }
  }
`;

export default {
  getCronjob,
  getCronjobs,
  enableCronjob,
  disableCronjob,
};
