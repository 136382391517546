import Transaction from './transaction';

export default [
  {
    path: '/transactions/:txId',
    name: 'transaction',
    component: Transaction,
    props: true,
    meta: {
      title: 'Transaction',
    },
  },
];
