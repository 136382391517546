<template>
  <div v-if="loading" class="row">
    <div class="col s6">
      <spinner />
    </div>
  </div>
  <div v-else class="row">
    <div class="col s12 l7">
      <div class="row">
        <div class="col s12">
          <h1>Create Played Recordings</h1>
        </div>
      </div>
      <div class="row inner">
        <div class="col s6">
          <h2>Played Recordings</h2>
        </div>
      </div>
      <form :data-vv-scope="scope" class="pos-relative card">
        <create-menu
          entity-type="file"
          cancel-route-name="sdegPlayedRecordings"
          @create="createFile"
        />
        <div class="row">
          <div class="col s4">
            <validated-text-input
              v-model="file.name"
              name="name"
              label="Name"
              :scope="scope"
              placeholder="E.g. Played Recordings 2018"
              :mandatory="true"
              rule="required"
            />
          </div>
          <div class="col s4">
            <validated-text-input
              v-model="file.start_year"
              name="start_year"
              label="Start year"
              :scope="scope"
              placeholder="YYYY"
              :mandatory="true"
              :rule="{ year: true, required: true, min_value: 2000 }"
            />
          </div>
          <div class="col s4">
            <validated-text-input
              v-model="file.end_year"
              name="end_year"
              label="End year"
              :scope="scope"
              placeholder="YYYY"
              :mandatory="true"
              :rule="{ year: true, required: true, min_value: file.start_year }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <fieldset name="rights" class="mandate-rights">
              <legend>Rights</legend>
              <div class="select-all">
                <input
                  id="toggleSelectAll"
                  type="checkbox"
                  class="filled-in"
                  @input="toggleSelectAll"
                />
                <label for="toggleSelectAll">
                  <span>Select All</span>
                </label>
              </div>
              <div v-for="right in rights" :key="right.code">
                <input
                  :id="`right-${right.code}`"
                  v-model="file.rights"
                  type="checkbox"
                  class="filled-in"
                  :value="right.code"
                />
                <label :for="`right-${right.code}`">
                  <span>{{ right.name }}</span>
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import CreateMenu from '../ui/create-menu';
import ValidatedTextInput from '../ui/input/validated-text-input';
import Spinner from '../spinner';
import PlayedRecordingsService from '../../services/playedRecordingsService';

const excludeRights = ['LE'];

export default {
  components: {
    CreateMenu,
    ValidatedTextInput,
    Spinner,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      scope: 'played-recordings',
      loading: false,
      file: {
        start_year: 0,
        end_year: 0,
        name: '',
        rights: this.$store.state.appdata.referenceData.mandateRights
          .filter((mr) => excludeRights.indexOf(mr.code) === -1)
          .map((mr) => mr.code),
      },
    };
  },
  computed: {
    rights() {
      return this.$store.state.appdata.referenceData.mandateRights;
    },
  },
  methods: {
    async createFile() {
      this.loading = true;
      const processId = uuid.v4();
      this.$store.dispatch('process/addProcess', {
        name: `Create Played Recordings File ${this.file.name}`,
        id: processId,
      });
      await PlayedRecordingsService.createPlayedRecordingsFile(this.file, processId);
      setTimeout(() => {
        this.loading = false;
        this.$router.push({
          name: 'sdegPlayedRecordings',
        });
      }, 1000);
    },
    hasRight(right) {
      return this.file.rights.indexOf(right.code) > -1;
    },
    toggleRight(right) {
      if (!this.hasRight(right)) {
        this.file.rights.push(right.code);
      } else {
        this.file.rights = this.file.rights.filter((r) => r !== right.code);
      }
    },
    toggleSelectAll(e) {
      this.file.rights = e.target.checked ? this.rights.map((r) => r.code) : [];
    },
  },
};
</script>

<style scoped>
.select-all {
  margin-bottom: 1em;
}
</style>
