import AuthenticationService, { GROUPS } from '../../../services/authenticationService';

const rootState = {
  username: null,
  realname: null,
  developer: false,
  tester: false,
  admin: false,
};

const actions = {
  loginComplete(context) {
    const user = AuthenticationService.getUser();

    context.commit('setNames', user['cognito:username'] || 'anonymous');
    context.commit('setPermissions', user['cognito:groups'] || []);
  },
};

const mutations = {
  setNames(state, username) {
    state.username = username;
    state.realname = username.replace(/\./g, ' ');
  },
  setPermissions(state, groups) {
    state.developer = groups.includes(GROUPS.DEVELOPER);
    state.tester = groups.includes(GROUPS.TESTER);
    state.admin = groups.includes(GROUPS.ADMIN);
  },
};

const getters = {
  user: (state) => state,
  username: (state) => state.username,
  realname: (state) => state.realname,
  isDeveloper: (state) => state.developer,
  isTester: (state) => state.tester,
  isAdmin: (state) => state.admin,
};

const module = {
  actions,
  mutations,
  namespaced: true,
  state: rootState,
  getters,
};

export default module;
