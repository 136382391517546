/**
 * Common functionality for playlist views
 */

import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { createRecordingAggregate } from '../../../../domain/recordingDomain';
import ContextMenu from '../../../ui/context-menu';
import RecordingService from '../../../../services/recordingService';
import Spinner from '../../../spinner';
import {
  playlistLineupStates,
  processType as ProcessType,
} from '../../../../domain/matchingDomain';
import AuthenticationService from '../../../../services/authenticationService';
import MatchingService from '../../../../services/matchingService';
import { queryNotes } from '../../../../services/notesService';
import { getWorklistsByAssignee, getWorklist } from '../../../../services/worklistsService';

export default {
  components: {
    ContextMenu,
    Spinner,
  },
  activated() {
    this.addKeyListeners();
  },
  deactivated() {
    this.clearComparedObjects();
    this.removeKeyListeners();
  },
  data() {
    return {
      loading: true,
      optionsVisibleId: '',
      columns: [
        {
          name: 'Recording',
          ascending: true,
          sortParam: 'title',
          active: true,
          default: true,
        },
        {
          name: 'Version Title',
        },
        {
          name: 'Main Artist',
          sortParam: 'main_artist',
          active: false,
          ascending: true,
        },
        {
          name: 'Recording Year',
        },
        {
          name: 'Recorded in Country',
        },
        {
          name: this.processType === 'VRDB2' ? 'VRDB ID' : 'Public ID',
        },
        {
          name: 'ISRC',
        },
        {
          name: 'Import Date',
          ascending: false,
          sortParam: 'date_created',
        },
      ],
      totalCount: 0,
      error: false,
      expandedRecordingId: '',
      dirty: false,
      worklists: [],
      selectedWorklist: this.worklistId,
    };
  },
  props: {
    processType: { type: String },
    reportId: { type: String },
    worklistId: { type: String },
  },
  watch: {
    worklistId() {
      this.selectedWorklist = this.worklistId;
    },
  },
  computed: {
    ...mapGetters('society', ['basicInfoName', 'id', 'metaInfoVrdbCode', 'metaInfoCode']),
    ...mapGetters('matching', [
      'currentIncoming',
      'currentCandidate',
      'reportLines',
      'viewPlaylistToCheck',
      'viewPlaylistAccepted',
      'viewPlaylistRejected',
      'viewPlaylistApplied',
      'viewPlaylistSentRejections',
    ]),
    numberOfPages() {
      return Math.ceil(this.totalCount / this.pagination.hitsPerPage);
    },
  },
  methods: {
    ...mapMutations('matching', [
      'updateReportLines',
      'updateCurrentCandidate',
      'updateNotesOnCurrentCandidate',
      'updateCurrentCandidateLineup',
      'updateCurrentIncoming',
      'updateViewPlaylistToCheckExpandedId',
      'updateViewPlaylistAcceptedExpandedId',
      'updateViewPlaylistRejectedExpandedId',
      'updateViewPlaylistAppliedExpandedId',
      'updateViewPlaylistSentRejectionsExpandedId',
    ]),
    async fetchData() {
      this.loading = true;
      this.error = false;
      try {
        const result = await MatchingService.fetchPlaylistRecordings({
          society_id: this.getCodeForSociety(this.processType),
          match_state: this.lineupStateMask,
          process_type: this.processType,
          report_id: this.reportId,
          worklist_id: this.selectedWorklist,
          limit: this.pagination.hitsPerPage,
          offset: this.getOffset(),
          ascending: this.sortTerms.order === 'ASC',
          main_artist_range: this.range,
          order_by: this.sortTerms.param,
          lineup: this.customSelect,
        });
        this.totalCount = result.total_count;
        const reportLines = result.items.map((incoming) => ({
          incoming: { ...incoming.recording, lineup: incoming.lineup },
          checked: false,
        }));
        // This is a hack to hide deleted lineup rows on applied recordings.
        if (this.lineupStateMask === playlistLineupStates.ACCEPTED) {
          this.updateReportLines(reportLines.filter((el) => el.incoming.match_state === 'M'));
        } else {
          this.updateReportLines(reportLines);
        }
        this.loading = false;
      } catch (error) {
        error.title = 'Could not fetch playlist recordings';
        this.$addStarError(error);
        this.updateReportLines([]);
      }
      const userName = AuthenticationService.getUserName();
      const assignedWorklists = await getWorklistsByAssignee(userName);
      const personalWorklists = await getWorklist(userName);
      this.worklists = assignedWorklists.concat(personalWorklists);
    },
    getCodeForSociety() {
      if (this.processType === ProcessType.VRDB2) {
        return this.metaInfoVrdbCode;
      }
      if (this.processType === ProcessType.SDEG) {
        return this.metaInfoCode;
      }
      throw new Error(`Unknown process type: ${this.processType}`);
    },
    getCandidateValue(path) {
      return _.get(this.candidate, path.split('.')) || '';
    },
    completeAndContinue(e) {
      this.markCompleted(e.groupId);
      this.togglePrevOrNext('next');
    },
    allAreSelected(predicate = () => true) {
      return this.reportLines.length > 0
        ? this.reportLines.filter(predicate).every((line) => line.checked)
        : false;
    },
    toggleSelectAll(deselect, predicate = () => true) {
      const select = deselect || this.allAreSelected(predicate);
      this.reportLines.filter(predicate).forEach((line) => (line.checked = !select));
    },
    anySelected() {
      return this.reportLines.some((line) => line.checked);
    },
    getSelected() {
      return this.reportLines.filter((line) => line.checked);
    },
    addKeyListeners() {
      window.addEventListener('keydown', this.onKeyDown);
    },
    removeKeyListeners() {
      window.removeEventListener('keydown', this.onKeyDown);
    },
    onKeyDown(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.togglePrevOrNext('next');
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.togglePrevOrNext('prev');
      }
    },
    getLine(internalId) {
      return this.reportLines.find((line) => line.incoming.internal_id === internalId);
    },
    getMatchState(line) {
      return line.incoming.match_state;
    },
    clearComparedObjects() {
      this.updateCurrentIncoming(undefined);
      this.updateCurrentCandidate(undefined);
    },
    async setComparedObjects(internalId) {
      const line = this.getLine(internalId);
      if (line) {
        this.updateCurrentIncoming(line.incoming);
        await this.setCurrentCandidate(line.incoming.internal_id);
        this.overwriteCandidateLineup();
      }
    },
    async addRejectionNotes(id) {
      const notes = await queryNotes(id);
      this.updateNotesOnCurrentCandidate(
        notes.filter((note) => note.user.includes('Rejected from')).map((note) => note.user),
      );
    },
    async setCurrentCandidate(recordingId) {
      if (!recordingId) {
        this.updateCurrentCandidate(createRecordingAggregate());
        return;
      }
      this.error = false;
      try {
        const result = await RecordingService.getRecording(recordingId);
        this.updateCurrentCandidate(result);
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    overwriteCandidateLineup() {
      const starLineup = _.cloneDeep(this.currentCandidate.lineup);
      // the lineup must be the one from the matching service response
      this.updateCurrentCandidateLineup(
        this.currentIncoming.lineup.map((o) => {
          if (o.artist) {
            const performer = starLineup.find((sl) => sl.relation.id === o.artist.internal_id);
            return { ...o.artist, pseudo_names: performer && performer.relation.pseudo_names };
          } else if (o.match_state === playlistLineupStates.ACCEPTED) {
            return o.incoming_artist;
          }
          return null;
        }),
      );
    },
    async updateRecordingWithCurrentCandidate() {
      this.error = false;
      try {
        await RecordingService.updateRecordingBasicInfo(this.currentCandidate);
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    async reloadCompared(internalId, clearFirst = false) {
      if (clearFirst) {
        this.clearComparedObjects();
      }
      const line = this.getLine(internalId);
      if (!line.incoming.lineup) {
        const playlistRecording = await MatchingService.getPlaylistRecording({
          recording_id: line.incoming.id,
        });
        line.incoming.lineup = playlistRecording.lineup;
      }
      await this.setComparedObjects(
        internalId !== undefined ? internalId : this.expandedRecordingId,
      );
      await this.addRejectionNotes(
        internalId !== undefined ? internalId : this.expandedRecordingId,
      );
      this.updateDirtyState();
    },
    async togglePrevOrNext(direction) {
      if (!this.expandedRecordingId) {
        this.setExpanded(undefined);
        return;
      }

      let idx = this.reportLines.findIndex((l) => this.isExpanded(l.incoming.internal_id));
      idx = direction === 'prev' ? idx - 1 : idx + 1;

      if (idx < 0 || idx >= this.reportLines.length) {
        const page =
          direction === 'prev' ? this.pagination.currentPage - 1 : this.pagination.currentPage + 1;
        this.setExpanded(undefined);

        if (page < 1 || page > this.numberOfPages) {
          return;
        }

        await this.selectPage(page);
        idx = direction === 'prev' ? this.reportLines.length - 1 : 0;
      }

      const internalId = this.reportLines[idx].incoming.internal_id;
      await this.toggleExpanded(internalId);
    },
    async toggleExpanded(internalId) {
      if (this.isExpanded(internalId)) {
        this.setExpanded(undefined);
        return;
      }
      this.setExpanded(internalId);
      await this.reloadCompared(internalId, true);
    },
    isExpanded(internalId) {
      return this.expandedRecordingId === internalId;
    },
    setExpanded(internalId) {
      this.expandedRecordingId = internalId;
      if (internalId !== undefined) {
        this.scrollToId(internalId);
      }
    },
    scrollToId(internalId) {
      this.$nextTick(() => {
        const idx = this.reportLines.findIndex((r) => r.incoming.internal_id === internalId);
        if (idx >= 0) {
          this.$scrollTo(this.$refs.comparelist.$refs.line[idx], {
            cancelable: false,
          });
        }
      });
    },
    markCompleted(internalId) {
      const line = this.getLine(internalId);
      line.completed = true;
      this.completed++;
    },
    updateDirtyState() {
      this.$nextTick(() => {
        if (this.$refs.compare && this.$refs.compare.length > 0) {
          this.dirty = !!this.$refs.compare[0].$children.find((el) => el.dirty === true);
        } else {
          this.dirty = false;
        }
      });
    },
    async onWorklistChanged(evt) {
      this.selectedWorklist = evt;
      const route = {
        name: this.$router.currentRoute.name,
        params: this.$router.currentRoute.params,
      };
      if (evt) {
        route.query = { worklist_id: evt };
      }
      await this.fetchData();
      this.$router.push(route);
    },
  },
};
