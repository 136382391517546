<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>VRDB</h2>
      </div>
    </div>
    <div class="row">
      <div class="row flush--bottom">
        <div v-if="sync_state === 'I'" class="row">
          <div class="col s12">
            <span>Recording was ignored.</span>
          </div>
        </div>
        <div v-if="duplicate_of" class="row">
          <div class="col s12">
            <strong>Duplicate of:</strong>
            <span v-if="duplicate_of === 'unknown'">Unknown</span>
            <router-link v-else :to="`/recording/${duplicate_of}/information`">
              {{ duplicate_of }}
            </router-link>
          </div>
        </div>
        <div v-if="validation_error" class="row">
          <div class="col s12">
            <strong>Validation error:</strong>
            {{ validation_error }}
          </div>
        </div>
        <div v-if="updated_at" class="row">
          <div class="col s12">
            <span>
              <strong>Updated at:</strong>
              {{ updatedAt }}
            </span>
          </div>
        </div>
        <div v-else class="row">
          <div class="col s12">
            <span>VRDB upload status unknown</span>
          </div>
        </div>
        <div v-if="is_enqueued" class="row">
          <div class="col s12">
            <span class="text--orange">Pending upload...</span>
          </div>
        </div>
        <div v-else class="row">
          <div class="col s12">
            <button class="btn primary m2" @click="enqueue">Add to upload queue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  name: 'Vrdb2Info',
  props: {
    sync_state: {
      type: String,
    },
    duplicate_of: {
      type: String,
    },
    validation_error: {
      type: String,
    },
    updated_at: {
      type: String,
    },
    is_enqueued: {
      type: Boolean,
    },
  },
  computed: {
    updatedAt() {
      return moment(this.updated_at).format('YYYY-MM-DD HH:mm');
    },
  },
  methods: {
    enqueue() {
      this.$emit('enqueue');
    },
  },
};
</script>
