<template>
  <div>
    <form @click.prevent>
      <div class="row">
        <div class="col s12">
          <action-buttons class="float-right" @save="onSave" @cancel="onCancel" />
        </div>
      </div>
      <div class="row card">
        <div class="row">
          <div class="col s2">Name of main artist</div>
          <div class="col s7">
            <validated-text-input
              v-model="basicInfo.name"
              name="basic_info.name"
              :mandatory="true"
              label="name"
              :show-label="false"
              rule="required"
              placeholder="E.g. Kent"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Alternate names</div>
          <div class="col s7">
            <tag-input
              name="alternateTitle"
              placeholder="E.g. Kent, the band"
              :value-list="basicInfo.alternate_names"
              @add="addToAlternateNames"
              @remove="removeFromAlternateNames"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Genre</div>
          <div class="col s7">
            <select-input
              name="basic_info.genre"
              :value="basicInfo.genre"
              :items="genres"
              item-key="code"
              item-value="name"
              @input="updateGenre"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Origin country</div>
          <div class="col s7">
            <select-country
              v-model="basicInfo.origin_country"
              name="data.origin_country"
              :show-label="false"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Origin city</div>
          <div class="col s5">
            <validated-text-input
              v-model="basicInfo.origin_city"
              name="basic_info.origin_city"
              rule=""
              placeholder="E.g. Esbjerg"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Years active</div>
          <div class="col s7 hard--sides">
            <create-active-period
              name="basicInfo.active_period"
              scope="edit-basic-information"
              :active-periods="basicInfo.active_period"
              @fromDateChanged="onActivePeriodFromDateChanged"
              @toDateChanged="onActivePeriodToDateChanged"
              @add="onAddActivePeriod"
              @remove="onRemoveActivePeriod"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Local codes</div>
          <div class="col s7">
            <local-codes
              name="basic_info.local_codes"
              :entries="basicInfo.local_codes"
              entry-key-name="code"
              entry-value-name="value"
              :local-codes="localCodeItems"
              selectable-item-key-name="code"
              selectable-item-value-name="name"
              :show-label="false"
              @input="updateLocalCode"
              @add="addLocalCode"
              @remove="removeFromBasicInfoLocalCodes"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Profile</div>
          <div class="col s7">
            <textarea
              v-model="basicInfo.profile_description"
              class="profile"
              name="basic_info.profile_description"
              placeholder="E.g. Kent was a Swedish rock band, formed in Eskilstuna in 1990."
              @keyup="autoHeight"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import { createActivePeriod } from '../../../domain/mainArtistDomain';
import { createSourceInfo, createLocalCode, textareaAutoheight } from '../../../domain/common';
import ActionButtons from '../../ui/button/action-buttons';
import CreateActivePeriod from '../create-active-period';
import LocalCodes from '../../ui/input/local-codes';
import SelectInput from '../../ui/select/select-input';
import TagInput from '../../ui/tag/tag-input';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import SelectCountry from '../../ui/select/select-country';

export default {
  name: 'EditBasicInformation',
  components: {
    SelectCountry,
    ActionButtons,
    CreateActivePeriod,
    LocalCodes,
    SelectInput,
    TagInput,
    ValidatedTextInput,
  },
  props: {
    mainArtist: { type: Object },
  },
  data() {
    return {
      basicInfo: _.cloneDeep(this.mainArtist.basic_info),
      placeHolderId: 'E.g. 1234567',
      placeHolderTitle: 'E.g. Eclipse',
      placeHolderPerformer: 'E.g. Dee Snider',
    };
  },
  computed: {
    localCodeItems() {
      return this.$store.state.appdata.localCodeTypes;
    },
    genres() {
      return this.$store.state.appdata.referenceData.genres;
    },
  },
  created() {
    if (this.basicInfo.local_codes.length === 0) {
      this.addLocalCode();
    }
    if (this.basicInfo.active_period.length === 0) {
      this.onAddActivePeriod();
    }
  },
  methods: {
    addLocalCode() {
      this.addToLocalCodes(createLocalCode());
    },
    updateGenre(value) {
      this.basicInfo.genre = value;
    },
    updateLocalCode(obj) {
      this.basicInfo.local_codes.splice(obj.index, 1, obj.entry);
    },
    addToLocalCodes(value) {
      this.basicInfo.local_codes.push(value);
    },
    removeFromBasicInfoLocalCodes(index) {
      this.basicInfo.local_codes.splice(index, 1);
      if (this.basicInfo.local_codes.length === 0) {
        this.addToLocalCodes(createLocalCode());
      }
    },
    onActivePeriodFromDateChanged(obj) {
      const toDate = this.basicInfo.active_period[obj.index].to;
      this.basicInfo.active_period.splice(obj.index, 1, { to: toDate, from: obj.entry });
    },
    onActivePeriodToDateChanged(obj) {
      const fromDate = this.basicInfo.active_period[obj.index].from;
      this.basicInfo.active_period.splice(obj.index, 1, { to: obj.entry, from: fromDate });
    },
    onAddActivePeriod() {
      this.basicInfo.active_period.push(createActivePeriod());
    },
    onRemoveActivePeriod(index) {
      this.basicInfo.active_period.splice(index, 1);
      if (this.basicInfo.active_period.length === 0) {
        this.basicInfo.active_period.push(createActivePeriod());
      }
    },
    addToAlternateNames(value) {
      this.basicInfo.alternate_names.push(value);
    },
    removeFromAlternateNames(idx) {
      this.basicInfo.alternate_names.splice(idx, 1);
    },
    addSourceInfo() {
      this.basicInfo.source_info = createSourceInfo();
    },
    updateSourceInfo(entry) {
      this.basicInfo.source_info = entry;
    },
    onCancel() {
      this.basicInfo = _.cloneDeep(this.mainArtist.basic_info);
      this.$emit('cancel', this.$options.name);
    },
    onSave() {
      this.mainArtist.basic_info = this.basicInfo;
      this.$emit('save', this.mainArtist);
    },
    autoHeight(e) {
      textareaAutoheight(e);
    },
  },
};
</script>
