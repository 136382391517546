<template>
  <div>
    <div v-if="loading">
      <Spinner />
    </div>

    <div v-else class="row">
      <div class="col s12 l9 wrapper">
        <div class="row">
          <h1>Merge Preview for {{ candidates[0].basic_info.name }}</h1>
        </div>

        <MergePreviewAlbum
          v-if="mergeType === 'album'"
          :changes="changes"
          :candidates="candidates"
          @setValue="setValue"
          @confirmMerge="confirmMerge"
        />

        <MergePreviewMainartist
          v-if="mergeType === 'mainartist'"
          :changes="changes"
          :candidates="candidates"
          @setValue="setValue"
          @confirmMerge="confirmMerge"
        />
      </div>

      <div class="col s12 l3">
        <MergeRightColumn
          :merge-type="mergeType"
          :candidates="candidates"
          @addDuplicateBlockedId="addDuplicateBlockedId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { set, get } from 'lodash';
import delay from '../../common/delay';
import MergeService from '../../services/mergeService';
import MergePreviewAlbum from './merge-preview-album';
import MergePreviewMainartist from './merge-preview-mainartist';
import MergeRightColumn from './merge-right-column';
import Spinner from '../spinner';

export default {
  name: 'ViewMergePreview',
  components: {
    Spinner,
    MergePreviewMainartist,
    MergePreviewAlbum,
    MergeRightColumn,
  },
  data() {
    return {
      loading: false,
      id: this.$router.currentRoute.params.id,
      mergeType: this.$router.currentRoute.params.mergeType,
      candidates: [],
      changes: {},
    };
  },
  computed: {
    master() {
      return this.candidates.find((candidate) => candidate.master);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const candidates = await MergeService.getMergePreview(this.id, this.mergeType);
        this.candidates = candidates
          .sort((candidate) => (candidate.master ? -1 : 1))
          .map((candidate, index) => ({
            ...candidate,
            descriptor: `${index}.`,
            color: candidate.master ? '#ffffff' : `hsl(${index * 137.508}, 50%, 75%)`,
          }));

        this.setRequiredValues();
      } catch (err) {
        this.$addStarError(err);
      }
      this.loading = false;
    },
    setRequiredValues() {
      switch (this.mergeType) {
        case 'album':
        case 'mainartist':
          this.setValue({
            id: this.master.id,
            path: 'basic_info.name',
            value: this.master.basic_info.name,
          });
          break;
        default:
          throw new Error(`Unknown merge type: ${this.mergeType}`);
      }
    },
    setValue({ id, value, path }) {
      set(this.changes, path, { id, value });
      this.changes = { ...this.changes };
    },
    getMergedRouteName() {
      switch (this.mergeType) {
        case 'album':
          return 'albumInformation';
        case 'mainartist':
          return 'mainArtistInformation';
        default:
          throw new Error(`Unknown merge type: ${this.mergeType}`);
      }
    },
    async addDuplicateBlockedId(blockedId) {
      try {
        await MergeService.addDuplicateBlockedId(this.master.id, blockedId);
        this.candidates = this.candidates.filter((candidate) => candidate.id !== blockedId);
      } catch (err) {
        this.$addStarError(err);
      }
    },
    async confirmMerge(paths) {
      this.loading = true;
      const aggregate = { id: this.master.id };

      paths.forEach((path) => {
        const change = get(this.changes, path);
        if (change) {
          set(aggregate, path, change.value);
        }
      });

      try {
        await MergeService.merge(aggregate, this.mergeType);
        await delay(1000);

        this.$router.replace({
          name: this.getMergedRouteName(),
          params: { id: aggregate.id },
        });
      } catch (err) {
        this.$addStarError(err);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.wrapper {
  padding-right: 24px;
}
</style>
