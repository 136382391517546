<template>
  <form :data-vv-scope="scope">
    <div class="row text--right">
      <action-buttons class="col s12" :loading="loading" @save="updateBasicInfo" @cancel="cancel" />
    </div>
    <div class="row card">
      <div class="col s12">
        <div class="row">
          <div class="col s2">Name of recording</div>
          <div class="col s7">
            <validated-text-input
              v-model="recording.basic_info.name"
              name="basic_info.name"
              placeholder="E.g. Stoppa mig Juni (Lilla Ego)"
              rule="required"
              :scope="scope"
              label="name of recording"
              :show-label="false"
              :notify-validation="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Version title</div>
          <div class="col s7">
            <validated-text-input
              v-model="recording.basic_info.version_title"
              name="basic_info.version_title"
              placeholder="E.g. Album version"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Main artist</div>
          <div class="col s7">
            <main-artist-input
              v-model="recording.basic_info.main_artist"
              name="basic_info.main_artist"
              placeholder="E.g. Kent"
              :show-label="false"
              :scope="scope"
              label="main artist"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Alternate names</div>
          <div class="col s7">
            <tag-input
              name="basic_info.alternate_titles"
              placeholder="E.g. Stoppa mig Juni"
              :value-list="recording.basic_info.alternate_titles"
              @add="addAlternateTitle"
              @remove="removeAlternateTitle"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Duration</div>
          <div class="col s7">
            <duration-input
              :value="recording.basic_info.duration_sec"
              :show-label="false"
              @input="updateDuration"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Label</div>
          <div class="col s7">
            <validated-text-input
              v-model="recording.basic_info.label"
              name="basic_info.label"
              placeholder="E.g. BMG"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Produced in country</div>
          <div class="col s7">
            <select-country
              v-model="recording.basic_info.produced_in_country"
              name="basic_info.produced_in_country"
              :show-label="false"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Recorded in country</div>
          <div class="col s7">
            <select-country
              v-model="recording.basic_info.recorded_in_country"
              name="basic_info.recorded_in_country"
              :show-label="false"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Recording date</div>
          <div class="col s3">
            <select-date
              v-model="recording.basic_info.recording_date"
              name="basic_info.recording_date"
              :scope="scope"
              :disabled="unknownProtected"
              :extra-date-patterns="['UNKNOWN_PROTECTED_DATE']"
              @input="_updateBasicInfoRecordingDate"
            />
          </div>

          <div class="col s4 unknown-protected">
            <input
              id="basic_info.unknown_protected"
              v-model="unknownProtected"
              type="checkbox"
              class="filled-in"
              @click="updateUnknownProtected"
            />
            <label for="basic_info.unknown_protected">Unknown and protected</label>
          </div>
        </div>
        <div class="row">
          <div class="col s2">Release date</div>
          <div class="col s7">
            <select-date
              v-model="recording.basic_info.release_date"
              name="basic_info.release_date"
              :scope="scope"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">ISRC</div>
          <div class="col s7">
            <validated-text-input
              v-model="recording.basic_info.isrc"
              name="basic_info.isrc"
              placeholder=""
              rule="isrc"
              @input="updateIsrc"
            />
          </div>
        </div>

        <!--VRDB fields-->
        <!--<div class="row">-->
        <!--<div class="col s2">-->
        <!--VRDB title-->
        <!--</div>-->
        <!--<div class="col s7">-->
        <!--<validated-text-input-->
        <!--disabled-->
        <!--name="basic_info.vrdb2_title"-->
        <!--placeholder=""-->
        <!--v-model="recording.basic_info.vrdb2_title" />-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<div class="col s2">-->
        <!--VRDB ID-->
        <!--</div>-->
        <!--<div class="col s7">-->
        <!--<validated-text-input-->
        <!--disabled-->
        <!--name="basic_info.vrdb2_id"-->
        <!--placeholder=""-->
        <!--v-model="recording.basic_info.vrdb2_id" />-->
        <!--</div>-->
        <!--<span-->
        <!--class="remove-button">-->
        <!--<button-->
        <!--role="button"-->
        <!--type="button"-->
        <!--tabindex="0"-->
        <!--:class="[disabled]"-->
        <!--@click.stop.prevent="onRemoveVrdbId"-->
        <!--@keyup.enter="onRemoveVrdbId"-->
        <!--&gt;-->
        <!--<i class="far fa-trash-alt"></i>-->
        <!--</button>-->
        <!--</span>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<div class="col s2">-->
        <!--VRDB RM-->
        <!--</div>-->
        <!--<div class="col s7">-->
        <!--<validated-text-input-->
        <!--disabled-->
        <!--name="basic_info.vrdb2_rm"-->
        <!--placeholder=""-->
        <!--v-model="recording.basic_info.vrdb2_rm" />-->
        <!--</div>-->
        <!--</div>-->
        <!--VRDB fields-->

        <div class="row">
          <div class="col s2">Genre</div>
          <div class="col s7">
            <select-input
              v-model="recording.basic_info.genre"
              name="basic_info.genre"
              :disabled="recording.lineup_locked"
              :items="genres"
              item-key="code"
              item-value="name"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">
            Composed by
            <span v-if="recording.basic_info.genre === 'classical_music'" class="mandatory">
              (mandatory)
            </span>
          </div>
          <div class="col s7">
            <validated-text-input
              v-model="recording.basic_info.composer"
              name="basic_info.composer"
              placeholder=""
              :scope="scope"
              label="composed by"
              :show-label="false"
              :rule="{ required: recording.basic_info.genre === 'classical_music' }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Written by</div>
          <div class="col s7">
            <validated-text-input
              v-model="recording.basic_info.writer"
              name="basic_info.writer"
              placeholder=""
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Arranged by</div>
          <div class="col s7">
            <validated-text-input
              v-model="recording.basic_info.arranger"
              name="basic_info.arranger"
              placeholder=""
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Original source</div>
          <div class="col s7">
            <selectable-entry
              name="basic_info.source_info"
              :entry="recording.basic_info.source_info"
              :scope="scope"
              entry-key-name="source"
              entry-value-name="comment"
              :entry-value-required="false"
              :items="localCodes"
              selectable-item-key-name="code"
              selectable-item-value-name="name"
              @input="updateSourceInfo"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Local codes</div>
          <div class="col s7">
            <local-codes
              name="basic_info.local_codes"
              :entries="recording.basic_info.local_codes"
              entry-key-name="code"
              entry-value-name="value"
              :local-codes="localCodes"
              selectable-item-key-name="code"
              selectable-item-value-name="name"
              :show-label="false"
              @add="addLocalCode"
              @input="updateLocalCode"
              @remove="removeLocalCode"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { createSourceInfo, createLocalCode } from '../../../domain/common';
import ActionButtons from '../../ui/button/action-buttons';
import EditHelper from './editHelper';
import MainArtistInput from '../../ui/input/main-artist-input';
import LocalCodes from '../../ui/input/local-codes';
import SelectableEntry from '../../ui/select/selectable-entry';
import SelectDate from '../../ui/select/select-date';
import SelectInput from '../../ui/select/select-input';
import TagInput from '../../ui/tag/tag-input';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import DurationInput from '../../ui/input/duration-input';
import SelectCountry from '../../ui/select/select-country';
import validationPatterns from '../../../common/validationPatterns';
import { UNKNOWN_PROTECTED_DATE } from '../../../domain/recordingDomain';

export default {
  name: 'EditBasicInformation',
  components: {
    SelectCountry,
    ActionButtons,
    MainArtistInput,
    LocalCodes,
    SelectableEntry,
    SelectDate,
    SelectInput,
    TagInput,
    ValidatedTextInput,
    DurationInput,
  },
  data() {
    return {
      scope: 'edit-basic-information',
      recording: EditHelper.getCurrentRecording(),
      loading: false,
      unknownProtected: false,
    };
  },
  computed: {
    localCodes() {
      return this.$store.state.appdata.localCodeTypes;
    },
    countrie() {
      return this.$store.state.appdata.referenceData.countries;
    },
    genres() {
      return this.$store.state.appdata.referenceData.genres;
    },
  },
  created() {
    if (validationPatterns.UNKNOWN_PROTECTED_DATE.test(this.recording.basic_info.recording_date)) {
      this.unknownProtected = true;
    }
    if (this.recording.basic_info.local_codes.length === 0) {
      this.addLocalCode();
    }
    if (!this.recording.basic_info.source_info) {
      this.addSourceInfo();
    }
  },
  methods: {
    updateDuration(seconds) {
      this.recording.basic_info.duration_sec = seconds;
    },
    addAlternateTitle(alternateTitle) {
      this.recording.basic_info.alternate_titles.push(alternateTitle);
    },
    removeAlternateTitle(idx) {
      this.recording.basic_info.alternate_titles.splice(idx, 1);
    },
    addLocalCode() {
      this.recording.basic_info.local_codes.push(createLocalCode());
    },
    updateLocalCode(entryUpdate) {
      this.recording.basic_info.local_codes.splice(entryUpdate.index, 1, entryUpdate.entry);
    },
    removeLocalCode(index) {
      this.recording.basic_info.local_codes.splice(index, 1);
    },
    addSourceInfo() {
      this.recording.basic_info.source_info = createSourceInfo();
    },
    updateIsrc(value) {
      this.recording.basic_info.isrc = value ? value.toUpperCase() : value;
    },
    updateSourceInfo(entryUpdate) {
      this.recording.basic_info.source_info = entryUpdate;
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    async updateBasicInfo() {
      this.$starContentLoading(true);
      try {
        await EditHelper.updateBasicInfo(this.recording);
      } finally {
        this.$starContentLoading(false);
        this.cancel();
      }
    },
    _updateBasicInfoRecordingDate(value) {
      this.recording.basic_info.recording_date = value;
      if (validationPatterns.UNKNOWN_PROTECTED_DATE.test(value)) {
        this.unknownProtected = true;
      }
    },
    updateUnknownProtected() {
      this.unknownProtected = !this.unknownProtected;
      if (this.unknownProtected) {
        this.recording.basic_info.recording_date = UNKNOWN_PROTECTED_DATE;
      } else {
        this.recording.basic_info.recording_date = '';
      }
    },
  },
};
</script>

<style lang="scss">
.remove-button {
  /*position: absolute;*/
  /*right: calc((var(--spacing) + 8.5rem));*/
  /*top: var(--spacing);*/

  /*i {*/
  /*font-size: 14px;*/
  /*}*/
}
</style>
