<template>
  <div class="row">
    <label class="col s2">
      {{ label }}
    </label>
    <div class="col s10 wrapper">
      <template v-if="computedCandidates.length">
        <span
          v-for="candidate of computedCandidates"
          :key="candidate.id"
          class="chip"
          :class="{ selected: selected === candidate.id }"
          :style="`background: ${candidate.color}`"
          @click="toggle(candidate)"
        >
          {{ candidate.descriptor }} {{ applyFilter(candidate.value) }}
        </span>
      </template>
      <span v-else class="none">None</span>
    </div>
  </div>
</template>

<script>
import { get, uniqBy } from 'lodash';

export default {
  name: 'MergeText',
  props: {
    label: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    changes: {
      type: Object,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
    filter: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    selected() {
      return get(this.changes, `${this.path}.id`, this.candidates[0].id);
    },
    computedCandidates() {
      return uniqBy(
        this.candidates
          .map((candidate) => ({
            id: candidate.id,
            value: get(candidate, this.path, ''),
            color: candidate.color,
            descriptor: candidate.descriptor,
          }))
          .filter((candidate) => candidate.value),
        'value',
      );
    },
  },
  methods: {
    toggle({ id, value }) {
      if (this.selected === id) {
        this.$emit('setValue', { path: this.path });
      } else {
        this.$emit('setValue', { id, value, path: this.path });
      }
    },
    applyFilter(value) {
      if (this.filter) {
        return this.$filters[this.filter](value);
      }

      return value;
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.chip {
  white-space: nowrap;
  height: 26px;

  &.selected {
    text-decoration: none;
    border: 1px solid black;
  }

  &:not(.selected) {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
