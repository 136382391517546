import _ from 'lodash';
import { getter as vuexGetter, commit as vuexCommit } from '../store/modules/matching/utils';

const matchingBaseFunctionalityMixin = {
  data() {
    return {
      pagination: {
        history: [],
        currentPage: 1,
        hitsPerPage: 25,
        path: '',
        album: false,
      },
      sortTerms: {
        param: 'title',
        order: 'ASC',
      },
      doRefreshOnly: false,
      completed: 0,
    };
  },
  activated() {
    this.completed = 0;
    if (!this.getStore()) {
      console.log(`no store for ${this.$options.name}`);
      return;
    }

    const pagination = this.getStore().pagination;
    const path = this.$route.path;
    const album = this.$router.currentRoute.query.album === 'true';

    if (pagination && pagination.path === path && pagination.album === album) {
      this.pagination = pagination;
      this.doRefreshOnly = true;
    } else {
      this.pagination = {
        ...this.pagination,
        path,
        album,
      };
    }

    if (this.getStore().sortTerms) {
      this.sortTerms = this.getStore().sortTerms;
      const col = this.columns.find((c) => c.sortParam === this.sortTerms.param);
      this.columns.forEach((c) => {
        c.active = false;
      });
      col.active = true;
      col.ascending = this.sortTerms.order === 'ASC';
      this.doRefreshOnly = true;
    } else {
      const def = this.findDefaultColumn();
      this.columns.forEach((c) => {
        c.active = false;
      });
      if (def) {
        def.active = true;
      }
    }
  },
  deactivated() {
    this.storePagination(this.pagination);
    this.storeSortTerms(this.sortTerms);
  },
  methods: {
    getStore() {
      return vuexGetter(_.camelCase(this.$options.name));
    },
    storePagination(obj) {
      vuexCommit(`update${_.upperFirst(_.camelCase(this.$options.name))}Pagination`, obj);
    },
    storeSortTerms(obj) {
      vuexCommit(`update${_.upperFirst(_.camelCase(this.$options.name))}SortTerms`, obj);
    },
    async loadPaginated() {
      return this.doRefreshOnly ? this.refresh() : this.reload();
    },
    async reload() {
      await this.fetchData();
      this.pagination.history = [];
    },
    async refresh() {
      await this.fetchData();
    },
    async selectPage(page) {
      if (page < this.pagination.currentPage) {
        this.completed = 0;
      }
      this.pagination.currentPage = page;
      await this.loadPaginated();
    },
    findDefaultColumn() {
      return this.columns.find((c) => c.default);
    },
    getOffset() {
      const expectedOffset = (this.pagination.currentPage - 1) * this.pagination.hitsPerPage;
      const offset = expectedOffset - this.completed;

      if (offset < 0) {
        this.completed = 0;
        return 0;
      }

      return offset;
    },
    async updateHitsPerPage(limit) {
      this.pagination.hitsPerPage = +limit;
      this.pagination.currentPage = 1;
      this.completed = 0;
      await this.loadPaginated();
    },
    async sort(sortEvent) {
      this.setActiveSortColumn(sortEvent.selected, sortEvent.columns);
      this.completed = 0;
      await this.reload();
    },
    setActiveSortColumn(selectedColumn, columns) {
      columns
        .filter((c) => c.sortParam !== selectedColumn.sortParam)
        .forEach((c) => {
          c.active = false;
        });
      if (selectedColumn.active) {
        selectedColumn.ascending = !selectedColumn.ascending;
      } else {
        selectedColumn.active = true;
      }
      this.sortTerms.order = selectedColumn.ascending ? 'ASC' : 'DESC';
      this.sortTerms.param = selectedColumn.sortParam;
    },
  },
};

export default matchingBaseFunctionalityMixin;
