<template>
  <div :name="name">
    <div class="row">
      <div class="col s4">
        <validated-text-input
          v-model="privateAccountData.clearing_number"
          name="private_account.clearing_number"
          label="Clearing number"
          scope="payment-information"
          placeholder="E.g. 1234"
          :rule="{
            required: privateAccountData.account_number ? true : false,
            clearingnumber: true,
          }"
          @input="emit"
        />
        {{ bank ? `Bank: ${bank.name}` : '' }}
      </div>
      <div class="col s8">
        <validated-text-input
          v-model="privateAccountData.account_number"
          name="private_account.account_number"
          :label="`Bank account number ${
            isSSNAccount(privateAccountData.clearing_number) ? '(SSN account)' : ''
          }`"
          scope="payment-information"
          :placeholder="`${
            isSSNAccount(privateAccountData.clearing_number) ? 'YYMMDDXXXX' : 'E.g. 1234567'
          }`"
          :rule="{
            required: privateAccountData.clearing_number ? true : false,
            accountbyclearing: privateAccountData.clearing_number
              ? privateAccountData.clearing_number
              : '',
          }"
          @input="emit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getBankByClearingNbr, isSSNAccount } from '../../../common/bankAccountUtils';
import clone from '../../../common/clone';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'PrivateAccountInput',
  components: {
    ValidatedTextInput,
  },
  inject: ['$validator'],
  props: {
    name: String,
    label: { type: String, default: '' },
    privateAccount: Object,
    editMode: { type: Boolean },
  },
  data() {
    return {
      vuexWatchHandles: [],
      privateAccountData: clone(this.privateAccount),
    };
  },
  computed: {
    bank() {
      return this.privateAccountData
        ? getBankByClearingNbr(this.privateAccountData.clearing_number)
        : undefined;
    },
  },
  watch: {
    privateAccount() {
      this.privateAccountData = clone(this.privateAccount);
    },
  },
  methods: {
    isSSNAccount(clearingNbr) {
      return clearingNbr && isSSNAccount(clearingNbr);
    },
    emit() {
      this.privateAccountData.bank = this.bank ? this.bank.code : null;
      this.$emit('input', this.privateAccountData);
    },
  },
};
</script>
