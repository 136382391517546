<template>
  <div ref="wrapper" class="compare__action-btns">
    <template v-if="line.match_state === 'I'">
      <div class="compare__buttons hc">
        <button class="btn red m2" @click="revertMatch(line)">Unidentify</button>
      </div>
    </template>
    <template v-else-if="line.match_state === 'M'">
      <div class="compare__buttons hc">
        <button class="btn red m2" @click="revertMatch(line)">Unidentify</button>
        <button v-if="handleIgnoreBtn" class="btn yellow m2" @click="handleIgnoreBtn(line)">
          Ignore
        </button>
        <button class="btn primary m2" :disabled="!dirty_line" @click="handleSaveBtn(line)">
          Save changes
        </button>
      </div>
    </template>
    <template v-else>
      <div class="compare__buttons hc">
        <button
          v-focus
          class="btn green m2"
          :disabled="!candidateId"
          @click="handleIdentifyBtn(line, candidateId)"
        >
          Identify
        </button>
        <button v-if="handleIgnoreBtn" class="btn yellow m2" @click="handleIgnoreBtn(line)">
          Ignore
        </button>
        <button class="btn secondary m2" @click="handleNew(line)">
          {{ newBtnTitle }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CompareButtonsMatching2',
  components: {},
  directives: {
    focus: {
      inserted(e) {
        e.focus();
      },
    },
  },
  props: {
    revertMatch: {
      type: Function,
    },
    handleIdentifyBtn: {
      type: Function,
    },
    handleIgnoreBtn: {
      type: Function,
    },
    handleNew: {
      type: Function,
    },
    handleSaveBtn: {
      type: Function,
    },
    line: {
      type: Object,
    },
    dirty_line: {
      type: Boolean,
      default: false,
    },
    newBtnTitle: {
      type: String,
    },
    candidateId: {
      type: String,
    },
    captureKeyPress: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    buttons() {
      return this.$refs.wrapper.querySelectorAll('button');
    },
  },
  mounted() {
    this.indexButtons();
    if (this.autoFocusBtn >= 0) {
      this.focusBtn(this.autoFocusBtn);
    }
    document.addEventListener('keydown', this.onKeyDown);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
  methods: {
    indexButtons() {
      this.buttons.forEach((btn, idx) => {
        btn.innerHTML = `${idx + 1}. ${btn.innerHTML}`;
      });
    },
    onKeyDown(e) {
      if (this.captureKeyPress) {
        const num = parseInt(e.key);
        if (!isNaN(num)) {
          this.focusBtn(num - 1);
        }
      }
    },
    focusBtn(idx) {
      const btn = this.buttons[idx];
      if (btn) {
        btn.focus();
      }
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.hc {
  display: flex;
  justify-content: center;
}

.m2 {
  margin: 2px;
}
</style>
