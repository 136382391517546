<template>
  <div>
    <div class="row">
      <div class="col s7">
        <h2>Mandate</h2>
      </div>
      <div class="col s2 text--right edit-link">
        <a @click="$emit('fetch')">
          <i class="fas fa-archive"></i>
          {{ showAllMandates ? 'Hide' : 'Show' }} archived
        </a>
      </div>
      <div class="col s2 text--right edit-link">
        <refresh-button :is-loading="isIpdSync" label="UPDATE FROM IPD" @click="ipdSync" />
      </div>
      <div class="col s1 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit"></i>
          Edit
        </a>
      </div>
    </div>

    <div class="card">
      <div class="row flush--bottom">
        <div class="col s12">
          <table>
            <thead>
              <th class="thin-column"></th>
              <th>Right</th>
              <th>Society</th>
              <th>Member type</th>
              <th>Member from</th>
              <th>Member to</th>
            </thead>
            <tbody>
              <template v-if="mandates && mandates.length > 0">
                <template v-for="(mandateGroup, index) in mandates">
                  <tr v-if="mandateGroup.mandates[0].id !== -1" :key="`group-${index}-tr1`">
                    <td
                      class="dropdown-button__arrow"
                      @click="toggleDetails(mandateGroup.right, $event)"
                    >
                      <i
                        :class="`fas fa-caret-${rightExpanded(mandateGroup.right) ? 'up' : 'down'}`"
                      ></i>
                    </td>
                    <td class="fs-14">
                      {{ rightName(mandateGroup.right) }}
                      <span
                        v-if="
                          mandateGroup.group_conflicts && mandateGroup.group_conflicts.length > 0
                        "
                        class="has_conflict"
                        :class="getResolvedColor(mandateGroup.group_conflicts)"
                      >
                        <i class="fas fa-exclamation-circle"></i>
                        Conflict
                      </span>
                    </td>
                    <td>
                      <span
                        v-for="(mandate, mIndex) in getUniqueBy(
                          mandateGroup.mandates,
                          'society_code',
                        )"
                        :key="mandate.id"
                      >
                        <span v-if="mIndex > 0">,&nbsp;</span>
                        <span>{{ $filters.formatSociety(mandate.society_code) }}</span>
                      </span>
                    </td>
                    <td>
                      <span
                        v-for="(mandate, mIndex) in getUniqueBy(
                          mandateGroup.mandates,
                          'mandate_type',
                        )"
                        :key="mandate.id"
                      >
                        <span v-if="mIndex > 0">,&nbsp;</span>
                        <span>{{ mandateTypeName(mandate.mandate_type) }}</span>
                      </span>
                    </td>
                    <td>
                      <span
                        v-for="(mandate, mIndex) in getUniqueBy(
                          mandateGroup.mandates,
                          'start_date',
                        )"
                        :key="mandate.id"
                      >
                        <span v-if="mIndex > 0">,&nbsp;</span>
                        <span>{{ mandate.start_date }}</span>
                      </span>
                    </td>
                    <td>
                      <span
                        v-for="(mandate, mIndex) in getUniqueBy(mandateGroup.mandates, 'end_date')"
                        :key="mandate.id"
                      >
                        <span v-if="mIndex > 0">,&nbsp;</span>
                        <span v-if="mandate.end_date">
                          {{ mandate.end_date }}
                        </span>
                        <span v-else class="none">None</span>
                      </span>
                    </td>
                  </tr>

                  <template v-if="rightExpanded(mandateGroup.right)">
                    <tr
                      v-for="mandate in mandateGroup.mandates"
                      :key="`group-${mandate.id}`"
                      class="expand-info"
                    >
                      <td></td>
                      <td colspan="3">
                        <div class="mandate-info">
                          <table>
                            <tr>
                              <td class="text--bold">Society</td>
                              <td>
                                {{ $filters.formatSociety(mandate.society_code) }}
                              </td>
                            </tr>
                            <tr :class="{ conflict: mandate.conflicts }">
                              <td class="text--bold">Member type</td>
                              <td>
                                {{ mandateTypeName(mandate.mandate_type) }}
                                <template
                                  v-if="
                                    mandate.mandate_type === 'R+' || mandate.mandate_type === 'WW-'
                                  "
                                >
                                  <span
                                    v-if="mandate.territories && mandate.territories.length > 0"
                                    class="territories-exceptions"
                                  >
                                    <span v-if="mandate.mandate_type === 'R+'">Including:</span>
                                    <span v-else>Excluding:</span>
                                    <span>
                                      {{ mandate.territories.map(territoryName).join(', ') }}
                                    </span>
                                  </span>
                                </template>
                              </td>
                            </tr>
                            <tr>
                              <td class="text--bold">Member from</td>
                              <td>
                                {{ mandate.start_date }}
                              </td>
                            </tr>
                            <tr>
                              <td class="text--bold">Member to</td>
                              <td>
                                <span v-if="mandate.end_date">{{ mandate.end_date }}</span>
                                <span v-else class="none">None</span>
                              </td>
                            </tr>
                            <tr>
                              <td class="text--bold">Member no</td>
                              <td>
                                <span v-if="mandate.member_no">{{ mandate.member_no }}</span>
                                <span v-else class="none">None</span>
                              </td>
                            </tr>
                            <tr>
                              <td class="text--bold">Performance activity</td>
                              <td>
                                <span>N/A</span>
                              </td>
                            </tr>
                            <tr>
                              <td class="text--bold">Conditional aspect</td>
                              <td>
                                <span>N/A</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </td>
                      <td></td>
                    </tr>
                    <td :key="`group-${index}-td1`" />
                    <td :key="`group-${index}-td2`" colspan="3">
                      <div
                        v-for="(groupConflict, gcIndex) in mandateGroup.group_conflicts"
                        :key="`${gcIndex}-group-conflict`"
                        class="group-conflict"
                        :class="getResolvedColor(groupConflict)"
                      >
                        <table>
                          <thead>
                            <th colspan="3">
                              {{ $filters.formatSociety(groupConflict.society_code) }} -
                              {{
                                $filters.formatSociety(
                                  groupConflict.conflictingMandate.society_code,
                                )
                              }}
                            </th>
                          </thead>
                          <tr>
                            <td class="text--bold">Discovered:</td>
                            <td>
                              {{ groupConflict.detected_date }}
                            </td>
                          </tr>
                          <tr v-if="!groupConflict.resolved">
                            <td class="text--bold">Blocked until:</td>
                            <td>
                              {{ groupConflict.deadline_date }}
                            </td>
                          </tr>
                          <tr v-if="groupConflict.winning_society_code">
                            <td class="text--bold">Winning Society:</td>
                            <td>
                              {{ $filters.formatSociety(groupConflict.winning_society_code) }}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </template>
                </template>
              </template>
              <template v-else>
                <tr>
                  <td colspan="1000" class="none">None</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import PerformerService from '../../../../services/performerService';
import RefreshButton from '../../../ui/button/refresh-button';
import { MANDATE_DEFAULT } from '../../../../domain/performerDomain';

export default {
  name: 'MandateInformation',
  components: {
    RefreshButton,
  },
  props: {
    showAllMandates: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MANDATE_DEFAULT,
      expandedRightCodes: [],
      isIpdSync: false,
    };
  },
  computed: {
    ...mapGetters('performer', ['id', 'mandates']),
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
    mandateRights() {
      return this.$store.state.appdata.referenceData.mandateRights;
    },
  },
  methods: {
    rightName(code) {
      if (code === MANDATE_DEFAULT) {
        return 'All rights';
      }
      const rightObject = this.mandateRights.find((right) => right.code === code);
      return rightObject.name;
    },
    mandateTypeName(code) {
      switch (code) {
        case 'R':
          return 'Regional';
        case 'R+':
          return 'Regional+';
        case 'WW':
          return 'Worldwide';
        case 'WW-':
          return 'Worldwide-';
        default:
          return '';
      }
    },
    getUniqueBy(array, prop) {
      return _.uniqBy(array, prop);
    },
    territoryName(iso) {
      return this.countries.find((country) => country.iso === iso).name;
    },
    rightExpanded(code) {
      return _.includes(this.expandedRightCodes, code);
    },
    toggleDetails(code) {
      const index = _.indexOf(this.expandedRightCodes, code);
      if (index === -1) {
        this.expandedRightCodes.push(code);
      } else {
        this.expandedRightCodes.splice(index, 1);
      }
    },
    getResolvedColor(gc) {
      if (Array.isArray(gc)) {
        if (gc.some((gc) => !gc.resolved)) {
          return '';
        }

        return gc.every((g) => g.winning_society_code === '06' || g.society_code === '104')
          ? 'resolved-green'
          : 'resolved-yellow';
      }

      if (!gc.resolved) {
        return '';
      }

      return gc.winning_society_code === '06' || gc.society_code === '104'
        ? 'resolved-green'
        : 'resolved-yellow';
    },
    async ipdSync() {
      this.isIpdSync = true;
      try {
        await PerformerService.ipdSync(this.id);
        this.$emit('reload');
      } finally {
        this.isIpdSync = false;
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.mandate-info {
  border: 1px solid var(--grey--light);
  border-radius: 10px;
  padding: 10px;
}

.has_conflict {
  color: var(--alert--error);

  .territories-exceptions {
    color: var(--alert--error);
  }

  i {
    font-size: 18px;
    vertical-align: text-top;
    margin-left: 5px;
  }

  &-issue {
    background-color: var(--warning--light);
    margin-left: 5px;
    border-bottom: 5px solid var(--white);
  }

  &.is_resolved {
    color: var(--alert--warning);
  }
}

.group-conflict {
  border: 1px solid var(--alert--error);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  th {
    color: var(--alert--error);
  }
}

.resolved {
  &-yellow {
    &.has_conflict,
    th {
      color: var(--alert--warning);
    }
    &.group-conflict {
      border: 1px solid var(--alert--warning);
    }
  }

  &-green {
    &.has_conflict,
    th {
      color: var(--alert--success);
    }
    &.group-conflict {
      border: 1px solid var(--alert--success);
    }
  }
}

.territories-exceptions {
  color: var(--grey--medium);
  font-style: italic;
}
</style>
