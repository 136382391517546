/**
 * The Matching Service contains music report matching related methods.
 */
import { query, mutate } from './apolloRequest';
import gql from '../domain/matchingGql';
import worklistGql from '../domain/worklistGql';
import prepareForWire from './wirePrepper';

function mapCreateWorklistsCommand(reportId, reportType, partitionInfo) {
  const command = {
    reportId,
    reportType,
    partitionInfo: {
      worklist_ids: partitionInfo.worklistIds,
      size: Number.parseInt(partitionInfo.size, 10),
      country_code: partitionInfo.country_code,
      match_state: partitionInfo.match_state,
    },
  };
  prepareForWire(command);
  return command;
}

function mapMatchGroupCommand(groupId, matchId, username) {
  const command = {
    group_id: groupId,
    user: username,
  };
  if (matchId) {
    command.match_id = matchId;
  }
  return command;
}

export default {
  // Headers
  getReportHeader: (reportType, reportId, worklistId) =>
    query(
      { query: gql.getReportHeader(reportType), variables: { reportId, worklistId } },
      { pickProp: 'getReportHeader' },
    ),
  listReportHeaders: (request) =>
    query(
      { query: gql.listReportHeaders(request.report_type), variables: { request } },
      { pickProp: 'listReportHeaders' },
    ),
  listVrdbReportHeaders: (societyCode) =>
    query(
      { query: gql.listVrdbReportHeaders, variables: { societyCode } },
      { pickProp: 'listVrdbReportHeaders' },
    ),
  updateReportHeader: async (reportId, request) =>
    mutate({ mutation: gql.updateReportHeader, variables: { reportId, request } }),
  archiveReportHeader: (reportId) =>
    mutate({ mutation: gql.archiveReportHeader, variables: { reportId } }),

  // Played recording
  loadHits: (streamIds) =>
    query({ query: gql.loadHits, variables: { streamIds } }, { pickProp: 'loadHits' }),
  getLines: (request) =>
    query({ query: gql.getLines, variables: { request } }, { pickProp: 'getLines' }),
  getAlbumLines: (request) =>
    query({ query: gql.getAlbumLines, variables: { request } }, { pickProp: 'getAlbumLines' }),
  getDataUrls: async (process, subtype, filename, metadata) =>
    query(
      { query: gql.getDataUrls, variables: { request: { process, subtype, filename, metadata } } },
      { pickProp: 'getDataUrls' },
    ),
  identifyMatchLine: (groupId, matchId, username) =>
    mutate({
      mutation: gql.identifyMatchLine,
      variables: { command: { group_id: groupId, match_id: matchId, user: username } },
    }),
  ignoreMatchLine: (groupId, matchId, username) =>
    mutate({
      mutation: gql.ignoreMatchLine,
      variables: { command: mapMatchGroupCommand(groupId, matchId, username) },
    }),
  unmatchMatchLine: (groupId, matchId, username) =>
    mutate({
      mutation: gql.unmatchMatchLine,
      variables: { command: mapMatchGroupCommand(groupId, matchId, username) },
    }),
  revertMatch: (groupIds, username) =>
    mutate({
      mutation: gql.revertMatch,
      variables: { command: { group_ids: groupIds, user: username } },
    }),
  exportRecordings: (reportId, processId) =>
    mutate({
      mutation: gql.exportRecordings,
      variables: { command: { report_id: reportId, process_id: processId } },
    }),
  createNewFromRested: (reportId, groupIds, all) =>
    mutate({
      mutation: gql.createRestedRecordings,
      variables: { command: { report_id: reportId, group_ids: groupIds, all } },
    }),
  setWorklistIds: async (reportId, reportType, partitionInfo) =>
    mutate(
      {
        mutation: worklistGql.createMatchWorklists,
        variables: { command: mapCreateWorklistsCommand(reportId, reportType, partitionInfo) },
        fetchPolicy: 'network-only',
      },
      {},
    ),
  addUsageCorrection: async (command) =>
    mutate({ mutation: gql.addUsageCorrection, variables: { command } }),
  getUsageDetails: async (reportId, groupId) =>
    query(
      { query: gql.getUsageDetails, variables: { reportId, groupId } },
      { pickProp: 'getUsageDetails' },
    ),
  deletePlayedRecordingReport: async (reportId) =>
    mutate({ mutation: gql.deletePlayedRecordingReport, variables: { reportId } }),

  // Playlist
  fetchPlaylistRecordings: async (request) =>
    query(
      { query: gql.fetchPlaylistRecordings, variables: { request } },
      { pickProp: 'fetchPlaylistRecordings' },
    ),
  getPlaylistRecording: async (request) =>
    query(
      { query: gql.getPlaylistRecording, variables: { request } },
      { pickProp: 'getPlaylistRecording' },
    ),
  toggleLineupUpdatedAttributes: async (command) =>
    mutate(
      { mutation: gql.toggleLineupUpdatedAttributes, variables: { command } },
      { pickProp: 'toggleLineupUpdatedAttributes' },
    ),
  acceptPlaylistLineup: async (command) =>
    mutate(
      { mutation: gql.acceptPlaylistLineup, variables: { command } },
      { pickProp: 'acceptPlaylistLineup' },
    ),
  rejectPlaylistLineup: async (command) =>
    mutate({ mutation: gql.rejectPlaylistLineup, variables: { command } }),
  rejectPlaylistLineupLine: async (command) =>
    mutate({ mutation: gql.rejectPlaylistLineupLine, variables: { command } }),
  undoRejectPlaylistLineupLine: async (command) =>
    mutate({ mutation: gql.undoRejectPlaylistLineupLine, variables: { command } }),
  undoMergePlaylistLineupLine: async (command) =>
    mutate({ mutation: gql.undoMergePlaylistLineupLine, variables: { command } }),
  mergePlaylistLineupLine: async (command) =>
    mutate({ mutation: gql.mergePlaylistLineupLine, variables: { command } }),
  deletePlaylistLineupLine: async (command) =>
    mutate({ mutation: gql.deletePlaylistLineupLine, variables: { command } }),
  rematchPlaylistRecording: async (command) =>
    mutate({ mutation: gql.rematchPlaylistRecording, variables: { command } }),
  acceptPlaylistRecordingsLineup: async (command) =>
    mutate(
      { mutation: gql.acceptPlaylistRecordingsLineup, variables: { command } },
      { pickProp: 'acceptPlaylistRecordingsLineup' },
    ),
  rejectPlaylistRecordingsLineup: async (command) =>
    mutate({ mutation: gql.rejectPlaylistRecordingsLineup, variables: { command } }),
  applyAllPlaylistChanges: async (command) =>
    mutate({ mutation: gql.applyAllPlaylistChanges, variables: { command } }),
  createPlaylistRejectionFile: async (command) =>
    mutate(
      { mutation: gql.createPlaylistRejectionFile, variables: { command } },
      { pickProp: 'createPlaylistRejectionFile' },
    ),
  deletePlaylistReport: async (reportId) =>
    mutate({ mutation: gql.deletePlaylistReport, variables: { reportId } }),

  // Album
  loadAlbumHits: (streamIds) =>
    query({ query: gql.loadAlbumHits, variables: { streamIds } }, { pickProp: 'loadAlbumHits' }),
  createStimFile: async (reportId) =>
    mutate(
      { mutation: gql.createStimFile, variables: { reportId }, fetchPolicy: 'network-only' },
      { pickProp: 'createStimFile' },
    ),
  identifyMatchAlbumLine: (groupId, matchId, username) =>
    mutate({
      mutation: gql.identifyMatchAlbumLine,
      variables: { command: { group_id: groupId, match_id: matchId, user: username } },
    }),
  ignoreMatchAlbumLine: (groupId, matchId, username) =>
    mutate({
      mutation: gql.ignoreMatchAlbumLine,
      variables: { command: mapMatchGroupCommand(groupId, matchId, username) },
    }),
  revertAlbumMatch: (groupIds, username) =>
    mutate({
      mutation: gql.revertAlbumMatch,
      variables: { command: { group_ids: groupIds, user: username } },
    }),
  createNewAlbumFromRested: (reportId, groupIds, all) =>
    mutate({
      mutation: gql.createRestedAlbums,
      variables: { command: { report_id: reportId, group_ids: groupIds, all } },
    }),
  exportAlbums: (reportId, processId) =>
    mutate({
      mutation: gql.exportAlbums,
      variables: { command: { report_id: reportId, process_id: processId } },
    }),
};
