import { AGGREGATE_TYPES } from '../../domain/common';
import PerformerService from '../../services/performerService';

/**
 * Creates the config for performer SSN-valition using ssnUnique-validator (vee-validate).
 * @returns {Object} the config
 */
// eslint-disable-next-line
export const ssnUnique = {
  entityType: AGGREGATE_TYPES.PERFORMER,
  originalValue: undefined,
  validator: (value) =>
    PerformerService.isUniqueSSN(value).then((result) => ({
      valid: result.unique,
      data: result.unique ? undefined : result,
    })),
};
