import gql from 'graphql-tag';

const projectors = gql`
  query {
    projectors {
      events {
        events
        sequence_no
      }
      projectors {
        service
        src
        unprocessed
        sequence_no
      }
    }
  }
`;

export default {
  projectors,
};
