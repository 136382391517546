<template>
  <div class="upload-sdeg-file">
    <div class="row small-inner">
      <div class="col s12">
        <h2>
          Upload
          <span v-if="displaySource === 'played-recordings'">played recordings</span>
          <span v-else>claims</span>
          file
        </h2>
      </div>
    </div>

    <div class="card">
      <form class="row" :data-vv-scope="scope">
        <div class="file-upload">
          <div class="col s2">
            <select-society
              label="Society"
              :scope="scope"
              :value="uploadFile.society"
              :extended-result="true"
              @input="
                (evt) => {
                  uploadFile.society = evt.localCode.code;
                }
              "
            />
          </div>
          <div class="col s2">
            <validated-text-input
              name="uploadFile.name"
              label="Name"
              :placeholder="
                displaySource === 'played-recordings'
                  ? 'E.g. Played recordings file from PPL'
                  : 'Claims from PPL'
              "
              :value="uploadFile.name"
              :scope="scope"
              @input="
                (value) => {
                  uploadFile.name = value;
                }
              "
            />
          </div>
          <div class="col s2">
            <validated-text-input
              name="uploadFile.from"
              label="Performance year from"
              placeholder="YYYY"
              :value="uploadFile.from"
              :rule="{ year: true, min: 4 }"
              :maxlength="4"
              :scope="scope"
              @input="
                (value) => {
                  uploadFile.from = value;
                }
              "
            />
          </div>
          <div class="col s2">
            <validated-text-input
              name="uploadFile.to"
              label="Performance year to"
              placeholder="YYYY"
              :value="uploadFile.to"
              :rule="
                !!uploadFile.from
                  ? { min_value: uploadFile.from, year: true, min: 4 }
                  : { year: true, min: 4 }
              "
              :maxlength="4"
              :scope="scope"
              @input="
                (value) => {
                  uploadFile.to = value;
                }
              "
            />
          </div>
          <div class="col s2">
            <select-date v-model="deadline" name="deadline" label="Deadline date" :scope="scope" />
          </div>
          <div class="col s2">
            <span>
              File to upload
              {{ displaySource === 'played-recordings' ? '(xml UTF-8)' : '(xml UTF-8, xls)' }}
            </span>
            <div class="file-input nowrap" action="" method="POST">
              <input
                id="file"
                type="file"
                :accept="displaySource === 'played-recordings' ? '.xml' : '.xml,.xls,.xlsx'"
                @change="addFile"
              />

              <span ref="fileUploadName">
                <i class="far fa-file-alt"></i>
                Drag your files here or click in this area.
              </span>
            </div>
          </div>
        </div>
      </form>

      <div v-if="displaySource === 'played-recordings'" class="row">
        <div class="col offset-s10 s2 text--right">
          <input
            id="fakeIds"
            name="fakeIds"
            class="filled-in"
            type="checkbox"
            :checked="false"
            @change="updateSaveRec($event.target.checked)"
          />
          <label for="fakeIds">Contains Fake LocalCodes</label>
        </div>
      </div>

      <div class="row flush">
        <div class="col offset-s8 s3 margin--top">
          <ProgressBar
            v-if="isUploadingFile && !error"
            label="Uploading"
            :total="100"
            :processed="uploadFile.uploadProgress"
            :show-percent="true"
          />
        </div>

        <div class="col s1 text--right">
          <button
            class="btn secondary abs-top"
            type="submit"
            :disabled="!validForm || isUploadingFile"
            @click.prevent="uploadSdegFile"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import SelectSociety from '../ui/select/select-society';
import ValidatedTextInput from '../ui/input/validated-text-input';
import { createSdegFile } from '../../domain/matchingDomain';
import RemoteFileHelper from '../../common/remoteFileHelper';
import RemoteFileService from '../../services/remoteFileService';
import { getConfigValue, PROPS } from '../../services/configService';
import SelectDate from '../ui/select/select-date';
import ProgressBar from '../ui/progress-bar';

export default {
  name: 'UploadSdegFile',
  components: {
    SelectSociety,
    ValidatedTextInput,
    SelectDate,
    ProgressBar,
  },
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scope: 'upload-sdeg-file',
      uploadFile: createSdegFile(null, 'sdeg', '', '', '', '', '', 0),
      isUploadingFile: false,
      displaySource: '',
      fakeIds: false,
      deadline: '',
    };
  },
  computed: {
    validForm() {
      return (
        !this.validationErrors.any(this.scope) &&
        this.uploadFile.file &&
        this.uploadFile.society &&
        this.uploadFile.name &&
        this.uploadFile.to &&
        this.uploadFile.from
      );
    },
  },
  watch: {
    $route() {
      this.setSource();
    },
    async isUploadingFile(isUploadingFile, wasUploading) {
      if (wasUploading && !isUploadingFile) {
        this.$emit('fetchData');
      }
    },
  },
  created() {
    this.setSource();
  },
  methods: {
    setSource() {
      if (
        this.$router.currentRoute.name === 'sdegPlayedRecordingsOverview' ||
        this.$router.currentRoute.name === 'sdegSocietyPlayedRecordingsOverview'
      ) {
        this.displaySource = 'played-recordings';
      } else {
        this.displaySource = 'claims';
      }
    },
    async uploadSdegFile() {
      await this.upload();
      this.$emit('fetchData');
    },
    async upload() {
      this.isUploadingFile = true;
      this.error = false;
      const filename = RemoteFileHelper.createSdegUploadFilename(this.uploadFile);
      const fileSuffix = filename.slice(filename.lastIndexOf('.') + 1);
      if (!RemoteFileService.isValidFileExtension(fileSuffix)) {
        this.$addStarError(`Unsupported file extension: ${fileSuffix}`);
        return;
      }
      const processId = uuid.v4();
      this.$store.dispatch('process/addProcess', {
        name: `SDEG Parsing - ${filename}`,
        id: processId,
      });
      const fileDescriptor = {
        filename,
        localFile: this.uploadFile.file,
        fileSuffix,
      };
      const claimsPayload = `performanceYearFrom=${this.uploadFile.from}&performanceYearTo=${
        this.uploadFile.to
      }&deadline=${this.deadline || ''}`;
      const playedRecordingsPayload = `${claimsPayload}&containsFakeIds=${this.fakeIds}`;

      const options = {
        repo: getConfigValue(PROPS.DATA_BUCKET),
        directory: `sdeg/${this.displaySource}/inbox`,
        metadata: [
          {
            key: 'society',
            value: this.uploadFile.society,
          },
          {
            key: 'user-file-name',
            value: this.uploadFile.name,
          },
          {
            key: 'original-file-name',
            value: encodeURIComponent(this.uploadFile.file.name),
          },
          {
            key: 'payload',
            value:
              this.displaySource === 'played-recordings' ? playedRecordingsPayload : claimsPayload,
          },
          {
            key: 'process_id',
            value: processId,
          },
        ],
        progressFunc: (progress) =>
          (this.uploadFile.uploadProgress = Math.round((progress.loaded * 100) / progress.total)),
      };
      this.uploadFile.uploadProgress = 0;
      try {
        await RemoteFileService.uploadToDocumentRepository(fileDescriptor, options);
        this.resetFileUpload();
      } catch (error) {
        this.isUploadingFile = false;
        this.handleError('Upload failed', error);
      }
    },
    handleError(title, error) {
      error.title = title;
      this.$addStarError(error);
      this.isFetchingHeaders = false;
    },
    addFile(upload) {
      this.$refs.fileUploadName.innerHTML = upload.target.files[0].name;
      this.uploadFile.file = upload.target.files[0];
      this.uploadFile.source = this.displaySource;
    },
    resetFileUpload() {
      this.isUploadingFile = false;
      this.$refs.fileUploadName.innerHTML = 'Drag your files here or click in this area.';
      this.uploadFile = createSdegFile(null, 'sdeg', '', '', '', '', '', 0);
      this.error = false;
      this.deadline = '';
    },
    updateSaveRec(checked) {
      this.fakeIds = checked;
    },
  },
};
</script>
