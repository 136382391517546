<template>
  <tbody>
    <template v-for="(line, index) in reportLines">
      <tr
        ref="line"
        :key="`${index}-tr1`"
        class="cursor-pointer"
        @click="toggleExpanded(line.incoming.group_id)"
      >
        <td class="compare__arrow">
          <i :class="`fas fa-caret-${isExpanded(line.incoming.group_id) ? 'up' : 'down'}`" />
        </td>
        <td v-if="selectable" class="compare__checkbox">
          <input
            :id="index"
            ref="mark"
            v-model="line.checked"
            class="filled-in"
            type="checkbox"
            name="mark"
            :disabled="line.completed"
            @click.stop
          />
          <label :for="index" @click.stop />
        </td>
        <td
          class="fs-14"
          :class="{
            'compare__table-item-row--diff': hasDiff(
              line.incoming.title,
              getMatchedBasicInfoProp(line, 'name'),
            ),
            'text--orange': line.completed && ['I', 'R'].includes(line.incoming.match_state),
            'text--green': line.completed && !['I', 'R'].includes(line.incoming.match_state),
          }"
        >
          <span v-html="line.incoming.title"></span>
          <i v-if="line.completed" class="fas fa-check-circle" />
        </td>
        <template v-if="!isExpanded(line.incoming.group_id) && !album">
          <td><span v-html="line.incoming.version_title"></span></td>
          <td
            :class="{
              'compare__table-item-row--diff': hasDiff(
                line.incoming.main_artist,
                getMatchedBasicInfoProp(line, 'main_artist.name'),
              ),
            }"
          >
            {{ line.incoming.main_artist }}
          </td>
          <td
            :class="{
              'compare__table-item-row--diff': hasDifferentYear(
                line.incoming.recording_year,
                getMatchedBasicInfoProp(line, 'recording_date'),
              ),
            }"
          >
            {{ line.incoming.recording_year }}
          </td>
          <td
            :class="{
              'compare__table-item-row--diff': hasDiff(
                line.incoming.recording_country,
                getMatchedBasicInfoProp(line, 'recorded_in_country'),
              ),
            }"
          >
            {{ $filters.formatCountry(line.incoming.recording_country) }}
          </td>
          <td
            :class="{
              'compare__table-item-row--diff': hasDiff(
                line.incoming.isrc,
                getMatchedBasicInfoProp(line, 'isrc'),
              ),
            }"
          >
            {{ line.incoming.isrc }}
          </td>
          <td
            :class="{
              'compare__table-item-row--diff': hasDiff(
                line.incoming.label,
                getMatchedBasicInfoProp(line, 'label'),
              ),
            }"
          >
            {{ line.incoming.label }}
          </td>
          <td v-if="!sdeg">{{ $filters.toMinutes(line.incoming.airplay_time) }}</td>
          <td>{{ line.incoming.line_count }}</td>
          <td v-if="!sdeg">{{ line.incoming.hits && line.incoming.hits.length }}</td>
          <td v-if="showSamiMember">
            <span v-if="line.incoming.sami_member">
              <i class="fas fa-user"></i>
            </span>
            <span v-else class="none">None</span>
          </td>
        </template>
        <template v-if="!isExpanded(line.incoming.group_id) && album">
          <td>
            {{ line.incoming.main_artist !== 'null' ? line.incoming.main_artist : 'Unknown' }}
          </td>
          <td
            :class="{
              'compare__table-item-row--diff': hasDifferentYear(
                line.incoming.total_count,
                getMatchedBasicInfoProp(line, 'total_count'),
              ),
            }"
          >
            {{ line.incoming.total_count }}
          </td>
        </template>
        <td v-else colspan="1000"></td>
      </tr>
      <template v-if="isExpanded(line.incoming.group_id) && !album">
        <tr :key="`${index}-tr2`" class="prevent-hover-bg no-borders">
          <td colspan="1000">
            <div v-if="loading" class="compare__loading">
              <spinner />
            </div>
            <div v-else class="compare__nested">
              <div class="compare__nested-main compare__main-divide">
                <span class="compare__nested-heading compare__nested-heading--incoming">
                  <strong>Played recording:</strong>
                  <span v-html="line.incoming.title"></span>
                </span>
                <span class="compare__nested-heading compare__nested-heading--candidate">
                  <strong>Star suggestion:</strong>
                  <a :href="`#/recordings/${getCandidateValue('id')}`">
                    {{ getCandidateValue('basic_info.name') }}
                    {{ getCandidateValue('lineup_locked') ? ' 🔒' : '' }}
                  </a>
                </span>
                <ul class="compare__nested-list">
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.name',
                      value: getCandidateValue('basic_info.name'),
                      displayFilter: 'prettyPrintArray',
                    }"
                    :incoming="{
                      key: 'title',
                      value: line.incoming.title,
                      displayFilter: 'toTitleCase',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.alternate_titles',
                      value: getCandidateValue('basic_info.alternate_titles'),
                      displayFilter: 'prettyPrintArray',
                    }"
                    :incoming="{
                      key: 'alternate_titles',
                      value: line.incoming.alternate_titles,
                      displayFilter: 'prettyPrintArray',
                    }"
                    :allow-action="allowAction && !line.completed"
                    :compute-new-value="mergeAlternateTitles"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.version_title',
                      value: getCandidateValue('basic_info.version_title'),
                    }"
                    :incoming="{
                      key: 'basic_info.version_title',
                      value: line.incoming.version_title,
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.main_artist.name',
                      value: getCandidateValue('basic_info.main_artist.name'),
                    }"
                    :incoming="{
                      key: 'main_artist',
                      value: line.incoming.main_artist,
                    }"
                    :original-value="getCandidateValue('basic_info.main_artist')"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.duration_sec',
                      value: getCandidateValue('basic_info.duration_sec'),
                      displayFilter: 'toMinutes',
                    }"
                    :incoming="{
                      key: 'duration_sec',
                      value: line.incoming.duration_sec,
                      displayFilter: 'toMinutes',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'albums',
                      value: getCandidateValue('albums'),
                      displayFilter: 'prettyPrintAlbumArray',
                    }"
                    :incoming="{
                      key: 'album',
                      value: line.incoming.album,
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.recording_date',
                      value: getCandidateValue('basic_info.recording_date'),
                      displayFilter: 'formatRecordingDate',
                    }"
                    :incoming="{
                      key: 'recording_year',
                      value: line.incoming.recording_year,
                    }"
                    :comparator="'yearsMatch'"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.recorded_in_country',
                      value: getCandidateValue('basic_info.recorded_in_country'),
                      displayFilter: 'formatCountry',
                    }"
                    :incoming="{
                      key: 'recording_country',
                      value: line.incoming.recording_country,
                      displayFilter: 'formatCountry',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.produced_in_country',
                      value: getCandidateValue('basic_info.produced_in_country'),
                      displayFilter: 'formatCountry',
                    }"
                    :incoming="{
                      key: 'produced_in_country',
                      value: line.incoming.produced_in_country,
                      displayFilter: 'formatCountry',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.isrc',
                      value: getCandidateValue('basic_info.isrc'),
                    }"
                    :incoming="{
                      key: 'isrc',
                      value: line.incoming.isrc,
                    }"
                    :allow-action="sdeg ? false : allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: getCandidateVrdbInfoValue().key,
                      value: getCandidateVrdbInfoValue().id,
                    }"
                    :incoming="{
                      key: 'vrdb2_id',
                      value: line.incoming.vrdb2_id,
                    }"
                    :allow-action="false"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.label',
                      value: getCandidateValue('basic_info.label'),
                      displayFilter: 'toTitleCase',
                    }"
                    :incoming="{
                      key: 'label',
                      value: line.incoming.label,
                      displayFilter: 'toTitleCase',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.genre',
                      value: getCandidateValue('basic_info.genre'),
                      displayFilter: 'formatGenre',
                    }"
                    :incoming="{
                      key: 'genre',
                      value: line.incoming.genre,
                      displayFilter: 'formatGenre',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.composer',
                      value: getCandidateValue('basic_info.composer'),
                      displayFilter: 'prettyPrintNames',
                    }"
                    :incoming="{
                      key: 'composer',
                      value: line.incoming.composer,
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.writer',
                      value: getCandidateValue('basic_info.writer'),
                    }"
                    :incoming="{
                      key: 'writer',
                      value: line.incoming.writer,
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.arranger',
                      value: getCandidateValue('basic_info.arranger'),
                    }"
                    :incoming="{
                      key: 'arranger',
                      value: line.incoming.arranger,
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.local_codes_label',
                      value: getCandidateValue('basic_info.local_codes').filter((lc) =>
                        sdeg ? ['SR', 'public_id'].includes(lc.code) : true,
                      ),
                      displayFilter: 'prettyPrintCodeValueObject',
                    }"
                    :incoming="{
                      key: 'basic_info.local_codes_label',
                      value: line.incoming.local_ids,
                      displayFilter: 'prettyPrintArray',
                    }"
                    :allow-action="sdeg ? false : allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                </ul>
              </div>

              <CompareAirtime
                :playtime="line.incoming.airplay_time"
                :linecount="line.incoming.line_count"
              />

              <CompareLineupMatching />

              <CompareButtonsMatching
                v-if="allowAction && !line.completed"
                :candidate-changed="candidateChanged"
                :handle-identify-btn="
                  sdeg && !samiLineupMember() ? handleIgnoreBtn : handleIdentifyBtn
                "
                :handle-ignore-btn="sdeg ? handleUnmatchBtn : handleIgnoreBtn"
                :handle-new-recording="handleNewRecording"
                :handle-save-btn="handleSaveBtn"
                :line="line"
                :revert-match="revertMatch"
              />
            </div>
          </td>
        </tr>
      </template>
      <template v-if="isExpanded(line.incoming.group_id) && album">
        <tr :key="`${index}-tr2`" class="prevent-hover-bg no-borders">
          <td colspan="1000">
            <div v-if="loading" class="compare__loading">
              <spinner />
            </div>
            <div v-else class="compare__nested">
              <div class="compare__nested-main compare__main-divide">
                <span class="compare__nested-heading compare__nested-heading--incoming">
                  <strong>Played Album:</strong>
                  <span v-html="line.incoming.title"></span>
                </span>
                <span class="compare__nested-heading compare__nested-heading--candidate">
                  <strong>Star suggestion:</strong>
                  <a :href="`#/albums/${getCandidateValue('id')}`">
                    {{ getCandidateValue('basic_info.name') }}
                  </a>
                </span>
                <ul class="compare__nested-list">
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.alternate_titles',
                      value: candidateAllTitles,
                      displayFilter: 'prettyPrintArray',
                    }"
                    :incoming="{
                      key: 'title',
                      value: line.incoming.title,
                      displayFilter: 'toTitleCase',
                    }"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.main_artist.name',
                      value: getCandidateValue('basic_info.main_artist.name'),
                    }"
                    :incoming="{
                      key: 'main_artist',
                      value: line.incoming.main_artist,
                    }"
                    :original-value="getCandidateValue('basic_info.main_artist')"
                    :allow-action="allowAction && !line.completed"
                    @valueUpdated="candidateValueUpdated"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.no_tracks',
                      value: getCandidateValue('tracks').length,
                    }"
                    :incoming="{
                      key: 'no_tracks',
                      value: '0',
                    }"
                    :allow-action="false"
                  />

                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.catalog_number',
                      value: getCandidateValue('basic_info.catalog_number'),
                    }"
                    :incoming="{
                      key: 'catalog_number',
                      value: '',
                    }"
                    :allow-action="false"
                  />
                </ul>
              </div>

              <CompareAirtime
                v-if="!album"
                :playtime="line.incoming.airplay_time"
                :linecount="line.incoming.line_count"
              />

              <CompareLineupMatching v-if="!album" />

              <CompareButtonsMatching
                v-if="allowAction && !line.completed"
                :candidate-changed="candidateChanged"
                :handle-identify-btn="
                  sdeg && !samiLineupMember() ? handleIgnoreBtn : handleIdentifyBtn
                "
                :handle-ignore-btn="sdeg ? handleUnmatchBtn : handleIgnoreBtn"
                :handle-new-recording="handleNewRecording"
                :handle-save-btn="handleSaveBtn"
                :is-album="album"
                :line="line"
                :revert-match="revertMatch"
              />
            </div>
          </td>
        </tr>
      </template>
    </template>
  </tbody>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapGetters } from 'vuex';
import CompareGridRow from './compare-grid-row';
import CompareAirtime from './compare-airtime';
import CompareLineupMatching from './compare-lineup-matching';
import CompareButtonsMatching from './compare-buttons-matching';
import Spinner from '../spinner';
import * as TypeUtils from '../../common/typeUtils';

export default {
  name: 'CompareListMatching',
  components: {
    CompareAirtime,
    CompareButtonsMatching,
    CompareGridRow,
    CompareLineupMatching,
    Spinner,
  },
  props: {
    allowAction: {
      type: Boolean,
      default: true,
    },
    candidateChanged: {
      type: Function,
    },
    revertMatch: {
      type: Function,
    },
    isExpanded: {
      type: Function,
    },
    toggleExpanded: {
      type: Function,
    },
    handleIdentifyBtn: {
      type: Function,
    },
    handleIgnoreBtn: {
      type: Function,
    },
    handleUnmatchBtn: {
      type: Function,
    },
    handleSaveBtn: {
      type: Function,
    },
    handleNewRecording: {
      type: Function,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    localCodeId: {
      type: String,
      default: undefined,
    },
    sdeg: {
      type: Boolean,
      default: false,
    },
    album: {
      type: Boolean,
      default: false,
    },
    showSamiMember: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('matching', ['candidates', 'currentIncoming', 'currentCandidate', 'reportLines']),
    loading() {
      return !(this.currentIncoming && this.currentCandidate && this.candidates);
    },
    candidateAllTitles() {
      if (!this.getCandidateValue('basic_info.name')) {
        return null;
      }
      return [
        this.getCandidateValue('basic_info.name'),
        ...this.getCandidateValue('basic_info.alternate_titles'),
      ];
    },
  },
  methods: {
    ...mapMutations('matching', ['updateCurrentCandidate']),
    mergeAlternateTitles(old, _new) {
      return _.union(old, _new).sort();
    },
    getCandidateValue(path) {
      return _.get(this.currentCandidate, path.split('.')) || '';
    },
    getCandidateVrdbInfoValue() {
      if (
        this.currentCandidate.basic_info.vrdb2_id &&
        this.currentCandidate.basic_info.vrdb2_id.length > 0
      ) {
        return { id: this.currentCandidate.basic_info.vrdb2_id, key: 'vrdb2_id_attested' };
      }
      if (this.currentCandidate.basic_info.local_codes) {
        const associated = this.currentCandidate.basic_info.local_codes.find(
          (obj) => obj.code.toUpperCase() === 'VRDB2_ASSOCIATED',
        );
        if (associated) {
          return { id: associated.value, key: 'vrdb2_id_associated' };
        }
        const playlist = this.currentCandidate.basic_info.local_codes.find(
          (obj) => obj.code.toUpperCase() === 'VRDB2_PLAYLIST_ID',
        );
        if (playlist) {
          return { id: playlist.value, key: 'vrdb2_id_playlist' };
        }
      }
      return { id: undefined, key: 'vrdb2_id' };
    },
    candidateValueUpdated(obj) {
      let mutationPath = TypeUtils.camelCase(obj.data.key.replace(/\./g, '_'), true);
      if (obj.data.key === 'basic_info.main_artist.name') {
        mutationPath = mutationPath.replace(/Name$/g, '');
        let value = obj.data.value;
        if (!value.name) {
          value = { name: obj.data.value };
        }
        this.$store.commit(`matching/${obj.action}CurrentCandidate${mutationPath}`, value);
        // detta borde kunna tas bort om vi tar bort add-knappen från gränssnittet
      } else if (obj.data.key === 'basic_info.local_codes_label') {
        let updateVal = [];
        if (obj.revert) {
          updateVal = obj.data.value;
        } else {
          const addLc = obj.data.value.map((v) => ({
            code: v.split(':')[0],
            value: v.split(':')[1],
          }));
          const existingLc = this.getCandidateValue('basic_info.local_codes');
          addLc.forEach((lc) => {
            if (!existingLc.find((eLc) => eLc.code === lc.code && eLc.value === lc.value)) {
              updateVal.push(lc);
            }
          });
          updateVal.push(...existingLc);
        }
        this.$store.commit('matching/updateCurrentCandidateBasicInfoLocalCodes', updateVal);
      } else {
        this.$store.commit(`matching/${obj.action}CurrentCandidate${mutationPath}`, obj.data.value);
      }
      this.$nextTick(() => {
        this.$emit('change', this.currentIncoming.group_id);
      });
    },
    getMatchedBasicInfoProp(line, path) {
      return _.get(line, `matched.basic_info.${path}`, false);
    },
    hasDiff(matchedValue, starValue) {
      if (matchedValue && starValue) {
        return !this.valuesMatchRelaxed(matchedValue, starValue);
      }
      return false;
    },
    hasDifferentYear(matchedYear, starDate) {
      if (matchedYear && starDate) {
        return !this.yearsMatch(matchedYear, starDate);
      }
      return false;
    },
    samiLineupMember() {
      return this.getCandidateValue('lineup').some(
        (l) =>
          l.relation.societies &&
          l.relation.societies.some(
            (s) => s.society_code === '06' && ['WW', 'WW-', 'R+'].includes(s.mandate_type),
          ),
      );
    },
  },
};
</script>
