<template>
  <div>
    <div class="cmp">
      <div class="row">
        <div class="col s2 hide-on-med-and-down">
          <entity-tag />
        </div>
        <div class="col s12 l7">
          <div class="row">
            <div class="col s12">
              <h1>Create Society</h1>
            </div>
          </div>

          <form :data-vv-scope="scope">
            <create-menu entity-type="society" @create="createSocietyAndNavigateToView" />

            <create-basic-info />

            <create-contact-info />

            <create-payment-information
              :foreign-account="paymentInfoForeignAccount"
              :company-account-visible="false"
              :show-private-account="false"
              @resetPaymentInfo="resetPaymentInfo"
              @addForeignAccount="commitChange('addForeignAccount', $event)"
            />

            <create-agreements @input="onUpdateAgreements" />

            <create-meta-information />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import {
  dispatch as vuexDispatch,
  getAggregate,
  commit,
} from '../../../store/modules/society/utils';
import EntityTag from '../../ui/entity-tag';
import CreateAgreements from './create-agreements';
import CreateBasicInfo from './create-basic-info';
import CreateContactInfo from './create-contact-info';
import CreateMetaInformation from './create-meta-info';
import CreateMenu from '../../ui/create-menu';
import CreatePaymentInformation from '../../ui/payment/create-payment-information';
import SocietyService from '../../../services/societyService';
import UploadContracts from '../uploadContracts';

export default {
  name: 'CreateSociety',
  components: {
    EntityTag,
    CreateAgreements,
    CreateBasicInfo,
    CreatePaymentInformation,
    CreateContactInfo,
    CreateMetaInformation,
    CreateMenu,
  },
  $_veeValidate: {
    validator: 'new',
  },
  data() {
    return {
      scope: 'create-society',
      error: false,
    };
  },
  computed: {
    ...mapGetters('society', ['paymentInfoForeignAccount', 'basicInfoName']),
  },
  created() {
    this.initAggregate();
  },
  methods: {
    ...mapMutations('society', ['updateAgreements']),
    resetPaymentInfo() {
      commit('resetPaymentInfo');
    },
    commitChange(type, event) {
      this.resetPaymentInfo();
      commit(type, event);
    },
    async createSocietyAndNavigateToView() {
      this.error = false;
      const aggregate = _.cloneDeep(getAggregate());
      try {
        await UploadContracts(this.basicInfoName, aggregate.agreements);
        const id = await SocietyService.createSociety(aggregate);
        this.$router.push({
          name: 'societyView',
          params: { id },
        });
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    initAggregate() {
      vuexDispatch('createAggregate', {});
    },
    onUpdateAgreements(agreements) {
      commit('updateAgreements', agreements);
    },
  },
};
</script>
