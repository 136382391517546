/**
 * The Wireprepper chews objects and properties that should not be sent to the backend.
 */
import _ from 'lodash';
import traverseApply from '../common/traverseApplicator';
import * as TypeUtils from '../common/typeUtils';

export default function prepareForWire(data, options) {
  if (options && options.noVersion) {
    delete data.version;
  }

  // Kill local ids use by views.
  traverseApply(
    data,
    (key) => key === '_localId',
    (key, field, leaf) => delete leaf[key],
    false,
  );

  // Null arrays with empty objects.
  traverseApply(
    data,
    (key, fieldValue) => Array.isArray(fieldValue),
    (key, fieldValue) =>
      fieldValue.filter((val) => val !== undefined && val !== '' && !TypeUtils.isPristine(val)),
  );

  // Null empty objects and strings.
  traverseApply(
    data,
    (key, fieldValue) =>
      (_.isPlainObject(fieldValue) &&
        TypeUtils.isPristine(fieldValue) &&
        key.toLowerCase() !== 'payload') ||
      fieldValue === '',
    () => null,
  );

  // Clear null values.
  traverseApply(
    data,
    (key, fieldValue, depth) => fieldValue === null && depth !== 0,
    (key, field, leaf) => delete leaf[key],
    false,
  );

  return data;
}
