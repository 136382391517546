<template>
  <span>
    <slot />

    <template v-if="isMortal && !fetched">
      <i class="fas fa-spinner fa-spin"></i>
    </template>

    <template v-if="isMortal && map[paymentReceiver.nodeId]">
      <span class="text--red">
        <i class="fas fa-exclamation-circle"></i>
        deceased
      </span>
    </template>
  </span>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

import PerformerService from '../../services/performerService';
import AssociateService from '../../services/associateService';

// Singleton data across all instances of the person-status component
const queue = [];
const map = {};
const scrollListener = {
  callbacks: [],
  registered: false,
};

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

const onScroll = _.debounce(() => {
  scrollListener.callbacks.forEach((callback) => callback());
}, 50);

export default {
  props: {
    paymentReceiver: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    queue,
    map,
    scrollListener,
    enqueued: false,
    fetched: false,
  }),

  computed: {
    isMortal() {
      const mortalTypes = [
        'heir',
        'performer',
        'intermediary',
        'legal_guardian',
        'manager',
        'other',
      ];
      return mortalTypes.includes(this.paymentReceiver.type);
    },
  },

  watch: {
    paymentReceiver(n, o) {
      if (o) {
        delete this.map[o.nodeId];
      }

      Vue.set(this.map, n.nodeId, null);
      this.enqueued = false;
      this.fetched = false;
      this.enqueue(n.nodeId);
    },
  },

  mounted() {
    this.scrollListener.callbacks.push(this.onScroll);

    if (!this.scrollListener.registered) {
      window.addEventListener('scroll', onScroll);
      this.scrollListener.registered = true;
    }

    this.enqueue(this.paymentReceiver.nodeId);
  },

  destroyed() {
    const i = this.scrollListener.callbacks.indexOf(this.onScroll);
    this.scrollListener.callbacks.splice(i, 1);

    if (this.scrollListener.callbacks.length === 0) {
      window.removeEventListener('scroll', onScroll);
      this.scrollListener.registered = false;
    }

    delete this.map[this.paymentReceiver.nodeId];
  },

  methods: {
    enqueue(id) {
      if (this.isMortal && !this.enqueued && !this.fetched && isElementInViewport(this.$el)) {
        this.enqueued = true;
        this.queue.push({
          id,
          type: this.paymentReceiver.type,
          callback: () => {
            this.fetched = true;
          },
        });
        this.load();
      }
    },

    load: _.debounce(
      async function load() {
        const perTypeMap = {};
        const callbacks = [];

        while (this.queue.length > 0) {
          const { id, type, callback } = this.queue.pop();

          if (!perTypeMap[type]) {
            perTypeMap[type] = [];
          }

          perTypeMap[type].push(id);
          callbacks.push(callback);
        }

        const requests = Object.keys(perTypeMap).map((type) =>
          this.getDateOfDeathFetcher(type, perTypeMap[type]),
        );

        const results = _.flatten(await Promise.all(requests));

        results.forEach(({ id, date }) => {
          Vue.set(this.map, id, date);
        });
        callbacks.forEach((callback) => callback());
      },
      250,
      { maxWait: 500 },
    ),

    async getDateOfDeathFetcher(type, ids) {
      switch (type) {
        case 'performer': {
          const performerResults = await PerformerService.getPerformerList(ids);

          return performerResults.map((result) => ({
            id: result.id,
            date: result.general_info.date_of_death,
          }));
        }
        default: {
          const requests = ids.map(async (id) => {
            const associateResult = await AssociateService.getAssociate(id);

            return { id: associateResult.id, date: associateResult.person_info.date_of_death };
          });

          return Promise.all(requests);
        }
      }
    },

    onScroll() {
      this.enqueue(this.paymentReceiver.nodeId);
    },
  },
};
</script>
