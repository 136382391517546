/**
 * Helper service for creating performers.
 */
import _ from 'lodash';
import { createPerformerRelation } from '../../../../domain/mainArtistDomain';
import MainArtistService from '../../../../services/mainArtistService';
import PerformerService from '../../../../services/performerService';
import UploadDocuments from '../../../../services/uploadDocuments';
import { dispatch as vuexDispatch, getAggregate } from '../../../../store/modules/performer/utils';
import { isPristine } from '../../../../common/typeUtils';
import { AGGREGATE_TYPES } from '../../../../domain/common';

function mapMainArtistPromises(mainArtists, performerId) {
  return mainArtists
    .map((mainArtistRelation) =>
      MainArtistService.addPerformer(
        mainArtistRelation.mainArtistId,
        createPerformerRelation(performerId, mainArtistRelation.instrument),
      ),
    )
    .concat(Promise.resolve(performerId));
}

function aggregateMandates(mandateGroups) {
  const mandates = [];
  mandateGroups.forEach((group) => {
    group.mandates.forEach((mandate) => {
      if (mandate.society_code) {
        mandates.push(mandate);
      }
    });
  });
  return mandates;
}

async function createPerformerAndNavigateToView() {
  const performerAggregate = _.cloneDeep(getAggregate('performer'));
  const isNewMainArtists =
    performerAggregate.mainartist_info &&
    performerAggregate.mainartist_info.new_mainartists.length > 0;
  performerAggregate.mandates = aggregateMandates(performerAggregate.mandates);
  performerAggregate.documents = performerAggregate.documents.filter(
    (document) => !isPristine(document, ['id', 'created']),
  );
  if (performerAggregate.documents.length > 0) {
    await UploadDocuments(AGGREGATE_TYPES.PERFORMER, performerAggregate.documents);
  }
  const createdId = await PerformerService.createPerformer(performerAggregate);
  return (
    isNewMainArtists
      ? Promise.all(
          mapMainArtistPromises(performerAggregate.mainartist_info.new_mainartists, createdId),
        )
      : Promise.resolve([createdId])
  ).then((ids) => ({ id: ids.pop(), isNewMainArtists }));
}

async function setFromIpdRightHolder(ipn) {
  const rightholder = await PerformerService.getIpdRightHolder(ipn);
  vuexDispatch('setPerformerAggregateFromRightholder', rightholder);
}

export default {
  setFromIpdRightHolder,
  createPerformerAndNavigateToView,
  initAggregate: () => vuexDispatch('createAggregate', {}),
};
