/**
 * The Matching Service contains music report matching related methods.
 */
import { query, mutate } from './apolloRequest';
import statementGql from '../domain/statementGql';

export default {
  fetchPerformerMatchingDetails: async (statementId, groupId) =>
    query(
      {
        query: statementGql.fetchPerformerMatchingDetails,
        variables: { statementId, referenceId: groupId },
      },
      { pickProp: 'fetchPerformerMatchingDetails' },
    ),
  changePerformerMatchState: async (command) =>
    mutate({ mutation: statementGql.changePerformerMatchState, variables: { command } }),
  getStatementPerformers: async (statementId, options) =>
    query(
      { query: statementGql.getStatementPerformers, variables: { statementId, options } },
      { pickProp: 'getStatementPerformers' },
    ),
  getStatementPerformerStateSummaries: async (statementId) =>
    query(
      { query: statementGql.getStatementPerformerStateSummaries, variables: { statementId } },
      { pickProp: 'getStatementPerformerStateSummaries' },
    ),
  confirmDisburse: async (command) =>
    mutate({ mutation: statementGql.confirmDisburse, variables: { command } }),
  createFeedbackFile: async (command) =>
    mutate({ mutation: statementGql.createFeedbackFile, variables: { command } }),
};
