<template>
  <div>
    <div class="col s2">
      <span class="text--bold">Allocation method</span>
    </div>
    <div class="col s1" />
    <div class="col s2">
      <select-input
        label=""
        :empty-select-option="false"
        :disabled="disabled"
        item-key="key"
        item-value="value"
        :name="name"
        :value="selectedValue"
        :items="types"
        @input="onInput"
      />
    </div>
    <div class="col s2">
      <button class="btn secondary" @click="onClick">Allocate</button>
    </div>
  </div>
</template>

<script>
import { DistributionMethod } from '../../../domain/distributionDomain';
import SelectInput from './select-input';

export default {
  name: 'SelectDistributionAllocationMethod',
  components: { SelectInput },
  props: {
    value: {
      type: String,
      default: () => DistributionMethod.POINTS,
    },
    name: {
      type: String,
      default: 'allocation-method',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: this.value,
      types: [
        {
          key: DistributionMethod.POINTS,
          value: this.$t('distribution.distribution_method.points'),
        },
        {
          key: DistributionMethod.AIRTIME,
          value: this.$t('distribution.distribution_method.airtime'),
        },
        /*  Reomved as an option until its working
         * { key: DistributionMethod.BY_SOURCE, value: this.$t('distribution.distribution_method.by_source') },
         */
        {
          key: DistributionMethod.MANUAL,
          value: this.$t('distribution.distribution_method.manual'),
        },
      ],
    };
  },
  watch: {
    value(val) {
      this.selectedValue = val;
    },
  },
  methods: {
    onInput(key) {
      this.selectedValue = key;
    },
    onClick() {
      this.$emit('input', DistributionMethod[this.selectedValue]);
    },
  },
};
</script>
