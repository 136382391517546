<template>
  <div class="row">
    <div v-if="newContactPersons.length > 0" class="row text--right flush--bottom">
      <div class="col s12">
        <action-buttons @save="onSave" @cancel="onCancel" />
      </div>
    </div>
    <div class="col s12">
      <div>
        <div v-for="(contactPerson, index) in newContactPersons" :key="contactPerson._localId">
          <contact-person
            :contact-person="contactPerson"
            :deletable="newContactPersons.length > 1"
            @remove="onRemoveContactPerson(index)"
            @input="onContactPersonChanged(index, $event)"
          />
        </div>
      </div>

      <div class="edit-link add-extra">
        <a :class="{ disabled: !lastHasEntry() }" @click="addContactPerson()">
          <i class="far fa-plus-square" />
          Add contact person
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import { isBlankStr } from '../../../common/typeUtils';
import ContactPerson from '../../ui/contact/contact-person';
import ActionButtons from '../../ui/button/action-buttons';

import { createContactPerson } from '../../../domain/common';

export default {
  components: {
    ContactPerson,
    ActionButtons,
  },
  inject: ['$validator'],
  data() {
    return {
      newContactPersons: [],
    };
  },
  methods: {
    lastHasEntry() {
      return (
        this.newContactPersons.length === 0 ||
        this.newContactPersons.every((cp) => !this.isEmpty(cp))
      );
    },
    addContactPerson() {
      // Add if empty or data exists on existing
      if (this.lastHasEntry()) {
        const person = createContactPerson();
        person._localId = uuid.v4();
        this.newContactPersons.push(person);
      }
    },
    onContactPersonChanged(index, contactPerson) {
      this.newContactPersons.splice(index, 1, contactPerson);
    },
    onRemoveContactPerson(index) {
      this.newContactPersons.splice(index, 1);
    },
    onSave() {
      const toCreate = this.newContactPersons.filter((cp) => !this.isEmpty(cp));
      if (toCreate.length > 0) {
        this.$emit('save', toCreate);
      } else {
        this.onCancel();
      }
    },
    onCancel() {
      this.newContactPersons = [];
      this.$emit('cancel', this.$options.name);
    },
    isEmpty(contactPerson) {
      return !contactPerson || isBlankStr(contactPerson.name);
    },
  },
};
</script>
