<template>
  <div class="worklist__personal">
    <div class="row">
      <h2>Add to personal worklist</h2>
    </div>

    <form data-vv-scope="add-to-personal-worklist" @click.prevent>
      <div class="row">
        <validated-text-input
          name="name"
          label="Name"
          scope="add-to-personal-worklist"
          placeholder="E.g. Distribution Errors 2017"
          :value="item.name"
          rule="required"
          @input="updateName"
        />
      </div>
      <div class="row">
        <textarea
          class="note"
          name="note"
          scope="add-to-personal-worklist"
          data-vv-as="note"
          :value="item.note"
          placeholder="E.g. Add lineup and fix release date."
          @input="updateNote($event.target.value)"
          @keyup="autoHeight"
        />
        <span
          v-if="validationErrors.has('note', 'add-to-personal-worklist')"
          class="help is-danger"
        >
          <i class="fas fa-times-circle" />
          {{ validationErrors.first('note', 'add-to-personal-worklist') }}
        </span>
      </div>
      <div class="row">
        <button class="btn" @click="addToWorklist">Add</button>
      </div>
    </form>
  </div>
</template>

<script>
import ValidatedTextInput from '../../ui/input/validated-text-input';
import AuthenticationService from '../../../services/authenticationService';
import { textareaAutoheight } from '../../../domain/common';
import WorklistHelper from './worklistHelper';

export default {
  name: 'AddToPersonalWorklist',
  components: {
    ValidatedTextInput,
  },
  inject: ['$validator'],
  data() {
    return {
      username: '',
      item: {
        name: '',
        note: '',
      },
    };
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.item.name = WorklistHelper.getCurrentHeader();
      }, 1000);
    },
  },
  created() {
    this.username = AuthenticationService.getUserName();
    this.$nextTick(() => {
      this.item.name = WorklistHelper.getCurrentHeader();
    });
  },
  methods: {
    updateName(name) {
      this.item.name = name;
    },
    updateNote(note) {
      this.item.note = note;
    },
    clearForm() {
      this.item.name = '';
      this.item.note = '';
      this.item.url = '';
      this.item.category = '';
    },
    async addToWorklist() {
      const promise = this.$validator.validate('add-to-personal-worklist.*');
      promise.then((success) => {
        if (success && !this.validationErrors.any('add-to-personal-worklist')) {
          try {
            this.item.url = this.$router.currentRoute.fullPath;
            this.item.category =
              this.$router.currentRoute.meta.name || this.$router.currentRoute.meta.title;
            this.item.created = new Date();
            WorklistHelper.addToPersonalWorklist(this.item, this.username);
            this.clearForm();
            this.$emit('close');
          } catch (e) {
            console.log("Couldn't save item", e);
          }
        } else {
          console.log('There are errors');
        }
      });
    },
    autoHeight(e) {
      textareaAutoheight(e);
    },
  },
};
</script>
