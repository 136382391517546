import * as uuid from 'uuid';
import { createContactInfo, createTaxInformation } from './common';
import { createPaymentInfo } from './commonPayment';

export const MANDATE_DEFAULT = 'DE';
export const MANDATE_SAMI_SOCIETY_CODE = '06';

export function createMainArtistRelation(mainArtistId, instrument) {
  return {
    mainArtistId,
    instrument,
  };
}

export function createMandate(
  right = null,
  id = -1,
  societyCode = null,
  mandateType = null,
  startDate = null,
  endDate = null,
  territories = [],
  performanceActivity = null,
  conditionalAspect = null,
) {
  return {
    right,
    id,
    society_code: societyCode,
    mandate_type: mandateType,
    start_date: startDate,
    end_date: endDate,
    territories,
    performance_activity: performanceActivity,
    conditional_aspect: conditionalAspect,
  };
}

export function createMandateGroup(right = null) {
  return {
    right,
    mandates: [createMandate(right)],
  };
}

export function createPerformerAggregate() {
  return {
    id: null,
    version: null,
    deleted: false,
    deletedBy: null,
    stream_id: uuid.v4(),
    process_id: null,
    general_info: {
      first_name: null,
      last_name: null,
      pseudo_names: [],
      name_variations: [],
      date_of_birth: null,
      social_security_number: null,
      date_of_death: null,
      protected_identity: false,
      nationality: null,
      country_of_residence: null,
      sex: null,
      profile: null,
      local_codes: [],
      collective: null,
      collective_size: null,
    },
    contact_info: createContactInfo(),
    membership_info: {
      main_instrument: null,
      secondary_instruments: [],
      voting_right: false,
      ipn: null,
    },
    mandates: [],
    tax_info: createTaxInformation(),
    metadata_info: {
      other_external_sources: [],
      tags: [],
    },
    payment_info: createPaymentInfo(),
    system_state: {
      payments_blocked: false,
      payments_blocked_reason: '',
    },
    mainartist_info: {
      removed_mainartists: [],
      new_mainartists: [],
    },
    associates: [],
    main_artists: [],
    documents: [],
    ipd_state: {
      sync_type: null,
      state: null,
    },
  };
}

export function createPerformerAggregateCommand(id = uuid.v4()) {
  return {
    type: 'performer:create',
    process_id: id,
    stream_id: id,
    timestamp: new Date(),
    general_info: null,
    contact_info: null,
    membership_info: null,
    mandates: null,
    tax_info: null,
    metadata_info: null,
    payment_info: null,
  };
}

export function createUpdateGeneralInfoCommand() {
  return {
    type: 'performer:updateGeneralInfo',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      first_name: null,
      last_name: null,
      pseudo_names: [],
      name_variations: [],
      date_of_birth: null,
      social_security_number: null,
      date_of_death: null,
      protected_identity: false,
      nationality: null,
      country_of_residence: null,
      sex: null,
      profile: null,
      local_codes: [],
      collective: null,
      collective_size: null,
    },
  };
}

export function createUpdateContactInfoCommand() {
  return {
    type: 'performer:contactInfo:update',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      address: null,
      emails: [],
      phone_numbers: [],
      website: null,
    },
  };
}

export function createUpdateMembershipInfoCommand() {
  return {
    type: 'performer:updateMembershipInfo',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      main_instrument: null,
      secondary_instruments: [],
      voting_right: false,
      ipn: null,
    },
  };
}

export function createCancelMandatesCommand() {
  return {
    type: 'performer:cancelMandates',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      ids: [],
    },
  };
}

export function createPatchIpnCommand(id, ipn, user) {
  return {
    type: 'performer:patchMembershipInfo',
    stream_id: id || uuid.v4(),
    process_id: id || uuid.v4(),
    timestamp: new Date(),
    user,
    membership_info: {
      ipn,
    },
  };
}

export function createUpdateMandateInfoCommand() {
  return {
    type: 'performer:updateMandates',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      verify_territories: true,
      mandates: [],
    },
  };
}

export function createMetadataInfoCommand() {
  return {
    type: 'performer:updateMetadataInfo',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      other_external_sources: [],
      tags: [],
    },
  };
}

export function createUpdatePaymentsBlockedCommand() {
  return {
    type: 'performer:systemState:paymentsBlocked:update',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      payments_blocked: false,
      payments_blocked_reason: '',
    },
  };
}

export function createInitiateMembershipCommand() {
  return {
    type: 'performer:membership:initiate',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {},
  };
}

export function createConfirmMembershipCommand() {
  return {
    type: 'performer:membership:confirm',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {},
  };
}

export function createApproveMembershipApplicationCommand() {
  return {
    type: 'performer:membership:applicationApproved',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {},
  };
}

export function createRegionalMembershipAgreementCommand() {
  return {
    type: 'performer:membership:createRegionalAgreement',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {},
  };
}
