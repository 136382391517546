import * as uuid from 'uuid';

export const PHONE_TYPES = {
  MOBILE: 'mobile',
  HOME: 'home',
  WORK: 'work',
  FAX: 'fax',
};

export const AGGREGATE_TYPES = {
  PERFORMER: 'performer',
  RECORDING: 'recording',
  MAINARTIST: 'mainartist',
  ALBUM: 'album',
  ASSOCIATE: 'associate',
  SOCIETY: 'society',
  DISTRIBUTION: 'distribution',
  NOTE: 'note',
  USER: 'user',
  TAG: 'tag',
  DEFAULT_TAG: 'defaultTag',
  RESERVATION: 'reservation',
};

export const SUB_AGGREGATE_TYPES = {
  AGREEMENT: 'agreement',
  REVENUE: 'revenue',
  MANDATE: 'mandate',
  PROXY: 'proxy',
};

export const REJECTION_REASONS = [
  'evidence_required',
  'recording_mismatch',
  'not_on_recording',
  'other',
];

export const UNKNOWN_COUNTRY = 'ZZ';

export function createSourceInfo(source = null, comment = null) {
  return {
    source,
    comment,
  };
}

export function createLocalCode(code = null, value = null) {
  return {
    code,
    value,
  };
}

export function createOtherExternalSources(source = null, url = null) {
  return {
    source,
    url,
  };
}

export function createDocument(id = uuid.v4(), type, url, created = new Date()) {
  return {
    id,
    type,
    url,
    created,
  };
}

export function createTaxInformation(
  taxDomicile = null,
  tin = null,
  vatNumber = null,
  incomeStatement = null,
) {
  return {
    tax_domicile: taxDomicile,
    tin,
    vat_number: vatNumber,
    income_statement: incomeStatement,
  };
}

export function createAddress(
  street = null,
  postalCode = null,
  city = null,
  province = null,
  country = null,
  careOf = null,
) {
  return {
    street,
    postal_code: postalCode,
    city,
    province,
    country,
    care_of: careOf,
  };
}

export function createPhoneNumber(type = null, number = null) {
  return {
    type,
    number,
  };
}

export function createContactPerson(name = null, emails = [], phoneNumbers = [], comment = null) {
  return {
    name,
    emails,
    phone_numbers: phoneNumbers,
    comment,
  };
}

export function createContactInfo(
  address = createAddress(),
  emails = [],
  phoneNumbers = [],
  contactPersons = [],
  website = null,
) {
  return {
    address,
    website,
    emails,
    phone_numbers: phoneNumbers,
    contact_persons: contactPersons,
  };
}

export function createUpdateTaxInfoCommand(aggregateType) {
  return {
    type: `${aggregateType}:taxInfo:update`,
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      tax_domicile: null,
      tin: null,
      vat_number: null,
      income_statement: null,
    },
  };
}

export function createUpdateContactInfoCommand(aggregateType, id = uuid.v4()) {
  return {
    type: `${aggregateType}:contactInfo:update`,
    version: null,
    stream_id: id,
    process_id: id,
    timestamp: new Date(),
    payload: {
      address: null,
      emails: null,
      phone_numbers: null,
      contact_persons: null,
      website: null,
    },
  };
}

export function createAssociation(
  id,
  rowId = -1,
  type = null,
  accessPolicy = 'NONE',
  startDate = null,
  endDate = null,
) {
  return {
    id,
    row_id: rowId,
    type,
    access_policy: accessPolicy,
    start_date: startDate,
    end_date: endDate,
    documents: [],
  };
}

export function createNamedRelation(id = null, name = null, type = null) {
  return {
    id,
    name,
    type,
  };
}

export function createUpdateAssociatesCommand(namespace) {
  return {
    type: `${namespace}:associates:update`.toLocaleLowerCase(),
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      associates: [],
    },
  };
}

export function createRemoveAssociatesCommand(namespace) {
  return {
    type: `${namespace}:associates:remove`.toLocaleLowerCase(),
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      id: null,
      row_id: null,
    },
  };
}

export function createDocumentsCommand(namespace) {
  return {
    type: `${namespace}:documents:update`,
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      documents: [],
    },
  };
}

export function textareaAutoheight(e) {
  if (e && e.target) {
    const self = e.target;
    const offset = self.offsetHeight - self.clientHeight;

    self.style.height = `${self.scrollHeight + offset}px`;
    return self;
  }
  return null;
}
