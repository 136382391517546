import SocietyService from '../../../services/societyService';
import ReferenceDataService from '../../../services/referenceDataService';

const rootState = {
  updatedAt: null,
  societies: [],
  localCodeTypes: [],
  initiated: false,
  referenceData: {},
  mandateTypes: [],
};

let refreshTimer = null;

const actions = {
  async loadAppData(context, isRefresh) {
    if (!isRefresh) {
      const referenceData = await ReferenceDataService.getReferenceData();
      context.commit('setReferenceData', referenceData);
    }
    const data = await SocietyService.getSocieties();
    const societies = data.map((s) => ({
      id: s.id,
      code: s.meta_info.code,
      name: s.basic_info.name,
      ipd_code: s.meta_info.IPD_society_code,
      ipd_name: s.meta_info.IPD_society_name,
      vrdb_code: s.meta_info.vrdb_code,
      country: s.meta_info.country,
      create_mandate: s.meta_info.create_mandate,
    }));
    context.commit('setSocieties', societies);

    const codes = societies
      .concat(context.getters.localCodes.filter((lc) => !lc.country))
      .map((lc) => ({
        code: lc.code,
        name: lc.name,
      }))
      .sort((a, b) => (a.name || a.code).localeCompare(b.name || b.code));

    const mandateTypes = [
      { name: 'Regional', code: 'R' },
      { name: 'Regional+', code: 'R+' },
      { name: 'Worldwide', code: 'WW' },
      { name: 'Worldwide-', code: 'WW-' },
    ];

    context.commit('setMandateTypes', mandateTypes);
    context.commit('setLocalCodesTypes', codes);
    context.commit('setUpdatedAt', new Date());
    context.commit('setInitiated', true);
  },
  scheduleAppDataRefresh(context, refreshInterval = 60000) {
    refreshTimer = setTimeout(async () => {
      await context.dispatch('init', { refreshInterval, isRefresh: true });
    }, refreshInterval);
  },
  stopAppDataRefresh() {
    clearTimeout(refreshTimer);
  },
  async init(context, data) {
    await context.dispatch('loadAppData', data.isRefresh);
    context.dispatch('scheduleAppDataRefresh', data.refreshInterval);
  },
};

const mutations = {
  setSocieties(state, societies) {
    state.societies = societies;
  },
  setReferenceData(state, referenceData) {
    state.referenceData = referenceData;
  },
  setLocalCodesTypes(state, codes) {
    state.localCodeTypes = codes;
  },
  setUpdatedAt(state, date) {
    state.updatedAt = date.toString();
  },
  setInitiated(state, initiated) {
    state.initiated = initiated;
  },
  setMandateTypes(state, mandateTypes) {
    state.mandateTypes = mandateTypes;
  },
};

const getters = {
  countries: (state) => state.referenceData.countries,
  mandateRights: (state) => state.referenceData.mandateRights,
  rolesRecording: (state) => state.referenceData.rolesRecording,
  instruments: (state) => state.referenceData.instruments,
  genres: (state) => state.referenceData.genres,
  localCodes: (state) => state.referenceData.localCodes,
  banks: (state) => state.referenceData.banks,
  societies: (state) => state.societies,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
