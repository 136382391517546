<template>
  <div>
    <view-contact-info :contact-info="contactInfo" @edit="onEdit" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewContactInfo from '../../ui/contact/view-contact-info';

export default {
  name: 'ContactInformation',
  components: { ViewContactInfo },
  computed: {
    ...mapGetters('society', ['contactInfo']),
  },
  methods: {
    onEdit() {
      this.$emit('edit', this.$options.name);
    },
  },
};
</script>
