/**
 * The Distribution Service contains distribution related methods.
 */
import { getConfigValue, PROPS } from './configService';
import post from './postRequest';
import { query, mutate } from './apolloRequest';
import {
  createDistributionChannelCommand,
  createRevenueCommand,
} from '../domain/distributionDomain';
import AuthenticationService from './authenticationService';
import gql from '../domain/distributionGql';
import worklistGql from '../domain/worklistGql';

const DISTRIBUTION_URL = `${getConfigValue(PROPS.DISTRIBUTION_URL)}`;

function buildCreateRevenueCommand(aggregate) {
  return Object.assign({}, createRevenueCommand(aggregate), {
    user: AuthenticationService.getUserName(),
  });
}

function mapAssignIssuesCommand(partitionInfo) {
  const command = {
    worklist_ids: partitionInfo.worklistIds,
    size: partitionInfo.size,
  };
  if (partitionInfo.country_code) {
    command.country = partitionInfo.country_code;
  }
  if (partitionInfo.year) {
    command.report_year = partitionInfo.year;
  }
  if (partitionInfo.issue_type) {
    command.issue_type = partitionInfo.issue_type;
  }
  return command;
}

function mapUnassignIssuesCommand(worklistId, recordingIds) {
  return {
    worklist_id: worklistId,
    recording_ids: recordingIds,
  };
}

export default {
  createChannel: async (aggregate) =>
    post(`${DISTRIBUTION_URL}/admin/channels/`, createDistributionChannelCommand(aggregate), {
      pickProp: 'id',
    }),
  createOrUpdateDistributionArea: async (aggregate) =>
    post(`${DISTRIBUTION_URL}/admin/areas/`, aggregate, { pickProp: 'id' }),
  createOrUpdateRevenue: async (aggregate) =>
    post(`${DISTRIBUTION_URL}/admin/revenues/`, buildCreateRevenueCommand(aggregate)),
  payToLedger: async (distributionId) =>
    mutate({ mutation: gql.payToLedger, variables: { distributionId } }),
  distribute: async (command) =>
    mutate(
      {
        mutation: gql.distribute,
        variables: {
          command: Object.assign(command, { user: AuthenticationService.getUserName() }),
        },
      },
      { pickProp: 'distribute' },
    ),
  getDistributionArea: async (id) =>
    query(
      { query: gql.getDistributionAreaAggregate, variables: { idTerm: Number(id) } },
      { pickProp: 'distributionAreaAggregate' },
    ),
  getDistributionChannels: async () =>
    query({ query: gql.getDistributionChannels }, { pickProp: 'distributionChannels' }),
  getDistributionChannelsCached: async (cache = 'cache-and-network') =>
    query({ query: gql.getDistributionChannels }, { pickProp: 'distributionChannels', cache }),
  getDistributionGroups: async () =>
    query({ query: gql.getDistributionGroups }, { pickProp: 'distributionGroups' }),
  getDistributionAreas: async () =>
    query({ query: gql.getDistributionAreas }, { pickProp: 'distributionAreas' }),
  getDistributionYearAll: async (year) =>
    query(
      { query: gql.getDistributionYearAll, variables: { yearTerm: Number(year) } },
      { pickProp: 'distributionYearAll' },
    ),
  getDistributionYear: async (id) =>
    query(
      { query: gql.getDistributionYear, variables: { idTerm: Number(id) } },
      { pickProp: 'distributionYear' },
    ),
  getDistributionYearIssues: async (id, limit, page) =>
    query(
      {
        query: gql.getDistributionYearIssues,
        variables: { yearIdTerm: Number(id), limitTerm: Number(limit), pageTerm: Number(page) },
      },
      { pickProp: 'distributionYearIssues' },
    ),
  getDistributionRules: async () =>
    query({ query: gql.getDistributionRules }, { pickProp: 'distributionRules' }),
  getIncompleteRecordings: async (incompleteRecordingsRequest) =>
    query(
      { query: gql.getIncompleteRecordings, variables: { incompleteRecordingsRequest } },
      { pickProp: 'incompleteRecordings' },
    ),
  getDistributionRevenues: async (distributionId) =>
    query(
      { query: gql.getDistributionRevenues, variables: { distributionId } },
      { pickProp: 'distributionRevenues' },
    ),
  getRevenues: async (options) =>
    query({ query: gql.getRevenues, variables: { options } }, { pickProp: 'revenues' }),
  getRevenue: async (id) =>
    query({ query: gql.getRevenue, variables: { idTerm: Number(id) } }, { pickProp: 'revenue' }),
  getRevenueYears: async () => query({ query: gql.getRevenueYears }, { pickProp: 'revenueYears' }),
  getRevenueSource: async (id) =>
    query(
      { query: gql.getRevenueSource, variables: { idTerm: id } },
      { pickProp: 'revenueSource' },
    ),
  getRevenueSources: async () =>
    query({ query: gql.getRevenueSources }, { pickProp: 'revenueSources' }),
  getClosedDistributions: async (year) =>
    query(
      { query: gql.getClosedDistributions, variables: { year } },
      { pickProp: 'closedDistributions' },
    ),
  recordingAirtimeSummery: async (starId) =>
    query(
      { query: gql.recordingAirtimeSummery, variables: { starId } },
      { pickProp: 'recordingAirtimeSummery' },
    ),
  recordingDistributionHistory: async (starId, limit, offset) =>
    query(
      { query: gql.recordingDistributionHistory, variables: { starId, limit, offset } },
      { pickProp: 'recordingDistributionHistory' },
    ),
  recordingDistributionHistoryDetails: async (starId, distDate, type) =>
    query(
      { query: gql.recordingDistributionHistoryDetails, variables: { starId, distDate, type } },
      { pickProp: 'recordingDistributionHistoryDetails' },
    ),
  recordingPerformerRemuneration: async (starId) =>
    query(
      { query: gql.recordingPerformerRemuneration, variables: { starId } },
      { pickProp: 'recordingPerformerRemuneration' },
    ),
  recordingPerformerRemunerationDetails: async (starId, performerId) =>
    query(
      { query: gql.recordingPerformerRemunerationDetails, variables: { starId, performerId } },
      { pickProp: 'recordingPerformerRemunerationDetails' },
    ),
  copyDistribution: async (command) =>
    mutate({ mutation: gql.copyDistribution, variables: { command } }),
  updateRevenueSource: async (command) =>
    mutate(
      { mutation: gql.updateRevenueSource, variables: { command } },
      { pickProp: 'updateRevenueSource' },
    ),
  updateRevenueFinancingRate: async (command) =>
    mutate(
      {
        mutation: gql.updateRevenueFinancingRate,
        variables: {
          command: Object.assign(command, { user: AuthenticationService.getUserName() }),
        },
      },
      { pickProp: 'updateRevenueFinancingRate' },
    ),
  removeRevenue: async (id) =>
    mutate(
      { mutation: gql.removeRevenue, variables: { id } },
      { pickProp: 'updateRevenueFinancingRate' },
    ),
  assignIssues: async (partitionInfo) =>
    mutate(
      {
        mutation: worklistGql.assignIssues,
        variables: { command: mapAssignIssuesCommand(partitionInfo) },
        fetchPolicy: 'network-only',
      },
      {},
    ),
  unassignIssues: async (worklistId, recordingIds) =>
    mutate(
      {
        mutation: worklistGql.unassignIssues,
        variables: { command: mapUnassignIssuesCommand(worklistId, recordingIds) },
        fetchPolicy: 'network-only',
      },
      {},
    ),
  getVrdb2Recordings: async (request, summaries = true) =>
    query(
      { query: gql.getVrdb2Recordings(summaries), variables: { request } },
      { pickProp: 'getVrdb2Recordings' },
    ),
  getVrdb2Headers: async () =>
    query({ query: gql.getVrdb2Headers }, { pickProp: 'getVrdb2Headers' }),
  uploadVrdb2Files: async (request) =>
    mutate(
      { mutation: gql.uploadVrdb2Files, variables: { request } },
      { pickProp: 'uploadVrdb2Files' },
    ),
  deleteWorklist: async (worklistId) =>
    mutate({
      mutation: worklistGql.deleteWorklist,
      variables: { worklistId },
      fetchPolicy: 'network-only',
    }),
  getRedistSummary: async () =>
    query({ query: gql.getRedistSummary }, { pickProp: 'redistSummary' }),
  getRedistDetails: async (recordingId) =>
    query(
      { query: gql.getRedistDetails, variables: { recordingId } },
      { pickProp: 'redistDetails' },
    ),
  blockRedistRecording: async (recordingId) =>
    mutate(
      { mutation: gql.blockRedistRecording, variables: { recordingId } },
      { pickProp: 'blockForRedistribution' },
    ),
  unblockRedistRecording: async (recordingId) =>
    mutate({ mutation: gql.unblockRedistRecording, variables: { recordingId } }),
  getProtectionChanges: async ({ page, limit }) =>
    query(
      { query: gql.getProtectionChanges, variables: { page, limit } },
      { pickProp: 'protectionChanges' },
    ),
  deleteProtectionChange: async (recordingId) =>
    mutate({ mutation: gql.deleteProtectionChange, variables: { recordingId } }),
  confirmApprovedRedist: async (processId) =>
    mutate({
      mutation: gql.confirmApprovedRedist,
      variables: { processId },
    }),
};
