<template>
  <div>
    <spinner v-if="loading" />
    <div class="row wrapper">
      <div class="col s6">
        <h1>Revenues</h1>
      </div>
      <div class="col s6 text--right">
        <router-link :to="{ name: 'createRevenue' }">
          <i class="far fa-edit" />
          Create revenue
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="tabs">
        <div class="tabs__wrapper">
          <router-link :to="{ name: 'revenuesViewDomestic' }">Domestic Sources</router-link>
          <router-link :to="{ name: 'revenuesViewInternational' }">
            International Sources
          </router-link>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s2 offset-s5">
        <select-input
          name="year-filter"
          label="Performance year"
          :value="extras.year"
          :items="years"
          @input="onSelectYear"
        />
      </div>
      <div class="col s2">
        <select-input
          name="state-filter"
          label="Status"
          :value="extras.state"
          :items="states"
          @input="onSelectState"
        />
      </div>
      <div class="col s3">
        <filter-input :filter-query="rangeStart" placeholder="E.g. Library" @input="onSearch" />
      </div>
    </div>

    <div class="row card report__headers">
      <table>
        <SortableHead
          :columns="columns"
          :expanded-content="true"
          :more-options="true"
          :bulk-enabled="false"
          :default-sort-should-override="true"
          @sort="sort"
        />
        <tbody>
          <template v-for="revenue in revenues">
            <tr :key="revenue.id">
              <td
                class="thin-column dropdown-button__arrow"
                @click="expandedIndex = expandedIndex === revenue.id ? null : revenue.id"
              >
                <i :class="`fas fa-caret-${expandedIndex === revenue.id ? 'up' : 'down'}`"></i>
              </td>
              <td>
                {{ $filters.formatDate(revenue.date_created) }}
              </td>
              <td>
                <router-link :to="{ name: 'revenueView', params: { id: revenue.id } }">
                  {{ revenue.name }}
                </router-link>
              </td>
              <td>
                {{ revenue.year }}
              </td>
              <td>
                {{ revenue.source.name }}
              </td>
              <td class="count">
                <span v-if="revenue.amount">
                  {{ $filters.formatAmountThousandSeparator(revenue.amount) }} SEK
                </span>
                <span v-else class="none">None</span>
              </td>
              <td class="count">
                <span v-if="revenue.distributable_amount">
                  {{ $filters.formatAmountThousandSeparator(revenue.distributable_amount) }}
                  SEK
                </span>
                <span v-else class="none">None</span>
              </td>
              <td class="count">
                <span v-if="revenue.distributed_amount">
                  {{ $filters.formatAmountThousandSeparator(revenue.distributed_amount) }}
                  SEK
                </span>
                <span v-else class="none">None</span>
              </td>
              <td>
                {{ $t(`revenue.state.${revenue.state.toLowerCase()}`) }}
              </td>
              <td>
                <context-menu
                  :disabled="revenue.state !== 'NEW'"
                  :options="['Delete']"
                  @delete="removeRevenue(revenue.id)"
                />
              </td>
            </tr>
            <tr
              v-if="expandedIndex === revenue.id"
              :key="`${revenue.id}-expanded`"
              class="expand-info"
            >
              <td colspan="100">
                <revenue-row-expanded :revenue="revenue" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <div class="result__pagination">
        <Pagination
          :number-of-pages="numberOfPages()"
          :selected-page="page"
          :number-of-hits="itemCount"
          :hits-per-page="pageSize"
          @selectPage="selectPage"
          @updateHitsPerPage="selectPageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RevenueState } from '../../../domain/distributionDomain';
import PaginationQueryParamMixin from '../../../common/paginationQueryParamMixin';
import DistributionService from '../../../services/distributionService';
import FilterInput from './../../ui/input/filter-input';
import SelectInput from './../../ui/select/select-input';
import RevenueRowExpanded from './revenue-row-expanded';
import SortableHead from '../../ui/table/sortable-head';
import Pagination from '../../pagination';
import ContextMenu from './../../ui/context-menu';
import Spinner from '../../spinner';

export default {
  name: 'ViewRevenues',
  components: {
    SelectInput,
    FilterInput,
    RevenueRowExpanded,
    SortableHead,
    Pagination,
    ContextMenu,
    Spinner,
  },
  mixins: [PaginationQueryParamMixin],
  data() {
    return {
      years: [],
      states: [
        { code: RevenueState.NEW, name: 'New' },
        { code: RevenueState.TO_BE_DISTRIBUTED, name: 'To be distributed' },
        { code: RevenueState.IN_PROGRESS, name: 'In progress' },
        { code: RevenueState.ON_HOLD, name: 'On hold' },
        { code: RevenueState.FINISHED, name: 'Finished' },
      ],
      revenues: [],
      loading: false,
      columns: [
        { name: 'Created date', sortParam: 'date_created', active: true, ascending: false },
        { name: 'Name of Payment', sortParam: 'name', ascending: true },
        { name: 'Performance Year', sortParam: 'year', ascending: false },
        { name: 'Source' },
        { name: 'Total Amount', styleClass: 'count' },
        { name: 'Distributable', styleClass: 'count' },
        { name: 'Distributed', styleClass: 'count' },
        { name: 'Status' },
      ],
    };
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  async created() {
    this.updateQueryParams({ state: RevenueState.NEW, ...this.$route.query });
    const revenueYears = await DistributionService.getRevenueYears();

    this.years = Object.values(revenueYears.years).map((code) => ({
      code: `${code}`,
      name: `${code}`,
    }));

    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        const result = await DistributionService.getRevenues({
          limit: this.pageSize,
          offset: this.getOffset(),
          year: this.extras.year ? +this.extras.year : undefined,
          state: this.extras.state,
          category: this.$route.path.match(/(international|domestic)$/)[0],
          order_by: this.sortParam,
          ascending: this.sortOrder === 'asc',
          filter: this.rangeStart,
        });

        this.revenues = result.items;
        this.itemCount = result.total_count;
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    onSelectYear(year) {
      this.setExtras({ ...this.extras, year, page: 1 });
    },
    onSelectState(state) {
      this.setExtras({ ...this.extras, state, page: 1 });
    },
    onSearch(filter) {
      this.setRangeStart(filter);
    },
    async removeRevenue(id) {
      await DistributionService.removeRevenue(id);
      await this.fetchData();
    },
  },
};
</script>
