<template>
  <div>
    <select-input
      name="distributionAreaRights"
      item-key="code"
      item-value="name"
      :rule="rule"
      :label="label"
      :value="code"
      :items="mandateRights"
      :return-object="returnObject"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectDistributionAreaRight',
  components: { SelectInput },
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: 'Rights' },
    returnObject: { type: Boolean },
    onLoaded: { type: Function },
    rule: {
      type: String,
      default: 'required',
    },
  },
  data() {
    return {
      code: this.value,
    };
  },
  computed: {
    mandateRights() {
      return this.$store.state.appdata.referenceData.mandateRights;
    },
  },
  watch: {
    value() {
      this.code = this.value;
    },
  },
  created() {
    if (this.onLoaded) this.onLoaded(this.mandateRights);
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
