<template>
  <div>
    <spinner v-if="projector.currentSeqNo === null" />
    <div v-else>
      <div
        v-if="projector.eventsBehind > 0"
        class="catching-up"
        :style="{ background: projectorProgressGradient }"
      >
        <h1>Please wait while the distribution closure projector is catching up.</h1>
        <div class="progress-indicator">
          <reusable-spinner :size="30" />
          <h2>
            Currently {{ projector.eventsBehind }}
            {{ projector.eventsBehind === 1 ? 'event' : 'events' }} behind...
          </h2>
        </div>
      </div>
      <div v-else>
        <distribution-closure-information class="row" :distribution-closure="distributionClosure" />
        <div class="row">
          <div v-if="working" class="col s4 offset-s9">
            <component-spinner />
          </div>
          <div v-else-if="!approved" class="col float-right">
            <action-buttons
              submit-label="Approve"
              :show-abort="false"
              :disable-submit="+distributionClosure.header.totalAmount === 0"
              @save="approveDistributionClosure"
            />
          </div>
          <div v-else-if="verificationStatus !== 'success'" class="col s4 offset-s8">
            <verify-with-password
              :message="verifyWithPasswordMessage"
              :verification-status="verificationStatus"
              :number-of-attempts="numberOfAttempts"
              :disabled="distributionClosure.rows.length === 0"
              submit-label="Confirm"
              @onSubmitPassword="onSubmitPassword"
              @onSubmitMfaCode="onSubmitMfaCode"
              @onBypassAuth="onBypassAuth"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <div class="float-right">
              <action-buttons
                submit-label="Back to distribution closures"
                :show-abort="false"
                @save="backToHistory()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentService from '../../../services/paymentService';
import ProjectorsService from '../../../services/projectorsService';
import { STATE } from '@/store/modules/process/store';
import AuthenticationMixin from '../../../common/authenictaionMixin';
import ActionButtons from '@/components/ui/button/action-buttons';
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import CommandService from '@/services/commandService';
import DistributionClosureInformation from '@/components/distribution/distribution-closure/distribution-closure-information';
import ReusableSpinner from '../../reusable-spinner.vue';
import Spinner from '../../spinner.vue';

export default {
  name: 'ProgressDistributionClosureVerify',
  components: {
    DistributionClosureInformation,
    ActionButtons,
    ReusableSpinner,
    Spinner,
  },
  mixins: [AuthenticationMixin],
  props: {
    distributionClosure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      projector: {
        currentSeqNo: null,
        targetSeqNo: null,
        eventsBehind: 0,
        maxEventsBehind: 0,
        progress: 0,
      },
      working: false,
      approved: this.distributionClosure.header?.state === distributionClosureState.Approved,
      verifyWithPasswordMessage:
        ' I hereby confirm that I want to perform\n' + '          a distribution closure.\n',
      lastEventSeqNo: null,
    };
  },
  computed: {
    projectorProgressGradient() {
      const start = (this.projector.progress * 1.05 - 0.05) * 100;
      const end = start + 5;

      return `linear-gradient(90deg, #c4e2c5 ${start.toFixed(2)}%, #ebebeb ${end.toFixed(2)}%)`;
    },
  },
  async mounted() {
    await this.awaitProjector();
  },
  methods: {
    async approveDistributionClosure() {
      this.working = true;
      const stream_id = this.distributionClosure.header.distributionClosureId;
      await CommandService.handleCommand('payment', {
        type: 'closure/approve',
        stream_id,
      });
      this.working = false;
      this.approved = true;
    },
    async doWork() {
      this.working = true;
      const stream_id = this.distributionClosure.header.distributionClosureId;
      const result = await CommandService.handleCommand('payment', {
        type: 'closure/confirm',
        stream_id,
      });
      this.working = false;
      this.$emit('refresh', 3000);
      this.$store.dispatch('process/addProcess', {
        name: 'Distribution closure',
        id: result.processId,
        route: `/distribution-closures/${result.processId}`,
        routeAvailability: [STATE.RUNNING, STATE.FINISHED],
      });
    },
    async awaitProjector() {
      let refreshNeeded = false;

      const status = await Promise.all([this.getProjectorProgress(), this.getLastEventSeqNo()]);

      this.projector.currentSeqNo = status[0];
      this.projector.targetSeqNo = status[1];

      console.log('Current sequence number', this.projector.currentSeqNo);
      console.log('Target sequnce number', this.projector.targetSeqNo);

      this.projector.eventsBehindMax = this.projector.targetSeqNo - this.projector.currentSeqNo;

      while (this.projector.currentSeqNo < this.projector.targetSeqNo) {
        this.projector.eventsBehind = this.projector.targetSeqNo - this.projector.currentSeqNo;
        this.projector.progress = 1 - this.projector.eventsBehind / this.projector.eventsBehindMax;

        await new Promise((resolve) => setTimeout(resolve, 2500));

        this.projector.currentSeqNo = await this.getProjectorProgress();
        refreshNeeded = true;
      }

      this.progress = 1;
      this.eventsBehind = 0;

      if (refreshNeeded) {
        this.$emit('refresh');
      }
    },
    async getLastEventSeqNo() {
      const res = await PaymentService.getDistributionClosureLastEvent(
        this.distributionClosure.header.distributionClosureId,
      );
      return res.lastEventSequenceNumber;
    },
    async getProjectorProgress() {
      const res = await ProjectorsService.getProjectorsProgress();
      const status = res.projectors.find(
        (p) => p.service === 'Payment2' && p.src === 'distribution_closure',
      );
      return Number.parseInt(status.sequence_no);
    },
    backToHistory() {
      this.$router.push({
        name: 'distributionClosureHistory',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.catching-up {
  text-align: center;
  background-color: #ebebeb;
  padding: 30px;
  border-radius: 10px;
}

.progress-indicator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
</style>
