import { render, staticRenderFns } from "./view-distribution-closure-history.vue?vue&type=template&id=43661b20&scoped=true"
import script from "./view-distribution-closure-history.vue?vue&type=script&lang=js"
export * from "./view-distribution-closure-history.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43661b20",
  null
  
)

export default component.exports