<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>

    <div class="row">
      <div class="col s12">
        <TableOptions
          :countries="recordedInCountries"
          :bulk-actions="bulkEditActions"
          :bulk-action-enabled="anySelected()"
          :recorded-in-country-code="recordedInCountryCode"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
          @countryChanged="onCountryChanged"
          @bulkAction="onBulkAction"
        />
      </div>
    </div>
    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table>
          <CompareSort
            :bulk-enabled="true"
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareMediarcMatching
            ref="comparelist"
            :album="isAlbum"
            :is-expanded="isExpanded"
            :toggle-expanded="toggleExpanded"
            :handle-unidentify-btn="handleUnidentifyBtn"
            :candidate-changed="candidateChanged"
            :handle-save-btn="handleSaveBtn"
            :handle-new-btn="handleNewBtn"
            @change="updateDirtyState"
          />
        </table>

        <div class="result__pagination">
          <Pagination
            v-if="numberOfPages() > 0"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="totalCount"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>

        <modal
          v-if="showNewAllModal"
          :submit-label="'Create new' + album ? 'albums' : 'recordings'"
          @save="onCreateNewAll()"
          @close="showNewAllModal = false"
          @cancel="showNewAllModal = false"
        >
          <h1 slot="header">Create {{ album ? 'albums' : 'recordings' }}?</h1>
          <div slot="body">
            This will create new {{ album ? 'albums' : 'recordings' }} for ALL unmatched. Are you
            sure?
          </div>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import CompareFieldsMixin from '../../../common/compareFieldsMixin';
import ViewMediarcReportMixin from './view-mediarc-report-mixin';
import PaginationAndSortMixin from '../../../common/paginationAndSortMixin';
import TableOptionsMixin from '../../../common/tableOptionsMixin';
import TableOptions from '../../ui/table/table-options';
import CompareSort from '../../compare/compare-sort';
import CompareMediarcMatching from '../../compare/compare-mediarc-matching';
import Pagination from '../../pagination';
import Modal from '../../ui/dialog/modal';

export default {
  name: 'ViewMediarcReportRestedLines',
  components: {
    TableOptions,
    CompareSort,
    Pagination,
    CompareMediarcMatching,
    Modal,
  },
  mixins: [CompareFieldsMixin, ViewMediarcReportMixin, PaginationAndSortMixin, TableOptionsMixin],
  data() {
    return {
      match_state: 'R',
      showNewAllModal: false,
    };
  },
  computed: {
    bulkEditActions() {
      return [
        { key: 'new', value: `New ${this.isAlbum ? 'album' : 'recording'}` },
        { key: 'new_all', value: `New ${this.isAlbum ? 'album' : 'recording'} (ALL)` },
      ];
    },
  },
  async activated() {
    const expandedId = this.viewMediarcReportRestedLines.expandedId;
    await this.loadPaginated();
    if (expandedId) {
      this.toggleExpanded(expandedId);
    }
  },
  deactivated() {
    this.updateViewMediarcReportRestedLinesExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
  created() {
    this.clearFilter('ViewMediarcReportRestedLines', false);
  },
  methods: {
    onBulkAction(value) {
      switch (value) {
        case 'new':
          this.createNewOnCheckedItems();
          break;
        case 'new_all':
          this.showNewAllModal = true;
          break;
        default:
          console.log('Unknown bulk action');
      }
    },
    onCreateNewAll() {
      this.showNewAllModal = false;
      this.createNewAll();
    },
  },
};
</script>
