<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Distribution Closure</h1>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DistributionClosure',
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
