<template>
  <div class="edit-link">
    <a :disabled="disabled" @click.prevent="onClick">
      <i class="fas fa-sync-alt" :class="{ spin: isLoading }" />
      <span v-if="showLabel">{{ label }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'RefreshButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'REFRESH',
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
