import {
  getProcessLogRows,
  getProcessesFromLocalStorage,
  writeProcessesToLocalStorage,
} from '../../../services/processService';

if (Notification.permission === 'default') {
  Notification.requestPermission();
}

const POLL_TIMEOUT = 600000; // 10 minutes
const MAX_LOG_ROWS = 300;

const excludeUndefined = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === 'undefined') {
      return acc;
    }
    return {
      ...acc,
      [key]: obj[key],
    };
  }, {});

export const STATE = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  TIMED_OUT: 'TIMED_OUT',
  FINISHED: 'FINISHED',
};

export const COMPLETE_STATES = [STATE.FINISHED, STATE.TIMED_OUT];

const pollConfig = {
  start: 2000,
  min: 500,
  max: 30000,
  relIncrease: 0.25,
};

const increase = (pollInterval) => pollInterval + pollInterval * pollConfig.relIncrease;

const rootState = {
  processes: [],
};

const createProcess = (p) => {
  if (typeof p.id !== 'string') {
    throw new Error(`Expected process id to be a string, got ${typeof p.id}`);
  }
  if (typeof p.name !== 'string') {
    throw new Error(`Expected process name to be a string, got ${typeof p.name}`);
  }
  return {
    name: p.name,
    id: p.id,
    pollInterval: p.pollInterval || pollConfig.start,
    lastActive: p.lastActive || Date.now(),
    lastTimestamp: p.lastTimestamp || null,
    createdAt: p.createdAt || Date.now(),
    logLevel: p.logLevel || 30,
    state: p.state || STATE.PENDING,
    route: p.route || null,
    routeAvailability: p.routeAvailability || [STATE.FINISHED],
    logRows: p.logRows || [],
  };
};

const notify = async (title, message) => {
  if (Notification.permission === 'granted') {
    new Notification(title, {
      body: message,
      icon: '/shooting_star_outlined.png',
      badge: '/shooting_star_outlined.png',
    });
  }
};

const actions = {
  loadFromStorage(context) {
    const processes = getProcessesFromLocalStorage();
    processes.forEach((p) => {
      context.dispatch('addProcess', p);
    });
  },
  addProcess(context, process) {
    const existing = context.getters.process(process.id);
    if (!existing) {
      context.commit('addProcess', process);
      context.dispatch('updateStorage');
      context.dispatch('fetchLogRows', process.id);
    }
  },
  updateStorage(context) {
    const processes = context.state.processes || [];
    writeProcessesToLocalStorage(processes);
  },
  updateProcess(context, { logRows, id, ...data }) {
    context.commit('updateProcess', { ...data, id });
    if (logRows) {
      context.commit('addLogRows', { id, rows: logRows });
    }
    context.dispatch('updateStorage');
  },
  removeProcess(context, processId) {
    context.commit('removeProcess', processId);
    context.dispatch('updateStorage');
  },
  async fetchLogRows(context, processId) {
    const process = context.getters.process(processId);
    if (!process || COMPLETE_STATES.includes(process.state)) {
      return;
    }
    const logRows = await getProcessLogRows(processId, process.logLevel, process.lastTimestamp);
    if (logRows && logRows.length > 0) {
      const lastRow = logRows[logRows.length - 1];
      const finished = logRows.some((row) => row.finished === true);
      const updates = {
        id: processId,
        lastTimestamp: lastRow.timestamp,
        lastActive: Date.now(),
        pollInterval: pollConfig.min,
        state: finished ? STATE.FINISHED : STATE.RUNNING,
        logRows,
      };
      context.dispatch('updateProcess', updates);
      if (finished) {
        const text = process.logRows.some((row) => !!row.error)
          ? 'Task finished with errors'
          : 'Task complete!';
        notify(process.name, text);
      } else {
        setTimeout(() => context.dispatch('fetchLogRows', processId), pollConfig.min);
      }
    } else {
      const timedOut = Date.now() - process.lastActive > POLL_TIMEOUT;
      const newInterval = increase(process.pollInterval);
      const pollInterval = newInterval < pollConfig.max ? newInterval : pollConfig.max;
      context.dispatch('updateProcess', {
        id: processId,
        pollInterval,
        state: timedOut ? STATE.TIMED_OUT : process.state,
      });
      if (timedOut) {
        const text = 'Task timed out';
        notify(process.name, text);
      } else {
        setTimeout(() => context.dispatch('fetchLogRows', processId), pollInterval);
      }
    }
  },
};

const mutations = {
  addProcess(state, data) {
    if (state.processes.length >= 100) {
      state.processes = state.processes.slice(10);
    }
    state.processes = [...state.processes, createProcess(data)];
  },
  removeProcess(state, processId) {
    state.processes = state.processes.filter((p) => p.id !== processId);
  },
  updateProcess(state, { id, ...data }) {
    const updates = excludeUndefined(data);
    const prc = state.processes.find((p) => p.id === id);
    if (!prc) {
      throw new Error(`Can not update non-existing process '${id}'`);
    }
    state.processes = [
      ...state.processes.filter((p) => p.id !== id),
      {
        ...prc,
        ...updates,
      },
    ];
  },
  addLogRows(state, { id, rows }) {
    const prc = state.processes.find((p) => p.id === id);
    if (!prc) {
      throw new Error(`Can not add log rows to non-existing process '${id}'`);
    }
    state.processes = [
      ...state.processes.filter((p) => p.id !== id),
      {
        ...prc,
        logRows: (prc.logRows || []).concat(rows).slice(-MAX_LOG_ROWS),
      },
    ];
  },
};

const getters = {
  process: (state) => (processId) => state.processes.find((p) => p.id === processId),
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
