<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Rome Convention & WPPT</h1>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <DataTable
          :columns="table.columns"
          :items="countries"
          :sort.sync="table.sort"
          item-key="iso"
          local-sort
          sticky-header
        >
          <template #item:name="{ item }">
            {{ item.name }}
          </template>

          <template #item:broadcast="{ item }">
            <i v-show="item.broadcast" class="fa fa-check text--green"></i>
          </template>

          <template #item:web="{ item }">
            <i v-show="item.web" class="fa fa-check text--green"></i>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/ui/table/DataTable.vue';

export default {
  components: {
    DataTable,
  },

  data() {
    return {
      table: {
        columns: [
          {
            key: 'name',
            text: 'Country',
            sortable: true,
          },
          {
            key: 'broadcast',
            text: 'Rome Convention',
            sortable: true,
          },
          {
            key: 'web',
            text: 'WPPT',
            sortable: true,
          },
        ],
        sort: {
          order_by: 'country',
          direction: 'asc',
        },
      },
    };
  },
  computed: {
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
};
</script>
