<template>
  <div class="simple-spinner">
    <i class="fa fa-circle-notch loader--spin" aria-hidden="true" />
  </div>
</template>

<script>
export default {
  name: 'SimpleSpinner',
};
</script>

<style lang="scss">
.simple-spinner {
  display: inline-block;
  font-size: 0.94em;
}
</style>
