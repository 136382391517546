<template>
  <div>
    <div class="row">
      <div class="col s12 l1">
        <entity-navigation :views="navigationViews" />
      </div>
      <div class="col s12 l11 view-layout">
        <keep-alive>
          <router-view type="recording" />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import EntityNavigation from '../../entity-navigation';

export default {
  name: 'ViewRecording',
  components: {
    EntityNavigation,
  },
  data() {
    return {
      navigationViews: [
        'recordingInformation',
        'recordingUsage',
        'distributionHistory',
        'recordingRemuneration',
      ],
    };
  },
};
</script>
