<template>
  <div>
    <select-input
      name="distributionArea"
      item-key="id"
      item-value="name"
      :label="label"
      :value="value"
      :items="areas"
      :return-object="returnObject"
      @input="onInput"
    />
  </div>
</template>

<script>
import DistributionService from '../../../services/distributionService';
import SelectInput from '../../ui/select/select-input';
import clone from '../../../common/clone';

export default {
  name: 'SelectDistributionArea',
  components: { SelectInput },
  props: {
    value: { type: Number, default: 0 },
    label: { type: String, default: 'Distribution Area' },
    returnObject: { type: Boolean },
    onLoaded: { type: Function },
  },
  data() {
    return {
      areas: [],
    };
  },
  async created() {
    const result = await DistributionService.getDistributionAreas();
    this.areas = clone(result.areas);
    if (this.onLoaded) this.onLoaded(this.areas);
  },
  methods: {
    onInput(value) {
      if (value) {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
