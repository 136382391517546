<template>
  <div class="custom-select">
    <label class="label" :class="{ 'label--error': validationErrors.has(name, scope) }">
      Source
    </label>
    <span v-if="mandatory" class="mandatory">(mandatory)</span>
    <div class="custom-select__input-wrapper">
      <input
        :id="`source-input`"
        v-model="content"
        v-validate="rule"
        autocomplete="off"
        :name="name"
        type="text"
        placeholder="Choose one..."
        class="custom-select__input"
        :class="{ 'is-danger': validationErrors.has(name, scope) }"
        :data-vv-scope="scope"
        :data-vv-as="`source`"
        @focus="toggleDropdown"
        @input="onChange"
        @keydown.tab="showList = false"
        @keyup.up="onKeyup"
        @keyup.down="onKeydown"
        @keyup.enter.prevent="onEnter"
        @mousedown="toggleDropdown"
      />
      <span class="custom-select__arrow" @click="toggleDropdown" />
      <div v-show="showList" class="custom-select__list-wrapper">
        <ul v-if="empty" ref="list" class="custom-select__list">
          <li
            v-for="(item, index) in allItems"
            :key="index"
            class="custom-select__list-item"
            :class="{ 'active-item': index === stepCounter }"
            @click="setResult(item)"
          >
            {{ getItemValue(item) }}
          </li>
        </ul>
        <ul v-else ref="list" class="custom-select__list">
          <li
            v-for="(result, idx) in results"
            v-show="results.length > 0"
            :key="idx"
            class="custom-select__list-item"
            :class="{ 'active-item': idx === stepCounter }"
            @click="setResult(result)"
          >
            {{ getItemValue(result) }}
          </li>
          <li v-show="results.length === 0" class="custom-select__list-item">
            Sorry, nothing matched your search...
          </li>
        </ul>
      </div>
    </div>
    <span v-show="validationErrors.has(name, scope)" class="help is-danger float-left">
      <i class="fas fa-times-circle" />
      {{ validationErrors.first(name, scope) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SelectSource',
  inject: ['$validator'],
  props: {
    mandatory: {
      type: Boolean,
      default: false,
    },
    sources: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: 'source',
    },
    rule: {
      type: [String, Object],
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    scope: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      content: '',
      results: [],
      showList: false,
      empty: true,
      stepCounter: -1,
      clicked: false,
    };
  },
  computed: {
    allItems() {
      const items = this.sources ? this.sources.slice() : [];
      if (this.emptySelectOption && !items.find((item) => item[this.itemKey] === null)) {
        if (this.itemKey && this.itemValue) {
          items.unshift({ [this.itemKey]: null, [this.itemValue]: 'Choose one...' });
        } else {
          items.unshift('choose_one');
        }
      }
      return items;
    },
  },
  watch: {
    value(val) {
      this.$nextTick(() => {
        if (val) {
          const selected = this.sources.find((item) => item.id === val);
          if (selected) {
            this.content = this.getItemValue(selected);
          }
        } else {
          this.content = '';
        }
      });
    },
    items() {
      this.$nextTick(() => {
        if (this.value) {
          const selected = this.sources.find((item) =>
            [item.id, item.project_code_in].includes(this.value),
          );
          if (selected) {
            this.content = this.getItemValue(selected);
          }
        } else {
          this.content = '';
        }
      });
    },
  },
  created() {
    setTimeout(() => {
      if (this.value) {
        const selected = this.sources.find((item) => item.id === this.value);
        if (selected) {
          this.content = this.getItemValue(selected);
        }
      }
    }, 250);
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.scrollList);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.scrollList);
  },
  methods: {
    onInput(id) {
      this.$emit('input', { source: this.sources.find((el) => el.id === id) });
    },
    onChange() {
      if (this.content) {
        this.empty = false;
      } else {
        this.empty = true;
      }
      this.stepCounter = -1;
      this.results = this.sources.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.content.toLowerCase()) > -1 ||
          item.project_code_in.indexOf(this.content.toLowerCase()) > -1,
      );
    },
    getItemValue(item) {
      if (item) {
        return item.name;
      }
      return '';
    },
    setResult(result) {
      this.content = this.getItemValue(result);
      this.showList = false;
      this.$emit('input', { source: result });
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.showList = false;
        this.stepCounter = -1;
      }
    },
    toggleDropdown(e) {
      if (e.type === 'focus') {
        this.$nextTick(() => {
          this.showList = true;
          this.stepCounter = 0;
        });
      }
      this.showList = !this.showList;
    },
    onKeydown() {
      if (this.empty) {
        if (this.stepCounter < this.sources.length) {
          this.stepCounter = this.stepCounter + 1;
        }
      } else if (!this.empty) {
        if (this.stepCounter < this.results.length) {
          this.stepCounter = this.stepCounter + 1;
        }
      }
    },
    onKeyup() {
      if (this.stepCounter > 0) {
        this.stepCounter = this.stepCounter - 1;
      }
    },
    onEnter(e) {
      let result;
      const idx = this.results.length > 0 ? this.stepCounter : this.stepCounter - 1;
      if (this.empty) {
        this.content = this.getItemValue(this.sources[this.stepCounter]);
        result = this.sources[idx];
      } else {
        this.content = this.getItemValue(this.results[this.stepCounter]);
        result = this.results[idx];
      }
      this.showList = false;
      this.stepCounter = -1;
      this.$emit('input', { source: result });
      e.target.blur();
    },
    scrollList(e) {
      if (this.$refs.list && this.$refs.list.offsetHeight === 177) {
        for (let i = 0; i < this.sources.length; i++) {
          if (e.keyCode === 40 && this.stepCounter > 2) {
            this.$refs.list.scrollTop = 29 * this.stepCounter;
          } else if (e.keyCode === 38 && this.stepCounter > 0) {
            this.$refs.list.scrollTop = 29 * this.stepCounter - 29;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.custom-select {
  &__input {
    cursor: pointer;
    &:focus {
      border-radius: 2px 2px 0px 0px;
    }
  }

  &__arrow {
    position: absolute;
    top: calc(50% - 12.5px);
    right: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    z-index: 99;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 10px;
  }

  &__list {
    width: auto;
    min-width: 100%;
    margin: 0;
    padding: 16px 0 24px;
    background: white;
    border: 1px solid #2a689c;
    border-radius: 0px 0px 2px 2px;
    position: absolute;
    z-index: 999;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    .label-inline & {
      position: absolute;
    }

    &-item {
      padding: 0 12px;
      height: 29px;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      white-space: nowrap;
      user-select: none;
      &.active-item,
      &:hover {
        background-color: var(--list-hover-blue);
      }
    }
  }
}
</style>
