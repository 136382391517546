<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>IPD Performer Update</h1>
      </div>
    </div>

    <div v-if="completedUploadFilename" class="row card">
      <span class="text--bold">Upload was successful. Star will now update the catalogue.</span>
      <br />
      [ File:
      <i>{{ completedUploadFilename }}</i>
      ]
    </div>

    <div v-show="isUploadingFile && !error" class="row card">
      Uploading... {{ uploadFile.uploadProgress }} %
      <div class="progress">
        <div ref="uploadProgressBar" class="determinate" style="width: 0%" />
      </div>
    </div>

    <div class="row card">
      <file-upload-simple-field
        ref="fileUploadField"
        label="Upload sync file"
        name="documentRefId"
        accept-file-type=".xml"
        @addFile="updateFile"
        @removeFile="onRemoveFile"
      />
      <br />
      <button
        class="btn secondary abs-top"
        type="submit"
        :disabled="isUploadingFile || !uploadFile.handle"
        @click.prevent="upload"
      >
        Upload
      </button>
    </div>
    <ipd-merge-ipn />
  </div>
</template>

<script>
import FileUploadSimpleField from '../ui/file/file-upload-simple-field';
import AuthenticationService from '../../services/authenticationService';
import RemoteFileService from '../../services/remoteFileService';
import FileHandleMixin from '../../common/fileHandleMixin';
import CreateUploadFileDescriptor from '../../domain/uploadFileDescriptor';
import { getConfigValue, PROPS } from '../../services/configService';
import { createError, ErrorType } from '../../domain/starNotification';
import IpdMergeIpn from './ipd-merge-ipn';

function getEntityType(filename) {
  if (filename.startsWith('Download')) {
    return 'IPD_DOWNLOAD';
  }
  if (filename.startsWith('SUC')) {
    return 'IPD_FEEDBACK';
  }

  throw createError(
    ErrorType.IllegalArgumentException,
    'Filename must start with "Download_" or "SUC_"',
  );
}
async function uploadFile(fileHandle, progressUpdatedFunc) {
  const progressFunc = (progressEvent) =>
    progressUpdatedFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  const entityType = getEntityType(fileHandle.name);
  const metaIdentifiers = [fileHandle.name];
  const fileDescriptor = CreateUploadFileDescriptor(
    entityType,
    'STAR',
    metaIdentifiers,
    fileHandle,
  );
  const bucket = getConfigValue(PROPS.DATA_BUCKET);
  const options = {
    repo: bucket,
    directory: 'ipd/inbox',
    metadata: [
      {
        key: 'user',
        value: String(AuthenticationService.getUserName()),
      },
      {
        key: 'Content-Type',
        value: 'text/plain; charset=iso-8859-1',
      },
    ],
    progressFunc,
  };
  await RemoteFileService.uploadToDocumentRepository(fileDescriptor, options);
}

export default {
  name: 'ViewIpdMemberOverview',
  components: {
    FileUploadSimpleField,
    IpdMergeIpn,
  },
  mixins: [FileHandleMixin],
  data() {
    return {
      viewRouteName: 'ipdMemberOverview',
      documentRefId: `${this.name}_document`,
      uploadFile: { handle: undefined, uploadProgress: 0 },
      isUploadingFile: false,
      completedUploadFilename: undefined,
    };
  },
  watch: {
    uploadFile: {
      handler(uploader) {
        this.$refs.uploadProgressBar.style.width = `${uploader.uploadProgress}%`;
      },
      deep: true,
    },
  },
  methods: {
    async upload() {
      this.isUploadingFile = true;
      this.error = false;
      try {
        this.uploadFile.uploadProgress = 0;
        await uploadFile(
          this.uploadFile.handle,
          (progressInPercent) => (this.uploadFile.uploadProgress = progressInPercent),
        );
        this.completedUploadFilename = String(this.uploadFile.handle.name);
        this.resetFileUpload();
      } catch (error) {
        this.handleError('Upload failed', error);
      } finally {
        this.isUploadingFile = false;
      }
    },
    handleError(title, error) {
      this.$addStarError(error);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-nopad {
  padding-bottom: 0px !important;
}
</style>
