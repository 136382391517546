<template>
  <div>
    <select-input
      name="society"
      :scope="scope"
      :label="label"
      :value="value"
      :items="inputSocieties"
      :rule="rule"
      :mandatory="mandatory"
      :item-key="itemKey"
      item-value="name"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectSociety',
  components: { SelectInput },
  props: {
    filterCodes: { type: Array, default: null },
    filterIds: { type: Array, default: null },
    value: { type: String, default: '' },
    label: { type: String, default: 'Country' },
    mandatory: { type: Boolean, default: false },
    rule: { type: String, default: '' },
    scope: { type: String, default: undefined },
    extendedResult: { type: Boolean, default: false },
    itemKey: { type: String, default: 'ipd_code' },
  },
  computed: {
    societies() {
      return this.$store.state.appdata.societies;
    },
    inputSocieties() {
      let societies = this.societies;
      if (this.filterCodes) {
        societies = societies.filter((s) => this.filterCodes.includes(s.ipd_code));
      }
      if (this.filterIds) {
        societies = societies.filter((s) => this.filterIds.includes(s.id));
      }
      return societies;
    },
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        if (this.extendedResult) {
          this.$emit('input', {
            value,
            localCode: this.societies.find((sd) => sd.ipd_code === value),
          });
        } else {
          this.$emit('input', value);
        }
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
