<template>
  <div>
    <table class="">
      <tbody>
        <tr v-for="(entry, index) in entries" :key="entry._localId">
          <template v-if="editing[entry._localId]">
            <td colspan="3" style="padding-right: 40px">
              <selectable-entry
                :name="'selectable.entry.' + entry._localId"
                label="Source"
                second-label="Local code"
                :disabled="isFixedCode(entry.code)"
                :show-label="showLabel"
                :scope="scope"
                :entry="entry"
                :entry-key-name="entryKeyName"
                :entry-value-name="entryValueName"
                :items="localCodeItems(entry.code)"
                :disabled-items="disabledItems"
                :selectable-item-key-name="selectableItemKeyName"
                :selectable-item-value-name="selectableItemValueName"
                value-placeholder="E.g. 81131"
                :deletable="!isFixedCode(entry.code) && entries.length > 1"
                @input="updateEntry(index, $event)"
                @remove="removeEntry(index)"
              />
            </td>
          </template>
          <template v-else>
            <td style="width: 50%">{{ entry.code }}</td>
            <td style="width: 50%">{{ entry.value }}</td>
            <td class="thin-column">
              <a v-if="!isFixedCode(entry.code)" href="#" @click.prevent="onClickEdit(entry)">
                Edit
              </a>
            </td>
          </template>
        </tr>
        <tr>
          <td colspan="3">
            <div class="edit-link">
              <a
                :class="{ disabled: !lastHasEntry() }"
                tabindex="0"
                @click="addEntry"
                @keyup.enter="addEntry"
              >
                <i class="far fa-plus-square" />
                Add Local code
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/**
 * The Selectable entries component is a variation of the selectable-entry that allows a collection of entries.
 * New entries can be added by clicking the add-button. Add can only be invoked if the entry key is set.
 *
 * The entries are provided to the component as an array. An entry can have one of the following formats:
 *
 * 1. [..,{ a-key-name: 'the key value', a-value-name: 'the value' },..]
 * 2  [..,{ a-key-name: a-value-name },..]
 *
 * Case 1 requires that the entryKeyName and entryValueName attributes are set.
 * Case 2 is assumed if the above attributes are omitted or ''.
 *
 * An input event is triggered on change, it contains the edited entry and the index position from the backing array.
 */
import * as uuid from 'uuid';
import SelectableEntry from '../select/selectable-entry';

export default {
  name: 'LocalCodes',
  components: { SelectableEntry },
  props: {
    name: { type: String, default: '' },
    showLabel: { type: Boolean, default: true },
    entries: Array,
    scope: { type: String, default: undefined },
    entryKeyName: { type: String, default: '' },
    entryValueName: { type: String, default: '' },
    entryValueRequired: { type: Boolean, default: true },
    localCodes: Array,
    selectableItemKeyName: { type: String, default: 'key' },
    selectableItemValueName: { type: String, default: 'value' },
  },
  data() {
    return {
      editing: {},
    };
  },
  computed: {
    disabledItems() {
      return this.localCodes.filter((code) => this.isFixedCode(code.code));
    },
  },
  watch: {
    entries() {
      const newIds = this.setLocalIds();
      newIds.forEach((id) => this.$set(this.editing, id, true));
    },
  },
  mounted() {
    this.setLocalIds();
  },
  methods: {
    onClickEdit(entry) {
      this.$set(this.editing, entry._localId, true);
    },
    isFixedCode(code) {
      return ['NEWONLINE', 'public_id', 'VRDB2', 'VRDB2_ASSOCIATED', 'VRDB2_PLAYLIST_ID'].includes(
        code,
      );
    },
    localCodeItems(code) {
      const localCode = this.localCodes.find((lc) => lc.code === code);
      if (!localCode) {
        return [...this.localCodes, { code, name: code }];
      }
      return this.localCodes;
    },
    getEntryKey(entry) {
      return this.entryKeyName !== '' ? entry[this.entryKeyName] : Object.keys(entry)[0];
    },
    setLocalIds() {
      const ids = [];

      this.entries.forEach((entry) => {
        if (!entry._localId) {
          const id = uuid.v4();
          entry._localId = id;
          ids.push(id);
        }
      });

      return ids;
    },
    updateEntry(index, entry) {
      this.$emit('input', { index, entry });
    },
    lastHasEntry() {
      if (this.entries.length === 0) {
        return true;
      }
      const lastEntry = this.entries.slice(-1)[0];
      return lastEntry && lastEntry.code && lastEntry.value;
    },
    addEntry() {
      if (this.lastHasEntry()) {
        this.$emit('add');
      }
    },
    removeEntry(index) {
      this.$emit('remove', index);
    },
  },
};
</script>
