<template>
  <form>
    <div v-if="editMode" class="row text--right">
      <div class="col s12 float-right">
        <action-buttons @save="update" @cancel="cancel" />
      </div>
    </div>
    <div class="card">
      <div class="row flush--bottom">
        <div class="col s12 l8">
          <validated-text-input
            label="Name"
            :mandatory="true"
            placeholder="E.g. United Federation of Planets"
            rule="required"
            name="basic_info.name"
            :value="basicInfoName"
            @input="updateBasicInfoName"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'CreateBasicInfo',
  components: {
    ValidatedTextInput,
    ActionButtons,
  },
  inject: ['$validator'],
  props: {
    editMode: { type: Boolean, default: false },
  },
  data() {
    return {
      middleNameActive: false,
    };
  },
  computed: mapGetters('society', ['basicInfoName']),
  methods: {
    ...mapMutations('society', ['updateBasicInfoName']),
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    update() {
      this.$emit('update', this.$options.name);
    },
  },
};
</script>
