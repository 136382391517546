<template>
  <tr>
    <td>
      <input :id="index" class="filled-in" type="checkbox" name="mark" disabled />
      <label :for="index" />
    </td>
    <td>{{ performer.name }}</td>
    <td>
      <select-input
        :name="`recording.lineup.${index}.pseudo`"
        :items="pseudoNameOptions"
        :value="localPerformer.pseudo"
        @input="updatePseudo"
      />
    </td>
    <td>
      <select-instrument
        :name="`recording.lineup.${index}.instrument`"
        :addable="true"
        :show-label="false"
        :value="localPerformer.instruments"
        @input="updateInstruments"
      />
    </td>
    <td colspan="2">
      <select-input
        v-model="localPerformer.role"
        :name="`recording.lineup.${index}.role`"
        :items="roleRefs"
        item-key="code"
        rule="required"
        item-value="name"
      />
    </td>
    <td class="text--right action-btn" colspan="4">
      <action-buttons @save="$emit('save', localPerformer)" @cancel="$emit('cancel')" />
    </td>
    <div />
  </tr>
</template>

<script>
import ActionButtons from '../../ui/button/action-buttons';
import SelectInstrument from '../../ui/select/select-instrument';
import clone from '../../../common/clone';
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'EditLineupRow',
  components: {
    ActionButtons,
    SelectInstrument,
    SelectInput,
  },
  props: {
    performer: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      optionsVisiblePerformerId: '',
      localPerformer: null,
    };
  },
  computed: {
    pseudoNameOptions() {
      const opts =
        this.localPerformer.pseudoNames &&
        this.localPerformer.pseudoNames.map((name) => ({
          code: name,
          name,
        }));
      return opts;
    },
    roles() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
    roleRefs() {
      const samiWWMandate =
        this.localPerformer.societies.filter(
          (s) => s.society_code === '06' && s.mandate_type === 'WW',
        ).length > 0;
      const { collective } = this.localPerformer;

      if (collective && samiWWMandate) {
        return this.roles.filter((r) => r.code === 'SC');
      } else if (collective) {
        return this.roles.filter((r) => r.code === 'FC');
      } else if (!samiWWMandate) {
        return this.roles.filter((r) => r.code !== 'SC');
      }

      return this.roles.filter((r) => !['SC', 'FC'].includes(r.code));
    },
  },
  created() {
    this.localPerformer = clone(this.performer);
  },
  methods: {
    updateInstruments(value) {
      this.localPerformer.instruments = value;
    },
    updatePseudo(pseudo) {
      this.localPerformer.pseudo = pseudo;
    },
  },
};
</script>

<style lang="scss" scoped>
tr:hover {
  background: none;
}
td {
  overflow: visible;
}
</style>
