<template>
  <div>
    <view-contact-info :contact-info="contactInfo" @edit="$emit('edit', $options.name)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewContactInfo from '../../../ui/contact/view-contact-info';

export default {
  name: 'ContactInformation',
  components: { ViewContactInfo },
  data() {
    return {};
  },
  computed: {
    hasAddress() {
      return this.street || this.city || this.province || this.country;
    },
    ...mapGetters('performer', ['contactInfo']),
  },
};
</script>
