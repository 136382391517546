<template>
  <div>
    <div class="row">
      <div class="col s12">
        {{ message }}
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        {{ verificationMessage }}
      </div>
    </div>
    <div class="row">
      <div class="col s6">
        <form
          v-if="verificationStatus === 'login' || verificationStatus === 'loginError'"
          data-vv-scope="password-form"
          @submit.prevent="onSubmit"
        >
          <div class="label-inline">
            <label for="password" class="label">Password</label>
            <input
              id="password"
              v-model="password"
              v-validate="
                verificationStatus === 'login' || verificationStatus === 'loginError'
                  ? 'required|min:10'
                  : ''
              "
              autocomplete="off"
              data-vv-as="password"
              name="password"
              type="password"
              :disabled="isSubmitted || disabled"
            />
          </div>
          <span
            v-show="validationErrors.has('password', 'password-form')"
            class="help is-danger float-right"
          >
            <i class="fas fa-times-circle"></i>
            {{ validationErrors.first('password-form.password') }}
          </span>
        </form>

        <form
          v-if="verificationStatus === 'mfaCodeRequired' || verificationStatus === 'mfaError'"
          data-vv-scope="mfaCode-form"
          @submit.prevent="onSubmit"
        >
          <div class="label-inline">
            <label for="mfaCode" class="label">Code</label>
            <input
              id="mfaCode"
              v-model="mfaCode"
              v-validate="
                verificationStatus === 'mfaCodeRequired' || verificationStatus === 'mfaError'
                  ? 'required'
                  : ''
              "
              data-vv-as="mfaCode"
              autocomplete="off"
              name="mfaCode"
              :disabled="isSubmitted || disabled"
            />
          </div>
          <span
            v-show="validationErrors.has(mfaCode, 'mfaCode-form')"
            class="help is-danger float-right"
          >
            <i class="fas fa-times-circle"></i>
            {{ validationErrors.first('mfaCodeform.mfaCode') }}
          </span>
        </form>
      </div>
      <div class="col s6">
        <action-buttons
          :submit-label="submitLabel"
          :show-abort="showAbort"
          :disable-submit="isSubmitted || disabled"
          @keydown.prevent.enter="onSubmit"
          @save="onSubmit"
          @cancel="onCancel"
        />
      </div>
    </div>
    <div v-if="local && developer" class="row">
      <div class="col"><button class="btn" @click="onBypassAuth">Bypass 😎</button></div>
    </div>
  </div>
</template>

<script>
import ActionButtons from '../button/action-buttons';
import { getConfigValue, PROPS } from '../../../services/configService';

export default {
  name: 'VerifyWithPassword',
  components: {
    ActionButtons,
  },
  inject: ['$validator'],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    showAbort: {
      type: Boolean,
      default: false,
    },
    submitLabel: {
      type: String,
      default: 'Confirm',
    },
    message: {
      type: String,
      default: "I hereby confirm that I know what I'm doing.",
    },
    verificationStatus: {
      type: String,
      default: 'login',
    },
    numberOfAttempts: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      password: '',
      mfaCode: '',
      isSubmitted: false,
    };
  },
  computed: {
    verificationMessage() {
      if (this.verificationStatus === 'login') {
        return 'Please verify with your password.';
      } else if (this.verificationStatus === 'mfaCodeRequired') {
        return 'Please verify your account with the verification code sent to you by SMS.';
      } else if (
        this.verificationStatus === 'loginError' ||
        this.verificationStatus === 'mfaError'
      ) {
        return 'Something went wrong, please try again. ' + this.verificationStatus;
      }
      return '';
    },
    developer() {
      return this.$store.state.user.developer;
    },
    local() {
      return getConfigValue(PROPS.ENVIRONMENT) === 'local';
    },
  },
  watch: {
    numberOfAttempts() {
      if (this.numberOfAttempts === 0) {
        this.isSubmitted = true;
      } else {
        this.isSubmitted = false;
        this.password = '';
        this.mfaCode = '';
      }
    },
    verificationStatus() {
      this.isSubmitted = false;
      this.password = '';
      this.mfaCode = '';
    },
  },
  created() {
    this.value = this.filterQuery;
  },
  methods: {
    onSubmit() {
      this.isSubmitted = true;
      if (this.verificationStatus === 'login' || this.verificationStatus === 'loginError') {
        this.onSubmitPassword();
      } else if (
        this.verificationStatus === 'mfaCodeRequired' ||
        this.verificationStatus === 'mfaError'
      ) {
        this.onSubmitMfaCode();
      }
    },
    onBypassAuth() {
      this.$emit('onBypassAuth');
    },
    onSubmitPassword() {
      this.$emit('onSubmitPassword', this.password);
    },
    onSubmitMfaCode() {
      this.$emit('onSubmitMfaCode', this.mfaCode);
    },
    onCancel() {
      this.$emit('onCancel');
    },
  },
};
</script>
