<template>
  <div class="reusable-spinner" :style="cssRules" />
</template>

<script>
/*
  This spinner is more reusable than ComponentSpinner because it doesn't include all the weird positioning.
  Position it how you want, whereever you use it.
  Use the size prop to set it's size in pixels.
*/

export default {
  name: 'ReusableSpinner',
  props: {
    size: {
      type: Number,
      default: 60,
      required: false,
    },
  },
  computed: {
    cssRules() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        borderWidth: `${(this.size * 0.16667).toFixed(2)}px`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.reusable-spinner {
  border-radius: 999999px;
  border-color: rgba(76, 175, 80, 0.3);
  border-style: solid;
  border-left-color: var(--green);
  animation: load8 1.1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
