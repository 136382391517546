/**
 * Common functionality for report views
 */

import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { createRecordingAggregate, UNKNOWN_PROTECTED_DATE } from '../../domain/recordingDomain';
import ContextMenu from '../ui/context-menu';
import MusicReportHelper from './musicReportHelper';
import RecordingService from '../../services/recordingService';
import AlbumService from '../../services/albumService';
import MatchingService from '../../services/matchingService';
import Spinner from '../spinner';

const CREATED_FROM_MUSIC_REPORT_TAG = 'created_from_music_report';

const playTimeCols = [
  {
    name: 'Recording',
    ascending: true,
    sortParam: 'title',
    active: true,
    default: true,
  },
  {
    name: 'Version',
  },
  {
    name: 'Main Artist',
    ascending: true,
    sortParam: 'main_artist',
    active: false,
  },
  {
    name: 'Recording Year',
    sortParam: 'recording_year',
    active: true,
  },
  {
    name: 'Recorded in Country',
    sortParam: 'recording_country',
    active: true,
  },
  {
    name: 'ISRC',
    sortParam: 'isrc',
    active: true,
  },
  {
    name: 'Label',
    sortParam: 'label',
    active: true,
  },
  {
    name: 'Airtime Played',
    ascending: true,
    sortParam: 'airplay_time',
    active: false,
  },
  {
    name: 'No. of Times',
    sortParam: 'line_count',
    active: true,
  },
  {
    name: 'Candidates',
  },
];

const albumCols = [
  {
    name: 'Album',
    ascending: true,
    sortParam: 'title',
    active: true,
    default: true,
  },
  {
    name: 'Main Artist',
    ascending: true,
    sortParam: 'main_artist',
    active: false,
    default: false,
  },
  {
    name: 'No. of Times',
    ascending: true,
    sortParam: 'total_count::int',
    active: false,
    default: false,
  },
];

const sdegCols = [
  {
    name: 'Recording',
    ascending: true,
    sortParam: 'title',
    active: true,
    default: true,
  },
  {
    name: 'Version',
  },
  {
    name: 'Main Artist',
    sortParam: 'main_artist',
    ascending: true,
    active: false,
  },
  {
    name: 'Recording Year',
    sortParam: 'recording_year',
    active: true,
  },
  {
    name: 'Recorded in Country',
    sortParam: 'recording_country',
    ascending: true,
    active: false,
  },
  {
    name: 'ISRC',
    sortParam: 'isrc',
    active: true,
  },
  {
    name: 'Label',
    sortParam: 'label',
    active: true,
  },
  {
    name: 'No. of Times',
    sortParam: 'line_count',
    active: true,
  },
];

const sdegCandidateCols = [
  {
    name: 'Recording',
    ascending: true,
    sortParam: 'title',
    active: true,
    default: true,
  },
  {
    name: 'Version',
  },
  {
    name: 'Main Artist',
    sortParam: 'main_artist',
    ascending: true,
    active: false,
  },
  {
    name: 'Recording Year',
    sortParam: 'recording_year',
    active: true,
  },
  {
    name: 'Recorded in Country',
    sortParam: 'recording_country',
    ascending: true,
    active: false,
  },
  {
    name: 'ISRC',
    sortParam: 'isrc',
    active: true,
  },
  {
    name: 'Label',
    sortParam: 'label',
    active: true,
  },
  {
    name: 'No. of Times',
    sortParam: 'line_count',
    active: true,
  },
  {
    name: 'Member',
    sortParam: 'sami_member',
    ascending: true,
    active: false,
  },
];

const errorCols = [
  {
    name: 'Recording',
    ascending: true,
    sortParam: 'title',
    active: true,
    default: true,
  },
  {
    name: 'Version',
  },
  {
    name: 'Main Artist',
    ascending: true,
    sortParam: 'main_artist',
    active: false,
  },
  {
    name: 'Recording Year',
    sortParam: 'recording_year',
    active: true,
  },
  {
    name: 'Recorded in Country',
    sortParam: 'recording_country',
    active: true,
  },
  {
    name: 'Error',
  },
];

export default {
  components: {
    ContextMenu,
    Spinner,
  },
  props: {
    reporterId: {
      type: String,
      default: '',
    },
  },
  activated() {
    this.addKeyListeners();
  },
  deactivated() {
    this.clearComparedObjects();
    this.removeKeyListeners();
  },
  data() {
    return {
      loading: true,
      reportId: this.$router.currentRoute.params.reportId,
      isAlbum: this.$router.currentRoute.query.album === 'true',
      optionsVisibleId: '',
      cols: this.columns,
      totalCount: 0,
      error: false,
      queryErrorTypes: [],
      expandedRecordingId: '',
      dirty: false,
      columns: [],
    };
  },
  created() {
    this.getColumns();
  },
  watch: {
    async $route() {
      this.getColumns();
    },
  },
  computed: {
    ...mapGetters('matching', [
      'candidates',
      'currentIncoming',
      'currentCandidate',
      'reportLines',
      'viewMusicReportMatchedLines',
      'viewMusicReportCandidateLines',
      'viewMusicReportRestedLines',
      'viewMusicReportIgnoredLines',
      'viewMusicReportErrorLines',
    ]),
    isSdeg() {
      return (
        this.$router.currentRoute.meta.namespace &&
        this.$router.currentRoute.meta.namespace === 'sdeg'
      );
    },
  },
  methods: {
    ...mapMutations('matching', [
      'replaceInReportLines',
      'updateCandidates',
      'updateCurrentCandidate',
      'updateCurrentIncoming',
      'updateReportLines',
      'updateViewMusicReportMatchedLinesExpandedId',
      'updateViewMusicReportCandidateLinesExpandedId',
      'updateViewMusicReportRestedLinesExpandedId',
      'updateViewMusicReportIgnoredLinesExpandedId',
      'updateViewMusicReportErrorLinesExpandedId',
    ]),
    getColumns() {
      if (this.$router.currentRoute.name === 'musicReportErrorView') {
        this.columns = errorCols;
      } else if (!this.isSdeg) {
        this.columns = this.isAlbum ? albumCols : playTimeCols;
      } else if (this.$router.currentRoute.name === 'viewSdegPlayedRecordingsReportCandidates') {
        this.columns = sdegCandidateCols;
      } else {
        this.columns = sdegCols;
      }
    },
    async fetchData() {
      this.loading = true;
      this.error = false;
      const worklistId = this.$router.currentRoute.query.worklist_id;

      try {
        const result = await MusicReportHelper.getLines(
          this.reportId,
          this.pagination.hitsPerPage,
          this.lineupStateMask,
          this.getOffset(),
          this.sortTerms.param,
          this.sortTerms.order,
          this.recordedInCountryCode,
          this.range,
          worklistId,
          this.queryErrorTypes,
          this.customSelect, // SAMI member
          this.isAlbum,
        );

        this.totalCount = result.total_count;
        const reportLines = result.items.map((incoming) => ({
          incoming,
          matched: {},
          checked: false,
          dirty: false,
        }));

        this.updateReportLines(reportLines);
      } catch (error) {
        error.title = 'Could not fetch report';
        this.$addStarError(error);
      }
      this.loading = false;
    },
    getCandidateValue(path) {
      return _.get(this.candidate, path.split('.')) || '';
    },
    numberOfPages() {
      return Math.ceil(this.totalCount / this.pagination.hitsPerPage);
    },
    allAreSelected(predicate = () => true) {
      return this.reportLines.length > 0
        ? this.reportLines
            .filter(predicate)
            .filter((line) => !line.completed)
            .every((line) => line.checked)
        : false;
    },
    toggleSelectAll(deselect, predicate = () => true) {
      const select = deselect || this.allAreSelected(predicate);
      this.reportLines
        .filter(predicate)
        .filter((line) => !line.completed)
        .forEach((line) => (line.checked = !select));
    },
    anySelected() {
      return this.reportLines.some((line) => line.checked);
    },
    addKeyListeners() {
      window.addEventListener('keydown', this.onKeyDown);
    },
    removeKeyListeners() {
      window.removeEventListener('keydown', this.onKeyDown);
    },
    onKeyDown(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.togglePrevOrNext('next');
      }
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.togglePrevOrNext('prev');
      }
    },
    getLine(groupId) {
      return this.reportLines.find((line) => line.incoming.group_id === groupId);
    },
    getLineIndex(groupId) {
      return this.reportLines.findIndex((r) => r.incoming.group_id === groupId);
    },
    clearComparedObjects() {
      this.updateCurrentIncoming(undefined);
      this.updateCurrentCandidate(undefined);
    },
    async setComparedObjects(groupId) {
      const line = this.getLine(groupId);
      if (line) {
        this.updateCurrentIncoming(line.incoming);
        if (line.incoming.match_id) {
          await this.setCurrentCandidate(line.incoming.match_id);
        } else if (this.candidates && this.candidates.length > 0) {
          await this.setCurrentCandidate(this.candidates[0].id);
        } else {
          this.clearCurrentCandidate();
        }
      }
    },
    async setCurrentCandidate(recordingId) {
      try {
        let result;
        if (this.isAlbum) {
          result = await AlbumService.getAlbum(recordingId);
        } else {
          result = await RecordingService.getRecording(recordingId);
        }
        this.updateCurrentCandidate(result);
        this.error = false;
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    clearCurrentCandidate() {
      this.updateCurrentCandidate(createRecordingAggregate());
    },
    candidateChanged(recordingId) {
      this.setCurrentCandidate(recordingId);
    },
    async handleIdentifyBtn(incoming) {
      if (!this.isAlbum) {
        const candidateUpdated =
          this.updateCandidate(incoming) || (await this.updateDirtyState(incoming.group_id));
        if (candidateUpdated) {
          await this.saveChanges();
        }
      }
      this.identifyCurrentCandidate(incoming.group_id, null);
    },
    removeAddedLocalCodes(incoming) {
      return this.removeLocalCodes(incoming.local_ids);
    },
    updateCandidate(incoming) {
      const localCodesUpdated = this.updateLocalCodes(incoming.local_ids);
      const isrcUpdated = this.updateIsrc(incoming.isrc);
      return localCodesUpdated || isrcUpdated;
    },
    removeLocalCodes(localCodes) {
      if (this.reporterId === 'COM') {
        return false;
      }
      const incomingLocalCodes = this.parseLocalCodes(localCodes);
      const candidateLocalCodes = this.currentCandidate.basic_info.local_codes;

      const removedLocalCodes = candidateLocalCodes.filter(
        (lc) => !incomingLocalCodes.find((ilc) => ilc.code === lc.code && ilc.value === lc.value),
      );
      if (removedLocalCodes.length !== candidateLocalCodes.length) {
        this.$store.commit('matching/updateCurrentCandidateBasicInfoLocalCodes', removedLocalCodes);
        return true;
      }
      return false;
    },
    updateLocalCodes(localCodes) {
      if (this.reporterId === 'COM') {
        return false;
      }
      const incomingLocalCodes = this.parseLocalCodes(localCodes);
      const candidateLocalCodes = this.currentCandidate.basic_info.local_codes;
      const mergedLocalCodes = this.mergeLocalCodes(incomingLocalCodes, candidateLocalCodes);

      if (mergedLocalCodes.length !== candidateLocalCodes.length) {
        this.$store.commit('matching/updateCurrentCandidateBasicInfoLocalCodes', mergedLocalCodes);
        return true;
      }
      return false;
    },
    updateIsrc(isrc) {
      if (isrc && !this.currentCandidate.basic_info.isrc) {
        this.$store.commit('matching/updateCurrentCandidateBasicInfoIsrc', isrc);
        return true;
      }
      return false;
    },
    async handleIgnoreBtn(incoming) {
      this.ignoreCurrentCandidate(incoming.group_id);
    },
    async handleUnmatchBtn(incoming) {
      const candidateUpdated = this.removeAddedLocalCodes(incoming);
      if (candidateUpdated) {
        await this.saveChanges();
      }
      this.unMatchCurrentCandidate(incoming.group_id);
    },
    async handleSaveBtn(groupId) {
      await this.saveChanges();
      this.reloadCompared(groupId);
    },
    async handleNewRecording(line) {
      await this.createAndIdentify(line);
      await this.refresh();
    },
    async identifyCurrentCandidate(groupId, recordingId) {
      if (this.currentCandidate.id) {
        const id = recordingId || this.currentCandidate.id;
        if (!this.isAlbum) {
          await MusicReportHelper.identifyMatchLine(groupId, this.currentCandidate.id);
        } else {
          await MusicReportHelper.identifyMatchAlbumLine(groupId, this.currentCandidate.id);
        }
        this.markCompleted([groupId], 'M', id);
        this.togglePrevOrNext('next');
      } else {
        this.$addStarError(
          new Error('Something went wrong when identifying candidate, please try again'),
        );
      }
    },

    async ignoreCurrentCandidate(groupId) {
      if (!this.isAlbum) {
        await MusicReportHelper.ignoreMatchLine(groupId, this.currentCandidate.id);
      } else {
        await MusicReportHelper.ignoreMatchAlbumLine(groupId, this.currentCandidate.id);
      }
      this.markCompleted([groupId], 'I');
      this.togglePrevOrNext('next');
    },
    async unMatchCurrentCandidate(groupId) {
      await MusicReportHelper.unmatchMatchLine(groupId, this.currentCandidate.id);
      this.markCompleted([groupId], 'R');
      this.togglePrevOrNext('next');
    },
    async saveChanges() {
      this.error = false;
      try {
        if (!this.isAlbum) {
          await RecordingService.updateRecordingBasicInfo(_.cloneDeep(this.currentCandidate), true);
        } else {
          await AlbumService.updateAlbumBasicInfo(_.cloneDeep(this.currentCandidate));
        }
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    async createNewRecordings(reportId, lines, all) {
      try {
        if (this.isAlbum) {
          await MusicReportHelper.createNewAlbumFromRested(reportId, lines, all);
        } else {
          await MusicReportHelper.createNewFromRested(reportId, lines, all);
        }

        const groupIds = lines.map((l) => l.group_id);
        this.markCompleted(groupIds, 'M');

        if (lines.every((l) => this.isExpanded(l.group_id))) {
          this.togglePrevOrNext('next');
        } else {
          this.$emit('updated');
        }
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    async createAndIdentify(line) {
      const agg = Object.assign(createRecordingAggregate(), {
        basic_info: {
          name: line.incoming.title,
          version_title: line.incoming.version_title,
          main_artist: {
            name: line.incoming.main_artist,
          },
          recorded_in_country: line.incoming.recording_country,
          recording_date: line.incoming.recording_year
            ? `${line.incoming.recording_year}-01-01`
            : UNKNOWN_PROTECTED_DATE,
          label: line.incoming.label,
          isrc: line.incoming.isrc,
          local_codes: this.parseLocalCodes(line.incoming.local_ids),
          tags: [CREATED_FROM_MUSIC_REPORT_TAG],
        },
      });
      try {
        const recordingId = await RecordingService.createRecording(agg);
        this.identifyCurrentCandidate(line.incoming.group_id, recordingId);
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
    async reloadCompared(groupId) {
      this.clearComparedObjects();
      await this.setComparedObjects(groupId);
      this.updateDirtyState(groupId);
    },
    async togglePrevOrNext(direction) {
      if (!this.expandedRecordingId) {
        this.setExpanded(undefined);
        return;
      }

      let idx = this.reportLines.findIndex((l) => this.isExpanded(l.incoming.group_id));
      idx = direction === 'prev' ? idx - 1 : idx + 1;

      if (idx < 0 || idx >= this.reportLines.length) {
        const page =
          direction === 'prev' ? this.pagination.currentPage - 1 : this.pagination.currentPage + 1;
        this.setExpanded(undefined);

        if (page < 1 || page > this.numberOfPages()) {
          return;
        }

        await this.selectPage(page);
        idx = direction === 'prev' ? this.reportLines.length - 1 : 0;
      }

      const groupId = this.reportLines[idx].incoming.group_id;
      await this.toggleExpanded(groupId);
    },
    async fetchCandidates(hits) {
      if (!hits) {
        return;
      }

      try {
        const streamIds = hits.map((hit) => hit.id);
        const candidates = this.isAlbum
          ? await MatchingService.loadAlbumHits(streamIds)
          : await MatchingService.loadHits(streamIds);
        const sortedCandidates = candidates.sort((a, b) => {
          if (a.samiLineupMember && !b.samiLineupMember) {
            return -1;
          }

          if (b.samiLineupMember && !a.samiLineupMember) {
            return 1;
          }

          if (a.vrdb2_id && !b.vrdb2_id) {
            return -1;
          }

          if (b.vrdb2_id && !a.vrdb2_id) {
            return 1;
          }

          if (a.status === 'Green' && b.status !== 'Green') {
            return -1;
          }

          if (b.status === 'Green' && a.status !== 'Green') {
            return 1;
          }

          return streamIds.indexOf(a.id) - streamIds.indexOf(b.id);
        });
        this.updateCandidates(sortedCandidates);
      } catch (error) {
        console.log('Error fetching candidates', error);
      }
    },
    async toggleExpanded(groupId) {
      if (this.isExpanded(groupId)) {
        this.setExpanded(undefined);
        return;
      }

      this.setExpanded(groupId);

      const line = this.getLine(groupId);
      if (line && line.incoming.match_state === 'C') {
        await this.fetchCandidates(line.incoming.hits);
      } else {
        this.updateCandidates([]);
      }

      await this.reloadCompared(groupId);
    },
    scrollToId(groupId) {
      this.$nextTick(() => {
        const idx = this.getLineIndex(groupId);
        if (idx >= 0) {
          this.$scrollTo(this.$refs.comparelist.$refs.line[idx], {
            cancelable: false,
          });
        }
      });
    },
    isExpanded(groupId) {
      return this.expandedRecordingId === groupId;
    },
    setExpanded(groupId) {
      this.expandedRecordingId = groupId;
      if (groupId !== undefined) {
        this.scrollToId(groupId);
      }
    },
    markCompleted(groupIds, state, matchId = undefined) {
      groupIds.forEach((id) => {
        const line = this.getLine(id);
        line.completed = true;
        line.checked = false;
        line.incoming.match_state = state;
        line.incoming.match_id = matchId;
      });

      this.completed += groupIds.length;
    },
    async identifyOnCheckedItems() {
      this.loading = true;
      const lines = this.reportLines.filter((line) => line.checked);

      const samiLineupMember = this.currentCandidate.lineup.some(
        (l) =>
          l.relation.societies &&
          l.relation.societies.some(
            (s) => s.society_code === '06' && ['WW', 'WW-', 'R+'].includes(s.mandate_type),
          ),
      );

      if (this.isSdeg && !samiLineupMember) {
        await Promise.all(
          lines.map(async (line) => this.ignoreCurrentCandidate(line.incoming.group_id)),
        );
      } else {
        let candidateUpdated = false;

        await Promise.all(
          lines.map(async (line) => {
            const updated = this.updateCandidate(line.incoming);
            candidateUpdated = candidateUpdated || updated;

            await this.identifyCurrentCandidate(line.incoming.group_id, null);
          }),
        );

        if (candidateUpdated) {
          await this.saveChanges();
        }
      }

      this.$emit('updated');
    },
    async revertMatchOnCheckedItems() {
      const groupIds = this.reportLines
        .filter((line) => line.checked)
        .map((line) => line.incoming.group_id);

      this.loading = true;

      if (!this.isAlbum) {
        await MusicReportHelper.revertMatch(groupIds);
      } else {
        await MusicReportHelper.revertAlbumMatch(groupIds);
      }

      this.markCompleted(groupIds, 'R');
      this.$emit('updated');
    },
    async revertMatch(incoming) {
      if (!this.isAlbum) {
        const candidateUpdated = this.removeAddedLocalCodes(incoming);
        if (candidateUpdated) {
          await this.saveChanges();
        }
        await MusicReportHelper.revertMatch([incoming.group_id]);
      } else {
        await MusicReportHelper.revertAlbumMatch([incoming.group_id]);
      }

      this.markCompleted([incoming.group_id], 'R');
      this.setComparedObjects(incoming.group_id);
      this.togglePrevOrNext('next');
    },
    async updateDirtyState(groupId) {
      await this.$nextTick();
      const line = this.getLine(groupId);
      if (line) {
        let state = false;
        if (this.$refs.comparelist && this.$refs.comparelist.$children.length > 0) {
          state = !!this.$refs.comparelist.$children.find((el) => el.dirty === true);
        }
        line.dirty = state;
        this.replaceInReportLines({ index: this.getLineIndex(groupId), value: line });
        return state;
      }
      return false;
    },
    mergeLocalCodes(incomingLocalCodes, candidateLocalCodes) {
      const mergedLocalCodes = [];
      incomingLocalCodes.forEach((lc) => {
        if (!candidateLocalCodes.find((eLc) => eLc.code === lc.code && eLc.value === lc.value)) {
          mergedLocalCodes.push(lc);
        }
      });
      mergedLocalCodes.push(...candidateLocalCodes);
      return mergedLocalCodes;
    },
    parseLocalCodes(ids = []) {
      if (!ids) {
        return [];
      }
      return ids.map((id) => {
        const [code, value] = id.split(':');
        return { code, value };
      });
    },
    async addUsageCorrection(payload) {
      this.loading = true;
      try {
        await MatchingService.addUsageCorrection({ report_id: this.reportId, ...payload });
        this.$emit('updated');
      } catch (error) {
        error.title = 'Could not add usage correction';
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async getUsageDetails(groupId) {
      this.loading = true;
      try {
        return MatchingService.getUsageDetails(this.reportId, groupId);
      } catch (error) {
        error.title = 'Could not fetch usage details';
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
