<template>
  <tr class="active-row">
    <td />
    <td>
      <validated-text-input
        v-model="model.name"
        class="add-padding-left"
        name="name"
        placeholder="E.g. Fonogram i TV"
        rule="required"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.source_society_name"
        name="source_society_name"
        placeholder="E.g. PPL"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.project_code_in"
        name="codeIn"
        placeholder="E.g. 601"
        rule="required"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.project_code_out"
        name="codeOut"
        placeholder="E.g. 701"
        rule="required"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.project_code_reserves"
        name="codeOut"
        placeholder="E.g. 801"
        rule="required"
      />
    </td>

    <td>
      <action-buttons class="float-right" @save="onSave" @cancel="onCancel" />
    </td>
  </tr>
</template>

<script>
import _ from 'lodash';
import ActionButtons from '../../ui/button/action-buttons';
import { createRevenueSubSource } from '../../../domain/distributionDomain';
import DistributionService from '../../../services/distributionService';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'EditDistributionSubSource',
  components: {
    ValidatedTextInput,
    ActionButtons,
  },
  inject: ['$validator'],
  props: {
    revenueSource: {
      type: Object,
    },
    revenueSubSource: {
      type: Object,
      default: () => createRevenueSubSource(),
    },
  },
  data() {
    return {
      model:
        this.revenueSubSource.name == null
          ? this.revenueSubSource
          : _.cloneDeep(this.revenueSubSource),
      subSourceIndex:
        this.revenueSubSource.name == null
          ? -1
          : this.revenueSource.sub_sources.findIndex(
              (subSource) => subSource.name === this.revenueSubSource.name,
            ),
    };
  },
  methods: {
    async onSave() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        const revenueSourceClone = _.cloneDeep(this.revenueSource);
        if (this.subSourceIndex > -1) {
          revenueSourceClone.sub_sources.splice(this.subSourceIndex, 1, this.model);
        } else {
          revenueSourceClone.sub_sources.push(this.model);
        }
        await DistributionService.updateRevenueSource(revenueSourceClone);
        this.$emit('changed', this.model);
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
