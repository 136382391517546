import { getter as vuexGetter, commit as vuexCommit } from '../../store/modules/matching/utils';
import clone from '../../common/clone';
import toCountries from '../../common/country';
import AuthenticationService from '../../services/authenticationService';
import MatchingService from '../../services/matchingService';

function getLines(
  id,
  limit,
  matchState,
  offset,
  sortParam,
  sortOrder,
  countryCode,
  range,
  worklistId,
  errorTypes,
  samiMember,
  album = 'false',
) {
  const request = {
    report_id: id,
    limit,
    offset,
    match_state: matchState,
    ascending: sortOrder ? sortOrder.toLowerCase() === 'asc' : true,
    order_by: sortParam,
    error_types: errorTypes && errorTypes.length > 0 ? errorTypes : undefined,
    sami_member: samiMember,
  };
  if (countryCode) {
    request.recording_country_filter = countryCode;
  }
  if (worklistId) {
    request.worklist_id = worklistId;
  }
  if (range) {
    if (range.start) {
      range.start = range.start.toUpperCase();
    }
    if (range.end) {
      range.end = range.end.toUpperCase();
    }
    request.main_artist_range = range;
  }
  if (album) {
    return MatchingService.getAlbumLines(request).then((linesResult) => clone(linesResult));
  }
  return MatchingService.getLines(request).then((linesResult) => clone(linesResult));
}

function identifyMatchLine(groupId, matchId) {
  return MatchingService.identifyMatchLine(
    groupId,
    matchId,
    AuthenticationService.getUserName(),
  ).then((response) => response);
}

async function ignoreMatchLine(groupId, matchId) {
  return MatchingService.ignoreMatchLine(groupId, matchId, AuthenticationService.getUserName());
}

async function unmatchMatchLine(groupId, matchId) {
  return MatchingService.unmatchMatchLine(groupId, matchId, AuthenticationService.getUserName());
}

function revertMatch(groupIds) {
  return MatchingService.revertMatch(groupIds, AuthenticationService.getUserName());
}

function identifyMatchAlbumLine(groupId, matchId) {
  return MatchingService.identifyMatchAlbumLine(
    groupId,
    matchId,
    AuthenticationService.getUserName(),
  ).then((response) => response);
}

async function ignoreMatchAlbumLine(groupId, matchId) {
  return MatchingService.ignoreMatchAlbumLine(
    groupId,
    matchId,
    AuthenticationService.getUserName(),
  );
}

function revertAlbumMatch(groupIds) {
  return MatchingService.revertAlbumMatch(groupIds, AuthenticationService.getUserName());
}

function exportAirtime(reportId, isAlbum, processId) {
  if (isAlbum) {
    return MatchingService.exportAlbums(reportId, processId);
  }
  return MatchingService.exportRecordings(reportId, processId);
}

function createNewFromRested(reportId, lines, all) {
  let groupIds;
  if (lines && !all) {
    groupIds = lines.map((line) => line.group_id);
  }
  return MatchingService.createNewFromRested(reportId, groupIds, all);
}

function createNewAlbumFromRested(reportId, lines, all) {
  let groupIds;
  if (lines && !all) {
    groupIds = lines.map((line) => line.group_id);
  }
  return MatchingService.createNewAlbumFromRested(reportId, groupIds, all);
}

function queryParams(sortTerms) {
  const params = {};
  if (sortTerms.param) {
    params.sortParam = sortTerms.param;
  }
  if (sortTerms.order) {
    params.sortOrder = sortTerms.order;
  }
  return params;
}

function getCountries() {
  return vuexGetter('countryList');
}

function setCountries(countryCodes) {
  vuexCommit('updateCountryList', toCountries(countryCodes, false));
}

function sortByRole(lineup) {
  const importantRoles = ['G', 'LD', 'S'];
  const isImportant = (r) => (r.role ? importantRoles.includes(r.role) : false);

  lineup.sort((r1, r2) => {
    if (r1.role === r2.role) {
      return r1.relation.name.localeCompare(r2.relation.name);
    }

    if (isImportant(r1)) {
      return -1;
    } else if (isImportant(r2)) {
      return 1;
    }
    return 0;
  });
  return lineup;
}

export default {
  getLines,
  identifyMatchLine,
  identifyMatchAlbumLine,
  ignoreMatchLine,
  unmatchMatchLine,
  revertMatch,
  ignoreMatchAlbumLine,
  revertAlbumMatch,
  exportAirtime,
  createNewFromRested,
  createNewAlbumFromRested,
  queryParams,
  getCountries,
  setCountries,
  sortByRole,
};
