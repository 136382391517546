import _ from 'lodash';
import patterns from './validationPatterns';

export const Type = {
  ZULU: 1,
  COUNTRY_CODE: 2,
  ARRAY: 3,
  OBJECT: 4,
  INSTRUMENT_CODE: 5,
  UNKNOWN: -1,
};

/**
 * Determines the object type.
 * @param obj the object
 * @returns {Object} the type
 */
export function ofType(obj) {
  if (Array.isArray(obj)) return Type.ARRAY;
  if (_.isObject(obj)) return Type.OBJECT;
  const value = typeof obj !== 'string' ? obj : obj.toString();
  if (patterns.ZULU.test(value)) return Type.ZULU;
  if (patterns.COUNTRY_CODE.test(value)) return Type.COUNTRY_CODE;
  if (patterns.INSTRUMENT_CODE.test(value)) return Type.INSTRUMENT_CODE;
  return Type.UNKNOWN;
}
