var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"name":_vm.name}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s4"},[_c('validated-text-input',{attrs:{"name":"private_account.clearing_number","label":"Clearing number","scope":"payment-information","placeholder":"E.g. 1234","rule":{
          required: _vm.privateAccountData.account_number ? true : false,
          clearingnumber: true,
        }},on:{"input":_vm.emit},model:{value:(_vm.privateAccountData.clearing_number),callback:function ($$v) {_vm.$set(_vm.privateAccountData, "clearing_number", $$v)},expression:"privateAccountData.clearing_number"}}),_vm._v(" "+_vm._s(_vm.bank ? `Bank: ${_vm.bank.name}` : '')+" ")],1),_c('div',{staticClass:"col s8"},[_c('validated-text-input',{attrs:{"name":"private_account.account_number","label":`Bank account number ${
          _vm.isSSNAccount(_vm.privateAccountData.clearing_number) ? '(SSN account)' : ''
        }`,"scope":"payment-information","placeholder":`${
          _vm.isSSNAccount(_vm.privateAccountData.clearing_number) ? 'YYMMDDXXXX' : 'E.g. 1234567'
        }`,"rule":{
          required: _vm.privateAccountData.clearing_number ? true : false,
          accountbyclearing: _vm.privateAccountData.clearing_number
            ? _vm.privateAccountData.clearing_number
            : '',
        }},on:{"input":_vm.emit},model:{value:(_vm.privateAccountData.account_number),callback:function ($$v) {_vm.$set(_vm.privateAccountData, "account_number", $$v)},expression:"privateAccountData.account_number"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }