import DistributionService from '..//services/distributionService';

export default {
  methods: {
    async getRevenueSources() {
      const result = await DistributionService.getRevenueSources();
      this.sources = [...result.sources];
      if (this.activeCategory) {
        this.filteredSources = this.filterSources();
      }
      this.sources.sort((a, b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        }
        return 0;
      });
    },
    filterSources() {
      return this.sources.filter((source) => source.category === this.activeCategory);
    },
  },
};
