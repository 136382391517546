import * as uuid from 'uuid';

export function createYearActive(from = null, to = null) {
  return {
    from,
    to,
  };
}

export function createActivePeriod(from = null, to = null) {
  return {
    from,
    to,
  };
}

export function createBasicInfo() {
  return {
    name: null,
    alternate_names: [],
    active_period: [],
    origin_country: null,
    origin_city: null,
    local_codes: [],
    profile_description: null,
    other_external_sources: [],
    genre: null,
    tags: [],
  };
}

export function createPerformerRelation(id, instrument, role, activePeriodArr, pseudo) {
  return {
    id,
    instrument,
    role,
    active_period: activePeriodArr,
    pseudo_name: pseudo,
  };
}

export function createMainArtistAggregate() {
  return {
    id: null,
    version: null,
    process_id: null,
    stream_id: uuid.v4(),
    timestamp: null,
    basic_info: createBasicInfo(),
    performers: [],
    readonly: false,
    merged_with: [],
  };
}

export function createMainArtistCommand() {
  return {
    type: 'mainartist:create',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      basic_info: null,
      performers: [],
      new_performers: [],
    },
  };
}

export function createUpdateBasicInfoCommand() {
  return {
    type: 'mainartist:updateBasicInfo',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      basic_info: null,
    },
  };
}

export function createUpdatePerformersCommand() {
  return {
    type: 'mainartist:updatePerformers',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      performers: [],
      new_performers: [],
    },
  };
}

export function createAddPerformerCommand() {
  return {
    type: 'mainartist:addPerformer',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      performer: null,
    },
  };
}

export function createRemovePerformerCommand() {
  return {
    type: 'mainartist:removePerformer',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      performerId: null,
    },
  };
}
