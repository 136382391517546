<template>
  <div>
    <div class="result">
      <div class="row wrapper">
        <div :class="isAlbum ? 'col s6' : 'col s8'">
          <h1>
            Match result for {{ reportName }}
            <span v-if="isArchived()">(Archived) {{ state }}</span>
          </h1>
        </div>
        <div v-if="isAlbum && !hasStimFile()" class="col s2">
          <button class="btn" :disabled="isArchived()" @click="createStimFile()">
            <i class="fas fa-sync-alt" :class="{ spin: creationInProgress }"></i>
            Create Stimfile
          </button>
        </div>
        <div v-if="isAlbum && hasStimFile()" class="col s2">
          <a @click="onDownloadStimFile(stim_file)">
            <i class="far fa-file-alt"></i>
            Link To Stimfile
          </a>
        </div>
        <div class="col s4">
          <button
            class="btn float-right"
            :disabled="isArchived()"
            @click="sendReportToDistribution()"
          >
            <i class="fas fa-sync-alt" :class="{ spin: transferInProgress }"></i>
            Transfer Airtime to Distribution
          </button>
        </div>
      </div>
      <div class="row wrapper">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link
              class="nowrap"
              :to="'/music-reports-deprecated/' + reportId + '/matched?album=' + isAlbum"
            >
              Matched ({{ $filters.formatNumber(counters.matched_lines) }})
            </router-link>
            <router-link
              class="nowrap"
              :to="'/music-reports-deprecated/' + reportId + '/candidates?album=' + isAlbum"
            >
              Candidates ({{ $filters.formatNumber(counters.candidate_lines) }})
            </router-link>
            <router-link
              class="nowrap"
              :to="'/music-reports-deprecated/' + reportId + '/rested?album=' + isAlbum"
            >
              Unmatched ({{ $filters.formatNumber(counters.rested_lines) }})
            </router-link>
            <router-link
              class="nowrap"
              :to="'/music-reports-deprecated/' + reportId + '/transferred?album=' + isAlbum"
            >
              Transferred ({{ $filters.formatNumber(counters.transferred_lines) }})
            </router-link>
            <router-link
              class="nowrap"
              :to="'/music-reports-deprecated/' + reportId + '/errors?album=' + isAlbum"
            >
              Errors ({{ $filters.formatNumber(counters.error_lines) }})
            </router-link>
          </div>
        </div>
      </div>
      <keep-alive>
        <template v-if="loading">
          <component-spinner />
        </template>
        <router-view :reporter-id="reporterId" @updated="loadHeader"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import MatchingService from '../../services/matchingService';
import MusicReportHelper from './musicReportHelper';
import TableOptionsMixin from '../../common/tableOptionsMixin';
import DownloadLink from '../ui/file/downloadLink';
import RemoteFileService from '../../services/remoteFileService';
import ComponentSpinner from '../../components/component-spinner';

export default {
  name: 'ViewMusicReportLines',
  components: {
    ComponentSpinner,
  },
  mixins: [TableOptionsMixin],
  data() {
    return {
      reportName: '',
      reportId: this.$router.currentRoute.params.reportId,
      reporterId: '',
      counters: {
        line_count: 0,
        matched_lines: 0,
        rested_lines: 0,
        candidate_lines: 0,
        transferred_lines: 0,
        error_lines: 0,
      },
      loading: false,
      error: false,
      transferInProgress: false,
      creationInProgress: false,
      state: '',
      isAlbum: false,
    };
  },
  watch: {
    $route() {
      this.loadHeader();
    },
  },
  created() {
    this.loadHeader();
    this.clearFilter('ViewMusicReportRestedLines');
    this.clearFilter('ViewMusicReportCandidateLines');
    this.clearFilter('ViewMusicReportMatchedLines');
  },
  methods: {
    isArchived() {
      return this.state === 'A';
    },
    hasStimFile() {
      return !!this.stim_file;
    },
    sendReportToDistribution() {
      this.transferInProgress = true;
      const processId = uuid.v4();
      MusicReportHelper.exportAirtime(this.reportId, this.isAlbum, processId);
      this.$store.dispatch('process/addProcess', {
        name: `Exporting Airtime for - ${this.reportName}`,
        id: processId,
      });
      setTimeout(() => {
        this.transferInProgress = false;
      }, 7000);
    },
    async createStimFile() {
      this.creationInProgress = true;
      try {
        this.stim_file = await MatchingService.createStimFile(this.reportId);
      } catch (error) {
        error.title = 'Could not create stim file';
        this.$addStarError(error);
        this.creationInProgress = false;
      }
      this.creationInProgress = false;
    },
    async loadHeader() {
      this.loading = true;
      this.error = false;
      try {
        const reportHeader = await MatchingService.getReportHeader('PRR', this.reportId);
        this.reportName = reportHeader.report_name;
        this.reportId = reportHeader.report_id;
        this.reporterId = reportHeader.reporter_id;
        this.state = reportHeader.report_state;
        this.stim_file = reportHeader.info && reportHeader.info.stim_file;
        this.isAlbum = reportHeader.local_code_id === 'LIBRARY';
        this.counters = reportHeader.prr_match_data;

        MusicReportHelper.setCountries(reportHeader.countries);
      } catch (error) {
        error.title = 'Could not fetch report header';
        this.$addStarError(error);
      }
      this.loading = false;
    },
    async onDownloadStimFile(url) {
      const fileData = await RemoteFileService.downloadStimFile(url);
      DownloadLink.direct(fileData, url);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.result {
  .col {
    padding-left: 0;
    padding-right: 0;
  }

  &__no-result {
    margin: 10px 0;
    font-style: italic;
  }

  &__pagination {
    text-align: center;
    margin-top: var(--spacing-five-times);

    button {
      width: 140px;
    }
  }
}
.result__spinner {
  position: absolute;
  width: 100%;
  top: 300px;
  margin-left: -3rem;
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
