<template>
  <div class="card">
    <div class="row">
      <div v-if="editMode" class="col s2">VAT No.</div>
      <div class="col" :class="{ s7: editMode, s8: !editMode }">
        <validated-text-input
          name="vat_number"
          label="VAT No."
          :show-label="!editMode"
          :scope="scope"
          placeholder="E.g. SE123456789012"
          :rule="`vatnumber:${domicile}`"
          :value="vatNumber"
          @input="onVATInput"
        >
          <template #after>
            <a
              class="read-more"
              target="_blank"
              tabindex="-1"
              href="https://www.skatteverket.se/foretagochorganisationer/moms/saljavarorochtjanster/forsaljningtillandraeulander/kontrollerakoparensmomsregistreringsnummer/momsregistreringsnummer.4.18e1b10334ebe8bc80002649.html"
            >
              <i class="fas fa-external-link-alt push-half--right" />
              More information about VAT
            </a>
          </template>
        </validated-text-input>
      </div>
    </div>

    <div class="row">
      <div v-if="editMode" class="col s2">Tax domicile</div>
      <div class="col" :class="{ s7: editMode, s8: !editMode }">
        <select-country
          rule-one-of-optional
          name="tax_domicile"
          label="Tax domicile"
          :scope="scope"
          :show-label="!editMode"
          :value="domicile"
          @input="$emit('update:domicile', $event)"
        />
      </div>
    </div>

    <div class="row">
      <div v-if="editMode" class="col s2">TIN</div>
      <div class="col" :class="{ s7: editMode, s8: !editMode }">
        <validated-text-input
          name="tin"
          label="TIN"
          :show-label="!editMode"
          placeholder="E.g. 1234567890"
          :scope="scope"
          :rule="{ tin: true }"
          :value="tin"
          @input="$emit('update:tin', $event)"
        >
          <template #after>
            <a
              class="read-more"
              target="_blank"
              tabindex="-1"
              href="https://ec.europa.eu/taxation_customs/tin/tinByCountry.html"
            >
              <i class="fas fa-external-link-alt push-half--right" />
              More information about TIN
            </a>
          </template>
        </validated-text-input>
      </div>
    </div>
  </div>
</template>

<script>
import SelectCountry from '@/components/ui/select/select-country';
import ValidatedTextInput from '@/components/ui/input/validated-text-input';

export default {
  components: {
    SelectCountry,
    ValidatedTextInput,
  },

  props: {
    vatNumber: {
      type: String,
    },
    domicile: {
      type: String,
    },
    tin: {
      type: String,
    },
    mode: {
      type: String,
    },
    scope: {
      type: String,
    },
  },

  computed: {
    editMode() {
      return this.mode === 'edit';
    },
  },

  methods: {
    onVATInput(vat) {
      if (vat && vat.length >= 2) {
        const domicile = vat.substring(0, 2).toUpperCase();

        this.$emit('update:domicile', domicile);
      } else {
        this.$emit('update:domicile', '');
      }

      this.$emit('update:vatNumber', vat);
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  margin-left: auto;
}
</style>
