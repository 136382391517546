<template>
  <div>
    <div class="cmp">
      <div class="row">
        <div class="col s2 hide-on-med-and-down">
          <entity-tag />
        </div>
        <div class="col s12 l6">
          <div class="row wrapper">
            <div class="col s12">
              <h1 v-if="!editMode">Create distribution area</h1>
              <h1 v-else>Edit distribution area</h1>
            </div>
          </div>

          <create-menu
            v-if="!editMode"
            cancel-route-name="BACK"
            @create="createAndNavigateToView"
          />

          <form data-vv-scope="create-distribution-area">
            <div class="card">
              <div class="row">
                <div class="col s6">
                  <validated-text-input
                    name="distribution_area.name"
                    label="Name"
                    placeholder="E.g. Kent"
                    :value="areaName"
                    rule="required"
                    @input="updateAreaName"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col s6">
                  <select-distribution-payment-rule
                    label="Payment rule"
                    :value="areaDistributionRuleId"
                    @input="updateAreaDistributionRuleId"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col s6">
                  <select-distribution-area-right
                    label="Category of rights"
                    :value="areaCategoryOfRights"
                    @input="updateAreaCategoryOfRights"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col s6">
                  <label class="label" for="distribution_basis">Distribution basis</label>
                  <div id="distribution_basis">
                    <input
                      :id="`distribution_basis.${DistributionBasis.REPORTED}`"
                      type="radio"
                      name="distribution_basis"
                      :checked="areaDistributionBasis === DistributionBasis.REPORTED"
                      @change="updateAreaDistributionBasis('REPORTED')"
                    />
                    <label :for="`distribution_basis.${DistributionBasis.REPORTED}`">
                      REPORTED
                    </label>
                    <input
                      :id="`distribution_basis.${DistributionBasis.ANALOGY}`"
                      type="radio"
                      name="distribution_basis"
                      :checked="areaDistributionBasis === DistributionBasis.ANALOGY"
                      @change="updateAreaDistributionBasis('ANALOGY')"
                    />
                    <label :for="`distribution_basis.${DistributionBasis.ANALOGY}`">ANALOGY</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div v-if="areaDistributionBasis === DistributionBasis.REPORTED" class="row">
                <div class="col s12">
                  <table>
                    <thead>
                      <th />
                      <th>Channel</th>
                      <th>Channel code</th>
                      <th>Proportion</th>
                    </thead>
                    <tbody>
                      <tr v-for="channel in grouplessChannels" :key="channel.id">
                        <td />
                        <td>
                          <input
                            :id="channel.id"
                            ref="mark"
                            v-model="checkedChannelsModel"
                            class="filled-in"
                            type="checkbox"
                            :value="channel.id"
                            @change="
                              onGrouplessChannelClick($event.target.value, $event.target.checked)
                            "
                          />
                          <label :for="channel.id" class="fs-14">{{ channel.name }}</label>
                        </td>
                        <td />
                      </tr>

                      <template v-for="(channels, group) in channelGroups">
                        <tr :key="`group-${group}`" class="cursor-pointer">
                          <td
                            class="thin-column dropdown-button__arrow"
                            @click="toggleGroup(group)"
                          >
                            <i v-if="expanded(group)" class="fas fa-caret-up" />
                            <i v-else class="fas fa-caret-down" />
                          </td>
                          <td @click="toggleGroup(group)">
                            <input
                              :id="group"
                              ref="mark"
                              class="filled-in"
                              type="checkbox"
                              :checked="allGroupChannelsSelected(group)"
                              :value="group"
                              @change="
                                onChannelGroupClick($event.target.value, $event.target.checked)
                              "
                            />
                            <label :for="group">{{ $filters.toTitleCase(group) }}</label>
                          </td>
                          <td />
                          <td>
                            <validated-text-input
                              :name="group + '.factor'"
                              scope="create-distribution-area"
                              class="factor"
                              label="Factor"
                              :show-label="false"
                              type="number"
                              :disabled="!anyGroupChannelsSelected(group)"
                              :value="groupFactor(group)"
                              :rule="{ min_value: 0, max_value: 10 }"
                              placeholder="E.g. 1,5"
                              @input="updateFactor(group, $event)"
                            />
                          </td>
                        </tr>

                        <template v-if="expanded(group)">
                          <tr v-for="channel in channels" :key="channel.id">
                            <td />
                            <td class="grouped-channel">
                              <input
                                :id="channel.id"
                                ref="mark"
                                v-model="checkedChannelsModel"
                                class="filled-in"
                                type="checkbox"
                                :value="channel.id"
                                @change="
                                  onChannelClick($event.target.value, $event.target.checked, group)
                                "
                              />
                              <label :for="channel.id">
                                {{ $filters.toTitleCase(channel.name) }}
                              </label>
                            </td>
                            <td>{{ channel.code }}</td>
                            <td />
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>

              <div v-if="editMode" class="row text--right flush--bottom">
                <action-buttons @save="createAndNavigateToView" @cancel="onCancel" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import {
  createDistributionAreaAggregate,
  DistributionBasis,
} from '../../../domain/distributionDomain';
import {
  getAggregate,
  setAggregate,
  createAggregate,
} from '../../../store/modules/distributionarea/utils';
import ActionButtons from '../../ui/button/action-buttons';
import EntityTag from '../../ui/entity-tag';
import CreateMenu from '../../ui/create-menu';
import DistributionService from '../../../services/distributionService';
import DistributionHelper from '../distributionHelper';
import SelectDistributionAreaRight from '../../ui/select/select-distribution-area-right';
import SelectDistributionPaymentRule from '../../ui/select/select-distribution-payment-rule';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'CreateDistributionArea',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    ActionButtons,
    EntityTag,
    CreateMenu,
    SelectDistributionAreaRight,
    SelectDistributionPaymentRule,
    ValidatedTextInput,
  },
  data() {
    return {
      viewRouteName: 'distributionAreasView',
      error: false,
      grouplessChannels: [],
      channelGroups: [],
      checkedChannelsModel: [],
      groupFactors: [],
      distributionRules: [],
      DistributionBasis,
      editId: this.$router.currentRoute.params.id,
      editMode: false,
      expandedGroups: [],
    };
  },
  computed: {
    ...mapGetters('distributionArea', [
      'areaName',
      'areaDistributionRuleId',
      'areaCategoryOfRights',
      'areaDistributionBasis',
      'channels',
    ]),
  },
  async created() {
    const channels = await DistributionHelper.getChannelsByGroup();
    this.grouplessChannels = channels.groupless.filter((ch) => ch.name !== '** NEW **');
    this.channelGroups = channels.groups;
    Object.keys(this.channelGroups).forEach((group) =>
      this.groupFactors.push({ group, factor: 1 }),
    );
    if (this.editId) {
      this.editMode = true;
      const areaAggregateResponse = await DistributionService.getDistributionArea(this.editId);
      const clonedAreaAggregateResponse = _.cloneDeep(areaAggregateResponse);
      const areaAggregateToEdit = createDistributionAreaAggregate(
        clonedAreaAggregateResponse.area,
        clonedAreaAggregateResponse.channelRelations.map((channel) => ({
          id: channel.channel_id,
          factor: channel.factor,
        })),
      );
      setAggregate(areaAggregateToEdit);
      this.setFactors(channels.flat);
      this.checkedChannelsModel = this.channels.slice().map((channel) => channel.id);
      this.expandGroups();
    } else {
      createAggregate();
    }
  },
  methods: {
    ...mapMutations('distributionArea', [
      'updateAreaName',
      'updateAreaDistributionRuleId',
      'updateAreaCategoryOfRights',
      'updateAreaDistributionBasis',
      'updateChannels',
      'removeFromChannels',
      'addToChannels',
      'updateChannelFactor',
    ]),
    expanded(group) {
      return this.expandedGroups.includes(group);
    },
    toggleGroup(group) {
      const index = this.expandedGroups.indexOf(group);
      if (index === -1) {
        this.expandedGroups.push(group);
      } else {
        this.expandedGroups.splice(index, 1);
      }
    },
    allGroupChannelsSelected(group) {
      const groupIds = this.channelGroups[group].map((channel) => channel.id);
      return groupIds.every((id) => this.checkedChannelsModel.includes(id));
    },
    anyGroupChannelsSelected(group) {
      const groupIds = this.channelGroups[group].map((channel) => channel.id);
      return groupIds.some((id) => this.checkedChannelsModel.includes(id));
    },
    setFactors(flatChannels) {
      this.channels.forEach((channel) => {
        const groupFactor = this.groupFactors.find(
          (gp) => gp.group === flatChannels.find((fc) => fc.id === channel.id).channel_group,
        );
        if (groupFactor) {
          groupFactor.factor = channel.factor;
        }
      });
    },
    expandGroups() {
      Object.keys(this.channelGroups).forEach((group) => {
        if (this.anyGroupChannelsSelected(group)) {
          this.expandedGroups.push(group);
        }
      });
    },
    groupFactor(group) {
      return this.groupFactors.find((gp) => gp.group === group).factor;
    },
    updateFactor(group, factor) {
      this.groupFactors.find((gp) => gp.group === group).factor = Number(factor);
      this.channelGroups[group].forEach((channel) => {
        if (this.checkedChannelsModel.indexOf(channel.id) !== -1) {
          this.updateChannelFactor({ id: channel.id, factor: Number(factor) });
        }
      });
    },
    onChannelGroupClick(group, checked) {
      const ids = this.channelGroups[group].map((channel) => channel.id);
      ids.forEach((id) => {
        if (checked && this.checkedChannelsModel.indexOf(id) === -1) {
          this.checkedChannelsModel.push(id);
          this.addToChannels({ id: Number(id), factor: 1 });
        } else if (!checked) {
          this.checkedChannelsModel.splice(this.checkedChannelsModel.indexOf(id), 1);
          this.removeFromChannels(this.channels.indexOf(id));
        }
      });
      if (!checked) {
        this.groupFactors.find((gp) => gp.group === group).factor = 1;
      }
    },
    onGrouplessChannelClick(id, checked) {
      if (!checked) {
        this.removeFromChannels(this.channels.map((channel) => channel.id).indexOf(Number(id)));
      } else {
        this.addToChannels({ id: Number(id), factor: 1 });
      }
    },
    onChannelClick(id, checked, group) {
      if (!checked) {
        this.removeFromChannels(this.channels.map((channel) => channel.id).indexOf(Number(id)));
        if (!this.anyGroupChannelsSelected(group)) {
          this.groupFactors.find((gp) => gp.group === group).factor = 1;
        }
      } else {
        const factor = this.groupFactors.find((gp) => gp.group === group).factor;
        this.addToChannels({ id: Number(id), factor });
      }
    },
    async createAndNavigateToView() {
      const aggregate = _.cloneDeep(getAggregate());
      this.error = false;
      try {
        aggregate.area.distribution_rule_id = aggregate.area.distribution_rule.id;
        delete aggregate.area.distribution_rule;
        if (aggregate.area.distribution_basis === DistributionBasis.ANALOGY) {
          aggregate.channels = [];
        }
        await DistributionService.createOrUpdateDistributionArea(aggregate);
        this.$router.push({
          name: this.viewRouteName,
        });
      } catch (error) {
        this.$addStarError(error);
      }
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.grouped-channel {
  padding-left: 30px;
}
.factor {
  width: 60px;
}
</style>
