import gql from 'graphql-tag';

const reserveReleaseHeader = `
  id
  releaseYear
  createdDate 
  releaseDate
  createdBy
  confirmedBy
  state
  totalReservedAmount
  error
`;

const reserveReleaseStats = `
  id
  reservedAmount
  releasedAmount
  remainder
  numPayees
`;

const reserveReleaseRow = `
  id
  releaseId
  performanceYear
  sourceId
  sourceName
  right
  areaId
  areaName
  reservedAmount
  distributedAmount
  releasedAmount
`;

const calculateReserveResponse = `
  id
  processId
`;

const confirmReserveResponse = `
  id
  processId
`;

const getReserveReleaseHeaders = gql`
   query getReserveReleaseHeaders($request: ReserveReleaseHeadersRequest) {
    getReserveReleaseHeaders(request: $request) {
      items {
        ${reserveReleaseHeader}
      }
      total_count
    }
  }
`;

const getReserveRelease = gql`
   query getReserveRelease($id: String!) {
    getReserveRelease(id: $id) {
      header {
        ${reserveReleaseHeader}
      }
      stats {
        ${reserveReleaseStats}
      }
      rows {
        ${reserveReleaseRow}
      }
    }
  }
`;

const createReserveRelease = gql`
  mutation createReserveRelease($command: CreateReserveCommand!) {
    createReserveRelease(command: $command)
  }
`;

const calculateReserveRelease = gql`
  mutation calculateReserveRelease($command: CalculateReserveCommand!) {
    calculateReserveRelease(command: $command) {
      ${calculateReserveResponse}
    }
  }
`;

const confirmReserveRelease = gql`
  mutation confirmReserveRelease($command: ConfirmReserveReleaseCommand!) {
    confirmReserveRelease(command: $command) {
      ${confirmReserveResponse}
    }
  }
`;

const deleteReserveRelease = gql`
  mutation deleteReserveRelease($id: String!) {
    deleteReserveRelease(id: $id)
  }
`;

export default {
  getReserveReleaseHeaders,
  getReserveRelease,
  createReserveRelease,
  calculateReserveRelease,
  confirmReserveRelease,
  deleteReserveRelease,
};
