<template>
  <Modal
    v-if="show"
    submit-label="Continue"
    @save="$emit('save')"
    @close="$emit('cancel')"
    @cancel="$emit('cancel')"
  >
    <h1 slot="header">Change {{ title }}</h1>
    <div slot="body">
      <div v-for="(item, index) in items" :key="index">
        <div v-if="item.value">
          You are about to change {{ item.prop }} to
          <strong>"{{ item.value }}"</strong>
          on
          <strong>{{ count }} recording(s)</strong>
        </div>
        <div v-else>
          You are about to
          <strong>remove the {{ item.prop }}</strong>
          on
          <strong>{{ count }} recording(s)</strong>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../../../ui/dialog/modal';

export default {
  name: 'ChangeTracksModal',
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    recordDisplayName(track) {
      if (track.version_title) {
        return `${track.name} (${track.version_title})`;
      } else {
        return track.name;
      }
    },
  },
};
</script>
