<template>
  <div>
    <select-input
      name="rejection-reason"
      :label="label"
      :value="code"
      :items="reasons"
      translation-prefix="matching.rejection_reason"
      @input="onInput"
    />
  </div>
</template>

<script>
import { REJECTION_REASONS } from '../../../domain/common';
import SelectInput from './select-input';

export default {
  name: 'SelectRejectionReason',
  components: {
    SelectInput,
  },
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: 'Rejection reason' },
  },
  data() {
    return {
      reasons: REJECTION_REASONS.map((r) => ({ code: r, name: r })),
      code: this.value,
    };
  },
  watch: {
    value() {
      this.code = this.value;
    },
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
