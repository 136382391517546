<template>
  <modal
    v-if="showModal"
    submit-label="Copy"
    :show-abort="false"
    :hide-overflow="false"
    @save="onCopy"
    @cancel="onCancel"
    @close="onCancel"
  >
    <h1 slot="header">Copy distribution</h1>

    <div slot="body">
      <div class="row">
        <div class="col s6">
          <select-input
            rule="required"
            name="distribution"
            label="From distribution"
            :items="closedDistributions"
            :value="copy.src_distribution_id"
            item-key="id"
            item-value="display_name"
            :empty-select-option="true"
            @input="copy.src_distribution_id = $event"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <select-input
            rule="required"
            name="distribution-area"
            label="To distribution area"
            :items="distributionAreas"
            :value="copy.new_distribution_area_id"
            item-key="id"
            item-value="name"
            :empty-select-option="true"
            @input="copy.new_distribution_area_id = $event"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s6">
          <validated-text-input
            v-model="copy.distribution_name"
            rule="required"
            name="name"
            placeholder="E.g. Copy of January"
            label="Name"
            @input="copy.distribution_name = $event"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../ui/dialog/modal';
import DistributionService from '../../../services/distributionService';
import SelectInput from '../../ui/select/select-input';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import { DistributionBasis } from '../../../domain/distributionDomain';

export default {
  name: 'CopyDistribution',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    Modal,
    SelectInput,
    ValidatedTextInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    year: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: this.show,
      closedDistributions: [],
      distributionAreas: [],
      copy: {
        src_distribution_id: null,
        new_distribution_area_id: null,
        distribution_name: '',
        distribution_year_id: this.year,
      },
    };
  },
  watch: {
    show(doShow) {
      this.showModal = doShow;
      if (this.showModal) {
        this.loadClosedDistributions();
        this.loadDistributionAreas();
      }
    },
  },
  methods: {
    async loadClosedDistributions() {
      const distributions = await DistributionService.getClosedDistributions(Number(this.year));
      this.closedDistributions = distributions.map((dist) => ({
        id: dist.id,
        area_id: dist.area_id,
        display_name: `${dist.name ? dist.name : ''} - ${dist.area_name}`,
      }));
    },
    async loadDistributionAreas() {
      const result = await DistributionService.getDistributionAreas();
      this.distributionAreas = result.areas.filter(
        (area) => area.distribution_basis === DistributionBasis.ANALOGY,
      );
    },
    onCancel() {
      this.$emit('cancel');
    },
    onCopy() {
      this.$emit('copy', this.copy);
    },
  },
};
</script>
