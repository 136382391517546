<template>
  <div>
    <spinner v-if="loadingActivePayments" />
    <div v-else>
      <p v-if="activePayments && activePayments.length === 0" class="no-active">
        No active payments
      </p>
      <ul v-else>
        <li v-for="p of activePayments" :key="p.paymentId" class="active-payment">
          <router-link :to="`/payments/${p.paymentId}`">{{ getPaymentName(p) }}</router-link>
          <div class="booked-amount">
            Booked amount: {{ $filters.formatAmountCent(p.total, 'SEK') }}
          </div>
        </li>
      </ul>
      <button v-if="!formIsOpen" class="btn" @click="openForm">
        <span>Add to payment</span>
      </button>
      <simple-spinner v-else-if="loadingManualPayments" />
      <select-input
        v-else
        name="payment"
        label="Select Payment"
        :show-label="true"
        :items="paymentItems"
        @input="paymentSelected"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { paymentState } from '../../domain/paymentDomain';
import { mutate, query } from '../../services/apolloRequest';
import SelectInput from '../ui/select/select-input';
import Spinner from '../ui/simple-spinner';
import { paymentCommand } from '../../store/modules/payment/store';
export default {
  name: 'ActivePayments',
  components: {
    SelectInput,
    Spinner,
  },
  props: {
    nodeId: String,
  },
  data() {
    return {
      payments: [],
      formIsOpen: false,
      activePayments: [],
      loadingActivePayments: false,
      loadingManualPayments: false,
    };
  },
  computed: {
    societies() {
      return this.$store.state.appdata.societies;
    },
    paymentItems() {
      return this.payments
        .filter((p) => !this.activePayments.find((ap) => ap.paymentId === p.id))
        .map((p) => ({
          code: p.id,
          name: this.getPaymentName(p),
        }));
    },
  },
  created() {
    this.fetchManualPayments();
    this.fetchActivePayments();
  },
  methods: {
    async paymentSelected(paymentId) {
      this.loadingActivePayments = true;
      await mutate(
        paymentCommand({
          stream_id: paymentId,
          type: 'payment/manual-add-nodes',
          payload: {
            nodeIds: [this.nodeId],
          },
        }),
      );
      this.closeForm();
      await new Promise((resolve) => setTimeout(resolve, 1500));
      await this.fetchActivePayments();
      this.loadingActivePayments = false;
    },
    getPaymentName(p) {
      return `${p.name} - ${this.societies.find((s) => s.id === p.societyId)?.name}`;
    },
    async openForm() {
      this.loadingManualPayments = true;
      await this.fetchManualPayments();
      this.loadingManualPayments = false;
      this.formIsOpen = true;
    },
    closeForm() {
      this.formIsOpen = false;
    },
    async fetchActivePayments() {
      const { activePayments } = await query({
        query: gql`
            query {
              activePayments(nodeId: "${this.nodeId}") {
                paymentId
                name
                total
                societyId
              }
            }
          `,
      });
      this.activePayments = activePayments;
    },
    async fetchManualPayments() {
      const paymentStates = [paymentState.Created, paymentState.Ready];
      const { payments } = await query(
        {
          query: gql`
            query Payments($query: PaymentsQuery) {
              payments(query: $query) {
                payments {
                  id
                  name
                  societyId
                }
              }
            }
          `,
          variables: {
            query: {
              limit: 50,
              states: paymentStates,
              isManual: true,
            },
          },
        },
        { pickProp: 'payments' },
      );
      this.payments = payments;
    },
  },
};
</script>

<style scoped>
.booked-amount {
  font-size: 0.8rem;
}
.active-payment + .active-payment {
  margin-top: 1rem;
}
.no-active {
  font-size: 0.8rem;
}
</style>
