<template>
  <div class="report__headers">
    <div class="row">
      <div class="col s12">
        <h2>Mediarc Matching</h2>
      </div>
    </div>

    <div class="card">
      <form class="row">
        <div class="col s3">
          <span>File to upload (xml UTF-8)</span>
          <div class="file-input nowrap" action="" method="POST">
            <input id="file" type="file" accept="text/xml" @change="addFile" />

            <span ref="fileUploadName">
              <i class="far fa-file-alt" />
              Drag your files here or click in thisarea.
            </span>
          </div>
        </div>
      </form>

      <div class="row flush">
        <div class="col offset-s8 s3 margin--top">
          <ProgressBar
            v-if="isUploadingFile && !error"
            label="Uploading"
            :total="100"
            :processed="uploadFile.uploadProgress"
            :show-percent="true"
          />
        </div>

        <div class="col s1 text--right">
          <button
            class="btn secondary abs-top"
            type="submit"
            :disabled="!validForm || isUploadingFile"
            @click.prevent="upload"
          >
            Upload
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col s12">
        <h2>Available Matchings</h2>
      </div>
    </div>
    <div class="card row">
      <div class="col s12">
        <table class="report__headers-table">
          <sortable-head
            :columns="columns"
            :bulk-enabled="false"
            :default-sort-should-override="true"
            @sort="onSort"
          />
          <tbody>
            <template v-if="loading">
              <tr>
                <td colspan="12">
                  <component-spinner />
                </td>
              </tr>
            </template>
            <template v-else>
              <template v-for="mediarcReport in reportHeadersResult.items">
                <tr
                  :key="`${mediarcReport.report_id}-tr-total`"
                  @click="mediarcReport.expanded = !mediarcReport.expanded"
                >
                  <td class="compare__arrow">
                    <i :class="`fas fa-caret-${mediarcReport.expanded ? 'up' : 'down'}`" />
                  </td>
                  <td>
                    {{ mediarcReport.report_name }}
                  </td>
                  <td>
                    {{ $filters.formatDate(mediarcReport.date_created) }}
                  </td>
                  <td style="min-width: 140px">
                    <span
                      class="chip float-left"
                      :class="getMatchStateClass(mediarcReport.report_state)"
                      :title="mediarcReport.error_message"
                    >
                      {{ $filters.formatMatchState(mediarcReport.report_state) }}
                    </span>
                  </td>
                  <td class="count count--green">
                    {{
                      $filters.formatNumber(
                        mediarcReport.mar_match_data.matched_album_lines +
                          mediarcReport.mar_match_data.matched_recording_lines,
                      )
                    }}
                  </td>
                  <td class="count count--gold">
                    {{
                      $filters.formatNumber(
                        mediarcReport.mar_match_data.candidate_album_lines +
                          mediarcReport.mar_match_data.candidate_recording_lines,
                      )
                    }}
                  </td>
                  <td class="count count--orange">
                    {{
                      $filters.formatNumber(
                        mediarcReport.mar_match_data.rested_album_lines +
                          mediarcReport.mar_match_data.rested_recording_lines,
                      )
                    }}
                  </td>
                  <td class="count count--blue">
                    {{
                      $filters.formatNumber(
                        mediarcReport.mar_match_data.done_album_lines +
                          mediarcReport.mar_match_data.done_recording_lines,
                      )
                    }}
                  </td>
                </tr>
                <template v-if="mediarcReport.expanded">
                  <template v-for="type in ['album', 'recording']">
                    <tr :key="`${mediarcReport.report_id}-${type}-tr-albums`">
                      <td />
                      <td class="mediarc-type" colspan="3">
                        <router-link
                          :to="`/mediarc/${mediarcReport.report_id}?album=${type === 'album'}`"
                        >
                          {{ type }}s
                        </router-link>
                      </td>
                      <td class="count count--green">
                        {{
                          $filters.formatNumber(
                            mediarcReport.mar_match_data[`matched_${type}_lines`],
                          )
                        }}
                      </td>
                      <td class="count count--gold">
                        {{
                          $filters.formatNumber(
                            mediarcReport.mar_match_data[`candidate_${type}_lines`],
                          )
                        }}
                      </td>
                      <td class="count count--orange">
                        {{
                          $filters.formatNumber(
                            mediarcReport.mar_match_data[`rested_${type}_lines`],
                          )
                        }}
                      </td>
                      <td class="count count--blue">
                        {{
                          $filters.formatNumber(mediarcReport.mar_match_data[`done_${type}_lines`])
                        }}
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </template>
          </tbody>
        </table>

        <div class="report__headers-pagination">
          <pagination
            v-if="numberOfPages > 0"
            :number-of-pages="numberOfPages"
            :selected-page="tablePagination.page"
            :number-of-hits="reportHeadersResult.total_count"
            :hits-per-page="maxReportHeadersPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import SortableHead from '../../ui/table/sortable-head';
import MatchingService from '../../../services/matchingService';
import Pagination from '../../pagination';
import ReportHeadersMixin from '../../../common/reportHeadersMixin';
import ComponentSpinner from '../../../components/component-spinner';
import RemoteFileHelper from '../../../common/remoteFileHelper';
import { createUploadFile } from '../../../domain/matchingDomain';
import ProgressBar from '../../ui/progress-bar';

export default {
  name: 'ViewMediarcMatching',
  components: {
    SortableHead,
    Pagination,
    ComponentSpinner,
    ProgressBar,
  },
  mixins: [ReportHeadersMixin],
  data() {
    return {
      uploadFile: createUploadFile(null, 'mediarc', 'xml', '', null, 'SR'),
      columns: [
        { name: '' },
        {
          name: 'Mediarc Report',
          ascending: true,
          sortParam: 'report_name',
          active: false,
        },
        {
          name: 'Uploaded',
          ascending: false,
          sortParam: 'date_created',
          active: false,
          thinColumn: true,
        },
        {
          name: 'Status',
          sortParam: 'report_state',
          ascending: true,
          thinColumn: true,
          active: false,
        },
        { name: 'Matched' },
        { name: 'Candidates' },
        { name: 'Unmatched' },
        { name: 'Done' },
      ],
    };
  },
  computed: {
    validForm() {
      return this.uploadFile.file;
    },
  },
  async created() {
    this.readQueryParams();
    await this.fetchData();
  },
  methods: {
    addFile(upload) {
      this.$refs.fileUploadName.innerHTML = upload.target.files[0].name;
      this.uploadFile.file = upload.target.files[0];
    },
    resetFileUpload() {
      this.isUploadingFile = false;
      this.$refs.fileUploadName.innerHTML = 'Drag your files here or click in this area.';
      this.uploadFile = createUploadFile(null, 'mediarc', '', '', null, 'SR');
      this.error = false;
    },
    async upload() {
      this.isUploadingFile = true;
      this.error = false;
      const processId = uuid.v4();
      try {
        this.$store.dispatch('process/addProcess', {
          name: `Mediarc upload`,
          id: processId,
        });
        this.uploadFile.uploadProgress = 0;
        const options = {
          progressFunc: (p) =>
            (this.uploadFile.uploadProgress = Math.round((p.loaded * 100) / p.total)),
          fileType: 'XML',
          metadata: [
            {
              key: 'process_id',
              value: processId,
            },
            {
              key: 'source',
              value: 'mediarc',
            },
          ],
        };
        await RemoteFileHelper.uploadMediarcReportFile(this.uploadFile, options);
        this.resetFileUpload();
        setTimeout(() => {
          this.fetchData();
        }, 3000);
      } catch (error) {
        this.handleError('Upload failed', error);
        this.$store.dispatch('process/removeProcess', processId);
      } finally {
        this.isUploadingFile = false;
      }
    },
    async fetchData() {
      this.loading = true;
      this.error = false;
      try {
        this.reportHeadersResult = await MatchingService.listReportHeaders({
          process_type: 'MEDIARC',
          report_type: 'MAR',
          limit: this.pagination.hitsPerPage,
          offset: this.getOffset(),
          filter: this.filterQuery,
          sort_by: this.sortTerms.param,
          sort_order: this.sortTerms.order,
        });

        this.reportHeadersResult.items = this.reportHeadersResult.items.map((item) => ({
          ...item,
          expanded: false,
        }));
        this.loading = false;

        const autoReload = this.reportHeadersResult.items.some((musicReport) =>
          this.isWaitState(musicReport.report_state),
        );
        if (autoReload && this.timeoutHandle === null) {
          this.timeoutHandle = setTimeout(() => {
            this.timeoutHandle = null;
            this.fetchData();
          }, 20000);
        }
      } catch (error) {
        this.handleError('Error fetching music report headers', error);
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.upload-progress {
  max-height: 40px;
}
.mediarc-type {
  padding-left: 30px;
  text-transform: capitalize;
}
</style>
