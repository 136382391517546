<template>
  <tr class="active-row">
    <td class="thin-column" />
    <td>
      <validated-text-input
        name="name"
        placeholder="E.g. SVT 1"
        :value="name"
        rule="required"
        @input="updateName"
      />
    </td>

    <td>
      <validated-text-input
        name="code"
        placeholder="E.g. 101"
        :value="code"
        rule="required"
        @input="updateCode"
      />
    </td>

    <td class="column--vrdb">
      <input
        id="vrdb"
        class="filled-in"
        name="vrdb"
        type="checkbox"
        :checked="vrdbPlaylist"
        @change="updateVrdbPlaylist($event.target.checked)"
      />
      <label for="vrdb">VRDB</label>
    </td>

    <td>
      <select-channel-group
        label=""
        :value="channelGroup"
        :disabled="localNewChannelGroup !== ''"
        @input="selectExistingGroup"
      />
    </td>
    <td>
      <validated-text-input
        name="group"
        placeholder="E.g. My Group"
        :value="localNewChannelGroup"
        :disabled="localExistingChannelGroup !== ''"
        @input="onNewGroupInput"
      />
    </td>
    <td class="column--action-buttons">
      <action-buttons :stacked="true" @save="onSave" @cancel="onCancel" />
    </td>
  </tr>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import DistributionService from '../../../services/distributionService';
import { getAggregate, setAggregate } from '../../../store/modules/distributionchannel/utils';
import SelectChannelGroup from '../../ui/select/select-channel-group';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import ActionButtons from '../../ui/button/action-buttons';

export default {
  name: 'EditDistributionChannel',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    ActionButtons,
    SelectChannelGroup,
    ValidatedTextInput,
  },
  props: {
    channel: { type: Object, default: () => null },
  },
  data() {
    return {
      error: false,
      existingGroups: [],
      localExistingChannelGroup: '',
      localNewChannelGroup: '',
    };
  },
  computed: {
    ...mapGetters('distributionChannel', ['name', 'code', 'vrdbPlaylist', 'channelGroup']),
  },
  created() {
    setAggregate(this.channel);
    this.getExistingGroups();
  },
  mounted() {
    this.localExistingChannelGroup = this.channelGroup ? this.channelGroup : '';
  },
  methods: {
    ...mapMutations('distributionChannel', [
      'updateName',
      'updateCode',
      'updateVrdbPlaylist',
      'updateChannelGroup',
    ]),
    selectExistingGroup(group) {
      this.localExistingChannelGroup = group;
      this.updateChannelGroup(group);
    },
    onNewGroupInput(group) {
      this.localNewChannelGroup = group;
    },
    async getExistingGroups() {
      const result = await DistributionService.getDistributionGroups();
      this.existingGroups = _.filter(result.groups, (group) => group);
    },
    async onSave() {
      if (this.localNewChannelGroup !== '') {
        this.updateChannelGroup(this.localNewChannelGroup);
      }
      const valid = await this.$validator.validateAll();
      if (valid) {
        const aggregate = _.cloneDeep(getAggregate());
        try {
          await DistributionService.createChannel(aggregate);
          this.$emit('changed');
        } catch (error) {
          this.$addStarError(error);
        }
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.column {
  &--web,
  &--vrdb {
    line-height: var(--spacing-five-times);
  }
  &--action-buttons {
    width: 230px;
    text-align: right;
  }
}
</style>
