<template>
  <form class="pos-relative" data-vv-scope="add-agreement">
    <div>
      <div class="row">
        <div class="col s4">
          <validated-text-input
            :ref="fromDateRefId"
            :name="`${name}-from`"
            label="Start date"
            :mandatory="true"
            placeholder="YYYY"
            :value="agreementModel.from_date"
            :rule="{ year: true, min: 4 }"
            :maxlength="4"
            :scope="scope"
            @input="updateFromDate"
          />
        </div>
        <div v-if="editMode" class="col s4">
          <validated-text-input
            label="End date"
            :name="`${name}-to`"
            :rule="
              !!agreementModel.from_date
                ? { min_value: agreementModel.from_date, year: true, min: 4 }
                : { year: true, min: 4 }
            "
            placeholder="YYYY"
            :maxlength="4"
            :scope="scope"
            :value="agreementModel.to_date"
            @input="updateToDate"
          />
        </div>
        <div class="col s4">
          <select-date
            :ref="signDateRefId"
            label="Sign date"
            :mandatory="true"
            :name="signDateRefId"
            :scope="scope"
            :value="agreementModel.sign_date"
            :rule="{ required: true }"
            :disabled="!agreementModel.from_date"
            @input="updateSignDate"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <template v-if="editMode && agreementModel.contract && agreementModel.contract.url">
            <a @click="onDownloadFile(agreementModel.contract.url)">
              <i class="far fa-file-alt" />
              Download
            </a>
            <i class="fas fa-times close cursor-pointer" @click="onRemoveContractUrl" />
          </template>
          <template v-else>
            <file-upload-simple-field
              name="documentRefId"
              :disabled="!agreementModel.from_date"
              @addFile="updateContractFile"
              @removeFile="onRemoveContract"
            />
          </template>
        </div>
        <div class="col s2">
          <select-agreement-type
            :ref="typeRefId"
            :name="typeRefId"
            :disabled="!agreementModel.from_date"
            scope="add-agreement"
            :rule="{ required: true }"
            :value="agreementModel.type"
            @input="updateType"
          />
        </div>
      </div>

      <div class="row flush--bottom add-agreement__type">
        <div v-for="(right, index) in mandateRights" :key="index" class="col s6">
          <input
            :id="name + index"
            ref="mark"
            v-validate="someRightsChecked ? '' : 'required'"
            :data-vv-as="right.name"
            name="mandateRights"
            class="filled-in"
            type="checkbox"
            :disabled="disableRights || !agreementModel.from_date"
            :checked="isChecked(right)"
            @change="updateRight(right)"
          />
          <label :for="name + index">
            {{ right.name }}
          </label>
        </div>

        <span v-show="validationErrors.has('mandateRights', scope)" class="help is-danger">
          <i class="fas fa-times-circle" />
          Category of rights is mandatory.
        </span>
      </div>

      <div v-if="editMode" class="row text--right">
        <div class="col s12">
          <action-buttons @save="onSave" @cancel="onCancel" />
        </div>
      </div>
    </div>

    <delete-button v-if="deletable" :is-component="true" @click="onRemove" />
  </form>
</template>

<script>
import {
  createAgreement,
  createAgreementContract,
  AgreementType,
} from '../../../domain/societyDomain';
import DeleteButton from '../../../components/ui/button/delete-button';
import SelectAgreementType from '../../ui/select/select-agreement-type';
import SelectDate from '../../ui/select/select-date';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import ActionButtons from '../../ui/button/action-buttons';
import FileUploadSimpleField from '../../ui/file/file-upload-simple-field';
import DownloadLink from '../../ui/file/downloadLink';
import RemoteFileService from '../../../services/remoteFileService';

export default {
  name: 'AddAgreement',
  components: {
    DeleteButton,
    SelectAgreementType,
    SelectDate,
    ValidatedTextInput,
    ActionButtons,
    FileUploadSimpleField,
  },
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    agreement: {
      type: Object,
      default: () => createAgreement(),
    },
    name: {
      type: String,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      agreementModel: this.agreement,
      fromDateRefId: `${this.name}_from_date`,
      signDateRefId: `${this.name}_sign_date`,
      typeRefId: `${this.name}_type`,
      documentRefId: `${this.name}_document`,
    };
  },
  computed: {
    someRightsChecked() {
      return this.agreementModel.rights.length > 0;
    },
    signDateRules() {
      return { required: !!this.agreementModel.from_date };
    },
    disableRights() {
      for (let idx = 0; idx < this.mandateRights.length; idx++) {
        if (
          this.validationErrors.has(`create-agreement-${idx}-from`, this.scope) ||
          this.validationErrors.has(`create-agreement-${idx}_sign_date`, this.scope)
        ) {
          return true;
        }
      }
      return false;
    },
    mandateRights() {
      return this.$store.state.appdata.referenceData.mandateRights;
    },
  },
  created() {
    if (!this.agreementModel.rights) {
      this.agreementModel.rights = [];
    }

    if (!this.agreementModel.contract) {
      this.agreementModel.contract = createAgreementContract();
    }

    if (!this.agreementModel.type) {
      this.agreementModel.type = AgreementType.A;
    }
  },
  methods: {
    async onDownloadFile(url) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
      DownloadLink.direct(fileData, url);
    },
    updateFromDate(date) {
      this.agreementModel.from_date = date;
      this.$validator.validateAll('add-agreement');
      this.emitUpdate();
    },
    updateToDate(date) {
      this.agreementModel.to_date = date;
      this.emitUpdate();
    },
    updateSignDate(date) {
      this.agreementModel.sign_date = date;
      this.emitUpdate();
    },
    updateContractFile(file) {
      this.$set(this.agreementModel, 'fileHandle', file);
    },
    onRemoveContract() {
      this.agreementModel.fileHandle = null;
    },
    onRemoveContractUrl() {
      this.agreementModel.contract = null;
    },
    updateType(type) {
      this.agreementModel.type = type;
      this.emitUpdate();
    },
    updateRight(right) {
      if (this.isChecked(right)) {
        this.agreementModel.rights.splice(this.agreementModel.rights.indexOf(right.code), 1);
      } else {
        this.agreementModel.rights.push(right.code);
      }
      this.emitUpdate();
    },
    isChecked(right) {
      return this.agreementModel.rights.includes(right.code);
    },
    emitUpdate() {
      this.$emit('input', this.agreementModel);
    },
    onCancel() {
      this.$emit('cancel', this.agreementModel);
    },
    onSave() {
      this.$emit('save', this.agreementModel);
    },
    onRemove(index) {
      this.$emit('remove', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.col.s6 {
  margin-bottom: var(--spacing);
}

.add-agreement__type {
  .col {
    padding-left: 0 !important;
  }
}
</style>
