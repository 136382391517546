<template>
  <div class="administrate-end-of-year-statement">
    <div class="row">
      <div class="col s12">
        <h1>End of Year Statements</h1>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col s12">
          <h2>Create End of Year Statement</h2>
        </div>
      </div>
      <div v-if="loading">
        <spinner />
      </div>
      <template v-else>
        <div class="row">
          <div class="col s12">
            <select-generic
              :disabled="Object.entries(selectableYears).length === 0"
              name="year"
              label="Select year"
              :empty-option="false"
              :value="selectedYear"
              :data="selectableYears"
              @input="(val) => (selectedYear = val)"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s5">
            <verify-with-password
              v-if="verificationStatus !== 'success'"
              :disabled="Object.entries(selectableYears).length === 0"
              :verification-status="verificationStatus"
              :number-of-attempts="numberOfAttempts"
              message="I hereby confirm that I want to create end of year statements."
              submit-label="Confirm"
              @onSubmitPassword="onSubmitPassword"
              @onSubmitMfaCode="onSubmitMfaCode"
              @onBypassAuth="onBypassAuth"
            />
          </div>
        </div>
      </template>
    </div>

    <div class="card">
      <div class="row">
        <div class="col s12">
          <h2>End of Year Statements</h2>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <table>
            <thead class="cursor-pointer">
              <th v-for="column in columns" :key="column.name" class="disabled-head">
                {{ column.name }}
                <span
                  v-if="column.name === 'Errors'"
                  class="alert-container"
                  title="End of year statement created, but without these performers/associates. To include them, fix the errors and create the statement once again."
                >
                  <i class="fas fa-question display--contents"></i>
                </span>
              </th>
            </thead>
            <tbody>
              <tr v-for="(row, index) in filesByYear" :key="index" class="row">
                <td>
                  <span>
                    {{ row.year }}
                  </span>
                </td>
                <td>
                  <span v-if="row.statement">
                    <a
                      @click="
                        onDownloadEndOfYearStatementFile(row.statement.path, row.statement.filename)
                      "
                    >
                      <i class="far fa-file-alt"></i>
                      Download
                    </a>
                  </span>
                  <span v-else class="none">No file created</span>
                </td>
                <td>
                  <span v-if="row.errorFile">
                    <a
                      @click="
                        onDownloadEndOfYearStatementFile(row.errorFile.path, row.errorFile.filename)
                      "
                    >
                      <i class="far fa-file-alt"></i>
                      Download
                    </a>

                    <a
                      @click="
                        onPreviewEndOfYearStatementFile(row.errorFile.path, row.errorFile.filename)
                      "
                    >
                      <i class="far fa-eye"></i>
                      Preview
                    </a>
                  </span>
                  <span v-else class="none">No file created</span>
                </td>
                <td>
                  <span v-if="row.statement">
                    {{ $filters.formatTimestamp(row.statement.lastModified) }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <span v-if="row.lockFile">
                    {{ $filters.formatTimestamp(row.lockFile.lastModified) }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td class="thin-column">
                  <context-menu
                    :disabled="row.lockFile"
                    :options="['Publish and lock']"
                    @publishAndLock="publishAndLockYear(row.year)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import SelectGeneric from './../ui/select/select-generic';
import endOfYearStatementService from '../../services/endOfYearStatementService';
import DownloadLink from '../ui/file/downloadLink';
import RemoteFileService from '../../services/remoteFileService';
import Spinner from '../spinner';
import ContextMenu from '../ui/context-menu';
import CreateUploadFileDescriptor from '../../domain/uploadFileDescriptor';
import VerifyWithPassword from '../ui/input/verify-with-password';
import AuthenicationMixin from '../../common/authenictaionMixin';

export default {
  name: 'AdministrateEndOfYearStatement',
  components: {
    SelectGeneric,
    Spinner,
    ContextMenu,
    VerifyWithPassword,
  },
  mixins: [AuthenicationMixin],
  data() {
    return {
      loading: false,
      createdClickedTime: undefined,
      selectedYear: undefined,
      years: [],
      selectableYears: {},
      filesByYear: [],
      columns: [
        {
          name: 'Year',
        },
        {
          name: 'End of Year Statement',
        },
        {
          name: 'Errors',
        },
        {
          name: 'Created',
        },
        {
          name: 'Locked',
        },
        {
          name: '',
          thinColumn: true,
        },
      ],
    };
  },
  async created() {
    const lastYear = new Date().getFullYear() - 1;
    this.years = _.range('2019', lastYear + 1, 1).reverse();
    this.prepareStatementRows();
    await this.fetchStatementFiles();
  },
  methods: {
    async publishAndLockYear(year) {
      try {
        this.loading = true;

        const entityType = 'EOYS';
        const lockFile = {
          name: 'json',
        };
        const fileDescriptor = CreateUploadFileDescriptor(
          entityType,
          '999999',
          ['LOCKED'],
          lockFile,
        );
        const options = {
          directory: `end-of-year-statements/${year}`,
          metadata: [
            {
              key: 'Content-Type',
              value: 'application/json',
            },
          ],
        };
        await RemoteFileService.uploadToDocumentRepository(fileDescriptor, options);
        await RemoteFileService.uploadToDocumentRepository(fileDescriptor, {
          ...options,
          repo: 'star-docrepo',
        });
        setTimeout(async () => {
          await this.fetchStatementFiles();
          this.loading = false;
        }, 500);
      } catch (error) {
        console.error('error', error);
      }
    },
    prepareStatementRows() {
      this.years.reduce((entries, year) => {
        entries.push({ year });
        return entries;
      }, this.filesByYear);
    },
    async doWork() {
      this.createdClickedTime = moment();
      try {
        this.loading = true;
        await endOfYearStatementService.createEndOfYearStatement(this.selectedYear);
        setInterval(async () => {
          if (this.createdClickedTime) {
            await this.fetchStatementFiles();
          }
        }, 5000);
      } catch (error) {
        console.error('error', error);
      }
    },
    async fetchStatementFiles() {
      const promises = this.filesByYear.map(async (row) => {
        const files = await RemoteFileService.listDocumentRepository(
          `end-of-year-statements/${row.year}`,
        );
        return {
          year: row.year,
          statement: files.find((file) => file.fileType.toLowerCase() === 'xml'),
          errorFile: files.find((file) => file.filename === 'errors.json'),
          lockFile: files.find((file) => file.filename.includes('__LOCKED.json')),
        };
      });
      this.filesByYear = await Promise.all(promises);
      this.filesByYear = _.orderBy(this.filesByYear, ['year'], 'desc');
      const selectableArr = this.years.filter(
        (year) => !this.filesByYear.find((row) => row.year === year).lockFile,
      );
      this.selectableYears = selectableArr.reduce((obj, type) => {
        obj[type] = type;
        return obj;
      }, {});
      if (selectableArr.length > 0) {
        this.selectedYear = selectableArr[0].toString();
      }
      const fileCreatedTime = this.filesByYear[0].statement
        ? moment(this.filesByYear[0].statement.lastModified, 'x')
        : '';
      if (this.createdClickedTime && fileCreatedTime > this.createdClickedTime) {
        this.loading = false;
        this.createdClickedTime = undefined;
      }
    },
    async onPreviewEndOfYearStatementFile(path, fileName) {
      try {
        const url = await RemoteFileService.getEndOfYearStatementFileFetchUrl(
          `${path}/${fileName}`,
        );
        this.$router.push({
          name: 'previewJson',
          query: { url },
        });
      } catch (error) {
        error.title = 'File could not be downloaded';
        this.$addStarError(error);
      }
    },
    async onDownloadEndOfYearStatementFile(path, fileName) {
      try {
        const file = `${path}/${fileName}`;
        const fileData = await RemoteFileService.downloadEndOfYearStatementFile(file);
        DownloadLink.direct(fileData, file);
      } catch (error) {
        error.title = 'File could not be downloaded';
        this.$addStarError(error);
      }
    },
  },
};
</script>

<style lang="scss">
.administrate-end-of-year-statement {
  .fa-question {
    color: var(--green--dark);
  }
  .custom-select {
    width: 200px;
  }
}
</style>
