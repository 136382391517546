<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Payment Information</h2>
      </div>
      <template v-if="editMode">
        <div class="col s6 text--right">
          <action-buttons scope="payment-information" @save="onSave" @cancel="onCancel" />
        </div>
      </template>
    </div>

    <form data-vv-scope="payment-information">
      <div class="e2e-create__payment-information card">
        <div v-if="!hasPaymentReceiver" class="row">
          <div class="col s12">
            <div class="tabs">
              <div class="tabs__wrapper">
                <a
                  v-if="showPrivateAccount"
                  :class="{ active: selectedAccountType === 'privateAccount' }"
                  @click="changeSelectedAccountType('privateAccount')"
                >
                  Bank Account
                </a>
                <a
                  v-if="companyAccountVisible"
                  :class="{ active: selectedAccountType === 'companyAccount' }"
                  @click="changeSelectedAccountType('companyAccount')"
                >
                  Company Account
                </a>
                <a
                  :class="{ active: selectedAccountType === 'foreignAccount' }"
                  @click="changeSelectedAccountType('foreignAccount')"
                >
                  Foreign Account
                </a>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!hasPaymentReceiver" class="row">
          <div class="col s12">
            <private-account-input
              v-show="isSelected('privateAccount')"
              :private-account="privateAccount"
              :edit-mode="editMode"
              @input="emitChange('addPrivateAccount', $event)"
            />
            <company-account-input
              v-show="isSelected('companyAccount')"
              :company-account="companyAccount"
              :company-account-disabled="companyAccountDisabled"
              :edit-mode="editMode"
              @input="emitChange('addCompanyAccount', $event)"
            />
            <foreign-account-input
              v-show="isSelected('foreignAccount')"
              :foreign-account="foreignAccount"
              :edit-mode="editMode"
              @input="emitChange('addForeignAccount', $event)"
            />
          </div>
        </div>

        <div v-if="!hasPaymentReceiver" class="row flush--bottom">
          <div class="col s12">
            <a
              v-show="selectedAccountType !== 'paymentReceiver'"
              class="float-right"
              @click="emitChange('resetPaymentInfo')"
            >
              Reset
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  createPrivateAccount,
  createCompanyAccount,
  createForeignAccount,
} from '../../../domain/commonPayment';
import ActionButtons from '../button/action-buttons';
import CompanyAccountInput from './company-account-input';
import ForeignAccountInput from './foreign-account-input';
import PrivateAccountInput from './private-account-input';

export default {
  name: 'CreatePaymentInformation',
  components: {
    ActionButtons,
    CompanyAccountInput,
    ForeignAccountInput,
    PrivateAccountInput,
  },
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    privateAccount: {
      type: Object,
      default: () => createPrivateAccount(),
    },
    companyAccount: {
      type: Object,
      default: () => createCompanyAccount(),
    },
    foreignAccount: {
      type: Object,
      default: () => createForeignAccount(),
    },
    hasPaymentReceiver: {
      type: Boolean,
      default: false,
    },
    companyAccountDisabled: {
      type: Boolean,
      default: false,
    },
    companyAccountVisible: {
      type: Boolean,
      default: true,
    },
    showPrivateAccount: {
      type: Boolean,
      default: true,
    },
    showForeignAccount: {
      type: Boolean,
      default: true,
    },
    paymentsBlocked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedAccountType: null,
    };
  },
  watch: {
    companyAccountVisible() {
      if (this.selectedAccountType === 'companyAccount' && !this.companyAccountVisible) {
        this.selectedAccountType = 'privateAccount';
      }
    },
  },
  created() {
    this.selectedAccountType = this.detectSelectedAccountType();
  },
  methods: {
    detectSelectedAccountType() {
      function accountHasPropertySet(account) {
        return Object.values(account).filter((value) => value !== null).length > 0;
      }

      if (
        (!this.editMode && this.showPrivateAccount) ||
        (this.editMode && accountHasPropertySet(this.privateAccount))
      ) {
        return 'privateAccount';
      }

      if (
        (!this.editMode && this.companyAccountVisible) ||
        (this.editMode && accountHasPropertySet(this.companyAccount))
      ) {
        return 'companyAccount';
      }

      if (
        (!this.editMode && this.showForeignAccount) ||
        (this.editMode && accountHasPropertySet(this.foreignAccount))
      ) {
        return 'foreignAccount';
      }

      return 'privateAccount';
    },
    changeSelectedAccountType(type) {
      this.selectedAccountType = type;
    },
    isSelected(type) {
      return this.selectedAccountType === type;
    },
    emitChange(eventType, data) {
      this.$emit(eventType, data);
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSave() {
      if (this.selectedAccountType !== 'companyAccount') {
        this.$emit('addCompanyAccount', createCompanyAccount());
      }
      if (this.selectedAccountType !== 'foreignAccount') {
        this.$emit('addForeignAccount', createForeignAccount());
      }
      if (this.selectedAccountType !== 'privateAccount') {
        this.$emit('addPrivateAccount', createPrivateAccount());
      }
      this.$emit('save', this.selectedAccountType);
    },
  },
};
</script>
