import gql from 'graphql-tag';

const getHistory = gql`
  query getHistory($idTerm: String!) {
    history(id: $idTerm) {
      stream_id
      type
      process_id
      user
      timestamp
      payload
      version
      origin {
        entityType
        entityId
        initiator {
          entityType
          entityId
          timestamp
        }
      }
    }
  }
`;

export default {
  getHistory,
};
