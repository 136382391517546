import { filter, orderBy, get } from 'lodash';
import PaymentService from '../../../services/paymentService';

function filterHistory(collection, terms) {
  const searchTerm = new RegExp(terms, 'i');
  return filter(
    collection,
    (row) =>
      [row.payment_date, row.amount.replace('.', ','), row.refund_date]
        .join(' ')
        .search(searchTerm) > -1,
  );
}

function filterAreas(collection, terms) {
  const searchTerm = new RegExp(terms, 'i');
  return filter(
    collection,
    (area) =>
      [
        area.area_key,
        area.right,
        area.sources,
        area.original_amount,
        area.fee,
        area.gross_amount,
        area.vat,
        area.tax,
        area.net_amount,
        area.balance,
      ]
        .join(' ')
        .search(searchTerm) > -1,
  );
}

function filterByRight(collection, right) {
  if (right) {
    return filter(collection, (area) => area.right === right);
  }
  return collection;
}

function filterBySociety(collection, societyCode, societies) {
  if (societyCode) {
    return filter(collection, (area) =>
      area.sources.includes(societies.find((s) => s.ipd_code === societyCode).name),
    );
  }
  return collection;
}

async function getLedgerDetails(id, pagination, filterTerms) {
  const ledgerDetailRequest = {
    ledgerId: id,
    limit: pagination.limit,
    offset: pagination.offset,
    startDate: filterTerms.start_date,
    endDate: filterTerms.end_date,
    areaId: filterTerms.area_id,
    sourceType: filterTerms.source_type,
  };
  const ledgerDetails = await PaymentService.getLedgerDetails(ledgerDetailRequest);
  return ledgerDetails.rows;
}

async function getPaymentHistory(nodeId, pagination, filterTerms) {
  const paymentHistoryRequest = {
    nodeId,
    limit: Number(pagination.limit),
    offset: Number(pagination.offset),
    startDate: filterTerms.start_date,
    endDate: filterTerms.end_date,
  };
  return PaymentService.getPaymentHistory(paymentHistoryRequest);
}

async function getLatestPayments(nodeId, limit) {
  const paymentHistoryRequest = {
    nodeId,
    limit,
  };
  return PaymentService.getPaymentHistory(paymentHistoryRequest);
}

function sortList(collection, field, order) {
  return orderBy(
    collection,
    (row) => {
      const val = get(row, field);
      return isNaN(val) ? val : Number(val);
    },
    [order],
  );
}

export default {
  filterAreas,
  filterHistory,
  filterByRight,
  filterBySociety,
  getLedgerDetails,
  getPaymentHistory,
  getLatestPayments,
  sortList,
};
