<template>
  <div>
    <div class="row wrapper">
      <div class="col s11">
        <h1>Report {{ fileName }}</h1>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <spinner v-if="loading" />
        <div v-else-if="fileInfo">
          <div class="file-info">
            <dl>
              <dt>Download</dt>
              <dd>
                <a @click="onDownloadReport()">
                  <i class="fas fa-download" />
                  ({{ fileInfo.size }} kb)
                </a>
              </dd>

              <dt>File Name</dt>
              <dd>{{ fileName }}</dd>

              <dt>File Size</dt>
              <dd>{{ fileInfo.size / 1000 }} kB</dd>

              <dt>Created at</dt>
              <dd>{{ $filters.dateFormat(fileInfo.createdAt, 'YYYY-MM-DD HH:mm:ss') }}</dd>
            </dl>
            <div v-if="isStringTrue(metadata.download)">
              <dl>
                <dt>Downloaded by</dt>
                <dd>{{ metadata.download_by }}</dd>

                <dt>Downloaded at</dt>
                <dd>
                  {{
                    $filters.dateFormat(
                      $filters.dateParse(metadata.download_date),
                      'YYYY-MM-DD HH:mm:ss',
                    )
                  }}
                </dd>
              </dl>
            </div>
            <div v-if="isStringTrue(metadata.booked)">
              <dl>
                <dt>Booked by</dt>
                <dd>{{ metadata.booked_by }}</dd>

                <dt>Booked at</dt>
                <dd>
                  {{
                    $filters.dateFormat(
                      $filters.dateParse(metadata.booked_date),
                      'YYYY-MM-DD HH:mm:ss',
                    )
                  }}
                </dd>
              </dl>
            </div>
            <div>
              <input
                id="report-booked"
                type="checkbox"
                name="report-booked"
                class="filled-in"
                :checked="isStringTrue(metadata.booked)"
                @change="setBooked($event.target.checked)"
              />
              <label for="report-booked">Booked</label>
            </div>
          </div>
          <div>
            <h2>Sources</h2>
            <div v-if="sources && sources.length > 0">
              <p>The following sources are included in the report</p>
              <table>
                <thead>
                  <th>Name</th>
                  <th>Date</th>
                  <th class="text--right">Net</th>
                  <th class="text--right">Vat</th>
                </thead>
                <tbody>
                  <tr v-for="source of sources" :key="source.id">
                    <td>
                      <router-link :to="getSourceLink(source)">{{ source.name }}</router-link>
                    </td>
                    <td>
                      {{ $filters.dateFormat($filters.dateParse(source.date), 'YYYY-MM-DD') }}
                    </td>
                    <td class="text--right">
                      {{ $filters.formatAmountCent(source.net, 'SEK') }}
                    </td>
                    <td class="text--right">
                      {{ $filters.formatAmountCent(source.vat, 'SEK') }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p v-else>No source information available for this report.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RemoteFileService from '@/services/remoteFileService';
import DownloadLink from '../ui/file/downloadLink';
import AuthenticationService from '@/services/authenticationService';
import Spinner from '@/components/spinner';
import { query } from '../../services/apolloRequest';
import gql from 'graphql-tag';
export default {
  components: {
    Spinner,
  },
  props: {
    year: String,
    monthId: String,
    fileName: String,
  },
  data() {
    return {
      fileInfo: null,
      metadata: null,
      loading: false,
      sources: [],
    };
  },
  computed: {
    sourceType() {
      return this.fileName.split('_')[0];
    },
    key() {
      return `accounting/${this.year}/${this.monthId}/${this.fileName}`;
    },
  },
  created() {
    this.getFileInfo();
  },
  methods: {
    isStringTrue(val) {
      return val === 'true';
    },
    async setBooked(val) {
      const username = AuthenticationService.getUserName();
      const metadata =
        val === true
          ? { booked: 'true', booked_by: username, booked_date: new Date() }
          : { booked: 'false' };
      await RemoteFileService.updateMetadata(this.key, metadata);
      await this.getFileInfo();
    },
    async onDownloadReport() {
      const username = AuthenticationService.getUserName();
      const metadata = { download: 'true', download_by: username, download_date: new Date() };
      const fileData = await RemoteFileService.downloadFromDocumentRepositoryAndUpdateMetadata(
        this.key,
        metadata,
      );
      DownloadLink.direct(fileData, this.fileName);
      this.getFileInfo();
    },
    async getFileInfo() {
      this.loading = true;
      try {
        const info = await RemoteFileService.getFileInfo(this.key);
        console.log(info, 'info');
        this.fileInfo = {
          size: info.size,
          createdAt: new Date(Number(info.lastModified)),
        };
        this.metadata = info.metadata;
        const sources = info.metadata && info.metadata.sources && JSON.parse(info.metadata.sources);
        if (sources) {
          await this.loadSources(sources);
        }
      } finally {
        this.loading = false;
      }
    },
    async loadSources(sourceIds) {
      if (this.sourceType === 'refund') {
        const refunds = await query(
          {
            query: gql`
              query refunds($query: RefundQuery) {
                refunds(query: $query) {
                  id
                  reason
                  note
                  date
                  net
                  vat
                }
              }
            `,
            variables: {
              query: {
                refundIds: sourceIds,
              },
            },
          },
          { pickProp: 'refunds' },
        );
        console.log(refunds, 'refunds');
        this.sources = refunds.map((r) => ({
          id: r.id,
          name: `${r.note} (${r.reason})`,
          date: r.date,
          net: r.net,
          vat: r.vat,
        }));
      } else if (this.sourceType === 'payment') {
        if (sourceIds.length > 1) {
          throw new Error(`Can not handle a payment with several sources`);
        }
        const payment = await query(
          {
            query: gql`
              query {
                payment(id: "${sourceIds[0]}") {
                  id
                  name
                  paymentDate
                  payableAmount
                  payableVat
                }
              }
            `,
          },
          { pickProp: 'payment' },
        );
        this.sources = [
          {
            id: payment.id,
            name: payment.name,
            date: payment.paymentDate,
            net: payment.payableAmount,
            vat: payment.payableVat,
          },
        ];
      }
    },
    getSourceLink(source) {
      switch (this.sourceType) {
        case 'payment':
          return `/payments/${source.id}`;
        case 'refund':
          return `/refunds/${source.id}`;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.file-info {
  margin-bottom: 2rem;
  max-width: 40rem;
}
dl {
  line-height: 1.8em;
}
dt {
  flex-basis: 20%;
}
dd {
  flex-basis: 80%;
}
h2 {
  margin-bottom: 1rem;
}
table {
  margin-top: 2rem;
}
</style>
