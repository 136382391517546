<template>
  <div class="cmp">
    <div class="row">
      <div class="col s2 hide-on-med-and-down">
        <entity-tag />
      </div>
      <div class="col s12 l7">
        <div class="row">
          <div class="col s12">
            <h1>Create Performer</h1>
          </div>
        </div>
        <form :data-vv-scope="scope">
          <create-menu entity-type="performer" @create="createPerformerAndNavigate" />
          <create-general-information :scope="scope" @searchIpd="searchIpd" />
          <create-contact-information />
          <create-age-living-information :scope="scope" />
          <create-member-information />
          <create-mandate-information :create-mode="true" />
          <create-payment-information
            :private-account="privateAccount"
            :foreign-account="foreignAccount"
            :company-account-visible="false"
            @resetPaymentInfo="resetPaymentInfo"
            @addPrivateAccount="commitChange('addPrivateAccount', $event)"
            @addForeignAccount="commitChange('addForeignAccount', $event)"
          />
          <create-associates namespace="performer" relation-type="associates" />
          <create-tax-information />
          <create-documents-information />
          <create-meta-information />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { commit } from '../../../../store/modules/performer/utils';
import CreateAgeLivingInformation from './create-age-living-information';
import CreateAssociates from '../../../ui/associates/create-associates';
import CreateContactInformation from './create-contact-information';
import CreateDocumentsInformation from './create-documents-information';
import CreateGeneralInformation from './create-general-information';
import CreateHelper from './createHelper';
import CreateMandateInformation from './create-mandate-information';
import CreateMenu from '../../../ui/create-menu';
import CreateMetaInformation from './create-meta-information';
import CreateMemberInformation from './create-member-information';
import CreatePaymentInformation from '../../../ui/payment/create-payment-information';
import CreateTaxInformation from './create-tax-information';
import EntityTag from '../../../ui/entity-tag';

export default {
  name: 'CreatePerformer',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    CreateAgeLivingInformation,
    CreateAssociates,
    CreateContactInformation,
    CreateDocumentsInformation,
    CreateGeneralInformation,
    CreateMandateInformation,
    CreateMenu,
    CreateMetaInformation,
    CreateMemberInformation,
    CreatePaymentInformation,
    CreateTaxInformation,
    EntityTag,
  },
  data() {
    return {
      scope: 'create-performer',
      error: false,
    };
  },
  computed: {
    ...mapGetters('performer', ['privateAccount', 'foreignAccount', 'associates']),
  },
  created() {
    CreateHelper.initAggregate();
  },
  methods: {
    resetPaymentInfo() {
      commit('resetPaymentInfo');
    },
    commitChange(type, event) {
      this.resetPaymentInfo();
      commit(type, event);
    },
    async searchIpd(ipn) {
      await CreateHelper.setFromIpdRightHolder(ipn);
    },
    async createPerformerAndNavigate() {
      this.error = false;
      try {
        const { id, isNewMainArtists } = await CreateHelper.createPerformerAndNavigateToView();
        this.$router.push({
          name: 'performerView',
          params: { id, waitForServer: isNewMainArtists },
        });
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
    },
  },
};
</script>
