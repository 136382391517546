<template>
  <div>
    <div class="title">New Music Usage to Distribute</div>
    <section class="widget-table">
      <header>
        <div class="widget-table--col">Distribution Area</div>
        <div class="widget-table--col sm">Year</div>
      </header>
      <div v-for="distribution in payload.items" :key="distribution.id" class="widget-table--row">
        <div class="widget-table--col">
          <router-link
            :to="`/distribution/distribute/${distribution.report_year}/${
              distribution.year_id
            }/calculate/${distribution.distribution_id || ''}`"
          >
            {{ distribution.area_name }}
          </router-link>
        </div>
        <div class="widget-table--col sm">{{ distribution.report_year }}</div>
      </div>
    </section>

    <section class="widget-link">
      <router-link to="/distribution/distribute">
        Show Distributions
        {{ payload.total_count > 5 ? '(' + (payload.total_count - 5) + ' more)' : '' }}
      </router-link>
    </section>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'NewDistributionsWidget',
  mixins: [WidgetMixin],
};
</script>
