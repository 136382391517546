<template>
  <div class="worklist__public">
    <div class="row">
      <h2>Create worklist</h2>
    </div>

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <form v-else data-vv-scope="create-public-worklist" @click.prevent>
      <div class="row">
        <validated-text-input
          name="name"
          label="Name"
          scope="create-public-worklist"
          placeholder="E.g. Distribution Errors 2017"
          :value="worklistInfo.name"
          rule="required"
          @input="updateName"
        />
      </div>

      <div class="row">
        <div class="col s12">
          <searchable-tag-input
            name="tags"
            label="Username"
            placeholder="E.g. Peter"
            scope="create-public-worklist"
            :value-list="worklistInfo.assignees"
            :searcher="searchUser"
            use-template="user"
            :rule="{ required: true }"
            @add="addAssignee"
            @remove="removeAssignee"
          />
        </div>
      </div>

      <div class="row partition-size">
        <validated-text-input
          name="partitionSize"
          label="Items per list"
          scope="create-public-worklist"
          placeholder="E.g. 100"
          :value="partitionInfo.size"
          rule="numeric|required"
          @input="updatePartitionSize"
        />
      </div>

      <div
        v-if="
          worklistInfo.category === 'Matching' || worklistInfo.category === 'Incomplete recordings'
        "
        class="row"
      >
        <select-country
          :value="partitionInfo.country_code"
          :input-countries="countries"
          label="Recorded in country"
          @input="updateCountryCode"
        />
      </div>

      <div v-if="worklistInfo.category === 'Incomplete recordings'" class="row">
        <select-year
          :value="partitionInfo.year"
          :years="years"
          label="Year"
          :required="false"
          @input="updateYear"
        />
      </div>

      <div v-if="worklistInfo.category === 'Incomplete recordings'" class="row">
        <select-input
          name="issue-type-filter"
          label="Error type"
          :items="issueTypes"
          :value="partitionInfo.issue_type"
          item-key="key"
          item-value="value"
          :empty-select-option="true"
          @input="updateIssueType"
        />
      </div>

      <div class="row">
        <button class="btn" @click="saveWorklists">Create</button>
      </div>
    </form>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import SearchableTagInput from '../../ui/tag/searchable-tag-input';
import SelectInput from '../../ui/select/select-input';
import SearchHelper from '../../search/searchHelper';
import PaymentService from '../../../services/paymentService';
import MatchingService from '../../../services/matchingService';
import DistributionService from '../../../services/distributionService';
import { ReportType } from '../../../domain/matchingDomain';
import { saveWorklist } from '../../../services/worklistsService';
import SelectCountry from '../../ui/select/select-country';
import SelectYear from '../../ui/select/select-year';
import Spinner from '../../spinner';

export default {
  name: 'CreatePublicWorklist',
  components: {
    ValidatedTextInput,
    SearchableTagInput,
    SelectInput,
    SelectCountry,
    SelectYear,
    Spinner,
  },
  inject: ['$validator'],
  data() {
    return {
      loading: false,
      years: [],
      countries: [],
      issueTypes: [],
      selectedUsers: [],
      partitionInfo: {
        worklistIds: [],
        size: null,
        country_code: null,
        issue_type: null,
        year: null,
        match_state: null,
      },
      worklistInfo: {
        name: '',
        category: '',
        assignees: [],
      },
    };
  },
  created() {
    switch (this.$router.currentRoute.name) {
      case 'payment':
        this.worklistInfo.category = 'Payment';
        this.worklistInfo.name = 'Payment errors';
        break;
      case 'musicReportCandidatesView':
        this.worklistInfo.category = 'Matching';
        this.worklistInfo.name = 'Match candidates';
        this.partitionInfo.match_state = 'C';
        break;
      case 'viewSocietySDEGPlaylistLinesToCheck':
        this.worklistInfo.category = 'Sdeg claims';
        this.worklistInfo.name = 'Sdeg claims to check';
        break;
      case 'incompleteRecordingsView':
        this.worklistInfo.category = 'Incomplete recordings';
        this.worklistInfo.name = 'Incomplete recordings';
        break;
      case 'viewSdegPlayedRecordingsReportNew':
        this.worklistInfo.category = 'Sdeg Played Recordings';
        this.worklistInfo.name = 'Sdeg Played Recordings to check';
        this.partitionInfo.match_state = 'R';
        break;
      default:
        this.worklistInfo.category = 'Matching';
        this.worklistInfo.name = 'Match errors';
    }
    const yearsStr = localStorage.getItem('worklistYears');
    if (yearsStr) {
      this.years = yearsStr.split(',');
    }
    this.countries = JSON.parse(localStorage.getItem('worklistCountries')) || [];
    this.issueTypes = JSON.parse(localStorage.getItem('worklistIssueTypes')) || [];
  },
  methods: {
    updateCountryCode(code) {
      this.partitionInfo.country_code = code;
    },
    updateYear(year) {
      this.partitionInfo.year = year;
    },
    updateName(name) {
      this.worklistInfo.name = name;
    },
    updateIssueType(issueType) {
      this.partitionInfo.issue_type = issueType;
    },
    async searchUser(terms) {
      return SearchHelper.searchUser(terms);
    },
    addAssignee(event) {
      if (event.item) {
        this.selectedUsers.push(event.item.name);
      }
    },
    removeAssignee(event) {
      this.selectedUsers.splice(event.idx, 1);
    },
    updatePartitionSize(partitionSize) {
      this.partitionInfo.size = partitionSize;
    },
    clearForm() {
      this.selectedUsers = [];
      this.worklistInfo.assignees = [];
      this.partitionInfo.worklistIds = [];
      this.partitionInfo.size = null;
      this.partitionInfo.country_code = null;
      this.partitionInfo.issue_type = null;
      this.partitionInfo.year = null;
      this.partitionInfo.match_state = null;
    },
    async saveWorklists() {
      const success = await this.$validator.validate('create-public-worklist.*');
      if (success && !this.validationErrors.any('create-public-worklist')) {
        try {
          this.loading = true;
          this.worklistInfo.assignees = this.selectedUsers;
          this.partitionInfo.worklistIds = this.selectedUsers.map(() => uuid.v4());
          if (this.worklistInfo.category === 'Payment') {
            await PaymentService.setWorklistIds(
              this.$router.currentRoute.query.id,
              this.partitionInfo,
            );
          } else if (
            this.worklistInfo.category === 'Matching' ||
            this.worklistInfo.category === 'Sdeg Played Recordings'
          ) {
            await MatchingService.setWorklistIds(
              this.$router.currentRoute.params.reportId,
              ReportType.PLAYED_RECORDING_REPORT,
              this.partitionInfo,
            );
          } else if (this.worklistInfo.category === 'Sdeg claims') {
            await MatchingService.setWorklistIds(
              this.$router.currentRoute.params.reportId,
              ReportType.PLAYLIST_REPORT,
              this.partitionInfo,
            );
          } else if (this.worklistInfo.category === 'Incomplete recordings') {
            await DistributionService.assignIssues(this.partitionInfo);
          }
          await this.createWorklists();
          this.clearForm();
          this.$emit('close');
          this.loading = false;
        } catch (e) {
          console.log("Couldn't save worklist", e);
        }
      } else {
        console.log('There are errors');
      }
    },
    async createWorklists() {
      const worklistsPromises = [];
      for (let idx = 0; idx < this.selectedUsers.length; idx++) {
        let url = '';
        if (Object.keys(this.$router.currentRoute.query).length > 1) {
          url = `${this.$router.currentRoute.fullPath}&worklist_id=${this.partitionInfo.worklistIds[idx]}`;
        } else {
          url = `${this.$router.currentRoute.fullPath}?worklist_id=${this.partitionInfo.worklistIds[idx]}`;
        }
        const worklist = {
          worklist_id: this.partitionInfo.worklistIds[idx],
          assignee: this.worklistInfo.assignees[idx],
          payload: {
            name: this.worklistInfo.name,
            category: this.worklistInfo.category,
            url,
          },
        };
        worklistsPromises.push(saveWorklist(worklist));
      }
      await Promise.all(worklistsPromises);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.searchable-input__autocomplete {
  position: absolute;
  left: 0;
  top: 55px;

  width: 100%;
  z-index: 1001;
  background-color: var(--white);
  margin-bottom: 100px;
  overflow-y: auto !important;
  box-shadow:
    0 2px 4px 0 rgba(0, 0, 0, 0.2),
    0 0 2px 0 rgba(0, 0, 0, 0.12);
  padding: var(--spacing-double);
}
</style>
