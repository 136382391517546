import gql from 'graphql-tag';

const dashboard = gql`
  query dashboard($request: DashboardRequest!) {
    dashboard(request: $request) {
      payload
    }
  }
`;

export default {
  dashboard,
};
