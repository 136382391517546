<template>
  <form class="pos-relative card" data-vv-scope="create-new-performer">
    <div>
      <div class="row">
        <div class="col s8">
          <validated-text-input
            ref="firstName"
            :name="name + '.first_name'"
            scope="create-new-performer"
            label="First name"
            :mandatory="true"
            placeholder="E.g. Joakim"
            :value="currentPerformer.first_name"
            :rule="nameRules"
            @input="updateFirstName"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <validated-text-input
            ref="lastName"
            :name="name + '.last_name'"
            scope="create-new-performer"
            label="Last name"
            :mandatory="true"
            placeholder="E.g. Berg"
            :value="currentPerformer.last_name"
            :rule="nameRules"
            @input="updateLastName"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <tag-input
            ref="pseudoNames"
            :name="name + '.pseudo_names'"
            label="Pseudo names"
            placeholder="E.g. Jocke Berg"
            :value-list="currentPerformer.pseudo_names"
            :rule="pseudoNameRules"
            @add="addPseudoName"
            @remove="removePseudoName"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s4">
          <select-instrument
            :name="`${name}.main_instrument`"
            :value="currentPerformer.instrument"
            @input="updateInstrument"
          />
        </div>

        <div class="col s4">
          <select-input
            :name="name + '.role'"
            label="Role"
            :value="currentPerformer.role"
            :items="roles"
            item-key="code"
            item-value="name"
            @input="updateRole"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <select-country
            :name="`${name}.country_of_residence`"
            label="Country of residence"
            scope="create-new-performer"
            :value="currentPerformer.country_of_residence"
            :mandatory="true"
            rule="required"
            @input="updateCountryOfResidence"
          />
        </div>
      </div>
      <div class="row">
        <create-active-period
          :active-periods="currentPerformer.active_period"
          :name="name"
          scope="create-new-performer"
          label="Years active"
          @fromDateChanged="onActivePeriodFromDateChanged"
          @toDateChanged="onActivePeriodToDateChanged"
          @add="onAddActivePeriod"
          @remove="onRemoveActivePeriod"
        />
      </div>
    </div>

    <delete-button :is-component="true" @click="onRemove" />
  </form>
</template>

<script>
import { createActivePeriod } from '../../../domain/mainArtistDomain';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import TagInput from '../../ui/tag/tag-input';
import SelectInput from '../../ui/select/select-input';
import CreateActivePeriod from '../create-active-period';
import DeleteButton from '../../ui/button/delete-button';
import SelectInstrument from '../../ui/select/select-instrument';
import SelectCountry from '../../ui/select/select-country';

export default {
  name: 'CreateNewPerformer',
  components: {
    SelectCountry,
    SelectInstrument,
    ValidatedTextInput,
    TagInput,
    SelectInput,
    CreateActivePeriod,
    DeleteButton,
  },
  inject: ['$validator'],
  props: {
    performer: { type: Object },
    name: { type: String },
    roles: { type: Array },
  },
  data() {
    return {
      currentPerformer: Object.assign({}, this.performer),
    };
  },
  computed: {
    nameRules() {
      if (this.currentPerformer.pseudo_names.length > 0) {
        return { required: false };
      }
      return { required: true };
    },
    pseudoNameRules() {
      if (this.currentPerformer.first_name && this.currentPerformer.last_name) {
        return { required: false };
      }
      return { required: true };
    },
  },
  methods: {
    addPseudoName(pseudoName) {
      this.currentPerformer.pseudo_names.push(pseudoName);
      this.emitUpdate();
    },
    removePseudoName(idx) {
      this.currentPerformer.pseudo_names.splice(idx, 1);
      this.emitUpdate();
    },
    updateFirstName(value) {
      this.currentPerformer.first_name = value;
      this.emitUpdate();
    },
    updateLastName(value) {
      this.currentPerformer.last_name = value;
      this.emitUpdate();
    },
    updateInstrument(value) {
      this.currentPerformer.instrument = value;
      this.emitUpdate();
    },
    updateRole(value) {
      this.currentPerformer.role = value;
      this.emitUpdate();
    },
    updateCountryOfResidence(value) {
      this.currentPerformer.country_of_residence = value;
      this.emitUpdate();
    },
    onActivePeriodFromDateChanged(obj) {
      const toDate = this.currentPerformer.active_period[obj.index].to;
      this.currentPerformer.active_period.splice(obj.index, 1, { to: toDate, from: obj.entry });
      this.emitUpdate();
    },
    onActivePeriodToDateChanged(obj) {
      const fromDate = this.currentPerformer.active_period[obj.index].from;
      this.currentPerformer.active_period.splice(obj.index, 1, { to: obj.entry, from: fromDate });
      this.emitUpdate();
    },
    onAddActivePeriod() {
      this.currentPerformer.active_period.push(createActivePeriod());
      this.emitUpdate();
    },
    onRemoveActivePeriod(index) {
      this.currentPerformer.active_period.splice(index, 1);
      if (this.currentPerformer.active_period.length === 0) {
        this.currentPerformer.active_period.push(createActivePeriod());
      }
      this.emitUpdate();
    },
    onRemove(index) {
      this.$emit('remove', index);
    },
    emitUpdate() {
      this.$emit('input', this.currentPerformer);
    },
  },
};
</script>

<style lang="scss" scoped>
.pos-relative.card {
  span.delete-button.delete-button--component {
    right: 0;
  }
}
</style>
