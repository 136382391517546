<template>
  <div>
    <div class="row">
      <div class="col s7">
        <searchable-input
          ref="name"
          :name="name + '.name'"
          label="Add an album"
          placeholder="E.g. Du & jag döden"
          :value="currentAlbum.name"
          :searcher="searchAlbum"
          use-template="album"
          @input="updateNameAndId"
        />
      </div>
      <div class="col s2">
        <validated-text-input
          ref="sideNumber"
          :name="name + '.side_no'"
          label="Side"
          :disabled="!currentAlbum.id"
          :value="1"
          :scope="scope"
          :rule="{ required: true, numeric: true, min_value: 1 }"
          @input="updateSideNo"
        />
      </div>
      <div class="col s2">
        <validated-text-input
          ref="trackNumber"
          :name="name + '.track_no'"
          label="Track"
          :disabled="!currentAlbum.id"
          :value="1"
          :scope="scope"
          :rule="{ required: true, numeric: true, min_value: 1 }"
          @input="updateTrackNo"
        />

        <delete-button v-if="deletable" :has-label="true" @click="emitRemove" />
      </div>
    </div>
  </div>
</template>

<script>
import DeleteButton from '../../ui/button/delete-button';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import SearchHelper from '../../search/searchHelper';
import SearchableInput from '../../ui/input/searchable-input';

export default {
  name: 'AddExistingAlbum',
  components: {
    DeleteButton,
    SearchableInput,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  props: {
    name: { type: String },
    album: { type: Object },
    deletable: { type: Boolean, default: false },
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentAlbum: Object.assign({}, this.album),
    };
  },
  methods: {
    searchAlbum(terms) {
      return SearchHelper.quickSearchAlbum(terms);
    },
    updateNameAndId(value) {
      if (value) {
        if (value.id) {
          this.currentAlbum.id = value.id;
        }
        this.currentAlbum.name = value.name;
        this.emitUpdate();
      }
    },
    updateSideNo(value) {
      this.currentAlbum.side_no = value;
      this.emitUpdate();
    },
    updateTrackNo(value) {
      this.currentAlbum.track_no = value;
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('input', this.currentAlbum);
    },
    emitRemove(index) {
      this.$emit('remove', index);
    },
  },
};
</script>
