<template>
  <div>
    <div class="row text--right">
      <div class="col s6">
        <h2 class="float-left">Add from Main Artist</h2>
      </div>
    </div>
    <form v-if="mode === 'edit'" :data-vv-scope="scope">
      <div class="row action-buttons text--right">
        <action-buttons :scope="scope" submit-label="Add" @cancel="cancel" @save="save" />
      </div>
      <div class="row card">
        <div class="row">
          <searchable-input
            ref="name"
            name="name"
            label="Add from Main Artist"
            placeholder="Main Artist name"
            use-template="mainArtistNameSearch"
            :searcher="searchMainArtist"
            @input="mainArtistSelected"
          />
        </div>
        <div class="row">
          <table class="lineup__add-from-main-artist">
            <sortable-head
              v-if="localLineup.length"
              :bulk-enabled="true"
              :columns="columns"
              :marked-for-bulk="allRowsMarked"
              @markAll="markAllRows"
            />
            <tbody>
              <template v-for="(row, index) in localLineup">
                <row
                  :key="index"
                  :item-id="getRowId(row)"
                  :options-menu-enabled="false"
                  :checked="isRowMarked(row)"
                  @change="markRow(row, !isRowMarked(row))"
                >
                  <td v-for="col in columns" :key="col.key">
                    <template v-if="col.key === 'societies'">
                      <mandate-list :mandates="getColValue(row, col.key)" />
                    </template>
                    <span v-else>
                      {{ getColValue(row, col.key) }}
                    </span>
                  </td>
                </row>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="edit-link">
        <a :class="{ disabled: mode === 'edit' }" @click="setMode('edit')">
          <i class="far fa-plus-square" />
          Add from Main Artist
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SearchHelper from '../../search/searchHelper';
import SearchableInput from '../../ui/input/searchable-input';
import ActionButtons from '../../ui/button/action-buttons';
import PerformerService from '../../../services/performerService';
import MandateList from '../../ui/performer/mandate-list';
import Row from '../../ui/table/row';
import SortableHead from '../../ui/table/sortable-head';
import { createPerformerReference } from './../../../domain/recordingDomain';
import _ from 'lodash';

export default {
  name: 'EditAddFromMainartist',
  components: {
    SearchableInput,
    ActionButtons,
    MandateList,
    Row,
    SortableHead,
  },
  props: {
    scope: {
      type: String,
      default: 'add-from-main-artist',
    },
  },
  data() {
    return {
      mode: 'view',
      columns: [
        { key: 'name', name: 'Name' },
        { key: 'pseudo_names', name: 'Pseudo' },
        { key: 'instruments', name: 'Main Instrument' },
        { key: 'nationality', name: 'Nationality' },
        { key: 'societies', name: 'Society' },
        { key: 'ipn', name: 'Ipn' },
      ],
      localLineup: [],
      markedRows: [],
    };
  },
  computed: {
    ...mapGetters('recording', ['lineup']),
    allRowsMarked() {
      return this.markedRows.length > 0 && this.markedRows.length === this.localLineup.length;
    },
    instruments() {
      return this.$store.state.appdata.referenceData.instruments;
    },
  },
  watch: {
    lineup() {
      this.reset();
      this.setMode('view');
    },
  },
  methods: {
    getColValue(lineupObj, key) {
      switch (key) {
        case 'instruments':
          return lineupObj.instruments.map(this.getInstrumentName).join(', ');
        case 'pseudo_names':
          return lineupObj.relation.pseudo_names.join(', ');
        case 'societies':
          return lineupObj.relation.societies.reduce((flat, m) => flat.concat(m.mandates), []);
        default:
          return lineupObj.relation[key];
      }
    },
    async searchMainArtist(name) {
      const res = await SearchHelper.advancedSearchMainArtists(
        name,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        1, // min lineup length
      );
      return res && res.results;
    },
    async mainArtistSelected(artist) {
      this.reset();
      if (artist) {
        const performers = await PerformerService.getPerformerList(
          artist.performers.map((p) => p.id),
        );
        this.localLineup = _.sortBy(
          performers.map((performer) => this.toLineupObject(performer)),
          'relation.name',
        );
      }
    },
    setMode(mode) {
      this.mode = mode;
    },
    cancel() {
      this.reset();
      this.setMode('view');
      this.$emit('cancel', this.$options.name);
    },
    reset() {
      this.localLineup = [];
      this.markedRows = [];
    },
    save() {
      const val = this.localLineup.filter(
        (row) =>
          this.isRowMarked(row) &&
          // exclude performers already in the lineup
          !this.lineup.find((r) => this.getRowId(r) === this.getRowId(row)),
      );
      this.$emit('save', val);
    },
    markAllRows(checked) {
      this.markedRows = checked ? this.localLineup.map((row) => this.getRowId(row)) : [];
    },
    getRowId(row) {
      return row.relation.id;
    },
    getInstrumentName(code) {
      const instrument = this.instruments.find((i) => i.code === code);
      return instrument && instrument.name;
    },
    markRow(row, mark) {
      const i = this.markedRows.indexOf(this.getRowId(row));
      if (i > -1) {
        this.markedRows.splice(i, 1);
      }
      if (mark === true) {
        this.markedRows.push(this.getRowId(row));
      }
    },
    isRowMarked(row) {
      return this.markedRows.includes(this.getRowId(row));
    },
    getRelationObj(p) {
      return {
        id: p.id,
        ipn: (p.membership_info || {}).ipn,
        name: `${p.general_info.first_name} ${p.general_info.last_name}`,
        nationality: p.general_info.nationality,
        pseudo_names: p.general_info.pseudo_names || [],
        societies: p.mandates || [],
        type: 'performer',
      };
    },
    toLineupObject(performer) {
      return createPerformerReference(
        this.getRelationObj(performer),
        [(performer.membership_info || {}).main_instrument],
        'G', // Group member
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.action-buttons {
  margin-top: -40px;
}
</style>
