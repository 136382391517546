<template>
  <div v-if="payment">
    <div class="row">
      <div class="col s12">
        <p>
          Payment
          <router-link :to="`/payments/${payment.id}`">{{ payment.name }}</router-link>
          ({{ $filters.dateFormat($filters.dateParse(payment.paymentDate), 'YYYY-MM-DD') }})
        </p>
        <p>
          Created by {{ payment.createdBy }},
          {{ $filters.dateFormat($filters.dateParse(payment.createdAt), 'YYYY-MM-DD HH:mm') }}
        </p>
        <p v-if="payment.confirmedAt">
          Confirmed by {{ payment.confirmedBy }},
          {{ $filters.dateFormat($filters.dateParse(payment.confirmedAt), 'YYYY-MM-DD HH:mm') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/services/apolloRequest';
import gql from 'graphql-tag';

export default {
  props: {
    id: String,
  },
  data() {
    return {
      payment: null,
    };
  },
  async created() {
    const { payment } = await query({
      query: gql`
        query {
          payment(id: "${this.id}") {
            id
            name
            paymentDate
            createdBy
            createdAt
            confirmedBy
            confirmedAt
          }
        }
      `,
    });
    this.payment = payment;
  },
};
</script>
