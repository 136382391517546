import {
  addStarError,
  starUserConfirmDialog,
  starContentLoading,
} from '../../services/notificationService';

export default {
  async beforeRouteLeave(to, from, next) {
    if (
      (Object.prototype.hasOwnProperty.call(to, 'redirectedFrom') && to.redirectedFrom !== '/') ||
      ['authentication', 'distributionChannelCreate', 'distributionAreaCreate'].includes(from.name)
    ) {
      next();
      return;
    }
    if (this.$pageMightHaveUnsavedChanges()) {
      const confirmed = await this.$starUserConfirmDialog(true);
      if (confirmed) {
        next();
      }
      this.$starUserConfirmDialog(false);
    } else {
      next();
    }
  },
  methods: {
    $addStarError: addStarError,
    $starContentLoading: starContentLoading,
    $starUserConfirmDialog(show, message, confirmText) {
      return starUserConfirmDialog(
        show,
        message || 'Your changes have not been saved. Are you sure you want to leave this page?',
        confirmText || 'Yes, continue',
      );
    },
    $pageMightHaveUnsavedChanges() {
      const valFields = this.validationFields;
      if (!valFields) {
        return false;
      }
      return Object.keys(valFields).some((key) => {
        if (key.startsWith('$')) {
          return Object.keys(valFields[key]).some((keyInner) => valFields[key][keyInner].dirty);
        }
        return valFields[key].dirty;
      });
    },
  },
};
