<template>
  <form :data-vv-scope="scope">
    <div class="row text--right">
      <div class="col s12">
        <action-buttons @save="updateGeneralInfo" @cancel="cancel" />
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <div class="col s2">
          First name
          <span
            v-if="
              (performer.general_info.first_name && performer.general_info.first_name) ||
              !performer.general_info.pseudo_names.length > 0
            "
            class="mandatory"
          >
            (mandatory)
          </span>
        </div>
        <div class="col s7">
          <validated-text-input
            v-model="performer.general_info.first_name"
            name="general_info.first_name"
            placeholder="E.g. Joakim"
            :scope="scope"
            :rule="firstAndLastNameRules"
            label="first name"
            :show-label="false"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s2">
          Last name
          <span
            v-if="
              (performer.general_info.first_name && performer.general_info.first_name) ||
              !performer.general_info.pseudo_names.length > 0
            "
            class="mandatory"
          >
            (mandatory)
          </span>
        </div>
        <div class="col s7">
          <validated-text-input
            v-model="performer.general_info.last_name"
            name="general_info.last_name"
            placeholder="E.g. Berg"
            :scope="scope"
            :rule="firstAndLastNameRules"
            label="last name"
            :show-label="false"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Protected identity</div>
        <div class="col s7">
          <input
            id="general_info--protected-identity"
            v-model="performer.general_info.protected_identity"
            name="general_info-protected-identity"
            type="checkbox"
            class="filled-in"
          />
          <label for="general_info--protected-identity"></label>
        </div>
      </div>

      <div class="row">
        <div class="col s2">Profile</div>
        <div class="col s7">
          <textarea
            v-model="performer.general_info.profile"
            class="profile"
            placeholder="E.g. Herbert Joakim 'Jocke' Berg (born 16 March 1970 in Eskilstuna, Sweden) is a Swedish singer, songwriter and musician, best known as the lead singer of Swedish Rock/Pop band Kent."
            @keyup="autoHeight"
          ></textarea>
        </div>
      </div>

      <div class="row">
        <div class="col s2">
          Pseudo name
          <span
            v-if="!(performer.general_info.first_name && performer.general_info.last_name)"
            class="mandatory"
          >
            (mandatory)
          </span>
        </div>
        <div class="col s7">
          <tag-input
            name="general_info.pseudo_names"
            :scope="scope"
            :rule="pseudoNameRules"
            placeholder="E.g. Jocke Berg"
            :value-list="performer.general_info.pseudo_names"
            label="pseudo name"
            :show-label="false"
            @add="addPseudoName"
            @remove="removePseudoName"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Name variations</div>
        <div class="col s7">
          <tag-input
            name="general_info.name_variations"
            placeholder="E.g. Joacim Berg"
            :value-list="performer.general_info.name_variations"
            @add="addNameVariation"
            @remove="removeNameVariation"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Date of birth</div>
        <div class="col s7">
          <select-date
            v-model="performer.general_info.date_of_birth"
            name="general_info.date_of_birth"
            :scope="scope"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Date of death</div>
        <div class="col s7">
          <select-date
            v-model="performer.general_info.date_of_death"
            name="general_info.date_of_death"
            :scope="scope"
            :rule="
              !!performer.general_info.date_of_birth
                ? { after: performer.general_info.date_of_birth }
                : {}
            "
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Social security number</div>
        <div class="col s7">
          <ssn-input
            v-model="performer.general_info.social_security_number"
            name="general_info.social_security_number"
            label="Social security number"
            :show-label="false"
            :scope="scope"
            :validation-config="validationConfig"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">
          Nationality
          <span class="mandatory">(mandatory)</span>
        </div>
        <div class="col s7">
          <select-country
            v-model="performer.general_info.nationality"
            name="general_info.nationality"
            rule="required"
            label="Nationality"
            :show-label="false"
            :scope="scope"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">
          Country of residence
          <span class="mandatory">(mandatory)</span>
        </div>
        <div class="col s7">
          <select-country
            v-model="performer.general_info.country_of_residence"
            name="general_info.country_of_residence"
            rule="required"
            label="Country of residence"
            :show-label="false"
            :scope="scope"
          />
        </div>
      </div>

      <div v-if="isDeveloper" class="row">
        <div class="col s2">Collective</div>
        <div class="col s7">
          <input
            id="general_info--collective"
            v-model="performer.general_info.collective"
            name="general_info-collective"
            type="checkbox"
            class="filled-in"
          />
          <label for="general_info--collective">(ONLY FOR DEVELOPERS)</label>
        </div>
      </div>

      <div v-if="!performer.general_info.collective" class="row">
        <div class="col s2">
          Gender
          <span class="mandatory">(mandatory)</span>
        </div>
        <div class="col s7">
          <input
            id="female"
            v-model="performer.general_info.sex"
            v-validate="'required'"
            type="radio"
            value="F"
            name="sex"
          />
          <label for="female" class="margin--right">Female</label>
          <input
            id="male"
            v-model="performer.general_info.sex"
            v-validate="'required'"
            type="radio"
            value="M"
            name="sex"
          />
          <label for="male" class="margin--right">Male</label>
          <input
            id="diverse"
            v-model="performer.general_info.sex"
            v-validate="'required'"
            type="radio"
            value="X"
            name="sex"
          />
          <label for="diverse">Diverse</label>

          <span v-show="validationErrors.has('sex', scope)" class="help is-danger">
            <i class="fas fa-times-circle"></i>
            {{ validationErrors.first('sex', scope) }}
          </span>
        </div>
      </div>

      <div v-if="performer.general_info.collective" class="row">
        <div class="col s2">
          Collective size
          <span class="mandatory">(mandatory)</span>
        </div>
        <div class="col s7">
          <validated-text-input
            v-model="performer.general_info.collective_size"
            name="general_info.collective_size"
            placeholder="15"
            :scope="scope"
            :rule="collectiveSizeRule"
            label="collective size"
            :show-label="false"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Local codes</div>
        <div class="col s7">
          <local-codes
            name="general_info.local_codes"
            scope="performer"
            :show-label="false"
            :entries="performer.general_info.local_codes"
            entry-key-name="code"
            entry-value-name="value"
            :local-codes="localCodeTypes"
            selectable-item-key-name="code"
            selectable-item-value-name="name"
            @input="updateLocalCode"
            @add="addLocalCode"
            @remove="removeLocalCode"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { createLocalCode, textareaAutoheight } from '../../../../domain/common';
import ActionButtons from '../../../ui/button/action-buttons';
import EditHelper from './editHelper';
import LocalCodes from '../../../ui/input/local-codes';
import SelectDate from '../../../ui/select/select-date';
import SsnInput from '../../../ui/input/ssn-input';
import TagInput from '../../../ui/tag/tag-input';
import ValidatedTextInput from '../../../ui/input/validated-text-input';
import SelectCountry from '../../../ui/select/select-country';
import { ssnUnique } from '../../ssn-input-validator';

import PnrService from '@/services/pnrService';
import { AGGREGATE_TYPES } from '@/domain/common';
import { createUpdateGeneralInfoCommand } from '@/domain/performerDomain';
import { flattenLocalCodes } from '@/common/aggregateUtils';

export default {
  name: 'EditGeneralInformation',
  components: {
    SelectCountry,
    ActionButtons,
    LocalCodes,
    SelectDate,
    SsnInput,
    TagInput,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  data() {
    return {
      performer: EditHelper.getCurrentPerformer(),
      validationConfig: Object.assign({}, { ssnUnique }),
      scope: 'edit-general-information',
    };
  },
  computed: {
    ...mapGetters('user', ['isDeveloper']),
    firstAndLastNameRules() {
      return {
        required: _.isEmpty(this.performer.general_info.pseudo_names),
        performer_name: true,
      };
    },
    pseudoNameRules() {
      return {
        required:
          _.isEmpty(this.performer.general_info.first_name) ||
          _.isEmpty(this.performer.general_info.last_name),
      };
    },
    localCodeTypes() {
      return this.$store.state.appdata.localCodeTypes;
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
    collectiveSizeRule() {
      return { required: this.performer.general_info.collective, numeric: true, min_value: 15 };
    },
  },
  async created() {
    if (this.performer.general_info.local_codes.length === 0) {
      this.addLocalCode();
    }
    this.validationConfig.ssnUnique.originalValue = String(
      this.performer.general_info.social_security_number,
    );
  },
  methods: {
    addPseudoName(pseudoName) {
      this.performer.general_info.pseudo_names.push(pseudoName);
    },
    removePseudoName(idx) {
      this.performer.general_info.pseudo_names.splice(idx, 1);
    },
    addNameVariation(nameVariation) {
      this.performer.general_info.name_variations.push(nameVariation);
    },
    removeNameVariation(idx) {
      this.performer.general_info.name_variations.splice(idx, 1);
    },
    addLocalCode() {
      this.performer.general_info.local_codes.push(createLocalCode());
    },
    updateLocalCode(entryUpdate) {
      this.performer.general_info.local_codes.splice(entryUpdate.index, 1, entryUpdate.entry);
    },
    removeLocalCode(index) {
      this.performer.general_info.local_codes.splice(index, 1);
      if (this.performer.general_info.local_codes.length === 0) {
        this.addLocalCode();
      }
    },
    cancel() {
      this.$emit('cancel');
    },
    async updateGeneralInfo() {
      const command = PnrService.prepareCommand(
        this.performer,
        createUpdateGeneralInfoCommand(AGGREGATE_TYPES.PERFORMER),
        this.performer.general_info,
        (payload) => flattenLocalCodes(payload),
      );

      await PnrService.postCommands({ errorTitle: 'Could not update information' }, [command]);
      this.$emit('saved');
    },
    autoHeight(e) {
      textareaAutoheight(e);
    },
  },
};
</script>
