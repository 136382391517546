<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>VRDB Repertoire</h1>
      </div>
    </div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col s3 float-right" />
      </div>

      <div class="row card">
        <table>
          <thead>
            <th
              v-for="(column, index) in columns"
              :key="index"
              :class="{ 'wide-column': true }"
              @click="column.sortName ? sort(column) : ''"
            >
              <span>{{ column.name }}</span>
            </th>
          </thead>

          <tbody>
            <template v-for="society in result">
              <tr :key="society.id">
                <td class="width-20">
                  <router-link v-if="society.id" :to="`/societies/${society.id}/vrdb/to_check`">
                    {{ society.name }}
                  </router-link>
                  <span v-else class="fs-14">{{ society.society_code }}</span>
                </td>
                <td class="width-10">
                  <span>{{ $filters.formatNumber(society.to_check) }}</span>
                </td>
                <td class="width-10">
                  <span>{{ $filters.formatNumber(society.accepted) }}</span>
                </td>
                <td class="width-10">
                  <span>{{ $filters.formatNumber(society.rejected) }}</span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import MatchingService from '../../services/matchingService';
import Spinner from '../spinner';

export default {
  name: 'ViewSocietiesOverview',
  components: {
    Spinner,
  },
  data() {
    return {
      viewRouteName: 'vrdbSocietiesOverview',
      loading: true,
      result: null,
      columns: [
        { name: 'Repertoire Manager' },
        { name: 'To Check' },
        { name: 'Accepted' },
        { name: 'Rejected' },
      ],
    };
  },
  computed: {
    societies() {
      return this.$store.state.appdata.societies;
    },
  },
  async created() {
    await this.reloadBaseData();
  },
  methods: {
    async reloadBaseData() {
      const result = await MatchingService.listVrdbReportHeaders();
      this.result = result
        .map((header) => ({
          ...header,
          ...this.societies.find((s) => s.vrdb_code === header.society_code),
        }))
        .sort((a, b) =>
          a.name && b.name
            ? a.name.localeCompare(b.name, 'sv')
            : a.society_code.toLowerCase().localeCompare(b.society_code.toLowerCase(), 'sv'),
        );
      this.loading = false;
    },
  },
};
</script>
