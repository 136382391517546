<template>
  <div class="right-column row">
    <div class="col s12">
      <div class="tabs">
        <div class="tabs__wrapper">
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'general' }"
            @click="selectedTab = 'general'"
          >
            General
          </div>
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'activity' }"
            @click="selectedTab = 'activity'"
          >
            Activity
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'general'">
        <create-tags
          v-if="isEditMode('Tags')"
          :edit-mode="true"
          scope="tags-album"
          entity-type="album"
          path="basic_info"
          add-method="addToBasicInfoTags"
          remove-method="removeFromBasicInfoTags"
          @update="onUpdateBasicInfo"
          @cancel="onCancel"
        />
        <tags v-else :tags="basicInfoTags" :readonly="readonly" @edit="onEdit" />
        <notes :id="id" :readonly="readonly" />
        <div class="row">
          <div class="col s12">
            <h2>SAMI Lists</h2>
            <p>
              <SamiListList :entity-id="id" entity-type="album" />
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <activity :version="version" :stream-id="id" view-name="albumInformationVersion" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Activity from '../ui/activity';
import CreateTags from '../tags/create-tags';
import Notes from '../ui/input/notes';
import Tags from '../tags/tags';
import SamiListList from '../sami-list/sami-list-list.vue';

export default {
  name: 'RightColumn',
  components: {
    Activity,
    CreateTags,
    Notes,
    Tags,
    SamiListList,
  },
  props: {
    editableComponent: {
      type: String,
      default: '',
    },
    preSelectedTab: {
      type: String,
      default: 'general',
    },
  },
  data() {
    return {
      selectedTab: this.preSelectedTab,
    };
  },
  computed: {
    ...mapGetters('album', ['id', 'version', 'basicInfoTags', 'readonly']),
  },
  methods: {
    onEdit(view) {
      this.$emit('onEdit', view);
    },
    onCancel() {
      this.$emit('onCancel');
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onUpdateBasicInfo() {
      this.$emit('onUpdateBasicInfo');
    },
  },
};
</script>
