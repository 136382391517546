<template>
  <div class="e2e-create-channel">
    <div class="cmp">
      <div class="row">
        <div class="col s2 hide-on-med-and-down">
          <entity-tag />
        </div>
        <div class="col s12 l6">
          <div class="row wrapper">
            <div class="col s12">
              <h1>Create Channel</h1>
            </div>
          </div>

          <create-menu
            entity-type="channel"
            :cancel-route-name="viewRouteName"
            @create="createAndNavigateToView"
          />

          <div class="row">
            <div class="col s6">
              <h2>Channel</h2>
            </div>
          </div>

          <form>
            <div class="card">
              <div class="row">
                <div class="col s6">
                  <validated-text-input
                    name="name"
                    label="Name"
                    placeholder="E.g. SVT 1"
                    :value="name"
                    rule="required"
                    @input="updateName"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col s6">
                  <validated-text-input
                    name="code"
                    label="Code"
                    placeholder="E.g. 101"
                    :value="code"
                    rule="required"
                    @input="updateCode"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col s6">
                  <input
                    id="vrdb"
                    name="vrdb"
                    class="filled-in"
                    type="checkbox"
                    :checked="vrdbPlaylist"
                    @change="updateVrdbPlaylist($event.target.checked)"
                  />
                  <label for="vrdb">Include in VRDB Playlist uploads</label>
                </div>
              </div>

              <div class="row">
                <div class="col s6">
                  <select-channel-group
                    :disabled="localNewChannelGroup !== ''"
                    @input="selectExistingGroup"
                  />
                </div>

                <div class="col s6">
                  <validated-text-input
                    name="group.name"
                    label="Create group"
                    placeholder="E.g. My Group"
                    :disabled="localExistingChannelGroup !== ''"
                    @input="onNewGroupInput"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { getAggregate, createAggregate } from '../../../store/modules/distributionchannel/utils';
import EntityTag from '../../ui/entity-tag';
import CreateMenu from '../../ui/create-menu';
import SelectChannelGroup from '../../ui/select/select-channel-group';
import DistributionService from '../../../services/distributionService';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'CreateDistributionChannel',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    CreateMenu,
    EntityTag,
    SelectChannelGroup,
    ValidatedTextInput,
  },
  data() {
    return {
      viewRouteName: 'distributionChannelsView',
      error: false,
      existingGroups: {},
      localExistingChannelGroup: '',
      localNewChannelGroup: '',
    };
  },
  computed: {
    ...mapGetters('distributionChannel', ['name', 'code', 'vrdbPlaylist', 'channel_group']),
  },
  created() {
    createAggregate();
  },
  methods: {
    ...mapMutations('distributionChannel', [
      'updateName',
      'updateCode',
      'updateVrdbPlaylist',
      'updateChannelGroup',
    ]),
    selectExistingGroup(group) {
      this.localExistingChannelGroup = group;
      this.updateChannelGroup(group);
    },
    onNewGroupInput(group) {
      this.localNewChannelGroup = group;
      this.updateChannelGroup(group);
    },
    async createAndNavigateToView() {
      const aggregate = _.cloneDeep(getAggregate());
      this.error = false;
      try {
        await DistributionService.createChannel(aggregate);
        this.$router.push({
          name: this.viewRouteName,
        });
      } catch (error) {
        this.$addStarError(error);
      }
    },
  },
};
</script>
