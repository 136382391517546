<template>
  <div>
    <div class="row">
      <button :disabled="candidates.length <= 1" class="btn float-right" @click="confirmMerge">
        Confirm Merge
      </button>
    </div>

    <div class="row card">
      <MergeText
        label="Name"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.name"
        @setValue="$emit('setValue', $event)"
      />

      <MergeText
        label="Release date"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.release_date"
        @setValue="$emit('setValue', $event)"
      />

      <MergeText
        label="Label"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.label"
        @setValue="$emit('setValue', $event)"
      />

      <MergeText
        label="Catalogue number"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.catalog_number"
        @setValue="$emit('setValue', $event)"
      />

      <MergeArray
        label="Tags"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.tags"
        @setValue="$emit('setValue', $event)"
      />
    </div>

    <div class="row card">
      <MergeLocalCodes
        :changes="changes"
        :candidates="candidates"
        path="basic_info.local_codes"
        @setValue="$emit('setValue', $event)"
      />
    </div>

    <div class="row card">
      <MergeList
        label="Tracks"
        :columns="trackColumns"
        :changes="changes"
        :candidates="candidates"
        path="tracks"
        id-path="recording.id"
        @setValue="$emit('setValue', $event)"
      />
    </div>
  </div>
</template>

<script>
import MergeText from './merge-text';
import MergeArray from './merge-array';
import MergeLocalCodes from './merge-local-codes';
import MergeList from './merge-list';

export default {
  name: 'MergePreviewAlbum',
  components: {
    MergeText,
    MergeArray,
    MergeLocalCodes,
    MergeList,
  },
  props: {
    changes: {
      type: Object,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      trackColumns: [
        { name: 'Recording', prop: 'name', link: '/recordings/{recording.id}' },
        { name: 'Version', prop: 'version_title' },
        { name: 'Main Artist', prop: 'main_artist.name', link: '/mainartists/{main_artist.id}' },
        { name: 'Side No', prop: 'recording.side_no' },
        { name: 'Track No', prop: 'recording.track_no' },
        { name: 'Duration', prop: 'duration_sec', filter: 'toMinutes' },
        { name: 'ISRC', prop: 'isrc' },
        { name: 'Status', prop: 'status', component: 'StatusIcon' },
      ],
    };
  },
  methods: {
    confirmMerge() {
      const paths = [
        'basic_info.name',
        'basic_info.release_date',
        'basic_info.label',
        'basic_info.catalog_number',
        'basic_info.tags',
        'basic_info.local_codes',
        'tracks',
      ];

      this.$emit('confirmMerge', paths);
    },
  },
};
</script>
