<template>
  <div>
    <select-input
      name="dateModified"
      label="Date Modified"
      item-key="code"
      item-value="name"
      :value="code"
      :items="entries"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from './select-input';

const ONE_DAYS = 1;
const THREE_DAYS = 3;
const SEVEN_DAYS = 7;
const THIRTY_DAYS = 30;
const NINETY_DAYS = 90;

export default {
  components: { SelectInput },
  props: {
    value: { type: Number, default: -1 },
  },
  data() {
    return {
      entries: [
        { code: ONE_DAYS, name: 'Last day' },
        { code: THREE_DAYS, name: 'Last 3 days' },
        { code: SEVEN_DAYS, name: 'Last 7 days' },
        { code: THIRTY_DAYS, name: 'Last 30 days' },
        { code: NINETY_DAYS, name: 'Last 90 days' },
      ],
      code: this.value,
    };
  },
  watch: {
    value() {
      this.code = this.value;
    },
  },
  methods: {
    onInput(value) {
      this.code = Number(value);
      let from = new Date();
      const to = new Date();
      switch (this.code) {
        case ONE_DAYS:
          from.setDate(to.getDate() - ONE_DAYS);
          break;
        case THREE_DAYS:
          from.setDate(to.getDate() - THREE_DAYS);
          break;
        case SEVEN_DAYS:
          from.setDate(to.getDate() - SEVEN_DAYS);
          break;
        case THIRTY_DAYS:
          from.setDate(to.getDate() - THIRTY_DAYS);
          break;
        case NINETY_DAYS:
          from.setDate(to.getDate() - NINETY_DAYS);
          break;
        default:
          from = null;
      }
      this.$emit('input', { from, to, code: this.code });
    },
  },
};
</script>
