<template>
  <div>
    <select-rejection-reason v-model="rejection.reason" class="push--bottom" />
    <a class="float-left" @click="showNote = true">
      <i class="fa fa-plus-square" />
      Add a note
    </a>
    <div v-if="showNote">
      <textarea v-model="rejection.note" :maxlength="rejectionNoteLimit" />
      <div class="text--grey">{{ rejectionNoteLength }}/{{ rejectionNoteLimit }}</div>
    </div>
    <div v-if="showButtons" class="float-right">
      <button class="btn tertiary" @click="onCancel">Cancel</button>
      <button
        class="btn red"
        :disabled="!rejection.reason || rejectionNoteLength > rejectionNoteLimit"
        @click="onReject"
      >
        Reject
      </button>
    </div>
  </div>
</template>

<script>
import selectRejectionReason from '../select/select-rejection-reason';

export default {
  name: 'RejectionDialog',
  components: {
    selectRejectionReason,
  },
  props: {
    rejectionNoteLimit: {
      type: Number,
      default: 1000,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    rejection: {
      type: Object,
      default: () => ({
        reason: null,
        note: null,
      }),
    },
  },
  data() {
    return {
      showNote: false,
    };
  },
  computed: {
    rejectionNoteLength() {
      return this.rejection.note ? this.rejection.note.length : 0;
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
    onCancel() {
      this.$emit('cancel');
    },
    onReject() {
      this.$emit('reject', this.rejection);
    },
  },
};
</script>
