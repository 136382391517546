<template>
  <div>
    <div class="row">
      <button :disabled="candidates.length <= 1" class="btn float-right" @click="confirmMerge">
        Confirm Merge
      </button>
    </div>

    <div class="row card">
      <MergeText
        label="Name"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.name"
        @setValue="$emit('setValue', $event)"
      />

      <MergeArray
        label="Alternate names"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.alternate_names"
        @setValue="$emit('setValue', $event)"
      />

      <MergeArray
        label="Years active"
        :changes="changes"
        :candidates="candidates"
        template="{from}-{to:present}"
        path="basic_info.active_period"
        @setValue="$emit('setValue', $event)"
      />

      <MergeText
        label="Origin Country"
        :changes="changes"
        :candidates="candidates"
        filter="formatCountry"
        path="basic_info.origin_country"
        @setValue="$emit('setValue', $event)"
      />

      <MergeText
        label="Origin City"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.origin_city"
        @setValue="$emit('setValue', $event)"
      />

      <MergeText
        label="Genre"
        :changes="changes"
        :candidates="candidates"
        filter="formatGenre"
        path="basic_info.genre"
        @setValue="$emit('setValue', $event)"
      />

      <MergeText
        label="Profile"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.profile_description"
        @setValue="$emit('setValue', $event)"
      />

      <MergeArray
        label="Tags"
        :changes="changes"
        :candidates="candidates"
        path="basic_info.tags"
        @setValue="$emit('setValue', $event)"
      />
    </div>

    <div class="row card">
      <MergeLocalCodes
        :changes="changes"
        :candidates="candidates"
        path="basic_info.local_codes"
        @setValue="$emit('setValue', $event)"
      />
    </div>

    <div class="row card">
      <MergeList
        label="Performers"
        :columns="performerColumns"
        :changes="changes"
        :candidates="candidates"
        path="performers"
        @setValue="$emit('setValue', $event)"
      />
    </div>

    <div class="row card">
      <MergeList
        label="Albums"
        :columns="albumColumns"
        :changes="changes"
        :candidates="candidates"
        :readonly="true"
        path="albums.albums"
        @setValue="$emit('setValue', $event)"
      />
    </div>

    <div class="row card">
      <MergeList
        label="Recordings"
        :columns="recordingColumns"
        :changes="changes"
        :candidates="candidates"
        :readonly="true"
        path="recordings.recordings"
        @setValue="$emit('setValue', $event)"
      />
    </div>
  </div>
</template>

<script>
import MergeText from './merge-text';
import MergeArray from './merge-array';
import MergeLocalCodes from './merge-local-codes';
import MergeList from './merge-list';

export default {
  name: 'MergePreviewMainartist',
  components: {
    MergeText,
    MergeArray,
    MergeLocalCodes,
    MergeList,
  },
  props: {
    changes: {
      type: Object,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      performerColumns: [
        { name: 'Name', prop: 'name', link: '/performers/{id}' },
        { name: 'Pseudo', prop: 'pseudo_name' },
        { name: 'Instrument', prop: 'instrument', filter: 'formatInstrument' },
        { name: 'Nationality', prop: 'nationality', filter: 'formatCountry' },
        { name: 'Acive', prop: 'active_period', template: '{from}-{to:present}' },
        { name: 'IPN', prop: 'ipn' },
        { name: 'Society', prop: 'mandates', template: '{society_code}', filter: 'formatSociety' },
      ],
      albumColumns: [
        { name: 'Album', prop: 'name', link: '/albums/{id}' },
        { name: 'Catalogue No.', prop: 'catalog_number' },
        { name: 'Release date', prop: 'release_date' },
        { name: 'Label', prop: 'label' },
      ],
      recordingColumns: [
        { name: 'Recording', prop: 'name', link: '/recordings/{id}' },
        { name: 'Version', prop: 'version_title' },
        { name: 'Duration', prop: 'duration_sec', filter: 'toMinutes' },
        { name: 'ISRC', prop: 'isrc' },
      ],
    };
  },
  methods: {
    confirmMerge() {
      const paths = [
        'basic_info.name',
        'basic_info.alternate_names',
        'basic_info.active_period',
        'basic_info.origin_country',
        'basic_info.origin_city',
        'basic_info.genre',
        'basic_info.profile_description',
        'basic_info.tags',
        'basic_info.local_codes',
        'performers',
      ];

      this.$emit('confirmMerge', paths);
    },
  },
};
</script>
