<template>
  <input
    :value="value"
    :style="{ cursor: cursor }"
    class="clearable-input"
    @input="onInput"
    @click="onClick"
    @blur="$emit('blur', $event)"
    @keydown="$emit('keydown', $event)"
    @mousemove="onMouseMove"
  />
</template>

<script>
export default {
  name: 'ClearableInput',
  props: {
    value: String,
  },
  data() {
    return {
      cursor: 'text',
    };
  },
  methods: {
    isHoveringButton(event) {
      const CIRCLE_MARGIN = 4;
      const CIRCLE_DIAMETER = 16;
      const CIRCLE_RADIUS = CIRCLE_DIAMETER / 2;

      const rect = this.$el.getBoundingClientRect();

      const x = event.clientX - rect.left - this.$el.offsetWidth + CIRCLE_MARGIN + CIRCLE_RADIUS;
      const y = event.clientY - rect.top - this.$el.offsetHeight / 2;

      return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)) <= CIRCLE_RADIUS;
    },
    onClick(event) {
      if (this.isHoveringButton(event)) {
        this.$emit('input', '');
        event.stopPropagation();
      } else {
        this.$emit('click', event);
      }
    },
    onMouseMove(event) {
      this.cursor = this.isHoveringButton(event) ? 'default' : 'text';
    },
    onInput() {
      this.$emit('input', this.$el.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.clearable-input {
  box-sizing: border-box;
  background-image: url('/public/clear-input-btn.svg');
  background-size: 20px; /* CIRCLE_DIAMETER */
  background-repeat: no-repeat;
  background-position-x: right; /* CIRCLE_MARGIN */
  background-position-y: center;
}
</style>
