<template>
  <div>
    <div class="row">
      <div class="col s12 l1 hide-on-med-and-down">
        <entity-tag />
      </div>
      <div class="col s12 l11 view-layout">
        <div class="row">
          <div class="col s8 l10 offset-s2 offset-l1">
            <bullet-progress
              :progression-steps="progressionSteps"
              :current-progress="currentProgress"
            />
          </div>
        </div>
        <template v-if="loading">
          <spinner />
        </template>

        <template v-if="!reserveRelease">
          <div class="row">
            <div class="col s12">
              <h1>New Release</h1>
            </div>
          </div>
          <progress-release-of-reserves-create @onSubmit="createNewRelease" />
        </template>

        <template v-else-if="isWorking">
          <div class="row">
            <div class="col s12">
              <h1>Processing Release</h1>
            </div>
          </div>
          <div class="row card">
            <div class="class col s12">The system is currently computing the release.</div>
          </div>
        </template>

        <template v-else-if="isPendingConfirmation">
          <div class="row">
            <div class="col s12">
              <h1>Verify Information</h1>
            </div>
          </div>
          <progress-verify-information
            :reserve-release="reserveRelease"
            @onSubmit="confirmRelease"
          />
        </template>

        <template v-if="isFinished">
          <div class="row">
            <div class="col s12">
              <h1>
                Release Completed
                <span class="text--green">✔︎</span>
              </h1>
            </div>
          </div>
          <progress-release-of-reserves-confirmed :reserve-release="reserveRelease" />
        </template>

        <template v-else-if="isFailed">
          <div class="row">
            <div class="col s12">
              <h1>
                Release Failed
                <span class="fas fa-check text--green" />
              </h1>
            </div>
          </div>
          <progress-release-of-reserves-confirmed :reserve-release="reserveRelease" />
        </template>
        <!--
        <div class="row">
            <div class="col s12">
            <h1>Release Failed</h1>
          </div>
        </div>
        <progress-release-of-reserves-error
          :reserve-release="reserveRelease"
        />
      </div>
      <div class="col s12 l11 view-layout" v-else-if="currentProgress">
        <div class="row">
          <div class="col s8 l10 offset-s2 offset-l1">
            <bullet-progress
              :currentProgress="currentProgress"
              :progressionSteps="progressionSteps"
            />
          </div>
        </div>
        <template v-if="loading">
          <spinner/>
        </template>
        <template v-else>
          <div class="row">
            <div class="col s12">
              <h1>
                {{ currentProgress.label }}
                <span v-if="currentProgress.name === 'finished'" class="text--green">✔︎</span>
              </h1>
            </div>
          </div>
          <progress-release-of-reserves-create
            v-if="currentProgress.name === 'create'"
          />
          <progress-verify-information
            v-if="currentProgress.name === 'verify' && reserveRelease"
            :reserve-release="reserveRelease"
          />
          <progress-release-of-reserves-confirmed
            v-if="currentProgress.name === 'finished'"
            :reserve-release="reserveRelease"
          />
        </template>
-->
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'lodash';
import { reserveReleaseState } from '../../domain/releaseOfReservesDomain';
import EntityTag from '../ui/entity-tag';
import BulletProgress from '../ui/bullet-progress';
import ReserveService from '../../services/reserveService';
import ProgressReleaseOfReservesConfirmed from './progress-release-of-reserves-confirmed';
import ProgressVerifyInformation from './progress-verify-information';
import ProgressReleaseOfReservesCreate from './progress-release-of-reserves-create';
import { STATE } from '../../store/modules/process/store';
import Spinner from '../spinner';

export default {
  name: 'ReleaseOfReservesWizardMain',
  components: {
    EntityTag,
    BulletProgress,
    ProgressReleaseOfReservesConfirmed,
    ProgressVerifyInformation,
    ProgressReleaseOfReservesCreate,
    Spinner,
  },
  async beforeRouteUpdate(to, from, next) {
    this.onRoute(to.query);
    next();
  },
  data() {
    return {
      progressionSteps: [
        {
          name: 'create',
          label: 'New Release',
        },
        {
          name: 'verify',
          label: 'Verify Information',
        },
        {
          name: 'finished',
          label: 'Release Completed',
        },
      ],
      reserveRelease: undefined,
      loading: false,
    };
  },
  computed: {
    state() {
      return this.reserveRelease && this.reserveRelease.header.state;
    },
    isFailed() {
      return this.state === reserveReleaseState.ERROR;
    },
    isCreated() {
      return this.state === reserveReleaseState.NEW;
    },
    isWorking() {
      return this.state === reserveReleaseState.WORKING;
    },
    isPendingConfirmation() {
      return this.state === reserveReleaseState.PENDING_CONFIRMATION;
    },
    isFinished() {
      return this.state === reserveReleaseState.FINISHED;
    },
    currentProgress() {
      if (this.isPendingConfirmation) {
        return this.progressionSteps.find((s) => s.name === 'verify');
      }
      if (this.isFinished) {
        return this.progressionSteps.find((s) => s.name === 'finished');
      }
      return this.progressionSteps.find((s) => s.name === 'create');
    },
  },
  async created() {
    if (this.$route.query.id) {
      this.loading = true;
      await this.fetchData(this.$route.query);
      this.loading = false;
    }
  },
  methods: {
    async fetchData(routeParams) {
      const query = pick(routeParams, 'id');
      try {
        this.reserveRelease = await ReserveService.getReserveRelease(query.id);
        return true;
      } catch (err) {
        this.$addStarError(err);
        return false;
      }
    },
    toHistoryView() {
      this.$router.push('/reserves/history');
    },
    async createNewRelease(year) {
      try {
        this.loading = true;
        const id = await ReserveService.createReserveRelease(year);
        const result = await ReserveService.calculateReserveRelease(id);
        this.$store.dispatch('process/addProcess', {
          name: 'Reserve Release',
          id: result.processId,
          route: `/reserves?id=${id}`,
          routeAvailability: [STATE.RUNNING, STATE.FINISHED],
        });
        if (id) {
          setTimeout(() => {
            // let the backend change state of the release before routing back tyo history view
            this.loading = false;
            this.$router.push({
              path: '/reserves/history',
            });
          }, 2000);
        }
      } catch (e) {
        this.loading = false;
        this.$addStarError(e);
      }
    },
    async confirmRelease() {
      try {
        this.loading = true;
        const id = this.reserveRelease.header.id;
        const result = await ReserveService.confirmReserveRelease(id);
        this.$store.dispatch('process/addProcess', {
          name: 'Reserve Release',
          id: result.processId,
          route: `/reserves?id=${id}`,
          routeAvailability: [STATE.RUNNING, STATE.FINISHED],
        });
        setTimeout(() => {
          // let the backend change state of the release before routing back tyo history view
          this.loading = false;
          this.$router.push({
            path: '/reserves/history',
          });
        }, 2000);
      } catch (err) {
        this.loading = false;
        this.$addStarError(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 span {
  font-size: 14px;
}
</style>
