/**
 * Helper service for edit recording tasks; such as update basic info and lineups.
 */
import delay from '../../../common/delay';
import RecordingService from '../../../services/recordingService';
import { getAggregate, dispatch } from '../../../store/modules/recording/utils';
import clone from '../../../common/clone';
import { createPerformerReference } from '../../../domain/recordingDomain';
import { createNamedRelation } from '../../../domain/common';

/**
 * Creates a new named relation from the provided data and makes sure its correctly structured for transfer.
 */
function normalizeNamedRelation(namedRelation) {
  return createNamedRelation(namedRelation.id, namedRelation.name, namedRelation.type);
}

/**
 * Creates a new lineup relation from the provided data and makes sure its correctly structured for transfer.
 */
function normalizeLineup(performerReferences) {
  return performerReferences.map((performerReference) => {
    const performerRelation = normalizeNamedRelation(performerReference.relation);

    return createPerformerReference(
      performerRelation,
      performerReference.instruments,
      performerReference.role,
      performerReference.pseudo_name,
    );
  });
}

async function update(updateFunction, recordingAggregate) {
  await updateFunction(recordingAggregate);
  await delay(1000);
  const updatedAggregate = await RecordingService.getRecording(recordingAggregate.id);
  dispatch('setRecordingAggregate', updatedAggregate);
  return clone(updatedAggregate);
}

async function updateBasicInfo(recordingAggregate) {
  recordingAggregate.basic_info.main_artist = normalizeNamedRelation(
    recordingAggregate.basic_info.main_artist,
  );
  const res = await update(RecordingService.updateRecordingBasicInfo, recordingAggregate);
  return res;
}

async function updateLineup(recordingAggregate) {
  recordingAggregate.lineup = normalizeLineup(recordingAggregate.lineup);
  const res = await update(RecordingService.updateRecordingLineup, recordingAggregate);
  return res;
}

export default {
  getCurrentRecording: () => clone(getAggregate('recording')),
  updateBasicInfo: (recording) => updateBasicInfo(clone(recording)),
  updateLineup: (recording) => updateLineup(clone(recording)),
};
