<template>
  <form :data-vv-scope="scope">
    <div class="row card">
      <delete-button :is-component="true" @click="removePerformer" />
      <div class="row">
        <div class="col s8">
          <validated-text-input
            ref="firstName"
            name="first_name"
            :scope="scope"
            label="First name"
            :mandatory="true"
            placeholder="E.g. Joakim"
            :value="currentPerformer.first_name"
            :rule="nameRules"
            @input="updateFirstName"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <validated-text-input
            ref="lastName"
            name="last_name"
            :scope="scope"
            label="Last name"
            :mandatory="true"
            placeholder="E.g. Berg"
            :value="currentPerformer.last_name"
            :rule="nameRules"
            @input="updateLastName"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <tag-input
            ref="pseudoNames"
            name="pseudo_names"
            :scope="scope"
            label="Pseudo names"
            :mandatory="true"
            placeholder="E.g. Jocke Berg"
            :value-list="currentPerformer.pseudo_names"
            :rule="pseudoNameRules"
            @add="addPseudoName"
            @remove="removePseudoName"
          />
        </div>
      </div>

      <div class="row flush--bottom">
        <div class="col s4">
          <select-instrument
            :scope="scope"
            :addable="true"
            :value="currentPerformer.instruments"
            @input="updateInstruments"
          />
        </div>

        <div class="col s4">
          <select-role-code
            name="role"
            rule="required"
            :show-label="true"
            :mandatory="true"
            :value="currentPerformer.role"
            :scope="scope"
            @input="updateRole"
          />
        </div>
        <div class="col s4">
          <select-country
            label="Country of residence"
            rule="required"
            :mandatory="true"
            :scope="scope"
            :value="currentPerformer.country_of_residence"
            @input="updateCountryOfResidence"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DeleteButton from '../../ui/button/delete-button';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import TagInput from '../../ui/tag/tag-input';
import SelectCountry from '../../ui/select/select-country';
import SelectRoleCode from '../../ui/select/select-role-code';
import SelectInstrument from '../../ui/select/select-instrument';

export default {
  name: 'CreateNewPerformer',
  components: {
    SelectInstrument,
    DeleteButton,
    ValidatedTextInput,
    SelectRoleCode,
    TagInput,
    SelectCountry,
  },
  inject: ['$validator'],
  props: {
    performer: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentPerformer: Object.assign({}, this.performer),
    };
  },
  computed: {
    nameRules() {
      if (this.currentPerformer.pseudo_names.length > 0) {
        return { required: false };
      }
      return { required: true };
    },
    pseudoNameRules() {
      if (this.currentPerformer.first_name && this.currentPerformer.last_name) {
        return { required: false };
      }
      return { required: true };
    },
  },
  methods: {
    emitUpdate() {
      this.$emit('input', this.currentPerformer);
    },
    addPseudoName(pseudoName) {
      this.currentPerformer.pseudo_names.push(pseudoName);
      this.emitUpdate();
    },
    removePseudoName(idx) {
      this.currentPerformer.pseudo_names.splice(idx, 1);
      this.emitUpdate();
    },
    updateFirstName(value) {
      this.currentPerformer.first_name = value;
      this.emitUpdate();
    },
    updateLastName(value) {
      this.currentPerformer.last_name = value;
      this.emitUpdate();
    },
    updateInstruments(value) {
      this.currentPerformer.instruments = value;
      this.emitUpdate();
    },
    updateRole(value) {
      this.currentPerformer.role = value;
      this.emitUpdate();
    },
    updateCountryOfResidence(value) {
      this.currentPerformer.country_of_residence = value;
      this.emitUpdate();
    },
    removePerformer() {
      this.$emit('remove');
    },
  },
};
</script>
