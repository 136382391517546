import Vue from 'vue';
import prepareForWire from './wirePrepper';

export default async function post(url, payload, options = {}) {
  const result = await Vue.$http.post(url, prepareForWire(payload));
  if (!options.pickProp) {
    return result.data;
  }
  return result.data[options.pickProp];
}
