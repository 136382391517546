<template>
  <div class="compare__action-btns">
    <div class="compare__buttons">
      <div class="push--bottom">
        <button :disabled="acceptAllDisabled" class="btn green" @click="acceptAll">
          Accept all
        </button>
        <button class="btn red" @click="showRejectionDialog = true">Reject all</button>
      </div>
      <rejection-dialog
        v-if="showRejectionDialog"
        @cancel="showRejectionDialog = false"
        @reject="rejectAll"
      />
    </div>
  </div>
</template>

<script>
import RejectionDialog from '../ui/dialog/rejection-dialog';

export default {
  name: 'CompareButtons',
  components: {
    RejectionDialog,
  },
  props: {
    acceptAllDisabled: Boolean,
  },
  data() {
    return {
      rejectionReason: null,
      showRejectionDialog: false,
    };
  },
  methods: {
    acceptAll() {
      this.$emit('accept');
    },
    rejectAll(e) {
      this.$emit('reject', e);
    },
  },
};
</script>
