import { generateMutations, generateGetters } from '../../utils';
import {
  createBasicInfo,
  createSocietyAggregate,
  createMetaInfo,
} from '../../../domain/societyDomain';
import { createContactInfo } from '../../../domain/common';
import { createPaymentInfo } from '../../../domain/commonPayment';

// The root, initial state object
const rootState = createSocietyAggregate();

const actions = {
  createAggregate(context) {
    context.commit('CREATE_AGGREGATE');
  },
  setAggregate(context, associateAggregate) {
    context.commit('SET_AGGREGATE', associateAggregate);
  },
};

const mutations = {
  ...generateMutations(rootState),
  CREATE_AGGREGATE(state) {
    state.id = null;
    state.version = null;
    state.stream_id = null;
    state.process_id = null;
    state.type = null;
    state.basic_info = createBasicInfo();
    state.contact_info = createContactInfo();
    state.payment_info = createPaymentInfo();
    state.meta_info = createMetaInfo();
    state.agreements = [];
    state.documents = [];
  },
  SET_AGGREGATE(state, aggregate) {
    state.id = aggregate.id;
    state.version = aggregate.version;
    state.stream_id = aggregate.stream_id;
    state.process_id = aggregate.process_id;
    state.type = aggregate.type;
    state.basic_info = aggregate.basic_info;
    state.contact_info = aggregate.contact_info;
    state.payment_info = aggregate.payment_info;
    state.meta_info = aggregate.meta_info;
    state.agreements = aggregate.agreements;
    state.role_codes = aggregate.role_codes;
    state.documents = aggregate.documents;
  },
  addForeignAccount(state, account) {
    state.payment_info.foreign_account = account;
  },
  resetPaymentInfo(state) {
    state.payment_info = createPaymentInfo();
  },
  addAddress(state, address) {
    state.contact_info.address = address;
  },
  addEmail(state, email) {
    state.contact_info.emails.push(email);
  },
  addPhoneNumber(state, phoneNumber) {
    state.contact_info.phone_numbers.push(phoneNumber);
  },
  replaceEmailAt(state, replacer) {
    state.contact_info.emails.splice(replacer.index, 1, replacer.value);
  },
  removeEmailAt(state, idx) {
    state.contact_info.emails.splice(idx, 1);
  },
  removePhoneNumberAt(state, idx) {
    state.contact_info.phone_numbers.splice(idx, 1);
  },
  updateDocuments(state, documents) {
    state.documents = documents;
  },
};

const getters = {
  ...generateGetters(rootState),
  aggregate: (state) => state,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
