<template>
  <div class="search-result">
    <h1>Search</h1>
    <div class="tabs row">
      <div class="tabs__wrapper">
        <router-link :to="'/search/everything/' + query.replace(/\//g, '%2F')">
          Everything
        </router-link>
        <router-link :to="'/search/recordings/' + query.replace(/\//g, '%2F')">
          Recordings
        </router-link>
        <router-link :to="'/search/albums/' + query.replace(/\//g, '%2F')">Albums</router-link>
        <router-link :to="'/search/performers/' + query.replace(/\//g, '%2F')">
          Performers
        </router-link>
        <router-link :to="'/search/mainartists/' + query.replace(/\//g, '%2F')">
          Main Artists
        </router-link>
        <router-link :to="'/search/associates/' + query.replace(/\//g, '%2F')">
          Associates
        </router-link>
        <router-link :to="'/search/societies/' + query.replace(/\//g, '%2F')">
          Societies
        </router-link>
      </div>
    </div>

    <router-view @updateIsExecuted="onUpdateIsExecuted" />
  </div>
</template>

<script>
export default {
  name: 'AdvancedSearch',
  components: {},
  data() {
    return {
      query: '',
      isExecuted: false,
    };
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.setQuery();
      }, 500);
    },
  },
  created() {
    this.setQuery();
  },
  methods: {
    setQuery: function setQuery() {
      this.isExecuted = this.$router.currentRoute.query.i !== undefined;
      const encodedQuery = this.queryExists()
        ? encodeURI(this.$router.currentRoute.params.query)
        : '';
      this.query = this.isExecuted
        ? `${encodedQuery}?i=${new Date().getTime()}`
        : `${encodedQuery}`;
    },
    onUpdateIsExecuted(isExecuted) {
      this.isExecuted = isExecuted;
    },
    queryExists() {
      return (
        this.$router.currentRoute.params !== undefined &&
        this.$router.currentRoute.params.query !== undefined
      );
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 24px;
}
</style>
