<template>
  <form class="pos-relative" :data-vv-scope="scope" @keyup.esc="cancel">
    <div v-if="editMode" class="row">
      <div class="col s3">
        <h2>Tags</h2>
      </div>
      <div class="col s9 text--right">
        <action-buttons @save="update" @cancel="cancel" />
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <searchable-tag-input
          :name="`${entityType}-tags`"
          label="Tags"
          placeholder="E.g. Top 100"
          :preview-list="previewTags"
          :value-list="localTags"
          :searcher="searchTag"
          use-template="tag"
          :scope="scope"
          @add="addTag"
          @remove="removeTagAt"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import ActionButtons from '../ui/button/action-buttons';
import SearchHelper from '../search/searchHelper';

export default {
  name: 'CreateTags',
  components: {
    SearchableTagInput,
    ActionButtons,
  },
  inject: ['$validator'],
  props: {
    scope: { type: String, default: '' },
    editMode: { type: Boolean, default: false },
    entityType: { type: String, required: true },
    path: { type: String, default: '' },
    addMethod: { type: String, required: true },
    removeMethod: { type: String, required: true },
  },
  data() {
    return {
      localTags: [],
      allTags: [],
    };
  },
  computed: mapState({
    tags() {
      return this.$store.state[this.entityType][this.path].tags;
    },
    previewTags() {
      return this.allTags.filter((tag) =>
        this.localTags.length > 0
          ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
          : true,
      );
    },
  }),
  async created() {
    this.localTags = this.copyTags(this.tags.slice());
    this.allTags = await SearchHelper.getAllTags();
  },
  methods: {
    ...mapMutations({
      addTagToModel(commit, payload) {
        return commit(`${this.entityType}/${this.addMethod}`, payload);
      },
      removeFromModel(commit, payload) {
        return commit(`${this.entityType}/${this.removeMethod}`, payload);
      },
    }),
    copyTags(tags) {
      return tags.map((tag) => ({ name: tag, type: 'tag' }));
    },
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.localTags = event.data;
      this.addTagToModel(event.item.name);
    },
    removeTagAt(event) {
      this.removeFromModel(event.idx, 1);
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    update() {
      this.$emit('update', this.$options.name);
    },
  },
};
</script>
