<template>
  <div>
    <select-input
      :items="associateTypes"
      label="Type"
      name="associateTypes"
      :mandatory="mandatory"
      :value="value"
      item-key="key"
      :rule="rule"
      item-value="value"
      @input="onInput"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { AssociateType } from '../../../domain/associateDomain';
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectAssociateType',
  components: { SelectInput },
  props: {
    value: String,
    rule: {
      type: [String, Object],
      default: '',
    },
    associateTypes: {
      type: Array,
      default: () =>
        Object.keys(AssociateType).map((oKey) => ({
          key: oKey,
          value: Vue.$i18n.t(`common.${AssociateType[oKey].toLowerCase()}`),
        })),
    },
    mandatory: { type: Boolean, default: false },
    anyType: { type: Boolean, default: false },
  },
  created() {
    if (this.anyType) {
      this.associateTypes.unshift({ key: '', value: Vue.$i18n.t('common.any') });
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>
