import gql from 'graphql-tag';

const distributionClosureHeader = `
  distributionClosureId
  year
  totalAmount
  createdDate
  createdBy
  confirmedBy
  confirmedDate
  state
  workerProcessId
`;

const distributionClosureRow = `
  type
  performanceYear
  totalNetAmount
`;

const calculateDistributionClosureResponse = `
  id
  processId
`;

const approveDistributionClosureResponse = `
  id
  processId
`;

const confirmDistributionClosureResponse = `
  id
  processId
`;

const getRemuneration = gql`
  query remuneration($idTerm: String!) {
    remuneration(id: $idTerm) {
      year
      original_amount
      fee
      gross_amount
      vat
      tax
      net_amount
      balance
    }
  }
`;

const getLedgerDetails = gql`
  query ledgerDetails($ledgerDetailRequest: LedgerDetailRequest) {
    ledgerDetails(ledgerDetailRequest: $ledgerDetailRequest) {
      rows {
        createdAt
        txText
        txType
        refId
        year
        areaId
        fee
        tax
        net
        entryType
        ledgerRef {
          id
          name
          type
        }
        balance
        pending
      }
    }
  }
`;

const getCurrentLedgerVersion = gql`
  query ledgerDetails($ledgerDetailRequest: LedgerDetailRequest) {
    ledgerDetails(ledgerDetailRequest: $ledgerDetailRequest) {
      version
    }
  }
`;

const getPaymentHistory = gql`
  query paymentHistory($paymentHistoryRequest: PaymentHistoryRequest) {
    paymentHistory(request: $paymentHistoryRequest) {
      count
      totalCount
      items {
        paymentId
        paymentDate
        total
        refunded
        refundDate
        refundedAmount
        refundedBy
        invoiceKey
        invoiceId
        creditInvoiceKey
        creditInvoiceId
      }
    }
  }
`;

const getUniqueErrorTypes = gql`
  query getUniqueErrorTypes($paymentId: String!) {
    getUniqueErrorTypes(paymentId: $paymentId)
  }
`;

const getSummaryArea = gql`
  query summaryArea($idTerm: String!, $yearTerm: String!) {
    summaryArea(id: $idTerm, year: $yearTerm) {
      areaName
      areaId
      right
      type
      sources
      fee
      tax
      net
      balance
    }
  }
`;

const getRemunerationBalance = gql`
  query remunerationBalance($idTerm: String!) {
    remunerationBalance(id: $idTerm) {
      year
      net
      tax
      fee
      balance
    }
  }
`;

const getLedgerBalance = gql`
  query ledgerBalance($idTerm: String!) {
    ledgerBalance(id: $idTerm)
  }
`;

const getTrackBreakdown = gql`
  query trackBreakdown($request: TrackBreakdownRequest!) {
    trackBreakdown(request: $request) {
      count
      totalNet
      totalTax
      totalFee
      fromDate
      toDate
      fromYear
      toYear
      sourceNames
      areaIds
      items {
        id
        right
        createdAt
        distributionType
        areaId
        areaName
        year
        sourceId
        sourceName
        net
        title
        recordingId
        versionTitle
        programTitle
        mainArtist
      }
    }
  }
`;

const getAllPayments = gql`
  query getAllPayments($request: PaymentsRequest) {
    getAllPayments(request: $request) {
      total_count
      headers {
        id
        name
        societyId
        paymentDate
        createdBy
        state
        totalNet
        totalVat
        payableNet
        payableVat
        numPerformers
        numPayees
        createdAt
        confirmUser
      }
    }
  }
`;

const getManualPayments = gql`
  query getManualPayments {
    getManualPayments {
      payees {
        ledger_id
        name
        mandate_societies
      }
    }
  }
`;

const addManualPayments = gql`
  mutation addManualPayments($ids: [String]!) {
    addManualPayments(ids: $ids)
  }
`;

const removeManualPayments = gql`
  mutation removeManualPayments($ids: [String]!) {
    removeManualPayments(ids: $ids)
  }
`;

const getPayment = gql`
  query getPayment($request: PaymentRequest!) {
    getPayment(request: $request) {
      header {
        id
        name
        societyId
        paymentDate
        minLimit
        createdBy
        state
        totalNet
        totalVat
        payableNet
        payableVat
        numPerformers
        numPayees
        createdAt
        confirmUser
      }
      stats {
        state
        totalCount
        totalNet
        payeeCount
        payeeNet
      }
      rows {
        id
        nodeId
        name
        nodeType
        state
        issue
        net
        vat
        isPayee
      }
      files {
        type
        name
        key
      }
    }
  }
`;

const getPaymentDetails = gql`
  query paymentDetails($nodeId: String!, $rowId: String!) {
    paymentDetails(nodeId: $nodeId, rowId: $rowId) {
      paymentDate
      rowId
      net
      fee
      tax
      vat
      vatBase
      refundable
      refunded
      refundDate
      refundReason
      refundNote
      invoiceKey
      invoiceId
      creditInvoiceKey
      creditInvoiceId
      graphId
      remunerations {
        year
        sourceId
        areaId
        right
        territory
        fee
        tax
        net
      }
    }
  }
`;

const getPaymentNodesQuery = gql`
  query getPaymentNodes($nodeIds: [String]) {
    getPaymentNodes(nodeIds: $nodeIds) {
      id
      version
      name
    }
  }
`;

const getDistributionClosurePredictionHeaderRowCounts = gql`
  query getDistributionClosurePredictionHeaderRowCounts($year: Int!) {
    getDistributionClosurePredictionHeaderRowCounts(year: $year) {
      calculatedRowCount
      exceptionRowCount
    }
  }
`;

const getDistributionClosurePredictionHeaderAmounts = gql`
  query getDistributionClosurePredictionHeaderAmounts($year: Int!) {
    getDistributionClosurePredictionHeaderAmounts(year: $year) {
      calculatedTotalAmount
      exceptionTotalAmount
    }
  }
`;

const getDistributionClosurePrediction = gql`
  query getDistributionClosurePrediction($command: DistributionClosurePredictionCommand!) {
    getDistributionClosurePrediction(command: $command) {
      rows {
        ledgerId
        name
        type
        performanceYear
        amount
      }
    }
  }
`;

const getActiveMandates = gql`
  query getActiveMandates($performerId: String!) {
    getActiveMandates(performerId: $performerId) {
      societyId
      performerId
      type
      right
      territory
    }
  }
`;

const createPayment = gql`
  mutation createPayment($command: CreatePaymentCommand!) {
    createPayment(command: $command) {
      paymentId
    }
  }
`;

const deletePayment = gql`
  mutation deletePayment($idTerm: String!) {
    deletePayment(id: $idTerm)
  }
`;

const createOutputs = gql`
  mutation createOutputs($command: CreateOutputsCommand!) {
    createOutputs(command: $command) {
      processId
    }
  }
`;

const confirmPayment = gql`
  mutation confirmPayment($idTerm: String!) {
    confirmPayment(id: $idTerm)
  }
`;

const approvePayment = gql`
  mutation approvePayment($idTerm: String!) {
    approvePayment(id: $idTerm)
  }
`;

const processPayment = gql`
  mutation processPayment($paymentId: String!, $pid: String!, $nodeIds: [String]) {
    processPayment(paymentId: $paymentId, pid: $pid, nodeIds: $nodeIds) {
      processId
    }
  }
`;

const movePaymentRows = gql`
  mutation movePaymentRows($command: MovePaymentRowsCommand!) {
    movePaymentRows(command: $command)
  }
`;

const skipTransferRow = gql`
  mutation skipTransferRow($paymentId: String!, $transferId: Int!) {
    skipTransferRow(paymentId: $paymentId, transferId: $transferId)
  }
`;

const unskipTransferRow = gql`
  mutation unskipTransferRow($paymentId: String!, $transferId: Int!) {
    unskipTransferRow(paymentId: $paymentId, transferId: $transferId)
  }
`;

const refund = gql`
  mutation createRefund($command: CreateRefundCommand!) {
    createRefund(command: $command)
  }
`;

const approveDistributionClosure = gql`
  mutation approveDistributionClosure($command: ApproveDistributionClosureCommand!) {
    approveDistributionClosure(command: $command) {
      ${approveDistributionClosureResponse}
    }
  }
`;

const confirmDistributionClosure = gql`
  mutation confirmDistributionClosure($command: ConfirmDistributionClosureCommand!) {
    confirmDistributionClosure(command: $command) {
      ${confirmDistributionClosureResponse}
    }
  }
`;

const createDistributionClosure = gql`
  mutation createDistributionClosure($command: CreateDistributionClosureCommand!) {
    createDistributionClosure(command: $command)
  }
`;

const calculateDistributionClosure = gql`
  mutation calculateDistributionClosure($command: CalculateDistributionClosureCommand!) {
    calculateDistributionClosure(command: $command) {
      ${calculateDistributionClosureResponse}
    }
  }
`;

const getActiveDistributionClosure = gql`
  query getActiveDistributionClosure {
    getActiveDistributionClosure {
      distributionClosureId
    }
  }
`;

const getDistributionClosure = gql`
   query getDistributionClosure($id: String!) {
    getDistributionClosure(id: $id) {
      header {
        ${distributionClosureHeader}
      }
      rows {
        ${distributionClosureRow}
      }
    }
  }
`;

const getDistributionClosureDetails = gql`
  query getDistributionClosureDetails($request: DistributionClosureDetailsRequest) {
    getDistributionClosureDetails(request: $request) {
      total {
        calculated
        exception
      }
      amount {
        calculated
        exception
      }
      rows {
        streamId
        performanceYear
        name
        type
        amount
      }
    }
  }
`;

const getDistributionClosureLastEvent = gql`
  query getDistributionClosureLastEvent($id: String!) {
    getDistributionClosureLastEvent(id: $id) {
      lastEventSequenceNumber
    }
  }
`;

const getDistributionClosureHeaders = gql`
   query getDistributionClosureHeaders {
    getDistributionClosureHeaders {
      ${distributionClosureHeader}
    }
  }
`;

const deleteDistributionClosure = gql`
  mutation deleteDistributionClosure($id: String!) {
    deleteDistributionClosure(id: $id)
  }
`;

const testCreateRenumerations = gql`
  mutation testCreateRemunerations($remunerations: [CreateRemuneration]!) {
    testCreateRemunerations(remunerations: $remunerations)
  }
`;

const testReset = gql`
  mutation testReset($input: String) {
    testReset(input: $input)
  }
`;

export default {
  getRemuneration,
  getLedgerDetails,
  getCurrentLedgerVersion,
  getSummaryArea,
  getRemunerationBalance,
  getAllPayments,
  getPaymentHistory,
  getPaymentDetails,
  getPayment,
  getManualPayments,
  getUniqueErrorTypes,
  addManualPayments,
  removeManualPayments,
  createPayment,
  deletePayment,
  createOutputs,
  confirmPayment,
  approvePayment,
  processPayment,
  movePaymentRows,
  refund,
  getDistributionClosurePredictionHeaderRowCounts,
  getDistributionClosurePredictionHeaderAmounts,
  getDistributionClosurePrediction,
  getDistributionClosureHeaders,
  createDistributionClosure,
  calculateDistributionClosure,
  getActiveDistributionClosure,
  getDistributionClosure,
  getDistributionClosureDetails,
  getDistributionClosureLastEvent,
  deleteDistributionClosure,
  approveDistributionClosure,
  confirmDistributionClosure,
  skipTransferRow,
  unskipTransferRow,
  getActiveMandates,
  getLedgerBalance,
  getTrackBreakdown,
  getPaymentNodesQuery,

  testCreateRenumerations,
  testReset,
};
