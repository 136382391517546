<template>
  <div>
    <div v-if="loading">
      <Spinner />
    </div>

    <div class="row wrapper">
      <div class="col s12">
        <h1>IPD Downloaded Right Holders</h1>
      </div>
    </div>

    <div class="row">
      <TableOptions
        :bulk-action-enabled="false"
        :custom-select="statusSelect"
        :range="{ start: rangeStart, end: rangeEnd }"
        @rangeStartChanged="setRangeStart"
        @rangeEndChanged="setRangeEnd"
        @customSelectChanged="setExtras({ status: $event })"
      />
    </div>

    <div class="row card report__headers">
      <table>
        <SortableHead
          :columns="columns"
          :expanded-content="false"
          :more-options="false"
          :bulk-enabled="false"
        />
        <tbody>
          <tr v-for="rightHolder in rightHolders" :key="rightHolder.stream_id">
            <td>
              <router-link :to="`/performers/${rightHolder.stream_id}`">
                {{ rightHolder.name }}
              </router-link>
            </td>
            <td>
              <span class="chip float-left" :class="getStatusClass(rightHolder.status)">
                {{ rightHolder.status }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="result__pagination">
        <Pagination
          v-if="numberOfPages() > 0"
          :number-of-pages="numberOfPages()"
          :selected-page="page"
          :number-of-hits="itemCount"
          :hits-per-page="pageSize"
          @selectPage="selectPage"
          @updateHitsPerPage="selectPageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaginationQueryParamMixin from '../../common/paginationQueryParamMixin';
import Spinner from '../spinner';
import IpdService from '../../services/ipdService';
import SortableHead from '../ui/table/sortable-head';
import Pagination from '../pagination';
import TableOptions from '../ui/table/table-options';

export default {
  name: 'ViewIpdDownloadRightHolders',
  components: {
    Spinner,
    SortableHead,
    Pagination,
    TableOptions,
  },
  mixins: [PaginationQueryParamMixin],
  data() {
    return {
      downloadId: +this.$router.currentRoute.params.downloadId,
      file: {},
      rightHolders: [],
      columns: [{ name: 'Name' }, { name: 'Status' }],
      statusSelect: {
        name: 'Status',
        selected: null,
        items: [
          { key: 'ACTIVE', name: 'Active' },
          { key: 'MERGED', name: 'Merged' },
          { key: 'DELETED', name: 'Deleted' },
        ],
        itemKey: 'key',
        itemValue: 'name',
      },
    };
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  created() {
    this.updateQueryParams(this.$route.query);
    if (this.extras.status) {
      this.statusSelect.selected = this.extras.status;
    }
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const { file, total, items } = await IpdService.getDownloadRightHolders({
          downloadId: this.downloadId,
          limit: this.pageSize,
          offset: this.getOffset(),
          range_start: this.rangeStart,
          range_end: this.rangeEnd,
          status: this.extras.status,
        });

        this.itemCount = total;
        this.file = file;
        this.rightHolders = items;
      } catch (err) {
        this.$addStarError(err);
      }

      this.loading = false;
    },
    getStatusClass(state) {
      switch (state) {
        case 'ACTIVE':
          return 'report__headers-chip--ok';
        case 'MERGED':
          return 'report__headers-chip--wait';
        default:
          return 'report__headers-chip--fail';
      }
    },
  },
};
</script>
