import _ from 'lodash';
import store from './index';
import * as TypeUtils from '../common/typeUtils';

function commit(namespace, methodName, obj) {
  store.commit(`${namespace}/${methodName}`, obj);
}

function dispatch(namespace, methodName, obj) {
  store.dispatch(`${namespace}/${methodName}`, obj);
}

function getter(namespace, methodName) {
  return store.getters[`${namespace}/${methodName}`];
}

/**
 * Traverses an object graph and returns the property paths as a flat array together with type information.
 * E.g.{ a: { b: { c: "test" }}} = { type: 'var', path: 'a.b.c' }
 * @param obj the object
 * @param mapped the path mapping
 * @param path the path
 * @returns {Array}
 */
function mapPathAndType(obj, mapped = [], path = []) {
  Object.keys(obj).forEach((key) => {
    if (_.isPlainObject(obj[key])) {
      mapped.push({ type: 'var', path: path.concat([key]).join('.') });
      path.push(key);
      mapPathAndType(obj[key], mapped, path);
      path.pop();
    } else if (_.isArray(obj[key])) {
      mapped.push({ type: 'array', path: path.concat([key]).join('.') });
    } else {
      mapped.push({ type: 'var', path: path.concat([key]).join('.') });
    }
  });
  return mapped;
}

/**
 * Generates mutators from the domain object.
 * @param obj
 * @param dbg debug print generated mutations
 * @returns a list of mutator functions
 */
function generateMutations(obj, dbg = false) {
  const generatedFunctions = {};
  mapPathAndType(obj).forEach((mapping) => {
    const key = TypeUtils.camelCase(mapping.path.replace(/\./g, '_'), true);
    /* eslint-disable no-new-func */
    if (mapping.type === 'array') {
      generatedFunctions[`addTo${key}`] = new Function(
        'state',
        'value',
        `state.${mapping.path}.push(value);`,
      );
      generatedFunctions[`removeFrom${key}`] = new Function(
        'state',
        'idx',
        `state.${mapping.path}.splice(idx, 1);`,
      );
      generatedFunctions[`replaceIn${key}`] = new Function(
        'state',
        'replacer',
        `state.${mapping.path}.splice(replacer.index, 1, replacer.value);`,
      );
      generatedFunctions[`update${key}`] = new Function(
        'state',
        'list',
        `state.${mapping.path} = list;`,
      );
    } else {
      generatedFunctions[`update${key}`] = new Function(
        'state',
        'obj',
        `state.${mapping.path} = obj;`,
      );
    }
    /* eslint-enable no-new-func */
  });
  if (dbg) {
    console.log('generated mutations', generatedFunctions);
  }
  return generatedFunctions;
}

/**
 * Generates getters from the domain object.
 * @param obj
 * @param dbg debug print generated getters
 * @returns a list of getter functions
 */
function generateGetters(obj, dbg = false) {
  const generatedFunctions = {};
  mapPathAndType(obj).forEach((mapping) => {
    const key = TypeUtils.camelCase(mapping.path.replace(/\./g, '_'), false);
    /* eslint-disable no-new-func */
    generatedFunctions[key] = new Function('state', `return state.${mapping.path}`);
    /* eslint-enable no-new-func */
  });
  if (dbg) {
    console.log('generated getters', generatedFunctions);
  }
  return generatedFunctions;
}

export { commit, dispatch, getter, generateMutations, generateGetters };
