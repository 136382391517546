<template>
  <div>
    <addable-select-input
      v-if="addable"
      :name="name"
      :label="inputLabel"
      :show-label="showLabel"
      :letter-sequence-filter="true"
      :value="value"
      :scope="scope"
      :disabled="disabled"
      :items="filteredInstruments"
      @input="onAddableInput"
    />
    <select-input
      v-else
      :name="name"
      :label="inputLabel"
      :show-label="showLabel"
      :value="value"
      :scope="scope"
      :disabled="disabled"
      :items="filteredInstruments"
      :letter-sequence-filter="true"
      item-key="code"
      item-value="name"
      @input="onInput"
    />
  </div>
</template>

<script>
import AddableSelectInput from './addable-select-input';
import SelectInput from './select-input';
import _ from 'lodash';

export default {
  name: 'SelectInstrument',
  components: { SelectInput, AddableSelectInput },
  props: {
    name: { type: String, default: 'instruments' },
    label: { type: String, default: '' },
    value: { type: [Array, String], default: '' },
    addable: { type: Boolean, default: false },
    scope: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: true },
    highlight: { type: Array, default: () => [] },
  },
  computed: {
    addableLabel() {
      return this.addable ? 'Instruments' : 'Instrument';
    },
    inputLabel() {
      return this.label || this.addableLabel;
    },
    filteredInstruments() {
      const hightlightMap = this.highlight.reduce((agg, value, index) => {
        agg[value] = index;
        return agg;
      }, {});

      const partitions = this.$store.state.appdata.referenceData.instruments
        .filter((inst) => !inst.disabled)
        .reduce(
          (partitions, value) => {
            const partition = hightlightMap[value.code] != null ? 'highlight' : 'other';
            partitions[partition].push(value);
            return partitions;
          },
          { highlight: [], other: [] },
        );

      partitions.highlight = partitions.highlight.sort((a, b) => {
        const aIndex = hightlightMap[a.code];
        const bIndex = hightlightMap[b.code];

        return aIndex - bIndex;
      });
      partitions.other = _.orderBy(partitions.other, 'name', 'asc');

      return [...partitions.highlight, ...partitions.other];
    },
  },
  methods: {
    onAddableInput(arr) {
      this.$emit('input', arr);
    },
    onInput(instrument) {
      this.$emit('input', instrument);
    },
  },
};
</script>
