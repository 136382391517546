<template>
  <div>
    <template v-if="loading">
      <spinner label="Loading distribution closure..." />
    </template>
    <div v-else class="row">
      <div v-if="currentProgress" class="col s12 view-layout">
        <div class="row">
          <div class="col s8 l10 offset-s2 offset-l1">
            <bullet-progress
              :current-progress="currentProgress"
              :progression-steps="progressionSteps"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s12">
            <h1>
              {{ currentProgress.label }}
              <span v-if="currentProgress.name === 'finished'" class="text--green">✔︎</span>
            </h1>
            <refresh-button
              v-show="currentProgress.name === 'verify'"
              class="float-right margin--bottom"
              @click="refresh(500)"
            />
          </div>
        </div>
        <progress-distribution-closure-create
          v-if="currentProgress.name === 'create'"
          @refresh="refresh"
        />
        <progress-distribution-closure-process
          v-if="currentProgress.name === 'process'"
          :distribution-closure="distributionClosure"
          @refresh="refresh($event)"
        />
        <progress-distribution-closure-verify
          v-if="currentProgress.name === 'verify' && distributionClosure"
          :distribution-closure="distributionClosure"
          @refresh="refresh"
        />
        <progress-distribution-closure-confirmed
          v-if="currentProgress.name === 'finished'"
          :distribution-closure="distributionClosure"
          @refresh="refresh"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from 'lodash';
import PaymentService from '../../../services/paymentService';
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import BulletProgress from '../../ui/bullet-progress';
import ProgressDistributionClosureCreate from './progress-distribution-closure-create';
import ProgressDistributionClosureProcess from './progress-distribution-closure-process';
import ProgressDistributionClosureVerify from './progress-distribution-closure-verify';
import ProgressDistributionClosureConfirmed from './progress-distribution-closure-confirmed';
import Spinner from '../../spinner';
import RefreshButton from '@/components/ui/button/refresh-button';

export default {
  name: 'DistributionClosureWizardMain',
  components: {
    RefreshButton,
    BulletProgress,
    ProgressDistributionClosureCreate,
    ProgressDistributionClosureProcess,
    ProgressDistributionClosureVerify,
    ProgressDistributionClosureConfirmed,
    Spinner,
  },
  async beforeRouteUpdate(to, from, next) {
    this.onRoute(to.query);
    next();
  },
  data() {
    return {
      currentProgress: undefined,
      progressionSteps: [
        {
          name: 'create',
          label: 'New Distribution Closure',
        },
        {
          name: 'process',
          label: 'Process',
        },
        {
          name: 'verify',
          label: 'Verify Information',
        },
        {
          name: 'finished',
          label: 'Distribution Closure Confirmed',
        },
      ],
      distributionClosure: undefined,
      loading: false,
    };
  },
  async created() {
    this.onRoute(this.$route.query);
  },
  methods: {
    async onRoute(query) {
      this.currentProgress = undefined;
      if (query.id) {
        if (await this.fetchData(query)) {
          this.determineProgress();
        } else {
          this.toHistoryView();
        }
      } else {
        this.currentProgress = this.progressionSteps[0];
      }
    },
    async fetchData(routeParams) {
      this.loading = true;
      const query = pick(routeParams, 'id');
      try {
        this.distributionClosure = await PaymentService.getDistributionClosure(query.id);
        this.loading = false;
        return true;
      } catch (err) {
        this.$addStarError(err);
        this.loading = false;
        return false;
      }
    },
    determineProgress() {
      switch (this.distributionClosure.header?.state) {
        case distributionClosureState.Created:
          this.toStep('process');
          break;
        case distributionClosureState.Ready:
        case distributionClosureState.Approved:
          this.toStep('verify');
          break;
        case distributionClosureState.Confirmed:
          this.toStep('finished');
          break;
        default:
          this.handleLimboState();
          break;
      }
    },
    toStep(str) {
      this.currentProgress = this.progressionSteps.find((s) => s.name === str);
      this.loading = false;
    },
    toHistoryView() {
      this.$router.push({ name: 'distributionClosureHistory' });
    },
    async refresh(event) {
      if (event.targetState) {
        this.loading = true;
        console.log(
          `Refreshing distribution closure until it has target state ${event.targetState}...`,
        );
        while (this.distributionClosure.header?.state !== event.targetState) {
          await new Promise((resolve) => setTimeout(resolve, 2500));
          await this.onRoute(this.$route.query);
          this.loading = true;
        }
        console.log(`Distribution closure state is ${event.targetState}! Done refreshing.`);
        this.loading = false;
      } else {
        this.loading = true;
        await this.onRoute(this.$route.query);
      }
    },
    handleLimboState(retries = 0) {
      const retryInterval = 1000;
      this.loading = true;

      setTimeout(async () => {
        await this.fetchData(this.$route.query);
        if (
          [
            distributionClosureState.Created,
            distributionClosureState.Approved,
            distributionClosureState.Confirmed,
          ].includes(this.distributionClosure?.header?.state)
        ) {
          this.determineProgress();
          return;
        }
        if (retries * retryInterval < 3000) {
          this.handleLimboState(retries + 1);
        } else {
          this.loading = false;
          this.toHistoryView();
        }
      }, retryInterval);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 span {
  font-size: 14px;
}
</style>
