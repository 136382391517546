<template>
  <div>
    <select-input
      name="paymentRule"
      :label="label"
      :value="ruleId"
      :items="paymentRules"
      item-key="id"
      item-value="name"
      rule="required"
      @input="onInput"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import DistributionService from '../../../services/distributionService';
import SelectInput from './select-input';

export default {
  name: 'SelectDistributionPaymentRule',
  components: { SelectInput },
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: 'Payment rules' },
  },
  data() {
    return {
      ruleId: this.value,
      paymentRules: [],
    };
  },
  watch: {
    value() {
      this.ruleId = this.value;
    },
  },
  async created() {
    this.paymentRules = _.clone(await DistributionService.getDistributionRules());
  },
  methods: {
    onInput(ruleId) {
      if (ruleId !== '') {
        this.$emit('input', ruleId);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
