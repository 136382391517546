<template>
  <div>
    <spinner v-if="loading" />

    <div class="card row">
      <DataTable
        :columns="columns"
        :items="recordings"
        :total-items="totalRecordings"
        @updatePagination="fetchData($event.limit, $event.offset)"
      >
        <template #item:title="{ item }">
          <router-link
            :to="{ name: 'recordingView', params: { id: item.match_id } }"
            target="_blank"
          >
            {{ item.recording.title }}
          </router-link>
        </template>
        <template #item:version_title="{ item }">
          <span
            v-if="
              item.recording.version_title &&
              matchVersionTitle(item.recording.version_title, item.recording_comment)
            "
            class="text--green"
          >
            <i class="fas fa-check-circle" />
            {{ item.recording.version_title }}
          </span>
          <span v-else-if="item.recording.version_title">
            {{ item.recording.version_title }}
          </span>
          <span v-else class="none">None</span>
        </template>
        <template #item:context-menu="{ item, index }">
          <context-menu
            :options="['Add to recording']"
            @addToRecording="toggleAddModal(item, index)"
          />
        </template>
      </DataTable>
    </div>

    <Modal
      v-if="showAddModal"
      submit-label="Add"
      :disable-submit="validationErrors.has('version_title')"
      @close="showAddModal = false"
      @cancel="showAddModal = false"
      @save="addVersionTitle()"
    >
      <h2 slot="header">Add / Replace version title to {{ selectedItem.title }}</h2>
      <div slot="body">
        <validated-text-input
          v-model="selectedItem.version_title"
          name="version_title"
          label="Version title"
          :rule="{ required: true }"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import MediarcService from '../../../services/mediarcService';
import DataTable from '../../ui/table/DataTable';
import Spinner from '../../spinner';
import ContextMenu from '../../ui/context-menu';
import Modal from '../../ui/dialog/modal';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import PnrService from '../../../services/pnrService';
import { createPatchBasicInfoCommand } from '../../../domain/recordingDomain';

export default {
  name: 'ViewMediarcReportVersionTitles',
  components: {
    Spinner,
    DataTable,
    ContextMenu,
    Modal,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  data() {
    return {
      loading: false,
      showAddModal: false,
      reportId: this.$router.currentRoute.params.reportId,
      totalRecordings: 0,
      recordings: [],
      selectedItem: {},
      columns: [
        { key: 'title', text: 'Title ' },
        { key: 'version_title', text: 'Version' },
        { key: 'recording_comment', text: 'Recording comment' },
        { key: 'context-menu' },
      ],
    };
  },
  activated() {
    this.fetchData();
  },
  methods: {
    async fetchData(limit = 25, offset = 0) {
      this.loading = true;

      try {
        const result = await MediarcService.getMediarcRecordingVersionTitles({
          report_id: this.reportId,
          limit,
          offset,
        });

        this.recordings = result.items;
        this.totalRecordings = result.total_count;
      } catch (err) {
        this.$addStarError(err);
      } finally {
        this.loading = false;
      }
    },
    toggleAddModal(item, index) {
      this.showAddModal = !this.showAddModal;
      this.selectedItem = {
        id: item.match_id,
        version: item.recording.version,
        title: item.recording.title,
        version_title: item.recording_comment,
        index,
      };
    },
    matchVersionTitle(version_title = '', recording_comment = '') {
      return recording_comment.toLowerCase().includes(version_title.toLowerCase());
    },
    async addVersionTitle() {
      this.loading = true;
      this.showAddModal = false;

      const updateBasicInfoCommand = PnrService.prepareCommand(
        {
          id: this.selectedItem.id,
          version: this.selectedItem.version,
        },
        createPatchBasicInfoCommand(),
        { basic_info: { version_title: this.selectedItem.version_title } },
      );

      await PnrService.postCommands({ errorTitle: 'Could not add version title' }, [
        updateBasicInfoCommand,
      ]);

      this.recordings[this.selectedItem.index].recording.version_title =
        this.selectedItem.version_title;

      this.loading = false;
    },
  },
};
</script>
