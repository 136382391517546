<template>
  <div v-if="memberAction !== 'IGNORE'">
    <div class="row small-inner">
      <div class="col s3">
        <h2>Membership</h2>
      </div>
    </div>
    <div v-if="memberAction === 'ADD_MEMBER'" class="row small-inner">
      <div class="col s9">
        <button
          v-if="createMembershipState === 'BEGIN' && isCompleteAddress"
          class="btn primary m2"
          @click="createMembershipState = 'CONFIRM'"
        >
          Send membership agreement
        </button>
        <span v-if="!isCompleteAddress">No address.</span>
        <span v-if="createMembershipState === 'CONFIRM'">
          <strong>Really send the agreement?</strong>
          <button class="btn red m2" @click="createMembershipState = 'BEGIN'">No</button>
          <button class="btn primary m2" @click="onSendMembershipAgreement">Yes</button>
        </span>
        <span v-if="createMembershipState === 'COMPLETE'">You have now sent an agreement.</span>
      </div>
    </div>
    <div v-if="memberAction === 'CONFIRM_MEMBER'" class="row small-inner">
      <div class="col s9">
        <button
          v-if="confirmMembershipState === 'BEGIN'"
          class="btn primary m2"
          @click="confirmMembershipState = 'CONFIRM'"
        >
          Resend MySAMI activation
        </button>
        <span v-if="confirmMembershipState === 'CONFIRM'">
          <strong>Really resend MySAMI activation?</strong>
          <button class="btn red m2" @click="confirmMembershipState = 'BEGIN'">No</button>
          <button class="btn primary m2" @click="onConfirmMembership()">Yes</button>
        </span>
        <span v-if="confirmMembershipState === 'COMPLETE'">MySAMI activation sent.</span>
      </div>
    </div>
    <div v-if="memberAction === 'APPROVE_APPLICATION'" class="row small-inner">
      <div class="col s9">
        <button
          v-if="confirmMembershipState === 'BEGIN'"
          class="btn primary m2"
          @click="confirmMembershipState = 'CONFIRM'"
        >
          Approve application and send to IPD
        </button>
        <span v-if="confirmMembershipState === 'CONFIRM'">
          <strong>Really approve application?</strong>
          <button class="btn red m2" @click="confirmMembershipState = 'BEGIN'">No</button>
          <button class="btn primary m2" @click="onApproveApplication()">Yes</button>
        </span>
        <span v-if="confirmMembershipState === 'PENDING_APPROVAL'">
          Membership Approved Pending IPD confimation
        </span>
      </div>
    </div>
    <div v-if="memberAction === 'APPROVE_REGIONAL_APPLICATION'" class="row small-inner">
      <div class="col s9">
        <button
          v-if="confirmMembershipState === 'BEGIN'"
          class="btn primary m2"
          @click="confirmMembershipState = 'CONFIRM'"
        >
          Create regional membership agreement
        </button>
        <span v-if="confirmMembershipState === 'CONFIRM'">
          <strong>Really send agreement?</strong>
          <button class="btn red m2" @click="confirmMembershipState = 'BEGIN'">No</button>
          <button class="btn primary m2" @click="onApproveRegionalApplication()">Yes</button>
        </span>
        <div v-if="confirmMembershipState === 'PENDING_SIGNED_REGIONAL_AGREEMENT'" class="row">
          Membership Approved Pending Signed Agreement
        </div>
      </div>
    </div>
    <div v-if="memberAction === 'PENDING_APPROVAL'" class="row">Pending IPD Confimation</div>
    <div v-if="memberAction === 'PENDING_SIGNED_REGIONAL_APPLICATION'" class="row">
      Pending Signed Agreement
    </div>
    <div v-if="memberAction === 'NO_ACTIVE_MANDATE'" class="row">
      Is waiting on approval but has no active sami mandates
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mandateIsActive, getMandatesForSociety, SAMI_SOCIETY_CODE } from 'star-common';
import AuthenticationService from '../../../services/authenticationService';
import PerformerService from '../../../services/performerService';

export default {
  name: 'MembershipAgreement',
  props: {},
  data() {
    return {
      confirmMembershipState: 'BEGIN',
      createMembershipState: 'BEGIN',
    };
  },
  computed: {
    ...mapGetters('performer', [
      'socialSecurityNumber',
      'dateOfBirth',
      'mandates',
      'id',
      'contactInfo',
      'associates',
      'tags',
    ]),
    isCompleteAddress() {
      return (
        this.contactInfo.address.street &&
        this.contactInfo.address.postal_code &&
        this.contactInfo.address.city
      );
    },
    isCompleteEmail() {
      return (
        this.contactInfo.emails &&
        this.contactInfo.emails.length > 0 &&
        this.contactInfo.emails[0] &&
        this.contactInfo.emails[0].length > 0
      );
    },
    memberAction() {
      if (!this.socialSecurityNumber && !this.dateOfBirth) {
        return 'IGNORE';
      }
      const flattenedMandates = this.mandates.reduce((acc, cur) => acc.concat(cur.mandates), []);
      const samiMandates = getMandatesForSociety(flattenedMandates, SAMI_SOCIETY_CODE);
      const activeSamiMandates = samiMandates.filter((m) => mandateIsActive(m));
      if (activeSamiMandates.length > 0 && activeSamiMandates.find((am) => am.id !== -1)) {
        if (!this.isCompleteEmail) {
          return 'IGNORE';
        }
        if (this.tags.includes('MEMBER_APPLICATION')) {
          return 'APPROVE_APPLICATION';
        }
        if (this.tags.includes('AWAITING_IPD_APPROVAL')) {
          return 'PENDING_APPROVAL';
        }
        return 'CONFIRM_MEMBER';
      } else if (this.tags.includes('REGIONAL_APPLICATION')) {
        return 'APPROVE_REGIONAL_APPLICATION';
      } else if (this.tags.includes('PENDING_SIGNED_REGIONAL_AGREEMENT')) {
        return 'PENDING_SIGNED_REGIONAL_APPLICATION';
      }

      if (this.tags.includes('MEMBER_APPLICATION') || this.tags.includes('REGIONAL_APPLICATION')) {
        return 'NO_ACTIVE_MANDATE';
      }
      return 'ADD_MEMBER';
    },
  },
  methods: {
    async onSendMembershipAgreement() {
      this.createMembershipState = 'COMPLETE';
      await PerformerService.initiateMembership(
        { username: AuthenticationService.getUserName() },
        this.id,
      );
      this.$emit('saved');
    },
    async onConfirmMembership() {
      this.confirmMembershipState = 'COMPLETE';
      await PerformerService.confirmMembership(
        { username: AuthenticationService.getUserName() },
        this.id,
      );
      this.$emit('saved');
    },
    async onApproveApplication() {
      this.confirmMembershipState = 'PENDING_APPROVAL';
      await PerformerService.approveApplication(
        { username: AuthenticationService.getUserName() },
        this.id,
      );
      this.$emit('saved');
    },
    async onApproveRegionalApplication() {
      this.confirmMembershipState = 'PENDING_SIGNED_REGIONAL_AGREEMENT';
      const associateId = this.associates.length > 0 ? this.associates[0].id : '';
      await PerformerService.createMembershipAgreement(
        { username: AuthenticationService.getUserName(), associate_id: associateId },
        this.id,
      );
      this.$emit('saved');
    },
  },
};
</script>
