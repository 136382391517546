import AuthenticationService from '../services/authenticationService';

export default {
  data() {
    return {
      verificationStatus: 'login',
      numberOfAttempts: 0,
    };
  },
  watch: {
    verificationStatus() {
      if (this.verificationStatus === 'success') {
        this.numberOfAttempts = 0;
      }
    },
  },
  methods: {
    async onBypassAuth() {
      this.numberOfAttempts = 0;
      this.verificationStatus = 'success';
      await this.doWork();
    },
    async onSubmitPassword(password) {
      try {
        const result = await AuthenticationService.reAuthenticate(password);
        this.verificationStatus = result.status;
        this.numberOfAttempts = 0;
        if (result.status === 'success') {
          await this.doWork();
        }
      } catch (error) {
        this.numberOfAttempts++;
        this.verificationStatus = 'loginError';
        console.dir(error);
        this.$addStarError({
          title: 'Ooops!',
          type: `${error.statusCode} - ${error.code}`,
          message: error.message,
        });
      }
    },
    async onSubmitMfaCode(mfaCode) {
      try {
        const result = await AuthenticationService.verifyMfaCode(mfaCode);
        if (result.status === 'success') {
          this.numberOfAttempts = 0;
          this.verificationStatus = result.status;
          await this.doWork();
        }
      } catch (error) {
        this.numberOfAttempts++;
        this.verificationStatus = 'mfaError';
        console.dir(error);
        this.$addStarError({
          title: 'Ooops!',
          type: `${error.statusCode} - ${error.code}`,
          message: error.message,
        });
      }
    },
  },
};
