<template>
  <router-link class="header-bar__logo" to="/">
    <svg
      width="940px"
      height="940px"
      viewBox="0 0 940 940"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      :style="magicStyle"
    >
      <!-- Generator: Sketch 51.3 (57544) - http://www.bohemiancoding.com/sketch -->
      <title>Logo Shooting Star Outlined</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <polygon
          id="path-1"
          points="133.302846 536.097542 374.697252 567.615075 423.236338 807.296613 -74.4609375 1314.61719 -251.78125 614.070312"
        />
      </defs>
      <g
        id="Logo-Shooting-Star-Outlined"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <polygon
          id="Star-2"
          fill="#FFFFFF"
          fill-rule="nonzero"
          transform="translate(500.680131, 432.569228) rotate(-317.000000) translate(-500.680131, -432.569228) "
          points="270.1238 411.409487 411.25978 515.376213 315.926375 716.395404 376.159672 526.78093 179.623683 382.004217 423.722255 380.398351 500.680131 148.743053 577.638007 380.398351 821.73658 382.004217 625.20059 526.78093 685.433887 716.395404 590.100482 515.376213 731.236462 411.409487 555.944947 410.256287 500.680131 243.900508 445.415315 410.256287"
        />
        <mask id="mask-2" fill="white">
          <use xlink:href="#path-1" />
        </mask>
        <g id="Path-2" />
        <path
          id="Combined-Shape"
          d="M-174.96852,938.066477 L297.595727,465.50223 L311.737863,479.644365 L-160.826385,952.208613 L-174.96852,938.066477 Z M-138.755794,984.569529 L333.808453,512.005281 L347.950589,526.147417 L-124.613659,998.711665 L-138.755794,984.569529 Z M-102.543068,1031.07258 L370.02118,558.508333 L384.163315,572.650469 L-88.4009324,1045.21472 L-102.543068,1031.07258 Z M-66.3303418,1077.57563 L406.233906,605.011385 L420.376041,619.15352 L-52.1882062,1091.71777 L-66.3303418,1077.57563 Z M-30.1176157,1124.07868 L442.446632,651.514436 L456.588768,665.656572 L-15.9754801,1138.22082 L-30.1176157,1124.07868 Z"
          fill="#FFFFFF"
          mask="url(#mask-2)"
        />
        <image
          v-if="isChristmasTime"
          id="santa-hat"
          href="/santa-hat.png"
          x="0"
          y="0"
          width="300"
          height="300"
        />
      </g>
    </svg>
  </router-link>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'Logo',
  data() {
    return {
      x: 0,
      y: 0,
      magicWord: ['s', 't', 'a', 'r'],
      magic: [],
    };
  },
  computed: {
    isChristmasTime() {
      return new Date().getMonth() === 11;
    },
    magicStyle() {
      if (
        _.isEqual(this.magic, this.magicWord) &&
        this.magicWord.length === this.magicWord.length
      ) {
        const angle = Math.atan(this.y / this.x);
        return {
          rotate: angle + 'rad',
        };
      }
      return {};
    },
  },
  created() {
    document.addEventListener('keydown', this.doMagic);
    document.addEventListener('mousemove', this.onMouseUpdate, false);
  },
  methods: {
    doMagic(event) {
      if (!_.isEqual(this.magic, this.magicWord)) {
        const key = event.key;
        if (this.magic.length >= 4) {
          this.magic = [];
        }
        this.magic.push(key);
      }
    },
    onMouseUpdate(e) {
      this.x = e.pageX - 30;
      this.y = e.pageY - 30;
    },
  },
};
</script>

<style scoped>
#santa-hat {
  transform: translate(90px, 200px) scale(1.5) rotate(-35deg);
}
</style>
