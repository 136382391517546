<template>
  <thead>
    <tr>
      <th v-if="expandedContent" class="thin-column" />
      <th v-if="bulkEnabled">
        <input
          :id="checkboxId"
          ref="mark"
          name="mark"
          type="checkbox"
          class="filled-in"
          :checked="markedForBulk"
          :disabled="bulkSuspend"
          @change="markAll"
        />
        <label :for="checkboxId" />
      </th>
      <th
        v-for="(column, index) in columns"
        :key="`${index}-column`"
        :class="[
          {
            'thin-column': column.thinColumn,
            'disabled-head': !column.sortParam || disabled,
            'text--grey': disabled,
            count: column.count,
          },
          column.styleClass,
        ]"
        @click="!bulkSuspend && sortDefault(column)"
      >
        {{ column.name }}
        <span v-if="column.sortParam">
          <i
            v-if="!column.ascending"
            class="fas fa-caret-down"
            :class="{ 'text--grey': !column.active || disabled }"
          />
          <i v-else class="fas fa-caret-up" :class="{ 'text--grey': !column.active || disabled }" />
        </span>
      </th>
      <th v-if="moreOptions" class="thin-column" />
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'SortableHead',
  props: {
    columns: Array,
    defaultSortColumnIndex: { type: Number, default: 1 },
    bulkEnabled: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    expandedContent: { type: Boolean, default: false },
    bulkSuspend: { type: Boolean, default: false },
    markedForBulk: { type: Boolean, default: true },
    moreOptions: { type: Boolean, default: true },
    defaultSortShouldOverride: { type: Boolean, default: false },
  },
  data() {
    return {
      checkboxId: Math.random().toString(36).substr(2),
    };
  },
  created() {
    if (!this.defaultSortShouldOverride) {
      this.sortDefault(this.columns[this.defaultSortColumnIndex], false);
    }
  },
  methods: {
    sortDefault(selectedColumn, emit = true) {
      if (!this.disabled && selectedColumn.sortParam) {
        const trgCol = this.columns.find((c) => c.name === selectedColumn.name);
        if (!trgCol) {
          throw new Error(`Could not find column with name ${selectedColumn.name}`);
        }
        trgCol.active = true;
        const otherCols = this.columns.filter((c) => c.name !== selectedColumn.name);
        // only toggle ascending if this column was already active
        if (!otherCols.some((c) => c.active === true)) {
          trgCol.ascending = !trgCol.ascending;
        }
        trgCol.order = trgCol.ascending ? 'asc' : 'desc';
        otherCols.forEach((col) => {
          col.active = false;
          col.ascending = true;
          col.order = 'asc';
        });
        if (emit) {
          this.$emit('sort', selectedColumn);
        }
      }
    },
    markAll(e) {
      this.$emit('markAll', e.target.checked);
    },
  },
};
</script>
