<template>
  <div>
    <div class="number" :style="'color: var(--' + icon.color + ')'">
      {{ count }}
    </div>
    <router-link :to="extras.url">{{ extras.title }}</router-link>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'IconWidget',
  mixins: [WidgetMixin],
  computed: {
    count() {
      if (Array.isArray(this.extras.type)) {
        return this.extras.type.reduce((acc, type) => acc + (this.payload[type] || 0), 0);
      }

      return this.payload[this.extras.type] || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.number {
  font-size: 20px;
  font-weight: bold;
}
</style>
