<template>
  <form :data-vv-as="scope">
    <div class="row">
      <div class="col s3">
        <searchable-input
          ref="name"
          name="name"
          label="Add a performer"
          placeholder="Name, SSN or IPN"
          use-template="performerNameSearch"
          :searcher="searchPerformer"
          :inactive-ids="inactiveIds"
          :value="performerName"
          :scope="scope"
          :rule="required ? 'required' : ''"
          @input="setPerformer"
          @clear="setPerformer(null)"
        />
      </div>
      <div class="col s3">
        <select-input
          name="pseudo-name"
          :scope="scope"
          :disabled="!(performer && performer.id)"
          :value="pseudo"
          :items="pseudoNames"
          label="Pseudo Name"
          @input="setPseudo"
        />
      </div>
      <div class="col s2">
        <select-instrument
          :scope="scope"
          :disabled="!(performer && performer.id)"
          :value="instruments"
          :addable="true"
          :highlight="instrumentsHighlight"
          @input="setInstruments"
        />
      </div>
      <div class="col s3">
        <select-role-code
          :scope="scope"
          :value="role"
          :show-label="true"
          :rule="!!(performer && performer.id) ? 'required' : ''"
          :disabled="!(performer && performer.id)"
          :collective-roles="collectiveRoles"
          @input="setRole"
        />
      </div>
    </div>
  </form>
</template>

<script>
import { createPerformerReference } from './../../../domain/recordingDomain';
import { createNamedRelation } from '../../../domain/common';
import PerformerService from '../../../services/performerService';
import SearchHelper from '../../search/searchHelper';
import SelectRoleCode from '../../ui/select/select-role-code';
import SelectInput from '../../ui/select/select-input';
import SearchableInput from '../../ui/input/searchable-input';
import SelectInstrument from '../../ui/select/select-instrument';

export default {
  name: 'AddPerformerRow',
  components: {
    SelectInstrument,
    SearchableInput,
    SelectRoleCode,
    SelectInput,
  },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      default: 'add-performer-row',
    },
    required: {
      type: Boolean,
      default: false,
    },
    inactiveIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      performer: {},
      instruments: [null],
      instrumentsHighlight: [],
      pseudo: null,
      role: null,
    };
  },
  computed: {
    pseudoNames() {
      return (
        this.generalInfo.pseudo_names &&
        this.generalInfo.pseudo_names.map((name) => ({
          code: name,
          name,
        }))
      );
    },
    generalInfo() {
      return (this.performer && this.performer.general_info) || {};
    },
    mandates() {
      return ((this.performer && this.performer.mandates) || []).flatMap((m) => m.mandates);
    },
    performerName() {
      return (
        this.generalInfo.first_name &&
        `${this.generalInfo.first_name || ''} ${this.generalInfo.last_name || ''}`
      );
    },
    collectiveRoles() {
      if (!this.generalInfo.collective) {
        return 'none';
      }
      const samiMandatesCount = this.mandates.filter((s) => s.society_code === '06').length;
      if (samiMandatesCount > 0 && samiMandatesCount === this.mandates.length) {
        return 'sami';
      } else if (samiMandatesCount === 0) {
        return 'foreign';
      }
      return 'all';
    },
  },
  methods: {
    async setPerformer(performer) {
      if (performer && performer.id) {
        this.performer = await PerformerService.getPerformer(performer.id);

        this.instrumentsHighlight = [];

        if (performer.main_instrument) {
          this.instrumentsHighlight.push(performer.main_instrument);
          this.instruments = [performer.main_instrument];
        }

        (performer.secondary_instruments || []).forEach((instrument) => {
          this.instrumentsHighlight.push(instrument);
        });
      } else {
        this.clear();
      }
      this.emitState();
    },
    async searchPerformer(query) {
      const res = await SearchHelper.advancedSearchPerformers({ query });
      return res.results.map((perf) => ({
        ...perf,
        name: `${perf.first_name} ${perf.last_name}`,
        main_artists: perf.main_artists.map((ma) => ma.name),
      }));
    },
    clear() {
      this.performer = {};
      this.instruments = [null];
      this.pseudo = null;
      this.role = null;
      this.instrumentsHighlight = [];
      this.emitState();
    },
    emitState() {
      this.$emit(
        'update',
        createPerformerReference(
          createNamedRelation(this.performer.id, this.performerName, 'performer'),
          this.instruments,
          this.role,
          this.pseudo,
        ),
      );
    },
    setPseudo(pseudo) {
      this.pseudo = pseudo;
      this.emitState();
    },
    setInstruments(instruments) {
      this.instruments = instruments;
      this.emitState();
    },
    setRole(role) {
      this.role = role;
      this.emitState();
    },
  },
};
</script>
