<template>
  <div>
    <select-input
      label="Statement file type"
      item-key="key"
      item-value="value"
      :name="name"
      :value="value"
      :items="types"
      :rule="rule"
      :scope="scope"
      :disabled="disabled"
      :show-label="showLabel"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectStatementFileType',
  components: { SelectInput },
  props: {
    value: String,
    name: String,
    disabled: { type: Boolean, default: false },
    showLabel: { type: Boolean, default: false },
    scope: {
      type: String,
      default: undefined,
    },
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      types: [
        { key: 'sdeg2.5', value: 'SDEG 2.5' },
        { key: 'rdr-r', value: 'DDEX RDR-R' },
      ],
    };
  },
  methods: {
    onInput(key) {
      this.$emit('input', key);
    },
  },
};
</script>
