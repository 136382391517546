import RemoteFileService from '../../services/remoteFileService';
import { AGGREGATE_TYPES, SUB_AGGREGATE_TYPES } from '../../domain/common';
import CreateUploadFileDescriptor from '../../domain/uploadFileDescriptor';

export default async function uploadContracts(societyName, agreements) {
  const agreementsToUpload = agreements.filter((agreement) => agreement.fileHandle);
  const promises = agreementsToUpload.map(async (agreement) => {
    const entityType = `${AGGREGATE_TYPES.SOCIETY}-${SUB_AGGREGATE_TYPES.AGREEMENT}`;
    const metaIdentifiers = [agreement.fileHandle.name];
    const fileDescriptor = CreateUploadFileDescriptor(
      entityType,
      agreement.id,
      metaIdentifiers,
      agreement.fileHandle,
    );
    const fileName = await RemoteFileService.uploadToDocumentRepository(fileDescriptor);
    agreement.contract.url = fileName;
    return delete agreement.fileHandle;
  });
  await Promise.all(promises);
}
