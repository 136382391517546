<template>
  <div>
    <form :data-vv-scope="scope">
      <div v-if="final" class="row">
        <div class="col s12">
          <span class="text--bold">Allocation Method:</span>
          {{ $filters.translate(`distribution.distribution_method.${distributionMethod}`) }}
        </div>
      </div>

      <div v-else>
        <div v-if="isDistributeManually">
          <div class="row">
            <div class="col s12">
              <action-buttons
                v-if="manualDistributionToggledOn && allocateSelected"
                class="float-right"
                :disable-submit="totalLeftOverOfManualAmounts != 0.0"
                @save="onSaveManualAllocation"
                @cancel="onCancelManualAllocation"
              />
            </div>
          </div>

          <div class="row">
            <div class="col s12">
              <div class="float-right text--bold">
                <span v-if="manualDistributionToggledOn && allocateSelected">
                  <span class="text--blue" @click="onDistributeManualAmountsEvenly">
                    <i class="fas fa-expand-arrows-alt" />
                    Distribute evenly
                  </span>

                  <span class="text--blue" @click="onClearAllManualAmounts">
                    <i class="fas fa-times" />
                    Clear all
                  </span>

                  <span class="padding-break">
                    Balance:
                    <span
                      :class="{
                        'text--bold': true,
                        'text--green': totalLeftOverOfManualAmounts > 0.0,
                        'text--red': totalLeftOverOfManualAmounts < 0.0,
                      }"
                    >
                      {{ $filters.formatAmountThousandSeparator(totalLeftOverOfManualAmounts) }}
                    </span>
                  </span>
                </span>

                <span v-if="!manualDistributionToggledOn" class="add-extra add-padding-right">
                  <a @click="onEditManualAllocation">
                    <i class="far fa-edit" />
                    Edit
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="row">
          <div class="col s12">
            <div v-if="loading" class="row">
              <div class="col s12">
                <spinner />
              </div>
            </div>
            <div v-else>
              <table v-if="distributionMethod === DistributionMethod.BY_SOURCE">
                <thead>
                  <th
                    v-for="(column, idx) in columnsBySources"
                    :key="idx"
                    :class="[
                      { 'thin-column': column.thinColumn, 'disabled-head': !column.sortName },
                      column.styleClass,
                    ]"
                    @click="column.sortName ? sort(column) : ''"
                  >
                    <span>{{ column.name }}</span>
                    <span v-if="column.sortName && !column.thinColumn">
                      <span v-if="column.sortParam" class="dropdown-button__arrow">
                        <i
                          v-if="column.order === 'desc'"
                          class="fas fa-caret-up"
                          :class="{ 'text--grey': !column.active }"
                        />
                        <i
                          v-else
                          class="fas fa-caret-down"
                          :class="{ 'text--grey': !column.active }"
                        />
                      </span>
                    </span>
                  </th>
                </thead>
                <tbody>
                  <template v-for="(group, idx) in selectedDistributionDistributionGroups">
                    <tr :key="idx">
                      <td>
                        <span class="fs-14">{{ group.source.name }}</span>
                      </td>
                      <td />
                      <td>
                        {{ $filters.airTime(group.airtime) }}
                      </td>
                      <td>
                        {{ $filters.formatNumber(group.recording_count) }}
                      </td>
                      <td>
                        {{ $filters.formatNumber($filters.removeDecimal(group.points || 0)) }}
                      </td>
                      <td>
                        {{ $filters.formatNumber(pointsPerMinute(group)) }}
                      </td>
                      <td>
                        {{ $filters.formatNumber(group.point_value) }}
                      </td>
                      <td>
                        {{ $filters.formatAmountThousandSeparator(group.amount) }}
                      </td>
                      <td>
                        {{ $filters.formatAmountThousandSeparator(reserved(group)) }}
                      </td>
                    </tr>

                    <template v-for="(subGroup, idx2) in group.sub_groups">
                      <tr :key="`sub-${idx2}`">
                        <td
                          class="add-padding-left thin-column dropdown-button__arrow"
                          style="width: 130px !important"
                        >
                          <span v-if="subGroup.channel_ids.length > 0">
                            <i
                              v-if="isExpandedSubSource(subGroup.source.id)"
                              class="fas fa-caret-up"
                              @click="toggleExpandedSubSource(subGroup.source.id, $event)"
                            />
                            <i
                              v-else
                              class="fas fa-caret-down"
                              @click="toggleExpandedSubSource(subGroup.source.id, $event)"
                            />
                          </span>
                          <span>{{ subGroup.source.name }}</span>
                        </td>
                        <td>
                          <span v-if="subGroup.channel_ids.length > 0">
                            <a
                              v-if="isOpen & !final"
                              tabindex="0"
                              role="button"
                              @click="onShowChannels(subGroup.source.id)"
                            >
                              {{ subGroup.channel_ids.length }} channels
                            </a>
                            <span v-else>{{ subGroup.channel_ids.length }} channels</span>
                          </span>
                          <span v-else class="row edit-link">
                            <a
                              tabindex="0"
                              role="button"
                              @click="onShowChannels(subGroup.source.id)"
                            >
                              <i class="far fa-plus-square" />
                              Add channels
                            </a>
                          </span>
                        </td>
                        <td>
                          {{ $filters.airTime(subGroup.airtime) }}
                        </td>
                        <td>
                          {{ $filters.formatNumber(subGroup.recording_count) }}
                        </td>
                        <td>
                          {{ $filters.formatNumber($filters.removeDecimal(subGroup.points || 0)) }}
                        </td>
                        <td>
                          {{ $filters.formatNumber(pointsPerMinute(subGroup)) }}
                        </td>
                        <td>
                          {{ $filters.formatNumber(subGroup.point_value) }}
                        </td>
                        <td>
                          {{ $filters.formatAmountThousandSeparator(subGroup.amount) }}
                        </td>
                        <td>
                          {{ $filters.formatAmountThousandSeparator(reserved(subGroup)) }}
                        </td>
                      </tr>

                      <template
                        v-if="
                          final ||
                          (isExpandedSubSource(subGroup.source.id) &&
                            subGroup.channel_ids.length > 0)
                        "
                      >
                        <tr
                          v-for="distribution in distributionsForChannels(subGroup.channel_ids)"
                          :key="distribution.id"
                        >
                          <td class="add-padding-left" />
                          <td>
                            <span class="fs-14">{{ channels[distribution.channel_id].name }}</span>
                          </td>
                          <td>
                            <span>{{ $filters.airTime(distribution.airtime) }}</span>
                          </td>
                          <td>
                            <span>
                              {{ $filters.formatNumber(distribution.recording_count) }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{
                                $filters.formatNumber(
                                  $filters.removeDecimal(distribution.points || 0),
                                )
                              }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ $filters.formatNumber(pointsPerMinute(distribution)) }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ $filters.formatNumber(distribution.point_value) }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ $filters.formatAmountThousandSeparator(distribution.amount) }}
                            </span>
                          </td>
                          <td>
                            <span>
                              {{ $filters.formatAmountThousandSeparator(reserved(distribution)) }}
                            </span>
                          </td>
                        </tr>
                      </template>
                    </template>
                  </template>
                </tbody>
              </table>

              <!-- POINTS, AIRTIME, MANUAL -->
              <table v-else>
                <thead>
                  <th
                    v-for="(column, idx) in columnsByChannel"
                    :key="idx"
                    :class="[
                      { 'thin-column': column.thinColumn, 'disabled-head': !column.sortName },
                      column.styleClass,
                    ]"
                    @click="column.sortName ? sort(column) : ''"
                  >
                    <span>{{ column.name }}</span>
                    <span v-if="column.sortName && !column.thinColumn">
                      <span v-if="column.sortParam" class="dropdown-button__arrow">
                        <i
                          v-if="column.order === 'desc'"
                          class="fas fa-caret-up"
                          :class="{ 'text--grey': !column.active }"
                        />
                        <i
                          v-else
                          class="fas fa-caret-down"
                          :class="{ 'text--grey': !column.active }"
                        />
                      </span>
                    </span>
                  </th>
                </thead>
                <tbody :class="{ disabler: pendingRecalculation && !isDistributeManually }">
                  <tr>
                    <td
                      v-if="selectedDistributionChannelDistributions.length === 0"
                      colspan="8"
                      class="text--center"
                    >
                      <span class="none" />
                    </td>
                  </tr>
                  <tr
                    v-for="(distribution, idx) in selectedDistributionChannelDistributions"
                    :key="idx"
                  >
                    <td>
                      <span v-if="channels[distribution.channel_id]" class="fs-14">
                        {{ channels[distribution.channel_id].name }}
                      </span>
                    </td>
                    <td>
                      <span v-if="distribution.source" class="fs-14">
                        {{ distribution.source.name }}
                      </span>
                    </td>
                    <td class="amount-row">
                      <span v-if="distribution.airtime">
                        {{ $filters.airTime(distribution.airtime) }}
                      </span>
                      <span v-else class="none">-</span>
                    </td>
                    <td class="amount-row">
                      <span v-if="distribution.recording_count">
                        {{ $filters.formatNumber(distribution.recording_count) }}
                      </span>
                      <span v-else class="none">-</span>
                    </td>
                    <td class="amount-row">
                      <span v-if="distribution.points">
                        {{
                          $filters.formatNumber($filters.removeDecimal(distribution.points || 0))
                        }}
                      </span>
                      <span v-else class="none">-</span>
                    </td>
                    <td class="amount-row">
                      <span v-if="distribution.points">
                        {{ $filters.formatNumber(pointsPerMinute(distribution)) }}
                      </span>
                      <span v-else class="none">-</span>
                    </td>
                    <td class="amount-row">
                      <span v-if="distribution.amount">
                        {{ $filters.formatNumber(distribution.point_value) }}
                      </span>
                      <span v-else class="none">-</span>
                    </td>
                    <td class="amount-row">
                      <template v-if="manualDistributionToggledOn && allocateSelected">
                        <validated-text-input
                          v-model="distribution.amount"
                          style="max-width: 100px"
                          :scope="scope"
                          :name="`distribution.amount.${distribution.channel_id}`"
                          type="number"
                          label="amount"
                          :show-label="false"
                          placeholder="E.g. 99,99"
                          rule="required"
                        />
                      </template>
                      <template v-else>
                        <span v-if="distribution.amount">
                          {{ $filters.formatAmountThousandSeparator(distribution.amount) }}
                        </span>
                        <span v-else class="none">-</span>
                      </template>
                    </td>
                    <td class="amount-row">
                      <span v-if="distribution.reserved_points">
                        {{ $filters.formatAmountThousandSeparator(reserved(distribution)) }}
                      </span>
                      <span v-else class="none">-</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="7">
                      <span>Sum:</span>
                    </td>
                    <td class="amount-row">
                      <span class="text--bold">
                        {{ $filters.formatAmountThousandSeparator(totalAmount) }} SEK
                      </span>
                    </td>
                    <td class="amount-row">
                      <span class="text--bold">
                        {{ $filters.formatAmountThousandSeparator(totalReserved) }} SEK
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <select-channel
              :show="showChannelsModal"
              :selected-channel-ids="channelsForSubSource(activeSubSourceId)"
              @cancel="onCancelChannels"
              @save="onSaveChannels"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import {
  createSourceDistributionInput,
  createClientAttributes,
  DistributionMethod,
  DistributionState,
} from '../../../domain/distributionDomain';

import DistributionService from '../../../services/distributionService';
import SelectChannel from './select-channel';
import Spinner from '../../spinner';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'DistributeYearCalculation',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    ActionButtons,
    SelectChannel,
    Spinner,
    ValidatedTextInput,
  },
  filters: {
    round(val) {
      return Math.round(val);
    },
  },
  props: {
    revenues: {
      type: Array,
      default: () => [],
    },
    distributionMethod: {
      type: String,
      default: DistributionMethod.POINTS,
    },
    pendingRecalculation: {
      type: Boolean,
      default: false,
    },
    final: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    allocateSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scope: 'distribution-year-calculation',
      DistributionMethod,
      selectedEndMonth: this.selectedDistributionToMonth ? this.selectedDistributionToMonth : 0,
      manualDistributionToggledOn: false,
      channels: [],
      selectedRevenues: _.cloneDeep(this.revenues),
      allRevenueSources: [],
      originalChannelDistributions: [],
      showChannelsModal: false,
      activeSubSourceId: '',
      channelsBySubSource: {},
      expandedSubSource: [],
      columnsByChannel: [
        {
          name: 'Channel',
        },
        {
          name: 'Source',
        },
        {
          name: 'Airtime',
          styleClass: 'amount-row',
        },
        {
          name: 'Recordings',
          styleClass: 'amount-row',
        },
        {
          name: 'Points',
          styleClass: 'amount-row',
        },
        {
          name: 'Points/Minute',
          styleClass: 'amount-row',
        },
        {
          name: 'Amount/Minute',
          styleClass: 'amount-row',
        },
        {
          name: 'Distributable',
          styleClass: 'amount-row',
        },
        {
          name: 'Reserved',
          styleClass: 'amount-row',
        },
      ],
      columnsBySources: [
        {
          name: 'Sources',
        },
        {
          name: 'Channels',
        },
        {
          name: 'Airtime',
          styleClass: 'amount-row',
        },
        {
          name: 'Unique recordings',
          styleClass: 'amount-row',
        },
        {
          name: 'Points',
          styleClass: 'amount-row',
        },
        {
          name: 'Points/Minute',
          styleClass: 'amount-row',
        },
        {
          name: 'Amount/Minute',
          styleClass: 'amount-row',
        },
        {
          name: 'Distributable',
          styleClass: 'amount-row',
        },
        {
          name: 'Reserved',
          styleClass: 'amount-row',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('distribution', [
      'aggregateHeaderName',
      'aggregateHeaderState',
      'aggregateHeaderErrorPct',
      'aggregateHeaderOpenMonth',
      'aggregateHeaderEndMonth',
      'aggregateHeaderAreaDistributionRuleId',
      'aggregateHeaderAreaCategoryOfRights',
      'aggregateDistributions',
      'selectedDistribution',
      'selectedDistributionId',
      'selectedDistributionFromMonth',
      'selectedDistributionToMonth',
      'selectedDistributionState',
      'selectedDistributionPoints',
      'selectedDistributionDistributionMethod',
      'selectedDistributionChannelDistributions',
      'selectedDistributionRevenueIds',
      'selectedDistributionClientAttributes',
      'selectedDistributionDistributionGroups',
    ]),
    totalReserved() {
      return Number(
        this.selectedDistributionChannelDistributions.reduce(
          (sum, dist) =>
            Number(sum) +
            (dist.reserved_points ? Number(dist.reserved_points) * Number(dist.point_value) : 0.0),
          0.0,
        ),
      );
    },
    totalAmount() {
      return Number(
        this.selectedDistributionChannelDistributions.reduce(
          (sum, dist) => Number(sum) + (dist.amount ? parseFloat(dist.amount) : 0.0),
          0.0,
        ),
      );
    },
    totalLeftOverOfManualAmounts() {
      return parseFloat(
        (this.totalBalanceOfManualAmounts() - this.sumOfSelectedRevenues()).toFixed(2),
      );
    },
    isOpen() {
      return this.selectedDistributionState === DistributionState.OPEN;
    },
    isDistributeManually() {
      return this.distributionMethod === DistributionMethod.MANUAL;
    },
  },
  watch: {
    revenues(values) {
      this.selectedRevenues = _.cloneDeep(values);
    },
    distributionMethod(newMethod) {
      if (newMethod !== DistributionMethod.MANUAL) {
        this.manualDistributionToggledOn = false;
      } else {
        this.manualDistributionToggledOn = true;
        this.$emit('edit');
      }
    },
  },
  async beforeMount() {
    const channelData = await DistributionService.getDistributionChannelsCached();
    this.channels = channelData.channels.reduce((store, channel) => {
      store[channel.id] = channel;
      return store;
    }, {});
    const revenuesSourcesResult = await DistributionService.getRevenueSources();
    this.allRevenueSources = revenuesSourcesResult.sources;
  },
  methods: {
    ...mapMutations('distribution', [
      'updateSelectedDistributionRevenueIds',
      'updateSelectedDistributionChannelDistributions',
      'removeFromSelectedDistributionRevenueIds',
      'updateSelectedDistributionClientAttributes',
    ]),
    distributionsForChannels(channelIds) {
      const idSet = new Set(channelIds);
      return this.selectedDistributionChannelDistributions.filter((dist) =>
        idSet.has(dist.channel_id),
      );
    },
    isExpandedSubSource(sourceId) {
      const expanded = this.expandedSubSource.includes(sourceId);
      return expanded;
    },
    toggleExpandedSubSource(sourceId, e) {
      const row = e.target.closest('tr');
      if (!this.expandedSubSource.includes(sourceId)) {
        this.expandedSubSource.push(sourceId);
        row.style.whiteSpace = 'normal';
      } else {
        this.expandedSubSource = _.without(this.expandedSubSource, sourceId);
        row.style.whiteSpace = 'nowrap';
      }
    },
    channelsForSubSource(sourceId) {
      if (this.selectedDistributionDistributionGroups) {
        const group = this.selectedDistributionDistributionGroups.find(
          (grp) => grp.source.id === sourceId,
        );
        if (!group) {
          return [];
        }
        return group.channel_ids;
      }
      return [];
    },
    reserved(distribution) {
      return (Number(distribution.reserved_points) * Number(distribution.point_value)).toFixed(2);
    },
    pointsPerMinute(distribution) {
      return (
        Number(distribution.points) /
        (Number(distribution.airtime.hours) * 60 +
          Number(distribution.airtime.minutes) +
          Number(distribution.airtime.seconds) / 60)
      ).toFixed(2);
    },
    onShowChannels(sourceId) {
      this.activeSubSourceId = sourceId;
      this.showChannelsModal = true;
    },
    sumOfSelectedRevenues() {
      return this.selectedRevenues.reduce(
        (accumulator, revenue) => accumulator + parseFloat(revenue.distributable_amount),
        0.0,
      );
    },
    totalBalanceOfManualAmounts() {
      return this.selectedDistributionChannelDistributions.reduce(
        (accumulator, distribution) =>
          accumulator +
          (distribution.amount && !isNaN(parseFloat(distribution.amount))
            ? parseFloat(distribution.amount)
            : 0.0),
        0.0,
      );
    },
    onSaveChannels(channelIds) {
      if (!this.selectedDistributionClientAttributes) {
        this.updateSelectedDistributionClientAttributes(createClientAttributes());
      }
      const sourceDistributions = this.selectedDistributionClientAttributes.source_distributions;
      const sourceDistribution = sourceDistributions
        ? sourceDistributions.find((s) => s.source_id === this.activeSubSourceId)
        : null;
      if (sourceDistribution) {
        sourceDistribution.channel_ids = channelIds;
      } else {
        sourceDistributions.push(createSourceDistributionInput(this.activeSubSourceId, channelIds));
      }
      this.updateSelectedDistributionClientAttributes(createClientAttributes(sourceDistributions));
      this.showChannelsModal = false;
      this.activeSubSourceId = '';
      this.$emit('channelsBySourceChanged');
    },
    onCancelChannels() {
      this.activeSubSourceId = '';
      this.showChannelsModal = false;
    },
    onEditManualAllocation() {
      this.$emit('edit');
      this.manualDistributionToggledOn = true;
    },
    onSaveManualAllocation() {
      this.$emit('saveManualDistribution');
      this.manualDistributionToggledOn = false;
    },
    onCancelManualAllocation() {
      this.$emit('revertEdit');
      this.manualDistributionToggledOn = false;
    },
    onClearAllManualAmounts() {
      const distributions = _.cloneDeep(this.selectedDistributionChannelDistributions);
      distributions.forEach((distribution) => (distribution.amount = '0'));
      this.updateSelectedDistributionChannelDistributions(distributions);
    },
    onDistributeManualAmountsEvenly() {
      const numberOfDistributions = parseFloat(
        this.selectedDistributionChannelDistributions.length,
      );
      const totalBalance = parseFloat(this.totalBalanceOfManualAmounts());
      const diff = totalBalance - this.sumOfSelectedRevenues();
      const absDiff = Math.abs(diff);
      const isAdd = diff < 0;
      if (absDiff.toFixed(2) !== '0.00') {
        let toDistribute = 0.0;
        if (totalBalance === 0.0) {
          toDistribute = this.sumOfSelectedRevenues();
        } else {
          toDistribute = absDiff;
        }
        const spreadAmount = toDistribute / numberOfDistributions;
        const fixedSpreadAmount =
          this.selectedDistributionChannelDistributions.length *
          parseFloat(spreadAmount.toFixed(2));
        const leftOver =
          spreadAmount * this.selectedDistributionChannelDistributions.length - fixedSpreadAmount;
        const distributions = _.cloneDeep(this.selectedDistributionChannelDistributions);
        distributions.forEach(
          (distribution) =>
            (distribution.amount = (
              Number(distribution.amount) + (isAdd ? spreadAmount : -spreadAmount)
            ).toFixed(2)),
        );
        distributions[0].amount = (
          parseFloat(distributions[0].amount) + (isAdd ? leftOver : -leftOver)
        ).toFixed(2);
        this.updateSelectedDistributionChannelDistributions(distributions);
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.add-padding-left {
  padding-left: 20px;
}

.add-padding-right {
  padding-right: 20px;
}

.padding-break {
  padding: 10px;
}

.widen {
  width: 130px !important;
}
</style>
