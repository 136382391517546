<template>
  <div class="e2e-view-album--tags">
    <div class="row small-inner">
      <div class="col s3">
        <h2>Tags</h2>
      </div>
      <div class="text--right edit-link col s9">
        <a :class="{ disabled: routeVersion() || readonly }" @click="$emit('edit', $options.name)">
          <i class="fas fa-pencil-alt"></i>
          <template v-if="tags.length > 0">Edit</template>
          <template v-else>Add</template>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col s12 chips__wrapper">
        <template v-for="(tag, index) in displayTags">
          <tag :key="index" :tag="tag" />
        </template>
        <span v-if="tags.length === 0" class="none">None</span>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from './tag';
import AggregateMixin from '../../common/aggregateMixin';

export default {
  name: 'Tags',
  components: { Tag },
  mixins: [AggregateMixin],
  props: {
    tags: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      displayTags: [],
    };
  },
  created() {
    this.displayTags = this.tags.filter(
      (tag) => !this.$store.state.appdata.referenceData.tagsInternal.includes(tag),
    );
  },
};
</script>
