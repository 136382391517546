<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Redistribution</h1>
      </div>
    </div>
    <spinner v-if="loadingList" />
    <div v-else>
      <div class="controls-row">
        <div class="text-filter">
          <label for="input-filter">Filter</label>
          <input
            id="input-filter"
            v-model="textFilter"
            placeholder="Main artist, title or performer name"
            type="text"
          />
        </div>
        <div>
          <process-progress-bar v-if="sendProcessId" :id="sendProcessId" @finished="sendFinished" />
          <button v-else type="submit" class="btn" @click="onConfirmApproved">
            Confirm approved
          </button>
        </div>
      </div>
      <div class="row card">
        <redistribution-table
          :redistribution="redistribution"
          :text-filter="textFilter"
          @expand="expandRow"
          @contract="contractRow"
          @block="blockRow"
          @unblock="unblockRow"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import RedistributionTable from './redistribution-table';
import Spinner from '../../spinner';
import ProcessProgressBar from '../../process/process-progress-bar.vue';

export default {
  name: 'RedistributionOverview',
  components: {
    RedistributionTable,
    Spinner,
    ProcessProgressBar,
  },
  data() {
    return {
      loadingList: false,
      loadingDetails: false,
      textFilter: '',
      details: [],
      sendProcessId: null,
    };
  },
  computed: {
    redistribution() {
      return this.$store.state.distribution.redistribution;
    },
  },
  async created() {
    this.loadingList = true;
    await this.$store.dispatch('distribution/fetchRedist');
    this.loadingList = false;
  },
  methods: {
    async expandRow({ recording_id }) {
      this.$store.dispatch('distribution/expandRedistRow', { recording_id });
      await this.$store.dispatch('distribution/fetchRedistDetails', recording_id);
    },
    contractRow({ recording_id }) {
      this.$store.dispatch('distribution/contractRedistRow', { recording_id });
    },
    async blockRow({ recording_id }) {
      await this.$store.dispatch('distribution/blockRedistRow', { recording_id });
    },
    async unblockRow({ recording_id }) {
      await this.$store.dispatch('distribution/unblockRedistRow', { recording_id });
    },
    async removeRow({ recording_id }) {
      await this.$store.dispatch('distribution/removeRedistRow', { recording_id });
    },
    async onConfirmApproved() {
      this.sendProcessId = uuid.v4();
      this.$store.dispatch('process/addProcess', {
        name: 'Send corrections',
        id: this.sendProcessId,
      });

      try {
        await this.$store.dispatch('distribution/sendRedist', this.sendProcessId);
      } catch (e) {
        e.title = 'Failed to send corrections';
        this.$addStarError(e);
        this.$store.dispatch('process/removeProcess', this.sendProcessId);
      }
    },
    async sendFinished() {
      this.sendProcessId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-filter {
  flex: 0 1 25%;
}
.controls-row {
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
</style>
