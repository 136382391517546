/**
 * Helper service for edit performer tasks; such as fetch and update.
 */
import clone from '../../../../common/clone';
import { AGGREGATE_TYPES } from '../../../../domain/common';
import { createPerformerRelation } from '../../../../domain/mainArtistDomain';
import { getAggregate, dispatch } from '../../../../store/modules/performer/utils';
import MainArtistService from '../../../../services/mainArtistService';
import PerformerService from '../../../../services/performerService';
import UploadDocuments from '../../../../services/uploadDocuments';
import * as path from 'path';

async function update(updateFunction, aggregate, aggregateOwnerId) {
  const id = await updateFunction(clone(aggregate), aggregateOwnerId);
  const updatedAggregate = await PerformerService.getPerformer(id);
  dispatch('setPerformerAggregate', updatedAggregate);
  return updatedAggregate;
}

function updateMainArtists(performerAggregate) {
  if (!performerAggregate.mainartist_info) {
    return [];
  }

  const addCommands = performerAggregate.mainartist_info.new_mainartists.map((relation) =>
    MainArtistService.createAddPerformerMainArtistCommand(
      relation.mainArtistId,
      createPerformerRelation(performerAggregate.id, relation.instrument),
    ),
  );

  const removeCommands = performerAggregate.mainartist_info.removed_mainartists.map(
    (mainArtistId) =>
      MainArtistService.createRemovePerformerMainArtistCommand(mainArtistId, performerAggregate.id),
  );

  return [...addCommands, ...removeCommands];
}

function aggregateMandates(mandateGroups) {
  const mandateAggregate = { mandates: [] };
  mandateGroups.forEach((group) => {
    group.mandates.forEach((mandate) => {
      if (mandate.society_code) {
        delete mandate.conflicts;
        if (mandate.society_code) {
          mandate.right = group.right;
          mandateAggregate.mandates.push(mandate);
        }
      }
    });
  });
  return mandateAggregate;
}

function overrideFileNames(documents, performer) {
  const ipn = performer.membership_info.ipn || 'ipn';
  const first = performer.general_info.first_name || 'first';
  const last = performer.general_info.last_name || 'last';

  return documents.map((doc) => {
    if (doc.type === 'US_TAX_FORM') {
      const ext = path.extname(doc.fileHandle.name);
      doc.fileName = `${ipn}_${first}_${last}_W-8BEN${ext}`;
    }

    return doc;
  });
}

export default {
  getCurrentPerformer: () => clone(getAggregate('performer')),
  updateGeneralInfo: (performer) => update(PerformerService.updatePerformerGeneralInfo, performer),
  updateContactInfo: (performer) => update(PerformerService.updatePerformerContactInfo, performer),
  updateMandateInfo: (performer) =>
    update(
      PerformerService.updatePerformerMandateInfo,
      aggregateMandates(performer.mandates),
      performer.id,
    ),
  updateMembershipInfo: (performer) =>
    update(PerformerService.updatePerformerMembershipInfo, performer),
  updateMainArtists: (performer) => updateMainArtists(performer),
  updateTaxInfo: (performer) => update(PerformerService.updatePerformerTaxInfo, performer),
  updateIntermediaryInfo: (performer) =>
    update(PerformerService.updatePerformerIntermediaryInfo, performer),
  updateMetadataInfo: (performer) =>
    update(PerformerService.updatePerformerMetadataInfo, performer),
  updatePaymentInfo: (paymentInfo, performerId) =>
    update(PerformerService.updatePerformerPaymentInfo, paymentInfo, performerId),
  updateDocumentsInfo: async (newDocuments, performer) => {
    if (newDocuments && newDocuments.length > 0) {
      await UploadDocuments(AGGREGATE_TYPES.PERFORMER, overrideFileNames(newDocuments, performer));
      performer.documents.push(...newDocuments);
    }
    return update(
      PerformerService.updatePerformerDocuments,
      { documents: performer.documents.filter((doc) => doc.id !== '') },
      performer.id,
    );
  },
  updatePerformerAssociates: (associate, performerId) =>
    update(PerformerService.updatePerformerAssociates, associate, performerId),
  removePerformerAssociate: (associateId, performerId) =>
    update(PerformerService.removePerformerAssociate, { id: associateId }, performerId),
};
