<template>
  <div v-if="orgNo && vatNo !== expectedVatNo" class="associate-vat-warning">
    <div>
      <span class="pre">You entered&#32;</span>
      <em class="code pre">{{ vatNo || '(nothing)' }}</em>
      <span class="pre">
        &#32;as the VAT number, the expected VAT number for this associate is&#32;
      </span>
      <em class="code pre">{{ expectedVatNo }}</em>
      <span class="pre">.</span>
    </div>
    <div v-if="displayButton" class="use-btn-container">
      <button class="btn" @click.prevent="useExpectedVatNo">
        Use "{{ expectedVatNo }}" instead
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssociateVatWarning',
  props: {
    orgNo: {
      type: String,
      required: true,
    },
    vatNo: {
      type: String,
      required: true,
    },
    displayButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    expectedVatNo() {
      return 'SE' + this.orgNo?.trim().replaceAll('-', '').substr(-10) + '01';
    },
  },
  methods: {
    useExpectedVatNo() {
      this.$emit('useExpectedVatNo', this.expectedVatNo);
    },
  },
};
</script>

<style scoped>
.associate-vat-warning {
  background-color: lightgoldenrodyellow;
  border: orange solid 1px;
  margin-bottom: 10px;
  padding: 10px;
}

.code {
  background-color: white;
  border: orange solid 1px;
  font-style: normal;
  font-family: monospace;
  padding: 2px 4px;
}

.use-btn-container {
  margin-top: 10px;
}

.pre {
  white-space: pre;
}
</style>
