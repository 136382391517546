<template>
  <form class="card">
    <div class="row">
      <div class="col s6">
        <input id="manualPayment" v-model="payment.isManual" type="checkbox" class="filled-in" />
        <label for="manualPayment">Manual payment</label>
      </div>
    </div>
    <div class="row">
      <div class="col s6">
        <select-society
          label="Choose society/members"
          :rule="'required'"
          :value="societyId"
          item-key="id"
          item-value="name"
          @input="setSocietyId"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s6">
        <validated-text-input
          v-model="payment.name"
          name="paymentName"
          label="Name"
          :rule="'required'"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s3">
        <select-date
          v-model="payment.paymentDate"
          name="paymentDate"
          label="Payment date"
          initial-view="day"
          :rule="{ required: true, after: Date.now(), before: beforeDate }"
        />
      </div>
    </div>
    <div class="row flush--bottom">
      <div class="col s12">
        <div class="float-right">
          <action-buttons
            submit-label="Create payment"
            :show-abort="false"
            :disable-submit="isSubmitted"
            @save="onSubmit()"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import ActionButtons from '../ui/button/action-buttons';
import SelectDate from '../ui/select/select-date';
import SelectSociety from '../ui/select/select-society';
import ValidatedTextInput from '../ui/input/validated-text-input';
import * as uuid from 'uuid';
import { mutate } from '../../services/apolloRequest';
import { paymentCommand } from '../../store/modules/payment/store';

export default {
  name: 'ProgressCreatePayment',
  components: {
    ActionButtons,
    SelectDate,
    SelectSociety,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  data() {
    return {
      payment: {
        name: undefined,
        societyId: undefined,
        paymentDate: undefined,
        minLimit: 20000,
        isManual: false,
      },
      isSubmitted: false,
      filterSocietyIds: null,
      manualPayment: false,
    };
  },
  computed: {
    beforeDate() {
      return moment().add(1, 'month').format('YYYY-MM-DD');
    },
    societies() {
      return this.$store.state.appdata.societies;
    },
    societyId() {
      return this.payment.societyId;
    },
  },
  created() {
    this.payment.paymentDate = moment(Date.now()).add(1, 'day').format('YYYY-MM-DD');
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      try {
        this.isSubmitted = true;
        const id = uuid.v4();
        const processId = uuid.v4();
        await mutate(
          paymentCommand({
            process_id: processId,
            stream_id: id,
            type: 'payment/create',
            payload: {
              name: this.payment.name,
              societyId: this.payment.societyId,
              paymentDate: this.payment.paymentDate,
              minLimit: this.payment.minLimit,
              isManual: this.payment.isManual,
            },
          }),
        );
        await new Promise((resolve) => setTimeout(resolve, 1500));
        this.$router.push('/payments');
      } catch (e) {
        this.$addStarError(e);
      } finally {
        this.loading = false;
      }
    },
    setSocietyId(id) {
      const soc = this.societies.find((s) => s.id === id);
      if (!soc) {
        throw new Error(`Can not find society with id ${id}`);
      }
      this.payment.societyId = soc.id;
      if (soc.code === 'SAMI') {
        this.payment.minLimit = 20000;
      } else {
        this.payment.minLimit = 1;
      }
    },
  },
};
</script>
