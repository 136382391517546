<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h1>Mediarc</h1>
      </div>
    </div>
    <div class="row wrapper">
      <div class="col s12">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link :to="{ name: 'mediarcRequestView' }">Request</router-link>
            <router-link :to="{ name: 'mediarcMatchingView' }">Matching</router-link>
          </div>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'ViewMediarc',
};
</script>
