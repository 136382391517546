import { addToWorklist, deleteWorklist } from '../../../services/worklistsService';

const CURRENT_HEADER = 'currentHeader';
const eligibleRoutes = [
  'musicReportCandidatesView',
  'viewSocietySDEGPlaylistLinesToCheck',
  'incompleteRecordingsView',
  'viewSdegPlayedRecordingsReportNew',
];

async function addToPersonalWorklist(item, userName) {
  const data = JSON.stringify({
    payload: [
      {
        added: new Date(),
        name: item.name,
        category: item.category,
        url: item.url,
        note: item.note,
      },
    ],
  });
  await addToWorklist(data, userName);
}

async function deleteFromPersonalWorklist(items, userName) {
  await deleteWorklist({ worklistId: userName, assignee: userName });
  const data = JSON.stringify({ payload: items });
  await addToWorklist(data, userName);
}

function getCurrentHeader() {
  const currentHeader = localStorage.getItem(CURRENT_HEADER);
  return currentHeader || 'Star';
}

function pageEligibleForPublicWorklist(currentRoute) {
  if (currentRoute.name === 'payment') {
    return currentRoute.query.row_state
      ? currentRoute.query.row_state === 'E' && !currentRoute.query.worklist_id
      : !currentRoute.query.worklist_id;
  } else if (eligibleRoutes.includes(currentRoute.name)) {
    return !currentRoute.query.worklist_id;
  }
  return false;
}

export default {
  addToPersonalWorklist,
  deleteFromPersonalWorklist,
  pageEligibleForPublicWorklist,
  getCurrentHeader,
};
