import ViewFinanceReportYear from './view-finance-report-year.vue';
import ViewFinanceReportMonth from './view-finance-report-month.vue';
import ViewFinanceReport from './view-finance-report.vue';

export default [
  // Financial reports
  {
    path: '/finance/reports',
    name: 'financeReportYearDefaultView',
    component: ViewFinanceReportYear,
    props: true,
    meta: {
      title: 'Financial Reports',
    },
  },
  {
    path: '/finance/reports/:year',
    name: 'financeReportYearView',
    component: ViewFinanceReportYear,
    props: true,
    meta: {
      title: 'Financial Reports',
    },
  },
  {
    path: '/finance/reports/:year/:monthId',
    name: 'financeReportMonthView',
    component: ViewFinanceReportMonth,
    props: true,
    meta: {
      title: 'Financial Reports',
    },
  },
  {
    path: '/finance/reports/:year/:monthId/:fileName',
    name: 'financeReportFileView',
    component: ViewFinanceReport,
    props: true,
  },
];
