<template>
  <div class="flex flex__options">
    <div v-visible="bulkActions.length > 0" class="flex flex__item">
      <div class="flex__text">Bulk</div>
      <div class="flex__modifier">
        <select-input
          name="bulk"
          :value="selectedBulkAction"
          :items="bulkActions"
          :disabled="!bulkActionEnabled"
          item-key="key"
          item-value="value"
          @input="onBulkActionChanged"
        />
      </div>
    </div>

    <div v-visible="customSelect" class="flex flex__item">
      <div class="flex__text">
        {{ customSelect && customSelect.name }}
      </div>
      <div class="flex__modifier">
        <select-input
          name="custom-select"
          :value="customSelect && customSelect.selected"
          :items="customSelect && customSelect.items"
          :item-key="customSelect && customSelect.itemKey"
          :item-value="customSelect && customSelect.itemValue"
          @input="onCustomSelectChanged"
        />
      </div>
    </div>

    <div v-visible="countries && countries.length" class="flex flex__item">
      <div class="flex__text">Recorded in country</div>
      <div class="flex__modifier">
        <select-country
          label=""
          :value="recordedInCountryCode"
          :input-countries="countries"
          @input="onCountryChange"
        />
      </div>
    </div>

    <div v-visible="worklists.length" class="flex flex__item">
      <div class="flex__text">Worklist</div>
      <div class="flex__modifier">
        <select-input
          name="worklist"
          :value="worklistId"
          :items="worklists"
          item-key="worklistId"
          item-value="name"
          @input="onWorklistChanged"
        />
      </div>
    </div>

    <!-- range selector must be the last div to ensure right alignment -->
    <div v-visible="rangeEnabled" class="flex flex__item range-selector">
      <div class="flex__text">Range</div>
      <div class="flex__modifier">
        <input
          name="rangeStart"
          type="text"
          autocomplete="off"
          :value="rangeStart"
          placeholder="E.g. A"
          @input="updateRangeStart($event.target.value)"
        />
      </div>
      <div class="flex__modifier">
        <input
          name="rangeEnd"
          type="text"
          autocomplete="off"
          :value="rangeEnd"
          placeholder="E.g. B"
          @input="updateRangeEnd($event.target.value)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SelectCountry from '../select/select-country';
import SelectInput from '../select/select-input';

export default {
  name: 'TableOptions',
  components: { SelectCountry, SelectInput },
  directives: {
    visible: (el, binding) => {
      el.style.visibility = binding.value ? 'visible' : 'hidden';
    },
  },
  props: {
    countries: { type: Array },
    label: { type: String, default: 'Country' },
    bulkActions: { type: Array, default: () => [] },
    bulkActionEnabled: { type: Boolean, default: false },
    recordedInCountryCode: { type: String, default: '' },
    worklists: { type: Array, default: () => [] },
    worklistId: { type: String },
    rangeEnabled: { type: Boolean, default: true },
    customSelect: { type: Object, default: null },
    range: { type: Object, default: () => ({ start: '', end: '' }) },
  },
  data() {
    return {
      selectedBulkAction: '',
    };
  },
  computed: {
    rangeStart() {
      return this.range.start;
    },
    rangeEnd() {
      return this.range.end;
    },
  },
  methods: {
    updateRangeStart(value) {
      this.$emit('rangeStartChanged', value);
    },
    updateRangeEnd(value) {
      this.$emit('rangeEndChanged', value);
    },
    onCountryChange(value) {
      this.$emit('countryChanged', value);
    },
    onBulkActionChanged(value) {
      this.selectedBulkAction = value;
      this.$emit('bulkAction', value ? value.toLowerCase() : null);
      setTimeout(() => {
        this.selectedBulkAction = '';
      }, 400);
    },
    onWorklistChanged(value) {
      this.$emit('worklistChanged', value);
    },
    onCustomSelectChanged(value) {
      this.$emit('customSelectChanged', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  &__options {
    justify-content: space-between;
  }
  &__item {
    flex: 1;
    align-items: center;
    justify-content: center;
    &:first-of-type {
      justify-content: flex-start;
      &.range-selector {
        justify-content: flex-end;
      }
    }
    &:last-of-type:not(:first-of-type) {
      justify-content: flex-end;
    }
  }
  &__text {
    margin-right: var(--spacing);
  }
  &__modifier {
    & + & {
      margin-left: var(--spacing);
    }
    .div {
      width: 180px !important;
    }
  }
}
</style>
