<template>
  <div class="row flush--bottom">
    <div class="row flush--bottom float-right">
      <action-buttons
        v-if="editMode"
        class="col s12"
        scope="contact-person"
        @save="onSave"
        @cancel="onCancel"
      />
    </div>

    <div class="col s12 card">
      <form data-vv-scope="contact-person">
        <div class="row">
          <div class="col s12 l8">
            <validated-text-input
              v-model="contactPersonData.name"
              name="name"
              label="Contact person"
              placeholder="E.g. Michael Caine"
              @input="emit"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s8 l8">
            <addable-text-input
              name="email"
              label="Email"
              :mandatory="true"
              placeholder="E.g. michael.caine@gmail.com"
              :rule="{ required: true, email: true }"
              scope="contact-person"
              :values="contactPersonData.emails"
              :disabled="!contactPersonData.name"
              @input="updateEmail"
              @remove="removeEmail"
              @add="addEmail"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s11 l8">
            <phone-number-input
              name="phone_numbers"
              label="Phone number"
              :phone-numbers="contactPersonData.phone_numbers"
              :disabled="!contactPersonData.name"
              scope="contact-person"
              @input="emit"
              @remove="removePhoneNumber"
              @add="addPhoneNumber()"
            />
          </div>
        </div>

        <div class="row flush--bottom">
          <div class="col s12 l8">
            <validated-text-input
              v-model="contactPersonData.comment"
              name="comment"
              label="Comment"
              placeholder="E.g. artistansvarig"
              :disabled="!contactPersonData.name"
              rule=""
              @input="emit"
            />
          </div>
        </div>
      </form>
    </div>
    <delete-button v-if="deletable" :is-component="true" @click="onRemove" />
  </div>
</template>

<script>
import _ from 'lodash';

import { createPhoneNumber } from '../../../domain/common';
import ActionButtons from '../../ui/button/action-buttons';
import AddableTextInput from '../../ui/input/addable-text-input';
import DeleteButton from '../../ui/button/delete-button';
import PhoneNumberInput from '../../ui/input/phone-number-input';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'ContactPerson',
  components: {
    ActionButtons,
    AddableTextInput,
    DeleteButton,
    PhoneNumberInput,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  props: {
    contactPerson: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contactPersonData: _.cloneDeep(this.contactPerson),
    };
  },
  computed: {
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  created() {
    if (!this.contactPersonData.emails || this.contactPersonData.emails.length === 0) {
      this.addEmail();
    }
    if (!this.contactPersonData.phone_numbers) {
      this.addPhoneNumberList();
    }
    if (this.contactPersonData.phone_numbers.length === 0) {
      this.addPhoneNumber();
    }
  },
  methods: {
    addEmail() {
      this.contactPersonData.emails.push('');
      this.emit();
    },
    addPhoneNumberList() {
      this.contactPersonData.phone_numbers = [];
    },
    addPhoneNumber() {
      this.contactPersonData.phone_numbers.push(createPhoneNumber());
      this.emit();
    },
    removePhoneNumber(index) {
      this.contactPersonData.phone_numbers.splice(index, 1);
      this.emit();
    },
    updateEmail(valueUpdate) {
      this.$set(this.contactPersonData.emails, valueUpdate.index, valueUpdate.value);
      this.emit();
    },
    removeEmail(index) {
      this.contactPersonData.emails.splice(index, 1);
      this.emit();
    },
    onPhoneNumberUpdated() {
      this.emit();
    },
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    onSave() {
      this.$emit('save', this.contactPersonData);
    },
    emit() {
      this.$emit('input', this.contactPersonData);
    },
    onRemove(index) {
      this.$emit('remove', index);
    },
  },
};
</script>
