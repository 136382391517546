<template>
  <div>
    <div v-if="loading" class="text--center">
      <simple-spinner />
    </div>
    <div v-if="isMigratedPayment">
      Track breakdown is not supported for payments before 2019-04-01
    </div>
    <div v-else>
      <div class="filters">
        <div class="main-artist-filter">
          <filter-input
            label="Filter Main Artist"
            :delay-ms="500"
            :filter-query="mainartistFilter"
            @input="setMainartistFilter"
          />
        </div>
        <div class="title-filter">
          <filter-input
            label="Filter Title"
            :delay-ms="500"
            :filter-query="titleFilter"
            @input="setTitleFilter"
          />
        </div>
        <div class="source-filter">
          <select-input
            name="source"
            label="Source"
            :items="sourceOptions"
            @input="setSourceFilter"
          />
        </div>
        <div class="year-filter">
          <select-input
            name="year"
            label="Performance Year"
            :items="yearOptions"
            @input="setYearFilter"
          />
        </div>
      </div>
      <div v-if="loadingRems" class="text--center">
        <simple-spinner />
      </div>
      <div v-else>
        <table>
          <thead>
            <th></th>
            <th @click="sortBy(SORT.DATE)">
              Date
              <span :class="sortClass(SORT.DATE)"></span>
            </th>
            <th @click="sortBy(SORT.MAIN_ARTIST)">
              Main Artist
              <span :class="sortClass(SORT.MAIN_ARTIST)"></span>
            </th>
            <th @click="sortBy(SORT.TITLE)">
              Title
              <span :class="sortClass(SORT.TITLE)"></span>
            </th>
            <th @click="sortBy(SORT.SOURCE)">
              Source
              <span :class="sortClass(SORT.SOURCE)"></span>
            </th>
            <th @click="sortBy(SORT.AREA)">
              Distribution Area
              <span :class="sortClass(SORT.AREA)"></span>
            </th>
            <th @click="sortBy(SORT.RIGHT)">
              Right
              <span :class="sortClass(SORT.RIGHT)"></span>
            </th>
            <th @click="sortBy(SORT.YEAR)">
              Year
              <span :class="sortClass(SORT.YEAR)"></span>
            </th>
            <th>Territory</th>
            <th class="text--right" @click="sortBy(SORT.AMOUNT)">
              Net
              <span :class="sortClass(SORT.AMOUNT)"></span>
            </th>
          </thead>
          <tbody>
            <template v-for="rem of remunerations">
              <tr :key="rem.id" :class="{ migrated: !!rem.migrationKey }">
                <td>
                  <span
                    v-if="isExpandable(rem)"
                    class="expandable fas"
                    :class="isExpanded(rem) ? 'fa-caret-up' : 'fa-caret-down'"
                    @click="toggleExpand(rem)"
                  />
                </td>
                <td>
                  {{ $filters.dateFormat(new Date(rem.createdAt), 'YYYY-MM-DD') }}
                  <span v-if="rem.migratedGeneric">*</span>
                </td>
                <td>{{ rem.mainArtist }}</td>
                <td>
                  <router-link
                    v-if="rem.recordingId"
                    :to="`/recording/${rem.recordingId}/information`"
                  >
                    {{ rem.title }}
                  </router-link>
                  <span v-else>{{ rem.title }}</span>
                </td>
                <td>{{ rem.sourceName }}</td>
                <td>{{ rem.areaName || 'N/A' }}</td>
                <td>{{ $filters.translate(`mandate.right.${rem.right.toLowerCase()}`) }}</td>
                <td>{{ rem.year }}</td>
                <td>{{ rem.territory }}</td>
                <td class="net text--right">
                  {{ $filters.formatAmountCent(rem.net, 'SEK') }}
                </td>
              </tr>
              <template v-if="isExpanded(rem)">
                <tr v-for="migRem of migratedRows(rem)" :key="migRem.id" class="migrated">
                  <td></td>
                  <td>
                    {{ $filters.dateFormat(new Date(migRem.createdAt), 'YYYY-MM-DD') }}
                  </td>
                  <td>{{ migRem.mainArtist }}</td>
                  <td>
                    <router-link
                      v-if="migRem.recordingId"
                      :to="`/recording/${migRem.recordingId}/information`"
                    >
                      {{ migRem.title }}
                    </router-link>
                    <span v-else>{{ migRem.title }}</span>
                  </td>
                  <td>{{ migRem.sourceName }}</td>
                  <td>{{ migRem.areaName }}</td>
                  <td>{{ $filters.translate(`mandate.right.${migRem.right.toLowerCase()}`) }}</td>
                  <td>{{ migRem.year }}</td>
                  <td>{{ migRem.territory }}</td>
                  <td class="net text--right">
                    {{ $filters.formatAmountCent(migRem.net, 'SEK') }}
                  </td>
                </tr>
                <tr :key="`${rem.id}-load`">
                  <td colspan="9" class="text--center">
                    <simple-spinner v-if="isExpandLoading(rem)" />
                    <button
                      v-else-if="migratedRemaining(rem) > 0"
                      class="btn"
                      @click="fetchMigrated(rem)"
                    >
                      Load {{ migrationLimit }} more ({{ migratedRemaining(rem) }} remaining)
                    </button>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="10" class="text--right">
                <strong>Total: {{ $filters.formatAmountCent(totalAmount, 'SEK') }}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
        <pagination
          :number-of-pages="numberOfPages"
          :number-of-hits="totalCount"
          :selected-page="Math.floor(pagination.offset / pagination.limit) + 1"
          :hits-per-page="pagination.limit"
          @selectPage="selectPage"
          @updateHitsPerPage="updateHitsPerPage"
        />
        <div v-if="hasMigrated">
          <i>* Date and contents of migrated remunerations may be inaccurate.</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { query } from '../../../services/apolloRequest';
import SelectInput from '../../ui/select/select-input';
import FilterInput from '../../ui/input/filter-input';
import Pagination from '../../pagination';
import SimpleSpinner from '../../ui/simple-spinner.vue';
const SORT = {
  DATE: 'created_at',
  YEAR: 'year',
  TITLE: 'title',
  AMOUNT: 'amount',
  MAIN_ARTIST: 'main_artist',
  AREA: 'area_name',
  SOURCE: 'source_name',
  RIGHT: 'right',
};

export default {
  components: {
    SelectInput,
    FilterInput,
    Pagination,
    SimpleSpinner,
  },
  props: {
    paymentDate: String,
    specIds: Array,
    isMigratedPayment: Boolean,
    id: String,
  },
  data() {
    return {
      loading: false,
      loadingRems: false,
      tx: null,
      years: [],
      sources: [],
      remunerations: [],
      migratedRemunerations: {},
      migratedCounts: {},
      totalCount: 0,
      sort: {
        by: null,
        order: 0,
      },
      pagination: {
        offset: 0,
        limit: 25,
      },
      migrationLimit: 25,
      titleFilter: null,
      sourceFilter: null,
      yearFilter: null,
      SORT,
      expanded: [],
      expandLoading: [],
      mainartistFilter: null,
    };
  },
  computed: {
    hasMigrated() {
      return !!this.remunerations.find((r) => r.migratedGeneric);
    },
    canExpandRows() {
      return this.$route.meta?.nodeType === 'performer';
    },
    numberOfPages() {
      return this.totalCount > 0 ? Math.ceil(this.totalCount / this.pagination.limit) : 0;
    },
    totalAmount() {
      return this.remunerations.reduce((tot, r) => tot + r.net, 0);
    },
    yearOptions() {
      const years = this.years.map((year) => ({
        name: year,
        code: year,
      }));

      // sort in descending order
      years.sort((a, b) => b.name - a.name);

      return years;
    },
    sourceOptions() {
      return this.sources.map(({ id, name }) => ({
        code: id,
        name,
      }));
    },
  },
  async created() {
    if (!this.isMigratedPayment) {
      this.loading = true;
      await this.fetchSpecStats();
      await this.fetchRemunerations();
      this.loading = false;
    }
  },
  methods: {
    sortClass(col) {
      const names = ['fas'];
      if (this.sort.by === col) {
        names.push('active');
      }
      if (this.sort.by === col && this.sort.order === -1) {
        names.push('fa-caret-down');
      } else {
        names.push('fa-caret-up');
      }
      return names.join(' ');
    },
    setMainartistFilter(val) {
      this.mainartistFilter = val;
      this.pagination.offset = 0;
      this.fetchRemunerations();
    },
    migratedRemaining(rem) {
      return (this.migratedCounts[rem.id] || 0) - (this.migratedRemunerations[rem.id]?.length || 0);
    },
    toggleExpand(rem) {
      if (this.isExpanded(rem)) {
        this.contract(rem);
      } else {
        this.expand(rem);
      }
    },
    contract(rem) {
      this.expanded = this.expanded.filter((id) => id !== rem.id);
    },
    async expand(rem) {
      this.expanded = [...this.expanded, rem.id];
      if (!(rem.id in this.migratedRemunerations)) {
        this.expandLoading = [...this.expandLoading, rem.id];
        await this.fetchMigrated(rem);
        await this.countMigrated(rem);
        this.expandLoading = this.expandLoading.filter((id) => id !== rem.id);
      }
    },
    migratedRows(rem) {
      return this.migratedRemunerations[rem.id];
    },
    isExpandable(rem) {
      return this.canExpandRows && !!rem.migratedGeneric;
    },
    isExpanded(rem) {
      return this.expanded.includes(rem.id);
    },
    isExpandLoading(rem) {
      return this.expandLoading.includes(rem.id);
    },
    selectPage(pageNo) {
      this.pagination.offset = this.pagination.limit * (pageNo - 1);
      this.fetchRemunerations();
    },
    updateHitsPerPage(limit) {
      this.pagination.limit = Number(limit);
      this.pagination.offset = 0;
      this.fetchRemunerations();
    },
    sortBy(val) {
      const oldSort = this.sort.by;
      this.sort.by = val;
      if (this.sort.order === 0) {
        this.sort.order = 1;
      }
      if (val === oldSort) {
        this.sort.order = -this.sort.order;
      }
      this.pagination.offset = 0;
      this.fetchRemunerations();
    },
    setTitleFilter(val) {
      this.titleFilter = val;
      this.pagination.offset = 0;
      this.fetchRemunerations();
    },
    setSourceFilter(val) {
      this.sourceFilter = val;
      this.pagination.offset = 0;
      this.fetchRemunerations();
    },
    setYearFilter(val) {
      this.yearFilter = val;
      this.pagination.offset = 0;
      this.fetchRemunerations();
    },
    async countMigrated(rem) {
      const { countMigrated } = await query({
        query: gql`
          query CountMigrated($options: MigratedRemunerationOptions!) {
            countMigrated(options: $options)
          }
        `,
        variables: {
          options: {
            migrationKey: rem.id,
            performerId: this.id,
            paymentDate: this.paymentDate,
          },
        },
      });
      this.migratedCounts[rem.id] = countMigrated;
    },
    async fetchMigrated(rem) {
      const offset = this.migratedRemunerations[rem.id]?.length || 0;
      const { migratedRemunerations } = await query({
        query: gql`
          query MigratedRemunerations($options: MigratedRemunerationOptions!) {
            migratedRemunerations(options: $options) {
              createdAt
              areaId
              areaName
              sourceId
              sourceName
              year
              territory
              title
              recordingId
              mainArtist
              net
              right
            }
          }
        `,
        variables: {
          options: {
            migrationKey: rem.id,
            performerId: this.id,
            paymentDate: this.paymentDate,
            offset,
            limit: this.migratedLimit,
          },
        },
      });
      this.migratedRemunerations = {
        ...this.migratedRemunerations,
        [rem.id]: [...(this.migratedRemunerations[rem.id] || []), ...migratedRemunerations],
      };
    },
    async fetchSpecStats() {
      try {
        const { specStats } = await query({
          query: gql`
            query SpecStats($specIds: [String]!) {
              specStats(specIds: $specIds) {
                years
                sources {
                  id
                  name
                }
                count
              }
            }
          `,
          variables: {
            specIds: this.specIds,
          },
        });
        this.years = specStats.years;
        this.sources = specStats.sources;
        this.totalCount = specStats.count;
      } catch (err) {
        console.error(err);
        this.$addStarError(`Could not fetch specification summary`);
      }
    },
    async fetchRemunerations() {
      this.loadingRems = true;
      try {
        const options = {
          offset: this.pagination.offset,
          limit: this.pagination.limit,
        };
        if (this.yearFilter) {
          options.performanceYear = this.yearFilter;
        }
        if (this.sourceFilter) {
          options.sourceId = this.sourceFilter;
        }
        if (this.titleFilter) {
          options.titleSearch = `%${this.titleFilter}%`;
        }
        if (this.mainartistFilter) {
          options.mainArtistSearch = `%${this.mainartistFilter}%`;
        }
        if (this.sort.by) {
          options.orderBy = this.sort.by;
        }
        if (this.sort.order) {
          options.dir = this.sort.order > 0 ? 'ASC' : 'DESC';
        }
        const { specRemunerations } = await query({
          query: gql`
            query SpecRemunerations($specIds: [String]!, $options: RemunerationQuery) {
              specRemunerations(specIds: $specIds, options: $options) {
                remunerations {
                  id
                  createdAt
                  areaId
                  areaName
                  sourceId
                  sourceName
                  year
                  territory
                  title
                  recordingId
                  mainArtist
                  net
                  performerId
                  migratedGeneric
                  migrationKey
                  right
                }
                totalCount
              }
            }
          `,
          variables: {
            specIds: this.specIds,
            options,
          },
        });
        this.remunerations = specRemunerations.remunerations;
        this.totalCount = specRemunerations.totalCount;
      } catch (err) {
        console.error(err);
        this.$addStarError(`Could not fetch remunerations`);
      } finally {
        this.loadingRems = false;
      }
    },
  },
};
</script>

<style scoped>
.filters {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}
.source-filter,
.year-filter,
.title-filter {
  flex: 1 1 22%;
}
.expandable {
  cursor: pointer;
}
.migrated :first-child {
  background-color: var(--sami--bgc);
}
th .fas {
  opacity: 0.2;
}
th .fas.active {
  opacity: 1;
}
</style>
