import { generateMutations, generateGetters } from '../../utils';
import * as domain from '../../../domain/distributionDomain';
import gql from 'graphql-tag';
import * as uuid from 'uuid';
import { mutate, query } from '../../../services/apolloRequest';

const mapRedistSummary = (redist) => ({
  ...redist,
  expanded: false,
  details: [],
});

const distCommand = gql`
  mutation handleCommand($target: CommandTarget!, $command: CommandInput!) {
    handleCommand(target: $target, command: $command) {
      processId
    }
  }
`;

// The root, initial state object
const rootState = {
  aggregate: domain.createDistributionYearAggregate(),
  selectedDistribution: domain.createDistribution(),
  redistribution: [],
};

const actions = {
  createAggregate(context) {
    context.commit('CREATE_AGGREGATE');
  },
  setAggregate(context, aggregate) {
    context.commit('SET_AGGREGATE', aggregate);
  },
  async fetchRedist(context) {
    const redist = await query(
      {
        query: gql`
          query {
            redistSummaries {
              recording_id
              title
              main_artist
              abs_amount
              amount
              performers
              blocked_by
              block_date
              juris_changed
            }
          }
        `,
      },
      {
        pickProp: 'redistSummaries',
      },
    );
    context.dispatch('setRedist', redist.map(mapRedistSummary));
  },
  async fetchRedistRow(context, recordingId) {
    const [row] = await query(
      {
        query: gql`
          query redistSummaries($recordingId: String!) {
            redistSummaries(recordingId: $recordingId) {
              recording_id
              title
              main_artist
              abs_amount
              amount
              performers
              blocked_by
              block_date
              juris_changed
            }
          }
        `,
        variables: {
          recordingId,
        },
      },
      {
        pickProp: 'redistSummaries',
      },
    );
    context.commit('updateRedistRow', { ...row, loading: false });
  },
  async fetchRedistDetails(context, recordingId) {
    const details = await query(
      {
        query: gql`
          query redistDetails($recordingId: String!) {
            redistDetails(recordingId: $recordingId) {
              performer_id
              name
              role_code
              dist_amount
              redist_amount
              tot_amount
            }
          }
        `,
        variables: { recordingId },
      },
      { pickProp: 'redistDetails' },
    );
    context.dispatch('setRedistDetails', { recording_id: recordingId, details });
  },
  expandRedistRow(context, { recording_id }) {
    context.commit('updateRedistRow', { recording_id, expanded: true });
  },
  contractRedistRow(context, { recording_id }) {
    context.commit('updateRedistRow', { recording_id, expanded: false });
  },
  setRedist(context, redist) {
    context.commit('setRedist', redist);
  },
  setRedistDetails(context, { recording_id, details }) {
    context.commit('updateRedistRow', { recording_id, details });
  },
  async blockRedistRow(context, { recording_id }) {
    context.commit('updateRedistRow', { recording_id, loading: true });
    await mutate({
      mutation: distCommand,
      variables: {
        target: 'distribution',
        command: {
          type: 'redist/block',
          stream_id: recording_id,
          payload: {},
        },
      },
    });
    return context.dispatch('fetchRedistRow', recording_id);
  },
  async unblockRedistRow(context, { recording_id }) {
    context.commit('updateRedistRow', { recording_id, loading: true });
    await mutate({
      mutation: distCommand,
      variables: {
        target: 'distribution',
        command: {
          type: 'redist/unblock',
          stream_id: recording_id,
          payload: {},
        },
      },
    });
    return context.dispatch('fetchRedistRow', recording_id);
  },
  async removeRedistRow(context, recId) {
    context.commit('updateRedistRow', { recording_id: recId, loading: true });
    await mutate({
      mutation: distCommand,
      variables: {
        target: 'distribution',
        command: {
          type: 'redist/remove',
          stream_id: recId,
          payload: {},
        },
      },
    });
    context.commit('removeRedistRow', recId);
  },
  async addRecordingToQueue(context, recId) {
    context.commit('updateAggregate', { redistLoading: true });
    try {
      await mutate({
        mutation: distCommand,
        variables: {
          target: 'distribution',
          command: {
            type: 'redist/add',
            stream_id: recId,
            payload: {},
          },
        },
      });
    } finally {
      context.commit('updateAggregate', { redistLoading: false });
    }
  },
  async startRedist(context, processId = uuid.v4()) {
    await mutate({
      mutation: distCommand,
      variables: {
        target: 'distribution',
        command: {
          type: 'redist/start',
          stream_id: uuid.v4(),
          process_id: processId,
          payload: {},
        },
      },
    });
  },
  async sendRedist(context, processId = uuid.v4()) {
    await mutate({
      mutation: distCommand,
      variables: {
        target: 'distribution',
        command: {
          type: 'redist/send',
          stream_id: uuid.v4(),
          process_id: processId,
          payload: {},
        },
      },
    });
  },
};

const mutations = {
  ...generateMutations(rootState),
  CREATE_AGGREGATE(state) {
    Object.assign(state.aggregate, domain.createDistributionYearAggregate());
    Object.assign(state.selectedDistribution, domain.createDistribution());
  },
  SET_AGGREGATE(state, aggregate) {
    Object.assign(state.aggregate, aggregate);
  },
  updateAggregate(state, data) {
    state.aggregate = {
      ...state.aggregate,
      ...data,
    };
  },
  setRedist(state, redist) {
    state.redistribution = redist;
  },
  updateRedistRow(state, { recording_id: recId, ...row }) {
    const idx = state.redistribution.findIndex((r) => r.recording_id === recId);
    if (idx === -1) {
      throw new Error(`Can not find a redistribution row for recording id ${recId}`);
    }
    const updatedRow = {
      ...state.redistribution[idx],
      ...row,
    };
    state.redistribution = [
      ...state.redistribution.slice(0, idx),
      updatedRow,
      ...state.redistribution.slice(idx + 1),
    ];
  },
  removeRedistRow(state, recId) {
    state.redistribution = state.redistribution.filter((row) => row.recording_id !== recId);
  },
};

const getters = {
  ...generateGetters(rootState),
  aggregate: (state) => state,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
