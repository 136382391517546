<template>
  <form class="e2e-search-result__associates">
    <div class="search-result__filters card">
      <div class="row">
        <div class="col s3">
          <label class="label">Search</label>
          <clearable-input
            v-model="searchTerms.query"
            type="search"
            name="filter"
            placeholder=""
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s3">
          <select-type v-model="searchTerms.type" :any-type="true" />
        </div>
        <div class="col s3">
          <select-country v-model="searchTerms.country" :input-countries="countryData" />
        </div>
        <div class="col s3">
          <searchable-tag-input
            name="tags"
            label="Tags"
            placeholder="E.g. Top 100"
            :value-list="localTags"
            :searcher="searchTag"
            :preview-list="previewTags"
            scope="advanced-search-associates"
            use-template="tag"
            @add="addTag"
            @remove="removeTagAt"
          />
        </div>
      </div>
      <div class="row flush--bottom">
        <div class="col s12 text--right">
          <action-buttons
            submit-label="Search"
            abort-label="Clear"
            @save="onSearchClick"
            @cancel="onClearClick"
          />
        </div>
      </div>
    </div>

    <div v-show="searchContext.isExecuted" class="search-result__summary">
      Search for
      <span v-if="searchContext.query != ''" class="text--bold">{{ searchContext.query }}</span>
      <span v-else>*</span>
      in Associates gave {{ $filters.formatNumber(searchContext.result.totalHits) }} results
    </div>

    <SearchQueryTooLong
      :length-limit="searchContext.searchLengthLimit"
      :query-too-long="searchContext.isTooLong"
    />

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="search-result__result card row">
        <div class="row">
          <div class="col s12">
            <table>
              <thead>
                <th
                  v-for="(column, index) in columns"
                  :key="`column-${index}`"
                  :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  @click="column.sortName ? sort(column) : null"
                >
                  <span>{{ column.name }}</span>
                  <span v-if="!column.thinColumn">
                    <span v-if="column.sortParam" class="dropdown-button__arrow">
                      <i
                        v-if="column.order === 'desc'"
                        class="fas fa-caret-up"
                        :class="{ 'text--grey': !column.active }"
                      />
                      <i
                        v-else
                        class="fas fa-caret-down"
                        :class="{ 'text--grey': !column.active }"
                      />
                    </span>
                  </span>
                </th>
              </thead>
              <tbody>
                <template v-for="result in searchContext.result.results">
                  <tr :key="`${result.id}-tr1`">
                    <td class="thin-column dropdown-button__arrow" />
                    <td>
                      <span>
                        <router-link
                          :to="`/associates/${result.id}`"
                          v-html="$filters.highlightToHtml(result.name, searchContext.query)"
                        />
                      </span>
                    </td>
                    <td>
                      {{ $filters.translate(`common.${result.associate_type.toLowerCase()}`) }}
                    </td>
                    <td>
                      <span v-if="result.org_ssn_no">{{ result.org_ssn_no }}</span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span
                        v-if="
                          result.contact_info &&
                          result.contact_info.address &&
                          result.contact_info.address.country
                        "
                      >
                        {{ $filters.formatCountry(result.contact_info.address.country) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                  </tr>
                  <tr
                    v-if="associateExpanded(result.id)"
                    :key="`${result.id}-tr2`"
                    class="expand-info"
                  >
                    <td class="thin-column" />
                    <td />
                    <td />
                    <td colspan="2" class="expand-info__meta">
                      <span class="expand-info__header">More meta data</span>
                      <div class="expand-info__meta__tags">
                        <div class="expand-info__meta__tags-title expand-info__meta__title">
                          Tags:
                        </div>
                        <div class="chips__wrapper">
                          <template v-if="result.tags && result.tags.length > 0">
                            <tag v-for="(tag, index) in result.tags" :key="index" :tag="tag" />
                          </template>
                          <div v-else class="none">None</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="searchContext.isExecuted && searchContext.result.totalHits === 0">
                  <tr>
                    <td colspan="1000" class="search-result__no-result">No items found.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="searchContext.isExecuted">
          <div class="col s12">
            <pagination
              v-show="numberOfPages > 0"
              topic="search"
              :number-of-pages="numberOfPages"
              :selected-page="pagination.page"
              :number-of-hits="searchContext.result.totalHits"
              :hits-per-page="pagination.hitsPerPage"
              @selectPage="selectPage"
              @updateHitsPerPage="updateHitsPerPage"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import ActionButtons from '../ui/button/action-buttons';
import Pagination from '../pagination';
import SearchHelper from './searchHelper';
import SelectCountry from '../ui/select/select-country';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import SelectType from '../ui/select/select-associate-type';
import Spinner from '../spinner';
import Tag from '../tags/tag';
import ClearableInput from '../ui/input/clearable-input.vue';
import SearchQueryTooLong from './search-query-too-long';

export default {
  name: 'AdvancedSearchAssociates',
  components: {
    ActionButtons,
    Pagination,
    SelectCountry,
    SelectType,
    Spinner,
    SearchableTagInput,
    Tag,
    ClearableInput,
    SearchQueryTooLong,
  },
  data() {
    return {
      loading: false,
      countryData: [],
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Name',
          sortName: 'name.keyword',
          order: 'desc',
          active: false,
          sortParam: true,
        },
        {
          name: 'Type',
          sortName: 'type',
          order: 'desc',
          active: false,
          sortParam: true,
        },
        {
          name: 'Organisation/Social Security Number',
          sortName: 'org_ssn_no',
          order: 'desc',
          active: false,
          sortParam: true,
        },
        {
          name: 'Country',
          sortName: 'contact_info.address.country',
          order: 'desc',
          active: false,
          sortParam: true,
        },
      ],
      allTags: [],
      localTags: [],
      searchTerms: {
        query: '',
        country: '',
        type: '',
        tags: [],
      },
      sortTerms: {
        param: '',
        order: '',
      },
      defaultHitsPerPage: 25,
      searchContext: {
        query: '',
        isExecuted: false,
        isTooLong: false,
        searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
        result: {
          totalHits: 0,
          typedResults: [],
        },
      },
      pagination: {
        hitsPerPage: this.defaultHitsPerPage,
        page: 1,
      },
      expandedAssociateIds: [],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.searchContext.result.totalHits / this.pagination.hitsPerPage);
    },
    previewTags() {
      return this.allTags
        .filter((tag) =>
          this.localTags.length > 0
            ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
            : true,
        )
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
    },
    firstHitIndexOnCurrentPage() {
      if (this.searchContext.result.totalHits === 0) {
        return 0;
      }
      return Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage + 1;
    },
    lastHitIndexOnCurrentPage() {
      const totalHits = this.searchContext.result.totalHits;
      const lastHitIndex =
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage +
        this.pagination.hitsPerPage;
      return lastHitIndex < totalHits ? lastHitIndex : totalHits;
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    $route() {
      this.updateRouterSearchParams();
      if (this.$router.currentRoute.query.i) {
        this.search();
      } else {
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        this.$emit('updateIsExecuted', false);
      }
    },
  },
  async created() {
    this.countryData = this.countries.slice();
    this.allTags = await SearchHelper.getAllTags();
    if (this.$router.currentRoute.query.i) {
      this.updateRouterSearchParams();
      this.search();
    }
  },
  methods: {
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.searchTerms.tags = event.data.map((tag) => tag.name);
      this.localTags = event.data;
    },
    removeTagAt(event) {
      this.searchTerms.tags.splice(event.idx, 1);
    },
    hasDetails(associate) {
      return associate.tags && associate.tags.length > 0;
    },
    toggleDetails(id, e) {
      const index = _.indexOf(this.expandedAssociateIds, id);
      const row = e.target.closest('tr');
      if (index === -1) {
        this.expandedAssociateIds.push(id);
        row.style.whiteSpace = 'normal';
      } else {
        this.expandedAssociateIds.splice(index, 1);
        row.style.whiteSpace = 'nowrap';
      }
    },
    associateExpanded(id) {
      return _.includes(this.expandedAssociateIds, id);
    },
    hasCountry(contactInfo) {
      return contactInfo && contactInfo.address && contactInfo.address.country;
    },
    selectPage(selectedPage) {
      this.pagination.page = selectedPage;
      this.pushSearch();
    },
    updateHitsPerPage(value) {
      this.pagination.hitsPerPage = Number(value);
      this.resetPages();
      this.pushSearch();
    },
    updateRouterSearchParams() {
      this.searchTerms.query = this.$router.currentRoute.params.query
        ? this.$router.currentRoute.params.query
        : '';
      this.searchTerms.country = this.$router.currentRoute.query.country
        ? this.$router.currentRoute.query.country
        : '';
      this.searchTerms.type = this.$router.currentRoute.query.type
        ? this.$router.currentRoute.query.type
        : '';
      this.searchTerms.tags = this.$router.currentRoute.query.tags
        ? this.$router.currentRoute.query.tags
        : [];
      if (this.$router.currentRoute.query.tags) {
        if (_.isArray(this.$router.currentRoute.query.tags)) {
          this.localTags = this.$router.currentRoute.query.tags.map((tag) => ({
            name: tag,
            type: 'tag',
          }));
        } else {
          this.localTags = [{ name: this.$router.currentRoute.query.tags, type: 'tag' }];
        }
      } else {
        this.localTags = [];
      }
      this.sortTerms.param = this.$router.currentRoute.query.sortParam
        ? this.$router.currentRoute.query.sortParam
        : '';
      this.sortTerms.order = this.$router.currentRoute.query.sortOrder
        ? this.$router.currentRoute.query.sortOrder
        : '';
      this.pagination.page = this.$router.currentRoute.query.page
        ? Number(this.$router.currentRoute.query.page)
        : 1;
      this.pagination.hitsPerPage = this.$router.currentRoute.query.hits
        ? Number(this.$router.currentRoute.query.hits)
        : this.defaultHitsPerPage;
    },
    onSearchClick() {
      this.resetPages();
      this.pushSearch();
    },
    onClearClick() {
      this.$router.push({
        name: 'advancedSearchAssociatesView',
        query: '',
      });
    },
    pushSearch() {
      const queryParams = {
        i: new Date().getTime(),
      };
      if (this.searchTerms.query) {
        queryParams.query = this.searchTerms.query;
      }
      if (this.searchTerms.country) {
        queryParams.country = this.searchTerms.country;
      }
      if (this.searchTerms.type) {
        queryParams.type = this.searchTerms.type;
      }
      if (this.searchTerms.tags) {
        queryParams.tags = this.searchTerms.tags;
      }
      if (this.sortTerms.param) {
        queryParams.sortParam = this.sortTerms.param;
      }
      if (this.sortTerms.order) {
        queryParams.sortOrder = this.sortTerms.order;
      }
      if (this.pagination.hitsPerPage) {
        queryParams.hits = this.pagination.hitsPerPage;
      }
      if (this.pagination.page) {
        queryParams.page = this.pagination.page;
      }
      if (this.searchTerms.query !== '') {
        this.$router.push({
          name: 'advancedSearchAssociatesQueryView',
          params: { query: this.searchTerms.query },
          query: queryParams,
        });
      } else {
        this.$router.push({
          name: 'advancedSearchAssociatesView',
          query: queryParams,
        });
      }
    },
    search() {
      this.searchContext.query = this.searchTerms.query;
      if (SearchHelper.isQueryTooLong(this.searchTerms.query)) {
        this.searchContext.isTooLong = true;
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        return;
      }
      this.searchContext.isTooLong = false;
      this.loading = true;
      SearchHelper.advancedSearchAssociates(
        this.searchTerms.query,
        this.searchTerms.country,
        this.searchTerms.type && this.searchTerms.type !== '' ? [this.searchTerms.type] : [],
        this.searchTerms.tags,
        this.sortTerms.param,
        this.sortTerms.order,
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage,
        this.pagination.hitsPerPage,
      )
        .then((result) => {
          this.searchContext.isExecuted = true;
          this.$emit('updateIsExecuted', true);
          this.searchContext.result = result;
        })
        .then(() => {
          this.loading = false;
        });
    },
    resetPages() {
      this.sortTerms = { param: '', order: '' };
      this.pagination.page = 1;
    },
    sort(column) {
      this.sortTerms.param = column.sortName;
      column.order = column.order === 'desc' ? 'asc' : 'desc';
      this.sortTerms.order = column.order;
      this.columns.forEach((c) => {
        c.active = false;
      });
      column.active = true;
      this.pushSearch();
      this.search();
    },
  },
};
</script>
