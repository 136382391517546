<template>
  <div class="e2e-view-revenue">
    <div class="row small-inner">
      <div class="col s12">
        <h2>Revenue</h2>
      </div>
    </div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>
    <div v-else-if="id">
      <div class="row wrapper">
        <div class="col s12 l11">
          <h1>
            {{ name }}
          </h1>
        </div>
      </div>
      <div class="row">
        <div class="col s2">
          <div class="text--bold">Status</div>
          <select-input
            v-if="isSelectableState"
            name="state-filter"
            label=""
            item-key="key"
            item-value="value"
            :disabled="editMode"
            :empty-select-option="false"
            :value="state"
            :items="states"
            @input="onUpdateState"
          />
          <span v-else v-t="`revenue.state.${state.toLowerCase()}`" class="chip"></span>
        </div>
      </div>

      <template v-if="editMode">
        <form :data-vv-scope="scope">
          <create-basic-information
            :edit-mode="true"
            :scope="scope"
            @save="onSaveEdit($event)"
            @cancel="onCancelEdit"
          />
        </form>
      </template>

      <basic-information
        v-else
        :source="source"
        :state-summary="stateSummary"
        :current-state="state"
        @edit="onEdit"
      />

      <statement-matching
        v-if="!editMode && aggregate.statements && aggregate.statements.length > 0"
        :statement-header="aggregate.statements[0]"
        @fetchRevenue="fetchData"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { setAggregate, createAggregate } from '../../../store/modules/revenue/utils';
import { RevenueState } from '../../../domain/distributionDomain';
import SelectInput from './../../ui/select/select-input';
import DistributionService from '../../../services/distributionService';
import StatementService from '../../../services/statementService';
import Spinner from './../../spinner';
import BasicInformation from './basic-information';
import StatementMatching from './statement-matching';
import CreateBasicInformation from './create-basic-information';
import RemoteFileHelper from '../../../common/remoteFileHelper';

export default {
  name: 'ViewRevenue',
  components: {
    Spinner,
    SelectInput,
    BasicInformation,
    CreateBasicInformation,
    StatementMatching,
  },
  data() {
    return {
      scope: 'view-revenue',
      loading: true,
      revenueId: this.$router.currentRoute.params.id,
      error: false,
      states: [],
      editMode: false,
      savedInitialAggregate: null,
      source: {},
      selectableRevenueStates: [
        RevenueState.NEW,
        RevenueState.TO_BE_DISTRIBUTED,
        RevenueState.ON_HOLD,
      ],
      stateSummary: [],
    };
  },
  computed: {
    ...mapGetters('revenue', [
      'id',
      'aggregate',
      'name',
      'state',
      'year',
      'currency',
      'paymentDate',
      'statementUrl',
      'attachmentUrls',
      'distributableAmount',
      'note',
      'amount',
      'originalAmount',
      'sourceId',
      'conversionRate',
      'financingFee',
      'financingRate',
      'aggregate',
      'specification',
      'missingPerformersUrl',
      'statementFeedbackFileUrl',
    ]),
    isSelectableState() {
      return this.selectableRevenueStates.some((key) => key === this.state);
    },
    firstStatement() {
      const st = this.aggregate.statements;
      return st && st.length && st[0];
    },
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
  },
  async created() {
    await this.fetchData();
    this.states = this.selectableRevenueStates.map((key) => ({
      key,
      value: Vue.$i18n.t(`revenue.state.${key.toLowerCase()}`),
    }));
  },
  methods: {
    ...mapMutations('revenue', ['updateState', 'updateConversionRate', 'updateFinancingRate']),
    async fetchData() {
      this.revenueId = this.$router.currentRoute.params.id;
      this.loading = true;
      this.error = false;
      try {
        const aggregate = await DistributionService.getRevenue(this.revenueId);
        if (aggregate.statements.length > 0) {
          // picking the first statement, just like in the template above
          this.stateSummary = await StatementService.getStatementPerformerStateSummaries(
            aggregate.statements[0].id,
          );
        }
        this.dispatchAggregate(aggregate);
        this.source = aggregate.source;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.dispatchAggregate();
        error.title = 'Could not load revenue';
        this.$addStarError(error);
      }
    },
    dispatchAggregate(_aggregate) {
      if (!_aggregate) {
        createAggregate();
        return;
      }
      const aggregate = Object.freeze(_.cloneDeep(_aggregate));
      setAggregate(aggregate);
      this.savedInitialAggregate = _.cloneDeep(aggregate);
    },
    async updateRevenue() {
      this.loading = true;
      this.error = false;
      try {
        if (this.conversionRate) {
          this.updateConversionRate(this.conversionRate.toString());
        }
        if (this.financingRate) {
          this.updateFinancingRate(this.financingRate.toString());
        }
        if (
          this.aggregate.attachmentFileHandles &&
          this.aggregate.attachmentFileHandles.length > 0
        ) {
          await RemoteFileHelper.uploadAttachments(this.aggregate);
        }
        if (this.aggregate.statementFileHandle && this.aggregate.statementFileHandle.name) {
          await RemoteFileHelper.uploadStatement(this.aggregate);
        }
        await DistributionService.createOrUpdateRevenue(this.aggregate);
        this.fetchData(true);
      } catch (error) {
        this.loading = false;
        error.title = 'Oops!';
        this.$addStarError(error);
      }
    },
    async onUpdateState(state) {
      this.updateState(state);
      this.updateRevenue();
    },
    onSaveEdit() {
      this.editMode = false;
      this.updateRevenue();
    },
    onCancelEdit() {
      this.dispatchAggregate(this.savedInitialAggregate);
      this.editMode = false;
    },
    onEdit() {
      this.editMode = true;
    },
  },
};
</script>
