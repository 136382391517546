<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>SDEG Collecting and Claiming for {{ basicInfoName }}</h1>
      </div>
    </div>

    <div class="tabs row">
      <div class="tabs__wrapper">
        <router-link :to="`/societies/${id}/sdeg/claims`">SAMI Collecting</router-link>
        <router-link :to="`/societies/${id}/sdeg/played-recordings`">SAMI Claiming</router-link>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewSocietyMixin from '../society/view/view-society-mixin';

export default {
  name: 'ViewSocietySdegOverview',
  mixins: [ViewSocietyMixin],
  data() {
    return {
      viewType: this.getViewType(),
    };
  },
  computed: {
    ...mapGetters('society', ['id', 'basicInfoName', 'metaInfoIpdSocietyCode']),
  },
  watch: {
    $route() {
      this.viewType = this.getViewType();
    },
  },
  created() {
    if (!this.id) {
      this.fetchSocietyInfo();
    }
  },
  methods: {
    async fetchSocietyInfo() {
      const societyId = this.$router.currentRoute.params.id;
      await this.fetchData(societyId);
    },
    getViewType() {
      return this.$router.currentRoute.name === 'sdegSocietyPlayedRecordingsOverview'
        ? 'collects'
        : 'claims';
    },
  },
};
</script>
