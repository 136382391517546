import _ from 'lodash';

/**
 * Merges the source object with the destination object.
 * Destination properties are overwritten if they exist in the source or otherwise retained.
 * Unknown source properties are not merged with destination.
 * @param dest
 * @param source
 * @returns {*}
 */
/* eslint-disable no-use-before-define */
export default function merge(dest, source) {
  if (Array.isArray(dest)) {
    if (!Array.isArray(source)) {
      return dest;
    }
    return mergeKeepShapeArray(dest, source);
  } else if (_.isObject(dest)) {
    if (!_.isObject(source)) {
      return dest;
    }
    return mergeKeepShapeObject(dest, source);
  }
  return source;
}

function mergeKeepShapeArray(dest, source) {
  if (source.length !== dest.length) {
    return dest.concat(source);
  }
  const ret = [];
  dest.forEach((v, i) => {
    ret[i] = merge(v, source[i]);
  });
  return ret;
}

function mergeKeepShapeObject(dest, source) {
  const ret = {};
  if (dest == null) {
    return source;
  }
  Object.keys(dest).forEach((key) => {
    const sourceValue = source[key];
    if (
      typeof sourceValue !== 'undefined' &&
      (sourceValue || _.isBoolean(sourceValue) || _.isFinite(sourceValue))
    ) {
      ret[key] = merge(dest[key], sourceValue);
    } else {
      ret[key] = dest[key];
    }
  });
  return ret;
}
/* eslint-enable */
