<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>

    <div class="row">
      <div class="col s12">
        <TableOptions
          :countries="!isSdeg ? recordedInCountries : []"
          :bulk-actions="isSdeg ? bulkEditActionsSdeg : bulkEditActions"
          :bulk-action-enabled="anySelected()"
          :recorded-in-country-code="recordedInCountryCode"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
          @countryChanged="onCountryChanged"
          @bulkAction="onBulkAction"
        />
      </div>
    </div>
    <div v-show="!loading" class="row card">
      <div class="col s12">
        <modal
          v-if="showNewRecordingAllModal"
          submit-label="Create new recordings"
          @save="newRecordingsAllItems()"
          @close="showNewRecordingAllModal = false"
          @cancel="showNewRecordingAllModal = false"
        >
          <h1 slot="header">Create recordings?</h1>
          <div slot="body">This will create new recordings for ALL unmatched. Are you sure?</div>
        </modal>

        <modal
          v-if="showIdentifyAllModal"
          :hide-overflow="false"
          :disable-submit="!currentCandidate || !currentCandidate.id"
          @save="onBulkIdentify"
          @close="showIdentifyAllModal = false"
          @cancel="showIdentifyAllModal = false"
        >
          <h1 slot="header">Identify all</h1>
          <candidate-selection
            slot="body"
            :is-album="isAlbum"
            @candidateChanged="candidateChanged"
          />
        </modal>

        <table>
          <CompareSort
            :bulk-enabled="true"
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareListMatching
            ref="comparelist"
            :sdeg="isSdeg"
            :album="isAlbum"
            :candidate-changed="candidateChanged"
            :get-candidate-value="getCandidateValue"
            :handle-identify-btn="handleIdentifyBtn"
            :handle-ignore-btn="handleIgnoreBtn"
            :handle-new-recording="newRecordingFromButton"
            :handle-save-btn="handleSaveBtn"
            :is-expanded="isExpanded"
            :revert-match="revertMatch"
            :toggle-expanded="toggleExpanded"
            @change="updateDirtyState"
          />
        </table>

        <div class="result__pagination">
          <Pagination
            v-if="numberOfPages() > 0"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="totalCount"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompareListMatching from '../compare/compare-list-matching';
import CompareSort from '../compare/compare-sort';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import MusicReportHelper from './musicReportHelper';
import Pagination from '../pagination';
import PaginationAndSortMixin from '../../common/paginationAndSortMixin';
import TableOptionsMixin from '../../common/tableOptionsMixin';
import TableOptions from '../ui/table/table-options';
import ViewMusicReportMixin from './view-music-report-mixin';
import Modal from '../ui/dialog/modal';
import CandidateSelection from './candidate-selection';

export default {
  name: 'ViewMusicReportRestedLines',
  components: {
    CompareListMatching,
    CompareSort,
    Pagination,
    TableOptions,
    Modal,
    CandidateSelection,
  },
  mixins: [
    DebouncedSearchMixin(50),
    PaginationAndSortMixin,
    TableOptionsMixin,
    ViewMusicReportMixin,
  ],
  data() {
    return {
      bulkEditActionsSdeg: [
        { key: 'new_recording', value: 'New recording' },
        { key: 'identify', value: 'Identify' },
      ],
      bulkEditActions: [
        { key: 'new_recording', value: 'New recording' },
        { key: 'new_recording_all', value: 'New recording (ALL)' },
        { key: 'identify', value: 'Identify' },
      ],
      lineupStateMask: 'R',
      showNewRecordingAllModal: false,
      showIdentifyAllModal: false,
    };
  },
  created() {
    this.clearFilter('ViewMusicReportRestedLines');
  },
  async activated() {
    const expandedId = this.viewMusicReportRestedLines.expandedId;
    await this.loadPaginated();
    this.recordedInCountries = MusicReportHelper.getCountries();
    if (expandedId) {
      this.toggleExpanded(expandedId);
    }
  },
  deactivated() {
    this.updateViewMusicReportRestedLinesExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
  methods: {
    onBulkAction(value) {
      switch (value) {
        case 'new_recording':
          this.newRecordingsFromCheckedItems();
          break;
        case 'new_recording_all':
          this.showNewRecordingAllModal = true;
          break;
        case 'identify':
          this.setExpanded(undefined);
          this.clearCurrentCandidate();
          this.updateCandidates([]);
          this.showIdentifyAllModal = true;
          break;
        default:
          console.log('Unknown bulk action');
      }
    },
    async newRecordingFromButton(line) {
      await this.createNewRecordings(this.reportId, [line.incoming]);
    },
    async newRecordingsFromCheckedItems() {
      const checkedLines = this.reportLines.filter((line) => line.checked);
      const recordsToCreate = checkedLines.map((line) => line.incoming);
      await this.createNewRecordings(this.reportId, recordsToCreate);
    },
    async newRecordingsAllItems() {
      this.showNewRecordingAllModal = false;
      const recordsToMarkComplete = this.reportLines.map((line) => line.incoming);
      await this.createNewRecordings(this.reportId, recordsToMarkComplete, true);
    },
    async onBulkIdentify() {
      this.showIdentifyAllModal = false;
      await this.identifyOnCheckedItems();
    },
  },
};
</script>
