<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2 class="float-left">Create and Add Performer</h2>
      </div>
      <div v-if="performers.length > 0" class="col s6 text--right">
        <action-buttons :scope="scope" @save="save" @cancel="cancel" />
      </div>
    </div>
    <div v-for="(performer, index) in performers" :key="performer._localId">
      <create-new-performer
        name=""
        :scope="scope"
        :performer="performer"
        :deletable="performers.length > 1 && index > 0"
        @input="updateNewPerformer(index, $event)"
        @remove="removeNewPerformer(index)"
      />
    </div>
    <div class="edit-link add-extra">
      <a :class="{ disabled: !lastHasEntry }" @click="addNewPerformer">
        <i class="far fa-plus-square" />
        Create and Add a New Performer
      </a>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import { mapGetters } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import CreateNewPerformer from '../create/create-new-performer';

export default {
  name: 'EditAddNewPerformers',
  components: {
    ActionButtons,
    CreateNewPerformer,
  },
  inject: ['$validator'],
  props: {},
  data() {
    return {
      performers: [],
      scope: 'create-new-performer',
    };
  },
  computed: {
    ...mapGetters('recording', ['lineup']),
    lastHasEntry() {
      const valFields = this.validationFields[`$${this.scope}-${this.performers.length - 1}`];
      if (!valFields) {
        return true;
      }
      return (
        Object.keys(valFields).some((key) => valFields[key].touched) &&
        !this.validationErrors.any(this.scope)
      );
    },
  },
  watch: {
    lineup() {
      this.performers = [];
    },
  },
  methods: {
    updateNewPerformer(index, valueUpdate) {
      this.performers[index] = valueUpdate;
    },
    cancel() {
      this.performers = [];
      this.$emit('cancel', this.$options.name);
    },
    save() {
      this.$emit('save', this.performers);
    },
    addNewPerformer() {
      this.performers.push(
        Object.assign(
          { _localId: uuid.v4() },
          {
            id: null,
            first_name: null,
            last_name: null,
            country_of_residence: null,
            pseudo_names: [],
            instruments: [],
            role: null,
          },
        ),
      );
    },
    removeNewPerformer(index) {
      this.performers.splice(index, 1);
    },
  },
};
</script>
