<template>
  <div>
    <div v-if="loadingSociety" class="result__spinner">
      <spinner />
    </div>
    <div class="result">
      <div class="row wrapper">
        <div class="col s8">
          <h1>SDEG Claiming Played Recordings from {{ basicInfoName }}</h1>
          <h2>{{ reportName }}</h2>
        </div>
        <div class="col s4">
          <button
            v-if="$router.currentRoute.name == 'viewSdegPlayedRecordingsReportMatched'"
            class="btn float-right"
            :disabled="creatingClaimsFile"
            @click="onCreateClaimsFile()"
          >
            <i class="fas fa-sync-alt" :class="{ spin: creatingClaimsFile }" />
            Create claims file
          </button>
        </div>
      </div>
      <div v-if="claimsInfo" class="row wrapper">
        <sdeg-deadline
          class="col s3 offset-s4"
          :deadline="claimsInfo.deadline"
          :finished="claimsInfo.finished_by !== undefined"
          @update="updateDeadline"
        />
        <div class="col s3">
          <strong>Claims</strong>
          <div v-if="claimsInfo.claims_files && claimsInfo.claims_files.length > 0">
            <ul>
              <li v-for="(file, index) in claimsInfo.claims_files" :key="index">
                <div>
                  <a class="word-break-all" @click="onDownloadFile(file.key)">
                    <i class="far fa-file-alt" />
                    Link to file
                  </a>
                  By {{ file.created_by }} {{ $filters.formatDate(file.created_date) }}
                </div>
                <div>
                  <input
                    :id="`file-${index}`"
                    :name="`file-${index}`"
                    type="checkbox"
                    value="!!file.sent_by"
                    :checked="!!file.sent_by"
                    class="filled-in"
                    @change="onSent(file, index)"
                  />
                  <label :for="`file-${index}`">Claims have been sent to society</label>
                  <div v-if="file.sent_by">
                    By {{ file.sent_by }} {{ $filters.formatDate(file.sent_date) }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="none">No claims files created</div>
        </div>
        <div class="col s2">
          <input
            id="finished-process"
            name="finished-process"
            type="checkbox"
            value="!!claimsInfo.finished_by"
            :checked="!!claimsInfo.finished_by"
            class="filled-in"
            @change="onFinished(claimsInfo)"
          />
          <label for="finished-process">Finished process</label>
        </div>
      </div>
      <div class="row wrapper">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link
              class="nowrap"
              :to="`/societies/${id}/sdeg/played-recordings/${reportId}/matched`"
            >
              Matched ({{ counters.matched_lines || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="`/societies/${id}/sdeg/played-recordings/${reportId}/matched-not-sami`"
            >
              Matched not SAMI ({{ counters.ignored_lines || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="`/societies/${id}/sdeg/played-recordings/${reportId}/candidates`"
            >
              Candidates ({{ counters.candidate_lines || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="`/societies/${id}/sdeg/played-recordings/${reportId}/new`"
            >
              Unmatched ({{ counters.rested_lines || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="`/societies/${id}/sdeg/played-recordings/${reportId}/sent-claims`"
            >
              Sent Claims ({{ counters.transferred_lines || 0 }})
            </router-link>
            <router-link
              class="nowrap"
              :to="`/societies/${id}/sdeg/played-recordings/${reportId}/errors`"
            >
              Errors ({{ counters.error_lines || 0 }})
            </router-link>
          </div>
        </div>
      </div>
      <keep-alive>
        <template v-if="loading">
          <component-spinner />
        </template>
        <router-view @updated="loadHeader" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { mapGetters } from 'vuex';
import Spinner from '../../spinner';
import ViewSocietyMixin from '../../society/view/view-society-mixin';
import MatchingService from '../../../services/matchingService';
import RemoteFileService from '../../../services/remoteFileService';
import AuthenticationService from '../../../services/authenticationService';
import DownloadLink from '../../ui/file/downloadLink';
import ComponentSpinner from '../../../components/component-spinner';
import SdegDeadline from '@/components/sdeg/sdeg-deadline.vue';

export default {
  name: 'ViewPlayedRecordingsReport',
  components: {
    SdegDeadline,
    ComponentSpinner,
    Spinner,
  },
  mixins: [ViewSocietyMixin],
  props: {
    processType: String,
  },
  data() {
    return {
      claimsInfo: {},
      reportName: '',
      loading: false,
      loadingSociety: false,
      creatingClaimsFile: false,
      reportId: this.$router.currentRoute.params.reportId,
      counters: {
        line_count: 0,
        matched_lines: 0,
        rested_lines: 0,
        candidate_lines: 0,
        transferred_lines: 0,
        error_lines: 0,
        ignored_lines: 0,
      },
    };
  },
  computed: {
    ...mapGetters('society', ['id', 'basicInfoName']),
  },
  async created() {
    await this.loadSociety();
    await this.loadHeader();
  },
  methods: {
    async loadHeader() {
      this.loading = true;
      const reportHeader = await MatchingService.getReportHeader('PRR', this.reportId);
      this.reportName = reportHeader.report_name;
      this.counters = reportHeader.prr_match_data;

      if (reportHeader.info) {
        this.claimsInfo = _.cloneDeep(reportHeader.info);
      }
      this.loading = false;
    },
    async loadSociety() {
      this.loadingSociety = true;
      this.aggregateId = this.$router.currentRoute.params.id;
      this.version = this.$router.currentRoute.params.version;
      try {
        await this.fetchData(this.aggregateId, this.version);
      } catch (error) {
        this.handleError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.aggregateId },
        });
      }
      this.loadingSociety = false;
    },
    async onSent(claimsFile, index) {
      if (!claimsFile.sent_by) {
        claimsFile.sent_by = AuthenticationService.getUserName();
        claimsFile.sent_date = new Date();
      } else {
        claimsFile.sent_by = undefined;
        claimsFile.sent_date = undefined;
      }
      this.$set(this.claimsInfo.claims_files, index, claimsFile);
      await MatchingService.updateReportHeader(this.reportId, { info: this.claimsInfo });
    },
    async onFinished(claimsInfo) {
      if (!claimsInfo.finished_by) {
        claimsInfo.finished_by = AuthenticationService.getUserName();
        claimsInfo.finished_date = new Date();
      } else {
        claimsInfo.finished_by = undefined;
        claimsInfo.finished_date = undefined;
      }
      await MatchingService.updateReportHeader(this.reportId, { info: this.claimsInfo });
    },
    async onDownloadFile(url) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
      DownloadLink.direct(fileData, url.split('/').pop());
    },
    async onCreateClaimsFile() {
      const processId = uuid.v4();
      this.creatingClaimsFile = true;
      try {
        this.$store.dispatch('process/addProcess', {
          name: `Creating Claims File For ${this.reportName}`,
          id: processId,
        });
        await MatchingService.exportRecordings(this.reportId, processId);
        await this.loadHeader();
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      } finally {
        setTimeout(() => {
          this.creatingClaimsFile = false;
        }, 1000);
      }
    },
    async updateDeadline(newDeadline) {
      this.claimsInfo.deadline = newDeadline;
      await MatchingService.updateReportHeader(this.reportId, { info: this.claimsInfo });
    },
  },
};
</script>

<style lang="scss" scoped>
strong {
  font-size: 13px;
}
.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
