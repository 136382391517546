<template>
  <div>
    <div class="title">Blocked Redistributions</div>
    <section class="widget-table">
      <header>
        <div class="widget-table--col">Title</div>
        <div class="widget-table--col">Main Artist</div>
        <div class="widget-table--col sm amount">State</div>
      </header>
      <div v-for="recording in payload.items" :key="recording.star_id" class="widget-table--row">
        <div class="widget-table--col">
          <router-link :to="`/recordings/${recording.star_id}`">
            {{ recording.title }}
            {{ recording.version_title ? '(' + recording.version_title + ')' : '' }}
          </router-link>
        </div>
        <div class="widget-table--col">
          {{ recording.main_artist }}
        </div>
        <div class="widget-table--col sm amount">
          <i class="fa" :class="recording.blocked_by === 'system' ? 'fa-ban' : 'fa-hand-paper'" />
        </div>
      </div>
    </section>

    <section class="widget-link">
      <router-link to="/distribution/redistribution">
        Show Redistributions
        {{ payload.total_count > 5 ? '(' + (payload.total_count - 5) + ' more)' : '' }}
      </router-link>
    </section>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'BlockedRedistributionsWidget',
  mixins: [WidgetMixin],
};
</script>
