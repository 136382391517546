<template>
  <div class="e2e-view-society">
    <div class="row">
      <div class="col s12 l1">
        <entity-navigation :views="navigationViews" />
      </div>
      <div class="col s12 l11 view-layout">
        <router-view type="society" />
      </div>
    </div>
  </div>
</template>

<script>
import EntityNavigation from '../../entity-navigation';

export default {
  name: 'ViewSociety',
  components: {
    EntityNavigation,
  },
  data() {
    return {
      navigationViews: [
        'societyInformation',
        'societyRoleCodesView',
        'viewSocietyPayments',
        'viewSocietyVRDB',
        'viewSocietySdeg',
        'viewSocietyMember',
      ],
    };
  },
};
</script>
