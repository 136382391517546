<template>
  <div class="card">
    <div class="row">
      <div class="col s8">
        VRDB ID
        <div v-if="vrdb2_id">
          {{ vrdb2_id }}
        </div>
        <div v-else class="none">None</div>
      </div>
      <div class="col s4">
        <button class="white-text btn" disabled>Search VRDB</button>
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          ref="name"
          name="basic_info.name"
          label="Name of recording"
          :scope="scope"
          :mandatory="true"
          :placeholder="placeHolderTitle"
          :value="name"
          rule="required"
          :notify-validation="true"
          @input="_updateBasicInfoName"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          ref="versionTitle"
          name="basic_info.version_title"
          label="Version title"
          :scope="scope"
          :placeholder="placeHolderVersionTitle"
          :value="version_title"
          @input="_updateBasicInfoVersionTitle"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <tag-input
          name="basic_info.alternate_titles"
          label="Alternate names"
          :placeholder="placeHolderAlternateTitle"
          :value-list="alternate_titles"
          @add="addToBasicInfoAlternateTitles"
          @remove="removeFromBasicInfoAlternateTitles"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        VRDB title
        <div v-if="vrdb2_title">
          {{ vrdb2_title }}
        </div>
        <div v-else class="none">None</div>
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <main-artist-input
          ref="mainArtist"
          name="basic_info.main_artist"
          :scope="scope"
          label="Main artist name"
          :mandatory="true"
          :placeholder="placeHolderPerformer"
          @input="_updateBasicInfoMainArtist"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s4">
        <select-country
          name="basic_info.recorded_in_country"
          label="Recorded in country"
          :value="recorded_in_country"
          @input="updateBasicInfoRecordedInCountry"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s4">
        <select-date
          name="basic_info.recording_date"
          :scope="scope"
          :disabled="unknownProtected"
          label="Recording date"
          :value="recording_date"
          :extra-date-patterns="['UNKNOWN_PROTECTED_DATE']"
          @input="_updateBasicInfoRecordingDate"
        />
      </div>
      <div class="col s4 unknown-protected">
        <input
          id="basic_info.unknown_protected"
          v-model="unknownProtected"
          type="checkbox"
          class="filled-in"
          @click="updateUnknownProtected"
        />
        <label for="basic_info.unknown_protected">Unknown and protected</label>
      </div>
    </div>

    <div class="row">
      <div class="col s4">
        <select-country
          name="basic_info.produced_in_country"
          label="Produced in country"
          :value="produced_in_country"
          @input="updateBasicInfoProducedInCountry"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s4">
        <select-date
          name="basic_info.release_date"
          :scope="scope"
          label="Release date"
          :value="release_date"
          @input="updateBasicInfoReleaseDate"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s4">
        <select-input
          name="basic_info.genre"
          label="Genre"
          :value="genre"
          :items="genres"
          item-key="code"
          item-value="name"
          @input="updateBasicInfoGenre"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s4">
        <duration-input
          name="basic_info.duration_sec"
          label="Duration"
          :scope="scope"
          :value="duration_sec"
          @input="updateBasicInfoDurationSec"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          name="basic_info.composer"
          label="Composed by"
          :placeholder="placeHolderPerformer"
          :scope="scope"
          :value="composer"
          :mandatory="genre === 'classical_music'"
          :rule="{ required: genre === 'classical_music' }"
          @input="updateBasicInfoComposer"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          name="basic_info.writer"
          label="Written by"
          :placeholder="placeHolderPerformer"
          :scope="scope"
          :value="writer"
          @input="updateBasicInfoWriter"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          name="basic_info.arranger"
          label="Arranged by"
          :placeholder="placeHolderPerformer"
          :scope="scope"
          :value="arranger"
          @input="updateBasicInfoArranger"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          name="basic_info.label"
          label="Label"
          placeholder="E.g. BMG"
          :value="label"
          :scope="scope"
          @input="updateBasicInfoLabel"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          name="basic_info.isrc"
          label="ISRC"
          placeholder=""
          :value="isrc"
          :scope="scope"
          rule="isrc"
          :input-filter="(v) => (v ? v.toUpperCase() : v)"
          @input="updateBasicInfoIsrc"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { UNKNOWN_PROTECTED_DATE } from '../../../domain/recordingDomain';
import { createLocalCode, createSourceInfo } from '../../../domain/common';
import MainArtistInput from '../../ui/input/main-artist-input';
import SelectDate from '../../ui/select/select-date';
import SelectInput from '../../ui/select/select-input';
import TagInput from '../../ui/tag/tag-input';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import DurationInput from '../../ui/input/duration-input';
import SelectCountry from '../../ui/select/select-country';
import validationPatterns from '../../../common/validationPatterns';

export default {
  name: 'CreateBasicInfo',
  components: {
    SelectCountry,
    MainArtistInput,
    SelectDate,
    SelectInput,
    TagInput,
    ValidatedTextInput,
    DurationInput,
  },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      placeHolderId: 'E.g. 1234567',
      placeHolderTitle: 'E.g. Stoppa mig Juni (Lilla Ego)',
      placeHolderAlternateTitle: 'E.g. Stoppa mig Juni',
      placeHolderVersionTitle: 'E.g. Album version',
      placeHolderPerformer: 'E.g. Joakim Berg',
      unknownProtected: false,
    };
  },
  computed: {
    ...mapState('recording', {
      vrdb2_id: (state) => state.basic_info.vrdb2_id,
      vrdb2_title: (state) => state.basic_info.vrdb2_title,
      name: (state) => state.basic_info.name,
      version_title: (state) => state.basic_info.version_title,
      alternate_titles: (state) => state.basic_info.alternate_titles,
      main_artist: (state) => state.basic_info.main_artist,
      recorded_in_country: (state) => state.basic_info.recorded_in_country,
      recording_date: (state) => state.basic_info.recording_date,
      produced_in_country: (state) => state.basic_info.produced_in_country,
      release_date: (state) => state.basic_info.release_date,
      duration_sec: (state) => state.basic_info.duration_sec,
      genre: (state) => state.basic_info.genre,
      composer: (state) => state.basic_info.composer,
      writer: (state) => state.basic_info.writer,
      arranger: (state) => state.basic_info.arranger,
      label: (state) => state.basic_info.label,
      isrc: (state) => state.basic_info.isrc,
      source_info: (state) => state.basic_info.source_info,
      local_codes: (state) => state.basic_info.local_codes,
    }),
    genres() {
      return this.$store.state.appdata.referenceData.genres;
    },
  },
  created() {
    if (this.local_codes.length === 0) {
      this.addBasicInfoLocalCode();
    }
    if (!this.source_info) {
      this._updateBasicInfoSourceInfo(createSourceInfo());
    }
  },
  methods: {
    ...mapMutations('recording', [
      'updateBasicInfoVrdb2Id',
      'updateBasicInfoVrdb2Title',
      'updateBasicInfoName',
      'updateBasicInfoVersionTitle',
      'addToBasicInfoAlternateTitles',
      'removeFromBasicInfoAlternateTitles',
      'updateBasicInfoMainArtist',
      'updateBasicInfoRecordedInCountry',
      'updateBasicInfoRecordingDate',
      'updateBasicInfoProducedInCountry',
      'updateBasicInfoReleaseDate',
      'updateBasicInfoGenre',
      'updateBasicInfoDurationSec',
      'updateBasicInfoComposer',
      'updateBasicInfoWriter',
      'updateBasicInfoArranger',
      'updateBasicInfoLabel',
      'updateBasicInfoIsrc',
      'updateBasicInfoSourceInfo',
      'addToBasicInfoLocalCodes',
      'removeFromBasicInfoLocalCodes',
      'replaceInBasicInfoLocalCodes',
    ]),
    _updateBasicInfoSourceInfo(entry) {
      this.updateBasicInfoSourceInfo(entry);
    },
    updateBasicInfoLocalCode(entryUpdate) {
      this.replaceInBasicInfoLocalCodes({ index: entryUpdate.index, value: entryUpdate.entry });
    },
    addBasicInfoLocalCode() {
      this.addToBasicInfoLocalCodes(createLocalCode());
    },
    _updateBasicInfoName(value) {
      this.updateBasicInfoName(value);
    },
    _updateBasicInfoVersionTitle(value) {
      this.updateBasicInfoVersionTitle(value);
    },
    _updateBasicInfoMainArtist(value) {
      this.updateBasicInfoMainArtist(value);
    },
    _updateBasicInfoRecordingDate(value) {
      this.updateBasicInfoRecordingDate(value);
      if (validationPatterns.UNKNOWN_PROTECTED_DATE.test(value)) {
        this.unknownProtected = true;
      }
    },
    updateUnknownProtected() {
      this.unknownProtected = !this.unknownProtected;
      if (this.unknownProtected) {
        this.updateBasicInfoRecordingDate(UNKNOWN_PROTECTED_DATE);
      } else {
        this.updateBasicInfoRecordingDate('');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 100%;
}

.unknown-protected {
  margin-top: 15px;
}
</style>
