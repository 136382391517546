<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>SDEG Played Recordings</h1>
      </div>
      <div class="col s6 text--right">
        <span>
          <router-link to="create-played-recordings">
            <i class="far fa-edit" />
            Create New
          </router-link>
        </span>
      </div>
    </div>
    <div class="row card">
      <div class="col s12">
        <table class="playedrecordings-table">
          <sortable-head
            :columns="columns"
            :bulk-enabled="false"
            :expanded-content="true"
            :more-options="true"
          />
          <tbody>
            <template v-for="file in playedRecordings">
              <tr :key="`row-${file.id}`">
                <td class="thin-column dropdown-button__arrow" @click="toggleExpand(file)">
                  <i v-if="isExpanded(file)" class="fas fa-caret-up" />
                  <i v-else class="fas fa-caret-down" />
                </td>
                <td>{{ $filters.formatDate(file.created_at) }}</td>
                <td>{{ file.start_year }} - {{ file.end_year }}</td>
                <td>{{ file.name }}</td>
                <td :class="{ 'text--error': file.state === 'E' }">
                  {{ getState(file) }}
                </td>
                <td>
                  <a v-if="file.state === 'C' && file.url" :href="file.url">
                    <i class="far fa-file-alt" />
                    Download
                  </a>
                </td>
                <td class="thin-column">
                  <context-menu :options="['Delete']" @delete="onDeleteFile(file)" />
                </td>
              </tr>
              <tr v-if="isExpanded(file)" :key="`view-${file.id}`">
                <td />
                <td colspan="5">
                  <div class="expanded-view">
                    <div v-if="file.rights" class="rights">
                      <strong>Rights:</strong>
                      <ul class="rights-list">
                        <li v-for="code of file.rights" :key="code">
                          {{ $filters.formatRight(code) }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <div class="report__headers-pagination">
          <pagination
            v-if="numberOfPages > 0"
            :number-of-pages="pagination.numberOfPages"
            :selected-page="pagination.currentPage"
            :number-of-hits="pagination.totalCount"
            :hits-per-page="pagination.itemsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortableHead from '../ui/table/sortable-head';
import ContextMenu from '../ui/context-menu';
import PlayedRecordingsService from '../../services/playedRecordingsService';
import Pagination from '../pagination';

export default {
  components: {
    SortableHead,
    ContextMenu,
    Pagination,
  },
  data() {
    return {
      columns: [
        { name: 'Created' },
        { name: 'Period' },
        { name: 'Name' },
        { name: 'State' },
        { name: 'Download' },
      ],
      expandedRows: [],
      playedRecordings: [],
      pagination: {
        totalCount: 0,
        currentPage: 1,
        itemsPerPage: 25,
      },
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.pagination.totalCount / this.pagination.itemsPerPage);
    },
  },
  created() {
    this.fetchFiles();
  },
  methods: {
    async fetchFiles() {
      const { getPlayedRecordingsFiles } = await PlayedRecordingsService.getPlayedRecordingsFiles({
        limit: this.pagination.itemsPerPage,
        offset: this.pagination.itemsPerPage * (this.pagination.currentPage - 1),
      });
      this.playedRecordings = getPlayedRecordingsFiles.items;
      this.pagination.totalCount = getPlayedRecordingsFiles.total_count;
    },
    selectPage(page) {
      let p = page;
      if (p < 1) {
        p = 1;
      } else if (p > this.numberOfPages()) {
        p = this.numberOfPages();
      }
      this.pagination.currentPage = p;
      this.fetchFiles();
    },
    updateHitsPerPage(hits) {
      this.pagination.itemsPerPage = Number(hits);
      this.fetchFiles();
    },
    expand(file) {
      this.expandedRows.push(file.id);
    },
    contract(file) {
      this.expandedRows = this.expandedRows.filter((id) => id !== file.id);
    },
    toggleExpand(file) {
      if (this.isExpanded(file)) {
        this.contract(file);
      } else {
        this.expand(file);
      }
    },
    isExpanded(file) {
      return this.expandedRows.indexOf(file.id) > -1;
    },
    getState(file) {
      if (file.state === 'P') {
        return `Processing, ${file.progress * 100} %`;
      }
      if (file.state === 'C') {
        return 'Created';
      }
      if (file.state === 'E') {
        return `Error: ${file.error}`;
      }
      return 'None';
    },
    async onDeleteFile(file) {
      await PlayedRecordingsService.deletePlayedRecordingsFile(file.id);
      await this.fetchFiles();
    },
  },
};
</script>
