<template>
  <thead>
    <tr>
      <th v-if="expandEnabled" class="thin-column" />
      <th v-if="bulkEnabled" class="thin-column compare__checkbox">
        <input
          id="markAll"
          ref="mark"
          name="mark"
          type="checkbox"
          class="filled-in"
          :value="marked"
          @change="markAll()"
        />
        <label for="markAll" />
      </th>
      <th
        v-for="column in columns"
        :key="column.name"
        class="push--sides nowrap"
        :class="[{ 'disabled-head': !column.sortParam }, column.styleClass]"
        @click="!bulkSuspend && sortDefault(column)"
      >
        <strong>{{ column.name }}</strong>
        <template v-if="column.sortParam">
          <i
            :class="`fas fa-caret-${column.ascending ? 'up' : 'down'} ${
              column.active ? '' : 'text--grey'
            }`"
          />
        </template>
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'CompareSort',
  props: {
    columns: Array,
    defaultSortColumnIndex: {
      type: Number,
      default: 1,
    },
    bulkEnabled: {
      type: Boolean,
      default: true,
    },
    expandedContent: {
      type: Boolean,
      default: false,
    },
    bulkSuspend: {
      type: Boolean,
      default: false,
    },
    markedForBulk: {
      type: Boolean,
      default: true,
    },
    moreOptions: {
      type: Boolean,
      default: true,
    },
    defaultSortShouldOverride: {
      type: Boolean,
      default: false,
    },
    expandEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localMarkedForBulk: false,
    };
  },
  computed: {
    marked() {
      return this.markedForBulk;
    },
  },
  created() {
    if (!this.defaultSortShouldOverride) {
      this.sortDefault(this.columns[this.defaultSortColumnIndex]);
    }
  },
  updated() {
    if (this.bulkEnabled) {
      this.localMarkedForBulk = this.markedForBulk;
      this.$refs.mark.checked = this.markedForBulk;
    }
  },
  methods: {
    sortDefault(selectedColumn) {
      this.$emit('sort', { selected: selectedColumn, columns: this.columns });
    },
    markAll: function markAll() {
      this.localMarkedForBulk = !this.localMarkedForBulk;
      this.$emit('markAll', this.localMarkedForBulk);
    },
  },
};
</script>
