<template>
  <div>
    <ComponentSpinner v-if="loading" />
    <div v-else class="row card">
      <div class="col s12">
        <table>
          <sortable-head
            :columns="columns"
            :bulk-enabled="false"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @sort="clickSort"
          />
          <tbody>
            <template v-for="(row, idx) in remunerationRows">
              <tr :key="idx" :class="{ 'dist-closure': distClosureYear === row.year }">
                <td class="year">
                  <router-link
                    v-if="row.year"
                    :to="{ name: distributionAreaRouterName, params: { year: row.year } }"
                  >
                    {{ row.year }}
                  </router-link>
                  <span v-else>Unspecified</span>
                </td>
                <td class="amount-row">
                  <span>{{ $filters.formatAmountCent(row.orig) }}</span>
                </td>
                <td class="amount-row">
                  <span>{{ $filters.formatAmountCent(row.fee) }}</span>
                </td>
                <td class="amount-row">
                  <span>{{ $filters.formatAmountCent(row.gross) }}</span>
                </td>
                <td class="amount-row">
                  <span>{{ $filters.formatAmountCent(row.tax) }}</span>
                </td>
                <td class="amount-row net-amount">
                  <span>{{ $filters.formatAmountCent(row.net) }}</span>
                </td>
                <td class="amount-row">
                  <span
                    :class="{
                      negative: Math.sign(Number(row.balance)) === -1,
                      positive: Math.sign(Number(row.balance)) === +1,
                    }"
                  >
                    {{ $filters.formatAmountCent(row.balance) }}
                  </span>
                </td>
              </tr>
            </template>
            <tr class="summary">
              <td class="text--bold">Total sum:</td>
              <td class="amount-row">
                {{ $filters.formatAmountCent(totalOriginalAmount) }}
              </td>
              <td class="amount-row">{{ $filters.formatAmountCent(totalFee) }}</td>
              <td class="amount-row">{{ $filters.formatAmountCent(totalGrossAmount) }}</td>
              <td class="amount-row">{{ $filters.formatAmountCent(totalTax) }}</td>
              <td class="amount-row">{{ $filters.formatAmountCent(totalNetAmount) }}</td>
              <td
                class="amount-row"
                :class="{
                  negative: Math.sign(Number(totalBalance)) === -1,
                  positive: Math.sign(Number(totalBalance)) === +1,
                }"
              >
                {{ $filters.formatAmountCent(totalBalance) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { AGGREGATE_TYPES } from '../../../domain/common';
import ComponentSpinner from '../../component-spinner';
import PaymentService from '../../../services/paymentService';
import SortableHead from '../../ui/table/sortable-head';

export default {
  name: 'ViewRemuneration',
  components: {
    ComponentSpinner,
    SortableHead,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      entityId: '',
      loading: false,
      columns: [
        {
          name: 'Performance year',
          ascending: true,
          sortParam: 'year',
          active: true,
        },
        {
          name: 'Original amount',
          ascending: true,
          sortParam: 'original_amount',
          active: false,
          styleClass: 'original-amount amount-row',
        },
        {
          name: 'Administrative cost',
          ascending: true,
          sortParam: 'fee',
          active: false,
          styleClass: 'amount-row',
        },
        {
          name: 'Gross amount',
          ascending: true,
          sortParam: 'gross_amount',
          active: false,
          styleClass: 'amount-row',
        },
        {
          name: 'Tax',
          ascending: true,
          sortParam: 'tax',
          active: false,
          styleClass: 'amount-row',
        },
        {
          name: 'Net amount',
          ascending: true,
          sortParam: 'net_amount',
          active: false,
          styleClass: 'amount-row',
        },
        {
          name: 'Balance',
          ascending: true,
          sortParam: 'balance',
          active: false,
          styleClass: 'amount-row',
        },
      ],
      sortColumn: 'year',
      sortOrder: 'desc',
      remunerationRows: [],
      totalOriginalAmount: 0,
      totalFee: 0,
      totalGrossAmount: 0,
      totalTax: 0,
      totalNetAmount: 0,
      totalBalance: 0,
      distClosureYear: 0,
    };
  },
  computed: {
    distributionAreaRouterName() {
      let name;
      if (this.type === AGGREGATE_TYPES.PERFORMER) {
        name = 'viewPerformerDistributionArea';
      } else if (this.type === AGGREGATE_TYPES.SOCIETY) {
        name = 'viewSocietyDistributionArea';
      } else {
        name = 'viewAssociateDistributionArea';
      }
      return name;
    },
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.entityId = this.$router.currentRoute.params.id;
      try {
        const res = await PaymentService.getRemunerationBalance(this.entityId);
        this.remunerationRows = res
          // remove "unspecified" row if it has zero balance
          .filter((row) => row.year || row.balance !== 0)
          .map((row) => ({
            ...row,
            gross: row.net - row.tax,
            orig: row.net - row.tax - row.fee,
          }));
        this.calculateTotals();
        this.setDistClosureYear();
        if (this.$route.query.sortColumn) {
          this.sort(this.$route.query.sortColumn, this.$route.query.sortOrder);
        } else {
          this.sort(this.sortColumn, this.sortOrder);
        }
        this.markSorting();
      } catch (error) {
        console.log('Error fetching discography records', error);
      }
      this.loading = false;
    },
    markSorting() {
      this.columns.forEach((column) => {
        if (column.sortParam === this.sortColumn) {
          column.active = true;
          column.ascending = this.sortOrder === 'asc';
        } else {
          column.active = false;
          column.ascending = true;
        }
      });
    },
    clickSort(sort) {
      this.sort(sort.sortParam, sort.ascending ? 'asc' : 'desc');
    },
    sort(sortColumn, sortOrder) {
      this.sortColumn = sortColumn;
      this.sortOrder = sortOrder;
      const sorted = _.orderBy(
        this.remunerationRows,
        (row) => {
          const val = _.get(row, this.sortColumn);
          return val ? Number(val) : val;
        },
        [this.sortOrder],
      );
      this.remunerationRows = sorted;

      const queryParams = {};
      queryParams.sortColumn = sortColumn;
      queryParams.sortOrder = sortOrder;
      this.updateRoute(queryParams);
    },
    updateRoute(queryParams) {
      this.$router.replace({
        name: 'viewPerformerRemunerationBalance',
        query: queryParams,
      });
    },
    calculateTotals() {
      this.remunerationRows.forEach((row) => {
        this.totalOriginalAmount += Number(row.orig);
        this.totalFee += Number(row.fee);
        this.totalGrossAmount += Number(row.net) - Number(row.tax);
        this.totalVat += Number(row.vat);
        this.totalTax += Number(row.tax);
        this.totalNetAmount += Number(row.net);
        this.totalBalance += Number(row.balance);
      });
    },
    setDistClosureYear() {
      const openRows = this.remunerationRows.filter(
        ({ year }) => year >= new Date().getFullYear() - 10,
      );
      if (openRows.length) {
        this.distClosureYear = openRows[openRows.length - 1].year;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dist-closure:not(:nth-last-child(2)) {
  border-bottom: 1px solid var(--grey--medium);
}
</style>
