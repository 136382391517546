import { AGGREGATE_TYPES } from '../../domain/common';
import AssociateService from '../../services/associateService';

/**
 * Creates the config for associate orgNo-valition using unique_org_no-validator (vee-validate).
 * @returns {Object} the config
 */
// eslint-disable-next-line
export const orgNoUnique = {
  entityType: AGGREGATE_TYPES.ASSOCIATE,
  type: undefined,
  streamId: undefined,
  validator: (value, type, streamId) =>
    AssociateService.checkOrgNo(value, type, streamId).then((result) => result),
};
