import { AGGREGATE_TYPES } from '../../domain/common';
import { CHILD_ASSOCIATION_TYPES } from '../../domain/associateDomain';
import { createError, ErrorType } from '../../domain/starNotification';

import PerformerService from '../../services/performerService';
import SearchService from '../../services/searchService';
import ValidationPatterns from '../../common/validationPatterns';
import AuthenticationService from '../../services/authenticationService';

const LATEST_VISITED_VERSION = 1;
const LS_LATEST_VISITED = 'latestVisited';
const LS_LATEST_VISITED_VERSION = 'latestVisitedVersion';
const QUERY_LENGTH_LIMIT = 100;

function isUUID(q) {
  return ValidationPatterns.UUID.test(q);
}

function isSSN(q) {
  return ValidationPatterns.SSN_RELAXED.test(q);
}

function isIPN(q) {
  return ValidationPatterns.IPN.test(q);
}

function isPublicId(q) {
  return ValidationPatterns.PUBLIC_ID.test(q);
}

function isQueryTooLong(q) {
  return q.length > QUERY_LENGTH_LIMIT;
}

function handleUpgradeLatestVisited() {
  const currentVersion = localStorage.getItem(LS_LATEST_VISITED_VERSION);
  if (!currentVersion || Number(currentVersion) < Number(LATEST_VISITED_VERSION)) {
    localStorage.setItem(LS_LATEST_VISITED_VERSION, LATEST_VISITED_VERSION);
    localStorage.removeItem(LS_LATEST_VISITED);
    console.log(
      `*** Bow to the almighty Star! Upgrading latest visited from ${currentVersion} to ${LATEST_VISITED_VERSION}! ***`,
    );
  }
}

function getLatestVisited() {
  const latestVisitedJson = localStorage.getItem(LS_LATEST_VISITED);
  return latestVisitedJson ? JSON.parse(latestVisitedJson) : [];
}

function addToLatestVisited(searchHit) {
  const latestVisited = getLatestVisited();
  const latestVisitedWithoutDuplicates = latestVisited.filter(
    (previousSearchHit) => previousSearchHit.id !== searchHit.id,
  );
  latestVisitedWithoutDuplicates.unshift(searchHit);
  const toStore = latestVisitedWithoutDuplicates.splice(0, 5);
  localStorage.setItem(LS_LATEST_VISITED, JSON.stringify(toStore));
}

function createSearchResult({
  performer = [],
  recording = [],
  mainartist = [],
  album = [],
  associate = [],
  society = [],
  latestVisited = [],
} = {}) {
  const enumerated = [].concat(
    performer,
    recording,
    mainartist,
    album,
    associate,
    society,
    latestVisited,
  );
  return {
    totalHits: enumerated.length,
    performers: performer,
    recordings: recording,
    mainartists: mainartist,
    albums: album,
    associates: associate,
    societies: society,
    latestVisited,
    enumerated,
  };
}

function fetchLatestVisited() {
  return createSearchResult({ latestVisited: getLatestVisited() });
}

function getSimplePerformer(id) {
  return PerformerService.getSimplePerformer(id).then((response) => Promise.resolve(response));
}

function getSimplePerformers(ids) {
  const performers = [];
  ids.forEach((id) => {
    performers.push(getSimplePerformer(id));
  });
  return Promise.all(performers);
}

function getSearchResult(type, result) {
  if (!result || !type) {
    return [];
  }
  switch (type) {
    case AGGREGATE_TYPES.PERFORMER:
      return result.performers || [];
    case AGGREGATE_TYPES.RECORDING:
      return result.recordings || [];
    case AGGREGATE_TYPES.MAINARTIST:
      return result.mainartists || [];
    case AGGREGATE_TYPES.ALBUM:
      return result.albums || [];
    case AGGREGATE_TYPES.ASSOCIATE:
      return result.associates || [];
    case AGGREGATE_TYPES.SOCIETY:
      return result.societies || [];
    default:
      return [];
  }
}

function getSearchResultValue(type, index, result) {
  return getSearchResult(type, result)[index];
}

function mapAggregateResult(result) {
  return {
    totalHits: result && result.hits ? result.hits : 0,
    typedResults: !result
      ? []
      : result.sortList.map((id) => {
          const type = id.split('_')[0];
          const index = id.split('_')[1];
          const value = getSearchResultValue(type, index, result);
          return { type, value };
        }),
  };
}

function mapResult(result, type) {
  return {
    totalHits: result && result.hits ? result.hits : 0,
    results: getSearchResult(type, result).slice(),
  };
}

function fieldSearch(field, value, index) {
  return SearchService.fieldSearch(field, value, index).then((result) => result);
}

function isTwelveDigitSsn(ssnOrOrg) {
  return ssnOrOrg.length === 12 && ssnOrOrg.substring(4, 6) <= 12;
}

function addDelimiterToSsn(ssn) {
  return `${ssn.substring(0, 8)}-${ssn.substring(8)}`;
}

function findBySSN(ssnRelaxed, index) {
  const m = ssnRelaxed.match(ValidationPatterns.SSN_RELAXED);
  if (isTwelveDigitSsn(m[0])) {
    const ssnWithDelimiter = addDelimiterToSsn(m[0]);
    return fieldSearch('org_ssn_no', ssnWithDelimiter, index);
  }
  return fieldSearch('org_ssn_no', m[0], index);
}

function findByIPN(ipn, index) {
  return fieldSearch('ipn', ipn, index);
}

function findByPublicId(publicId, index) {
  return fieldSearch('local_codes', publicId, index);
}

function findByStreamId(id, index) {
  return fieldSearch('_id', id, index);
}

function quickSearch(terms, types, maxPerType = 5) {
  if (terms && terms.trim().length >= 3) {
    return SearchService.quickSearch(terms).then((result) => {
      const obj = {};
      Object.values(AGGREGATE_TYPES).forEach((aggType) => {
        if (!types || types.includes(aggType)) {
          obj[aggType] = Array.from(getSearchResult(aggType, result)).splice(0, maxPerType);
        }
      });
      return Promise.resolve(createSearchResult(obj));
    });
  }
  return Promise.reject({ type: 'MIN_CHAR', message: 'terms < min char length' });
}

async function searchUser(terms) {
  if (!terms || terms.length < 2) {
    throw createError(ErrorType.MINIMUM_CHARACTER, 'At least two characters required');
  }
  const usersResult = await AuthenticationService.listUsers();
  return usersResult.users.filter((user) => user.name.toLowerCase().includes(terms.toLowerCase()));
}

async function getAllTags() {
  const tags = await SearchService.getAllTags();
  return tags.map((tag) => ({
    name: tag.name,
    count: tag.count,
    type: tag.count > 99999 ? 'defaultTag' : 'tag',
  }));
}

async function tagSearch(allTags, terms, localTags) {
  const tags = allTags
    .filter((tag) => tag.name.toLowerCase().includes(terms.toLowerCase()))
    .filter((tag) =>
      localTags ? !localTags.map((localTag) => localTag.name).includes(tag.name) : true,
    )
    .map((tag) => ({
      name: tag.name,
      count: tag.count,
      type: 'tag',
    }));
  return tags;
}

function quickSearchRecording(terms) {
  return quickSearch(terms, [AGGREGATE_TYPES.RECORDING], 10).then((result) => result.recordings);
}

function quickSearchAlbum(terms) {
  return quickSearch(terms, [AGGREGATE_TYPES.ALBUM], 10).then((result) => result.albums);
}

function advancedSearchAll(query, dateFrom, dateTo, countryCode, tags, pageFrom, pageSize) {
  const searchRequest = {
    query,
    dateFrom,
    dateTo,
    countryCode,
    tags,
    pageFrom,
    pageSize,
  };
  return SearchService.advancedSearchAll(searchRequest).then(mapAggregateResult);
}

function advancedSearchMainArtists(
  query,
  originCountry,
  genre,
  tags,
  sortParam,
  sortOrder,
  pageFrom,
  pageSize,
  minLineupLength,
) {
  const searchRequest = {
    query,
    originCountry,
    genre,
    tags,
    sortParam,
    sortOrder,
    pageFrom,
    pageSize,
    minLineupLength,
  };
  return SearchService.advancedSearchMainArtists(searchRequest).then((result) =>
    mapResult(result, AGGREGATE_TYPES.MAINARTIST),
  );
}

function advancedSearchRecordings(
  query,
  mainArtist,
  label,
  countryCode,
  lineup,
  tags,
  sortParam,
  sortOrder,
  pageFrom,
  pageSize,
  member,
  recordingYear,
  releaseYear,
) {
  const searchRequest = {
    query,
    mainArtist,
    label,
    countryCode,
    lineup,
    tags,
    sortParam,
    sortOrder,
    pageFrom,
    pageSize,
    member,
    recordingYear,
    releaseYear,
  };
  return SearchService.advancedSearchRecordings(searchRequest).then((result) =>
    mapResult(result, AGGREGATE_TYPES.RECORDING),
  );
}

function advancedSearchPerformers(searchTerms, sortOrder, sortParam, pageFrom, pageSize) {
  const searchRequest = {
    ...searchTerms,
    sortOrder,
    sortParam,
    pageFrom,
    pageSize,
  };
  if (sortParam === 'pseudo_names.keyword') {
    searchRequest.sortMode = 'min';
  }
  return SearchService.advancedSearchPerformers(searchRequest).then((result) =>
    mapResult(result, AGGREGATE_TYPES.PERFORMER),
  );
}

function advancedSearchAlbums(
  query,
  mainArtist,
  catalogNumber,
  label,
  tags,
  sortParam,
  sortOrder,
  pageFrom,
  pageSize,
) {
  const searchRequest = {
    query,
    mainArtist,
    catalogNumber,
    label,
    tags,
    sortParam,
    sortOrder,
    pageFrom,
    pageSize,
  };
  return SearchService.advancedSearchAlbums(searchRequest).then((result) =>
    mapResult(result, AGGREGATE_TYPES.ALBUM),
  );
}

function advancedSearchAssociatesByRequest(searchRequest) {
  return SearchService.advancedSearchAssociates(searchRequest).then((result) =>
    mapResult(result, AGGREGATE_TYPES.ASSOCIATE),
  );
}

function advancedSearchAssociates(
  query,
  countryCode,
  types,
  tags,
  sortParam,
  sortOrder,
  pageFrom,
  pageSize,
) {
  const searchRequest = {
    query,
    countryCode,
    types,
    tags,
    sortParam,
    sortOrder,
    pageFrom,
    pageSize,
  };
  return advancedSearchAssociatesByRequest(searchRequest);
}

function advancedSearchSocieties(
  query,
  countryCode,
  tags,
  sortParam,
  sortOrder,
  pageFrom,
  pageSize,
) {
  const searchRequest = {
    query,
    countryCode,
    tags,
    sortParam,
    sortOrder,
    pageFrom,
    pageSize,
  };
  return SearchService.advancedSearchSocieties(searchRequest).then((result) =>
    mapResult(result, AGGREGATE_TYPES.SOCIETY),
  );
}

function nameSearch(terms, index, types) {
  if (terms && terms.trim().length >= 2) {
    if (isUUID(terms)) {
      return findByStreamId(terms, index).then((result) => Promise.resolve(result));
    }
    if (isSSN(terms)) {
      return findBySSN(terms, index).then((result) => Promise.resolve(result));
    }
    if (isIPN(terms)) {
      return findByIPN(terms, index).then((result) => Promise.resolve(result));
    }
    return SearchService.nameSearch(terms, types).then((result) =>
      result.map((nameSearchHit) => ({
        id: nameSearchHit.id,
        name: nameSearchHit.name,
        display_name: nameSearchHit.display_name,
        alternate_names: nameSearchHit.alternate_names,
        type: nameSearchHit.relation_type,
        relation_type: nameSearchHit.relation_type,
        has_payment_info: nameSearchHit.has_payment_info,
        nationality: nameSearchHit.nationality,
        date_of_birth: nameSearchHit.date_of_birth,
        ipn: nameSearchHit.membership_info ? nameSearchHit.membership_info.ipn : '',
        main_instrument: nameSearchHit.membership_info
          ? nameSearchHit.membership_info.main_instrument
          : '',
        main_artists: nameSearchHit.membership_info
          ? nameSearchHit.membership_info.main_artists
          : '',
        mandates: nameSearchHit.membership_info ? nameSearchHit.membership_info.mandates : '',
      })),
    );
  }
  return Promise.reject({ type: 'MIN_CHAR', message: 'terms < min char length' });
}

function nameSearchMainArtist(terms) {
  if (isPublicId(terms)) {
    return findByPublicId(terms, 'mainartists', [AGGREGATE_TYPES.MAINARTIST]).then((result) =>
      Promise.resolve(result),
    );
  }
  return nameSearch(terms, 'mainartists', [AGGREGATE_TYPES.MAINARTIST]);
}

function nameSearchPerformer(terms) {
  return nameSearch(terms, 'performers', [AGGREGATE_TYPES.PERFORMER]);
}

function nameSearchAssociates(terms) {
  return nameSearch(terms, 'associates', [
    CHILD_ASSOCIATION_TYPES.ASSOCIATE_HEIR,
    CHILD_ASSOCIATION_TYPES.ASSOCIATE_COMPANY,
    CHILD_ASSOCIATION_TYPES.ASSOCIATE_INTERMEDIARY,
    CHILD_ASSOCIATION_TYPES.ASSOCIATE_MANAGER,
    CHILD_ASSOCIATION_TYPES.ASSOCIATE_ACCOUNTING_FIRM,
    CHILD_ASSOCIATION_TYPES.ASSOCIATE_LEGAL_GUARDIAN,
    CHILD_ASSOCIATION_TYPES.ASSOCIATE_OTHER,
  ]);
}

function searchAssociatesByType(query, types) {
  return advancedSearchAssociatesByRequest({ query, types }).then((result) =>
    result.results.map((associate) => ({
      id: associate.id,
      name: associate.name,
      display_name: associate.name,
      type: AGGREGATE_TYPES.ASSOCIATE,
      associate_type: associate.associate_type,
    })),
  );
}

export default {
  handleUpgradeLatestVisited,
  addToLatestVisited,
  fetchLatestVisited,
  getSimplePerformer,
  getSimplePerformers,
  nameSearchMainArtist,
  getAllTags,
  tagSearch,
  nameSearchPerformer,
  nameSearchAssociates,
  searchAssociatesByType,
  quickSearch,
  quickSearchRecording,
  quickSearchAlbum,
  advancedSearchAll,
  advancedSearchRecordings,
  advancedSearchPerformers,
  advancedSearchMainArtists,
  advancedSearchAlbums,
  advancedSearchSocieties,
  advancedSearchAssociates,
  findByStreamId,
  searchUser,
  isUUID,
  isQueryTooLong,
  QUERY_LENGTH_LIMIT,
};
