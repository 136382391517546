<template>
  <div class="worklist">
    <div class="row">
      <div class="col s6">
        <h2>{{ label }}</h2>
      </div>
    </div>
    <div v-if="categories.length > 1" class="row small-inner">
      <div class="col s12 filter">
        <filter-tags
          :tags="categories"
          :active-tab="activeTab"
          all-tag="All categories"
          @filter="filterByTags"
          @filterAll="filterByAllTags"
        />

        <select
          v-if="activeTab === 'homeViewAllWorklists'"
          class="filter--assignee"
          :disabled="markedItems.length > 0"
          @change="filterByAssignee($event.target.value)"
        >
          <option value="" selected>All assignees</option>
          <option
            v-for="(assignee, index) in assignees"
            :key="`assignee-${index}`"
            :value="assignee"
          >
            {{ assignee }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="activeTab === 'homeViewPersonalWorklist'" class="row">
      <bulk-action
        v-if="activeTab === 'homeViewPersonalWorklist'"
        :enabled="markedItems.length > 0"
        :options="['Delete']"
        @input="onBulkDelete"
      />
    </div>
    <div class="row">
      <div class="card col s12 l8">
        <table>
          <thead>
            <th v-if="activeTab === 'homeViewPersonalWorklist'">
              <input
                id="markAll"
                type="checkbox"
                class="filled-in"
                :checked="allMarked"
                @change="markAll()"
              />
              <label for="markAll" />
            </th>
            <th v-if="activeTab === 'homeViewPersonalWorklist'">Date Added</th>
            <th v-else>Created</th>
            <th>Name</th>
            <th>Type</th>
            <th v-if="activeTab === 'homeViewPersonalWorklist'">Note</th>
            <th v-else>Assigned to</th>
            <th class="thin-column" />
          </thead>
          <tbody v-if="items.length > 0">
            <template v-for="(item, index) in items">
              <tr :key="`item-row1-${index}`">
                <td v-if="activeTab === 'homeViewPersonalWorklist'">
                  <input
                    :id="'mark_' + index"
                    type="checkbox"
                    class="filled-in"
                    :checked="isMarked(item)"
                    @change="markOne(item)"
                  />
                  <label :for="'mark_' + index" />
                </td>
                <td>
                  <span v-if="activeTab === 'homeViewPersonalWorklist'">
                    {{ $filters.formatDate(item.added) }}
                  </span>
                  <span v-else>
                    {{ $filters.formatDate(item.created) }}
                  </span>
                </td>
                <td>
                  <router-link :to="item.url">
                    {{ item.name }}
                  </router-link>
                </td>
                <td>
                  {{ item.category }}
                </td>
                <td v-if="activeTab !== 'homeViewPersonalWorklist' && editMode === index">
                  <form data-vv-scope="update-assignee" :style="{ width: '80%' }">
                    <searchable-input
                      ref="assignee"
                      :name="item.assignee + '.assignee'"
                      scope="update-assignee"
                      placeholder="E.g. peter"
                      use-template="user"
                      label="Username"
                      :value="item.assignee"
                      :searcher="searchUser"
                      @input="updateAssignee($event, item)"
                    />
                  </form>
                </td>
                <td v-else-if="activeTab !== 'homeViewPersonalWorklist'">
                  {{ item.assignee }}
                </td>
                <td v-else>
                  <span v-if="item.note">
                    {{ item.note }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <context-menu
                    :options="
                      activeTab !== 'homeViewPersonalWorklist' ? ['Edit', 'Delete'] : ['Delete']
                    "
                    @edit="handleEditLinkClick(item, index)"
                    @delete="handleDeleteIncomingClick(item, index)"
                  />
                </td>
              </tr>
              <tr
                v-if="activeTab !== 'homeViewPersonalWorklist' && editMode === index"
                :key="`item-row2-${index}`"
              >
                <td colspan="6">
                  <action-buttons
                    class="float-right"
                    :style="{ 'margin-right': '10px' }"
                    scope="update-assignee"
                    @save="saveUpdatedWorklist"
                    @cancel="cancel(item)"
                  />
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else class="none">
            <tr>
              <td colspan="6">No worklists.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import {
  getAllWorklists,
  getWorklistsByAssignee,
  getWorklist,
  saveWorklist,
  deleteWorklist,
} from '../../../services/worklistsService';
import AuthenticationService from '../../../services/authenticationService';
import distributionService from '../../../services/distributionService';
import ContextMenu from '../../ui/context-menu';
import ActionButtons from '../../ui/button/action-buttons';
import SearchableInput from '../../ui/input/searchable-input';
import FilterTags from '../../ui/tag/filter-tags';
import SearchHelper from '../../search/searchHelper';
import WorklistHelper from './worklistHelper';
import BulkAction from '../../ui/table/bulk-action';

export default {
  name: 'Worklists',
  components: {
    ContextMenu,
    SearchableInput,
    ActionButtons,
    FilterTags,
    BulkAction,
  },
  inject: ['$validator'],
  data() {
    return {
      items: [],
      originalItems: [],
      categories: [],
      assignees: [],
      filteredCategories: [],
      filteredAssignee: null,
      lastKey: null,
      userName: '',
      editMode: false,
      originalWorklist: {},
      worklistToUpdate: {},
      allMarked: false,
      markedItems: [],
      activeTab: '',
      label: '',
    };
  },
  watch: {
    $route() {
      this.fetchData();
      this.cancel();
    },
  },
  created() {
    this.userName = AuthenticationService.getUserName();
    this.$nextTick(() => {
      this.fetchData();
    });
  },
  methods: {
    async fetchData() {
      this.activeTab = this.$router.currentRoute.name;
      this.label = this.$router.currentRoute.meta.name;
      this.items = [];
      this.lastKey = null;
      if (this.activeTab === 'homeViewAssignedWorklists') {
        await this.fetchWorklistsAssignedToMe();
      } else if (this.activeTab === 'homeViewPersonalWorklist') {
        await this.fetchPersonalWorklist();
      } else if (this.activeTab === 'homeViewAllWorklists') {
        await this.fetchAllWorklists();
      }
      this.originalItems = _.clone(this.items);
      this.sort();
      this.setFilterOptions();
    },
    async fetchWorklistsAssignedToMe() {
      try {
        this.items = await getWorklistsByAssignee(this.userName);
      } catch (e) {
        console.log("Couldn't fetch data", e);
      }
    },
    async fetchAllWorklists() {
      try {
        const worklistsResult = await getAllWorklists(this.lastKey);
        this.items = this.items.concat(worklistsResult.worklists);
        this.lastKey = worklistsResult.lastEvaluatedKey;
        if (this.lastKey) {
          await this.fetchAllWorklists();
        }
      } catch (e) {
        console.log("Couldn't fetch data", e);
      }
    },
    async fetchPersonalWorklist() {
      try {
        this.items = await getWorklist(this.userName);
      } catch (e) {
        console.log("Couldn't fetch data", e);
      }
    },
    handleEditLinkClick(worklist, index) {
      this.editMode = index;
      this.originalWorklist = _.cloneDeep(worklist);
      this.worklistToUpdate = {
        worklist_id: worklist.worklistId,
        assignee: worklist.assignee,
        payload: {
          name: worklist.name,
          category: worklist.category,
          url: worklist.url,
        },
      };
    },
    handleDeleteIncomingClick(item, index) {
      if (this.activeTab === 'homeViewPersonalWorklist') {
        this.deleteFromPersonalWorklist(item, index);
      } else {
        this.deleteWorklist(item, index);
      }
    },
    sort() {
      this.items.sort((a, b) => moment(b.created).unix() - moment(a.created).unix());
    },
    searchUser(terms) {
      return SearchHelper.searchUser(terms);
    },
    async updateAssignee(user, item) {
      if (user) {
        item.assignee = user.name;
        this.worklistToUpdate.assignee = user.name;
      }
    },
    cancel(item) {
      this.editMode = false;
      if (this.originalWorklist.assignee) {
        item.assignee = this.originalWorklist.assignee;
        this.worklistToUpdate = {};
        this.originalWorklist = {};
      }
    },
    async saveUpdatedWorklist() {
      try {
        this.deleteWorklist(this.originalWorklist);
        await saveWorklist(this.worklistToUpdate);
        this.worklistToUpdate = {};
        this.originalWorklist = {};
        this.editMode = false;
        this.fetchData();
      } catch (e) {
        console.log("Couldn't save worklist", e);
      }
    },
    setFilterOptions() {
      this.categories = Array.from(new Set(this.originalItems.map((item) => item.category)));
      this.categories.unshift('All categories');
      this.assignees = Array.from(new Set(this.originalItems.map((item) => item.assignee)));
    },
    async deleteFromPersonalWorklist(item, index) {
      try {
        const orgIndex = this.originalItems.indexOf(item);
        if (orgIndex >= 0) {
          this.originalItems.splice(orgIndex, 1);
        }
        this.items.splice(index, 1);
        await WorklistHelper.deleteFromPersonalWorklist(this.items, this.userName);
        this.setFilterOptions();
      } catch (e) {
        console.log("Couldn't delete item", e);
      }
    },
    async deleteWorklist(worklist, index) {
      this.items.splice(index, 1);
      try {
        await deleteWorklist(worklist);
        await distributionService.deleteWorklist(worklist.worklistId);
        this.setFilterOptions();
      } catch (e) {
        console.log("Couldn't delete worklist", e);
      }
    },
    filterByTags(tags) {
      this.filteredCategories = tags;
      this.filter();
      this.sort();
    },
    filterByAllTags() {
      this.filteredCategories = [];
      this.filter();
      this.sort();
    },
    filterByAssignee(assignee) {
      if (assignee) {
        this.filteredAssignee = assignee;
      } else {
        this.filteredAssignee = null;
      }
      this.filter();
      this.sort();
    },
    filter() {
      let filteredItems = [];
      if (this.filteredCategories.length > 0) {
        filteredItems = this.originalItems.filter((item) =>
          this.filteredCategories.includes(item.category),
        );
      } else {
        filteredItems = this.originalItems;
      }
      if (this.filteredAssignee) {
        filteredItems = filteredItems.filter((item) => item.assignee === this.filteredAssignee);
      }
      this.items = filteredItems;
      this.allMarked = this.items.every((item) => this.isMarked(item));
    },
    isMarked(item) {
      return this.markedItems.includes(item);
    },
    markAll() {
      if (this.allMarked) {
        this.markedItems = [];
        this.allMarked = false;
      } else {
        this.markedItems = _.clone(this.items);
        this.allMarked = true;
      }
    },
    markOne(item) {
      if (this.markedItems.indexOf(item) !== -1) {
        this.markedItems.splice(this.markedItems.indexOf(item), 1);
        this.allMarked = false;
      } else {
        this.markedItems.push(item);
        this.allMarked = this.items.every((itm) => this.isMarked(itm));
      }
    },
    async onBulkDelete(selected) {
      if (selected === 'delete') {
        this.markedItems.forEach((item) => {
          const orgIndex = this.originalItems.indexOf(item);
          const index = this.items.indexOf(item);

          if (orgIndex >= 0) {
            this.originalItems.splice(orgIndex, 1);
          }

          if (index >= 0) {
            this.items.splice(index, 1);
          }
        });
        WorklistHelper.deleteFromPersonalWorklist(this.originalItems, this.userName);
        this.allMarked = false;
        this.markedItems = [];
        this.setFilterOptions();
      }
    },
  },
};
</script>
