<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Projectors Progress</h1>
      </div>
    </div>

    <div class="row small-inner">
      <div class="col s12">
        <h2>Current Sequence No</h2>
      </div>
    </div>

    <div class="row card">
      <DataTable :columns="currentColumns" :items="currentData">
        <template #item:sequence_no="{ item }">
          {{ $filters.formatNumber(item.sequence_no) }}
        </template>
      </DataTable>
    </div>

    <div class="row small-inner">
      <div class="col s12">
        <h2>Projectors Progress</h2>
      </div>
    </div>

    <div class="row card report__headers">
      <DataTable :columns="progressColumns" :items="progressData">
        <template #item:status="{ item }">
          <span
            class="chip"
            :class="'report__headers-chip--' + (item.unprocessed > 0 ? 'wait' : 'ok')"
          >
            {{ item.unprocessed > 0 ? 'Processing' : 'Idle' }}
          </span>
        </template>
        <template #item:unprocessed="{ item }">
          {{ $filters.formatNumber(item.unprocessed) }}
        </template>
        <template #item:sequence_no="{ item }">
          {{ $filters.formatNumber(item.sequence_no) }}
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script>
import ProjectorsService from '../../services/projectorsService';
import DataTable from '../ui/table/DataTable';

export default {
  name: 'ViewProjectorsProgress',
  components: {
    DataTable,
  },
  data() {
    return {
      pnrSequenceNo: 0,
      paymentSequenceNo: 0,
      currentData: [],
      currentColumns: [
        { key: 'events', text: 'Events ' },
        { key: 'sequence_no', text: 'Sequence No' },
      ],
      progressColumns: [
        { key: 'service', text: 'Service ' },
        { key: 'src', text: 'Source' },
        { key: 'status', text: 'Status' },
        { key: 'unprocessed', text: 'Unprocessed Events' },
        { key: 'sequence_no', text: 'Sequence No' },
      ],
      progressData: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await ProjectorsService.getProjectorsProgress();
        this.currentData = response.events;
        this.progressData = response.projectors;
      } catch (err) {
        this.$addStarError(err);
      }
    },
  },
};
</script>
