<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>End Of Year Statement for {{ performerName }}</h1>
      </div>
    </div>

    <end-of-year-statement-table :id="id" />
  </div>
</template>

<script>
import PerformerService from '../../services/performerService';
import EndOfYearStatementTable from './end-of-year-statement-table';

export default {
  name: 'ViewPerformerEndOfYearStatement',
  components: {
    EndOfYearStatementTable,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id.toUpperCase(),
      performerName: '',
    };
  },
  async created() {
    const performer = await PerformerService.getPerformer(this.id);
    this.performerName = `${performer.general_info.first_name} ${performer.general_info.last_name}`;
  },
};
</script>
