<template>
  <div class="searchable-input">
    <div class="searchable-input__input-component">
      <template v-if="showLabel">
        <label :class="{ label: true, 'label--empty': !label, 'label--error': showErrorMessage }">
          {{ label }}
        </label>
        <span v-if="mandatory" class="mandatory">(mandatory)</span>
      </template>
      <div
        class="searchable-input__chips chips"
        :class="{ 'is-danger-chip': showErrorMessage }"
        @click="clickHandler"
      >
        <template v-for="(tag, index) in tags">
          <div
            :key="index"
            class="chip"
            :tabindex="100 + index"
            @keydown.prevent.delete="removeTag(index)"
          >
            <span>{{ tag.name }}</span>
            <i class="fas fa-times close" @click="removeTag(index)"></i>
          </div>
        </template>
        <input
          ref="newSearchTag"
          v-validate="computedRules"
          type="search"
          :name="name"
          :placeholder="tags.length === 0 ? placeholder : null"
          class="input"
          :value="query"
          :data-vv-as="label.toLowerCase()"
          autocomplete="off"
          @input="updateQuery($event.target.value)"
          @keydown.down="openSearchResults()"
          @click="openSearchResults()"
          @keydown.prevent.down="nextHit"
          @keydown.prevent.up="previousHit"
          @keydown.prevent.esc="clearSearch"
          @keydown.delete="removeLastTag"
          @keydown.prevent.enter="selected"
          @blur="closeSearchResults()"
        />
      </div>

      <span v-show="validationErrors.has(name, scope)" class="help is-danger">
        <i class="fas fa-times-circle"></i>
        {{ validationErrors.first(name, scope) }}
      </span>
    </div>
    <div v-show="showDropdown" class="searchable-input__autocomplete">
      <table>
        <thead>
          <tr class="header-row">
            <template v-for="(entry, index) in resultTemplate">
              <th :key="index" class="nowrap">{{ entry.label }}</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="searchResult.length > 0">
            <tr
              v-for="(result, index) in searchResult"
              :key="index"
              class="result-row"
              :class="{ selected: index === selectedIndex }"
              @mouseover.prevent="setSelectedIndex(index)"
              @mousedown.prevent="selected"
            >
              <template v-for="(entry, idx) in resultTemplate">
                <td v-if="entry.icon" :key="idx" class="nowrap" />
                <td v-else :key="idx">
                  <span
                    v-if="entry.highlight"
                    v-html="$filters.highlightToHtml(getValue(result, entry), query)"
                  />
                  <span v-else v-html="getValue(result, entry)" />
                </td>
              </template>
            </tr>
          </template>
          <template v-else>
            <tr class="tags__list-item">No results.</tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import DebouncedSearchMixin from '../../../common/debouncedSearchMixin';
import Templates from '../input/searchable-input-templates';

export default {
  name: 'SearchableTagWithColumnsInput',
  mixins: [DebouncedSearchMixin(350)],
  inject: ['$validator'],
  props: {
    showLabel: { type: Boolean, default: true },
    placeholder: { type: String },
    label: { type: String, default: '' },
    mandatory: { type: Boolean, default: false },
    minChars: { type: String },
    searcher: { type: Function },
    simpleSearcher: { type: Function },
    rule: {
      type: [String, Object],
      default: '',
    },
    name: { type: String },
    useTemplate: { type: String },
    scope: { type: String, default: undefined },
    valueList: {
      type: Array,
      default: () => [],
    },
    previewList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchResult: [],
      resultTemplate: [],
      selectedIndex: -1,
      showDropdown: false,
      query: this.value || '',
      tags: this.valueList ? this.valueList.slice() : [],
      below: false,
    };
  },
  computed: {
    showErrorMessage() {
      if (this.validationErrors.has(this.name, this.scope)) {
        return true;
      }
      const field = this.validationFields[this.name];
      return field && field.touched && field.invalid;
    },
    computedRules() {
      const ruleCopy = _.cloneDeep(this.rule);
      if (_.isObject(ruleCopy) && this.tags.length > 0) {
        ruleCopy.required = false;
      }
      return Object.assign(
        {},
        {
          reserved: this.$store.state.appdata.referenceData.tagsInternal,
          requiredTag: this.rule && this.rule.required ? this.tags : false,
        },
        ruleCopy,
      );
    },
  },
  watch: {
    valueList(list) {
      this.tags = list;
    },
  },
  methods: {
    updateQuery(terms) {
      if (!terms) {
        this.$emit('input', null);
      }
      this.query = terms;
      this.debouncedSearch(this.query);
    },
    search(terms) {
      this.clearSelectedIndex();
      if (this.simpleSearcher) {
        this.simpleSearch(terms);
      } else {
        this.searcher(terms)
          .then((result) => {
            this.resultTemplate = result[0] ? Templates[this.useTemplate] : [];
            this.searchResult = result;
            if (this.searchResult) {
              this.openSearchResults();
            }
          })
          .catch((error) => {
            if (error.type === 'MIN_CHAR') {
              this.searchResult = [];
              this.closeSearchResults();
            }
          });
      }
    },
    simpleSearch(terms) {
      const result = this.simpleSearcher(terms);
      this.resultTemplate = result.length > 0 ? Templates.country : [];
      this.searchResult = result.filter((value) => !this.valueList.includes(value));
      if (this.searchResult) {
        this.openSearchResults();
      }
    },
    selected() {
      if (
        this.$validator.errors.items.length > 0 &&
        this.$validator.errors.items.find((i) => i.rule === 'reserved')
      ) {
        return;
      }
      let selectedHit = null;
      if (this.selectedIndex !== -1) {
        selectedHit = this.searchResult[this.selectedIndex];
        this.tags.push(selectedHit);
        this.query = '';
        this.closeSearchResults();
        if (selectedHit) {
          this.$emit('add', { data: this.tags, item: selectedHit });
        }
      }
    },
    getValue(result, entry) {
      let valueWithTransformations = _.get(result, entry.field, null);
      if (entry.transform) {
        valueWithTransformations = entry.transform(valueWithTransformations);
      }
      if (entry.translationPrefix) {
        valueWithTransformations = this.$filters.translate(
          `${entry.translationPrefix}.${valueWithTransformations}`,
        );
      }
      if (entry.filter) {
        if (this.$filters[entry.filter]) {
          valueWithTransformations = this.$filters[entry.filter](valueWithTransformations);
        }
      }
      return valueWithTransformations;
    },
    setSelectedIndex(index) {
      this.selectedIndex = index;
    },
    nextHit() {
      this.selectedIndex =
        this.selectedIndex < this.searchResult.length - 1
          ? this.selectedIndex + 1
          : this.searchResult.length - 1;
    },
    previousHit() {
      this.selectedIndex = this.selectedIndex > 1 ? this.selectedIndex - 1 : 0;
    },
    openSearchResults() {
      if (this.previewList.length > 0 && this.query.length === 0) {
        this.resultTemplate = this.previewList[0] ? Templates[this.previewList[0].type] : [];
        this.searchResult = this.previewList;
        this.showDropdown = true;
      }
      if (this.searchResult && this.query.length > 0) {
        this.showDropdown = true;
      }
    },
    removeTag(idx) {
      const item = this.tags.splice(idx, 1);
      this.$emit('remove', { idx, data: this.tags, item: item.pop() });
    },
    removeLastTag(e) {
      if (this.query === '') {
        e.preventDefault();
        const idx = this.tags.length - 1;
        const item = this.tags.splice(idx, 1);
        this.$emit('remove', { idx, data: this.tags, item: item.pop() });
      }
    },
    clearSelectedIndex() {
      this.setSelectedIndex(-1);
    },
    closeSearchResults() {
      this.showDropdown = false;
      this.clearSelectedIndex();
    },
    removeSelected() {
      this.removeTag(this.tags.length - 1);
    },
    clearSearch(e) {
      if (!e || !e.relatedTarget || !e.relatedTarget.hasAttribute('data-event')) {
        this.query = '';
        this.closeSearchResults();
        this.$emit('clear', null);
      }
    },
    setFocus() {
      this.$refs.newSearchTag.focus();
    },
    dropdownCheck() {
      const list = document.querySelector('.searchable-input').getBoundingClientRect();
      if (this.showDropdown) {
        if (list.bottom < 0 || list.top < 0) {
          this.below = true;
        } else {
          this.below = false;
        }
      }
    },
    clickHandler() {
      this.setFocus();
      this.dropdownCheck();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.searchable-input {
  &__autocomplete {
    position: absolute;
    min-width: 50vw;
    width: 100%;
    max-width: 100vw;
    z-index: 100;
    background-color: var(--white);
    border: 1px solid var(--grey--dark);
    padding-top: var(--spacing);
    margin-bottom: var(--spacing-sixfold);
  }
}
.tags {
  display: block;
  width: 100%;

  &__list {
    margin: 0;
    padding: var(--spacing-double) 0;

    &-item {
      padding: var(--spacing) var(--spacing-double);
      cursor: pointer;

      &:hover {
        background-color: var(--list-hover-blue);
      }
      &.selected {
        background-color: var(--list-hover-blue);
      }
    }
  }
}

.searchable-input__chips {
  padding-left: 4px;
  border-radius: 2px;
}
</style>
