<template>
  <form :data-vv-scope="scope">
    <div class="row card">
      <delete-button :is-component="true" @click="removeAlbum" />
      <div class="row">
        <div class="col s4">
          <validated-text-input
            ref="albumName"
            name="album_name"
            :scope="scope"
            label="Name"
            :mandatory="true"
            placeholder="E.g. Du & jag döden"
            :value="currentAlbum.album_name"
            rule="required"
            @input="updateAlbumName"
          />
        </div>

        <div class="col s4">
          <validated-text-input
            name="side_no"
            label="Side no"
            :scope="scope"
            :value="1"
            :rule="{ required: true, numeric: true, min_value: 1 }"
            @input="updateSideNo"
          />
        </div>

        <div class="col s4">
          <validated-text-input
            name="track_no"
            label="Track no"
            :scope="scope"
            :value="1"
            :rule="{ required: true, numeric: true, min_value: 1 }"
            @input="updateTrackNo"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <main-artist-input
            ref="mainArtist"
            name="main_artist"
            label="Main artist"
            :mandatory="true"
            :scope="scope"
            placeholder="E.g. Kent"
            :notify-validation="true"
            @input="updateMainArtist"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <select-country
            name="produced_in_country"
            label="Produced in country"
            :value="currentAlbum.produced_in_country"
            @input="updateProducedInCountry"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s8">
          <validated-text-input
            name="catalog_number"
            label="Catalogue number"
            placeholder="E.g. 123456"
            :value="currentAlbum.catalog_number"
            rule=""
            @input="updateCatalogNumber"
          />
        </div>
      </div>

      <div class="row flush--bottom">
        <div class="col s8">
          <select-date
            name="release_date"
            :scope="scope"
            label="Release date"
            :value="currentAlbum.release_date"
            @input="updateReleaseDate"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DeleteButton from '../../ui/button/delete-button';
import MainArtistInput from '../../ui/input/main-artist-input';
import SelectDate from '../../ui/select/select-date';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import SelectCountry from '../../ui/select/select-country';

export default {
  name: 'CreateNewAlbum',
  components: {
    SelectCountry,
    DeleteButton,
    SelectDate,
    ValidatedTextInput,
    MainArtistInput,
  },
  inject: ['$validator'],
  props: {
    album: { type: Object },
    name: { type: String },
    countries: { type: Array },
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentAlbum: Object.assign({}, this.album),
    };
  },
  methods: {
    updateAlbumName(value) {
      this.currentAlbum.album_name = value;
      this.emitUpdate();
    },
    updateMainArtist(value) {
      this.currentAlbum.main_artist = value;
      this.emitUpdate();
    },
    updateProducedInCountry(value) {
      this.currentAlbum.produced_in_country = value;
      this.emitUpdate();
    },
    updateCatalogNumber(value) {
      this.currentAlbum.catalog_number = value;
      this.emitUpdate();
    },
    updateReleaseDate(value) {
      this.currentAlbum.release_date = value;
      this.emitUpdate();
    },
    updateSideNo(value) {
      this.currentAlbum.side_no = value;
      this.emitUpdate();
    },
    updateTrackNo(value) {
      this.currentAlbum.track_no = value;
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit('input', this.currentAlbum);
    },
    removeAlbum() {
      this.$emit('remove');
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  span.delete-button.delete-button--component {
    right: 0;
  }
}
</style>
