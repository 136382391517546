import { mutate, query } from './apolloRequest';
import gql from '../domain/mediarcGql';

export default {
  fetchAlbumLines: async (request) =>
    query(
      { query: gql.fetchMediarcAlbumLines, variables: { request } },
      { pickProp: 'fetchMediarcAlbumLines' },
    ),
  fetchRecordingLines: async (request) =>
    query(
      { query: gql.fetchMediarcRecordingLines, variables: { request } },
      { pickProp: 'fetchMediarcRecordingLines' },
    ),
  requestFile: async (command) =>
    mutate({
      mutation: gql.requestMediarcFile,
      variables: { command },
      fetchPolicy: 'network-only',
    }),
  updateMatchState: async (command) =>
    mutate({ mutation: gql.updateMediarcMatchState, variables: { command } }),
  getAlbumHits: async (albumId) =>
    query(
      { query: gql.getMediarcAlbumHits, variables: { albumId } },
      { pickProp: 'getMediarcAlbumHits' },
    ),
  getRecordingHits: async (recordingId) =>
    query(
      { query: gql.getMediarcRecordingHits, variables: { recordingId } },
      { pickProp: 'getMediarcRecordingHits' },
    ),
  createNew: async (command) => mutate({ mutation: gql.createMediarcNew, variables: { command } }),
  getMediarcRecordingVersionTitles: async (request) =>
    query(
      { query: gql.getMediarcRecordingVersionTitles, variables: { request } },
      { pickProp: 'getMediarcRecordingVersionTitles' },
    ),
};
