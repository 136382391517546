<template>
  <span
    :class="{
      'text--bold': true,
      'text--green': value === 0,
      'text--warning': value > 0 && value < 15,
      'is-danger': value >= 15,
    }"
  >
    {{ value }}%
  </span>
</template>
<script>
export default {
  name: 'ErrorPercent',
  props: {
    value: {
      type: Number,
      required: false,
    },
  },
};
</script>
