<template>
  <div class="process-sidebar" :class="{ 'process-sidebar--visible': visible }">
    <div class="process-sidebar-contents">
      <div class="text--right close">
        <i class="fa fa-arrow-right" @click="close" />
      </div>
      <process-list
        @processAdded="processAdded"
        @processFinished="processFinished"
        @processError="processError"
      />
    </div>
  </div>
</template>

<script>
import ProcessList from './process-list';

export default {
  name: 'ProcessSidebar',
  components: {
    ProcessList,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    subscriptions() {
      return this.$store.process.subscriptions;
    },
  },
  methods: {
    addProcess(processId) {
      this.$store.dispatch('process/subscribe', processId);
    },
    removeProcess(processId) {
      this.$store.dispatch('process/unsubscribe', processId);
    },
    close() {
      this.$emit('onClose');
    },
    processFinished(prc) {
      this.$emit('processFinished', prc);
    },
    processAdded(prc) {
      this.$emit('processAdded', prc);
    },
    processError(err) {
      this.$emit('processError', err);
    },
  },
};
</script>

<style scoped>
.process-sidebar {
  position: absolute;
  background: white;
  width: 0;
  right: 0;
  transition: width 0.4s;
  overflow: hidden;
}
.process-sidebar--visible {
  width: 35rem;
}
.process-sidebar-contents {
  width: 35rem;
  padding: 1rem 1.5rem;
  border-left: 1px black solid;
  border-bottom: 1px black solid;
  min-height: 10rem;
}
.close {
  cursor: pointer;
  font-size: 1.2rem;
}
</style>
