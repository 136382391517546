import gql from 'graphql-tag';
import CommonGql from './commonGql';
import CommonPaymentGql from './commonPaymentGql';

const getAssociate = gql`
  query associate($idTerm: String!, $versionTerm: Int) {
    associateAggregate(id: $idTerm, version: $versionTerm) {
      id,
      version,
      type,
      person_info {
        social_security_number
        date_of_birth
        date_of_death
        first_name
        last_name
        middle_name
        protected_identity
      }
      organisation_info {
        org_no
        name
      },
      ${CommonGql.contactInfoWithContactPersons}
      ${CommonPaymentGql.paymentInfo}
      tax_info {
        tax_domicile
        tin
        vat_number
      }
      meta_info {
         tags
      }
      local_codes {
        code
        value
      }
      num_child_associations
      ${CommonGql.associates}
      system_state {
        payments_blocked
        payments_blocked_reason
      }
      documents {
        id
        type
        url
        created
      }
    }
  }
`;

const fetchChildAssociates = gql`
  query fetchChildAssociates($idTerm: String, $pageFrom: Int, $limit: Int) {
    fetchChildAssociates(id: $idTerm, pageFrom: $pageFrom, limit: $limit) {
      child_associations {
        id
        row_id
        name
        type
        access_policy
        start_date
        end_date
        share
      }
    }
  }
`;

const checkUniqueOrgNo = gql`
  query checkUniqueOrgNo($orgNo: String, $type: String, $streamId: String) {
    checkUniqueOrgNo(orgNo: $orgNo, type: $type, streamId: $streamId)
  }
`;

export default {
  getAssociate,
  fetchChildAssociates,
  checkUniqueOrgNo,
};
