import gql from 'graphql-tag';

const fetchMediarcRecordingLines = gql`
  query fetchMediarcRecordingLines($request: MediarcLinesRequest!) {
    fetchMediarcRecordingLines(request: $request) {
      total_count
      items {
        recording_id
        album_id
        sr_code
        main_artist
        genre
        duration_sec
        recording_date
        recorded_in_country
        title
        label
        composer
        writer
        arranger
        produced_in_country
        match_state
        match_id
        album
        notes
      }
    }
  }
`;

const fetchMediarcAlbumLines = gql`
  query fetchMediarcAlbumLines($request: MediarcLinesRequest!) {
    fetchMediarcAlbumLines(request: $request) {
      total_count
      items {
        album_id
        sr_code
        main_artist
        label
        catalog_number
        release_date
        match_state
        title
        match_id
        number_of_tracks
        notes
      }
    }
  }
`;

const getMediarcAlbumHits = gql`
  query getMediarcAlbumHits($albumId: Int!) {
    getMediarcAlbumHits(albumId: $albumId) {
      hits {
        id
        name
        main_artist
        catalog_number
        number_of_tracks
        label
      }
    }
  }
`;

const getMediarcRecordingHits = gql`
  query getMediarcRecordingHits($recordingId: Int!) {
    getMediarcRecordingHits(recordingId: $recordingId) {
      hits {
        id
        name
        main_artist
        label
        recording_date
        status
        samiLineupMember
      }
    }
  }
`;

const getMediarcRecordingVersionTitles = gql`
  query getMediarcRecordingVersionTitles($request: MediarcRecordingVersionTitlesRequest!) {
    getMediarcRecordingVersionTitles(request: $request) {
      total_count
      items {
        match_id
        recording_comment
        recording {
          title
          version_title
          version
        }
      }
    }
  }
`;

const requestMediarcFile = gql`
  mutation requestMediarcFile($command: MediarcFileRequest!) {
    requestMediarcFile(command: $command)
  }
`;

const updateMediarcMatchState = gql`
  mutation updateMediarcMatchState($command: MediarcUpdateMatchStateCommand!) {
    updateMediarcMatchState(command: $command)
  }
`;

const createMediarcNew = gql`
  mutation createMediarcNew($command: MediarcNewCommand!) {
    createMediarcNew(command: $command)
  }
`;

export default {
  fetchMediarcRecordingLines,
  fetchMediarcAlbumLines,
  requestMediarcFile,
  updateMediarcMatchState,
  getMediarcAlbumHits,
  getMediarcRecordingHits,
  createMediarcNew,
  getMediarcRecordingVersionTitles,
};
