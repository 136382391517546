<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Sources</h1>
      </div>
    </div>
    <form class="row wrapper" :data-vv-scope="headerScope">
      <div class="col s2">
        <select-year
          v-if="years.length > 0"
          label="Year"
          name="year-filter"
          :empty-select-option="false"
          :years="years"
          :value="yearModel"
        />
      </div>
      <div v-if="!loading" class="col s4">
        <div class="row flush--bottom">
          <div class="col s4">
            <label>{{ $filters.translate(`common.administrative_cost`) }}</label>
          </div>
        </div>
        <div class="row">
          <div v-if="editableGlobalFinancingRate === null" class="col s4">
            <div style="margin-top: var(--spacing)">{{ revenueYear.financing_rate }} %</div>
          </div>
          <div
            v-if="
              activeCategory === category.DOMESTIC &&
              editableGlobalFinancingRate === null &&
              !loading
            "
            class="col s2 no-padding auto-width"
          >
            <a @click="editableGlobalFinancingRate = revenueYear.financing_rate">
              <i class="far fa-edit" />
              Edit
            </a>
          </div>
          <div v-if="activeCategory === category.DOMESTIC && editableGlobalFinancingRate !== null">
            <div class="col s2">
              <validated-text-input
                v-model="editableGlobalFinancingRate"
                name="financingRate"
                placeholder="E.g. 27"
                rule="numeric|required"
                :show-label="false"
              />
            </div>
            <div class="col s10">
              <action-buttons
                @save="onSaveGlobalFinancingRate"
                @cancel="editableGlobalFinancingRate = null"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading" class="col s6">
        <div class="float-right">
          <br />
          <button class="btn float-right" @click="onFinalize">
            Finalize
            <i class="fas fa-arrow-right" />
          </button>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col s12">
        <div class="tabs">
          <div class="tabs__wrapper">
            <a
              :class="{ active: activeCategory === category.DOMESTIC }"
              @click="toggleCategory(category.DOMESTIC)"
            >
              Domestic Sources
            </a>
            <a
              :class="{ active: activeCategory === category.INTERNATIONAL }"
              @click="toggleCategory(category.INTERNATIONAL)"
            >
              International Sources
            </a>
          </div>
        </div>
      </div>
    </div>

    <form class="row card" :data-vv-scope="editSourceScope">
      <div class="row small-inner">
        <div class="col s12">
          <table>
            <thead>
              <th
                v-for="(column, index) in columns"
                :key="`column-${index}`"
                :class="[
                  { 'thin-column': column.thinColumn, 'disabled-head': !column.sortName },
                  column.styleClass,
                ]"
                @click="column.sortName ? sort(column) : ''"
              >
                <span>{{ column.name }}</span>
                <span v-if="column.sortName && !column.thinColumn">
                  <span v-if="column.sortParam" class="dropdown-button__arrow">
                    <i
                      v-if="column.order === 'desc'"
                      class="fas fa-caret-down"
                      :class="{ 'text--grey': !column.active }"
                    />
                    <i v-else class="fas fa-caret-up" :class="{ 'text--grey': !column.active }" />
                  </span>
                </span>
              </th>
            </thead>
            <tbody>
              <tr class="prevent-hover-bg">
                <td v-if="revenueSources.length === 0" colspan="5" class="text--center">
                  <span class="none">No data</span>
                </td>
              </tr>

              <!-- View  source -->
              <template v-for="source in revenueSources">
                <tr v-if="editId !== source.id" :key="`${source.id}-tr1`">
                  <td
                    class="thin-column dropdown-button__arrow"
                    @click="toggleExpanded(source.id, $event)"
                  >
                    <template v-if="source.sub_sources && source.sub_sources.length > 0">
                      <i :class="`fas fa-caret-${expandedId === source.id ? 'up' : 'down'}`" />
                    </template>
                  </td>
                  <td>
                    <span class="fs-14">{{ source.name }}</span>
                  </td>
                  <td>
                    <span class="fs-14">{{ source.source_society_name }}</span>
                  </td>
                  <td>
                    <span>{{ source.project_code_in }}</span>
                  </td>
                  <td>
                    <span>{{ source.project_code_out }}</span>
                  </td>
                  <td>
                    <span>{{ source.project_code_reserves }}</span>
                  </td>
                  <td>
                    <span v-if="source.financing_rate" class="text--bold">
                      {{ source.financing_rate }}%
                    </span>
                    <span v-else class="none">{{ revenueYear.financing_rate }}%</span>
                  </td>
                  <td>
                    <context-menu
                      :disabled="createId || editId"
                      :options="['Add sub source', 'Edit']"
                      @addSubSource="addSubSource(source.id)"
                      @edit="onEditSource(source.id)"
                    />
                  </td>
                </tr>

                <!-- Edit source -->
                <edit-distribution-source
                  v-if="editId === source.id"
                  :key="`${source.id}-tr2`"
                  :revenue-source="source"
                  :financing-rate="revenueYear.financing_rate"
                  :category="activeCategory"
                  @changed="onSave"
                  @cancel="onCancel"
                />

                <!-- View sub source -->
                <template v-for="(subSource, index) in source.sub_sources">
                  <tr
                    v-if="expandedId === source.id && editId !== subSourceId(source, subSource)"
                    :key="`${index}-${source.id}-tr1`"
                  >
                    <td />
                    <td class="add-padding-left">
                      <span>{{ subSource.name }}</span>
                    </td>
                    <td>
                      <span>{{ subSource.project_code_in }}</span>
                    </td>
                    <td>
                      <span>{{ subSource.project_code_out }}</span>
                    </td>
                    <td>
                      <span>{{ subSource.project_code_reserves }}</span>
                    </td>
                    <td />
                    <td>
                      <context-menu
                        :options="['Edit', 'Delete']"
                        @edit="editId = subSourceId(source, subSource)"
                        @delete="onDeleteSubSource(source, index)"
                      />
                    </td>
                  </tr>

                  <!-- Edit sub source -->
                  <edit-distribution-sub-source
                    v-if="editId === subSourceId(source, subSource)"
                    :key="`${index}-${source.id}-edit`"
                    :revenue-source="source"
                    :revenue-sub-source="subSource"
                    @changed="onSave"
                    @cancel="onCancel"
                  />
                </template>

                <!-- Create sub source -->
                <edit-distribution-sub-source
                  v-if="createId === source.id"
                  :key="`${index}-${source.id}-create`"
                  :revenue-source="source"
                  @changed="onSave"
                  @cancel="onCancel"
                />
              </template>
              <edit-distribution-source
                v-if="createId === '_source'"
                :category="activeCategory"
                :financing-rate="revenueYear.financing_rate"
                @changed="onSave"
                @cancel="onCancel"
              />
            </tbody>
          </table>
        </div>
      </div>
      <span class="row add-extra">
        <a
          :class="{ disabled: createId || editId }"
          tabindex="0"
          role="button"
          @click="createId = '_source'"
          @keyup.enter="createId = '_source'"
        >
          <i class="far fa-plus-square" />
          Create source
        </a>
      </span>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  createRevenueFinancingRateCommand,
  DistributionCategory,
} from '../../../domain/distributionDomain';
import ActionButtons from '../../ui/button/action-buttons';
import ContextMenu from '../../ui/context-menu';
import DistributionService from '../../../services/distributionService';
import EditDistributionSource from './edit-distribution-source';
import EditDistributionSubSource from './edit-distribution-sub-source';
import SelectYear from './../../ui/select/select-year';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'ViewDistributionSources',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    ActionButtons,
    ContextMenu,
    EditDistributionSource,
    EditDistributionSubSource,
    SelectYear,
    ValidatedTextInput,
  },
  data() {
    return {
      headerScope: 'distribution-sources-header',
      editSourceScope: 'edit-distribution-source',
      category: DistributionCategory,
      activeCategory: DistributionCategory.DOMESTIC,
      loading: false,
      revenueYears: [],
      revenueSources: [],
      selectedYear: null,
      yearModel: new Date().getFullYear().toString(),
      optionsId: null,
      createId: null,
      editId: null,
      expandedId: null,
      editableGlobalFinancingRate: null,
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Name',
          sortName: 'name',
          order: 'asc',
          active: false,
          sortParam: true,
          default: true,
        },
        {
          name: 'Society name',
          sortName: 'source_society_name',
          order: 'asc',
          active: false,
          sortParam: true,
          default: true,
        },
        {
          name: 'Project Code In',
        },
        {
          name: 'Project Code Out',
        },
        {
          name: 'Project Code Reserves',
        },
        {
          name: 'Administrative Cost',
          styleClass: 'amount-row',
        },
        {
          thinColumn: true,
        },
      ],
      sortTerms: {
        param: 'name',
        order: 'asc',
      },
    };
  },
  computed: {
    years() {
      const years = this.revenueYears.map((year) => year.year);
      years.sort();
      return years;
    },
    revenueYear() {
      const year = _.cloneDeep(this.revenueYears.find((r) => r.year === Number(this.yearModel)));
      if (this.activeCategory === DistributionCategory.INTERNATIONAL) {
        year.financing_rate = 0;
      }
      return year;
    },
  },
  async created() {
    await this.loadBaseData();
  },
  methods: {
    subSourceId(source, subSource) {
      return source.id + subSource.name;
    },
    addSubSource(sourceId) {
      this.createId = sourceId;
    },
    toggleOptions(sourceId) {
      if (this.optionsId === sourceId) {
        this.optionsId = null;
      } else {
        this.optionsId = sourceId;
      }
    },
    toggleCategory(category) {
      this.activeCategory = category;
      this.loadBaseData();
    },
    toggleExpanded(sourceId, e) {
      const row = e.target.closest('tr');
      if (!this.editId) {
        if (this.expandedId === sourceId) {
          this.expandedId = null;
          row.style.whiteSpace = 'normal';
        } else {
          this.expandedId = sourceId;
          row.style.whiteSpace = 'nowrap';
        }
      }
    },
    async onSaveGlobalFinancingRate() {
      await DistributionService.updateRevenueFinancingRate(
        createRevenueFinancingRateCommand(this.revenueYear.year, this.editableGlobalFinancingRate),
      );
      this.editableGlobalFinancingRate = null;
      this.loadBaseData();
    },
    onEditSource(id) {
      this.editId = id;
    },
    async onDeleteSubSource(source, subSourceIndex) {
      const sourceClone = _.cloneDeep(source);
      sourceClone.sub_sources.splice(subSourceIndex, 1);
      await DistributionService.updateRevenueSource(sourceClone);
      this.optionsId = null;
      this.loadBaseData();
    },
    onSave() {
      this.createId = null;
      this.editId = null;
      this.loadBaseData();
    },
    onCancel() {
      this.createId = null;
      this.editId = null;
      this.optionsId = null;
    },
    onFinalize() {
      // eslint-disable-next-line
      alert('begowk!');
    },
    async loadBaseData() {
      this.loading = true;
      const result = await DistributionService.getRevenueSources();
      this.revenueYears = result.years;
      this.revenueSources = _.orderBy(
        result.sources.filter((source) => source.category === this.activeCategory),
        this.sortTerms.param,
        this.sortTerms.order,
      );
      this.loading = false;
    },
    sort(column) {
      this.sortTerms.param = column.sortName;
      column.order = column.order === 'desc' ? 'asc' : 'desc';
      this.sortTerms.order = column.order;
      this.columns.forEach((c) => {
        c.active = false;
      });
      column.active = true;
      this.revenueSources = _.orderBy(
        this.revenueSources,
        this.sortTerms.param,
        this.sortTerms.order,
      );
    },
  },
};
</script>
