<template>
  <div class="row">
    <template v-if="loading">
      <div class="col s12">
        <spinner />
      </div>
    </template>

    <template v-else>
      <div class="col s12 l9 performer-wrapper">
        <h1>
          {{ firstName }} {{ lastName }}
          <span v-if="aggregate.deleted" class="text--italic">
            (deleted by {{ aggregate.deletedBy }})
          </span>
        </h1>
        <version
          :version="routeVersion()"
          :readonly="aggregate.deleted"
          entity-type="performer"
          view-name="performerInformation"
        />

        <StarViewEditPairs v-model="editableComponent" class="e2e-view-performer__information">
          <StarViewEditPair id="GeneralInformation">
            <template #view="{ activator }">
              <general-information @edit="activator" />
            </template>

            <edit-general-information @cancel="onCancel" @saved="onSaved" />
          </StarViewEditPair>

          <StarViewEditPair id="MemberInformation">
            <template #view="{ activator }">
              <member-information @edit="activator" />
            </template>

            <edit-member-information @cancel="onCancel" @saved="onSaved" />
          </StarViewEditPair>

          <StarViewEditPair id="MandateInformation">
            <template #view="{ activator }">
              <mandate-information
                :show-all-mandates="showAllMandates"
                @edit="activator"
                @reload="fetchData"
                @fetch="fetchMandates()"
              />
            </template>

            <create-mandate-information :edit-mode="true" @cancel="onCancel" @saved="onSaved" />
          </StarViewEditPair>

          <StarViewEditPair id="PaymentInformation">
            <template #view="{ activator }">
              <payment-information
                :private-account="privateAccount"
                :company-account="companyAccount"
                :foreign-account="foreignAccount"
                :has-payment-receiver="hasPaymentReceiver"
                @edit="activator"
              />
            </template>

            <create-payment-information
              :edit-mode="true"
              :company-account-visible="
                companyAccount.account_number && !companyAccount.account_number.isEmpty
              "
              :company-account-disabled="true"
              :private-account="privateAccount"
              :foreign-account="foreignAccount"
              :company-account="companyAccount"
              :has-payment-receiver="hasPaymentReceiver"
              @resetPaymentInfo="resetPaymentInfo"
              @addPrivateAccount="commitChange('addPrivateAccount', $event)"
              @addCompanyAccount="commitChange('addCompanyAccount', $event)"
              @addForeignAccount="commitChange('addForeignAccount', $event)"
              @cancel="onCancel"
              @save="updatePaymentInfo"
            />
          </StarViewEditPair>

          <StarViewEditPair id="Associates" single>
            <template #view="{ activator, editIsActive }">
              <associates
                :enable-row-options="routeVersion() === null"
                namespace="performer"
                relation-type="associates"
                :edit-mode="editIsActive"
                @edit="activator"
                @cancel="onCancel"
                @remove="removeAssociate"
                @save="saveAssociate"
              />
            </template>
          </StarViewEditPair>

          <StarViewEditPair id="ContactInformation">
            <template #view="{ activator }">
              <contact-information @edit="activator" />
            </template>

            <edit-contact-information @cancel="onCancel" @saved="onSaved" />
          </StarViewEditPair>

          <StarViewEditPair v-if="!dateOfDeath" id="TaxInformation">
            <template #view="{ activator }">
              <tax-information
                :tax-tin="taxTin"
                :tax-domicile="taxDomicile"
                :tax-vat-number="taxVatNumber"
                :statement-of-income="statementOfIncome"
                @edit="activator"
              />
            </template>

            <edit-tax-information
              :entity="currentPerformer"
              @cancel="onCancel"
              @save="onUpdateTaxInfo"
            />
          </StarViewEditPair>

          <StarViewEditPair id="DocumentsInformation">
            <template #view="{ activator }">
              <documents-information :documents="documents" @edit="activator" />
            </template>

            <edit-documents-information
              :documents="documents"
              :update-documents="updateDocuments"
              aggregate-type="performer"
              @cancel="onCancel"
              @saved="onUpdateDocument($event)"
            />
          </StarViewEditPair>
        </StarViewEditPairs>
      </div>
      <div class="col s12 l3">
        <right-column
          :editable-component.sync="editableComponent"
          :pre-selected-tab="routeVersion() ? 'activity' : 'general'"
          :performer-deleted="aggregate.deleted || routeVersion() !== null"
          @cancel="onCancel"
          @saved="onSaved"
          @deletePerformer="showDeleteModal"
        />
      </div>
    </template>

    <modal
      v-if="deleteModalShown"
      submit-label="Delete"
      :disable-submit="!canDelete.canDelete"
      :show-confirm-check-box="canDelete.canDelete"
      confirm-message="I confirm deletion of performer. This action is not reversible!"
      action-button-class="red"
      @close="deleteModalShown = false"
      @cancel="deleteModalShown = false"
      @save="onDeletePerformer()"
    >
      <h1 slot="header">Delete Performer?</h1>
      <div v-if="canDelete.reasons.length > 0" slot="body">
        <div v-for="reason in canDelete.reasons" :key="reason" class="row margin--bottom">
          <span class="is-danger float-left">
            <i class="fas fa-times-circle" />
            {{ reason }}
          </span>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import { commit, dispatch, getAggregate } from '../../../../store/modules/performer/utils';
import clone from '../../../../common/clone';
import AggregateMixin from '../../../../common/aggregateMixin';
import Associates from '../../../ui/associates/associates';
import ContactInformation from './contact-information';
import CreateMandateInformation from './../create/create-mandate-information';
import CreatePaymentInformation from '../../../ui/payment/create-payment-information';
import DocumentsInformation from '../../../ui/documents/documents-information';
import EditContactInformation from '../edit/edit-contact-information';
import EditDocumentsInformation from '../../../ui/documents/edit-documents-information';
import EditGeneralInformation from '../edit/edit-general-information';
import EditHelper from '../edit/editHelper';
import EditMemberInformation from '../edit/edit-member-information';
import EditTaxInformation from '../../../ui/tax-info/edit-tax-information';
import GeneralInformation from './general-information';
import MandateInformation from './../view/mandate-information';
import MemberInformation from './member-information';
import PaymentInformation from '../../../ui/payment/payment-information';
import PerformerService from '@/services/performerService';
import PnrService from '@/services/pnrService';
import RightColumn from '../right-column';
import Spinner from '../../../spinner';
import TaxInformation from '../../../ui/tax-info/tax-information';
import Version from '../../../ui/version';
import Modal from '../../../ui/dialog/modal';
import AuthenticationService from '../../../../services/authenticationService';

import { AssociateType } from '../../../../domain/associateDomain';

import { AGGREGATE_TYPES, createUpdateTaxInfoCommand } from '@/domain/common';

import { createUpdatePaymentInfoCommand } from '@/domain/commonPayment';

export default {
  name: 'ViewPerformerInformation',
  components: {
    Associates,
    ContactInformation,
    CreateMandateInformation,
    CreatePaymentInformation,
    DocumentsInformation,
    EditContactInformation,
    EditDocumentsInformation,
    EditGeneralInformation,
    EditMemberInformation,
    EditTaxInformation,
    GeneralInformation,
    MandateInformation,
    MemberInformation,
    Modal,
    PaymentInformation,
    RightColumn,
    Spinner,
    TaxInformation,
    Version,
  },
  mixins: [AggregateMixin],
  data() {
    return {
      loading: true,
      editableComponent: '',
      hasPaymentReceiver: false,
      statementOfIncome: false,
      showAllMandates: false,
      deleteModalShown: false,
      canDelete: { canDelete: false, reasons: [] },
    };
  },
  computed: {
    ...mapGetters('performer', [
      'aggregate',
      'associates',
      'dateOfDeath',
      'firstName',
      'foreignAccount',
      'id',
      'lastName',
      'paymentInfo',
      'privateAccount',
      'companyAccount',
      'taxDomicile',
      'taxTin',
      'taxVatNumber',
      'version',
      'documents',
    ]),
    currentPerformer() {
      return clone(getAggregate('performer'));
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapMutations('performer', ['updateDocuments', 'setMandates']),
    async fetchData() {
      this.loading = true;
      try {
        const aggregate = await PerformerService.getPerformer(
          this.routeAggregateId(),
          this.showAllMandates,
          this.routeVersion(),
        );
        dispatch('setPerformerAggregate', aggregate);
        this.savedInitialAggregate = _.cloneDeep(aggregate);
      } catch (error) {
        dispatch('createAggregate');
        error.title = 'Could not load performer';
        this.$addStarError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.routeAggregateId() },
        });
      } finally {
        this.hasPaymentReceiver = this.associates.some(
          (a) => a.share && this.isActive(a.startDate, a.endDate),
        );
        this.statementOfIncome = this.hasStatementOfIncome();
        this.loading = false;
      }
    },
    async fetchMandates() {
      this.showAllMandates = !this.showAllMandates;

      try {
        const mandates = await PerformerService.getMandates(
          this.routeAggregateId(),
          this.showAllMandates,
          this.routeVersion(),
        );

        this.setMandates(mandates);
        this.savedInitialAggregate.mandates = _.cloneDeep(mandates);
      } catch (error) {
        this.$addStarError(error);
      }
    },
    hasStatementOfIncome() {
      const associates = this.currentPerformer.associates;
      const associatesWithStatementOfIncome = associates.filter(
        (ass) =>
          (ass.type === AssociateType.HEIR || ass.type === AssociateType.COMPANY) &&
          this.isActive(ass.start_date, ass.end_date),
      );
      if (associatesWithStatementOfIncome.length === 0) {
        return true;
      }
      return false;
    },
    isActive(startDate, endDate) {
      return moment().isBetween(startDate, !endDate ? moment().endOf('day') : endDate);
    },
    resetPaymentInfo() {
      commit('resetPaymentInfo');
    },
    commitAccountChange(type, event) {
      this.resetPaymentInfo();
      this.commitChange(type, event);
    },
    commitChange(type, event) {
      commit(type, event);
    },
    onCancel() {
      this.editableComponent = '';
      dispatch('setPerformerAggregate', this.savedInitialAggregate);
    },
    async onSaved() {
      this.editableComponent = '';
      await this.fetchData();
    },
    async showDeleteModal() {
      this.loading = true;
      this.canDelete = await PerformerService.canDeletePerformer(this.id);
      this.loading = false;
      this.deleteModalShown = true;
    },
    async onDeletePerformer() {
      console.log('should delete the performer, oh yeah!');
      this.loading = true;
      const response = await PerformerService.deletePerformer(this.id);
      if (response && response.length === 1 && response[0].error) {
        const error = new Error();
        error.title = response[0].error.message;
        this.$addStarError(error);
      } else {
        this.$store.commit('performer/SET_DELETE_AGGREGATE', AuthenticationService.getUserName());
      }
      this.loading = false;
      this.deleteModalShown = false;
    },
    async onUpdateTaxInfo(performer) {
      const command = PnrService.prepareCommand(
        performer,
        createUpdateTaxInfoCommand(AGGREGATE_TYPES.PERFORMER),
        performer.tax_info,
      );

      await PnrService.postCommands({ errorTitle: 'Could not update tax information' }, [command]);
      await this.onSaved();
    },
    async onUpdateDocument(newDocuments) {
      try {
        await EditHelper.updateDocumentsInfo(newDocuments, EditHelper.getCurrentPerformer());
      } catch (error) {
        error.title = 'Could not update documents';
        throw error;
      }
      await this.onSaved();
    },
    async updatePaymentInfo() {
      const command = PnrService.prepareCommand(
        this.aggregate,
        createUpdatePaymentInfoCommand(AGGREGATE_TYPES.PERFORMER),
        this.paymentInfo,
      );

      await PnrService.postCommands({ errorTitle: 'Could not update payment information' }, [
        command,
      ]);
      await this.onSaved();
    },
    async saveAssociate(id) {
      try {
        const aggregate = _.cloneDeep(this.aggregate);
        await PerformerService.updatePerformerAssociates(
          aggregate.associates,
          this.id,
          id,
          EditHelper.getCurrentPerformer(),
        );
      } catch (error) {
        error.title = 'Error updating performer associates';
        throw error;
      } finally {
        this.loading = false;
      }

      await this.onSaved();
    },
    async removeAssociate(e) {
      const confirmed = await this.$starUserConfirmDialog(
        true,
        'Do you really want to remove this associate?',
        'Yes!',
      );
      this.$starUserConfirmDialog(false);
      if (!confirmed) {
        return;
      }
      try {
        await PerformerService.removePerformerAssociate(e.id, e.row_id, this.id);
        this.fetchData();
      } catch (error) {
        error.title = 'Error while removing associate.';
        this.$addStarError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.performer-wrapper {
  padding-right: 24px;
}
</style>
