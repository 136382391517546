<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2>Create and Add Performer</h2>
      </div>
    </div>

    <div v-if="performers.length > 0">
      <div v-for="(performer, index) in performers" :key="performer._localId">
        <create-new-performer
          :name="`${name}_${index}`"
          :performer="performer"
          :roles="roles"
          @input="updateNewPerformer(index, $event)"
          @remove="onRemovePerformer(index)"
        />
      </div>
    </div>

    <div class="row edit-link">
      <a
        :class="{ disabled: !lastHasEntry }"
        tabindex="0"
        role="button"
        @click="addNewPerformer"
        @keyup.enter="addNewPerformer"
      >
        <i class="far fa-plus-square" />
        Create and Add a New Performer
      </a>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import { createYearActive } from './../../../domain/mainArtistDomain';
import CreateNewPerformer from './create-new-performer';

export default {
  name: 'CreateNewPerformers',
  components: { CreateNewPerformer },
  inject: ['$validator'],
  props: {
    name: {
      type: String,
      default: 'create-new-performer',
    },
  },
  data() {
    return {
      performers: [],
    };
  },
  computed: {
    lastHasEntry() {
      return (
        this.performers.length === 0 ||
        (this.performers[this.performers.length - 1].first_name &&
          this.performers[this.performers.length - 1].last_name) ||
        this.performers[this.performers.length - 1].pseudo_names.length > 0
      );
    },
    roles() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
  },
  watch: {
    performers() {
      this.setLocalIds();
    },
  },
  mounted() {
    this.setLocalIds();
  },
  methods: {
    setLocalIds() {
      this.performers.forEach((performer) => {
        if (!performer._localId) {
          performer._localId = uuid.v4();
        }
      });
    },
    onRemovePerformer(idx) {
      this.performers.splice(idx, 1);
    },
    updateNewPerformer(index, valueUpdate) {
      const performer = this.performers[index];
      performer.id = valueUpdate.id;
      performer.first_name = valueUpdate.first_name;
      performer.last_name = valueUpdate.last_name;
      performer.pseudo_names = valueUpdate.pseudo_names;
      performer.country_of_residence = valueUpdate.country_of_residence;
      performer.instrument = valueUpdate.instrument;
      performer.role = valueUpdate.role;
      performer.active_period = valueUpdate.active_period;
      this.performers.splice(index, 1, performer);
      this.emitUpdate();
    },
    addNewPerformer() {
      this.performers.push({
        id: null,
        first_name: null,
        last_name: null,
        pseudo_names: [],
        country_of_residence: null,
        instrument: null,
        role: null,
        active_period: [createYearActive()],
      });
    },
    emitUpdate() {
      this.$emit('input', this.performers);
    },
  },
};
</script>
