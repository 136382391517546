/**
 * The Configuration Service provides access to application properties.
 */
import { config } from '../../environment';

function getEnvironmentConfig() {
  if (typeof window === 'undefined') {
    return config.dev;
  }

  const domain = window.location.hostname.split('.')[0];

  switch (domain) {
    case 'star':
      return config.prod;
    case 'localhost':
      return config.local;
    case 'test':
      return config.test;
    case 'dev':
      return config.dev;
    default:
      throw new Error(`Could not resolve config from domain name`);
  }
}

export const PROPS = {
  ENVIRONMENT: 0,
  PNR_URL: 1,
  GQL_URL: 2,
  AUTHENTICATION: 3,
  DISTRIBUTION_URL: 4,
  PAYMENT_URL: 5,
  NOTES_URL: 6,
  WORKLIST_URL: 7,
  DATA_BUCKET: 8,
  DATA_BUCKET_INTERNAL: 9,
  PROCESS_URL: 10,
  CRONJOB: 11,
};

export function getConfigValue(key) {
  const config = getEnvironmentConfig();

  switch (key) {
    case PROPS.ENVIRONMENT:
      return config.env;
    case PROPS.PNR_URL:
      return config.pnr.url;
    case PROPS.GQL_URL:
      return config.gql.url;
    case PROPS.DISTRIBUTION_URL:
      return config.distribution.url;
    case PROPS.PAYMENT_URL:
      return config.payment.url;
    case PROPS.NOTES_URL:
      return config.notes.url;
    case PROPS.WORKLIST_URL:
      return config.worklist.url;
    case PROPS.PROCESS_URL:
      return config.process.url;
    case PROPS.AUTHENTICATION:
      return config.cognito;
    case PROPS.DATA_BUCKET:
      return config.buckets.data;
    case PROPS.DATA_BUCKET_INTERNAL:
      return config.buckets.internal;
    case PROPS.CRONJOB:
      return config.cronjob;
    default:
      return null;
  }
}
