<template>
  <form :data-vv-scope="scope">
    <div class="row">
      <div class="col s12">
        <h2 class="float-left">Tax information</h2>
      </div>
    </div>

    <TaxInformationInputs
      :vat-number="vatNumber"
      :domicile="domicile"
      :tin="tin"
      :scope="scope"
      @update:vatNumber="updateTaxVatNumber"
      @update:domicile="updateTaxDomicile"
      @update:tin="updateTaxTin"
    ></TaxInformationInputs>
  </form>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { commit } from '@/store/modules/performer/utils';

import { createTaxInformation } from '@/domain/common';

import TaxInformationInputs from '@/components/ui/tax-info/tax-information-inputs';

export default {
  components: {
    TaxInformationInputs,
  },

  props: {
    scope: {
      type: String,
      default: 'tax_info',
    },
  },

  computed: {
    ...mapState('performer', {
      domicile: (state) => state.tax_info.tax_domicile,
      tin: (state) => state.tax_info.tin,
      vatNumber: (state) => state.tax_info.vat_number,
    }),
  },

  created() {
    commit('addTaxInformation', createTaxInformation(null, null));
  },

  methods: {
    ...mapMutations('performer', ['updateTaxDomicile', 'updateTaxTin', 'updateTaxVatNumber']),
  },
};
</script>
