<template>
  <div class="e2e-payment-information">
    <div class="row">
      <div class="col s6">
        <h2>Payment Information</h2>
      </div>
      <div v-if="!hasPaymentReceiver" class="col s6 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>

    <div v-if="paymentsBlocked" class="row text--red">
      <div class="col s12">
        <span class="fa fa-exclamation-circle" />
        <span>All payments are blocked</span>
      </div>
    </div>

    <div v-if="hasPaymentReceiver" class="row card">
      <div class="row flush--bottom">
        <div class="col s12">Payment transferred as specified in "Associates" table below.</div>
      </div>
    </div>
    <template v-else>
      <div v-if="hasCompanyAccount" class="row card">
        <div class="row">
          <label class="col s3">Account type</label>
          <div class="col s9">
            <span>{{ companyAccount.account_type }}</span>
          </div>
        </div>
        <div class="row flush--bottom">
          <label class="col s3">Account No.</label>
          <div class="col s9">
            <span>{{ $filters.formatAccountNumber(companyAccount.account_number) }}</span>
          </div>
        </div>
      </div>
      <div v-if="hasForeignAccount" class="row card">
        <div class="row">
          <label class="col s3">Account number</label>
          <div class="col s9">
            <span v-if="foreignAccount.account_number">{{ foreignAccount.account_number }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Account holder</label>
          <div class="col s9">
            <span v-if="foreignAccount.account_holder">{{ foreignAccount.account_holder }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">IBAN</label>
          <div class="col s9">
            <span v-if="foreignAccount.iban_number">
              {{ $filters.formatIBAN(foreignAccount.iban_number) }}
            </span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">BIC/SWIFT No.</label>
          <div class="col s9">
            <span>{{ foreignAccount.bic_swift_number }}</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Country</label>
          <div class="col s9">
            <span v-if="foreignAccount.routing_code">
              {{ $filters.translateRoutingCode(foreignAccount.routing_code) }}
            </span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Routing No.</label>
          <div class="col s9">
            <span v-if="foreignAccount.routing_number">{{ foreignAccount.routing_number }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row flush--bottom">
          <label class="col s3">Additional bank information</label>
          <div class="col s9">
            <span v-if="foreignAccount.meta_data" class="text--wrap">
              {{ foreignAccount.meta_data }}
            </span>
            <span v-else class="none">None</span>
          </div>
        </div>
      </div>
      <div v-if="hasPrivateAccount" class="row card">
        <div class="row">
          <label class="col s3">Bank</label>
          <div class="col s9">
            <span>{{ $t('payment_info.banks.' + privateAccount.bank) }}</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Clearing No.</label>
          <div class="col s9">
            <span>{{ $filters.formatClearingNumber(privateAccount.clearing_number) }}</span>
          </div>
        </div>
        <div class="row flush--bottom">
          <label class="col s3">Account No.</label>
          <div class="col s9">
            <span>{{ $filters.formatAccountNumber(privateAccount.account_number) }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="!hasCompanyAccount && !hasForeignAccount && !hasPrivateAccount && !hasPaymentReceiver"
      >
        <div class="row card">
          <label class="col s3">Payment</label>
          <div class="col s9">
            <span class="none">None</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  createPrivateAccount,
  createCompanyAccount,
  createForeignAccount,
} from '../../../domain/commonPayment';
import { isPristine } from '../../../common/typeUtils';

export default {
  name: 'PaymentInformation',
  props: {
    privateAccount: { type: Object, default: () => createPrivateAccount() },
    companyAccount: { type: Object, default: () => createCompanyAccount() },
    foreignAccount: { type: Object, default: () => createForeignAccount() },
    hasPaymentReceiver: { type: Boolean, default: false },
    paymentsBlocked: { type: Boolean, default: false },
  },
  computed: {
    hasCompanyAccount() {
      return !isPristine(this.companyAccount);
    },
    hasForeignAccount() {
      return !isPristine(this.foreignAccount);
    },
    hasPrivateAccount() {
      return !isPristine(this.privateAccount);
    },
  },
};
</script>
