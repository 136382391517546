/**
 * Helper service for creating main artists.
 */
import MainArtistService from '../../../services/mainArtistService';
import clone from '../../../common/clone';

function createMainArtistAndNavigateToView(basicInfo, performers, newPerformers) {
  return MainArtistService.createMainArtist(
    clone(basicInfo),
    clone(performers),
    clone(newPerformers),
  );
}

export default {
  createMainArtistAndNavigateToView,
};
