<template>
  <div>
    <div class="row wrapper">
      <div class="col s11">
        <h2>Upload Signed Membership Agreements</h2>
      </div>
    </div>
    <div class="row card">
      <file-upload-multi-field
        ref="uploadField"
        label="Upload agreements"
        name="uploadRefId"
        accept-file-type=".pdf"
        @updateFiles="updateFiles"
      />

      <div v-if="state === 'UPLOADING' || state === 'COMPLETE' || state === 'ERROR'">
        <span><strong>Upload progress</strong></span>
        <div v-for="(file, index) in files" :key="file.fid">[{{ index }}]: {{ file.p }} %</div>
      </div>

      <div v-if="state === 'COMPLETE'" class="mt row">
        <span class="text--green">
          <strong>
            <i class="fas fa-thumbs-up" />
            UPLOAD COMPLETED
          </strong>
        </span>
      </div>

      <div v-if="state === 'ERROR'" class="mt row">
        <span class="text--red">
          <strong>
            <i class="fas fa-thumbs-down" />
            UPLOAD FAILED
          </strong>
        </span>
      </div>

      <div class="row">
        <button
          v-if="state === 'COMPLETE'"
          class="btn secondary abs-top"
          type="submit"
          @click.prevent="reset"
        >
          Upload more files
        </button>

        <button
          v-if="state === 'ERROR'"
          class="btn secondary abs-top"
          type="submit"
          @click.prevent="uploadFiles"
        >
          <span>Try again</span>
        </button>

        <button
          v-if="state === 'ERROR' || state === 'ADD_FILES'"
          :disabled="!files || !files.length > 0"
          class="btn secondary abs-top"
          type="submit"
          @click.prevent="reset"
        >
          <span>Reset</span>
        </button>
      </div>
    </div>
    <div class="row">
      <button
        :disabled="state !== 'ADD_FILES' || files.length === 0"
        class="btn secondary abs-top float-right"
        type="submit"
        @click.prevent="uploadFiles"
      >
        <i v-if="state === 'UPLOADING'" class="fas fa-sync-alt spin" />
        Upload agreements
      </button>
    </div>
  </div>
</template>

<script>
import AuthenticationService from '../../services/authenticationService';
import RemoteFileService from '../../services/remoteFileService';
import FileUploadMultiField from '../ui/file/file-upload-multi-field';

async function uploadFile(fileHandle, progressUpdatedFunc) {
  const progressFunc = (progressEvent) =>
    progressUpdatedFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  const options = {
    metadata: [
      {
        key: 'user',
        value: String(AuthenticationService.getUserName()),
      },
    ],
    progressFunc,
  };
  const fileDesc = {
    filename: `membership-agreement/inbox/${fileHandle.name}`,
    fileSuffix: `${fileHandle.name.split('.').pop()}`,
    localFile: fileHandle,
  };
  await RemoteFileService.uploadToDocumentRepository(fileDesc, options);
}

export default {
  name: 'UploadMembershipAgreement',
  components: {
    FileUploadMultiField,
  },
  data() {
    return {
      error: false,
      state: 'ADD_FILES',
      showProgress: false,
      files: [],
    };
  },
  watch: {
    files() {
      this.state = 'ADD_FILES';
    },
  },
  methods: {
    updateFiles(files) {
      this.files = files;
    },
    reset() {
      this.$refs.uploadField.reset();
      this.state = 'ADD_FILES';
    },
    async uploadFiles() {
      this.state = 'UPLOADING';
      this.showProgress = true;
      this.error = false;
      try {
        const promises = [];
        this.files.forEach((file) => {
          promises.push(
            uploadFile(file, (progressInPercent) => {
              this.$set(file, 'p', progressInPercent);
              this.$forceUpdate();
            }),
          );
        });
        await Promise.all(promises);
        this.state = 'COMPLETE';
      } catch (error) {
        this.$addStarError(error);
        this.state = 'ERROR';
      }
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.mt {
  margin-top: 10px;
}

.spin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-direction: normal;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
