import Vue from 'vue';
// Local storage
import VueLocalStorage from 'vue-localstorage';
// Internationalization
import VueI18n from 'vue-i18n';
// GUI scolling
import VueScrollTo from 'vue-scrollto';

import store from '@/store';
import router from '@/router';

/* eslint-disable */
import { filters } from './filters';
import * as Directives from './directives';
/* eslint-enable */

import AppComp from './app';

import messages from './assets/i18n/messages';

import StarNotificationsGlobalMixin from './components/errors/star-notifications-global-mixin';

import Apollo from '@/plugins/apollo';
import Axios from '@/plugins/axios';
import VeeValidate from '@/plugins/vee-validate';

import DataTable from '@/components/ui/table/DataTable.vue';

import ViewEditPair from '@/components/view-edit-pair.vue';
import ViewEditPairs from '@/components/view-edit-pairs.vue';
import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import FloatingVue from 'floating-vue';

Vue.use(VueLocalStorage);
Vue.use(VueI18n);
Vue.use(VueScrollTo);

Vue.use(Apollo);
Vue.use(Axios);
Vue.use(VeeValidate);
Vue.use(FloatingVue);

Vue.component('StarDataTable', DataTable);

Vue.component('StarViewEditPair', ViewEditPair);
Vue.component('StarViewEditPairs', ViewEditPairs);

Vue.mixin(StarNotificationsGlobalMixin);

const i18n = new VueI18n({
  locale: 'en',
  messages,
});

Vue.$i18n = i18n;

Vue.config.devtools = true;

Vue.prototype.$filters = filters;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(AppComp),
}).$mount('#app');

export default {
  router,
};
