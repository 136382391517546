<template>
  <div>
    <h1 class="row">Deceased Performer Reports</h1>

    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>

    <div class="row">
      <div class="card">
        <button class="btn" :disabled="generating" @click="onGenerateReport">
          Generate new report
        </button>
        <table class="row">
          <thead>
            <tr>
              <th>Name</th>
              <th>Report</th>
            </tr>
          </thead>

          <tbody>
            <template v-for="report in reports">
              <tr :key="report.s3_key">
                <td>{{ report.name }}</td>
                <td>
                  <a :href="report.url">
                    <i class="far fa-file-alt"></i>
                    Download
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import { query } from '../../services/apolloRequest';
import gql from '../../domain/deceasedPerformerReportGql';

import DataTableMixin from '../distribution/data-table-mixin';

import Spinner from '../spinner';

export default {
  components: {
    Spinner,
  },

  mixins: [DataTableMixin],

  data: () => ({
    reports: [],
    generating: false,
    processId: null,
  }),

  computed: {
    process() {
      return this.$store.getters['process/process'](this.processId) || {};
    },
  },

  methods: {
    async onGenerateReport() {
      this.generating = true;
      this.processId = uuid.v4();
      this.$store.dispatch('process/addProcess', {
        name: 'Generating deceased performers report',
        id: this.processId,
      });
      try {
        await query({
          query: gql.generateDeceasedPerformerReport,
          variables: { processId: this.processId },
        });
      } catch (e) {
        e.title = 'Failed to generate deceased performers report';
        this.$addStarError(e);
        this.$store.dispatch('process/removeProcess', this.processId);
      } finally {
        this.generating = false;
      }
    },

    async reloadBaseData() {
      this.loading = true;
      try {
        this.reports = await query(
          { query: gql.deceasedPerformerReports },
          { pickProp: 'deceasedPerformerReports' },
        );
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
}

button {
  align-self: end;
}

table {
  tr td:first-child {
    width: 1%;
    white-space: nowrap;
  }
}
</style>
