import { mutate, query } from './apolloRequest';
import gql from '../domain/endOfYearStatementGql';

export default {
  getEndOfYearStatements: async (ledgerId) =>
    query(
      { query: gql.getEndOfYearStatements, variables: { ledgerId } },
      { pickProp: 'getEndOfYearStatements' },
    ),
  createEndOfYearStatement: async (year) =>
    mutate(
      { mutation: gql.createEndOfYearStatement, variables: { year }, fetchPolicy: 'network-only' },
      { pickProp: 'createEndOfYearStatement' },
    ),
};
