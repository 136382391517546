import { mutate, query } from './apolloRequest';
import gql from '../domain/reserveGql';
import AuthenticationService from './authenticationService';

export default {
  getReserveReleaseHeaders: async (request) =>
    query(
      { query: gql.getReserveReleaseHeaders, variables: { request } },
      { pickProp: 'getReserveReleaseHeaders' },
    ),
  getReserveRelease: async (id) =>
    query({ query: gql.getReserveRelease, variables: { id } }, { pickProp: 'getReserveRelease' }),
  createReserveRelease: async (releaseYear) =>
    mutate(
      {
        mutation: gql.createReserveRelease,
        variables: {
          command: { release_year: releaseYear, user: AuthenticationService.getUserName() },
        },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'createReserveRelease' },
    ),
  calculateReserveRelease: async (id) =>
    mutate(
      {
        mutation: gql.calculateReserveRelease,
        variables: { command: { id, user: AuthenticationService.getUserName() } },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'calculateReserveRelease' },
    ),
  confirmReserveRelease: async (id, processId) =>
    mutate(
      {
        mutation: gql.confirmReserveRelease,
        variables: {
          command: { id: id.toString(), processId, user: AuthenticationService.getUserName() },
        },
        fetchPolicy: 'network-only',
      },
      { pickProp: 'confirmReserveRelease' },
    ),
  deleteReserveRelease: async (id) =>
    mutate(
      {
        mutation: gql.deleteReserveRelease,
        variables: { id: id.toString() },
        fetchPolicy: 'network-only',
      },
      {},
    ),
};
