<template>
  <div>
    <div v-if="routeName === 'homeViewDashboard'" class="widget-wrapper">
      <Widget
        v-for="(widget, index) in iconWidgets"
        :key="`${index}-icon`"
        class="widget-container"
        :class="widget.size"
        :widget="widget"
      />
    </div>

    <div v-if="routeName === 'homeViewDashboard'" class="widget-wrapper">
      <Widget
        v-for="(widget, index) in workWidgets"
        :key="`${index}-work`"
        class="widget-container"
        :class="widget.size"
        :widget="widget"
      />
    </div>

    <div v-if="routeName === 'homeViewStatistics'" class="widget-wrapper">
      <Widget
        v-for="(widget, index) in statWidgets"
        :key="`${index}-stat`"
        class="widget-container"
        :class="widget.size"
        :widget="widget"
      />
    </div>
  </div>
</template>

<script>
import Widget from './widgets/widget.vue';
import DashboardService from '../../../services/dashboardService';

const prevYear = new Date().getFullYear() - 1;
const timestamp = Date.now();

export default {
  name: 'ViewDashboard',
  components: { Widget },
  data() {
    return {
      iconWidgets: [
        {
          component: 'IconWidget',
          loading: true,
          size: 'small',
          title: 'Member Applications',
          icon: {
            color: 'red',
            entity: 'performer',
          },
          extras: {
            type: 'MEMBER_APPLICATION',
            title: 'Member applications',
            url: `/search/performers?i=${timestamp}&tags=MEMBER_APPLICATION&sortParam=first_name.keyword&sortOrder=asc`,
          },
          request: {
            type: 'PERFORMER_TAGS',
          },
          sharePayloadWithIndex: 1,
        },
        {
          component: 'IconWidget',
          loading: true,
          size: 'small',
          title: 'Regional Applications',
          icon: {
            color: 'red',
            entity: 'performer',
          },
          extras: {
            type: 'REGIONAL_APPLICATION',
            title: 'Regional applications',
            url: '/membership/applications',
          },
        },
        {
          component: 'IconWidget',
          loading: true,
          size: 'small',
          title: 'Sami List',
          icon: {
            color: 'gold',
            entity: 'album',
          },
          extras: {
            type: 'Sami-list',
            title: 'Sami list',
            url: `/search/albums?i=${timestamp}&tags=Sami-list`,
          },
          request: {
            type: 'ALBUM_TAGS',
          },
        },
        {
          component: 'IconWidget',
          loading: true,
          size: 'small',
          title: 'Missing Recording List',
          icon: {
            color: 'blue',
            entity: 'recording',
          },
          extras: {
            type: 'Missing Recording List',
            title: 'Missing recording list',
            url: `/search/recordings?i=${timestamp}&tags=Missing%20Recording%20List`,
          },
          request: {
            type: 'RECORDING_TAGS',
          },
          sharePayloadWithIndex: 4,
        },
        {
          component: 'IconWidget',
          loading: true,
          size: 'small',
          title: 'MyPages Updates',
          icon: {
            color: 'blue',
            entity: 'recording',
          },
          extras: {
            type: 'MyPages',
            title: 'MyPages updates',
            url: `/search/recordings?i=${timestamp}&tags=MyPages`,
          },
        },
      ],
      workWidgets: [
        {
          component: 'NewDistributionsWidget',
          loading: true,
          color: 'green',
          hideEmpty: true,
          request: {
            type: 'NEW_DISTRIBUTIONS',
          },
        },
        {
          component: 'NewRevenuesWidget',
          loading: true,
          color: 'turquoise',
          hideEmpty: true,
          extras: {
            title: 'Domestic Revenues',
            url: '/distribution/revenues/domestic?state=NEW',
          },
          request: {
            type: 'NEW_REVENUES',
            payload: { category: 'domestic' },
          },
        },
        {
          component: 'NewRevenuesWidget',
          loading: true,
          color: 'gold',
          hideEmpty: true,
          extras: {
            title: 'International Revenues',
            url: '/distribution/revenues/international?state=NEW',
          },
          request: {
            type: 'NEW_REVENUES',
            payload: { category: 'international' },
          },
        },
        {
          component: 'BlockedRedistributionsWidget',
          loading: true,
          color: 'blue',
          hideEmpty: true,
          request: {
            type: 'BLOCKED_REDISTRIBUTIONS',
          },
        },
        {
          component: 'VrdbToCheckWidget',
          loading: true,
          color: 'green',
          request: {
            type: 'VRDB_TO_CHECK',
          },
        },
        {
          component: 'SdegClaimingDeadlinesWidget',
          loading: true,
          color: 'turquoise',
          hideEmpty: true,
          request: {
            type: 'SDEG_CLAIMING_DEADLINES',
          },
        },
        {
          component: 'NewChannelsWidget',
          loading: true,
          color: 'yellow',
          hideEmpty: true,
          request: {
            type: 'NEW_CHANNELS',
          },
        },
      ],
      statWidgets: [
        {
          component: 'MembersWidget',
          loading: true,
          size: 'small',
          title: 'Member Statistics',
          height: 154,
          request: {
            type: 'MEMBERS',
          },
        },
        {
          component: 'AirtimeAllTimeWidget',
          loading: true,
          size: 'large',
          title: `Top Recordings ${prevYear}`,
          height: 418,
          request: {
            type: 'AIRTIME_ALL_TIME',
            payload: { year: prevYear },
          },
        },
      ],
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.loadWidgets();
      }
    },
  },
  created() {
    this.loadWidgets();
  },
  methods: {
    loadWidgets() {
      if (this.routeName === 'homeViewDashboard') {
        this.iconWidgets.forEach(this.fetchData);
        this.workWidgets.forEach(this.fetchData);
      }

      if (this.routeName === 'homeViewStatistics') {
        this.statWidgets.forEach(this.fetchData);
      }
    },
    async fetchData(widget, index, array) {
      if (!widget.request) {
        widget.loading = false;
        return;
      }

      try {
        const { payload } = await DashboardService.dashboard(widget.request);
        widget.payload = payload;

        if (widget.sharePayloadWithIndex > index) {
          array[widget.sharePayloadWithIndex] = {
            ...array[widget.sharePayloadWithIndex],
            payload,
          };
          this.$forceUpdate();
        }
      } catch (err) {
        widget.error = true;
      } finally {
        widget.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  .widget-container {
    width: auto !important;
    flex: 1 1 33%;

    &.small {
      flex: 1 1 20%;
    }

    &.large {
      flex: 1 1 50%;
    }
  }
}
</style>
