import _ from 'lodash';

/**
 * Checks if the string does not have a value.
 * @param str the string to check
 * @returns {boolean} if blank
 */
export function isBlankStr(str) {
  return str == null || str.length === 0;
}

/**
 * Checks if the string has a value.
 * @param str the string to check
 * @returns {boolean} if has value
 */
export function isStr(str) {
  return str != null && str.length > 0;
}

/**
 * Returns the string if set and otherwise null.
 * @param str the string to mangle
 * @returns the string or null
 */
export function strOrNull(str) {
  return isStr(str) ? str : null;
}

/**
 * Converts the string to camel case
 * @param str
 * @param makeFirstUpper if the first letter should be upper case
 * @returns {*}
 */
export function camelCase(str, makeFirstUpper = false) {
  const camel = _.camelCase(str);
  return makeFirstUpper ? _.upperFirst(camel) : camel;
}

/**
 * Capitalises the string.
 * @param str
 * @returns {*}
 */
export function capitalise(str) {
  if (isStr(str)) {
    return _.upperFirst(str);
  }
  return str;
}

/**
 * Checks if all property keys on the objects are empty.
 * @param obj the object to checke
 * @param ignoreProperties properties that shall be ignored,
 *        i.e. the object is considered empty event when these are set.
 * @returns {boolean} true if empty otherwise false
 */
export function isPristine(obj, ignoreProperties = []) {
  if (!obj) {
    return true;
  }
  if (typeof obj !== 'object') {
    return false;
  }
  return Object.keys(obj).every((x) =>
    !ignoreProperties.includes(x)
      ? obj[x] === undefined || obj[x] === null || obj[x] === '' || obj[x].length === 0
      : true,
  );
}
