<template>
  <div>
    <div class="row">
      <label v-if="showLabel" class="col s3">Local codes</label>
      <div class="col s9">
        <span v-if="localCodesPreview.length === 0" class="none">None</span>

        <div v-for="(code, index) in localCodesPreview" :key="index" :style="injectStyle">
          {{ code.name }} - {{ code.value }}
        </div>
        <div v-if="allLocalCodesExpanded" :style="injectStyle">
          <div v-for="(code, index) in localCodesExtended" :key="index">
            {{ code.name }} - {{ code.value }}
          </div>
        </div>
      </div>
    </div>
    <div class="row show-all">
      <div class="col s3"></div>
      <div class="col s9">
        <a
          v-if="localCodesExtended.length > 0"
          @click="allLocalCodesExpanded = !allLocalCodesExpanded"
        >
          <span v-if="allLocalCodesExpanded">
            <i class="fas fa-caret-up"></i>
            Show less
          </span>
          <span v-else>
            <i class="fas fa-caret-down"></i>
            Show all local codes
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    localCodes: { type: Array, default: () => [] },
    injectStyle: { type: String, default: '' },
    showLabel: { type: Boolean, default: true },
  },
  data() {
    return {
      allLocalCodesExpanded: false,
    };
  },
  computed: {
    localCodesPreview() {
      return this.sortedDecoratedLocalCodes.slice(0, 10);
    },
    localCodesExtended() {
      return this.sortedDecoratedLocalCodes.slice(10);
    },
    localCodeTypes() {
      return this.$store.state.appdata.localCodeTypes;
    },

    decoratedLocalCodes() {
      const localCodeTypesMap = this.localCodeTypes.reduce((acc, type) => {
        acc[type.code] = type;
        return acc;
      }, {});

      return this.localCodes.map((code) => {
        return {
          ...code,
          name: localCodeTypesMap[code.code]?.name || code.code,
        };
      });
    },

    sortedDecoratedLocalCodes() {
      const orderMap = {
        'Public ID': 1,
        SR: 2,
      };

      return [...this.decoratedLocalCodes].sort(({ name: aName }, { name: bName }) => {
        const aOrder = orderMap[aName] || 100;
        const bOrder = orderMap[bName] || 100;

        if (aOrder < bOrder) {
          return -1;
        }

        if (aOrder > bOrder) {
          return 1;
        }

        if (aName < bName) {
          return -1;
        }

        if (aName > bName) {
          return 1;
        }

        return 0;
      });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.show-all {
  margin-top: -10px;
}
</style>
