<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Protection Changes</h1>
      </div>
    </div>

    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>

    <div v-else class="row card">
      <div class="row">
        <div class="col s12">
          <table>
            <thead>
              <th v-for="column in columns" :key="column.id" :class="[column.styleClass]">
                {{ column.name }}
              </th>
            </thead>
            <tbody>
              <tr v-for="recording in recordings" :key="recording.id">
                <td>
                  <span v-if="recording.title">
                    <router-link :to="'/recordings/' + recording.id">
                      {{ recording.title }}
                    </router-link>
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <span v-if="recording.version_title">
                    {{ recording.version_title }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <span v-if="recording.main_artist">
                    {{ recording.main_artist }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <span v-if="recording.vrdb2_id">
                    {{ recording.vrdb2_id }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <span v-if="recording.playing_time_sec">
                    {{ $filters.toMinutes(recording.playing_time_sec) }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>
                  <span
                    v-if="recording.prev_produced_in_country !== recording.produced_in_country"
                    class="margin--right"
                    :class="{
                      none: !recording.prev_produced_in_country,
                      'text--line-through':
                        recording.prev_produced_in_country !== recording.produced_in_country,
                    }"
                  >
                    {{ $filters.formatCountry(recording.prev_produced_in_country || 'None') }}
                  </span>
                  <span
                    :class="{
                      none: !recording.produced_in_country,
                    }"
                  >
                    {{ $filters.formatCountry(recording.produced_in_country || 'None') }}
                  </span>
                </td>
                <td>
                  <span
                    v-if="recording.prev_recording_country !== recording.recording_country"
                    class="margin--right"
                    :class="{
                      none: !recording.prev_recording_country,
                      'text--line-through':
                        recording.prev_recording_country !== recording.recording_country,
                    }"
                  >
                    {{ $filters.formatCountry(recording.prev_recording_country || 'None') }}
                  </span>
                  <span
                    :class="{
                      none: !recording.recording_country,
                    }"
                  >
                    {{ $filters.formatCountry(recording.recording_country || 'None') }}
                  </span>
                </td>
                <td>
                  {{ $filters.formatDate(recording.date_updated) }}
                </td>
                <td>
                  <context-menu
                    :options="['Remove from list']"
                    @removeFromList="deleteRecording(recording.recording_id)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <pagination
        :loading="loading"
        :number-of-pages="searchContext.numberOfPages"
        :selected-page="pagination.page"
        :number-of-hits="searchContext.numberOfHits"
        :hits-per-page="pagination.hitsPerPage"
        @selectPage="selectPage"
        @updateHitsPerPage="updateHitsPerPage"
      />
    </div>
  </div>
</template>

<script>
import DistributionService from '../../../services/distributionService';
import Spinner from '../../spinner';
import ContextMenu from '../../ui/context-menu';
import Pagination from '../../pagination';
import DataTableMixin from '../data-table-mixin';

export default {
  name: 'ViewProtectionChanges',
  components: {
    Spinner,
    ContextMenu,
    Pagination,
  },
  mixins: [DataTableMixin],
  data() {
    return {
      recordings: [],
      loading: false,
      columns: [
        { name: 'Recording' },
        { name: 'Version' },
        { name: 'Main Artist' },
        { name: 'VRDB ID' },
        { name: 'Total Usage' },
        { name: 'Produced in Country' },
        { name: 'Recorded in Country' },
        { name: 'Changed' },
      ],
    };
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        if (!this.loading) {
          this.reloadBaseData();
        }
      },
    },
  },
  created() {
    this.enableSort = false;
  },
  methods: {
    async reloadBaseData() {
      this.loading = true;
      try {
        const query = {
          page: this.pagination.page,
          limit: this.pagination.hitsPerPage,
        };
        const result = await DistributionService.getProtectionChanges(query);

        this.recordings = result.items;

        this.searchContext.numberOfHits = result.total_count;
        this.searchContext.numberOfPages = Math.ceil(
          result.total_count / this.pagination.hitsPerPage,
        );
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async deleteRecording(recordingId) {
      await DistributionService.deleteProtectionChange(recordingId);
      this.recordings = this.recordings.filter((rec) => rec.recording_id !== recordingId);
    },
  },
};
</script>
