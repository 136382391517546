<template>
  <div>
    <div>
      <div class="row">
        <h3>Summary</h3>
      </div>
      <div class="card">
        <ComponentSpinner v-if="loadingStats" />
        <table v-else class="stats-table">
          <thead>
            <th>Date Range</th>
            <th>Performance Year Range</th>
            <th>Remuneration Count</th>
            <th class="text--right">Total Net</th>
          </thead>
          <tbody>
            <tr>
              <td>
                <span v-if="stats.fromDate">
                  {{ $filters.dateFormat($filters.dateParse(stats.fromDate), 'YYYY-MM-DD') }}
                </span>
                -
                <span v-if="stats.toDate">
                  {{ $filters.dateFormat($filters.dateParse(stats.toDate), 'YYYY-MM-DD') }}
                </span>
              </td>
              <td>{{ stats.fromYear }} - {{ stats.toYear }}</td>
              <td>{{ $filters.formatNumber(stats.count) }}</td>
              <td class="text--right">
                <span
                  :class="{
                    negative: Math.sign(Number(stats.totalNet)) === -1,
                    positive: Math.sign(Number(stats.totalNet)) === +1,
                  }"
                >
                  {{ $filters.formatAmountCent(stats.totalNet, 'SEK') }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <h3>Result</h3>
      </div>
      <div class="card">
        <div class="row">
          <ComponentSpinner v-if="loadingList" />
          <div v-else>
            <form class="filters" @submit.prevent="onSubmitFilters">
              <div class="filter">
                <label>From date</label>
                <SelectDate v-model="filter.createdFrom" name="from-date" />
              </div>
              <div class="filter">
                <label>To date</label>
                <SelectDate v-model="filter.createdTo" name="to-date" />
              </div>
              <div class="filter">
                <SelectInput
                  v-model="filter.performanceYear"
                  label="Performance Year"
                  name="year"
                  :items="years"
                />
              </div>
              <div class="filter">
                <SelectInput
                  v-model.number="filter.sourceName"
                  label="Source"
                  name="source"
                  :items="sourceList"
                />
              </div>
              <div class="filter">
                <label>
                  <span>Title</span>
                  <input v-model="filter.titleSearch" type="text" />
                </label>
              </div>
              <div class="filter">
                <label>
                  <span>Main Artist</span>
                  <input v-model="filter.mainArtistSearch" type="text" />
                </label>
              </div>
              <div class="filter-btn-container">
                <button type="submit" class="btn filter-btn">Filter</button>
                <button type="button" class="btn tertiary filter-btn" @click.prevent="resetFilter">
                  Reset
                </button>
              </div>
            </form>
            <table class="modal__headers">
              <SortableHead
                :columns="columns"
                :bulk-enabled="false"
                :more-options="false"
                :default-sort-column-index="0"
                :default-sort-should-override="true"
                @sort="clickSort"
              />
              <tbody>
                <tr v-for="row in data" :key="row.id">
                  <td>
                    <a @click.prevent="setDateFilter(row.createdAt)">
                      {{ $filters.dateFormat($filters.dateParse(row.createdAt), 'YYYY-MM-DD') }}
                    </a>
                  </td>
                  <td>{{ row.programTitle || row.mainArtist }}</td>
                  <td>
                    <router-link
                      v-if="row.recordingId"
                      :to="`/recording/${row.recordingId}/information`"
                    >
                      <span>{{ row.title }}</span>
                      <span v-if="row.versionTitle" class="version-title">
                        ({{ row.versionTitle }})
                      </span>
                    </router-link>
                    <span v-else>
                      <span>{{ row.title }}</span>
                      <span v-if="row.versionTitle" class="version-title">
                        ({{ row.versionTitle }})
                      </span>
                    </span>
                  </td>
                  <td>{{ row.sourceName }}</td>
                  <td>{{ row.areaName }}</td>
                  <td>{{ $filters.translate(`mandate.right.${row.right.toLowerCase()}`) }}</td>
                  <td>{{ row.year }}</td>
                  <td>
                    <span v-if="row.distributionType">
                      {{ $filters.translate(`ledger.distribution_type.${row.distributionType}`) }}
                    </span>
                  </td>
                  <td class="text--right">
                    <span
                      :class="{
                        negative: Math.sign(Number(row.net)) === -1,
                        positive: Math.sign(Number(row.net)) === +1,
                      }"
                    >
                      {{ $filters.formatAmountCent(row.net, 'SEK') }}
                    </span>
                  </td>
                </tr>
                <tr v-if="data.length === 0">
                  <td :colspan="columns.length" class="text--center">(Nothing to show)</td>
                </tr>
              </tbody>
            </table>
            <Pagination
              topic="track-breakdown"
              :number-of-pages="numberOfPages"
              :selected-page="selectedPage"
              :number-of-hits="stats.count"
              :hits-per-page="hitsPerPage"
              :show-hits-per-page="true"
              :loading="loadingList"
              @selectPage="onSelectPage"
              @updateHitsPerPage="onUpdateHitsPerPage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortableHead from '../../../../components/ui/table/sortable-head';
import SelectInput from '../../../../components/ui/select/select-input';
import SelectDate from '../../../../components/ui/select/select-date';
import Pagination from '../../../../components/pagination';
import ComponentSpinner from '../../../../components/component-spinner';
import { query } from '../../../../services/apolloRequest';
import gql from 'graphql-tag';

export default {
  name: 'TrackBreakdown',
  components: {
    SortableHead,
    ComponentSpinner,
    SelectInput,
    SelectDate,
    Pagination,
  },
  data() {
    return {
      filter: {
        createdFrom: null,
        createdTo: null,
        performanceYear: null,
        sourceName: null,
        titleSearch: null,
        mainArtistSearch: null,
      },
      sources: [],
      comittedFilters: {},
      columns: [
        {
          name: 'Date',
          ascending: false,
          sortParam: 'created_at',
          active: true,
        },
        {
          name: 'Main Artist/Program Title',
          ascending: true,
          sortParam: 'main_artist',
          active: false,
        },
        {
          name: 'Title',
          ascending: true,
          sortParam: 'title',
          active: false,
        },
        {
          name: 'Source',
          ascending: true,
          sortParam: 'source_name',
          active: false,
        },
        {
          name: 'Area',
          active: false,
        },
        {
          name: 'Right',
          active: false,
        },
        {
          name: 'Year',
          ascending: true,
          sortParam: 'year',
          active: false,
        },
        {
          name: 'Distribution Type',
          active: false,
        },
        {
          name: 'Net',
          ascending: true,
          sortParam: 'net',
          active: false,
          styleClass: 'text--right',
        },
      ],
      loadingList: false,
      loadingStats: false,
      data: [],
      stats: {
        count: 0,
        totalNet: 0,
        totalTax: 0,
        totalFee: 0,
        fromDate: null,
        toDate: null,
        fromYear: null,
        toYear: null,
        areaIds: [],
      },
      totalNet: 0,
      totalTax: 0,
      totalFee: 0,
      sortColumn: 'created_at',
      sortOrder: 'DESC',
      selectedPage: 1,
      hitsPerPage: 25,
    };
  },
  computed: {
    areaIds() {
      return this.stats.areaIds || [];
    },
    sourceList() {
      return (this.stats.sourceNames || []).map((name) => ({
        name,
        code: name,
      }));
    },
    years() {
      const toYear = this.stats.toYear || new Date().getFullYear();
      const fromYear = this.stats.fromYear || toYear - 20;
      return new Array(toYear - fromYear + 1).fill(0).map((_, index) => {
        const year = toYear - index;

        return {
          code: year,
          name: year,
        };
      });
    },
    numberOfPages() {
      return Math.max(Math.ceil(this.stats.count / this.hitsPerPage), 1);
    },
    request() {
      const request = {
        performerId: this.$route.params.id,
        orderBy: this.sortColumn,
        dir: this.sortOrder,
        limit: this.hitsPerPage,
        offset: (this.selectedPage - 1) * this.hitsPerPage,
      };

      for (var key in this.comittedFilters) {
        request[key] = this.comittedFilters[key];
      }

      if (request.mainArtistSearch) {
        request.mainArtistSearch = '%' + request.mainArtistSearch + '%';
      }

      if (request.titleSearch) {
        request.titleSearch = '%' + request.titleSearch + '%';
      }
      return request;
    },
  },
  async created() {
    this.loadData();
    this.loadStats();
  },
  methods: {
    setDateFilter(timestamp) {
      const date = this.$filters.dateFormat(new Date(timestamp), 'YYYY-MM-DD');
      this.filter.createdFrom = date;
      this.filter.createdTo = date;
      this.onSubmitFilters();
    },
    resetFilter() {
      this.filter = {
        createdFrom: null,
        createdTo: null,
        performanceYear: null,
        sourceName: null,
        titleSearch: null,
        mainArtistSearch: null,
      };
      this.onSubmitFilters();
    },
    async loadStats() {
      try {
        this.loadingStats = true;
        const { remunerationStats } = await query({
          query: gql`
            query remunerationStats($request: RemunerationHistoryRequest!) {
              remunerationStats(request: $request) {
                count
                totalNet
                totalTax
                totalFee
                fromDate
                toDate
                fromYear
                toYear
                sourceNames
                areaIds
              }
            }
          `,
          variables: {
            request: this.request,
          },
        });
        this.stats = remunerationStats;
      } catch (error) {
        this.$addStarError({
          title: 'Error fetching remuneration stats',
          type: `${error.statusCode} - ${error.code}`,
          message: error.message,
        });
      } finally {
        this.loadingStats = false;
      }
    },
    async loadData() {
      if (this.loadingList) {
        return;
      }

      this.loadingList = true;

      try {
        const { remunerationHistory } = await query({
          query: gql`
            query remunerationHistory($request: RemunerationHistoryRequest!) {
              remunerationHistory(request: $request) {
                items {
                  id
                  right
                  createdAt
                  distributionType
                  areaId
                  areaName
                  year
                  sourceId
                  sourceName
                  net
                  title
                  recordingId
                  versionTitle
                  programTitle
                  mainArtist
                }
              }
            }
          `,
          variables: {
            request: this.request,
          },
        });
        this.data = remunerationHistory.items;
      } catch (error) {
        this.$addStarError({
          title: 'Error fetching remuneration history',
          type: `${error.statusCode} - ${error.code}`,
          message: error.message,
        });
      } finally {
        this.loadingList = false;
      }
    },
    clickSort(sort) {
      this.sortColumn = sort.sortParam;
      this.sortOrder = sort.ascending ? 'ASC' : 'DESC';
      this.loadData();
    },
    onSelectPage(page) {
      this.selectedPage = parseInt(page);
      this.loadData();
    },
    onUpdateHitsPerPage(hitsPerPage) {
      this.hitsPerPage = parseInt(hitsPerPage);
      this.selectedPage = 1;
      this.loadData();
    },
    async onSubmitFilters() {
      this.selectedPage = 1;

      // copy filters from form model to comittedFilters
      this.comittedFilters = {};
      for (var key in this.filter) {
        if (
          this.filter[key] !== '' &&
          this.filter[key] !== null &&
          this.filter[key] !== undefined
        ) {
          this.comittedFilters[key] = this.filter[key];
        }
      }

      await Promise.all([this.loadData(), this.loadStats()]);
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 15px;
}

.filter {
  flex: 1;
}

.filter-btn-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.version-title {
  color: #777777;
}
</style>
