<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2>Create and Add Performer</h2>
      </div>
    </div>

    <div v-if="localPerformers.length > 0">
      <div v-for="(performer, index) in localPerformers" :key="performer._localId">
        <create-new-performer
          :name="scopeByIndex(index)"
          :scope="scopeByIndex(index)"
          :performer="performer"
          :roles="roles"
          @input="updateNewPerformer(index, $event)"
          @remove="removeNewPerformer(index)"
        />
      </div>
    </div>
    <div class="row edit-link">
      <div class="col s12">
        <a :class="{ disabled: false }" @click="addNewPerformer" @keyup.enter="addNewPerformer">
          <i class="far fa-plus-square" />
          Create and Add a New Performer
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import { mapMutations, mapGetters } from 'vuex';
import clone from './../../../common/clone';
import { createPerformerReference } from './../../../domain/recordingDomain';
import { createNamedRelation } from './../../../domain/common';
import { createPerformerAggregateCommand } from './../../../domain/performerDomain';
import CreateNewPerformer from './create-new-performer';

export default {
  name: 'CreateNewPerformers',
  components: { CreateNewPerformer },
  inject: ['$validator'],
  data() {
    return {
      localPerformers: [],
    };
  },
  computed: {
    ...mapGetters('recording', ['newPerformers', 'lineup']),
    disableAddButton() {
      return !this.lastHasEntry || this.hasErrors;
    },
    hasErrors() {
      for (let idx = 0; idx < this.localPerformers.length; idx++) {
        if (this.validationErrors.has(`${this.name}.${idx}`, 'create-new-performer')) {
          return true;
        }
      }
      return false;
    },
    lastHasEntry() {
      return (
        this.localPerformers.length === 0 ||
        this.validationErrors.any(this.scopeByIndex(this.localPerformers.length - 1))
      );
    },
    roles() {
      return this.$store.state.appdata.referenceData.rolesRecording;
    },
  },
  methods: {
    ...mapMutations('recording', [
      'addToNewPerformers',
      'removeFromNewPerformers',
      'replaceInNewPerformers',
      'addToLineup',
      'replaceInLineup',
    ]),
    scopeByIndex(index) {
      return `create-new-performer.${index}`;
    },
    updateNewPerformer(index, valueUpdate) {
      // The performer was just added, add it to the lineup and bind the ids etc.
      if (valueUpdate.id === null) {
        const newPerformerCommand = createPerformerAggregateCommand();
        newPerformerCommand.general_info = {
          first_name: null,
          last_name: null,
          country_of_residence: null,
          pseudo_names: [],
        };
        this.addToNewPerformers(newPerformerCommand);
        const performerRelation = createNamedRelation(
          newPerformerCommand.stream_id,
          null,
          'performer',
        );
        this.addToLineup(createPerformerReference(performerRelation, null, null));
        valueUpdate.id = newPerformerCommand.stream_id;
      }
      // Now update new performer with the edited value
      const newPerformerIndex = this.newPerformers.findIndex(
        (performer) => performer.stream_id === valueUpdate.id,
      );
      if (newPerformerIndex !== -1) {
        const newPerformerUpdate = clone(this.newPerformers[newPerformerIndex]);
        newPerformerUpdate.general_info.first_name = valueUpdate.first_name;
        newPerformerUpdate.general_info.last_name = valueUpdate.last_name;
        newPerformerUpdate.general_info.pseudo_names = valueUpdate.pseudo_names;
        newPerformerUpdate.general_info.country_of_residence = valueUpdate.country_of_residence;
        this.replaceInNewPerformers({ index: newPerformerIndex, value: newPerformerUpdate });
      }
      // Update new performer in lineup
      const lineupIndex = this.lineup.findIndex(
        (performer) => performer.relation.id === valueUpdate.id,
      );
      if (lineupIndex !== -1) {
        const lineupPerformer = clone(this.lineup[lineupIndex]);
        lineupPerformer.instruments = valueUpdate.instruments;
        lineupPerformer.role = valueUpdate.role;
        lineupPerformer.relation.name =
          valueUpdate.pseudo_names.length > 0
            ? valueUpdate.pseudo_names[0]
            : `${valueUpdate.first_name} ${valueUpdate.last_name}`;
        this.replaceInLineup({ index: lineupIndex, value: lineupPerformer });
      }
      const performer = this.localPerformers[index];
      performer.id = valueUpdate.id;
      performer.first_name = valueUpdate.first_name;
      performer.last_name = valueUpdate.last_name;
      performer.pseudo_names = valueUpdate.pseudo_names;
      performer.country_of_residence = valueUpdate.country_of_residence;
      performer.instruments = valueUpdate.instruments;
      performer.role = valueUpdate.role;
      performer.active_period = valueUpdate.active_period;
      this.localPerformers.splice(index, 1, performer);
    },
    removeNewPerformer(index) {
      this.localPerformers.splice(index, 1);
    },
    addNewPerformer() {
      this.localPerformers.push(
        Object.assign(
          { _localId: uuid.v4() },
          {
            id: null,
            first_name: null,
            last_name: null,
            country_of_residence: null,
            pseudo_names: [],
            instruments: [],
            role: null,
          },
        ),
      );
    },
  },
};
</script>
