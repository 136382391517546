<template>
  <div>
    <div class="row">
      <div v-if="loading" class="col s12">
        <spinner />
      </div>
      <div v-else class="col s12">
        <h1 class="row wrapper">
          Discography
          <span v-if="name">for {{ name }}</span>
        </h1>

        <div class="tabs row wrapper">
          <div class="tabs__wrapper">
            <router-link :to="{ name: 'discographyMainArtistRecordings', params: { id } }">
              Recordings
            </router-link>
            <router-link :to="{ name: 'discographyMainArtistAlbums', params: { id } }">
              Albums
            </router-link>
          </div>
        </div>

        <router-view type="mainartist" />
      </div>
    </div>
  </div>
</template>

<script>
import MainArtistService from '../../../services/mainArtistService';
import Spinner from '../../spinner';

export default {
  name: 'ViewDiscography',
  components: {
    Spinner,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      name: null,
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    const mainArtist = await MainArtistService.getMainArtist(this.id);
    this.name = mainArtist.basic_info.name;
    this.loading = false;
  },
};
</script>
