<template>
  <entity-badge :entity="aggregateType" size="large" />
</template>

<script>
import EntityBadge from './entity-badge';

export default {
  name: 'EntityTag',
  components: {
    EntityBadge,
  },
  data() {
    return {
      routeEntity: this.$router.currentRoute.path.split('/')[1],
      img_src: '',
    };
  },
  computed: {
    aggregateType() {
      if (this.routeEntity === 'societies') {
        return 'society';
      }
      return this.routeEntity.replace(/s\s*$/, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-tag {
  &__image {
    width: 100%;
    max-width: 160px;
  }
}
</style>
