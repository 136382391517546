<template>
  <div>
    <button type="button" :disabled="disableDecrement" @click="onClick(value - 1)">
      <i class="fas fa-chevron-up"></i>
    </button>

    <span v-if="showValue">
      {{ value }}
    </span>

    <button type="button" :disabled="disableIncrement" @click="onClick(value + 1)">
      <i class="fas fa-chevron-down"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    showValue: {
      type: Boolean,
    },
    disableIncrement: {
      type: Boolean,
    },
    disableDecrement: {
      type: Boolean,
    },
  },

  methods: {
    onClick(newPriority) {
      this.$emit('input', newPriority);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 90%;
}

button {
  border-radius: 50%;
  padding: 0.25rem;

  &:hover {
    background-color: rgba(0, 191, 211, 0.2);
  }
}
</style>
