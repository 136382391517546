<template>
  <form class="cmp">
    <div v-if="editMode" class="row text--right">
      <div class="col s12">
        <action-buttons @save="update" @cancel="cancel" />
      </div>
    </div>
    <template v-if="editMode">
      <div class="row card">
        <div class="row">
          <div class="col s2">Org no.</div>
          <div class="col s7">
            <validated-text-input
              v-validate="computedRule"
              name="organisation_info.org_no"
              placeholder="e.g. 123456-1234"
              :value="org_no"
              @input="_updateOrganisationNumber"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">
            Company name
            <span class="mandatory">(mandatory)</span>
          </div>
          <div class="col s7">
            <validated-text-input
              name="organisation_info.name"
              placeholder="E.g. My Company AB"
              :value="name"
              label="company name"
              :show-label="false"
              rule="required"
              @input="_updateName"
            />
          </div>
        </div>
        <div class="row">
          <div class="col s2">Local codes</div>
          <div class="col s7">
            <local-codes
              name="general_info.local_codes"
              scope="associate"
              :show-label="false"
              :entries="localCodes"
              entry-key-name="code"
              entry-value-name="value"
              :local-codes="localCodeTypes"
              selectable-item-key-name="code"
              selectable-item-value-name="name"
              @add="addLocalCode"
              @input="updateLocalCode"
              @remove="removeLocalCode"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="row card">
        <div class="row">
          <div class="col s8">
            <validated-text-input
              v-validate="computedRule"
              name="organisation_info.org_no"
              placeholder="e.g. sss 123456-1234"
              label="Org no."
              :value="org_no"
              @input="_updateOrganisationNumber"
            />
          </div>
        </div>

        <div class="row flush--bottom">
          <div class="col s8">
            <validated-text-input
              name="organisation_info.name"
              placeholder="E.g. My Company AB"
              :mandatory="true"
              label="Company name"
              :value="name"
              rule="required"
              @input="_updateName"
            />
          </div>
        </div>
      </div>
    </template>
  </form>
</template>

<script>
import * as _ from 'lodash';
import { mapState, mapMutations, mapGetters } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import { orgNoUnique } from '../org_no-input-validator';
import LocalCodes from '../../ui/input/local-codes';
import { createLocalCode } from '../../../domain/common';

export default {
  name: 'CreateOrganisationInfo',
  components: {
    ActionButtons,
    ValidatedTextInput,
    LocalCodes,
  },
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      middleNameActive: false,
      validationConfig: Object.assign({}, { orgNoUnique }),
    };
  },
  computed: {
    ...mapState('associate', {
      org_no: (state) => state.organisation_info.org_no,
      name: (state) => state.organisation_info.name,
      stream_id: (state) => state.id,
    }),
    ...mapGetters('associate', ['type', 'localCodes']),
    computedRule() {
      return Object.assign(
        {},
        {
          org_no: true,
          unique_org_no: this.validationConfig.orgNoUnique,
        },
        this.rule,
      );
    },
    localCodeTypes() {
      return this.$store.state.appdata.localCodeTypes;
    },
  },
  created() {
    this.validationConfig.orgNoUnique.type = String(this.type);
    this.validationConfig.orgNoUnique.streamId = String(this.stream_id);
  },
  methods: {
    ...mapMutations('associate', [
      'updateOrganisationInfoOrgNo',
      'updateOrganisationInfoName',
      'updateLocalCodes',
    ]),
    _updateOrganisationNumber(val) {
      this.updateOrganisationInfoOrgNo(val);
    },
    _updateName(val) {
      this.updateOrganisationInfoName(val);
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    update() {
      this.$emit('update', this.$options.name);
    },
    addLocalCode() {
      const localCodes = _.cloneDeep(this.localCodes);
      localCodes.push(createLocalCode());
      this.updateLocalCodes(localCodes);
    },
    updateLocalCode(entryUpdate) {
      const localCodes = _.cloneDeep(this.localCodes);
      localCodes.splice(entryUpdate.index, 1, entryUpdate.entry);
      this.updateLocalCodes(localCodes);
    },
    removeLocalCode(index) {
      const localCodes = _.cloneDeep(this.localCodes);
      localCodes.splice(index, 1);
      this.updateLocalCodes(localCodes);

      if (localCodes.length === 0) {
        this.addLocalCode();
      }
    },
  },
};
</script>
