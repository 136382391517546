<template>
  <div>
    <select-generic
      name="group"
      :label="label"
      :value="value"
      :data="existingGroups"
      :disabled="disabled"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectGeneric from '../../ui/select/select-generic';
import DistributionService from '../../../services/distributionService';

export default {
  name: 'SelectChannelGroup',
  components: {
    SelectGeneric,
  },
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: 'Group' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      existingGroups: {},
    };
  },
  created() {
    this.getExistingGroups();
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
    async getExistingGroups() {
      const result = await DistributionService.getDistributionGroups();
      this.existingGroups = {};
      result.groups.forEach((item) => {
        this.existingGroups[item] = item;
      });
    },
  },
};
</script>
