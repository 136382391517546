<template>
  <tbody>
    <tr>
      <td class="thin-column dropdown-button__arrow" @click="expanded = !expanded">
        <i :class="`fas fa-caret-${expanded ? 'up' : 'down'}`"></i>
      </td>

      <Column
        v-for="(column, index) in columns[mergeType]"
        :key="`column-${index}`"
        :column="column"
        :item="match"
      />

      <td>
        <span @click="!match.master && $emit('updateDuplicateStreamId', match.id)">
          <i :class="`fa${match.master ? 's' : 'r'} fa-star`" />
        </span>
      </td>
      <td>
        <span
          v-if="!match.master"
          class="cursor-pointer"
          @click="$emit('addDuplicateBlockedId', match.id)"
        >
          <i class="far fa-trash-alt" />
        </span>
      </td>
    </tr>

    <template v-if="expanded">
      <DuplicateMatchAlbum v-if="mergeType === 'album'" :id="match.id" />

      <DuplicateMatchMainartist v-if="mergeType === 'mainartist'" :match="match" />
    </template>
  </tbody>
</template>

<script>
import DuplicateMatchAlbum from './duplicate-match-album';
import DuplicateMatchMainartist from './duplicate-match-mainartist';
import Column from '../ui/table/column';

export default {
  name: 'DuplicatesMatch',
  components: {
    DuplicateMatchAlbum,
    DuplicateMatchMainartist,
    Column,
  },
  props: {
    match: {
      type: Object,
      required: true,
    },
    mergeType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
      columns: {
        mainartist: [
          { prop: 'name', link: '/mainartists/{id}' },
          { prop: 'alternate_names' },
          { prop: 'origin_country', filter: 'formatCountry' },
          { prop: 'num_performers' },
        ],
        album: [
          { prop: 'name', link: '/albums/{id}' },
          { prop: 'main_artist.name', link: '/mainartists/{main_artist.id}' },
          { prop: 'catalog_number' },
          { prop: 'release_date', filter: 'formatRecordingDate' },
          { prop: 'label' },
          { prop: 'number_of_tracks' },
        ],
      },
    };
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
i.far.fa-star {
  opacity: 0.4;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}
</style>
