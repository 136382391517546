<template>
  <form class="search-result__recordings">
    <div class="search-result__filters card">
      <div class="row">
        <div class="col s4">
          <label class="label">Search</label>
          <clearable-input
            v-model="searchTerms.query"
            type="search"
            name="filter"
            placeholder=""
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s3">
          <label class="label">Main Artist</label>
          <clearable-input
            v-model="searchTerms.mainArtist"
            type="text"
            placeholder="E.g. Kent"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s2">
          <label class="label">Label</label>
          <clearable-input
            v-model="searchTerms.label"
            type="text"
            placeholder="E.g. BMG"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s3">
          <select-country
            v-model="searchTerms.countryCode"
            label="Recorded in Country"
            :input-countries="localCountries"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s4">
          <searchable-tag-input
            name="tags"
            label="Tags"
            placeholder="E.g. Top 100"
            :preview-list="previewTags"
            :value-list="localTags"
            :searcher="searchTag"
            scope="advanced-search-recordings"
            use-template="tag"
            @add="addTag"
            @remove="removeTagAt"
          />
        </div>
        <div class="col s4">
          <add-lineup
            :performers="searchTerms.lineup"
            :remove="true"
            @removeInLineup="removeInLineup"
            @replaceInLineup="replaceInLineup"
          />
        </div>
        <div class="col s2">
          <label class="label">Recording Year</label>
          <clearable-input
            v-model="searchTerms.recordingYear"
            type="text"
            name="recordingYear"
            placeholder="YYYY"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
        <div class="col s2">
          <label class="label">Release Year</label>
          <clearable-input
            v-model="searchTerms.releaseYear"
            type="text"
            name="releaseYear"
            placeholder="YYYY"
            @keydown.prevent.enter="onSearchClick"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s4">
          <input
            id="member"
            name="member"
            class="filled-in"
            type="checkbox"
            :checked="searchTerms.member"
            @change="onlySami($event.target.checked)"
          />
          <label for="member">Recordings with SAMI members (international)</label>
        </div>
      </div>
      <div class="row flush--bottom">
        <div class="col s12 text--right">
          <action-buttons
            submit-label="Search"
            abort-label="Clear"
            @save="onSearchClick"
            @cancel="onClearClick"
          />
        </div>
      </div>
    </div>

    <div v-show="searchContext.isExecuted" class="search-result__summary">
      Search for
      <span v-if="searchContext.query != ''" class="text--bold">{{ searchContext.query }}</span>
      <span v-else>*</span>
      in Recordings gave {{ $filters.formatNumber(searchContext.result.totalHits) }} results
    </div>

    <SearchQueryTooLong
      :length-limit="searchContext.searchLengthLimit"
      :query-too-long="searchContext.isTooLong"
    />

    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else>
      <div class="search-result__result card row flush--bottom">
        <div class="row">
          <div class="col s12">
            <table>
              <thead>
                <th
                  v-for="(column, index) in columns"
                  :key="index"
                  :class="{ 'thin-column': column.thinColumn, 'disabled-head': !column.sortName }"
                  @click="column.sortName ? sort(column) : null"
                >
                  <span>{{ column.name }}</span>
                  <span v-if="column.sortName" class="dropdown-button__arrow">
                    <i
                      v-if="column.order === 'desc'"
                      class="fas fa-caret-up"
                      :class="{ 'text--grey': !column.active }"
                    />
                    <i v-else class="fas fa-caret-down" :class="{ 'text--grey': !column.active }" />
                  </span>
                </th>
              </thead>
              <tbody>
                <template v-for="(result, resultIndex) in searchContext.result.results">
                  <tr :key="resultIndex">
                    <td
                      v-if="hasDetails(result)"
                      class="thin-column dropdown-button__arrow"
                      @click="toggleDetails(result.id, $event)"
                    >
                      <i v-if="recordingExpanded(result.id)" class="fas fa-caret-up" />
                      <i v-else-if="!recordingExpanded(result.id)" class="fas fa-caret-down" />
                    </td>
                    <td v-if="!hasDetails(result)" class="no-details thin-column">
                      <i class="fas fa-caret-down" />
                    </td>
                    <td>
                      <span>
                        <router-link
                          :to="`/recordings/${result.id}`"
                          v-html="
                            $filters.highlightToHtml(
                              result.name + `${result.lineup_locked ? ' 🔒' : ''}`,
                              searchContext.query,
                            )
                          "
                        />
                      </span>
                    </td>
                    <td>
                      <span v-if="result.version_title">{{ result.version_title }}</span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.vrdb_type">
                        {{ $filters.toTitleCase(result.vrdb_type) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="!result.main_artist" class="none">None</span>
                      <span v-else>
                        <span v-if="result.main_artist.id">
                          <router-link
                            :to="`/mainartists/${result.main_artist.id}`"
                            class="fs-13"
                            v-html="
                              $filters.highlightToHtml(
                                result.main_artist.name,
                                searchContext.mainArtist,
                              )
                            "
                          />
                        </span>
                        <span v-else>
                          {{ result.main_artist.name }}
                        </span>
                      </span>
                    </td>
                    <td>
                      <span v-if="result.recording_date">
                        {{ $filters.formatRecordingDate(result.recording_date) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.recorded_in_country">
                        {{ $filters.formatCountry(result.recorded_in_country) }}
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.isrc">{{ result.isrc }}</span>
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span
                        v-if="result.label"
                        v-html="$filters.highlightToHtml(result.label, searchContext.label)"
                      />
                      <span v-else class="none">None</span>
                    </td>
                    <td>
                      <span v-if="result.sami_members">
                        <i class="fas fa-user" />
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                    <td class="status">
                      <span v-if="result.distribution_state && result.distribution_state.status">
                        <status-icon :status="mapDistributionState(result)" />
                      </span>
                      <span v-else class="none">None</span>
                    </td>
                  </tr>
                  <tr
                    v-if="recordingExpanded(result.id)"
                    :key="`${resultIndex}-expanded`"
                    class="expand-info"
                  >
                    <td class="thin-column" />
                    <td>
                      <span
                        v-show="result.alternate_titles && result.alternate_titles.length > 0"
                        class="expand-info__header"
                      >
                        Alternate titles
                      </span>
                      <div v-for="(alternate_title, index) in result.alternate_titles" :key="index">
                        {{ alternate_title }}
                      </div>
                    </td>
                    <td />
                    <td>
                      <span class="expand-info__header">Lineup</span>
                      <div v-if="!result.lineup || result.lineup.length === 0" class="none">
                        None
                      </div>
                      <div>
                        <div class="expand-info__lineup">
                          <simple-spinner v-if="!detailedLineups[result.id]" />
                          <template v-for="(performer, index) in detailedLineups[result.id]" v-else>
                            <div v-if="index < 10" :key="performer.relation.id">
                              <router-link :to="'/performers/' + performer.relation.id">
                                {{ performer.relation.name }}
                                {{ performer.relation.protected_identity ? ' 🔒' : '' }}
                              </router-link>
                            </div>
                            <div
                              v-else-if="index === 10"
                              :key="performer.relation.id"
                              class="expand-info__lineup__more-exists"
                            >
                              {{ result.lineup.length - 10 }} more
                            </div>
                          </template>
                        </div>
                      </div>
                    </td>
                    <td v-if="result.lineup && result.lineup.length > 0">
                      <span class="expand-info__header">Role</span>

                      <div v-if="result.lineup.length > 10">
                        <div class="expand-info__lineup">
                          <div
                            v-for="(performer, index) in result.lineup.slice(0, 10)"
                            :key="index"
                          >
                            <span>
                              {{ $filters.formatRole(performer.role) }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-for="(performer, index) in result.lineup" v-else :key="index">
                        <span>
                          {{ $filters.formatRole(performer.role) }}
                        </span>
                      </div>
                    </td>
                    <td colspan="5" class="expand-info__meta">
                      <span class="expand-info__header">More meta data</span>
                      <ul>
                        <li class="expand-info__meta__title">Duration:</li>
                        <li v-if="result.duration_sec">
                          {{ $filters.toMinutes(result.duration_sec) }}
                        </li>
                        <li v-else>-</li>

                        <li class="expand-info__meta__title">Produced in country:</li>
                        <li v-if="result.produced_in_country">
                          {{ $filters.formatCountry(result.produced_in_country) }}
                        </li>
                        <li v-else>-</li>

                        <li class="expand-info__meta__title">VRDB ID:</li>
                        <li>{{ alternateVrdbIds(result) || '-' }}</li>
                      </ul>
                      <ul>
                        <li class="expand-info__meta__title">Composed by:</li>
                        <li>{{ result.composer || '-' }}</li>
                        <li class="expand-info__meta__title">Written by:</li>
                        <li>{{ result.writer || '-' }}</li>
                        <li class="expand-info__meta__title">Arranged by:</li>
                        <li>{{ result.arranger || '-' }}</li>
                      </ul>
                      <div class="expand-info__meta__tags">
                        <div class="expand-info__meta__tags-title expand-info__meta__title">
                          Tags:
                        </div>
                        <div class="chips__wrapper">
                          <template v-if="result.tags && result.tags.length > 0">
                            <tag v-for="(tag, index) in result.tags" :key="index" :tag="tag" />
                          </template>
                          <div v-else class="none">None</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-if="searchContext.isExecuted && searchContext.result.totalHits === 0">
                  <tr>
                    <td colspan="1000" class="search-result__no-result">No items found.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="searchContext.isExecuted">
          <div class="col s12">
            <pagination
              v-show="numberOfPages > 0"
              topic="search"
              :number-of-pages="numberOfPages"
              :selected-page="pagination.page"
              :number-of-hits="searchContext.result.totalHits"
              :hits-per-page="pagination.hitsPerPage"
              @selectPage="selectPage"
              @updateHitsPerPage="updateHitsPerPage"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';

import ActionButtons from '../ui/button/action-buttons';
import AddLineup from './add-lineup';
import Pagination from '../pagination';
import SearchHelper from './searchHelper';
import SearchableTagInput from '../ui/tag/searchable-tag-input';
import SelectCountry from '../ui/select/select-country';
import Spinner from '../spinner';
import StatusIcon from '../ui/status-icon';
import Tag from '../tags/tag';
import ClearableInput from '../ui/input/clearable-input.vue';
import SearchQueryTooLong from './search-query-too-long';
import RecordingService from '@/services/recordingService';
import SimpleSpinner from '@/components/ui/simple-spinner.vue';

export default {
  name: 'AdvancedSearchRecordings',
  components: {
    SimpleSpinner,
    ActionButtons,
    AddLineup,
    SelectCountry,
    Pagination,
    SearchableTagInput,
    Spinner,
    StatusIcon,
    Tag,
    ClearableInput,
    SearchQueryTooLong,
  },
  data() {
    return {
      loading: false,
      localCountries: [],
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Recording',
          sortName: 'name.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'Version',
          sortName: 'version_title.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'VRDB',
          sortName: 'vrdb_type',
          order: 'desc',
          active: false,
        },
        {
          name: 'Main Artist',
          sortName: 'main_artist.name.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'Recording Date',
          sortName: 'recording_date',
          order: 'desc',
          active: false,
        },
        {
          name: 'Recorded in Country',
          sortName: 'recorded_in_country',
          order: 'desc',
          active: false,
        },
        {
          name: 'ISRC',
          sortName: 'isrc',
          order: 'desc',
          active: false,
        },
        {
          name: 'Label',
          sortName: 'label.keyword',
          order: 'desc',
          active: false,
        },
        {
          name: 'Member',
        },
        {
          name: 'Status',
          sortName: 'distribution_state.status',
          order: 'desc',
          active: false,
        },
      ],
      searchTerms: {
        query: '',
        mainArtist: '',
        label: '',
        lineup: [],
        countryCode: '',
        tags: [],
        releaseYear: '',
        recordingYear: '',
        member: false,
      },
      sortTerms: {
        param: '',
        order: '',
      },
      defaultHitsPerPage: 25,
      expandedRecordingIds: [],
      allTags: [],
      localTags: [],
      searchContext: {
        query: '',
        mainArtist: '',
        label: '',
        isExecuted: false,
        isTooLong: false,
        searchLengthLimit: SearchHelper.QUERY_LENGTH_LIMIT,
        result: {
          totalHits: 0,
          results: [],
        },
      },
      detailedLineups: {},
      pagination: {
        hitsPerPage: this.defaultHitsPerPage,
        page: 1,
      },
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.searchContext.result.totalHits / this.pagination.hitsPerPage);
    },
    previewTags() {
      return this.allTags
        .filter((tag) =>
          this.localTags.length > 0
            ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
            : true,
        )
        .sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        });
    },
    firstHitIndexOnCurrentPage() {
      if (this.searchContext.result.totalHits === 0) {
        return 0;
      }
      return Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage + 1;
    },
    lastHitIndexOnCurrentPage() {
      const totalHits = this.searchContext.result.totalHits;
      const lastHitIndex =
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage +
        this.pagination.hitsPerPage;
      return lastHitIndex < totalHits ? lastHitIndex : totalHits;
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    $route() {
      this.updateRouterSearchParams();
      if (this.$router.currentRoute.query.i) {
        this.search();
      } else {
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        this.$emit('updateIsExecuted', false);
      }
    },
  },
  async created() {
    this.localCountries = this.countries.slice();
    this.allTags = await SearchHelper.getAllTags();
    if (this.$router.currentRoute.query.i) {
      this.updateRouterSearchParams();
      this.search();
    }
  },
  methods: {
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.searchTerms.tags = event.data.map((tag) => tag.name);
      this.localTags = event.data;
    },
    removeTagAt(event) {
      this.searchTerms.tags.splice(event.idx, 1);
    },
    updateMainArtist(mainArtist = '') {
      this.searchTerms.mainArtist = mainArtist;
    },
    removeInLineup(index) {
      this.searchTerms.lineup.splice(index, 1);
    },
    replaceInLineup(returnValue) {
      if (returnValue.performerName) {
        this.searchTerms.lineup.splice(returnValue.index, 1, returnValue.performerName);
      } else {
        this.searchTerms.lineup.splice(returnValue.index, 1);
      }
    },
    onlySami(checked) {
      this.searchTerms.member = checked;
    },
    alternateVrdbIds(recording) {
      if (recording.vrdb2_id) {
        return recording.vrdb2_id;
      } else if (recording.vrdb_type === 'associated') {
        const associated = recording.local_codes.find((lc) => lc.code === 'VRDB2_ASSOCIATED');
        return associated ? associated.value : '';
      } else if (recording.vrdb_type === 'playlist') {
        const playlist = recording.local_codes.find((lc) => lc.code === 'VRDB2_PLAYLIST_ID');
        return playlist ? playlist.value : '';
      }
      return '';
    },
    hasDetails(recording) {
      return (
        (recording.alternate_titles && recording.alternate_titles.length > 0) ||
        (recording.lineup && recording.lineup.length > 0) ||
        recording.produced_in_country ||
        recording.duration_sec ||
        this.alternateVrdbIds(recording) ||
        recording.composer ||
        recording.writer ||
        recording.arranger ||
        (recording.tags && recording.tags.length > 0)
      );
    },
    async toggleDetails(id, e) {
      const index = _.indexOf(this.expandedRecordingIds, id);
      const row = e.target.closest('tr');
      if (index === -1) {
        this.expandedRecordingIds.push(id);
        row.style.whiteSpace = 'normal';
        const result = await RecordingService.getRecordingLineup(id);
        this.$set(this.detailedLineups, id, result.lineup);
      } else {
        this.expandedRecordingIds.splice(index, 1);
        row.style.whiteSpace = 'nowrap';
      }
    },
    recordingExpanded(id) {
      return _.includes(this.expandedRecordingIds, id);
    },
    selectPage(selectedPage) {
      this.pagination.page = selectedPage;
      this.pushSearch();
    },
    updateHitsPerPage(value) {
      this.pagination.hitsPerPage = Number(value);
      this.resetPages();
      this.pushSearch();
    },
    updateRouterSearchParams() {
      this.searchTerms.query = this.$router.currentRoute.params.query
        ? this.$router.currentRoute.params.query
        : '';
      this.searchTerms.mainArtist = this.$router.currentRoute.query.mainArtist
        ? this.$router.currentRoute.query.mainArtist
        : '';
      this.searchTerms.label = this.$router.currentRoute.query.label
        ? this.$router.currentRoute.query.label
        : '';
      this.searchTerms.countryCode = this.$router.currentRoute.query.country
        ? this.$router.currentRoute.query.country
        : '';
      this.searchTerms.tags = this.$router.currentRoute.query.tags
        ? this.$router.currentRoute.query.tags
        : [];
      this.searchTerms.member = [true, 'true'].includes(this.$router.currentRoute.query.member);
      this.searchTerms.recordingYear = this.$router.currentRoute.query.recordingYear
        ? this.$router.currentRoute.query.recordingYear
        : '';
      this.searchTerms.releaseYear = this.$router.currentRoute.query.releaseYear
        ? this.$router.currentRoute.query.releaseYear
        : '';
      if (this.$router.currentRoute.query.lineup) {
        this.searchTerms.lineup =
          typeof this.$router.currentRoute.query.lineup === 'string'
            ? [this.$router.currentRoute.query.lineup]
            : this.$router.currentRoute.query.lineup;
      } else {
        this.searchTerms.lineup = [];
      }

      if (this.$router.currentRoute.query.tags) {
        if (_.isArray(this.$router.currentRoute.query.tags)) {
          this.localTags = this.$router.currentRoute.query.tags.map((tag) => ({
            name: tag,
            type: 'tag',
          }));
        } else {
          this.localTags = [{ name: this.$router.currentRoute.query.tags, type: 'tag' }];
        }
      } else {
        this.localTags = [];
      }
      this.sortTerms.param = this.$router.currentRoute.query.sortParam
        ? this.$router.currentRoute.query.sortParam
        : '';
      this.sortTerms.order = this.$router.currentRoute.query.sortOrder
        ? this.$router.currentRoute.query.sortOrder
        : '';
      this.pagination.page = this.$router.currentRoute.query.page
        ? Number(this.$router.currentRoute.query.page)
        : 1;
      this.pagination.hitsPerPage = this.$router.currentRoute.query.hits
        ? Number(this.$router.currentRoute.query.hits)
        : this.defaultHitsPerPage;
    },
    onSearchClick() {
      this.resetPages();
      this.pushSearch();
    },
    onClearClick() {
      this.$router.push({
        name: 'advancedSearchRecordingsView',
        query: '',
      });
    },
    pushSearch() {
      const queryParams = {
        i: new Date().getTime(),
      };
      if (this.searchTerms.mainArtist) {
        queryParams.mainArtist = this.searchTerms.mainArtist;
      }
      if (this.searchTerms.lineup) {
        queryParams.lineup = this.searchTerms.lineup;
      }
      if (this.searchTerms.label) {
        queryParams.label = this.searchTerms.label;
      }
      if (this.searchTerms.countryCode) {
        queryParams.country = this.searchTerms.countryCode;
      }
      if (this.searchTerms.member) {
        queryParams.member = this.searchTerms.member;
      }
      if (this.searchTerms.tags) {
        queryParams.tags = this.searchTerms.tags;
      }
      if (this.sortTerms.param) {
        queryParams.sortParam = this.sortTerms.param;
      }
      if (this.sortTerms.order) {
        queryParams.sortOrder = this.sortTerms.order;
      }
      if (this.pagination.hitsPerPage) {
        queryParams.hits = this.pagination.hitsPerPage;
      }
      if (this.pagination.page) {
        queryParams.page = this.pagination.page;
      }
      if (this.searchTerms.recordingYear) {
        queryParams.recordingYear = this.searchTerms.recordingYear;
      }
      if (this.searchTerms.releaseYear) {
        queryParams.releaseYear = this.searchTerms.releaseYear;
      }
      if (this.searchTerms.query !== '') {
        this.$router.push({
          name: 'advancedSearchRecordingsQueryView',
          params: { query: this.searchTerms.query },
          query: queryParams,
        });
      } else {
        this.$router.push({
          name: 'advancedSearchRecordingsView',
          query: queryParams,
        });
      }
    },
    search() {
      this.searchContext.query = this.searchTerms.query;
      this.searchContext.mainArtist = this.searchTerms.mainArtist;
      this.searchContext.label = this.searchTerms.label;
      if (SearchHelper.isQueryTooLong(this.searchTerms.query)) {
        this.searchContext.isTooLong = true;
        this.searchContext.result = [];
        this.searchContext.isExecuted = false;
        return;
      }
      this.searchContext.isTooLong = false;
      this.loading = true;
      SearchHelper.advancedSearchRecordings(
        this.searchTerms.query,
        this.searchTerms.mainArtist,
        this.searchTerms.label,
        this.searchTerms.countryCode,
        this.searchTerms.lineup,
        this.searchTerms.tags,
        this.sortTerms.param,
        this.sortTerms.order,
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage,
        this.pagination.hitsPerPage,
        this.searchTerms.member,
        this.searchTerms.recordingYear,
        this.searchTerms.releaseYear,
      )
        .then((result) => {
          this.searchContext.isExecuted = true;
          this.$emit('updateIsExecuted', true);
          this.searchContext.result = result;
        })
        .then(() => {
          this.loading = false;
        });
    },
    resetPages() {
      this.sortTerms = { param: '', order: '' };
      this.pagination.page = 1;
    },
    sort: function sort(column) {
      this.sortTerms.param = column.sortName;
      column.order = column.order === 'desc' ? 'asc' : 'desc';
      this.sortTerms.order = column.order;
      this.columns.forEach((c) => {
        c.active = false;
      });
      column.active = true;
      this.pushSearch();
      this.search();
    },
    mapDistributionState(result) {
      if (
        result.distribution_state.status !== 'Green' &&
        result.lineup.length &&
        (result.recorded_in_country || result.produced_in_country) &&
        (result.recording_date || result.release_date)
      ) {
        return 'Yellow';
      }

      return result.distribution_state.status;
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.status {
  vertical-align: middle;
  min-width: 80px;
}
</style>
