<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Albums</h2>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <table class="lineup__performers">
          <sortable-head
            :columns="columns"
            :bulk-enabled="false"
            :default-sort-column-index="0"
            @sort="onSort"
          />
          <tbody>
            <template v-for="album in localAlbums">
              <tr :key="album.id">
                <td>
                  <router-link :to="'/albums/' + album.id">
                    {{ album.name }}
                  </router-link>
                </td>
                <td>{{ album.side_no }}</td>
                <td>{{ album.track_no }}</td>
                <td>
                  <span v-if="album.release_date">
                    {{ album.release_date }}
                  </span>
                  <span v-else class="none">None</span>
                </td>
                <td>{{ album.catalog_number }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import clone from './../../common/clone';
import SortableHead from '../ui/table/sortable-head';

export default {
  name: 'Albums',
  components: {
    SortableHead,
  },
  data() {
    return {
      localAlbums: [],
      columns: [
        {
          name: 'Name',
          ascending: false,
          sortParam: 'name',
          active: true,
        },
        {
          name: 'Side No',
          ascending: false,
          sortParam: 'side_no',
          active: true,
        },
        {
          name: 'Track No',
          ascending: false,
          sortParam: 'track_no',
          active: true,
        },
        {
          name: 'Release Date',
          ascending: false,
          sortParam: 'release_date',
          active: true,
        },
        {
          name: 'Catalogue No',
          ascending: false,
          sortParam: 'catalog_no',
          active: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('recording', ['albums']),
  },
  mounted() {
    this.localAlbums = clone(this.albums);
    this.$nextTick(() => this.onSort(this.columns[0]));
  },
  methods: {
    onSort(column) {
      const sortOrder = column.ascending ? 'asc' : 'desc';
      const sortParam = column.sortParam;
      const sorted = _.orderBy(
        this.localAlbums,
        [
          function sortValue(recording) {
            return recording[sortParam];
          },
        ],
        [sortOrder],
      );
      this.localAlbums = sorted;
    },
  },
};
</script>
