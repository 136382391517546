<script>
import { mapMutations, mapState } from 'vuex';
import { createAssociation } from '../../../domain/common';

export default {
  props: {
    namespace: {
      type: String,
      required: true,
    },
    relationType: {
      type: String,
      validator: (value) => ['childAssociations', 'associates'].includes(value),
      required: true,
    },
    scope: {
      type: String,
      default: 'associates',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      aggregate(state, getters) {
        return getters[`${this.namespace}/aggregate`];
      },
      associates(state, getters) {
        return getters[`${this.namespace}/associates`];
      },
      childAssociations(state, getters) {
        return getters[`${this.namespace}/childAssociations`];
      },
      numChildAssociations(state, getters) {
        return getters[`${this.namespace}/numChildAssociations`];
      },
      paymentInfo(state, getters) {
        return getters[`${this.namespace}/paymentInfo`];
      },
    }),
    columns() {
      return [
        {
          name: 'Name',
        },
        {
          name: 'Type',
        },
        {
          name: 'From',
        },
        {
          name: 'To',
        },
        {
          name: 'View',
          thinColumn: true,
        },
        {
          name: 'Edit',
          thinColumn: true,
        },
        {
          name: 'Share',
          thinColumn: true,
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      updateAssociates(commit, payload) {
        return commit(`${this.namespace}/updateAssociates`, payload);
      },
      removeFromAssociates(commit, payload) {
        return commit(`${this.namespace}/removeFromAssociates`, payload);
      },
      addToAssociates(commit, payload) {
        return commit(`${this.namespace}/addToAssociates`, payload);
      },
      replaceInAssociates(commit, payload) {
        return commit(`${this.namespace}/replaceInAssociates`, payload);
      },
    }),
    addNewAssociation() {
      const obj = createAssociation(null, -1, this.namespace === 'performer' ? null : 'performer');
      obj._new = true;
      obj.is_recipient_of_money = false;
      obj.share = '1';
      this.addToAssociates(obj);
    },
    addNewChildAssociate() {
      const obj = createAssociation(null, -1, 'performer');
      obj._new = true;
      obj.is_recipient_of_money = false;
      obj.share = '1';
      this.addToChildAssociations(obj);
    },
    resolveEntityType(type) {
      return type.toLowerCase() === 'performer' ? 'PERFORMER' : 'ASSOCIATE';
    },
  },
};
</script>
