import { render, staticRenderFns } from "./create-general-information.vue?vue&type=template&id=0cac6d09&scoped=true"
import script from "./create-general-information.vue?vue&type=script&lang=js"
export * from "./create-general-information.vue?vue&type=script&lang=js"
import style0 from "./create-general-information.vue?vue&type=style&index=0&id=0cac6d09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cac6d09",
  null
  
)

export default component.exports