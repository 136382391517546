<template>
  <div class="server-error row">
    <div class="col offset-l3 l6">
      <h1>✵</h1>
      <h2>500 - There was an error on the server</h2>
      <generic-error v-if="error" :error="error" />
    </div>
  </div>
</template>

<script>
import GenericError from './generic-error';

export default {
  name: 'ServerError',
  components: {
    GenericError,
  },
  props: {
    error: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.server-error {
  h1 {
    font: 50px sans-serif;
    text-align: center;
  }
  h2 {
    font: 700 30px sans-serif;
    text-align: center;
  }
}
</style>
