import _ from 'lodash';
import * as domain from '../../../domain/starNotification';

// The root, initial state object
const rootState = domain.createStarNotificationRootState();

const actions = {
  pushError(context, error) {
    context.commit('addToStarErrors', error);
  },
};

const mutations = {
  addToStarErrors(state, error) {
    state.errors.push(error);
  },
  removeFromStarErrors(state, error) {
    state.errors.splice(
      _.findIndex(state.errors, (stateErr) => stateErr.timestamp === error.timestamp),
      1,
    );
  },
  updateModal(state, modal) {
    state.modal = modal;
  },
  updateLoadingState(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  starErrors: (state) => state.errors,
  modal: (state) => state.modal,
  loadingState: (state) => state.loading,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
