<template>
  <div>
    <svg class="stat-circle" width="30" viewBox="0 0 20 20">
      <circle class="bg" cx="10" cy="10" r="8" />
      <circle
        class="progress"
        :style="cssVars"
        cx="10"
        cy="10"
        r="8"
        transform="rotate(-90 10 10)"
        :data-percentage="percentage"
      />
      <text x="50%" y="60%">{{ percentage }}</text>
    </svg>
  </div>
</template>

<script>
import { STATE } from '@/store/modules/process/store';

export default {
  name: 'ProcessProgressCircle',
  props: {
    id: String,
  },
  data() {
    return {
      currentState: null,
    };
  },
  computed: {
    process() {
      return this.$store.getters['process/process'](this.id) || {};
    },
    lastRow() {
      const { logRows = [] } = this.process;
      return logRows[logRows.length - 1] || {};
    },
    progress() {
      this.checkStateChange();
      const { progress = {} } = this.lastRow;
      return {
        total: progress.total || 0,
        processed: progress.processed || 0,
        entity: progress.entity || null,
      };
    },
    percentage() {
      const { total, processed } = this.progress;
      const pct = total === 0 ? 0 : processed / total;
      return (100 * pct).toFixed(0);
    },
    cssVars() {
      return {
        '--progress-offset': -51 - (51 / 100) * this.percentage,
      };
    },
  },
  created() {
    this.currentState = this.process.state;
  },
  methods: {
    checkStateChange() {
      if (this.process.state !== this.currentState) {
        switch (this.process.state) {
          case STATE.FINISHED:
            this.$emit('finished');
            break;
        }
        this.currentState = this.process.state;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.stat-circle {
  circle.bg {
    fill: none;
    stroke: #cecdcd;
    stroke-width: 2;
  }

  circle.progress {
    fill: none;
    stroke: #2ecc71;
    stroke-width: 2;
    stroke-dasharray: 51 51;
    stroke-dashoffset: var(--progress-offset);
    stroke-linecap: round;
  }

  text {
    font-size: 6px;
    text-anchor: middle;
    fill: #ffffff;
  }
}
</style>
