<template>
  <div class="right-column row">
    <div class="col s12">
      <div class="row">
        <h2>Duplicates to merge</h2>
      </div>

      <div class="row">
        <div
          v-for="candidate of candidates"
          :key="candidate.id"
          class="chip"
          :style="`background: ${candidate.color}`"
        >
          <router-link :to="`/${mergeType}s/${candidate.id}`" target="_blank" class="flex">
            {{ candidate.descriptor }} {{ candidate.basic_info.name }}
            <template v-if="mergeType === 'album' && candidate.basic_info.main_artist">
              by {{ candidate.basic_info.main_artist.name }}
            </template>
            <i class="add-padding-left fas fa-external-link-alt" />
          </router-link>
          <span v-if="!candidate.master" @click="$emit('addDuplicateBlockedId', candidate.id)">
            <i class="add-padding-left far fa-trash-alt" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MergeRightColumn',
  props: {
    mergeType: {
      type: String,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.chip {
  justify-content: space-between;
  width: 100%;
  height: unset;
}
.flex {
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
</style>
