<template>
  <div>
    <create-edit-contact-info
      :contact-info="contactInfo"
      :enable-contact-person="!editMode"
      :country-required="true"
      :save-function="onSave"
      :cancel-function="onCancel"
      :edit-mode="editMode"
      @input="onContactInfoChanged"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { commit } from '../../../store/modules/society/utils';
import CreateEditContactInfo from '../../ui/contact/create-edit-contact-info';

export default {
  name: 'CreateContactInfo',
  components: {
    CreateEditContactInfo,
  },
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('society', ['contactInfo']),
  },
  methods: {
    ...mapMutations('society', ['updateContactInfo']),
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    onSave() {
      this.$emit('update', this.$options.name);
    },
    onContactInfoChanged(value) {
      commit('updateContactInfo', value);
    },
  },
};
</script>
