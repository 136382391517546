<template>
  <div class="no-padding">
    <div v-for="(period, index) in activePeriods" :key="index" class="row small-inner">
      <div class="row flush--bottom">
        <label>{{ label }}</label>
      </div>
      <div>
        <div class="col s5">
          <validated-text-input
            :name="`${name}-period-${index}-from`"
            label="from"
            :show-label="false"
            :disabled="disabled"
            placeholder="YYYY"
            :value="period.from"
            :rule="{ year: true, min: 4 }"
            :maxlength="4"
            :scope="scope"
            @input="onFromDateChanged(index, $event)"
          />
        </div>
        <div class="col s5">
          <validated-text-input
            :name="`${name}-period-${index}-to`"
            label="to"
            :show-label="false"
            placeholder="YYYY"
            :disabled="disabled"
            :value="period.to"
            :rule="{ year: true, min_value: period.from }"
            :scope="scope"
            :maxlength="4"
            @input="onToDateChanged(index, $event)"
          />
          <delete-button
            v-if="activePeriods.length > 1"
            tabindex="0"
            @click="onRemovePeriod(index)"
          />
        </div>
      </div>
    </div>

    <div class="row flush--bottom edit-link">
      <a
        :class="{ disabled: disableAddButton }"
        tabindex="0"
        @click="onAddPeriod"
        @keyup.enter="onAddPeriod"
      >
        <i class="far fa-plus-square" />
        Add active period
      </a>
    </div>
  </div>
</template>

<script>
import DeleteButton from '../ui/button/delete-button';
import ValidatedTextInput from '../ui/input/validated-text-input';

export default {
  name: 'CreateActivePeriod',
  scope: { type: String, default: undefined },
  components: { DeleteButton, ValidatedTextInput },
  inject: ['$validator'],
  props: {
    activePeriods: { type: Array },
    name: { type: String },
    scope: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    isInlineEdit: { type: Boolean, default: false },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    disableAddButton() {
      return this.hasErrors || !this.lastHasEntry;
    },
    hasErrors() {
      for (let idx = 0; idx < this.activePeriods.length; idx++) {
        if (
          this.validationErrors.has(`${this.name}-period-${idx}-from`, this.scope) ||
          this.validationErrors.has(`${this.name}-period-${idx}-to`, this.scope)
        ) {
          return true;
        }
      }
      return false;
    },
    lastHasEntry() {
      return this.activePeriods.length !== 0 && this.activePeriods.every((ap) => ap.from || ap.to);
    },
  },
  created() {
    if (this.activePeriods.length === 0) {
      this.onAddPeriod();
    }
  },
  methods: {
    onAddPeriod() {
      this.$emit('add');
    },
    onRemovePeriod(index) {
      this.$emit('remove', index);
    },
    onFromDateChanged(index, entry) {
      this.$emit('fromDateChanged', { index, entry });
    },
    onToDateChanged(index, entry) {
      this.$emit('toDateChanged', { index, entry });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.add-extra {
  margin-left: 10px;
}
</style>
