<template>
  <form>
    <div v-for="(externalSource, index) in externalSources" :key="index" class="row">
      <div :class="{ col: true, s5: rightColumn, s4: !rightColumn }">
        <label v-if="index === 0 && label" class="label">{{ label }}</label>
        <select-input
          v-model="externalSource.source"
          :name="`other_external_sources_${index}`"
          :items="externalSourcesTypes"
          item-key="code"
          item-value="name"
          @input="onSelectExtSource(index)"
        />
      </div>

      <div :class="{ col: true, s6: rightColumn, s4: !rightColumn }">
        <validated-text-input
          v-model="externalSource.url"
          :name="`other_external_sources_url_${index}`"
          :label="addressLabel(index)"
          placeholder="E.g. www.discogs.com/artist/123"
          rule="required|url"
          :mandatory="true"
          :disabled="!externalSource.source"
          @validation="$emit('validation', $event)"
        />
        <delete-button
          v-if="externalSources.length > 1"
          :has-label="index === 0 && !rightColumn"
          @click="onRemoveExternalSource(index)"
        />
      </div>
    </div>

    <div class="row flush--bottom">
      <div class="col s12 edit-link">
        <a :class="{ disabled: !lastHasEntry }" @click="onAddExternalSource">
          <i class="far fa-plus-square" />
          Add external source
        </a>
      </div>
    </div>
  </form>
</template>

<script>
import ValidatedTextInput from './validated-text-input';
import SelectInput from '../select/select-input';
import DeleteButton from '../button/delete-button';

export default {
  name: 'ExternalSourcesInput',
  components: { ValidatedTextInput, SelectInput, DeleteButton },
  inject: ['$validator'],
  props: {
    name: String,
    label: {
      type: String,
      default: '',
    },
    scope: {
      type: String,
      default: '',
    },
    externalSources: {
      type: Array,
      required: true,
    },
    rightColumn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      externalSourcesTypes: [
        { name: 'AllMusic', code: 'allmusic' },
        { name: 'Discogs', code: 'discogs' },
        { name: 'iTunes', code: 'itunes' },
        { name: 'MetalArchives', code: 'metalarchives' },
        { name: 'MusicBrainz', code: 'musicbrainz' },
        { name: 'Popfakta', code: 'popfakta' },
        { name: 'Spotify', code: 'spotify' },
        { name: 'SR Sök', code: 'sr_sok' },
      ],
      tempUrls: [],
    };
  },
  computed: {
    lastHasEntry() {
      return this.externalSources.every((s) => s.source || s.url);
    },
  },
  methods: {
    onAddExternalSource(index) {
      this.$emit('add', index);
    },
    onRemoveExternalSource(index) {
      this.$emit('remove', index);
    },
    addressLabel(index) {
      return index === 0 && this.label ? 'Address' : '';
    },
    onSelectExtSource(index) {
      if (!this.externalSources[index].source) {
        this.tempUrls[index] = this.externalSources[index].url;
        this.externalSources[index].url = '';
      } else if (this.tempUrls[index]) {
        this.externalSources[index].url = this.tempUrls[index];
        this.tempUrls[index] = undefined;
      }
    },
  },
};
</script>
