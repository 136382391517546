<template>
  <div>
    <label v-if="showLabel" class="label">{{ label }}</label>
    <div v-for="(val, index) in value" :key="index" class="push--bottom">
      <select-input
        :name="`${name}.${index}`"
        :rule="rule"
        :value="val"
        :disabled="disabled"
        :items="items"
        :scope="scope"
        :letter-sequence-filter="letterSequenceFilter"
        :sorted="sorted"
        @input="updateItem(index, $event)"
      />
      <delete-button v-if="value.length > 1" @click="removeItem(index)" />
    </div>

    <div class="row edit-link flush--bottom">
      <a
        :class="{ disabled: !lastHasEntry }"
        :disabled="!lastHasEntry"
        @click="lastHasEntry ? appendItem() : null"
      >
        <i class="far fa-plus-square" />
        Add {{ label }}
      </a>
    </div>
  </div>
</template>

<script>
import DeleteButton from '../button/delete-button';
import SelectInput from './select-input';

export default {
  name: 'AddableSelectInput',
  components: {
    DeleteButton,
    SelectInput,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: undefined,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'code',
    },
    itemValue: {
      type: String,
      default: 'name',
    },
    value: {
      type: Array,
      required: true,
    },
    rule: {
      type: String,
      default: '',
    },
    scope: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sorted: {
      type: Boolean,
      default: false,
    },
    letterSequenceFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lastHasEntry() {
      return this.value.length === 0 || this.value.slice(-1)[0] !== null;
    },
  },
  created() {
    if (this.value.length === 0) {
      this.appendItem();
    }
  },
  methods: {
    getItemKey(item) {
      return this.itemKey ? item[this.itemKey] : item;
    },
    getItemValue(item) {
      return this.itemValue ? item[this.itemValue] : item;
    },
    appendItem() {
      const arr = this.value.slice();
      arr.push(null);
      this.emitArray(arr);
    },
    removeItem(index) {
      const arr = this.value.slice();
      arr.splice(index, 1);
      this.emitArray(arr);
    },
    updateItem(index, value) {
      const arr = this.value.slice();
      arr[index] = value;
      this.emitArray(arr);
    },
    emitArray(arr) {
      this.$emit('input', arr);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.col .delete-button {
  right: 5px;
  background-color: var(--white);
  z-index: 100;
}
select {
  margin-bottom: var(--spacing);
}
.add-extra {
  margin-top: 0;
}
</style>
