<template>
  <div>
    <div class="row">
      <h1>Member Files for {{ basicInfoName }}</h1>
    </div>

    <div v-if="loading" class="row">
      <div class="col s6">
        <Spinner />
      </div>
    </div>

    <div v-else class="row card">
      <table>
        <thead>
          <th v-for="(column, idx) in columns" :key="idx">
            {{ column.name }}
          </th>
        </thead>
        <tbody>
          <template v-if="memberFiles && memberFiles.length > 0">
            <template v-for="(file, idx) in memberFiles">
              <tr v-if="file.filename" :key="idx">
                <td>
                  <span>
                    <a @click="onDownloadFile(file)">
                      <i class="fas fa-download" />
                      {{ file.filename }} ({{ (file.size / 1000000).toFixed(2) }} MB)
                    </a>
                  </span>
                </td>
                <td>{{ $filters.formatTimestamp(file.lastModified) }}</td>
              </tr>
            </template>
          </template>
          <tr v-else>
            <td colspan="2" class="none">None</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Spinner from '../../spinner';
import ViewSocietyMixin from './view-society-mixin';
import RemoteFileService from '../../../services/remoteFileService';
import DownloadLink from '../../ui/file/downloadLink';

export default {
  name: 'Member',
  components: {
    Spinner,
  },
  mixins: [ViewSocietyMixin],
  data() {
    return {
      error: false,
      loading: true,
      memberFiles: [],
      columns: [{ name: 'File' }, { name: 'Generated Date' }],
    };
  },
  computed: {
    ...mapGetters('society', ['id', 'aggregate', 'basicInfoName', 'metaInfoCode']),
  },
  async created() {
    this.loading = true;
    await this.fetchData(this.$router.currentRoute.params.id);
    this.loading = false;
    await this.fetchMemberFiles();
  },
  methods: {
    async onDownloadFile(file) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(
        `${file.path}/${file.filename}`,
        'star-data',
      );
      DownloadLink.direct(fileData, file.filename);
    },
    async fetchMemberFiles() {
      try {
        this.loading = true;
        const memberFiles = await RemoteFileService.listDocumentRepository(
          `mandates/exported/${this.metaInfoCode}`,
          'star-data',
        );
        this.memberFiles = memberFiles.sort((a, b) => +b.lastModified - +a.lastModified);
      } catch (error) {
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped></style>
