<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>

    <div class="row">
      <div class="col s12">
        <TableOptions
          :worklists="worklists"
          :worklist-id="selectedWorklist"
          :range="range"
          @worklistChanged="onWorklistChanged"
          @rangeStartChanged="onRangeStartChanged"
          @rangeEndChanged="onRangeEndChanged"
        />
      </div>
    </div>

    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table>
          <CompareSort
            :bulk-enabled="false"
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareRecordingPlaylist
            ref="comparelist"
            :process-type="processType"
            :is-expanded="isExpanded"
            :toggle-expanded="toggleExpanded"
            :get-candidate-value="getCandidateValue"
            :get-match-state="getMatchState"
            :lineup-state-mask="lineupStateMask"
            :bulk-enabled="false"
            :action-enabled="false"
            @change="updateDirtyState"
            @rejectAll="completeAndContinue"
            @acceptAll="completeAndContinue"
          />
        </table>

        <pagination
          v-if="numberOfPages > 0"
          class="result__pagination"
          :number-of-pages="numberOfPages"
          :selected-page="pagination.currentPage"
          :number-of-hits="totalCount"
          :hits-per-page="pagination.hitsPerPage"
          @selectPage="selectPage"
          @updateHitsPerPage="updateHitsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CompareRecordingPlaylist from '../../../compare/compare-recording-playlist';
import CompareSort from '../../../compare/compare-sort';
import ViewPlaylistMixin from './view-playlist-mixin';
import Pagination from '../../../pagination';
import PaginationAndSortMixin from '../../../../common/paginationAndSortMixin';
import { playlistLineupStates } from '../../../../domain/matchingDomain';
import TableOptions from '../../../ui/table/table-options';
import TableOptionsMixin from '../../../../common/tableOptionsMixin';

export default {
  name: 'ViewPlaylistApplied',
  components: {
    CompareRecordingPlaylist,
    CompareSort,
    Pagination,
    TableOptions,
  },
  mixins: [PaginationAndSortMixin, ViewPlaylistMixin, TableOptionsMixin],
  data() {
    return {
      lineupStateMask: playlistLineupStates.APPLIED,
    };
  },
  activated() {
    const expandedId = this.viewPlaylistApplied.expandedId;
    this.loadPaginated().then(() => {
      if (expandedId) {
        this.toggleExpanded(expandedId);
      }
    });
  },
  deactivated() {
    this.updateViewPlaylistAppliedExpandedId(this.expandedRecordingId);
    this.setExpanded(undefined);
  },
};
</script>
<style lang="scss" scoped>
.flex {
  &__options {
    justify-content: space-between;
  }
  &__item {
    flex: 1;
    align-items: center;
  }
  &__text {
    margin-right: var(--spacing);
  }
  &__modifier {
    & + & {
      margin-left: var(--spacing);
    }
    div {
      width: 180px !important;
    }
  }
}
</style>
