<template>
  <tr :style="injectStyle">
    <td v-if="isBulkEnabled" class="thin-column">
      <input
        :id="itemId"
        v-model="internalChecked"
        class="filled-in"
        type="checkbox"
        :name="'chk_' + itemId"
        :disabled="bulkSuspend"
        @change="checkedChanged"
      />
      <label :for="itemId"></label>
    </td>
    <slot>This will only be displayed if there is no content to be distributed.</slot>
    <td v-if="optionsMenuEnabled" align="text--right" class="thin-column">
      <context-menu
        :options="['Edit', 'Remove']"
        @edit="onEditPerformer"
        @remove="onDeletePerformer"
      />
    </td>
  </tr>
</template>

<script>
import ContextMenu from '../../ui/context-menu';

export default {
  name: 'Row',
  components: {
    ContextMenu,
  },
  props: {
    itemId: { type: String },
    checked: { type: Boolean, default: false },
    bulkEnabled: { type: Boolean, default: true },
    bulkSuspend: { type: Boolean, default: false },
    optionsMenuEnabled: { type: Boolean, default: true },
    optionEditEnabled: { type: Boolean, default: true },
    injectStyle: { type: String, default: '' },
  },
  data() {
    return {
      internalChecked: Boolean(this.checked),
    };
  },
  computed: {
    isBulkEnabled() {
      return this.bulkEnabled;
    },
  },
  watch: {
    checked() {
      this.internalChecked = Boolean(this.checked);
    },
  },
  methods: {
    checkedChanged() {
      this.$emit('change', { id: this.itemId, checked: this.internalChecked });
    },
    onEditPerformer() {
      this.$emit('edit', this.itemId);
    },
    onDeletePerformer() {
      this.$emit('delete', this.itemId);
    },
  },
};
</script>
