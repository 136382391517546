<template>
  <form>
    <div class="row">
      <div class="class col s12">
        <p>
          Total amount:
          {{ $filters.formatAmountCent(payment.payableAmount + payment.payableVat, 'SEK') }}
          <span class="text--none">
            Last updated
            {{ $filters.dateFormat($filters.dateParse(payment.createdAt), 'YYYY-MM-DD') }}
          </span>
        </p>
      </div>
    </div>
    <div class="row card">
      <payment-node-list :payment="payment" />
    </div>
    <div class="row">
      <div class="col s12">
        <div class="float-right">
          <action-buttons
            submit-label="Approve"
            :disable-submit="!canApprove || isApproved"
            :show-abort="false"
            @save="onApprove()"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { validationErrorTypes } from '../../domain/paymentDomain';
import ActionButtons from '../ui/button/action-buttons';
import RoutesMixin from '../../common/routesMixin';
import PaymentNodeList from './payment-node-list';
import { mutate } from '../../services/apolloRequest';
import { paymentCommand } from '../../store/modules/payment/store';

export default {
  name: 'ProgressVerifyInformation',
  components: {
    ActionButtons,
    PaymentNodeList,
  },
  mixins: [RoutesMixin],
  props: {
    payment: Object,
  },
  data() {
    return {
      isApproved: false,
    };
  },
  computed: {
    paymentId() {
      return this.payment.id;
    },
    canApprove() {
      return this.payment.payableAmount > 0;
    },
    validationErrorTypes() {
      return validationErrorTypes;
    },
  },
  methods: {
    onSelectedItems(items) {
      console.log(items, 'selected items');
      this.selectedItems = items;
    },
    isPaymentExpanded() {
      return this.paymentExpanded;
    },
    toggleExpandedPayment() {
      this.paymentExpanded = !this.paymentExpanded;
    },
    updateRoute() {
      this.$router.replace({ path: this.$route.path, query: this.queryParams });
    },
    isExpandable(row) {
      return !!row.graphId;
    },
    isExpanded(idx) {
      return this.expanded.has(idx);
    },
    expand(idx) {
      this.expanded.add(idx);
    },
    contractAll() {
      this.expanded.clear();
    },
    async onApprove() {
      try {
        const id = this.payment.id;
        this.isApproved = true;
        await mutate(
          paymentCommand({
            stream_id: id,
            type: 'payment/user-approve',
            payload: {},
          }),
          { pickProp: 'handleCommand' },
        );
        this.$emit('approved');
      } catch (e) {
        this.$addStarError(e);
      }
    },
  },
};
</script>
