import { generateMutations, generateGetters } from '../../utils';
import * as domain from '../../../domain/distributionDomain';

// The root, initial state object
const rootState = domain.createDistributionChannel();

const actions = {
  createAggregate(context) {
    context.commit('CREATE_AGGREGATE');
  },
  setAggregate(context, aggregate) {
    context.commit('SET_AGGREGATE', aggregate);
  },
};

const mutations = {
  ...generateMutations(rootState),
  CREATE_AGGREGATE(state) {
    Object.assign(state, domain.createDistributionChannel());
  },
  SET_AGGREGATE(state, aggregate) {
    Object.assign(state, aggregate);
  },
};

const getters = {
  ...generateGetters(rootState),
  aggregate: (state) => state,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
