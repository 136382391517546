<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else class="row">
      <div class="col s12 xl9 recording-wrapper">
        <h1>
          {{ basicInfoName }}
          <span v-if="aggregate.deleted" class="text--italic">
            (deleted by {{ aggregate.deletedBy }})
          </span>
        </h1>
        <h2>
          <span v-if="basicInfoVersionTitle">({{ basicInfoVersionTitle }})</span>
          <span v-if="!basicInfoMainArtist">(main artist missing)</span>
          <span v-else>
            <span v-if="basicInfoMainArtist.id">
              by
              <router-link :to="'/mainartists/' + basicInfoMainArtist.id">
                {{ basicInfoMainArtist.name }}
              </router-link>
            </span>
            <span v-else>by {{ basicInfoMainArtist.name }}</span>
          </span>
        </h2>
        <version
          :version="routeVersion()"
          :readonly="aggregate.deleted"
          entity-type="recording"
          view-name="recordingInformation"
        />

        <div class="e2e-view-recording__information">
          <edit-basic-information v-if="isEditMode('BasicInformation')" @cancel="onCancel" />

          <basic-information v-else @edit="onEdit" />

          <lineup
            :disabled="routeVersion() !== null || aggregate.deleted || lineupLocked"
            :lineup-complete="distributionStateStatus === 'Green'"
            :lineup-locked="lineupLocked"
            @update="onLineupUpdate"
            @toggleLock="toggleLock"
          />

          <albums />
        </div>
      </div>
      <div class="col s12 xl3">
        <right-column
          :editable-component="editableComponent"
          :distribution-state-status="distributionStateStatus"
          :pre-selected-tab="routeVersion() ? 'activity' : 'general'"
          :recording-deleted="aggregate.deleted || routeVersion() !== null"
          @onEdit="onEdit"
          @onCancel="onCancel"
          @onStatusChange="onStatusChange"
          @onVrdb2Enqueue="onVrdb2Enqueue"
          @addToRedist="addToRedist"
          @deleteRecording="showDeleteModal"
        />
      </div>
    </div>

    <modal
      v-if="deleteModalShown"
      submit-label="Delete"
      :disable-submit="!canDelete.canDelete"
      :show-confirm-check-box="canDelete.canDelete"
      confirm-message="I confirm deletion of recording. This action is not reversible!"
      action-button-class="red"
      @close="deleteModalShown = false"
      @cancel="deleteModalShown = false"
      @save="onDeleteRecording()"
    >
      <h1 slot="header">Delete Recording?</h1>
      <div v-if="canDelete.reasons.length > 0" slot="body">
        <div v-for="reason in canDelete.reasons" :key="reason" class="row margin--bottom">
          <span class="is-danger float-left">
            <i class="fas fa-times-circle" />
            {{ reason }}
          </span>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import { dispatch } from '../../../store/modules/recording/utils';
import Albums from './../albums';
import RecordingMixin from './recording-mixin';
import RecordingService from '../../../services/recordingService';
import RightColumn from '../right-column';
import BasicInformation from './basic-information';
import EditBasicInformation from './../edit/edit-basic-information';
import Lineup from '../lineup/lineup';
import Version from '../../ui/version';
import Modal from '../../ui/dialog/modal';
import AuthenticationService from '../../../services/authenticationService';

export default {
  name: 'RecordingInformationView',
  components: {
    Modal,
    Albums,
    RightColumn,
    BasicInformation,
    EditBasicInformation,
    Lineup,
    Version,
  },
  mixins: [RecordingMixin],
  data() {
    return {
      editableComponent: '',
      deleteModalShown: false,
      canDelete: { canDelete: false, reasons: [] },
    };
  },
  computed: {
    ...mapGetters('recording', [
      'id',
      'version',
      // 'aggregate',
      'basicInfoName',
      'basicInfoVersionTitle',
      'basicInfoMainArtist',
      'distributionStateStatus',
      'lineupLocked',
    ]),
    aggregate() {
      return this.$store.state.recording;
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  errorCaptured(error) {
    error.title = 'Could not update information';
    this.$addStarError(error);
  },
  methods: {
    ...mapMutations('recording', ['updateDistributionStateStatus', 'updateLineupLocked']),
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onLineupUpdate() {
      this.fetchData();
    },
    async toggleLock() {
      this.updateLineupLocked(!this.lineupLocked);
      this.loading = true;
      try {
        await RecordingService.updateLineupLocked(this.id, this.lineupLocked);
      } catch (error) {
        error.title = 'Could not update lineup locked';
        this.updateLineupLocked(!this.lineupLocked);
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    onEdit(view) {
      this.editableComponent = view;
    },
    async onStatusChange(status) {
      this.loading = true;
      const currentStatus = this.distributionStateStatus;
      this.updateDistributionStateStatus(status);
      try {
        await RecordingService.updateRecordingDistributionState(_.cloneDeep(this.aggregate));
        const aggregate = await this.loadAggregate(RecordingService.getRecording, this.id);
        dispatch('setRecordingAggregate', aggregate);
        this.editableComponent = '';
      } catch (error) {
        error.title = 'Could not set status';
        this.updateDistributionStateStatus(currentStatus);
        this.$addStarError(error);
      } finally {
        this.loading = false;
      }
    },
    async onCancel() {
      this.editableComponent = '';
    },
    async onVrdb2Enqueue() {
      this.errorIfForbidden(async () => {
        await RecordingService.vrdb2EnqueueRecording(this.aggregate);
        await this.fetchData();
      }, 'Cannot enqueue recording to VRDB.');
    },
    async addToRedist() {
      this.errorIfForbidden(async () => {
        console.log('add to redist');
        await this.$store.dispatch('distribution/addRecordingToQueue', this.id);
      }, 'Cannot redistribute recording.');
    },
    async showDeleteModal() {
      this.loading = true;
      this.canDelete = await RecordingService.canDeleteRecording(this.id);
      this.loading = false;
      this.deleteModalShown = true;
    },
    async onDeleteRecording() {
      this.loading = true;
      const response = await RecordingService.deleteRecording(this.id);
      if (response && response.length === 1 && response[0].error) {
        const error = new Error();
        error.title = response[0].error.message;
        this.$addStarError(error);
      } else {
        this.$store.commit('recording/SET_DELETE_AGGREGATE', AuthenticationService.getUserName());
      }
      this.loading = false;
      this.deleteModalShown = false;
    },
    errorIfForbidden(fun, title) {
      if (this.aggregate.deleted) {
        const error = new Error();
        error.title = title + ` Deleted by: ${this.aggregate.deletedBy}.`;
        this.$addStarError(error);
      } else if (this.routeVersion() !== null) {
        const error = new Error();
        error.title = title + ` Not current version.`;
        this.$addStarError(error);
      } else {
        fun();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recording-wrapper {
  padding-right: 24px;
}
</style>
