<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s6">
        <spinner />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div class="col s3 float-right">
          <filter-input
            :filter-query="filterQuery"
            label="Albums"
            placeholder="E.g. Du & jag döden"
            @input="filterAlbums"
          />
        </div>
        <div class="col s3 float-right" style="margin-right: 8px">
          <filter-input
            :filter-query="mainArtist"
            label="Main artist"
            placeholder="E.g. Joakim Berg"
            @input="filterMainArtist"
          />
        </div>
      </div>
      <div class="row card">
        <div class="row">
          <table>
            <sortable-head
              :bulk-enabled="false"
              :columns="columns"
              :more-options="false"
              :default-sort-column-index="0"
              @sort="clickSort"
            />
            <tbody>
              <template v-for="album in albums">
                <tr :key="`${album.id}-tr1`">
                  <td
                    class="thin-column dropdown-button__arrow"
                    @click="toggleDetails(album.id, $event)"
                  >
                    <i :class="`fas fa-caret-${expandedAlbum === album.id ? 'up' : 'down'}`" />
                  </td>
                  <td>
                    <router-link :to="'/albums/' + album.id">
                      {{ album.name }}
                    </router-link>
                  </td>
                  <td v-if="type === AGGREGATE_TYPES.PERFORMER">
                    <span v-if="album.main_artist">
                      <router-link :to="'/mainartists/' + album.main_artist.id">
                        {{ album.main_artist.name }}
                      </router-link>
                    </span>
                    <span v-else class="none">None</span>
                  </td>
                  <td>
                    <span v-if="album.catalog_number">{{ album.catalog_number }}</span>
                    <span v-else class="none">None</span>
                  </td>
                  <td>
                    <span v-if="album.release_date">{{ album.release_date }}</span>
                    <span v-else class="none">None</span>
                  </td>
                  <td>
                    <span v-if="album.label">{{ album.label }}</span>
                    <span v-else class="none">None</span>
                  </td>
                </tr>
                <tr v-if="expandedAlbum === album.id" :key="`${album.id}-tr2`" class="expand-info">
                  <td class="thin-column" />
                  <td>
                    <span class="expand-info__header">Tracks</span>
                    <simple-spinner v-if="currentTracks.length === 0" />
                    <div v-for="track in currentTracks" v-else :key="track.recording.id">
                      <router-link :to="'/recordings/' + track.recording.id">
                        {{ track.recordingAggregate.basic_info.name }}
                        {{ track.recordingAggregate.lineup_locked ? ' 🔒' : '' }}
                      </router-link>
                      by
                      <router-link
                        :to="'/mainartists/' + track.recordingAggregate.basic_info.main_artist.id"
                      >
                        {{ track.recordingAggregate.basic_info.main_artist.name }}
                      </router-link>
                      <span v-if="track.recordingAggregate.basic_info.duration_sec > 0">
                        -
                        {{ $filters.toMinutes(track.recordingAggregate.basic_info.duration_sec) }}
                      </span>
                    </div>
                  </td>
                  <td />
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div>
          <pagination
            v-if="numberOfPages > 0"
            topic="discography"
            :number-of-pages="numberOfPages"
            :selected-page="page"
            :number-of-hits="totalAlbums"
            :hits-per-page="pageSize"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
      <template v-if="numberOfPages === 0">
        <div class="no-result">0 albums found</div>
      </template>
    </div>
  </div>
</template>

<script>
import { AGGREGATE_TYPES } from '../../domain/common';
import AlbumService from '../../services/albumService';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';
import FilterInput from '../ui/input/filter-input';
import Pagination from '../pagination';
import Spinner from '../spinner';
import SortableHead from '../ui/table/sortable-head';
import SimpleSpinner from '@/components/ui/simple-spinner.vue';

export default {
  name: 'ViewDiscographyAlbums',
  components: {
    SimpleSpinner,
    Spinner,
    SortableHead,
    Pagination,
    FilterInput,
  },
  mixins: [DebouncedSearchMixin(400)],
  props: {
    type: { type: String },
  },
  data() {
    return {
      AGGREGATE_TYPES,
      loading: true,
      filterQuery: '',
      mainArtist: '',
      albums: [],
      currentTracks: [],
      pageSize: 25,
      totalAlbums: 0,
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Album',
          ascending: true,
          sortParam: 'name.keyword',
          active: true,
        },
        {
          name: 'Catalogue No.',
          ascending: true,
          sortParam: 'catalog_number.keyword',
          active: false,
        },
        {
          name: 'Release Date',
          ascending: true,
          sortParam: 'release_date',
          active: false,
        },
        {
          name: 'Label',
          ascending: true,
          sortParam: 'label.keyword',
          active: false,
        },
      ],
      expandedAlbum: '',
      page: 1,
      sortColumn: 'name.keyword',
      sortOrder: 'asc',
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.totalAlbums / this.pageSize);
    },
  },
  watch: {
    async $route() {
      const performerId = this.$router.currentRoute.params.id;
      await this.fetchData(performerId);
    },
  },
  created() {
    if (this.type === AGGREGATE_TYPES.PERFORMER) {
      const mainArtistColumn = {
        name: 'Main artist',
        ascending: true,
        sortParam: 'main_artist.name.keyword',
        active: false,
      };
      this.columns.splice(2, 0, mainArtistColumn);
    }
    this.fetchData(this.$router.currentRoute.params.id);
  },
  methods: {
    async fetchData(id) {
      this.loading = true;

      if (this.$router.currentRoute.query) {
        this.page = Number(this.$router.currentRoute.query.page) || 1;
        this.pageSize = Number(this.$router.currentRoute.query.pageSize) || 25;
        this.sortColumn = this.$router.currentRoute.query.sortColumn;
        this.sortOrder = this.$router.currentRoute.query.sortOrder;
        this.filterQuery = this.$router.currentRoute.query.filter;
        this.mainArtist = this.$router.currentRoute.query.mainArtist;
      }

      const options = {
        filterQuery: this.filterQuery,
        mainArtist: this.mainArtist,
        pageFrom: (this.page - 1) * this.pageSize,
        pageSize: this.pageSize,
        sortField: this.sortColumn,
        sortOrder: this.sortOrder,
      };

      try {
        const result = await AlbumService.getDiscographyAlbums(this.type, id, options);
        this.handleResult(result);
      } catch (error) {
        console.log('Error fetching discography albums', error);
        this.loading = false;
      }
    },
    handleResult(result) {
      this.totalAlbums = result.total;
      this.albums = result.albums;
      this.loading = false;
      if (this.$route.query.page) {
        this.page = Number(this.$route.query.page);
      }
      if (this.$route.query.pageSize) {
        this.pageSize = Number(this.$route.query.pageSize);
      }
      this.markSorting();
    },
    markSorting() {
      this.columns.forEach((column) => {
        if (column.sortParam === this.sortColumn) {
          column.active = true;
          column.ascending = this.sortOrder === 'asc';
        } else {
          column.active = false;
          column.ascending = true;
        }
      });
    },
    filterAlbums(query) {
      this.filterQuery = query;
      this.debouncedSearch(query, this.filterQuery);
    },
    filterMainArtist(query) {
      this.mainArtist = query;
      this.debouncedSearch(query, this.mainArtist);
    },
    search() {
      this.selectPage(1);
      this.updateRouterSearchParams();
    },
    clickSort(sort) {
      this.sort(sort.sortParam, sort.ascending ? 'asc' : 'desc');
    },
    sort(sortColumn, sortOrder) {
      this.sortColumn = sortColumn;
      this.sortOrder = sortOrder;
      this.updateRouterSearchParams();
    },
    toggleDetails(id, e) {
      const row = e.target.closest('tr');
      this.currentTracks = [];
      this.expandedAlbum = this.expandedAlbum === id ? '' : id;
      if (this.expandedAlbum) {
        const performerId = this.$router.currentRoute.params.id;
        row.style.whiteSpace = 'normal';
        AlbumService.getTracksByAlbum(id).then((tracks) => {
          this.currentTracks = tracks.filter((t) =>
            new Set(t.recordingAggregate.lineup.map((l) => l.relation.id)).has(performerId),
          );
        });
      } else {
        row.style.whiteSpace = 'nowrap';
      }
    },
    selectPage: function selectNumber(selectedPage) {
      this.page = selectedPage;
      this.updateRouterSearchParams();
    },
    updateRoute(id, queryParams) {
      this.$router.replace({
        name:
          this.type === AGGREGATE_TYPES.MAINARTIST
            ? 'discographyMainArtistAlbums'
            : 'discographyPerformerAlbums',
        params: { id },
        query: queryParams,
      });
    },
    updateHitsPerPage: function updateHitsPerPage(hitsPerPage) {
      this.pageSize = Number(hitsPerPage);
      this.selectPage(1);
    },
    updateRouterSearchParams() {
      const queryParams = {};
      const performerId = this.$router.currentRoute.params.id;

      if (this.page > 1) {
        queryParams.page = this.page;
      }

      if (this.pageSize !== 25) {
        queryParams.pageSize = this.pageSize;
      }

      if (this.sortColumn) {
        queryParams.sortColumn = this.sortColumn;
        queryParams.sortOrder = this.sortOrder;
      }

      if (this.filterQuery) {
        if (this.filterQuery !== this.$route.query.filter) {
          this.page = 1;
          queryParams.page = 1;
        }
        queryParams.filter = this.filterQuery;
      }

      if (this.mainArtist) {
        if (this.mainArtist !== this.$route.query.mainArtist) {
          this.page = 1;
          queryParams.page = 1;
        }
        queryParams.mainArtist = this.mainArtist;
      }
      this.updateRoute(performerId, queryParams);
    },
  },
};
</script>
