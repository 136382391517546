<template>
  <div>
    <button
      v-if="showAbort"
      class="btn tertiary"
      type="button"
      @click="cancel"
      @keyup.enter="cancel"
    >
      {{ abortLabel }}
    </button>
    <button
      class="btn"
      :class="{ 'margin-t5': stacked, [buttonClass]: buttonClass }"
      type="button"
      :disabled="disableSubmit"
      @click="submit"
      @keyup.enter="submit"
    >
      {{ submitLabel }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ActionButtons',
  inject: ['$validator'],
  props: {
    buttonClass: {
      type: String,
      default: undefined,
    },
    submitLabel: {
      type: String,
      default: 'Save',
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    abortLabel: {
      type: String,
      default: 'Cancel',
    },
    showAbort: {
      type: Boolean,
      default: true,
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    scope: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    submit() {
      const promise = this.scope
        ? this.$validator.validate(`${this.scope}.*`)
        : this.$validator.validate();
      promise.then((success) => {
        if (success && !this.validationErrors.any(`${this.scope}`)) {
          this.$emit('save');
        } else {
          console.log('There are errors', JSON.stringify(this.validationErrors));
        }
      });
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
