<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Mandate</h2>
      </div>
      <div class="col s6 text--right">
        <template v-if="editMode">
          <action-buttons @save="onSave" @cancel="onCancel" />
        </template>
      </div>
    </div>

    <form data-vv-scope="create-mandate-information">
      <div class="row card">
        <table>
          <tbody>
            <tr class="mandate-group">
              <td
                class="thin-column dropdown-button__arrow"
                @click="defaultMandateExpanded = !defaultMandateExpanded"
              >
                <i :class="`fas fa-caret-${defaultMandateExpanded ? 'up' : 'down'}`" />
              </td>
              <td class="text--bold" colspan="4">All rights</td>
            </tr>
            <tr v-if="defaultMandateExpanded" class="expand-info">
              <td class="thin-column" />
              <td colspan="4">
                <template v-for="(mandateGroup, index) in localMandateGroups">
                  <create-mandate
                    v-if="mandateGroup.right === MANDATE_DEFAULT"
                    :key="`mandate-${index}`"
                    :mandates="mandateGroup.mandates"
                    :right-type="mandateGroup.right"
                    :edit-mode="editMode"
                    @updateSocietyCode="onUpdateSocietyCode(mandateGroup.right, $event)"
                    @updateMandateType="onUpdateMandateType(mandateGroup.right, $event)"
                    @updateStartDate="onUpdateStartDate(mandateGroup.right, $event)"
                    @updateEndDate="onUpdateEndDate(mandateGroup.right, $event)"
                    @addMandate="onAddMandate(mandateGroup.right)"
                    @removeMandate="onShowRemoveModal(mandateGroup.right, $event)"
                    @addTerritory="onAddTerritory(mandateGroup.right, $event)"
                    @removeTerritory="onRemoveTerritory(mandateGroup.right, $event)"
                  />
                </template>
              </td>
            </tr>
            <tr class="mandate-group">
              <td
                class="thin-column dropdown-button__arrow"
                @click="customizeMandatesExpanded = !customizeMandatesExpanded"
              >
                <i :class="`fas fa-caret-${customizeMandatesExpanded ? 'up' : 'down'}`" />
              </td>
              <td class="text--bold" colspan="4">Customize rights</td>
            </tr>
            <template v-if="customizeMandatesExpanded">
              <template v-for="(mandateGroup, index) in localMandateGroups">
                <tr v-if="mandateGroup.right !== MANDATE_DEFAULT" :key="`tr1-${index}`">
                  <td
                    class="thin-column dropdown-button__arrow"
                    @click="toggleDetails(mandateGroup.right)"
                  >
                    <i
                      :class="`fas fa-caret-${rightExpanded(mandateGroup.right) ? 'up' : 'down'}`"
                    />
                  </td>
                  <td colspan="4">
                    {{ rightName(mandateGroup.right) }}
                  </td>
                </tr>
                <tr
                  v-if="rightExpanded(mandateGroup.right)"
                  :key="`tr2-${index}`"
                  class="expand-info"
                >
                  <td />
                  <td colspan="4">
                    <create-mandate
                      :mandates="mandateGroup.mandates"
                      :right-type="mandateGroup.right"
                      :edit-mode="editMode"
                      @updateSocietyCode="onUpdateSocietyCode(mandateGroup.right, $event)"
                      @updateMandateType="onUpdateMandateType(mandateGroup.right, $event)"
                      @updateStartDate="onUpdateStartDate(mandateGroup.right, $event)"
                      @updateEndDate="onUpdateEndDate(mandateGroup.right, $event)"
                      @addMandate="onAddMandate(mandateGroup.right)"
                      @removeMandate="onShowRemoveModal(mandateGroup.right, $event)"
                      @addTerritory="onAddTerritory(mandateGroup.right, $event)"
                      @removeTerritory="onRemoveTerritory(mandateGroup.right, $event)"
                    />
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </form>

    <modal
      v-if="showRemoveModal"
      @save="onRemoveMandate()"
      @close="onCancelRemoveMandate()"
      @cancel="onCancelRemoveMandate()"
    >
      <h1 slot="header">Remove mandate?</h1>
      <div slot="body">Are you sure you want to remove this mandate?</div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { commit } from '../../../../store/modules/performer/utils';
import CreateMandate from './create-mandate';
import EditHelper from '../edit/editHelper';
import ActionButtons from '../../../ui/button/action-buttons';
import Modal from '../../../ui/dialog/modal';
import {
  createMandate,
  createMandateGroup,
  MANDATE_DEFAULT,
  MANDATE_SAMI_SOCIETY_CODE,
} from '../../../../domain/performerDomain';
import delay from '@/common/delay';
import PnrService from '@/services/pnrService';
import {
  createUpdateMandateInfoCommand,
  createCancelMandatesCommand,
} from '@/domain/performerDomain';

export default {
  name: 'CreateMandateInformation',
  components: {
    CreateMandate,
    ActionButtons,
    Modal,
  },
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    createMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultMandateExpanded: true,
      customizeMandatesExpanded: false,
      expandedRightCodes: [],
      MANDATE_DEFAULT,
      MANDATE_SAMI_SOCIETY_CODE,
      localMandateGroups: [],
      activeSamiMembership: false,
      mandatesToRemove: [],
      mandateToRemove: null,
      showRemoveModal: false,
    };
  },
  computed: {
    ...mapState('performer', {
      mandates: (state) => state.mandates,
    }),
    mandateRights() {
      return this.$store.state.appdata.referenceData.mandateRights;
    },
  },
  watch: {
    mandates() {
      if (this.createMode) {
        this.expandMandates();
        this.addMandateGroups();
        this.localMandateGroups = this.mandates;
      }
    },
  },
  created() {
    if (this.editMode) {
      this.expandMandates();
    }

    this.addMandateGroups();
    this.localMandateGroups = this.mandates;
  },
  methods: {
    ...mapMutations('performer', [
      'addMandateGroup',
      'addMandate',
      'removeMandateAt',
      'updateSocietyCode',
      'updateMandateType',
      'updateStartDate',
      'updateEndDate',
      'addTerritory',
      'removeTerritoryAt',
      'clearTerritories',
    ]),
    addMandateGroups() {
      this.mandateRights.forEach((mandateRight) => {
        if (!this.mandates.find((group) => group.right === mandateRight.code)) {
          this.addMandateGroup(createMandateGroup(mandateRight.code));
        }
        if (!this.mandates.find((group) => group.right === MANDATE_DEFAULT)) {
          this.addMandateGroup(createMandateGroup(MANDATE_DEFAULT));
        }
      });
    },
    expandMandates() {
      this.customizeMandatesExpanded =
        this.mandates &&
        this.mandates.find(
          (group) => group.right !== MANDATE_DEFAULT && group.mandates[0].id !== -1,
        );
      this.mandates.forEach((group) =>
        group.right !== MANDATE_DEFAULT && group.mandates[0].id !== -1
          ? this.expandedRightCodes.push(group.right)
          : null,
      );
    },
    rightName(code) {
      return this.mandateRights.find((right) => right.code === code).name;
    },
    rightExpanded(code) {
      return _.includes(this.expandedRightCodes, code);
    },
    toggleDetails(code) {
      const index = _.indexOf(this.expandedRightCodes, code);
      if (index === -1) {
        this.expandedRightCodes.push(code);
      } else {
        this.expandedRightCodes.splice(index, 1);
      }
    },
    onAddMandate(type) {
      const mandateData = { type, mandate: createMandate(type) };
      commit('addMandate', mandateData);
    },
    onShowRemoveModal(type, event) {
      this.mandateToRemove = { type, event };
      this.showRemoveModal = true;
    },
    onCancelRemoveMandate() {
      this.mandateToRemove = null;
      this.showRemoveModal = false;
    },
    onRemoveMandate() {
      this.showRemoveModal = false;
      const { type, event } = this.mandateToRemove;

      commit('removeMandateAt', { type, mandateIdx: event.mandateIdx });
      if (this.localMandateGroups.find((group) => group.right === type).mandates.length === 0) {
        this.onAddMandate(type);
      }
      if (event.id > 0) {
        this.mandatesToRemove.push(event.id);
      }
      this.mandateToRemove = null;
    },
    onUpdateSocietyCode(type, event) {
      const mandateData = { type, mandateIdx: event.mandateIdx, societyCode: event.societyCode };
      commit('updateSocietyCode', mandateData);
    },
    onUpdateMandateType(type, event) {
      const clearData = { type, mandateIdx: event.mandateIdx };
      commit('clearTerritories', clearData);
      const mandateData = { type, mandateIdx: event.mandateIdx, mandateType: event.mandateType };
      commit('updateMandateType', mandateData);
    },
    onUpdateStartDate(type, event) {
      const mandateData = { type, mandateIdx: event.mandateIdx, startDate: event.startDate };
      commit('updateStartDate', mandateData);
    },
    onUpdateEndDate(type, event) {
      const mandateData = { type, mandateIdx: event.mandateIdx, endDate: event.endDate };
      commit('updateEndDate', mandateData);
    },
    onAddTerritory(type, event) {
      const mandateData = { type, mandateIdx: event.mandateIdx, iso: event.territory.iso };
      commit('addTerritory', mandateData);
    },
    onRemoveTerritory(type, event) {
      const mandateData = { type, mandateIdx: event.mandateIdx, territoryIdx: event.territoryIdx };
      commit('removeTerritoryAt', mandateData);
    },
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    async onSave() {
      const performer = EditHelper.getCurrentPerformer();
      const commands = [];

      if (this.mandatesToRemove.length) {
        commands.push(
          PnrService.prepareCommand(performer, createCancelMandatesCommand(), {
            ids: [...new Set(this.mandatesToRemove)],
          }),
        );
      }

      const mandatesToEdit = this.mandates
        .flatMap((group) => group.mandates)
        .filter((mandate) => mandate.society_code && !this.mandatesToRemove.includes(mandate.id))
        .map(({ conflicts, ...mandate }) => mandate);

      if (mandatesToEdit.length) {
        commands.push(
          PnrService.prepareCommand(performer, createUpdateMandateInfoCommand(), {
            mandates: mandatesToEdit,
          }),
        );
      }

      await PnrService.postCommands(
        { errorTitle: 'Could not update mandate information' },
        commands,
      );

      await delay(500);
      this.$emit('saved');
    },
  },
};
</script>

<style lang="scss" scoped>
.mandate-group {
  background-color: var(--grey--vlight);
  td {
    &::before {
      box-shadow: none;
    }
  }
}
table {
  margin-bottom: 16px;
}
</style>
