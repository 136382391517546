import Vue from 'vue';
import _ from 'lodash';
import { AGGREGATE_TYPES } from '../domain/common';
import { getConfigValue, PROPS } from './configService';
import AuthenticationService from './authenticationService';
import CreateUploadFileDescriptor from '../domain/uploadFileDescriptor';
import RemoteFileService from '../services/remoteFileService';

const headers = { 'content-type': 'application/json' };

function mapNote(note) {
  return {
    entityId: note.entity_id,
    timestamp: +note.timestamp,
    noteText: decodeURI(note.note_text),
    pinned: note.pinned === 'true',
    user: note.user,
    attachments: note.attachments,
  };
}

export async function queryNotes(id) {
  const result = await Vue.$http.get(`${getConfigValue(PROPS.NOTES_URL)}/${id}`);
  return _.map(result.data.notes, mapNote);
}

export async function saveNote(note) {
  const data = JSON.stringify([
    {
      entity_id: note.entityId,
      timestamp: note.timestamp || new Date().getTime(),
      note_text: encodeURI(note.noteText),
      pinned: note.pinned === true,
      deleted: note.deleted === true,
      user: note.user || AuthenticationService.getUserName(),
      attachments: note.attachments || [],
    },
  ]);
  const result = await Vue.$http.post(getConfigValue(PROPS.NOTES_URL), data, { headers });
  return result.data;
}

export async function storeNoteAttachments(files, entityId) {
  const fileNames = [];
  const promises = files.map(async (file) => {
    const entityType = `${AGGREGATE_TYPES.NOTE}`;
    const metaIdentifiers = [file.name];
    const fileDescriptor = CreateUploadFileDescriptor(entityType, entityId, metaIdentifiers, file);
    const fileName = await RemoteFileService.uploadToDocumentRepository(fileDescriptor);
    fileNames.push(fileName);
    return true;
  });
  await Promise.all(promises);
  return fileNames;
}
