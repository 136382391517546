<template>
  <div class="cmp">
    <div class="row">
      <div class="col s2 hide-on-med-and-down">
        <entity-tag />
      </div>
      <div class="col s12 l7">
        <div class="row">
          <div class="col s12">
            <h1>Create Recording</h1>
          </div>
        </div>
        <form :data-vv-scope="scope">
          <create-menu entity-type="recording" @create="createRecordingAndNavigateToView" />

          <create-basic-info :scope="scope" />
          <add-performer-form
            :display-action-buttons="false"
            :always-open="true"
            @update="updateLineup"
          />
          <create-new-performers />
          <add-mainartist-performers />
          <add-existing-albums :scope="scope" />
          <create-new-albums />

          <div class="card">
            <create-tags :scope="scope" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { dispatch as vuexDispatch, getAggregate } from '../../../store/modules/recording/utils';
import delay from '../../../common/delay';
import AddExistingAlbums from './add-existing-albums';
import AddPerformerForm from '../lineup/add-performer-form';
import AddMainartistPerformers from './add-mainartist-performers';
import EntityTag from '../../ui/entity-tag';
import clone from '../../../common/clone';
import CreateBasicInfo from './create-basic-info';
import CreateNewPerformers from './create-new-performers';
import CreateNewAlbums from './create-new-albums';
import CreateMenu from '../../ui/create-menu';
import CreateTags from './create-tags';
import RecordingService from '../../../services/recordingService';

export default {
  name: 'CreateRecording',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    AddExistingAlbums,
    AddPerformerForm,
    AddMainartistPerformers,
    EntityTag,
    CreateBasicInfo,
    CreateMenu,
    CreateNewAlbums,
    CreateNewPerformers,
    CreateTags,
  },
  data() {
    return {
      error: false,
      scope: 'create-recording',
    };
  },
  created: function created() {
    vuexDispatch('createAggregate', {});
  },
  methods: {
    ...mapMutations('recording', ['updateLineup']),
    async createRecordingAndNavigateToView() {
      this.error = false;
      this.$starContentLoading(true);
      try {
        const aggregate = clone(getAggregate('recording'));
        const id = await RecordingService.createRecording(aggregate);
        await delay(1000);
        this.$router.push({
          name: 'recordingView',
          params: { id },
        });
      } catch (error) {
        error.title = 'Ooops!';
        this.$addStarError(error);
      }
      this.$starContentLoading(false);
    },
  },
};
</script>
