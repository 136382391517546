<template>
  <div>
    <div class="cmp">
      <div class="row">
        <div class="col s12 l1">
          <div class="left-content-column">
            <entity-navigation :views="navigationViews" />
          </div>
        </div>
        <div class="col s12 l11 view-layout">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import { setAggregate, createAggregate } from '../../../store/modules/distribution/utils';
import { DistributionState, createDistribution } from '../../../domain/distributionDomain';
import DistributionService from '../../../services/distributionService';
import EntityNavigation from '../../entity-navigation';

export default {
  name: 'DistributeYear',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    EntityNavigation,
  },
  beforeRouteLeave(_to, _from, next) {
    createAggregate();
    next();
  },
  data() {
    return {
      DistributionState,
      yearId: this.$router.currentRoute.params.yearId,
      year: this.$router.currentRoute.params.year,
      distributionId: this.$router.currentRoute.params.distributionId,
      error: false,
    };
  },
  computed: {
    ...mapGetters('distribution', [
      'aggregateHeaderName',
      'aggregateHeaderState',
      'aggregateHeaderOpenMonth',
      'aggregateDistributions',
      'selectedDistributionId',
      'selectedDistribution',
    ]),
    isIssuesView() {
      return this.$route.name === 'distributionIssuesView';
    },
    closedDistributions() {
      return this.aggregateDistributions.filter((dist) => dist.state !== 'OPEN');
    },
    openDistribution() {
      return this.aggregateDistributions.find((dist) => dist.state === 'OPEN');
    },
    navigationViews() {
      const arr = [];
      const params = { year: this.year, yearId: this.yearId };

      if (this.aggregateDistributions.length === 0) {
        // No distributions yet
        arr.push({
          name: 'distributionCalculateView',
          title: 'Distribution area',
          params,
          exactActiveRoute: true,
        });
      } else {
        // Existing distributions
        const sortedDistributions = _.reverse(_.sortBy(this.aggregateDistributions, 'state'));
        sortedDistributions.forEach((distribution) => {
          arr.push({
            name: 'distributionCalculateIdView',
            title: this.name(distribution),
            params: Object.assign({}, params, { distributionId: distribution.id }),
          });
        });
      }

      // Issues
      arr.push({
        name: 'distributionIssuesView',
        title: 'Errors',
        params,
      });
      return arr;
    },
  },
  watch: {
    async $route() {
      this.distributionId = this.$router.currentRoute.params.distributionId;
      if (
        !this.distributionId &&
        this.selectedDistribution &&
        this.selectedDistribution.id != null
      ) {
        this.updateSelectedDistribution(createDistribution());
      }
    },
  },
  created() {
    createAggregate();
    this.loadDistributions();
  },
  methods: {
    ...mapMutations('distribution', ['updateSelectedDistribution']),
    name(distribution) {
      let name = '';
      if (distribution && distribution.name) {
        name = distribution.name;
      } else {
        name = `${moment.months(distribution.from_month)} - ${
          Number.isInteger(distribution.to_month) ? moment.months(distribution.to_month) : ''
        }`;
      }
      if (distribution.state === DistributionState.CLOSED) {
        name = `${name} (closed)`;
      }
      return name;
    },
    async loadDistributions() {
      try {
        const distributionAggregate = await DistributionService.getDistributionYear(this.yearId);
        setAggregate(_.cloneDeep(distributionAggregate));
      } catch (error) {
        this.$addStarError(error);
      }
    },
  },
};
</script>
