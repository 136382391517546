/**
 * The Associate Service contains society related methods, such as CRUD.
 */
import Vue from 'vue';
import _ from 'lodash';
import { getConfigValue, PROPS } from './configService';
import { transformApolloError } from '../common/errorTransformers';
import { addCommandIds } from '../common/aggregateUtils';
import merge from '../common/objectMerger';
import clone from '../common/clone';
import { isPristine } from '../common/typeUtils';
import prepareForWire from './wirePrepper';
import { preparePhoneNumbers } from './wirePrepperUtils';
import traverseApply from '../common/traverseApplicator';
import {
  createAddAgreementCommand,
  createRemoveAgreementCommand,
  createSocietyAggregate,
  createSocietyAggregateCommand,
  createUpdateAgreementCommand,
  createUpdateBasicInfoCommand,
  createUpdateMetaInfoCommand,
  createUpdateRoleCodesCommand,
} from '../domain/societyDomain';
import {
  AGGREGATE_TYPES,
  createUpdateContactInfoCommand,
  createDocumentsCommand,
} from '../domain/common';
import { createUpdatePaymentInfoCommand } from '../domain/commonPayment';
import gql from '../domain/societyGql';

const SOCIETIES_URL = `${getConfigValue(PROPS.PNR_URL)}/societies`;

function processSocietyAggregate(aggregate) {
  // Merge fetched society with the known domain model, retain known domain model fields and omit unknown on fetched society.
  const base = createSocietyAggregate();
  const ret = merge(base, aggregate);
  if (ret.role_codes === null) {
    ret.role_codes = base.role_codes;
  }
  if (ret.role_codes.incoming === null) {
    ret.role_codes.incoming = {};
  }
  if (ret.role_codes.outgoing === null) {
    ret.role_codes.outgoing = {};
  }
  return ret;
}

function preparePayload(aggregate, payloadProperty, commandTemplate) {
  addCommandIds(commandTemplate, aggregate);
  commandTemplate.payload = merge(
    commandTemplate.payload,
    payloadProperty ? aggregate[payloadProperty] : aggregate,
  );
  if (
    commandTemplate.payload.contact_persons &&
    commandTemplate.payload.contact_persons.length > 0
  ) {
    commandTemplate.payload.contact_persons.forEach((person) => {
      if (!person.phone_numbers || person.phone_numbers.length === 0) {
        delete person.phone_numbers;
      }
    });
  }
  preparePhoneNumbers(commandTemplate.payload);
  prepareForWire(commandTemplate);
  return commandTemplate;
}

function prepareAgreementPayload(aggregate, payload, commandTemplate, noVersion = false) {
  addCommandIds(commandTemplate, aggregate);
  commandTemplate.payload = merge(commandTemplate.payload, payload);
  return prepareForWire(commandTemplate, { noVersion });
}

function prepareDocumentsPayload(payload, aggregateId, commandTemplate) {
  commandTemplate.stream_id = aggregateId;
  commandTemplate.process_id = aggregateId;
  commandTemplate.timestamp = new Date();
  commandTemplate.payload = payload;
  delete commandTemplate.version;
  // Clear undefs and nulls
  return traverseApply(
    commandTemplate,
    (key, fieldValue) => !fieldValue,
    (key, field, leaf) => delete leaf[key],
    false,
  );
}

function createSocietyCommand(society) {
  const createCommand = createSocietyAggregateCommand();
  createCommand.payload = merge(createCommand.payload, society);
  createCommand.payload.contact_info.contact_persons.forEach((contactPerson, index) => {
    if (!contactPerson.name) {
      delete createCommand.payload.contact_info.contact_persons[index];
    }
  });
  traverseApply(
    createCommand.payload.agreements,
    (key, fieldValue) =>
      Array.isArray(fieldValue) && fieldValue.every((o) => isPristine(o, ['id'])),
    (key, field, leaf) => delete leaf[key],
  );
  preparePhoneNumbers(createCommand);
  return prepareForWire(createCommand);
}

function prepareRoleCodePayload(roleCodes, commandTemplate) {
  _.flatten(Object.values(roleCodes.outgoing)).forEach((rc) => {
    if (rc.type === 'ALL') {
      delete rc.type;
    }
  });
  const data = prepareForWire(Object.assign({}, commandTemplate, { payload: clone(roleCodes) }));
  if (!data.payload) {
    data.payload = clone(roleCodes);
  }
  return data;
}

export default {
  createSociety: (aggregate) =>
    Vue.$http
      .post(`${SOCIETIES_URL}/creation`, createSocietyCommand(aggregate))
      .then((response) => Promise.resolve(response.data.id)),
  getSociety: (idTerm, versionTerm) =>
    Vue.$apollo.client
      .query({
        query: gql.getSociety,
        variables: { idTerm, versionTerm },
        fetchPolicy: 'network-only',
      })
      .then((response) => processSocietyAggregate(clone(response.data.societyAggregate)))
      .catch((error) => Promise.reject(transformApolloError(error))),
  getSocieties: () =>
    Vue.$apollo.client
      .query({ query: gql.societies, variables: {} })
      .then((response) => response.data.societies)
      .catch((error) => Promise.reject(transformApolloError(error))),
  updateBasicInfo: (societyAggregate) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/basic-info`,
        preparePayload(societyAggregate, 'basic_info', createUpdateBasicInfoCommand()),
      )
      .then((response) => Promise.resolve(response.data.id)),
  updateContactInfo: (societyAggregate) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/contact-info`,
        preparePayload(
          societyAggregate,
          'contact_info',
          createUpdateContactInfoCommand(AGGREGATE_TYPES.SOCIETY),
        ),
      )
      .then((response) => Promise.resolve(response.data.id)),
  updateMetaInfo: (societyAggregate) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/meta-info`,
        preparePayload(societyAggregate, 'meta_info', createUpdateMetaInfoCommand()),
      )
      .then((response) => Promise.resolve(response.data.id)),
  updatePaymentInfo: (paymentInfo) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/payment-info`,
        preparePayload(
          paymentInfo,
          'payment_info',
          createUpdatePaymentInfoCommand(AGGREGATE_TYPES.SOCIETY),
        ),
      )
      .then((response) => Promise.resolve(response.data.id)),
  addAgreement: (agreement, parentAggregate) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/agreements`,
        prepareAgreementPayload(
          parentAggregate,
          clone(agreement),
          createAddAgreementCommand(),
          true,
        ),
      )
      .then((response) => Promise.resolve(response.data.id)),
  removeAgreement: (agreementId, parentAggregate) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/agreements`,
        prepareAgreementPayload(parentAggregate, agreementId, createRemoveAgreementCommand()),
      )
      .then((response) => Promise.resolve(response.data.id)),
  updateAgreement: (agreement, parentAggregate) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/agreements`,
        prepareAgreementPayload(parentAggregate, clone(agreement), createUpdateAgreementCommand()),
      )
      .then((response) => Promise.resolve(response.data.id)),
  updateRoleCodes: (aggregate, roleCodes) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/role-codes`,
        prepareRoleCodePayload(
          roleCodes,
          createUpdateRoleCodesCommand(aggregate.id, aggregate.version),
        ),
      )
      .then((response) => Promise.resolve(response.data.id)),
  updateDocuments: (documents, aggregateId) =>
    Vue.$http
      .post(
        `${SOCIETIES_URL}/documents`,
        prepareDocumentsPayload(
          documents,
          aggregateId,
          createDocumentsCommand(AGGREGATE_TYPES.SOCIETY),
        ),
      )
      .then((response) => Promise.resolve(response.data.id)),
};
