import Vue from 'vue';
import Vuex from 'vuex';
import performerModule from './modules/performer/store';
import recordingModule from './modules/recording/store';
import albumModule from './modules/album/store';
import matchingModule from './modules/matching/store';
import associateModule from './modules/associate/store';
import societyModule from './modules/society/store';
import distributionAreaModule from './modules/distributionarea/store';
import distributionChannelModule from './modules/distributionchannel/store';
import distributionModule from './modules/distribution/store';
import revenueModule from './modules/revenue/store';
import starNotificationModule from './modules/error/store';
import processModule from './modules/process/store';
import cronjobModule from './modules/cronjob/store';
import appdataModule from './modules/appdata/store';
import userModule from './modules/user/store';
import paymentModule from './modules/payment/store';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  modules: {
    appdata: appdataModule,
    performer: performerModule,
    recording: recordingModule,
    album: albumModule,
    matching: matchingModule,
    associate: associateModule,
    society: societyModule,
    distributionArea: distributionAreaModule,
    distributionChannel: distributionChannelModule,
    distribution: distributionModule,
    revenue: revenueModule,
    starNotifications: starNotificationModule,
    process: processModule,
    cronjob: cronjobModule,
    user: userModule,
    payment: paymentModule,
  },
});
