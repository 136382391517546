<template>
  <div class="e2e-view-society-information">
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>

    <div v-else class="row">
      <div class="col s12 l9 society-wrapper">
        <div class="row">
          <div class="col s12">
            <h1 class="float-left">
              {{ savedInitialAggregate.basic_info.name }}
            </h1>
            <div class="edit-link text--right">
              <a v-if="version === undefined" @click="onEdit('BasicInformation')">
                <i class="far fa-edit" />
                Edit
              </a>
            </div>
            <version :version="Number(version)" view-name="societyInformation" />
          </div>
        </div>
        <create-basic-info
          v-if="isEditMode('BasicInformation')"
          :edit-mode="true"
          @update="onUpdateBasicInfo"
          @cancel="onCancel"
        />

        <create-contact-info
          v-if="isEditMode('ContactInformation')"
          :edit-mode="true"
          @update="onUpdateContactInfo"
          @cancel="onCancel"
        />
        <view-contact-info v-else @edit="onEdit" />

        <contact-person-info :disabled="version !== undefined" @update="onUpdateContactInfo" />

        <create-payment-information
          v-if="isEditMode('PaymentInformation')"
          :edit-mode="true"
          :foreign-account="paymentInfoForeignAccount"
          :company-account-visible="false"
          :show-private-account="false"
          :show-payment-receiver="false"
          @resetPaymentInfo="resetPaymentInfo"
          @deleteAccountTypes="deleteAccountTypes"
          @addForeignAccount="onAddForeignAccount"
          @save="updatePaymentInfo"
          @cancel="onCancel"
        />
        <payment-information v-else :foreign-account="paymentInfoForeignAccount" @edit="onEdit" />

        <agreement-info
          :society-name="basicInfoName"
          :agreements="agreements"
          :disabled="version !== undefined"
          @remove="onRemoveAgreement"
          @input="onUpdateAgreement"
        />
        <create-agreements
          :society-name="basicInfoName"
          :edit-mode="true"
          :hide-label="true"
          @save="onSaveNewAgreements"
        />

        <create-meta-information
          v-if="isEditMode('MetaInfo')"
          :edit-mode="true"
          @update="onUpdateMetaInfo"
          @cancel="onCancel"
        />
        <view-meta-information v-else @edit="onEdit" />

        <documents-information
          v-if="!isEditMode('DocumentsInformation')"
          :documents="documents"
          @edit="onEdit"
        />
        <edit-documents-information
          v-else
          :documents="documents"
          :update-documents="updateDocuments"
          @cancel="onCancel"
          @saved="onUpdateDocument($event)"
        />
      </div>
      <div class="col s12 l3">
        <right-column
          :editable-component="editableComponent"
          :pre-selected-tab="version ? 'activity' : 'general'"
          @onEdit="onEdit"
          @onCancel="onCancel"
          @onUpdateMetaInfo="onUpdateMetaInfo"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { commit } from '../../../store/modules/society/utils';
import AgreementInfo from './agreement-information';
import ContactPersonInfo from './contact-person-information';
import CreateAgreements from '../create/create-agreements';
import CreateBasicInfo from '../create/create-basic-info';
import CreateContactInfo from '../create/create-contact-info';
import CreateMetaInformation from '../create/create-meta-info';
import CreatePaymentInformation from '../../ui/payment/create-payment-information';
import PaymentInformation from '../../ui/payment/payment-information';
import RightColumn from '../right-column';
import SocietyService from '../../../services/societyService';
import Spinner from './../../spinner';
import UploadContracts from '../uploadContracts';
import ViewContactInfo from './contact-information';
import ViewMetaInformation from '../view/view-meta-info';
import ViewSocietyMixin from './view-society-mixin';
import Version from '../../ui/version';
import { AGGREGATE_TYPES } from '../../../domain/common';
import DocumentsInformation from '../../ui/documents/documents-information';
import EditDocumentsInformation from '../../ui/documents/edit-documents-information';
import UploadDocuments from '../../../services/uploadDocuments';

export default {
  name: 'ViewSocietyInformation',
  components: {
    AgreementInfo,
    ContactPersonInfo,
    CreateAgreements,
    CreateBasicInfo,
    CreateContactInfo,
    CreateMetaInformation,
    CreatePaymentInformation,
    PaymentInformation,
    RightColumn,
    Spinner,
    Version,
    ViewContactInfo,
    ViewMetaInformation,
    DocumentsInformation,
    EditDocumentsInformation,
  },
  mixins: [ViewSocietyMixin],
  data() {
    return {
      loading: true,
      editableComponent: '',
      savedInitialAggregate: null,
    };
  },
  computed: {
    ...mapGetters('society', [
      'id',
      'aggregate',
      'basicInfoName',
      'agreements',
      'paymentInfoForeignAccount',
      'documents',
    ]),
  },
  watch: {
    async $route() {
      await this.loadData();
    },
  },
  async created() {
    await this.loadData();
  },
  methods: {
    ...mapMutations('society', ['updateDocuments']),
    async updateBasicInfo() {
      try {
        await SocietyService.updateBasicInfo(this.aggregate);
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async updateMetaInfo() {
      try {
        await SocietyService.updateMetaInfo(this.aggregate);
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async updateContactInfo() {
      try {
        await SocietyService.updateContactInfo(this.aggregate);
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async updatePaymentInfo() {
      try {
        await SocietyService.updatePaymentInfo(this.aggregate);
        this.editableComponent = '';
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    resetPaymentInfo() {
      commit('resetPaymentInfo');
    },
    async addAgreements(agreements) {
      try {
        await UploadContracts(this.basicInfoName, agreements);
        await agreements
          .map((agreement) => () => SocietyService.addAgreement({ agreement }, this.aggregate))
          .reduce(
            (promiseChain, currentTask) => promiseChain.then(currentTask),
            Promise.resolve([]),
          );
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async removeAgreement(agreementId) {
      try {
        await SocietyService.removeAgreement({ agreement_id: agreementId }, this.aggregate);
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async updateAgreement(agreement) {
      try {
        await UploadContracts(this.basicInfoName, [agreement]);
        await SocietyService.updateAgreement({ agreement }, this.aggregate);
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onEdit(ref) {
      this.editableComponent = ref;
    },
    async onUpdateBasicInfo() {
      await this.updateBasicInfo();
      this.editableComponent = '';
    },
    async onUpdateMetaInfo() {
      await this.updateMetaInfo();
      this.editableComponent = '';
    },
    async onUpdateContactInfo() {
      await this.updateContactInfo();
      this.editableComponent = '';
    },
    deleteAccountTypes() {
      commit('addForeignAccount', {});
    },
    onAddForeignAccount(account) {
      this.deleteAccountTypes();
      commit('addForeignAccount', account);
    },
    onRemoveAgreement(agreementId) {
      this.removeAgreement(agreementId);
    },
    async onUpdateDocument(newDocuments) {
      try {
        await UploadDocuments(AGGREGATE_TYPES.SOCIETY, newDocuments);
        await SocietyService.updateDocuments(
          { documents: [...this.documents, ...newDocuments].filter((doc) => doc.id !== '') },
          this.aggregateId,
        );
        this.editableComponent = '';
        this.fetchData(this.aggregateId);
      } catch (error) {
        this.handleError(error);
      }
    },
    onCancel() {
      this.dispatchAggregate(this.savedInitialAggregate);
      this.editableComponent = '';
    },
    onSaveNewAgreements(agreements) {
      if (agreements && agreements.length > 0) {
        this.addAgreements(agreements);
      }
    },
    onUpdateAgreement(agreement) {
      if (agreement) {
        this.updateAgreement(agreement);
      }
    },
    async loadData() {
      this.aggregateId = this.$router.currentRoute.params.id;
      this.version = this.$router.currentRoute.params.version;
      try {
        await this.fetchData(this.aggregateId, this.version);
      } catch (error) {
        this.handleError(error);
        this.$router.replace({
          name: '404',
          params: { id: this.aggregateId },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.society-wrapper {
  padding-right: 20px;
}
</style>
