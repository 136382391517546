<template>
  <div>
    <div class="row">
      <label class="col s12">{{ label }} {{ readonly ? '(READONLY)' : '' }}</label>
    </div>
    <div class="row">
      <table>
        <SortableHead
          :columns="[{ thinColumn: true }, ...columns]"
          :expanded-content="true"
          :more-options="false"
          :bulk-enabled="false"
          :default-sort-should-override="true"
        />
        <tbody v-if="candidatesCount > 0">
          <tr
            v-for="(candidate, index) of computedCandidates"
            :key="`${getId(candidate)}-${index}-row`"
            class="selected text--bold"
            :class="{ checked: isChecked(candidate), readonly: readonly }"
            :style="`background: ${candidate.color}`"
          >
            <td>
              <input
                :id="`${getId(candidate)}-${index}`"
                :disabled="readonly || candidate.master"
                :checked="isChecked(candidate)"
                class="filled-in"
                type="checkbox"
                @click="toggle(candidate)"
              />
              <label :for="`${getId(candidate)}-${index}`" />
            </td>

            <td>
              {{ candidate.descriptor }}
            </td>

            <Column
              v-for="(column, cIndex) in columns"
              :key="`${getId(candidate)}-${index}-${cIndex}-column`"
              :column="column"
              :item="candidate"
            />
          </tr>
        </tbody>
        <tr v-else>
          <td colspan="100%">
            <span class="none">Nothing to add, nor any existing</span>
          </td>
        </tr>
      </table>
    </div>
    <div v-if="candidatesCount > 0" class="row">
      <a v-if="masterCount > 0" @click="showAll = !showAll">
        <i :class="`far fa-${showAll ? 'minus' : 'plus'}-square`"></i>
        {{ showAll ? 'Hide' : 'Show' }} existing
      </a>
      <span v-else class="disabled">
        <i class="far fa-plus-square"></i>
        Show existing
      </span>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import SortableHead from '../ui/table/sortable-head';
import Column from '../ui/table/column';

export default {
  name: 'MergeList',
  components: {
    SortableHead,
    Column,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    changes: {
      type: Object,
      required: true,
    },
    candidates: {
      type: Array,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    idPath: {
      type: String,
      default: 'id',
    },
    columns: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    masterIds() {
      return get(this.candidates[0], this.path, []).map(this.getId);
    },
    candidatesCount() {
      return this.candidates.flatMap((candidate) => get(candidate, this.path, [])).length;
    },
    masterCount() {
      return this.candidates
        .filter((candidate) => candidate.master)
        .flatMap((candidate) => get(candidate, this.path, [])).length;
    },
    computedCandidates() {
      return this.candidates
        .filter((candidate) => this.showAll || !candidate.master)
        .sort((candidate) => (candidate.master ? 1 : -1))
        .flatMap((candidate) =>
          get(candidate, this.path, []).map((value) => ({
            ...value,
            color: candidate.color,
            master: candidate.master,
            descriptor: candidate.descriptor,
          })),
        )
        .filter((candidate) => candidate.master || !this.masterIds.includes(this.getId(candidate)));
    },
    values() {
      return get(this.changes, `${this.path}.value`, []) || [];
    },
  },
  methods: {
    getId(value) {
      return get(value, this.idPath);
    },
    isChecked(value) {
      return (
        this.readonly ||
        value.master ||
        !!this.values.find((v) => this.getId(v) === this.getId(value))
      );
    },
    toggle(value) {
      let values = [];
      if (this.isChecked(value)) {
        values = this.values.filter((v) => this.getId(v) !== this.getId(value));
      } else {
        values = [...this.values, value];
      }

      this.$emit('setValue', { value: values, path: this.path });
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
tr:not(.readonly).selected {
  opacity: 0.6;

  &.checked,
  &:hover {
    opacity: 1;
  }
}
</style>
