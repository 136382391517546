<template>
  <form :data-vv-scope="scope">
    <div class="row">
      <div class="col s12">
        <h2>Extra Information and Meta Data</h2>
      </div>
    </div>

    <div class="card">
      <div class="row">
        <div class="col s8">
          <label class="label">Profile</label>
          <textarea
            class="profile"
            name="profile"
            placeholder="E.g. Herbert Joakim Berg, född 16 mars 1970 i Eskilstuna, är en svensk låtskrivare, sångare och gitarrist, tidigare i gruppen Kent. Han är uppvuxen i Eskilstuna men är numera bosatt i Stockholm."
            :value="profile"
            @input="updateProfile($event.target.value)"
            @keyup="autoHeight"
          />
        </div>
      </div>

      <div class="row flush--bottom">
        <external-sources-input
          label="External sources"
          :external-sources="other_external_sources"
          :scope="scope"
          @add="onAddExternalSource"
          @remove="onRemoveExternalSource"
        />
      </div>
    </div>

    <div class="card">
      <div class="row flush--bottom">
        <div class="col s8">
          <searchable-tag-input
            label="Tags"
            name="tags"
            placeholder="E.g. Top 100"
            use-template="tag"
            :preview-list="previewTags"
            :scope="scope"
            :searcher="searchTag"
            :value-list="localTags"
            @add="addTag"
            @remove="removeTagAt"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { commit } from '../../../../store/modules/performer/utils';
import { createOtherExternalSources, textareaAutoheight } from '../../../../domain/common';
import ExternalSourcesInput from '../../../ui/input/external-sources-input';
import SearchableTagInput from '../../../ui/tag/searchable-tag-input';
import SearchHelper from '../../../search/searchHelper';

export default {
  name: 'CreateMetaInformation',
  components: { ExternalSourcesInput, SearchableTagInput },
  inject: ['$validator'],
  props: {
    scope: { type: String, default: 'meta-info' },
  },
  data() {
    return {
      localTags: [],
      allTags: [],
    };
  },
  computed: {
    ...mapState('performer', {
      profile: (state) => state.general_info.profile,
      other_external_sources: (state) => state.metadata_info.other_external_sources,
      tags: (state) => state.metadata_info.tags,
    }),
    previewTags() {
      return this.allTags.filter((tag) =>
        this.localTags.length > 0
          ? !this.localTags.map((localTag) => localTag.name).includes(tag.name)
          : true,
      );
    },
  },
  async created() {
    this.onAddExternalSource();
    this.allTags = await SearchHelper.getAllTags();
  },
  methods: {
    ...mapMutations('performer', ['updateProfile', 'addTag', 'removeTagAt']),
    searchTag(terms) {
      return SearchHelper.tagSearch(this.allTags, terms, this.localTags);
    },
    addTag(event) {
      this.localTags = event.data;
      this.tags.push(event.item.name);
    },
    removeTagAt(event) {
      this.tags.splice(event.idx, 1);
    },
    onAddExternalSource() {
      commit('addExternalSource', createOtherExternalSources());
    },
    onRemoveExternalSource(index) {
      commit('removeExternalSource', index);
      if (this.other_external_sources.length === 0) {
        commit('addExternalSource', createOtherExternalSources());
      }
    },
    autoHeight(e) {
      textareaAutoheight(e);
    },
  },
};
</script>
