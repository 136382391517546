/**
 * Extracts the actual filename from a remote file name, from the '__'-metadata part.
 * @param originalName the system file name
 * @returns {String} the name
 */
export default function extractRemoteFileName(originalName) {
  if (originalName.indexOf('/') >= 0) {
    return originalName.split('/').pop();
  }
  if (originalName.indexOf('__') < 0) {
    return originalName;
  }
  let name = originalName.split('__');
  name.shift();
  name = name.join('__');
  name = name.substring(0, name.match(/\.\w+$/).index);
  return name.length > 0 ? name : originalName;
}
