import _ from 'lodash';

export const ROOT = 0;

/**
 * Traverses an object graph and applies the transformer rule if the test is successful.
 * @param obj the object to traverse
 * @param fTest the test
 * @param fApply the transformer rule (provides the callee with current key, current field value and the traversed object)
 * @param assign if result of apply should be set on actual object (default: true)
 * @param depth traversal depth
 * @returns {*} the transformed object
 */
export default function traverseApply(obj, fTest, fApply, assign = true, depth = ROOT) {
  Object.keys(obj).map((key) => {
    const fieldValue = obj[key];
    if (_.isPlainObject(fieldValue)) {
      traverseApply(fieldValue, fTest, fApply, assign, depth + 1);
    } else if (
      Array.isArray(fieldValue) &&
      fieldValue.length > 0 &&
      _.isPlainObject(fieldValue[0])
    ) {
      fieldValue.forEach((a) => traverseApply(a, fTest, fApply, assign, depth + 1));
    }
    if (fTest(key, fieldValue, depth)) {
      if (assign) {
        obj[key] = fApply(key, fieldValue, obj);
      } else {
        fApply(key, fieldValue, obj);
      }
    }
    return true;
  });
  return obj;
}
