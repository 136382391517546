const contactInfo = `
  contact_info {
    address {
      street
      postal_code
      city
      province
      country
      care_of
    }
    emails
    phone_numbers {
      type
      number
    }
    website
  }`;

const contactPersons = `
  contact_persons {
    name
    emails
    phone_numbers {
      type
      number
    }
    comment
  }`;

const contactInfoWithContactPersons = `
  contact_info {
    address {
      street
      postal_code
      city
      province
      country
      care_of
    }
    emails
    phone_numbers {
      type
      number
    }
    website
    ${contactPersons}
  }`;

const associateReference = `
  id
  row_id
  name
  type
  access_policy
  start_date
  end_date
  share
`;

const associates = `
  associates {
    ${associateReference}
  }
`;

const childAssociates = `
  child_associations {
    ${associateReference}
  }
  num_child_associations
`;

export default {
  contactInfo,
  contactInfoWithContactPersons,
  associates,
  childAssociates,
};
