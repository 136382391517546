<template>
  <tr class="active-row">
    <td />
    <td>
      <validated-text-input
        v-model="model.name"
        name="name"
        placeholder="E.g. Fonogram i TV"
        rule="required"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.source_society_name"
        name="source_society_name"
        placeholder="E.g. PPL"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.project_code_in"
        name="codeIn"
        placeholder="E.g. 601"
        rule="required"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.project_code_out"
        name="codeOut"
        placeholder="E.g. 702"
        rule="required"
      />
    </td>

    <td>
      <validated-text-input
        v-model="model.project_code_reserves"
        name="codeReserves"
        placeholder="E.g. 801"
        rule="required"
      />
    </td>

    <td>
      <action-buttons class="float-right" @save="onSave" @cancel="onCancel" />
      <div class="col s1 float-right">
        <validated-text-input
          v-model="model.financing_rate"
          name="financingRate"
          placeholder="E.g. 27"
          rule="numeric"
        />
      </div>
    </td>
    <td />
  </tr>
</template>

<script>
import _ from 'lodash';
import ActionButtons from '../../ui/button/action-buttons';
import { createRevenueSource } from '../../../domain/distributionDomain';
import DistributionService from '../../../services/distributionService';
import ValidatedTextInput from '../../ui/input/validated-text-input';

export default {
  name: 'EditDistributionSource',
  components: {
    ValidatedTextInput,
    ActionButtons,
  },
  inject: ['$validator'],
  props: {
    revenueSource: {
      type: Object,
      default: () => createRevenueSource(),
    },
    category: {
      type: String,
    },
    financingRate: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      model: _.cloneDeep(this.revenueSource),
    };
  },
  mounted() {
    this.model.category = this.category;
    if (this.model.financing_rate == null) {
      this.model.financing_rate = this.financingRate;
    }
  },
  methods: {
    async onSave() {
      const valid = await this.$validator.validateAll();
      if (valid) {
        if (
          this.model.financing_rate === '' ||
          Number(this.model.financing_rate) === this.financingRate
        ) {
          this.model.financing_rate = null;
        }
        await DistributionService.updateRevenueSource(_.cloneDeep(this.model));
        this.$emit('changed', this.model);
      }
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
