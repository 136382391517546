<template>
  <div>
    <select-input
      :item-key="itemKey"
      :item-value="itemValue"
      :sorted="true"
      :name="name"
      :scope="scope"
      :label="label"
      :value="code"
      :items="countries"
      :rule="validationRule"
      :mandatory="mandatory"
      :show-label="showLabel"
      :disabled="disabled"
      :return-object="returnObject"
      @input="onInput"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectCountry',
  components: { SelectInput },
  props: {
    value: { type: String, default: '' },
    inputCountries: { type: Array, default: () => [] },
    label: { type: String, default: 'Country' },
    name: { type: String, default: 'country' },
    mandatory: { type: Boolean, default: false },
    rule: { type: String, default: '' },
    scope: { type: String, default: undefined },
    showLabel: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    itemKey: { type: String, default: 'iso' },
    ruleOneOfOptional: { type: Boolean },
    returnObject: { type: Boolean },
  },
  data() {
    return {
      itemValue: 'name',
      code: this.value,
      validationRule: _.isObject(this.rule)
        ? { ...this.rule }
        : this.rule // if rule is not undefined
          ? { [this.rule]: true }
          : {},
    };
  },
  computed: {
    countries() {
      return this.inputCountries.length > 0
        ? this.inputCountries
        : this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    value() {
      this.code = this.value;
    },
  },

  mounted() {
    if (this.ruleOneOfOptional) {
      this.validationRule['one_of_optional'] = [this.itemValue, this.countries];
    }
  },

  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
