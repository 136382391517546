<template>
  <modal
    v-if="modal.show === true"
    :submit-label="modal.submitLabel"
    @save="onSave"
    @cancel="onCancel"
    @close="onClose"
  >
    <h1 slot="header">Warning</h1>
    <div slot="body">
      {{ modal.message }}
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../ui/dialog/modal';

export default {
  name: 'GenericModal',
  components: {
    Modal,
  },
  computed: {
    ...mapGetters('starNotifications', ['modal']),
  },
  methods: {
    onSave() {
      this.modal.confirmPromise.resolve();
    },
    onCancel() {
      this.modal.confirmPromise.reject();
    },
    onClose() {
      this.$starUserConfirmDialog(false);
    },
  },
};
</script>
