<template>
  <div class="administrate-end-of-year-statement">
    <div class="row">
      <div class="col s12">
        <h1>Voting Rights</h1>
      </div>
    </div>
    <div class="card">
      <div class="row">
        <div class="col s12">
          <h2>Create Voting Rights File</h2>
        </div>
      </div>
      <div v-if="loading">
        <spinner />
      </div>
      <template v-else>
        <div class="row">
          <div class="col s12">
            <button
              v-if="!creating"
              class="btn"
              :disabled="creating"
              type="submit"
              @click.prevent="createVotingRightsFile"
            >
              Create Voting Rights File
            </button>
            <span v-else>Creation in progress, please check back in a while</span>
          </div>
        </div>
      </template>
    </div>

    <div class="card">
      <div class="row">
        <div class="col s12">
          <h2>Voting Right File(s)</h2>
        </div>
      </div>

      <div class="row">
        <div class="col s12">
          <table>
            <thead class="cursor-pointer">
              <th v-for="(column, index) in columns" :key="`column-${index}`" class="disabled-head">
                {{ column.name }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(row, index) in files" :key="`file-${index}`" class="row">
                <td>
                  <span>
                    {{ toDate(row.filename) }}
                  </span>
                </td>
                <td>
                  <span v-if="row.filename">
                    <a @click="onDownloadVotingRightsFile(row.filename)">
                      <i class="far fa-file-alt" />
                      Download {{ row.filename }}
                    </a>
                  </span>
                  <span v-else class="none">No file created</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import moment from 'moment';
import DownloadLink from '../ui/file/downloadLink';
import RemoteFileService from '../../services/remoteFileService';
import Spinner from '../spinner';
export default {
  name: 'AdministrateVotingRights',
  components: {
    Spinner,
  },
  data() {
    return {
      loading: false,
      creating: false,
      files: [],
      columns: [
        {
          name: 'Date',
        },
        {
          name: 'Name',
        },
      ],
    };
  },
  async created() {
    await this.fetchVotingRightFiles();
  },
  methods: {
    toDate(filename) {
      return moment(filename.replace('voting_rights_', '').replace('.xlsx', '')).format(
        'YYYY-MM-DD',
      );
    },
    async createVotingRightsFile() {
      this.creating = true;
      this.loading = true;

      const processId = uuid.v4();
      this.$store.dispatch('process/addProcess', {
        name: 'Generate voting rights file',
        id: processId,
      });
      try {
        await RemoteFileService.createVotingRightsFile(processId);
      } catch (error) {
        error.title = 'Failed to generate voting rights file';
        this.$addStarError(error);
        this.$store.dispatch('process/removeProcess', processId);
      }
      this.loading = false;
    },
    async fetchVotingRightFiles() {
      const size = this.files.length;
      this.files = await RemoteFileService.listDocumentRepository('admin/voting_rights/');
      this.loading = false;
      if (this.files.length > size) {
        this.creating = false;
      }
    },
    async onDownloadVotingRightsFile(fileName) {
      try {
        const xmlFile = `admin/voting_rights/${fileName}`;
        const fileData = await RemoteFileService.downloadFromDocumentRepository(xmlFile);
        DownloadLink.direct(fileData, xmlFile);
      } catch (error) {
        error.title = 'File could not be downloaded';
        this.$addStarError(error);
      }
    },
  },
};
</script>

<style lang="scss">
.administrate-end-of-year-statement {
  .fa-question {
    color: var(--green--dark);
  }
  .custom-select {
    width: 200px;
  }
}
</style>
