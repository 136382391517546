<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Tax Information</h2>
      </div>
      <div class="col s6 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit" />
          Edit
        </a>
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <label class="col s3">Statement of income</label>
        <div class="col s9">
          <span v-if="statementOfIncome">
            Yes
            <i class="far fa-envelope" />
          </span>
          <span v-else>No</span>
        </div>
      </div>
      <div class="row">
        <label class="col s3">VAT No.</label>
        <div class="col s9">
          <span v-if="taxVatNumber">{{ taxVatNumber }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row">
        <label class="col s3">Tax domicile</label>
        <div class="col s9">
          <span v-if="taxDomicile">{{ $filters.formatCountry(taxDomicile) }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>

      <div class="row flush--bottom">
        <label class="col s3">TIN</label>
        <div class="col s9">
          <span v-if="taxTin">{{ taxTin }}</span>
          <span v-else class="none">None</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaxInformation',
  components: {},
  props: {
    taxTin: { type: String },
    taxDomicile: { type: String },
    taxVatNumber: { type: String },
    statementOfIncome: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
};
</script>
