<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1 class>Cronjobs</h1>
      </div>
    </div>
    <div class="row card">
      <spinner v-if="loading" />
      <ul class="cronjob-list">
        <li v-for="job of cronjobs" :key="job.name">
          <cronjob-list-item
            v-bind="job"
            @stateChange="onStateChange(job, $event)"
            @trigger="onTrigger(job)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CronjobListItem from './cronjob-list-item';
import Spinner from '../spinner';

export default {
  name: 'CronjobOverview',
  components: {
    CronjobListItem,
    Spinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    cronjobs() {
      return this.$store.state.cronjob.cronjobs;
    },
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch('cronjob/fetchCronjobs');
    this.loading = false;
  },
  methods: {
    async onStateChange(job, newState) {
      this.loading = true;
      if (newState === false) {
        await this.$store.dispatch('cronjob/disableCronjob', job.name);
      } else {
        await this.$store.dispatch('cronjob/enableCronjob', job.name);
      }
      this.loading = false;
    },
    async onTrigger(job) {
      await this.$store.dispatch('cronjob/triggerCronjob', job.trigger);
    },
  },
};
</script>
