var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[_vm._l((_vm.reportLines),function(line,index){return [_c('tr',{key:`${index}-tr1`,ref:"line",refInFor:true,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.toggleExpanded(line.incoming.group_id)}}},[_c('td',{staticClass:"compare__arrow"},[_c('i',{class:`fas fa-caret-${_vm.isExpanded(line.incoming.group_id) ? 'up' : 'down'}`})]),(_vm.selectable)?_c('td',{staticClass:"compare__checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(line.checked),expression:"line.checked"}],ref:"mark",refInFor:true,staticClass:"filled-in",attrs:{"id":index,"type":"checkbox","name":"mark","disabled":line.completed},domProps:{"checked":Array.isArray(line.checked)?_vm._i(line.checked,null)>-1:(line.checked)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){var $$a=line.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(line, "checked", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(line, "checked", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(line, "checked", $$c)}}}}),_c('label',{attrs:{"for":index},on:{"click":function($event){$event.stopPropagation();}}})]):_vm._e(),_c('td',{staticClass:"fs-14",class:{
          'compare__table-item-row--diff': _vm.hasDiff(
            line.incoming.title,
            _vm.getMatchedBasicInfoProp(line, 'name'),
          ),
          'text--orange': line.completed && ['I', 'R'].includes(line.incoming.match_state),
          'text--green': line.completed && !['I', 'R'].includes(line.incoming.match_state),
        }},[_c('span',{domProps:{"innerHTML":_vm._s(line.incoming.title)}}),(line.completed)?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e()]),(!_vm.isExpanded(line.incoming.group_id) && !_vm.album)?[_c('td',[_c('span',{domProps:{"innerHTML":_vm._s(line.incoming.version_title)}})]),_c('td',{class:{
            'compare__table-item-row--diff': _vm.hasDiff(
              line.incoming.main_artist,
              _vm.getMatchedBasicInfoProp(line, 'main_artist.name'),
            ),
          }},[_vm._v(" "+_vm._s(line.incoming.main_artist)+" ")]),_c('td',{class:{
            'compare__table-item-row--diff': _vm.hasDifferentYear(
              line.incoming.recording_year,
              _vm.getMatchedBasicInfoProp(line, 'recording_date'),
            ),
          }},[_vm._v(" "+_vm._s(line.incoming.recording_year)+" ")]),_c('td',{class:{
            'compare__table-item-row--diff': _vm.hasDiff(
              line.incoming.recording_country,
              _vm.getMatchedBasicInfoProp(line, 'recorded_in_country'),
            ),
          }},[_vm._v(" "+_vm._s(_vm.$filters.formatCountry(line.incoming.recording_country))+" ")]),_c('td',{class:{
            'compare__table-item-row--diff': _vm.hasDiff(
              line.incoming.isrc,
              _vm.getMatchedBasicInfoProp(line, 'isrc'),
            ),
          }},[_vm._v(" "+_vm._s(line.incoming.isrc)+" ")]),_c('td',{class:{
            'compare__table-item-row--diff': _vm.hasDiff(
              line.incoming.label,
              _vm.getMatchedBasicInfoProp(line, 'label'),
            ),
          }},[_vm._v(" "+_vm._s(line.incoming.label)+" ")]),(!_vm.sdeg)?_c('td',[_vm._v(_vm._s(_vm.$filters.toMinutes(line.incoming.airplay_time)))]):_vm._e(),_c('td',[_vm._v(_vm._s(line.incoming.line_count))]),(!_vm.sdeg)?_c('td',[_vm._v(_vm._s(line.incoming.hits && line.incoming.hits.length))]):_vm._e(),(_vm.showSamiMember)?_c('td',[(line.incoming.sami_member)?_c('span',[_c('i',{staticClass:"fas fa-user"})]):_c('span',{staticClass:"none"},[_vm._v("None")])]):_vm._e()]:_vm._e(),(!_vm.isExpanded(line.incoming.group_id) && _vm.album)?[_c('td',[_vm._v(" "+_vm._s(line.incoming.main_artist !== 'null' ? line.incoming.main_artist : 'Unknown')+" ")]),_c('td',{class:{
            'compare__table-item-row--diff': _vm.hasDifferentYear(
              line.incoming.total_count,
              _vm.getMatchedBasicInfoProp(line, 'total_count'),
            ),
          }},[_vm._v(" "+_vm._s(line.incoming.total_count)+" ")])]:_c('td',{attrs:{"colspan":"1000"}})],2),(_vm.isExpanded(line.incoming.group_id) && !_vm.album)?[_c('tr',{key:`${index}-tr2`,staticClass:"prevent-hover-bg no-borders"},[_c('td',{attrs:{"colspan":"1000"}},[(_vm.loading)?_c('div',{staticClass:"compare__loading"},[_c('spinner')],1):_c('div',{staticClass:"compare__nested"},[_c('div',{staticClass:"compare__nested-main compare__main-divide"},[_c('span',{staticClass:"compare__nested-heading compare__nested-heading--incoming"},[_c('strong',[_vm._v("Played recording:")]),_c('span',{domProps:{"innerHTML":_vm._s(line.incoming.title)}})]),_c('span',{staticClass:"compare__nested-heading compare__nested-heading--candidate"},[_c('strong',[_vm._v("Star suggestion:")]),_c('a',{attrs:{"href":`#/recordings/${_vm.getCandidateValue('id')}`}},[_vm._v(" "+_vm._s(_vm.getCandidateValue('basic_info.name'))+" "+_vm._s(_vm.getCandidateValue('lineup_locked') ? ' 🔒' : '')+" ")])]),_c('ul',{staticClass:"compare__nested-list"},[_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.name',
                    value: _vm.getCandidateValue('basic_info.name'),
                    displayFilter: 'prettyPrintArray',
                  },"incoming":{
                    key: 'title',
                    value: line.incoming.title,
                    displayFilter: 'toTitleCase',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.alternate_titles',
                    value: _vm.getCandidateValue('basic_info.alternate_titles'),
                    displayFilter: 'prettyPrintArray',
                  },"incoming":{
                    key: 'alternate_titles',
                    value: line.incoming.alternate_titles,
                    displayFilter: 'prettyPrintArray',
                  },"allow-action":_vm.allowAction && !line.completed,"compute-new-value":_vm.mergeAlternateTitles},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.version_title',
                    value: _vm.getCandidateValue('basic_info.version_title'),
                  },"incoming":{
                    key: 'basic_info.version_title',
                    value: line.incoming.version_title,
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.main_artist.name',
                    value: _vm.getCandidateValue('basic_info.main_artist.name'),
                  },"incoming":{
                    key: 'main_artist',
                    value: line.incoming.main_artist,
                  },"original-value":_vm.getCandidateValue('basic_info.main_artist'),"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.duration_sec',
                    value: _vm.getCandidateValue('basic_info.duration_sec'),
                    displayFilter: 'toMinutes',
                  },"incoming":{
                    key: 'duration_sec',
                    value: line.incoming.duration_sec,
                    displayFilter: 'toMinutes',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'albums',
                    value: _vm.getCandidateValue('albums'),
                    displayFilter: 'prettyPrintAlbumArray',
                  },"incoming":{
                    key: 'album',
                    value: line.incoming.album,
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.recording_date',
                    value: _vm.getCandidateValue('basic_info.recording_date'),
                    displayFilter: 'formatRecordingDate',
                  },"incoming":{
                    key: 'recording_year',
                    value: line.incoming.recording_year,
                  },"comparator":'yearsMatch',"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.recorded_in_country',
                    value: _vm.getCandidateValue('basic_info.recorded_in_country'),
                    displayFilter: 'formatCountry',
                  },"incoming":{
                    key: 'recording_country',
                    value: line.incoming.recording_country,
                    displayFilter: 'formatCountry',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.produced_in_country',
                    value: _vm.getCandidateValue('basic_info.produced_in_country'),
                    displayFilter: 'formatCountry',
                  },"incoming":{
                    key: 'produced_in_country',
                    value: line.incoming.produced_in_country,
                    displayFilter: 'formatCountry',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.isrc',
                    value: _vm.getCandidateValue('basic_info.isrc'),
                  },"incoming":{
                    key: 'isrc',
                    value: line.incoming.isrc,
                  },"allow-action":_vm.sdeg ? false : _vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: _vm.getCandidateVrdbInfoValue().key,
                    value: _vm.getCandidateVrdbInfoValue().id,
                  },"incoming":{
                    key: 'vrdb2_id',
                    value: line.incoming.vrdb2_id,
                  },"allow-action":false}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.label',
                    value: _vm.getCandidateValue('basic_info.label'),
                    displayFilter: 'toTitleCase',
                  },"incoming":{
                    key: 'label',
                    value: line.incoming.label,
                    displayFilter: 'toTitleCase',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.genre',
                    value: _vm.getCandidateValue('basic_info.genre'),
                    displayFilter: 'formatGenre',
                  },"incoming":{
                    key: 'genre',
                    value: line.incoming.genre,
                    displayFilter: 'formatGenre',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.composer',
                    value: _vm.getCandidateValue('basic_info.composer'),
                    displayFilter: 'prettyPrintNames',
                  },"incoming":{
                    key: 'composer',
                    value: line.incoming.composer,
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.writer',
                    value: _vm.getCandidateValue('basic_info.writer'),
                  },"incoming":{
                    key: 'writer',
                    value: line.incoming.writer,
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.arranger',
                    value: _vm.getCandidateValue('basic_info.arranger'),
                  },"incoming":{
                    key: 'arranger',
                    value: line.incoming.arranger,
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.local_codes_label',
                    value: _vm.getCandidateValue('basic_info.local_codes').filter((lc) =>
                      _vm.sdeg ? ['SR', 'public_id'].includes(lc.code) : true,
                    ),
                    displayFilter: 'prettyPrintCodeValueObject',
                  },"incoming":{
                    key: 'basic_info.local_codes_label',
                    value: line.incoming.local_ids,
                    displayFilter: 'prettyPrintArray',
                  },"allow-action":_vm.sdeg ? false : _vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}})],1)]),_c('CompareAirtime',{attrs:{"playtime":line.incoming.airplay_time,"linecount":line.incoming.line_count}}),_c('CompareLineupMatching'),(_vm.allowAction && !line.completed)?_c('CompareButtonsMatching',{attrs:{"candidate-changed":_vm.candidateChanged,"handle-identify-btn":_vm.sdeg && !_vm.samiLineupMember() ? _vm.handleIgnoreBtn : _vm.handleIdentifyBtn,"handle-ignore-btn":_vm.sdeg ? _vm.handleUnmatchBtn : _vm.handleIgnoreBtn,"handle-new-recording":_vm.handleNewRecording,"handle-save-btn":_vm.handleSaveBtn,"line":line,"revert-match":_vm.revertMatch}}):_vm._e()],1)])])]:_vm._e(),(_vm.isExpanded(line.incoming.group_id) && _vm.album)?[_c('tr',{key:`${index}-tr2`,staticClass:"prevent-hover-bg no-borders"},[_c('td',{attrs:{"colspan":"1000"}},[(_vm.loading)?_c('div',{staticClass:"compare__loading"},[_c('spinner')],1):_c('div',{staticClass:"compare__nested"},[_c('div',{staticClass:"compare__nested-main compare__main-divide"},[_c('span',{staticClass:"compare__nested-heading compare__nested-heading--incoming"},[_c('strong',[_vm._v("Played Album:")]),_c('span',{domProps:{"innerHTML":_vm._s(line.incoming.title)}})]),_c('span',{staticClass:"compare__nested-heading compare__nested-heading--candidate"},[_c('strong',[_vm._v("Star suggestion:")]),_c('a',{attrs:{"href":`#/albums/${_vm.getCandidateValue('id')}`}},[_vm._v(" "+_vm._s(_vm.getCandidateValue('basic_info.name'))+" ")])]),_c('ul',{staticClass:"compare__nested-list"},[_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.alternate_titles',
                    value: _vm.candidateAllTitles,
                    displayFilter: 'prettyPrintArray',
                  },"incoming":{
                    key: 'title',
                    value: line.incoming.title,
                    displayFilter: 'toTitleCase',
                  },"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.main_artist.name',
                    value: _vm.getCandidateValue('basic_info.main_artist.name'),
                  },"incoming":{
                    key: 'main_artist',
                    value: line.incoming.main_artist,
                  },"original-value":_vm.getCandidateValue('basic_info.main_artist'),"allow-action":_vm.allowAction && !line.completed},on:{"valueUpdated":_vm.candidateValueUpdated}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.no_tracks',
                    value: _vm.getCandidateValue('tracks').length,
                  },"incoming":{
                    key: 'no_tracks',
                    value: '0',
                  },"allow-action":false}}),_c('CompareGridRow',{attrs:{"candidate":{
                    key: 'basic_info.catalog_number',
                    value: _vm.getCandidateValue('basic_info.catalog_number'),
                  },"incoming":{
                    key: 'catalog_number',
                    value: '',
                  },"allow-action":false}})],1)]),(!_vm.album)?_c('CompareAirtime',{attrs:{"playtime":line.incoming.airplay_time,"linecount":line.incoming.line_count}}):_vm._e(),(!_vm.album)?_c('CompareLineupMatching'):_vm._e(),(_vm.allowAction && !line.completed)?_c('CompareButtonsMatching',{attrs:{"candidate-changed":_vm.candidateChanged,"handle-identify-btn":_vm.sdeg && !_vm.samiLineupMember() ? _vm.handleIgnoreBtn : _vm.handleIdentifyBtn,"handle-ignore-btn":_vm.sdeg ? _vm.handleUnmatchBtn : _vm.handleIgnoreBtn,"handle-new-recording":_vm.handleNewRecording,"handle-save-btn":_vm.handleSaveBtn,"is-album":_vm.album,"line":line,"revert-match":_vm.revertMatch}}):_vm._e()],1)])])]:_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }