<template>
  <form data-vv-scope="create-mandate">
    <div>
      <div v-for="(mandate, index) in mandates" :key="index" class="mandate-right">
        <template
          v-if="
            enableAllSocietyCodes ||
            !mandate.society_code ||
            allowedSocietyCodes.includes(mandate.society_code.toUpperCase())
          "
        >
          <div class="row">
            <div class="col s4">Society</div>
            <div class="col s5">
              <select-input
                ref="society"
                :rule="rule"
                :disabled="mandate.id > 0"
                :name="'mandate.society_code.' + rightType + '.' + index"
                :value="
                  mandate.society_code ? mandate.society_code.toUpperCase() : mandate.society_code
                "
                :items="enableAllSocietyCodes ? societyCodes : editableSocietyCodes"
                label="society"
                :show-label="false"
                scope="create-mandate"
                item-key="ipd_code"
                item-value="name"
                @input="updateSocietyCode(index, $event)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s4">Member type</div>
            <div class="col s5">
              <select-input
                ref="mandateType"
                :disabled="!mandate.society_code"
                :name="'mandate.mandate_type.' + rightType + '.' + index"
                :value="mandate.mandate_type"
                :items="mandateTypes"
                :rule="!!mandate.society_code ? { required: true } : {}"
                label="mandate type"
                :show-label="false"
                scope="create-mandate"
                item-key="code"
                item-value="name"
                @input="updateMandateType(index, $event)"
              />
            </div>
          </div>
          <div v-if="mandate.mandate_type === 'R+' || mandate.mandate_type === 'WW-'" class="row">
            <div class="col s4">
              <span v-if="mandate.mandate_type === 'R+'">Including territories</span>
              <span v-else>Excluding</span>
            </div>
            <div class="col s5">
              <searchable-tag-input
                :name="`mandate.territories.${rightType}.${index}`"
                :value-list="
                  editMode ? localTerritories(mandate) : mandateTerritoriesByIndex(index)
                "
                :simple-searcher="searchTerritory"
                scope="create-mandate"
                placeholder="E.g. Norway"
                @add="onAddTerritory(index, $event)"
                @remove="onRemoveTerritory(index, $event)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s4">Valid</div>
            <div class="col s8">
              <select-date
                ref="startDate"
                :disabled="!mandate.society_code"
                :name="`mandate.start_date.${rightType}.${index}`"
                :value="mandate.start_date"
                :rule="!!mandate.society_code ? { required: true } : {}"
                scope="create-mandate"
                @input="updateStartDate(index, $event)"
              />
              <div class="date_separator">-</div>
              <select-date
                ref="endDate"
                :disabled="!mandate.society_code"
                :name="'mandate.end_date.' + rightType + '.' + index"
                :value="mandate.end_date"
                :rule="!!mandate.start_date ? { after: mandate.start_date } : {}"
                scope="create-mandate"
                @input="updateEndDate(index, $event)"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s4">Performance activity</div>
            <div class="col s5">
              <select-input
                ref="performanceActivity"
                disabled
                :name="'mandate.member_performance_activity.' + rightType + '.' + index"
                value="na"
                :items="performanceActivities"
                :empty-select-option="false"
                item-key="code"
                item-value="name"
              />
            </div>
          </div>
          <div class="row">
            <div class="col s4">Conditional aspect</div>
            <div class="col s5">
              <select-input
                ref="conditionalAspects"
                disabled
                :name="'mandate.member_conditional_aspect.' + rightType + '.' + index"
                value="na"
                :items="conditionalAspects"
                :empty-select-option="false"
                item-key="code"
                item-value="name"
              />
            </div>
          </div>
          <delete-button
            v-if="!(mandates[index].id === -1 && !mandates[index].society_code)"
            :is-component="true"
            @click="onRemoveMandate(index, mandate.id)"
          />
        </template>
      </div>
    </div>
    <a class="add-society" :class="{ disabled: !lastHasEntry }" @click="onAddMandate()">
      <i class="fas fa-plus"></i>
      Add mandate
    </a>
  </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DeleteButton from '../../../ui/button/delete-button';
import SearchableTagInput from '../../../ui/tag/searchable-tag-input';
import SelectDate from '../../../ui/select/select-date';
import SelectInput from '../../../ui/select/select-input';
import { MANDATE_DEFAULT } from '../../../../domain/performerDomain';

export default {
  name: 'CreateMandate',
  components: {
    DeleteButton,
    SearchableTagInput,
    SelectDate,
    SelectInput,
  },
  inject: ['$validator'],
  props: {
    mandates: { type: Array, default: () => [] },
    rightType: { type: String, default: MANDATE_DEFAULT },
    editMode: { type: Boolean, default: false },
    rule: {
      type: [String, Object],
      default: '',
    },
  },
  data() {
    return {
      enableAllSocietyCodes: false,
      performanceActivities: [{ name: 'N/A', code: 'na' }],
      conditionalAspects: [{ name: 'N/A', code: 'na' }],
      mandateTerritories: [],
    };
  },
  computed: {
    ...mapState('performer', {}),
    ...mapGetters('user', ['isDeveloper', 'isAdmin']),
    lastHasEntry() {
      return (
        this.mandates.length > 0 &&
        this.mandates.slice(-1)[0].society_code !== null &&
        this.mandates.slice(-1)[0].mandate_type !== null &&
        this.mandates.slice(-1)[0].start_date !== null
      );
    },
    societyCodes() {
      return this.$store.state.appdata.societies;
    },
    editableSocietyCodes() {
      return this.societyCodes.filter((s) => s.create_mandate);
    },
    allowedSocietyCodes() {
      return this.societyCodes.filter((s) => s.create_mandate).map((s) => s.ipd_code);
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
    mandateTypes() {
      return this.$store.state.appdata.mandateTypes;
    },
  },
  created() {
    this.enableAllSocietyCodes = this.isDeveloper || this.isAdmin;
  },
  methods: {
    mandateTerritoriesByIndex(index) {
      const result = this.mandateTerritories.find((mt) => mt.mandateIdx === index);
      return result ? result.territories : [];
    },
    searchTerritory(terms) {
      let result = [];
      if (terms && terms.trim().length >= 2) {
        result = this.countries.filter((country) =>
          country.name.toLowerCase().includes(terms.toLowerCase()),
        );
      }
      return result;
    },
    localTerritories(mandate) {
      if (mandate.territories && mandate.territories.length > 0) {
        return mandate.territories.map((territory) =>
          this.countries.find((country) => country.iso === territory),
        );
      }
      return [];
    },
    updateSocietyCode(mandateIdx, societyCode) {
      this.$emit('updateSocietyCode', { mandateIdx, societyCode });
    },
    updateMandateType(mandateIdx, mandateType) {
      this.$emit('updateMandateType', { mandateIdx, mandateType });
    },
    updateStartDate(mandateIdx, startDate) {
      this.$emit('updateStartDate', { mandateIdx, startDate });
    },
    updateEndDate(mandateIdx, endDate) {
      this.$emit('updateEndDate', { mandateIdx, endDate });
    },
    onAddTerritory(mandateIdx, event) {
      const territoriesToUpdate = this.mandateTerritories.find(
        (mt) => mt.mandateIdx === mandateIdx,
      );
      if (territoriesToUpdate) {
        territoriesToUpdate.territories = event.data;
      } else {
        this.mandateTerritories.push({ mandateIdx, territories: event.data });
      }
      this.$emit('addTerritory', { mandateIdx, territory: event.item });
    },
    onRemoveTerritory(mandateIdx, event) {
      const territoriesToUpdate = this.mandateTerritories.find(
        (mt) => mt.mandateIdx === mandateIdx,
      );
      if (territoriesToUpdate) {
        territoriesToUpdate.territories = event.data;
      }
      this.$emit('removeTerritory', { mandateIdx, territoryIdx: event.idx });
    },
    onAddMandate() {
      this.$emit('addMandate');
    },
    onRemoveMandate(mandateIdx, id) {
      this.$emit('removeMandate', { mandateIdx, id });
    },
  },
};
</script>

<style lang="scss" scoped>
.mandate-right {
  margin: var(--spacing) 0 var(--spacing-half);

  &:not(:last-child) {
    margin-bottom: 50px;
  }
  .select-date {
    width: 120px;
    float: left;
  }
  .date_separator {
    float: left;
    line-height: 30px;
    margin: 0 5px;
  }
  .col {
    line-height: 30px;
    padding: 0;
  }

  button {
    border: none;
    background-color: inherit;
    padding: 0;
    color: var(--grey--dark);
  }
}
.add-society {
  display: block;
  margin-bottom: var(--spacing);
}
</style>
