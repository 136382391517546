import Vue from 'vue';
import { transformApolloError } from '../common/errorTransformers';

/**
 * Query with Apollo Gql
 * @param apolloFunc the function (query | mutate)
 * @param payload the apollo payload
 * @param options the options { pickProp = prop on response to return, cache = [ network-only (default, no cache) | cache-and-network (cache then fetch behind) | cache-first (only cached) ]}
 * @returns {Promise.<void>}
 */
async function doWithApollo(apolloFunc, payload, options = {}) {
  try {
    const result = await apolloFunc(
      Object.assign(payload, { fetchPolicy: options.cachePolicy || 'network-only' }),
    );
    if (!options.pickProp) {
      return result.data;
    }
    return result.data[options.pickProp];
  } catch (error) {
    return Promise.reject(transformApolloError(error));
  }
}

export async function query(queryObj, options = {}) {
  return doWithApollo(Vue.$apollo.client.query, queryObj, options);
}

export async function mutate(mutationObj, options = {}) {
  options.cachePolicy = 'no-cache';
  return doWithApollo(Vue.$apollo.client.mutate, mutationObj, options);
}
