import { commit as _commit, dispatch as _dispatch, getter as _getter } from '../../utils';

export const namespace = 'revenue';

export function commit(methodName, obj) {
  _commit(namespace, methodName, obj);
}

export function dispatch(methodName, obj) {
  _dispatch(namespace, methodName, obj);
}

export function getter(methodName) {
  return _getter(namespace, methodName);
}

export function getAggregate() {
  return getter('aggregate');
}

export function setAggregate(aggregate) {
  dispatch('setAggregate', aggregate);
}

export function createAggregate() {
  dispatch('createAggregate');
}
