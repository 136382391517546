<template>
  <div>
    <multi-line-form
      v-if="alwaysOpen || viewForm"
      scope="add-performer-form"
      :display-action-buttons="displayActionButtons"
      :add-enabled="addEnabled"
      @save="save"
      @cancel="cancel"
    >
      <template slot="title">
        <h2 class="float-left">Add Existing Performer</h2>
      </template>
      <template slot-scope="{ index }">
        <add-performer-row
          :required="required"
          :scope="getScopeByIndex(index)"
          :inactive-ids="inactiveIds"
          @update="updateLineupRow(index, $event)"
        />
      </template>
    </multi-line-form>
    <div v-else>
      <div class="row">
        <h2>Add Existing Performer</h2>
      </div>
      <div class="edit-link">
        <a @click="displayForm" @keyup.enter="displayForm">
          <i class="far fa-plus-square" />
          Add performer
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AddPerformerRow from './add-performer-row';
import MultiLineForm from '../../ui/form/multi-line-form';

export default {
  name: 'AddExistingPerformers',
  components: {
    AddPerformerRow,
    MultiLineForm,
  },
  props: {
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    displayActionButtons: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lineupRows: [],
      viewForm: false,
      addEnabled: false,
    };
  },
  computed: {
    ...mapGetters('recording', ['lineup']),
    inactiveIds() {
      return this.lineup.map((l) => l.relation.id);
    },
  },
  methods: {
    async updateLineupRow(index, data) {
      this.lineupRows[index] = data;
      this.addEnabled = this.lineupRows.filter((lr) => lr.relation.id && lr.role).length > 0;
      this.$emit('update', this.filterValidRows(this.lineupRows));
    },
    save() {
      this.$emit('save', this.lineupRows);
    },
    cancel() {
      this.viewForm = false;
      this.lineupRows = [];
      this.$emit('cancel');
    },
    displayForm() {
      this.viewForm = true;
    },
    filterValidRows(rows) {
      return rows.filter((row) => row.relation && row.relation.id && row.role);
    },
    getScopeByIndex(idx) {
      return `add-performer-${idx}`;
    },
  },
};
</script>
