import AuthenticationService from '@/services/authenticationService';
import { transformAxiosError } from '@/common/errorTransformers';

export default function configure(Axios) {
  Axios.interceptors.request.use((config) => {
    const token = AuthenticationService.getAuthToken();
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  });
  Axios.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(transformAxiosError(error)),
  );
  return Axios;
}
