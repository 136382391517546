import ApolloClient from 'apollo-client';
import VueApollo from 'vue-apollo';
import apolloClientConfig from './apolloConfiguration';

export default {
  install(Vue) {
    // Create the apollo client
    const apolloClientInstance = new ApolloClient(apolloClientConfig());

    // Expose directly on vue as well (same as vue-apollo)
    Vue.use(VueApollo, {
      apolloClientInstance,
    });
    Vue.$apollo = { client: apolloClientInstance };
  },
};
