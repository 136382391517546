<template>
  <form :data-vv-scope="scope" class="cmp">
    <div v-if="editMode" class="row text--right">
      <div class="col s12">
        <action-buttons @save="update" @cancel="cancel" />
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <div v-if="editMode" class="col s2">Social security number</div>
        <div class="col s7">
          <validated-text-input
            name="person_info.social_security_number"
            label="Social security number"
            placeholder="YYYYMMDD-XXXX"
            :scope="scope"
            :rule="computedRule"
            :show-label="!editMode"
            :value="social_security_number"
            @input="updatePersonInfoSocialSecurityNumber"
          />
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">Protected identity</div>
        <div class="col s7">
          <input
            id="person_info.protected_identity"
            v-model="localProtectedIdentity"
            name="person_info.protected_identity"
            type="checkbox"
            class="filled-in"
            @change="_updatePersonInfoProtectedIdentity(!protected_identity)"
          />
          <label for="person_info.protected_identity">
            {{ !editMode ? 'Protected identity' : '' }}
          </label>
        </div>
      </div>

      <div class="row">
        <div v-if="editMode" class="col s2">
          First name
          <span class="mandatory">(mandatory)</span>
        </div>
        <div class="col s7">
          <validated-text-input
            ref="firstName"
            :scope="scope"
            name="person_info.first_name"
            label="First name"
            :show-label="!editMode"
            placeholder="E.g. Robin"
            :mandatory="true"
            :value="first_name"
            rule="required"
            @input="_updateFirstName"
          />
        </div>
      </div>

      <div class="row small-inner">
        <div v-if="editMode" class="col s2">
          Last name
          <span class="mandatory">(mandatory)</span>
        </div>
        <div class="col s7">
          <validated-text-input
            ref="lastName"
            :scope="scope"
            name="person_info.last_name"
            label="Last name"
            :show-label="!editMode"
            :mandatory="true"
            placeholder="E.g. Penn"
            :value="last_name"
            rule="required"
            @input="_updateLastName"
          />
        </div>
      </div>

      <div v-if="!middleNameActive" class="row">
        <div v-if="editMode" class="col s2"></div>
        <div class="col s7 edit-link">
          <a @click="showMiddleName">
            <i class="far fa-plus-square"></i>
            Add middle name
          </a>
        </div>
      </div>

      <div v-show="middle_name || middleNameActive" class="row">
        <div v-if="editMode" class="col s2">Middle name</div>
        <div class="col s7">
          <validated-text-input
            name="person_info.middle_name"
            label="Middle name"
            :show-label="!editMode"
            placeholder="E.g. Wright"
            :value="middle_name"
            rule=""
            @input="updatePersonInfoMiddleName"
          />
        </div>
      </div>

      <div class="row small-inner">
        <div v-if="editMode" class="col s2">Date of birth</div>
        <div class="col s7">
          <select-date
            :scope="scope"
            name="person_info.date_of_birth"
            :label="!editMode ? 'Date of birth' : ''"
            :value="date_of_birth"
            @input="_updateDateOfBirth"
          />
        </div>
      </div>

      <div v-show="date_of_death || dateOfDeathActive" class="row small-inner">
        <div v-if="editMode" class="col s2">Date of death</div>
        <div class="col s7">
          <select-date
            :scope="scope"
            name="person_info.date_of_death"
            :label="!editMode ? 'Date of death' : ''"
            :value="date_of_death"
            :rule="!!date_of_birth ? { after: date_of_birth } : {}"
            @input="_updateDateOfDeath"
          />
        </div>
      </div>

      <div v-show="!dateOfDeathActive && !date_of_death" class="row">
        <div v-if="editMode" class="col s2"></div>
        <div class="col s7 edit-link">
          <a @click="dateOfDeathActive = 'true'">
            <i class="far fa-plus-square"></i>
            Add date of death
          </a>
        </div>
      </div>

      <div v-if="editMode" class="row">
        <div class="col s2">Local codes</div>
        <div class="col s7">
          <local-codes
            name="general_info.local_codes"
            scope="associate"
            :show-label="false"
            :entries="local_codes"
            entry-key-name="code"
            entry-value-name="value"
            :local-codes="localCodeTypes"
            selectable-item-key-name="code"
            selectable-item-value-name="name"
            @add="addLocalCode"
            @input="updateLocalCode"
            @remove="removeLocalCode"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import * as _ from 'lodash';
import { mapState, mapMutations, mapGetters } from 'vuex';
import ActionButtons from '../../ui/button/action-buttons';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import SelectDate from '../../ui/select/select-date';
import LocalCodes from '../../ui/input/local-codes';
import { createLocalCode } from '../../../domain/common';
import { orgNoUnique } from '../org_no-input-validator';

export default {
  name: 'CreatePersonInfo',
  components: {
    ActionButtons,
    ValidatedTextInput,
    SelectDate,
    LocalCodes,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
      localProtectedIdentity: false,
    },
  },
  data() {
    return {
      scope: 'create-person-info',
      middleNameActive: false,
      dateOfDeathActive: false,
    };
  },
  computed: {
    ...mapState('associate', {
      social_security_number: (state) => state.person_info.social_security_number,
      first_name: (state) => state.person_info.first_name,
      last_name: (state) => state.person_info.last_name,
      date_of_birth: (state) => state.person_info.date_of_birth,
      date_of_death: (state) => state.person_info.date_of_death,
      middle_name: (state) => state.person_info.middle_name,
      protected_identity: (state) => state.person_info.protected_identity,
      local_codes: (state) => state.local_codes,
      stream_id: (state) => state.id,
    }),
    ...mapGetters('associate', ['type']),
    localCodeTypes() {
      return this.$store.state.appdata.localCodeTypes;
    },
    computedRule() {
      return Object.assign(
        {},
        {
          ssn: true,
          unique_org_no: { ...orgNoUnique, type: this.type, streamId: `${this.stream_id}` },
        },
        this.rule,
      );
    },
  },
  created() {
    this.localProtectedIdentity = this.protected_identity;
  },
  methods: {
    ...mapMutations('associate', [
      'updatePersonInfoSocialSecurityNumber',
      'updatePersonInfoFirstName',
      'updatePersonInfoDateOfBirth',
      'updatePersonInfoDateOfDeath',
      'updatePersonInfoLastName',
      'updatePersonInfoMiddleName',
      'updatePersonInfoProtectedIdentity',
      'updateLocalCodes',
    ]),
    showMiddleName() {
      this.middleNameActive = true;
    },
    _updateFirstName(firstName) {
      this.updatePersonInfoFirstName(firstName);
    },
    _updateLastName(val) {
      this.updatePersonInfoLastName(val);
    },
    _updatePersonInfoProtectedIdentity(protectedIdentity) {
      this.localProtectedIdentity = protectedIdentity;
      this.updatePersonInfoProtectedIdentity(protectedIdentity);
    },
    _updateDateOfBirth(dateOfBirth) {
      this.updatePersonInfoDateOfBirth(dateOfBirth);
    },
    _updateDateOfDeath(dateOfDeath) {
      this.updatePersonInfoDateOfDeath(dateOfDeath);
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    update() {
      this.$emit('update', this.$options.name);
    },
    addLocalCode() {
      const localCodes = _.cloneDeep(this.local_codes);
      localCodes.push(createLocalCode());
      this.updateLocalCodes(localCodes);
    },
    updateLocalCode(entryUpdate) {
      const localCodes = _.cloneDeep(this.local_codes);
      localCodes.splice(entryUpdate.index, 1, entryUpdate.entry);
      this.updateLocalCodes(localCodes);
    },
    removeLocalCode(index) {
      const localCodes = _.cloneDeep(this.local_codes);
      localCodes.splice(index, 1);
      this.updateLocalCodes(localCodes);

      if (localCodes.length === 0) {
        this.addLocalCode();
      }
    },
  },
};
</script>
