<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>{{ task }}</h1>
      </div>
    </div>
    <div class="row card">
      <spinner v-if="false" />
      <process-item
        v-else
        :id="id"
        :name="task"
        :log-rows="rows"
        :removeable="false"
        :limit-rows="0"
        :display-log-level="true"
      />
    </div>
  </div>
</template>

<script>
import { getProcessLogRows, LOG_LEVEL, isFinished } from '../../services/processService';
import ProcessItem from './process-item';
import Spinner from '../spinner';

export default {
  name: 'Process',
  components: {
    Spinner,
    ProcessItem,
  },
  data() {
    return {
      rows: [],
      loading: false,
      id: null,
      fetchTimeout: null,
      shouldFetch: true,
    };
  },
  computed: {
    task() {
      const first = this.rows[0];
      return first ? first.task : '';
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchLogRows();
  },
  destroyed() {
    clearTimeout(this.fetchTimeout);
    this.shouldFetch = false;
  },
  methods: {
    async fetchLogRows() {
      this.loading = true;
      const lastRow = this.rows[this.rows.length - 1];
      const lastTimestamp = lastRow && lastRow.timestamp;
      const rows = await getProcessLogRows(this.id, LOG_LEVEL.DEBUG, lastTimestamp);
      this.rows = [...this.rows, ...rows];
      this.loading = false;
      if (this.shouldFetch === true && !isFinished(this.rows)) {
        this.fetchTimeout = setTimeout(() => this.fetchLogRows(), 1000);
      }
    },
  },
};
</script>
