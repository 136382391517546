<template>
  <div>
    <div
      :class="{
        'header-bar--prod-warning':
          (!isTestEnvironment && (isDeveloper || isTester)) ||
          (isTestEnvironment && !isDeveloper && !isTester),
      }"
      class="header-bar"
      :style="isChristmasTime"
    >
      <div class="header-bar__star">
        <logo />
      </div>

      <div class="header-bar__search">
        <search v-if="!advancedSearchScope" placeholder="E.g. Joakim Berg" min-chars="2" />

        <div v-if="uuid" class="line-height" style="margin-right: 10px">
          <button class="btn copy_btn" @click="copyUuid">
            <i class="fas fa-copy"></i>
          </button>
        </div>
        <div v-if="isDeveloper" style="margin-right: 10px">
          <button class="btn" @click="copyAuthToken">Copy authToken</button>
        </div>
        <span
          v-if="isTestEnvironment"
          class="capitalize text--bold"
          style="color: var(--yellow); font-size: 18px"
        >
          | {{ !isDeveloper && !isTester ? 'WARNING: ' : '' }}{{ getEnvironment.toUpperCase() }} |
        </span>
        <span
          v-if="!isTestEnvironment && (isDeveloper || isTester)"
          class="capitalize text--bold"
          style="color: var(--yellow); font-size: 18px"
        >
          | WARNING: PROD |
        </span>
      </div>

      <div class="header-bar__menu">
        <nav>
          <ul class="header-bar__list">
            <li @click="toggleWorklistSidebar">
              <i class="fas fa-list-ul"></i>
            </li>
            <li
              class="header-bar__list-item"
              :class="{ illuminate: illuminateNoticeIcon }"
              @click="toggleProcessSidebar"
            >
              <i class="fas fa-clock"></i>
              <span v-if="processChanges.length > 0 && !processSidebarVisible" class="notice-badge">
                <span>{{ processChanges.length }}</span>
              </span>
            </li>
            <li
              class="header-bar__settings"
              @click="showSettings"
              @mouseleave="settingsVisible = false"
            >
              <i class="fas fa-user"></i>
              <ul
                :class="{
                  'header-bar__options': true,
                  'header-bar__options--visible': settingsVisible,
                }"
              >
                <li @click="logout">Log out</li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <worklist-sidebar :visible="worklistSidebarVisible" @onClose="toggleWorklistSidebar" />
    <process-sidebar
      :visible="processSidebarVisible"
      @onClose="toggleProcessSidebar"
      @processAdded="addProcessChange('added', $event)"
      @processFinished="addProcessChange('finished', $event)"
      @processError="addProcessChange('error', $event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import AuthenticationService from '../services/authenticationService';
import Logo from './logo';
import Search from './search/search';
import WorklistSidebar from './home/worklist/worklist-sidebar';
import ProcessSidebar from './process/process-sidebar';
import { getConfigValue, PROPS } from '../services/configService';
import searchHelper from './search/searchHelper';

export default {
  name: 'HeaderBar',
  components: {
    Search,
    Logo,
    WorklistSidebar,
    ProcessSidebar,
  },
  data() {
    return {
      advancedSearchScope: false,
      worklistSidebarVisible: false,
      processSidebarVisible: false,
      illuminateNoticeIcon: false,
      processChanges: [],
      settingsVisible: false,
      notice: 0,
      uuid: '',
    };
  },
  computed: {
    ...mapGetters('user', ['isDeveloper', 'isTester']),
    getEnvironment() {
      return getConfigValue(PROPS.ENVIRONMENT);
    },
    isTestEnvironment() {
      return getConfigValue(PROPS.ENVIRONMENT) !== 'prod';
    },
    isChristmasTime() {
      if (new Date().getMonth() === 11) {
        return {
          'background-image': `url('/snow.gif?v2')`,
          'background-size': '420px 70px',
        };
      }
      return {};
    },
  },
  watch: {
    $route() {
      this.updateScope();
      this.uuid = this.$router.currentRoute.params.id;
      this.worklistSidebarVisible = false;
      this.processSidebarVisible = false;
      this.illuminateNoticeIcon = false;
    },
  },
  created() {
    this.updateScope();
    this.uuid = this.validatedUUID(this.$router.currentRoute.params.id);
  },
  methods: {
    copyUuid() {
      const aux = document.createElement('input');
      aux.setAttribute('value', this.uuid);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);
    },
    validatedUUID(value) {
      return searchHelper.isUUID(value) ? value : false;
    },
    copyAuthToken() {
      const authToken = AuthenticationService.getAuthToken();
      const aux = document.createElement('input');
      aux.setAttribute('value', authToken);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);
    },
    showSettings() {
      this.settingsVisible = true;
      this.worklistSidebarVisible = false;
    },
    toggleWorklistSidebar() {
      this.processSidebarVisible = false;
      this.worklistSidebarVisible = !this.worklistSidebarVisible;
      this.settingsVisible = false;
    },
    logout: function logout() {
      AuthenticationService.logout();
      this.$router.push({
        name: 'authentication',
        params: { local: true },
      });
    },
    updateScope: function updateScope() {
      this.advancedSearchScope = _.includes(this.$router.currentRoute.path, '/search');
    },
    toggleProcessSidebar() {
      this.worklistSidebarVisible = false;
      this.processSidebarVisible = !this.processSidebarVisible;
      this.processChanges = [];
    },
    addProcessChange(type, data) {
      this.processChanges.push({
        type,
        data,
      });
      if (type === 'added') {
        this.illuminateNoticeIcon = true;
        setTimeout(() => {
          this.illuminateNoticeIcon = false;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss">
.header-bar {
  box-shadow: none;
  background-color: var(--green--dark);
  height: 56px;
  line-height: 56px;
  display: grid;
  grid-template-areas: 'star searchbar menu';
  grid-template-columns: 56px auto 168px;
  grid-template-rows: 56px;
  position: relative;

  &--prod-warning {
    background-color: var(--orange);
  }

  &__star {
    width: 56px !important;
    height: inherit;
    overflow: hidden;
    grid-area: star;

    a {
      color: white;
    }
  }

  &__search {
    grid-area: searchbar;
    display: flex;
    align-items: center;
    border-left: 2px solid var(--white);

    .quick-search {
      margin-left: var(--spacing);
      width: 640px;
      margin-top: 0;

      .row {
        margin: 0 auto;
      }
    }
  }

  &__logo {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    background-color: var(--green);

    &:hover {
      background-color: var(--green--light);
    }

    img {
      width: 36px;
    }

    i {
      font-size: 24px;
    }
  }

  &__menu {
    padding-right: 0 !important;
    grid-area: menu;

    nav {
      background-color: inherit;
      font-weight: bold;
    }

    li {
      cursor: pointer;

      &:hover {
        background-color: var(--green--medium);
      }

      ul {
        background-color: var(--grey--dark);
      }
    }
  }

  &__options {
    display: none;
    position: absolute;
    right: 0;
    top: 56px;

    &--visible {
      display: block;
      box-shadow:
        0 2px 4px 0 rgba(0, 0, 0, 0.2),
        0 0 2px 0 rgba(0, 0, 0, 0.12);
    }
  }

  &__list {
    display: flex;
    align-items: center;

    li {
      width: 56px;
      height: 56px;
      box-shadow: -2px 0px 0px 0px var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }

    &-item {
      position: relative;
      transition: background-color 0.2s;

      &.illuminate {
        background-color: #ffb614;
      }

      .notice-badge {
        position: absolute;
        top: 14px;
        right: 9px;
        background: var(--red);
        height: 14px;
        width: 14px;
        border-radius: 50%;

        span {
          top: -22px;
          font-size: 10px;
          position: absolute;
          right: 3.5px;
        }
      }
    }
  }

  &__settings {
    position: relative;
    z-index: 2;

    ul li {
      width: 200px;
      line-height: 36px;
      font-size: 12px;
      background: var(--white);
      color: var(--black);
      overflow: visible;

      &:hover {
        color: var(--white);
      }
    }
  }

  .row {
    margin-bottom: 0;
  }

  .line-height {
    line-height: 1em;

    .copy_btn {
      height: 32px;
    }
  }
}
</style>
