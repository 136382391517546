/**
 * The Main Artist Service contains main artist related methods, such as CRUD.
 */
import Vue from 'vue';
import * as uuid from 'uuid';
import clone from '../common/clone';
import { getConfigValue, PROPS } from './configService';
import { flattenLocalCodes } from '../common/aggregateUtils';
import merge from '../common/objectMerger';
import prepareForWire from './wirePrepper';
import {
  createMainArtistAggregate,
  createMainArtistCommand,
  createUpdateBasicInfoCommand,
  createUpdatePerformersCommand,
  createAddPerformerCommand,
  createRemovePerformerCommand,
} from '../domain/mainArtistDomain';
import gql from '../domain/mainArtistGql';
import { query } from './apolloRequest';

const MAIN_ARTISTS_URL = `${getConfigValue(PROPS.PNR_URL)}/mainartist`;

function addCommandIds(command, aggregate) {
  command.version = aggregate.version;
  command.stream_id = aggregate.id;
  command.process_id = aggregate.id;
  command.timestamp = new Date();
}

function prepareDataForWire(command) {
  prepareForWire(command);
  return flattenLocalCodes(command);
}

function createUpdateMainArtistBasicInfoCommand(aggregate) {
  const updateCommand = createUpdateBasicInfoCommand();
  addCommandIds(updateCommand, aggregate);
  updateCommand.payload.basic_info = merge(updateCommand.payload.basic_info, aggregate.basic_info);
  prepareDataForWire(updateCommand.payload.basic_info);
  return updateCommand;
}

function createAddPerformerMainArtistCommand(mainArtistId, performerRelation) {
  const updateCommand = createAddPerformerCommand();
  updateCommand.stream_id = mainArtistId;
  updateCommand.process_id = performerRelation.id;
  updateCommand.timestamp = new Date();
  updateCommand.version = 0;
  updateCommand.payload.performer = performerRelation;
  prepareDataForWire(updateCommand.payload.performer);
  return updateCommand;
}

function createRemovePerformerMainArtistCommand(mainArtistId, performerId) {
  const updateCommand = createRemovePerformerCommand();
  updateCommand.process_id = performerId;
  updateCommand.stream_id = mainArtistId;
  updateCommand.timestamp = new Date();
  updateCommand.version = 0;
  updateCommand.payload.performerId = performerId;
  return updateCommand;
}

function processAggregate(aggregate) {
  // Merge fetched main artist with the known domain model, retain known domain model fields and omit unknown on fetched main artist.
  return merge(createMainArtistAggregate(), aggregate);
}

function createUpdateMainArtistPerformersCommand(aggregate) {
  const updateCommand = createUpdatePerformersCommand();
  const id = uuid.v4();
  addCommandIds(updateCommand, aggregate);
  updateCommand.payload.performers = merge(updateCommand.payload.performers, aggregate.performers);
  updateCommand.payload.new_performers = merge(
    updateCommand.payload.new_performers,
    aggregate.new_performers,
  );
  updateCommand.payload.new_performers.forEach((performerCommand) => {
    performerCommand.process_id = id;
    performerCommand.timestamp = updateCommand.timestamp;
    performerCommand.version = updateCommand.version;
  });
  prepareForWire(updateCommand.payload);
  return updateCommand;
}

function createMainArtistAggregateCommand(basicInfo, performers, newPerformers) {
  const createCommand = createMainArtistCommand();
  const id = uuid.v4();
  createCommand.process_id = id;
  createCommand.stream_id = id;
  createCommand.timestamp = new Date();
  createCommand.version = 0;
  createCommand.payload.basic_info = basicInfo;
  createCommand.payload.performers = performers;
  createCommand.payload.new_performers = newPerformers;
  createCommand.payload.new_performers.forEach((performerCommand) => {
    performerCommand.process_id = id;
    performerCommand.timestamp = createCommand.timestamp;
    performerCommand.version = createCommand.version;
  });
  return prepareDataForWire(createCommand);
}

async function getMainArtist(idTerm, versionTerm = null) {
  const res = await query(
    { query: gql.getFullMainArtist, variables: { idTerm, versionTerm } },
    { pickProp: 'mainArtistAggregate' },
  );
  return processAggregate(clone(res));
}

export default {
  createAddPerformerMainArtistCommand,
  createRemovePerformerMainArtistCommand,

  getMainArtist,
  getMainArtistPerformers: (idTerm) =>
    query(
      { query: gql.getMainArtistPerformers, variables: { idTerm } },
      { pickProp: 'mainArtistAggregate' },
    ),
  createMainArtist: (basicInfo, performers, newPerformers) =>
    Vue.$http
      .post(
        `${MAIN_ARTISTS_URL}/creation`,
        createMainArtistAggregateCommand(basicInfo, performers, newPerformers),
      )
      .then((response) => Promise.resolve(response.data.id)),
  updateMainArtistBasicInfo: (aggregate) =>
    Vue.$http
      .post(`${MAIN_ARTISTS_URL}/basic-info`, createUpdateMainArtistBasicInfoCommand(aggregate))
      .then((response) => Promise.resolve(response.data.id)),
  updateMainArtistPerformers(aggregate) {
    return Vue.$http
      .post(`${MAIN_ARTISTS_URL}/performers`, createUpdateMainArtistPerformersCommand(aggregate))
      .then((response) => Promise.resolve(response.data.id));
  },
  addPerformer(mainArtistId, performerRelation) {
    return Vue.$http.post(
      `${MAIN_ARTISTS_URL}/add-performer`,
      createAddPerformerMainArtistCommand(mainArtistId, performerRelation),
    );
  },
  removePerformer(mainArtistId, performerId) {
    return Vue.$http.post(
      `${MAIN_ARTISTS_URL}/remove-performer`,
      createRemovePerformerMainArtistCommand(mainArtistId, performerId),
    );
  },
  mainArtistMatchSearch: async (name) =>
    query(
      { query: gql.mainArtistMatchSearch, variables: { name } },
      { pickProp: 'mainArtistMatch' },
    ),
};
