import { createError, ErrorType } from './starNotification';

export default function createUploadFileDescriptor(
  entityType = null,
  entityId = null,
  metaIdentifiers = [],
  localFile = null,
) {
  if (!(entityType && entityId && localFile)) {
    throw createError(
      ErrorType.ILLEGAL_ARGUMENT,
      'Entity type, entity id and localFile is required',
    );
  }
  return {
    createdEpoch: Math.floor(Date.now() / 1000),
    entityType,
    entityId,
    metaIdentifiers,
    localFile,
    fileSuffix: localFile.name.split('.').pop(),
  };
}
