<template>
  <form>
    <div v-if="!hideLabel" class="row">
      <div class="col s12">
        <h2>Agreements</h2>
      </div>
    </div>
    <template v-if="agreements.length > 0 && editMode">
      <div class="row">
        <div class="col s12 text--right">
          <action-buttons @save="onSave" @cancel="onCancel" />
        </div>
      </div>
    </template>

    <div class="row" :class="{ card: agreements.length > 0 }">
      <div class="col s12">
        <div v-for="(agreement, index) in agreements" :key="agreement._localId">
          <add-agreement
            :ref="`create-agreement-${index}`"
            :name="`create-agreement-${index}`"
            scope="add-agreement"
            :agreement="agreement"
            :edit-mode="inlineEditMode"
            :deletable="agreements.length > 1"
            @input="updateAgreement(index, $event)"
            @remove="onRemoveAgreement(index)"
          />
        </div>
      </div>
    </div>

    <div class="row edit-link">
      <a :class="{ disabled: !lastHasEntry }" @click="onAddAgreement">
        <i class="far fa-plus-square" />
        Add agreement
      </a>
    </div>
  </form>
</template>

<script>
import * as uuid from 'uuid';
import { createAgreement } from '../../../domain/societyDomain';
import AddAgreement from './add-agreement';
import ActionButtons from '../../ui/button/action-buttons';

export default {
  name: 'CreateAgreements',
  components: {
    AddAgreement,
    ActionButtons,
  },
  inject: ['$validator'],
  props: {
    inlineEditMode: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      agreements: [],
    };
  },
  computed: {
    lastHasEntry() {
      return this.agreements.length === 0 || !!this.agreements.slice(-1)[0].from_date;
    },
  },
  watch: {
    agreements() {
      this.setLocalIds();
    },
  },
  mounted() {
    this.setLocalIds();
    if (!this.editMode) {
      this.addAgreement();
    }
  },
  methods: {
    setLocalIds() {
      this.agreements.forEach((agreement) => {
        if (!agreement._localId) {
          agreement._localId = uuid.v4();
        }
      });
    },
    updateAgreement(index, agreement) {
      this.agreements.splice(index, 1, agreement);
      this.emitUpdate();
    },
    removeAgreement(index) {
      this.agreements.splice(index, 1);
      this.emitUpdate();
    },
    addAgreement() {
      if (this.lastHasEntry) {
        this.agreements.push(createAgreement());
      }
    },
    onAddAgreement() {
      if (this.lastHasEntry) {
        this.addAgreement();
      }
    },
    onRemoveAgreement(index) {
      this.agreements.splice(index, 1);
      if (this.agreements.length === 0) {
        this.agreements.push(createAgreement());
      }
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit(
        'input',
        this.agreements.filter((a) => a.from_date),
      );
    },
    onSave() {
      this.$emit(
        'save',
        this.agreements.filter((a) => a.from_date),
      );
    },
    onCancel() {
      this.agreements = [];
      this.$emit('cancel', this.$options.name);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding-bottom: 24px !important;
}
</style>
