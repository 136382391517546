import * as uuid from 'uuid';
import { createRecordingAggregate } from '@/domain/recordingDomain';

export function createRecordingReference() {
  return {
    id: null,
    side_no: null,
    track_no: null,
  };
}

export function createTrack() {
  return {
    track_title: null,
    duration_sec: null,
    recording: createRecordingReference(),
    recordingAggregate: createRecordingAggregate(),
  };
}

export function createBasicInfo() {
  return {
    name: null,
    alternate_titles: [],
    main_artist: null,
    catalog_number: null,
    recorded_in_country: null,
    release_date: null,
    produced_in_country: null,
    genre: null,
    label: null,
    composer: null,
    local_codes: [],
    description: null,
    tags: [],
  };
}

export function createAlbumAggregate() {
  return {
    id: null,
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    basic_info: createBasicInfo(),
    tracks: [],
    readonly: false,
    merged_with: [],
  };
}

export function createAlbumCommand() {
  return {
    type: 'album:create',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      basic_info: null,
      tracks: [],
    },
  };
}

export function createUpdateBasicInfoCommand() {
  return {
    type: 'album:updateBasicInfo',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      basic_info: null,
    },
  };
}

export function createUpdateTracksCommand(upsert = false) {
  return {
    type: 'album:updateTracks',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      tracks: [],
      upsert,
    },
  };
}
