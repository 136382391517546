<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>

    <div v-show="!loading" class="row card">
      <div class="col s12">
        <table>
          <CompareSort
            :bulk-enabled="false"
            :marked-for-bulk="allAreSelected()"
            :expanded-content="true"
            :columns="columns"
            :more-options="false"
            :default-sort-column-index="0"
            :default-sort-should-override="true"
            :expand-enabled="false"
            @markAll="toggleSelectAll()"
            @sort="sort"
          />

          <CompareMediarcMatching
            ref="comparelist"
            :album="isAlbum"
            :is-expanded="isExpanded"
            :toggle-expanded="toggleExpanded"
            :handle-unidentify-btn="handleUnidentifyBtn"
            :candidate-changed="candidateChanged"
            :handle-save-btn="handleSaveBtn"
            :handle-new-btn="handleNewBtn"
            :selectable="false"
            :expand-enabled="false"
            @change="updateDirtyState"
          />
        </table>

        <div class="result__pagination">
          <Pagination
            v-if="numberOfPages() > 0"
            :number-of-pages="numberOfPages()"
            :selected-page="pagination.currentPage"
            :number-of-hits="totalCount"
            :hits-per-page="pagination.hitsPerPage"
            @selectPage="selectPage"
            @updateHitsPerPage="updateHitsPerPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompareFieldsMixin from '../../../common/compareFieldsMixin';
import ViewMediarcReportMixin from './view-mediarc-report-mixin';
import PaginationAndSortMixin from '../../../common/paginationAndSortMixin';
import CompareSort from '../../compare/compare-sort';
import CompareMediarcMatching from '../../compare/compare-mediarc-matching';
import Pagination from '../../pagination';

export default {
  name: 'ViewMediarcReportDoneLines',
  components: {
    CompareSort,
    Pagination,
    CompareMediarcMatching,
  },
  mixins: [CompareFieldsMixin, ViewMediarcReportMixin, PaginationAndSortMixin],
  data() {
    return {
      match_state: 'D',
    };
  },
  async activated() {
    await this.loadPaginated();
  },
};
</script>
