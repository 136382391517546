<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2 class="float-left">Extra Information and Meta Data</h2>
      </div>
    </div>
    <form data-vv-scope="create-meta-info">
      <div>
        <div v-if="editMode" class="row text--right">
          <div class="col s12 float-right">
            <action-buttons @save="update" @cancel="cancel" />
          </div>
        </div>

        <create-tags
          v-if="!editMode"
          scope="tags-associate"
          entity-type="associate"
          path="meta_info"
          add-method="addToMetaInfoTags"
          remove-method="removeFromMetaInfoTags"
        />
      </div>
    </form>
  </div>
</template>

<script>
import ActionButtons from '../../ui/button/action-buttons';
import CreateTags from '../../tags/create-tags';

export default {
  name: 'CreateMetaInfo',
  components: {
    ActionButtons,
    CreateTags,
  },
  inject: ['$validator'],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    update() {
      this.$emit('update', this.$options.name);
    },
  },
};
</script>
