<template>
  <div class="e2e-phone-input">
    <label class="label" :class="{ 'label--error': showErrorMessage }">
      {{ label }}
    </label>
    <div v-for="(phoneNumber, index) in phoneNumbers" :key="index" class="row small-inner">
      <div class="col s4 hard--left e2e-phone-input-type">
        <select-generic
          v-model="phoneNumber.type"
          :name="`${name}.${index}.type`"
          :disabled="disabled"
          :data="phoneNumberTypes"
          @input="onInput"
        />
      </div>
      <div class="col s8 hard--right e2e-phone-input-number">
        <input
          ref="inputField"
          v-model="phoneNumber.number"
          v-validate="'phonenumber:true'"
          autocomplete="off"
          type="tel"
          :data-vv-as="label ? label.toLowerCase() : 'phone number'"
          :name="`${name}.${index}.number`"
          :label="label ? label.toLowerCase() : 'phone number'"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="onInput"
          @keydown.enter.prevent
        />
        <span v-show="validationErrors.has(name + '.' + index, scope)" class="help is-danger">
          <i class="fas fa-times-circle" />
          {{ validationErrors.first(name + '.' + index, scope) }}
        </span>
      </div>
      <delete-button v-if="phoneNumbers.length > 1" @click="onRemove(index)" />
    </div>

    <div class="row flush--bottom edit-link">
      <a :class="{ disabled: !lastHasEntry }" @click="addPhoneNumber(phoneNumbers.length - 1)">
        <i class="far fa-plus-square" />
        Add {{ label }}
      </a>
    </div>
  </div>
</template>

<script>
import { PHONE_TYPES } from '../../../domain/common';
import DeleteButton from '../button/delete-button';
import SelectGeneric from '../select/select-generic';

export default {
  name: 'PhoneNumberInput',
  components: {
    DeleteButton,
    SelectGeneric,
  },
  inject: ['$validator'],
  props: {
    name: String,
    scope: { type: String, default: undefined },
    label: { type: String, default: '' },
    placeholder: { type: String, default: 'E.g. 070 111 22 33' },
    phoneNumberTypes: { type: Object, default: () => PHONE_TYPES },
    phoneNumbers: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
  },
  computed: {
    showErrorMessage() {
      for (let idx = 0; idx < this.phoneNumbers.length; idx++) {
        if (this.validationErrors.has(`${this.name}.${idx}`, this.scope)) {
          return true;
        }
      }
      return false;
    },
    lastHasEntry() {
      const fieldName = `${this.name}.${this.phoneNumbers.length - 1}`;
      const field =
        this.scope && this.validationFields && this.validationFields[`$${this.scope}`]
          ? this.validationFields[`$${this.scope}`][`${fieldName}.number`]
          : this.validationFields[fieldName];

      const lastEntry = this.phoneNumbers[this.phoneNumbers.length - 1];
      if (field) {
        return (
          lastEntry !== -1 && lastEntry.number !== null && lastEntry.number !== '' && field.valid
        );
      }
      return false;
    },
  },
  methods: {
    addPhoneNumber(index) {
      this.$emit('add', index);
    },
    onInput() {
      this.$emit('input', this.phoneNumbers);
    },
    onRemove(index) {
      this.$emit('remove', index);
    },
  },
};
</script>
