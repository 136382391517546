<template>
  <div>
    <div class="title">New Reported Channels</div>
    <section class="widget-table">
      <header>
        <div class="widget-table--col">Channel</div>
        <div class="widget-table--col">Date</div>
      </header>
      <div v-for="channel in payload.items" :key="channel.id" class="widget-table--row">
        <div class="widget-table--col">{{ channel.code }}</div>
        <div class="widget-table--col">{{ $filters.formatDate(channel.date_changed) }}</div>
      </div>
    </section>

    <section class="widget-link">
      <router-link to="/distribution/distribution-channel">
        Show Channels
        {{ payload.total_count > 5 ? '(' + (payload.total_count - 5) + ' more)' : '' }}
      </router-link>
    </section>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'NewChannelsWidget',
  mixins: [WidgetMixin],
};
</script>
