<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h1>Preview</h1>
      </div>
    </div>
    <div v-if="!error && !loading" class="row card">
      <table>
        <thead>
          <tr>
            <td v-for="(column, index) of columns" :key="`${index}-col`">{{ column }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) of rows" :key="`${index}-row`">
            <td v-for="(value, vIndex) of Object.values(row)" :key="`${index}-${vIndex}`">
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else-if="loading">
      <spinner />
    </div>
    <div v-else class="row">
      <h2>Something went wrong downloading the file or link may have expired.</h2>
    </div>
  </div>
</template>

<script>
import RemoteFileService from '../../services/remoteFileService';
import Spinner from '../spinner';

export default {
  name: 'PreviewJson',
  components: {
    Spinner,
  },
  data() {
    return {
      rows: undefined,
      error: false,
      loading: false,
      columns: [],
    };
  },
  async created() {
    const url = this.$router.currentRoute.query.url;
    if (url) {
      this.downloadFile(url);
    }
  },
  methods: {
    async downloadFile(url) {
      this.loading = true;
      try {
        this.rows = await RemoteFileService.downloadBinary(url, { responseType: 'json' });

        // Currently this component only support json arrays, feel free to add support for objects if needed.
        if (!Array.isArray(this.rows) || !this.rows.length) {
          throw new Error('File content is empty or not supported');
        }

        this.columns = Object.keys(this.rows[0]);
      } catch (error) {
        this.error = true;
        console.error(error);
      }
      this.loading = false;
    },
  },
};
</script>
