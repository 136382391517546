<template>
  <div>
    <bulk-table
      ref="agreementTable"
      name="Agreements"
      :columns="columns"
      :item-ids="itemIds"
      :edit-buttons-enabled="false"
      :edit-mode="editMode"
      :enable-row-options="!disabled"
      @bulkAction="onBulkAction"
      @editItem="onEditItem"
      @deleteItem="onDeleteItem"
      @sort="onSort"
    >
      <template v-for="(child, index) in model">
        <template v-if="editItemId === child.id" :slot="child.id">
          <td :key="`${child.id}-td1`" colspan="4">
            <add-agreement
              :ref="`edit-agreement-${index}`"
              :society-name="societyName"
              :name="`edit-agreement-${index}`"
              scope="add-agreement"
              :agreement="child"
              :deletable="model.length > 1"
              :edit-mode="true"
              @save="onEditItemSave(index, $event)"
              @cancel="onEditItemCancel"
            />
          </td>
        </template>

        <template v-if="editItemId !== child.id" :slot="child.id">
          <td :key="`${child.id}-td2`" class="thin-column dropdown-button__arrow">
            <i
              v-if="expanded(child.id)"
              class="fas fa-caret-up"
              @click="toggleDetails(child.id, $event)"
            />
            <i
              v-else-if="!expanded(child.id)"
              class="fas fa-caret-down"
              @click="toggleDetails(child.id, $event)"
            />
          </td>
          <td :key="`${child.id}-td3`">
            <span
              v-if="isActive(child.from_date, child.to_date)"
              v-t="'common.yes'"
              class="fs-14"
            />
            <span v-else v-t="'common.no'" class="fs-14" />
          </td>
          <td :key="`${child.id}-td4`" class="nowrap">
            <span>{{ child.from_date }} -</span>
            <span v-if="child.to_date">{{ child.to_date }}</span>
            <span v-else>present</span>
          </td>
          <td :key="`${child.id}-td5`">
            <div v-if="child.rights">
              <ul>
                <li>
                  <span v-for="(right, index2) in child.rights" :key="index2" class="csv-item">
                    {{ $filters.formatRight(right) }}
                  </span>
                </li>
              </ul>
            </div>
            <div v-else>
              <span class="None">None</span>
            </div>
          </td>
        </template>

        <template :slot="`expand_${child.id}`">
          <tr v-if="expanded(child.id)" :key="`${child.id}-tr1`" class="expand-info">
            <td class="thin-column" />
            <td colspan="3">
              <div class="expand-info__meta">
                <div class="container">
                  <div>
                    <span class="expand-info__meta__title">Agreement sign date:</span>
                    <span v-if="child.sign_date">{{ child.sign_date }}</span>
                    <span v-else class="none">None</span>
                  </div>
                  <div>
                    <span class="expand-info__meta__title">Contract:</span>
                    <a
                      v-if="child.contract && child.contract.url"
                      @click="onDownloadContract(child)"
                    >
                      <i class="far fa-file-alt" />
                      {{ getFileName(child.contract.url) }}
                    </a>
                    <span v-else class="none">None</span>
                  </div>
                  <div>
                    <span class="expand-info__meta__title">Type:</span>
                    <span v-if="child.type">{{ child.type }}</span>
                    <span v-else class="none">None</span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </template>
    </bulk-table>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import RemoteFileService from '../../../services/remoteFileService';
import AddAgreement from '../create/add-agreement';
import BulkTable from '../../ui/table/bulk-table';
import clone from './../../../common/clone';
import DownloadLink from '../../ui/file/downloadLink';
import extractRemoteFileName from '../../../common/extractRemoteFileName';

export default {
  name: 'AgreementInformation',
  components: {
    BulkTable,
    AddAgreement,
  },
  props: {
    societyName: { type: String },
    agreements: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      model: clone(this.agreements),
      editItemId: null,
      editItem: null,
      editMode: false,
      expandedItemIds: [],
      selectedItemIds: [],
      columns: [
        {
          thinColumn: true,
        },
        {
          name: 'Active',
          ascending: true,
          sortParam: 'active',
          active: true,
        },
        {
          name: 'Agreement Period',
          ascending: true,
          sortParam: '',
          active: true,
        },
        {
          name: 'Categories of Right',
          ascending: true,
          sortParam: '',
          active: true,
        },
      ],
    };
  },
  computed: {
    itemIds() {
      return this.model.map((child) => child.id);
    },
    hasItems() {
      return this.model && this.model.length > 0;
    },
  },
  methods: {
    isActive(startDate, endDate) {
      return moment().isBetween(startDate, !endDate ? moment().endOf('day') : endDate);
    },
    expanded(id) {
      return _.includes(this.expandedItemIds, id) && this.editItemId !== id;
    },
    toggleDetails(id, e) {
      const index = _.indexOf(this.expandedItemIds, id);
      const row = e.target.closest('tr');
      if (index === -1) {
        this.expandedItemIds.push(id);
        row.style.whiteSpace = 'normal';
      } else {
        this.expandedItemIds.splice(index, 1);
        row.style.whiteSpace = 'nowrap';
      }
    },
    getFileName(url) {
      return extractRemoteFileName(url);
    },
    isEditRowItem(id) {
      return id.toString() === this.editItemId;
    },
    onEditItem(id) {
      this.editItemId = id;
      this.editItem = this.model[id];
      this.editMode = true;
    },
    onEditItemSave(index, agreement) {
      this.$emit('input', agreement);
    },
    onEditItemCancel() {
      if (this.$refs.agreementTable) {
        this.$refs.agreementTable.cancelEdit();
      }
      this.editItemId = null;
      this.editItem = null;
      this.editMode = false;
      this.model = clone(this.agreements);
    },
    onDeleteItem(id) {
      this.$emit('remove', id);
    },
    async onDownloadContract(agreement) {
      const fileData = await RemoteFileService.downloadFromDocumentRepository(
        agreement.contract.url,
      );
      DownloadLink.direct(fileData, agreement.contract.url);
    },
    onSort(column) {
      const sortOrder = column.ascending ? 'asc' : 'desc';
      const sortParam = column.sortParam;
      const sorted = _.orderBy(
        this.model,
        [
          function sortValue(child) {
            return child[sortParam];
          },
        ],
        [sortOrder],
      );
      this.model = sorted;
    },
    onBulkAction(type) {
      switch (type) {
        case 'remove':
          this.deleteItems(this.selectedItemIds);
          break;
        case 'edit':
          break;
        default:
          console.log(`Unknown event type: ${type}`);
      }
    },
    deleteItems(ids) {
      this.model = this.model.filter((child) => !ids.includes(child.id));
      this.emit();
    },
    emit() {
      this.$emit('input', this.model);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.expand-info__meta {
  background-color: var(--white) !important;
  .container {
    border: 1px solid var(--grey--light);
    padding: 20px;
  }
}
</style>
