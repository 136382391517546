<template>
  <validated-text-input
    class="input"
    :class="{ locked }"
    :disabled="locked"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #input:after>
      <div class="lock" @click="onToggleLock">
        <i v-if="locked" class="fa fa-lock"></i>
        <i v-if="!locked" class="fa fa-lock-open"></i>
      </div>

      <span v-if="protectionHelpText" class="protection-help-text">
        {{ protectionHelpText }}
      </span>
    </template>
  </validated-text-input>
</template>

<script>
import ValidatedTextInput from './validated-text-input';

export default {
  name: 'ProtectedTextInput',
  components: {
    ValidatedTextInput,
  },

  props: {
    lockedInitially: {
      type: Boolean,
      required: false,
      default: false,
    },
    protectionHelpText: {
      type: String,
      required: false,
      default: '',
    },
  },

  data: () => ({
    locked: false,
  }),

  created() {
    this.locked = this.lockedInitially;
  },

  methods: {
    onToggleLock() {
      this.locked = !this.locked;
    },
  },
};
</script>

<style lang="scss" scoped>
.lock {
  cursor: pointer;

  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 34px;

  background-color: #dfdfdf;
  border: solid 1px rgba(59, 59, 59, 0.25);

  &:hover {
    background-color: #ebebeb;
  }
}

.input {
  input {
    flex: 1;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      & + .lock {
        border-color: rgba(59, 59, 59, 0.8);
      }
    }
  }

  &.locked:hover {
    .lock {
      background-color: #ebebeb;
      border-color: rgba(59, 59, 59, 0.8);
    }
  }

  .help {
    flex-basis: 100%;
  }
}

.protection-help-text {
  flex-basis: 100%;
}
</style>
