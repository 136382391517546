<template>
  <div>
    <h3>Tasks</h3>
    <p v-if="processes.length === 0">No tasks to display</p>
    <ul class="process-list">
      <li v-for="(proc, idx) of processes" :key="idx" class="process-item">
        <process-item v-bind="proc" @remove="removeProcess(proc)" />
      </li>
    </ul>
  </div>
</template>

<script>
import ProcessItem from './process-item';
import { COMPLETE_STATES } from '../../store/modules/process/store';

export default {
  name: 'ProcessList',
  components: {
    ProcessItem,
  },
  props: {
    processIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    processes() {
      const prcs = this.$store.state.process.processes
        .slice()
        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      if (this.processIds.length > 0) {
        return prcs.filter((p) => this.processIds.includes(p.id));
      }
      return prcs;
    },
    finishedProcesses() {
      return this.processes.filter((p) => COMPLETE_STATES.includes(p.state));
    },
    errors() {
      if (this.processes.length > 0) {
        return this.processes.reduce((acc, cur) => (cur.error ? [...acc, cur.error] : acc), []);
      }
      return [];
    },
  },
  watch: {
    processes(newVal, oldVal) {
      this.emitArrayAdditions(newVal, oldVal, 'processAdded');
    },
    finishedProcesses(newVal, oldVal) {
      this.emitArrayAdditions(newVal, oldVal, 'processFinished');
    },
    errors(newVal, oldVal) {
      this.emitArrayAdditions(newVal, oldVal, 'processError');
    },
  },
  methods: {
    emitArrayAdditions(newVal, oldVal, eventType) {
      if (newVal.length > oldVal.length) {
        newVal.slice(oldVal.length).forEach((p) => {
          this.$emit(eventType, p);
        });
      }
    },
    removeProcess(proc) {
      this.$store.dispatch('process/removeProcess', proc.id);
    },
  },
};
</script>
