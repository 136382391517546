<template>
  <form :data-vv-scope="scope">
    <div class="row">
      <div class="col s8">
        <h2 class="float-left">Associates</h2>
      </div>
    </div>
    <template v-if="associates && associates.length > 0">
      <div class="card">
        <template v-for="(associate, index) in associates">
          <hr v-if="index > 0" :key="`${index}-hr`" />
          <div :key="index">
            <edit-associate
              :associate="associate"
              :index="index"
              :namespace="namespace"
              :relation-type="relationType"
              :required="required"
              @remove="onRemove(index)"
            />
          </div>
        </template>
      </div>
    </template>
    <div class="row">
      <div class="col s12 edit-link">
        <a :class="{ disabled: !lastHasEntry }" @click="addNewAssociation">
          <i class="far fa-plus-square" />
          Add associated
        </a>
      </div>
    </div>
  </form>
</template>

<script>
import AssociatesMixin from './associates-mixin';
import EditAssociate from './edit-associate';

export default {
  name: 'CreateAssociates',
  components: {
    EditAssociate,
  },
  mixins: [AssociatesMixin],
  inject: ['$validator'],
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lastHasEntry() {
      return this.associates.every((a) => a.type && a.name && a.start_date);
    },
  },
  methods: {
    onRemove(idx) {
      this.associates.splice(idx, 1);
    },
  },
};
</script>
