/**
 * The Recording Service contains recording related methods, such as CRUD.
 */
import * as uuid from 'uuid';
import { mutate, query } from './apolloRequest';
import { flattenLocalCodes } from '../common/aggregateUtils';
import { getConfigValue, PROPS } from './configService';
import MainArtistService from './mainArtistService';
import merge from '../common/objectMerger';
import post from './postRequest';

import {
  createRecordingAggregate,
  createRecordingCommand,
  createUpdateBasicInfoCommand,
  createUpdateDistributionStateCommand,
  createUpdateLineupCommand,
  isEmptyPerformerReference,
} from '../domain/recordingDomain';
import gql from '../domain/recordingGql';
import prepareForWire from './wirePrepper';
import AuthenticationService from './authenticationService';

const RECORDINGS_URL = `${getConfigValue(PROPS.PNR_URL)}/recordings`;
const VRDB2_URL = `${getConfigValue(PROPS.PNR_URL)}/vrdb2`;

function addCommandIds(command, aggregate) {
  command.version = aggregate.version || 0;
  command.stream_id = aggregate.id || uuid.v4();
  command.process_id = uuid.v4();
  command.timestamp = new Date();
}

function prepareRecordingDataForWire(recordingData) {
  prepareForWire(recordingData);
  return flattenLocalCodes(recordingData);
}

function createUpdateRecordingBasicInfoCommand(aggregate) {
  const updateCommand = createUpdateBasicInfoCommand();
  addCommandIds(updateCommand, aggregate);
  updateCommand.payload.basic_info = merge(updateCommand.payload.basic_info, aggregate.basic_info);
  prepareRecordingDataForWire(updateCommand.payload.basic_info);
  return updateCommand;
}

function processAggregate(aggregate) {
  // Merge fetched performer with the known domain model, retain known domain model fields and omit unknown on fetched performer.
  return merge(createRecordingAggregate(), aggregate);
}

function createUpdateRecordingLineupCommand(aggregate) {
  const updateCommand = createUpdateLineupCommand();
  addCommandIds(updateCommand, aggregate);
  updateCommand.payload.lineup = merge(
    updateCommand.payload.lineup,
    aggregate.lineup.filter((entry) => !isEmptyPerformerReference(entry)),
  );
  updateCommand.payload.new_performers = merge(
    updateCommand.payload.new_performers,
    aggregate.new_performers,
  );
  updateCommand.payload.new_performers.forEach((performerCommand) => {
    performerCommand.process_id = updateCommand.process_id;
    performerCommand.timestamp = updateCommand.timestamp;
    performerCommand.version = updateCommand.version;
  });
  prepareRecordingDataForWire(updateCommand.payload);
  return updateCommand;
}

function createUpdateRecordingDistStateCommand(aggregate) {
  const command = createUpdateDistributionStateCommand();
  addCommandIds(command, aggregate);
  command.payload.distribution_state = aggregate.distribution_state;
  return command;
}

function createRecordingAggregateCommand(aggregate) {
  const createCommand = createRecordingCommand();
  addCommandIds(createCommand, aggregate);
  createCommand.payload.basic_info = merge(createCommand.payload.basic_info, aggregate.basic_info);
  createCommand.payload.lineup = merge(
    createCommand.payload.lineup,
    aggregate.lineup.filter((entry) => !isEmptyPerformerReference(entry)),
  );
  createCommand.payload.albums = merge(createCommand.payload.albums, aggregate.albums);
  createCommand.payload.new_performers = merge(
    createCommand.payload.new_performers,
    aggregate.new_performers,
  );
  createCommand.payload.new_performers.forEach((performerCommand) => {
    performerCommand.process_id = createCommand.process_id;
    performerCommand.timestamp = createCommand.timestamp;
    performerCommand.version = createCommand.version;
  });
  createCommand.payload.new_albums = aggregate.new_albums;

  prepareRecordingDataForWire(createCommand);
  return createCommand;
}

async function getRecording(id, version = null) {
  const res = await query(
    { query: gql.getFullRecording, variables: { idTerm: id, versionTerm: version } },
    { pickProp: 'recordingAggregate' },
  );
  return processAggregate(res);
}

export default {
  getRecording,
  getRecordingLineup: (idTerm) =>
    query(
      {
        query: gql.getLineup,
        variables: { idTerm },
      },
      { pickProp: 'recordingAggregate' },
    ),
  canDeleteRecording: (idTerm) =>
    query(
      {
        query: gql.canDeleteRecording,
        variables: { idTerm },
      },
      { pickProp: 'canDeleteRecording' },
    ),
  deleteRecording: (idTerm) =>
    mutate(
      {
        mutation: gql.deleteRecording,
        variables: { idTerm, user: AuthenticationService.getUserName() },
      },
      { pickProp: 'handleCommands' },
    ),
  updateLineupLocked: (idTerm, lineupLocked) =>
    mutate(
      {
        mutation: gql.updateLineupLocked,
        variables: { idTerm, user: AuthenticationService.getUserName(), lineupLocked },
      },
      { pickProp: 'handleCommands' },
    ),
  getDiscographyRecordingByLineupPerformer: (idTerm, filterQuery, mainArtist, pagination) =>
    query(
      {
        query: gql.getRecordingsByPerformer,
        variables: { idTerm, filterQuery, mainArtist, pagination },
      },
      { pickProp: 'recordingsByLineupPerformer' },
    ),
  getDiscographyRecordingByMainArtist: (idTerm, filterQuery, pagination) =>
    query(
      { query: gql.getRecordingsByMainArtist, variables: { idTerm, filterQuery, pagination } },
      { pickProp: 'recordingsByMainArtist' },
    ),
  createRecording: (aggregate) =>
    post(`${RECORDINGS_URL}/creation`, createRecordingAggregateCommand(aggregate), {
      pickProp: 'id',
    }),
  updateRecordingBasicInfo: async (aggregate, checkExistingMainArtist) => {
    if (checkExistingMainArtist && aggregate.basic_info.main_artist.name) {
      const result = await MainArtistService.mainArtistMatchSearch(
        aggregate.basic_info.main_artist.name,
      );
      if (result.name === aggregate.basic_info.main_artist.name) {
        aggregate.basic_info.main_artist.id = result.id;
      }
    }
    return post(`${RECORDINGS_URL}/basic-info`, createUpdateRecordingBasicInfoCommand(aggregate), {
      pickProp: 'id',
    });
  },
  updateRecordingLineup: (aggregate) =>
    post(`${RECORDINGS_URL}/lineup`, createUpdateRecordingLineupCommand(aggregate), {
      pickProp: 'id',
    }),
  updateRecordingDistributionState: (aggregate) =>
    post(`${RECORDINGS_URL}/distribution-state`, createUpdateRecordingDistStateCommand(aggregate), {
      pickProp: 'id',
    }),
  vrdb2EnqueueRecording: (aggregate) => post(`${VRDB2_URL}/enqueue`, { ids: [aggregate.id] }),
};
