import Vue from 'vue';
import moment from 'moment';
import 'moment-duration-format';
import _ from 'lodash';
import { friendlyFormatIBAN } from 'ibantools';
import { conformToMask } from 'text-mask-core';
import store from './store/index';
import { isStr } from './common/typeUtils';
import * as OfType from './common/ofType';
import { AGGREGATE_TYPES } from './domain/common';
import { UNKNOWN_PROTECTED_DATE } from './domain/recordingDomain';
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import { dateParse } from '@vuejs-community/vue-filter-date-parse';
import { dateFormat } from '@vuejs-community/vue-filter-date-format';

const numberFormat = new Intl.NumberFormat();

export const filters = {};

export default {
  install(V) {
    V.$filters = filters;
  },
};

const addFilter = (filterName, filterFn) => {
  filters[filterName] = filterFn;
};

addFilter('dateParse', dateParse);
addFilter('dateFormat', dateFormat);
addFilter('date', (val) => dateFormat(dateParse(val)));

function getCountries() {
  return store.getters['appdata/countries'] || [];
}

function getInstruments() {
  return store.getters['appdata/instruments'] || [];
}

function getGenres() {
  return store.getters['appdata/genres'] || [];
}

function getRolesRecording() {
  return store.getters['appdata/rolesRecording'] || [];
}

function getMandateRights() {
  return store.getters['appdata/mandateRights'] || [];
}

function getSocieties() {
  return store.getters['appdata/societies'] || [];
}

export function formatInstrument(instrumentCode) {
  if (instrumentCode) {
    const o = getInstruments().find(
      (instrument) => instrument.code === instrumentCode.toLowerCase(),
    );
    return o ? o.name : instrumentCode;
  }
  return instrumentCode;
}

function formatInstrumentList(instrumentList) {
  return instrumentList
    .filter((i) => i !== null)
    .map((instrumentCode) => {
      if (instrumentCode) {
        const o = getInstruments().find(
          (instrument) => instrument.code === instrumentCode.toLowerCase(),
        );
        return o ? o.name : instrumentCode;
      }
      throw new Error('Got empty value in instrument array');
    })
    .join(', ');
}

export function formatInstrumentListAsFamily(instrumentList) {
  // Make a list of instruments or instrument families readable
  return _.uniq(
    instrumentList.map((familyCode) => {
      if (familyCode) {
        const prop = Number.isNaN(Number(familyCode)) ? 'code' : 'family';
        const o = getInstruments().find(
          (instrument) => instrument[prop] === familyCode.toLowerCase(),
        );
        return o ? o.family_name : familyCode;
      }
      throw new Error('Got empty value in instrument array');
    }),
  ).sort();
}

export function formatCountry(countryCode) {
  if (countryCode) {
    const o = getCountries().find((country) => country.iso === countryCode);
    return o ? o.name : countryCode;
  }
  return countryCode;
}

function formatStatementFileType(type) {
  switch (type) {
    case 'sdeg2.5':
      return 'SDEG 2.5';
    case 'rdr-r':
      return 'DDEX RDR-R';
    default:
      return 'N/A';
  }
}

function formatDdexPartyId(id) {
  if (typeof id !== 'string') {
    return '';
  }
  return id.replace(/^(PA)(DPIDA)(\d{10})([A-Z0-9])$/, '$1-$2-$3-$4');
}

function formatNumber(number) {
  if (isNaN(number)) {
    return 0;
  }
  if (number) {
    return numberFormat.format(number);
  }
  return number;
}

export function formatDecimals(number, decimals = 2) {
  if (isNaN(number) || !isFinite(number)) {
    return 0;
  }
  const numerator = number * 10 ** decimals;
  const denominator = 10 ** decimals;
  return parseFloat(Math.round(numerator) / denominator).toFixed(decimals);
}

function formatPhoneNumber(number) {
  // TBD
  return number;
}

export function formatIBAN(number) {
  return number ? friendlyFormatIBAN(number) : '';
}

export function flipSign(number) {
  if (number < 0) {
    return Math.abs(number);
  }
  return -Math.abs(number);
}

export function formatAccountNumber(number) {
  const accountFormatMasks = {
    7: [/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    8: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
    9: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
    10: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
  };

  try {
    const n = number.replace(/\s/g, '');
    if (accountFormatMasks[n.length]) {
      return conformToMask(n, accountFormatMasks[n.length]).conformedValue;
    }
    return n;
  } catch (e) {
    return number;
  }
}

export function formatClearingNumber(number) {
  const clearingNumberFormatMasks = {
    4: [/\d/, /\d/, /\d/, /\d/],
    5: [/\d/, /\d/, /\d/, /\d/, '-', /\d/],
  };

  try {
    const n = number.replace(/\s/g, '');
    if (clearingNumberFormatMasks[n.length]) {
      return conformToMask(n, clearingNumberFormatMasks[n.length]).conformedValue;
    }
    return n;
  } catch (e) {
    return number;
  }
}

function formatMatchingMatchState(state) {
  switch (state) {
    case 'I':
      return 'Inserting';
    case 'M':
      return 'Matching';
    case 'MF':
      return 'Matched';
    case 'T':
      return 'Transferring';
    case 'TF':
      return 'Transferred';
    case 'D':
      return 'Deleting';
    case 'E':
      return 'Error';
    case 'A':
      return 'Archived';
    case 'F':
      return 'Finished';
    default:
      return 'N/A';
  }
}

function formatDistributionClosureState(state) {
  switch (state) {
    case distributionClosureState.Created:
      return 'Created';
    case distributionClosureState.Ready:
      return 'Pending approval';
    case distributionClosureState.Approved:
      return 'Pending confirmation';
    case distributionClosureState.Confirmed:
      return 'Finished';
    case distributionClosureState.Error:
      return 'Error';
    default:
      return 'N/A';
  }
}

function formatDistributionType(type) {
  switch (type) {
    case 'D':
      return 'Distribution';
    case 'C':
      return 'Redistribution';
    case 'R':
      return 'Reserve release';
    default:
      return 'Unknown';
  }
}

function formatIpdDownloadState(state) {
  switch (state) {
    case 'N':
      return 'New';
    case 'R':
      return 'Requested';
    case 'QD':
      return 'Queued for download';
    case 'D':
      return 'Downloading';
    case 'QP':
      return 'Queued for process';
    case 'P':
      return 'Processing';
    case 'F':
      return 'Finished';
    case 'T':
      return 'Timeout';
    case 'E':
      return 'Error';
    default:
      return 'N/A';
  }
}

function iconByAggregateType(type) {
  switch (type) {
    case AGGREGATE_TYPES.PERFORMER:
      return 'fa-user';
    case AGGREGATE_TYPES.MAINARTIST:
      return 'fa-star';
    case AGGREGATE_TYPES.ASSOCIATE:
      return 'fa-puzzle-piece';
    case AGGREGATE_TYPES.ALBUM:
      return 'fa-compact-disc';
    case AGGREGATE_TYPES.RECORDING:
      return 'fa-music';
    case AGGREGATE_TYPES.SOCIETY:
      return 'fa-building';
    case AGGREGATE_TYPES.USER:
      return 'fa-user-circle';
    case AGGREGATE_TYPES.TAG:
      return 'fa-bookmark';
    case AGGREGATE_TYPES.DEFAULT_TAG:
      return 'fa-heart';
    default:
      return 'fa-question-circle';
  }
}

function iconByMandateTypeRegionalOrWorldWide(type) {
  switch (type) {
    case 'R':
      return 'fa-map-marker text--green';
    case 'R+':
      return 'fa-map-marker text--green';
    case 'WW':
      return 'fa-globe text--blue';
    case 'WW-':
      return 'fa-globe text--blue';
    default:
      return '';
  }
}

function iconByMandateTypePlusOrMinus(type) {
  switch (type) {
    case 'R+':
      return 'fa-plus text--green';
    case 'WW-':
      return 'fa-minus text--blue';
    default:
      return '';
  }
}

export function toTitleCase(str) {
  return str
    ? str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    : str;
}

function toHref(url) {
  if (url && !/^https?:\/\//i.test(url)) {
    return `http://${url}`;
  }
  return url;
}

function formatSex(str) {
  switch (str) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    case 'X':
      return 'Diverse';
    default:
      return 'Other';
  }
}

function convertToMinutes(time) {
  const duration = moment.duration(time, 'seconds');
  let hours = Math.floor(duration.asHours());
  hours = hours < 10 ? `0${hours}` : hours;
  const minutes = duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes();
  const seconds = duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds();
  return `${hours}:${minutes}:${seconds}`;
}

function formatDateTime(dateTimeStr) {
  return dateTimeStr ? moment.utc(dateTimeStr).local().format('YYYY-MM-DD HH:mm') : '';
}

function formatDate(dateTimeStr) {
  if (!dateTimeStr) return '';

  const momentDate = moment(String(dateTimeStr));
  if (momentDate.hour() === 0) {
    momentDate.add(12, 'hours');
  }
  return momentDate.utc().format('YYYY-MM-DD');
}

function year(dateTimeStr) {
  return dateTimeStr ? moment.utc(dateTimeStr).format('YYYY') : '';
}

function formatRecordingDate(recordingDate) {
  if (recordingDate) {
    return recordingDate === UNKNOWN_PROTECTED_DATE
      ? 'Unknown and protected'
      : formatDate(recordingDate);
  }
  return '';
}

function ofTypeFormat(value) {
  const t = OfType.ofType(value);
  switch (t) {
    case OfType.Type.ARRAY:
      return value.length > 0 ? value.join(', ') : null;
    case OfType.Type.OBJECT:
      if (value.name) return value.name;
      if (value.type && value.number) return `${value.type}: ${value.number}`;
      if (value.society) return `${value.society}`;
      if (value.source) return `${value.source}`;
      if (value.relation) return `${value.relation.name}`;
      return '';
    case OfType.Type.ZULU:
      return formatDate(value);
    case OfType.Type.COUNTRY_CODE:
      return formatCountry(value);
    case OfType.Type.INSTRUMENT_CODE:
      return formatInstrument(value);
    default:
      return value;
  }
}

function highlightToHtml(text, highlightWord) {
  const spans = [];
  if (text && highlightWord) {
    let textToHighlight = Array.isArray(text) ? text.join(', ') : text;
    highlightWord
      .trim()
      .replace(/^"(.*)"$/, '$1')
      .split(' ')
      .filter(isStr)
      .map((str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      .forEach((word, wordIdx) => {
        const regex = new RegExp(word, 'gi');
        let matches;
        while ((matches = regex.exec(textToHighlight)) !== null) {
          spans.push({
            id: wordIdx,
            index: matches.index,
            type: 'start',
            token: '<span class="text--bold">',
          });
          spans.push({
            id: wordIdx,
            index: matches.index + word.length,
            type: 'end',
            token: '</span>',
          });
        }
      });

    spans.sort((a, b) => a.index - b.index);

    // remove overlapping spans
    for (let i = 0; i < spans.length - 1; i++) {
      const a = spans[i];
      const b = spans[i + 1];

      if (a.id !== b.id && a.type === 'start') {
        spans.splice(i + 1, 1);
        i--;
      }
    }

    // start from the last element to not destroy span indexes
    for (const span of spans.reverse()) {
      textToHighlight =
        textToHighlight.substring(0, span.index) +
        span.token +
        textToHighlight.substring(span.index);
    }

    return textToHighlight;
  }
  return text;
}

export function localCodeName(code) {
  if (/[åÅäÄöÖ]/g.test(code)) {
    return Vue.$i18n.t(`general_info.local_codes.${code.replace(/[åÅäÄöÖ]/g, 'O').toLowerCase()}`);
  }
  return Vue.$i18n.t(`general_info.local_codes.${code.toLowerCase()}`);
}

export function associateTypeName(type) {
  return Vue.$i18n.t(`common.${type.toLowerCase()}`);
}

function prettyPrintAlbumArray(arr) {
  if (!arr || arr.length === 0) {
    return '<span class="none fs-13">None</span>';
  }
  return arr.map((a) => `<a href="#/albums/${a.id}">${a.name}</a>`).join(', ');
}

function prettyPrintAlbumObject(obj) {
  if (!obj) {
    return '<span class="none fs-13">None</span>';
  }
  return `<a href="#/albums/${obj.id}">${obj.name}</a>`;
}

function prettyPrintArray(arr) {
  if (!Array.isArray(arr)) {
    return arr;
  }
  return arr.join(', ');
}

/** STRING **/
addFilter('toTitleCase', (input) => toTitleCase(input));
addFilter('toHref', (input) => toHref(input));
addFilter('uppercase', (str) => (str ? str.toUpperCase() : str));
addFilter('lowercase', (str) => (str ? str.toLowerCase() : str));
addFilter('killSnake', (input) => input.toUpperCase().replace(/_/g, ' '));
addFilter('join', (strList) => strList.join(', '));

/** TRANSLATE **/
addFilter('translate', (str) => (str ? Vue.$i18n.t(str.toLowerCase()) : ''));

/** NUMBER **/
addFilter('nextIntegerRoundUp', (input) => Math.round(input));
addFilter('formatNumber', (number) => formatNumber(number));
addFilter('removeDecimal', (number) =>
  Number.isFinite(number) ? Math.floor(number) : number.split('.')[0],
);
addFilter('formatDecimals', (number, decimals) => formatDecimals(number, decimals));
addFilter('formatPhoneNumber', (number) => formatPhoneNumber(number));
addFilter('formatAccountNumber', (number) => formatAccountNumber(number));
addFilter('formatClearingNumber', (number) => formatClearingNumber(number));
addFilter('formatIBAN', (number) => formatIBAN(number));
addFilter('flipSign', (number) => flipSign(number));

addFilter('formatAmountThousandSeparator', (amount, currency) => {
  const rounded = (Math.round(amount * 100) / 100).toFixed(2);
  const thousandSeparated = rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const formattedAmount = thousandSeparated.replace('.', ',');
  return `${formattedAmount} ${currency ? currency.toUpperCase() : ''}`;
});

addFilter('formatAmountCent', (cent, currency) => {
  const amount = (cent / 100).toFixed(2);
  const thousandSeparated = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const formattedAmount = thousandSeparated.replace('.', ',');
  return `${formattedAmount} ${currency ? currency.toUpperCase() : ''}`;
});

/** DATE AND TIME **/
addFilter('formatUnixDate', (epoch) => (epoch ? moment.unix(epoch).format('YYYY-MM-DD') : null));
addFilter('formatTimestamp', (timestamp) => formatDateTime(new Date(+timestamp)));
addFilter('formatDate', formatDate);
addFilter('formatDateTime', formatDateTime);
addFilter('year', year);
addFilter('formatRecordingDate', formatRecordingDate);
// HH:mm:ss
addFilter('formatTime', (time) => moment.utc(time).format('HH:mm:ss'));
addFilter('formatMonthFromNumber', (monthNum) => moment.months(monthNum));
addFilter('formatMonthFromNumber1', (monthNum) => moment.months(monthNum - 1));
addFilter('toMinutes', (time) => convertToMinutes(time));
addFilter('airTime', (airTime) => moment.duration(airTime).format('HH:mm:ss', { trim: false }));

/** ICON **/
addFilter('iconByAggregateType', iconByAggregateType);
addFilter('iconByMandateTypeRegionalOrWorldWide', iconByMandateTypeRegionalOrWorldWide);
addFilter('iconByMandateTypePlusOrMinus', iconByMandateTypePlusOrMinus);

/** TYPES **/
addFilter('formatDdexPartyId', formatDdexPartyId);
addFilter('formatStatementFileType', formatStatementFileType);
addFilter('formatCountry', formatCountry);
addFilter('formatSex', (sex) => formatSex(sex));
addFilter('formatMatchState', formatMatchingMatchState);
addFilter('formatDistributionClosureState', formatDistributionClosureState);
addFilter('formatDistributionType', formatDistributionType);
addFilter('formatIpdDownloadState', formatIpdDownloadState);
addFilter('formatCountryList', (countryList) =>
  countryList.map(
    (countryCode) =>
      getCountries()
        .filter((country) => country.iso === countryCode)
        .pop().name,
  ),
);
addFilter('formatInstrument', (instrumentCode) => formatInstrument(instrumentCode));
addFilter('formatInstrumentList', (instrumentList) => formatInstrumentList(instrumentList));
addFilter('formatInstrumentListAsFamily', (instrumentList) =>
  formatInstrumentListAsFamily(instrumentList).join(', '),
);
addFilter('formatGenre', (genreCode) => getGenres().find((genre) => genre.code === genreCode).name);

export function addRoleFaFormat(r) {
  let fa;
  if (r.featured_artist === true) {
    fa = 'FA';
  } else if (r.featured_artist === false) {
    fa = 'NF';
  }
  r.name_fa = fa ? `${r.name} (${fa})` : r.name;
  return r;
}

function formatRole(roleCode) {
  const match = getRolesRecording().find((role) => role.code === roleCode);
  if (!match) {
    // Legacy roles
    switch (roleCode) {
      case 'M2':
        return 'Musician';
      default:
        return roleCode;
    }
  }
  return addRoleFaFormat(match).name_fa;
}

function formatOutgoingRoleCode(roleCode) {
  if (!roleCode) {
    return '';
  }

  const match = getRolesRecording().find((role) => role.code === roleCode.id);
  if (match) {
    return `${match.name} ${roleCode.type || ''}`;
  }
  return `${roleCode.id} ${roleCode.type || ''}`;
}

addFilter('formatRole', formatRole);
addFilter('formatRoleList', (roles) => prettyPrintArray(roles.map(formatRole)));
addFilter('formatOutgoingRoleCodes', (roles) =>
  prettyPrintArray(roles.map(formatOutgoingRoleCode)),
);

function formatSociety(societyCode) {
  const society = getSocieties().find((s) => s.ipd_code === societyCode.toUpperCase());

  if (society) {
    return society.name;
  }

  return societyCode;
}

function formatSocietyCode(societyCode) {
  const society = getSocieties().find((s) => s.code === societyCode);

  if (society) {
    return society.name;
  }

  return societyCode;
}

function formatSocietyV2(societyId, societies) {
  return (
    societies.filter((society) => society.id === societyId).map((society) => society.name)[0] ||
    'Unknown'
  );
}

function translateRoutingCode(routingCode) {
  return (
    getCountries().find((country) => country.routing_code === routingCode) || { name: routingCode }
  ).name;
}

addFilter('translateRoutingCode', translateRoutingCode);
addFilter('formatSociety', formatSociety);
addFilter('formatSocietyCode', formatSocietyCode);
addFilter('formatSocietyV2', formatSocietyV2);
addFilter('formatSocieties', (societies) => prettyPrintArray(societies.map(formatSociety)));
addFilter('formatRight', (rightCode) => {
  try {
    return getMandateRights().find((right) => right.code === rightCode).name;
  } catch (e) {
    console.log('Could not retrieve display name for IPD right:', rightCode);
    return rightCode;
  }
});
addFilter('yesno', (bool) => (bool ? 'Yes' : 'No'));

/** PRETTY **/
addFilter('prettyPrintNames', (namesString) =>
  namesString
    .split(';')
    .map((name) => name.replace(',', ' '))
    .join(', '),
);
addFilter('formatPseudoNames', (pseudoNames) =>
  prettyPrintArray(
    pseudoNames
      .slice(0, 5)
      .concat(pseudoNames.length > 5 ? ` and ${pseudoNames.length - 5} more` : ''),
  ),
);
addFilter('prettyPrintArray', prettyPrintArray);
addFilter('prettyPrintAlbumArray', prettyPrintAlbumArray);
addFilter('prettyPrintAlbumObject', prettyPrintAlbumObject);
addFilter('prettyPrintCodeValueObject', (obj) => {
  const pretty = [];
  _.forOwn(obj, (value) => pretty.push(`${value.code}:${value.value}`));
  return pretty.join(', ');
});

/** ACTIVITY **/
addFilter('ofTypeFormat', (str) => (str ? ofTypeFormat(str) : ''));

/** SEARCH **/
addFilter('highlightToHtml', (text, highlightWord) => highlightToHtml(text, highlightWord));

/** LOCAL CODE **/
addFilter('localCodeName', (code) => localCodeName(code));

/** ASSOCIATE TYPE **/
addFilter('associateTypeName', (type) => associateTypeName(type));
