/**
 * Helper service for edit main artist tasks; such as update basic info and performers.
 */
import MainArtistService from '../../services/mainArtistService';
import clone from '../../common/clone';
import { createPerformerRelation } from '../../domain/mainArtistDomain';

function update(updateFunction, mainArtistAggregate) {
  return updateFunction(mainArtistAggregate)
    .then((id) => MainArtistService.getMainArtist(id))
    .then((updatedAggregate) => clone(updatedAggregate));
}

function updateBasicInfo(mainArtistAggregate) {
  return update(MainArtistService.updateMainArtistBasicInfo, mainArtistAggregate);
}

function updatePerformers(mainArtistAggregate) {
  mainArtistAggregate.performers = mainArtistAggregate.performers.map((performer) =>
    createPerformerRelation(
      performer.id,
      performer.instrument,
      performer.role,
      performer.active_period,
      performer.pseudo_name,
    ),
  );
  return update(MainArtistService.updateMainArtistPerformers, mainArtistAggregate);
}

export default {
  updateBasicInfo: (mainArtistAggregate) => updateBasicInfo(clone(mainArtistAggregate)),
  updatePerformers: (mainArtistAggregate) => updatePerformers(clone(mainArtistAggregate)),
};
