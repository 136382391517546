import gql from 'graphql-tag';
import CommonGql from './commonGql';
import CommonPaymentGql from './commonPaymentGql';

const mandates = `
  right
  group_conflicts {
    society_code
    detected_date
    deadline_date
    resolved
    winning_society_code
    conflictingMandate {
      society_code
      right
    }
  }
  mandates {
    id
    right
    society_code
    mandate_type
    member_no
    start_date
    end_date
    territories
    performance_activity
    conditional_aspect
    conflicts {
      mandate_ids
      reason
      detected_date
    }
  }
`;

const getSimplePerformer = gql`
  query performer($idTerm: String!) {
    performerAggregate(id: $idTerm) {
      id
      general_info {
        first_name
        last_name
      }
    }
  }
`;

const getMandates = gql`
  query mandates($idTerm: String!, $versionTerm: Int, $allMandates: Boolean) {
    performerAggregate(id: $idTerm, version: $versionTerm) {
      mandates(all: $allMandates) {
        ${mandates}
      }
    }
  }
`;

const getFullPerformer = gql`
  query performer($idTerm: String!, $versionTerm: Int, $allMandates: Boolean) {
    performerAggregate(id: $idTerm, version: $versionTerm) {
      id
      version
      deleted
      deletedBy
      general_info {
        social_security_number
        first_name
        last_name
        pseudo_names
        name_variations
        date_of_birth
        date_of_death
        protected_identity
        sex
        nationality
        country_of_residence
        profile
        local_codes {
          code
          value
        }
        collective
        collective_size
      }
      ${CommonGql.contactInfo}
      membership_info {
        main_instrument
        secondary_instruments
        voting_right
        ipn
      }
      mandates(all: $allMandates) {
        ${mandates}
      }
      tax_info {
        tax_domicile
        tin
        vat_number
      }
      ${CommonPaymentGql.paymentInfo}
      ${CommonGql.associates}
      metadata_info {
       other_external_sources {
          source url
        }
        tags
      }
      main_artists {
        id
        name
      }
      documents {
        id
        type
        url
        created
      }
      system_state {
        payments_blocked
        payments_blocked_reason
      }
      ipd_state {
        state
        sync_type
      }
    }
  }
`;

const getPerformerList = gql`
  query performers($idList: [String!]) {
    performerAggregateList(idList: $idList) {
      id
      version
      general_info {
        first_name
        last_name
        pseudo_names
        name_variations
        date_of_birth
        date_of_death
        nationality
        country_of_residence
      }
      membership_info {
        main_instrument
        secondary_instruments
        ipn
      }
      mandates {
        right
        mandates {
          id
          society_code
          mandate_type
          start_date
          end_date
          territories
          performance_activity
          conditional_aspect
          conflicts {
            mandate_ids
            reason
            detected_date
          }
        }
      }
    }
  }
`;

const uniqueSSN = gql`
  query uniqueSSN($ssn: String!) {
    uniqueSSN(ssn: $ssn) {
      unique
      performer_id
      performer_name
    }
  }
`;

const idByIPN = gql`
  query idByIPN($ipnList: [String]) {
    idByIPN(ipnList: $ipnList) {
      id
      ipn
    }
  }
`;

const ipdSync = gql`
  mutation ipdSync($idTerm: String!) {
    ipdSync(id: $idTerm)
  }
`;

const ipdUpload = gql`
  mutation ipdUpload($idTerm: String!) {
    ipdUpload(id: $idTerm) {
      state
      sync_type
    }
  }
`;

const canDeletePerformer = gql`
  query canDeletePerformer($idTerm: String!) {
    canDeletePerformer(id: $idTerm) {
      canDelete
      reasons
    }
  }
`;

const deletePerformer = gql`
  mutation deletePerformer($idTerm: String, $user: String) {
    handleCommands(
      target: pnr
      commands: [{ stream_id: $idTerm, type: "performer:delete", user: $user, payload: {} }]
    ) {
      streamId
      error
      command {
        stream_id
        process_id
        timestamp
        type
        version
        payload
        user
      }
    }
  }
`;

const ipdRightHolder = gql`
  query ipdRightHolder($ipn: String!) {
    ipdRightHolder(ipn: $ipn) {
      general_info {
        first_name
        last_name
        date_of_birth
        sex
        nationality
        country_of_residence
        collective
        collective_size
        pseudo_names
      }
      membership_info {
        main_instrument
        secondary_instruments
        ipn
      }
      mandates {
        ${mandates}
      }
    }
  }
`;

export default {
  getFullPerformer,
  getSimplePerformer,
  getPerformerList,
  getMandates,
  ipdSync,
  uniqueSSN,
  idByIPN,
  ipdUpload,
  canDeletePerformer,
  deletePerformer,
  ipdRightHolder,
};
