<template>
  <div>
    <section class="widget-table">
      <header>
        <div class="widget-table--col">Member Type</div>
        <div class="widget-table--col amount">Members</div>
      </header>
      <div v-for="channel in payload.items" :key="channel.id" class="widget-table--row">
        <div class="widget-table--col">{{ channel.code }}</div>
        <div>{{ $filters.formatDate(channel.date_changed) }}</div>
      </div>
      <div class="widget-table--row">
        <span class="widget-table--col">Total</span>
        <span class="widget-table--col amount">
          {{ $filters.formatNumber(payload.total) }}
        </span>
      </div>
      <div class="widget-table--row">
        <span class="widget-table--col">
          Worldwide
          <i class="fas fa-globe text--blue" />
        </span>
        <span class="widget-table--col amount">
          {{ $filters.formatNumber(payload.WW) }}
        </span>
      </div>
      <div class="widget-table--row">
        <span class="widget-table--col">
          Worldwide -
          <i class="fas fa-globe text--blue" />
          <i class="fas fa-minus text--blue" />
        </span>
        <span class="widget-table--col amount">
          {{ $filters.formatNumber(payload['WW-']) }}
        </span>
      </div>
      <div class="widget-table--row">
        <span class="widget-table--col">
          Regional
          <i class="fas fa-map-marker text--green" />
        </span>
        <span class="widget-table--col amount">{{ $filters.formatNumber(payload.R) }}</span>
      </div>
      <div class="widget-table--row">
        <span class="widget-table--col">
          Regional +
          <i class="fas fa-map-marker text--green" />
          <i class="fas fa-plus text--green" />
        </span>
        <span class="widget-table--col amount">
          {{ $filters.formatNumber(payload['R+']) }}
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'MembersWidget',
  mixins: [WidgetMixin],
};
</script>

<style lang="scss" scoped>
.fas {
  font-size: 13px;
}
</style>
