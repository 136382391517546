import gql from 'graphql-tag';

const createMandateFile = gql`
  mutation createMandateFile($societyCode: String!, $processId: String!) {
    createMandateFile(societyCode: $societyCode, processId: $processId)
  }
`;

export default {
  createMandateFile,
};
