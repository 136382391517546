<template>
  <div>
    <multi-line-form
      v-if="alwaysOpen || viewForm"
      scope="add-performer-form"
      :display-action-buttons="displayActionButtons"
      @save="save"
      @cancel="cancel"
    >
      <template slot="title">
        <h2 class="float-left">Add Existing Performers</h2>
      </template>
      >
      <template slot-scope="{ index }">
        <add-performer-row
          :required="required"
          :scope="getScopeByIndex(index)"
          @update="updateMember(index, $event)"
        />
      </template>
    </multi-line-form>
    <div v-else>
      <div class="row">
        <h2>Add Existing Performers</h2>
      </div>
      <div class="edit-link">
        <a @click="displayForm" @keyup.enter="displayForm">
          <i class="far fa-plus-square" />
          Add performer
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AddPerformerRow from './add-performer-row';
import MultiLineForm from '../../ui/form/multi-line-form';

export default {
  name: 'AddExistingPerformers',
  components: {
    AddPerformerRow,
    MultiLineForm,
  },
  props: {
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    displayActionButtons: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      members: [],
      viewForm: false,
    };
  },
  methods: {
    updateMember(index, data) {
      this.members[index] = data;
      this.$emit('input', this.filterValidRows(this.members));
    },
    save() {
      this.$emit('save', this.members);
    },
    cancel() {
      this.viewForm = false;
      this.members = [];
      this.$emit('cancel');
    },
    displayForm() {
      this.viewForm = true;
    },
    filterValidRows(rows) {
      return rows.filter((row) => row.id);
    },
    getScopeByIndex(idx) {
      return `add-performer-${idx}`;
    },
  },
};
</script>
