<template>
  <div class="create__menu fixed-action-btn">
    <div v-if="validationErrors.items.length > 0" class="error">
      <span class="help is-danger">
        <i class="fas fa-times-circle" />
        <span v-if="validationErrors.items.length === 1">The form contains 1 error</span>
        <span v-else>The form contains {{ uniqueErrors() }} errors</span>
      </span>
    </div>

    <action-buttons :submit-label="`Create ${entityType}`" @save="create" @cancel="cancel" />
  </div>
</template>

<script>
import ActionButtons from './button/action-buttons';

export default {
  name: 'CreateMenu',
  components: { ActionButtons },
  inject: ['$validator'],
  props: {
    entityType: { type: String, default: '' },
    cancelRouteName: { type: String, default: 'homeView' },
  },
  methods: {
    uniqueErrors() {
      return [...new Set(this.validationErrors.items.map((i) => i.field))].length;
    },
    create() {
      this.$emit('create');
    },
    cancel() {
      if (this.cancelRouteName === 'BACK') {
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: this.cancelRouteName,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.create__menu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 48px;
  line-height: 48px;
  bottom: 0;
  margin-right: -23px;
  text-align: right;
  padding: 0 10px;
  background-color: #f8f8f8;
  z-index: 500;
  border-top: 1px solid #eee;

  .error {
    padding: 1px 2px;
  }
}
</style>
