import clone from '../../../common/clone';
import { generateMutations } from '../../utils';
import * as recordingDomain from '../../../domain/recordingDomain';

const stateTemplate = {
  pagination: null,
  sortTerms: null,
  recordedInCountryCode: '',
  range: null,
  expandedId: '',
};

// The root, initial state object
const rootState = {
  statementMatchingTable: clone(stateTemplate),
  viewMusicReportCandidateLines: clone(stateTemplate),
  viewMusicReportMatchedLines: clone(stateTemplate),
  viewMusicReportRestedLines: clone(stateTemplate),
  viewMusicReportIgnoredLines: clone(stateTemplate),
  viewMusicReportErrorLines: clone(stateTemplate),
  viewMusicReportTransferredLines: clone(stateTemplate),
  viewMusicReportClaimedLines: clone(stateTemplate),
  viewMediarcReportMatchedLines: clone(stateTemplate),
  viewMediarcReportCandidateLines: clone(stateTemplate),
  viewMediarcReportRestedLines: clone(stateTemplate),
  viewMediarcReportDoneLines: clone(stateTemplate),
  viewPlaylistToCheck: clone(stateTemplate),
  viewPlaylistAccepted: clone(stateTemplate),
  viewPlaylistRejected: clone(stateTemplate),
  viewPlaylistApplied: clone(stateTemplate),
  viewPlaylistSentRejections: clone(stateTemplate),
  viewPlaylistUnknown: clone(stateTemplate),
  countryList: [],
  currentCandidate: recordingDomain.createRecordingAggregate(),
  currentIncoming: {
    lineup: [],
    rejected_reason: null,
    rejected_note: null,
  },
  reportLines: [],
  candidates: null,
  matchedExpandedRecordingId: null,
  candidateExpandedRecordingId: null,
  restedExpandedRecordingId: null,
  ignoredExpandedRecordingId: null,
};

const actions = {
  clear(context) {
    context.commit('clear');
  },
  updateCurrentCandidate({ commit }, value) {
    commit('updateCurrentCandidate', value);
  },
  updateNotesOnCurrentCandidate({ commit }, value) {
    commit('updateNotesOnCurrentCandidate', value);
  },
  updateCurrentIncoming({ commit }, value) {
    commit('updateCurrentIncoming', value);
  },
  updateCountryList({ commit }, value) {
    commit('updateCountryList', value);
  },
  updateViewMusicReportMatchedLinesExpandedId({ commit }, value) {
    commit('updateViewMusicReportMatchedLinesExpandedId', value);
  },
  updateViewMusicReportCandidateLinesExpandedId({ commit }, value) {
    commit('updateViewMusicReportCandidateLinesExpandedId', value);
  },
  updateViewMusicReportRestedLinesExpandedId({ commit }, value) {
    commit('updateViewMusicReportRestedLinesExpandedId', value);
  },
  updateViewMusicReportIgnoredLinesExpandedId({ commit }, value) {
    commit('updateViewMusicReportIgnoredLinesExpandedId', value);
  },
  updateViewMusicReportErrorLinesExpandedId({ commit }, value) {
    commit('updateViewMusicReportErrorLinesExpandedId', value);
  },
  updateReportLines({ commit }, values) {
    commit('updateReportLines', values);
  },
  updateCandidates({ commit }, values) {
    commit('updateCandidates', values);
  },
  replaceInReportLines({ commit }, input) {
    commit('replaceInReportLines', input);
  },
};

const mutations = {
  ...generateMutations(rootState),
  clear(state) {
    state.statementMatchingTable = clone(stateTemplate);
    state.viewMusicReportCandidateLines = clone(stateTemplate);
    state.viewMusicReportMatchedLines = clone(stateTemplate);
    state.viewMusicReportRestedLines = clone(stateTemplate);
    state.viewMusicReportIgnoredLines = clone(stateTemplate);
    state.viewMusicReportErrorLines = clone(stateTemplate);
    state.viewMusicReportTransferredLines = clone(stateTemplate);
    state.viewMusicReportClaimedLines = clone(stateTemplate);
    state.viewMediarcReportMatchedLines = clone(stateTemplate);
    state.viewMediarcReportCandidateLines = clone(stateTemplate);
    state.viewMediarcReportRestedLines = clone(stateTemplate);
    state.viewMediarcReportDoneLines = clone(stateTemplate);
    state.viewPlaylistToCheck = clone(stateTemplate);
    state.viewPlaylistAccepted = clone(stateTemplate);
    state.viewPlaylistRejected = clone(stateTemplate);
    state.viewPlaylistApplied = clone(stateTemplate);
    state.viewPlaylistSentRejections = clone(stateTemplate);
    state.viewPlaylistUnknown = clone(stateTemplate);
    state.candidateExpandedRecordingId = '';
    state.matchedExpandedRecordingId = '';
    state.restedExpandedRecordingId = '';
    state.ignoredExpandedRecordingId = '';
    state.countryList = [];
  },
  updateCurrentCandidate(state, recordingAggregate) {
    state.currentCandidate = recordingAggregate;
  },
  updateNotesOnCurrentCandidate(state, notes) {
    state.currentCandidate.notes = notes;
  },
  updateCurrentIncoming(state, reportItem) {
    state.currentIncoming = reportItem;
  },
  updateLineUpdatedAttributes(state, { id, updatedAttributes, key, value }) {
    state.currentIncoming.lineup = state.currentIncoming.lineup.map((line) => {
      if (line.id !== id) {
        return line;
      }

      if (key === 'role_code' && value) {
        return {
          ...line,
          incoming_artist: {
            ...line.incoming_artist,
            role_code: value,
          },
          updated_attributes: updatedAttributes,
        };
      }

      return {
        ...line,
        updated_attributes: updatedAttributes,
      };
    });
  },
};

const getters = {
  countryList: (state) => state.countryList,
  reportLines: (state) => state.reportLines,
  candidates: (state) => state.candidates,
  candidateExpandedRecordingId: (state) => state.candidateExpandedRecordingId,
  matchedExpandedRecordingId: (state) => state.matchedExpandedRecordingId,
  restedExpandedRecordingId: (state) => state.restedExpandedRecordingId,
  ignoredExpandedRecordingId: (state) => state.ignoredExpandedRecordingId,
  statementMatchingTable: (state) => state.statementMatchingTable,
  viewMusicReportCandidateLines: (state) => state.viewMusicReportCandidateLines,
  viewMusicReportMatchedLines: (state) => state.viewMusicReportMatchedLines,
  viewMusicReportRestedLines: (state) => state.viewMusicReportRestedLines,
  viewMusicReportIgnoredLines: (state) => state.viewMusicReportIgnoredLines,
  viewMusicReportTransferredLines: (state) => state.viewMusicReportTransferredLines,
  viewMusicReportClaimedLines: (state) => state.viewMusicReportTransferredLines,
  viewMusicReportErrorLines: (state) => state.viewMusicReportErrorLines,
  viewMediarcReportMatchedLines: (state) => state.viewMediarcReportMatchedLines,
  viewMediarcReportCandidateLines: (state) => state.viewMediarcReportCandidateLines,
  viewMediarcReportRestedLines: (state) => state.viewMediarcReportRestedLines,
  viewMediarcReportDoneLines: (state) => state.viewMediarcReportDoneLines,
  viewPlaylistToCheck: (state) => state.viewPlaylistToCheck,
  viewPlaylistAccepted: (state) => state.viewPlaylistAccepted,
  viewPlaylistRejected: (state) => state.viewPlaylistRejected,
  viewPlaylistApplied: (state) => state.viewPlaylistApplied,
  viewPlaylistSentRejections: (state) => state.viewPlaylistSentRejections,
  viewPlaylistUnknown: (state) => state.viewPlaylistUnknown,
  currentCandidate: (state) => state.currentCandidate,
  currentIncoming: (state) => state.currentIncoming,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
