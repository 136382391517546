import gql from 'graphql-tag';

const getMatchData = (reportType, countries) => {
  switch (reportType) {
    case 'PRR':
      return `
        ${countries ? 'countries' : ''}
        prr_match_data {
          line_count
          matched_lines
          rested_lines
          candidate_lines
          transferred_lines
          ignored_lines
          error_lines
        }
      `;
    case 'MAR':
      return `
        mar_match_data {
          album_count
          matched_album_lines
          candidate_album_lines
          rested_album_lines
          done_album_lines
          recording_count
          matched_recording_lines
          candidate_recording_lines
          rested_recording_lines
          done_recording_lines
        }
      `;
    case 'PLR':
      return `
        plr_match_data {
          line_count
          to_check
          accepted
          rejected
          applied
          rejected_sent
          not_matched
        }
      `;
    case 'SRR':
      return `
        srr_match_data {
          line_count
          matched_lines
          issue_lines
          rested_lines
          candidate_lines
          transferred_lines
        }
      `;
    default:
      return '';
  }
};

const reportHeader = `
    report_id
    report_type
    date_created
    date_modified
    report_date
    report_name
    report_state
    reporter_id
    local_code_id
    reporter_line_count
    error_message
    info
    process_type
    priority
    file {
      filename
      user
    }
`;

const getReportHeader = (reportType) => gql`
  query getReportHeader($reportId: String!, $worklistId: String) {
    getReportHeader(reportId: $reportId, worklistId: $worklistId) {
      ${reportHeader}
      ${getMatchData(reportType, true)}
    }
  }
`;

const listReportHeaders = (reportType) => gql`
    query listReportHeaders($request: ReportHeadersRequest) {
      listReportHeaders(request: $request) {
        total_count
        items {
          ${reportHeader}
          ${getMatchData(reportType)}
        }
      }
    }
  `;

const listVrdbReportHeaders = gql`
  query listVrdbReportHeaders($societyCode: String) {
    listVrdbReportHeaders(societyCode: $societyCode) {
      society_code
      line_count
      to_check
      accepted
      rejected
      applied
      rejected_sent
      not_matched
    }
  }
`;

const loadHits = gql`
  query loadHits($streamIds: [String]) {
    loadHits(streamIds: $streamIds) {
      id
      name
      version_title
      main_artist
      label
      recording_date
      status
      samiLineupMember
      vrdb2_id
    }
  }
`;

const loadAlbumHits = gql`
  query loadAlbumHits($streamIds: [String]) {
    loadAlbumHits(streamIds: $streamIds) {
      id
      name
      main_artist
      label
      catalog_number
      number_of_tracks
    }
  }
`;

const getLines = gql`
  query getLines($request: GetLinesRequest) {
    getLines(request: $request) {
      total_count
      items {
        report_id
        group_id
        title
        alternate_titles
        version_title
        main_artist
        airplay_time
        label
        line_count
        isrc
        local_ids
        hits {
          id
        }
        recording_year
        recording_country
        match_state
        match_id
        sami_member
        errors {
          error
          payload
        }
      }
    }
  }
`;

const getAlbumLines = gql`
  query getAlbumLines($request: GetLinesRequest) {
    getAlbumLines(request: $request) {
      total_count
      items {
        report_id
        group_id
        title
        main_artist
        total_count
        hits {
          id
        }
        match_state
        match_id
      }
    }
  }
`;

const playlistRecording = `
  recording {
    id
    external_id
    internal_id
    match_state
    society_code
    main_artist
    title
    isrc
    title_version
    recording_year
    recording_country
    producer_country
    label
    composer
    duration
    keywords
    genre
    weight
    rejected_reason
    rejected_note
    rejected_recordings
    rejected_lineups
    lineup_complete
    date_created
  }
  lineup {
    id
    recording_id
    incoming_artist {
      ipn
      local_performer_id
      first_name
      last_name
      role_code
      instruments
      date_of_birth
      artist_type
      internal_id
      source_society
      sex
      collective
    }
    artist {
      ipn
      local_performer_id
      first_name
      last_name
      role_code
      instruments
      date_of_birth
      artist_type
      internal_id
      sex
      collective
    }
    updated_attributes
    match_state
    rejected_reason
    rejected_note
  }
`;

const playlistRecordingLight = `
  recording {
    id
    external_id
    internal_id
    match_state
    society_code
    main_artist
    title
    isrc
    title_version
    recording_year
    recording_country
    producer_country
    label
    composer
    duration
    keywords
    genre
    weight
    rejected_reason
    rejected_note
    rejected_recordings
    rejected_lineups
    lineup_complete
    lineup_locked
    date_created
  }
`;

const fetchPlaylistRecordings = gql`
  query fetchPlaylistRecordings($request: FetchPlaylistRecordingsRequest) {
    fetchPlaylistRecordings(request: $request) {
      items {
        ${playlistRecordingLight}
      }
      total_count
    }
  }
`;

const getPlaylistRecording = gql`
  query getPlaylistRecording($request: GetPlaylistRecordingRequest) {
    getPlaylistRecording(request: $request) {
      ${playlistRecording}
    }
  }
`;

const getDataUrls = gql`
  query getDataUrls($request: GetDataUrlsRequest) {
    getDataUrls(request: $request) {
      headImported
      headInbox
      fetch
      upload
    }
  }
`;

const identifyMatchLine = gql`
  mutation identifyMatchLine($command: MatchGroupCommand) {
    identifyMatchLine(command: $command)
  }
`;

const ignoreMatchLine = gql`
  mutation ignoreMatchLine($command: MatchGroupCommand) {
    ignoreMatchLine(command: $command)
  }
`;

const unmatchMatchLine = gql`
  mutation unmatchMatchLine($command: MatchGroupCommand) {
    unmatchMatchLine(command: $command)
  }
`;

const revertMatch = gql`
  mutation revertMatch($command: MatchGroupsCommand) {
    revertMatch(command: $command)
  }
`;

const identifyMatchAlbumLine = gql`
  mutation identifyMatchAlbumLine($command: MatchGroupCommand) {
    identifyMatchAlbumLine(command: $command)
  }
`;

const ignoreMatchAlbumLine = gql`
  mutation ignoreMatchAlbumLine($command: MatchGroupCommand) {
    ignoreMatchAlbumLine(command: $command)
  }
`;

const revertAlbumMatch = gql`
  mutation revertAlbumMatch($command: MatchGroupsCommand) {
    revertAlbumMatch(command: $command)
  }
`;

const createRestedAlbums = gql`
  mutation createRestedAlbums($command: CreateRestedCommand) {
    createRestedAlbums(command: $command)
  }
`;

const exportAlbums = gql`
  mutation exportAlbums($command: ExportAlbumsCommand) {
    exportAlbums(command: $command)
  }
`;

const exportRecordings = gql`
  mutation exportRecordings($command: ExportRecordingsCommand) {
    exportRecordings(command: $command)
  }
`;

const createRestedRecordings = gql`
  mutation createRestedRecordings($command: CreateRestedCommand) {
    createRestedRecordings(command: $command)
  }
`;

const toggleLineupUpdatedAttributes = gql`
  mutation toggleLineupUpdatedAttributes($command: ToggleLineupUpdatedAttributesCommand!) {
    toggleLineupUpdatedAttributes(command: $command)
  }
`;

const acceptPlaylistLineup = gql`
  mutation acceptPlaylistLineup($command: AcceptPlaylistLineupCommand!) {
    acceptPlaylistLineup(command: $command) {
      recording_ids
    }
  }
`;

const rejectPlaylistLineup = gql`
  mutation rejectPlaylistLineup($command: RejectPlaylistLineupCommand!) {
    rejectPlaylistLineup(command: $command)
  }
`;

const rejectPlaylistLineupLine = gql`
  mutation rejectPlaylistLineupLine($command: RejectPlaylistLineupLineCommand!) {
    rejectPlaylistLineupLine(command: $command)
  }
`;

const undoRejectPlaylistLineupLine = gql`
  mutation undoRejectPlaylistLineupLine($command: UndoRejectPlaylistLineupLineCommand!) {
    undoRejectPlaylistLineupLine(command: $command) {
      match_state
    }
  }
`;

const undoMergePlaylistLineupLine = gql`
  mutation undoMergePlaylistLineupLine($command: UndoMergePlaylistLineupLineCommand!) {
    undoMergePlaylistLineupLine(command: $command) {
      id
    }
  }
`;

const mergePlaylistLineupLine = gql`
  mutation mergePlaylistLineupLine($command: MergePlaylistLineupLineCommand!) {
    mergePlaylistLineupLine(command: $command)
  }
`;

const deletePlaylistLineupLine = gql`
  mutation deletePlaylistLineupLine($command: DeletePlaylistLineupLineCommand!) {
    deletePlaylistLineupLine(command: $command)
  }
`;

const rematchPlaylistRecording = gql`
  mutation rematchPlaylistRecording($command: RematchPlaylistRecordingCommand!) {
    rematchPlaylistRecording(command: $command)
  }
`;

const acceptPlaylistRecordingsLineup = gql`
  mutation acceptPlaylistRecordingsLineup($command: [AcceptPlaylistLineupCommand]!) {
    acceptPlaylistRecordingsLineup(command: $command) {
      recording_ids
    }
  }
`;

const rejectPlaylistRecordingsLineup = gql`
  mutation rejectPlaylistRecordingsLineup($command: [RejectPlaylistLineupCommand]!) {
    rejectPlaylistRecordingsLineup(command: $command)
  }
`;

const applyAllPlaylistChanges = gql`
  mutation applyAllPlaylistChanges($command: ApplyAllPlaylistChangesCommand!) {
    applyAllPlaylistChanges(command: $command)
  }
`;

const createPlaylistRejectionFile = gql`
  mutation createPlaylistRejectionFile($command: CreatePlaylistRejectionFileCommand!) {
    createPlaylistRejectionFile(command: $command)
  }
`;

const deletePlayedRecordingReport = gql`
  mutation deletePlayedRecordingReport($reportId: String!) {
    deletePlayedRecordingReport(reportId: $reportId)
  }
`;

const deletePlaylistReport = gql`
  mutation deletePlaylistReport($reportId: String!) {
    deletePlaylistReport(reportId: $reportId)
  }
`;

const archiveReportHeader = gql`
  mutation archiveReportHeader($reportId: String!) {
    archiveReportHeader(reportId: $reportId)
  }
`;

const createStimFile = gql`
  mutation createStimFile($reportId: String) {
    createStimFile(reportId: $reportId)
  }
`;

const addUsageCorrection = gql`
  mutation addUsageCorrection($command: AddUsageCorrectionCommand!) {
    addUsageCorrection(command: $command)
  }
`;

const getUsageDetails = gql`
  query getUsageDetails($reportId: String!, $groupId: String!) {
    getUsageDetails(reportId: $reportId, groupId: $groupId) {
      channel
      channel_name
      program
      play_date
    }
  }
`;

const updateReportHeader = gql`
  mutation updateReportHeader($reportId: String!, $request: UpdateReportHeaderRequest!) {
    updateReportHeader(reportId: $reportId, request: $request)
  }
`;

export default {
  getDataUrls,
  getLines,
  getAlbumLines,
  identifyMatchLine,
  ignoreMatchLine,
  unmatchMatchLine,
  revertMatch,
  identifyMatchAlbumLine,
  ignoreMatchAlbumLine,
  revertAlbumMatch,
  exportRecordings,
  exportAlbums,
  createRestedRecordings,
  createRestedAlbums,
  loadHits,
  getReportHeader,
  listReportHeaders,
  listVrdbReportHeaders,
  fetchPlaylistRecordings,
  getPlaylistRecording,
  toggleLineupUpdatedAttributes,
  acceptPlaylistLineup,
  rejectPlaylistLineup,
  rejectPlaylistLineupLine,
  undoRejectPlaylistLineupLine,
  undoMergePlaylistLineupLine,
  mergePlaylistLineupLine,
  deletePlaylistLineupLine,
  rematchPlaylistRecording,
  acceptPlaylistRecordingsLineup,
  rejectPlaylistRecordingsLineup,
  applyAllPlaylistChanges,
  createPlaylistRejectionFile,
  deletePlayedRecordingReport,
  deletePlaylistReport,
  archiveReportHeader,
  createStimFile,
  loadAlbumHits,
  addUsageCorrection,
  getUsageDetails,
  updateReportHeader,
};
