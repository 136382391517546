import { createError, ErrorType } from '../domain/starNotification';
/**
 * Transforms the Apollo client error.
 * @param obj the error to transform
 * @returns {Object} the error
 */
export function transformApolloError(error) {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const e = error.graphQLErrors[0];
    const message = e.cause?.message || e.message;
    return createError(ErrorType.SERVER, message, error, {
      errors: e.extensions?.errors || [],
    });
  }
  return createError(ErrorType.NETWORK, error.message, error);
}

export function transformAxiosError(axiosError) {
  let type = 'Error';
  let errorMessage = '';
  if (!axiosError.response) {
    type = ErrorType.NETWORK;
    return createError(type, errorMessage, axiosError);
  } else if (axiosError.response.status >= 400) {
    type = ErrorType.SERVER;
    errorMessage = `${axiosError.response.data.message}`;
  }

  const { error, message, statusCode, ...errorData } = axiosError.response.data;

  return createError(type, errorMessage, axiosError, errorData || {});
}
