import { strOrNull } from '../../../common/typeUtils';
import { generateMutations, generateGetters } from '../../utils';
import {
  createPersonInfo,
  createOrganisationInfo,
  createAssociateAggregate,
  createMetaInfo,
  createSystemState,
} from '../../../domain/associateDomain';
import { createTaxInformation, createContactInfo } from '../../../domain/common';
import { createPaymentInfo } from '../../../domain/commonPayment';

// The root, initial state object
const rootState = createAssociateAggregate();

const actions = {
  createAggregate(context) {
    context.commit('CREATE_AGGREGATE');
  },
  setAggregate(context, associateAggregate) {
    context.commit('SET_AGGREGATE', associateAggregate);
  },
};

const mutations = {
  ...generateMutations(rootState),
  CREATE_AGGREGATE(state) {
    state.id = null;
    state.version = null;
    state.stream_id = null;
    state.process_id = null;
    state.type = null;
    state.person_info = createPersonInfo();
    state.organisation_info = createOrganisationInfo();
    state.contact_info = createContactInfo();
    state.tax_info = createTaxInformation();
    state.meta_info = createMetaInfo();
    state.payment_info = createPaymentInfo();
    state.associates = [];
    state.num_child_associations = null;
    state.child_associations = [];
    state.system_state = createSystemState();
    state.documents = [];
    state.local_codes = [];
  },
  SET_AGGREGATE(state, associateAggregate) {
    state.id = associateAggregate.id;
    state.version = associateAggregate.version;
    state.stream_id = associateAggregate.stream_id;
    state.process_id = associateAggregate.process_id;
    state.type = associateAggregate.type;
    state.person_info = associateAggregate.person_info;
    state.organisation_info = associateAggregate.organisation_info;
    state.contact_info = associateAggregate.contact_info;
    state.payment_info = associateAggregate.payment_info;
    state.tax_info = associateAggregate.tax_info;
    state.meta_info = associateAggregate.meta_info;
    state.associates = associateAggregate.associates;
    state.num_child_associations = associateAggregate.num_child_associations;
    state.child_associations = associateAggregate.child_associations;
    state.system_state = associateAggregate.system_state;
    state.documents = associateAggregate.documents;
    state.local_codes = associateAggregate.local_codes;
  },
  addCompanyAccount(state, account) {
    state.payment_info.company_account = account;
  },
  removeCompanyAccount(state) {
    delete state.payment_info.company_account;
  },
  addForeignAccount(state, account) {
    state.payment_info.foreign_account = account;
  },
  removeForeignAccount(state) {
    delete state.payment_info.foreign_account;
  },
  addPrivateAccount(state, privateAccount) {
    state.payment_info.private_account = privateAccount;
  },
  removePrivateAccount(state) {
    delete state.payment_info.private_account;
  },
  resetPaymentInfo(state) {
    state.payment_info = {
      ...createPaymentInfo(),
    };
  },
  addAddress(state, address) {
    state.contact_info.address = address;
  },
  addEmail(state, email) {
    state.contact_info.emails.push(email);
  },
  addPhoneNumber(state, phoneNumber) {
    state.contact_info.phone_numbers.push(phoneNumber);
  },
  replaceEmailAt(state, replacer) {
    state.contact_info.emails.splice(replacer.index, 1, replacer.value);
  },
  removeEmailAt(state, idx) {
    state.contact_info.emails.splice(idx, 1);
  },
  removePhoneNumberAt(state, idx) {
    state.contact_info.phone_numbers.splice(idx, 1);
  },
  updateTaxDomicile(state, taxDomicile) {
    state.tax_info.tax_domicile = strOrNull(taxDomicile);
  },
  updateTaxTin(state, taxTin) {
    state.tax_info.tin = strOrNull(taxTin);
  },
  updateTaxVatNumber(state, taxVatNumber) {
    state.tax_info.vat_number = strOrNull(taxVatNumber);
  },
  updateChildAssociations(state, childAssociations) {
    state.child_associations = childAssociations;
  },
  updateDocuments(state, documents) {
    state.documents = documents;
  },
};

const getters = {
  ...generateGetters(rootState),
  person_info: (state) => state.person_info,
  child_associations: (state) => state.child_associations,
  organisation_info: (state) => state.organisation_info,
  contact_info: (state) => state.contact_info,
  paymentInfo: (state) => state.payment_info,
  privateAccount: (state) => (state.payment_info ? state.payment_info.private_account : null),
  companyAccount: (state) => (state.payment_info ? state.payment_info.company_account : null),
  foreignAccount: (state) => (state.payment_info ? state.payment_info.foreign_account : null),
  tax_info: (state) => state.tax_info,
  taxTin: (state) => (state.tax_info.tin ? state.tax_info.tin : null),
  taxDomicile: (state) => (state.tax_info.tax_domicile ? state.tax_info.tax_domicile : null),
  taxVatNumber: (state) => (state.tax_info.vat_number ? state.tax_info.vat_number : null),
  aggregate: (state) => state,
  systemState: (state) => state.system_state,
  documents: (state) => state.documents,
  localCodes: (state) => state.local_codes,
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
