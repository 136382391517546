var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header-bar",class:{
      'header-bar--prod-warning':
        (!_vm.isTestEnvironment && (_vm.isDeveloper || _vm.isTester)) ||
        (_vm.isTestEnvironment && !_vm.isDeveloper && !_vm.isTester),
    },style:(_vm.isChristmasTime)},[_c('div',{staticClass:"header-bar__star"},[_c('logo')],1),_c('div',{staticClass:"header-bar__search"},[(!_vm.advancedSearchScope)?_c('search',{attrs:{"placeholder":"E.g. Joakim Berg","min-chars":"2"}}):_vm._e(),(_vm.uuid)?_c('div',{staticClass:"line-height",staticStyle:{"margin-right":"10px"}},[_c('button',{staticClass:"btn copy_btn",on:{"click":_vm.copyUuid}},[_c('i',{staticClass:"fas fa-copy"})])]):_vm._e(),(_vm.isDeveloper)?_c('div',{staticStyle:{"margin-right":"10px"}},[_c('button',{staticClass:"btn",on:{"click":_vm.copyAuthToken}},[_vm._v("Copy authToken")])]):_vm._e(),(_vm.isTestEnvironment)?_c('span',{staticClass:"capitalize text--bold",staticStyle:{"color":"var(--yellow)","font-size":"18px"}},[_vm._v(" | "+_vm._s(!_vm.isDeveloper && !_vm.isTester ? 'WARNING: ' : '')+_vm._s(_vm.getEnvironment.toUpperCase())+" | ")]):_vm._e(),(!_vm.isTestEnvironment && (_vm.isDeveloper || _vm.isTester))?_c('span',{staticClass:"capitalize text--bold",staticStyle:{"color":"var(--yellow)","font-size":"18px"}},[_vm._v(" | WARNING: PROD | ")]):_vm._e()],1),_c('div',{staticClass:"header-bar__menu"},[_c('nav',[_c('ul',{staticClass:"header-bar__list"},[_c('li',{on:{"click":_vm.toggleWorklistSidebar}},[_c('i',{staticClass:"fas fa-list-ul"})]),_c('li',{staticClass:"header-bar__list-item",class:{ illuminate: _vm.illuminateNoticeIcon },on:{"click":_vm.toggleProcessSidebar}},[_c('i',{staticClass:"fas fa-clock"}),(_vm.processChanges.length > 0 && !_vm.processSidebarVisible)?_c('span',{staticClass:"notice-badge"},[_c('span',[_vm._v(_vm._s(_vm.processChanges.length))])]):_vm._e()]),_c('li',{staticClass:"header-bar__settings",on:{"click":_vm.showSettings,"mouseleave":function($event){_vm.settingsVisible = false}}},[_c('i',{staticClass:"fas fa-user"}),_c('ul',{class:{
                'header-bar__options': true,
                'header-bar__options--visible': _vm.settingsVisible,
              }},[_c('li',{on:{"click":_vm.logout}},[_vm._v("Log out")])])])])])])]),_c('worklist-sidebar',{attrs:{"visible":_vm.worklistSidebarVisible},on:{"onClose":_vm.toggleWorklistSidebar}}),_c('process-sidebar',{attrs:{"visible":_vm.processSidebarVisible},on:{"onClose":_vm.toggleProcessSidebar,"processAdded":function($event){return _vm.addProcessChange('added', $event)},"processFinished":function($event){return _vm.addProcessChange('finished', $event)},"processError":function($event){return _vm.addProcessChange('error', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }