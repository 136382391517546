<template>
  <div>
    <div class="row wrapper">
      <h1 class="float-left">Ledger for {{ performerName }}</h1>
    </div>
    <ledger-overview
      :id="performerId"
      :balance="ledgerBalance"
      :latest-payments="latestPayments"
      :manual-payments-disabled="performerDeleted"
      details-view="viewPerformerPaymentDetails"
      node-type="performer"
    />

    <div class="tabs row wrapper">
      <div class="tabs__wrapper">
        <router-link :to="{ name: 'viewPerformerRemunerationHistory', params: { performerId } }">
          Remuneration History
        </router-link>
        <router-link :to="{ name: 'viewPerformerRemunerationBalance', params: { performerId } }">
          Remuneration Balance
        </router-link>
        <router-link :to="{ name: 'viewPerformerPaymentHistory', params: { performerId } }">
          Payment History
        </router-link>
        <router-link :to="{ name: 'viewPerformerTransactionHistory', params: { performerId } }">
          Transaction History
        </router-link>
      </div>
    </div>

    <router-view type="performer" />
  </div>
</template>

<script>
import PaymentService from '../../../../services/paymentService';
import PerformerService from '../../../../services/performerService';
import LedgerOverview from '../../../ui/ledger/ledger-overview';
import LedgerHelper from '../../../ui/ledger/ledgerHelper';

export default {
  name: 'ViewLedger',
  components: {
    LedgerOverview,
  },
  data() {
    return {
      performerId: this.$router.currentRoute.params.id,
      loading: false,
      performerName: '',
      ledgerBalance: 0,
      performerDeleted: false,
      latestPayments: [],
    };
  },
  async created() {
    this.fetchPaymentHistory();
    const performer = await PerformerService.getPerformer(this.performerId);
    this.performerName = `${performer.general_info.first_name} ${performer.general_info.last_name}`;
    this.performerDeleted = performer.deleted;
    this.$nextTick(() => this.fetchRemunerationBalance());
  },
  methods: {
    async fetchRemunerationBalance() {
      this.loading = true;
      try {
        const res = await PaymentService.getLedgerBalance(this.performerId);
        this.ledgerBalance = res.ledgerBalance;
      } catch (error) {
        error.title = 'Error fetching payment remuneration records';
        this.$addStarError(error);
      }
      this.loading = false;
    },
    async fetchPaymentHistory() {
      this.loading = true;
      try {
        const ledgerId = this.$router.currentRoute.params.id;
        const { items } = await LedgerHelper.getLatestPayments(ledgerId, 4);
        this.latestPayments = items;
      } catch (error) {
        error.title = 'Error fetching payment history';
        this.$addStarError(error);
      }
      this.loading = false;
    },
  },
};
</script>
