<template>
  <form>
    <div class="row small-inner">
      <div class="col s6">
        <h2>External Information</h2>
      </div>
      <div class="col s6 text--right">
        <action-buttons @save="updateMetadataInfo" @cancel="cancel" />
      </div>
    </div>

    <div class="row card">
      <external-sources-input
        label="External sources"
        :external-sources="performer.metadata_info.other_external_sources"
        :right-column="true"
        @add="onAddExternalSource"
        @remove="onRemoveExternalSource"
      />
    </div>
  </form>
</template>

<script>
import EditHelper from './editHelper';
import { createOtherExternalSources } from '../../../../domain/common';
import ExternalSourcesInput from '../../../ui/input/external-sources-input';
import ActionButtons from '../../../ui/button/action-buttons';

import PnrService from '@/services/pnrService';
import { createMetadataInfoCommand } from '@/domain/performerDomain';

export default {
  name: 'EditExternalSources',
  components: {
    ExternalSourcesInput,
    ActionButtons,
  },
  data() {
    return {
      performer: EditHelper.getCurrentPerformer(),
    };
  },
  created() {
    if (this.performer.metadata_info.other_external_sources.length === 0) {
      this.onAddExternalSource();
    }
  },
  methods: {
    onAddExternalSource() {
      this.performer.metadata_info.other_external_sources.push(createOtherExternalSources());
    },
    onRemoveExternalSource(index) {
      this.performer.metadata_info.other_external_sources.splice(index, 1);
      if (this.performer.metadata_info.other_external_sources.length === 0) {
        this.onAddExternalSource();
      }
    },
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    async updateMetadataInfo() {
      const command = PnrService.prepareCommand(
        this.performer,
        createMetadataInfoCommand(),
        this.performer.metadata_info,
      );

      await PnrService.postCommands({ errorTitle: 'Could not update external information' }, [
        command,
      ]);
      this.$emit('saved');
    },
  },
};
</script>
