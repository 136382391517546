<template>
  <modal
    :hide-overflow="false"
    submit-label="Apply"
    :wide="true"
    :disable-submit="!selected.length"
    @save="$emit('copy', selected)"
    @close="$emit('cancel')"
    @cancel="$emit('cancel')"
  >
    <h1 slot="header">Copy Lineup</h1>
    <div slot="body">
      <div v-if="source" class="row">
        <span class="text--bold">Copy lineup from:</span>
        {{ source.name }}
        {{ source.version_title ? '(' + source.version_title + ')' : '' }}
        <span class="text--italic">by</span>
        {{ source.main_artist.name }}
      </div>

      <div v-if="enableSearch" class="row">
        <searchable-input
          label="Search recording"
          name="otherRecording"
          placeholder="E.g. Stoppa mig Juni (Lilla Ego)"
          use-template="recording"
          :clear-after-select="true"
          :css-props="{ whiteSpace: 'normal' }"
          :searcher="search"
          @input="addDestination"
        />
      </div>

      <table class="modal__headers">
        <sortable-head
          :bulk-enabled="true"
          :columns="columns"
          :marked-for-bulk="
            localDestinations.length > 0 && localDestinations.length === selected.length
          "
          :more-options="false"
          :bulk-suspend="localDestinations.length === 0"
          @markAll="toggleAll($event)"
        />
        <tbody>
          <tr v-if="localDestinations.length === 0">
            <td colspan="4" class="none">No recordings, try searching for one...</td>
          </tr>
          <tr
            v-for="dest in localDestinations.slice(offset, offset + HITS_PER_PAGE)"
            :key="dest.recording.id"
          >
            <td class="thin-column">
              <input
                :id="dest.recording.id"
                class="filled-in"
                type="checkbox"
                :checked="isSelected(dest) && !dest.lineup_locked"
                :disabled="dest.lineup_locked"
                @change="toggleOne($event.target.checked, dest)"
              />
              <label :for="dest.recording.id" />
            </td>
            <td>
              <span v-if="dest.recording.side_no || dest.recording.track_no">
                {{ dest.recording.side_no }}.{{ dest.recording.track_no }}
              </span>
            </td>
            <td>
              {{ dest.name }}
              {{ dest.lineup_locked ? ' 🔒' : '' }}
            </td>
            <td>{{ dest.main_artist.name }}</td>
          </tr>
        </tbody>
      </table>

      <pagination
        v-show="numberOfPages > 0"
        :number-of-pages="numberOfPages"
        :selected-page="offset / HITS_PER_PAGE + 1"
        :number-of-hits="localDestinations.length"
        :hits-per-page="HITS_PER_PAGE"
        :show-hits-per-page="false"
        @selectPage="offset = ($event - 1) * HITS_PER_PAGE"
      />
    </div>
  </modal>
</template>

<script>
import { cloneDeep } from 'lodash';
import Modal from '../dialog/modal';
import SearchableInput from '../input/searchable-input';
import SearchHelper from '../../search/searchHelper';
import SortableHead from '../table/sortable-head';
import Pagination from '../../pagination';

export default {
  name: 'CopyLineupModal',
  components: {
    Modal,
    SearchableInput,
    SortableHead,
    Pagination,
  },
  props: {
    enableSearch: {
      type: Boolean,
      default: false,
    },
    destinations: {
      type: Array,
      default: () => [],
    },
    source: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      localDestinations: [],
      selected: [],
      columns: [{ name: 'No', thinColumn: true }, { name: 'Track' }, { name: 'Main Artist' }],
      offset: 0,
      HITS_PER_PAGE: 12,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.localDestinations.length / this.HITS_PER_PAGE);
    },
    sourceTrack() {
      return this.mapToTrack(this.source);
    },
  },
  created() {
    this.localDestinations = cloneDeep(this.destinations);
  },
  methods: {
    toggleAll(toggle) {
      if (toggle) {
        this.selected = this.localDestinations
          .filter((t) => !t.lineup_locked)
          .map((t) => t.recording.id);
      } else {
        this.selected = [];
      }
    },
    toggleOne(toggle, track) {
      if (toggle) {
        this.selected = [...this.selected, track.recording.id];
      } else {
        this.selected = this.selected.filter((id) => id !== track.recording.id);
      }
    },
    isSelected(track) {
      return this.selected.includes(track.recording.id);
    },
    search(terms) {
      return SearchHelper.quickSearchRecording(terms);
    },
    addDestination(recording) {
      const track = this.mapToTrack(recording);
      this.localDestinations = [...this.localDestinations, track];
      this.selected = [...this.selected, ...(!track.lineup_locked ? [track.recording.id] : [])];
    },
    mapToTrack(recording) {
      if (recording.recording && recording.recording.id) {
        return recording;
      }

      return {
        name: recording.name,
        main_artist: recording.main_artist,
        version_title: recording.version_title,
        lineup_locked: recording.lineup_locked,
        recording: {
          id: recording.id,
        },
      };
    },
  },
};
</script>
