<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <ComponentSpinner />
      </div>
    </div>

    <div v-else>
      <div class="row">
        <div class="col s2">
          <select-input
            name="tx-type-filter"
            label="Transaction type"
            item-key="code"
            item-value="name"
            :value="currentSourceType"
            :items="sourceTypeFilter"
            @input="onSourceTypeFilter"
          />
        </div>

        <div class="col s2">
          <select-date
            label="From"
            name="start-filter"
            :value="filterTerms.startDate"
            @input="onStartDateFilter"
          />
        </div>

        <div class="col s2">
          <select-date
            label="To"
            name="end-filter"
            :value="filterTerms.endDate"
            @input="onEndDateFilter"
          />
        </div>
      </div>

      <div class="row card">
        <div class="row">
          <table class="table__border">
            <thead>
              <th>Date</th>
              <th>Transaction</th>
              <th>Source</th>
              <th class="text--right">Amount</th>
              <th class="text--right">Balance</th>
            </thead>
            <tbody>
              <tr v-for="(row, idx) of rows" :key="row.id" :class="{ noborder: noBorder(idx) }">
                <td>{{ $filters.dateFormat($filters.dateParse(row.createdAt), 'YYYY-MM-DD') }}</td>
                <td>
                  <router-link :to="`/transactions/${row.txId}`">
                    {{ getLabelText(row) }}
                    {{ getRefsText(row) }}
                  </router-link>
                </td>
                <td>
                  <router-link v-if="getSourceLink(row)" :to="getSourceLink(row)">
                    {{ getSourceType(row.sourceType) }}
                  </router-link>
                  <span v-else>{{ getSourceType(row.sourceType) }}</span>
                </td>
                <td class="text--right">
                  {{ $filters.formatAmountCent(getAmount(row), 'SEK') }}
                </td>
                <td
                  class="text--right"
                  :class="{ negative: row.balance < 0, positive: row.balance > 0 }"
                >
                  {{ $filters.formatAmountCent(row.balance, 'SEK') }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          topic="account-statement"
          :number-of-pages="numPages"
          :number-of-hits="pagination.total"
          :hits-per-page="pagination.limit"
          :selected-page="curPage"
          :show-hits-per-page="true"
          :loading="loading"
          @selectPage="onSelectPage"
          @updateHitsPerPage="onUpdateHitsPerPage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectDate from '../../ui/select/select-date';
import SelectInput from '../../ui/select/select-input';
import ComponentSpinner from '../../component-spinner';
import { query } from '../../../services/apolloRequest';
import gql from 'graphql-tag';
import { txSourceType, EntryType, LabelType } from '@/domain/paymentDomain';
import Pagination from '../../pagination';

export default {
  name: 'ViewAccountStatement',
  components: {
    SelectDate,
    SelectInput,
    ComponentSpinner,
    Pagination,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      entityId: '',
      loading: false,
      sortColumn: 'date',
      sortOrder: 'asc',
      filterTerms: {
        startDate: null,
        endDate: null,
        areaId: null,
        sourceType: null,
      },
      nodes: [],
      rows: [],
      pagination: {
        offset: 0,
        limit: 25,
        total: 0,
      },
    };
  },
  computed: {
    numPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    curPage() {
      return Math.floor(this.pagination.offset / this.pagination.limit) + 1;
    },
    sourceTypeFilter() {
      const { Payment, Refund, Deposit, DistClosure } = txSourceType;
      return [Payment, Refund, Deposit, DistClosure].map((v) => ({
        code: txSourceType[v],
        name: txSourceType[v],
      }));
    },
    currentSourceType() {
      const st = this.filterTerms.sourceType;
      return st === null ? st : txSourceType[st];
    },
  },
  async created() {
    await this.fetchData();
    await this.getReferredNodes();
  },
  methods: {
    async onSelectPage(pageNo) {
      this.pagination.offset = this.pagination.limit * (pageNo - 1);
      await this.fetchData();
      await this.getReferredNodes();
    },
    async onUpdateHitsPerPage(numHits) {
      this.pagination.limit = Number(numHits);
      this.pagination.offset = 0;
      await this.fetchData();
      await this.getReferredNodes();
    },
    isNewTxSource(rowIdx) {
      const cur = this.rows[rowIdx];
      const last = this.rows[rowIdx - 1];
      return !last || last.sourceId !== cur.sourceId;
    },
    noBorder(rowIdx) {
      const cur = this.rows[rowIdx];
      const last = this.rows[rowIdx - 1];
      return !!last && last.sourceId === cur.sourceId;
    },
    getLabelText(row) {
      switch (row.labelType) {
        case LabelType.VatReceived:
          return 'VAT Received';
        case LabelType.VatSent:
          return 'VAT Returned';
        case LabelType.PaymentSent:
          return 'Payment Sent';
        case LabelType.PaymentReceived:
          return 'Payment Received';
        case LabelType.RefundReceived:
          return 'Refund Received';
        case LabelType.RefundSent:
          return 'Refund Sent';
        default:
          return this.getSourceType(row.sourceType);
      }
    },
    getLabelPreposition(row) {
      if (row.labelRefs?.length === 0) {
        return '';
      }
      switch (row.labelType) {
        case LabelType.PaymentSent:
        case LabelType.RefundSent:
        case LabelType.ManualTransferTo:
          return 'to';
        case LabelType.PaymentReceived:
        case LabelType.RefundReceived:
        case LabelType.ManualTransferFrom:
          return 'from';
        default:
          return '';
      }
    },
    getRefsText(row) {
      let str = '';
      if (row.labelRefs) {
        str = this.getLabelPreposition(row);
        const nodes = this.nodes.filter((n) => row.labelRefs.includes(n.id));
        const lengthDiff = row.labelRefs.length - nodes.length;
        str = `${str} ${nodes.map((n) => n.name).join(', ')}`;
        if (lengthDiff > 0) {
          str = `${str} (and ${lengthDiff} more)`;
        }
      }
      return str;
    },
    getSourceLink(row) {
      switch (row.sourceType) {
        case txSourceType.Payment:
          return `payment-details/${row.sourceId}`;
        case txSourceType.Refund:
          return `/refunds/${row.sourceId}`;
        case txSourceType.Transfer:
          return `/transactions/${row.txId}`;
        default:
          return null;
      }
    },
    async getReferredNodes() {
      const maxRefsPerRow = 2;
      const nodeIds = this.rows.flatMap((r) => r.labelRefs?.slice(0, maxRefsPerRow) || []);
      if (nodeIds.length > 0) {
        const { nodes } = await query({
          query: gql`
            query nodes($nodeIds: [String]) {
              nodes(nodeIds: $nodeIds) {
                id
                name
              }
            }
          `,
          variables: {
            nodeIds,
          },
        });
        this.nodes = nodes;
      }
    },
    async fetchData() {
      this.loading = true;
      this.entityId = this.$router.currentRoute.params.id;
      try {
        const res = await query(
          {
            query: gql`
              query ledgerDetails($ledgerDetailRequest: LedgerDetailRequest) {
                ledgerDetails(ledgerDetailRequest: $ledgerDetailRequest) {
                  totalCount
                  rows {
                    id
                    amount
                    balance
                    createdAt
                    txId
                    entryType
                    entryId
                    sourceType
                    sourceId
                    areaName
                    labelType
                    labelRefs
                  }
                }
              }
            `,
            variables: {
              ledgerDetailRequest: {
                ledgerId: this.entityId,
                limit: this.pagination.limit,
                offset: this.pagination.offset,
                startDate: this.filterTerms.startDate,
                endDate: this.filterTerms.endDate,
                areaId: this.filterTerms.areaId,
                sourceType: this.filterTerms.sourceType,
              },
            },
          },
          { pickProp: 'ledgerDetails' },
        );
        this.rows = res.rows;
        this.pagination.total = res.totalCount;
        // this.mutableRows = result;
        // this.sourceRows = result;
        // if (result.length > 0) {
        //   this.pagination.nextFromVersion = this.mutableRows[this.mutableRows.length - 1].version;
        // }
      } catch (error) {
        console.log('Error fetching account statements', error);
      }
      this.loading = false;
    },
    async nextPage() {
      this.pagination.offset += this.pagination.limit;
      await this.fetchData();
    },
    async previousPage() {
      this.pagination.offset = Math.max(this.pagination.offset - this.pagination.limit, 0);
      await this.fetchData();
    },
    onSourceTypeFilter(sourceType) {
      this.filterTerms.sourceType = txSourceType[sourceType];
      this.fetchData();
    },
    onStartDateFilter(date) {
      this.filterTerms.startDate = date;
      this.fetchData();
    },
    onEndDateFilter(date) {
      this.filterTerms.endDate = date;
      this.fetchData();
    },
    getSourceType(id) {
      return this.$filters.translate(`ledger.source_type.${id}`);
    },
    getAmount(row) {
      if (row.entryType === EntryType.Credit) {
        return row.amount;
      }
      return -row.amount;
    },
  },
};
</script>

<style scoped>
.pending {
  opacity: 0.5;
}
.noborder {
  border-top: none;
}
</style>
