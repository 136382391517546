<template>
  <div>
    <input
      :id="id"
      type="checkbox"
      :name="name"
      :checked="on"
      class="toggle-switch-checkbox"
      @change="onChange"
    />
    <label class="toggle-switch-label" :for="id">
      <span class="toggle-switch-label-text">Enabled</span>
      <div class="toggle-switch-wrapper">
        <div class="toggle-switch-indicator" />
        <div class="toggle-switch-state">{{ on ? 'ON' : 'OFF' }}</div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    name: String,
    id: String,
    on: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(event) {
      this.$emit('change', event.target.checked);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.toggle-switch-label {
  width: 4rem;
  height: 1.5rem;
  cursor: pointer;
  background: #f0f0f0;
  padding: 0;
  display: block;
  &:before {
    content: none;
  }
}

.toggle-switch-checkbox:checked + .toggle-switch-label {
  background: var(--green--extralight);
  & .toggle-switch-indicator {
    background: var(--green--medium);
    order: 2;
  }
  & .toggle-switch-state {
    order: 1;
    color: var(--text);
  }
}

.toggle-switch-label-text {
  display: none;
}

.toggle-switch-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  align-items: center;
}

.toggle-switch-indicator {
  height: 100%;
  background: var(--grey--vlight);
  flex: 0 0 40%;
  order: 1;
}

.toggle-switch-state {
  height: 100%;
  flex: 0 0 60%;
  order: 2;
  text-align: center;
  line-height: 1.5rem;
  background: transparent;
  color: var(--grey--light);
}
</style>
