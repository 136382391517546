<template>
  <div class="page-not-found">
    <h1>✵</h1>
    <h2>404 - This URL does not lead anywhere, sorry.</h2>
    <p v-if="id" class="text--center">
      ID you looked for:
      <strong>{{ id }}</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.page-not-found {
  h1 {
    font: 50px sans-serif;
    text-align: center;
  }
  h2 {
    font: 700 30px sans-serif;
    text-align: center;
  }
}
</style>
