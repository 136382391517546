export const reserveReleaseState = {
  WORKING: 'W',
  PENDING_CONFIRMATION: 'P',
  COMPLETE: 'C',
  ERROR: 'E',
  NEW: 'N',
  FINISHED: 'F',
};

export const reserveReleaseRowState = {};
