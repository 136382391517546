/**
 * The History Service contains history related methods.
 */
import gql from '../domain/historyGql';
import { query } from './apolloRequest';

function sdegCreatedRecording(payload) {
  return (
    payload.basic_info &&
    payload.basic_info.tags &&
    payload.basic_info.tags.indexOf('created_from_music_report') > -1
  );
}

async function getActivities(id) {
  const history = await query(
    { query: gql.getHistory, variables: { idTerm: id } },
    { pickProp: 'history' },
  );
  const activities = history.map((item) => ({
    timestamp: item.timestamp,
    username: item.user,
    version: item.version,
    type: item.type.split(':')[0],
    section:
      item.version === 1 && sdegCreatedRecording(item.payload)
        ? 'sdeg_created_recording'
        : item.type.split(':')[1],
    payload: item.payload,
  }));
  activities.sort((a, b) => b.version - a.version);
  activities.forEach((activity, index) => (activity.id = index));
  return activities;
}

export default {
  getActivities,
};
