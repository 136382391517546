export default {
  mainartist: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'name', highlight: true },
    { label: 'Alternate names', field: 'alternate_names', highlight: true },
    { label: 'Origin', field: 'origin_country', filter: 'formatCountry' },
  ],
  performer: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'name', highlight: true },
    { label: 'Pseudo names', field: 'pseudo_names', highlight: true },
    { label: 'Nationality', field: 'nationality', filter: 'formatCountry' },
  ],
  mainArtistNameSearch: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'name', highlight: true },
    { label: 'Genre', field: 'genre' },
    { label: 'Nationality', field: 'origin_country', filter: 'formatCountry' },
    {
      label: 'Lineup',
      field: 'performers',
      transform: (lineup) =>
        lineup &&
        lineup
          .map((p) => p.name)
          .slice(0, 5)
          .join(', ')
          .concat(lineup.length > 5 ? ` and ${lineup.length - 5} more` : ''),
    },
  ],
  performerNameSearch: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'name', highlight: true },
    {
      label: 'Pseudo names',
      field: 'pseudo_names',
      transform: (pseudo) =>
        pseudo &&
        Array.isArray(pseudo) &&
        pseudo
          .slice(0, 5)
          .join(', ')
          .concat(pseudo.length > 5 ? ` and ${pseudo.length - 5} more` : ''),
    },
    { label: 'Instrument', field: 'main_instrument', filter: 'formatInstrument' },
    { label: 'Date of birth', field: 'date_of_birth' },
    {
      label: 'Main artists',
      field: 'main_artists',
      transform: (artist) =>
        artist &&
        Array.isArray(artist) &&
        artist
          .slice(0, 5)
          .join(', ')
          .concat(artist.length > 5 ? ` and ${artist.length - 5} more` : ''),
    },
    {
      label: 'Society',
      field: 'mandates',
      transform: (mandates) =>
        mandates &&
        Object.values(
          mandates
            .filter((m) => !m.end_date)
            .reduce((acc, curr) => {
              const key = curr.mandate_type + curr.society_code;
              if (!acc[key]) {
                acc.key = curr;
              }
              return acc;
            }, {}),
        ),
      icon: true,
    },
    { label: 'IPN', field: 'ipn' },
  ],
  associateNameSearch: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'display_name', highlight: true },
    { label: 'Date of birth', field: 'date_of_birth' },
    { label: 'Nationality', field: 'nationality', filter: 'formatCountry' },
    { label: 'Type', field: 'relation_type', translationPrefix: 'common.child_associate' },
  ],
  album: [
    { label: '', field: 'icon', icon: true },
    { label: 'Album', field: 'name', highlight: true },
    { label: 'Main artist', field: 'main_artist.name', highlight: true },
    { label: 'Release date', field: 'release_date' },
  ],
  recording: [
    { label: '', field: 'icon', icon: true },
    { label: 'Recording name', field: 'name', highlight: true },
    { label: 'Version', field: 'version_title' },
    { label: 'VRDB', field: 'vrdb_type', filter: 'toTitleCase' },
    { label: 'Main artist', field: 'main_artist.name', highlight: true },
    { label: 'Recording date', field: 'recording_date', filter: 'formatRecordingDate' },
    { label: 'Status', field: 'distribution_state.status' },
  ],
  society: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'name', highlight: true },
    { label: 'Country', field: 'contact_info.address.country', filter: 'formatCountry' },
  ],
  user: [
    { label: '', field: 'icon', icon: true },
    {
      label: 'Name',
      field: 'name',
      highlight: true,
      transform: (value) => value.replace('.', ' '),
      filter: 'toTitleCase',
    },
    { label: 'Username', field: 'name' },
  ],
  tag: [
    { label: '', field: 'icon', icon: true },
    {
      label: 'Name',
      field: 'name',
      highlight: true,
      transform: (value) => value.replace('.', ' '),
      filter: 'toTitleCase',
    },
  ],
  defaultTag: [
    { label: '', field: 'icon', icon: true },
    {
      label: 'Name',
      field: 'name',
      highlight: true,
      transform: (value) => value.replace('.', ' '),
      filter: 'toTitleCase',
    },
  ],
  associate: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'name', highlight: true },
    { label: 'Associate type', field: 'associate_type', translationPrefix: 'common' },
  ],
  childAssociate: [
    { label: '', field: 'icon', icon: true },
    { label: 'Name', field: 'name', highlight: true },
    { label: 'Associate type', field: 'type', translationPrefix: 'common.child_associate' },
  ],
  country: [{ label: 'Country', field: 'name', highlight: true }],
};
