<!-- A generic selectbox -->
<template>
  <div class="custom-select" :class="{ 'label-inline': inlineLabel }">
    <label
      v-if="label && showLabel"
      class="label"
      :class="{ 'label--error': validationErrors.has(name, scope) }"
    >
      <template v-if="bigLabel">
        <h2 class="row">
          {{ label }}
        </h2>
      </template>
      <template v-else>
        {{ label }}
      </template>
    </label>
    <span v-if="mandatory" class="mandatory">(mandatory)</span>
    <div class="custom-select__input-wrapper">
      <input
        v-if="rule !== ''"
        :id="`${name}-input`"
        v-model="content"
        v-validate="rule"
        autocomplete="off"
        :name="name"
        value=""
        type="text"
        placeholder="Choose one..."
        class="custom-select__input"
        :class="{ 'is-danger': validationErrors.has(name, scope) }"
        :disabled="disabled"
        :data-vv-scope="scope"
        :data-vv-as="label.toLowerCase()"
        @focus="toggleDropdown"
        @input="onChange"
        @keydown.tab="showList = false"
        @keyup.up="onKeyup"
        @keyup.down="onKeydown"
        @keyup.enter.prevent="onEnter"
        @change="updateValue"
        @mousedown="toggleDropdown"
      />
      <input
        v-else
        :id="`${name}-input`"
        v-model="content"
        autocomplete="off"
        :name="name"
        value=""
        type="text"
        placeholder="Choose one..."
        class="custom-select__input"
        :class="{ 'is-danger': validationErrors.has(name, scope) }"
        :disabled="disabled"
        :data-vv-scope="scope"
        :data-vv-as="label.toLowerCase()"
        @focus="toggleDropdown"
        @input="onChange"
        @keydown.tab="showList = false"
        @keyup.up="onKeyup"
        @keyup.down="onKeydown"
        @keyup.enter.prevent="onEnter"
        @change="updateValue"
        @mousedown="toggleDropdown"
      />
      <span class="custom-select__arrow" :disabled="disabled" @click="toggleDropdown"></span>
      <div v-show="showList" class="custom-select__list-wrapper">
        <ul v-if="empty" ref="list" class="custom-select__list">
          <li
            v-for="(item, index) in allItems"
            :key="index"
            class="custom-select__list-item"
            :class="{
              'active-item': index === stepCounter,
              disabled: disabledItems.includes(item),
            }"
            @click="setResult(item)"
          >
            {{ translationPrefix === '' ? getItemValue(item) : getTranslatedItemValue(item) }}
          </li>
        </ul>
        <ul v-else ref="list" class="custom-select__list">
          <li
            v-for="(result, idx) in results"
            v-show="results.length > 0"
            :key="idx"
            class="custom-select__list-item"
            :class="{
              'active-item': idx === stepCounter,
              disabled: disabledItems.includes(result),
            }"
            @click="setResult(result)"
          >
            {{ translationPrefix === '' ? getItemValue(result) : getTranslatedItemValue(result) }}
          </li>
          <li v-show="results.length === 0" class="custom-select__list-item">
            Sorry, nothing matched your search...
          </li>
        </ul>
      </div>
    </div>
    <span v-show="validationErrors.has(name, scope)" class="help is-danger float-left">
      <i class="fas fa-times-circle"></i>
      {{ validationErrors.first(name, scope) }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';

export default {
  name: 'SelectInput',
  inject: ['$validator'],
  props: {
    name: {
      type: String,
    },
    scope: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    bigLabel: {
      type: Boolean,
      default: false,
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    emptySelectOption: {
      type: Boolean,
      default: true,
    },
    rule: {
      type: [String, Object],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number, Object],
    },
    itemKey: {
      type: String,
      default: 'code',
    },
    itemValue: {
      type: String,
      default: 'name',
    },
    showError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledItems: {
      type: Array,
      default: () => [],
    },
    translationPrefix: {
      type: String,
      default: '',
    },
    sorted: {
      type: Boolean,
      default: false,
    },
    letterSequenceFilter: {
      type: Boolean,
      default: false,
    },
    queryParamPersist: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: '',
      results: [],
      showList: false,
      empty: true,
      stepCounter: -1,
      clicked: false,
      key: '',
    };
  },
  computed: {
    allItems() {
      let items = this.items ? this.items.slice() : [];
      if (this.sorted) {
        items = _.orderBy(items, this.itemValue, 'asc');
      }
      if (this.emptySelectOption && !items.find((item) => item[this.itemKey] === null)) {
        if (this.itemKey && this.itemValue) {
          items.unshift({ [this.itemKey]: null, [this.itemValue]: 'Choose one...' });
        } else {
          items.unshift('choose_one');
        }
      }
      return items;
    },
  },
  watch: {
    value(val) {
      if (val) {
        const selected = this.items.find(
          (item) => item[this.itemKey] === val || item[this.itemKey] === val[this.itemKey],
        );
        if (selected) {
          this.content =
            this.translationPrefix === ''
              ? this.getItemValue(selected)
              : this.getTranslatedItemValue(selected);
        } else {
          this.content = val;
        }
      } else {
        this.content = '';
      }
      if (this.queryParamPersist) {
        this.setQueryParam(val);
      }
    },
    items() {
      if (this.value != null) {
        const selected = this.items.find((item) => item[this.itemKey] === this.value);
        if (selected) {
          this.content =
            this.translationPrefix === ''
              ? this.getItemValue(selected)
              : this.getTranslatedItemValue(selected);
        }
      } else {
        this.content = '';
      }
    },
  },
  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.scrollList);
  },
  destroyed() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.scrollList);
  },
  created() {
    setTimeout(() => {
      if (this.value != null) {
        const selected = this.items.find((item) => item[this.itemKey] === this.value);
        if (selected) {
          this.content = this.getItemValue(selected);
        }
      }
    }, 250);
    if (this.queryParamPersist) {
      const value = (this.$route.query || {})[this.name];
      this.setQueryParam(value);
      this.emitInput(value);
    }
  },
  methods: {
    emitInput(value) {
      const emitValue = this.returnObject
        ? this.items.find((item) => item[this.itemKey] === value)
        : value;

      this.$emit('input', emitValue);
    },
    getItemKey(item) {
      if (item) {
        return this.itemKey ? item[this.itemKey] : item;
      }
      return '';
    },
    getItemValue(item) {
      if (item) {
        return this.itemValue ? item[this.itemValue] : item;
      }
      return '';
    },
    getTranslatedItemValue(item) {
      if (this.getItemKey(item) !== null) {
        return this.$filters.translate(`${this.translationPrefix}.${this.getItemValue(item)}`);
      }
      return 'Choose one...';
    },
    updateValue() {
      // this.$emit('input', e.target.value);
    },
    setResult(result) {
      this.content =
        this.translationPrefix === ''
          ? this.getItemValue(result)
          : this.getTranslatedItemValue(result);
      this.key = this.getItemKey(result);
      this.showList = false;
      this.emitInput(this.getItemKey(result));
    },
    onChange() {
      if (this.content) {
        this.empty = false;
      } else {
        this.empty = true;
      }
      this.stepCounter = -1;
      if (this.letterSequenceFilter) {
        this.results = this.items.filter((item) =>
          item[this.itemValue].toLowerCase().startsWith(this.content.toLowerCase()),
        );
      } else {
        this.results = this.items.filter(
          (item) => item[this.itemValue].toLowerCase().indexOf(this.content.toLowerCase()) > -1,
        );
      }
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.showList = false;
        this.stepCounter = -1;
      }
    },
    toggleDropdown(e) {
      if (e.type === 'focus') {
        this.$nextTick(() => {
          this.showList = true;
          this.stepCounter = 0;
        });
      }
      this.showList = !this.showList;
    },
    onKeydown() {
      if (this.empty) {
        if (this.stepCounter < this.items.length) {
          this.stepCounter = this.stepCounter + 1;
        }
      } else if (!this.empty) {
        if (this.stepCounter < this.results.length) {
          this.stepCounter = this.stepCounter + 1;
        }
      }
    },
    onKeyup() {
      if (this.stepCounter > 0) {
        this.stepCounter = this.stepCounter - 1;
      }
    },
    onEnter(e) {
      const idx = this.results.length > 0 ? this.stepCounter : this.stepCounter - 1;
      if (this.empty) {
        this.content =
          this.translationPrefix === ''
            ? this.getItemValue(this.items[this.stepCounter])
            : this.getTranslatedItemValue(this.items[this.stepCounter]);
        this.key = this.getItemKey(this.items[idx]);
      } else {
        this.content =
          this.translationPrefix === ''
            ? this.getItemValue(this.results[this.stepCounter])
            : this.getTranslatedItemValue(this.results[this.stepCounter]);
        this.key = this.getItemKey(this.results[idx]);
      }
      this.showList = false;
      this.stepCounter = -1;
      this.emitInput(this.key);
      e.target.blur();
    },
    scrollList(e) {
      if (this.$refs.list && this.$refs.list.offsetHeight === 177) {
        for (let i = 0; i < this.items.length; i++) {
          if (e.keyCode === 40 && this.stepCounter > 2) {
            this.$refs.list.scrollTop = 29 * this.stepCounter;
          } else if (e.keyCode === 38 && this.stepCounter > 0) {
            this.$refs.list.scrollTop = 29 * this.stepCounter - 29;
          }
        }
      }
    },
    setQueryParam(value) {
      const query = { ...this.$route.query };
      if (value == null) {
        delete query[this.name];
      } else {
        query[this.name] = value;
      }
      if (this.$route.query[this.name] === value) {
        return;
      }
      this.$router.replace({ path: this.$route.path, query });
    },
  },
};
</script>

<style lang="scss">
.payment__error-types {
  .custom-select__list {
    right: 0;
  }
}

.custom-select {
  &__input {
    cursor: pointer;
    &:focus {
      border-radius: 2px 2px 0px 0px;
    }
  }

  &__arrow {
    position: absolute;
    top: calc(50% - 12.5px);
    right: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    z-index: 99;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 10px;
  }

  &__list {
    width: auto;
    min-width: 100%;
    margin: 0;
    padding: 16px 0 24px;
    background: white;
    border: 1px solid #2a689c;
    border-radius: 0px 0px 2px 2px;
    position: absolute;
    z-index: 999;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;

    .label-inline & {
      position: absolute;
    }

    &-item {
      padding: 0 12px;
      height: 29px;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      white-space: nowrap;
      user-select: none;
      &.active-item,
      &:hover {
        background-color: var(--list-hover-blue);
      }
    }
  }
}
</style>
