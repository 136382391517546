<template>
  <div class="cmp" :data-vv-scope="scope">
    <div class="row">
      <div class="col s12">
        <h2>Tax Information</h2>
      </div>
    </div>

    <AssociateVatWarning
      :vat-no="vatNumber"
      :org-no="orgNo"
      @useExpectedVatNo="updateTaxVatNumber($event)"
    />
    <TaxInformationInputs
      :vat-number="vatNumber"
      :domicile="domicile"
      :tin="tin"
      :scope="scope"
      @update:vatNumber="updateTaxVatNumber"
      @update:domicile="updateTaxDomicile"
      @update:tin="updateTaxTin"
    ></TaxInformationInputs>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { commit } from '@/store/modules/performer/utils';

import { createTaxInformation } from '@/domain/common';

import TaxInformationInputs from '@/components/ui/tax-info/tax-information-inputs';
import AssociateVatWarning from '../AssociateVatWarning.vue';

export default {
  name: 'CreateTaxInfo',
  components: {
    TaxInformationInputs,
    AssociateVatWarning,
  },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('associate', ['type']),

    ...mapState('associate', {
      domicile: (state) => state.tax_info.tax_domicile,
      tin: (state) => state.tax_info.tin,
      vatNumber: (state) => state.tax_info.vat_number,
      orgNo: (state) => state.organisation_info.org_no,
    }),

    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },

  created() {
    commit('addTaxInformation', createTaxInformation(null, null));
  },

  methods: {
    ...mapMutations('associate', ['updateTaxDomicile', 'updateTaxTin', 'updateTaxVatNumber']),
  },
};
</script>
