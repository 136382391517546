import traverseApply from '../common/traverseApplicator';

// eslint-disable-next-line
export function preparePhoneNumbers(data) {
  return traverseApply(
    data,
    (key) => key === 'phone_numbers',
    (key, field) =>
      field.forEach((f) => {
        // Set phone type to null if there is no number
        f.type = f.number ? f.type : null;
        // Wash away non-digits from number
        f.number = f.number ? f.number.replace(/[^+\d]+/g, '') : null;
      }),
    false,
  );
}
