import gql from 'graphql-tag';

const getEndOfYearStatements = gql`
  query getEndOfYearStatements($ledgerId: String!) {
    getEndOfYearStatements(ledgerId: $ledgerId) {
      items {
        amount
        vat
        field
        type
        year
      }
    }
  }
`;

const createEndOfYearStatement = gql`
  mutation createEndOfYearStatement($year: String!) {
    createEndOfYearStatement(year: $year)
  }
`;

export default {
  getEndOfYearStatements,
  createEndOfYearStatement,
};
