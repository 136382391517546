<template>
  <div>
    <div class="row">
      <div class="col s6">
        <h2>Status</h2>
      </div>
      <template v-if="editMode">
        <div class="col s6 text--right">
          <action-buttons @save="save" @cancel="$emit('cancel')" />
        </div>
      </template>
      <template v-else>
        <div class="col s6 text--right edit-link">
          <a :class="{ disabled: routeVersion() !== null || editDisabled }" @click="$emit('edit')">
            <template v-if="selectedItem">
              <i class="fas fa-pencil-alt" />
              Edit
            </template>
            <template v-else>
              <i class="far fa-plus-square" />
              Add
            </template>
          </a>
        </div>
      </template>
    </div>
    <div class="row">
      <div class="row" :class="{ 'flush--bottom': !editMode }">
        <div class="col s12">
          <status-banner v-if="selectedItem" :status="selectedItem" />
          <status-banner v-if="vrdb2Status" :status="vrdb2Status" />
        </div>
      </div>
      <template v-if="editMode">
        <div v-if="selectedItem.value !== 'Red'" class="row flush--bottom">
          <div class="col s12">
            <input id="completeLineup" v-model="completeLineup" type="checkbox" class="filled-in" />
            <label for="completeLineup">Complete lineup</label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ActionButtons from '../ui/button/action-buttons';
import StatusBanner from '../ui/status-banner';
import AggregateMixin from '../../common/aggregateMixin';

export default {
  name: 'StatusInformation',
  components: {
    ActionButtons,
    StatusBanner,
  },
  mixins: [AggregateMixin],
  props: {
    status: {
      type: String,
      default: undefined,
    },
    vrdb2SyncState: {
      type: String,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      recordingStatusLevels: [
        { label: 'Distribution state OK', value: 'Green', class: 'success' },
        { label: 'Incomplete lineup', value: 'Yellow', class: 'warning' },
        { label: 'Missing information', value: 'Red', class: 'error' },
      ],
      completeLineup: this.status === 'Green',
    };
  },
  computed: {
    selectedItem() {
      if (this.completeLineup) {
        return this.recordingStatusLevels.find((el) => el.value === 'Green');
      }
      return this.recordingStatusLevels.find((el) => el.value === this.status);
    },
    vrdb2Status() {
      switch (this.vrdb2SyncState) {
        case 'S':
          return { class: 'success', label: 'VRDB' };
        case 'F':
          return { class: 'error', label: 'VRDB' };
        case 'I':
        default:
          return null;
      }
    },
  },
  methods: {
    save() {
      this.$emit('statuschange', this.completeLineup ? 'Green' : 'Yellow');
    },
  },
};
</script>
