<template>
  <th :colspan="colspan" :width="width" @click="$emit('click', $event)">
    <slot />
    <span class="dropdown-button__arrow">
      <i v-if="active" :class="`fas fa-caret-${sortAsc ? 'up' : 'down'}`" />
      <i v-else class="fas fa-caret-up text--grey" />
    </span>
  </th>
</template>

<script>
export default {
  name: 'SortableHeadCol',
  props: {
    active: Boolean,
    sortAsc: Boolean,
    colspan: {
      type: Number,
      default: 1,
    },
    width: {
      type: String,
      default: 'auto',
    },
  },
};
</script>
