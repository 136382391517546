<template>
  <div>
    <div class="row">
      <div class="col s3">
        <searchable-input
          ref="name"
          name="name"
          label="Add a performer"
          placeholder="Name, SSN or IPN"
          use-template="performerNameSearch"
          :searcher="searchPerformer"
          :value="performerName"
          :scope="scope"
          :rule="required ? 'required' : ''"
          @input="setPerformer"
          @clear="setPerformer(null)"
        />
      </div>
      <div class="col s3">
        <select-input
          name="pseudo-name"
          :scope="scope"
          :disabled="!(performer && performer.id)"
          :value="pseudo"
          :items="pseudoNames"
          label="Pseudo Name"
          @input="setPseudo"
        />
      </div>
      <div class="col s2">
        <select-instrument
          :scope="scope"
          :disabled="!(performer && performer.id)"
          :value="instrument"
          @input="setInstrument"
        />
      </div>
      <div class="col s3">
        <create-active-period
          :active-periods="activePeriod"
          name="active-period"
          label="Years active"
          :scope="scope"
          :disabled="!(performer && performer.id)"
          @fromDateChanged="onActivePeriodFromDateChanged"
          @toDateChanged="onActivePeriodToDateChanged"
          @add="onAddActivePeriod"
          @remove="onRemoveActivePeriod"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PerformerService from '../../../services/performerService';
import SearchHelper from '../../search/searchHelper';
import SelectInput from '../../ui/select/select-input';
import SearchableInput from '../../ui/input/searchable-input';
import SelectInstrument from '../../ui/select/select-instrument';
import { createActivePeriod } from '../../../domain/mainArtistDomain';
import CreateActivePeriod from '../create-active-period';

export default {
  name: 'AddPerformerRow',
  components: {
    SelectInstrument,
    SearchableInput,
    SelectInput,
    CreateActivePeriod,
  },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      default: 'add-performer-row',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      performer: {},
      instrument: null,
      pseudo: null,
      activePeriod: [{}],
    };
  },
  computed: {
    pseudoNames() {
      return (
        this.generalInfo.pseudo_names &&
        this.generalInfo.pseudo_names.map((name) => ({
          code: name,
          name,
        }))
      );
    },
    generalInfo() {
      return (this.performer && this.performer.general_info) || {};
    },
    performerName() {
      return (
        this.generalInfo.first_name &&
        `${this.generalInfo.first_name || ''} ${this.generalInfo.last_name || ''}`
      );
    },
  },
  methods: {
    async setPerformer(performer) {
      if (performer && performer.id) {
        this.performer = await PerformerService.getPerformer(performer.id);
      } else {
        this.clear();
      }
      this.emitState();
    },
    async searchPerformer(query) {
      const res = await SearchHelper.advancedSearchPerformers({ query });
      return res.results.map((perf) => ({
        ...perf,
        name: `${perf.first_name} ${perf.last_name}`,
        main_artists: perf.main_artists.map((ma) => ma.name),
      }));
    },
    clear() {
      this.performer = {};
      this.instrument = null;
      this.pseudo = null;
      this.activePeriod = [];
      this.emitState();
    },
    emitState() {
      this.$emit('update', {
        id: this.performer.id,
        instrument: this.instrument,
        active_period: this.activePeriod,
        pseudo_name: this.pseudo,
      });
    },
    setPseudo(pseudo) {
      this.pseudo = pseudo;
      this.emitState();
    },
    setInstrument(instrument) {
      this.instrument = instrument;
      this.emitState();
    },
    setRole(role) {
      this.role = role;
      this.emitState();
    },
    onActivePeriodFromDateChanged(obj) {
      const toDate = this.activePeriod[obj.index].to;
      this.activePeriod.splice(obj.index, 1, { to: toDate, from: obj.entry });
      this.emitState();
    },
    onActivePeriodToDateChanged(obj) {
      const fromDate = this.activePeriod[obj.index].from;
      this.activePeriod.splice(obj.index, 1, { to: obj.entry, from: fromDate });
      this.emitState();
    },
    onAddActivePeriod() {
      this.activePeriod.push(createActivePeriod());
      this.emitState();
    },
    onRemoveActivePeriod(index) {
      this.activePeriod.splice(index, 1);
      if (this.activePeriod.length === 0) {
        this.activePeriod.push(createActivePeriod());
      }
      this.emitState();
    },
  },
};
</script>
