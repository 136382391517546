<template>
  <div :class="{ worklist: true, worklist__sidebar: true, 'worklist__sidebar--visible': visible }">
    <div class="worklist__sidebar__contents">
      <div class="text--right close">
        <i class="fas fa-arrow-right close" @click="onClose"></i>
      </div>
      <add-to-personal-worklist @close="onClose" />
      <create-public-worklist v-if="publicWorklistActive" @close="onClose" />
    </div>
  </div>
</template>

<script>
import CreatePublicWorklist from './create-public-worklist';
import AddToPersonalWorklist from './add-to-personal-worklist';
import WorklistHelper from './worklistHelper';

export default {
  name: 'WorklistSidebar',
  $_veeValidate: {
    validator: 'new',
  },
  components: {
    CreatePublicWorklist,
    AddToPersonalWorklist,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      publicWorklistActive: false,
    };
  },
  watch: {
    $route() {
      this.pageEligibleForPublicWorklist();
    },
  },
  created() {
    this.pageEligibleForPublicWorklist();
  },
  methods: {
    pageEligibleForPublicWorklist() {
      this.publicWorklistActive = WorklistHelper.pageEligibleForPublicWorklist(
        this.$router.currentRoute,
      );
    },
    onClose() {
      this.$emit('onClose');
      this.validationErrors.clear('add-to-personal-worklist');
      this.validationErrors.clear('create-public-worklist');
    },
  },
};
</script>
