<template>
  <tbody>
    <template v-for="(recordingLine, recordingLineIndex) in reportLines">
      <tr
        :key="`${recordingLine.incoming.id}-tr1`"
        ref="line"
        class="cursor-pointer"
        @click="handleExpansion(recordingLine)"
      >
        <td class="compare__arrow">
          <i
            :class="`fas fa-caret-${
              isExpanded(recordingLine.incoming.internal_id) ? 'up' : 'down'
            }`"
          />
        </td>
        <td v-if="bulkEnabled" class="compare__checkbox">
          <input
            :id="recordingLineIndex"
            ref="mark"
            v-model="recordingLine.checked"
            class="filled-in"
            type="checkbox"
            name="mark"
            :disabled="recordingLine.completed"
            @click.stop
          />
          <label :for="recordingLineIndex" @click.stop />
        </td>
        <td
          class="fs-14"
          :class="{
            'compare__body-item-row--diff': hasDiff(
              recordingLine.incoming.title,
              getMatchedBasicInfoProp(recordingLine, 'name'),
            ),
            'text--green': recordingLine.completed,
            'text--red':
              recordingLine.incoming.rejected_lineups ||
              recordingLine.incoming.rejected_recordings ||
              (recordingLine.completed && recordingLine.incoming.match_state === 'R'),
          }"
        >
          {{ recordingLine.incoming.title }}
          <i
            v-if="recordingLine.completed && recordingLine.incoming.match_state !== 'R'"
            class="fas fa-check-circle"
          />
          <i
            v-else-if="
              recordingLine.incoming.rejected_lineups ||
              recordingLine.incoming.rejected_recordings ||
              (recordingLine.completed && recordingLine.incoming.match_state === 'R')
            "
            class="fas fa-exclamation-circle"
          />
        </td>
        <template v-if="!isExpanded(recordingLine.incoming.internal_id)">
          <td
            :class="{
              'compare__body-item-row--diff': hasDiff(
                recordingLine.incoming.title_version,
                getMatchedBasicInfoProp(recordingLine, ''),
              ),
            }"
          >
            {{ recordingLine.incoming.title_version }}
          </td>
          <td
            :class="{
              'compare__body-item-row--diff': hasDifferentYear(
                recordingLine.incoming.main_artist,
                getMatchedBasicInfoProp(recordingLine, 'main_artist.name'),
              ),
            }"
          >
            {{ recordingLine.incoming.main_artist }}
          </td>
          <td
            :class="{
              'compare__body-item-row--diff': hasDiff(
                recordingLine.incoming.recording_year,
                getMatchedBasicInfoProp(recordingLine, 'recording_year'),
              ),
            }"
          >
            {{ recordingLine.incoming.recording_year }}
          </td>
          <td
            :class="{
              'compare__body-item-row--diff': hasDiff(
                recordingLine.incoming.recording_country,
                getMatchedBasicInfoProp(recordingLine, 'recorded_in_country'),
              ),
            }"
          >
            {{ $filters.formatCountry(recordingLine.incoming.recording_country) }}
          </td>
          <td>
            {{ recordingLine.incoming.external_id }}
          </td>
          <td
            :class="{
              'compare__body-item-row--diff': hasDiff(
                recordingLine.incoming.isrc,
                getMatchedBasicInfoProp(recordingLine, 'isrc'),
              ),
            }"
          >
            {{ recordingLine.incoming.isrc }}
          </td>
          <td>
            {{ $filters.formatDate(recordingLine.incoming.date_created) }}
          </td>
        </template>
        <td v-else colspan="1000" />
      </tr>
      <template v-if="isExpanded(recordingLine.incoming.internal_id)">
        <tr :key="`${recordingLine.incoming.id}-tr2`" class="prevent-hover-bg no-borders">
          <td class="hard--ends hard--sides" colspan="1000">
            <button
              ref="top-of-expanded-row"
              style="position: absolute; top: 12px; z-index: 1; padding-left: 16px"
              @click="scrollToBottomOfExpandedRow()"
            >
              <i class="fas fa-long-arrow-alt-down fa-lg"></i>
            </button>
            <div v-if="isLoading" class="compare__loading">
              <spinner />
            </div>
            <div v-else class="compare__nested compare__nested--full">
              <div class="compare__nested-main compare__main-divide">
                <div
                  class="compare__nested-heading compare__nested-heading--incoming"
                  style="margin-left: -24px"
                >
                  <button @click="recordingExpanded = !recordingExpanded">
                    <i :class="`fas fa-caret-${recordingExpanded ? 'up' : 'down'}`" />
                  </button>
                  <strong>Played recording:</strong>
                  {{ recordingLine.incoming.title }}
                </div>
                <span class="compare__nested-heading compare__nested-heading--candidate">
                  <strong>Star suggestion:</strong>
                  <a :href="`#/recordings/${getCandidateValue('id')}`">
                    {{ getCandidateValue('basic_info.name') }}
                    {{ recordingLine.incoming.lineup_locked ? ' 🔒' : '' }}
                  </a>
                </span>
                <ul class="compare__nested-list">
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.alternate_titles',
                      value: candidateAllTitles,
                      displayFilter: 'prettyPrintArray',
                    }"
                    :incoming="{
                      key: 'title',
                      value: recordingLine.incoming.title,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.version_title',
                      value: getCandidateValue('basic_info.version_title'),
                    }"
                    :incoming="{
                      key: 'version_title',
                      value: recordingLine.incoming.title_version,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :candidate="{
                      key: 'basic_info.main_artist.name',
                      value: getCandidateValue('basic_info.main_artist.name'),
                      link: getCandidateValue('basic_info.main_artist.id')
                        ? `#/mainartists/${getCandidateValue('basic_info.main_artist.id')}`
                        : null,
                    }"
                    :incoming="{
                      key: 'main_artist',
                      value: recordingLine.incoming.main_artist,
                    }"
                    :original-value="getCandidateValue('basic_info.main_artist')"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="processType === 'VRDB2'"
                    :candidate="{
                      key: getCandidateVrdbInfoValue().key,
                      value: getCandidateVrdbInfoValue().id,
                    }"
                    :incoming="{
                      key: 'vrdb2_id',
                      value: recordingLine.incoming.external_id,
                    }"
                    :allow-action="false"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.duration_sec',
                      value: getCandidateValue('basic_info.duration_sec'),
                      displayFilter: 'toMinutes',
                    }"
                    :incoming="{
                      key: 'duration_sec',
                      value: recordingLine.incoming.duration,
                      displayFilter: 'toMinutes',
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'albums',
                      value: getCandidateValue('albums'),
                      displayFilter: 'prettyPrintAlbumArray',
                    }"
                    :incoming="{
                      key: 'album',
                      value: recordingLine.incoming.album,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.recording_date',
                      value: getCandidateValue('basic_info.recording_date'),
                      displayFilter: 'formatRecordingDate',
                    }"
                    :incoming="{
                      key: 'recording_year',
                      value: recordingLine.incoming.recording_year,
                    }"
                    :comparator="'yearsMatch'"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.recorded_in_country',
                      value: getCandidateValue('basic_info.recorded_in_country'),
                      displayFilter: 'formatCountry',
                    }"
                    :incoming="{
                      key: 'recording_country',
                      value: recordingLine.incoming.recording_country,
                      displayFilter: 'formatCountry',
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.produced_in_country',
                      value: getCandidateValue('basic_info.produced_in_country'),
                      displayFilter: 'formatCountry',
                    }"
                    :incoming="{
                      key: 'produced_in_country',
                      value: recordingLine.incoming.producer_country,
                      displayFilter: 'formatCountry',
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.isrc',
                      value: getCandidateValue('basic_info.isrc'),
                    }"
                    :incoming="{
                      key: 'isrc',
                      value: recordingLine.incoming.isrc,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.label',
                      value: getCandidateValue('basic_info.label'),
                    }"
                    :incoming="{
                      key: 'label',
                      value: recordingLine.incoming.label,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.genre',
                      value: getCandidateValue('basic_info.genre'),
                      displayFilter: 'formatGenre',
                    }"
                    :incoming="{
                      key: 'genre',
                      value: recordingLine.incoming.genre,
                      displayFilter: 'formatGenre',
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.composer',
                      value: getCandidateValue('basic_info.composer'),
                      displayFilter: 'prettyPrintNames',
                    }"
                    :incoming="{
                      key: 'composer',
                      value: recordingLine.incoming.composer,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.writer',
                      value: getCandidateValue('basic_info.writer'),
                    }"
                    :incoming="{
                      key: 'writer',
                      value: recordingLine.incoming.writer,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.arranger',
                      value: getCandidateValue('basic_info.arranger'),
                    }"
                    :incoming="{
                      key: 'arranger',
                      value: recordingLine.incoming.arranger,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <CompareGridRow
                    :visible="ruleForShowingRow"
                    :candidate="{
                      key: 'basic_info.local_codes_label',
                      value: getCandidateValue('basic_info.local_codes'),
                      displayFilter: 'prettyPrintCodeValueObject',
                    }"
                    :incoming="{
                      key: 'local_codes',
                      value: recordingLine.incoming.local_codes,
                    }"
                    :allow-action="!recordingLine.completed"
                    @valueUpdated="candidateValueUpdated"
                  />
                  <li
                    v-if="rejectionData && rejectionData.rejections.length"
                    class="compare__list-item"
                  >
                    <div class="compare__candidate">
                      <strong>{{ rejectionData.label }}</strong>
                      <div class="compare__candidate-value">
                        <template v-for="(rejected, index) in rejectionData.rejections">
                          <div :key="index">
                            {{ rejected }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="!isLoading" class="compare__nested compare__nested--full push--bottom">
              <div class="compare__nested-main">
                <span class="compare__nested-heading compare__nested-heading--incoming">
                  Incoming lineup
                  <template v-if="recordingLine.incoming.lineup_complete">
                    <span class="float-right push-half--left">Marked as complete in VRDB</span>
                    <status-icon class="float-right" status="Green" />
                  </template>
                  <template v-else-if="recordingLine.incoming.lineup_complete === false">
                    <span class="float-right push-half--left">Marked as incomplete in VRDB</span>
                    <status-icon class="float-right" status="Yellow" />
                  </template>
                </span>
                <span class="compare__nested-heading compare__nested-heading--candidate">
                  SAMI's lineup
                  <span v-if="recordingLine.incoming.lineup_locked" class="float-right">🔒</span>
                  <status-icon
                    v-if="recordingLine.incoming.lineup_complete !== null"
                    class="float-right"
                    :status="currentCandidate.distribution_state.status"
                  />
                </span>
                <div class="compare__nested-list mt-20">
                  <template v-for="performerLine in recordingLine.incoming.lineup">
                    <ComparePerformerPlaylist
                      v-if="lineupStateMask === performerLine.match_state"
                      :key="performerLine.id"
                      :performer-line="performerLine"
                      :allow-action="
                        allowAction && !performerLine.preventAction && !recordingLine.completed
                      "
                      :process-type="processType"
                      @toggleUpdatedAttribute="toggleUpdatedAttribute($event)"
                      @reject="rejectSingle"
                      @undoReject="undoRejectSingle"
                      @undoMerge="undoMergeSingle"
                      @previewMerge="previewMerge($event, recordingLineIndex)"
                      @cancelMerge="cancelMerge($event, recordingLineIndex)"
                      @merge="mergeLineupPerformer"
                      @remove="removeFromLineup(performerLine, $event)"
                    />
                  </template>

                  <template v-if="hiddenLineup(recordingLine).length > 0">
                    <div class="compare__list-item">
                      <div class="compare__incoming compare__more">
                        <a @click="showFullLineup = !showFullLineup">
                          <i class="fas" :class="`fa-chevron-${showFullLineup ? 'up' : 'down'}`" />
                          {{ hiddenLineup(recordingLine).length }} more performers on this lineup
                        </a>
                      </div>
                      <div class="compare__candidate compare__more">
                        <a @click="showFullLineup = !showFullLineup">
                          <i class="fas" :class="`fa-chevron-${showFullLineup ? 'up' : 'down'}`" />
                          {{ hiddenCandidateLineupCount }} more performers on this lineup
                        </a>
                      </div>
                    </div>
                  </template>

                  <template v-if="showFullLineup">
                    <div class="compare__nested-list">
                      <template v-for="performerLine in hiddenLineup(recordingLine)">
                        <div :key="performerLine.id" class="row box">
                          <ComparePerformerPlaylist
                            :performer-line="performerLine"
                            :allow-action="
                              allowAction &&
                              !performerLine.preventAction &&
                              !recordingLine.completed
                            "
                            :process-type="processType"
                            @toggleUpdatedAttribute="toggleUpdatedAttribute($event)"
                            @reject="rejectSingle"
                            @undoReject="undoRejectSingle"
                            @undoMerge="undoMergeSingle"
                            @previewMerge="previewMerge($event, recordingLineIndex)"
                            @cancelMerge="cancelMerge($event, recordingLineIndex)"
                            @merge="mergeLineupPerformer"
                            @remove="removeFromLineup(performerLine, $event)"
                          />
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>

              <CompareButtonsPlaylist
                v-if="
                  recordingLine.incoming.lineup && allowActionbuttons && !recordingLine.completed
                "
                :accept-all-disabled="!!recordingLine.incoming.lineup_locked"
                @accept="acceptAll(recordingLine)"
                @reject="rejectAll(recordingLine, $event)"
              />
              <button
                ref="bottom-of-expanded-row"
                style="position: absolute; bottom: 24px; padding-left: 16px"
                @click="scrollToTopOfExpandedRow()"
              >
                <i class="fas fa-long-arrow-alt-up fa-lg"></i>
              </button>
            </div>
          </td>
        </tr>
      </template>
    </template>
  </tbody>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapMutations } from 'vuex';
import CompareGridRow from './compare-grid-row';
import ComparePerformerPlaylist from './compare-performer-playlist';
import CompareButtonsPlaylist from './compare-buttons-playlist';
import MatchingService from '../../services/matchingService';
import RecordingService from '../../services/recordingService';
import { getConfigValue, PROPS } from '../../services/configService';
import Spinner from '../spinner';
import * as TypeUtils from '../../common/typeUtils';
import { playlistLineupStates } from '../../domain/matchingDomain';
import StatusIcon from '../ui/status-icon';

export default {
  name: 'CompareRecordingPlaylist',
  components: {
    CompareButtonsPlaylist,
    CompareGridRow,
    ComparePerformerPlaylist,
    Spinner,
    StatusIcon,
  },
  props: {
    isExpanded: {
      type: Function,
    },
    toggleExpanded: {
      type: Function,
    },
    getMatchState: {
      type: Function,
    },
    lineupStateMask: {
      type: String,
    },
    bulkEnabled: {
      type: Boolean,
      default: true,
    },
    actionEnabled: {
      type: Boolean,
      default: true,
    },
    processType: {
      type: String,
    },
  },
  data() {
    return {
      showFullLineup: false,
      loading: false,
      recordingExpanded: false,
      allowAction: this.actionEnabled,
    };
  },
  computed: {
    ...mapGetters('matching', ['currentIncoming', 'currentCandidate', 'reportLines']),
    isLoading() {
      return this.loading || !(this.currentIncoming && this.currentCandidate);
    },
    allowActionbuttons() {
      return this.allowAction && this.lineupStateMask === playlistLineupStates.CONFLICT;
    },
    candidateAllTitles() {
      return [
        this.getCandidateValue('basic_info.name'),
        ...this.getCandidateValue('basic_info.alternate_titles'),
      ];
    },
    ruleForShowingRow() {
      if (!this.recordingExpanded) {
        return { byStatus: ['error', 'warning'] };
      }
      return true;
    },
    hiddenCandidateLineupCount() {
      return this.currentIncoming.lineup.filter(
        (line) =>
          line.artist !== null &&
          line.match_state !== this.lineupStateMask &&
          line.match_state !== playlistLineupStates.MERGED,
      ).length;
    },
    getEnvironment() {
      return getConfigValue(PROPS.ENVIRONMENT);
    },
    rejectionData() {
      if (this.currentIncoming.rejected_recordings) {
        return {
          rejections: this.currentIncoming.rejected_recordings,
          label: 'Rejected Recordings',
        };
      } else if (this.currentIncoming.rejected_lineups) {
        return { rejections: this.currentIncoming.rejected_lineups, label: 'Rejected Lineups' };
      } else if (this.currentCandidate.notes) {
        return { rejections: this.currentCandidate.notes, label: 'Rejected in PAID' };
      }
      return false;
    },
  },
  methods: {
    ...mapMutations('matching', [
      'updateCurrentCandidate',
      'updateCurrentIncoming',
      'updateCurrentIncomingLineup',
      'updateCurrentIncomingMatchState',
      'replaceInCurrentCandidateLineup',
      'replaceInReportLines',
      'updateLineUpdatedAttributes',
    ]),
    scrollToBottomOfExpandedRow() {
      let element = this.$refs['bottom-of-expanded-row'];
      element[0].scrollIntoView({ behavior: 'instant', block: 'center' });
    },
    scrollToTopOfExpandedRow() {
      let element = this.$refs['top-of-expanded-row'];
      element[0].scrollIntoView({ behavior: 'instant', block: 'center' });
    },
    hiddenLineup(row) {
      if (!row.incoming.lineup) {
        return [];
      }
      return row.incoming.lineup.filter(
        (line) =>
          line.match_state !== this.lineupStateMask &&
          line.match_state !== playlistLineupStates.MERGED,
      );
    },
    handleExpansion(line) {
      this.toggleExpanded(line.incoming.internal_id);
      this.showFullLineup = false;
    },
    getCandidateValue(path) {
      return _.get(this.currentCandidate, path.split('.')) || '';
    },
    getMatchedBasicInfoProp(line, path) {
      return _.get(line, `matched.basic_info.${path}`, false);
    },
    hasDiff(matchedValue, starValue) {
      if (matchedValue && starValue) {
        return !this.valuesMatchRelaxed(matchedValue, starValue);
      }
      return false;
    },
    hasDifferentYear(matchedYear, starDate) {
      if (matchedYear && starDate) {
        return !this.yearsMatch(matchedYear, starDate);
      }
      return false;
    },
    findNewPerformers(line) {
      return line.incoming.lineup
        .filter((o) => !o.artist)
        .map((o) => {
          const obj = {
            id: o.id,
          };
          if (o.incoming_artist.role_code_old !== undefined) {
            obj.role_code = o.incoming_artist.role_code;
          }
          return obj;
        });
    },
    previewMerge(e, recordingLineIndex) {
      const tmpLine = _.cloneDeep(this.reportLines[recordingLineIndex]);
      const mergeToIndex = this.currentIncoming.lineup.findIndex((l) => l.id === e.incoming_id);
      tmpLine.incoming.lineup[mergeToIndex].artist = e.candidate;
      tmpLine.incoming.lineup[mergeToIndex].preventAction = true;
      this.replaceInReportLines({
        index: recordingLineIndex,
        value: tmpLine,
      });
    },
    cancelMerge(e, recordingLineIndex) {
      const tmpLine = _.cloneDeep(this.reportLines[recordingLineIndex]);
      const mergeToIndex = this.currentIncoming.lineup.findIndex((l) => l.id === e.incoming_id);
      tmpLine.incoming.lineup[mergeToIndex].artist = null;
      this.replaceInReportLines({
        index: recordingLineIndex,
        value: tmpLine,
      });
    },
    async undoMergeSingle(command) {
      try {
        await MatchingService.undoMergePlaylistLineupLine(command);
        this.refreshRecording(command.recording_id);
      } catch (e) {
        this.error(e);
      }
    },
    async mergeLineupPerformer(mergeReq) {
      try {
        await MatchingService.mergePlaylistLineupLine(mergeReq);
        const recording = await this.refreshRecording(mergeReq.recording_id);
        const line = recording.incoming.lineup.find((l) => l.id === mergeReq.incoming_id);
        line.merged = true;
      } catch (e) {
        this.error(e);
      }
    },
    async refreshRecording(recordingId) {
      const index = this.reportLines.findIndex(
        (r) => parseInt(r.incoming.id, 10) === parseInt(recordingId, 10),
      );
      const res = await MatchingService.getPlaylistRecording({ recording_id: recordingId });
      const recordingAgg = _.cloneDeep(res);
      const recordingRejects = {
        rejected_recordings: this.currentIncoming.rejected_recordings,
        rejected_lineups: this.currentIncoming.rejected_lineups,
      };
      const recordingLineup = { lineup: recordingAgg.lineup };
      const incoming = Object.assign({}, recordingAgg.recording, recordingRejects, recordingLineup);
      const value = Object.assign({}, this.reportLines[index], { incoming });
      this.replaceInReportLines({ index, value });
      this.$emit('refreshRecording');
      return value;
    },
    async candidateValueUpdated(obj) {
      let mutationPath = TypeUtils.camelCase(obj.data.key.replace(/\./g, '_'), true);
      if (obj.data.key === 'basic_info.main_artist.name') {
        mutationPath = mutationPath.replace(/Name$/g, '');
        let value = obj.data.value;
        if (!value.name) {
          value = { name: obj.data.value };
        }
        this.$store.commit(`matching/${obj.action}CurrentCandidate${mutationPath}`, value);
      } else {
        this.$store.commit(`matching/${obj.action}CurrentCandidate${mutationPath}`, obj.data.value);
      }
      this.loading = true;
      try {
        await RecordingService.updateRecordingBasicInfo(_.cloneDeep(this.currentCandidate), true);
      } catch (e) {
        this.error(e);
      }
      this.loading = false;
      this.$nextTick(() => {
        this.$emit('refreshRecording', this.currentCandidate.id);
      });
    },
    async toggleUpdatedAttribute(command) {
      try {
        const updatedAttributes = await MatchingService.toggleLineupUpdatedAttributes(command);
        this.$store.commit('matching/updateLineUpdatedAttributes', {
          ...command,
          updatedAttributes,
        });
      } catch (e) {
        this.error(e);
      }
    },
    async acceptAll(recordingLine) {
      try {
        const newPerformers = this.findNewPerformers(recordingLine);
        const acceptResult = await MatchingService.acceptPlaylistLineup({
          recording_id: recordingLine.incoming.id,
          new_performers: newPerformers,
        });
        const lup = _.cloneDeep(this.currentIncoming.lineup);
        lup.forEach((o) => {
          o.match_state = playlistLineupStates.ACCEPTED;
        });
        this.updateCurrentIncomingLineup(lup);
        this.$emit('acceptAll', {
          groupId: this.currentIncoming.internal_id,
          rematchedRecordingIds: acceptResult.recording_ids,
        });
      } catch (e) {
        this.error(e);
      }
    },
    async rejectAll(line, obj) {
      try {
        await MatchingService.rejectPlaylistLineup(
          Object.assign({}, obj, {
            recording_id: line.incoming.id,
            society_code: line.incoming.society_code,
          }),
        );
        const lup = _.cloneDeep(this.currentIncoming.lineup);
        lup.forEach((o) => {
          o.match_state = playlistLineupStates.REJECTED;
        });
        line.incoming.lineup = lup;
        line.incoming.rejected_reason = obj.reason;
        line.incoming.rejected_note = obj.note;
        line.incoming.lineup.forEach((l) => {
          l.rejected_reason = obj.reason;
          l.rejected_note = obj.note;
        });
        line.incoming.match_state = playlistLineupStates.REJECTED;
        this.$emit('rejectAll', { groupId: this.currentIncoming.internal_id });
      } catch (e) {
        this.error(e);
      }
    },
    async rejectSingle(command) {
      try {
        await MatchingService.rejectPlaylistLineupLine(command);
        const lup = _.cloneDeep(this.currentIncoming.lineup);
        const obj = lup.find((o) => o.id === command.id);
        obj.rejected_reason = command.reason;
        obj.rejected_note = command.note;
        obj.match_state = 'R';
        this.updateCurrentIncomingLineup(lup);
      } catch (e) {
        this.error(e);
      }
    },
    async undoRejectSingle(command) {
      try {
        const result = await MatchingService.undoRejectPlaylistLineupLine(command);
        const updatedLine = result.undoRejectPlaylistLineupLine;
        const lup = _.cloneDeep(this.currentIncoming.lineup);
        const obj = lup.find((o) => o.id === command.id);
        obj.match_state = updatedLine.match_state;
        this.updateCurrentIncomingLineup(lup);
      } catch (e) {
        this.error(e);
      }
    },
    async removeFromLineup(performerLine, rejection) {
      try {
        await MatchingService.deletePlaylistLineupLine({
          recording_id: performerLine.recording_id,
          id: performerLine.id,
          rejection,
        });
        await this.refreshRecording(performerLine.recording_id);
      } catch (e) {
        this.error(e);
      }
    },
    getCandidateVrdbInfoValue() {
      if (
        this.currentCandidate.basic_info.vrdb2_id &&
        this.currentCandidate.basic_info.vrdb2_id.length > 0
      ) {
        return { id: this.currentCandidate.basic_info.vrdb2_id, key: 'vrdb2_id_attested' };
      }
      if (this.currentCandidate.basic_info.local_codes) {
        const associated = this.currentCandidate.basic_info.local_codes.find(
          (obj) => obj.code.toUpperCase() === 'VRDB2_ASSOCIATED',
        );
        if (associated) {
          return { id: associated.value, key: 'vrdb2_id_associated' };
        }
        const playlist = this.currentCandidate.basic_info.local_codes.find(
          (obj) => obj.code.toUpperCase() === 'VRDB2_PLAYLIST_ID',
        );
        if (playlist) {
          return { id: playlist.value, key: 'vrdb2_id_playlist' };
        }
      }
      return { id: undefined, key: 'vrdb2_id' };
    },
    error(e) {
      console.error(e);
      this.$addStarError(e);
    },
  },
};
</script>
<style lang="scss">
/* .
  box {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.08);
    padding: 6px;
  }
  */
.mt-20 {
  margin-top: 20px;
}
</style>
