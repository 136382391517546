<template>
  <div v-if="refund">
    <div class="row">
      <div class="col s12">
        <p>
          Refund
          <router-link :to="`/refunds/${refund.id}`">{{ refund.note }}</router-link>
          ({{ $filters.dateFormat($filters.dateParse(refund.date), 'YYYY-MM-DD') }})
        </p>
        <p>Created by {{ refund.createdBy }}</p>
        <p v-if="refund.confirmedAt">
          Confirmed by {{ payment.confirmedBy }},
          {{ $filters.dateFormat($filters.dateParse(refund.confirmedAt), 'YYYY-MM-DD HH:mm') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { query } from '@/services/apolloRequest';
import gql from 'graphql-tag';

export default {
  props: {
    id: String,
  },
  data() {
    return {
      refund: null,
    };
  },
  async created() {
    const { refunds } = await query({
      query: gql`
        query {
          refunds(query: { refundIds: ["${this.id}"] }) {
            id
            paymentId
            reason
            note
            date
            state
            createdBy
            confirmedBy
            confirmedAt
          }
        }
      `,
    });
    this.refund = refunds[0];
  },
};
</script>
