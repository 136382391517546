<template>
  <div>
    <create-edit-contact-info :contact-info="contactInfo" @input="onContactInfoChanged" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { commit } from '../../../../store/modules/performer/utils';
import CreateEditContactInfo from '../../../ui/contact/create-edit-contact-info';

export default {
  name: 'CreateContactInformation',
  components: { CreateEditContactInfo },
  inject: ['$validator'],
  computed: {
    ...mapGetters('performer', ['contactInfo']),
  },
  methods: {
    ...mapMutations('performer', ['updateContactInfo']),
    onContactInfoChanged(value) {
      commit('updateContactInfo', value);
    },
  },
};
</script>
