var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('ComponentSpinner'):_c('div',{staticClass:"row card"},[_c('div',{staticClass:"col s12"},[_c('table',[_c('sortable-head',{attrs:{"columns":_vm.columns,"bulk-enabled":false,"more-options":false,"default-sort-column-index":0,"default-sort-should-override":true},on:{"sort":_vm.clickSort}}),_c('tbody',[_vm._l((_vm.remunerationRows),function(row,idx){return [_c('tr',{key:idx,class:{ 'dist-closure': _vm.distClosureYear === row.year }},[_c('td',{staticClass:"year"},[(row.year)?_c('router-link',{attrs:{"to":{ name: _vm.distributionAreaRouterName, params: { year: row.year } }}},[_vm._v(" "+_vm._s(row.year)+" ")]):_c('span',[_vm._v("Unspecified")])],1),_c('td',{staticClass:"amount-row"},[_c('span',[_vm._v(_vm._s(_vm.$filters.formatAmountCent(row.orig)))])]),_c('td',{staticClass:"amount-row"},[_c('span',[_vm._v(_vm._s(_vm.$filters.formatAmountCent(row.fee)))])]),_c('td',{staticClass:"amount-row"},[_c('span',[_vm._v(_vm._s(_vm.$filters.formatAmountCent(row.gross)))])]),_c('td',{staticClass:"amount-row"},[_c('span',[_vm._v(_vm._s(_vm.$filters.formatAmountCent(row.tax)))])]),_c('td',{staticClass:"amount-row net-amount"},[_c('span',[_vm._v(_vm._s(_vm.$filters.formatAmountCent(row.net)))])]),_c('td',{staticClass:"amount-row"},[_c('span',{class:{
                    negative: Math.sign(Number(row.balance)) === -1,
                    positive: Math.sign(Number(row.balance)) === +1,
                  }},[_vm._v(" "+_vm._s(_vm.$filters.formatAmountCent(row.balance))+" ")])])])]}),_c('tr',{staticClass:"summary"},[_c('td',{staticClass:"text--bold"},[_vm._v("Total sum:")]),_c('td',{staticClass:"amount-row"},[_vm._v(" "+_vm._s(_vm.$filters.formatAmountCent(_vm.totalOriginalAmount))+" ")]),_c('td',{staticClass:"amount-row"},[_vm._v(_vm._s(_vm.$filters.formatAmountCent(_vm.totalFee)))]),_c('td',{staticClass:"amount-row"},[_vm._v(_vm._s(_vm.$filters.formatAmountCent(_vm.totalGrossAmount)))]),_c('td',{staticClass:"amount-row"},[_vm._v(_vm._s(_vm.$filters.formatAmountCent(_vm.totalTax)))]),_c('td',{staticClass:"amount-row"},[_vm._v(_vm._s(_vm.$filters.formatAmountCent(_vm.totalNetAmount)))]),_c('td',{staticClass:"amount-row",class:{
                negative: Math.sign(Number(_vm.totalBalance)) === -1,
                positive: Math.sign(Number(_vm.totalBalance)) === +1,
              }},[_vm._v(" "+_vm._s(_vm.$filters.formatAmountCent(_vm.totalBalance))+" ")])])],2)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }