import { mutate, query } from '../../../services/apolloRequest';
import gql from 'graphql-tag';
import { paymentState } from '../../../domain/paymentDomain';

const rootState = {
  totalCount: 0,
  payments: [],
  payment: {},
  polling: false,
};

const actions = {
  async createPayment(context, payment) {
    await mutate(
      paymentCommand({
        process_id: payment.processId,
        stream_id: payment.id,
        type: 'payment/create',
        payload: {
          name: payment.name,
          societyId: payment.societyId,
          paymentDate: payment.paymentDate,
          minLimit: payment.minLimit,
          isManual: payment.isManual,
        },
      }),
    );
    context.commit('prependPaymentElement', payment);
  },

  async approvePayment(context, paymentId) {
    await mutate(
      paymentCommand({
        stream_id: paymentId,
        type: 'payment/user-approve',
        payload: {},
      }),
      { pickProp: 'handleCommand' },
    );
  },

  async removePaymentElement(context, id) {
    await mutate(
      paymentCommand({
        stream_id: id,
        type: 'payment/remove',
        payload: {},
      }),
    );
    context.commit('removePaymentElement', id);
  },

  async fetchPayments(context, opts) {
    const { offset = 0, limit = 20 } = opts;
    const res = await query(
      {
        query: gql`
          query Payments($query: PaymentsQuery) {
            payments(query: $query) {
              totalCount
              payments {
                id
                name
                state
                createdAt
                paymentDate
                societyId
                createdBy
                payableAmount
                payableVat
                processId
                occupied
                isManual
              }
            }
          }
        `,
        variables: {
          query: {
            limit,
            offset,
          },
        },
      },
      { pickProp: 'payments' },
    );
    context.commit('setTotalCount', res.totalCount);
    context.commit('setPayments', res.payments);
  },

  async reloadPaymentElement(context, id) {
    const payment = await query(
      {
        query: gql`
        query {
          payment(id: "${id}") {
            id
            name
            state
            createdAt
            paymentDate
            societyId
            createdBy
            payableAmount
            payableVat
          }
        }
      `,
      },
      { pickProp: 'payment' },
    );
    context.dispatch('addPaymentElement', payment);
  },

  async getPayment(context, id) {
    const payment = await query(
      {
        query: gql`
        query {
          payment(id: "${id}") {
            id
            name
            state
            createdAt
            paymentDate
            societyId
            payableAmount
            payableVat
            totalAmount
            totalVat
            numPerformers
            numPayees
            createdBy
            confirmedBy
            processId
            occupied
            files {
              type
              name
              key
            }
            stats {
              state
              totalCount
              payeeCount
              totalAmount
              payeeAmount
            }
          }
        }
      `,
      },
      { pickProp: 'payment' },
    );
    context.commit('setPayment', payment);
  },

  // Keeps fetching the payment until it exists and `desiredState(payment) == true`
  async pollPayment(context, { id, desiredState }) {
    context.commit('setPolling', true);

    while (!context.state.payment || !desiredState(context.state.payment)) {
      await context.dispatch('getPayment', id);

      if (!context.state.payment || !desiredState(context.state.payment)) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }

    context.commit('setPolling', false);
  },

  async getPaymentNodes(context, request) {
    const rows = await query(
      {
        query: gql`
          query paymentNodes($request: PaymentNodeRequest!) {
            paymentNodes(request: $request) {
              id
              name
              isPayee
              amount
              vat
              type
              state
              graphId
              paymentInfo
              taxInfo
            }
          }
        `,
        variables: {
          request,
        },
      },
      { pickProp: 'paymentNodes' },
    );
    context.commit('updatePayment', { rows });
  },

  addPaymentElement(context, payment) {
    if (context.state.payments.find((p) => p.id === payment.id)) {
      context.commit('updatePaymentElement', payment);
    } else {
      context.commit('appendPaymentElement', payment);
    }
  },

  async createPaymentFiles(context, paymentId) {
    const { processId } = await mutate(
      paymentCommand({
        stream_id: paymentId,
        type: 'payment/process/create-output',
        payload: {},
      }),
      { pickProp: 'handleCommand' },
    );
    return context.commit('updatePayment', { processId });
  },

  async confirmPayment(context, paymentId) {
    await mutate(
      paymentCommand({
        stream_id: paymentId,
        type: 'payment/confirm',
        payload: {},
      }),
    );
    context.commit('updatePayment', { state: paymentState.Working });
  },
};

const mutations = {
  setPayments(state, payments) {
    state.payments = [...payments];
  },

  appendPaymentElement(state, payment) {
    state.payments = [...state.payments, payment];
  },

  prependPaymentElement(state, payment) {
    state.payments = [payment, ...state.payments];
  },

  updatePaymentElement(state, payment) {
    const idx = state.payments.findIndex((p) => p.id === payment.id);
    if (idx === -1) {
      throw new Error(`Can not find payment with id ${payment.id}`);
    }
    state.payments = [
      ...state.payments.slice(0, idx),
      {
        ...state.payments[idx],
        ...payment,
      },
      ...state.payments.slice(idx + 1),
    ];
  },

  removePaymentElement(state, id) {
    state.payments = state.payments.map((p) => ({
      ...p,
      state: id === p.id ? paymentState.Removed : p.state,
    }));
  },

  setTotalCount(state, count) {
    state.totalCount = count;
  },

  setPayment(state, payment) {
    state.payment = payment;
  },

  updatePayment(state, updates) {
    state.payment = {
      ...state.payment,
      ...updates,
    };
  },

  setPolling(state, newState) {
    state.polling = newState;
  },
};

const getters = {
  paymentById: (state) => (id) => state.payments.find((p) => p.id === id),
};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;

export const paymentCommand = (command) => ({
  mutation: gql`
    mutation handleCommand($target: CommandTarget!, $command: CommandInput!) {
      handleCommand(target: $target, command: $command) {
        processId
      }
    }
  `,
  variables: {
    command,
    target: 'payment',
  },
  fetchPolicy: 'network-only',
});
