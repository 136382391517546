<template>
  <div class="compare__action-btns">
    <template v-if="line.incoming.match_state === 'I'">
      <div class="compare__buttons hc">
        <button class="btn red m2" @click="revertMatch(line.incoming, isAlbum)">Unidentify</button>
      </div>
    </template>
    <template v-else-if="line.incoming.match_state === 'M'">
      <div class="compare__buttons hc">
        <button class="btn red m2" @click="revertMatch(line.incoming, isAlbum)">Unidentify</button>
        <button
          v-if="handleIgnoreBtn"
          class="btn yellow m2"
          @click="handleIgnoreBtn(line.incoming)"
        >
          Ignore
        </button>
        <button
          class="btn primary m2"
          :disabled="!line.dirty"
          @click="handleSaveBtn(line.incoming.group_id)"
        >
          Save changes
        </button>
      </div>
    </template>
    <template v-else>
      <candidate-selection :is-album="isAlbum" @candidateChanged="candidateChanged" />
      <div class="compare__buttons hc">
        <button
          class="btn green m2"
          :disabled="!(currentCandidate && currentCandidate.id)"
          @click="handleIdentifyBtn(line.incoming)"
        >
          Identify
        </button>
        <button
          v-if="handleIgnoreBtn"
          class="btn yellow m2"
          @click="handleIgnoreBtn(line.incoming)"
        >
          Ignore
        </button>
        <button class="btn secondary m2" @click="handleNewRecording(line)">
          {{ isAlbum ? 'New Album' : 'New recording' }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CandidateSelection from '../matching/candidate-selection';

export default {
  name: 'CompareButtonsMatching',
  components: {
    CandidateSelection,
  },
  props: {
    candidateChanged: {
      type: Function,
    },
    revertMatch: {
      type: Function,
    },
    handleIdentifyBtn: {
      type: Function,
    },
    handleIgnoreBtn: {
      type: Function,
    },
    handleNewRecording: {
      type: Function,
    },
    handleSaveBtn: {
      type: Function,
    },
    line: {
      type: Object,
    },
    isAlbum: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters('matching', ['currentCandidate']),
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.hc {
  display: flex;
  justify-content: center;
}
.m2 {
  margin: 2px;
}
</style>
