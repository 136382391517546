<template>
  <div
    class="process-item"
    :class="{
      'finished-with-errors': finishedWithErrors,
      'finished-with-success': finishedWithSuccess,
      'timed-out': isTimedOut,
    }"
  >
    <div class="header">
      <h4 class="name">
        <i v-if="isTimedOut" class="fas fa-hourglass-end" title="timed out" />
        <router-link v-if="isRouteAvailable" :to="route">
          {{ name }}
        </router-link>
        <span v-else>{{ name }}</span>
      </h4>
      <div v-if="removeable" class="remove text--right">
        <i class="fas fa-times" @click="remove" />
      </div>
    </div>
    <ol class="messages">
      <li v-if="state === 'PENDING'" class="message">pending...</li>
      <li
        v-for="(row, idx) of visibleLogRows"
        v-else
        :key="idx"
        class="message"
        :class="{ error: !!row.error }"
      >
        <span class="message-timestamp">{{ getTime(row.timestamp_unix) }}</span>
        <span v-if="displayLogLevel" class="log-level" :class="logLevel(row.level).toLowerCase()">
          [{{ logLevel(row.level) }}]
        </span>
        <span class="message-text">{{ row.message }}</span>
      </li>
    </ol>
    <span
      v-if="limitRows > 0 && logRows.length > limitRows"
      class="show-all"
      @click="showAllRows = !showAllRows"
    >
      <span v-if="showAllRows">
        <i class="caret fas fa-caret-up" />
        less
      </span>
      <span v-else>
        <i class="caret fas fa-caret-down" />
        more
      </span>
    </span>
    <ul v-if="errors.length" class="error-list">
      <li v-for="(err, idx) of errors" :key="idx">
        <div class="error-item">
          <span class="fa fa-exclamation-circle error-icon" />
          <span class="error-message">{{ err.message }}</span>
        </div>
      </li>
    </ul>
    <progress-bar
      v-if="progress.total > 0"
      :total="progress.total"
      :processed="progress.processed"
      :show-percent="true"
      :percent-decimals="2"
    />
    <div class="process-id">
      {{ id }}
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';
import ProgressBar from '../ui/progress-bar';
import { STATE } from '../../store/modules/process/store';
import { LOG_LEVEL } from '../../services/processService';

export default {
  name: 'ProcessItem',
  components: {
    ProgressBar,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    state: {
      type: String,
    },
    logRows: {
      type: Array,
      default: () => [],
    },
    maxRows: {
      type: Number,
      default: 100,
    },
    route: {
      type: String,
    },
    routeAvailability: {
      type: [Array, String],
      default: () => [],
    },
    removeable: {
      type: Boolean,
      default: true,
    },
    limitRows: {
      type: Number,
      default: 1,
    },
    displayLogLevel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAllRows: false,
    };
  },
  computed: {
    isFinished() {
      return this.state === STATE.FINISHED;
    },
    isTimedOut() {
      return this.state === STATE.TIMED_OUT;
    },
    errors() {
      return this.logRows.reduce(
        (errs, row) =>
          row.error ? [...errs, { ...row.error, message: row.error.message || row.message }] : errs,
        [],
      );
    },
    hasErrors() {
      return this.errors.length > 0;
    },
    finishedWithErrors() {
      return this.isFinished && this.hasErrors;
    },
    finishedWithSuccess() {
      return this.isFinished && !this.hasErrors;
    },
    isRouteAvailable() {
      return (
        typeof this.route === 'string' &&
        (this.routeAvailability === '*' || this.routeAvailability.includes(this.state))
      );
    },
    lastRow() {
      return this.logRows[this.logRows.length - 1] || {};
    },
    progress() {
      const { progress = {} } = this.lastRow;
      return {
        total: progress.total || 0,
        processed: progress.processed || 0,
        entity: progress.entity || null,
      };
    },
    visibleLogRows() {
      if (this.limitRows === 0 || this.showAllRows) {
        return this.logRows.slice().reverse();
      }
      return this.logRows.slice().reverse().slice(0, this.limitRows);
    },
  },
  methods: {
    remove() {
      this.$emit('remove');
    },
    logLevel(level) {
      return Object.keys(LOG_LEVEL).find((key) => LOG_LEVEL[key] === level);
    },
    getTime(timestamp) {
      return this.$filters.dateFormat(new Date(Number(timestamp)), 'HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.process-item {
  margin-bottom: 1.5rem;
}
.process-id {
  font-size: 0.55rem;
  color: rgba(0, 0, 0, 0.3);
}
.header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.name {
  font-weight: bold;
  flex: auto 1 1;
  overflow: hidden; // stops the name from expanding too far, pushing the X-button out of view
}
.remove {
  flex: auto 1 1;
  cursor: pointer;
}
.messages {
  padding: 0;
  list-style: none;
}
.message {
  margin: 0.5rem 0;
  color: rgba(0, 0, 0, 0.6);
  &.error {
    color: var(--red);
  }
}
.finished-with-errors {
  .name {
    color: var(--red);
  }
  .progress-bar {
    color: var(--red);
    .bar {
      background: var(--red);
    }
  }
}
.finished-with-success {
  .name {
    color: var(--alert--success);
  }
  .progress-bar {
    color: var(--alert--success);
    .bar {
      background: var(--alert--success);
    }
  }
}
.timed-out {
  .name {
    color: var(--alert--warning);
  }
  .progress-bar {
    color: var(--alert--warning);
    .bar {
      background: var(--alert--warning);
    }
  }
}
.error-list {
  font-size: 0.7rem;
}
.error-item {
  padding: 0.15rem 0;
}
.error-icon {
  color: var(--red);
}
.error-message {
  margin-left: 0.5rem;
  font-size: 0.65rem;
  color: var(--red);
}
.message-timestamp {
  font-style: italic;
  opacity: 0.6;
}
.message-text {
  margin-left: 0.5rem;
}
.show-all {
  line-height: 1.5rem;
  font-size: 0.7rem;
  cursor: pointer;
}
.log-level {
  margin-left: 0.3rem;
  font-size: 0.7rem;
  &.debug {
    color: blue;
  }
  &.info {
    color: green;
  }
  &.warning {
    color: yellow;
  }
  &.error {
    color: red;
  }
}
</style>
