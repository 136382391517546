import { mutate, query } from './apolloRequest';
import playedRecordingsGql from '../domain/playedRecordingsGql';
import AuthenticationService from './authenticationService';

export default {
  createPlayedRecordingsFile: async (file, processId) =>
    mutate({
      mutation: playedRecordingsGql.createPlayedRecordingsFile,
      variables: {
        command: {
          ...file,
          created_by: AuthenticationService.getUserName(),
          process_id: processId,
        },
      },
    }),
  deletePlayedRecordingsFile: async (id) =>
    mutate({
      mutation: playedRecordingsGql.deletePlayedRecordingsFile,
      variables: { id },
    }),
  getPlayedRecordingsFiles: async (options) =>
    query({
      query: playedRecordingsGql.getPlayedRecordingsFiles,
      variables: { options },
    }),
};
