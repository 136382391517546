<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
  },

  watch: {
    value(value) {
      for (const child of this.$children) {
        child.isActive = child.id === value;
      }
    },
  },

  methods: {
    onPairEdit(activePairId) {
      this.$emit('input', activePairId);
    },
  },
};
</script>
