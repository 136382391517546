/**
 * Common functionality for mediarc report views
 */

import { mapGetters, mapMutations } from 'vuex';
import ContextMenu from '../../ui/context-menu';
import MediarcService from '../../../services/mediarcService';
import AlbumService from '../../../services/albumService';
import Spinner from '../../spinner';
import musicReportMixin from '../view-music-report-mixin';
import { saveNote } from '../../../services/notesService';

const recordingColumns = [
  {
    name: 'Recording',
    ascending: true,
    sortParam: 'title',
    active: true,
    default: true,
  },
  {
    name: 'Main Artist',
    ascending: true,
    sortParam: 'main_artist',
    active: false,
    default: false,
  },
  { name: 'SR' },
  { name: 'Recording Year' },
  { name: 'Recorded in Country' },
  { name: 'Label' },
  { name: 'Duration' },
];

const albumColumns = [
  {
    name: 'Album',
    ascending: true,
    sortParam: 'title',
    active: true,
    default: true,
  },
  {
    name: 'Main Artist',
    ascending: true,
    sortParam: 'main_artist',
    active: false,
    default: false,
  },
  { name: 'SR' },
  { name: 'Label' },
  { name: 'Release Date' },
];

export default {
  components: {
    ContextMenu,
    Spinner,
  },
  activated() {
    this.addKeyListeners();
  },
  deactivated() {
    this.clearComparedObjects();
    this.removeKeyListeners();
  },
  data() {
    return {
      loading: true,
      error: false,
      reportId: this.$router.currentRoute.params.reportId,
      isAlbum: this.$router.currentRoute.query.album === 'true',
      totalCount: 0,
      expandedId: '',
      dirty: false,
      columns: [],
      expandedRecordingId: '',
    };
  },
  computed: {
    ...mapGetters('matching', [
      'reportLines',
      'candidates',
      'currentIncoming',
      'currentCandidate',
      'viewMediarcReportMatchedLines',
      'viewMediarcReportCandidateLines',
      'viewMediarcReportRestedLines',
    ]),
    type() {
      return this.isAlbum ? 'album' : 'recording';
    },
  },
  created() {
    this.columns = this.getColumns();
  },
  watch: {
    async $route() {
      this.columns = this.getColumns();
    },
  },
  methods: {
    ...musicReportMixin.methods,
    ...mapMutations('matching', [
      'updateViewMediarcReportMatchedLinesExpandedId',
      'updateViewMediarcReportCandidateLinesExpandedId',
      'updateViewMediarcReportRestedLinesExpandedId',
    ]),
    getColumns() {
      return this.isAlbum ? albumColumns : recordingColumns;
    },
    async handleUnidentifyBtn(incoming, matchId, matchState) {
      await this.updateMatches([incoming.group_id], matchId, matchState);
      this.markCompleted([incoming.group_id], 'R');
      this.togglePrevOrNext('next');
    },
    async updateMatchOnCheckedItems(matchId, matchState) {
      const ids = this.reportLines
        .filter((line) => line.checked)
        .map((line) => line.incoming.group_id);

      await this.updateMatches(ids, matchId, matchState);
      this.markCompleted(ids, matchState);
      this.$emit('updated');
    },
    async updateMatches(ids, matchId, matchState) {
      try {
        await MediarcService.updateMatchState({
          ids,
          match_id: matchId,
          match_state: matchState,
          type: this.type,
        });
      } catch (error) {
        error.title = `Could not unidentify ${this.type}`;
        this.$addStarError(error);
      }
    },
    async fetchCandidates(id) {
      if (this.isAlbum) {
        return MediarcService.getAlbumHits(id)
          .then((result) => result.hits)
          .catch((error) => {
            console.log('Error fetching candidates', error);
          });
      }
      return MediarcService.getRecordingHits(id)
        .then((result) => result.hits)
        .catch((error) => {
          console.log('Error fetching candidates', error);
        });
    },
    async toggleExpanded(groupId) {
      if (this.isExpanded(groupId)) {
        this.setExpanded(undefined);
        return;
      }

      this.setExpanded(groupId);

      let candidates = [];
      const line = this.getLine(groupId);
      if (line && line.incoming.match_state === 'C') {
        candidates = await this.fetchCandidates(line.incoming.group_id);
      }
      this.updateCandidates(candidates);

      await this.reloadCompared(groupId);
    },
    async fetchData() {
      this.loading = true;
      this.error = false;

      const request = {
        report_id: this.reportId,
        limit: this.pagination.hitsPerPage,
        match_state: this.match_state,
        main_artist_range: this.range,
        offset: this.getOffset(),
        ascending: this.sortTerms.order ? this.sortTerms.order.toLowerCase() === 'asc' : true,
        order_by: this.sortTerms.param,
      };
      const service = this.isAlbum
        ? MediarcService.fetchAlbumLines
        : MediarcService.fetchRecordingLines;

      try {
        const result = await service(request);

        this.totalCount = result.total_count;
        const reportLines = result.items.map((incoming) => ({
          incoming: {
            ...incoming,
            group_id: this.isAlbum ? incoming.album_id : incoming.recording_id,
          },
          matched: {},
          checked: false,
          dirty: false,
        }));
        this.updateReportLines(reportLines);
        this.loading = false;
      } catch (error) {
        error.title = 'Could not fetch report';
        this.$addStarError(error);
      }
    },
    async handleSaveBtn(id, matchId, dirty) {
      if (dirty) {
        await this.saveChanges();

        if (this.currentCandidate.albums && this.currentCandidate.albums.length === 1) {
          const [album] = this.currentCandidate.albums;
          const aggregate = {
            id: album.id,
            tracks: [
              {
                duration_sec: this.currentCandidate.basic_info.duration_sec,
                recording: {
                  id: this.currentCandidate.id,
                  side_no: album.side_no,
                  track_no: album.track_no,
                },
              },
            ],
          };

          await AlbumService.updateAlbumTracks(aggregate, true);
        }
      }
      if (this.currentIncoming.notes) {
        await saveNote({ entityId: matchId, noteText: this.currentIncoming.notes });
      }
      await this.updateMatches([id], matchId, 'D');
      this.markCompleted([id], 'D', matchId);
      this.togglePrevOrNext('next');
    },
    async handleNewBtn(line) {
      await this.createNew([line.incoming.group_id]);
      this.markCompleted([line.incoming.group_id], 'D');
      this.togglePrevOrNext('next');
    },
    async createNewOnCheckedItems() {
      const ids = this.reportLines
        .filter((line) => line.checked)
        .map((line) => line.incoming.group_id);

      await this.createNew(ids);
      this.markCompleted(ids, 'D');
      this.$emit('updated');
    },
    async createNewAll() {
      const ids = this.reportLines.map((line) => line.incoming.group_id);

      await this.createNew(undefined, true);
      this.markCompleted(ids, 'D');
      this.$emit('updated');
    },
    async createNew(ids, all) {
      await MediarcService.createNew({ ids, all, report_id: this.reportId, type: this.type });
    },
  },
};
