import gql from 'graphql-tag';

const matchPerformerRow = `
  id
  performer_id
  main_artists
  tags
  group_id
  first_name
  last_name
  ipn
  date_of_birth
  state
  gross_amount
  net_amount
  converted_amount
  error
`;

const getStatementPerformers = gql`
  query getStatementPerformers($statementId: String!, $options: StatementPerformersOptions) {
    getStatementPerformers(statementId: $statementId, options: $options) {
      items {
        ${matchPerformerRow}
      }
      total_count
      feedback
    }
  }
`;

const getStatementPerformerStateSummaries = gql`
  query getStatementPerformerStateSummaries($statementId: String!) {
    getStatementPerformerStateSummaries(statementId: $statementId) {
      state
      amount_total
      converted_amount_total
    }
  }
`;

const fetchPerformerMatchingDetails = gql`
  query fetchPerformerMatchingDetails($statementId: String!, $referenceId: String!) {
    fetchPerformerMatchingDetails(statementId: $statementId, referenceId: $referenceId) {
      id
      recordings {
        title
        main_artists
        role_codes
      }
      hits {
        id
        first_name
        last_name
        ipn
        date_of_birth
        society_codes
        tags
        nationality
      }
      match_state
      date_modified
      error
    }
  }
`;

const changePerformerMatchState = gql`
  mutation changePerformerMatchState($command: ChangePerformerMatchStateCommand!) {
    changePerformerMatchState(command: $command)
  }
`;

const confirmDisburse = gql`
  mutation confirmDisburse($command: ConfirmDisburseCommand!) {
    confirmDisburse(command: $command)
  }
`;

const createFeedbackFile = gql`
  mutation createFeedbackFile($command: CreateFeedbackFileCommand!) {
    createFeedbackFile(command: $command)
  }
`;

export default {
  getStatementPerformers,
  getStatementPerformerStateSummaries,
  fetchPerformerMatchingDetails,
  changePerformerMatchState,
  confirmDisburse,
  createFeedbackFile,
};
