<template>
  <tr class="prevent-hover-bg no-borders">
    <td colspan="1000">
      <ComponentSpinner v-if="loading" />
      <div v-else class="compare__nested">
        <div class="compare__nested-main compare__main-divide">
          <span class="compare__nested-heading compare__nested-heading--incoming">
            <strong>Played {{ config.entityType }}:</strong>
            <span>{{ line[config.lineTitleKey] }}</span>
          </span>
          <span class="compare__nested-heading compare__nested-heading--candidate">
            <strong>Star suggestion:</strong>
            <a :href="`#${config.candidateRoute}${getCandidateValue('id')}`">
              {{ getCandidateValue('name') }}
              {{ getCandidateValue('lineup_locked') ? ' 🔒' : '' }}
            </a>
          </span>
          <ul class="compare__nested-list">
            <CompareGridRow2
              v-for="compare in config.compare"
              :key="`${selectedHit.id}-${compare.candidate.displayKey}`"
              :candidate="{
                key: compare.candidate.displayKey,
                value: candidateValue(compare.candidate),
                valueKey: compare.candidate.valueKey,
                displayFilter: compare.candidate.displayFilter,
              }"
              :incoming="{
                key: compare.incoming.displayKey,
                value: line[compare.incoming.valueKey],
                displayFilter: compare.incoming.displayFilter,
              }"
              :enabled="!!selectedHit.id && compare.enabled"
              :allow-action="compare.allowAction"
              :inherit-when-empty="compare.inheritWhenEmpty"
              :comparator="compare.comparator"
              @valueUpdated="candidateValueUpdated"
            />
          </ul>
        </div>
        <div :style="{ 'grid-area': 'action' }">
          <div class="compare__search">
            <div class="compare__nested-heading">
              <strong>Possible candidates</strong>
            </div>
            <div v-if="hits.length === 0" class="none push--bottom">
              No candidates, try searching for one...
            </div>
            <ul v-else>
              <li
                v-for="(cnd, idx) in hits"
                :key="cnd.id"
                class="candidate"
                :class="{ selected: isSelected(idx), match: isCandidateMatch(cnd.id) }"
                @click="selectCandidate(idx)"
              >
                <div class="candidate_left">
                  <div class="candidate_title">{{ getCandidateName(cnd) }}</div>
                  <div class="candidate_artist">
                    <span class="candidate-artist-separator text--italic">by</span>
                    {{ cnd.main_artist }}
                  </div>
                  <div class="candidate_date">
                    {{ $filters.year(cnd[config.candidateDateKey]) }}
                  </div>
                </div>
                <div class="candidate_right">
                  <div class="candidate_meta">
                    <div class="candidate_lineup">
                      <span>{{ cnd.lineup_length }}</span>
                      <span>
                        <i
                          title="Sami WW/WW-"
                          class="fas fa-user member"
                          :class="{ 'member--active': cnd.samiLineupMember }"
                        ></i>
                      </span>
                    </div>
                    <div class="candidate_status">
                      <status-icon :status="cnd.status" />
                    </div>
                  </div>
                  <div class="flex-break" />
                  <div class="candidate_link">
                    <router-link v-slot="{ href }" :to="candidateLink" custom>
                      <a :href="href" :target="candidateTabName">
                        <i class="fas fa-arrow-right"></i>
                      </a>
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
            <div class="row compare__search__id">
              <div class="col s12">
                <span>{{ config.searchAndCompare.title }}</span>
                <searchable-input
                  :show-label="false"
                  name="name"
                  :placeholder="'E.g. Stoppa mig Juni (Lilla Ego)'"
                  :clear-after-select="true"
                  :css-props="{ whiteSpace: 'normal' }"
                  :searcher="search"
                  :use-template="config.searchAndCompare.template"
                  @input="add"
                />
              </div>
            </div>
          </div>
          <div class="row compare__action-btns">
            <div class="col s12">
              <compare-buttons-matching2
                :line="line"
                :dirty_line="changes > 0"
                :candidate-id="selectedHit.id"
                :is-album="false"
                :handle-identify-btn="handleIdentify"
                :handle-ignore-btn="ignore"
                :handle-new="handleNew"
                :new-btn-title="config.entityType === 'recording' ? 'New recording' : 'New Album'"
                :handle-save-btn="saveCandidate"
                :revert-match="revertMatch"
                :capture-key-press="captureKeyPress"
              />
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import config from './config';
import _ from 'lodash';
import { query } from '@/services/apolloRequest';
import ComponentSpinner from '../component-spinner.vue';
import SearchableInput from '@/components/ui/input/searchable-input.vue';
import StatusIcon from '@/components/ui/status-icon.vue';
import CompareButtonsMatching2 from '@/components/music-reports/compare-buttons-matching-2.vue';
import CompareGridRow2 from '@/components/music-reports/compare-grid-row-2.vue';

export default {
  name: 'CompareLine',
  components: {
    CompareButtonsMatching2,
    StatusIcon,
    SearchableInput,
    CompareGridRow2,
    ComponentSpinner,
  },
  props: {
    line: {
      type: Object,
      required: true,
    },
    identify: {
      type: Function,
    },
    ignore: {
      type: Function,
    },
    handleNew: {
      type: Function,
    },
    save: {
      type: Function,
    },
    revertMatch: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      changes: 0,
      hits: [],
      selectedHitIdx: 0,
      candidateTabName: 'candidate',
      candidate: {},
      captureKeyPress: true,
    };
  },
  computed: {
    config() {
      return config[this.$route.params.type];
    },
    selectedHit() {
      return this.hits[this.selectedHitIdx] || {};
    },
    allowAction() {
      return true;
    },
    candidateLink() {
      return this.config.candidateRoute + this.selectedHit.id;
    },
  },
  created() {
    this.fetchData().then(() => this.$emit('ready'));
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('focusin', this.onFocus);
    window.addEventListener('focusout', this.onFocusOut);
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('focusin', this.onFocus);
    window.removeEventListener('focusout', this.onFocusOut);
  },
  methods: {
    getCandidateName(candidate) {
      return candidate.version_title
        ? `${candidate.name} (${candidate.version_title})`
        : candidate.name;
    },
    onKeyDown(e) {
      if (!this.captureKeyPress) {
        return;
      }
      if (e.key === 'Tab') {
        e.preventDefault();
        if (e.shiftKey) {
          this.prevCandidate();
        } else {
          this.nextCandidate();
        }
      }
      if (e.code === 'Space' && this.selectedHit) {
        e.preventDefault();
        const route = this.$router.resolve(this.candidateLink);
        window.open(route.href, this.candidateTabName);
      }
    },
    onFocus(e) {
      this.captureKeyPress = !['input', 'textarea'].includes(e.target.tagName?.toLowerCase());
    },
    onFocusOut() {
      this.captureKeyPress = true;
    },
    isCandidateMatch(id) {
      return this.line.match_id === id;
    },
    prevCandidate() {
      this.selectCandidate(this.selectedHitIdx - 1);
    },
    nextCandidate() {
      this.selectCandidate(this.selectedHitIdx + 1);
    },
    add(result) {
      if (!result || !result.id) {
        return;
      }
      if (!this.hits.find((hit) => hit.id === result.id)) {
        this.hits.push(this.config.searchAndCompare.mapper(result));
        this.selectCandidate(this.hits.length - 1);
      }
    },
    async handleIdentify(line, candidateId) {
      await this.identify(line, candidateId, this.candidate);
    },
    candidateValueUpdated(val) {
      if (val.revert) {
        _.unset(this.candidate, val.data.key);
        this.changes -= 1;
      } else {
        _.set(this.candidate, val.data.key, val.data.value);
        this.changes += 1;
      }
    },
    candidateValue(candidateConfig) {
      const { value, valueKey } = candidateConfig;
      if (value) {
        if (typeof value === 'function') {
          return value(this.selectedHit);
        }
        return value;
      } else if (valueKey) {
        return this.selectedHit[valueKey];
      }
      return '';
    },
    saveCandidate() {
      this.hits[this.selectedHitIdx] = _.merge(this.selectedHit, this.candidate);
      this.save(this.config.hitToAggregate(this.selectedHit));
      this.candidate = {};
      this.changes = 0;
    },
    getCandidateValue(path) {
      return _.get(this.selectedHit, path.split('.')) || '';
    },
    isSelected(idx) {
      return idx === this.selectedHitIdx;
    },
    selectCandidate(idx) {
      if (idx >= this.hits.length) {
        this.selectedHitIdx = 0;
      } else if (idx < 0) {
        this.selectedHitIdx = this.hits.length - 1;
      } else {
        this.selectedHitIdx = idx;
      }
    },
    async fetchData() {
      if (this.line.hits.length === 0) {
        return;
      }
      this.loading = true;
      try {
        this.hits = await query(
          {
            query: this.config.gql.hits.query,
            variables: {
              streamIds: this.line.hits,
            },
          },
          { pickProp: this.config.gql.hits.pickProp },
        );
        const state = this.$route.params.state;
        if (state === 'matched' && this.line.match_id) {
          this.selectedHitIdx = this.hits.findIndex((hit) => hit.id === this.line.match_id);
        }
      } catch (err) {
        this.$addStarError(err);
      } finally {
        this.loading = false;
      }
    },
    search(terms) {
      return this.config.searchAndCompare.searcher(terms);
    },
  },
};
</script>

<style lang="scss" scoped>
.compare__search {
  font-size: 13px;
  ul {
    border: 1px solid #e8e8e8;
    max-height: 510px;
    margin-bottom: var(--spacing-triple);
    overflow-y: auto;
  }
  li {
    padding: var(--spacing);
    margin-bottom: 0;
    &:first-child {
      margin-top: -1px;
    }
    &:last-child {
      margin-bottom: -1px;
    }
    &.selected {
      position: relative;
      font-weight: bold;
      border-left: 3px solid var(--blue--water);
      margin-left: -1px;
      cursor: default;
    }
    &:hover:not(.selected) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .candidate-artist-separator {
    color: var(--grey--vlight);
    margin-right: 0.3rem;
  }
}
.candidate {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  &.match {
    background: var(--yellow--suplight);
  }
}
.candidate_left {
  display: flex;
  flex-flow: row wrap;
}

.candidate_lineup,
.candidate_status {
  display: inline-block;
}
.candidate_status {
  margin-left: 6px;
}

.candidate_title {
  margin-right: 6px;
}

.candidate_right {
  width: 80px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}
.candidate_date {
  flex: 1 1 100%;
}
.flex-break {
  flex: 0 0 100%;
  height: 0;
}
.member {
  opacity: 0.15;
  margin-left: 6px;
}
.member--active {
  opacity: 1;
}
</style>
