<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h1>Refunds</h1>
      </div>
    </div>
    <div class="row card">
      <div class="col s12">
        <table>
          <thead>
            <th>Date</th>
            <th>Reason</th>
            <th>Note</th>
            <th>Confirmed By</th>
            <th class="text--right">Net</th>
            <th class="text--right">Vat</th>
          </thead>
          <tbody>
            <tr v-for="refund of refunds" :key="refund.id">
              <td>
                <router-link :to="refund.id" append>
                  {{ $filters.dateFormat($filters.dateParse(refund.date), 'YYYY-MM-DD') }}
                </router-link>
              </td>
              <td>{{ refund.reason }}</td>
              <td>{{ refund.note }}</td>
              <td>{{ refund.confirmedBy }}</td>
              <td class="text--right">
                {{ $filters.formatAmountCent(refund.net, 'SEK') }}
              </td>
              <td class="text--right">
                {{ $filters.formatAmountCent(refund.vat, 'SEK') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { query } from '../../services/apolloRequest';
export default {
  data() {
    return {
      refunds: [],
      paging: {
        offset: 0,
        limit: 100,
      },
      orderBy: 'date',
      dir: 'desc',
    };
  },
  created() {
    this.fetchRefunds();
  },
  methods: {
    async fetchRefunds() {
      this.loading = true;
      try {
        this.refunds = await query(
          {
            query: gql`
              query refunds($query: RefundQuery) {
                refunds(query: $query) {
                  id
                  reason
                  date
                  note
                  createdBy
                  confirmedBy
                  net
                  vat
                }
              }
            `,
            variables: {
              query: {
                limit: this.paging.limit,
                offset: this.paging.offset,
                orderBy: this.orderBy,
                dir: this.dir,
              },
            },
          },
          { pickProp: 'refunds' },
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
