<template>
  <div>
    <div v-if="loading" class="row">
      <div class="col s12">
        <spinner />
      </div>
    </div>
    <div v-else-if="id" class="row">
      <div class="col s12 l9 associate-wrapper">
        <h1 v-if="selectedCategory === 'organisation'">
          {{ organisationInfoName }} ({{ $filters.translate(`common.${type.toLowerCase()}`) }})
        </h1>
        <h1 v-else>
          {{ personInfoFirstName }} {{ personInfoMiddleName }} {{ personInfoLastName }} ({{
            $filters.translate(`common.${type.toLowerCase()}`)
          }})
        </h1>
        <version :version="routeVersion()" view-name="associateInformation" />
        <div>
          <div v-if="selectedCategory === 'organisation'">
            <create-organisation-info
              v-if="isEditMode('OrganisationInformation')"
              :edit-mode="true"
              @update="onUpdateBasicInfo"
              @cancel="onCancel"
            />
            <organisation-info v-else @edit="onEdit" />
          </div>
          <div v-else>
            <create-person-info
              v-if="isEditMode('PersonInformation')"
              :edit-mode="true"
              @update="onUpdateBasicInfo"
              @cancel="onCancel"
            />
            <person-info v-else @edit="onEdit" />
          </div>
          <create-contact-info
            v-if="isEditMode('ContactInformation')"
            :selected-category="selectedCategory"
            :edit-mode="true"
            @update="onUpdateContactInfo"
            @cancel="onCancel"
          />
          <contact-info
            v-else
            :selected-category="selectedCategory"
            :disabled="routeVersion() !== null"
            @edit="onEdit"
            @update="onUpdateContactInfo"
          />

          <payment-information
            v-if="!isEditMode('PaymentInformation')"
            :private-account="privateAccount"
            :company-account="companyAccount"
            :foreign-account="foreignAccount"
            :has-payment-receiver="hasPaymentReceiver"
            @edit="onEdit"
          />
          <create-payment-information
            v-else
            :edit-mode="true"
            :company-account-visible="
              selectedCategory === 'organisation' ||
              (companyAccount.account_number && companyAccount.account_number.isEmpty)
            "
            :company-account-disabled="selectedCategory === 'person'"
            :private-account="privateAccount"
            :company-account="companyAccount"
            :foreign-account="foreignAccount"
            @resetPaymentInfo="resetPaymentInfo"
            @addPrivateAccount="commitChange('addPrivateAccount', $event)"
            @addCompanyAccount="commitChange('addCompanyAccount', $event)"
            @addForeignAccount="commitChange('addForeignAccount', $event)"
            @save="updatePaymentInfo"
            @cancel="onCancel"
          />
        </div>

        <associates
          v-if="type === 'COMPANY' || type === 'HEIR'"
          :enable-row-options="routeVersion() === null"
          namespace="associate"
          :edit-mode="isEditMode('associates')"
          relation-type="associates"
          @edit="onEdit('associates')"
          @remove="removeAssociate"
          @save="saveAssociate"
        />

        <associates
          :enable-row-options="routeVersion() === null"
          namespace="associate"
          :edit-mode="isEditMode('childAssociations')"
          relation-type="childAssociations"
          @edit="onEdit('childAssociations')"
          @remove="removeChildAssociation"
          @save="updateChildAssociation"
          @cancel="editableComponent = ''"
        />

        <AssociateVatWarning
          :org-no="organisationInfo.org_no"
          :vat-no="taxVatNumber"
          :display-button="false"
        />
        <tax-information
          v-if="!isEditMode('TaxInformation')"
          :tax-tin="taxTin"
          :tax-domicile="taxDomicile"
          :tax-vat-number="taxVatNumber"
          :statement-of-income="statementOfIncome"
          @edit="onEdit"
        />
        <edit-tax-information
          v-else
          :edit-mode="true"
          :entity="currentAssociate"
          @cancel="onCancel"
          @save="onUpdateTaxInfo($event)"
        />

        <documents-information
          v-if="!isEditMode('DocumentsInformation')"
          :documents="documents"
          @edit="onEdit"
        />
        <edit-documents-information
          v-else
          :documents="documents"
          :update-documents="updateDocuments"
          @cancel="onCancel"
          @saved="onUpdateDocument($event)"
        />
      </div>
      <div class="col s12 l3">
        <right-column
          :editable-component="editableComponent"
          :pre-selected-tab="routeVersion() ? 'activity' : 'general'"
          @onEdit="onEdit"
          @onCancel="onCancel"
          @onUpdateMetaInfo="onUpdateMetaInfo"
          @onUpdateBlockedPayments="onSave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';
import {
  setAggregate,
  createAggregate,
  commit,
  getAggregate,
} from '../../../store/modules/associate/utils';
import { AGGREGATE_TYPES } from '../../../domain/common';
import AggregateMixin from '../../../common/aggregateMixin';
import Associates from '../../ui/associates/associates';
import AssociateService from '../../../services/associateService';
import clone from '../../../common/clone';
import ContactInfo from './contact-information';
import CreateContactInfo from '../create/create-contact-info';
import CreateOrganisationInfo from '../create/create-organisation-info';
import CreatePaymentInformation from '../../ui/payment/create-payment-information';
import CreatePersonInfo from '../create/create-person-info';
import Delay from '../../../common/delay';
import EditTaxInformation from '../../ui/tax-info/edit-tax-information';
import OrganisationInfo from './organisation-information';
import PaymentInformation from '../../ui/payment/payment-information';
import PersonInfo from './person-information';
import RightColumn from '../right-column';
import Spinner from './../../spinner';
import TaxInformation from '../../ui/tax-info/tax-information';
import UploadDocuments from '../../../services/uploadDocuments';
import Version from '../../ui/version';
import { AssociateType } from '../../../domain/associateDomain';
import DocumentsInformation from '../../ui/documents/documents-information';
import EditDocumentsInformation from '../../ui/documents/edit-documents-information';
import AssociateVatWarning from '../AssociateVatWarning';

export default {
  name: 'ViewAssociateInformation',
  components: {
    Associates,
    ContactInfo,
    CreateContactInfo,
    CreateOrganisationInfo,
    CreatePaymentInformation,
    CreatePersonInfo,
    EditTaxInformation,
    OrganisationInfo,
    PaymentInformation,
    PersonInfo,
    RightColumn,
    Spinner,
    TaxInformation,
    Version,
    DocumentsInformation,
    EditDocumentsInformation,
    AssociateVatWarning,
  },
  mixins: [AggregateMixin],
  data() {
    return {
      loading: true,
      editableComponent: '',
      savedInitialAggregate: null,
      hasPaymentReceiver: false,
      statementOfIncome: false,
      selectedCategory: '',
    };
  },
  computed: {
    ...mapGetters('associate', [
      'aggregate',
      'associates',
      'companyAccount',
      'foreignAccount',
      'childAssociations',
      'id',
      'organisationInfo',
      'organisationInfoName',
      'paymentInfo',
      'personInfo',
      'personInfoFirstName',
      'personInfoLastName',
      'personInfoMiddleName',
      'personInfoSocialSecurityNumber',
      'privateAccount',
      'taxDomicile',
      'taxTin',
      'taxVatNumber',
      'type',
      'documents',
      'localCodes',
    ]),
    currentAssociate() {
      return clone(getAggregate('associate'));
    },
  },
  watch: {
    async $route() {
      await this.fetchData();
    },
    childAssociations() {
      this.statementOfIncome = this.hasStatementOfIncome();
    },
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    ...mapMutations('associate', [
      'removeFromAssociates',
      'removeFromChildAssociations',
      'updateChildAssociations',
      'updateDocuments',
    ]),
    async fetchData(options = { delayMs: 0 }) {
      try {
        this.loading = true;
        if (options.delayMs) {
          await Delay(options.delayMs);
        }
        const aggregate = await this.loadAggregate(AssociateService.getAssociate);
        this.dispatchAggregate(aggregate);
        this.savedInitialAggregate = _.cloneDeep(aggregate);
      } catch (error) {
        this.dispatchAggregate();
        error.title = 'Could not load associate';
      } finally {
        this.hasPaymentReceiver = this.associates.some(
          (a) => a.share && this.isActive(a.startDate, a.endDate),
        );
        this.selectedCategory = this.organisationInfoName ? 'organisation' : 'person';
        this.loading = false;
      }
    },
    hasStatementOfIncome() {
      // only companies and heirs can have statement of income
      if (
        this.currentAssociate.type === AssociateType.COMPANY ||
        this.currentAssociate.type === AssociateType.HEIR
      ) {
        const childAssociations = this.currentAssociate.child_associations;
        if (childAssociations.length > 0) {
          const activeChildAssociates = childAssociations.filter((ass) =>
            this.isActive(ass.start_date, ass.end_date),
          );

          // if NO active child association, this company or heir should NOT have statement of income
          if (activeChildAssociates.length === 0) {
            return false;
            // if active child association and this is a company, it should have statement of income
          } else if (this.currentAssociate.type === AssociateType.COMPANY) {
            return true;
          }
          // if active child association and this is an heir, check if the heir has any active companies. If so this
          // company should NOT have statement of income.
          const associates = this.currentAssociate.associates;
          if (
            associates.length > 0 &&
            associates.filter(
              (ass) =>
                ass.type === AssociateType.COMPANY && this.isActive(ass.start_date, ass.end_date),
            ).length > 0
          ) {
            return false;
          }
          return true;
        }
      }
      return false;
    },
    isActive(startDate, endDate) {
      return moment().isBetween(startDate, !endDate ? moment().endOf('day') : endDate);
    },
    dispatchAggregate(_aggregate) {
      if (!_aggregate) {
        createAggregate();
        return;
      }
      const aggregate = Object.freeze(_.cloneDeep(_aggregate));
      setAggregate(aggregate);
      this.savedInitialAggregate = _.cloneDeep(aggregate);
    },
    resetPaymentInfo() {
      commit('resetPaymentInfo');
    },
    commitAccountChange(type, event) {
      this.resetPaymentInfo();
      this.commitChange(type, event);
    },
    commitChange(type, event) {
      commit(type, event);
    },
    async updateBasicInfo() {
      try {
        await AssociateService.updateBasicInfo(this.aggregate);
        this.fetchData();
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      }
    },
    async updateContactInfo() {
      try {
        await AssociateService.updateContactInfo(this.aggregate);
        this.fetchData();
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      }
    },
    async updateChildAssociation(id) {
      this.loading = true;
      try {
        const aggregate = _.cloneDeep(this.aggregate);
        const documents = aggregate.associates
          .map((child) => child.documents)
          .reduce((a, b) => a.concat(b), []);
        await UploadDocuments(AGGREGATE_TYPES.ASSOCIATE, documents);
        await AssociateService.updateChildAssociations(aggregate, id);
        this.fetchData({ delayMs: 2000 });
        this.editableComponent = '';
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      }
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    onEdit(ref) {
      this.editableComponent = ref;
    },
    async removeChildAssociation(e) {
      const confirmed = await this.$starUserConfirmDialog(
        true,
        'Do you really want to remove this connection?',
        'Yes',
      );
      this.$starUserConfirmDialog(false);
      if (!confirmed) {
        return;
      }
      try {
        this.$starContentLoading(true);
        const aggregate = _.cloneDeep(this.aggregate);
        await AssociateService.removeChildAssociation(aggregate, { ...e });
        setTimeout(() => {
          this.fetchData();
          this.$starContentLoading(false);
        }, 3000);
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      }
    },
    async onUpdateBasicInfo() {
      await this.updateBasicInfo();
      this.editableComponent = '';
    },
    async onUpdateTaxInfo(updatedAssociate) {
      updatedAssociate.tax_info.income_statement = this.statementOfIncome;
      try {
        await AssociateService.updateTaxInfo(updatedAssociate);
        this.editableComponent = '';
        this.fetchData();
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      }
    },
    async onUpdateDocument(newDocuments) {
      try {
        await UploadDocuments(AGGREGATE_TYPES.ASSOCIATE, newDocuments);
        await AssociateService.updateDocuments(
          { documents: [...this.documents, ...newDocuments].filter((doc) => doc.id !== '') },
          this.id,
        );
        this.editableComponent = '';
        this.fetchData();
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      }
    },
    async onUpdateContactInfo() {
      await this.updateContactInfo();
      this.editableComponent = '';
    },
    async updatePaymentInfo() {
      try {
        await AssociateService.updatePaymentInfo(_.cloneDeep(this.paymentInfo), this.id);
        this.editableComponent = '';
        this.fetchData();
      } catch (error) {
        error.title = 'Error updating payment info';
        throw error;
      }
    },
    onCancel() {
      this.editableComponent = '';
      this.dispatchAggregate(this.savedInitialAggregate);
    },
    async updateMetaInfo() {
      try {
        await AssociateService.updateMetaInfo(this.aggregate);
        this.fetchData();
      } catch (error) {
        error.title = 'Ooops!';
        throw error;
      }
    },
    async onUpdateMetaInfo() {
      await this.updateMetaInfo();
      this.editableComponent = '';
    },
    async onSave() {
      this.fetchData();
    },
    async saveAssociate() {
      try {
        const aggregate = _.cloneDeep(this.aggregate);
        await AssociateService.updateAssociateAssociates(aggregate);
        this.$nextTick(() => {
          this.fetchData();
        });
        this.editableComponent = '';
      } catch (error) {
        error.title = 'Error updating performer associates';
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async removeAssociate(e) {
      const confirmed = await this.$starUserConfirmDialog(
        true,
        'Do you really want to remove this associate?',
        'Yes!',
      );
      this.$starUserConfirmDialog(false);
      if (!confirmed) {
        return;
      }
      try {
        await AssociateService.removeAssociate(e.id, e.row_id, this.id);
        this.fetchData();
      } catch (error) {
        error.title = 'Error while removing associate.';
        throw error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.associate-wrapper {
  padding-right: 24px;
}
</style>
