<template>
  <div>
    <select-input
      label="Currency"
      :name="name"
      :value="code"
      :items="currencies"
      :rule="rule"
      item-key="key"
      item-value="value"
      @input="onInput"
    />
  </div>
</template>

<script>
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectCurrency',
  components: { SelectInput },
  props: {
    name: {
      type: String,
      default: 'currency',
    },
    rule: {
      type: [String, Object],
      default: '',
    },
    currencies: {
      type: Array,
      default: () => [
        { key: 'cad', value: 'CAD' },
        { key: 'chf', value: 'CHF' },
        { key: 'czk', value: 'CZK' },
        { key: 'dkk', value: 'DKK' },
        { key: 'eur', value: 'EUR' },
        { key: 'gbp', value: 'GBP' },
        { key: 'huf', value: 'HUF' },
        { key: 'jpy', value: 'JPY' },
        { key: 'nok', value: 'NOK' },
        { key: 'pln', value: 'PLN' },
        { key: 'sek', value: 'SEK' },
        { key: 'usd', value: 'USD' },
        { key: 'zar', value: 'ZAR' },
      ],
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      code: this.value,
    };
  },
  watch: {
    value() {
      this.code = this.value;
    },
  },
  methods: {
    onInput(value) {
      if (value !== '') {
        this.$emit('input', value);
      } else {
        this.$emit('input', null);
      }
    },
  },
};
</script>
