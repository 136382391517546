<template>
  <div>
    <div class="row wrapper">
      <div class="col s6">
        <h1>Payments</h1>
      </div>
      <div class="col s6 text--right push--top edit-link">
        <router-link to="/payments/create">
          <i class="far fa-plus-square" />
          Create payment
        </router-link>
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <div class="col s12">
          <payment-history-list />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentHistoryList from './payment-history-list.vue';

export default {
  name: 'ViewPaymentHistory',
  components: {
    PaymentHistoryList,
  },
  async beforeRouteUpdate(to, from, next) {
    await this.onRoute(to.query);
    next();
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.numberOfPayments / this.paging.limit);
    },
    societies() {
      return this.$store.state.appdata.societies;
    },
  },
  methods: {
    handleCreateNew() {
      this.$router.push({
        path: '/payments',
      });
    },
  },
};
</script>
