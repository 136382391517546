import _ from 'lodash';
import { setAggregate, createAggregate } from '../../../store/modules/society/utils';
import SocietyService from '../../../services/societyService';
import delay from '../../../common/delay';
import { createError, ErrorType } from '../../../domain/starNotification';

export default {
  data() {
    return {
      navigationViews: ['societyView', 'societyRoleCodesView'],
    };
  },
  methods: {
    async fetchData(id, version) {
      await delay(200);
      this.loading = true;
      try {
        const aggregate = await SocietyService.getSociety(id, version);
        this.dispatchAggregate(aggregate);
      } catch (error) {
        this.dispatchAggregate();
        throw createError(ErrorType.NETWORK, 'Could not load society', error.cause);
      } finally {
        this.loading = false;
      }
    },
    dispatchAggregate(_aggregate) {
      if (!_aggregate) {
        createAggregate();
      } else {
        const aggregate = Object.freeze(_.cloneDeep(_aggregate));
        setAggregate(aggregate);
        this.savedInitialAggregate = _.cloneDeep(aggregate);
      }
    },
    handleError(error) {
      this.dispatchAggregate(this.savedInitisavedInitialAggregate);
      error.title = 'Ooops!';
      this.loading = false;
      throw error;
    },
  },
};
