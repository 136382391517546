<template>
  <div v-if="version || readonly" class="version">
    <div v-if="readonly" class="row">
      <div class="col s12">This {{ entityType }} has been deleted, and is in readonly mode.</div>
    </div>
    <template v-if="version">
      <div class="row">
        <div class="col s12">
          <div>You are watching version: {{ version }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <button class="btn return float-right" @click="navigate">Back to current version</button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Version',
  props: {
    streamId: String,
    version: Number,
    viewName: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    entityType: {
      type: String,
      default: 'entity',
    },
  },
  mounted() {
    if (this.version || this.readonly) {
      this.disableEditLinks();
    }
  },
  methods: {
    disableEditLinks() {
      document.querySelectorAll('.edit-link a').forEach((el) => el.classList.add('disabled'));
    },
    enableEditLinks() {
      document.querySelectorAll('.edit-link a').forEach((el) => el.classList.remove('disabled'));
    },
    navigate() {
      this.$router.push({
        name: this.viewName,
        query: { id: this.streamId },
      });
    },
    beforeRouteLeave(to, from, next) {
      if (this.version || this.readonly) {
        this.enableEditLinks();
      }
      next();
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.version {
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: var(--orange);
}
</style>
