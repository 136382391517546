<template>
  <div>
    <div class="row card">
      <div class="class col s12">
        <dl>
          <dt>Total reserved amount</dt>
          <dd>
            {{ $filters.formatAmountThousandSeparator(reserveRelease.stats.reservedAmount, 'SEK') }}
          </dd>
          <dt>Total released amount</dt>
          <dd>
            {{ $filters.formatAmountThousandSeparator(reserveRelease.stats.releasedAmount, 'SEK') }}
          </dd>
          <dt>Remainder</dt>
          <dd>
            {{ $filters.formatAmountThousandSeparator(reserveRelease.stats.remainder, 'SEK') }}
          </dd>
          <dt>Number of payees</dt>
          <dd>{{ $filters.formatNumber(reserveRelease.stats.numPayees) }}</dd>
        </dl>

        <dl>
          <dt>Created by</dt>
          <dd>
            <span>{{ reserveRelease.header.createdBy }}&#32;</span>
            <span>{{ $filters.formatDate(reserveRelease.header.createdDate) }}</span>
          </dd>
          <dt>Confirmed by</dt>
          <dd>
            <span>{{ reserveRelease.header.confirmedBy || '-' }}&#32;</span>
            <span>{{ $filters.formatDate(reserveRelease.header.releaseDate) }}</span>
          </dd>
        </dl>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <div class="float-right">
          <action-buttons submit-label="Back to reserves" :show-abort="false" @save="onSubmit()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reserveReleaseState } from '../../domain/releaseOfReservesDomain';
import ActionButtons from '../ui/button/action-buttons';

export default {
  name: 'ProgressReleaseOfReservesConfirmed',
  components: {
    ActionButtons,
  },
  props: {
    reserveRelease: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reserveReleaseState,
    };
  },
  methods: {
    onSubmit() {
      this.$router.push({
        path: '/reserves/history',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
dl {
  max-width: 400px;
}
</style>
