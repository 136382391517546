<template>
  <div ref="contextMenu" class="context-menu">
    <a :class="{ disabled }" @click.stop="onMenuClick">
      <i class="fas fa-ellipsis-v" />
    </a>
    <div v-if="isVisible" class="context-menu__options">
      <ul>
        <li
          v-for="(option, index) in options"
          :key="index"
          :class="`context-menu__option--${option.toLowerCase()}`"
        >
          <a
            class="nowrap"
            :class="{ disabled: disabledOptions.includes(option) }"
            @click.once="onOptionClick(option)"
          >
            {{ option }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ContextMenu',
  props: {
    options: {
      type: Array,
      required: true,
    },
    disabledOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isVisible: false,
      disabled: false,
    };
  },
  created() {
    if (this.options.length === 0) {
      this.disabled = true;
    }
  },
  methods: {
    addEventListeners() {
      window.addEventListener('mouseup', this.handleEvents);
      window.addEventListener('keyup', this.handleEvents);
    },
    removeEventListeners() {
      window.removeEventListener('mouseup', this.handleEvents);
      window.removeEventListener('keyup ', this.handleEvents);
    },
    handleEvents(e) {
      if (e.keyCode === 27) {
        // Escape
        this.hideOptions();
        return;
      }
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path.includes(this.$refs.contextMenu)) {
        this.hideOptions();
      }
    },
    onMenuClick() {
      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        this.addEventListeners();
      } else {
        this.hideOptions();
      }
    },
    hideOptions() {
      this.isVisible = false;
      this.removeEventListeners();
    },
    onOptionClick(option) {
      this.isVisible = false;
      this.$emit(_.camelCase(option));
    },
  },
};
</script>

<style lang="scss">
.context-menu {
  user-select: none;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;

  a {
    color: var(--black);
    display: flex;
    height: 24px;
    justify-content: center;
    align-items: center;
    width: 24px;
    border-radius: 2px;

    &:hover {
      background-color: var(--list-hover-blue--light);
    }
  }

  &[disabled] a {
    color: var(--text--disabled);
  }

  .disabled {
    color: var(--text--disabled);
  }

  &__options {
    position: absolute;
    top: 32px;
    right: 0;
    z-index: 1000;
    background: var(--white);
    min-width: 150px;
    box-shadow:
      0 2px 4px 0 rgba(0, 0, 0, 0.2),
      0 0 2px 0 rgba(0, 0, 0, 0.12);
    padding: 16px 0 24px;

    ul {
      margin: 0;
    }

    a {
      padding: 5px 24px 5px 16px;
      display: block;
      color: var(--black);
      height: 100%;
      width: 100%;
    }
  }
}
</style>
