import gql from 'graphql-tag';

const downloadFile = `
  id
  society
  territory
  state
  file_id
  process_id
  error
  active
  deleted
  merged
  created
  user
  progress
`;

const getDownloadFiles = gql`
  query getDownloadFiles($request: DownloadFileRequest!) {
    getDownloadFiles(request: $request) {
      total
      items {
        ${downloadFile}
      }
    }
  }
`;

const getDownloadRightHolders = gql`
  query getDownloadRightHolders($request: DownloadRightHoldersRequest!) {
    getDownloadRightHolders(request: $request) {
      file {
        ${downloadFile}
      }
      total
      items {
        stream_id
        name
        status
      }
    }
  } 
`;

const createDownloadFiles = gql`
  mutation createDownloadFiles($command: DownloadFileOptions!) {
    createDownloadFiles(command: $command) {
      ${downloadFile}
    }
  }
`;

export default {
  getDownloadFiles,
  createDownloadFiles,
  getDownloadRightHolders,
};
