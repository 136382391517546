<template>
  <div>
    <div class="row card">
      <div class="class col s12">
        <dl>
          <dt>Total amount</dt>
          <dd>
            {{ $filters.formatAmountCent(distributionClosure.header.totalAmount, 'SEK') }}
          </dd>
        </dl>

        <p>
          <i>
            Created by {{ distributionClosure.header.createdBy }}
            {{ $filters.formatDateTime(distributionClosure.header.createdDate) }}
          </i>
          <template v-if="distributionClosure.header.state === distributionClosureState.Confirmed">
            <br />
            <i>
              Confirmed by {{ distributionClosure.header.confirmedBy }}
              {{ $filters.formatDateTime(distributionClosure.header.confirmedDate) }}
            </i>
          </template>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <div class="float-right">
          <action-buttons
            submit-label="Back to distribution closures"
            :show-abort="false"
            @save="onSubmit()"
          />
        </div>
      </div>
    </div>
    <distribution-closure-information class="row" :distribution-closure="distributionClosure" />
  </div>
</template>

<script>
import { distributionClosureState } from '@/domain/distributionClosureDomain';
import ActionButtons from '../../ui/button/action-buttons';
import DistributionClosureInformation from '@/components/distribution/distribution-closure/distribution-closure-information';

export default {
  name: 'ProgressDistributionClosureConfirmed',
  components: {
    ActionButtons,
    DistributionClosureInformation,
  },
  props: {
    distributionClosure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      distributionClosureState,
    };
  },
  methods: {
    onSubmit() {
      this.$router.push({
        name: 'distributionClosureHistory',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
dl {
  max-width: 400px;
}
</style>
