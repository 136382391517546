<template>
  <div :name="name">
    <div class="row">
      <div class="col s8">
        <validated-text-input
          v-model="foreignAccountData.account_number"
          name="foreign_account.account_number"
          placeholder="E.g. 1234567890"
          label="Account number"
          scope="payment-information"
          :rule="accountNumberRule"
          @input="emit"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s8">
        <validated-text-input
          v-model="foreignAccountData.account_holder"
          name="foreign_account.account_holder"
          placeholder="E.g. Michael Caine"
          label="Account holder"
          scope="payment-information"
          @input="emit"
        />
      </div>
    </div>
    <div class="row">
      <div class="col s8">
        <validated-text-input
          v-model="foreignAccountData.iban_number"
          name="foreign_account.iban_number"
          scope="payment-information"
          label="IBAN"
          placeholder="E.g. SE01010101010010101"
          :rule="ibanRule"
          @input="emit"
        />
        <a
          class="float-right read-more"
          target="_blank"
          tabindex="-1"
          href="https://webapp.sebgroup.com/mb/mblib.nsf/a-w/EEAB3C269D47245AC1257B890029BEEA/$FILE/valutaguide.pdf"
        >
          <i class="fas fa-external-link-alt" />
          More information about IBAN
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col s8">
        <validated-text-input
          v-model="foreignAccountData.bic_swift_number"
          name="foreign_account.bic_swift_number"
          scope="payment-information"
          label="BIC/Swift"
          css-props="text-transform:uppercase"
          placeholder="E.g. SE01010101010010101"
          :rule="bicSwiftNumberRule"
          @input="updateBicSwiftNumber"
        />
        <a
          class="float-right read-more"
          target="_blank"
          tabindex="-1"
          href="https://webapp.sebgroup.com/mb/mblib.nsf/a-w/EEAB3C269D47245AC1257B890029BEEA/$FILE/valutaguide.pdf"
        >
          <i class="fas fa-external-link-alt" />
          More information about BIC/SWIFT
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col s4">
        <validated-text-input
          v-model="foreignAccountData.routing_number"
          name="foreign_account.routing_number"
          scope="payment-information"
          label="Routing number"
          :placeholder="routingNumberPlaceholder"
          :rule="routingNumberRule"
          @input="updateRoutingNumber"
        />
      </div>
      <div class="col s4">
        <select-country
          v-model="foreignAccountData.routing_code"
          label="Country"
          :mandatory="foreignAccountData.routing_number !== ''"
          :input-countries="inputCountries"
          item-key="routing_code"
          :disabled="!foreignAccountData.routing_number"
          scope="payment-information"
          :rule="foreignAccountData.routing_number !== '' ? 'required' : ''"
          @input="emit"
        />
      </div>
    </div>
    <div class="row flush--bottom">
      <div class="col s8">
        <label>Additional bank information</label>
        <textarea
          v-model="foreignAccountData.meta_data"
          @change="emit"
          @input="autoHeight"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import clone from '../../../common/clone';
import { textareaAutoheight } from '../../../domain/common';
import ValidatedTextInput from '../../ui/input/validated-text-input';
import SelectCountry from '../../ui/select/select-country';

export default {
  name: 'ForeignAccountInput',
  components: {
    ValidatedTextInput,
    SelectCountry,
  },
  inject: ['$validator'],
  props: {
    name: String,
    label: { type: String, default: '' },
    foreignAccount: Object,
    editMode: { type: Boolean },
  },
  data() {
    return {
      vuexWatchHandles: [],
      foreignAccountData: clone(this.foreignAccount),
      inputCountries: [],
      routingNumberPlaceholder: 'E.g. 123456',
    };
  },
  computed: {
    bicSwiftNumberRule() {
      if (
        this.foreignAccountData.account_number ||
        this.foreignAccountData.account_holder ||
        this.foreignAccountData.iban_number ||
        this.foreignAccountData.bic_swift_number ||
        this.foreignAccountData.routing_number
      ) {
        return { required: true, bicswiftnumber: true };
      }
      return {};
    },
    accountNumberRule() {
      if (this.foreignAccountData.iban_number) {
        return { accountNumberRestriction: true };
      }
      if (
        this.foreignAccountData.routing_number ||
        this.foreignAccountData.account_holder ||
        (this.foreignAccountData.bic_swift_number && !this.foreignAccountData.iban_number)
      ) {
        return { required: true };
      }
      return {};
    },
    ibanRule() {
      if (this.foreignAccountData.account_number || this.foreignAccountData.routing_number) {
        return { ibanRestriction: true };
      }
      if (
        this.foreignAccountData.account_holder ||
        (this.foreignAccountData.bic_swift_number && !this.foreignAccountData.account_number)
      ) {
        return { required: true };
      }
      return { iban: true };
    },
    routingNumberRule() {
      if (this.foreignAccountData.iban_number) {
        return { routingNumberRestriction: true };
      }
      if (!this.foreignAccountData.routing_code) {
        return {};
      }
      return this.foreignAccountData.routing_code === 'USABA' ||
        this.foreignAccountData.routing_code === 'CACPA'
        ? { routingnumber_long: true }
        : { routingnumber_short: true };
    },
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    foreignAccount() {
      this.foreignAccountData = clone(this.foreignAccount);
    },
  },
  created() {
    this.inputCountries = this.countries.filter((country) => !_.isEmpty(country.routing_code));
  },
  methods: {
    updateRoutingNumber(value) {
      if (value === '') {
        this.foreignAccountData.routing_code = '';
      }
      this.emit();
    },
    updateBicSwiftNumber() {
      this.foreignAccountData.bic_swift_number =
        this.foreignAccountData.bic_swift_number.toUpperCase();
      this.emit();
    },
    emit() {
      this.$emit('input', this.foreignAccountData);
    },
    autoHeight(e) {
      textareaAutoheight(e);
    },
  },
};
</script>
