import store from '../store/index';
import { createError } from '../domain/starNotification';

// ERRORS

const ERROR_DISPLAY_TIMEOUT = 30000;

function errorExists(error) {
  return (
    store.getters['starNotifications/starErrors'].find(
      (storeError) => storeError.type === error.type,
    ) !== undefined
  );
}

export function removeStarError(error) {
  store.commit('starNotifications/removeFromStarErrors', error);
}

export function addStarError(_error) {
  const error = typeof _error === 'string' ? createError(_error) : _error;
  if (errorExists(error)) {
    return;
  }

  const errors = error.errors && error.errors.length ? error.errors : [error];

  errors.forEach((error) => {
    store.commit('starNotifications/addToStarErrors', error);
    setTimeout(() => removeStarError(error), ERROR_DISPLAY_TIMEOUT);
  });
}

function defer() {
  let res;
  let rej;

  const promise = new Promise((resolve, reject) => {
    res = resolve;
    rej = reject;
  });

  promise.resolve = res;
  promise.reject = rej;

  return promise;
}

// CONFIRM MODAL/DIALOG

export async function starUserConfirmDialog(show, message, submitLabel) {
  if (!show) {
    store.commit('starNotifications/updateModal', {
      show,
    });
  }

  // Confirm dialog will cancel or continue based on the resolvement of this promise
  const confirmPromise = defer();

  store.commit('starNotifications/updateModal', {
    show,
    message,
    submitLabel,
    confirmPromise,
  });

  return confirmPromise.then(() => true).catch(() => false);
}

// LOADING STATE

function updateStarLoadingState(newState) {
  store.commit(
    'starNotifications/updateLoadingState',
    Object.assign({}, store.getters['starNotifications/loadingState'], newState),
  );
}

export function starContentLoading(bool) {
  if (bool === undefined) {
    throw new Error('starContentLoading() argument is required');
  }
  updateStarLoadingState({ content: bool });
}
