<template>
  <div v-if="results.length > 0" class="quick-search__wrapper">
    <div
      class="quick-search__result-caption"
      :class="[results[0].type, !showHeader ? 'quick-search__result-header' : '']"
    >
      <template v-if="label === 'Latest Visited'">
        <i class="fas fa-history type-icon" />
        <span class="quick-search__result-heading">
          {{ label }}
        </span>
      </template>
      <template v-else>
        <entity-icon class="entity-icon" :entity="results[0].type" />
        <span class="quick-search__result-heading">{{ label }}</span>
      </template>
    </div>
    <div class="quick-search__result">
      <ul class="quick-search__result-list">
        <li
          v-for="result in results"
          :ref="result.type + '_' + result.id"
          :key="result.id"
          :class="{
            selected: selectedResult && selectedResult.id === result.id,
            'quick-search__result-list-item': true,
          }"
          @mouseover.prevent="emitSelectedResult(result)"
          @mousedown.left.prevent="emitShowSelected()"
        >
          <p>{{ resultDisplayName(result) }}</p>
          <p :class="{ capitalize: shouldCapitalize(result) }">
            {{ resultType(result) }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { AGGREGATE_TYPES } from '../../domain/common';
import Templates from '../ui/input/searchable-input-templates';
import { formatCountry, toTitleCase } from '../../filters';
import EntityIcon from '../ui/entity-icon';

export default {
  name: 'QuickSearchResult',
  components: {
    EntityIcon,
  },
  props: {
    results: { type: Array },
    label: { type: String, default: '' },
    selectedResult: { type: Object },
    query: { type: String },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    selectedResult(value) {
      if (value) {
        const ref = this.$refs[`${value.type}_${value.id}`]
          ? this.$refs[`${value.type}_${value.id}`][0]
          : null;
        if (ref) {
          if (ref.scrollIntoViewIfNeeded) {
            // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoViewIfNeeded
            ref.scrollIntoViewIfNeeded(false);
          } else {
            ref.scrollIntoView(false);
          }
        }
      }
    },
  },
  methods: {
    shouldCapitalize(result) {
      return [AGGREGATE_TYPES.ASSOCIATE, AGGREGATE_TYPES.PERFORMER].includes(result.type);
    },
    resultTemplate(result) {
      return result ? Templates[result.type] : this.results[0].type;
    },
    resultDisplayName(result) {
      if (result.type === AGGREGATE_TYPES.PERFORMER) {
        return `${result.first_name || ''} ${result.last_name || ''}`;
      } else if (
        [
          AGGREGATE_TYPES.RECORDING,
          AGGREGATE_TYPES.MAINARTIST,
          AGGREGATE_TYPES.ALBUM,
          AGGREGATE_TYPES.ASSOCIATE,
          AGGREGATE_TYPES.SOCIETY,
        ].includes(result.type)
      ) {
        return result.name || '';
      }
      return '';
    },
    resultType(result) {
      switch (result.type) {
        case AGGREGATE_TYPES.RECORDING:
        case AGGREGATE_TYPES.ALBUM:
          return result.main_artist ? result.main_artist.name : '';
        case AGGREGATE_TYPES.MAINARTIST:
          return result.origin_country ? formatCountry(result.origin_country) : '';
        case AGGREGATE_TYPES.PERFORMER:
          return result.ipn || '';
        case AGGREGATE_TYPES.ASSOCIATE:
          return result.associate_type ? toTitleCase(result.associate_type) : '';
        case AGGREGATE_TYPES.SOCIETY:
          return this.getCountryFromResult(result.contact_info);
        default:
          return '';
      }
    },
    getValue(result, entry) {
      if (entry.field === 'name') {
        return this.resultDisplayName(result);
      }
      const value = _.get(result, entry.field, '-');
      if (_.isArray(value)) {
        if (entry.translationPrefix) {
          const translated = [];
          value.forEach((v) =>
            translated.push(this.$filters.translate(`${entry.translationPrefix}.${v}`)),
          );
          return translated.join(', ');
        }
        return value.join(', ');
      }
      return entry.translationPrefix
        ? this.$filters.translate(`${entry.translationPrefix}.${value}`)
        : value;
    },
    getCountryFromResult(contactInfo) {
      if (!contactInfo || !contactInfo.address || !contactInfo.address.country) return '';
      return formatCountry(contactInfo.address.country);
    },
    emitSelectedResult(result) {
      this.$emit('selected', result);
    },
    emitShowSelected() {
      this.$emit('clicked');
    },
  },
};
</script>

<style>
.entity-icon {
  display: inline-block;
  width: var(--spacing-triple);
  color: var(--green);
  font-size: 15px;
  vertical-align: top;
}
</style>
