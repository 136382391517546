<script>
import _ from 'lodash';
import DebouncedSearchMixin from '../../common/debouncedSearchMixin';

export default {
  name: 'DataTableMixin',
  mixins: [DebouncedSearchMixin(350, 'pushSearch')],
  data() {
    return {
      loading: true,
      showOptionsId: -1,
      baseData: [],
      filterQuery: '',
      showPagination: true,
      sortTerms: {
        param: 'name',
        order: 'asc',
      },
      defaultHitsPerPage: 25,
      searchContext: {
        query: '',
        isExecuted: false,
        result: {
          totalHits: 0,
          results: [],
        },
      },
      pagination: {
        hitsPerPage: this.defaultHitsPerPage,
        page: 1,
      },
      columns: [],
      enableSort: true,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.searchContext.result.totalHits / this.pagination.hitsPerPage);
    },
    firstHitIndexOnCurrentPage() {
      if (this.searchContext.result.totalHits === 0) {
        return 0;
      }
      return Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage + 1;
    },
    lastHitIndexOnCurrentPage() {
      const totalHits = this.searchContext.result.totalHits;
      const lastHitIndex =
        Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage +
        this.pagination.hitsPerPage;
      return lastHitIndex < totalHits ? lastHitIndex : totalHits;
    },
    filterFields() {
      return this.columns
        .filter((column) => column.filterable)
        .map((filterColumn) => filterColumn.sortName);
    },
  },
  watch: {
    async $route() {
      this.updateRouterSearchParams();
      await this.reloadBaseData();
      this.search(this.filterQuery);
    },
  },
  async created() {
    this.updateRouterSearchParams();
    await this.reloadBaseData();
    this.search(this.filterQuery);
  },
  methods: {
    selectPage(selectedPage) {
      this.pagination.page = selectedPage;
      this.pushSearch();
    },
    updateHitsPerPage(value) {
      this.pagination.hitsPerPage = Number(value);
      this.resetPages();
      this.pushSearch();
    },
    updateRouterCustomSearchParams() {
      // No-op
    },
    routeParams(base) {
      return base;
    },
    routeQuery(base) {
      return base;
    },
    updateRouterSearchParams() {
      this.updateRouterCustomSearchParams();
      this.sortTerms.param = this.$router.currentRoute.query.sortParam
        ? this.$router.currentRoute.query.sortParam
        : this.sortTerms.param;
      this.sortTerms.order = this.$router.currentRoute.query.sortOrder
        ? this.$router.currentRoute.query.sortOrder
        : this.sortTerms.order;
      this.filterQuery = this.$router.currentRoute.query.filter
        ? this.$router.currentRoute.query.filter
        : '';
      if (this.showPagination) {
        this.pagination.page = this.$router.currentRoute.query.page
          ? Number(this.$router.currentRoute.query.page)
          : 1;
        this.pagination.hitsPerPage = this.$router.currentRoute.query.hits
          ? Number(this.$router.currentRoute.query.hits)
          : this.defaultHitsPerPage;
      }
    },
    pushSearch(replace = false) {
      const queryParams = {
        i: new Date().getTime(),
      };
      if (this.enableSort && this.sortTerms.param) {
        queryParams.sortParam = this.sortTerms.param;
      }
      if (this.enableSort && this.sortTerms.order) {
        queryParams.sortOrder = this.sortTerms.order;
      }
      if (this.filterQuery) {
        queryParams.filter = this.filterQuery;
      }
      if (this.showPagination) {
        if (this.pagination.hitsPerPage) {
          queryParams.hits = this.pagination.hitsPerPage;
        }
        if (this.pagination.page) {
          queryParams.page = this.pagination.page;
        }
      }
      const route = {
        name: this.viewRouteName,
        query: this.routeQuery(queryParams),
        params: this.routeParams({}),
      };
      if (replace) {
        this.$router.replace(route);
      } else {
        this.$router.push(route);
      }
    },
    clearFilter() {
      this.filterQuery = null;
      this.filterSearch();
    },
    filterSearch(terms) {
      this.filterQuery = terms;
      this.debouncedSearch(terms);
    },
    async search(terms = null) {
      this.loading = true;
      this.searchContext.query = terms;
      const searchTerm = new RegExp(terms, 'i');
      let searchResult = null;
      if (!terms) {
        searchResult = this.baseData;
      } else {
        searchResult = this.baseData.filter(
          (data) =>
            Object.values(_.pick(data, this.filterFields)).join(' ').search(searchTerm) > -1,
        );
      }
      this.searchContext.result.totalHits = searchResult.length;
      const sortedResult = _.orderBy(searchResult, this.sortTerms.param, this.sortTerms.order);
      const pageFromIndex = Math.max(0, this.pagination.page - 1) * this.pagination.hitsPerPage;
      if (this.showPagination && sortedResult.length > pageFromIndex) {
        this.searchContext.result.results = sortedResult.slice(
          pageFromIndex,
          pageFromIndex + this.pagination.hitsPerPage,
        );
      } else {
        this.searchContext.result.results = sortedResult;
      }
      this.searchContext.isExecuted = true;
      this.loading = false;
    },
    resetPages() {
      this.pagination.page = 1;
    },
    sort(column) {
      this.sortTerms.param = column.sortName;
      // Handle both bulk-table and local table sorts.
      if ('ascending' in column) {
        column.order = column.ascending ? 'desc' : 'asc';
      } else {
        column.order = column.order === 'desc' ? 'asc' : 'desc';
      }
      this.sortTerms.order = column.order;
      this.columns.forEach((c) => {
        c.active = false;
      });
      column.active = true;
      this.pushSearch();
    },
    sorted(column) {
      this.sortTerms.param = column.sortName;
      this.sortTerms.order = column.order;
      this.pushSearch();
    },
  },
};
</script>
