import VueRouter from 'vue-router';

import AuthenticationComp from '@/components/authentication/authentication';
import AuthenticationService from '@/services/authenticationService';

import { PROPS, getConfigValue } from '@/services/configService';

import store from '@/store';

const authenticationRouteName = 'authentication';

export const routes = [
  {
    path: '/auth',
    name: authenticationRouteName,
    component: AuthenticationComp,
    meta: {
      title: 'Login to Star',
    },
  },
];

export async function routerBeforeEach(to, from, next) {
  if (to.name === authenticationRouteName) {
    // short circuit as the user is currently trying to authenticate
    return next();
  }

  try {
    const valid = await AuthenticationService.hasValidSession();

    if (valid) {
      if (from === VueRouter.START_LOCATION || from.name === authenticationRouteName) {
        store.dispatch('process/loadFromStorage');
        store.dispatch('appdata/init', {});
        store.dispatch('user/loginComplete');
      }
      next();
    } else {
      next({
        path: '/auth',
        query: { redirect: to.fullPath },
      });
    }
  } catch (err) {
    console.log(err);
    next({
      path: '/auth',
      query: { redirect: to.fullPath },
    });
  }
}

export function userHasAccessToRoute(user, route) {
  function userIsAuthorized() {
    const routeAccessGroups = route.meta.accessGroups || [];

    if (routeAccessGroups.length === 0) {
      return true;
    }

    const userIsAnyGroup = routeAccessGroups.some((group) => {
      switch (group) {
        case 'developers':
          return user.developer;
        case 'testers':
          return user.tester;
        default:
          throw new Error(`Access group '${group}' is not configured`);
      }
    });

    return userIsAnyGroup;
  }

  function routeExistsInEnvironment() {
    const environment = getConfigValue(PROPS.ENVIRONMENT);
    const environments = route.meta.environments || [];

    if (environments.length === 0 || environment === 'local') {
      return true;
    }

    return environments.includes(environment);
  }

  if (!route.meta) {
    return true;
  }

  return routeExistsInEnvironment() && userIsAuthorized();
}
