<template>
  <div>
    <spinner v-if="loading"></spinner>

    <div class="row wrapper">
      <div class="col s6">
        <h1>US Tax Forms</h1>
      </div>
    </div>

    <div class="row">
      <div class="col s12">
        <h2>Create Expiring Tax Form File</h2>
      </div>
    </div>

    <div class="row card">
      <template v-if="!initiated">
        <div class="col s2">
          <select-year
            label="Expiry Year"
            :years="years"
            :empty-select-option="false"
            @input="year = $event"
          />
        </div>
        <div class="col s2">
          <br />
          <button class="btn" :disabled="!year" @click="createFile()">Create file</button>
        </div>
      </template>

      <div v-else class="col s12">File is being created, check back here in a few minutes.</div>
    </div>

    <div class="row">
      <div class="col s12">
        <h2>Files</h2>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <DataTable :columns="columns" :items="files">
          <template #item:filename="{ item }">
            <a @click="downloadFile(item)">
              <i class="far fa-file-alt" />
              Download
              {{ item.filename }}
            </a>
          </template>
          <template #item:year="{ item }">
            {{ item.metadata.year }}
          </template>
          <template #item:user="{ item }">
            {{ item.metadata.user }}
          </template>
          <template #item:lastModified="{ item }">
            {{ $filters.formatTimestamp(item.lastModified) }}
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import RemoteFileService from '../../services/remoteFileService';
import DataTable from '../ui/table/DataTable';
import Spinner from '../spinner';
import DownloadLink from '../ui/file/downloadLink';
import SelectYear from '../ui/select/select-year';
import PnrService from '../../services/pnrService';

export default {
  name: 'ViewUsTaxForms',
  components: {
    DataTable,
    Spinner,
    SelectYear,
  },
  data() {
    return {
      loading: false,
      initiated: false,
      files: [],
      columns: [
        { key: 'filename', text: 'Name' },
        { key: 'year', text: 'Expiry Year' },
        { key: 'lastModified', text: 'Created' },
        { key: 'user', text: 'User' },
      ],
      years: [],
      year: undefined,
    };
  },
  created() {
    this.year = new Date().getFullYear();
    this.years = Array(10)
      .fill(null)
      .map((_, index) => this.year - index);
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;

      try {
        const files = await RemoteFileService.listDocumentRepository('reports/us-tax-form');
        this.files = files.sort((a, b) => b.lastModified - a.lastModified);
      } catch (err) {
        this.$addStarError(err);
      } finally {
        this.loading = false;
      }
    },
    async createFile() {
      this.initiated = true;
      this.loading = true;

      try {
        await PnrService.postJob({
          type: 'GenerateUsTaxFormFile',
          payload: { year: this.year },
        });
      } catch (err) {
        this.initiated = false;
        this.$addStarError(err);
      } finally {
        this.loading = false;
      }
    },
    async downloadFile(file) {
      const url = `${file.path}/${file.filename}`;
      const fileData = await RemoteFileService.downloadFromDocumentRepository(url);
      DownloadLink.direct(fileData, url);
    },
  },
};
</script>

<style></style>
