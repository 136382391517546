<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h1>Payments for {{ society.name }}</h1>
      </div>
    </div>
    <div class="row card">
      <div class="col s12">
        <payment-history-list :society-id="id" />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentHistoryList from '../../payment/payment-history-list.vue';
export default {
  components: {
    PaymentHistoryList,
  },
  props: {
    id: String,
  },
  data() {
    return {};
  },
  computed: {
    society() {
      return this.$store.state.appdata.societies.find((s) => s.id === this.id);
    },
  },
};
</script>
