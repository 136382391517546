/**
 * The Associate Service contains associate related methods, such as CRUD.
 */
import * as uuid from 'uuid';
import moment from 'moment';
import { query } from './apolloRequest';
import {
  createAssociateAggregate,
  createAssociateAggregateCommand,
  createUpdateBasicInfoCommand,
  createUpdateChildAssociationsCommand,
  createRemoveChildAssociationsCommand,
  createUpdateMetaInfoCommand,
  createUpdatePaymentsBlockedCommand,
  createConfirmMembershipCommand,
  AssociateType,
} from '../domain/associateDomain';
import gql from '../domain/associateGql';
import clone from '../common/clone';
import { createUpdatePaymentInfoCommand } from '../domain/commonPayment';
import {
  AGGREGATE_TYPES,
  createAssociation,
  createUpdateAssociatesCommand,
  createUpdateContactInfoCommand,
  createUpdateTaxInfoCommand,
  createRemoveAssociatesCommand,
  createDocumentsCommand,
} from '../domain/common';
import { flattenLocalCodes } from '../common/aggregateUtils';
import { getConfigValue, PROPS } from './configService';
import merge from '../common/objectMerger';
import post from './postRequest';
import prepareForWire from './wirePrepper';
import { preparePhoneNumbers } from './wirePrepperUtils';
import traverseApply from '../common/traverseApplicator';

const ASSOCIATES_URL = `${getConfigValue(PROPS.PNR_URL)}/associates`;

function addCommandIds(command, aggregate) {
  command.version = aggregate.version;
  command.stream_id = aggregate.id;
  command.process_id = uuid.v4();
  command.timestamp = new Date();
}

function processAssociateAggregate(associateAggregate) {
  // Merge fetched associate with the known domain model, retain known domain model fields and omit unknown on fetched associate.
  const mergedAssociateAggregate = merge(createAssociateAggregate(), associateAggregate);
  // Convert zulu utc to readable
  return traverseApply(
    mergedAssociateAggregate,
    (key) => ['date_of_birth', 'date_of_death'].includes(key),
    (key, fieldValue) =>
      fieldValue ? moment.utc(fieldValue).local().format('YYYY-MM-DD') : fieldValue,
  );
}

function prepareAssociates(aggregate) {
  if (aggregate.associates.filter((a) => a._new).length > 1) {
    // even though the backend can handle it...
    throw new Error('There should only be one new associate in aggregate.');
  }
  const associates = [];
  aggregate.associates.forEach((a) => {
    if (!a.id) {
      return;
    }
    const obj = {};

    obj.associate = createAssociation(
      a.id,
      a.row_id,
      a.type,
      a.access_policy,
      a.start_date,
      a.end_date,
    );

    if (a._new ? a.is_recipient_of_money : a.share) {
      obj.payment_receiver = {
        share: a.share,
      };
    }

    associates.push(obj);
  });

  aggregate.associates = associates;
  return aggregate;
}

function isActive(startDate, endDate) {
  return moment().isBetween(startDate, !endDate ? moment().endOf('day') : endDate);
}

function hasStatementOfIncome(associate) {
  // only companies and heirs can have statement of income
  if (associate.type === AssociateType.COMPANY || associate.type === AssociateType.HEIR) {
    const childAssociations = associate.child_associations;
    if (childAssociations.length > 0) {
      const activeChildAssociates = childAssociations.filter((ass) =>
        isActive(ass.start_date, ass.end_date),
      );

      // if NO active child association, this company or heir should NOT have statement of income
      if (activeChildAssociates.length === 0) {
        return false;
        // if active child association and this is a company, it should have statement of income
      } else if (associate.type === AssociateType.COMPANY) {
        return true;
      }
      // if active child association and this is an heir, check if the heir has any active companies. If so this
      // company should NOT have statement of income.
      const associates = associate.associates;
      if (
        associates.length > 0 &&
        associates.filter(
          (ass) => ass.type === AssociateType.COMPANY && isActive(ass.start_date, ass.end_date),
        ).length > 0
      ) {
        return false;
      }
      return true;
    }
  }
  return false;
}

function processChildAssociations(aggregate) {
  aggregate.child_associations = aggregate.child_associations
    .filter((a) => a.id)
    .map((a) => {
      const obj = createAssociation(
        a.id,
        a.row_id,
        a.type,
        a.access_policy,
        a.start_date,
        a.end_date,
      );

      if (a._new ? a.is_recipient_of_money : a.share) {
        obj.payment_receiver = {
          share: a.share,
        };
      }
      return obj;
    });
  return aggregate;
}

function preparePayload(aggregate, payloadProperty, commandTemplate) {
  addCommandIds(commandTemplate, aggregate);
  commandTemplate.payload = merge(
    commandTemplate.payload,
    payloadProperty ? aggregate[payloadProperty] : aggregate,
  );
  preparePhoneNumbers(commandTemplate.payload);
  prepareForWire(commandTemplate.payload);
  flattenLocalCodes(commandTemplate.payload);
  return commandTemplate;
}

function prepareUpdatePaymentsBlocked(payload, aggregateId, commandTemplate) {
  commandTemplate.stream_id = aggregateId;
  commandTemplate.process_id = aggregateId;
  commandTemplate.timestamp = new Date();
  commandTemplate.payload = payload;
  delete commandTemplate.version;
  return commandTemplate;
}

function prepareUpdateAssociatesPayload(payload, aggregateId, commandTemplate) {
  commandTemplate.stream_id = aggregateId;
  commandTemplate.process_id = aggregateId;
  commandTemplate.timestamp = new Date();
  commandTemplate.payload = payload;
  delete commandTemplate.version;
  // Clear undefs and nulls
  return traverseApply(
    commandTemplate,
    (key, fieldValue) => !fieldValue,
    (key, field, leaf) => delete leaf[key],
    false,
  );
}

function prepareUpdateChildrenPayload(aggregate, payload, commandTemplate) {
  commandTemplate.stream_id = aggregate.id;
  commandTemplate.process_id = aggregate.id;
  commandTemplate.timestamp = new Date();
  commandTemplate.payload = payload;
  delete commandTemplate.version;
  delete commandTemplate.payload.index;
  commandTemplate.payload.type = aggregate.child_associations.find(
    (ca) => ca.id === payload.id,
  ).type;
  // Clear undefs and nulls
  return traverseApply(
    commandTemplate,
    (key, fieldValue) => !fieldValue,
    (key, field, leaf) => delete leaf[key],
    false,
  );
}

function preparePaymentInfo(paymentInfo, aggregateId, commandTemplate) {
  commandTemplate.stream_id = aggregateId;
  commandTemplate.process_id = aggregateId;
  commandTemplate.timestamp = new Date();
  commandTemplate.payload = paymentInfo;
  delete commandTemplate.version;
  prepareForWire(commandTemplate.payload);
  return commandTemplate;
}

function createAssociateCommand(associate) {
  const createCommand = createAssociateAggregateCommand(uuid.v4());
  flattenLocalCodes(associate);

  createCommand.payload.associate = {
    type: associate.type,
    person_info: associate.person_info,
    organisation_info: associate.organisation_info,
    contact_info: associate.contact_info,
    payment_info: associate.payment_info,
    meta_info: associate.meta_info,
    local_codes: associate.local_codes,
    associates: associate.associates,
  };

  prepareAssociates(createCommand.payload.associate);
  createCommand.payload.child_associations = processChildAssociations(associate).child_associations;
  createCommand.payload.associate.tax_info = {
    ...associate.tax_info,
    income_statement: hasStatementOfIncome(associate),
  };

  preparePhoneNumbers(createCommand);
  prepareForWire(createCommand);
  return createCommand;
}

function sortChildren(list) {
  return list.sort((a, b) => {
    // push new associates to the end of the array
    if (a._new) {
      return 1;
    } else if (b._new) {
      return -1;
    }

    const aEnd = Date.parse(a.end_date);
    const bEnd = Date.parse(b.end_date);

    // if neither has an end date sort by name
    if (!aEnd && !bEnd) {
      return a.name.localeCompare(b.name) > 0 ? 1 : -1;
    }
    // push missing end dates to the beginning of the array
    if (!bEnd) return 1;
    if (!aEnd) return -1;

    // sort by name
    return a.name.localeCompare(b.name) > 0 ? 1 : -1;
  });
}

export default {
  createAssociate: async (associate) =>
    post(`${ASSOCIATES_URL}/creation`, createAssociateCommand(associate), { pickProp: 'id' }),

  getAssociate: async (idTerm, versionTerm) => {
    const aggregate = await query(
      { query: gql.getAssociate, variables: { idTerm, versionTerm } },
      { pickProp: 'associateAggregate' },
    );
    return processAssociateAggregate(clone(aggregate));
  },
  checkOrgNo: async (orgNo, type, streamId) =>
    query(
      { query: gql.checkUniqueOrgNo, variables: { orgNo, type, streamId } },
      { pickProp: 'checkUniqueOrgNo' },
    ),
  fetchChildAssociates: async (idTerm, numChildAssociations) => {
    const childrenPromises = [];
    const limit = 100;
    for (let i = 0; i <= numChildAssociations + limit; i += limit) {
      childrenPromises.push(
        query(
          { query: gql.fetchChildAssociates, variables: { idTerm, pageFrom: i, limit } },
          { pickProp: 'fetchChildAssociates' },
        ),
      );
    }
    const result = await Promise.all(childrenPromises);
    const allChildren = result.reduce((arr, elem) => [...arr, ...elem.child_associations], []);
    return {
      child_associations: sortChildren(allChildren),
    };
  },
  updateBasicInfo: async (associateAggregate) =>
    post(
      `${ASSOCIATES_URL}/basic-info`,
      preparePayload(associateAggregate, null, createUpdateBasicInfoCommand()),
      { pickProp: 'id' },
    ),

  updateContactInfo: async (associateAggregate) =>
    post(
      `${ASSOCIATES_URL}/contact-info`,
      preparePayload(
        associateAggregate,
        'contact_info',
        createUpdateContactInfoCommand(AGGREGATE_TYPES.ASSOCIATE),
      ),
      { pickProp: 'id' },
    ),

  updatePaymentInfo: async (paymentInfo, aggregateId) =>
    post(
      `${ASSOCIATES_URL}/payment-info`,
      preparePaymentInfo(
        paymentInfo,
        aggregateId,
        createUpdatePaymentInfoCommand(AGGREGATE_TYPES.ASSOCIATE),
      ),
      { pickProp: 'id' },
    ),

  updateTaxInfo: async (associateAggregate) =>
    post(
      `${ASSOCIATES_URL}/tax-info`,
      preparePayload(
        associateAggregate,
        'tax_info',
        createUpdateTaxInfoCommand(AGGREGATE_TYPES.ASSOCIATE),
      ),
      { pickProp: 'id' },
    ),

  updateAssociateAssociates: async (
    associateAggregate, // update associates
  ) =>
    post(
      `${ASSOCIATES_URL}/associates`,
      preparePayload(
        prepareAssociates(associateAggregate),
        null,
        createUpdateAssociatesCommand('associate'),
      ),
      { pickProp: 'id' },
    ),

  updateChildAssociations: async (aggregate, associateId) =>
    post(
      `${ASSOCIATES_URL}/children`,
      preparePayload(
        processChildAssociations(aggregate, [associateId]),
        null,
        createUpdateChildAssociationsCommand(),
      ),
      { pickProp: 'id' },
    ),

  removeChildAssociation: async (aggregate, payload) =>
    post(
      `${ASSOCIATES_URL}/children`,
      prepareUpdateChildrenPayload(aggregate, payload, createRemoveChildAssociationsCommand()),
      { pickProp: 'id' },
    ),

  updateAssociate: async (
    associate,
    aggregateId, // update the entity itself
  ) =>
    post(
      `${ASSOCIATES_URL}/associates`,
      prepareUpdateAssociatesPayload(
        associate,
        aggregateId,
        createUpdateAssociatesCommand('associate'),
      ),
      { pickProp: 'id' },
    ),

  updateMetaInfo: (associateAggregate) =>
    post(
      `${ASSOCIATES_URL}/meta-info`,
      preparePayload(associateAggregate, 'meta_info', createUpdateMetaInfoCommand()),
      { pickProp: 'id' },
    ),

  removeAssociate: async (associateId, rowId, aggregateId) =>
    post(
      `${ASSOCIATES_URL}/associates`,
      prepareUpdateAssociatesPayload(
        { id: associateId, row_id: rowId },
        aggregateId,
        createRemoveAssociatesCommand('associate'),
      ),
      { pickProp: 'id' },
    ),

  updateAssociatePaymentsBlocked: (data, aggregateId) =>
    post(
      `${ASSOCIATES_URL}/system-state/payments-blocked`,
      prepareUpdatePaymentsBlocked(data, aggregateId, createUpdatePaymentsBlockedCommand()),
    ),

  confirmMembership: (data, aggregateId) =>
    post(
      `${ASSOCIATES_URL}/membership/confirm`,
      prepareUpdateAssociatesPayload(data, aggregateId, createConfirmMembershipCommand()),
    ),

  updateDocuments: (documents, aggregateId) =>
    post(
      `${ASSOCIATES_URL}/documents`,
      prepareUpdateAssociatesPayload(
        documents,
        aggregateId,
        createDocumentsCommand(AGGREGATE_TYPES.ASSOCIATE),
      ),
      { pickProp: 'id' },
    ),
};
