<template>
  <div>
    <div :style="`height: ${pusherHeight}px`" />
    <div ref="genericErrors" class="generic__errors">
      <div
        v-for="error in starErrors"
        :key="error.timestamp"
        class="row generic__errors-row"
        :class="typeClass(error)"
      >
        <div class="col s11">
          <p class="push--left">
            <i class="fas fa-exclamation-circle" />
            <span>
              <strong>{{ error.title || 'Something went wrong' }}</strong>
            </span>
            <span v-if="getErrorMessage(error)">: {{ getErrorMessage(error) }}</span>
            <b v-if="requiresReload(error)" class="reload">
              - please reload the page and try again
            </b>
          </p>
        </div>
        <div class="col s1">
          <p class="float-right push--right">
            <a @click="removeFromStarErrors(error)">
              <i class="fas fa-times cursor-pointer" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ErrorType } from '../../domain/starNotification';

export default {
  name: 'GenericError',
  data() {
    return {
      pusherHeight: 0,
    };
  },
  computed: {
    ...mapGetters('starNotifications', ['starErrors']),
  },
  watch: {
    starErrors() {
      this.$nextTick(() => {
        this.setPusherHeight();
      });
    },
  },
  mounted() {
    this.setPusherHeight();
  },
  methods: {
    getErrorMessage(error) {
      const errorMessageMap = {
        'pnr:400.100': 'Your changes have not been saved because someone made changes before you',
      };

      return errorMessageMap[error.errorCode] || error.message;
    },
    requiresReload(error) {
      const errorCodes = ['pnr:400.100'];

      return errorCodes.includes(error.errorCode);
    },

    typeClass(error) {
      if (error.errorCode) {
        switch (error.errorCode) {
          case 'pnr:400.100':
            return 'generic__errors-row--warning';
        }
      }

      switch (error.type) {
        case ErrorType.PROCESS_FINISHED:
          return 'generic__errors-row--success';
        default:
          return 'generic__errors-row--error';
      }
    },
    setPusherHeight() {
      this.pusherHeight = this.$refs.genericErrors ? this.$refs.genericErrors.clientHeight : 0;
    },
    ...mapMutations('starNotifications', ['removeFromStarErrors']),
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.generic {
  &__errors {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9003;
    white-space: pre-wrap;

    &-pusher {
      height: 0;
    }

    &-row {
      font-size: 12px;
      margin-bottom: 0;

      a {
        color: var(--white);
      }

      i {
        font-size: 16px;
        margin-top: -3px;
        margin-right: 5px;
      }

      &--error {
        background-color: var(--alert--error);
        color: var(--white);
      }

      &--warning {
        background-color: var(--alert--warning);
        color: var(--black);
      }

      &--success {
        background-color: var(--alert--success);
        color: var(--white);
      }
    }
  }
}
</style>
