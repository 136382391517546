<template>
  <div>
    <label v-show="showLabel" class="label" :class="{ 'label--error': showErrorMessage }">
      {{ label }}
      <span v-if="mandatory" class="mandatory">(mandatory)</span>
    </label>
    <div v-for="(value, index) in values" :key="index" class="row small-inner">
      <div class="col s12 hard--sides">
        <input
          ref="inputField"
          v-validate="rule"
          autocomplete="off"
          :data-vv-as="label.toLowerCase()"
          :name="`${name}.${index}`"
          :value="value"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="updateValue(index, $event.target.value)"
          @keydown.enter.prevent
        />
        <span v-show="showErrorMessage" class="help is-danger">
          <i class="fas fa-times-circle" />
          {{ validationErrors.first(`${name}.${index}`, scope) }}
        </span>
      </div>
      <delete-button v-if="values.length > 1" @click="onRemove(index)" />
    </div>

    <div class="row flush--bottom edit-link">
      <a :class="{ disabled: !lastHasEntry }" @click="addValue">
        <i class="far fa-plus-square" />
        Add {{ label.toLowerCase() }}
      </a>
    </div>
  </div>
</template>

<script>
import DeleteButton from '../button/delete-button';

export default {
  name: 'AddableTextInput',
  components: {
    DeleteButton,
  },
  inject: ['$validator'],
  props: {
    name: { type: String, default: '' },
    scope: { type: String, default: undefined },
    label: { type: String, default: '' },
    rule: { type: [String, Object], default: '' },
    placeholder: { type: String, default: '' },
    values: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    mandatory: {
      type: Boolean,
      default: false,
    },
    showLabel: { type: Boolean, default: true },
  },
  computed: {
    showErrorMessage() {
      for (let idx = 0; idx < this.values.length; idx++) {
        if (this.validationErrors.has(`${this.name}.${idx}`, this.scope)) {
          return true;
        }
      }
      return false;
    },
    lastHasEntry() {
      const fieldName = `${this.name}.${this.values.length - 1}`;
      const field =
        this.scope && this.validationFields && this.validationFields[`$${this.scope}`]
          ? this.validationFields[`$${this.scope}`][fieldName]
          : this.validationFields[fieldName];
      const lastEntry = this.values[this.values.length - 1];
      if (field) {
        return lastEntry !== '' && field.valid;
      }
      return false;
    },
  },
  methods: {
    addValue(index) {
      const lastValue = this.values.slice(-1)[0];
      if (lastValue !== '') {
        this.$emit('add', index);
      }
    },
    updateValue(index, value) {
      this.$emit('input', { index, value });
    },
    onRemove(index) {
      this.$emit('remove', index);
    },
  },
};
</script>
