<template>
  <div>
    <div class="row card">
      <div class="class col s12">
        <dl class="stats">
          <dt>Paid amount</dt>
          <dd>
            {{ $filters.formatAmountCent(payment.payableAmount + payment.payableVat, 'SEK') }}
          </dd>
          <dt>Paid VAT</dt>
          <dd>{{ $filters.formatAmountCent(payment.payableVat, 'SEK') }}</dd>
          <dt>Refunded amount</dt>
          <dd>{{ $filters.formatAmountCent(totalRefunded, 'SEK') }}</dd>
          <dt>Refunded VAT</dt>
          <dd>{{ $filters.formatAmountCent(totalRefundedVat, 'SEK') }}</dd>
          <dt>Total amount</dt>
          <dd>
            {{
              $filters.formatAmountCent(
                payment.payableAmount + payment.payableVat - totalRefunded,
                'SEK',
              )
            }}
          </dd>
          <dt>Total number of performers</dt>
          <dd>{{ payment.numPerformers }}</dd>
          <dt>Total number of payees</dt>
          <dd>{{ payment.numPayees }}</dd>
        </dl>
        <p>
          <i>
            Created by {{ payment.createdBy }}
            {{ $filters.formatDateTime(payment.createdAt) }}
          </i>
        </p>
        <p>
          <i>
            Confirmed by {{ payment.confirmedBy }}
            {{ $filters.formatDateTime(payment.confirmedAt) }}
          </i>
        </p>
        <br />
        <p>Attachments:</p>
        <div v-for="(file, idx) in payment.files" :key="idx">
          <a :href="file.link" :download="file.name">
            <i class="fas fa-download"></i>
            {{ file.name }}
          </a>
          <span>({{ getFileType(file) }})</span>
        </div>
      </div>
    </div>
    <div v-if="!isMigrated" class="row card">
      <payment-node-list :payment="payment" default-tab="accepted" />
    </div>
    <div class="row">
      <div class="col s12">
        <div class="float-right">
          <router-link to="/payments">
            <button class="btn">Back to Payments</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fileType, paymentState, paymentType } from '../../domain/paymentDomain';
import DownloadLink from '../ui/file/downloadLink';
import RemoteFileService from '../../services/remoteFileService';
import PaymentNodeList from './payment-node-list';
import { query } from '../../services/apolloRequest';
import gql from 'graphql-tag';

export default {
  name: 'ProgressPaymentConfirmed',
  components: {
    PaymentNodeList,
  },
  props: {
    payment: Object,
  },
  data() {
    return {
      paymentState,
      refunds: [],
    };
  },
  computed: {
    totalRefunded() {
      return this.refunds.reduce((sum, refund) => sum + refund.net + refund.vat, 0);
    },
    totalRefundedVat() {
      return this.refunds.reduce((sum, refund) => sum + refund.vat, 0);
    },
    isMigrated() {
      return this.payment.type !== paymentType.Default;
    },
  },
  created() {
    this.fetchRefunds();
  },
  methods: {
    async fetchRefunds() {
      const { refunds } = await query({
        query: gql`
          query {
            refunds(query: { paymentId: "${this.payment.id}" }) {
              id
              net
              vat
              nodes {
                nodeId
              }
            }
          }
        `,
      });
      this.refunds = refunds;
    },
    async downloadBankfile(key) {
      try {
        const fileData = await RemoteFileService.downloadFromDocumentRepository(key);
        DownloadLink.direct(fileData, key);
      } catch (e) {
        console.log("Couldn't download", e);
      }
    },
    async downloadAccountingfile(key) {
      try {
        const fileData = await RemoteFileService.downloadFromDocumentRepository(key);
        DownloadLink.direct(fileData, key);
      } catch (e) {
        console.log("Couldn't download", e);
      }
    },
    onSubmit() {
      this.$router.push({
        path: '/payments/history',
      });
    },
    getFileType(file) {
      return fileType[file.type] || 'Unknown';
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.stats {
  display: block;
}
.stats dt {
  float: left;
  min-width: 13rem;
}
.stats {
  line-height: 2;
}
</style>
