<template>
  <form data-vv-scope="create-musical-membership-information">
    <div>
      <div class="row">
        <div class="col s12">
          <h2>Member Information</h2>
        </div>
      </div>

      <div class="card">
        <div class="row">
          <div class="col s8">
            <searchable-tag-with-columns-input
              name="membership_info.mainartist"
              label="Mainartist"
              placeholder="E.g. Kent"
              use-template="mainartist"
              scope="create-musical-membership-information"
              :value-list="mainArtists"
              :searcher="searchMainArtist"
              @add="addMainArtist"
              @remove="removeMainArtist"
            />
          </div>
        </div>

        <div class="row">
          <div class="col s4">
            <select-instrument
              label="Main instrument"
              name="membership_info.main_instrument"
              :value="mainInstrument"
              @input="_updateMainInstrument"
            />
          </div>

          <div class="col s4">
            <select-instrument
              name="membership_info.secondary_instruments"
              label="Secondary instruments"
              :value="secondaryInstruments"
              :addable="true"
              @input="updateSecondaryInstruments"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { createMainArtistRelation } from '../../../../domain/performerDomain';
import SearchableTagWithColumnsInput from '../../../ui/tag/searchable-tag-with-columns-input';
import SearchHelper from '../../../search/searchHelper';
import SelectInstrument from '../../../ui/select/select-instrument';

export default {
  name: 'CreateMemberInformation',
  components: {
    SelectInstrument,
    SearchableTagWithColumnsInput,
  },
  inject: ['$validator'],
  data() {
    return {
      mainArtists: [],
    };
  },
  computed: {
    ...mapState('performer', {
      mainArtistsNew: (state) => state.mainartist_info.new_mainartists,
      mainInstrument: (state) => state.membership_info.main_instrument,
      secondaryInstruments: (state) => state.membership_info.secondary_instruments,
    }),
  },
  methods: {
    ...mapMutations('performer', [
      'updateMainInstrument',
      'addMainArtistNew',
      'removeMainArtistNewAt',
      'updateSecondaryInstruments',
    ]),
    async searchMainArtist(name) {
      const res = await SearchHelper.advancedSearchMainArtists(name);
      return res.results;
    },
    _updateMainInstrument(value) {
      this.updateMainInstrument(value);
      this.mainArtistsNew.forEach((relation) => (relation.instrument = value));
    },
    addMainArtist(event) {
      this.mainArtists = event.data;
      this.addMainArtistNew(createMainArtistRelation(event.item.id, this.mainInstrument));
    },
    removeMainArtist(event) {
      this.mainArtists = event.data;
      this.removeMainArtistNewAt(event.idx);
    },
  },
};
</script>
