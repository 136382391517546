<template>
  <form>
    <div class="row">
      <div class="col s6">
        <h2>Tax Information</h2>
      </div>
      <div class="text--right col s6">
        <action-buttons @save="save" @cancel="cancel" />
      </div>
    </div>

    <TaxInformationInputs
      :vat-number.sync="localValue.tax_info.vat_number"
      :domicile.sync="localValue.tax_info.tax_domicile"
      :tin.sync="localValue.tax_info.tin"
      :scope="scope"
    ></TaxInformationInputs>
  </form>
</template>

<script>
import * as _ from 'lodash';
import { createTaxInformation } from '@/domain/common';
import ActionButtons from '@/components/ui/button/action-buttons';

import TaxInformationInputs from '@/components/ui/tax-info/tax-information-inputs';

export default {
  components: {
    ActionButtons,
    TaxInformationInputs,
  },

  props: {
    entity: {
      type: Object,
      default: () => {},
    },
    scope: {
      type: String,
      default: 'tax_info',
    },
  },
  data() {
    return {
      localValue: _.cloneDeep(this.entity),
    };
  },
  computed: {
    countries() {
      return this.$store.state.appdata.referenceData.countries;
    },
  },
  watch: {
    entity(e) {
      this.localValue = e;
    },
  },
  created() {
    if (!this.entity.tax_info) {
      this.localValue.tax_info = createTaxInformation();
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel', this.$options.name);
    },
    save() {
      this.$emit('save', this.localValue);
    },
  },
};
</script>
