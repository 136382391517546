<template>
  <div class="card">
    <div class="row">
      <div class="col s8">
        <ssn-input
          name="general_info.social_security_number"
          label="Social security number"
          :scope="scope"
          :value="social_security_number"
          :validation-config="validationConfig"
          @input="updateSocialSecurityNumber"
        />
      </div>
      <div class="col s4">
        <button class="white-text btn" disabled="true">Search person</button>
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <input
          id="general_info.protected_identity"
          name="general_info.protected_identity"
          type="checkbox"
          :value="protected_identity"
          class="filled-in"
          @change="updateProtectedIdentity($event.target.checked)"
        />
        <label for="general_info.protected_identity">Protected identity</label>
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          name="general_info.ipn"
          label="IPN"
          placeholder="12345678"
          :scope="scope"
          :value="ipn"
          rule="alpha_num|ipn"
          @input="updateIpn"
        />
      </div>
      <div class="col s4">
        <button
          class="white-text btn"
          :disabled="!ipnValid"
          @click.prevent="$emit('searchIpd', ipn)"
        >
          Search IPD
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          ref="firstName"
          name="general_info.first_name"
          :mandatory="true"
          label="First name"
          placeholder="E.g. Joakim"
          :value="first_name"
          :scope="scope"
          :rule="firstAndLastNameRules"
          @input="_updateFirstName"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <validated-text-input
          ref="lastName"
          name="general_info.last_name"
          label="Last name"
          :mandatory="true"
          :scope="scope"
          placeholder="E.g. Berg"
          :value="last_name"
          :rule="firstAndLastNameRules"
          @input="_updateLastName"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <tag-input
          ref="pseudoNames"
          name="general_info.pseudo_names"
          label="Pseudo names"
          :mandatory="true"
          :scope="scope"
          placeholder="E.g. Jocke Berg"
          :value-list="pseudo_names"
          :rule="pseudoNameRules"
          @add="_addPseudoName"
          @remove="removePseudoName"
        />
      </div>
    </div>

    <div class="row">
      <div class="col s8">
        <tag-input
          name="general_info.name_variations"
          label="Name variations"
          placeholder="E.g. Joacim Berg"
          :value-list="name_variations"
          @add="addNameVariation"
          @remove="removeNameVariationAt"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';
import { createLocalCode } from '../../../../domain/common';
import SsnInput from '../../../ui/input/ssn-input';
import TagInput from '../../../ui/tag/tag-input';
import ValidatedTextInput from '../../../ui/input/validated-text-input';
import { ssnUnique } from '../../ssn-input-validator';

export default {
  name: 'CreateGeneralInformation',
  components: {
    SsnInput,
    TagInput,
    ValidatedTextInput,
  },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      validationConfig: Object.assign({}, { ssnUnique }),
    };
  },
  computed: {
    ...mapState('performer', {
      social_security_number: (state) => state.general_info.social_security_number,
      protected_identity: (state) => state.general_info.protected_identity,
      ipn: (state) => state.membership_info.ipn,
      first_name: (state) => state.general_info.first_name,
      last_name: (state) => state.general_info.last_name,
      pseudo_names: (state) => state.general_info.pseudo_names,
      name_variations: (state) => state.general_info.name_variations,
      local_codes: (state) => state.general_info.local_codes,
    }),
    firstAndLastNameRules() {
      return { required: _.isEmpty(this.pseudo_names), performer_name: true };
    },
    pseudoNameRules() {
      return { required: _.isEmpty(this.first_name) || _.isEmpty(this.last_name) };
    },
    ipnValid() {
      return (
        this.ipn &&
        this.validationFields &&
        this.validationFields['$create-performer'] &&
        this.validationFields['$create-performer']['general_info.ipn'] &&
        this.validationFields['$create-performer']['general_info.ipn'].valid
      );
    },
    localCodes() {
      return this.$store.state.appdata.localCodeTypes;
    },
  },
  created() {
    if (this.local_codes.length === 0) {
      this.onAddLocalCode();
    }
  },
  methods: {
    ...mapMutations('performer', [
      'updateSocialSecurityNumber',
      'updateProtectedIdentity',
      'updateIpn',
      'updateFirstName',
      'updateLastName',
      'addPseudoName',
      'addNameVariation',
      'removePseudoNameAt',
      'removeNameVariationAt',
      'addLocalCode',
      'removeLocalCodeAt',
      'replaceLocalCodeAt',
    ]),
    onUpdateLocalCode(entryUpdate) {
      this.replaceLocalCodeAt({ index: entryUpdate.index, value: entryUpdate.entry });
    },
    onAddLocalCode() {
      this.addLocalCode(createLocalCode());
    },
    _addPseudoName(pseudoName) {
      this.addPseudoName(pseudoName);
    },
    removePseudoName(idx) {
      this.removePseudoNameAt(idx);
    },
    _updateFirstName(firstName) {
      this.updateFirstName(firstName);
    },
    _updateLastName(val) {
      this.updateLastName(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  width: 100%;
  margin-top: 17px;
}
</style>
