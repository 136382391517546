<template>
  <div>
    <div class="row">
      <div class="col s6 card">
        <table>
          <thead class="cursor-pointer">
            <th v-for="column in columns" :key="column.name" class="disabled-head">
              {{ column.name }}
            </th>
          </thead>
          <tbody>
            <template v-if="statements.items.length > 0">
              <tr v-for="(statement, index) in statements.items" :key="index" class="row">
                <td>
                  <span>
                    {{ statement.year }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ statement.type }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ statement.field }}
                  </span>
                </td>
                <td class="amount-row">
                  <span>
                    {{ $filters.formatNumber(statement.amount) }}
                  </span>
                </td>
                <td class="amount-row">
                  <span>
                    {{ $filters.formatNumber(statement.vat) }}
                  </span>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="none" colspan="5">No end of year statement data</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import endOfYearStatementService from '../../services/endOfYearStatementService';

export default {
  name: 'EndOfYearStatementTable',
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      statements: {
        items: [],
      },
      columns: [
        {
          name: 'Year',
          ascending: true,
          sortParam: 'year',
          active: true,
        },
        {
          name: 'Type',
        },
        {
          name: 'Field',
          active: false,
        },
        {
          name: 'Amount',
          sortParam: 'amount',
          active: false,
          styleClass: 'amount-row',
        },
        {
          name: 'Vat',
          sortParam: 'vat',
          active: false,
          styleClass: 'amount-row',
        },
      ],
    };
  },
  async created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.statements = await endOfYearStatementService.getEndOfYearStatements(this.id);
      } catch (error) {
        console.error('error', error);
      }
    },
  },
};
</script>
