<template>
  <div class="entity-badge" :class="[entity, size]">
    <div class="aspect-ratio-fix">
      <div class="badge-wrapper">
        <entity-icon class="icon" :entity="entity" />
        <div v-if="showLabel" class="entity-badge-label">
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntityIcon from './entity-icon';

export default {
  name: 'EntityBadge',
  components: {
    EntityIcon,
  },
  props: {
    entity: String,
    size: {
      type: String,
      default: 'medium',
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    label() {
      if (this.entity === 'mainartist') {
        return 'main artist';
      }
      return this.entity;
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-badge {
  width: 80px;
  height: auto;
  background: var(--red);
  border-radius: 5%;
  max-width: 100%;
  aspect-ratio: 1 / 1;
  &.tiny {
    width: 20px;
    font-size: 0.2rem;
  }
  &.small {
    width: 60px;
  }
  &.medium {
    width: 80px;
    font-size: 0.7rem;
  }
  &.large {
    width: 120px;
    font-size: 0.9rem;
  }
  &.performer {
    background: var(--green--dark);
  }
  &.recording {
    background: var(--blue);
  }
  &.album {
    background: var(--gold);
  }
  &.mainartist {
    background: var(--green);
  }
  &.associate {
    background: var(--turquoise);
  }
  &.society {
    background: var(--orange);
  }
  &.payment,
  &.reserve,
  &.distribution {
    background: var(--grey);
  }
}
.badge-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.aspect-ratio-fix {
  width: 100%;
  padding-top: 100%;
}
.entity-badge-label {
  text-align: center;
  color: white;
  text-transform: capitalize;
}
.icon {
  font-size: 3.6em;
}
</style>
