import gql from 'graphql-tag';

const deceasedPerformerReports = gql`
  query deceasedPerformerReports {
    deceasedPerformerReports {
      name
      url
    }
  }
`;

const generateDeceasedPerformerReport = gql`
  query generateDeceasedPerformerReport($processId: String!) {
    generateDeceasedPerformerReport(processId: $processId)
  }
`;

export default {
  deceasedPerformerReports,
  generateDeceasedPerformerReport,
};
