<template>
  <router-link
    :to="'/search/everything?i=true&tags=' + tag"
    class="chip"
    :class="`chip--${tag.toLowerCase()}`"
  >
    {{ tag }}
  </router-link>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    tag: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  margin: var(--spacing-half);

  &--green {
    background-color: var(--green);
    color: var(--white);
  }
  &--red {
    background-color: var(--red);
    color: var(--white);
  }
  &--yellow {
    background-color: var(--yellow);
  }
}
</style>
