<!--
Specially made for letting incoming role code be editable
-->

<template>
  <li class="compare__list-item" :class="`compare__list--${status}`">
    <template v-if="showLeftSide">
      <div class="compare__incoming">
        <strong>{{ $t('common.' + incoming.key) }}</strong>
        <div v-if="editRoleCode">
          <SelectRoleCode v-model="selectedRoleCode" name="compare-incoming" class="float-left" />
          <ActionButtons
            class="float-right"
            @cancel="editRoleCode = false"
            @save="onSaveRoleCode"
          />
        </div>
        <span v-else class="compare__incoming-value" :class="{ none: !incoming.value }">
          {{ getFilteredValue(incoming) }}
          <a
            v-if="allowAction"
            class="float-right add-padding-right-small"
            @click="editRoleCode = true"
          >
            <i class="fa fa-edit" />
          </a>
        </span>
      </div>
    </template>
    <template v-else>
      <div v-if="!incoming.value" class="compare__incoming compare__candidate--empty none" />
    </template>
    <ActionColumn
      :action="action"
      :dirty="dirty"
      :visible="allowAction && !candidate.isEmpty"
      :status="status"
      @action="handleAction(action)"
    />
    <div v-if="candidate.isEmpty" class="compare__candidate compare__candidate--empty none">
      {{ candidate.valueWhenEmpty }}
    </div>
    <div v-else class="compare__candidate">
      <strong>{{ $t('common.' + candidate.key) }}</strong>
      <a
        v-if="candidate.link"
        class="compare__candidate-value"
        :href="candidate.link"
        v-html="getUpdatedAttributeValue(candidate)"
      />
      <div v-else class="compare__candidate-value">
        <span :class="{ none: !candidate.value }" v-html="getUpdatedAttributeValue(candidate)" />
        <span
          v-if="dirty && showOldValue"
          class="text--line-through"
          :class="{
            none: !originalCandidateValue,
            'original-value': !originalCandidateValue,
          }"
        >
          {{ getFilteredValue(candidate, true) }}
        </span>
      </div>
    </div>
  </li>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import CompareFieldsMixin from '../../common/compareFieldsMixin';
import ActionColumn from './action-column';
import CompareGridRowAsMixin from './compare-grid-row';
import SelectRoleCode from '../ui/select/select-role-code';
import ActionButtons from '../ui/button/action-buttons';

export default {
  name: 'CompareGridRowRoleCode',
  components: {
    ActionButtons,
    ActionColumn,
    SelectRoleCode,
  },
  mixins: [CompareFieldsMixin, CompareGridRowAsMixin],
  props: {
    incoming: {
      type: Object,
      default() {
        return {
          key: '',
          value: '',
        };
      },
    },
    candidate: {
      type: Object,
      default() {
        return {
          key: '',
          value: '',
        };
      },
    },
    comparator: {
      type: String,
      default: 'valuesMatchRelaxed',
    },
    allowAction: {
      type: Boolean,
      default: true,
    },
    originalValue: {
      default: undefined,
    },
    showLeftSide: {
      type: Boolean,
      default: true,
    },
    updatedAttribute: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      originalCandidateValue: '',
      dirty: false,
      editRoleCode: false,
      selectedRoleCode: '',
    };
  },
  computed: {
    ...mapGetters('matching', ['currentCandidate']),
    action() {
      if (this.incomingDataMatch() && !this.dirty) {
        return undefined;
      }
      if (_.isArray(this.candidate.value)) {
        return this.dirty ? 'removeFrom' : 'addTo';
      }
      return 'update';
    },
    status() {
      if (!this.hasValue(this.candidate.value) && !this.hasValue(this.incoming.value)) {
        return 'error';
      }
      if (!this.hasValue(this.candidate.value)) {
        return 'idle';
      }
      if (this.incomingHasAddableData()) {
        return 'warning';
      }
      if (this.incomingDataMatch()) {
        return 'ok';
      }
      return 'error';
    },
    showOldValue() {
      return _.isString(this.originalCandidateValue);
    },
  },
  watch: {
    currentCandidate(newCandidate, oldCandidate) {
      if (newCandidate.id !== oldCandidate.id) {
        this.dirty = this.updatedAttribute;
        this.setOriginalCandidateValue();
      }
    },
  },
  created() {
    this.setOriginalCandidateValue();
    this.selectedRoleCode = this.incoming.value;
    this.dirty = this.updatedAttribute;
  },
  methods: {
    onSaveRoleCode() {
      this.editRoleCode = false;
      this.dirty = true;
      this.$emit('roleCodeChange', this.selectedRoleCode);
    },
    getUpdatedAttributeValue(obj) {
      if (!this.updatedAttribute) {
        return this.getFilteredValue(obj);
      }

      return this.getFilteredValue({
        ...obj,
        value: _.isArray(this.candidate.value)
          ? [...this.candidate.value, ...this.incoming.value]
          : this.incoming.value,
      });
    },
    getFilteredValue(obj, original = false) {
      const value = original ? this.originalCandidateValue : obj.value;
      if (!value || value.length === 0) {
        return 'None';
      }
      if (!obj.displayFilter) {
        return value;
      }
      return this.$filters[obj.displayFilter](value);
    },
    handleAction(action) {
      let newValue = this.incoming.value;
      if (this.dirty && action === 'update') {
        newValue = this.originalCandidateValue;
      }
      this.$emit('valueUpdated', {
        action,
        data: {
          key: this.candidate.key,
          value: newValue,
        },
      });
      this.dirty = !this.dirty;
    },
    incomingHasAddableData() {
      if (_.isArray(this.candidate.value)) {
        return !this.arrayIncludesValueRelaxed(this.candidate.value, this.incoming.value);
      }
      return !this.hasValue(this.candidate.value) && this.hasValue(this.incoming.value);
    },
    incomingDataMatch() {
      if (!this.hasValue(this.incoming.value)) {
        return true;
      }
      if (
        _.isArray(this.candidate.value) &&
        this.arrayIncludesValueRelaxed(this.candidate.value, this.incoming.value)
      ) {
        return true;
      } else if (this[this.comparator](this.incoming.value, this.candidate.value)) {
        return true;
      }
      return false;
    },
    setOriginalCandidateValue() {
      this.$nextTick(() => {
        this.originalCandidateValue = this.originalValue || this.candidate.value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.compare__incoming {
  align-items: center;
  > div {
    margin-left: -80px;
    @media only screen and (min-width: 1500px) {
      margin-left: 0;
    }
  }
  .compare__incoming-value {
    padding-top: 0;
  }
}
</style>
