<template>
  <select-input
    :label="label"
    :inline-label="inlineLabel"
    item-key="key"
    item-value="name"
    :name="name"
    :value="value"
    :items="types"
    :rule="rule"
    :scope="scope"
    :disabled="disabled"
    :empty-select-option="emptyOption"
    @input="onInput"
  />
</template>

<script>
import SelectInput from '../../ui/select/select-input';

export default {
  name: 'SelectGeneric',
  components: { SelectInput },
  props: {
    label: {
      type: String,
      default: '',
    },
    inlineLabel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    emptyOption: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      required: false,
    },
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    scope: {
      type: String,
      default: undefined,
    },
    rule: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    types() {
      return this.data
        ? Object.keys(this.data).map((oKey) => ({ key: oKey, name: this.data[oKey] }))
        : [];
    },
  },
  methods: {
    onInput(key) {
      this.$emit('input', key);
    },
  },
};
</script>
