<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h1>{{ album ? 'Albums' : 'Recordings' }} Match Result for {{ report_name }}</h1>
      </div>
    </div>
    <div class="row wrapper">
      <div class="col s12">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link class="nowrap" :to="`/mediarc/${reportId}/matched?album=${album}`">
              Matched ({{
                $filters.formatNumber(
                  album ? counters.matched_album_lines : counters.matched_recording_lines,
                )
              }})
            </router-link>
            <router-link class="nowrap" :to="`/mediarc/${reportId}/candidates?album=${album}`">
              Candidates ({{
                $filters.formatNumber(
                  album ? counters.candidate_album_lines : counters.candidate_recording_lines,
                )
              }})
            </router-link>
            <router-link class="nowrap" :to="`/mediarc/${reportId}/rested?album=${album}`">
              Unmatched ({{
                $filters.formatNumber(
                  album ? counters.rested_album_lines : counters.rested_recording_lines,
                )
              }})
            </router-link>
            <router-link class="nowrap" :to="`/mediarc/${reportId}/done?album=${album}`">
              Done ({{
                $filters.formatNumber(
                  album ? counters.done_album_lines : counters.done_recording_lines,
                )
              }})
            </router-link>
            <router-link v-if="!album" class="nowrap" :to="`/mediarc/${reportId}/version-titles`">
              Version titles
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <template v-if="loading">
        <component-spinner />
      </template>
      <router-view @updated="loadHeader" />
    </keep-alive>
  </div>
</template>

<script>
import MatchingService from '../../../services/matchingService';
import TableOptionsMixin from '../../../common/tableOptionsMixin';
import ComponentSpinner from '../../../components/component-spinner';

export default {
  name: 'ViewMediarcReportLines',
  components: {
    ComponentSpinner,
  },
  mixins: [TableOptionsMixin],
  data() {
    return {
      report_name: '',
      reportId: this.$router.currentRoute.params.reportId,
      album: this.$router.currentRoute.query.album === 'true',
      counters: {
        album_count: 0,
        matched_album_lines: 0,
        candidate_album_lines: 0,
        rested_album_lines: 0,
        done_album_lines: 0,
        recording_count: 0,
        matched_recording_lines: 0,
        candidate_recording_lines: 0,
        rested_recording_lines: 0,
        done_recording_lines: 0,
      },
      loading: false,
    };
  },
  created() {
    this.loadHeader();
    this.clearFilter('ViewMediarcReportRestedLines', false);
    this.clearFilter('ViewMediarcReportCandidateLines', false);
    this.clearFilter('ViewMediarcReportMatchedLines', false);
    this.clearFilter('ViewMediarcReportDoneLines', false);
  },
  methods: {
    async loadHeader() {
      this.loading = true;
      try {
        const reportHeader = await MatchingService.getReportHeader('MAR', this.reportId);
        this.counters = reportHeader.mar_match_data;
        this.report_name = reportHeader.report_name;
      } catch (error) {
        error.title = 'Could not fetch report header';
        this.$addStarError(error);
      }
      this.loading = false;
    },
  },
};
</script>
