<template>
  <div class="e2e-recording__information">
    <div class="row">
      <div class="col s12 text--right edit-link">
        <a @click="$emit('edit', $options.name)">
          <i class="far fa-edit"></i>
          Edit
        </a>
      </div>
    </div>

    <div class="row card">
      <div class="col s12">
        <div class="row">
          <label class="col s3">Alternate names</label>
          <div class="col s9">
            <span v-for="(title, i) in basicInfoAlternateTitles" :key="i">
              {{ title + (basicInfoAlternateTitles.length - 1 > i ? ', ' : '') }}
            </span>
            <span v-if="basicInfoAlternateTitles.length === 0" class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Duration</label>
          <div class="col s9">
            <span v-if="basicInfoDurationSec">
              {{ $filters.toMinutes(basicInfoDurationSec) }}
            </span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Label</label>
          <div class="col s9">
            <span v-if="basicInfoLabel">{{ basicInfoLabel }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Produced in country</label>
          <div class="col s9">
            <span v-if="basicInfoProducedInCountry">
              {{ $filters.formatCountry(basicInfoProducedInCountry) }}
            </span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Recorded in country</label>
          <div class="col s9">
            <span v-if="basicInfoRecordedInCountry">
              {{ $filters.formatCountry(basicInfoRecordedInCountry) }}
            </span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Recording date</label>
          <div class="col s9">
            <template v-if="basicInfoRecordingDate">
              <span>
                {{ $filters.formatRecordingDate(basicInfoRecordingDate) }}
              </span>
            </template>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Release date</label>
          <div class="col s9">
            <span v-if="basicInfoReleaseDate">{{ basicInfoReleaseDate }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">ISRC</label>
          <div class="col s9">
            <span v-if="basicInfoIsrc">{{ basicInfoIsrc }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">VRDB title</label>
          <div class="col s9">
            <span v-if="basicInfoVrdb2Title">{{ basicInfoVrdb2Title }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">
            VRDB ID
            <span v-if="vrdbInfo">({{ $filters.translate(`common.${vrdbInfo.type}`) }})</span>
          </label>
          <div class="col s9">
            <span v-if="vrdbInfo">{{ vrdbInfo.id }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">VRDB RM</label>
          <div class="col s9">
            <span v-if="basicInfoVrdb2Rm">{{ basicInfoVrdb2Rm }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Genre</label>
          <div class="col s9">
            <span v-if="basicInfoGenre">{{ $filters.formatGenre(basicInfoGenre) }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Composed by</label>
          <div class="col s9">
            <span v-if="basicInfoComposer">{{ basicInfoComposer }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Written by</label>
          <div class="col s9">
            <span v-if="basicInfoWriter">{{ basicInfoWriter }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>
        <div class="row">
          <label class="col s3">Arranged by</label>
          <div class="col s9">
            <span v-if="basicInfoArranger">{{ basicInfoArranger }}</span>
            <span v-else class="none">None</span>
          </div>
        </div>

        <div class="row">
          <label class="col s3">Original source</label>
          <div class="col s9">
            <div v-if="basic_info.source_info">
              <div>{{ basicInfoSourceInfo.source }}</div>
              <div>{{ basicInfoSourceInfo.comment }}</div>
            </div>
            <span v-else class="none">None</span>
          </div>
        </div>

        <view-local-codes :local-codes="basicInfoLocalCodes" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ViewLocalCodes from '../../ui/view-local-codes';
import { UNKNOWN_PROTECTED_DATE } from '../../../domain/recordingDomain';

export default {
  name: 'BasicInformation',
  components: { ViewLocalCodes },
  data() {
    return {
      UNKNOWN_PROTECTED_DATE,
    };
  },
  computed: {
    ...mapGetters('recording', [
      'basic_info',
      'basicInfoAlternateTitles',
      'basicInfoArranger',
      'basicInfoComposer',
      'basicInfoDurationSec',
      'basicInfoGenre',
      'basicInfoIsrc',
      'basicInfoLabel',
      'basicInfoLocalCodes',
      'basicInfoMainArtist',
      'basicInfoName',
      'basicInfoProducedInCountry',
      'basicInfoRecordedInCountry',
      'basicInfoRecordingDate',
      'basicInfoReleaseDate',
      'basicInfoSourceInfo',
      'basicInfoVersionTitle',
      'basicInfoVrdb2Id',
      'basicInfoVrdb2Title',
      'basicInfoVrdb2Rm',
      'basicInfoWriter',
    ]),
    vrdbInfo() {
      if (this.basicInfoVrdb2Id && this.basicInfoVrdb2Id.length > 0) {
        return { type: 'attested', id: this.basicInfoVrdb2Id };
      }
      if (this.basicInfoLocalCodes) {
        const associated = this.basicInfoLocalCodes.find(
          (obj) => obj.code.toUpperCase() === 'VRDB2_ASSOCIATED',
        );
        const playlist = this.basicInfoLocalCodes.find(
          (obj) => obj.code.toUpperCase() === 'VRDB2_PLAYLIST_ID',
        );
        if (associated) {
          return { type: 'associated', id: associated.value };
        }
        if (playlist) {
          return { type: 'playlist', id: playlist.value };
        }
      }
      return undefined;
    },
  },
};
</script>
