import { mandateIsActive, SAMI_SOCIETY_CODE } from 'star-common';

import { strOrNull } from '../../../common/typeUtils';
import { createPerformerAggregate } from '../../../domain/performerDomain';
import { createPaymentInfo } from '../../../domain/commonPayment';

const actions = {
  createAggregate(context) {
    context.commit('SET_PERFORMER_AGGREGATE', createPerformerAggregate());
  },
  setPerformerAggregate(context, performerAggregate) {
    context.commit('SET_PERFORMER_AGGREGATE', performerAggregate);
  },
  setPerformerAggregateFromRightholder(context, rightholder) {
    context.commit('SET_PERFORMER_AGGREGATE_FROM_RIGHTHOLDER', rightholder);
  },
};

const mutations = {
  updateSocialSecurityNumber(state, socialSecurityNumber) {
    state.general_info.social_security_number = strOrNull(socialSecurityNumber);
  },
  updateProtectedIdentity(state, protectedIdentity) {
    state.general_info.protected_identity = protectedIdentity;
  },
  updateFirstName(state, firstName) {
    state.general_info.first_name = strOrNull(firstName);
  },
  updateLastName(state, lastName) {
    state.general_info.last_name = strOrNull(lastName);
  },
  addPseudoName(state, pseudoName) {
    state.general_info.pseudo_names.push(pseudoName);
  },
  removePseudoNameAt(state, idx) {
    state.general_info.pseudo_names.splice(idx, 1);
  },
  addNameVariation(state, nameVariation) {
    state.general_info.name_variations.push(nameVariation);
  },
  removeNameVariationAt(state, idx) {
    state.general_info.name_variations.splice(idx, 1);
  },
  updateProfile(state, profileText) {
    state.general_info.profile = strOrNull(profileText);
  },
  addLocalCode(state, localCode) {
    state.general_info.local_codes.push(localCode);
  },
  removeLocalCodeAt(state, idx) {
    state.general_info.local_codes.splice(idx, 1);
  },
  replaceLocalCodeAt(state, replacer) {
    state.general_info.local_codes.splice(replacer.index, 1, replacer.value);
  },
  updateDateOfBirth(state, dateOfBirth) {
    state.general_info.date_of_birth = strOrNull(dateOfBirth);
  },
  updateDateOfDeath(state, dateOfDeath) {
    state.general_info.date_of_death = strOrNull(dateOfDeath);
  },
  updateSex(state, sex) {
    state.general_info.sex = strOrNull(sex);
  },
  updateCollective(state, collective) {
    state.general_info.collective = collective;
  },
  updateCollectiveSize(state, size) {
    state.general_info.collective_size = size;
  },
  updateNationality(state, nationality) {
    state.general_info.nationality = strOrNull(nationality);
  },
  updateCountryOfResidence(state, countryOfResidence) {
    state.general_info.country_of_residence = strOrNull(countryOfResidence);
  },
  addAddress(state, address) {
    state.contact_info.address = address;
  },
  updateStreet(state, street) {
    state.contact_info.address.street = strOrNull(street);
  },
  updateCity(state, city) {
    state.contact_info.address.city = strOrNull(city);
  },
  updateProvince(state, province) {
    state.contact_info.address.province = strOrNull(province);
  },
  updatePostalCode(state, postalCode) {
    state.contact_info.address.postal_code = strOrNull(postalCode);
  },
  updateCountry(state, country) {
    state.contact_info.address.country = strOrNull(country);
  },
  updateCareOf(state, careOf) {
    state.contact_info.address.care_of = strOrNull(careOf);
  },
  addEmail(state, email) {
    state.contact_info.emails.push(email);
  },
  removeEmailAt(state, idx) {
    state.contact_info.emails.splice(idx, 1);
  },
  replaceEmailAt(state, replacer) {
    state.contact_info.emails.splice(replacer.index, 1, replacer.value);
  },
  addPhoneNumber(state, phoneNumber) {
    state.contact_info.phone_numbers.push(phoneNumber);
  },
  removePhoneNumberAt(state, idx) {
    state.contact_info.phone_numbers.splice(idx, 1);
  },
  updateMainInstrument(state, mainInstrument) {
    state.membership_info.main_instrument = mainInstrument;
  },
  updateSecondaryInstruments(state, secondaryInstruments) {
    state.membership_info.secondary_instruments = secondaryInstruments;
  },
  updateVotingRight(state, votingRight) {
    state.membership_info.voting_right = votingRight;
  },
  updateIpn(state, ipn) {
    state.membership_info.ipn = strOrNull(ipn);
  },
  updatePaymentsBlocked(state, paymentsBlocked) {
    state.system_state.payments_blocked = paymentsBlocked;
  },
  updatePaymentsBlockedReason(state, reason) {
    state.system_state.payments_blocked_reason = reason;
  },
  addForeignAccount(state, account) {
    state.payment_info.foreign_account = account;
  },
  removeForeignAccount(state) {
    delete state.payment_info.foreign_account;
  },
  addPrivateAccount(state, privateAccount) {
    state.payment_info.private_account = privateAccount;
  },
  // only for clearing company accounts imported from PAID. Performers should never get new company accounts.
  addCompanyAccount(state, companyAccount) {
    state.payment_info.company_account = companyAccount;
  },
  removePrivateAccount(state) {
    delete state.payment_info.private_account;
  },
  resetPaymentInfo(state) {
    state.payment_info = createPaymentInfo();
  },
  updateForeignAccountAccountHolder(state, accountHolder) {
    state.payment_info.foreign_account.account_holder = strOrNull(accountHolder);
  },
  updateForeignAccountIbanNumber(state, ibanNumber) {
    state.payment_info.foreign_account.iban_number = strOrNull(ibanNumber);
  },
  updateForeignAccountAccountNumber(state, accountNumber) {
    state.payment_info.foreign_account.account_number = strOrNull(accountNumber);
  },
  updateForeignAccountBicSwiftNumber(state, bicSwiftNumber) {
    state.payment_info.foreign_account.bic_swift_number = strOrNull(bicSwiftNumber);
  },
  updateForeignAccountRoutingNumber(state, routingNumber) {
    state.payment_info.foreign_account.routing_number = strOrNull(routingNumber);
  },
  updateForeignAccountRoutingCode(state, routingCode) {
    state.payment_info.foreign_account.routing_code = strOrNull(routingCode);
  },
  updatePrivateAccountBank(state, bank) {
    state.payment_info.private_account.bank = strOrNull(bank);
  },
  updatePrivateAccountClearingNumber(state, clearingNumber) {
    state.payment_info.private_account.clearing_number = strOrNull(clearingNumber);
  },
  updatePrivateAccountAccountNumber(state, accountNumber) {
    state.payment_info.private_account.account_number = strOrNull(accountNumber);
  },
  addTaxInformation(state, taxInformation) {
    state.tax_info = taxInformation;
  },
  updateTaxDomicile(state, taxDomicile) {
    state.tax_info.tax_domicile = strOrNull(taxDomicile);
  },
  updateTaxTin(state, taxTin) {
    state.tax_info.tin = strOrNull(taxTin);
  },
  updateTaxVatNumber(state, vatNumber) {
    state.tax_info.vat_number = strOrNull(vatNumber);
  },
  updateDocuments(state, documents) {
    state.documents = documents;
  },
  addMandateGroup(state, mandateGroup) {
    state.mandates.push(mandateGroup);
  },
  setMandates(state, mandates) {
    state.mandates = mandates;
  },
  addMandate(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates.push(mandateData.mandate);
  },
  removeMandateAt(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates.splice(mandateData.mandateIdx, 1);
  },
  updateSocietyCode(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates[mandateData.mandateIdx].society_code = mandateData.societyCode;
  },
  updateMandateType(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates[mandateData.mandateIdx].mandate_type = mandateData.mandateType;
  },
  updateStartDate(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates[mandateData.mandateIdx].start_date = mandateData.startDate;
  },
  updateEndDate(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates[mandateData.mandateIdx].end_date = mandateData.endDate;
  },
  addTerritory(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates[mandateData.mandateIdx].territories.push(mandateData.iso);
  },
  removeTerritoryAt(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates[mandateData.mandateIdx].territories.splice(
      mandateData.territoryIdx,
      1,
    );
  },
  clearTerritories(state, mandateData) {
    const mandateGroupToUpdate = state.mandates.find((group) => group.right === mandateData.type);
    mandateGroupToUpdate.mandates[mandateData.mandateIdx].territories = [];
  },
  addExternalSource(state, externalSource) {
    state.metadata_info.other_external_sources.push(externalSource);
  },
  removeExternalSource(state, index) {
    state.metadata_info.other_external_sources.splice(index, 1);
  },
  removeOtherExternalSource(state, idx) {
    state.metadata_info.other_external_sources.splice(idx, 1);
  },
  addTag(state, tag) {
    state.metadata_info.tags.push(tag);
  },
  removeTagAt(state, idx) {
    state.metadata_info.tags.splice(idx, 1);
  },
  addMainArtistNew(state, mainArtistId) {
    state.mainartist_info.new_mainartists.push(mainArtistId);
  },
  removeMainArtistNewAt(state, idx) {
    state.mainartist_info.new_mainartists.splice(idx, 1);
  },
  replaceMainArtistNewAt(state, replacer) {
    state.mainartist_info.new_mainartists.splice(replacer.index, 1, replacer.value);
  },
  updateAssociates(state, associates) {
    state.associates = associates;
  },
  removeFromAssociates(state, idx) {
    state.associates.splice(idx, 1);
  },
  addToAssociates(state, associate) {
    state.associates.push(associate);
  },
  replaceInAssociates(state, replacer) {
    state.associates.splice(replacer.index, 1, replacer.value);
  },
  updateContactInfo(state, contactInfo) {
    state.contact_info = contactInfo;
  },
  updateIpdState(state, ipdState) {
    state.ipd_state = ipdState;
  },

  /* eslint-enable */
  SET_PERFORMER_AGGREGATE(state, performerAggregate) {
    state.deleted = performerAggregate.deleted;
    state.deletedBy = performerAggregate.deletedBy;
    state.version = performerAggregate.version;
    state.stream_id = performerAggregate.stream_id;
    state.process_id = performerAggregate.process_id;
    state.id = performerAggregate.id;
    state.general_info = performerAggregate.general_info;
    state.contact_info = performerAggregate.contact_info;
    state.membership_info = performerAggregate.membership_info;
    state.mandates = performerAggregate.mandates;
    state.tax_info = performerAggregate.tax_info;
    state.metadata_info = performerAggregate.metadata_info;
    state.payment_info = performerAggregate.payment_info;
    state.mainartist_info = performerAggregate.mainartist_info;
    state.associates = performerAggregate.associates;
    state.main_artists = performerAggregate.main_artists;
    state.documents = performerAggregate.documents;
    state.system_state = performerAggregate.system_state;
    state.ipd_state = performerAggregate.ipd_state;
  },
  SET_PERFORMER_AGGREGATE_FROM_RIGHTHOLDER(state, rightholder) {
    state.general_info = rightholder.general_info;
    state.membership_info = rightholder.membership_info;
    state.mandates = rightholder.mandates;
  },
  SET_DELETE_AGGREGATE(state, deletedBy) {
    state.deleted = true;
    state.deletedBy = deletedBy;
  },
};

const getters = {
  id: (state) => state.id,
  version: (state) => state.version,
  socialSecurityNumber: (state) => state.general_info.social_security_number,
  protectedIdentity: (state) => state.general_info.protected_identity,
  ipn: (state) => state.membership_info.ipn,
  firstName: (state) => state.general_info.first_name,
  lastName: (state) => state.general_info.last_name,
  pseudoNames: (state) => state.general_info.pseudo_names,
  nameVariations: (state) => state.general_info.name_variations,
  localCodes: (state) => state.general_info.local_codes,
  contactInfo: (state) => state.contact_info,
  address: (state) => state.contact_info.address,
  street: (state) => (state.contact_info.address ? state.contact_info.address.street : null),
  city: (state) => (state.contact_info.address ? state.contact_info.address.city : null),
  province: (state) => (state.contact_info.address ? state.contact_info.address.province : null),
  postalCode: (state) =>
    state.contact_info.address ? state.contact_info.address.postal_code : null,
  country: (state) => (state.contact_info.address ? state.contact_info.address.country : null),
  care_of: (state) => (state.contact_info.care_of ? state.contact_info.address.care_of : null),
  emails: (state) => state.contact_info.emails,
  phoneNumbers: (state) => state.contact_info.phone_numbers,
  dateOfBirth: (state) => state.general_info.date_of_birth,
  dateOfDeath: (state) => state.general_info.date_of_death,
  sex: (state) => state.general_info.sex,
  collective: (state) => state.general_info.collective,
  collective_size: (state) => state.general_info.collective_size,
  nationality: (state) => state.general_info.nationality,
  countryOfResidence: (state) => state.general_info.country_of_residence,
  mainInstrument: (state) => state.membership_info.main_instrument,
  secondaryInstruments: (state) => state.membership_info.secondary_instruments,
  societies: (state) => state.membership_info.societies,
  votingRight: (state) => state.membership_info.voting_right,
  mandates: (state) => state.mandates,
  mainArtists: (state) => state.main_artists,
  mainArtistsNew: (state) => state.mainartist_info.new_mainartists,
  foreignAccount: (state) => (state.payment_info ? state.payment_info.foreign_account : null),
  foreignAccountAccountHolder: (state) =>
    state.payment_info.foreign_account ? state.payment_info.foreign_account.account_holder : null,
  foreignAccountIbanNumber: (state) =>
    state.payment_info.foreign_account ? state.payment_info.foreign_account.iban_number : null,
  foreignAccountAccountNumber: (state) =>
    state.payment_info.foreign_account ? state.payment_info.foreign_account.account_number : null,
  foreignAccountBicSwiftNumber: (state) =>
    state.payment_info.foreign_account ? state.payment_info.foreign_account.bic_swift_number : null,
  foreignAccountRoutingNumber: (state) =>
    state.payment_info.foreign_account ? state.payment_info.foreign_account.routing_number : null,
  foreignAccountRoutingCode: (state) =>
    state.payment_info.foreign_account ? state.payment_info.foreign_account.routing_code : null,
  privateAccount: (state) => (state.payment_info ? state.payment_info.private_account : null),
  privateAccountBank: (state) =>
    state.payment_info.private_account ? state.payment_info.private_account.bank : null,
  privateAccountClearingNumber: (state) =>
    state.payment_info.private_account ? state.payment_info.private_account.clearing_number : null,
  privateAccountAccountNumber: (state) =>
    state.payment_info.private_account ? state.payment_info.private_account.account_number : null,
  companyAccount: (state) => (state.payment_info ? state.payment_info.company_account : null),
  paymentInfo: (state) => state.payment_info,
  taxDomicile: (state) => (state.tax_info ? state.tax_info.tax_domicile : null),
  taxTin: (state) => (state.tax_info ? state.tax_info.tin : null),
  taxVatNumber: (state) => (state.tax_info ? state.tax_info.vat_number : null),
  profile: (state) => state.general_info.profile,
  otherExternalSources: (state) => state.metadata_info.other_external_sources,
  tags: (state) => state.metadata_info.tags,
  associates: (state) => state.associates,
  documents: (state) => state.documents,
  performer: (state) => state,
  aggregate: (state) => state,
  systemState: (state) => state.system_state,
  ipdState: (state) => state.ipd_state,
  hasActiveSamiMandates: (state) =>
    state.mandates
      .flatMap((group) => group.mandates)
      .some((m) => m.society_code === SAMI_SOCIETY_CODE && mandateIsActive(m)),
};

const module = {
  namespaced: true,
  state: createPerformerAggregate(),
  actions,
  mutations,
  getters,
};

export default module;
