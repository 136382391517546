<template>
  <div>
    <div class="row">
      <div v-if="loading" class="col s12">
        <spinner />
      </div>
      <div v-else class="col s12">
        <div class="row">
          <h1>
            Discography
            <span v-if="name">for {{ name }}</span>
          </h1>
        </div>

        <div class="tabs row">
          <div class="tabs__wrapper">
            <router-link :to="{ name: 'discographyPerformerRecordings', params: { id } }">
              Recordings
            </router-link>
            <router-link :to="{ name: 'discographyPerformerAlbums', params: { id } }">
              Albums
            </router-link>
          </div>
        </div>

        <router-view type="performer" />
      </div>
    </div>
  </div>
</template>

<script>
import PerformerService from '../../../services/performerService';
import Spinner from '../../spinner';

export default {
  name: 'ViewDiscography',
  components: {
    Spinner,
  },
  data() {
    return {
      id: this.$router.currentRoute.params.id,
      name: null,
      loading: true,
    };
  },
  async created() {
    this.loading = true;
    const performer = await PerformerService.getPerformer(this.id);
    this.name = `${performer.general_info.first_name} ${performer.general_info.last_name}`;
    this.loading = false;
  },
};
</script>
