<template>
  <div v-show="queryTooLong">
    <i class="far fa-frown" />
    Too long search query! I can only handle {{ lengthLimit }} characters...
  </div>
</template>

<script>
export default {
  name: 'SearchQueryTooLong',
  props: {
    lengthLimit: {
      type: Number,
    },
    queryTooLong: {
      type: Boolean,
    },
  },
};
</script>
