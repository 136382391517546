<template>
  <div>
    <div class="row wrapper-large">
      <div class="col s12">
        <h1>
          <i class="fas align-bottom left" :class="iconByTimeOfDay"></i>
          Good {{ $filters.lowercase(timeOfDay) }},
          {{ $filters.toTitleCase(realname) }}
        </h1>
      </div>
    </div>

    <div class="row wrapper">
      <div class="col s12">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link :to="{ name: 'homeViewDashboard' }">Dashboard</router-link>
            <router-link :to="{ name: 'homeViewStatistics' }">Statistics</router-link>
            <router-link :to="{ name: 'homeViewAssignedWorklists' }">
              Assigned Worklists
            </router-link>
            <router-link :to="{ name: 'homeViewPersonalWorklist' }">Personal Worklist</router-link>
            <router-link :to="{ name: 'homeViewAllWorklists' }">All Worklists</router-link>
          </div>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ViewHome',
  computed: {
    ...mapGetters('user', ['realname']),
    timeOfDay() {
      const hours = new Date().getHours();
      switch (true) {
        case hours < 5 || hours > 22:
          return 'NIGHT';
        case hours >= 5 && hours < 10:
          return 'MORNING';
        case hours >= 10 && hours < 17:
          return 'DAY';
        default:
          return 'EVENING';
      }
    },
    iconByTimeOfDay() {
      switch (this.timeOfDay) {
        case 'MORNING':
          return 'fa-coffee';
        case 'DAY':
          return 'fa-sun';
        case 'NIGHT':
          return 'fa-bed';
        case 'EVENING':
        default:
          return 'fa-moon';
      }
    },
  },
};
</script>
