<template>
  <div>
    <div class="row">
      <div class="col s12">
        <h2 class="float-left">Add Existing Albums</h2>
      </div>
    </div>

    <div class="card">
      <div v-for="(album, index) in localAlbums" :key="album._localId">
        <add-existing-album
          :name="'album_' + index"
          :album="album"
          :scope="scope"
          :deletable="localAlbums.length > 1"
          @input="updateExistingAlbum(index, $event)"
          @remove="removeAlbum(index)"
        />
      </div>
      <div class="edit-link">
        <a
          :class="{ disabled: !lastHasEntry }"
          @click="addExistingAlbum"
          @keyup.enter="addExistingAlbum"
        >
          <i class="far fa-plus-square" />
          Add album
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import * as uuid from 'uuid';
import clone from './../../../common/clone';
import { createAlbumReference } from './../../../domain/recordingDomain';
import AddExistingAlbum from './add-existing-album';

export default {
  name: 'AddExistingAlbums',
  components: { AddExistingAlbum },
  inject: ['$validator'],
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localAlbums: [],
    };
  },
  computed: {
    ...mapGetters('recording', ['albums']),
    lastHasEntry() {
      return this.albums.length === this.localAlbums.length;
    },
  },
  created() {
    this.addExistingAlbum();
  },
  methods: {
    ...mapMutations('recording', ['addToAlbums', 'removeFromAlbums', 'replaceInAlbums']),
    updateExistingAlbum(index, valueUpdate) {
      const albumIndex = this.albums.findIndex((album) => album.id === valueUpdate.id);
      if (albumIndex !== -1) {
        if (valueUpdate.name !== '') {
          const album = clone(this.albums[albumIndex]);
          album.side_no = valueUpdate.side_no;
          album.track_no = valueUpdate.track_no;
          this.replaceInAlbums({ index: albumIndex, value: album });
        } else {
          this.removeFromAlbums(albumIndex);
        }
      } else {
        const album = createAlbumReference(
          valueUpdate.id,
          valueUpdate.side_no,
          valueUpdate.track_no,
        );
        album.id = valueUpdate.id;
        album.side_no = valueUpdate.side_no;
        album.track_no = valueUpdate.track_no;
        this.addToAlbums(album);
      }
    },
    addExistingAlbum() {
      this.localAlbums.push({
        _localId: uuid.v4(),
        id: null,
        name: null,
        side_no: 1,
        track_no: 1,
      });
    },
    removeAlbum(index) {
      this.removeFromAlbums(index);
      this.localAlbums.splice(index, 1);
      if (this.localAlbums.length === 0) {
        this.addExistingAlbum();
      }
    },
  },
};
</script>
