import gql from 'graphql-tag';

const activePerformerMembershipApplications = gql`
  query activePerformerMembershipApplications($request: PerformerMembershipApplicationRequest) {
    activePerformerMembershipApplications(request: $request) {
      id
      performer {
        id
        general_info {
          first_name
          last_name
        }
        associates {
          id
          name
          type
        }
      }
      created
      type
      status
      on_hold
    }
  }
`;

export default {
  activePerformerMembershipApplications,
};
