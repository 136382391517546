import gql from 'graphql-tag';

const playedRecordingsFile = `
  id
  name
  created_at
  created_by
  start_year
  end_year
  rights
  state
  progress
  url
  error
`;

const createPlayedRecordingsFile = gql`
  mutation createPlayedRecordingsFile($command: CreatePlayedRecordingsFileCommand!) {
    createPlayedRecordingsFile(command: $command)
  }
`;

const getPlayedRecordingsFiles = gql`
  query PlayedRecordingsFiles($options: GetPlayedRecordingsFilesOptions) {
    getPlayedRecordingsFiles(options: $options) {
      items {
        id
        name
        progress
        state
        start_year
        end_year
        created_at
        rights
        url
        error
      }
      count
      total_count
    }
  }
`;

const deletePlayedRecordingsFile = gql`
  mutation DeletePlayedRecordingsFile($id: Int!) {
    deletePlayedRecordingsFile(id: $id)
  }
`;

export default {
  playedRecordingsFile,
  createPlayedRecordingsFile,
  getPlayedRecordingsFiles,
  deletePlayedRecordingsFile,
};
