<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Music Reports</h1>
      </div>
    </div>

    <upload-report />

    <reports-table />
  </div>
</template>

<script>
import UploadReport from './upload-report';
import ReportsTable from './reports-table';

export default {
  name: 'MusicReports',
  components: {
    UploadReport,
    ReportsTable,
  },
};
</script>
