<template>
  <tr class="prevent-hover-bg">
    <td></td>
    <td colspan="100%" class="expanded">
      <div class="flex">
        <div>
          <div class="expand-info__meta__title">Albums: {{ match.albums.total }}</div>
          <ul>
            <li v-for="album of sortedAlbums.slice(0, 10)" :key="album.id">
              <router-link :to="`/albums/${album.id}`">
                {{ album.name }}
              </router-link>
            </li>
            <li v-if="!albumsExpanded && match.albums.total > 10">
              <a @click="albumsExpanded = true">
                Show {{ match.albums.total - 10 }} more albums...
              </a>
            </li>
            <template v-if="albumsExpanded">
              <li v-for="album of sortedAlbums.slice(10)" :key="album.id">
                <router-link :to="`/albums/${album.id}`">
                  {{ album.name }}
                </router-link>
              </li>
              <li>
                <a @click="albumsExpanded = false">Show less albums</a>
              </li>
            </template>
          </ul>
        </div>
        <div>
          <div class="expand-info__meta__title">Recordings: {{ match.recordings.total }}</div>
          <ul>
            <li v-for="recording of sortedRecordings.slice(0, 10)" :key="recording.id">
              <router-link :to="`/recordings/${recording.id}`">
                {{ recording.name }}
              </router-link>
            </li>
            <li v-if="!recordingsExpanded && match.recordings.total > 10">
              <a @click="recordingsExpanded = true">
                Show {{ match.recordings.total - 10 }} more recordings...
              </a>
            </li>
            <template v-if="recordingsExpanded">
              <li v-for="recording of sortedRecordings.slice(10)" :key="recording.id">
                <router-link :to="`/recordings/${recording.id}`">
                  {{ recording.name }}
                </router-link>
              </li>
              <li>
                <a @click="recordingsExpanded = false">Show less recordings</a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { orderBy } from 'lodash';

export default {
  name: 'DuplicateMatchMainartist',
  props: {
    match: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      albumsExpanded: false,
      recordingsExpanded: false,
    };
  },
  computed: {
    sortedRecordings() {
      return orderBy(this.match.recordings.recordings, ['name']);
    },
    sortedAlbums() {
      return orderBy(this.match.albums.albums, ['name']);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.expanded {
  border: 1px solid #eee;
}
.flex > div {
  flex: 1;
}
</style>
