import * as uuid from 'uuid';
import { isPristine } from '../common/typeUtils';
import { createNamedRelation } from './common';

export function isEmptyPerformerReference(reference) {
  return isPristine(reference, ['_localId', 'relation']) && isPristine(reference.relation);
}

export const UNKNOWN_PROTECTED_DATE = '4000-01-01';

export function createPerformerReference(
  relation = createNamedRelation(),
  instruments = [],
  role = null,
  pseudo = null,
) {
  return {
    relation,
    instruments,
    role,
    pseudo_name: pseudo,
  };
}

export function createDuplicateReference(id, vrdbId, mergeAble, name, mainArtist) {
  return {
    id,
    vrdb_id: vrdbId,
    merge_able: mergeAble,
    name,
    main_artist: mainArtist,
  };
}

export function createAlbumReference(id, sideNo, trackNo) {
  return {
    id,
    side_no: sideNo,
    track_no: trackNo,
  };
}

export function createBasicInfo() {
  return {
    vrdb2_id: null,
    vrdb2_rm: null,
    name: null,
    version_title: null,
    alternate_titles: [],
    vrdb2_title: null,
    main_artist: null,
    recorded_in_country: null,
    recording_date: null,
    produced_in_country: null,
    release_date: null,
    genre: null,
    duration_sec: null,
    composer: null,
    writer: null,
    arranger: null,
    label: null,
    isrc: null,
    source_info: null,
    local_codes: [],
    tags: [],
    catalog_number: null,
  };
}

export function createDistributionState() {
  return {
    status: null,
    legacy_unknown_performers: null,
  };
}

export function createVrdb2State() {
  return {
    sync_state: null,
    duplicate_of: null,
    validation_error: null,
    updated_at: null,
    is_enqueued: false,
  };
}

export function createMergeAble() {
  return {
    mergeAble: true,
    message: '',
  };
}

export function createRecordingAggregate() {
  return {
    id: null,
    version: null,
    deleted: false,
    deletedBy: null,
    process_id: null,
    stream_id: uuid.v4(),
    timestamp: null,
    basic_info: createBasicInfo(),
    distribution_state: createDistributionState(),
    lineup: [],
    lineup_complete: false,
    lineup_locked: false,
    albums: [],
    new_performers: [],
    vrdb2_state: createVrdb2State(),
  };
}

export function createAlbum() {
  return {
    stream_id: uuid.v4(),
    album_name: null,
    main_artist: null,
    produced_in_country: null,
    catalog_number: null,
    release_date: null,
    side_no: null,
    track_no: null,
  };
}

export function createRecordingCommand() {
  return {
    type: 'recording:create',
    version: null,
    stream_id: uuid.v4(),
    process_id: null,
    timestamp: null,
    payload: {
      basic_info: null,
      lineup: [],
      albums: [],
      new_performers: [],
      new_albums: [],
    },
  };
}

export function createUpdateBasicInfoCommand() {
  return {
    type: 'recording:updateBasicInfo',
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      basic_info: null,
    },
  };
}

export function createPatchBasicInfoCommand() {
  return {
    type: 'recording:patchBasicInfo',
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      basic_info: null,
    },
  };
}

export function createUpdateLineupCommand() {
  return {
    type: 'recording:updateLineup',
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      lineup: [],
      new_performers: [],
    },
  };
}

export function createUpdateDistributionStateCommand() {
  return {
    type: 'recording:updateDistributionState',
    version: null,
    stream_id: null,
    process_id: null,
    timestamp: null,
    payload: {
      distribution_state: null,
    },
  };
}
