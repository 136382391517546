<template>
  <div>
    <div class="title">VRDB Repertoire To Check</div>
    <section class="widget-table">
      <header>
        <div class="widget-table--col">Society</div>
        <div class="widget-table--col amount">To Check</div>
      </header>
      <div v-for="report in payload" :key="report.society_code" class="widget-table--row">
        <div class="widget-table--col">
          <router-link :to="`/societies/${getSocietyId(report.society_code)}/vrdb/to_check`">
            {{ getSocietyName(report.society_code) }}
          </router-link>
        </div>
        <div class="widget-table--col amount">
          {{ $filters.formatNumber(report.to_check) }}
        </div>
      </div>
    </section>

    <section class="widget-link">
      <router-link to="/integrations/vrdb/repertoire">Show VRDB Repertoire</router-link>
    </section>
  </div>
</template>

<script>
import WidgetMixin from './widget-mixin';

export default {
  name: 'VrdbToCheckWidget',
  mixins: [WidgetMixin],
  computed: {
    societies() {
      return this.$store.state.appdata.societies;
    },
  },
  methods: {
    getSocietyId(code) {
      return this.societies.find((s) => s.vrdb_code === code)?.id;
    },
    getSocietyName(code) {
      return this.societies.find((s) => s.vrdb_code === code)?.name;
    },
  },
};
</script>
