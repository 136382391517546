<template>
  <div class="right-column row">
    <div class="col s12">
      <div class="tabs">
        <div class="tabs__wrapper">
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'general' }"
            @click="selectedTab = 'general'"
          >
            General
          </div>
          <div
            class="tabs__div"
            :class="{ active: selectedTab === 'activity' }"
            @click="selectedTab = 'activity'"
          >
            Activity
          </div>
        </div>
      </div>
      <div v-if="selectedTab === 'general'">
        <membership-agreement @saved="onSaved" />

        <system-state namespace="performer" @cancel="onCancel" @saved="onSaved" />

        <StarViewEditPairs
          :value="editableComponent"
          @input="$emit('update:editableComponent', $event)"
        >
          <StarViewEditPair id="Tags">
            <template #view="{ activator }">
              <tags :tags="tags" @edit="activator" />
            </template>

            <edit-tags @cancel="onCancel" @saved="onSaved" />
          </StarViewEditPair>

          <notes :id="id" />

          <StarViewEditPair id="ExternalSources">
            <template #view="{ activator }">
              <external-sources @edit="activator" />
            </template>

            <edit-external-sources @cancel="onCancel" @saved="onSaved" />
          </StarViewEditPair>
        </StarViewEditPairs>

        <div class="row small-inner">
          <div class="col s12">
            <div class="row small-inner">
              <div class="col s12">
                <h2>Active Payments</h2>
              </div>
            </div>
            <div class="row card">
              <div class="col s12">
                <active-payments :node-id="id" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="!performerDeleted" class="row">
          <div class="col s12">
            <h2>Delete Performer</h2>
            <p>
              <button class="btn red" @click="deletePerformer">
                <i class="fas fa-times" />
                <span>Delete</span>
              </button>
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <activity :stream-id="id" :version="version" view-name="performerInformationVersion" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Activity from '../../ui/activity';
import EditExternalSources from './edit/edit-external-sources';
import EditTags from './edit/edit-tags';
import ExternalSources from './view/external-sources';
import ActivePayments from '../../payment/active-payments';
import MembershipAgreement from './membership-agreement';
import Notes from '../../ui/input/notes';
import Tags from '../../tags/tags';
import SystemState from '../../ui/input/system-state';

export default {
  name: 'RightColumn',
  components: {
    Activity,
    EditExternalSources,
    EditTags,
    ExternalSources,
    ActivePayments,
    MembershipAgreement,
    Notes,
    Tags,
    SystemState,
  },
  props: {
    editableComponent: {
      type: String,
      default: '',
    },
    preSelectedTab: {
      type: String,
      default: 'general',
    },
    performerDeleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTab: this.preSelectedTab,
    };
  },
  computed: {
    ...mapGetters('performer', ['id', 'version', 'tags']),
  },
  watch: {
    preSelectedTab(tab) {
      this.selectedTab = tab;
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSaved() {
      this.$emit('saved');
    },
    isEditMode(ref) {
      return this.editableComponent === ref;
    },
    deletePerformer() {
      this.$emit('deletePerformer');
    },
  },
};
</script>
