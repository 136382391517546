<template>
  <div>
    <entity-badge entity="mainartist" />
    <div class="search-result__everything__data">
      <div>
        <span class="search-result__everything__data__name">
          <router-link :to="'/mainartists/' + typedResult.value.id">
            {{ typedResult.value.name }}
          </router-link>
        </span>
      </div>
      <div
        v-if="typedResult.value.alternate_titles && typedResult.value.alternate_titles.length > 0"
      >
        <span class="label">Also known as</span>
        <span>{{ $filters.join(typedResult.value.alternate_titles) }}</span>
      </div>
      <div
        v-if="typedResult.value.profile_description"
        :class="{
          'search-result__everything__data__profile': true,
          'search-result__everything__data__profile--full': showFullProfile,
        }"
        @click="showFullProfile = true"
      >
        {{ typedResult.value.profile_description }}
      </div>
      <div v-if="typedResult.value.origin_country">
        <span class="label">Origin country:</span>
        <span>{{ $filters.formatCountry(typedResult.value.origin_country) }}.</span>
      </div>
      <div v-if="typedResult.value.genre">
        <span class="label">Genre:</span>
        <span>{{ $filters.formatGenre(typedResult.value.genre) }}.</span>
      </div>
      <div v-if="typedResult.value.tags" class="search-result__tags chips__wrapper">
        <template v-for="(tag, index) in typedResult.value.tags">
          <tag :key="index" :tag="tag" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '../tags/tag';
import EntityBadge from '../ui/entity-badge';

export default {
  name: 'EverythingResultMainArtist',
  components: { Tag, EntityBadge },
  props: {
    typedResult: Object,
  },
  data() {
    return {
      showFullProfile: false,
    };
  },
};
</script>
