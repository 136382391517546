<template>
  <span class="entity-badge-icon">{{ fontStr }}</span>
</template>

<script>
export default {
  name: 'EntityIcon',
  props: {
    entity: String,
  },
  computed: {
    fontStr() {
      switch (this.entity) {
        case 'performer':
          return 'record_voice_over';
        case 'recording':
          return 'library_music';
        case 'album':
          return 'album';
        case 'associate':
          return 'extension';
        case 'society':
          return 'domain';
        case 'mainartist':
          return 'groups';
        case 'payment':
          return 'paid';
        case 'reserve':
          return 'restore';
        case 'distribution':
          return 'calculate';
        default:
          return 'grade';
      }
    },
  },
};
</script>

<style lang="scss">
.entity-badge-icon {
  font-family: 'Material Icons';
  color: white;
}
</style>
