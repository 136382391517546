<template>
  <div>
    <label v-if="label && !blueLink" class="label">{{ label }} {{ encoding }}</label>
    <span v-if="mandatory" class="mandatory">(mandatory)</span>
    <div v-if="!selectedFile">
      <label v-if="blueLink" :for="name">
        <a>
          <i class="fas fa-paperclip" />
          {{ label }} {{ encoding }}
        </a>
      </label>
      <label v-else :for="name" :class="{ disabled: disabled }" class="btn secondary">
        <i class="fas fa-upload" />
        Choose file...
      </label>
      <input
        :id="name"
        :ref="name"
        v-validate="rule"
        type="file"
        class="blank-style"
        placeholder="fiwoj"
        style="display: none"
        :name="name"
        :data-vv-as="name.toLowerCase()"
        :disabled="disabled"
        :accept="acceptFileType"
        @change="onAddFile"
      />
    </div>
    <div v-else>
      <i :class="iconClass" />
      {{ selectedFile.name }}
      <i class="fas fa-times close cursor-pointer" @click="removeFile" />
    </div>
    <div>
      <span v-if="validationErrors.has(name, scope)" class="help is-danger">
        <i class="fas fa-times-circle" />
        {{ validationErrors.first(name, scope) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    name: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: 'Upload document',
    },
    scope: {
      type: String,
      default: undefined,
    },
    rule: {
      type: [String, Object],
      default: '',
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    blueLink: {
      type: Boolean,
      default: false,
    },
    fileHandle: {
      type: File,
      default: () => undefined,
    },
    acceptFileType: {
      type: String,
      default: '*',
    },
  },
  data() {
    return {
      selectedFile: null,
      type: '',
      iconClass: '',
    };
  },
  computed: {
    encoding() {
      if (this.acceptFileType === '*') {
        return '';
      }
      let encoding = '';
      if (/csv|xml/.test(this.acceptFileType)) {
        encoding = ' UTF-8';
      } else if (/txt/.test(this.acceptFileType)) {
        encoding = ' ISO-8859-1';
      }
      return `(${this.acceptFileType.replace(/\./g, '')}${encoding})`;
    },
  },
  watch: {
    fileHandle(handle) {
      if (handle) {
        this.selectedFile = handle;
      }
    },
  },
  methods: {
    onAddFile(upload) {
      this.selectedFile = upload.target.files[0];
      this.$emit('addFile', this.selectedFile);
      this.type = this.selectedFile.type.split('/').pop(-1);
      switch (this.type) {
        case 'pdf':
          this.iconClass = 'far fa-file-pdf';
          break;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'svg':
          this.iconClass = 'far fa-file-image';
          break;
        case 'ppt':
          this.iconClass = 'far fa-file-powerpoint';
          break;
        case 'txt':
        case 'doc':
        case 'docx':
        case 'csv':
          this.iconClass = 'far fa-file-alt';
          break;
        case 'xls':
        case 'xlsx':
          this.iconClass = 'far fa-file-excel';
          break;
        default:
          this.iconClass = 'far fa-file';
          break;
      }
    },
    removeFile() {
      this.selectedFile = null;
      this.$emit('removeFile');
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.blank-style {
  width: 300px;
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  margin-bottom: 0;
}
</style>
