import Vue from 'vue';
import gql from '../../../domain/cronjobGql';
import { mutate, query } from '../../../services/apolloRequest';
import { getConfigValue, PROPS } from '../../../services/configService';

const CONFIG = getConfigValue(PROPS.CRONJOB);

const rootState = {
  cronjobs: [],
};

const actions = {
  async fetchCronjob(context, jobName) {
    const job = await query(
      { query: gql.getCronjob, variables: { name: jobName } },
      { pickProp: 'cronjob' },
    );
    context.commit('updateJob', job);
  },
  async fetchCronjobs(context) {
    const jobs = await query({ query: gql.getCronjobs }, { pickProp: 'cronjobs' });
    context.commit('setJobs', jobs);
  },
  async enableCronjob(context, jobName) {
    const job = await mutate(
      { mutation: gql.enableCronjob, variables: { name: jobName } },
      { pickProp: 'enableCronjob' },
    );
    context.commit('updateJob', job);
  },
  async disableCronjob(context, jobName) {
    const job = await mutate(
      { mutation: gql.disableCronjob, variables: { name: jobName } },
      { pickProp: 'disableCronjob' },
    );
    context.commit('updateJob', job);
  },
  async triggerCronjob(context, trigger) {
    const url = trigger.url.replace(CONFIG.replace, CONFIG.with);
    switch (trigger.method) {
      case 'get':
        await Vue.$http.get(url);
        break;
      case 'post':
        await Vue.$http.post(url, trigger.payload || {});
        break;
      default:
        throw new Error(`Unknown method: ${trigger.method}`);
    }
  },
};

const mutations = {
  setJobs(state, jobs) {
    state.cronjobs = jobs;
  },
  updateJob(state, job) {
    const oldJobIdx = state.cronjobs.findIndex((j) => j.name === job.name);
    if (oldJobIdx === -1) {
      state.cronjobs = [...state.cronjobs, job];
    } else {
      state.cronjobs = [
        ...state.cronjobs.slice(0, oldJobIdx),
        job,
        ...state.cronjobs.slice(oldJobIdx + 1),
      ];
    }
  },
};

const getters = {};

const module = {
  namespaced: true,
  state: rootState,
  actions,
  mutations,
  getters,
};

export default module;
