<template>
  <form data-vv-scope="edit-member-information">
    <div class="row">
      <div class="col s6">
        <h2>Member Information</h2>
      </div>
      <div class="text--right col s6 float-right">
        <action-buttons @save="onSave" @cancel="onCancel" />
      </div>
    </div>

    <div class="row card">
      <div class="row">
        <div class="col s2">Main artists</div>
        <div class="col s7">
          <searchable-tag-with-columns-input
            name="membership_info.mainartists"
            placeholder="E.g. Capt. Serenity"
            scope="edit-member-information"
            use-template="mainartist"
            :value-list="mainArtists"
            :searcher="searchMainArtist"
            @add="addMainArtist"
            @remove="removeMainArtist"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Main instrument</div>
        <div class="col s7">
          <select-instrument
            v-model="performer.membership_info.main_instrument"
            :show-label="false"
            name="membership_info.main_instrument"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">Secondary instruments</div>
        <div class="col s7">
          <select-instrument
            :show-label="false"
            name="membership_info.secondary_instruments"
            :value="performer.membership_info.secondary_instruments"
            :addable="true"
            @input="updateSecondaryInstruments"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s2">IPN</div>
        <div class="col s7">
          <protected-text-input
            v-model="performer.membership_info.ipn"
            name="membership_info.ipn"
            placeholder="E.g. 12345678"
            scope="edit-member-information"
            rule="alpha_num|ipn"
            :locked-initially="true"
            protection-help-text="Please note that a change of IPN will replace IPD-related information in Star with information from IPD at the next synchronization."
          />
        </div>
      </div>

      <div class="row flush--bottom">
        <div class="col s2">Voting right</div>
        <div class="col s7">
          <input
            id="membership_info.voting_right"
            v-model="performer.membership_info.voting_right"
            name="membership_info.voting_right"
            type="checkbox"
            class="filled-in"
          />
          <label for="membership_info.voting_right">
            <span
              v-if="performer.membership_info.voting_right && !hasActiveSamiMandates"
              class="is-danger"
            >
              This performer is not an active SAMI member
            </span>
          </label>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { createMainArtistRelation } from '../../../../domain/performerDomain';
import ActionButtons from '../../../ui/button/action-buttons';
import EditHelper from './editHelper';
import SearchableTagWithColumnsInput from '../../../ui/tag/searchable-tag-with-columns-input';
import SearchHelper from '../../../search/searchHelper';
import ProtectedTextInput from '../../../ui/input/protected-text-input';
import SelectInstrument from '../../../ui/select/select-instrument';
import delay from '@/common/delay';
import PnrService from '@/services/pnrService';
import { createPerformerRelation } from '@/domain/mainArtistDomain';
import MainArtistService from '@/services/mainArtistService';
import { createUpdateMembershipInfoCommand } from '@/domain/performerDomain';

export default {
  name: 'EditMemberInformation',
  components: {
    SelectInstrument,
    ActionButtons,
    SearchableTagWithColumnsInput,
    ProtectedTextInput,
  },
  inject: ['$validator'],
  data() {
    return {
      performer: EditHelper.getCurrentPerformer(),
      mainArtists: EditHelper.getCurrentPerformer().main_artists.slice(),
    };
  },
  computed: {
    ...mapGetters('performer', ['hasActiveSamiMandates']),
  },
  methods: {
    async searchMainArtist(name) {
      const res = await SearchHelper.advancedSearchMainArtists(name);
      return res.results;
    },
    updateSecondaryInstruments(valueUpdate) {
      this.performer.membership_info.secondary_instruments = valueUpdate;
    },
    addMainArtist(event) {
      this.mainArtists = event.data;
      if (
        !EditHelper.getCurrentPerformer().main_artists.find(
          (mainArtist) => mainArtist.id === event.item.id,
        )
      ) {
        this.performer.mainartist_info.new_mainartists.push(
          createMainArtistRelation(event.item.id, this.performer.membership_info.main_instrument),
        );
      } else {
        this.performer.mainartist_info.removed_mainartists =
          this.performer.mainartist_info.removed_mainartists.filter(
            (mainArtistId) => mainArtistId !== event.item.id,
          );
      }
    },
    removeMainArtist(event) {
      this.mainArtists = event.data;
      if (
        EditHelper.getCurrentPerformer().main_artists.find(
          (mainArtist) => mainArtist.id === event.item.id,
        )
      ) {
        this.performer.mainartist_info.removed_mainartists.push(event.item.id);
      } else {
        this.performer.mainartist_info.new_mainartists =
          this.performer.mainartist_info.new_mainartists.filter(
            (mainArtistRelation) => mainArtistRelation.mainArtistId !== event.item.id,
          );
      }
    },
    onCancel() {
      this.$emit('cancel', this.$options.name);
    },
    async onSave() {
      const commands = [];

      // updateMembershipInfo
      const updateMembershipInfoCommand = PnrService.prepareCommand(
        this.performer,
        createUpdateMembershipInfoCommand(),
        this.performer.membership_info,
      );

      commands.push(updateMembershipInfoCommand);

      // updateMainArtists
      if (this.performer.mainartist_info) {
        const addCommands = this.performer.mainartist_info.new_mainartists.map((relation) =>
          MainArtistService.createAddPerformerMainArtistCommand(
            relation.mainArtistId,
            createPerformerRelation(this.performer.id, relation.instrument),
          ),
        );

        const removeCommands = this.performer.mainartist_info.removed_mainartists.map(
          (mainArtistId) =>
            MainArtistService.createRemovePerformerMainArtistCommand(
              mainArtistId,
              this.performer.id,
            ),
        );

        commands.push(...addCommands);
        commands.push(...removeCommands);
      }

      await PnrService.postCommands(
        { errorTitle: 'Could not update member information' },
        commands,
      );
      await delay(500);
      this.$emit('saved');
    },
  },
};
</script>
