<template>
  <div v-if="memberAction !== 'IGNORE'">
    <div class="row small-inner">
      <div class="col s3">
        <h2>Membership</h2>
      </div>
    </div>
    <div v-if="memberAction === 'CONFIRM_MEMBER'" class="row small-inner">
      <div class="col s9">
        <button
          v-if="confirmMembershipState === 'BEGIN'"
          class="btn primary m2"
          @click="confirmMembershipState = 'CONFIRM'"
        >
          Resend MySAMI activation
        </button>
        <span v-if="confirmMembershipState === 'CONFIRM'">
          <strong>Really resend MySAMI activation?</strong>
          <button class="btn red m2" @click="confirmMembershipState = 'BEGIN'">No</button>
          <button class="btn primary m2" @click="onConfirmMembership()">Yes</button>
        </span>
        <span v-if="confirmMembershipState === 'COMPLETE'">MySAMI activation sent.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthenticationService from '../../services/authenticationService';
import AssociateService from '../../services/associateService';

export default {
  name: 'MembershipAgreement',
  props: {},
  data() {
    return {
      confirmMembershipState: 'BEGIN',
    };
  },
  computed: {
    ...mapGetters('associate', ['id', 'contactInfo']),
    isCompleteEmail() {
      return (
        this.contactInfo.emails &&
        this.contactInfo.emails.length > 0 &&
        this.contactInfo.emails[0] &&
        this.contactInfo.emails[0].length > 0
      );
    },
    memberAction() {
      if (this.isCompleteEmail) {
        return 'CONFIRM_MEMBER';
      }
      return 'IGNORE';
    },
  },
  methods: {
    async onConfirmMembership() {
      this.confirmMembershipState = 'COMPLETE';
      await AssociateService.confirmMembership(
        { username: AuthenticationService.getUserName() },
        this.id,
      );
      this.$emit('saved');
    },
  },
};
</script>
