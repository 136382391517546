<template>
  <div>
    <div class="row wrapper">
      <div class="col s12">
        <h1>Duplicates</h1>
      </div>
    </div>

    <div class="row wrapper">
      <div class="col s12">
        <div class="tabs">
          <div class="tabs__wrapper">
            <router-link :to="{ name: 'mergeDuplicateMainArtists', query: query }">
              Main Artists
            </router-link>
            <router-link :to="{ name: 'mergeDuplicateAlbums', query: query }">Albums</router-link>
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'ViewDuplicates',
  computed: {
    query() {
      const { pageSize } = this.$route.query;
      return pageSize ? { pageSize } : undefined;
    },
  },
};
</script>
