import Vue from 'vue';

// GLOBAL DIRECTIVES

Vue.directive('focus', {
  inserted: function inserted(el) {
    el.focus();
  },
});

export default function noop() {
  return undefined;
}
